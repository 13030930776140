import { Link } from "react-router-dom"
import { dateFormat, dateFormatTime } from '../helper/DateHelper'
import { getBoartStatus } from '../helper/PostStatusHelper'
import { AiFillCheckCircle } from "react-icons/ai"

const PostCard = ({post}) => {
    const number_format = new Intl.NumberFormat()
    const status = getBoartStatus(4, post.data.status, 'admin')

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} key={post.id}>
            <Link className="btn btn-white" to={`/admin/post/detail/4/${post.id}`} style={{ display: 'flex', flex: 1 }}>
                <div className="card" style={{ width: "16rem", alignItems:'center', display: 'flex', flex: 1 }}>
                    {(post.data.status==1) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-primary" style={{ color: '#ffffff'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {(post.data.status==2) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-primary">
                                <strong>สถานะ:</strong> {status} <span className="badge badge-light" style={{ backgroundColor: '#ff0000'}}>{post.offer.length}</span>
                            </button>
                        </div>)
                    }
                    {(post.data.status==3) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-warning" style={{ color: '#000000'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {(post.data.status==4) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {(post.data.status==5) &&
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-danger" style={{ color: '#FFFFFF'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {(post.data.status==6) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-danger" style={{ color: '#ffffff'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {(post.data.status==9) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center' }} >
                        <img src={post.data.picture} alt="WEEE" height="150" />
                    </div>
                    <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                        <div className="card-text" style={{ textAlign: 'left' }}>
                            {post.data.type} {post.brand} {post.model}<br/>
                        </div> 
                        <div className="card-text" style={{ textAlign: 'left' }}><strong>ปีที่ซื้อ :</strong> {post.data.buy_year?"พ.ศ. "+post.data.buy_year:"ไม่ระบุ"}</div>
                        <div className="card-text" style={{ textAlign: 'left' }}>
                            <strong>งบประมาณซ่อมไม่เกิน:</strong> {number_format.format(post.data.price)} บาท <br/>
                            <strong>อาการ:</strong> {post.broken} <br/>
                            <strong>อาการเพิ่มเติม:</strong> {post.data.description?post.data.description:"ไม่ระบุ"} <br/>
                        </div>
                        <div className="card-text" style={{ textAlign: 'left' }}>
                            <strong>รูปแบบการซ่อม:</strong> 
                            {post.data.fix_type=="1"?" ให้มาซ่อมในที่พักอาศัย":""}  
                            {post.data.fix_type=="2"?" ต้องการให้มายกไปซ่อมที่ร้าน":""}  
                            {post.data.fix_type=="3"?" ต้องการยกไปที่ร้านเอง":""} 
                            <br/>
                            <strong>ต้องการซ่อมไม่เกิน:</strong> {post.data.fix_day} วัน<br/>
                        </div>
                        <div className="card-text" style={{ textAlign: 'left' }}><strong>ผู้ประกาศ :</strong> {post.poster.data().firstname} {post.poster.data().lastname}</div> 
                        <div className="card-text" style={{ textAlign: 'left' }}><strong>วันที่ลงประกาศ:</strong><br/>{dateFormatTime(post.data.post_timestamp)}</div>
                    </div>
                </div>
            </Link>
        </div>
    )
} 

export default PostCard