import React, { useState, useEffect } from 'react';
import { collection, query, onSnapshot, addDoc, orderBy, updateDoc, doc, deleteDoc, getDocs, where } from "firebase/firestore"
import { db } from "../../assets/js/firebase";
import { RiAdminLine } from "react-icons/ri"
import { FiDelete, FiEdit, FiInfo } from "react-icons/fi"
import { GiElectric } from "react-icons/gi"
import { IoCloseCircle, IoCheckmarkCircleSharp } from "react-icons/io5"
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

const ElectircList = () => {
    const [electricList, setElectricList] = useState([])
    const [name, setName] = useState('')
    const [nameErrorDiv, setNameErrorDiv] = useState(false)

    const [editId, setEditId] = useState('')
    const [nameEdit, setNameEdit] = useState('')
    const [openEdit, setOpenEdit] = useState(false)
    const [nameEditErrorDiv, setNameEditErrorDiv] = useState(false)

    const [deleteId, setDeleteId] = useState('')
    const [openAlertDelete, setOpenAlertDelete] = useState(false)

    const [selectedElectricName, setSelectedElectricName] = useState('')
    const [selectedElectricId, setSelectedElectricId] = useState('')
    const [brandList, setBrandList] = useState([])
    const [brandName, setBrandName] = useState('')
    const [brandNameErrorDiv, setBrandNameErrorDiv] = useState(false)
    const [editBrandId, setEditBrandId] = useState('')
    const [brandNameEdit, setBrandNameEdit] = useState('')
    const [openBrandEdit, setOpenBrandEdit] = useState(false)
    const [brandNameEditErrorDiv, setBrandNameEditErrorDiv] = useState(false)
    const [deleteBrandId, setDeleteBrandId] = useState('')
    const [openAlertDeleteBrand, setOpenAlertDeleteBrand] = useState(false)

    const [selectedBrandName, setSelectedBrandName] = useState('')
    const [selectedBrandId, setSelectedBrandId] = useState('')
    const [modelList, setModelList] = useState([])
    const [modelName, setModelName] = useState('')
    const [modelNameErrorDiv, setModelNameErrorDiv] = useState(false)
    const [editModelId, setEditModelId] = useState('')
    const [modelNameEdit, setModelNameEdit] = useState('')
    const [openEditModel, setOpenEditModel] = useState(false)
    const [modelNameEditErrorDiv, setModelNameEditErrorDiv] = useState(false)
    const [deleteModelId, setDeleteModelId] = useState('')
    const [openAlertDeleteModel, setOpenAlertDeleteModel] = useState(false)

    const isSpecialChar = (text) => {
        return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/.test(text)
    }

    const handleCloseAlertDelete = () => {
        setDeleteId('')
        setOpenAlertDelete(false)
    }

    const handleOpenAlertDelete = (id) => {
        setDeleteId(id)
        setOpenAlertDelete(true)
    }

    const handleDelete = () => {
        deleteDoc(doc(db, `electric_type`, deleteId)).then(() => {
            handleCloseAlertDelete()
        })
    }

    const validateFormEdit = () => {
        if(!nameEdit || isSpecialChar(nameEdit)) {
            setNameEditErrorDiv(true)
            return false;
        } else {
            setNameEditErrorDiv(false)
        }
        return true
    }

    const handleEdit = (e) => {
    	e.preventDefault()
        if(validateFormEdit()) {
            updateDoc(doc(db, `electric_type`, editId), {
                name: nameEdit
            }).then(() => {
                setName('')
                handleCloseEdit()
            })
        }
    }

    const handleOpenEdit = (id, name) => {
        setEditId(id)
        setNameEdit(name)
        setNameEditErrorDiv(false)
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setEditId('')
        setNameEdit('')
        setNameEditErrorDiv(false)
        setOpenEdit(false)
    }

    const validateForm = () => {
        if(!name || isSpecialChar(name)) {
            setNameErrorDiv(true)
            return false;
        } else {
            setNameErrorDiv(false)
        }
        return true
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()
        if(validateForm()) {
            await addDoc(collection(db, "electric_type"), {
                name: name
            }).then(() => {
                setName('')
            })
        }
    }


    const handleGetBrand = (electricId, name) => {
        setSelectedElectricId(electricId)
        setSelectedElectricName(name)
        setSelectedBrandId('')
        setSelectedBrandName('')
        modelList.splice(0, modelList.length)
        setModelList([...modelList])
        getDocs(query(collection(db, 'brands'), where('electric_id', '==', electricId), orderBy('name', 'asc'))).then((brandDocs) => {
            setBrandList(brandDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const validateBrandForm = () => {
        if(!brandName || isSpecialChar(brandName)) {
            setBrandNameErrorDiv(true)
            return false;
        } else {
            setBrandNameErrorDiv(false)
        }
        return true
    }

    const handleSubmitBrand = async (e) => {
    	e.preventDefault()
        if(validateBrandForm()) {
            await addDoc(collection(db, "brands"), {
                electric_id: selectedElectricId,
                name: brandName
            }).then(() => {
                handleGetBrand(selectedElectricId, selectedElectricName)
                setBrandName('')
            })
        }
    }

    const handleOpenEditBrand = (id, name) => {
        setEditBrandId(id)
        setBrandNameEdit(name)
        setBrandNameEditErrorDiv(false)
        setOpenBrandEdit(true)
    }

    const validateFormEditBrand = () => {
        if(!brandNameEdit || isSpecialChar(brandNameEdit)) {
            setBrandNameEditErrorDiv(true)
            return false;
        } else {
            setBrandNameEditErrorDiv(false)
        }
        return true
    }

    const handleEditBrand = (e) => {
    	e.preventDefault()
        if(validateFormEditBrand()) {
            updateDoc(doc(db, `brands`, editBrandId), {
                name: brandNameEdit
            }).then(() => {
                setBrandNameEdit('')
                handleGetBrand(selectedElectricId, selectedElectricName)
                handleCloseEditBrand()
            })
        }
    }

    const handleCloseEditBrand = () => {
        setEditBrandId('')
        setBrandNameEdit('')
        setBrandNameEditErrorDiv(false)
        setOpenBrandEdit(false)
    }

    const handleOpenAlertDeleteBrand = (id) => {
        setDeleteBrandId(id)
        setOpenAlertDeleteBrand(true)
    }

    const handleCloseAlertDeleteBrand = () => {
        setDeleteBrandId('')
        setOpenAlertDeleteBrand(false)
    }

    const handleDeleteBrand = () => {
        deleteDoc(doc(db, `brands`, deleteBrandId)).then(() => {
            setDeleteBrandId('')
            handleGetBrand(selectedElectricId, selectedElectricName)
            handleCloseAlertDeleteBrand()
        })
    }


    const handleGetModel = (brandId, name) => {
        setSelectedBrandId(brandId)
        setSelectedBrandName(name)
        getDocs(query(collection(db, 'models'), where('brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleSubmitModel = async (e) => {
    	e.preventDefault()
        if(validateFormModel()) {
            await addDoc(collection(db, "models"), {
                brand_id: selectedBrandId,
                name: modelName
            }).then(() => {
                handleGetModel(selectedBrandId, selectedBrandName)
                setModelName('')
            })
        }
    }

    const validateFormModel = () => {
        if(!modelName || isSpecialChar(modelName)) {
            setModelNameErrorDiv(true)
            return false;
        } else {
            setModelNameErrorDiv(false)
        }
        return true
    }

    const handleCloseEditModel = () => {
        setEditModelId('')
        setModelNameEdit('')
        setModelNameEditErrorDiv(false)
        setOpenEditModel(false)
    }

    const handleOpenEditModel = (id, name) => {
        setEditModelId(id)
        setModelNameEdit(name)
        setModelNameEditErrorDiv(false)
        setOpenEditModel(true)
    }

    const handleEditModel = (e) => {
    	e.preventDefault()
        if(validateFormEditModel()) {
            updateDoc(doc(db, `models`, editModelId), {
                name: modelNameEdit
            }).then(() => {
                setModelNameEdit('')
                handleGetModel(selectedBrandId, selectedBrandName)
                handleCloseEditModel()
            })
        }
    }

    const validateFormEditModel = () => {
        if(!modelNameEdit || isSpecialChar(modelNameEdit)) {
            setModelNameEditErrorDiv(true)
            return false;
        } else {
            setModelNameEditErrorDiv(false)
        }
        return true
    }

    const handleDeleteModel = () => {
        deleteDoc(doc(db, `models`, deleteModelId)).then(() => {
            handleGetModel(selectedBrandId, selectedBrandName)
            handleCloseAlertDeleteModel()
        })
    }

    const handleOpenAlertDeleteModel = (id) => {
        setDeleteModelId(id)
        setOpenAlertDeleteModel(true)
    }

    const handleCloseAlertDeleteModel = () => {
        setDeleteModelId('')
        setOpenAlertDeleteModel(false)
    }


    useEffect(() => {
        const electricQuery = query(collection(db, 'electric_type'), orderBy('name', 'asc'))
        onSnapshot(electricQuery, (querySnapshot) => {
            setElectricList(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><RiAdminLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายการประเภท/ยี่ห้อ/รุ่น เครื่องใช้ไฟฟ้า</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 20, paddingRight: 20, borderRight: "1px solid #dddddd"}}>
                    <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                        <h6><GiElectric style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                        <b>เพิ่มประเภทเครื่องใช้ไฟฟ้า</b></h6>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 105 }}><strong>ชื่อประเภท <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='name' onChange={(e) => setName(e.target.value)} 
                                        value={name} placeholder='ชื่อประเภท'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex' }} />
                                    {nameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกขื่อประเภทเครื่องใช้ไฟฟ้า</strong>
                                        </div>
                                    }
                                </div>
                            </div>  
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop:7}}>
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                    <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                </button>
                            </div>
                        </div>
                    </div>
                    <table className="table" style={{ marginLeft: 10}}>
                        <thead>
                            <tr>
                                <th scope="col" colSpan={2} style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ประเภทเครื่องใช้ไฟฟ้า</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            electricList?.map((electric, i) => (
                                <tr key={i}>
                                    <th scope="row">{electric.data.name}</th>
                                    <td style={{ display: 'flex', flexDirection: 'row'}}>
                                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenEdit(electric.id, electric.data.name) }}><FiEdit style={{ fontSize: 20 }} /></button>
                                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenAlertDelete(electric.id) }}><IoCloseCircle style={{ fontSize: 20 }} /></button>
                                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleGetBrand(electric.id, electric.data.name) }}><FiInfo style={{ fontSize: 20, marginRight: 5 }} />ยี่ห้อ</button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>

                <div style={{ marginRight: 20, paddingRight: 20, borderRight: "1px solid #dddddd" }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                        {selectedElectricId != '' && 
                        <>
                        <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                            <h6><GiElectric style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>เพิ่มยี่ห้อเครื่องใช้ไฟฟ้า ภายใต้ประเภท {selectedElectricName}</b></h6>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 90}}><strong>ชื่อยี่ห้อ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='brandName' onChange={(e) => setBrandName(e.target.value)} 
                                            value={brandName} placeholder='ชื่อยี่ห้อ'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex' }} />
                                        {brandNameErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกยี่ห้อเครื่องใช้ไฟฟ้า</strong>
                                            </div>
                                        }
                                    </div>
                                </div>  
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop:7}}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmitBrand}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                            </div>
                        </div></>
                        }
                        {brandList.length > 0 && 
                        <div>
                            <table className="table" style={{ marginLeft:0}}>
                                <thead>
                                    <tr>
                                        <th scope="col" colSpan={2} style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ยี่ห้อเครื่องใช้ไฟฟ้าในประเภท {selectedElectricName}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    brandList?.map((brand, i) => (
                                        <tr key={i}>
                                            <th scope="row">{brand.data.name}</th>
                                            <td style={{ display: 'flex', flexDirection: 'row'}}>
                                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenEditBrand(brand.id, brand.data.name) }}><FiEdit style={{ fontSize: 20 }} /></button>
                                                <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenAlertDeleteBrand(brand.id) }}><IoCloseCircle style={{ fontSize: 20 }} /></button>
                                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleGetModel(brand.id, brand.data.name) }}><FiInfo style={{ fontSize: 20, marginRight: 5 }} />รุ่น</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                        {selectedBrandId != '' && 
                        <>
                        <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                            <h6><GiElectric style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>เพิ่มรุ่นเครื่องใช้ไฟฟ้า ภายใต้ยี่ห้อ {selectedBrandName}</b></h6>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1, marginBottom: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 80}}><strong>ชื่อรุ่น <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='modelName' onChange={(e) => setModelName(e.target.value)} 
                                            value={modelName} placeholder='ชื่อรุ่น'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {modelNameErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกรุ่นเครื่องใช้ไฟฟ้า</strong>
                                            </div>
                                        }
                                    </div>
                                </div>  
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop:7}}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmitModel}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                            </div>
                        </div></>
                        }
                        {modelList.length > 0 && 
                        <div>
                            <table className="table" style={{ marginLeft:0}}>
                                <thead>
                                    <tr>
                                        <th scope="col" colSpan={2} style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>รุ่นเครื่องใช้ไฟฟ้าในยี่ห้อ {selectedBrandName}</th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    modelList?.map((model, i) => (
                                        <tr key={i}>
                                            <th scope="row">{model.data.name}</th>
                                            <td style={{ display: 'flex', flexDirection: 'row'}}>
                                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenEditModel(model.id, model.data.name) }}><FiEdit style={{ fontSize: 20 }} /></button>
                                                <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenAlertDeleteModel(model.id) }}><IoCloseCircle style={{ fontSize: 20 }} /></button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>
            </div>

            <Dialog open={openEdit} onClose={handleCloseEdit}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>แก้ไขประเภทเครื่องใช้ไฟฟ้า</strong></div></DialogTitle>
                <DialogContent>
                    <div><h6>ชื่อประเภทเครื่องใช้ไฟฟ้า</h6></div>
                    <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={nameEdit}
                        onChange={(e) => setNameEdit(e.target.value)}
                        style={{ paddingLeft: 5, marginTop: 15 }}
                    />
                    {nameEditErrorDiv &&
                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                            <strong>** กรุณากรอกขื่อประเภทเครื่องใช้ไฟฟ้า</strong>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseEdit} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleEdit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertDelete} onClose={handleCloseAlertDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบประเภทเครื่องใช้ไฟฟ้านี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDelete} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { handleDelete() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openBrandEdit} onClose={handleCloseEditBrand}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>แก้ไขยี่ห้อเครื่องใช้ไฟฟ้า</strong></div></DialogTitle>
                <DialogContent>
                    <div><h6>ชื่อยี่ห้อเครื่องใช้ไฟฟ้า</h6></div>
                    <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={brandNameEdit}
                        onChange={(e) => setBrandNameEdit(e.target.value)}
                        style={{ paddingLeft: 5, marginTop: 15 }}
                    />
                    {brandNameEditErrorDiv &&
                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                            <strong>** กรุณากรอกยี่ห้อเครื่องใช้ไฟฟ้า</strong>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseEditBrand} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleEditBrand} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertDeleteBrand} onClose={handleCloseAlertDeleteBrand} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบยี่ห้อเครื่องใช้ไฟฟ้านี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDeleteBrand} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { handleDeleteBrand() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEditModel} onClose={handleCloseEditModel}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>แก้ไขรุ่นเครื่องใช้ไฟฟ้า</strong></div></DialogTitle>
                <DialogContent>
                    <div><h6>ชื่อรุ่นเครื่องใช้ไฟฟ้า</h6></div>
                    <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={modelNameEdit}
                        onChange={(e) => setModelNameEdit(e.target.value)}
                        style={{ paddingLeft: 5, marginTop: 15 }}
                    />
                    {modelNameEditErrorDiv &&
                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                            <strong>** กรุณากรอกยรุ่นเครื่องใช้ไฟฟ้า</strong>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseEditModel} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleEditModel} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertDeleteModel} onClose={handleCloseAlertDeleteModel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบรุ่นเครื่องใช้ไฟฟ้านี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDeleteModel} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { handleDeleteModel() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
        </div>
        
    )
}

export default ElectircList;