import { collection, addDoc } from 'firebase/firestore'

export function addShopCoinLog(db, shopId, amount, event, coinType, logType, id, from, to, add) {
    const logDate = new Date()
    //addDoc(collection(db, `/shops/${shopId}/coin_log`), {
    addDoc(collection(db, `/shops/${shopId}/coin_logs`), {
        date: logDate.getTime(),
        type: logType,
        amount: amount,
        event: event,
        coin_type: coinType,
        id: id,
        from: from,
        to: to,
        add: add
    })
}

export function addUserCoinLog(db, userId, amount, event, coinType, logType, id, from, to, add) {
    const logDate = new Date()
    //addDoc(collection(db, `/users/${userId}/coin_log`), {
    addDoc(collection(db, `/users/${userId}/coin_logs`), {
        date: logDate.getTime(),
        type: logType,
        amount: amount,
        event: event,
        coin_type: coinType,
        id: id,
        from: from,
        to: to,
        add: add
    })
}

export function addPlatformCoinLog(db, amount, event, coinType, logType, id, from, fromId, to, toId, add) {
    const logDate = new Date()
    addDoc(collection(db, `/coins/used_log/transaction`), {
        date: logDate.getTime(),
        type: logType,
        amount: amount,
        event: event,
        coin_type: coinType,
        id: id,
        from: from,
        from_id: fromId,
        to: to,
        to_id: toId,
        add: add
    })
}

export function addFreeCoinLog(db, amount, event, userId, logType) {
    const logDate = new Date()
    addDoc(collection(db, `/free_coins/logs/transaction`), {
        date: logDate.getTime(),
        amount: amount,
        event: event,
        user_id: userId,
        type: logType
    })
}

export function addExchangeLog(db, amount, event, userId, logType) {
    const logDate = new Date()
    addDoc(collection(db, `/exchange_coins/logs/transaction`), {
        date: logDate.getTime(),
        amount: amount,
        event: event,
        user_id: userId,
        type: logType
    })
}