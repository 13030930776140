import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { signOut, GoogleAuthProvider, signInWithCredential, signInWithEmailAndPassword } from "firebase/auth"
import { useGoogleLogin } from '@react-oauth/google'
import { useNavigate } from "react-router-dom"
import logo from "../../assets/images/weee-logo.png"
import { collection, addDoc, getDoc, doc, updateDoc, getDocs, query, where } from "firebase/firestore"
import gmail from "../../assets/images/gmail-logo.png"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from "react-icons/io5"
import { defaultLatLng } from "../../../assets/constants/locations"
import { Loading } from "../layouts/Loading"

const Login = () => {
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [openErrorDiv, setOpenErrorDiv] = useState(false)
    const [errorMessage, setErrorMessage] = useState('')
    const [openAcceptPolicy1, setOpenAcceptPolicy1] = useState(false)
    const [openAcceptPolicy2, setOpenAcceptPolicy2] = useState(false)
    const [openAcceptPolicy3, setOpenAcceptPolicy3] = useState(false)
    const [openAcceptPolicy4, setOpenAcceptPolicy4] = useState(false)
    const [openAcceptPolicy5, setOpenAcceptPolicy5] = useState(false)
    const [openAcceptPolicy6, setOpenAcceptPolicy6] = useState(false)
    const [checkPolicy1, setCheckPolicy1] = useState(false)
    const [checkPolicy2, setCheckPolicy2] = useState(false)
    const [checkPolicy3, setCheckPolicy3] = useState(false)
    const [checkPolicy4, setCheckPolicy4] = useState(false)
    const [checkPolicy5, setCheckPolicy5] = useState(false)
    const [checkPolicy6, setCheckPolicy6] = useState(false)
    const [policy1ErrorDiv, setPolicy1ErrorDiv] = useState(false)
    const [policy2ErrorDiv, setPolicy2ErrorDiv] = useState(false)
    const [policy3ErrorDiv, setPolicy3ErrorDiv] = useState(false)
    const [policy4ErrorDiv, setPolicy4ErrorDiv] = useState(false)
    const [policy5ErrorDiv, setPolicy5ErrorDiv] = useState(false)
    const [policy6ErrorDiv, setPolicy6ErrorDiv] = useState(false)
    const [signInLoading, setSignInLoading] = useState(false)
    const [googleError, setGoogleError] = useState('')

    const onMobile = window.innerWidth < 576

    const startAcceptPolicy = () => {
        setOpenAcceptPolicy1(true)
    }

    const closeAllPolicyError = () => {
        signOut(auth)
        setSignInLoading(false)
        setPolicy1ErrorDiv(false)
        setPolicy2ErrorDiv(false)
        setPolicy3ErrorDiv(false)
        setPolicy4ErrorDiv(false)
        setPolicy5ErrorDiv(false)
        setPolicy6ErrorDiv(false)
        setCheckPolicy1(false)
        setCheckPolicy2(false)
        setCheckPolicy3(false)
        setCheckPolicy4(false)
        setCheckPolicy5(false)
        setCheckPolicy6(false)
    }

    const handleCloseAcceptPolicy1 = () => {
        setOpenAcceptPolicy1(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy2 = () => {
        setOpenAcceptPolicy2(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy3 = () => {
        setOpenAcceptPolicy3(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy4 = () => {
        setOpenAcceptPolicy4(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy5 = () => {
        setOpenAcceptPolicy5(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy6 = () => {
        setOpenAcceptPolicy6(false)
        closeAllPolicyError()
    }

    const handleCheckPolicy1 = () => {
        setCheckPolicy1(!checkPolicy1)
    }

    const handleCheckPolicy2 = () => {
        setCheckPolicy2(!checkPolicy2)
    }

    const handleCheckPolicy3 = () => {
        setCheckPolicy3(!checkPolicy3)
    }

    const handleCheckPolicy4 = () => {
        setCheckPolicy4(!checkPolicy4)
    }

    const handleCheckPolicy5 = () => {
        setCheckPolicy5(!checkPolicy5)
    }

    const handleCheckPolicy6 = () => {
        setCheckPolicy6(!checkPolicy6)
    }

    const handleAcceptPolicy1 = () => {
        if(!checkPolicy1) {
            setPolicy1ErrorDiv(true)
        } else {
            setPolicy1ErrorDiv(false)
            setOpenAcceptPolicy1(false)
            setOpenAcceptPolicy2(true)
        }
    }

    const handleAcceptPolicy2 = () => {
        if(!checkPolicy2) {
            setPolicy2ErrorDiv(true)
        } else {
            setPolicy2ErrorDiv(false)
            setOpenAcceptPolicy2(false)
            setOpenAcceptPolicy3(true)
        }
    }

    const handleAcceptPolicy3 = () => {
        if(!checkPolicy3) {
            setPolicy3ErrorDiv(true)
        } else {
            setPolicy3ErrorDiv(false)
            setOpenAcceptPolicy3(false)
            setOpenAcceptPolicy4(true)
        }
    }

    const handleAcceptPolicy4 = () => {
        if(!checkPolicy4) {
            setPolicy4ErrorDiv(true)
        } else {
            setPolicy4ErrorDiv(false)
            setOpenAcceptPolicy4(false)
            setOpenAcceptPolicy5(true)
        }
    }

    const handleAcceptPolicy5 = () => {
        if(!checkPolicy5) {
            setPolicy5ErrorDiv(true)
        } else {
            setPolicy5ErrorDiv(false)
            setOpenAcceptPolicy5(false)
            setOpenAcceptPolicy6(true)
        }
    }

    const handleAcceptPolicy6 = () => {
        if(!checkPolicy6) {
            setPolicy6ErrorDiv(true)
        } else {
            setPolicy6ErrorDiv(false)
            setOpenAcceptPolicy6(false)
            //signInWithGoogle()
            addUser()
        }
    }

    const addUser = () => {
        addDoc(collection(db, "users"), {
            uid: user.uid,
            authProvider: "google",
            type: 1,
            email: user.email,
            display_name: user.displayName,
            lat: defaultLatLng.lat,
            lng: defaultLatLng.lng,
            status: 1
        }).then((docRef) => {
            getDoc(doc(db, 'free_coins', 'get_free_rate')).then((giveSnapshot) => {
                const data = giveSnapshot.data()
                updateDoc(doc(db, 'users', docRef.id), {
                freecoin: data.coin*1
                })
            }).then(() => {
                return navigate("/profile")
            })
        })
    }


    const register = async () => {
        signInWithEmailAndPassword(auth, email, password).then((result) => {

        }).catch((error) => {
            setErrorMessage("กรุณาตรวจสอบ email และรหัสผ่านของคุณ")
            setOpenErrorDiv(true)
            if (error.code === 'auth/user-not-found') {
            }
        
            if (error.code === 'auth/invalid-email') {
                
            }
            console.error(error)
        })
    }

    const checkUser = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if(docs.docs.length > 0) {
                navigate("/profile")
            } else {
            }
        })
    }

    const signInWithGoogle = useGoogleLogin({
        onSuccess: (codeResponse) => {
            setSignInLoading(true)
            const credential = GoogleAuthProvider.credential(null, codeResponse.access_token)
            signInWithCredential(auth, credential).then((result) => {
                const user = result.user
                getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
                    if (docs.docs.length === 0) {
                        startAcceptPolicy(0)
                        /* addDoc(collection(db, "users"), {
                            uid: user.uid,
                            authProvider: "google",
                            type: 1,
                            email: user.email,
                            display_name: user.displayName,
                            lat: defaultLatLng.lat,
                            lng: defaultLatLng.lng,
                            status: 1
                        }).then((docRef) => {
                            getDoc(doc(db, 'free_coins', 'get_free_rate')).then((giveSnapshot) => {
                                const data = giveSnapshot.data()
                                updateDoc(doc(db, 'users', docRef.id), {
                                freecoin: data.coin*1
                                })
                            }).then(() => {
                                return navigate("/profile")
                            })
                        }) */
                    } else {
                        return navigate("/profile")
                    }
                })
            })
        },
        onError: (error) => console.log('Login Failed:', error)
    })

    useEffect(() => {
        if (loading) return;
        if (user) checkUser()
    }, [user, loading])

    return (
        <>
            <Loading open={signInLoading} text={"กำลังเชื่อมต่อข้อมูล กรุณารอสักครู่"} />
            <div style={{ backgroundColor: "#EEEEEE", minHeight: 550, paddingTop: 10}}>
                <div className="container">
                    <div className="col-xs-12 col-md-4 offset-md-4">
                        <div className="card" style={{ background: "#fff", marginTop: 40 }}>
                            <div className="card-body">
                                <div className="text-center">
                                    <img src={logo} className="" style={{ height: 25 }} alt="WEEE" />
                                </div>
                                <div className="text-center" style={{ marginTop: 8, fontSize: 22 }}><b>เข้าใช้ระบบ</b></div>
                                <div style={{ display:'flex', marginTop: 10, flexDirection: 'column', width:'100%', marginBottom: 15 }}>
                                    <div style={{ marginBottom: 10, display: 'flex', flex: 1 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 80}}><strong>อีเมล <font className="text-danger">*</font></strong></label>
                                        <input className='form-control' type='text' name='email' onChange={(e) => setEmail(e.target.value)} 
                                            value={email} placeholder='อีเมล' style={{ display: 'flex', flex: 1 }} />
                                    </div>
                                    <div style={{ marginBottom: 10, display: 'flex', flex: 1, width: '100%' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 80}}><strong>รหัสผ่าน <font className="text-danger">*</font></strong></label>
                                        <input className='form-control' type='password' name='password' onChange={(e) => setPassword(e.target.value)} 
                                            value={password} placeholder='รหัสผ่าน' style={{ display: 'flex', flex: 1 }}/>
                                    </div>
                                    {openErrorDiv &&
                                        <div className="card-body text-danger border-danger" style={{ marginLeft: 80, marginTop:5}}>
                                            <p className="font-weight-bold"><strong>** {errorMessage}</strong></p>
                                        </div>
                                    }
                                    <div className="d-grid gap-2" style={{ marginTop: 0 }}>
                                        <button className="btn btn-primary" type='button' onClick={register}>เข้าใช้ระบบ</button>
                                    </div>
                                    <div className="d-grid gap-2" style={{ marginTop: 10 }}>
                                        <button className="btn btn-success" type='button' onClick={() => { navigate("/register" ) }}>สมัครสมาชิก</button>
                                    </div>
                                    <div className="d-grid gap-2" style={{ marginTop: 10, textAlign: 'center' }}>
                                        <h5><strong>หรือ</strong></h5>
                                    </div>
                                    <div className="d-grid gap-1" style={{ textAlign: 'center' }}>
                                        <button className="btn btn-light" type="button" style={{ paddingLeft: 20 }} onClick={() => { signInWithGoogle() }}><img src={gmail} className="" style={{ height: 15, marginRight: 5 }} alt="gmail" /> เชื่อมต่อด้วย Google Account</button> {googleError}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={openAcceptPolicy1} onClose={handleCloseAcceptPolicy1} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>เงื่อนไขการใช้งาน</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. แพลตฟอร์มนี้ให้บริการเฉพาะผู้ที่บรรลุนิติภาวะแล้วเท่านั้น หากท่านยังไม่บรรลุนิติภาวะ ท่านต้องหยุดการเข้าถึงแพลตฟอร์มในขณะนี้ การใช้บริการแพลตฟอร์มเป็นการแสดงเจตนายืนยันว่าท่านบรรลุนิติภาวะแล้ว และมีคุณสมบัติครบถ้วนที่จะทำการยอมรับเงื่อนไขการใช้งาน อย่างไรก็ตามหาก หจก. ออนซอน พบว่าท่านมีคุณสมบัติไม่ครบถ้วนตามที่ หจก. ออนซอน กำหนด ทั้งนี้ หจก. ออนซอน มีสิทธิ์ที่จะระงับการใช้งานบัญชีผู้ใช้ของท่าน และหากมีความเสียหายหรือข้อร้องเรียนอันเกิดจากท่านทั้งตั้งใจหรือการแอบอ้างโดยใช้ชื่อหรือหลักฐานของผู้อื่นถือว่าท่านยอมรับให้ดำเนินคดีตามกฎหมายและยินยอมชดใช้ความเสียหายทั้งหมดรวมถึงค่าใช้จ่ายในการดำเนินคดีทั้งหมด</p>
                            <p>2. เงื่อนไขการใช้งานนี้เป็นสัญญาระหว่าง หจก. ออนซอน ในฐานะผู้ให้บริการแพลตฟอร์มกับท่าน ในฐานะผู้ใช้บริการแพลตฟอร์ม (“ท่าน” หรือ “ผู้ใช้งาน”) ท่านตกลงว่าความสัมพันธ์ระหว่างท่านกับ หจก. ออนซอน อันเป็นผลมาจากการทำสัญญานี้ หรือจากการใช้งานแพลตฟอร์ม ไม่ถือเป็นการเป็นหุ้นส่วน การลงทุนร่วม หรือความสัมพันธ์ระหว่างตัวการกับตัวแทน ระหว่างท่านและ หจก. ออนซอน และไม่มีสิ่งใดอนุญาตให้ท่านสร้างภาระค่าใช้จ่ายหรือหนี้สินในนามของหจก. ออนซอน</p>
                            <p>3. หจก. ออนซอน ขอสงวนสิทธิที่จะแก้ไขหรือเปลี่ยนแปลงเงื่อนไขการใช้งานโดยไม่ต้องแจ้งให้ท่านทราบ ท่านมีหน้าที่ที่จะต้องคอยตรวจสอบเงื่อนไขการใช้งาน รวมทั้งเงื่อนไขเพิ่มเติมใดๆ ที่ระบุอยู่ในแพลตฟอร์มอย่างสม่ำเสมอ การใช้แพลตฟอร์มของท่านอย่างต่อเนื่องย่อมก่อให้เกิดการตกลงและสัญญาตามเงื่อนไขการใช้งานใดๆที่ได้ถูกแก้ไขหรือเปลี่ยนแปลงดังกล่าวทั้งหมดด้วย</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy1?"checked":""} onChange={(e) => handleCheckPolicy1(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy1ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy1} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy1} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy2} onClose={handleCloseAcceptPolicy2} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>การเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. ท่านตกลงและยอมรับว่าข้อมูลส่วนบุคคลของท่านทั้งหมดที่ หจก. ออนซอน รวบรวมได้จะถูกเก็บรวบรวม ถูกใช้ และ/หรือถูกเปิดเผยต่อบุคคลและเพื่อวัตถุประสงค์ตามที่ระบุอยู่ในนโยบายการคุ้มครองข้อมูลส่วนบุคคลของแพลตฟอร์ม</p>
                            <p>2. ท่านยินยอมและอนุญาตในการที่ หจก. ออนซอน ใช้ข้อมูลใดๆ ที่ท่านให้ไว้ (รวมทั้งข้อมูลส่วนบุคคล) เพื่อประโยชน์ในการส่งอีเมล์ข้อมูลรายละเอียดหรือการส่งเสริมการขายให้แก่ท่าน ท่านสามารถยกเลิกรับอีเมล์ส่งเสริมการขายในภายหลังได้ด้วยการคลิกที่ลิงค์ที่แนบไว้กับอีเมล์ส่งเสริมการขายใดๆ</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy2?"checked":""} onChange={(e) => handleCheckPolicy2(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy2ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy2} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy2} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy3} onClose={handleCloseAcceptPolicy3} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>สัญญาให้บริการ</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. สัญญาให้บริการระบบ Platform 3R ระหว่างผู้ใช้บริการแพลตฟอร์ม ประกอบไปด้วย ประชาชนทั่วไปที่สมัครเข้าใช้งานแพลตฟอร์ม  ในข้อตกลงนี้ คือ “สมาชิก” และสมาชิกที่เป็นนิติบุคคล ร้าน หรือ บริษัท ในข้อตกลงนี้ คือ “ผู้ให้บริการ”</p>
                            <p>2. สัญญาให้บริการระบบ Platform 3R เป็นสัญญาโดยตรงระหว่างสมาชิกและผู้ให้บริการ หจก. ออนซอน ไม่ได้เป็นคู่สัญญาของสัญญาฉบับนั้นหรือสัญญาอื่นใดระหว่างสมาชิกและผู้ให้บริการ หจก. ออนซอน จะไม่ยอมรับข้อผูกพันใดๆที่เกี่ยวเนื่องกับสัญญาดังกล่าว คู่สัญญาของธุรกรรมดังกล่าวจะรับผิดชอบต่อสัญญาระหว่างคู่สัญญาทั้งหมด หจก. ออนซอน ไม่มีส่วนเกี่ยวข้องในธุรกรรมระหว่างสมาชิกและผู้ให้บริการ หจก. ออนซอน ไม่รับประกันในผลของงานใดๆ ของผู้ให้บริการ</p>
                            <p>3. หจก. ออนซอน แนะนำให้สมาชิกต้องใช้ความระมัดระวังรอบคอบในการตรวจสอบความมีตัวตน ความน่าเชื่อถือ และความรู้ ความสามารถ ของผู้ให้บริการหรือคู่สัญญา</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy3?"checked":""} onChange={(e) => handleCheckPolicy3(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy3ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy3} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy3} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy4} onClose={handleCloseAcceptPolicy4} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>บัญชีผู้ใช้งานและความปลอดภัย</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. การใช้บริการบางอย่างของแพลตฟอร์มอาจจำเป็นต้องมีการลงทะเบียนสร้างบัญชีผู้ใช้งาน โดยการกำหนดชื่อผู้ใช้งาน (username) ที่ไม่ซ้ำกันและรหัสผ่าน และการให้ข้อมูลบางอย่างของท่าน ท่านยืนยันว่าข้อมูลต่างๆที่ใช้ในการสร้างบัญชีผู้ใช้งานเป็นข้อมูลที่ถูกต้องแท้จริงของท่านเอง ท่านต้องไม่สร้างบัญชีผู้ใช้งานมากกว่า 1 บัญชี ท่านต้องไม่แกล้งทำเป็นบุคคลใดนอกเหนือจากตัวท่านเอง ท่านต้องไม่ทำให้ หจก. ออนซอน หรือบุคคลภายนอกเข้าใจผิดในเรื่องต้นกำเนิดของการส่งข้อมูลใดๆ และท่านตกลงและยอมรับว่าสิทธิการเป็นเจ้าของในข้อมูลของบัญชีผู้ใช้งานเป็นของ หจก. ออนซอน และของท่านร่วมกัน</p>
                            <p>2. หจก. ออนซอน ห้ามไม่ให้ท่านใช้บัญชีผู้ใช้งานของบุคคลอื่นโดยไม่ได้รับอนุญาต ท่านจะต้องรักษารหัสของบัญชีผู้ใช้งานของท่านไว้อย่างปลอดภัย ท่านจะไม่เปิดเผยข้อมูลของบัญชีผู้ใช้งานต่อบุคคลภายนอกไม่ว่าทั้งหมดหรือบางส่วน และจะไม่ยินยอมให้บุคคลภายนอกสามารถเข้าถึงหรือใช้ข้อมูลบัญชีผู้ใช้ของท่าน ท่านจะต้องรับผิดชอบแต่เพียงผู้เดียวสำหรับกิจกรรมใดๆ ที่เกิดขึ้นในและ/หรือจากบัญชีผู้ใช้งานของท่าน โดยท่านจะต้องรับผิดต่อความสูญเสียหรือเสียหายที่ หจก. ออนซอน หรือบุคคลภายนอกอื่นได้รับเนื่องจากการเข้าถึงบัญชีผู้ใช้งานและ/หรือการทำกิจกรรมใดๆผ่านบัญชีผู้ใช้งานของท่าน</p>
                            <p>3. ท่านตกลงที่จะเปลี่ยนรหัสผ่านของท่านเป็นครั้งคราว หจก. ออนซอน อาจจะร้องขอให้ท่านอัพเดทข้อมูลของท่าน หรือยกเลิกชื่อผู้ใช้งานและ/หรือรหัสผ่านทันทีในเวลาใดๆ โดยดุลพินิจแต่ฝ่ายเดียวของ หจก. ออนซอน โดยไม่ต้องให้เหตุผลหรือคำบอกกล่าวล่วงหน้า และ หจก. ออนซอน ไม่ต้องรับผิดหรือรับผิดชอบในความสูญเสียใดๆ ที่ท่านได้รับจากคำขอหรือการยกเลิกดังกล่าว</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy4?"checked":""} onChange={(e) => handleCheckPolicy4(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy4ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy4} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy4} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy5} onClose={handleCloseAcceptPolicy5} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ลิงก์ของบุคคลภายนอก</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. แพลตฟอร์มอาจมีลิงก์ไปยังเว็บไซต์ แพลตฟอร์ม เครือข่าย หรือสื่ออื่นใดของบุคคลภายนอก (“ลิงก์ของบุคคลภายนอก”) ลิงก์ของบุคคลภายนอกเหล่านี้ไม่ได้เป็นของหรือควบคุมโดย หจก. ออนซอน แต่ดำเนินการโดย และเป็นทรัพย์สินของบุคคลภายนอก หจก. ออนซอน ไม่ได้ตรวจสอบและไม่รับผิดชอบต่อเนื้อหา ฟังก์ชันการทำงาน ความปลอดภัย บริการ นโยบายการคุ้มครองข้อมูลส่วนบุคคล หรือการปฏิบัติอื่นๆ ของลิงก์ของบุคคลภายนอกเหล่านี้ หจก. ออนซอน ขอแนะนำให้ท่านอ่านข้อกำหนดและนโยบายใดๆ ที่เผยแพร่โดยบุคคลภายนอกดังกล่าว อีกทั้งท่านรับทราบและตกลงว่า หจก. ออนซอน จะไม่รับผิดชอบในลักษณะใดก็ตามอันเนื่องมาจากการใช้งานหรือการไม่สามารถใช้งานลิงก์ของบุคคลภายนอก</p>
                            <p>2. ท่านรับทราบและตกลงว่า หจก. ออนซอน สงวนสิทธิ์ในการลบลิงก์ของบุคคลภายนอกโดยไม่ต้องให้เหตุผลหรือคำบอกกล่าวล่วงหน้า และ หจก. ออนซอน ไม่ต้องรับผิดชอบในความสูญเสียใดๆ จากการลบลิงก์ของบุคคลภายนอกดังกล่าว</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy5?"checked":""} onChange={(e) => handleCheckPolicy5(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy5ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy5} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy5} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy6} onClose={handleCloseAcceptPolicy6} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ข้อกำหนดทั่วไป</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. หจก. ออนซอน สงวนสิทธิทั้งหมดที่ไม่ได้แจ้งไว้ในที่นี้</p>
                            <p>2. เว้นแต่จะระบุไว้เป็นอย่างอื่น สกุลเงินที่ใช้อ้างถึงบนแพลตฟอร์มคือเงินบาทไทย</p>
                            <p>3. การที่ หจก. ออนซอน ไม่ได้บังคับตามเงื่อนไขการใช้งานนี้จะไม่ถือเป็นการสละสิทธิในเงื่อนไขนี้ และการไม่ได้บังคับดังกล่าวจะไม่กระทบต่อสิทธิที่จะบังคับตามเงื่อนไขการใช้งานนี้ในภายหลัง หจก. ออนซอน ยังคงมีสิทธิที่จะใช้สิทธิและการเยียวยาของ หจก. ออนซอน ในสถานการณ์อื่นใดที่ท่านกระทำการผิดสัญญาในเงื่อนไขการใช้งานนี้</p>
                            <p>4. หากข้อกำหนดใดในเงื่อนไขการใช้งานนี้ถือว่าผิดกฎหมาย เป็นโมฆะ หรือไม่สามารถบังคับใช้ได้ไม่ว่าด้วยเหตุผลใด ให้ถือว่าข้อกำหนดนั้นสามารถแยกออกจากกันได้จากข้อกำหนดที่เหลือ และไม่ส่งผลต่อความสมบูรณ์และการมีผลบังคับใช้ของข้อกำหนดที่เหลือ</p>
                            <p>5. หจก. ออนซอน สงวนสิทธิที่จะมอบหมายหรือทำสัญญาช่วงการปฏิบัติตามหน้าที่ใดๆ ของ หจก. ออนซอน อันเกี่ยวพันกับแพลตฟอร์มและ/หรือ หจก. ออนซอน และสงวนสิทธิที่จะใช้ผู้ให้บริการภายนอก ผู้รับเหมาช่วง และ/หรือตัวแทนรายใดในเงื่อนไขที่ หจก. ออนซอน เห็นว่าเหมาะสม</p>
                            <p>6. ท่านไม่อาจโอนสิทธิของท่านภายใต้เงื่อนไขการใช้งานนี้หากไม่ได้รับความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากหจก. ออนซอน ทั้งนี้ หจก. ออนซอน อาจจะโอนสิทธิของ หจก. ออนซอน ภายใต้เงื่อนไขการใช้งานนี้แก่บุคคลภายนอกคนใดก็ได้</p>
                            <p>7. หจก. ออนซอน ไม่ต้องรับผิดในการไม่ปฏิบัติตามหน้าที่ของ หจก. ออนซอน ภายใต้เงื่อนไขการใช้งานนี้ (หรือส่วนหนึ่งส่วนใดของเงื่อนไขการใช้งานนี้) หรือในความไม่ถูกต้อง ความไม่น่าเชื่อถือ หรือความไม่เหมาะสมใดๆของแพลตฟอร์ม หากไม่ว่าทั้งหมดหรือบางส่วน ไม่ว่าโดยตรงหรือโดยอ้อม เกิดจากเหตุการณ์หรือความผิดพลาดที่อยู่เหนือการควบคุมอย่างเหมาะสมของ หจก. ออนซอน (เหตุสุดวิสัย)</p>
                            <p>8. เงื่อนไขการใช้งานนี้อยู่ภายใต้บังคับของกฎหมายแห่งราชอาณาจักรไทย และข้อพิพาทใดๆที่เกี่ยวข้องจะอยู่ภายใต้อำนาจในการตัดสินคดีของศาลไทย</p>
                            <p>9. คำถามและข้อร้องเรียน หากท่านมีคำถาม ข้อร้องเรียน หรือปัญหาใดๆเกี่ยวกับเงื่อนไขการใช้งานนี้ โปรดติดต่อ หจก. ออนซอน ที่ หรือผ่านทางช่องทางการติดต่อในหน้าติดต่อเรา</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy6?"checked":""} onChange={(e) => handleCheckPolicy6(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy6ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy6} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy6} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Login;