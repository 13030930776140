import React, { useState, useEffect } from 'react'
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, addDoc, updateDoc, doc, onSnapshot, setDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'
import addImage from "../../assets/images/add-image-icon.png"
import { AiOutlineCheckCircle } from "react-icons/ai"
import { Loading } from "../layouts/Loading"

export function AppointmentShopWash({appointment, exchangeCoin}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(false)

    const appointmentId = appointment.id
    const userId = appointment.data.user_id
    const postId = appointment.data.post_id
    const depositCoin = appointment.data.deposit_coin * 1
    const offer = appointment.offer
    const reportBefore = appointment.checkReportBefore
    const reportAfter = appointment.checkReportAfter

    const [message, setMessage] = useState('')
    const [reason, setReason] = useState('')

    const [openConfirmWash, setOpenConfirmWash] = useState(false)
    const [openCancleWash, setOpenCancleWash] = useState(false)
    const [openCancleJobWash, setOpenCancleJobWash] = useState(false)

    const [openCheckBeforeWash, setOpenCheckBeforeWash] = useState(false)
    const [openShowBeforeWash, setOpenShowBeforeWash] = useState(false)
    const [openReportAfterWash, setOpenReportAfterWash] = useState(false)
    const [openShowReportAfterWash, setOpenShowReportAfterWash] = useState(false)

    const jobStep1 = offer.offer_step1?offer.offer_step1:false
    const jobStep2 = offer.offer_step2?offer.offer_step2:false
    const jobStep3 = offer.offer_step3?offer.offer_step3:false
    const jobStep4 = offer.offer_step4?offer.offer_step4:false
    const jobStep5 = offer.offer_step5?offer.offer_step5:false
    const jobStep6 = offer.offer_step6?offer.offer_step6:false
    const jobStep7 = offer.offer_step7?offer.offer_step7:false
    const jobStep8 = offer.offer_step8?offer.offer_step8:false
    const jobStep9 = offer.offer_step9?offer.offer_step9:false
    const jobStep10 = offer.offer_step10?offer.offer_step10:false
    const jobStep11 = offer.offer_step11?offer.offer_step11:false
    const jobStep12 = offer.offer_step12?offer.offer_step12:false
    const jobStep13 = offer.offer_step13?offer.offer_step13:false
    const jobStep14 = offer.offer_step14?offer.offer_step14:false
    const jobStep15 = offer.offer_step15?offer.offer_step15:false
    const jobStep16 = offer.offer_step16?offer.offer_step16:false
    const jobStep17 = offer.offer_step17?offer.offer_step17:false
    const jobStep18 = offer.offer_step18?offer.offer_step18:false
    const jobStep19 = offer.offer_step19?offer.offer_step19:false
    const jobStep20 = offer.offer_step20?offer.offer_step20:false
    const jobStep21 = offer.offer_step21?offer.offer_step21:false

    const showBeforeWashSymptom = reportBefore.beforeWashSymptom?reportBefore.beforeWashSymptom:''
    const showWashBefore1 = reportBefore.check_wash_before1?reportBefore.check_wash_before1:false
    const showWashBefore2 = reportBefore.check_wash_before2?reportBefore.check_wash_before2:false
    const showWashBefore3 = reportBefore.check_wash_before3?reportBefore.check_wash_before3:false
    const showWashBefore4 = reportBefore.check_wash_before4?reportBefore.check_wash_before4:false
    const showWashBefore5 = reportBefore.check_wash_before5?reportBefore.check_wash_before5:false
    const showWashBefore6 = reportBefore.check_wash_before6?reportBefore.check_wash_before6:false
    const showWashBefore7 = reportBefore.check_wash_before7?reportBefore.check_wash_before7:false
    const showWashBefore8 = reportBefore.check_wash_before8?reportBefore.check_wash_before8:false
    const showWashBefore9 = reportBefore.check_wash_before9?reportBefore.check_wash_before9:false
    const showWashBefore10 = reportBefore.check_wash_before10?reportBefore.check_wash_before10:false
    const showWashBefore11 = reportBefore.check_wash_before11?reportBefore.check_wash_before11:false
    const showWashBefore12 = reportBefore.check_wash_before12?reportBefore.check_wash_before12:false
    const showWashBefore13 = reportBefore.check_wash_before13?reportBefore.check_wash_before13:false
    const showWashBefore14 = reportBefore.check_wash_before14?reportBefore.check_wash_before14:false
    const showWashBefore15 = reportBefore.check_wash_before15?reportBefore.check_wash_before15:false
    const showWashBefore16 = reportBefore.check_wash_before16?reportBefore.check_wash_before16:false
    const showWashBefore17 = reportBefore.check_wash_before17?reportBefore.check_wash_before17:false
    const showWashBefore18 = reportBefore.check_wash_before18?reportBefore.check_wash_before18:false
    const showWashBefore19 = reportBefore.check_wash_before19?reportBefore.check_wash_before19:false
    const showWashBefore20 = reportBefore.check_wash_before20?reportBefore.check_wash_before20:false
    const showWashBefore21 = reportBefore.check_wash_before21?reportBefore.check_wash_before21:false
    const showWashBefore22 = reportBefore.check_wash_before22?reportBefore.check_wash_before22:false
    const showWashBefore23 = reportBefore.check_wash_before23?reportBefore.check_wash_before23:false

    const afterWashSymptomShow = reportBefore.afterWashSymptom?reportBefore.afterWashSymptom:''
    const jobReportStep1Show = reportAfter.job_report_step1?reportAfter.job_report_step1:false
    const jobReportStep2Show = reportAfter.job_report_step2?reportAfter.job_report_step2:false
    const jobReportStep3Show = reportAfter.job_report_step3?reportAfter.job_report_step3:false
    const jobReportStep4Show = reportAfter.job_report_step4?reportAfter.job_report_step4:false
    const jobReportStep5Show = reportAfter.job_report_step5?reportAfter.job_report_step5:false
    const jobReportStep6Show = reportAfter.job_report_step6?reportAfter.job_report_step6:false
    const jobReportStep7Show = reportAfter.job_report_step7?reportAfter.job_report_step7:false
    const jobReportStep8Show = reportAfter.job_report_step8?reportAfter.job_report_step8:false
    const jobReportStep9Show = reportAfter.job_report_step9?reportAfter.job_report_step9:false
    const jobReportStep10Show = reportAfter.job_report_step10?reportAfter.job_report_step10:false
    const jobReportStep11Show = reportAfter.job_report_step11?reportAfter.job_report_step11:false
    const jobReportStep12Show = reportAfter.job_report_step12?reportAfter.job_report_step12:false
    const jobReportStep13Show = reportAfter.job_report_step13?reportAfter.job_report_step13:false
    const jobReportStep14Show = reportAfter.job_report_step14?reportAfter.job_report_step14:false
    const jobReportStep15Show = reportAfter.job_report_step15?reportAfter.job_report_step15:false
    const jobReportStep16Show = reportAfter.job_report_step16?reportAfter.job_report_step16:false
    const jobReportStep17Show = reportAfter.job_report_step17?reportAfter.job_report_step17:false
    const jobReportStep18Show = reportAfter.job_report_step18?reportAfter.job_report_step18:false
    const jobReportStep19Show = reportAfter.job_report_step19?reportAfter.job_report_step19:false
    const jobReportStep20Show = reportAfter.job_report_step20?reportAfter.job_report_step20:false
    const jobReportStep21Show = reportAfter.job_report_step21?reportAfter.job_report_step21:false
    const [reportImages, setReportImages] = useState([])

    const [beforeWashSymptom, setBeforeWashSymptom] = useState('')
    const [checkWashBefore1, setCheckWashBefore1] = useState(false)
    const [checkWashBefore2, setCheckWashBefore2] = useState(false)
    const [checkWashBefore3, setCheckWashBefore3] = useState(false)
    const [checkWashBefore4, setCheckWashBefore4] = useState(false)
    const [checkWashBefore5, setCheckWashBefore5] = useState(false)
    const [checkWashBefore6, setCheckWashBefore6] = useState(false)
    const [checkWashBefore7, setCheckWashBefore7] = useState(false)
    const [checkWashBefore8, setCheckWashBefore8] = useState(false)
    const [checkWashBefore9, setCheckWashBefore9] = useState(false)
    const [checkWashBefore10, setCheckWashBefore10] = useState(false)
    const [checkWashBefore11, setCheckWashBefore11] = useState(false)
    const [checkWashBefore12, setCheckWashBefore12] = useState(false)
    const [checkWashBefore13, setCheckWashBefore13] = useState(false)
    const [checkWashBefore14, setCheckWashBefore14] = useState(false)
    const [checkWashBefore15, setCheckWashBefore15] = useState(false)
    const [checkWashBefore16, setCheckWashBefore16] = useState(false)
    const [checkWashBefore17, setCheckWashBefore17] = useState(false)
    const [checkWashBefore18, setCheckWashBefore18] = useState(false)
    const [checkWashBefore19, setCheckWashBefore19] = useState(false)
    const [checkWashBefore20, setCheckWashBefore20] = useState(false)
    const [checkWashBefore21, setCheckWashBefore21] = useState(false)
    const [checkWashBefore22, setCheckWashBefore22] = useState(false)
    const [checkWashBefore23, setCheckWashBefore23] = useState(false)

    const [jobReportStep1, setJobReportStep1] = useState(false)
    const [jobReportStep2, setJobReportStep2] = useState(false)
    const [jobReportStep3, setJobReportStep3] = useState(false)
    const [jobReportStep4, setJobReportStep4] = useState(false)
    const [jobReportStep5, setJobReportStep5] = useState(false)
    const [jobReportStep6, setJobReportStep6] = useState(false)
    const [jobReportStep7, setJobReportStep7] = useState(false)
    const [jobReportStep8, setJobReportStep8] = useState(false)
    const [jobReportStep9, setJobReportStep9] = useState(false)
    const [jobReportStep10, setJobReportStep10] = useState(false)
    const [jobReportStep11, setJobReportStep11] = useState(false)
    const [jobReportStep12, setJobReportStep12] = useState(false)
    const [jobReportStep13, setJobReportStep13] = useState(false)
    const [jobReportStep14, setJobReportStep14] = useState(false)
    const [jobReportStep15, setJobReportStep15] = useState(false)
    const [jobReportStep16, setJobReportStep16] = useState(false)
    const [jobReportStep17, setJobReportStep17] = useState(false)
    const [jobReportStep18, setJobReportStep18] = useState(false)
    const [jobReportStep19, setJobReportStep19] = useState(false)
    const [jobReportStep20, setJobReportStep20] = useState(false)
    const [jobReportStep21, setJobReportStep21] = useState(false)
    const [afterWashSymptom, setAfterWashSymptom] = useState('')
    const [srcReportWashPicture1, setSrcReportWashPicture1] = useState(addImage)
    const [srcReportWashPicture2, setSrcReportWashPicture2] = useState(addImage)
    const [srcReportWashPicture3, setSrcReportWashPicture3] = useState(addImage)
    const [srcReportWashPicture4, setSrcReportWashPicture4] = useState(addImage)
    const [srcReportWashPicture5, setSrcReportWashPicture5] = useState(addImage)
    const [reportWashPicture1, setReportWashPicture1] = useState('')
    const [reportWashPicture2, setReportWashPicture2] = useState('')
    const [reportWashPicture3, setReportWashPicture3] = useState('')
    const [reportWashPicture4, setReportWashPicture4] = useState('')
    const [reportWashPicture5, setReportWashPicture5] = useState('')

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleOpenConfirmWash = () => {
        setOpenConfirmWash(true)
    }

    const handleCloseConfirmWash = () => {
        setOpenConfirmWash(false)
    }

    const handleConfirmWash = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 2,
            confirm_datetime: logDate.getTime()
        }).then(() => {
            addDoc(collection(db, `/users/${userId}/notifications`), {
                date: logDate.getTime(),
                message: "ทางร้าน/บริษัท ยินดีให้บริการล้างเครื่องซักผ้า กรุณากดยืนยันหรือยกเลิก เพ่ือดำเนินการต่อไป",
                link: 'profile/appointments',
                type: 'appointment',
                appointment_id: appointmentId,
                status: 0
            }).then(() => {
                setShowLoading(false)
                navigate(0)
            })
        })
    }

    const handleOpenCancleWash = () => {
        setOpenCancleWash(true)
    }

    const handleCloseCancleWash = () => {
        setOpenCancleWash(false)
    }

    const handleCancleWash = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 4,
            shop_cancle_message: message,
            shop_cancle_reason: reason,
            shop_cancle_datetime: logDate.getTime()
        }).then(() => {
            addDoc(collection(db, `/users/${userId}/notifications`), {
                date: logDate.getTime(),
                message: "ช่าง แจ้งยกเลิกบริการ",
                link: 'profile/appointments',
                type: 'appointment',
                appointment_id: appointmentId,
                status: 0
            }).then(() => {
                updateDoc(doc(db, `cleans`, postId), { 
                    status: 5,
                }).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const handleJobReportStep1Change = () => {
        setJobReportStep1(!jobReportStep1)
    }

    const handleJobReportStep2Change = () => {
       
        setJobReportStep2(!jobReportStep2)
    }
    const handleJobReportStep3Change = () => {
        setJobReportStep3(!jobReportStep3)
    }

    const handleJobReportStep4Change = () => {
        setJobReportStep4(!jobReportStep4)
    }

    const handleJobReportStep5Change = () => {
        setJobReportStep5(!jobReportStep5)
    }

    const handleJobReportStep6Change = () => {
        setJobReportStep6(!jobReportStep6)
    }

    const handleJobReportStep7Change = () => {
        setJobReportStep7(!jobReportStep7)
    }

    const handleJobReportStep8Change = () => {
        setJobReportStep8(!jobReportStep8)
    }

    const handleJobReportStep9Change = () => {
        setJobReportStep9(!jobReportStep9)
    }

    const handleJobReportStep10Change = () => {
        setJobReportStep10(!jobReportStep10)
    }

    const handleJobReportStep11Change = () => {
        setJobReportStep11(!jobReportStep11)
    }

    const handleJobReportStep12Change = () => {
        setJobReportStep12(!jobReportStep12)
    }

    const handleJobReportStep13Change = () => {
        setJobReportStep13(!jobReportStep13)
    }

    const handleJobReportStep14Change = () => {
        setJobReportStep14(!jobReportStep14)
    }

    const handleJobReportStep15Change = () => {
        setJobReportStep15(!jobReportStep15)
    }

    const handleJobReportStep16Change = () => {
        setJobReportStep16(!jobReportStep16)
    }

    const handleJobReportStep17Change = () => {
        setJobReportStep17(!jobReportStep17)
    }

    const handleJobReportStep18Change = () => {
        setJobReportStep18(!jobReportStep18)
    }

    const handleJobReportStep19Change = () => {
        setJobReportStep19(!jobReportStep19)
    }

    const handleJobReportStep20Change = () => {
        setJobReportStep20(!jobReportStep20)
    }

    const handleJobReportStep21Change = () => {
        setJobReportStep21(!jobReportStep21)
    }

    const handleOpenReportAfterWash = () => {
        setOpenReportAfterWash(true)
    }

    const handleCloseReportAfterWash = () => {
        setOpenReportAfterWash(false)
    }

    const handleReportWashImage1 = (file) => {
        setReportWashPicture1(file)
        setSrcReportWashPicture1(URL.createObjectURL(file))
    }

    const handleReportWashImage2 = (file) => {
        setReportWashPicture2(file)
        setSrcReportWashPicture2(URL.createObjectURL(file))
    }

    const handleReportWashImage3 = (file) => {
        setReportWashPicture3(file)
        setSrcReportWashPicture3(URL.createObjectURL(file))
    }

    const handleReportWashImage4 = (file) => {
        setReportWashPicture4(file)
        setSrcReportWashPicture4(URL.createObjectURL(file))
    }

    const handleReportWashImage5 = (file) => {
        setReportWashPicture5(file)
        setSrcReportWashPicture5(URL.createObjectURL(file))
    }

    const handleReportAfterWash = () => {
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 8,
            job_report_datetime: logDate.getTime()
        }).then(() => {
            setDoc(doc(db, `appointments/${appointmentId}/report/`, 'after'), {
                report_date: logDate.getTime(),
                job_report_step1: jobReportStep1,
                job_report_step2: jobReportStep2,
                job_report_step3: jobReportStep3,
                job_report_step4: jobReportStep4,
                job_report_step5: jobReportStep5,
                job_report_step6: jobReportStep6,
                job_report_step7: jobReportStep7,
                job_report_step8: jobReportStep8,
                job_report_step9: jobReportStep9,
                job_report_step10: jobReportStep10,
                job_report_step11: jobReportStep11,
                job_report_step12: jobReportStep12,
                job_report_step13: jobReportStep13,
                job_report_step14: jobReportStep14,
                job_report_step15: jobReportStep15,
                job_report_step16: jobReportStep16,
                job_report_step17: jobReportStep17,
                job_report_step18: jobReportStep18,
                job_report_step19: jobReportStep19,
                job_report_step20: jobReportStep20,
                job_report_step21: jobReportStep21,
                afterWashSymptom
            }).then(() => {
                const promises = []
                if (reportWashPicture1 || reportWashPicture2 || reportWashPicture3 || reportWashPicture4 || reportWashPicture5) {
                    const fileImageUpload = []
                    if(reportWashPicture1) fileImageUpload.push(reportWashPicture1);
                    if(reportWashPicture2) fileImageUpload.push(reportWashPicture2);
                    if(reportWashPicture3) fileImageUpload.push(reportWashPicture3);
                    if(reportWashPicture4) fileImageUpload.push(reportWashPicture4);
                    if(reportWashPicture5) fileImageUpload.push(reportWashPicture5);
                    var i = 1;
                    fileImageUpload.map((file) => {
                        const storageRef = ref(storage, `/appointments/${appointmentId}/report/after/${i}`)
                        const uploadTask = uploadBytesResumable(storageRef, file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    addDoc(collection(db, `appointments/${appointmentId}/report/after/images`), {
                                        url: downloadURLs,
                                    })
                                })
                            }
                        )
                        i++
                    })
                }

                Promise.all(promises).then(() => {
                    setOpenReportAfterWash(false)
                    navigate(0)
                })
            })
        })
        
    }

    const handleOpenShowReportAfterWash = () => {
        setOpenShowReportAfterWash(true)
    }

    const handleCloseShowReportAfterWash = () => {
        setOpenShowReportAfterWash(false)
    }

    const handleOpenCancleJobWash = () => {
        setOpenCancleJobWash(true)
    }

    const handleCloseCancleJobWash = () => {
        setOpenCancleJobWash(false)
    }

    const handleCancleJobWash = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 10,
            shop_cancle_message: message,
            shop_cancle_reason: reason,
            shop_cancle_job_datetime: logDate.getTime()
        }).then(() => {
            var promisesTask = []
            var addNotiTask = addDoc(collection(db, `/users/${userId}/notifications`), {
                date: logDate.getTime(),
                message: "ช่าง แจ้งยกเลิกบริการ",
                link: 'profile/appointments',
                type: 'appointment',
                appointment_id: appointmentId,
                status: 0
            })
            promisesTask.push(addNotiTask)

            var updatePostTask = updateDoc(doc(db, `cleans`, postId), { 
                status: 5,
            })
            promisesTask.push(updatePostTask)

            var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
                coin: depositCoin,
                date: logDate.getTime(),
                event_type: 'deposit',
                event: 'คืนมัดจำ 30% ของค่าบริการล้างเครื่องซักผ้า',
                type: 'decrease',
                post_type: 'wash',
                post_id: postId,
                appointment_id: appointmentId
            })
            promisesTask.push(addCoinLogTask)

            var getUserCoinTask = updateDoc(doc(db, 'users/', userId), {
                    exchange_coin: (exchangeCoin * 1) + depositCoin
            })
            promisesTask.push(getUserCoinTask)

            var addUserCoinLogTask = addDoc(collection(db, `users/${userId}/coin_log/`), {
                coin: depositCoin,
                date: logDate.getTime(),
                event_type: 'deposit',
                event: 'คืนค่ามัดจำ 30% ของค่าบริการล้างเครื่องซักผ้า',
                coin_type: 'exchange',
                post_type: 'wash',
                post_id: postId,
                appointment_id: appointmentId
            })
            promisesTask.push(addUserCoinLogTask)
        
            Promise.all(promisesTask).then(() => {
                setShowLoading(false)
                navigate(0)
            })
        })
    }

    const handleOpenCheckBeforeWash = () => {
        setOpenCheckBeforeWash(true)
    }

    const handleCloseCheckBeforeWash = () => {
        setOpenCheckBeforeWash(false)
    }

    const handleCheckBeforeWash = () => {
        const reportDate = new Date()
        setDoc(doc(db, `appointments/${appointmentId}/report/`, 'before'), {
            report_date: reportDate,
            check_wash_before1: checkWashBefore1,
            check_wash_before2: checkWashBefore2,
            check_wash_before3: checkWashBefore3,
            check_wash_before4: checkWashBefore4,
            check_wash_before5: checkWashBefore5,
            check_wash_before6: checkWashBefore6,
            check_wash_before7: checkWashBefore7,
            check_wash_before8: checkWashBefore8,
            check_wash_before9: checkWashBefore9,
            check_wash_before10: checkWashBefore10,
            check_wash_before11: checkWashBefore11,
            check_wash_before12: checkWashBefore12,
            check_wash_before13: checkWashBefore13,
            check_wash_before14: checkWashBefore14,
            check_wash_before15: checkWashBefore15,
            check_wash_before16: checkWashBefore16,
            check_wash_before17: checkWashBefore17,
            check_wash_before18: checkWashBefore18,
            check_wash_before19: checkWashBefore19,
            check_wash_before20: checkWashBefore20,
            check_wash_before21: checkWashBefore21,
            check_wash_before22: checkWashBefore22,
            check_wash_before23: checkWashBefore23,
            beforeWashSymptom
        }).then(() => {
            setOpenCheckBeforeWash(false)
            navigate(0)
        })
    }

    const handleCheckWashBefore1Change = () => {
        setCheckWashBefore1(!checkWashBefore1)
    }

    const handleCheckWashBefore2Change = () => {
        setCheckWashBefore2(!checkWashBefore2)
    }

    const handleCheckWashBefore3Change = () => {
        setCheckWashBefore3(!checkWashBefore3)
    }

    const handleCheckWashBefore4Change = () => {
        setCheckWashBefore4(!checkWashBefore4)
    }

    const handleCheckWashBefore5Change = () => {
        setCheckWashBefore5(!checkWashBefore5)
    }

    const handleCheckWashBefore6Change = () => {
        setCheckWashBefore6(!checkWashBefore6)
    }

    const handleCheckWashBefore7Change = () => {
        setCheckWashBefore7(!checkWashBefore7)
    }

    const handleCheckWashBefore8Change = () => {
        setCheckWashBefore8(!checkWashBefore8)
    }

    const handleCheckWashBefore9Change = () => {
        setCheckWashBefore9(!checkWashBefore9)
    }

    const handleCheckWashBefore10Change = () => {
        setCheckWashBefore10(!checkWashBefore10)
    }

    const handleCheckWashBefore11Change = () => {
        setCheckWashBefore11(!checkWashBefore11)
    }

    const handleCheckWashBefore12Change = () => {
        setCheckWashBefore12(!checkWashBefore12)
    }

    const handleCheckWashBefore13Change = () => {
        setCheckWashBefore13(!checkWashBefore13)
    }

    const handleCheckWashBefore14Change = () => {
        setCheckWashBefore14(!checkWashBefore14)
    }

    const handleCheckWashBefore15Change = () => {
        setCheckWashBefore15(!checkWashBefore15)
    }

    const handleCheckWashBefore16Change = () => {
        setCheckWashBefore16(!checkWashBefore16)
    }
    
    const handleCheckWashBefore17Change = () => {
        setCheckWashBefore17(!checkWashBefore17)
    }

    const handleCheckWashBefore18Change = () => {
        setCheckWashBefore18(!checkWashBefore18)
    }

    const handleCheckWashBefore19Change = () => {
        setCheckWashBefore19(!checkWashBefore19)
    }

    const handleCheckWashBefore20Change = () => {
        setCheckWashBefore20(!checkWashBefore20)
    }

    const handleCheckWashBefore21Change = () => {
        setCheckWashBefore21(!checkWashBefore21)
    }

    const handleCheckWashBefore22Change = () => {
        setCheckWashBefore22(!checkWashBefore22)
    }

    const handleCheckWashBefore23Change = () => {
        setCheckWashBefore23(!checkWashBefore23)
    }

    const handleOpenShowBeforeWash = () => {
        setOpenShowBeforeWash(true)
    }

    const handleCloseShowBeforeWash = () => {
        setOpenShowBeforeWash(false)
    }

    useEffect(() => {
        if(reportAfter) {
            const imagesQuery = query(collection(db, `appointments/${appointmentId}/report/after/images`))
            onSnapshot(imagesQuery, (querySnapshot) => {
                setReportImages(querySnapshot.docs.map((doc, i) => { 
                    return {
                        id: doc.id,
                        data: doc.data(),
                    }
                }))
            })
        }
    })

    return (
        <>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
                {appointment.badgeStyle.show && (<>
                    <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                        <button type="button" className={"btn "+"btn-"+appointment.badgeStyle.class} style={{ color: appointment.badgeStyle.color }}><strong>{appointment.badgeStyle.message}</strong></button>
                    </div>
                </>)}
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                    <img src={appointment.post.picture} alt="APP3R" height="150" />
                </div>
                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                    <p className="card-text">
                        <strong>ประเภทนัดหมาย:</strong> ล้างเครื่องซักผ้า<br/>
                        <strong>วันนัดหมาย:</strong> {dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/>
                        <strong>ที่อยู่:</strong> {appointment.post.address} <br/>
                        {appointment.post.brand} {appointment.data.model}<br/>
                    </p>
                </div>
                <div className="card-body">
                    <Link className="btn btn-primary m-1" to={`/postclean/${appointment.data.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                    <Link className="btn btn-primary m-1" to={`/shop/wash/offer/${appointment.data.post_id}`} target='_blank'><strong>ดูข้อเสนอ</strong></Link>
                    {!reportBefore && (<>
                        <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenCheckBeforeWash() }}>บันทึกสำรวจหน้างาน</button>
                    </>)}
                    {reportBefore && (<>
                        <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenShowBeforeWash() }}>ดูรายงานสำรวจหน้างาน</button>
                        {(appointment.data.status==1) && (<>
                            <button type="button" className="btn btn-success m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenConfirmWash() }}>ยืนยันให้บริการ</button>
                            <button type="button" className="btn btn-danger m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenCancleWash() }}>ยกเลิกการให้บริการ</button>
                        </>)}
                    </>)}
                    {!reportAfter && (<>
                        {(appointment.data.status==3) && (<>
                            <button type="button" className="btn btn-danger m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenCancleJobWash() }}>ยกเลิกงาน</button>
                            <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenReportAfterWash() }}>บันทึกส่งมอบงาน</button>
                        </>)}
                    </>)}
                    {reportAfter && (<>
                        <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenShowReportAfterWash() }}>ดูรายงานบันทึกส่งมอบงาน</button>
                    </>)}
                </div>
            </div>

            <Dialog open={openShowBeforeWash} onClose={handleCloseShowBeforeWash} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานสำรวจหน้างาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อาการผิดหลังสำรวจ</strong></h5></label>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore1)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ระบบเปิด-ปิด ทำงานไม่ปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore2)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ทดสอบการซัก ทำงานไม่ปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore3)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ฟังเสียงเครื่องทำงาน ทำงานไม่ปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore4)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ตรวจสภาพภายนอกเครื่องมีสนิมผุกร่อน</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore5)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    แผงควบคุมอิเล็กทรอนิกส์ทำงานผิดปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore6)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    แกนซักไม่อยู่ในสภาพปกติหรือชำรุด</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore7)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ถ่ายน้ำไม่ออก</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore8)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ไม่ปั่น ปั่นไม่แห้งไม่หมาด</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore9)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    เครื่องปั่นมีเสียงดัง</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore10)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    เครื่องปั่นไม่หยุด</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore11)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    แกนหัก</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore12)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ไม่ระบายน้ำทิ้ง</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore13)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    เครื่องไม่ทำงาน</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore14)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    น้้ำรั่ว จากเครื่อง</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore15)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    เครื่องไม่ผสมผงซักฟอก</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore16)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    วัดค่าแรงดัน และกระแสไฟฟ้าผิดปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore17)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ไม่มีขั้วเสียบสายไฟ สายกราวด์</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore18)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ชุดทำความร้อน (เฉพาะเครื่องซักผ้าบางรุ่น) ทำงานผิดปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore19)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ถึงที่พักแต่ไม่พบผู้ประกาศหรือไม่สามารถ เข้าถึงเครื่องใช้ไฟฟ้าได้</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore20)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    สภาพพื้นที่ไม่อำนวยต่อการซ่อม</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore21)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ไม่ได้รับความร่วมมือจากผู้เรียกใช้บริการ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore22)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    มีความเสี่ยงในการเกิดอุบัติเหตุ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showWashBefore23)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    เคร่ืองซักผ้ามีความผิดปกติ ควรดำเนิการซ่อม</font>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อื่นๆ</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>{showBeforeWashSymptom}</div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShowBeforeWash} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCheckBeforeWash} onClose={handleCloseCheckBeforeWash} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานสำรวจหน้างาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อาการผิดหลังสำรวจ</strong></h5></label>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore1?"checked":""} onChange={(e) => handleCheckWashBefore1Change(e.target.value)} /> ระบบเปิด-ปิด ทำงานไม่ปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore2?"checked":""} onChange={(e) => handleCheckWashBefore2Change(e.target.value)} /> ทดสอบการซัก ทำงานไม่ปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore3?"checked":""} onChange={(e) => handleCheckWashBefore3Change(e.target.value)} /> ฟังเสียงเครื่องทำงาน ทำงานไม่ปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore4?"checked":""} onChange={(e) => handleCheckWashBefore4Change(e.target.value)} /> ตรวจสภาพภายนอกเครื่องมีสนิมผุกร่อน</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore5?"checked":""} onChange={(e) => handleCheckWashBefore5Change(e.target.value)} /> แผงควบคุมอิเล็กทรอนิกส์ทำงานผิดปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore6?"checked":""} onChange={(e) => handleCheckWashBefore6Change(e.target.value)} /> แกนซักไม่อยู่ในสภาพปกติหรือชำรุด</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore7?"checked":""} onChange={(e) => handleCheckWashBefore7Change(e.target.value)} /> ถ่ายน้ำไม่ออก</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore8?"checked":""} onChange={(e) => handleCheckWashBefore8Change(e.target.value)} /> ไม่ปั่น ปั่นไม่แห้งไม่หมาด</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore9?"checked":""} onChange={(e) => handleCheckWashBefore9Change(e.target.value)} /> เครื่องปั่นมีเสียงดัง</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore10?"checked":""} onChange={(e) => handleCheckWashBefore10Change(e.target.value)} /> เครื่องปั่นไม่หยุด</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore11?"checked":""} onChange={(e) => handleCheckWashBefore11Change(e.target.value)} /> แกนหัก</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore12?"checked":""} onChange={(e) => handleCheckWashBefore12Change(e.target.value)} /> ไม่ระบายน้ำทิ้ง</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore13?"checked":""} onChange={(e) => handleCheckWashBefore13Change(e.target.value)} /> เครื่องไม่ทำงาน</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore14?"checked":""} onChange={(e) => handleCheckWashBefore14Change(e.target.value)} /> น้้ำรั่ว จากเครื่อง</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore15?"checked":""} onChange={(e) => handleCheckWashBefore15Change(e.target.value)} /> เครื่องไม่ผสมผงซักฟอก</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore16?"checked":""} onChange={(e) => handleCheckWashBefore16Change(e.target.value)} /> วัดค่าแรงดัน และกระแสไฟฟ้าผิดปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore17?"checked":""} onChange={(e) => handleCheckWashBefore17Change(e.target.value)} /> ไม่มีขั้วเสียบสายไฟ สายกราวด์</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore18?"checked":""} onChange={(e) => handleCheckWashBefore18Change(e.target.value)} /> ชุดทำความร้อน (เฉพาะเครื่องซักผ้าบางรุ่น) ทำงานผิดปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore19?"checked":""} onChange={(e) => handleCheckWashBefore19Change(e.target.value)} /> ถึงที่พักแต่ไม่พบผู้ประกาศหรือไม่สามารถ เข้าถึงเครื่องใช้ไฟฟ้าได้</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore20?"checked":""} onChange={(e) => handleCheckWashBefore20Change(e.target.value)} /> สภาพพื้นที่ไม่อำนวยต่อการซ่อม</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore21?"checked":""} onChange={(e) => handleCheckWashBefore21Change(e.target.value)} /> ไม่ได้รับความร่วมมือจากผู้เรียกใช้บริการ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore22?"checked":""} onChange={(e) => handleCheckWashBefore22Change(e.target.value)} /> มีความเสี่ยงในการเกิดอุบัติเหตุ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkWashBefore23?"checked":""} onChange={(e) => handleCheckWashBefore23Change(e.target.value)} /> เคร่ืองซักผ้ามีความผิดปกติ ควรดำเนิการซ่อม</font>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อื่นๆ</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>
                                                    <textarea style={{ display: 'flex', flex: 1 }}
                                                        onChange={(e) => setBeforeWashSymptom(e.target.value)}
                                                        placeholder='อื่นๆ' 
                                                        value={beforeWashSymptom}></textarea>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCheckBeforeWash} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCheckBeforeWash} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึกสำรวจหน้างาน</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirmWash} onClose={handleCloseConfirmWash} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันให้บริการ</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันให้บริการงานนี้</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseConfirmWash} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleConfirmWash} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันให้บริการ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancleWash} onClose={handleCloseCancleWash} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการให้บริการล้างเครื่องซักผ้า</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ไม่รับบริการ</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5, paddingLeft: 10, paddingRight: 10 }}>
                                                <option value="1">ไม่ได้รับความร่วมมือ</option>
                                                <option value="2">สภาพแวดล้อมไม่เหมาะกับการดาเนินงาน</option>
                                                <option value="3">เกิดอุบัติเหตุระหว่างดำเนินงาน</option>
                                                <option value="4">มีการเรียกรับเงิน</option>
                                                <option value="5">ควรดาเนินการซ่อม</option>
                                                <option value="6">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleWash} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleWash} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการให้บริการ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openShowReportAfterWash} onClose={handleCloseShowReportAfterWash} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานส่งมอบงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>ขั้นตอนการดำเนินงาน</strong></h5></label>
                                                {jobReportStep1Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า</font>}
                                                {jobReportStep2Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ปิดเบรกเกอร์เครื่องใช้ไฟฟ้า</font>}
                                                <font style={{ fontSize: 17 }}><strong>ฉีดล้างด้วยเครื่องฉีดน้ำแรงดันสูงทั้งภายในและภายนอกถัง</strong></font>
                                                {jobReportStep3Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ถังซัก</font>}
                                                {jobReportStep4Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ถังอุ้มน้ำ</font>}
                                                {jobReportStep5Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตะแกรงกรองฝุ่น</font>}
                                                {jobReportStep6Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ที่ใส่ผงซักฟอก</font>}
                                                {jobReportStep7Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> จานซัก</font>}
                                                {jobReportStep8Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> แผ่นฟิลเตอร์</font>}
                                                {jobReportStep9Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตะแกรงกรองผง</font>}
                                                <font style={{ fontSize: 17 }}><strong>ถอดชิ้นส่วนและอะไหล่</strong></font>
                                                {jobReportStep10Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> เดรนวาล์วหรือเดรนปั้มออกมาทำความสะอาด</font>}
                                                {jobReportStep11Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ทำความสะอาดมอเตอร์และสายพาน</font>}
                                                {jobReportStep12Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> เป่าไล่ความชื้นชิ้นส่วนและอะไหล่ต่าง ๆ</font>}
                                                {jobReportStep13Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ประกอบชิ้นส่วนคืนพร้อมอัดจารบีเข้ากับชิ้นส่วนที่จำเป็น ตั้งสายพานใหม่ตามรุ่นเครื่องซักผ้า</font>}
                                                <font style={{ fontSize: 17 }}><strong>ตรวจสอบการทำงาน ก่อนส่งงาน</strong></font>
                                                {jobReportStep14Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบการทำงานเครื่องซักผ้า</font>}
                                                {jobReportStep15Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบแผงควบคุมการทำงานเครื่อง</font>}
                                                {jobReportStep16Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบวัดค่าแรงดัน และค่ากระแสไฟฟ้า</font>}
                                                {jobReportStep17Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบขั้วเสียบสายไฟ สายกราวด์</font>}
                                                {jobReportStep18Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบจุดต่อ ข้อต่อต่าง ๆ</font>}
                                                {jobReportStep19Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบชุดทำความร้อน (เฉพาะเครื่องซักผ้าบางรุ่น)</font>}
                                                {jobReportStep20Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบขอบยางประตู/ฝาเปิด</font>}
                                                {jobReportStep21Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจเช็กการทำงานของเครื่องซักผ้าตามโปรแกรมการทำงานต่าง ๆ</font>}
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>รายงานเพิ่มเติม</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>
                                                    <textarea style={{ display: 'flex', flex: 1 }}
                                                        placeholder='รายงานเพิ่มเติม' readOnly={true}
                                                        value={afterWashSymptomShow}></textarea>
                                                </div>
                                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                    <label className="col-form-label" style={{ display: 'flex'}}><font style={{ fontSize: 17 }}><strong>รูปภาพงานเพิ่มเติม <font className="text-danger">*</font></strong></font></label>
                                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                                                        <div className="card-body mx-auto">
                                                            <div className="d-flex flex-wrap">
                                                                {reportImages?.map((image, i) => (
                                                                <div key={image.id} className="m-2">
                                                                    <img src={image.data.url} alt="APP3R" width="120" />
                                                                </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShowReportAfterWash} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openReportAfterWash} onClose={handleCloseReportAfterWash} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานส่งมอบงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>ขั้นตอนการดำเนินงาน</strong></h5></label>
                                                {jobStep1 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep1?"checked":""} onChange={(e) => handleJobReportStep1Change(e.target.value)} /> ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า</font>}
                                                {jobStep2 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep2?"checked":""} onChange={(e) => handleJobReportStep2Change(e.target.value)} /> ปิดเบรกเกอร์เครื่องใช้ไฟฟ้า</font>}
                                                <font style={{ fontSize: 17 }}><strong>ฉีดล้างด้วยเครื่องฉีดน้ำแรงดันสูงทั้งภายในและภายนอกถัง</strong></font>
                                                {jobStep3 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep3?"checked":""} onChange={(e) => handleJobReportStep3Change(e.target.value)} /> ถังซัก</font>}
                                                {jobStep4 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep4?"checked":""} onChange={(e) => handleJobReportStep4Change(e.target.value)} /> ถังอุ้มน้ำ</font>}
                                                {jobStep5 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep5?"checked":""} onChange={(e) => handleJobReportStep5Change(e.target.value)} /> ตะแกรงกรองฝุ่น</font>}
                                                {jobStep6 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep6?"checked":""} onChange={(e) => handleJobReportStep6Change(e.target.value)} /> ที่ใส่ผงซักฟอก</font>}
                                                {jobStep7 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep7?"checked":""} onChange={(e) => handleJobReportStep7Change(e.target.value)} /> จานซัก</font>}
                                                {jobStep8 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep8?"checked":""} onChange={(e) => handleJobReportStep8Change(e.target.value)} /> แผ่นฟิลเตอร์</font>}
                                                {jobStep9 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep9?"checked":""} onChange={(e) => handleJobReportStep9Change(e.target.value)} /> ตะแกรงกรองผง</font>}
                                                <font style={{ fontSize: 17 }}><strong>ถอดชิ้นส่วนและอะไหล่</strong></font>
                                                {jobStep10 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep10?"checked":""} onChange={(e) => handleJobReportStep10Change(e.target.value)} /> เดรนวาล์วหรือเดรนปั้มออกมาทำความสะอาด</font>}
                                                {jobStep11 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep11?"checked":""} onChange={(e) => handleJobReportStep11Change(e.target.value)} /> ทำความสะอาดมอเตอร์และสายพาน</font>}
                                                {jobStep12 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep12?"checked":""} onChange={(e) => handleJobReportStep12Change(e.target.value)} /> เป่าไล่ความชื้นชิ้นส่วนและอะไหล่ต่าง ๆ</font>}
                                                {jobStep13 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep13?"checked":""} onChange={(e) => handleJobReportStep13Change(e.target.value)} /> ประกอบชิ้นส่วนคืนพร้อมอัดจารบีเข้ากับชิ้นส่วนที่จำเป็น ตั้งสายพานใหม่ตามรุ่นเครื่องซักผ้า</font>}
                                                <font style={{ fontSize: 17 }}><strong>ตรวจสอบการทำงาน ก่อนส่งงาน</strong></font>
                                                {jobStep14 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep14?"checked":""} onChange={(e) => handleJobReportStep14Change(e.target.value)} /> ตรวจสอบการทำงานเครื่องซักผ้า</font>}
                                                {jobStep15 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep15?"checked":""} onChange={(e) => handleJobReportStep15Change(e.target.value)} /> ตรวจสอบแผงควบคุมการทำงานเครื่อง</font>}
                                                {jobStep16 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep16?"checked":""} onChange={(e) => handleJobReportStep16Change(e.target.value)} /> ตรวจสอบวัดค่าแรงดัน และค่ากระแสไฟฟ้า</font>}
                                                {jobStep17 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep17?"checked":""} onChange={(e) => handleJobReportStep17Change(e.target.value)} /> ตรวจสอบขั้วเสียบสายไฟ สายกราวด์</font>}
                                                {jobStep18 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep18?"checked":""} onChange={(e) => handleJobReportStep18Change(e.target.value)} /> ตรวจสอบจุดต่อ ข้อต่อต่าง ๆ</font>}
                                                {jobStep19 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep19?"checked":""} onChange={(e) => handleJobReportStep19Change(e.target.value)} /> ตรวจสอบชุดทำความร้อน (เฉพาะเครื่องซักผ้าบางรุ่น)</font>}
                                                {jobStep20 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep20?"checked":""} onChange={(e) => handleJobReportStep20Change(e.target.value)} /> ตรวจสอบขอบยางประตู/ฝาเปิด</font>}
                                                {jobStep21 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep21?"checked":""} onChange={(e) => handleJobReportStep21Change(e.target.value)} /> ตรวจเช็กการทำงานของเครื่องซักผ้าตามโปรแกรมการทำงานต่าง ๆ</font>}
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>รายงานเพิ่มเติม</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>
                                                    <textarea style={{ display: 'flex', flex: 1 }}
                                                        onChange={(e) => setAfterWashSymptom(e.target.value)}
                                                        placeholder='รายงานเพิ่มเติม' 
                                                        value={afterWashSymptom}></textarea>
                                                </div>
                                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                    <label className="col-form-label" style={{ display: 'flex'}}><font style={{ fontSize: 17 }}><strong>รูปภาพงานเพิ่มเติม <font className="text-danger">*</font></strong></font></label>
                                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportWashPicture1} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                                            <label htmlFor='fileReportWashUpload1' style={{ padding: 0}}>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportWashUpload1" type="file" accept="image/*" onChange={(e) => handleReportWashImage1(e.target.files[0])} />
                                                        </div>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportWashPicture2} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                                            <label htmlFor='fileReportWashUpload2'>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportWashUpload2" type="file" accept="image/*" onChange={(e) => handleReportWashImage2(e.target.files[0])} />
                                                        </div>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportWashPicture3} alt="APP3R" width="80"style={{marginBottom: 5}}  />
                                                            <label htmlFor='fileReportWashUpload3'>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportWashUpload3" type="file" accept="image/*" onChange={(e) => handleReportWashImage3(e.target.files[0])} />
                                                        </div>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportWashPicture4} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                                            <label htmlFor='fileReportWashUpload4'>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportWashUpload4" type="file" accept="image/*" onChange={(e) => handleReportWashImage4(e.target.files[0])} />
                                                        </div>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportWashPicture5} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                                            <label htmlFor='fileReportWashUpload5'>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportWashUpload5" type="file" accept="image/*" onChange={(e) => handleReportWashImage5(e.target.files[0])} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseReportAfterWash} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleReportAfterWash} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึกสำรวจหน้างาน</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancleJobWash} onClose={handleCloseCancleJobWash} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการให้บริการล้างเครื่องซักผ้า</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ยกเลิกบริการ</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5, paddingLeft: 10, paddingRight: 10 }}>
                                                <option value="1">ไม่ได้รับความร่วมมือ</option>
                                                <option value="2">สภาพแวดล้อมไม่เหมาะกับการดาเนินงาน</option>
                                                <option value="3">เกิดอุบัติเหตุระหว่างดำเนินงาน</option>
                                                <option value="4">มีการเรียกรับเงิน</option>
                                                <option value="5">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleJobWash} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleJobWash} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการให้บริการ</button>
                </DialogActions>
            </Dialog>
        </>
    )
}