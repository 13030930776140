export function getBoartStatus(board, status, type) {
    if(type == 'admin') {
        if(board == 1) {
            switch(status) {
                case 1: return 'ปกติ';
                case 2: return 'มีผู้เสนอรับซาก';
                case 3: return 'รอยืนยันนัดรับซาก';
                case 4: return 'ยืนยันนัดรับซาก';
                case 5: return 'ระงับการประกาศ';
                case 6: return 'ซ่อนประกาศ';
                case 9: return 'ทิ้งซากสำเร็จ';
                default: return 'ปกติ';
            }
        } else if(board == 2) {
            switch(status) {
                case 1: return 'ปกติ';
                case 2: return 'มีผู้เสนอรับซาก';
                case 3: return 'รอยืนยันนัดซื้อขายซาก';
                case 4: return 'ยืนยันนัดซื้อขายซาก';
                case 5: return 'ระงับการประกาศ';
                case 6: return 'ซ่อนประกาศ';
                case 9: return 'ซื้อขายซากสำเร็จ';
                default: return 'ปกติ';
            }
        } else if(board == 3) {
            switch(status) {
                case 1: return 'ปกติ';
                case 2: return 'มีผู้เสนอซื้อ';
                case 3: return 'รอยืนยันนัดซื้อขาย';
                case 4: return 'ยืนยันนัดซื้อขาย';
                case 5: return 'ระงับการประกาศ';
                case 6: return 'ซ่อนประกาศ';
                case 9: return 'ซื้อขายสำเร็จ';
                default: return 'ปกติ';
            }
        }
    }
}