import React, { useEffect, useState } from "react";
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { auth, db } from "../../assets/js/firebase"
import { getDocs, query, collection, where } from 'firebase/firestore'
import { signOut } from "firebase/auth"
import "../../assets/css/admin.css";
import logo from "../../assets/images/weee-logo.png"

const Header = () => {
    const [admin, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [adminData, setAdminData] = useState([])
    const [adminEmail, setAdminEmail] = useState('')

    const logout = () => {
        signOut(auth)
        navigate("/admin")
    };

    const checkAdmin = () => {
        getDocs(query(collection(db, "admins"), where("uid", "==", admin.uid))).then((docs) => {
            if(docs.docs.length > 0) {
                setAdminData(docs.docs[0].data())
                setAdminEmail(docs.docs[0].data().email)
            } else { 
                navigate('/') 
            }
        })
    }

    useEffect(() => {
        if (loading) return;
        checkAdmin()
        if (admin) { checkAdmin() } else { navigate('/admin') }
    }, [loading]);

    return (
        <nav className="navbar navbar-expand-md navbar-dark fixed-top shadow-sm" style={{ backgroundColor: "#ffffff" }}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/admin"> 
                    <img src={logo} className="" style={{ height: 30 }} alt="WEEE" />
                </a>
                <div>{adminEmail}</div>
                <div style={{ width:100, cursor:"pointer" }} className="text-black"><span onClick={logout}>Log Out</span></div>  
            </div> 
        </nav>
    )
}

export default Header;