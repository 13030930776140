import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, doc, getDoc, onSnapshot } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import { AiFillShop } from "react-icons/ai"
import { TbUser, TbEdit, TbCircleCheck, TbPhoto, TbHome, TbAlertCircle, TbClock, TbMessageCircle2 } from "react-icons/tb"
import { RiBankCardLine } from 'react-icons/ri'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { IoFolderOpenOutline } from 'react-icons/io5'

import LeftMenu from './LeftMenu'
import { getProvice, getDistrict, getSubDistrict } from "../../../assets/js/AreaHelper"
import { defaultLatLng, googleMapsApiKey } from "../../../assets/constants/locations"
import { Loading } from "../layouts/Loading"

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const ProfileShop = () => {
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [isShop, setIsShop] = useState(0)
    const [shopId, setShopId] = useState('')
    const [shop, setShop] = useState([])
    const [shopStatus, setShopStatus] = useState(1)
    const [images, setImages] = useState([])
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState()

    const [province, setProvince] = useState('')
    const [district, setDistrict] = useState('')
    const [subDistrict, setSubDistrict] = useState('')

    const [showLoading, setShowLoading] = useState(true)
    const [openSendMessage, setOpenSendMessage] = useState(false)

    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey })

    const getDocShop = async() => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMember(data)
                setMemberId(data.id)
                setShopId(data.shop_id)
                if(data.is_shop) setIsShop(data.is_shop)
                getDoc(doc(db, 'shops', data.shop_id)).then(async(shopSnapshot) => {
                    setShop(shopSnapshot.data())
                    if(shopSnapshot.data().lat) setLat(shopSnapshot.data().lat)
                    if(shopSnapshot.data().lng) setLng(shopSnapshot.data().lng)
                    setShopStatus(shopSnapshot.data().status)
                    setShowLoading(false)
                    if(shopSnapshot.data().province) {
                        var name = await getProvice(db, shopSnapshot.data().province)
                        setProvince(name)
                    }
                    if(shopSnapshot.data().district) {
                        var name = await getDistrict(db, shopSnapshot.data().district)
                        setDistrict(name)
                    }
                    if(shopSnapshot.data().sub_district) {
                        var name = await getSubDistrict(db, shopSnapshot.data().sub_district)
                        setSubDistrict(name)
                    }
                }).then(() => {
                    const imagesSnapshot = query(collection(db, `shops/${data.shop_id}/images`))
                    onSnapshot(imagesSnapshot, (querySnapshot) => {
                        setImages(querySnapshot.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })))
                    })
                })
            }
        }).catch((err) => {
            alert(err.message)
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else { getDocShop() }
    }, [user, loading])

return (
    <>
        <Loading open={showLoading} text={"กำลังทำการเรียกข้อมูล กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="profile" isShop={isShop} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }} className="d-none d-md-block d-lg-block"><h4><b>ข้อมูลร้านค้า</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="profile" isShop={isShop} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingTop: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column',  marginBottom: 30 }}>
                            <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                                <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                                    <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                                </div>
                            </div>
                            <div className="flex-container" style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flex: 1 }}>
                                <h4 style={{ display: 'flex', flex: 1, flexDirection: 'row' }}><AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /><b>ข้อมูลร้านค้า</b></h4>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                    <div style={{ float: 'right' }}>
                                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/shop/edit/${shopId}`) }}>
                                            <TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขข้อมูล
                                        </button>
                                    </div>
                                    {/* <div style={{ float: 'right' }}>
                                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenSendMessage(true) }}>
                                            <TbMessageCircle2 style={{ fontSize: 20, marginRight: 5 }} />ส่งข้อความ
                                        </button>
                                    </div> */}
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>สถานะ</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shopStatus==1&&(<><font><TbClock style={{ fontSize: 20, marginRight: 10, marginTop: -5 }} />รอการอนุมัติ</font></>)}
                                            {shopStatus==2&&(<><font className='text-success'><TbCircleCheck style={{ fontSize: 20, marginRight: 10, marginTop: -5 }} />อนุมัติ</font></>)}
                                            {shopStatus==3&&(<><font className='text-danger'><TbAlertCircle style={{ fontSize: 20, marginRight: 10, marginTop: -5 }} />ระงับการใช้งาน</font></>)}
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>ผู้ใช้ </strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                {(member) && (
                                                <>
                                                    <font style={{ marginRight: 10}}>{member.firstname?member.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                                    <font style={{ marginRight: 10}}>{member.lastname?member.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                                    <font style={{ marginRight: 10}}>({member.display_name?member.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font>
                                                </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>email </strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                {(member) && (
                                                <><font style={{ marginRight: 10}}>{member.email?member.email:""}</font></>
                                                )}
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>ประเภทร้านค้า</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {(shop.type==1)&&(<div><TbUser style={{ fontSize: 20, marginRight: 5 }} />บุคคลธรรมดา</div>)}
                                            {(shop.type==2)&&(<div><AiFillShop style={{ fontSize: 20, marginRight: 5 }} />นิติบุคคล</div>)}
                                        </div>
                                    </div> 
                                    {(shop.type == 2) && (
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>ชื่อร้านค้า</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.shopname?shop.shopname:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    )}
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>ชื่อ (เจ้าของ)</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.firstname?shop.firstname:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>นามสกุล (เจ้าของ)</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.lastname?shop.lastname:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>เบอร์โทรศัพท์</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.tel_no?shop.tel_no:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div>
                                    <div style={{ paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                                        <h6><RiBankCardLine style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>บัญชีธนาคาร</b></h6>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 15}}>
                                        <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>ธนาคาร</strong></label>
                                            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.bank_name?shop.bank_name:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>หมายเลขบัญชี</strong></label>
                                            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.bank_account_no?shop.bank_account_no:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="flex-container" style={{ display: 'flex' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>สำเนาหน้าสมุดบัญชี</strong></label>
                                            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                                {(shop.book_bank) &&
                                                    <a href={shop.book_bank} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                                }
                                                {(!shop.book_bank) &&(<font className="text-danger" style={{ fontWeight: '600', marginTop: -10 }}>ไม่พบเอกสาร</font>)}
                                            </div>
                                        </div> 
                                    </div>
                                    <div style={{ paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                                        <h6><TbPhoto style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>รูปภาพหน้าร้านค้า</b></h6>
                                    </div>
                                    <div style={{ display:'flex', flexDirection: 'column', borderBottom: '1px solid #cccccc' }}>
                                        <div className="" style={{ display: 'flex', flexDirection: 'column' }}>
                                            <div style={{ display:'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor:'#ffffff', marginBottom: 10, paddingTop:10, paddingBottom: 10, textAlign: 'left' }}>
                                            {
                                                images?.map((image, i) => (
                                                    <div key={image.id} style={{ margin: 10, minWidth: 100, maxWidth: 200}}>
                                                        <a href={image.data.url} target='_blank'><img src={image.data.url} alt="WEEE" className='img-fluid' /></a>
                                                    </div>
                                                ))
                                            }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-none d-md-block d-lg-block" style={{ minWidth: 10, marginLeft: 10, borderLeft: '1px solid #cccccc',  }}>&nbsp;</div>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                                    <div style={{ marginBottom: 10, marginTop: 5, paddingTop: 8, borderBottom: '1px solid #cccccc' }}>
                                        <h6><TbHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>ข้อมูลที่อยู่</b></h6>
                                    </div>
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>บ้านเลขที่, หมู่บ้าน, อาคาร</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.address?shop.address:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flexDirection: 'row', flex: 3 }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>ถนน</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.road?shop.road:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>ตำบล</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {subDistrict}
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>อำเภอ</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {district}
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3}}><strong>จังหวัด</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {province}
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>รหัสไปรษณีย์</strong></label>
                                        <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.postcode?shop.postcode:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                                        <h6><FaMapMarkerAlt style={{ fontSize: 20, marginRight: 5 }} />
                                        <b>ตำแหน่งพิกัดร้านค้า</b></h6>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="mb-3 row" style={{ display: 'flex', height:250, flexDirection: 'column' }}>
                                            <div style={{  display: 'flex', flexWrap: 'nowrap', height:250, flex: 1 }}>
                                                {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                                <GoogleMap mapContainerClassName="map-container" center={{ lat: lat, lng: lng }} zoom={17} >
                                                    <MarkerF position={{ lat: lat, lng: lng }}/>
                                                </GoogleMap>
                                                )}
                                            </div>
                                        </div>
                                    </div> 
                                    <div style={{ marginBottom: 10, paddingTop: 13, marginTop: 5, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                                        <h6><IoFolderOpenOutline style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <strong>เอกสารประกอบการสมัคร</strong></h6>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div className="flex-container" style={{ display: 'flex' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 3}}><strong>สำเนาบัตรประชาชน</strong></label>
                                            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {(shop.id_card) &&
                                                <a href={shop.id_card} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                            }
                                            {(!shop.id_card) &&(<font className="text-danger" style={{ fontWeight: '600', marginTop: -10 }}>ไม่พบเอกสาร</font>)}
                                            </div>
                                        </div> 
                                        <div className="flex-container" style={{ display: 'flex' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>ใบประกอบวิชาชีพ</strong></label>
                                            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {(shop.support_file_1) &&
                                                <a href={shop.support_file_1} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                            }
                                            {(!shop.support_file_1) &&(<font className="text-danger" style={{ fontWeight: '600', marginTop: -10 }}>ไม่พบเอกสาร</font>)}
                                            </div>
                                        </div> 
                                        <div className="flex-container" style={{ display: 'flex' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>วุฒิการศึกษา</strong></label>
                                            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {(shop.support_file_2) &&
                                                <a href={shop.support_file_2} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                            }
                                            {(!shop.support_file_2) &&(<font className="text-danger" style={{ fontWeight: '600', marginTop: -10 }}>ไม่พบเอกสาร</font>)}
                                            </div>
                                        </div> 
                                        <div className="flex-container" style={{ display: 'flex' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>ประกาศนียบัตร</strong></label>
                                            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {(shop.support_file_3) &&
                                                <a href={shop.support_file_3} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                            }
                                            {(!shop.support_file_3) &&(<font className="text-danger" style={{ fontWeight: '600', marginTop: -10 }}>ไม่พบเอกสาร</font>)}
                                            </div>
                                        </div> 
                                        <div className="flex-container" style={{ display: 'flex' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 3}}><strong>หนังสือรับรอง</strong></label>
                                            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {(shop.support_file_4) &&
                                                <a href={shop.support_file_4} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                            }
                                            {(!shop.support_file_4) &&(<font className="text-danger" style={{ fontWeight: '600', marginTop: -10 }}>ไม่พบเอกสาร</font>)}
                                            </div>
                                        </div> 
                                        {(shop.type == 2) && (
                                        <div className="flex-container" style={{ display: 'flex' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 3 }}><strong>สำเนาใบสำคัญการจดทะเบียน</strong></label>
                                            <div style={{ display: 'flex', flex: 4, flexDirection: 'column', marginTop: 7 }}>
                                            {(shop.support_file_5) &&
                                                <a href={shop.support_file_5} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                            }
                                            {(!shop.support_file_5) &&(<font className="text-danger" style={{ fontWeight: '600', marginTop: -10 }}>ไม่พบเอกสาร</font>)}
                                            </div>
                                        </div>)}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}

export default ProfileShop