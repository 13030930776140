import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, getDoc, doc } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import { BsPersonVcard } from "react-icons/bs"
import { TbHome, TbEdit } from 'react-icons/tb'
import { FaMapMarkerAlt } from 'react-icons/fa'
import { RiBankCardLine } from 'react-icons/ri'
import LeftMenu from './LeftMenu'
import noImage from "../../assets/images/avatar.png"

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

import { Loading } from "../layouts/Loading"
import { defaultLatLng, googleMapsApiKey } from "../../../assets/constants/locations"

const Profile = () => {
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)

    const [showLoading, setShowLoading] = useState(false)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [uid, setUid] = useState('')
    const [member, setMember] = useState([])
    const [memberId, setMemberId] = useState('')
    const [isShop, setIsShop] = useState(0)

    const [subDistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [bankName, setBankName] = useState('')

    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey })

    const getDocUser = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const memberData = docs.docs[0].data()
                setUid(user.uid)
                setMemberId(docs.docs[0].id)
                setMember(memberData)
                if(memberData.is_shop) setIsShop(memberData.is_shop)

                var promisesTask = []
                if(memberData.province) {
                    const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', memberData.province * 1))).then((docs) => {
                        if(docs.docs.length > 0) setProvince(docs.docs[0].data().province_name)
                    })
                    promisesTask.push(provinceTask)
                }

                if(memberData.district) {
                    const districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', memberData.district))).then((docs) => {
                        if(docs.docs.length > 0) setDistrict(docs.docs[0].data().district_name)
                    })
                    promisesTask.push(districtTask)
                }

                if(memberData.sub_district) {
                    const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', memberData.sub_district * 1))).then((docs) => {
                        if(docs.docs.length > 0) setSubDistrict(docs.docs[0].data().subdistrict_name)
                    })
                    promisesTask.push(subdistrictTask)
                }

                if(memberData.bank_id == 0 || !memberData.bank_id) {
                    setBankName('ไม่ระบุ')
                } else {
                    const bankTask  = getDoc(doc(db, `banks/${memberData.bank_id}`)).then((doc) => {
                        setBankName(doc.data().bank_name_th)
                    })
                    promisesTask.push(bankTask)
                }

                setLat(memberData.lat)
                setLng(memberData.lng)

                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                })
            } else {
                navigate("/")
                setShowLoading(false)
            }
        }).then(() => {
        }).catch((error) => {
            alert(error.message)
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }
    
    useEffect(() => {
        if (loading) return
        if (!user) {
            return navigate("/")
        } else {
            setShowLoading(true)
            getDocUser()
        }
    }, [user, loading])

    return (
        <>
            <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
            <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 280 }} role="presentation">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                            <div style={{ marginBottom: 10 }}>
                                <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                            </div>
                            <LeftMenu currentPage="profile" isShop={isShop} />
                        </div>
                    </div>
                </Box>
            </Drawer>
            <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10 }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }} className="d-none d-md-block d-lg-block"><h4><b>ข้อมูลส่วนตัว</b></h4></div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                            <LeftMenu currentPage="profile" isShop={isShop} />
                        </div>
                        {!showLoading &&
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                                <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                                    <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop: 10 }}>
                                <h4 style={{ display: 'flex', flex: 1}}><BsPersonVcard style={{ fontSize: 30, marginRight: 10 }} />
                                <b>ข้อมูลผู้ใช้</b></h4>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate('/profile/edit') }}>
                                        <TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขข้อมูลผู้ใช้
                                    </button>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', width: 170 }}>
                                    <div style={{ minWidth: 100, maxWidth: 150 }}>
                                        {member.image_profile?
                                        (<img src={member.image_profile} alt="WEEE" className='img-fluid' />):
                                        (<img src={noImage} alt="WEEE" className='img-fluid' />)}
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, borderTop: '1px solid #cccccc'}}>
                                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', paddingTop: 13 }}>
                                        <h6><BsPersonVcard style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>ข้อมูลส่วนตัว</b></h6>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15 }}>
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1 }}><strong>การยืนยัน Email</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{user?(user.emailVerified?(<font className='text-success'>ยืนยัน</font>):(<font className='text-danger'>ยังไม่ยืนยัน</font>)):''}</div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1 }}><strong>สถานะ</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{member.status==1?(<font className='text-danger'>ยังไม่อนุมัติ</font>):(<font className='text-success'>อนุมัติ</font>)}</div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1 }}><strong>Email</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{user?user.email:''}</div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>ชื่อผู้ใช้</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{member.display_name?member.display_name:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>ชื่อ-นามสกุล</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}><font style={{ marginRight: 10 }}>{member.firstname?member.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font> <font>{member.lastname?member.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font></div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>เบอร์โทรศัพท์</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{member.tel_no?member.tel_no:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>สำเนาบัตรประชาชน</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>
                                                {member.id_card?
                                                (<a href={member.id_card} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><img src={member.id_card} alt="WEEE" className='img-fluid' style={{ width: 200 }} /></a>):
                                                (<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                    </div>
                                    <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginTop: 15 }}>
                                        <h6><RiBankCardLine style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>บัญชีธนาคาร</b></h6>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>ธนาคาร</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}><font style={{ marginRight: 10 }}>{bankName?bankName:(<font className="text-danger">ไม่ระบุ</font>)}</font></div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>หมายเลขบัญชี</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{member.bank_account_no?member.bank_account_no:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>สำเนาหน้าสมุดบัญชี</strong></label>
                                            <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>
                                                {member.book_bank?
                                                (<a href={member.book_bank} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><img src={member.book_bank} alt="WEEE" className='img-fluid' style={{ width: 200 }} /></a>):
                                                (<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                                <div className="d-none d-md-block d-lg-block" style={{ minWidth: 20 }}>&nbsp;</div>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, borderTop: '1px solid #cccccc'}}>
                                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', paddingTop: 13 }}>
                                        <h6><TbHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>ข้อมูลที่อยู่</b></h6>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>บ้านเลขที่</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {member.address?member.address:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>หมู่บ้าน/สถานที่</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {member.village?member.village:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>อาคาร</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {member.building?member.building:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>ชั้น</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {member.floor?member.floor:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>ถนน</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {member.road?member.road:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>ห้อง</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {member.room?member.room:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>ซอย</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {member.subroad?member.subroad:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>ตำบล</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {subDistrict?subDistrict:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>อำเภอ</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {district?district:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>จังหวัด</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {province?province:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div> 
                                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <label className="col-form-label" style={{ display: 'flex', flex: 1}}><strong>รหัสไปรษณีย์</strong></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                                {member.postcode?member.postcode:(<font className="text-danger">ไม่ระบุ</font>)}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ width: 170 }} className="d-none d-md-block d-lg-block">&nbsp;</div>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginTop: 15 }}>
                                        <h6><FaMapMarkerAlt style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} />
                                        <b>ตำแหน่งพิกัดที่พักอาศัย</b></h6>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap' }}>
                                            <font className="text-danger" style={{ marginRight: 5 }}>**</font> ลาก Marker <FaMapMarkerAlt className='text-danger' style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} /> เพื่อกำหนดตำแหน่ง
                                        </div>
                                        <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                                        <div className="mb-3 row" style={{ display: 'flex', height:400, flexDirection: 'column' }}>
                                            <div style={{  display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                                {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                                <GoogleMap mapContainerClassName="map-container" center={{ lat: lat, lng: lng }} zoom={17} >
                                                    <MarkerF position={{ lat: lat, lng: lng }}/>
                                                </GoogleMap>
                                                )}
                                            </div>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                        }
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile