import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, updateDoc, doc, getDoc, addDoc, orderBy } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useNavigate, useParams } from "react-router-dom"
import { BsCashCoin } from "react-icons/bs"
import { PiCoinsDuotone, PiHandCoins } from "react-icons/pi"
import { AiFillInfoCircle } from 'react-icons/ai'
import { Typography, Paper, TextField } from '@mui/material'
import { Dialog, DialogActions } from '@mui/material'
import { Card, CardContent } from '@mui/material'
import LeftMenu from '../shop/LeftMenu'

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Coins = () => {
    const params = useParams()
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [openDrawer, setOpenDrawer] = useState(false)

    const [user, loading] = useAuthState(auth)
    const [uid, setUid] = useState('')

    const [shopId, setShopId] = useState(params.id)
    const [shop, setShop] = useState([])
    
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState({})
    const [freeCoin, setFreeCoin ] = useState(0)
    const [exchangeCoin, setExchangeCoin] = useState(0)
    const [coinLog, setCoinLog] = useState([])
    const [exchangeLog, setExchangeLog] = useState([])
    const [reserveList, setReserveList] = useState([])
    const [reserveCoin, setReserveCoin] = useState([])

    const [buyAmount, setBuyAmount] = useState(0)
    const [openBuyCoin, setOpenBuyCoin] = useState(false)
    const [moneyAmount, setMoneyAmount] = useState(0)
    const inputRef = useRef(null);
    const [buySlip, setBuySlip] = useState('')
    
    const [moneyToCoin1, setMoneyToCoin1] = useState(0)
    const [moneyToCoin2, setMoneyToCoin2] = useState(0)
    const [coinToMoney1, setCoinToMoney1] = useState(0)
    const [coinToMoney2, setCoinToMoney2] = useState(0)

    const [displayBuyCoinErrorDiv, setDisplayBuyCoinErrorDiv] = useState(false)
    const [displayBuySlipErrorDiv, setDisplayBuySlipErrorDiv] = useState(false)
 
    const dateFormatTime = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]+" น."
    }

    const handleCloseBuyCoin = () => {
        setOpenBuyCoin(false)
    }

    const validateBuyForm = () => {
        if((buyAmount*1) <= 0) {
            setDisplayBuyCoinErrorDiv(true)
            return false
        } else {
            setDisplayBuyCoinErrorDiv(false)
        }
        if(!buySlip) {
            setDisplayBuySlipErrorDiv(true)
            return false
        } else {
            setDisplayBuySlipErrorDiv(false)
        }
        return true
    }

    const handleBuyCoin = () => {
        if(validateBuyForm()) {
            const transaction_date = new Date()
            addDoc(collection(db, `exchange_coins/buy_coin/transaction`), {
                date: transaction_date,
                coin: buyAmount*1,
                amount: moneyAmount*1,
                shop_id: shopId,
                rate_per_coin: moneyToCoin1*1,
                status: 1
            }).then((docRef) => {
                const storageRef = ref(storage, `/buy_slip/${docRef.id}/${buySlip.name}`)
                const uploadTask = uploadBytesResumable(storageRef, buySlip)
                uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err),
                    () => { 
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            updateDoc(doc(db, `exchange_coins/buy_coin/transaction`, docRef.id), {
                                buy_slip: url,
                            }).then(() => {
                                setOpenBuyCoin(false)
                                navigate(0)
                            })
                        })
                    }
                )
            })
        }
    }

    const calBuyAmount = (amount) => {
        setBuyAmount(amount)
        setMoneyAmount( (amount*1) * (moneyToCoin1*1))
    }

    const getExchangeRate = () => {
        getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeSnapshot) => {
            const data = exchangeSnapshot.data()
            setMoneyToCoin1(data.money_to_coin_1)
            setMoneyToCoin2(data.money_to_coin_2)
            setCoinToMoney1(data.coin_to_money_1)
            setCoinToMoney2(data.coin_to_money_2)
        })
    }

    const getShop = () => {
        getDoc(doc(db, 'shops', shopId)).then((shopDoc) => {
            setShop(shopDoc)
            if(shopDoc.data().freecoin) setFreeCoin(shopDoc.data().freecoin)
            if(shopDoc.data().exchange_coin) setExchangeCoin(shopDoc.data().exchange_coin)

            getDocs(query(collection(db, `shops/${shopId}/coin_logs`), where('coin_type', '==', '1'), orderBy('date', 'desc'))).then((docs) => {
                if(!docs.empty) {
                    setCoinLog(docs.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })))
                }
            })

            getDocs(query(collection(db, `shops/${shopId}/coin_logs`), where('coin_type', '==', '2'), orderBy('date', 'desc'))).then((docs) => {
                if(!docs.empty) {
                    setExchangeLog(docs.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })))
                }
            })

            getDocs(query(collection(db, `shops/${shopId}/reserve_coin`), where('status', '==', 1))).then((docs) => {
                if(!docs.empty) {
                    var coins = 0
                    docs.docs.forEach((reserve) => {
                        coins += reserve.data().coin
                        setReserveCoin(coins)
                    })
                }
            })
        }).then(() => {
            getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
                if (docs.docs.length === 1) {
                    setUid(user.uid)
                    setMemberId(docs.docs[0].id)
                    setMember(docs.docs[0].data())
                } else {
                    navigate('/')
                }
            }).catch((error) => {
                alert(error.message)
            })
        }).catch((error) => {
            alert(error.message)
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    var marginTopCoin = 35

    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        marginTopCoin = -15
    } else if(window.innerWidth < 576) {
        marginTopCoin = -15
    }
    
    useEffect(() => {
        if (loading) return
        if (!user) return navigate("/")
        else { 
            getShop()
            getExchangeRate() 
        }
    }, [user, loading])

    return (
        <>
            <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 280 }} role="presentation">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                            <div style={{ marginBottom: 10 }}>
                                <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                            </div>
                            <LeftMenu currentPage="coins" isShop={true} />
                        </div>
                    </div>
                </Box>
            </Drawer>
            <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                    <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                        <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                            <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                        </div>
                    </div>
                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ข้อมูลเหรียญ WEEE</b></h4></div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                            <LeftMenu currentPage="coins" isShop={true} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div className="flex-container" style={{ display: 'flex' }}>
                                <Paper elevation={2} style={{ display: 'flex', flex: 1, marginBottom: 20, marginRight: 10, flexDirection: 'column' }}>
                                    <div className='bg-primary' style={{ height: 50, backgroundColor: "#dddddd", paddingTop: 8, paddingLeft: 20}}>
                                        <h3 className='text-white'><strong><PiCoinsDuotone style={{ marginRight: 8 }} />เหรียญแจกฟรี</strong></h3>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                                        <div><h4><strong>จำนวนเหรียญที่เหลือ</strong></h4></div>
                                        <div className="flex-container" style={{ paddingLeft: 10, marginTop: -20, display: 'flex' }}>
                                            <font style={{ fontSize: 60, marginRight: 15 }}>{number_format.format(freeCoin)}</font>
                                            <font style={{ fontSize: 30, marginTop: marginTopCoin }}>เหรียญ</font>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 25, marginBottom: 15, float: 'right'}}>
                                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { navigate(`/coins/free/`) }}>
                                            <AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูลเพิ่มเติม
                                        </button>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">รายละเอียด</th>
                                                    <th scope="col">จำนวน</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {coinLog?.map((log, i) => (
                                                <tr key={i}>
                                                    <td><strong>{log.data.event}</strong><br/>{dateFormatTime(log.data.date)}</td>
                                                    <td>
                                                        {log.data.add && (<font className="text-success">{number_format.format(log.data.amount)}</font>)}
                                                        {!log.data.add && (<font className="text-danger">-{number_format.format(log.data.amount)}</font>)}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Paper>
                                <Paper elevation={2} style={{ display: 'flex', flex: 1, marginBottom: 20, marginRight: 10, flexDirection: 'column' }}>
                                    <div className='bg-success' style={{ height: 50, backgroundColor: "#dddddd", paddingTop: 8, paddingLeft: 20}}>
                                        <h3 className='text-white'><strong><PiCoinsDuotone style={{ marginRight: 8 }} />เหรียญแลกเปลี่ยน</strong></h3>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                                        <div><h4><strong>จำนวนเหรียญที่เหลือ</strong></h4></div>
                                        <div className="flex-container" style={{ paddingLeft: 10, marginTop: -20, display: 'flex' }}>
                                            <font style={{ fontSize: 60, marginRight: 15 }}>{number_format.format(exchangeCoin)}</font>
                                            <font style={{ fontSize: 30, marginTop: marginTopCoin }}>เหรียญ</font>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                                        <div><h5><strong>จำนวนเหรียญที่ถูกสำรอง</strong></h5></div>
                                        <div style={{ paddingLeft: 10, marginTop: -20 }}>
                                            <font style={{ fontSize: 40, marginRight: 15 }}>{number_format.format(reserveCoin)}</font>
                                            <font style={{ fontSize: 30, marginTop: marginTopCoin }}>เหรียญ</font>
                                        </div>
                                    </div>
                                    <div className="flex-container" style={{ display: 'flex', paddingLeft: 25, marginBottom: 5, float: 'right'}}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenBuyCoin(true) }}>
                                                <PiHandCoins style={{ fontSize: 20, marginRight: 5 }} />ซื้อเหรียญ
                                            </button>
                                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { navigate(0) }}>
                                                <BsCashCoin style={{ fontSize: 18, marginRight: 5 }} />แลกเหรียญ
                                            </button>
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                            <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { navigate(`/coins/exchange/`) }}>
                                                <AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูลเพิ่มเติม
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: 'row', padding: 10 }}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">รายละเอียด</th>
                                                    <th scope="col">จำนวน</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {exchangeLog?.map((log, i) => (
                                                <tr key={i}>
                                                    <td><strong>{log.data.event}</strong><br/>{dateFormatTime(log.data.date)}</td>
                                                    <td>
                                                        {log.data.add && (<font className="text-success">{number_format.format(log.data.amount)}</font>)}
                                                        {!log.data.add && (<font className="text-danger">-{number_format.format(log.data.amount)}</font>)}
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                </Paper>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog open={openBuyCoin} onClose={handleCloseBuyCoin}>
                    <Card variant="outlined">
                        <CardContent style={{ fontFamily: 'Prompt' }}>
                            <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}>
                                ซื้อเหรียญ
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                อัตราแลกเปลี่ยน {number_format.format(moneyToCoin1)} บาท = {number_format.format(moneyToCoin2)} เหรียญ
                            </Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="จำนวนเหรียญที่จะซื้อ"
                                type="number"
                                fullWidth
                                variant="standard"
                                value={buyAmount}
                                onChange={(e) => calBuyAmount(e.target.value)}
                                style={{ fontFamily: 'Prompt' }}
                            />
                            {displayBuyCoinErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกจำนวนเหรียญ</strong>
                                </div>
                            }
                            <Typography gutterBottom variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                คิดเป็นยอดโอน {number_format.format(moneyAmount)} บาท
                            </Typography>
                            <Typography gutterBottom variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                หลักฐานการโอน
                            </Typography>
                            <input ref={inputRef} className="form-control file" id="buy_slip" type="file" accept="image/*" onChange={(e) => setBuySlip(e.target.files[0])} />
                            {displayBuySlipErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาอัพโหลดสลิปการโอนเงิน</strong>
                                </div>
                            }
                        </CardContent>
                        <DialogActions>
                            <button onClick={handleCloseBuyCoin} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                            <button onClick={handleBuyCoin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                        </DialogActions>
                    </Card>
                </Dialog>
            </div>
        </>
    )
}

export default Coins