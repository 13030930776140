import React, { useState } from 'react'
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline  } from 'react-icons/io5'
import { db } from "../../assets/js/firebase"
import { collection, addDoc } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"

export function MessageToMember ({to, receiverId, postId, open, close}) {
    const navigate = useNavigate()
    const [message, setMessage] = useState('')

    const submitMessage = () => {
        const date = new Date()
        addDoc(collection(db, `/users/${receiverId}/system_message`), {
            date: date.getTime(),
            message: message,
            post_id: postId,
            link: `/post/${postId}`,
            status: 0
        }).then(() => {
            close()
            navigate(0)
        })
    }

    return (
        <Dialog open={open} onClose={close} maxWidth='sm' fullWidth={true}>
            <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ส่งข้อความหาผู้ประกาศ</strong></h4></div></DialogTitle>
            <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                        <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                            <div style={{ backgroundColor: '#ffffff' }}>
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                    <label className="" style={{ display: 'flex' }}><h5><strong>ส่งถึง</strong></h5></label>
                                    <div style={{ display: 'flex', flex: 1, marginLeft: 15 }}><h5>{to}</h5></div>
                                </div> 
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                    <label className="" style={{ display: 'flex' }}><h5><strong>ข้อความ</strong></h5></label>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        <textarea style={{ display: 'flex', flex: 1, height: 120, paddingLeft: 8 }}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder='ข้อความ' 
                                            value={message}></textarea>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={close} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                <button onClick={submitMessage} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ส่งข้อความ</button>
            </DialogActions>
        </Dialog>
    )
}

export function MessageToShopOffer({to, receiverId, offerId, open, close}) {
    const navigate = useNavigate()
    const [message, setMessage] = useState('')

    const submitMessage = () => {
        const date = new Date()
        addDoc(collection(db, `/shops/${receiverId}/system_message`), {
            date: date.getTime(),
            message: message,
            offer_id: offerId,
            link: `/shop/offers/${offerId}`,
            status: 0
        }).then(() => {
            close()
            navigate(0)
        })
    }

    return (
        <Dialog open={open} onClose={close} maxWidth='sm' fullWidth={true}>
            <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ส่งข้อความหาร้านค้า</strong></h4></div></DialogTitle>
            <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                        <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                            <div style={{ backgroundColor: '#ffffff' }}>
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                    <label className="" style={{ display: 'flex' }}><h5><strong>ส่งถึงร้านค้า</strong></h5></label>
                                    <div style={{ display: 'flex', flex: 1, marginLeft: 15 }}><h5>{to}</h5></div>
                                </div> 
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                    <label className="" style={{ display: 'flex' }}><h5><strong>ข้อความ</strong></h5></label>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        <textarea style={{ display: 'flex', flex: 1, height: 120, paddingLeft: 8 }}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder='ข้อความ' 
                                            value={message}></textarea>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={close} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                <button onClick={submitMessage} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ส่งข้อความ</button>
            </DialogActions>
        </Dialog>
    )
}

export function MessageToShopAppointment({to, receiverId, appointmentId, open, close}) {
    const navigate = useNavigate()
    const [message, setMessage] = useState('')

    const submitMessage = () => {
        const date = new Date()
        addDoc(collection(db, `/shops/${receiverId}/system_message`), {
            date: date.getTime(),
            message: message,
            appointment_id: appointmentId,
            link: `/shop/appoinment/${appointmentId}`,
            status: 0
        }).then(() => {
            close()
            navigate(0)
        })
    }

    return (
        <Dialog open={open} onClose={close} maxWidth='sm' fullWidth={true}>
            <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ส่งข้อความหาร้านค้า</strong></h4></div></DialogTitle>
            <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                        <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                            <div style={{ backgroundColor: '#ffffff' }}>
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                    <label className="" style={{ display: 'flex' }}><h5><strong>ส่งถึงร้านค้า</strong></h5></label>
                                    <div style={{ display: 'flex', flex: 1, marginLeft: 15 }}><h5>{to}</h5></div>
                                </div> 
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                    <label className="" style={{ display: 'flex' }}><h5><strong>ข้อความ</strong></h5></label>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        <textarea style={{ display: 'flex', flex: 1, height: 120, paddingLeft: 8 }}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder='ข้อความ' 
                                            value={message}></textarea>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={close} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                <button onClick={submitMessage} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ส่งข้อความ</button>
            </DialogActions>
        </Dialog>
    )
}