import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { db, auth } from "../../assets/js/firebase"
import useScript from '../../assets/js/useScript';
import { collection, query,  where, getDocs, updateDoc, doc } from 'firebase/firestore'
import { useNavigate } from "react-router-dom";
import LeftMenu from './LeftMenu';
import "../../assets/css/jquery.Thailand.min.css";

const Address = () => {
    useScript('https://code.jquery.com/jquery-3.2.1.min.js');
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dependencies/JQL.min.js');
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dependencies/typeahead.bundle.js');
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dist/jquery.Thailand.min.js');

    const inputSubDistrictRef = useRef(null);
    const inputDistrictRef = useRef(null);
    const inputProvinceRef = useRef(null);
    const inputPostcodeRef = useRef(null);

    const [user, loading, error] = useAuthState(auth);
    const [uid, setUid] = useState('');
    const [docId, setDicId] = useState('');
    const [isShop, setIsShop] = useState(0);

    const [address, setAddress] = useState('');
    const [road, setRoad] = useState('');
    const [subDistrict, setSubDistrict] = useState('');
    const [district, setDistrict] = useState('');
    const [province, setProvince] = useState('');
    const [postcode, setPostcode] = useState('');
    const [lat, setLat] = useState(15.11745249400088);
    const [lng, setLng] = useState(104.90284046686465);
    const navigate = useNavigate();

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    });
    const center = { lat: lat, lng: lng };
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat);
        setLng(lng);
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()
        const subDistrictRef = inputSubDistrictRef.current.value;
        const districtRef = inputDistrictRef.current.value;
        const provinceRef = inputProvinceRef.current.value;
        const postcodeRef = inputPostcodeRef.current.value;
		try {
            updateDoc(doc(db, 'users', docId), {
                address: address,
                road: road,
                sub_district: subDistrictRef,
                district: districtRef,
                province: provinceRef,
                postcode: postcodeRef,
                lat: lat,
                lng: lng,
            }).then(() => {
                getDocUser()
            })
		} catch (err) {
			alert(err)
		}

    }

    const getDocUser = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user.uid));
            const docs = await getDocs(q);
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDicId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.postcode) setPostcode(data.postcode)
                if(data.province) setProvince(data.province)
                if(data.district) setDistrict(data.district)
                if(data.sub_district) setSubDistrict(data.sub_district)
                if(data.lat) setLat(data.lat)
                if(data.lng) setLng(data.lng)
                if(data.is_shop) setIsShop(data.is_shop)

            }
        } catch (err) {
            console.error(err);
            alert(err.message);
        }
      }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else {
            getDocUser()
        }
    }, [user, loading]);

    useScript('https://firebasestorage.googleapis.com/v0/b/weee-81f51.appspot.com/o/js%2FthailandAddress.js?alt=media&token=b1c469d5-5f9e-4cb1-98b3-05056ea57a86');

return (
    <>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 20}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div style={{ paddingLeft: 20, marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ที่อยู่ของฉัน</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <LeftMenu currentPage="address" isShop={isShop} />
                    <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '1px solid #cccccc', paddingLeft: 20, flex: 1, paddingTop: 15 }}>
                        <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>บ้านเลขที่, หมู่บ้าน, อาคาร</strong></label>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <input type='text' name='address' onChange={(e) => setAddress(e.target.value)} 
                                    value={address} placeholder='บ้านเลขที่, หมู่บ้าน, อาคาร'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                            </div>
                        </div> 
                        <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ถนน</strong></label>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                    value={road} placeholder='ภนน'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                            </div>
                        </div> 
                        <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ตำบล</strong></label>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <input type='text' id="district" name='subDistrict' autoComplete='no' onChange={(e) => setSubDistrict(e.target.value)} 
                                    value={subDistrict} placeholder='ตำบล' ref={inputSubDistrictRef}
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', height: 40 }} />
                            </div>
                        </div> 
                        <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>อำเภอ</strong></label>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <input type='text' id="amphoe" name='district' autoComplete='no' onChange={(e) => setDistrict(e.target.value)} 
                                    value={district} placeholder='อำเภอ' ref={inputDistrictRef}
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', height: 40 }} />
                            </div>
                        </div> 
                        <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>จังหวัด</strong></label>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <input type='text' id="province" name='province' autoComplete='no' onChange={(e) => setProvince(e.target.value)} 
                                    value={province} placeholder='จังหวัด' ref={inputProvinceRef}
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', height: 40 }} />
                            </div>
                        </div> 
                        <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>รหัสไปรษณีย์</strong></label>
                            <div style={{ display: 'flex', flex: 1 }}>
                                <input type='text' id="zipcode" name='postcode' autoComplete='no' onChange={(e) => setPostcode(e.target.value)} 
                                    value={postcode} placeholder='รหัสไปรษณีย์' ref={inputPostcodeRef}
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', height: 40 }} />
                            </div>
                        </div> 
                        <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ตำแหน่งพิกัดที่พักอาศัย</strong></label>
                            <div style={{ marginBottom: 1, flexDirection: 'column', display: 'flex', flexWrap: 'nowrap', height:300, flex: 1 }}>

                                <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap' }}>
                                    <input type='text' name='Lat' value={lat} style={{ marginRight: 10}} readOnly />
                                    <input type='text' name='Long' value={lng} readOnly />
                                </div>
                                {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                    <MarkerF
                                        draggable={true} position={center}
                                        onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                    />
                                </GoogleMap>
                                )}
                            </div>
                        </div>
                        <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}> </label>
                            <button style={{ marginLeft: 12, width: 300, textAlign: 'center' }} className="btn btn-primary" type='button' onClick={handleSubmit}>บันทึก</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}

export default Address;