import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, orderBy, onSnapshot, doc, or, and } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import LeftMenu from './LeftMenu'
import { AppointmentShopBoard1 } from './AppointBoard1'
import { AppointmentShopBoard2 } from './AppointBoard2'
import { AppointmentShopBoard3 } from './AppointBoard3'
import { AppointmentShopBoard4 } from './AppointBoard4'
import { AppointmentShopAir } from './AppointAir'
import { AppointmentShopWash } from './AppointWash'
import { Loading } from "../layouts/Loading"

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Appointments = () => {
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const [uid, setUid] = useState('')
    const [userId, setUserId] = useState('')
    const [shopId, setShopId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [appointments, setAppointments] = useState([])
    const [showLoading, setShowLoading] = useState(true)
    const [searchBoard, setSearchBoard] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)

    const handleSearchByBoard = (value) => {
        const status = value * 1
        const others = Array.from(
            document.getElementsByClassName('board-other')
        )
        const allBoard = Array.from(
            document.getElementsByClassName('board-all')
        )

        if(status == 0) {
            searchBoard.splice(0, searchBoard.length)
            setSearchBoard([...searchBoard])
            allBoard.forEach(element => {
                element.checked = true
            })
            others.forEach(element => {
                element.checked = false
                element.removeAttribute('checked')
            })
        } else {
            if(searchBoard.indexOf(status) < 0) {
                searchBoard.push(status)
            } else {
                searchBoard.splice(searchBoard.indexOf(status), 1)
            }
            setSearchBoard([...searchBoard])
            if(searchBoard.length > 0) {
                allBoard.forEach(element => {
                    element.checked = false
                    element.removeAttribute('checked')
                })
            } else {
                allBoard.forEach(element => {
                    element.checked = true
                })
            }
        }

        handleSearch()
    }

    const handleSearch = () => {
        if(userId) {
            setShowLoading(true)
            const constraints = []
            const order = []
            order.push(orderBy('appointment_date', 'asc'))
            order.push(orderBy('appointment_hour', 'asc'))
            order.push(orderBy('appointment_minute', 'asc'))

            if(searchBoard.length > 0) {
                searchBoard.forEach((board) => {
                    if(board == 1) { // ทิ้งซาก
                        constraints.push(and(where('appointment_type', '==', 'post'), where('post_onboard', '==', 1)))
                    } else if(board == 2) { // ขายซาก
                        constraints.push(and(where('appointment_type', '==', 'post'), where('post_onboard', '==', 2)))
                    } else if(board == 3) { // ขายมือสอง
                        constraints.push(and(where('appointment_type', '==', 'post'), where('post_onboard', '==', 3)))
                    } else if(board == 4) { // แจ้งซ่อม
                        constraints.push(and(where('appointment_type', '==', 'fix')))
                    } else if(board == 5) { // ร้านลงขาย
                    } else if(board == 6) { // แจ้งล้างแอร์
                        constraints.push(and(where('appointment_type', '==', 'air')))
                    } else if(board == 7) { // แจ้งล้างเครื่องซักผ้า
                        constraints.push(and(where('appointment_type', '==', 'wash')))
                    }
                })
            }
            
            if(constraints.length >= 2) {
                var appointmentQuery = query(collection(db, 'appointments'), and(where('shop_id', '==', shopId), or(...constraints)), ...order)
            } else {
                var appointmentQuery = query(collection(db, 'appointments'), and(where('shop_id', '==', shopId), ...constraints), ...order)
            }

            onSnapshot(appointmentQuery, (querySnapshot) => {
                appointments.splice(0, appointments.length)
                setAppointments([...appointments])
                var i = 1
                if(querySnapshot.docs.length == 0) { setShowLoading(false) }
                else {
                    querySnapshot.forEach( async(appointment) => {
                        var postType = 'posts'
                        if(appointment.data().appointment_type == 'air' || appointment.data().appointment_type == 'washing') {
                            postType = 'cleans'
                        }
                        
                        const postSnapshot = await getDoc(doc(db, `${postType}`, appointment.data().post_id));
                        const offerSnapshot = await getDoc(doc(db, `${postType}/${appointment.data().post_id}/offers`, appointment.data().offer_id));
                        const checkSnapshot = await getDoc(doc(db, `appointments/${appointment.id}/report/before`))
                        const checkAfterSnapshot = await getDoc(doc(db, `appointments/${appointment.id}/report/after`))
                        if(postSnapshot.data()) {
                            var badgeStyle = { message: '', class: 'success', color: '#000000', show: false }
                            if(appointment.data().appointment_type == 'air' || appointment.data().appointment_type == 'washing') {
                                if(appointment.data().status==1) {
                                    if(!appointment.checkReportBefore) {
                                        badgeStyle = { message: 'รอสำรวจหน้างาน', class: 'warning', color: '#000000', show: true }
                                    } else {
                                        badgeStyle = { message: 'รอยืนยันให้บริการ', class: 'warning', color: '#000000', show: true }
                                    }
                                } else if(appointment.data().status==2) {
                                    badgeStyle = { message: 'รอตัดสินใจใช้บริการ', class: 'warning', color: '#000000', show: true }
                                } else if(appointment.data().status==3) {
                                    badgeStyle = { message: 'ตกลงใช้บริการ', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==4) {
                                    badgeStyle = { message: 'ช่างยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==5) {
                                    badgeStyle = { message: 'ผู้เรียกยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==6) {
                                } else if(appointment.data().status==7) {
                                } else if(appointment.data().status==8) {
                                    badgeStyle = { message: 'รอผู้เรียกรับมอบงาน', class: 'warning', color: '#000000', show: true }
                                } else if(appointment.data().status==9) {
                                    badgeStyle = { message: 'ผู้เรียกรับมอบงาน', class: 'succes', color: '#ffffff', show: true }
                                } else if(appointment.data().status==10) {
                                    badgeStyle = { message: 'ช่างยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==11) {
                                    badgeStyle = { message: 'ผู้เรียกไม่รับมอบงาน', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==12) {
                                    badgeStyle = { message: 'ผู้เรียกโอนเหรียญเรียบร้อย', class: 'success', color: '#ffffff', show: true }
                                }
                            } else if(appointment.data().appointment_type == 'post') {
                                if(appointment.data().status==1) {
                                    if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                    }
                                } else if(appointment.data().status==2) {
                                    if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'จ่ายเหรียญซื้อซากแล้ว', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'จ่ายเหรียญมัดจำซื้อสินค้าแล้ว', class: 'success', color: '#ffffff', show: true }
                                    }
                                } else if(appointment.data().status==3) {
                                    if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'ผู้ประกาศยืนยันขายซาก', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'ผู้ประกาศยืนยันขายสินค้า', class: 'success', color: '#ffffff', show: true }
                                    }
                                } else if(appointment.data().status==4) {
                                    if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'ยกเลิกการซื้อซาก', class: 'danger', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'ยกเลิกการซื้อสินค้า', class: 'danger', color: '#ffffff', show: true }
                                    }
                                } else if(appointment.data().status==5) {
                                    if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'ผู้ประกาศยกเลิกการขายซาก', class: 'danger', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'ผู้ประกาศยกเลิกการขายสินค้า', class: 'danger', color: '#ffffff', show: true }
                                    }
                                } else if(appointment.data().status==6) {
                                    if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'ยืนยันได้รับซากแล้ว', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'ยืนยันได้รับสินค้าแล้ว', class: 'success', color: '#ffffff', show: true }
                                    }
                                }
                            } else if(appointment.data().appointment_type == 'fix') {
                                if(appointment.data().status==1) {
                                    badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                } else if(appointment.data().status==2) {
                                    badgeStyle = { message: 'ยืนยันการรับซ่อม', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==3) {
                                    badgeStyle = { message: 'ผู้ประกาศยืนยันให้ซ่อม', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==4) {
                                    badgeStyle = { message: 'ปฏิเสธการรับซ่อม', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==5) {
                                    badgeStyle = { message: 'ผู้ประกาศไม่ให้ซ่อม', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==6) {
                                    badgeStyle = { message: 'รายงานการซ่อมแล้ว', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==7) {
                                    badgeStyle = { message: 'ผู้ประกาศยืนยันรับรายงาน', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==8) {
                                    badgeStyle = { message: 'ยืนยันได้รับเหรียญ', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==9) {
                                    badgeStyle = { message: 'ผู้ประกาศยกเลิกจ่ายค่าซ่อม', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==10) {
                                    badgeStyle = { message: 'ยกเลิกการรับเหรียญ', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==11) {
                                    badgeStyle = { message: 'ผู้ประกาศยืนยันเสริ็จงาน', class: 'success', color: '#ffffff', show: true }
                                }
                            }
                            
                            appointments.push({
                                id: appointment.id,
                                data: appointment.data(),
                                offer: offerSnapshot.data(),
                                post: postSnapshot.data(),
                                checkReportBefore: checkSnapshot.data(),
                                checkReportAfter: checkAfterSnapshot.data(),
                                badgeStyle: badgeStyle
                            })

                            setAppointments([...appointments, {
                                id: appointment.id,
                                data: appointment.data(),
                                offer: offerSnapshot.data(),
                                post: postSnapshot.data(),
                                checkReportBefore: checkSnapshot.data(),
                                checkReportAfter: checkAfterSnapshot.data(),
                                badgeStyle: badgeStyle
                            }])
                        }
                    })
                    setShowLoading(false)
                }
            })
        }
    }

    const fetchAppointments = () => {
        setShowLoading(true)
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setUserId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) {
                    getDoc(doc(db, `shops/`, data.shop_id)).then((shopDoc) => {
                        if(shopDoc.data().exchange_coin) setOfferExchangeCoin(shopDoc.data().exchange_coin)
                    })

                    
                    setIsShop(data.is_shop)
                    setShopId(data.shop_id)
                    const postQuery = query(collection(db, 'appointments'),
                                                where('shop_id', '==', data.shop_id), 
                                                /* and(where('shop_id', '==', data.shop_id), 
                                                or(
                                                    where('status', '==', 1),
                                                    where('status', '==', 2),
                                                    where('status', '==', 6))), */
                                                orderBy('appointment_date', 'asc'), 
                                                orderBy('appointment_hour', 'asc'), 
                                                orderBy('appointment_minute', 'asc'))
                    onSnapshot(postQuery, (querySnapshot) => {
                        appointments.splice(0, appointments.length)
                        setAppointments([...appointments])
                        querySnapshot.forEach( async(appointment) => {
                            var postType = 'posts'
                            if(appointment.data().appointment_type == 'air' || appointment.data().appointment_type == 'washing') {
                                postType = 'cleans'
                            }
                            
                            const postSnapshot = await getDoc(doc(db, `${postType}`, appointment.data().post_id));
                            const offerSnapshot = await getDoc(doc(db, `${postType}/${appointment.data().post_id}/offers`, appointment.data().offer_id));
                            const checkSnapshot = await getDoc(doc(db, `appointments/${appointment.id}/report/before`))
                            const checkAfterSnapshot = await getDoc(doc(db, `appointments/${appointment.id}/report/after`))
                            if(postSnapshot.data()) {
                                var badgeStyle = { message: '', class: 'success', color: '#000000', show: false }
                                if(appointment.data().appointment_type == 'air' || appointment.data().appointment_type == 'washing') {
                                    if(appointment.data().status==1) {
                                        if(!appointment.checkReportBefore) {
                                            badgeStyle = { message: 'รอสำรวจหน้างาน', class: 'warning', color: '#000000', show: true }
                                        } else {
                                            badgeStyle = { message: 'รอยืนยันให้บริการ', class: 'warning', color: '#000000', show: true }
                                        }
                                    } else if(appointment.data().status==2) {
                                        badgeStyle = { message: 'รอตัดสินใจใช้บริการ', class: 'warning', color: '#000000', show: true }
                                    } else if(appointment.data().status==3) {
                                        badgeStyle = { message: 'ตกลงใช้บริการ', class: 'success', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==4) {
                                        badgeStyle = { message: 'ช่างยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==5) {
                                        badgeStyle = { message: 'ผู้เรียกยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==6) {
                                    } else if(appointment.data().status==7) {
                                    } else if(appointment.data().status==8) {
                                        badgeStyle = { message: 'รอผู้เรียกรับมอบงาน', class: 'warning', color: '#000000', show: true }
                                    } else if(appointment.data().status==9) {
                                        badgeStyle = { message: 'ผู้เรียกรับมอบงาน', class: 'succes', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==10) {
                                        badgeStyle = { message: 'ช่างยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==11) {
                                        badgeStyle = { message: 'ผู้เรียกไม่รับมอบงาน', class: 'danger', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==12) {
                                        badgeStyle = { message: 'ผู้เรียกโอนเหรียญเรียบร้อย', class: 'success', color: '#ffffff', show: true }
                                    }
                                } else if(appointment.data().appointment_type == 'post') {
                                    if(appointment.data().status==1) {
                                        if(postSnapshot.data().onboard == 2) {
                                            badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                        } else if(postSnapshot.data().onboard == 3) {
                                            badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                        }
                                    } else if(appointment.data().status==2) {
                                        if(postSnapshot.data().onboard == 2) {
                                            badgeStyle = { message: 'จ่ายเหรียญซื้อซากแล้ว', class: 'success', color: '#ffffff', show: true }
                                        } else if(postSnapshot.data().onboard == 3) {
                                            badgeStyle = { message: 'จ่ายเหรียญมัดจำซื้อสินค้าแล้ว', class: 'success', color: '#ffffff', show: true }
                                        }
                                    } else if(appointment.data().status==3) {
                                        if(postSnapshot.data().onboard == 2) {
                                            badgeStyle = { message: 'ผู้ประกาศยืนยันขายซาก', class: 'success', color: '#ffffff', show: true }
                                        } else if(postSnapshot.data().onboard == 3) {
                                            badgeStyle = { message: 'ผู้ประกาศยืนยันขายสินค้า', class: 'success', color: '#ffffff', show: true }
                                        }
                                    } else if(appointment.data().status==4) {
                                        if(postSnapshot.data().onboard == 2) {
                                            badgeStyle = { message: 'ยกเลิกการซื้อซาก', class: 'danger', color: '#ffffff', show: true }
                                        } else if(postSnapshot.data().onboard == 3) {
                                            badgeStyle = { message: 'ยกเลิกการซื้อสินค้า', class: 'danger', color: '#ffffff', show: true }
                                        }
                                    } else if(appointment.data().status==5) {
                                        if(postSnapshot.data().onboard == 2) {
                                            badgeStyle = { message: 'ผู้ประกาศยกเลิกการขายซาก', class: 'danger', color: '#ffffff', show: true }
                                        } else if(postSnapshot.data().onboard == 3) {
                                            badgeStyle = { message: 'ผู้ประกาศยกเลิกการขายสินค้า', class: 'danger', color: '#ffffff', show: true }
                                        }
                                    } else if(appointment.data().status==6) {
                                        if(postSnapshot.data().onboard == 2) {
                                            badgeStyle = { message: 'ยืนยันได้รับซากแล้ว', class: 'success', color: '#ffffff', show: true }
                                        } else if(postSnapshot.data().onboard == 3) {
                                            badgeStyle = { message: 'ยืนยันได้รับสินค้าแล้ว', class: 'success', color: '#ffffff', show: true }
                                        }
                                    }
                                } else if(appointment.data().appointment_type == 'fix') {
                                    if(appointment.data().status==1) {
                                        badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                    } else if(appointment.data().status==2) {
                                        badgeStyle = { message: 'ยืนยันการรับซ่อม', class: 'success', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==3) {
                                        badgeStyle = { message: 'ผู้ประกาศยืนยันให้ซ่อม', class: 'success', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==4) {
                                        badgeStyle = { message: 'ปฏิเสธการรับซ่อม', class: 'danger', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==5) {
                                        badgeStyle = { message: 'ผู้ประกาศไม่ให้ซ่อม', class: 'danger', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==6) {
                                        badgeStyle = { message: 'รายงานการซ่อมแล้ว', class: 'success', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==7) {
                                        badgeStyle = { message: 'ผู้ประกาศยืนยันรับรายงาน', class: 'success', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==8) {
                                        badgeStyle = { message: 'ยืนยันได้รับเหรียญ', class: 'success', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==9) {
                                        badgeStyle = { message: 'ผู้ประกาศยกเลิกจ่ายค่าซ่อม', class: 'danger', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==10) {
                                        badgeStyle = { message: 'ยกเลิกการรับเหรียญ', class: 'danger', color: '#ffffff', show: true }
                                    } else if(appointment.data().status==11) {
                                        badgeStyle = { message: 'ผู้ประกาศยืนยันเสริ็จงาน', class: 'success', color: '#ffffff', show: true }
                                    }
                                }
                                
                                appointments.push({
                                    id: appointment.id,
                                    data: appointment.data(),
                                    offer: offerSnapshot.data(),
                                    post: postSnapshot.data(),
                                    checkReportBefore: checkSnapshot.data(),
                                    checkReportAfter: checkAfterSnapshot.data(),
                                    badgeStyle: badgeStyle
                                })

                                setAppointments([...appointments, {
                                    id: appointment.id,
                                    data: appointment.data(),
                                    offer: offerSnapshot.data(),
                                    post: postSnapshot.data(),
                                    checkReportBefore: checkSnapshot.data(),
                                    checkReportAfter: checkAfterSnapshot.data(),
                                    badgeStyle: badgeStyle
                                }])
                            }
                        })
                    })
                    setShowLoading(false)
                } else {
                    setShowLoading(false)
                    navigate("/profile")
                }
            }
            setShowLoading(false)
        }).catch((error) => {
            alert(error.message);
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user)  { return navigate("/") }
        else { 
            getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
                if (docs.docs.length === 1) {
                    setUserId(docs.docs[0].id)
                    setShopId(docs.docs[0].data().shop_id)
                    getDoc(doc(db, `shops/`, docs.docs[0].data().shop_id)).then((shopDoc) => {
                        if(shopDoc.data().exchange_coin) setOfferExchangeCoin(shopDoc.data().exchange_coin)
                    })
                }
            })
        }
    }, [user, loading])

    useEffect(() => {
        handleSearch()
    }, [userId])

    var appointmentKey = []
    var appointmentList = []
    appointments.forEach(async(element) => {
        if(appointmentKey.lastIndexOf(element.id) < 0) {
            appointmentKey.push(element.id)
            appointmentList.push(element)
        }
    })

    return (
    <>
        <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="appointments" isShop={true} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>การนัดหมายของร้านค้า</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="appointments" isShop={true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, paddingBottom: 10, borderBottom: '1px solid #cccccc' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}><h6><strong>ประเภทการนัดหมาย</strong></h6></div>
                            </div>
                            <div style={{ paddingLeft: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-all" type="checkbox" value="0" defaultChecked={true} onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ทั้งหมด</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="1" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ทิ้งซาก</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="2" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ขายซาก</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="3" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ขายเครื่องใช้ไฟฟ้ามือสอง</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="4" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> แจ้งซ่อมเครื่องใช้ไฟฟ้า</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="6" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> แจ้งล้างแอร์</div>
                                <div style={{ whiteSpace: 'nowrap'}}><input className="form-check-input board-other" type="checkbox" value="7" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> แจ้งล้างเครื่องซักผ้า</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5 }}>
                        {appointmentList?.map((appointment, i) => (
                            <div key={appointment.id}>
                                {(appointment.data.appointment_type == 'post' && appointment.post.onboard == 1) && (
                                <AppointmentShopBoard1 appointment={appointment} offerExchangeCoin={offerExchangeCoin} />
                                )}
                                {(appointment.data.appointment_type == 'post' && appointment.post.onboard == 2) && (
                                <AppointmentShopBoard2 appointment={appointment} offerExchangeCoin={offerExchangeCoin}  />
                                )}
                                {(appointment.data.appointment_type == 'post' && appointment.post.onboard == 3) && (
                                <AppointmentShopBoard3 appointment={appointment} offerExchangeCoin={offerExchangeCoin} />
                                )}
                                {(appointment.data.appointment_type == 'fix') && (
                                <AppointmentShopBoard4 appointment={appointment} />
                                )}
                                {(appointment.data.appointment_type == 'air') && (
                                <AppointmentShopAir appointment={appointment} offerExchangeCoin={offerExchangeCoin} />
                                )}
                                {(appointment.data.appointment_type == 'washing') && (
                                <AppointmentShopWash appointment={appointment} offerExchangeCoin={offerExchangeCoin} />
                                )}
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Appointments