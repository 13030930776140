import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, getDoc, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import LeftMenu from './LeftMenu'
import addImage from "../../assets/images/add-image-icon.png"

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'

import { IoCheckmarkCircleSharp, IoCloseCircle, IoChevronBackCircleSharp } from 'react-icons/io5'
import { AiFillEdit } from 'react-icons/ai'
import { FaMapMarkerAlt } from "react-icons/fa"

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { Loading } from "../layouts/Loading"

import LeftMenuPost from './LeftMenuPost'
import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { defaultLatLng, googleMapsApiKey } from "../../../assets/constants/locations"
import { defauleImageSize } from "../../../assets/constants/images"

const EditPostWash = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const number_format = new Intl.NumberFormat()
    const today = dayjs()
    const [postId, setPostId] = useState(params.id)

    const [uid, setUid] = useState('')
    const [member, setMember] = useState([])
    const [memberId, setMenberId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [onBoard, setOnBoard] = useState(1)
    const [status, setStatus] = useState(1)

    const [address, setAddress] = useState('')
    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [pickDate, setPickDate] = useState(today)
    const [hour, setHour] = useState('09')
    const [minute, setMinute] = useState('00')

    const [offerUseCoin, setOfferUseCoin] = useState(0)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [useCoinFrom, setUseCoinFrom] = useState(1)
    const [useCoinErrorDiv, setUseCoinErrorDiv] = useState(false)

    const [brandList, setBrandList] = useState([])
    const [brandId1, setBrandId1] = useState('')
    const [brandId2, setBrandId2] = useState('')
    const [brand1, setBrand1] = useState('')
    const [brand2, setBrand2] = useState('')

    const [modelList1, setModelList1] = useState([])
    const [modelList2, setModelList2] = useState([])
    const [modelId1, setModelId1] = useState('')
    const [modelId2, setModelId2] = useState('')
    const [model1, setModel1] = useState('')
    const [model2, setModel2] = useState('')
    
    const [washType1, setWashType1] = useState(true)
    const [wash1Amount, setWash1Amount] = useState(0)
    const [wash1Capacity, setWash1Capacity] = useState(1)
    const [wash1Images, setWash1Images] = useState([])
    const [washingType1, setWashingType1] = useState(1)
    const [price1, setPrice1] = useState(0)
    const [wash1IsProblem1, setWash1IsPloblem1] = useState(false)
    const [wash1IsProblem2, setWash1IsPloblem2] = useState(false)
    const [wash1IsProblem3, setWash1IsPloblem3] = useState(false)
    const [wash1IsProblem4, setWash1IsPloblem4] = useState(false)
    const [wash1IsProblem5, setWash1IsPloblem5] = useState(false)
    const [wash1IsProblem6, setWash1IsPloblem6] = useState(false)
    const [wash1IsProblem7, setWash1IsPloblem7] = useState(false)
    const [wash1IsProblem8, setWash1IsPloblem8] = useState(false)
    const [wash1IsProblem9, setWash1IsPloblem9] = useState(false)
    const [pictureWash1ErrorDiv, setPictureWash1ErrorDiv] = useState(false)

    const [washType2, setWashType2] = useState(false)
    const [wash2Amount, setWash2Amount] = useState(0)
    const [wash2Capacity, setWash2Capacity] = useState(1)
    const [wash2Images, setWash2Images] = useState([])
    const [washingType2, setWashingType2] = useState(1)
    const [price2, setPrice2] = useState(0)
    const [wash2IsProblem1, setWash2IsPloblem1] = useState(false)
    const [wash2IsProblem2, setWash2IsPloblem2] = useState(false)
    const [wash2IsProblem3, setWash2IsPloblem3] = useState(false)
    const [wash2IsProblem4, setWash2IsPloblem4] = useState(false)
    const [wash2IsProblem5, setWash2IsPloblem5] = useState(false)
    const [wash2IsProblem6, setWash2IsPloblem6] = useState(false)
    const [wash2IsProblem7, setWash2IsPloblem7] = useState(false)
    const [wash2IsProblem8, setWash2IsPloblem8] = useState(false)
    const [wash2IsProblem9, setWash2IsPloblem9] = useState(false)
    const [pictureWash2ErrorDiv, setPictureWash2ErrorDiv] = useState(false)

    const [brandIdErrorDiv1, setBrandIdErrorDiv1] = useState(false)
    const [brandIdErrorDiv2, setBrandIdErrorDiv2] = useState(false)
    const [brandErrorDiv1, setBrandErrorDiv1] = useState(false)
    const [brandErrorDiv2, setBrandErrorDiv2] = useState(false)
    const [modelIdErrorDiv1, setModelIdErrorDiv1] = useState(false)
    const [modelIdErrorDiv2, setModelIdErrorDiv2] = useState(false)
    const [modelErrorDiv1, setModelErrorDiv1] = useState(false)
    const [modelErrorDiv2, setModelErrorDiv2] = useState(false)

    const [currentLat, setCurrentLat] = useState(defaultLatLng.lat)
    const [currentLng, setCurrentLng] = useState(defaultLatLng.lng)
    const [regisLat, setRegisLat] = useState(defaultLatLng.lat)
    const [regisLng, setRegisLng] = useState(defaultLatLng.lng)

    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [provinceList, setProvinceList] = useState([])
    const [provinceId, setProvinceId] = useState(0)
    const [districtList, setDistrictList] = useState([])
    const [districtId, setDistrictId] = useState(0)
    const [subdistrictList, setSubDistrictList] = useState([])
    const [subdistrictId, setSubDistrictId] = useState(0)
    const [postcode, setPostcode] = useState('')

    const [addressNoRegis, setAddressNoRegis] = useState('')
    const [villageRegis, setVillageRegis] = useState('')
    const [buildingRegis, setBuildingRegis] = useState('')
    const [floorRegis, setFloorRegis] = useState('')
    const [roomRegis, setRoomRegis] = useState('')
    const [subroadRegis, setSubroadRegis] = useState('')
    const [roadRegis, setRoadRegis] = useState('')
    const [provinceIdRegis, setProvinceIdRegis] = useState(0)
    const [districtIdRegis, setDistrictIdRegis] = useState(0)
    const [subdistrictIdRegis, setSubDistrictIdRegis] = useState(0)
    const [postcodeRegis, setPostcodeRegis] = useState('')

    const [provinceIdErrorDiv, setProvinceIdErrorDiv] = useState(false)
    const [districtIdErrorDiv, setDistrictIdErrorDiv] = useState(false)
    const [subdistrictIdErrorDiv, setSubDistrictErrorDiv] = useState(false)
    const [imageSize1Error, setImageSize1Error] = useState(false)
    const [imageSize2Error, setImageSize2Error] = useState(false)

    const [showLoading, setShowLoading] = useState(false)
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [openAlertDelete, setOpenAlertDelete] = useState(false)

    const [wash1AmountErrorDiv, setWash1AmountErrorDiv] = useState(false)
    const [price1ErrorDiv, setPrice1ErrorDiv] = useState(false)
    const [wash2AmountErrorDiv, setWash2AmountErrorDiv] = useState(false)
    const [price2ErrorDiv, setPrice2ErrorDiv] = useState(false)

    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey })
    const [center, setCenter] = useState({ lat: defaultLatLng.lat, lng: defaultLatLng.lng })
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    var locationOptions = {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 0,
    }
    
    const handelGoToRegisPosition = () => {
        setLat(regisLat)
        setLng(regisLng)
        setCenter({lat: regisLat, lng: regisLng})
    }

    const handelGoToCurrentPosition = () => {
        setLat(currentLat)
        setLng(currentLng)
        setCenter({lat: currentLat, lng: currentLng})
    }

    const locationSuccess = (pos) => {
        var crd = pos.coords
        setCurrentLat(crd.latitude)
        setCurrentLng(crd.longitude)
    }

    const locationErrors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                //console.log(result);
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
            });
        } else {
          //console.log("Geolocation is not supported by this browser.");
        }
    }, [])

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    const handleWashType1Change = () => {
        setWashType1(!washType1)
    }

    const handleWash1IsProblem1 = () => {
        setWash1IsPloblem1(!wash1IsProblem1)
    }

    const handleWash1IsProblem2 = () => {
        setWash1IsPloblem2(!wash1IsProblem2)
    }

    const handleWash1IsProblem3 = () => {
        setWash1IsPloblem3(!wash1IsProblem3)
    }

    const handleWash1IsProblem4 = () => {
        setWash1IsPloblem4(!wash1IsProblem4)
    }

    const handleWash1IsProblem5 = () => {
        setWash1IsPloblem5(!wash1IsProblem5)
    }

    const handleWash1IsProblem6 = () => {
        setWash1IsPloblem6(!wash1IsProblem6)
    }

    const handleWash1IsProblem7 = () => {
        setWash1IsPloblem7(!wash1IsProblem7)
    }

    const handleWash1IsProblem8 = () => {
        setWash1IsPloblem8(!wash1IsProblem8)
    }

    const handleWash1IsProblem9 = () => {
        setWash1IsPloblem9(!wash1IsProblem9)
    }

    const handleWashType2Change = () => {
        setWashType2(!washType2)
    }

    const handleWash2IsProblem1 = () => {
        setWash2IsPloblem1(!wash2IsProblem1)
    }

    const handleWash2IsProblem2 = () => {
        setWash2IsPloblem2(!wash2IsProblem2)
    }

    const handleWash2IsProblem3 = () => {
        setWash2IsPloblem3(!wash2IsProblem3)
    }

    const handleWash2IsProblem4 = () => {
        setWash2IsPloblem4(!wash2IsProblem4)
    }

    const handleWash2IsProblem5 = () => {
        setWash2IsPloblem5(!wash2IsProblem5)
    }

    const handleWash2IsProblem6 = () => {
        setWash2IsPloblem6(!wash2IsProblem6)
    }

    const handleWash2IsProblem7 = () => {
        setWash2IsPloblem7(!wash2IsProblem7)
    }

    const handleWash2IsProblem8 = () => {
        setWash2IsPloblem8(!wash2IsProblem8)
    }

    const handleWash2IsProblem9 = () => {
        setWash2IsPloblem9(!wash2IsProblem9)
    }


    const validateForm = () => {
        /* if(useCoinFrom == 1) {
            if(offerUseCoin > offerFreeCoin) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if (useCoinFrom == 2) {
            if(offerUseCoin > offerExchangeCoin) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } */

        if(washType1) {
            if(!brandId1 || brandId1 == '0') {
                setBrandIdErrorDiv1(true)
                return false
            } else {
                if(brandId1 == '999') {
                    if(!brand1) {
                        setBrandErrorDiv1(true)
                        return false
                    } else {
                        setBrandErrorDiv1(false)
                        setBrandIdErrorDiv1(false)
                    }
                } else {
                    setBrandErrorDiv1(false)
                    setBrandIdErrorDiv1(false)
                }
            }
    
            if(!modelId1) {
                setModelIdErrorDiv1(true)
                return false
            } else {
                if(modelId1 == '999' || modelId1 == '0') {
                    if(!model1) {
                        setModelErrorDiv1(true)
                        return false
                    } else {
                        setModelErrorDiv1(false)
                        setModelIdErrorDiv1(false)
                    }
                } else {
                    setModelErrorDiv1(false)
                    setModelIdErrorDiv1(false)
                }
            }

            if(!wash1Amount || !isOnlyNumeric(wash1Amount)) {
                setWash1AmountErrorDiv(true)
                return false
            } else {
                setWash1AmountErrorDiv(false)
            }

            if(!price1 || !isOnlyNumeric(price1)) {
                setPrice1ErrorDiv(true)
                return false
            } else {
                setPrice1ErrorDiv(false)
            }

            if(!imageSize1Error) {
                var numPic1 = 0
                wash1Images.forEach((image) => {
                    if(image.status != 0) {
                        numPic1++;
                    }
                })
                if(numPic1 > 0) {
                    setPictureWash1ErrorDiv(false)
                } else {
                    setPictureWash1ErrorDiv(true)
                    return false;
                }
            } else {
                return false
            }
        }

        if(washType2) {
            if(!brandId2 || brandId2 == '0') {
                setBrandIdErrorDiv2(true)
                return false
            } else {
                if(brandId2 == '999') {
                    if(!brand2) {
                        setBrandErrorDiv2(true)
                        return false
                    } else {
                        setBrandErrorDiv2(false)
                        setBrandIdErrorDiv2(false)
                    }
                } else {
                    setBrandErrorDiv2(false)
                    setBrandIdErrorDiv2(false)
                }
            }
    
            if(!modelId2) {
                setModelIdErrorDiv2(true)
                return false
            } else {
                if(modelId2 == '999' || modelId2 == '0') {
                    if(!model2) {
                        setModelErrorDiv2(true)
                        return false
                    } else {
                        setModelErrorDiv2(false)
                        setModelIdErrorDiv2(false)
                    }
                } else {
                    setModelErrorDiv2(false)
                    setModelIdErrorDiv2(false)
                }
            }

            if(!wash2Amount || !isOnlyNumeric(wash2Amount)) {
                setWash2AmountErrorDiv(true)
                return false
            } else {
                setWash2AmountErrorDiv(false)
            }

            if(!price2 || !isOnlyNumeric(price2)) {
                setPrice2ErrorDiv(true)
                return false
            } else {
                setPrice2ErrorDiv(false)
            }

            if(!imageSize2Error) {
                var numPic2 = 0
                wash2Images.forEach((image) => {
                    if(image.status != 0) {
                        numPic2++;
                    }
                })
                if(numPic2 > 0) {
                    setPictureWash2ErrorDiv(false)
                } else {
                    setPictureWash2ErrorDiv(true)
                    return false;
                }
            } else {
                return false
            }
        }

        if(provinceId == 0) {
            setProvinceIdErrorDiv(true)
            return false
        } else {
            setProvinceIdErrorDiv(false)
        }

        if(districtId == 0) {
            setDistrictIdErrorDiv(true)
            return false
        } else {
            setDistrictIdErrorDiv(false)
        }

        if(subdistrictId == 0) {
            setSubDistrictErrorDiv(true)
            return false
        } else {
            setSubDistrictErrorDiv(false)
        }

        return true
    }

    const handleSubmit = (e) => {
    	e.preventDefault()
        if(validateForm()) {
            const new_date = new Date()
            setShowProgressBar(true)
            updateDoc(doc(db, 'cleans', postId), {
                pick_date: pickDate.toDate(),
                hour: hour,
                minute: minute,
                
                wash_type1: washType1,
                wash1_brand_id: brandId1,
                wash1_brand: brand1,
                wash1_model_id: modelId1,
                wash1_model: model1,
                wash1_capacity: wash1Capacity,
                wash1_amount: wash1Amount*1,
                washing_type1: washingType1,
                price_1: price1,
                wash1_is_problem1: wash1IsProblem1,
                wash1_is_problem2: wash1IsProblem2,
                wash1_is_problem3: wash1IsProblem3,
                wash1_is_problem4: wash1IsProblem4,
                wash1_is_problem5: wash1IsProblem5,
                wash1_is_problem6: wash1IsProblem6,
                wash1_is_problem7: wash1IsProblem7,
                wash1_is_problem8: wash1IsProblem8,
                wash1_is_problem9: wash1IsProblem9,
                
                wash_type2: washType2,
                wash2_brand_id: brandId2,
                wash2_brand: brand2,
                wash2_model_id: modelId2,
                wash2_model: model2,
                wash2_capacity: wash2Capacity,
                wash2_amount: wash2Amount*1,
                washing_type2: washingType2,
                price_2: price2,
                wash2_is_problem1: wash2IsProblem1,
                wash2_is_problem2: wash2IsProblem2,
                wash2_is_problem3: wash2IsProblem3,
                wash2_is_problem4: wash2IsProblem4,
                wash2_is_problem5: wash2IsProblem5,
                wash2_is_problem6: wash2IsProblem6,
                wash2_is_problem7: wash2IsProblem7,
                wash2_is_problem8: wash2IsProblem8,
                wash2_is_problem9: wash2IsProblem9,

                lat: lat,
                lng: lng,
                address: address,
                address_no: addressNo,
                road: road,
                subroad: subroad,
                village: village,
                building: building,
                floor: floor,
                room: room,
                sub_district: subdistrictId,
                district: districtId,
                province: provinceId,
                postcode: postcode,
                status: 1,
            }).then(() => {
                const promises = []
                var vari = 1
                wash1Images.forEach((image, i) => {
                    if(image.status == 0) {
                        if(image.id != "") {
                            deleteDoc(doc(db, `cleans/${postId}/images1`, image.id));
                        }
                    } else if(image.status == 2) {
                        const storageRef = ref(storage, `/cleans/${postId}/wash1/${i+1}`)
                        const uploadTask = uploadBytesResumable(storageRef, image.file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    if(image.id == "") {
                                        addDoc(collection(db, `/cleans/${postId}/images1`), {
                                            url: downloadURLs,
                                        })
                                    } else {
                                        updateDoc(doc(db, `/cleans/${postId}/images1`, image.id), {
                                            url: downloadURLs
                                        })
                                    }
                                    if(i+1 == 1) {
                                        updateDoc(doc(db, 'cleans', postId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        vari++
                    }
                })
                vari = 1
                wash2Images.forEach((image, i) => {
                    if(image.status == 0) {
                        if(image.id != "") {
                            deleteDoc(doc(db, `cleans/${postId}/images2`, image.id));
                        }
                    } else if(image.status == 2) {
                        const storageRef = ref(storage, `/cleans/${postId}/wash2/${i+1}`)
                        const uploadTask = uploadBytesResumable(storageRef, image.file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    if(image.id == "") {
                                        addDoc(collection(db, `/cleans/${postId}/images2`), {
                                            url: downloadURLs,
                                        })
                                    } else {
                                        updateDoc(doc(db, `/cleans/${postId}/images2`, image.id), {
                                            url: downloadURLs
                                        })
                                    }
                                    if(i+1 == 1) {
                                        updateDoc(doc(db, 'cleans', postId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        vari++
                    }
                })

                Promise.all(promises).then(() => {
                    setShowProgressBar(false)
                    navigate("/profile/posts/wash")
                })
            }).catch(function(error) {
                console.error("Error adding document: ", error)
            })
        }
    }

    const getPost = () => {
        setShowLoading(true)
        getDoc(doc(db, 'cleans', postId)).then((postDoc) => {
            if (postDoc.exists()) {
                setOnBoard(postDoc.data().onboard)
                setStatus(postDoc.data().status)
                if(postDoc.data().wash_type1) {
                    setWashType1(postDoc.data().wash_type1)
                    setBrandId1(postDoc.data().wash1_brand_id)
                    setBrand1(postDoc.data().wash1_brand)
                    setModelId1(postDoc.data().wash1_model_id)
                    setModel1(postDoc.data().wash1_model)
                    setWash1Capacity(postDoc.data().wash1_capacity)
                    setWash1Amount(postDoc.data().wash1_amount)
                    setWashingType1(postDoc.data().washing_type1)
                    setPrice1(postDoc.data().price_1)
                    setWash1IsPloblem1(postDoc.data().wash1_is_problem1)
                    setWash1IsPloblem2(postDoc.data().wash1_is_problem2)
                    setWash1IsPloblem3(postDoc.data().wash1_is_problem3)
                    setWash1IsPloblem4(postDoc.data().wash1_is_problem4)
                    setWash1IsPloblem5(postDoc.data().wash1_is_problem5)
                    setWash1IsPloblem6(postDoc.data().wash1_is_problem6)
                    setWash1IsPloblem7(postDoc.data().wash1_is_problem7)
                    setWash1IsPloblem8(postDoc.data().wash1_is_problem8)
                    setWash1IsPloblem9(postDoc.data().wash1_is_problem9)

                    const imagesQuery = query(collection(db, `cleans/${postId}/images1`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setWash1Images(querySnapshot.docs.map((doc, i) => { 
                            return {
                                id: doc.id,
                                url: doc.data().url,
                                file: "",
                                status: 1
                            }
                        }))
                    })
                }
                if(postDoc.data().wash_type2) {
                    setWashType2(postDoc.data().wash_type2)
                    setBrandId2(postDoc.data().wash2_brand_id)
                    setBrand2(postDoc.data().wash2_brand)
                    setModelId2(postDoc.data().wash2_model_id)
                    setModel2(postDoc.data().wash2_model)
                    setWash2Capacity(postDoc.data().wash2_capacity)
                    setWash2Amount(postDoc.data().wash2_amount)
                    setWashingType2(postDoc.data().washing_type2)
                    setPrice2(postDoc.data().price_2)
                    setWash2IsPloblem1(postDoc.data().wash2_is_problem1)
                    setWash2IsPloblem2(postDoc.data().wash2_is_problem2)
                    setWash2IsPloblem3(postDoc.data().wash2_is_problem3)
                    setWash2IsPloblem4(postDoc.data().wash2_is_problem4)
                    setWash2IsPloblem5(postDoc.data().wash2_is_problem5)
                    setWash2IsPloblem6(postDoc.data().wash2_is_problem6)
                    setWash2IsPloblem7(postDoc.data().wash2_is_problem7)
                    setWash2IsPloblem8(postDoc.data().wash2_is_problem8)
                    setWash2IsPloblem9(postDoc.data().wash2_is_problem9)

                    const imagesQuery = query(collection(db, `cleans/${postId}/images2`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setWash2Images(querySnapshot.docs.map((doc, i) => { 
                            return {
                                id: doc.id,
                                url: doc.data().url,
                                file: "",
                                status: 1
                            }
                        }))
                    })
                }

                setLat(postDoc.data().lat)
                setLng(postDoc.data().lng)
                setAddress(postDoc.data().address)
                setAddressNo(postDoc.data().address_no)
                setVillage(postDoc.data().village)
                setBuilding(postDoc.data().building)
                setFloor(postDoc.data().floor)
                setRoom(postDoc.data().room)
                setSubroad(postDoc.data().subroad)
                setRoad(postDoc.data().road)
                var promisesTask = []

                if(postDoc.data().province) {
                    setProvinceId(postDoc.data().province)
                    const districtTask = getDocs(query(collection(db, 'distrincts'), where('province_id', '==', postDoc.data().province * 1))).then((docs) => {
                        setDistrictList(docs.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })))
                    })
                    promisesTask.push(districtTask)
                }

                if(postDoc.data().district) {
                    setDistrictId(postDoc.data().district)
                    const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', postDoc.data().district))).then((docs) => {
                        setSubDistrictList(docs.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })))
                    })
                    promisesTask.push(subdistrictTask)
                }
                if(postDoc.data().sub_district) { setSubDistrictId(postDoc.data().sub_district) }
                if(postDoc.data().postcode) { setPostcode(postDoc.data().postcode) }
                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                })
            } else {
                console.log("Post doesn't exist")
                navigate('/')
            }
        })
    }

    useEffect(() => {
        if(wash1Images.length < 5) {
            setWash1Images([...wash1Images, { id: "", url: addImage, file: "", status: 0 }])
        }
    }, [wash1Images])

    useEffect(() => {
        if(wash1Images.length < 5) {
            setWash2Images([...wash1Images, { id: "", url: addImage, file: "", status: 0 }])
        }
    }, [wash1Images])

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMember(data)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)
                if(data.address) { setAddressNoRegis(data.address) }
                if(data.village) { setVillageRegis(data.village) }
                if(data.building) { setBuildingRegis(data.building) }
                if(data.floor) { setFloorRegis(data.floor) }
                if(data.room) { setRoomRegis(data.room) }
                if(data.subroad) { setSubroadRegis(data.subroad); }
                if(data.road) { setRoadRegis(data.road) }
                if(data.province) { setProvinceIdRegis(data.province) }
                if(data.district) { setDistrictIdRegis(data.district); }
                if(data.sub_district) { setSubDistrictIdRegis(data.sub_district) }
                setPostcodeRegis(data.postcode)
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    const deletePost = async () => {
        var promisesAuth = []
        const authTask = await deleteDoc(doc(db, `cleans`, postId))
        promisesAuth.push(authTask)
        Promise.all(promisesAuth).then(() => {
            navigate("/profile/posts/wash")
        })
    }

    const handleCloseAlertDelete = () => {
        setOpenAlertDelete(false)
    }

    const getUseRegisAddress = () => {
        var promisesTask = []

        const districtTask = getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceIdRegis * 1))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        promisesTask.push(districtTask)

        const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtIdRegis))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        promisesTask.push(subdistrictTask)
        Promise.all(promisesTask).then(() => {
            setAddressNo(addressNoRegis)
            setVillage(villageRegis)
            setBuilding(buildingRegis)
            setFloor(floorRegis)
            setRoom(roomRegis)
            setSubroad(subroadRegis)
            setRoad(roadRegis)
            setProvinceId(provinceIdRegis)
            setDistrictId(districtIdRegis)
            setSubDistrictId(subdistrictIdRegis)
            setPostcode(postcodeRegis)
        })
    }

    const handleSelectProvince = (provinceId) => {
        setProvinceId(provinceId)

        setDistrictId(0)
        districtList.splice(0, districtList.length)
        setDistrictList([...districtList])

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceId*1), orderBy('province_id', 'asc'))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectDistrict = (districtId) => {
        setDistrictId(districtId)

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtId), orderBy('district_id', 'asc'))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handelSelectsubDistrict = (subdistrictId) => {
        setSubDistrictId(subdistrictId)
        setPostcode('')
        getDocs(query(collection(db, 'postcodes'), where('subdistrict_id', '==', subdistrictId*1))).then((docs) => {
            setPostcode(docs.docs[0].data().postcode)
        })
    }

    const getProvince = () => {
        getDocs(query(collection(db, 'provinces'), orderBy('province_id', 'asc'))).then((docs) => {
            setProvinceList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    const getBrand = () => {
        getDocs(query(collection(db, 'wash_brands'))).then((docs) => {
            setBrandList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectBrand1 = (brandId) => {
        setBrandId1(brandId)
        getDocs(query(collection(db, 'wash_models'), where('wash_brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList1(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleSelectBrand2 = (brandId) => {
        setBrandId2(brandId)
        getDocs(query(collection(db, 'wash_models'), where('wash_brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList2(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleImageWash1 = (file, i) => {
        let items = [...wash1Images]
        let item = {...items[i]}
        item.url = URL.createObjectURL(file)
        item.file = file
        item.status = 2
        items[i] = item
        setWash1Images(items)
    }

    const deleteImageWash1 = (i) => {
        let items = [...wash1Images]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setWash1Images(items)
    }

    const handleImageWash2 = (file, i) => {
        let items = [...wash2Images]
        let item = {...items[i]}
        item.url = URL.createObjectURL(file)
        item.file = file
        item.status = 2
        items[i] = item
        setWash2Images(items)
    }

    const deleteImageWash2 = (i) => {
        let items = [...wash2Images]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setWash2Images(items)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/") 
        else  {
            getPost()
            getDocUser()
            getProvince()
            getBrand()
        }
    }, [user, loading])

    return (
    <>
        <Loading open={showLoading} text={"กำลังทำเรียกข้อมูล กรุณารอสักครู่"} />
        <Loading open={showProgressBar} text={"กำลังทำแก้ไขประกาศ กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 280 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenuPost currentPage="post" isShop={isShop} postType={6} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 10, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <h4 className='d-block d-md-none d-lg-none'>
                            <b>แก้ไขประกาศ {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} onClick={toggleDrawer(true)} >แจ้งล้างเครื่องซักผ้า<IoMdMenu style={{ fontSize: 25, marginLeft: 5, marginBottom: 3 }} /></font></b>
                        </h4>
                        <h4 className='d-none d-md-block d-lg-block'>
                            <b>แก้ไขประกาศ {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} >แจ้งล้างเครื่องซักผ้า</font></b>
                        </h4>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenuPost currentPage="post" isShop={isShop} postType={6} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div className="flex-container" style={{ marginTop: 5, marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <h4 style={{ display: 'flex', flex: 1 }}>
                                    <AiFillEdit style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                    <font style={{ fontSize: 20 }}>แก้ไขข้อมูลประกาศ</font>
                                </h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/profile/posts/wash`) }}>
                                        <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDelete(true) }}>
                                        <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบประกาศ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}><h6><strong>วันเวลาที่ต้องการใช้บริการ</strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'row' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker slotProps={{ textField: { size: 'small' } }} format="DD/MM/YYYY" minDate={today} defaultValue={today} onChange={(value) => { setPickDate(value)} } />
                                    </LocalizationProvider>
                                    <select name='hour' onChange={(e) => setHour(e.target.value)} style={{ width: 70, marginLeft: 10 }}>
                                        <option value="00">00</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                    </select>
                                    <select name='minute' onChange={(e) => setMinute(e.target.value)} style={{ width: 70, marginLeft: 5 }}>
                                        <option value="00">00</option>
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="45">45</option>
                                    </select>
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <font style={{ fontSize: 18 }}><input type="checkbox" checked={washType1?"checked":""} onChange={(e) => handleWashType1Change(e.target.value)} /> บริการล้างเครื่องซักผ้า<strong>ฝาบน</strong></font>
                            </div>
                            {washType1 && (<>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ยี่ห้อเครื่องซักผ้า <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='brand_id' value={brandId1} onChange={(e) => { handleSelectBrand1(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                            {brandList?.map((brand, i) => (
                                            <option value={brand.id} key={brand.id}>
                                                {brand.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {brandIdErrorDiv1 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกยี่ห้อ</strong>
                                        </div>
                                        }
                                    </div>
                                    {brandId1 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setBrand1(e.target.value)} 
                                        value={brand1} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {brandErrorDiv1 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกยี่ห้อ</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>รุ่นเครื่องซักผ้า <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                        <select name='model_id' value={modelId1} onChange={(e) => { setModelId1(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                            <option value="0">--- กรุณารุ่น ---</option>
                                            {modelList1?.map((model, i) => (
                                            <option value={model.id} key={model.id}>
                                                {model.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {modelIdErrorDiv1 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกรุ่น</strong>
                                        </div>
                                        }
                                    </div>
                                    {modelId1 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setModel1(e.target.value)} 
                                        value={model1} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {modelErrorDiv1 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกรุ่น</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='wash1Amount' onChange={(e) => setWash1Amount(e.target.value)} 
                                        value={wash1Amount} placeholder='จำนวน (เครื่อง)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                    {wash1AmountErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกจำนวน (เครื่อง) และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ความจุ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <select name='wash1Capacity' onChange={(e) => { setWash1Capacity(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="1">ความจุ ไม่เกิน 15 กก.</option>
                                        <option value="2">ความจุ 15 - 19 กก.</option>
                                        <option value="3">ความจุ 19 - 22กก.</option>
                                        <option value="4">ความจุ 22 กก. ขึ้นไป</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ต้องการล้างแบบ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <select name='washingType1' onChange={(e) => { setWashingType1(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="1">ถอดถังซัก</option>
                                        <option value="2">ไม่ถอดถังซัก</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ราคาล้างไม่เกิน (บาท) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='price1' onChange={(e) => setPrice1(e.target.value)} 
                                        value={price1} placeholder='ราคาล้างไม่เกิน (บาท)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize: 17 }} />
                                    {price1ErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกราคาล้าง และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>เครื่องซักผ้ามีอาการดังต่อไปนี้</strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash1IsProblem1?"checked":""} onChange={(e) => handleWash1IsProblem1(e.target.value)} /> ถ่ายน้ำไม่ออก</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash1IsProblem2?"checked":""} onChange={(e) => handleWash1IsProblem2(e.target.value)} /> ไม่ปั่น ปั่นไม่แห้งไม่หมาด</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash1IsProblem3?"checked":""} onChange={(e) => handleWash1IsProblem3(e.target.value)} /> เครื่องปั่นมีเสียงดัง</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash1IsProblem4?"checked":""} onChange={(e) => handleWash1IsProblem4(e.target.value)} /> เครื่องปั่นไม่หยุด</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash1IsProblem5?"checked":""} onChange={(e) => handleWash1IsProblem5(e.target.value)} /> แกนหัก</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash1IsProblem6?"checked":""} onChange={(e) => handleWash1IsProblem6(e.target.value)} /> ไม่ระบายน้ำทิ้ง</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash1IsProblem7?"checked":""} onChange={(e) => handleWash1IsProblem7(e.target.value)} /> เครื่องไม่ทำงาน</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash1IsProblem8?"checked":""} onChange={(e) => handleWash1IsProblem8(e.target.value)} /> น้ำรั่วจากเครื่อง</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash1IsProblem9?"checked":""} onChange={(e) => handleWash1IsProblem9(e.target.value)} /> เครื่องไม่ผสมผงซักฟอก</font></label>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, flex: 3 }}><h6><strong>รูปตำแหน่งที่ตั้ง และสภาพภายในและภายนอกของเครื่องซักผ้า <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {wash1Images?.map((image, i) => (
                                        <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                            <img src={image.url} alt="WEEE" width="100" style={{marginBottom: 5}} />
                                            <div style={{ display: "flex", flexDirection:'row'}}>
                                                <label htmlFor={"fileUpload"+i} style={{ padding: 0}}>
                                                    {(image.status!=0)?
                                                    (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                                                    (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                                                    }
                                                </label>
                                                {(image.status!=0) &&
                                                (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImageWash1(i) }}>
                                                    <IoCloseCircle style={{ fontSize: 20 }} />
                                                </button>)
                                                }
                                            </div>
                                            <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImageWash1(e.target.files[0], i)} />
                                        </div>
                                        ))}
                                    </div>
                                    {imageSize1Error &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                    }
                                    {pictureWash1ErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณาเลือกรูปภาพ</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            </>)}

                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <font style={{ fontSize: 18 }}><input type="checkbox" checked={washType2?"checked":""} onChange={(e) => handleWashType2Change(e.target.value)} /> บริการล้างเครื่องซักผ้า<strong>ฝาหน้า</strong></font>
                            </div>
                            {washType2 && (<>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ยี่ห้อเครื่องซักผ้า <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='brand_id' value={brandId2} onChange={(e) => { handleSelectBrand2(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                            {brandList?.map((brand, i) => (
                                            <option value={brand.id} key={brand.id}>
                                                {brand.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {brandIdErrorDiv1 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกยี่ห้อ</strong>
                                        </div>
                                        }
                                    </div>
                                    {brandId2 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setBrand2(e.target.value)} 
                                        value={brand2} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {brandErrorDiv2 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกยี่ห้อ</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>รุ่นเครื่องซักผ้า <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                        <select name='model_id' value={modelId2} onChange={(e) => { setModelId2(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                            <option value="0">--- กรุณารุ่น ---</option>
                                            {modelList2?.map((model, i) => (
                                            <option value={model.id} key={model.id}>
                                                {model.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {modelIdErrorDiv2 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกรุ่น</strong>
                                        </div>
                                        }
                                    </div>
                                    {modelId2 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setModel1(e.target.value)} 
                                        value={model1} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {modelErrorDiv2 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกรุ่น</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='wash2Amount' onChange={(e) => setWash2Amount(e.target.value)} 
                                        value={wash2Amount} placeholder='จำนวน (เครื่อง)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                    {wash2AmountErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกจำนวน (เครื่อง) และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ความจุ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <select name='wash2Capacity' onChange={(e) => { setWash2Capacity(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="1">ความจุ ไม่เกิน 15 กก.</option>
                                        <option value="2">ความจุ 15 - 19 กก.</option>
                                        <option value="3">ความจุ 19 - 22กก.</option>
                                        <option value="4">ความจุ 22 กก. ขึ้นไป</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ต้องการล้างแบบ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <select name='washingType2' onChange={(e) => { setWashingType2(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="1">ถอดถังซัก</option>
                                        <option value="2">ไม่ถอดถังซัก</option>
                                    </select>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ราคาล้างไม่เกิน (บาท) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='price2' onChange={(e) => setPrice2(e.target.value)} 
                                        value={price2} placeholder='ราคาล้างไม่เกิน (บาท)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize: 17 }} />
                                    {price2ErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกราคาล้าง และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>เครื่องซักผ้ามีอาการดังต่อไปนี้</strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash2IsProblem1?"checked":""} onChange={(e) => handleWash2IsProblem1(e.target.value)} /> ถ่ายน้ำไม่ออก</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash2IsProblem2?"checked":""} onChange={(e) => handleWash2IsProblem2(e.target.value)} /> ไม่ปั่น ปั่นไม่แห้งไม่หมาด</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash2IsProblem3?"checked":""} onChange={(e) => handleWash2IsProblem3(e.target.value)} /> เครื่องปั่นมีเสียงดัง</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash2IsProblem4?"checked":""} onChange={(e) => handleWash2IsProblem4(e.target.value)} /> เครื่องปั่นไม่หยุด</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash2IsProblem5?"checked":""} onChange={(e) => handleWash2IsProblem5(e.target.value)} /> แกนหัก</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash2IsProblem6?"checked":""} onChange={(e) => handleWash2IsProblem6(e.target.value)} /> ไม่ระบายน้ำทิ้ง</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash2IsProblem7?"checked":""} onChange={(e) => handleWash2IsProblem7(e.target.value)} /> เครื่องไม่ทำงาน</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash2IsProblem8?"checked":""} onChange={(e) => handleWash2IsProblem8(e.target.value)} /> น้ำรั่วจากเครื่อง</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={wash2IsProblem9?"checked":""} onChange={(e) => handleWash2IsProblem9(e.target.value)} /> เครื่องไม่ผสมผงซักฟอก</font></label>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, flex: 3 }}><h6><strong>รูปตำแหน่งที่ตั้ง และสภาพภายในและภายนอกของเครื่องซักผ้า <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {wash2Images?.map((image, i) => (
                                        <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                            <img src={image.url} alt="WEEE" width="100" style={{marginBottom: 5}} />
                                            <div style={{ display: "flex", flexDirection:'row'}}>
                                                <label htmlFor={"fileUpload"+i} style={{ padding: 0}}>
                                                    {(image.status!=0)?
                                                    (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                                                    (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                                                    }
                                                </label>
                                                {(image.status!=0) &&
                                                (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImageWash2(i) }}>
                                                    <IoCloseCircle style={{ fontSize: 20 }} />
                                                </button>)
                                                }
                                            </div>
                                            <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImageWash2(e.target.files[0], i)} />
                                        </div>
                                        ))}
                                    </div>
                                    {imageSize2Error &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                    }
                                    {pictureWash2ErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณาเลือกรูปภาพ</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            </>)}

                            <div className="flex-container" style={{ display: 'flex', marginBottom: 10, marginTop: 10, height:400 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}><h6><strong>ตำแหน่งพิกัดที่พักอาศัย</strong></h6></label>
                                <div style={{ marginBottom: 1, flexDirection: 'column', display: 'flex', flexWrap: 'nowrap', height:400, flex: 9 }}>
                                    <div className='text-danger' style={{ marginBottom: 10, marginTop: 8, display: 'flex', flexWrap: 'nowrap' }}>
                                        ** ลาก <FaMapMarkerAlt className='text-danger' style={{ marginLeft: 5, marginRight: 5 }} /> Marker  เพื่อกำหนดตำแหน่ง
                                    </div>
                                    <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                                    <div className="mb-3 row" style={{ display: 'flex', height:400, flexDirection: 'column' }}>
                                        <div style={{  display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                            <GoogleMap mapContainerClassName="map-container" center={{ lat: lat, lng: lng }} zoom={17} >
                                                <MarkerF
                                                    draggable={true} position={center}
                                                    onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                                />
                                            </GoogleMap>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}> </label>
                                <div className="flex-container" style={{ marginBottom: 1, display: 'flex', flexWrap: 'nowrap', flex: 9 }}>
                                    <button style={{ textAlign: 'center', marginRight: 15, marginBottom: 5 }} className="btn btn-success" type='button' onClick={() => { handelGoToRegisPosition() }}>ไปยังตำแหน่งเริ่มต้น</button>
                                    <button style={{ textAlign: 'center', marginRight: 15, marginBottom: 5 }} className="btn btn-success" type='button' onClick={() => { handelGoToCurrentPosition() }}>ไปยังตำแหน่งปัจจุบัน</button>
                                </div>
                            </div>
                            <div className="" style={{ display: 'flex', marginTop: 10, flexDirection: 'column' }}>
                                <div  className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                    <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}><h6><strong>ที่อยู่</strong></h6></label>
                                    <div style={{ display: 'flex', flex: 9, width:"100%" }}>
                                        <button style={{ textAlign: 'center' }} className="btn btn-success" type='button' onClick={() => { getUseRegisAddress() }}>ใช้ที่อยู่เริ่มต้น</button>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                    <label className='d-none d-md-block d-lg-block' style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}>
                                        <h6><strong> </strong></h6>
                                    </label>
                                    <div style={{ display: 'flex', flexDirection:'column', flex: 9 }}>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', flexWrap: 'wrap' }}>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='addressNo' onChange={(e) => setAddressNo(e.target.value)} 
                                                value={addressNo} placeholder='บ้านเลขที่'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='village' onChange={(e) => setVillage(e.target.value)} 
                                                value={village} placeholder='หมู่บ้าน/สถานที่'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='room' onChange={(e) => setRoom(e.target.value)} 
                                                value={room} placeholder='ห้อง'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='floor' onChange={(e) => setFloor(e.target.value)} 
                                                value={floor} placeholder='ชั้น'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='building' onChange={(e) => setBuilding(e.target.value)} 
                                                value={building} placeholder='อาคาร'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='subroad' onChange={(e) => setSubroad(e.target.value)} 
                                                value={subroad} placeholder='ซอย'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                                value={road} placeholder='ถนน'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <select name='province' value={provinceId} onChange={(e) => { handleSelectProvince(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                <option value="0">--- กรุณาเลือกจังหวัด ---</option>
                                                {provinceList?.map((province, i) => (
                                                <option value={province.data.province_id} key={province.data.province_id}>
                                                    {province.data.province_name}
                                                </option>
                                                ))}
                                            </select>
                                            {provinceIdErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                                <strong>** กรุณาเลือกจังหวัด</strong>
                                            </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <select name='district' value={districtId} onChange={(e) => { handleSelectDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                <option value="0" key="0">--- กรุณาเลือกอำเภอ ---</option>
                                                {districtList?.map((district, i) => (
                                                <option value={district.data.district_id} key={district.data.district_id}>
                                                    {district.data.district_name}
                                                </option>
                                                ))}
                                            </select>
                                            {districtIdErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                                <strong>** กรุณาเลือกอำเภอ</strong>
                                            </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <select name='subdistrict' value={subdistrictId} onChange={(e) => { handelSelectsubDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                <option value="0">--- กรุณาเลือกตำบล ---</option>
                                                {subdistrictList?.map((subdistrict, i) => (
                                                <option value={subdistrict.data.subdistrict_id} key={subdistrict.data.subdistrict_id}>
                                                    {subdistrict.data.subdistrict_name}
                                                </option>
                                                ))}
                                            </select>
                                            {subdistrictIdErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                                <strong>** กรุณาเลือกตำบล</strong>
                                            </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='postcode' readOnly
                                                value={postcode} placeholder='รหัสไปรษณีย์'
                                                style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}><h6><strong>ที่อยู่เพิ่มเติม</strong></h6></label>
                                <div style={{ display: 'flex', flex: 9 }}>
                                    <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 10 }}
                                        onChange={(e) => setAddress(e.target.value)}
                                        placeholder='ที่อยู่เพิ่มเติม' 
                                        value={address}></textarea>
                                </div>
                            </div> 
                        </div>
                        <div className="flex-container d-block d-md-none d-lg-none" style={{ marginTop: 5, marginTop: 10, borderTop: '1px solid #cccccc', display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/profile/posts/wash`) }}>
                                        <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDelete(true) }}>
                                        <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบประกาศ
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog open={openAlertDelete} onClose={handleCloseAlertDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                        {"คุณต้องการลบประกาศนี้?"}
                    </DialogTitle>
                    <DialogActions>
                    <Button onClick={handleCloseAlertDelete} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                    <Button onClick={() => { deletePost() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    </>
    )
}

export default EditPostWash