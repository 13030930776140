import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, getDocs, updateDoc, doc, getDoc, onSnapshot, orderBy, where, deleteDoc } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom";
import { FiEdit } from "react-icons/fi";
import { IoCloseCircle, IoChevronBackCircleSharp } from "react-icons/io5"
import { AiFillShop, AiFillInfoCircle, AiOutlineWarning, AiOutlineCheckCircle } from "react-icons/ai"

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const Post = () => {
    const params = useParams();
    const postId = params.id
    const [post, setPost] = useState([])
    const [images, setImages] = useState([])
    const [offers, setOffers] = useState([])
    const [appointment, setAppointment] = useState([])
    const [status, setStatus] = useState(0)
    const [deleteOfferId, setDeleteOfferId] = useState(0)
    const [deleteShopId, setDeleteShopId] = useState(0)

    const navigate = useNavigate();

    const [openAlertDisable, setOpenAlertDisable] = useState(false);
    const [openAlertCancleDisable, setOpenAlertCancleDisable] = useState(false);
    const [openAlertDeleteOffer, setOpenAlertDeleteOffer] = useState(false);
    const [openAlertDeletePost, setOpenAlertDeletePost] = useState(false)

    const [lat, setLat] = useState(15.11745249400088);
    const [lng, setLng] = useState(104.90284046686465);
    const [loading, error] = useAuthState(auth);
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    });
    const center = { lat: lat, lng: lng };

    const dateFormat = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const textMonth = (month) => {
        var text = ""
        switch(month) {
            case "1": text = "มกราคม"; break;
            case "2": text = "กุมภาพันธ์"; break;
            case "3": text = "มีนาคม"; break;
            case "4": text = "เมษายน"; break;
            case "5": text = "พฤษภาคม"; break;
            case "6": text = "มิถุนายน"; break;
            case "7": text = "กรกฎาคม"; break;
            case "8": text = "สิงหาคม"; break;
            case "9": text = "กันยายน"; break;
            case "10": text = "ตุลาคม"; break;
            case "11": text = "พฤศจิกายน"; break;
            case "12": text = "ธันวาคม"; break;
        }
        return text
    }
 
    const dateFormatDate = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH");
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        const date_sprit = date_string.split('/')
        return date_sprit[0]+" "+textMonth(date_sprit[1])+" "+date_sprit[2]+" เวลา "+time[0]+":"+time[1]+" น."
    }

    const disablePost = () => {
        updateDoc(doc(db, `/posts/`, postId), { status: 5, before_disabled: status }).then(() => {
            setOpenAlertDisable(false)
            navigate(0)
        })
    }

    const handleCloseAlertDisable = () => {
        setOpenAlertDisable(false)
    }

    const cancleDisablePost = () => {
        updateDoc(doc(db, `/posts/`, postId), { status: post.before_disabled }).then(() => {
            setOpenAlertCancleDisable(false)
            navigate(0)
        })
    }

    const handleCloseAlertCancleDisable = () => {
        setOpenAlertDisable(false)
    }

    const deleteOffer = () => {
        getDocs(query(collection(db, `shops/${deleteShopId}/offers`), where('offer_id', '==', deleteOfferId))).then((offerSnapshot) => {
            offerSnapshot.forEach((offer) => {
                deleteDoc(doc(db, `shops/${deleteShopId}/offers`, offer.id))
            })
        }).then(() => {
            deleteDoc(doc(db, `posts/${postId}/offers`, deleteOfferId)).then(() => {
                navigate(0)
            })
        })
    }

    const handleCloseAlertDeleteOffer = () => {
        setOpenAlertDeleteOffer(false)
    }

    const deletePost = () => {
        getDocs(query(collection(db, `posts/${postId}/offers`))).then((offerSnapshot) => {
            offerSnapshot.forEach((offer) => {
                console.log(offer.id)
                getDocs(query(collection(db, `shops/${offer.data().shop_id}/offers`), where('offer_id', '==', offer.id))).then((shopSnapshot) => {
                    shopSnapshot.forEach((shopOffer) => {
                        console.log(offer.data().shop_id)
                        console.log(shopOffer.id)
                        deleteDoc(doc(db, `shops/${offer.data().shop_id}/offers`, shopOffer.id))
                    })
                })
            })
        }).then(() => {
            deleteDoc(doc(db, `posts`, postId)).then(() => {
                navigate(`/admin/posts/`)
            })
        })
    }

    const handleCloseAlertDeletePost = () => {
        setOpenAlertDeletePost(false)
    }

    const getShop = async(offer) => {
        const shopSnapshot = await getDoc(doc(db, 'shops', offer.data().shop_id))
        if(shopSnapshot.exists) {
            offers.push({
                id: offer.id,
                data: offer.data(),
                shopId: offer.data().shop_id,
                shop: shopSnapshot.data()
            })
            setOffers([...offers, {
                id: offer.id,
                data: offer.data(),
                shopId: offer.data().shop_id,
                shop: shopSnapshot.data()
            }])
        }
    }

    const getAppointment = async() => {
    }

    const getDocPost = async(id) => {
        await getDoc(doc(db, 'posts', id)).then((postSnapshot) => {
            setPost(postSnapshot.data());
            if(postSnapshot.data().status) setStatus(postSnapshot.data().status)
            if(postSnapshot.data().lat) setLat(postSnapshot.data().lat)
            if(postSnapshot.data().lng) setLng(postSnapshot.data().lng)
            if(postSnapshot.data().status == 4) {
                getDocs(query(collection(db, `appointments`), where('post_id', '==', postId))).then((appointmentSnapshot) => {
                    if(appointmentSnapshot.docs.length > 0) {
                        setAppointment({
                            id: appointmentSnapshot.docs[0].id,
                            data: appointmentSnapshot.docs[0].data()
                        })
                    }
                })
            }
        }).then(() => {
            const imagesSnapshot = query(collection(db, `posts/${postId}/images`))
            onSnapshot(imagesSnapshot, (querySnapshot) => {
                setImages(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        })
    }

    useEffect(() => {
        getDocPost(postId)
        var offerList = []
        const getOffers = async() => {
            const offerQuery = await getDocs(query(collection(db, `posts/${postId}/offers`), orderBy('offer_timestamp', 'asc')))
            offerQuery.forEach((offer) => {
                offerList.push(offer)
            })
        }
        getOffers().then(() => {
            setOffers([])
            offerList.forEach((offer) => {
                getShop(offer)
            })
        })
    },[loading])

    var offerKey = []
    var offerList = []
    offers.forEach(element => {
        if(offerKey.lastIndexOf(element.id) < 0) {
            offerKey.push(element.id)
            offerList.push(element)
        }
    });
    getAppointment()
    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>ข้อมูลประกาศ</b></h4>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/posts/`) }}>
                            <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(0) }}>
                            <FiEdit style={{ fontSize: 20, marginRight: 5 }} />ส่งข้อความหาผู้ประกาศ
                        </button>
                    </div>
                    {(post.status != 5) &&
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDisable(true) }}>
                            <AiOutlineWarning style={{ fontSize: 20, marginRight: 5 }} />ระงับประกาศ
                        </button>
                    </div>
                    }
                    {(post.status == 5) &&
                    (<><div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertCancleDisable(true) }}>
                            <AiOutlineCheckCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิกการระงับ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDeletePost(true) }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบประกาศ
                        </button>
                    </div></>)
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingTop: 10, flex: 4 }}>
                        <h4 style={{ display: 'flex', flexDirection: 'row'}}>
                            <div style={{ marginRight: 5 }}>
                            {(post.onboard == 1)&&(<span className="badge bg-warning" style={{ marginRight: 5 }}>ทิ้งซาก</span>)}
                            {(post.onboard == 2)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}>ขายซาก</span>)}
                            {(post.onboard == 3)&&(<span className="badge bg-success" style={{ marginRight: 5 }}>ขายมือสอง</span>)}
                            </div>
                            <div style={{ marginRight: 5 }}>
                            {(post.status == 1)&&(<span className="badge bg-success" style={{ marginRight: 5 }}>ปกติ</span>)}
                            {(post.status == 2)&&(<span className="badge bg-warning" style={{ marginRight: 5 }}>มีผู้เสนอราคา</span>)}
                            {(post.status == 3)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}>เลือกข้อเสนอแล้ว</span>)}
                            {(post.status == 4)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}>ตกลงนัดหมาย</span>)}
                            {(post.status == 5)&&(<span className="badge bg-warning" style={{ marginRight: 5 }}>ถูกระงับ</span>)}
                            </div>
                            <strong>{post.type} {post.brand} {post.model}</strong>
                        </h4>
                        {(post.onboard==2 || post.onboard==3) && 
                            (<div><h5><strong>ราคาประกาศ: ฿ {post.price}</strong></h5></div>)
                        }
                        {(appointment.id) && 
                            (<div><h5><strong>วันที่นัดหมาย:</strong> {dateFormat(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <AiFillInfoCircle style={{ fontSize: 25, marginBottom: 3 }} className='text-primary' /></h5></div>)
                        }
                        <div style={{ marginTop: 5 }}><h6><strong>ขนาด (กxยxส) :</strong> {post.width?post.width:"ไม่ระบุ"} x {post.length?post.length:"ไม่ระบุ"} x {post.height?post.height:"ไม่ระบุ"} (ซม.)</h6></div>
                        <div style={{ marginTop: 5 }}><h6><strong>น้ำหนัก :</strong> {post.weight?post.weight+" กิโลกรัม":"ไม่ระบุ"}</h6></div>
                        <div style={{ marginTop: 5 }}>
                            <h6 style={{ marginBottom: 10 }}><strong>รายละเอียดเพิ่มเติม :</strong></h6>
                            <h6 style={{ marginLeft: 10 }}>{post.description?post.description:"ไม่ระบุ"}</h6>
                        </div>
                        <div style={{ marginTop: 10 }}><h6><strong>วันเวลาที่สะดวก :</strong> {dateFormat(post.pick_date)} เวลา {post.hour}:{post.minute} น.</h6></div> 
                        <div style={{ marginTop: 10 }}><h6><strong>วันเวลาที่ลงประกาศ :</strong> {dateFormatDate(post.post_timestamp)}</h6></div> 
                        <div style={{ marginTop: 10 }}><h6><strong>ตำแหน่ง :</strong></h6></div>
                        <div style={{ marginTop: 5, height: 200 }}>
                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                            <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                <MarkerF
                                    draggable={false} position={{lat, lng}}
                                />
                            </GoogleMap>
                            )}
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <h6><strong>ที่อยู่เพิ่มเติม :</strong></h6>
                            <h6 style={{ marginLeft: 10 }}>{post.address?post.address:"ไม่ระบุ"}</h6>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 5, borderLeft: '1px solid #cccccc', marginLeft: 30}}>
                        {(offerList.length > 0) && 
                        (<div style={{ display:'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 20, paddingTop:10, textAlign: 'left' }}>
                        <h6><strong>ข้อเสนอ :</strong></h6>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {
                                offerList?.map((offer, i) => (
                                    <div className="card" style={{ width: "16rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}  key={offer.id}>
                                        <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                            <p className="card-text" style={{ marginBottom: 5 }}><strong>ร้านค้า:</strong> {(offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname} <AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} className='text-primary' /></p>
                                            {(post.onboard==2 || post.onboard==3) && 
                                                (<p className="card-text" style={{ marginBottom: 5 }}><strong>ราคาที่เสนอ:</strong> {offer.data.price} บาท</p>)
                                            }
                                            <p className="card-text" style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong> {dateFormat(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.</p>
                                            {(post.status==2 || post.status==5) && 
                                                (<div style={{ display: 'flex', flexDirection: 'row' }}><button style={{ marginRight: 10 }} type="button" className="btn btn-warning btn-sm" onClick={() => deleteOffer(postId, offer.id)}><strong>แจ้งเตือนผู้ประกาศ</strong></button>
                                                <button type="button" className="btn btn-danger btn-sm" onClick={() => { 
                                                    setDeleteOfferId(offer.id)
                                                    setDeleteShopId(offer.shopId)
                                                    setOpenAlertDeleteOffer(true) 
                                                }}><strong>ลบข้อเสนอ</strong></button></div>)
                                            }
                                        </div>
                                    </div>
                                ))
                            }
                        </div>
                        </div>)
                        }
                        <div style={{ marginLeft: 20, marginTop: 10 }}><h6><strong>รูปภาพ :</strong></h6></div>
                        <div style={{ display:'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 15, marginBottom: 10, paddingTop:10, paddingBottom: 10, textAlign: 'left' }}>
                        
                        {
                            images?.map((image, i) => (
                                <div key={image.id} style={{ margin: 10, minWidth: 100, maxWidth: 200}}>
                                    <img src={image.data.url} alt="WEEE" className='img-fluid' />
                                </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={openAlertDisable} onClose={handleCloseAlertDisable} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการระงับประกาศนี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDisable} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { disablePost() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertCancleDisable} onClose={handleCloseAlertCancleDisable} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการยกเลิกระงับประกาศนี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertCancleDisable} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { cancleDisablePost() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertDeleteOffer} onClose={handleCloseAlertDeleteOffer} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบข้อเสนอนี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDeleteOffer} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { deleteOffer() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertDeletePost} onClose={handleCloseAlertDeletePost} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบประกาาศนี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDeletePost} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { deletePost() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
        </div>
    )

}

export default Post;