import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, storage, auth } from "../../assets/js/firebase"
import { updateDoc, doc, getDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useNavigate, useParams } from "react-router-dom"
import { BsPersonVcard } from "react-icons/bs"
import { IoCloseCircle, IoCheckmarkCircleSharp, IoChevronBackCircleSharp } from "react-icons/io5"
import { RiAdminLine } from "react-icons/ri"

const EditAdmin = () => {
    const navigate = useNavigate()
    const params = useParams()

    const userId = params.id
    const inputRef = useRef(null)
    const [loading, error] = useAuthState(auth)
    const [user, setUser] = useState([])
    const [displayName, setDisplayName] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [telNo, setTelNo] = useState('')
    const [picture, setPicture] = useState('')

    const getDocUser = async(id) => {
        await getDoc(doc(db, 'admins', id)).then((userSnapshot) => {
            const data = userSnapshot.data()
            setUser(data)
            if(data.display_name) setDisplayName(data.display_name)
            if(data.firstname) setFirstname(data.firstname)
            if(data.lastname) setLastname(data.lastname)
            if(data.tel_no) setTelNo(data.tel_no)
        })
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()
		try {
            updateDoc(doc(db, 'admins', userId), {
                display_name: displayName,
                firstname: firstname,
                lastname: lastname,
                tel_no: telNo,
            }).then(() => {
                if (picture) {
                    const storageRef = ref(storage, `/admins/${userId}/image/idcard/${picture.name}`)
                    const uploadTask = uploadBytesResumable(storageRef, picture);
                    uploadTask.on(
                    "state_changed",
                    (snapshot) => { },
                    (err) => console.log(err),
                    () => { 
                        getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                            updateDoc(doc(db, 'admins', userId), {
                                id_card: url,
                            }).then(() => {
                                navigate(`/admin/user/view/${userId}`)
                            })
                        });
                    })
                } else {
                    navigate(`/admin/user/view/${userId}`)
                }
            }).then(() => {
                navigate(`/admin/user/view/${userId}`)
            })
		} catch (err) {
			alert(err)
		}

    }

    useEffect(() => {
        getDocUser(userId)
    },[loading])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><RiAdminLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>แก้ไขข้อมูลผูดูแลระบบ</b></h4>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/users/`) }}>
                            <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                            <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(0) }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, flex: 4 }}>
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>Email</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {user.email?user.email:""}
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อผู้ใช้</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='displayName' onChange={(e) => setDisplayName(e.target.value)} 
                                    value={displayName} placeholder='ชื่อผู้ใช้'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อ</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='firstname' onChange={(e) => setFirstname(e.target.value)} 
                                    value={firstname} placeholder='ชื่อ'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>นามสกุล</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='lastname' onChange={(e) => setLastname(e.target.value)} 
                                    value={lastname} placeholder='นามสกุล'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>เบอร์โทรศัพท์</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='tel_no' onChange={(e) => setTelNo(e.target.value)} 
                                    value={telNo} placeholder='เบอร์โทรศัพท์'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>สำเนาบัตรประชาชน</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {user.id_card?
                                    (<a href={user.id_card} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><BsPersonVcard style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>)
                                    :(<font className="text-danger">ไม่ระบุ</font>)
                                }
                                <input ref={inputRef} className="form-control file" id="idcard_image" type="file" accept="image/*" onChange={(e) => setPicture(e.target.files[0])} />
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditAdmin;