import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { useNavigate } from "react-router-dom"

const PostOnboard = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)

    useEffect(() => {
        if (loading) return;
    }, [user, loading])

return (
    <>
        <div style={{ backgroundColor: "#fcfcfc", padding: 20}}>
            <div className="container">
                <div className="row gy-3" style={{ marginTop: 40 }}>
                    <div className="col">
                        <h1 className="text-center text-danger"><b>ขออภัย</b></h1>
                        <h3 className="text-center" style={{ marginTop: 20 }}><b>กำลังอยู่ระหว่างดำเนินการพัฒนา</b></h3>
                        <h5 className="text-center" style={{ marginTop: 20 }}><button type="button" className="btn btn-outline-primary btn-lg text-start" onClick={() => { return navigate("/") }}>กลับหน้าหลัก</button></h5>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}

export default PostOnboard;