import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, orderBy, onSnapshot, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom";
import LeftMenu from './LeftMenu';

import { BsHourglass, BsCalendarCheck } from "react-icons/bs"
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai"

import { Button, Dialog, DialogActions, DialogTitle, DialogContent, DialogContentText } from '@mui/material';

const OffersConfirm = () => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);

    const [uid, setUid] = useState('');
    const [docId, setDocId] = useState('');
    const [isShop, setIsShopId] = useState(0);
    const [shopId, setShopId] = useState('');
    const [offers, setOffers] = useState([])
    const [memberId, setMenberId] = useState('')

    const [confirmPostId, setConfirmPostId] = useState('')
    const [confirmOfferId, setConfirmOfferId] = useState('')
    const [openAlertConfirm, setOpenAlertConfirm] = useState(false)
    const [openAlertCancle, setOpenAlertCancle] = useState(false)

    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [postOnboard, setPostOnboard] = useState(1)
    const [offerPrice, setOfferPrice] = useState(0)
    const [depositCoin, setDepositCoin] = useState(0)
    const [depositEnough, setDepositEnough] = useState(false)

    const [noticeAcceptMessage, setNoticeAcceptMessage] = useState('')
    const [noticeCancleMessage, setNoticeCancleMessage] = useState('')

    const [posts, setPosts] = useState([]);
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH");
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleCancleOffer = (postId) => {
        setConfirmPostId(postId)
        setOpenAlertCancle(true)
    }

    const handleCloseAlertCancle = () => {
        setOpenAlertCancle(false)
    }

    const handleConfirmOffer = (postId, offerId) => {
        setConfirmOfferId(offerId)
        setConfirmPostId(postId)

        getDoc(doc(db, `posts/${postId}/offers/`, offerId)).then((offerDoc) => {
            const offerData = offerDoc.data()
            getDoc(doc(db, 'posts', postId)).then((postDoc) => {
                const postData = postDoc.data()
                if(postData.onboard == 3) {
                    setPostOnboard(3)
                    const price = offerData.price*1
                    const depositPercent = Math.ceil((price*30)/100)
                    setOfferPrice(price)
                    getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeSnapshot) => {
                        const rate = exchangeSnapshot.data().money_to_coin_1*1
                        const depositCoin = Math.ceil(depositPercent/rate)
                        setDepositCoin(depositCoin)
                        if(depositCoin < offerExchangeCoin) setDepositEnough(true)
                    })
                }
                if(postData.onboard == 4) {
                    //setNoticeAcceptMessage('เมื่อยืนยันตัดสินใจซ่อม จะถือว่าท่านยินยอมตามข้อเสนอให้บริการซ่อม และราคาที่ผู้เสนอได้เสนอไว้ โดยไม่มีข้อโต้แย้งใดๆ')
                } else {
                    //setNoticeAcceptMessage('')
                }
            })
        })
        setOpenAlertConfirm(true)
    }

    const handleCloseAlertConfirm = () => {
        setOpenAlertConfirm(false)
    }

    const fetchPosts = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docUser) => {
            if (docUser.docs.length === 1) {
                const data = docUser.docs[0].data()
                setDocId(docUser.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShopId(data.is_shop)

                if(data.shop_id) {
                    setShopId(data.shop_id)
                    const queryOffer = query(collection(db, `shops/${data.shop_id}/offers`), where("status", "==", 2));
                    onSnapshot(queryOffer, (querySnapshot) => {
                        offers.splice(0, offers.length)
                        setOffers([...offers])
                        querySnapshot.forEach((offer) => {
                            getDoc(doc(db, 'posts', offer.data().post_id)).then((postDoc) => {
                                if (postDoc.exists()) {
                                    offers.push({
                                        id: offer.id,
                                        post_id: postDoc.id,
                                        data: offer.data(),
                                        post: postDoc.data()
                                    })
                                    setOffers([...offers])
                                } else {
                                    console.log("Post doesn't exist");
                                }
                            })
                        })
                    })
                }

                const postQuery = query(collection(db, 'posts'), 
                                            where('onboard', '==', 1), 
                                            where('poster_id', '==', docUser.docs[0].id), 
                                            orderBy('post_timestamp', 'desc'))
                onSnapshot(postQuery, (querySnapshot) => {
                    setPosts(querySnapshot.docs.map((doc) => { 
                        return {
                            id: doc.id,
                            data: doc.data(),
                        }
                    }))
                })
            }
        }).catch((error) => {
            alert(error.message);
        })
    }

    const cancleOffer = () => {
        getDocs(query(collection(db, `posts/${confirmPostId}/offers`))).then((offers) => {
            offers.forEach((offer) => {
                getDocs(query(collection(db, `/shops/${offer.data().shop_id}/offers`), where('post_id', '==', confirmPostId))).then((shopDocs) => {
                    shopDocs.forEach((docRef) => {
                        updateDoc(doc(db, `/shops/${offer.data().shop_id}/offers`, docRef.id), { status: 5 })
                    })
                }).then(() => {
                    addDoc(collection(db, `posts/${confirmPostId}/offer_history/`), {
                        hour: offer.data().hour,
                        message: offer.data().message,
                        minute: offer.data().minute,
                        offer_timestamp: offer.data().offer_timestamp,
                        pick_date: offer.data().pick_date,
                        shop_id: offer.data().shop_id,
                    }).then(() => {
                        deleteDoc(doc(db, `posts/${confirmPostId}/offers/`, offer.id))
                    })
                })
            })
        }).then(() => {
            const date = new Date()
            addDoc(collection(db, `shops/${shopId}/cancle_history/`), {
                date: date,
                post_id: confirmPostId,
                type: "offer",
                reason: ""
            })
        }).then(() => {
            updateDoc(doc(db, `/posts`, confirmPostId), { status: 1 }).then(() => {
                getDoc(doc(db, 'posts', confirmPostId)).then((postDoc) => {
                    const postData = postDoc.data();
                    const date = new Date()
                    var link = "/profile/appointments"
                    addDoc(collection(db, `/users/${postData.poster_id}/notifications`), {
                        date: date.getTime(),
                        message: "ผู้เสนอยกเลิกการนัดหมาย",
                        type: 'appointment',
                        link: link,
                        status: 0
                    })
                }).then(() => {
                    setOpenAlertCancle(false)
                })
            })
        }).catch((error) => {
            alert(error.message)
        })
    }

    const confirmOffer = () => {
        getDoc(doc(db, 'posts', confirmPostId)).then((postDoc) => {
            const postData = postDoc.data();
            getDoc(doc(db, `posts/${confirmPostId}/offers/`, confirmOfferId)).then((offerDoc) => {
                const offerData = offerDoc.data();
                const new_date = new Date()
                updateDoc(doc(db, `/posts`, confirmPostId), { status: 4 }).then(() => {
                    var depositCoinAppoint = 0
                    var appointmentType = "post"
                    if(postData.onboard == 3) {
                        depositCoinAppoint = depositCoin
                    }
                    if(postData.onboard == 4) {
                        appointmentType = "fix"
                    }
                    addDoc(collection(db, 'appointments'), {
                        post_id: confirmPostId,
                        offer_id: confirmOfferId,
                        user_id: postData.poster_id,
                        shop_id: offerData.shop_id,
                        appointment_date: offerData.pick_date,
                        appointment_hour: offerData.hour,
                        appointment_minute: offerData.minute,
                        appointment_timestamp: new_date.getTime(),
                        appointment_type: appointmentType,
                        deposit_coin: depositCoinAppoint,
                        status: 1
                    })
                }).then(() => {
                    getDocs(query(collection(db, `/shops/${offerData.shop_id}/offers`), where('offer_id', '==', confirmOfferId))).then((shopDocs) => {
                        shopDocs.forEach((docRef) => {
                            updateDoc(doc(db, `/shops/${offerData.shop_id}/offers`, docRef.id), { status: 3 })
                        })
                    })
                }).then(() => {
                    if(postData.onboard == 3) {
                        updateDoc(doc(db, `/shops`, shopId), {
                            exchange_coin: (offerExchangeCoin*1)-(depositCoin*1)
                        }).then(() => {
                            getDoc(doc(db, 'exchange_coins', 'sum_data')).then((sumDoc) => {
                                updateDoc(doc(db, 'exchange_coins', 'sum_data'), {
                                    used: (sumDoc.data().used*1) + (depositCoin*1),
                                    all: (sumDoc.data().all*1) + (depositCoin*1)
                                }).then(() => {
                                    const log_date = new Date()
                                    addDoc(collection(db, `/coins/used_log/transaction`), {
                                        date: log_date,
                                        coin: depositCoin,
                                        event: 'deposit 30%',
                                        post_id: confirmPostId,
                                        offer_id: confirmOfferId,
                                        shop_id: offerData.shop_id,
                                        coin_type: "exchanbge",
                                        user_id: memberId
                                    })
                                })
                            })
                        })
                    }
                }).then(() => {
                    updateDoc(doc(db, `/posts/${confirmPostId}/offers/`, confirmOfferId), { status: 3 })
                }).then(() => {
                    const date = new Date()
                    var link = "/profile/appointments"
                    addDoc(collection(db, `/users/${postData.poster_id}/notifications`), {
                        date: date.getTime(),
                        message: "การนัดหมายยืนยันแล้ว",
                        type: 'appointment',
                        link: link,
                        status: 0
                    })
                }).then(() => {
                    //navigate(0)
                    handleCloseAlertConfirm()
                })
            })
        }).catch((error) => {
            alert(error.message);
        })
    }

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMenberId(docs.docs[0].id)
                if(data.shop_id)
                getDoc(doc(db, `shops/`, data.shop_id)).then((shopDoc) => {
                    const dataShop = shopDoc.data()
                    if(dataShop.freecoin) setOfferFreeCoin(dataShop.freecoin)
                    if(dataShop.exchange_coin) setOfferExchangeCoin(dataShop.exchange_coin)
                })
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else {
            fetchPosts()
            getDocUser()
        }
    }, [user, loading]);

    return (
    <>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 20}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div style={{ paddingLeft: 20, marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ข้อเสนอของฉัน</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <LeftMenu currentPage="offers" />
                    <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '1px solid #cccccc', paddingLeft: 20, flex: 1 }}>
                        <div style={{ borderBottom: '1px solid #cccccc', paddingLeft: 20 }}>
                            <ul className="nav nav-underline">
                                <li className="nav-item">
                                    <a className="nav-link"href="/shop/offers/"><BsHourglass style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />รอการยืนยัน</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page"  href="/shop/offers/confirm"><AiOutlineCheckCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ยืนยัน</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/shop/offers/cancle"><AiOutlineCloseCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ยกเลิก</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/shop/appointments"><BsCalendarCheck style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />นัดหมาย</a>
                                </li>
                            </ul>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 15}}>
                        {
                            offers?.map((offer, i) => (
                            <div className="card" style={{ width: "21rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}  key={offer.post_id}>
                                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                    <img src={offer.post.picture} alt="WEEE" height="200" />
                                </div>
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                    <div className="card-text">
                                        <h5><strong>{offer.post.type} {offer.post.brand} {offer.post.model}</strong></h5>
                                        {offer.post.onboard == 4 &&
                                        (<>
                                        <strong>ราคาซ่อม:</strong> {offer.data.offer_price} บาท <br/>
                                        <strong>อาการ:</strong> {offer.post.description} <br/>
                                        </>)
                                        }
                                        <strong>วันที่ยื่นข้อเสนอ:</strong> {dateFormat(offer.data.offer_timestamp)} <br/>
                                        <strong>วันที่นัดหมาย:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.
                                    </div>
                                </div>
                                <div className="card-body">
                                    {offer.post.onboard == 4 &&
                                        (<>
                                        <Link className='btn btn-primary m-1' to={`/postFix/${offer.post_id}`} target='_blank'><strong>รายละเอียด</strong></Link>
                                        <button type="button" className="btn btn-success m-1" onClick={() => handleConfirmOffer(offer.post_id, offer.data.offer_id)}><strong>ยืนยันการเลือก</strong></button>
                                        <button type="button" className="btn btn-danger m-1" onClick={() => handleCancleOffer(offer.post_id)}><strong>ยกเลิกการรับซ่อม</strong></button>
                                        </>)
                                    }
                                    {offer.post.onboard == 1 || offer.post.onboard == 2 || offer.post.onboard == 3 &&
                                        (<>
                                        <Link className='btn btn-primary m-1' to={`/post/${offer.post_id}`} target='_blank'><strong>รายละเอียด</strong></Link>
                                        <button type="button" className="btn btn-success m-1" onClick={() => handleConfirmOffer(offer.post_id, offer.data.offer_id)}><strong>ยืนยันการนัดหมาย</strong></button>
                                        <button type="button" className="btn btn-danger m-1" onClick={() => handleCancleOffer(offer.post_id)}><strong>ยกเลิกนัดหมาย</strong></button>
                                        </>)
                                    }
                                    
                                </div>
                            </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={openAlertConfirm} onClose={handleCloseAlertConfirm} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการยืนยันการนัดหมายนี้?"}
                </DialogTitle>
                <DialogContent>
                    {noticeAcceptMessage}
                </DialogContent>
                <DialogActions>
                    <Button variant="contained" color="error" onClick={handleCloseAlertConfirm} style={{ fontFamily: 'Prompt' }}>ปิดหน้าต่าง</Button>
                    <Button variant="contained" color="success" onClick={() => { confirmOffer() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยันการนัดหมาย</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertCancle} onClose={handleCloseAlertCancle} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการยกเลิกการนัดหมายนี้?"}
                </DialogTitle>
                <DialogContent>
                    {noticeCancleMessage}
                </DialogContent>
                <DialogActions>
                <Button variant="contained" color="error" onClick={handleCloseAlertCancle} style={{ fontFamily: 'Prompt' }}>ปิดหน้าต่าง</Button>
                <Button variant="contained" color="success" onClick={() => { cancleOffer() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยันการยกเลิก</Button>
                </DialogActions>
            </Dialog>
        </div>
    </>
    )
}

export default OffersConfirm;