import React, { useState, useEffect } from "react"
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, updateDoc, doc, limit } from 'firebase/firestore'
import { signOut } from "firebase/auth"
import { MdOutlineAccountCircle } from "react-icons/md"
import { RxAvatar } from 'react-icons/rx'
import { IoMegaphoneOutline } from "react-icons/io5"
import { IoMdLogOut } from "react-icons/io"
import { BsCalendarDate } from "react-icons/bs"
import { PiCoinsDuotone } from "react-icons/pi"
import { AiOutlinePlusCircle, AiOutlineShop } from 'react-icons/ai'
import { TbMessageCircle2, TbBellRinging } from 'react-icons/tb'
import { WiMoonAltNew } from "react-icons/wi"
import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2, TbSearch } from "react-icons/tb"
import { LuAirVent } from "react-icons/lu"
import { CgSmartHomeWashMachine } from "react-icons/cg"

import "../../assets/css/site.css"
import "../../assets/css/modal.css"
import logo from "../../assets/images/weee-logo.png"
import header_bg from "../../assets/images/header_bg4.png"

import { Button, Menu, MenuItem } from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'

const Header = () => {
    const [user, loading, error] = useAuthState(auth)
    const navigate = useNavigate()
    var logoHeight = 60

    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        logoHeight = 40
    } else if(window.innerWidth < 576) {
        logoHeight = 30
    }

    const [anchorEl, setAnchorEl] = useState(null)
    const [anchorElNoti, setAnchorElNoti] = useState(null)
    const [anchorElPost, setAnchorElPost] = useState(null)

    const openProfileMenu = Boolean(anchorEl)
    const openNotiMenu = Boolean(anchorElNoti)
    const openPostMenu = Boolean(anchorElPost)

    const [profile, setProfile] = useState([])
    const [displayName, setDisplayName] = useState('')
    const [showNoti, setShowNoti] = useState(false)
    const [notiUser, setNotiUser] = useState([])
    const [newNoti, setNewNoti] = useState(0)
    const [memberId, setMemberId] = useState('')
    const [shopId, setShopId] = useState('')

    const [userStatus, setUserStatus] = useState(1)

    const handleProfileMenu = (event) => {
        setAnchorEl(event.currentTarget)
    }

    const handleCloseProfileMenu = () => {
      setAnchorEl(null)
    }

    const handleNotiMenu = (event) => {
        setAnchorElNoti(event.currentTarget)
    }

    const handleCloseNotiMenu = () => {
        setAnchorElNoti(null)
    }

    const handlePostMenu = (event) => {
        setAnchorElPost(event.currentTarget)
    }

    const handleClosePostMenu = () => {
        setAnchorElPost(null)
    }

    const logout = () => {
        signOut(auth)
        navigate("/")
    }

    const readNoti = (noti) => {
        if(noti.type == 'user') {
            updateDoc(doc(db, `users/${memberId}/notifications/`, noti.id), { status: 1 }).then(() => {
                navigate(noti.link)
            })
        } else {
            updateDoc(doc(db, `shops/${shopId}/notifications/`, noti.id), { status: 1 }).then(() => {
                navigate(noti.link)
            })
        }
    }

    const getProfile = async () => {
        await getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if(docs.docs.length > 0) {
                setProfile(docs.docs[0].data())
                if(docs.docs[0].id) setMemberId(docs.docs[0].id)
                if(docs.docs[0].data().display_name) setDisplayName(docs.docs[0].data().display_name)
                if(docs.docs[0].data().status) setUserStatus(docs.docs[0].data().status)
                notiUser.splice(0, notiUser.length)
                setNotiUser([...notiUser])
                setNewNoti(0)
                getDocs(query(collection(db, `users/${docs.docs[0].id}/notifications/`), limit(3))).then((notiUserDocs) => {
                    if(notiUserDocs.docs.length > 0) {
                        setShowNoti(true)
                        notiUserDocs.forEach((noti) => {
                            if(noti.data().status == 0) setNewNoti(newNoti+1)
                            setNotiUser([...notiUser, {
                                id: noti.id,
                                date: noti.data().date,
                                message: noti.data().message,
                                status: noti.data().status,
                                link: noti.data().link,
                                type: 'user'
                            }])
                        })
                    }
                })
                if(docs.docs[0].data().is_shop) {
                    if(docs.docs[0].data().is_shop == 1) {
                        setShopId(docs.docs[0].data().shop_id)
                        getDocs(query(collection(db, `shops/${docs.docs[0].data().shop_id}/notifications/`), limit(3))).then((notiShopDocs) => {
                            if(notiShopDocs.docs.length > 0) {
                                setShowNoti(true)
                                notiShopDocs.forEach((noti) => {
                                    if(noti.data().status == '0') setNewNoti(newNoti+1)
                                    setNotiUser([...notiUser, {
                                        id: noti.id,
                                        date: noti.data().date,
                                        message: noti.data().message,
                                        status: noti.data().status,
                                        link: noti.data().link,
                                        type: 'shop'
                                    }])
                                })
                            }
                        })
                    }
                }
            } else {
            }
        }).then(() => {
            //setNewNoti(numNewNoti)
        })
    }

    useEffect(() => {
        if (loading) return;
        if (user) {
            getProfile()
        }
    }, [user, loading])

    return (
        <nav className="navbar navbar-expand-md navbar fixed-top shadow-sm" style={{ backgroundColor: '#FFFFFF' }}>
            <div className="container-fluid" style={{ minHeight: 90, display: 'flex', fleDirection: 'row', backgroundImage: `url(${header_bg})`, backgroundSize: 'contain', backgroundRepeat:'repeat-x', backgroundPosition: 'bottom', paddingBottom: 5, paddingTop: 5 }}>
                <div style={{ displat: 'flex', flexDirection: 'row'}}>
                    <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: logoHeight }} alt="WEEE" /></a>
                </div>
                <div style={{ display: 'flex', fleDirection: 'row', marginTop: 'auto', marginBottom: 5, alignSelf: 'end', float: 'right' }}>
                    {(!user)? 
                    <div style={{ display: 'flex', flexDisplay: 'row' }}>
                        <button style={{ marginTop: 5}} type="button" className="btn btn-light" onClick={() => { navigate('/login')} }>
                            <MdOutlineAccountCircle style={{ fontSize: 20, marginRight: 5, marginBottom: 3 }} />เข้าสู่ระบบ / สมัครสมาชิก
                        </button>
                    </div>
                    : 
                    <div style={{ display: 'flex', flexDisplay: 'row', alignmentBaseline: 'baseline' }}> 
                        {(userStatus == 2) && (
                        <div style={{ display: 'flex', flexDisplay: 'row', alignSelf: 'end' }}>
                        <Button
                            id="post-button"
                            aria-controls="post-menu"
                            aria-haspopup="true"
                            aria-expanded="true"
                            variant="contained"
                            disableElevation
                            onClick={handlePostMenu}
                            endIcon={<KeyboardArrowDownIcon className="text-white" />}
                        >
                            <font className="text-white" style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><AiOutlinePlusCircle style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />ลงประกาศ</font>
                        </Button>
                        <Menu
                            id="post-menu"
                            anchorEl={anchorElPost}
                            open={openPostMenu}
                            onClose={handleClosePostMenu}
                            MenuListProps={{ 'aria-labelledby': 'post-button' }}
                        >
                            <MenuItem onClick={() => { return navigate("/posts/second")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><TbHexagonNumber2 style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />ประกาศขายเครื่องใช้ไฟฟ้ามือสอง</font></MenuItem>
                            <MenuItem onClick={() => { return navigate("/posts/fix")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><BiWrench style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />แจ้งความต้องการซ่อมเครื่องใช้ไฟฟ้า</font></MenuItem>
                            <MenuItem onClick={() => { return navigate("/clean/air")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><LuAirVent style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />แจ้งความต้องการล้างแอร์</font></MenuItem>
                            <MenuItem onClick={() => { return navigate("/clean/wash")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><CgSmartHomeWashMachine style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />แจ้งความต้องการล้างเครื่องซักผ้า</font></MenuItem>
                            <MenuItem onClick={() => { return navigate("/posts/gabage")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><BiMoneyWithdraw style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />ประกาศขายซากเครื่องใช้ไฟฟ้า</font></MenuItem>
                            <MenuItem onClick={() => { return navigate("/posts/throw")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><RiDeleteBinLine style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />ประกาศทิ้งเครื่องใช้ไฟฟ้ามือสอง</font></MenuItem>
                        </Menu>
                        </div>)}

                        <Button
                            id="profile-button"
                            aria-controls="profile-menu"
                            aria-haspopup="true"
                            aria-expanded="true"
                            disableElevation
                            onClick={handleProfileMenu}
                            endIcon={<KeyboardArrowDownIcon className="text-white" />}
                        >
                            <RxAvatar className="text-white" style={{ fontSize: 25, paddingBottom: 3, marginRight: 5 }} /><font className="text-white" style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}>{displayName?displayName:user.displayName}</font>
                        </Button>
                        <Menu
                            id="profile-menu"
                            anchorEl={anchorEl}
                            open={openProfileMenu}
                            onClose={handleCloseProfileMenu}
                            MenuListProps={{ 'aria-labelledby': 'profile-button' }}
                        > 
                            <MenuItem onClick={() => { return navigate("/profile")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><RxAvatar style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />ข้อมูลผู้ใช้</font></MenuItem>
                            <MenuItem onClick={() => { return navigate("/profile/notifications")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><TbMessageCircle2 style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />ข้อความ</font></MenuItem>
                            <MenuItem onClick={() => { return navigate("/profile/posts")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />จัดการประกาศ</font></MenuItem>
                            <MenuItem onClick={() => { return navigate("/profile/appointments")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><BsCalendarDate style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />จัดการนัดหมาย</font></MenuItem>
                            <MenuItem onClick={() => { return navigate("/profile/coins")}}><font style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><PiCoinsDuotone style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />เหรียญ WEEE</font></MenuItem>
                            <MenuItem onClick={logout}><font className='text-danger' style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}><IoMdLogOut style={{ fontSize: 25, marginRight: 5, paddingBottom: 3 }} />ออกจากระบบ</font></MenuItem>
                        </Menu>



                        {/* {showNoti && */} 
                        {false && 
                        (<>
                            <Button
                                id="noti-button"
                                aria-controls={openNotiMenu ? 'noti-menu' : undefined}
                                aria-haspopup="true"
                                aria-expanded={openNotiMenu ? 'true' : undefined}
                                disableElevation
                                onClick={handleNotiMenu}
                                endIcon={<TbBellRinging className="text-white" />}
                            ><font className="text-white" style={{ fontFamily: 'Prompt', fontWeight: 'bold' }}>แจ้งเตือน {(newNoti > 0) && (<span className="badge badge-light" style={{ backgroundColor: '#ff0000', fontSize: 14 }}>{newNoti}</span>)}</font></Button>
                            <Menu
                                id="noti-menu"
                                anchorEl={anchorElNoti}
                                open={openNotiMenu}
                                onClose={handleCloseNotiMenu}
                                MenuListProps={{
                                'aria-labelledby': 'noti-button',
                                }}
                            >
                                {
                                    notiUser?.map((noti, i) => (
                                    <MenuItem onClick={() => { readNoti(noti) }} key={i}>
                                        <font style={{ fontFamily: 'Prompt', fontSize: 15 }}>
                                            {(noti.type == 'user') &&
                                            (<RxAvatar className="text-white" style={{ fontSize: 20, marginRight: 5, paddingBottom: 3 }} />)
                                            }
                                            {(noti.type == 'shop') &&
                                            (<AiOutlineShop className="text-white" style={{ fontSize: 20, marginRight: 5, paddingBottom: 3 }} />)
                                            }
                                            {(noti.status == 0) && (
                                                <WiMoonAltNew className="text-danger" style={{ fontSize: 20, marginRight: 5, paddingBottom: 3 }} />
                                            )}
                                            {noti.message}
                                        </font>
                                    </MenuItem>
                                    ))
                                }
                            </Menu>
                        </>)
                        }
                        
                    </div>
                    }
                </div>  
            </div>
        </nav>
    )
}

export default Header