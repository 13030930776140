import React from "react"
import "../../assets/css/admin.css";
import { TbUser, TbChartPie, TbFolders, TbReportMoney, TbListDetails, TbCalendar } from "react-icons/tb"
import { AiFillShop } from "react-icons/ai"
import { HiOutlineMegaphone } from "react-icons/hi2"
import { RiAdminLine, RiExchangeFundsFill } from "react-icons/ri"
import { PiHandCoinsDuotone } from "react-icons/pi"
import { GiElectric, GiComputerFan } from "react-icons/gi"
import { FaWrench } from "react-icons/fa"
import { CgSmartHomeWashMachine } from "react-icons/cg"
import { MdElectricalServices } from "react-icons/md"
import { GoGraph } from "react-icons/go"
import { BsMegaphone } from "react-icons/bs"
import { FaPowerOff } from "react-icons/fa6"
import { CiTextAlignCenter } from "react-icons/ci"

const menus = [
    { path: "/admin/dashboard", title: "หน้าหลัก", icon: <TbChartPie style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/members", title: "จัดการผู้ใช้", icon: <TbUser style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/shops", title: "จัดการร้านค้า", icon: <AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/posts", title: "จัดการประกาศ", icon: <HiOutlineMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/coins", title: "จัดการเหรียญ WEEE", icon: <RiExchangeFundsFill style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/exchange", title: "ซื้อ-ขายเหรียญ WEEE", icon: <PiHandCoinsDuotone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/ads", title: "จัดการโฆษณา", icon: <BsMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/electric", title: "จัดการประเภท/ยี่ห้อ/รุ่น", icon: <MdElectricalServices style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/airbrand", title: "จัดการยี่ห้อ/รุ่นแอร์", icon: <GiComputerFan style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/washbrand", title: "จัดการยี่ห้อ/รุ่นเครื่องซักผ้า", icon: <CgSmartHomeWashMachine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/broken", title: "จัดการอาการซ่อม", icon: <FaWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/users", title: "จัดการผู้ดูแลระบบ", icon: <RiAdminLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/texts", title: "จัดการข้อความ", icon: <CiTextAlignCenter style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/reports", title: "รายงาน", icon: <GoGraph style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
    { path: "/admin/settings", title: "เปิด/ปิดการทำงาน", icon: <FaPowerOff style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> },
]

const LeftMenu = () => {
    return (
        <div className="d-flex flex-column p-2 bg-light" style={{ width: 300, flexWrap: 'nowrap' }}>
            <ul className="nav nav-pills flex-column mb-auto" style={{ marginTop: 20 }}>
                {menus.map((menu, i) => {
                    return (
                    <li className="nav-item" style={{ width: 300, flexWrap: 'nowrap' }} key={i}>
                        <a href={menu.path} className="nav-link link-dark" aria-current="page">
                            {menu.icon}
                            <b>{menu.title}</b>
                        </a>
                    </li>)
                })}
                {/* <li style={{ width: 300, flexWrap: 'nowrap' }}>
                    <a href="/admin/brand" className="nav-link link-dark" aria-current="page">
                        <GiElectric style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                        <b>จัดการยี่ห้อ/รุ่นเครื่องใช้ไฟฟ้า</b>
                    </a>
                </li> */}
            </ul>
        </div>
    )
}

export default LeftMenu;