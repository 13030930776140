import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, doc, getDoc, onSnapshot, addDoc, updateDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { FaMapMarkerAlt } from 'react-icons/fa'
import { RxHome } from "react-icons/rx"
import { TbUser, TbTool, TbEdit, TbForbid2, TbFileSearch, TbPhoto, TbHome, TbUsers, TbUserPlus } from "react-icons/tb"
import { AiFillShop, AiFillInfoCircle } from "react-icons/ai"
import { IoCloseCircle, IoChevronBackCircleSharp, IoCheckmarkCircleSharp } from "react-icons/io5"
import useScript from '../../assets/js/useScript'
import addImage from "../../assets/images/add-image-icon.png"

const EditTech = () => {
    useScript('https://code.jquery.com/jquery-3.2.1.min.js')
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dependencies/JQL.min.js')
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dependencies/typeahead.bundle.js')
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dist/jquery.Thailand.min.js')
    
    const inputSubDistrictRef = useRef(null)
    const inputDistrictRef = useRef(null)
    const inputProvinceRef = useRef(null)
    const inputPostcodeRef = useRef(null)

    const params = useParams();
    const shopId = params.shopId
    const techId = params.techId
    const [shop, setShop] = useState([])
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState()
    const navigate = useNavigate()

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [telNo, setTelNo] = useState('')
    const [address, setAddress] = useState('')
    const [road, setRoad] = useState('')
    const [subDistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [postcode, setPostcode] = useState('')

    const [imageProfile, setImageProfile] = useState('')
    const [imageProfileUrl, setImageProfileUrl] = useState(addImage)

    const [firstnameErrorDiv, setFirstnameErrorDiv] = useState(false)
    const [lastnameErrorDiv, setLastnameErrorDiv] = useState(false)
    const [telNoErrorDiv, setTelNoErrorDiv] = useState(false)

    const [lat, setLat] = useState(15.11745249400088)
    const [lng, setLng] = useState(104.90284046686465)

    const [loading, error] = useAuthState(auth)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    })
    const center = { lat: lat, lng: lng }
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()

        if(validateForm()) {
            try {
                updateDoc(doc(db, `shops/${shopId}/technician`, techId), {
                    firstname: firstname,
                    lastname: lastname,
                    tel_no: telNo,
                    address: address,
                    road: road,
                    sub_district: subDistrict,
                    district: district,
                    province: province,
                    postcode: postcode,
                    lat: lat,
                    lng: lng,
                }).then(() => {
                    if(imageProfile) {
                        const storageRef = ref(storage, `/shops/${shopId}/image/profile/${techId}`)
                        const uploadTask = uploadBytesResumable(storageRef, imageProfile);
                        uploadTask.on(
                            "state_changed",
                            (snapshot) => { },
                            (err) => console.log(err),
                            () => { 
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    updateDoc(doc(db, `shops/${shopId}/technician`, techId), {
                                        image_profile: url,
                                    }).then(() => {
                                        navigate(`/admin/technician/view/${shopId}/${techId}`)
                                    })
                                })
                            }
                        )
                    } else {
                        navigate(`/admin/technician/view/${shopId}/${techId}`)
                    }
                })
            } catch (err) {
                console.log(err)
            }
        }
    }

    const handleImageProfile = (file) => {
        setImageProfileUrl(URL.createObjectURL(file))
        setImageProfile(file)
    }

    const setAllAddress = () => {
        const subDistrictRef = inputSubDistrictRef.current.value;
        const districtRef = inputDistrictRef.current.value;
        const provinceRef = inputProvinceRef.current.value;
        const postcodeRef = inputPostcodeRef.current.value;
        setSubDistrict(subDistrictRef)
        setDistrict(districtRef)
        setProvince(provinceRef)
        setPostcode(postcodeRef)
    }

    const validateForm = () => {
        if(!firstname) {
            setFirstnameErrorDiv(true)
            return false;
        } else {
            setFirstnameErrorDiv(false)
        }
        if(!lastname) {
            setLastnameErrorDiv(true)
            return false;
        } else {
            setLastnameErrorDiv(false)
        }
        if(!telNo) {
            setTelNoErrorDiv(true)
            return false;
        } else {
            setTelNoErrorDiv(false)
        }
        return true
    }

    const getDocShop = async(id) => {
        await getDoc(doc(db, 'shops', id)).then((shopSnapshot) => {
            setShop(shopSnapshot.data());
            if(shopSnapshot.data().user_id) {
                setMemberId(shopSnapshot.data().user_id)
                getDoc(doc(db, 'users', shopSnapshot.data().user_id)).then((memberDoc) => {
                    setMember(memberDoc.data())
                })
            }
        })
    }

    const getDocTech = async(id) => {
        await getDoc(doc(db, `shops/${shopId}/technician/`, id)).then((techSnapshot) => {
            if(techSnapshot.data().firstname) setFirstname(techSnapshot.data().firstname)
            if(techSnapshot.data().lastname) setLastname(techSnapshot.data().lastname)
            if(techSnapshot.data().tel_no) setTelNo(techSnapshot.data().tel_no)
            if(techSnapshot.data().address) setAddress(techSnapshot.data().address)
            if(techSnapshot.data().sub_district) setSubDistrict(techSnapshot.data().sub_district)
            if(techSnapshot.data().district) setDistrict(techSnapshot.data().district)
            if(techSnapshot.data().province) setProvince(techSnapshot.data().province)
            if(techSnapshot.data().postcode) setPostcode(techSnapshot.data().postcode)
            if(techSnapshot.data().lat) setLat(techSnapshot.data().lat)
            if(techSnapshot.data().lng) setLng(techSnapshot.data().lng)
            if(techSnapshot.data().image_profile) setImageProfileUrl(techSnapshot.data().image_profile)
        })
    }

    useEffect(() => {
        getDocShop(shopId)
        getDocTech(techId)
    },[loading])

    useScript('https://firebasestorage.googleapis.com/v0/b/weee-81f51.appspot.com/o/js%2FthailandAddress.js?alt=media&token=b1c469d5-5f9e-4cb1-98b3-05056ea57a86');

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><TbUserPlus style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>เพิ่มช่างในร้าน</b></h4>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shoptech/${shopId}`) }}>
                            <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                            <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/view/${shopId}`) }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/view/${shopId}`) }}>
                            <AiFillShop style={{ fontSize: 20, marginRight: 5 }} />ข้อมูลร้านค้า
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
                        <div style={{ display: 'flex'}}>
                            <div style={{ display: 'flex', minWidth: 200}}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={imageProfileUrl} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <div style={{ display: "flex", flexDirection:'row'}}>
                                    <label htmlFor="fileProfileUpload" style={{ padding: 0 }}>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    </div>
                                    <input style={{ display:'none' }} className="form-control file" id="fileProfileUpload" type="file" accept="image/*" onChange={(e) => handleImageProfile(e.target.files[0])} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                                <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 140}}><strong>ชื่อ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='firstname' onChange={(e) => setFirstname(e.target.value)} 
                                            value={firstname} placeholder='ชื่อ'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {firstnameErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกชื่อ</strong>
                                            </div>
                                        }
                                    </div>
                                </div> 
                                <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 140}}><strong>นามสกุล <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='lastname' onChange={(e) => setLastname(e.target.value)} 
                                            value={lastname} placeholder='นามสกุล'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {lastnameErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกนามสกุล</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 140}}><strong>เบอร์โทรศัพท์ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='tel_no' onChange={(e) => setTelNo(e.target.value)} 
                                            value={telNo} placeholder='เบอร์โทรศัพท์'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {telNoErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกเบอร์โทรศัพท์</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div> 
                        <div style={{ marginBottom: 10, marginTop: 5, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                            <h6><RxHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อมูลที่อยู่</b></h6>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>บ้านเลขที่, หมู่บ้าน, อาคาร</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='address' onChange={(e) => setAddress(e.target.value)} 
                                    value={address} placeholder='บ้านเลขที่, หมู่บ้าน, อาคาร'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ถนน</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                    value={road} placeholder='ถนน'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ตำบล</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' id="district" name='subDistrict' autoComplete='no' onChange={(e) => setSubDistrict(e.target.value)} onBlur={(e) => setAllAddress()}
                                    value={subDistrict} placeholder='ตำบล' ref={inputSubDistrictRef} 
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>อำเภอ</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' id="amphoe" name='district' autoComplete='no' onChange={(e) => setDistrict(e.target.value)} onBlur={(e) => setAllAddress()} 
                                    value={district} placeholder='อำเภอ' ref={inputDistrictRef} 
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>จังหวัด</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' id="province" name='province' autoComplete='no' onChange={(e) => setProvince(e.target.value)} onBlur={(e) => setAllAddress()} 
                                    value={province} placeholder='จังหวัด' ref={inputProvinceRef} 
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>รหัสไปรษณีย์</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' id="zipcode" name='postcode' autoComplete='no' onChange={(e) => setPostcode(e.target.value)} onBlur={(e) => setAllAddress()} 
                                    value={postcode} placeholder='รหัสไปรษณีย์' ref={inputPostcodeRef} 
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                            </div>
                        </div> 
                        <div style={{ marginTop: 10, marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                            <h6><FaMapMarkerAlt style={{ fontSize: 20, marginRight: 5 }} />
                            <b>ตำแหน่งพิกัดที่พักอาศัย</b></h6>
                        </div>
                        <div className="mb-3 row" style={{ display: 'flex', height:400, flexDirection: 'column' }}>
                            <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap' }}>
                                <font className="text-danger" style={{ marginRight: 5 }}>**</font> ลาก Marker <FaMapMarkerAlt className='text-danger' style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} /> เพื่อกำหนดตำแหน่ง
                            </div>
                            <div style={{  display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                    <MarkerF
                                        draggable={true} position={center}
                                        onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                    />
                                </GoogleMap>
                                )}
                            </div>
                            <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap', textAlign: 'right' }}>
                                <div style={{ display: 'flex', float: 'right', flex: 1 }}>lat:{lat}, lng:{lng}</div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3, borderLeft: '1px solid #cccccc', marginLeft: 20, paddingLeft: 20}}>
                        <div style={{ paddingTop: 10, borderBottom: '1px solid #cccccc' }}>
                            <h6><AiFillShop style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อมูลร้านค้า</b></h6>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ผู้ใช้ </strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    {(member) && (
                                    <>
                                        <font style={{ marginRight: 10}}>{member.firstname?member.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                        <font style={{ marginRight: 10}}>{member.lastname?member.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                        <font style={{ marginRight: 10}}>({member.display_name?member.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font>
                                        <a href={`/admin/member/view/${memberId}`}><AiFillInfoCircle className='text-primary' style={{ fontSize: 25 }} /></a>
                                    </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>email </strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    {(member) && (
                                    <><font style={{ marginRight: 10}}>{member.email?member.email:""}</font></>
                                    )}
                                </div>
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ประเภทร้านค้า</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {(shop.type==1)&&(<div><TbUser style={{ fontSize: 20, marginRight: 5 }} />บุคคลธรรมดา</div>)}
                                {(shop.type==2)&&(<div><AiFillShop style={{ fontSize: 20, marginRight: 5 }} />นิติบุคคล</div>)}
                            </div>
                        </div> 
                        {(shop.type==2)&& (
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อร้านค้า</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.shopname?shop.shopname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        )}
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อ (เจ้าของ)</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.firstname?shop.firstname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>นามสกุล (เจ้าของ)</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.lastname?shop.lastname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>เบอร์โทรศัพท์</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.tel_no?shop.tel_no:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditTech;