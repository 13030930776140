import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, getDoc, doc, updateDoc, deleteDoc, and } from 'firebase/firestore'
import { Link, useNavigate } from "react-router-dom"
import LeftMenu from './LeftMenu'

import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2 } from "react-icons/tb"
import { IoMegaphoneOutline } from 'react-icons/io5'
import { FiEdit, FiMinusCircle, FiInfo, FiXCircle, FiCheckCircle, FiCalendar } from "react-icons/fi"
import { StarRating } from '../helper/StarHelper'
import { AiFillInfoCircle } from 'react-icons/ai'

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { Alert } from '../layouts/Alert'
import { Loading } from "../layouts/Loading"
import { getBrand, getModel } from '../helper/BrandHelper'
import { getElectric } from '../helper/ElectricTypeHelper'
import { addShopNotification } from "../../../assets/js/NotificationHelper"
import { LuAirVent } from "react-icons/lu"
import { CgSmartHomeWashMachine } from "react-icons/cg"

import LeftMenuPost from './LeftMenuPost'
import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Posts = () => {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [memberId, setMemberId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [posts, setPosts] = useState([])

    const [postId, setPostId] = useState('')
    const [openChooseOffersShop, setOpenChooseOffersShop] = useState(false)
    const [offerList, setOfferList] = useState([])
    const [confirmOffer, setConfirmOffer] = useState('')
    const [openErrorDiv, setOpenErrorDiv] = useState(false)

    const [openHidePost, setOpenHidePost] = useState(false)
    const [openShowPost, setOpenShowPost] = useState(false)
    const [openDeletePost, setOpenDeletePost] = useState(false)
    const [choosePostId, setChoosePostId] = useState('')

    const [showProgressBar, setShowProgressBar] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const handleCloseHidePost = () => {
        setChoosePostId('')
        setOpenHidePost(false)
    }

    const handleOpenHidePost = (post_id) => {
        setChoosePostId(post_id)
        setOpenHidePost(true)
    }

    const handleCloseShowPost = () => {
        setChoosePostId('')
        setOpenShowPost(false)
    }

    const handleOpenShowPost = (post_id) => {
        setChoosePostId(post_id)
        setOpenShowPost(true)
    }

    const handleCloseDeletePost = () => {
        setChoosePostId('')
        setOpenDeletePost(false)
    }

    const handleOpenDeletePost = (post_id) => {
        setChoosePostId(post_id)
        setOpenDeletePost(true)
    }

    const handleHidePost = () => {
        updateDoc(doc(db, `posts`, choosePostId), { status: 6 }).then(() => {
            navigate(0)
        })
    }

    const handleShowPost = () => {
        updateDoc(doc(db, `posts`, choosePostId), { status: 1 }).then(() => {
            navigate(0)
        })
    }

    const handleDeletePost = () => {
        deleteDoc(doc(db, `posts`, choosePostId)).then(() => {
            navigate(0)
        })
    }

    const handleCloseAcceptPostShop = () => {
        setOpenChooseOffersShop(false)
    }
 
    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }
 
    const dateFormatTime = (datetime) => {
        return (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const showChooseOffersList = (post) => {
        setConfirmOffer('')
        setPostId(post.id)
        offerList.splice(0, offerList.length)
        setOfferList([...offerList])
        getDocs(query(collection(db, `posts/${post.id}/offers`), orderBy('offer_timestamp', 'asc'))).then(async (offerDocs) => {
            offerDocs.docs.map((offer) => {
                getDoc(doc(db, 'shops', offer.data().shop_id)).then((shop) => {
                    offerList.push({
                        id: offer.id,
                        data: offer.data(),
                        shopId: offer.data().shop_id,
                        shop: shop.data()
                    })
                    setOfferList([...offerList]) 
                })
            })
        }).then(() => {
            setOpenChooseOffersShop(true)
        })
    }

    const handleOfferSubmit = () => {
        if(validateOfferForm()) {
            setShowLoading(true)
            getDoc(doc(db, `posts/${postId}/offers/`, confirmOffer)).then((offerDoc) => {
                updateDoc(doc(db, `/posts`, postId), { status: 3 }).then(async () => {
                    getDocs(query(collection(db, `/posts/${postId}/offers`))).then((offerDocs) => {
                        offerDocs.forEach(async(docRef) => {
                            updateDoc(doc(db, `/posts/${postId}/offers`, docRef.id), { status: 4 }).then(() => {
                                getDocs(query(collection(db, `/shops/${docRef.data().shop_id}/offers`), where('offer_id', '==', docRef.id))).then((offerShop) => {
                                    offerShop.forEach((offer) => {
                                        updateDoc(doc(db, `/shops/${docRef.data().shop_id}/offers`, offer.id), { status: 4 })
                                    })
                                })
                            })
                        })
                    }).then(() => {
                        updateDoc(doc(db, `/posts/${postId}/offers/`, confirmOffer), { status: 2 }).then(() => {
                            getDocs(query(collection(db, `/shops/${offerDoc.data().shop_id}/offers`), where('offer_id', '==', confirmOffer))).then((offerShop) => {
                                offerShop.forEach(async (docRef) => {
                                    updateDoc(doc(db, `/shops/${offerDoc.data().shop_id}/offers`, docRef.id), { status: 2 }).then(() => {
                                        addShopNotification(db, offerDoc.data().shop_id, "ยินดีด้วย ข้อเสนอรับซากของคุณได้รับการเลือก", 'offer', postId, '/shop/offers')
                                        setShowLoading(false)
                                        setOpenChooseOffersShop(false)
                                        navigate(0)
                                    })
                                })
                            })
                        })
                    })
                })
            })
        }
    }

    const validateOfferForm = () => {
        if(!confirmOffer) {
            setOpenErrorDiv(true)
            return false;
        } else {
            setOpenErrorDiv(false)
        }
        return true
    }

    const fetchPosts = () => {
        setShowProgressBar(true)
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then(async(docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMemberId(docs.docs[0].id)
                if(data.is_shop) setIsShop(data.is_shop)
                await getDocs(query(collection(db, 'posts'), 
                            where('onboard', '==', 1), 
                            where('poster_id', '==', docs.docs[0].id), 
                            orderBy('post_timestamp', 'desc'))).then((postDocs) => {
                    posts.splice(0, posts.length)
                    setPosts([...posts])
                    postDocs.forEach((docPost) => {
                        var brand = 'ไม่ระบุ'
                        var model = 'ไม่ระบุ'
                        var type = 'ไม่ระบุ'
                        var promisesTask = []
                        var getBrandTask = getBrand(db, docPost.data().brand_id, docPost.data().brand).then((brandName) => {
                            brand = brandName
                        })
                        promisesTask.push(getBrandTask)
        
                        var getModelTask = getModel(db, docPost.data().model_id, docPost.data().model).then((modelName) => {
                            model = modelName
                        })
                        promisesTask.push(getModelTask)
                        var getElectricType = getElectric(db, docPost.data().type_id, docPost.data().other_type).then((typeName) => {
                            type = typeName
                        })
                        promisesTask.push(getElectricType)

                        getDocs(query(collection(db, `posts/${docPost.id}/offers`), where('status', '==', 1), orderBy('offer_timestamp', 'desc'))).then((offerQuery) => {
                            var offersList = []
                            offerQuery.forEach((offer) => {
                                offersList.push({ id: offer.id, data: offer.data() })
                            })
                            Promise.all(promisesTask).then(() => {
                                posts.push({
                                    id: docPost.id,
                                    data: docPost.data(),
                                    offer: offersList,
                                    brand: brand,
                                    model: model,
                                    type: type
                                })
                                setPosts([...posts], {
                                    id: docPost.id,
                                    data: docPost.data(),
                                    offer: offersList,
                                    brand: brand,
                                    model: model,
                                    type: type
                                })
                            })
                        })
                    })
                }).then(() => {
                    setShowProgressBar(false)
                })
            }
        }).catch((error) => {
            alert(error.message);
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else fetchPosts()
    }, [user, loading]);

    var postKey = []
    var postList = []
    posts.forEach(element => {
        if(postKey.lastIndexOf(element.id) < 0) {
            postKey.push(element.id)
            postList.push(element)
        }
    })

    return (
    <>
        <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
        <Loading open={showProgressBar} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 280 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenuPost currentPage="post" isShop={isShop} postType={1} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 10, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <h4 className='d-block d-md-none d-lg-none'>
                            <b>ประกาศของฉัน {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} onClick={toggleDrawer(true)} >ทิ้งซาก<IoMdMenu style={{ fontSize: 25, marginLeft: 5, marginBottom: 3 }} /></font></b>
                        </h4>
                        <h4 className='d-none d-md-block d-lg-block'>
                            <b>ประกาศของฉัน {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} >ทิ้งซาก</font></b>
                        </h4>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="post" isShop={isShop} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div className="d-none d-md-block d-lg-block" style={{ borderBottom: '1px solid #cccccc', paddingLeft: 20 }}>
                            <ul className="nav nav-underline">
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/profile/posts/"><RiDeleteBinLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ทิ้งซาก</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/posts/garbage"><BiMoneyWithdraw style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายซาก</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/posts/second"><TbHexagonNumber2 style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายมือสอง</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/posts/fix"><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งซ่อมเครื่องใช้ไฟฟ้า</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/posts/airs"><LuAirVent style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างแอร์</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/posts/wash"><CgSmartHomeWashMachine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างเครื่องซักผ้า</a>
                                </li>
                            </ul>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10}}>
                        {postList?.map((post, i) => (
                            <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={i}>
                                {(post.offer.length > 0 && post.data.status==2) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-primary" onClick={() => { showChooseOffersList(post) }}>
                                        ข้อเสนอ <span className="badge badge-light" style={{ backgroundColor: '#ff0000'}}>{post.offer.length}</span>
                                        </button>
                                    </div>)
                                }
                                {(post.data.status==3) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-warning" style={{ color: '#000000'}}>รอยืนยันนัดหมาย</button>
                                    </div>)
                                }
                                {(post.data.status==4) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}><strong>ยืนยันนัดหมาย</strong></button>
                                    </div>)
                                }
                                {post.data.status == 5 &&
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-danger" style={{ color: '#FFFFFF'}}><strong>ระงับการประกาศ</strong></button>
                                    </div>)
                                }
                                {(post.data.status==6) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-danger" style={{ color: '#ffffff'}}><strong>ซ่อนประกาศ</strong></button>
                                    </div>)
                                }
                                {(post.data.status==9) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}><strong>ทิ้งซากเรียบร้อย</strong></button>
                                    </div>)
                                }
                                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                    <img src={post.data.picture} alt="APP3R" height="150" />
                                </div>
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                    <p className="card-text">
                                        {post.type} {post.brand} {post.model}<br/>
                                        <strong>รายละเอียดเพิ่มเติม:</strong> {post.data.description}
                                    </p>
                                    <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong><br/>{dateFormatDate(post.data.pick_date)} เวลา {post.data.hour}:{post.data.minute} น.</p>
                                    <p className="card-text"><strong>วันที่ประกาศ:</strong> {dateFormatTime(post.data.post_timestamp)}</p>
                                </div>
                                <div className="card-body">
                                    {(post.data.status==1) && (<>
                                        <Link className="btn btn-warning m-1" to={`/post/edit/throw/${post.id}`}><FiEdit /> <strong>แก้ไข</strong></Link>
                                        <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenHidePost(post.id) }}><FiMinusCircle /> <strong>ซ่อน</strong></button>
                                    </>)}
                                    <Link className="btn btn-primary m-1" to={`/post/${post.id}`} target='_blank'><FiInfo /> <strong>ดูประกาศ</strong></Link>
                                    {(post.data.status==3) && (<>
                                        <Link className="btn btn-primary m-1" to={`/profile/postdetail/${post.data.onboard}/${post.id}/`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                                    </>)}
                                    {(post.data.status==4) && (<>
                                        <Link className="btn btn-primary m-1" to={`/profile/postdetail/${post.data.onboard}/${post.id}/`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                                        <Link className="btn btn-primary m-1" to={`/profile/appointments`} target='_blank'><FiCalendar /> <strong>ดูนัดหมาย</strong></Link>
                                    </>)}
                                    {(post.data.status==6) && (<>
                                        <Link className="btn btn-warning m-1" to={`/post/edit/throw/${post.id}`}><FiEdit /> <strong>แก้ไข</strong></Link>
                                        <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenShowPost(post.id) }}><FiCheckCircle /> <strong>แสดง</strong></button>
                                        <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenDeletePost(post.id) }}><FiXCircle /> <strong>ลบ</strong></button>
                                    </>)}
                                    {(post.data.status==9) && (<>
                                        <Link className="btn btn-primary m-1" to={`/profile/postdetail/${post.data.onboard}/${post.id}/`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                                    </>)}
                                </div>
                            </div>
                        ))}
                        {postList.length <= 0 && (
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <font className="text-danger" style={{ fontWeight: '600'}}>ไม่พบประกาศ</font>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={openChooseOffersShop} onClose={handleCloseAcceptPostShop} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยื่นข้อเสนอ</strong></h3></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', marginTop: 10, flexDirection: 'column', width:'100%', marginBottom: 15 }}>
                                <div style={{ backgroundColor: '#ffffff'}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                    <th scope="col">ข้อเสนอร้านค้า</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {offerList?.map((offer, i) => (
                                                <tr key={i}>
                                                    <th scope="row">
                                                        <input className="form-check-input" type="radio" 
                                                            name="chooseOfferRadio" id="chooseOfferRadio" value={offer.id}
                                                            onClick={() => setConfirmOffer(offer.id)}
                                                        />
                                                    </th>
                                                    <td>
                                                        <div className="flex-container">
                                                            <div className="flex-container" style={{ marginBottom: 10, marginRight: 10 }}>
                                                                {(offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname}<br/>
                                                                <StarRating score={offer.shop.score_average?offer.shop.score_average:0} />
                                                                <a href={`/shop/history/${offer.shopId}`} target='_blank'><AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} /></a>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, marginRight: 10}}>
                                                                <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.</p>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10}}>
                                                                {offer.data.message}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>
                                {openErrorDiv &&
                                    <div className="card-body text-danger border-danger" style={{ marginLeft: 10, marginTop:-5}}>
                                        <p className="font-weight-bold"><strong>** กรุณาเลือกข้อเสนอเพื่อยืนยันรับข้อเสนอ</strong></p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPostShop} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleOfferSubmit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันรับข้อเสนอ</button>
                </DialogActions>
            </Dialog>
            
            <Alert name="Hide Post"
                title={"ซ่อนประกาศ"}
                text={"คุณต้องการซ่อนประกาศนี้?"}
                open={openHidePost} 
                close={handleCloseHidePost}
                submit={handleHidePost} />
            
            <Alert name="Show Post"
                title={"แสดงประกาศ"}
                text={"คุณต้องการแสดงประกาศนี้?"}
                open={openShowPost} 
                close={handleCloseShowPost}
                submit={handleShowPost} />
            
            <Alert name="Delete Post"
                title={"ลบประกาศ"}
                text={"คุณต้องการลบประกาศนี้?"}
                open={openDeletePost} 
                close={handleCloseDeletePost}
                submit={handleDeletePost} />

        </div>
    </>
    )
}

export default Posts