import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, onSnapshot, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import { Link, useNavigate } from "react-router-dom"

import LeftMenu from './LeftMenu'
import { IoMegaphoneOutline  } from 'react-icons/io5'

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

import { Loading } from "../layouts/Loading"

const Offers = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [user, loading, error] = useAuthState(auth)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [uid, setUid] = useState('')
    const [docId, setDocId] = useState('')
    const [isShop, setIsShopId] = useState(0)
    const [offers, setOffers] = useState([])

    const [chooseOffer, setChooseOffer] = useState([])
    const [chooseOfferId, setChooseOfferId] = useState('')
    const [choosePostId, setChoosePostId] = useState('')
    const [chooseDeliveryOffer, setChooseDeliveryOffer] = useState('1')
    const [chooseUserId, setChooseUserId] = useState('')
    const [userOfferId, setUserOfferId] = useState('')
    const [depositCoin, setDepositCoin] = useState(0)
    const [depositEnough, setDepositEnough] = useState(false)
    const [remainCoin, setRemainCoin] = useState(0)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [depositMessage, setDepositMessage] = useState('')

    const [openCancleOffer, setOpenCancleOffer] = useState(false);
    const [cancleMessage, setCancleMessage] = useState('');
    const [cancleReason, setCancleReason] = useState(1);

    const [openUserConfirm, setOpenUserConfirm] = useState(false);

    const [openUserCancle, setOpenUserCancle] = useState(false);
    const [userCancleMessage, setUserCancleMessage] = useState('')
    const [userCancleTitle, setUserCancleTitle] = useState('')
    const [userCancleButton, setUserCancleButton] = useState('')

    const [showLoading, setShowLoading] = useState(true)
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]+" น."
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleCloseUserConfirm = () => {
        setOpenUserConfirm(false)
    }

    const handleOpenUserConfirm = (offerId, postId, userId, userOfferId, offer, delivery) => {
        setChooseOfferId(offerId)
        setChoosePostId(postId)
        setChooseUserId(userId)
        setUserOfferId(userOfferId)
        setChooseOffer(offer)
        setChooseDeliveryOffer(delivery)

        var price = offer.data.price*1
        const depositPercent = Math.ceil((price*30)/100)
        const remainPercent = Math.ceil((price*70)/100)
        getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeSnapshot) => {
            const rate = exchangeSnapshot.data().money_to_coin_1*1
            const depositCoin = Math.ceil(depositPercent/rate)
            const remainCoin = Math.ceil(remainPercent/rate)
            setDepositCoin(depositCoin)
            setRemainCoin(remainCoin)
            if(depositCoin < offerExchangeCoin) { 
                setDepositEnough(true)
                setDepositMessage('คุณมีเหรียญซื้อ '+number_format.format(offerExchangeCoin)+' เหรียญ คุณจะใช้ '+number_format.format(depositCoin)+' เหรียญ (30% ของมูลค่าสินค้า '+number_format.format(price)+' บาท) เพื่อยืนยันการจองสินค้า?') 
            } else  { 
                setDepositEnough(false) 
                setDepositMessage('คุณมีจำนวนเหรียญซื้อไม่พอ คุณมี '+number_format.format(offerExchangeCoin)+' เหรียญ คุณต้องใช้ '+number_format.format(depositCoin)+' เหรียญ (30% ของมูลค่าสินค้า '+number_format.format(price)+' บาท) เพื่อยืนยันการจองสินค้า')
            }
        })

        setOpenUserConfirm(true)
    }

    const handleUserConfirm = () => {
        setShowLoading(true)
        const postData = chooseOffer.post
        const offerPostId = chooseOffer.data.offer_id
        const offerUserId = chooseOffer.id
        const postId = chooseOffer.post_id
        const confirmDate = new Date()
        var depositCoinAppoint = 0
        var remainCoinAppoint = 0

        var promisesTask = []
        var updatePost = updateDoc(doc(db, `/postshops`, postId), { status: 4 })
        promisesTask.push(updatePost)

        depositCoinAppoint = depositCoin
        remainCoinAppoint = remainCoin
        var updateUserCoinTask = updateDoc(doc(db, 'users/', docId), {
            exchange_coin: (offerExchangeCoin*1)-(depositCoin*1)
        })
        promisesTask.push(updateUserCoinTask)

        var addNotiUserTask = addDoc(collection(db, `/users/${docId}/notifications`), {
            date: confirmDate.getTime(),
            message: "คุณจ่ายเหรียญเพื่อจองสินค้า จำนวน "+number_format.format(depositCoin)+" เหรียญ",
            link: '/shop/post/'+postId,
            type: 'coin',
            status: 0
        })
        promisesTask.push(addNotiUserTask)

        var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
            coin: depositCoin*1,
            date: confirmDate.getTime(),
            event_type: 'deposit',
            event: 'มัดจำเพื่อจองสินค้า',
            type: 'increase',
            post_type: 'shop',
            post_id: postId
        })
        promisesTask.push(addCoinLogTask)

        var updateCoinTask = getDoc(doc(db, 'exchange_coins', 'sum_data')).then((freeSnapshot) => {
            const data = freeSnapshot.data()
            updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                all: (data.all*1)+(depositCoin*1)
            })
        })
        promisesTask.push(updateCoinTask)
        
        var addAppointment = addDoc(collection(db, 'appointmentshops'), {
            post_id: postId,
            offer_id: offerPostId,
            user_offer_id: offerUserId,
            shop_id: postData.poster_id,
            user_id: docId,
            appointment_date: chooseOffer.data.pick_date,
            appointment_delivery: chooseDeliveryOffer,
            appointment_hour: chooseOffer.data.hour,
            appointment_minute: chooseOffer.data.minute,
            appointment_timestamp: confirmDate.getTime(),
            deposit_coin: depositCoinAppoint,
            remain_coin: remainCoinAppoint,
            status: 1
        })
        promisesTask.push(addAppointment)

        var updatePostOffer = updateDoc(doc(db, `/postshops/${postId}/offers`, offerPostId), { status: 3 })
        promisesTask.push(updatePostOffer)

        var updateUserOffer = updateDoc(doc(db, `/users/${docId}/offers`, offerUserId), { status: 3 })
        promisesTask.push(updateUserOffer)

        Promise.all(promisesTask).then(() => {
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleCloseCancleOffer = () => {
        setOpenCancleOffer(false)
    }

    const handleOpenCancleOffer = (offerId, postId, userId, userOfferId, offer) => {
        setChooseOfferId(offerId)
        setChoosePostId(postId)
        setChooseUserId(userId)
        setUserOfferId(userOfferId)
        setChooseOffer(offer)
    }

    const handleCancleOffer = () => {
        setShowLoading(true)
        const cancle_date = new Date()
        var promisesTask = []
        var addCancleHistoryTask = addDoc(collection(db, `postshops/${choosePostId}/cancle_offers`), {
            user_id: docId,
            cancle_reason: cancleReason,
            cancle_message: cancleMessage,
            cancle_datetime: cancle_date.getTime()
        })
        promisesTask.push(addCancleHistoryTask)

        var deletePostOfferTask = deleteDoc(doc(db, `postshops/${choosePostId}/offers`, chooseOfferId)).then(() => {
            getDocs(query(collection(db, `postshops/${choosePostId}/offers`), where("status", "!=", 5))).then((docs) => {
                var status = 1
                if (docs.docs.length > 0) { status = 2 }
                updateDoc(doc(db, `postshops/`, choosePostId), {
                    status: status
                })
            })
        })
        promisesTask.push(deletePostOfferTask)

        var deleteUserOfferTask = deleteDoc(doc(db, `users/${docId}/offers`, userOfferId))
        promisesTask.push(deleteUserOfferTask)

        Promise.all(promisesTask).then(() => {
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleCloseUserCancle = () => {
        setUserCancleButton('')
        setUserCancleMessage('')
        setUserCancleTitle('')
        setOpenUserCancle(false)
    }

    const handleOpenUserCancle = (offerId, postId, userId, userOfferId, offer) => {
        setChooseOfferId(offerId)
        setChoosePostId(postId)
        setChooseUserId(userId)
        setUserOfferId(userOfferId)
        setChooseOffer(offer)
        
        setOpenUserCancle(true)
    }

    const handleUserCancle = () => {
        setShowLoading(true)
        updateDoc(doc(db, `postshops/${choosePostId}/offers`, chooseOfferId), {
            status: 5,
            cancle_reason: cancleReason,
            cancle_message: cancleMessage
        }).then(() => {
            getDocs(query(collection(db, `postshops/${choosePostId}/offers`), where("status", "!=", 5))).then((docs) => {
                var status = 1
                if (docs.docs.length > 0) { status = 2 }
                var promisesTask = []
                var updateAddNotiTask = getDoc(doc(db, `postshops/`, choosePostId)).then((postDoc) => {
                    const postData = postDoc.data();
                    const date = new Date()
                    var link = "/profile/posts"
                    addDoc(collection(db, `/shops/${postData.poster_id}/notifications`), {
                        date: date.getTime(),
                        message: "ผู้เสนอยกเลิกข้อเสนอ กรุณาเลือกข้อเสนอรายใหม่",
                        link: link,
                        type: 'offer',
                        offer_id: chooseOfferId,
                        status: 0
                    })
                })
                promisesTask.push(updateAddNotiTask)

                var updateUserTask = updateDoc(doc(db, `user/${docId}/offers`, userOfferId), {
                    status: 5
                })
                promisesTask.push(updateUserTask)

                var updatePostTask = updateDoc(doc(db, `postshops/`, choosePostId), {
                    status: status
                })
                promisesTask.push(updatePostTask)

                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const fetchPosts = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDocId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShopId(data.is_shop)

                if(data.freecoin) setOfferFreeCoin(data.freecoin)
                if(data.exchange_coin) setOfferExchangeCoin(data.exchange_coin)

                offers.splice(0, offers.length)
                setOffers([...offers])
                const queryOffer = query(collection(db, `users/${docs.docs[0].id}/offers`));
                onSnapshot(queryOffer, (querySnapshot) => {
                    querySnapshot.forEach(async(offer) => {
                        const postSnapshot = await getDoc(doc(db, 'postshops', offer.data().post_id));
                        if (postSnapshot.exists()) {
                            var brand = 'ไม่ระบุ'
                            var model = 'ไม่ระบุ'
                            var deliveryOffer = '1'
                            var promisesTask = []
                            if(postSnapshot.data().brand_id) {
                                if(postSnapshot.data().brand_id == '999') {
                                    if(postSnapshot.data().brand) {
                                        brand = postSnapshot.data().brand
                                    }
                                } else {
                                    var brand_id = postSnapshot.data().brand_id
                                    var getBrandTask = await getDoc(doc(db, 'brands', brand_id)).then((docBrand) => {
                                        brand = docBrand.data().name
                                    })
                                    promisesTask.push(getBrandTask)
                                }
                            } else {
                                if(postSnapshot.data().brand) {
                                    brand = postSnapshot.data().brand
                                }
                            }
                            if(postSnapshot.data().model_id) {
                                if(postSnapshot.data().model_id == '999') {
                                    if(postSnapshot.data().model) {
                                        model = postSnapshot.data().model
                                    }
                                } else {
                                    var model_id = postSnapshot.data().model_id
                                    var getModelTask = await getDoc(doc(db, 'models', model_id)).then((docModel) => {
                                        model = docModel.data().name
                                    })
                                    promisesTask.push(getModelTask)
                                }
                            } else {
                                if(postSnapshot.data().model) {
                                    model = postSnapshot.data().model
                                }
                            }

                            var getPostOffer = await getDoc(doc(db, `postshops/${offer.data().post_id}/offers`, offer.data().offer_id)).then((offerDoc) => {
                                if(offerDoc.data().delivery_offer) {
                                    deliveryOffer = offerDoc.data().delivery_offer
                                } else {
                                    deliveryOffer = "1"
                                }
                            })
                            promisesTask.push(getPostOffer)

                            Promise.all(promisesTask)

                            var badgeStyle = { message: '', class: 'success', color: '#000000', show: false }
                            if(offer.data().status==1) {
                                badgeStyle = { message: 'รอเลือกข้อเสนอ', class: 'warning', color: '#000000', show: true }
                            } else if(offer.data().status==2) {
                                badgeStyle = { message: 'ข้อเสนอถูกเลือก', class: 'success', color: '#ffffff', show: true }
                            } else if(offer.data().status==3) {
                                badgeStyle = { message: 'ยืนยันจองสินค้า', class: 'success', color: '#ffffff', show: true }
                            } else if(offer.data().status==4) {
                                badgeStyle = { message: 'ข้อเสนอไม่ถูกเลือก', class: 'danger', color: '#ffffff', show: true }
                            } else if(offer.data().status==5) {
                                badgeStyle = { message: 'ยกเลิกข้อเสนอ', class: 'danger', color: '#ffffff', show: true }
                            } else if(offer.data().status==6) {
                            } else if(offer.data().status==7) {
                                badgeStyle = { message: 'ข้อเสนอถูกยกเลิก', class: 'danger', color: '#ffffff', show: true }
                            } else if(offer.data().status==8) {
                            } else if(offer.data().status==9) {
                            }



                            offers.push({
                                id: offer.id,
                                post_id: postSnapshot.id,
                                data: offer.data(),
                                post: postSnapshot.data(),
                                brand: brand,
                                model: model,
                                delivery_offer: deliveryOffer,
                                badgeStyle: badgeStyle
                            })
                            setOffers([...offers, {
                                id: offer.id,
                                post_id: postSnapshot.id,
                                data: offer.data(),
                                post: postSnapshot.data(),
                                brand: brand,
                                model: model,
                                delivery_offer: deliveryOffer,
                                badgeStyle: badgeStyle
                            }])
                        } else {
                            console.log("Post doesn't exist")
                        }
                    })
                })
            }
            setShowLoading(false)
        }).catch((err) => {
            console.error(err)
        })

    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else fetchPosts()
    }, [user, loading])

    var offerKey = []
    var offerList = []
    offers.forEach(async(element) => {
        if(offerKey.lastIndexOf(element.id) < 0) {
            offerKey.push(element.id)
            offerList.push(element)
        }
    })

return (
    <>
        <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 280 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="offers" isShop={isShop} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ข้อเสนอของฉัน</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="offers" isShop={isShop} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {offerList?.map((offer, i) => (
                            <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}  key={offer.post_id}>
                                {offer.badgeStyle.show && 
                                <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                    <button type="button" className={"btn "+"btn-"+offer.badgeStyle.class} style={{ color: offer.badgeStyle.color }}><strong>{offer.badgeStyle.message}</strong></button>
                                </div>}
                                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                    <img src={offer.post.picture} alt="WEEE" height="150" />
                                </div>
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                    <p className="card-text">
                                        {/* ทิ้งซาก, ขายซาก, ขายมือสอง, ประกาศซ่อม */}
                                        {(!offer.data.board)  && 
                                            (<>
                                            <><strong>ซื้อเครื่องใช้ไฟฟ้ามือสอง</strong><br/></>
                                            <>{offer.data.type} {offer.brand} {offer.model}<br/></>
                                            <><strong>ที่อยู่:</strong> {offer.post.address} <br/></>
                                            <>
                                                <strong>ราคาเสนอซื้อ:</strong> {number_format.format(offer.data.price)} บาท <br/>
                                                <strong>ราคาประกาศขาย:</strong> {number_format.format(offer.post.price)} บาท <br/>
                                            </>
                                            </>)
                                        }
                                        <strong>วันที่ยื่นข้อเสนอ:</strong> {dateFormat(offer.data.offer_timestamp)} <br/>
                                        <strong>ข้อเสนอ:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.
                                    </p>
                                </div>
                                <div className="card-body">
                                    {(!offer.data.board)  && 
                                        (<>
                                        <Link className="btn btn-primary m-1" to={`/shop/post/${offer.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                                        
                                        <>
                                            <button type="button" className="btn btn-primary m-1" style={{ color: '#ffffff' }} onClick={() => {  }}><strong>ดูข้อเสนอ</strong></button>
                                            {(offer.data.status==1) && (<>
                                                <button type="button" className="btn btn-danger m-1" onClick={() => handleOpenCancleOffer(offer.data.offer_id, offer.post_id, docId, offer.id, offer) }><strong>ยกเลิกข้อเสนอซื้อสินค้า</strong></button>
                                            </>)}
                                            {(offer.data.status==2) && (<>
                                                <button type="button" className="btn btn-success m-1" onClick={() => handleOpenUserConfirm(offer.data.offer_id, offer.post_id, docId, offer.id, offer, offer.delivery_offer) }><strong>ยืนยันการจองสินค้า</strong></button>
                                                <button type="button" className="btn btn-danger m-1" onClick={() => handleOpenUserCancle(offer.data.offer_id, offer.post_id, docId, offer.id, offer) }><strong>ยกเลิกการจองสินค้า</strong></button>
                                            </>)}
                                            {(offer.data.status==3) && (<>
                                                <button type="button" className="btn btn-success m-1" style={{ color: '#ffffff' }} onClick={() => {  }}><strong>ดูนัดหมาย</strong></button>
                                            </>)}
                                        </>
                                        
                                    </>)}
                                </div>
                            </div>
                        ))}
                        {offerList.length <= 0 && (
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <font className="text-danger" style={{ fontWeight: '600'}}>ไม่พบข้อเสนอของฉัน</font>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={openCancleOffer} onClose={handleCloseCancleOffer} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกข้อเสนอซื้อเครื่องใช้ไฟฟ้ามือสอง</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5, flex: 1 }}>
                                                <option value="1">หลังจากเสนอฯ ผู้ประกาศตอบกลับช้า</option>
                                                <option value="2">ซื้อสินค้าใหม่</option>
                                                <option value="3">อื่นๆ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setCancleMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={cancleMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleOffer} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleOffer} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกข้อเสนอรับซื้อเครื่องใช้ไฟฟ้ามือสอง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openUserConfirm} onClose={handleCloseUserConfirm} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันจองเครื่องใช้ไฟฟ้ามือสอง</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันจองเครื่องใช้ไฟฟ้ามือสอง?</h5>
                                    {depositEnough &&
                                    <h6><strong>{depositMessage}</strong></h6>
                                    }
                                    {!depositEnough &&
                                    <h6 className='text-danger'><strong>{depositMessage}</strong></h6>
                                    }
                                    <h6 className='text-danger'>*** เมื่อผู้ประกาศขายยืนยันการจองแล้ว<br/><strong>หากท่านยกเลิกการจอง</strong><br/><strong>ภายใน 3 วัน</strong> ต้องจ่ายค่าปรับจากร้อยละของจำนวนเหรียญที่จอง ให้ผู้ประกาศฯ 10% ให้แพลตฟอร์ม 10% คืนให้ผู้จอง 80%<br/><strong>หากเกิน 3 วัน</strong> ต้องจ่ายค่าปรับจากร้อยละของจำนวนเหรียญที่จอง ให้ผู้ประกาศฯ 20% ให้แพลตฟอร์ม 30% คืนให้ผู้จอง 50%</h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseUserConfirm} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    {depositEnough &&
                    <button onClick={handleUserConfirm} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันจองเครื่องใช้ไฟฟ้ามือสอง</button>
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={openUserCancle} onClose={handleCloseUserCancle} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการจองเครื่องใช้ไฟฟ้ามือสอง</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5, flex: 1 }}>
                                                <option value="1">ไม่สะดวกไปตามเวลานัดหมาย</option>
                                                <option value="2">ซื้อสินค้าใหม่</option>
                                                <option value="3">อื่นๆ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100 }}
                                                onChange={(e) => setCancleMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={cancleMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseUserCancle} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleUserCancle} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการจองเครื่องใช้ไฟฟ้ามือสอง</button>
                </DialogActions>
            </Dialog>
        </div>
    </>
)
}

export default Offers;