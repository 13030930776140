import React, { useState, useEffect } from 'react'
import { collection, query, onSnapshot, addDoc, orderBy, updateDoc, doc, deleteDoc, getDocs, where } from "firebase/firestore"
import { db } from "../../assets/js/firebase"
import { useNavigate } from "react-router-dom"
import { RiAdminLine } from "react-icons/ri"
import { FiDelete, FiEdit, FiInfo } from "react-icons/fi"
import { GiElectric } from "react-icons/gi"
import { IoCloseCircle, IoCheckmarkCircleSharp } from "react-icons/io5"
import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'

const AirBrandList = () => {
    const navigate = useNavigate();
    const [brandList, setBrandList] = useState([])
    const [name, setName] = useState('')
    const [nameErrorDiv, setNameErrorDiv] = useState(false)
    const [editId, setEditId] = useState('')
    const [nameEdit, setNameEdit] = useState('')
    const [openEdit, setOpenEdit] = useState(false)
    const [nameEditErrorDiv, setNameEditErrorDiv] = useState(false)
    const [deleteId, setDeleteId] = useState('')
    const [openAlertDelete, setOpenAlertDelete] = useState(false)

    const [selectedBrandName, setSelectedBrandName] = useState('')
    const [selectedBrandId, setSelectedBrandId] = useState('')

    const [modelList, setModelList] = useState([])
    const [modelName, setModelName] = useState('')
    const [modelNameErrorDiv, setModelNameErrorDiv] = useState(false)
    const [editModelId, setEditModelId] = useState('')
    const [modelNameEdit, setModelNameEdit] = useState('')
    const [openEditModel, setOpenEditModel] = useState(false)
    const [modelNameEditErrorDiv, setModelNameEditErrorDiv] = useState(false)
    const [deleteModelId, setDeleteModelId] = useState('')
    const [openAlertDeleteModel, setOpenAlertDeleteModel] = useState(false)

    const isSpecialChar = (text) => {
        return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/.test(text)
    }

    const handleCloseAlertDelete = () => {
        setDeleteId('')
        setOpenAlertDelete(false)
    }

    const handleCloseAlertDeleteModel = () => {
        setDeleteModelId('')
        setOpenAlertDeleteModel(false)
    }

    const handleOpenAlertDelete = (id) => {
        setDeleteId(id)
        setOpenAlertDelete(true)
    }

    const handleOpenAlertDeleteModel = (id) => {
        setDeleteModelId(id)
        setOpenAlertDeleteModel(true)
    }

    const handleDelete = () => {
        deleteDoc(doc(db, `air_brands`, deleteId)).then(() => {
            handleCloseAlertDelete()
        })
    }

    const handleDeleteModel = () => {
        deleteDoc(doc(db, `air_models`, deleteModelId)).then(() => {
            handleGetModel(selectedBrandId, selectedBrandName)
            handleCloseAlertDeleteModel()
        })
    }

    const validateFormEdit = () => {
        if(!nameEdit || isSpecialChar(nameEdit)) {
            setNameEditErrorDiv(true)
            return false;
        } else {
            setNameEditErrorDiv(false)
        }
        return true
    }

    const validateFormEditModel = () => {
        if(!modelNameEdit || isSpecialChar(modelNameEdit)) {
            setModelNameEditErrorDiv(true)
            return false;
        } else {
            setModelNameEditErrorDiv(false)
        }
        return true
    }

    const handleEdit = (e) => {
    	e.preventDefault()
        if(validateFormEdit()) {
            updateDoc(doc(db, `air_brands`, editId), {
                name: nameEdit
            }).then(() => {
                setNameEdit('')
                handleCloseEdit()
            })
        }
    }

    const handleEditModel = (e) => {
    	e.preventDefault()
        if(validateFormEditModel()) {
            updateDoc(doc(db, `air_models`, editModelId), {
                name: modelNameEdit
            }).then(() => {
                setModelNameEdit('')
                handleGetModel(selectedBrandId, selectedBrandName)
                handleCloseEditModel()
            })
        }
    }

    const handleOpenEdit = (id, name) => {
        setEditId(id)
        setNameEdit(name)
        setNameEditErrorDiv(false)
        setOpenEdit(true)
    }

    const handleOpenEditModel = (id, name) => {
        setEditModelId(id)
        setModelNameEdit(name)
        setModelNameEditErrorDiv(false)
        setOpenEditModel(true)
    }

    const handleCloseEdit = () => {
        setEditId('')
        setNameEdit('')
        setNameEditErrorDiv(false)
        setOpenEdit(false)
    }

    const handleCloseEditModel = () => {
        setEditModelId('')
        setModelNameEdit('')
        setModelNameEditErrorDiv(false)
        setOpenEditModel(false)
    }

    const validateForm = () => {
        if(!name || isSpecialChar(name)) {
            setNameErrorDiv(true)
            return false;
        } else {
            setNameErrorDiv(false)
        }
        return true
    }

    const validateFormModel = () => {
        if(!modelName || isSpecialChar(modelName)) {
            setModelNameErrorDiv(true)
            return false;
        } else {
            setModelNameErrorDiv(false)
        }
        return true
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()
        if(validateForm()) {
            await addDoc(collection(db, "air_brands"), {
                name: name
            }).then(() => {
                setName('')
            })
        }
    }

    const handleSubmitModel = async (e) => {
    	e.preventDefault()
        if(validateFormModel()) {
            await addDoc(collection(db, "air_models"), {
                air_brand_id: selectedBrandId,
                name: modelName
            }).then(() => {
                handleGetModel(selectedBrandId, selectedBrandName)
                setModelName('')
            })
        }
    }

    const handleGetModel = (brandId, name) => {
        setSelectedBrandId(brandId)
        setSelectedBrandName(name)
        getDocs(query(collection(db, 'air_models'), where('air_brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            console.log(modelDocs.docs.length)
            setModelList(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    useEffect(() => {
        const brandQuery = query(collection(db, 'air_brands'), orderBy('name', 'asc'))
        onSnapshot(brandQuery, (querySnapshot) => {
            setBrandList(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 2, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><RiAdminLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายการยี่ห้อแอร์</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ display: 'flex', flexDirection: 'column', marginRight: 20, paddingRight: 20, borderRight: "1px solid #dddddd"}}>
                        <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                            <h6><GiElectric style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>เพิ่มยี่ห้อแอร์</b></h6>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 60}}><strong>ชื่อ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='name' onChange={(e) => setName(e.target.value)} 
                                            value={name} placeholder='ชื่อ'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {nameErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกยี่ห้อแอร์</strong>
                                            </div>
                                        }
                                    </div>
                                </div>  
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop:7}}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                            </div>
                        </div>
                    <table className="table" style={{ marginLeft: 0, flex: 1}}>
                        <thead>
                            <tr>
                                <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ยี่ห้อแอร์</th>
                                <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            brandList?.map((brand, i) => (
                                <tr key={i} style={{ display: 'flex', flexDirection: 'row'}}>
                                    <th scope="row" style={{ display: 'flex', flexDirection: 'row', flex: 1}}>{brand.data.name}</th>
                                    <td style={{ display: 'flex', flexDirection: 'row'}}>
                                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenEdit(brand.id, brand.data.name) }}><FiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข</button>
                                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenAlertDelete(brand.id) }}><FiDelete style={{ fontSize: 20, marginRight: 5 }} />ลบ</button>
                                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleGetModel(brand.id, brand.data.name) }}><FiInfo style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูลรุ่น</button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                        {selectedBrandId != '' && 
                        <>
                        <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                            <h6><GiElectric style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>เพิ่มรุ่นแอร์ ภายใต้ยี่ห้อ {selectedBrandName}</b></h6>
                        </div>

                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1}}>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 80}}><strong>ชื่อรุ่น <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='modelName' onChange={(e) => setModelName(e.target.value)} 
                                            value={modelName} placeholder='ชื่อรุ่น'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {modelNameErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกรุ่นแอร์</strong>
                                            </div>
                                        }
                                    </div>
                                </div>  
                            </div>
                            <div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop:7}}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmitModel}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                            </div>
                        </div></>
                        }
                        {modelList.length > 0 && 
                        <div>
                            <table className="table" style={{ marginLeft:0}}>
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>รุ่นแอร์ในยี่ห้อ {selectedBrandName}</th>
                                        <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    modelList?.map((model, i) => (
                                        <tr key={i}>
                                            <th scope="row">{model.data.name}</th>
                                            <td style={{ display: 'flex', flexDirection: 'row'}}>
                                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenEditModel(model.id, model.data.name) }}><FiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข</button>
                                                <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenAlertDeleteModel(model.id) }}><FiDelete style={{ fontSize: 20, marginRight: 5 }} />ลบ</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>}
                    </div>
                </div>
            </div>
            <Dialog open={openEdit} onClose={handleCloseEdit}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>แก้ไขยี่ห้อแอร์</strong></div></DialogTitle>
                <DialogContent>
                    <div><h6>ชื่อยี่ห้อแอร์</h6></div>
                    <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={nameEdit}
                        onChange={(e) => setNameEdit(e.target.value)}
                        style={{ paddingLeft: 5, marginTop: 15 }}
                    />
                    {nameEditErrorDiv &&
                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                            <strong>** กรุณากรอกยี่ห้อแอร์</strong>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseEdit} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleEdit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertDelete} onClose={handleCloseAlertDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบยี่ห้อแอร์นี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDelete} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { handleDelete() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>


            <Dialog open={openEditModel} onClose={handleCloseEditModel}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>แก้ไขรุ่นแอร์</strong></div></DialogTitle>
                <DialogContent>
                    <div><h6>ชื่อรุ่นแอร์</h6></div>
                    <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={modelNameEdit}
                        onChange={(e) => setModelNameEdit(e.target.value)}
                        style={{ paddingLeft: 5, marginTop: 15 }}
                    />
                    {modelNameEditErrorDiv &&
                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                            <strong>** กรุณากรอกยรุ่นแอร์</strong>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseEditModel} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleEditModel} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertDeleteModel} onClose={handleCloseAlertDeleteModel} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบรุ่นแอร์นี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDeleteModel} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { handleDeleteModel() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default AirBrandList;