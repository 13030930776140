import React, { useState, useEffect } from 'react'
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, addDoc, updateDoc, doc, onSnapshot, setDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'
import addImage from "../../assets/images/add-image-icon.png"
import { AiOutlineCheckCircle } from "react-icons/ai"
import { Loading } from "../layouts/Loading"

export function AppointmentShopAir({appointment, exchangeCoin}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(false)

    const appointmentId = appointment.id
    const userId = appointment.data.user_id
    const postId = appointment.data.post_id
    const depositCoin = appointment.data.deposit_coin * 1
    const offer = appointment.offer
    const reportBefore = appointment.checkReportBefore
    const reportAfter = appointment.checkReportAfter

    const [message, setMessage] = useState('')
    const [reason, setReason] = useState('')

    const [openConfirmAir, setOpenConfirmAir] = useState(false)
    const [openCancleAir, setOpenCancleAir] = useState(false)
    const [openCancleJobAir, setOpenCancleJobAir] = useState(false)

    const [openCheckBeforeAir, setOpenCheckBeforeAir] = useState(false)
    const [openShowBeforeAir, setOpenShowBeforeAir] = useState(false)
    const [openReportAfterAir, setOpenReportAfterAir] = useState(false)
    const [openShowReportAfterAir, setOpenShowReportAfterAir] = useState(false)

    const jobStep1 = offer.offer_step1?offer.offer_step1:false
    const jobStep2 = offer.offer_step2?offer.offer_step2:false
    const jobStep3 = offer.offer_step3?offer.offer_step3:false
    const jobStep4 = offer.offer_step4?offer.offer_step4:false
    const jobStep5 = offer.offer_step5?offer.offer_step5:false
    const jobStep6 = offer.offer_step6?offer.offer_step6:false
    const jobStep7 = offer.offer_step7?offer.offer_step7:false
    const jobStep8 = offer.offer_step8?offer.offer_step8:false
    const jobStep9 = offer.offer_step9?offer.offer_step9:false
    const jobStep10 = offer.offer_step10?offer.offer_step10:false
    const jobStep11 = offer.offer_step11?offer.offer_step11:false
    const jobStep12 = offer.offer_step12?offer.offer_step12:false
    const jobStep13 = offer.offer_step13?offer.offer_step13:false
    const jobStep14 = offer.offer_step14?offer.offer_step14:false
    const jobStep15 = offer.offer_step15?offer.offer_step15:false
    const jobStep16 = offer.offer_step16?offer.offer_step16:false

    const showBeforeAirSymptom = reportBefore?reportBefore.beforeAirSymptom:''
    const showAirBefore1 = reportBefore?reportBefore.check_air_before1:false
    const showAirBefore2 = reportBefore?reportBefore.check_air_before2:false
    const showAirBefore3 = reportBefore?reportBefore.check_air_before3:false
    const showAirBefore4 = reportBefore?reportBefore.check_air_before4:false
    const showAirBefore5 = reportBefore?reportBefore.check_air_before5:false
    const showAirBefore6 = reportBefore?reportBefore.check_air_before6:false
    const showAirBefore7 = reportBefore?reportBefore.check_air_before7:false
    const showAirBefore8 = reportBefore?reportBefore.check_air_before8:false
    const showAirBefore9 = reportBefore?reportBefore.check_air_before9:false
    const showAirBefore10 = reportBefore?reportBefore.check_air_before10:false
    const showAirBefore11 = reportBefore?reportBefore.check_air_before11:false
    const showAirBefore12 = reportBefore?reportBefore.check_air_before12:false
    const showAirBefore13 = reportBefore?reportBefore.check_air_before13:false
    const showAirBefore14 = reportBefore?reportBefore.check_air_before14:false
    const showAirBefore15 = reportBefore?reportBefore.check_air_before15:false
    const showAirBefore16 = reportBefore?reportBefore.check_air_before16:false
    const showAirBefore17 = reportBefore?reportBefore.check_air_before17:false
    const showAirBefore18 = reportBefore?reportBefore.check_air_before18:false

    const afterAirSymptomShow = reportBefore?reportBefore.afterAirSymptom:''
    const jobReportStep1Show = reportAfter?reportAfter.job_report_step1:false
    const jobReportStep2Show = reportAfter?reportAfter.job_report_step2:false
    const jobReportStep3Show = reportAfter?reportAfter.job_report_step3:false
    const jobReportStep4Show = reportAfter?reportAfter.job_report_step4:false
    const jobReportStep5Show = reportAfter?reportAfter.job_report_step5:false
    const jobReportStep6Show = reportAfter?reportAfter.job_report_step6:false
    const jobReportStep7Show = reportAfter?reportAfter.job_report_step7:false
    const jobReportStep8Show = reportAfter?reportAfter.job_report_step8:false
    const jobReportStep9Show = reportAfter?reportAfter.job_report_step9:false
    const jobReportStep10Show = reportAfter?reportAfter.job_report_step10:false
    const jobReportStep11Show = reportAfter?reportAfter.job_report_step11:false
    const jobReportStep12Show = reportAfter?reportAfter.job_report_step12:false
    const jobReportStep13Show = reportAfter?reportAfter.job_report_step13:false
    const jobReportStep14Show = reportAfter?reportAfter.job_report_step14:false
    const jobReportStep15Show = reportAfter?reportAfter.job_report_step15:false
    const jobReportStep16Show = reportAfter?reportAfter.job_report_step16:false
    const [reportImages, setReportImages] = useState([])

    const [beforeAirSymptom, setBeforeAirSymptom] = useState('')
    const [checkAirBefore1, setCheckAirBefore1] = useState(false)
    const [checkAirBefore2, setCheckAirBefore2] = useState(false)
    const [checkAirBefore3, setCheckAirBefore3] = useState(false)
    const [checkAirBefore4, setCheckAirBefore4] = useState(false)
    const [checkAirBefore5, setCheckAirBefore5] = useState(false)
    const [checkAirBefore6, setCheckAirBefore6] = useState(false)
    const [checkAirBefore7, setCheckAirBefore7] = useState(false)
    const [checkAirBefore8, setCheckAirBefore8] = useState(false)
    const [checkAirBefore9, setCheckAirBefore9] = useState(false)
    const [checkAirBefore10, setCheckAirBefore10] = useState(false)
    const [checkAirBefore11, setCheckAirBefore11] = useState(false)
    const [checkAirBefore12, setCheckAirBefore12] = useState(false)
    const [checkAirBefore13, setCheckAirBefore13] = useState(false)
    const [checkAirBefore14, setCheckAirBefore14] = useState(false)
    const [checkAirBefore15, setCheckAirBefore15] = useState(false)
    const [checkAirBefore16, setCheckAirBefore16] = useState(false)
    const [checkAirBefore17, setCheckAirBefore17] = useState(false)
    const [checkAirBefore18, setCheckAirBefore18] = useState(false)

    const [jobReportStep1, setJobReportStep1] = useState(false)
    const [jobReportStep2, setJobReportStep2] = useState(false)
    const [jobReportStep3, setJobReportStep3] = useState(false)
    const [jobReportStep4, setJobReportStep4] = useState(false)
    const [jobReportStep5, setJobReportStep5] = useState(false)
    const [jobReportStep6, setJobReportStep6] = useState(false)
    const [jobReportStep7, setJobReportStep7] = useState(false)
    const [jobReportStep8, setJobReportStep8] = useState(false)
    const [jobReportStep9, setJobReportStep9] = useState(false)
    const [jobReportStep10, setJobReportStep10] = useState(false)
    const [jobReportStep11, setJobReportStep11] = useState(false)
    const [jobReportStep12, setJobReportStep12] = useState(false)
    const [jobReportStep13, setJobReportStep13] = useState(false)
    const [jobReportStep14, setJobReportStep14] = useState(false)
    const [jobReportStep15, setJobReportStep15] = useState(false)
    const [jobReportStep16, setJobReportStep16] = useState(false)
    const [afterAirSymptom, setAfterAirSymptom] = useState('')
    const [srcReportAirPicture1, setSrcReportAirPicture1] = useState(addImage)
    const [srcReportAirPicture2, setSrcReportAirPicture2] = useState(addImage)
    const [srcReportAirPicture3, setSrcReportAirPicture3] = useState(addImage)
    const [srcReportAirPicture4, setSrcReportAirPicture4] = useState(addImage)
    const [srcReportAirPicture5, setSrcReportAirPicture5] = useState(addImage)
    const [reportAirPicture1, setReportAirPicture1] = useState('')
    const [reportAirPicture2, setReportAirPicture2] = useState('')
    const [reportAirPicture3, setReportAirPicture3] = useState('')
    const [reportAirPicture4, setReportAirPicture4] = useState('')
    const [reportAirPicture5, setReportAirPicture5] = useState('')

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleOpenConfirmAir = () => {
        setOpenConfirmAir(true)
    }

    const handleCloseConfirmAir = () => {
        setOpenConfirmAir(false)
    }

    const handleConfirmAir = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 2,
            confirm_datetime: logDate.getTime()
        }).then(() => {
            addDoc(collection(db, `/users/${userId}/notifications`), {
                date: logDate.getTime(),
                message: "ทางร้าน/บริษัท ยินดีให้บริการล้างแอร์ กรุณากดยืนยันหรือยกเลิก เพ่ือดำเนินการต่อไป",
                link: 'profile/appointments',
                type: 'appointment',
                appointment_id: appointmentId,
                status: 0
            }).then(() => {
                setShowLoading(false)
                navigate(0)
            })
        })
    }

    const handleOpenCancleAir = () => {
        setOpenCancleAir(true)
    }

    const handleCloseCancleAir = () => {
        setOpenCancleAir(false)
    }

    const handleCancleAir = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 4,
            shop_cancle_message: message,
            shop_cancle_reason: reason,
            shop_cancle_datetime: logDate.getTime()
        }).then(() => {
            addDoc(collection(db, `/users/${userId}/notifications`), {
                date: logDate.getTime(),
                message: "ช่าง แจ้งยกเลิกบริการ",
                link: 'profile/appointments',
                type: 'appointment',
                appointment_id: appointmentId,
                status: 0
            }).then(() => {
                updateDoc(doc(db, `cleans`, postId), { 
                    status: 5,
                }).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const handleJobReportStep1Change = () => {
        setJobReportStep1(!jobReportStep1)
    }

    const handleJobReportStep2Change = () => {
       
        setJobReportStep2(!jobReportStep2)
    }
    const handleJobReportStep3Change = () => {
        setJobReportStep3(!jobReportStep3)
    }

    const handleJobReportStep4Change = () => {
        setJobReportStep4(!jobReportStep4)
    }

    const handleJobReportStep5Change = () => {
        setJobReportStep5(!jobReportStep5)
    }

    const handleJobReportStep6Change = () => {
        setJobReportStep6(!jobReportStep6)
    }

    const handleJobReportStep7Change = () => {
        setJobReportStep7(!jobReportStep7)
    }

    const handleJobReportStep8Change = () => {
        setJobReportStep8(!jobReportStep8)
    }

    const handleJobReportStep9Change = () => {
        setJobReportStep9(!jobReportStep9)
    }

    const handleJobReportStep10Change = () => {
        setJobReportStep10(!jobReportStep10)
    }

    const handleJobReportStep11Change = () => {
        setJobReportStep11(!jobReportStep11)
    }

    const handleJobReportStep12Change = () => {
        setJobReportStep12(!jobReportStep12)
    }

    const handleJobReportStep13Change = () => {
        setJobReportStep13(!jobReportStep13)
    }

    const handleJobReportStep14Change = () => {
        setJobReportStep14(!jobReportStep14)
    }

    const handleJobReportStep15Change = () => {
        setJobReportStep15(!jobReportStep15)
    }

    const handleJobReportStep16Change = () => {
        setJobReportStep16(!jobReportStep16)
    }

    const handleOpenReportAfterAir = () => {
        setOpenReportAfterAir(true)
    }

    const handleCloseReportAfterAir = () => {
        setOpenReportAfterAir(false)
    }

    const handleReportAirImage1 = (file) => {
        setReportAirPicture1(file)
        setSrcReportAirPicture1(URL.createObjectURL(file))
    }

    const handleReportAirImage2 = (file) => {
        setReportAirPicture2(file)
        setSrcReportAirPicture2(URL.createObjectURL(file))
    }

    const handleReportAirImage3 = (file) => {
        setReportAirPicture3(file)
        setSrcReportAirPicture3(URL.createObjectURL(file))
    }

    const handleReportAirImage4 = (file) => {
        setReportAirPicture4(file)
        setSrcReportAirPicture4(URL.createObjectURL(file))
    }

    const handleReportAirImage5 = (file) => {
        setReportAirPicture5(file)
        setSrcReportAirPicture5(URL.createObjectURL(file))
    }

    const handleReportAfterAir = () => {
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 8,
            job_report_datetime: logDate.getTime()
        }).then(() => {
            setDoc(doc(db, `appointments/${appointmentId}/report/`, 'after'), {
                report_date: logDate.getTime(),
                job_report_step1: jobReportStep1,
                job_report_step2: jobReportStep2,
                job_report_step3: jobReportStep3,
                job_report_step4: jobReportStep4,
                job_report_step5: jobReportStep5,
                job_report_step6: jobReportStep6,
                job_report_step7: jobReportStep7,
                job_report_step8: jobReportStep8,
                job_report_step9: jobReportStep9,
                job_report_step10: jobReportStep10,
                job_report_step11: jobReportStep11,
                job_report_step12: jobReportStep12,
                job_report_step13: jobReportStep13,
                job_report_step14: jobReportStep14,
                job_report_step15: jobReportStep15,
                job_report_step16: jobReportStep16,
                afterAirSymptom
            }).then(() => {
                const promises = []
                if (reportAirPicture1 || reportAirPicture2 || reportAirPicture3 || reportAirPicture4 || reportAirPicture5) {
                    const fileImageUpload = []
                    if(reportAirPicture1) fileImageUpload.push(reportAirPicture1);
                    if(reportAirPicture2) fileImageUpload.push(reportAirPicture2);
                    if(reportAirPicture3) fileImageUpload.push(reportAirPicture3);
                    if(reportAirPicture4) fileImageUpload.push(reportAirPicture4);
                    if(reportAirPicture5) fileImageUpload.push(reportAirPicture5);
                    var i = 1;
                    fileImageUpload.map((file) => {
                        const storageRef = ref(storage, `/appointments/${appointmentId}/report/after/${i}`)
                        const uploadTask = uploadBytesResumable(storageRef, file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    addDoc(collection(db, `appointments/${appointmentId}/report/after/images`), {
                                        url: downloadURLs,
                                    })
                                })
                            }
                        )
                        i++
                    })
                }

                Promise.all(promises).then(() => {
                    setOpenReportAfterAir(false)
                    navigate(0)
                })
            })
        })
        
    }

    const handleOpenShowReportAfterAir = () => {
        setOpenShowReportAfterAir(true)
    }

    const handleCloseShowReportAfterAir = () => {
        setOpenShowReportAfterAir(false)
    }

    const handleOpenCancleJobAir = () => {
        setOpenCancleJobAir(true)
    }

    const handleCloseCancleJobAir = () => {
        setOpenCancleJobAir(false)
    }

    const handleCancleJobAir = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 10,
            shop_cancle_message: message,
            shop_cancle_reason: reason,
            shop_cancle_job_datetime: logDate.getTime()
        }).then(() => {
            var promisesTask = []
            var addNotiTask = addDoc(collection(db, `/users/${userId}/notifications`), {
                date: logDate.getTime(),
                message: "ช่าง แจ้งยกเลิกบริการ",
                link: 'profile/appointments',
                type: 'appointment',
                appointment_id: appointmentId,
                status: 0
            })
            promisesTask.push(addNotiTask)

            var updatePostTask = updateDoc(doc(db, `cleans`, postId), { 
                status: 5,
            })
            promisesTask.push(updatePostTask)

            var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
                coin: depositCoin,
                date: logDate.getTime(),
                event_type: 'deposit',
                event: 'คืนมัดจำ 30% ของค่าบริการล้างแอร์',
                type: 'decrease',
                post_type: 'air',
                post_id: postId,
                appointment_id: appointmentId
            })
            promisesTask.push(addCoinLogTask)

            var getUserCoinTask = updateDoc(doc(db, 'users/', userId), {
                    exchange_coin: (exchangeCoin * 1) + depositCoin
            })
            promisesTask.push(getUserCoinTask)

            var addUserCoinLogTask = addDoc(collection(db, `users/${userId}/coin_log/`), {
                coin: depositCoin,
                date: logDate.getTime(),
                event_type: 'deposit',
                event: 'คืนค่ามัดจำ 30% ของค่าบริการล้างแอร์',
                coin_type: 'exchange',
                post_type: 'air',
                post_id: postId,
                appointment_id: appointmentId
            })
            promisesTask.push(addUserCoinLogTask)
        
            Promise.all(promisesTask).then(() => {
                setShowLoading(false)
                navigate(0)
            })
        })
    }

    const handleOpenCheckBeforeAir = () => {
        setOpenCheckBeforeAir(true)
    }

    const handleCloseCheckBeforeAir = () => {
        setOpenCheckBeforeAir(false)
    }

    const handleCheckBeforeAir = () => {
        const reportDate = new Date()
        setDoc(doc(db, `appointments/${appointmentId}/report/`, 'before'), {
            report_date: reportDate,
            check_air_before1: checkAirBefore1,
            check_air_before2: checkAirBefore2,
            check_air_before3: checkAirBefore3,
            check_air_before4: checkAirBefore4,
            check_air_before5: checkAirBefore5,
            check_air_before6: checkAirBefore6,
            check_air_before7: checkAirBefore7,
            check_air_before8: checkAirBefore8,
            check_air_before9: checkAirBefore9,
            check_air_before10: checkAirBefore10,
            check_air_before11: checkAirBefore11,
            check_air_before12: checkAirBefore12,
            check_air_before13: checkAirBefore13,
            check_air_before14: checkAirBefore14,
            check_air_before15: checkAirBefore15,
            check_air_before16: checkAirBefore16,
            check_air_before17: checkAirBefore17,
            check_air_before18: checkAirBefore18,
            beforeAirSymptom
        }).then(() => {
            setOpenCheckBeforeAir(false)
            navigate(0)
        })
    }

    const handleCheckAirBefore1Change = () => {
        setCheckAirBefore1(!checkAirBefore1)
    }

    const handleCheckAirBefore2Change = () => {
        setCheckAirBefore2(!checkAirBefore2)
    }

    const handleCheckAirBefore3Change = () => {
        setCheckAirBefore3(!checkAirBefore3)
    }

    const handleCheckAirBefore4Change = () => {
        setCheckAirBefore4(!checkAirBefore4)
    }

    const handleCheckAirBefore5Change = () => {
        setCheckAirBefore5(!checkAirBefore5)
    }

    const handleCheckAirBefore6Change = () => {
        setCheckAirBefore6(!checkAirBefore6)
    }

    const handleCheckAirBefore7Change = () => {
        setCheckAirBefore7(!checkAirBefore7)
    }

    const handleCheckAirBefore8Change = () => {
        setCheckAirBefore8(!checkAirBefore8)
    }

    const handleCheckAirBefore9Change = () => {
        setCheckAirBefore9(!checkAirBefore9)
    }

    const handleCheckAirBefore10Change = () => {
        setCheckAirBefore10(!checkAirBefore10)
    }

    const handleCheckAirBefore11Change = () => {
        setCheckAirBefore11(!checkAirBefore11)
    }

    const handleCheckAirBefore12Change = () => {
        setCheckAirBefore12(!checkAirBefore12)
    }

    const handleCheckAirBefore13Change = () => {
        setCheckAirBefore13(!checkAirBefore13)
    }

    const handleCheckAirBefore14Change = () => {
        setCheckAirBefore14(!checkAirBefore14)
    }

    const handleCheckAirBefore15Change = () => {
        setCheckAirBefore15(!checkAirBefore15)
    }

    const handleCheckAirBefore16Change = () => {
        setCheckAirBefore16(!checkAirBefore16)
    }
    
    const handleCheckAirBefore17Change = () => {
        setCheckAirBefore17(!checkAirBefore17)
    }

    const handleCheckAirBefore18Change = () => {
        setCheckAirBefore18(!checkAirBefore18)
    }

    const handleOpenShowBeforeAir = () => {
        setOpenShowBeforeAir(true)
    }

    const handleCloseShowBeforeAir = () => {
        setOpenShowBeforeAir(false)
    }

    useEffect(() => {
        if(reportAfter) {
            const imagesQuery = query(collection(db, `appointments/${appointmentId}/report/after/images`))
            onSnapshot(imagesQuery, (querySnapshot) => {
                setReportImages(querySnapshot.docs.map((doc, i) => { 
                    return {
                        id: doc.id,
                        data: doc.data(),
                    }
                }))
            })
        }
    })

    return (
        <>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
                {appointment.badgeStyle.show && (<>
                    <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                        <button type="button" className={"btn "+"btn-"+appointment.badgeStyle.class} style={{ color: appointment.badgeStyle.color }}><strong>{appointment.badgeStyle.message}</strong></button>
                    </div>
                </>)}
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                    <img src={appointment.post.picture} alt="APP3R" height="150" />
                </div>
                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                    <p className="card-text">
                        <strong>ประเภทนัดหมาย:</strong> ล้างแอร์<br/>
                        <strong>วันนัดหมาย:</strong> {dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/>
                        <strong>ที่อยู่:</strong> {appointment.post.address} <br/>
                        {appointment.post.brand} {appointment.data.model}<br/>
                    </p>
                </div>
                <div className="card-body">
                    <Link className="btn btn-primary m-1" to={`/postclean/${appointment.data.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                    <Link className="btn btn-primary m-1" to={`/shop/air/offer/${appointment.data.post_id}`} target='_blank'><strong>ดูข้อเสนอ</strong></Link>
                    {!reportBefore && (<>
                        <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenCheckBeforeAir() }}>บันทึกสำรวจหน้างาน</button>
                    </>)}
                    {reportBefore && (<>
                        <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenShowBeforeAir() }}>ดูรายงานสำรวจหน้างาน</button>
                        {(appointment.data.status==1) && (<>
                            <button type="button" className="btn btn-success m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenConfirmAir() }}>ยืนยันให้บริการ</button>
                            <button type="button" className="btn btn-danger m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenCancleAir() }}>ยกเลิกการให้บริการ</button>
                        </>)}
                    </>)}
                    {!reportAfter && (<>
                        {(appointment.data.status==3) && (<>
                            <button type="button" className="btn btn-danger m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenCancleJobAir() }}>ยกเลิกงาน</button>
                            <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenReportAfterAir() }}>บันทึกส่งมอบงาน</button>
                        </>)}
                    </>)}
                    {reportAfter && (<>
                        <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenShowReportAfterAir() }}>ดูรายงานบันทึกส่งมอบงาน</button>
                    </>)}
                </div>
            </div>

            <Dialog open={openShowBeforeAir} onClose={handleCloseShowBeforeAir} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานสำรวจหน้างาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อาการผิดหลังสำรวจ</strong></h5></label>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore1)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ความเย็นอยู่ในระดับปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore2)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    บานสวิงทำงานได้ปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore3)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    เสียงการทำงานของคอยล์เย็นปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore4)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    เสียงการทำงานของคอยล์ร้อนปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore5)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    เช็คแรงดันน้ำยาปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore6)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ค่ากระแสไฟที่วัดได้ปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore7)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ถอดฟิลเตอร์สภาพปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore8)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    หน้ากากแอร์สภาพปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore9)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ถาดน้ำทิ้งสภาพปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore10)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    คอยล์ร้อนหรือคอยล์เย็นติดตั้งไม่สูงเกิน 3เมตร สามารถทำงานได้</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore11)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    คอยล์ร้อนหรือคอยล์เย็นติดตั้งสูงเกิน 3 เมตร แต่สามารถทำงานได้</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore12)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    คอยล์ร้อนหรือคอยล์เย็นติดตั้งสูงเกิน 3 เมตร ไม่สามารถทำงานได้</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore13)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    บริเวณติดตั้งคอยล์ร้อนหรือคอยล์เย็น ไม่สามารถเข้าทำงานได้</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore14)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ถึงที่พักแต่ไม่พบผู้ประกาศหรือไม่สามารถเข้าถึงเครื่องใช้ไฟฟ้าได้</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore15)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    สภาพพื้นที่ไม่อำนวยต่อการซ่อม</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore16)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    ไม่ได้รับความร่วมมือจากผู้เรียกใช้บริการ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore17)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    มีความเสี่ยงในการเกิดอุบัติเหตุ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    <AiOutlineCheckCircle className={"text-"+((showAirBefore18)?"success":"danger")} style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                                    แอร์มีความผิดปกติ ควรดำเนิการซ่อม</font>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อื่นๆ</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>{showBeforeAirSymptom}</div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShowBeforeAir} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCheckBeforeAir} onClose={handleCloseCheckBeforeAir} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานสำรวจหน้างาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อาการผิดหลังสำรวจ</strong></h5></label>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore1?"checked":""} onChange={(e) => handleCheckAirBefore1Change(e.target.value)} /> ความเย็นอยู่ในระดับปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore2?"checked":""} onChange={(e) => handleCheckAirBefore2Change(e.target.value)} /> บานสวิงทำงานได้ปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore3?"checked":""} onChange={(e) => handleCheckAirBefore3Change(e.target.value)} /> เสียงการทำงานของคอยล์เย็นปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore4?"checked":""} onChange={(e) => handleCheckAirBefore4Change(e.target.value)} /> เสียงการทำงานของคอยล์ร้อนปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore5?"checked":""} onChange={(e) => handleCheckAirBefore5Change(e.target.value)} /> เช็คแรงดันน้ำยาปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore6?"checked":""} onChange={(e) => handleCheckAirBefore6Change(e.target.value)} /> ค่ากระแสไฟที่วัดได้ปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore7?"checked":""} onChange={(e) => handleCheckAirBefore7Change(e.target.value)} /> ถอดฟิลเตอร์สภาพปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore8?"checked":""} onChange={(e) => handleCheckAirBefore8Change(e.target.value)} /> หน้ากากแอร์สภาพปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore9?"checked":""} onChange={(e) => handleCheckAirBefore9Change(e.target.value)} /> ถาดน้ำทิ้งสภาพปกติ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore10?"checked":""} onChange={(e) => handleCheckAirBefore10Change(e.target.value)} /> คอยล์ร้อนหรือคอยล์เย็นติดตั้งไม่สูงเกิน 3เมตร สามารถทำงานได้</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore11?"checked":""} onChange={(e) => handleCheckAirBefore11Change(e.target.value)} /> คอยล์ร้อนหรือคอยล์เย็นติดตั้งสูงเกิน 3 เมตร แต่สามารถทำงานได้</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore12?"checked":""} onChange={(e) => handleCheckAirBefore12Change(e.target.value)} /> คอยล์ร้อนหรือคอยล์เย็นติดตั้งสูงเกิน 3 เมตร ไม่สามารถทำงานได้</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore13?"checked":""} onChange={(e) => handleCheckAirBefore13Change(e.target.value)} /> บริเวณติดตั้งคอยล์ร้อนหรือคอยล์เย็น ไม่สามารถเข้าทำงานได้</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore14?"checked":""} onChange={(e) => handleCheckAirBefore14Change(e.target.value)} /> ถึงที่พักแต่ไม่พบผู้ประกาศหรือไม่สามารถเข้าถึงเครื่องใช้ไฟฟ้าได้</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore15?"checked":""} onChange={(e) => handleCheckAirBefore15Change(e.target.value)} /> สภาพพื้นที่ไม่อำนวยต่อการซ่อม</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore16?"checked":""} onChange={(e) => handleCheckAirBefore16Change(e.target.value)} /> ไม่ได้รับความร่วมมือจากผู้เรียกใช้บริการ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore17?"checked":""} onChange={(e) => handleCheckAirBefore17Change(e.target.value)} /> มีความเสี่ยงในการเกิดอุบัติเหตุ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={checkAirBefore18?"checked":""} onChange={(e) => handleCheckAirBefore18Change(e.target.value)} /> แอร์มีความผิดปกติ ควรดำเนิการซ่อม</font>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อื่นๆ</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>
                                                    <textarea style={{ display: 'flex', flex: 1 }}
                                                        onChange={(e) => setBeforeAirSymptom(e.target.value)}
                                                        placeholder='อื่นๆ' 
                                                        value={beforeAirSymptom}></textarea>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCheckBeforeAir} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCheckBeforeAir} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึกสำรวจหน้างาน</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirmAir} onClose={handleCloseConfirmAir} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันให้บริการ</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันให้บริการงานนี้</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseConfirmAir} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleConfirmAir} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันให้บริการ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancleAir} onClose={handleCloseCancleAir} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการให้บริการล้างแอร์</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ไม่รับบริการ</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5, paddingLeft: 10, paddingRight: 10 }}>
                                                <option value="1">ไม่ได้รับความร่วมมือ</option>
                                                <option value="2">สภาพแวดล้อมไม่เหมาะกับการดาเนินงาน</option>
                                                <option value="3">เกิดอุบัติเหตุระหว่างดำเนินงาน</option>
                                                <option value="4">มีการเรียกรับเงิน</option>
                                                <option value="5">ควรดาเนินการซ่อม</option>
                                                <option value="6">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleAir} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleAir} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการให้บริการ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openShowReportAfterAir} onClose={handleCloseShowReportAfterAir} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานส่งมอบงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>ขั้นตอนการดำเนินงาน</strong></h5></label>
                                                {jobReportStep1Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (ก่อนล้าง)</font>}
                                                {jobReportStep2Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ปิดเบรกเกอร์แอร์</font>}
                                                {jobReportStep3Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ปูผ้าใบกันเปื้อนรอบบริเวณพื้นที่ที่ต้องการล้างทำความสะอาดแอร์ ตรวจสอบการทำงานของแอร์ บานสวิง วัดอุณหภูมิและกระแสไฟฟ้า</font>}
                                                {jobReportStep4Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ล้างทำความสะอาดแผงฟิลคอยด์</font>}
                                                {jobReportStep5Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ล้างทำความสะอาดแผ่นฟิลเตอร์</font>}
                                                {jobReportStep6Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ล้างทำความสะอาดพัดลมกรงกระรอก</font>}
                                                {jobReportStep7Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ดูดและเป่าท่อน้ำทิ้ง</font>}
                                                {jobReportStep8Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ทำความสะอาดตัวเครื่อง (FAN COIL)</font>}
                                                {jobReportStep9Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> เป่าไล่ความชื้นในส่วนต่าง ๆ</font>}
                                                {jobReportStep10Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ฉีดสเปรย์ฆ่าเชื้อโรคแผงฟิลคอยล์</font>}
                                                {jobReportStep11Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ล้างทำความสะอาดคอยล์ร้อน</font>}
                                                {jobReportStep12Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบแผงควบคุม และการทำงานของเครื่อง</font>}
                                                {jobReportStep13Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจวัดอุณหภูมิความเย็น</font>}
                                                {jobReportStep14Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (หลังล้าง)</font>}
                                                {jobReportStep15Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบสายกราวน์และเช็คจุดต่อสายไฟ</font>}
                                                {jobReportStep16Show && <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> เปิดเครื่องทดสอบประมาณ 15 นาที พร้อมตรวจสอบการทางานของแอร์ เช่น ไฟหน้าเครื่อง ฟังเสียง อุณหภูมิ บานสวิง มีอาการน้ำหยดหรือไม่</font>}
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>รายงานเพิ่มเติม</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>
                                                    <textarea style={{ display: 'flex', flex: 1 }}
                                                        placeholder='รายงานเพิ่มเติม' readOnly={true}
                                                        value={afterAirSymptomShow}></textarea>
                                                </div>
                                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                    <label className="col-form-label" style={{ display: 'flex'}}><font style={{ fontSize: 17 }}><strong>รูปภาพงานเพิ่มเติม <font className="text-danger">*</font></strong></font></label>
                                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                                                        <div className="card-body mx-auto">
                                                            <div className="d-flex flex-wrap">
                                                                {reportImages?.map((image, i) => (
                                                                <div key={image.id} className="m-2">
                                                                    <img src={image.data.url} alt="APP3R" width="120" />
                                                                </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShowReportAfterAir} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openReportAfterAir} onClose={handleCloseReportAfterAir} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานส่งมอบงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>ขั้นตอนการดำเนินงาน</strong></h5></label>
                                                {jobStep1 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep1?"checked":""} onChange={(e) => handleJobReportStep1Change(e.target.value)} /> ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (ก่อนล้าง)</font>}
                                                {jobStep2 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep2?"checked":""} onChange={(e) => handleJobReportStep2Change(e.target.value)} /> ปิดเบรกเกอร์แอร์</font>}
                                                {jobStep3 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep3?"checked":""} onChange={(e) => handleJobReportStep3Change(e.target.value)} /> ปูผ้าใบกันเปื้อนรอบบริเวณพื้นที่ที่ต้องการล้างทำความสะอาดแอร์ ตรวจสอบการทำงานของแอร์ บานสวิง วัดอุณหภูมิและกระแสไฟฟ้า</font>}
                                                {jobStep4 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep4?"checked":""} onChange={(e) => handleJobReportStep4Change(e.target.value)} /> ล้างทำความสะอาดแผงฟิลคอยด์</font>}
                                                {jobStep5 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep5?"checked":""} onChange={(e) => handleJobReportStep5Change(e.target.value)} /> ล้างทำความสะอาดแผ่นฟิลเตอร์</font>}
                                                {jobStep6 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep6?"checked":""} onChange={(e) => handleJobReportStep6Change(e.target.value)} /> ล้างทำความสะอาดพัดลมกรงกระรอก</font>}
                                                {jobStep7 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep7?"checked":""} onChange={(e) => handleJobReportStep7Change(e.target.value)} /> ดูดและเป่าท่อน้ำทิ้ง</font>}
                                                {jobStep8 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep8?"checked":""} onChange={(e) => handleJobReportStep8Change(e.target.value)} /> ทำความสะอาดตัวเครื่อง (FAN COIL)</font>}
                                                {jobStep9 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep9?"checked":""} onChange={(e) => handleJobReportStep9Change(e.target.value)} /> เป่าไล่ความชื้นในส่วนต่าง ๆ</font>}
                                                {jobStep10 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep10?"checked":""} onChange={(e) => handleJobReportStep10Change(e.target.value)} /> ฉีดสเปรย์ฆ่าเชื้อโรคแผงฟิลคอยล์</font>}
                                                {jobStep11 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep11?"checked":""} onChange={(e) => handleJobReportStep11Change(e.target.value)} /> ล้างทำความสะอาดคอยล์ร้อน</font>}
                                                {jobStep12 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep12?"checked":""} onChange={(e) => handleJobReportStep12Change(e.target.value)} /> ตรวจสอบแผงควบคุม และการทำงานของเครื่อง</font>}
                                                {jobStep13 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep13?"checked":""} onChange={(e) => handleJobReportStep13Change(e.target.value)} /> ตรวจวัดอุณหภูมิความเย็น</font>}
                                                {jobStep14 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep14?"checked":""} onChange={(e) => handleJobReportStep14Change(e.target.value)} /> ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (หลังล้าง)</font>}
                                                {jobStep15 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep15?"checked":""} onChange={(e) => handleJobReportStep15Change(e.target.value)} /> ตรวจสอบสายกราวน์และเช็คจุดต่อสายไฟ</font>}
                                                {jobStep16 && <font style={{ fontSize: 16 }}><input type="checkbox" checked={jobReportStep16?"checked":""} onChange={(e) => handleJobReportStep16Change(e.target.value)} /> เปิดเครื่องทดสอบประมาณ 15 นาที พร้อมตรวจสอบการทางานของแอร์ เช่น ไฟหน้าเครื่อง ฟังเสียง อุณหภูมิ บานสวิง มีอาการน้ำหยดหรือไม่</font>}
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>รายงานเพิ่มเติม</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>
                                                    <textarea style={{ display: 'flex', flex: 1 }}
                                                        onChange={(e) => setAfterAirSymptom(e.target.value)}
                                                        placeholder='รายงานเพิ่มเติม' 
                                                        value={afterAirSymptom}></textarea>
                                                </div>
                                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                    <label className="col-form-label" style={{ display: 'flex'}}><font style={{ fontSize: 17 }}><strong>รูปภาพงานเพิ่มเติม <font className="text-danger">*</font></strong></font></label>
                                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportAirPicture1} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                                            <label htmlFor='fileReportAirUpload1' style={{ padding: 0}}>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportAirUpload1" type="file" accept="image/*" onChange={(e) => handleReportAirImage1(e.target.files[0])} />
                                                        </div>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportAirPicture2} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                                            <label htmlFor='fileReportAirUpload2'>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportAirUpload2" type="file" accept="image/*" onChange={(e) => handleReportAirImage2(e.target.files[0])} />
                                                        </div>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportAirPicture3} alt="APP3R" width="80"style={{marginBottom: 5}}  />
                                                            <label htmlFor='fileReportAirUpload3'>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportAirUpload3" type="file" accept="image/*" onChange={(e) => handleReportAirImage3(e.target.files[0])} />
                                                        </div>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportAirPicture4} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                                            <label htmlFor='fileReportAirUpload4'>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportAirUpload4" type="file" accept="image/*" onChange={(e) => handleReportAirImage4(e.target.files[0])} />
                                                        </div>
                                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                                            <img src={srcReportAirPicture5} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                                            <label htmlFor='fileReportAirUpload5'>
                                                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                                            </label>
                                                            <input style={{ display:'none' }} className="form-control file" id="fileReportAirUpload5" type="file" accept="image/*" onChange={(e) => handleReportAirImage5(e.target.files[0])} />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseReportAfterAir} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleReportAfterAir} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึกสำรวจหน้างาน</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancleJobAir} onClose={handleCloseCancleJobAir} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการให้บริการล้างแอร์</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ยกเลิกบริการ</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5, paddingLeft: 10, paddingRight: 10 }}>
                                                <option value="1">ไม่ได้รับความร่วมมือ</option>
                                                <option value="2">สภาพแวดล้อมไม่เหมาะกับการดาเนินงาน</option>
                                                <option value="3">เกิดอุบัติเหตุระหว่างดำเนินงาน</option>
                                                <option value="4">มีการเรียกรับเงิน</option>
                                                <option value="5">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleJobAir} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleJobAir} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการให้บริการ</button>
                </DialogActions>
            </Dialog>
        </>
    )
}