import React, { useState, useEffect } from 'react'
import { collection, query, onSnapshot, where, and, or, orderBy } from "firebase/firestore"
import { db } from "../../assets/js/firebase"
import { useNavigate, useParams } from "react-router-dom"
import { HiOutlineMegaphone } from "react-icons/hi2"
import { Card, CardContent } from '@mui/material'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'

const PostList = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()

    const [board1Num, setBoard1Num] = useState(0)
    const [board1NormalNum, setBoard1NormalNum] = useState(0)
    const [board1OfferNum, setBoard1OfferNum] = useState(0)
    const [board1AppointmentNum, setBoard1AppointmentNum] = useState(0)
    const [board1SuccessNum, setBoard1SuccessNum] = useState(0)
    const [board1SuspendNum, setBoard1SuspendNum] = useState(0)
    const [board1Lastest, setBoard1Lastest] = useState("")

    const [board2Num, setBoard2Num] = useState(0)
    const [board2NormalNum, setBoard2NormalNum] = useState(0)
    const [board2OfferNum, setBoard2OfferNum] = useState(0)
    const [board2AppointmentNum, setBoard2AppointmentNum] = useState(0)
    const [board2SuccessNum, setBoard2SuccessNum] = useState(0)
    const [board2SuspendNum, setBoard2SuspendNum] = useState(0)
    const [board2Lastest, setBoard2Lastest] = useState("")

    const [board3Num, setBoard3Num] = useState(0)
    const [board3NormalNum, setBoard3NormalNum] = useState(0)
    const [board3OfferNum, setBoard3OfferNum] = useState(0)
    const [board3AppointmentNum, setBoard3AppointmentNum] = useState(0)
    const [board3SuccessNum, setBoard3SuccessNum] = useState(0)
    const [board3SuspendNum, setBoard3SuspendNum] = useState(0)
    const [board3Lastest, setBoard3Lastest] = useState("")

    const [board4Num, setBoard4Num] = useState(0)
    const [board4NormalNum, setBoard4NormalNum] = useState(0)
    const [board4OfferNum, setBoard4OfferNum] = useState(0)
    const [board4AppointmentNum, setBoard4AppointmentNum] = useState(0)
    const [board4SuccessNum, setBoard4SuccessNum] = useState(0)
    const [board4SuspendNum, setBoard4SuspendNum] = useState(0)
    const [board4Lastest, setBoard4Lastest] = useState("")

    const [boardAirNum, setBoardAirNum] = useState(0)
    const [boardAirNormalNum, setBoardAirNormalNum] = useState(0)
    const [boardAirOfferNum, setBoardAirOfferNum] = useState(0)
    const [boardAirAppointmentNum, setBoardAirAppointmentNum] = useState(0)
    const [boardAirSuccessNum, setBoardAirSuccessNum] = useState(0)
    const [boardAirSuspendNum, setBoardAirSuspendNum] = useState(0)
    const [boardAirLastest, setBoardAirLastest] = useState("")

    const [boardWashNum, setBoardWashNum] = useState(0)
    const [boardWashNormalNum, setBoardWashNormalNum] = useState(0)
    const [boardWashOfferNum, setBoardWashOfferNum] = useState(0)
    const [boardWashAppointmentNum, setBoardWashAppointmentNum] = useState(0)
    const [boardWashSuccessNum, setBoardWashSuccessNum] = useState(0)
    const [boardWashSuspendNum, setBoardWashSuspendNum] = useState(0)
    const [boardWashLastest, setBoardWashLastest] = useState("")

    const [boardShopNum, setBoardShopNum] = useState(0)
    const [boardShopNormalNum, setBoardShopNormalNum] = useState(0)
    const [boardShopOfferNum, setBoardShopOfferNum] = useState(0)
    const [boardShopAppointmentNum, setBoardShopAppointmentNum] = useState(0)
    const [boardShopSuccessNum, setBoardShopSuccessNum] = useState(0)
    const [boardShopSuspendNum, setBoardShopSuspendNum] = useState(0)
    const [boardShopLastest, setBoardShopLastest] = useState("")
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH");
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]
    }

    useEffect(() => {
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 1), orderBy('post_timestamp', 'desc')), 
        (snapshot) => {
            setBoard1Num(snapshot.docs.length)
            if(snapshot.docs.length > 0) {
                setBoard1Lastest(dateFormat(snapshot.docs[0].data().post_timestamp))
            }
        })
        onSnapshot(query(collection(db, 'posts'), and(where('onboard', '==', 1), or(where('status', '==', 1), where('status', '==', 6)))), 
        (snapshot) => {
            setBoard1NormalNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), and(where('onboard', '==', 1), or(where('status', '==', 2), where('status', '==', 3)))), 
        (snapshot) => {
            setBoard1OfferNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 1), where('status', '==', 4)), 
        (snapshot) => {
            setBoard1AppointmentNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 1), where('status', '==', 5)), 
        (snapshot) => {
            setBoard1SuspendNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 1), where('status', '==', 9)), 
        (snapshot) => {
            setBoard1SuccessNum(snapshot.docs.length)
        })


        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 2), orderBy('post_timestamp', 'desc')), 
        (snapshot) => {
            setBoard2Num(snapshot.docs.length)
            if(snapshot.docs.length > 0) {
                setBoard2Lastest(dateFormat(snapshot.docs[0].data().post_timestamp))
            }
        })
        onSnapshot(query(collection(db, 'posts'), and(where('onboard', '==', 2), or(where('status', '==', 1), where('status', '==', 6)))), 
        (snapshot) => {
            setBoard2NormalNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), and(where('onboard', '==', 2), or(where('status', '==', 2), where('status', '==', 3)))), 
        (snapshot) => {
            setBoard2OfferNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 2), where('status', '==', 4)), 
        (snapshot) => {
            setBoard2AppointmentNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 2), where('status', '==', 5)), 
        (snapshot) => {
            setBoard2SuspendNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 2), where('status', '==', 9)), 
        (snapshot) => {
            setBoard2SuccessNum(snapshot.docs.length)
        })


        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 3), orderBy('post_timestamp', 'desc')), 
        (snapshot) => {
            setBoard3Num(snapshot.docs.length)
            if(snapshot.docs.length > 0) {
                setBoard3Lastest(dateFormat(snapshot.docs[0].data().post_timestamp))
            }
        })
        onSnapshot(query(collection(db, 'posts'), and(where('onboard', '==', 3), or(where('status', '==', 1), where('status', '==', 6)))), 
        (snapshot) => {
            setBoard3NormalNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), and(where('onboard', '==', 3), or(where('status', '==', 2), where('status', '==', 3)))), 
        (snapshot) => {
            setBoard3OfferNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 3), where('status', '==', 4)), 
        (snapshot) => {
            setBoard3AppointmentNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 3), where('status', '==', 5)), 
        (snapshot) => {
            setBoard3SuspendNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 3), where('status', '==', 9)), 
        (snapshot) => {
            setBoard3SuccessNum(snapshot.docs.length)
        })

        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 4), orderBy('post_timestamp', 'desc')), 
        (snapshot) => {
            setBoard4Num(snapshot.docs.length)
            if(snapshot.docs.length > 0) {
                setBoard4Lastest(dateFormat(snapshot.docs[0].data().post_timestamp))
            }
        })
        onSnapshot(query(collection(db, 'posts'), and(where('onboard', '==', 4), or(where('status', '==', 1), where('status', '==', 6)))), 
        (snapshot) => {
            setBoard4NormalNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), and(where('onboard', '==', 4), or(where('status', '==', 2), where('status', '==', 3)))), 
        (snapshot) => {
            setBoard4OfferNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 4), where('status', '==', 4)), 
        (snapshot) => {
            setBoard4AppointmentNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 4), where('status', '==', 5)), 
        (snapshot) => {
            setBoard4SuspendNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'posts'), where('onboard', '==', 4), where('status', '==', 9)), 
        (snapshot) => {
            setBoard4SuccessNum(snapshot.docs.length)
        })

        onSnapshot(query(collection(db, 'cleans'), where('onboard', '==', 1), orderBy('post_timestamp', 'desc')), 
        (snapshot) => {
            setBoardAirNum(snapshot.docs.length)
            if(snapshot.docs.length > 0) {
                setBoardAirLastest(dateFormat(snapshot.docs[0].data().post_timestamp))
            }
        })
        onSnapshot(query(collection(db, 'cleans'), and(where('onboard', '==', 1), or(where('status', '==', 1), where('status', '==', 6)))), 
        (snapshot) => {
            setBoardAirNormalNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'cleans'), and(where('onboard', '==', 1), or(where('status', '==', 2), where('status', '==', 3)))), 
        (snapshot) => {
            setBoardAirOfferNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'cleans'), where('onboard', '==', 1), where('status', '==', 4)), 
        (snapshot) => {
            setBoardAirAppointmentNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'cleans'), where('onboard', '==', 1), where('status', '==', 5)), 
        (snapshot) => {
            setBoardAirSuspendNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'cleans'), where('onboard', '==', 1), where('status', '==', 9)), 
        (snapshot) => {
            setBoardAirSuccessNum(snapshot.docs.length)
        })

        onSnapshot(query(collection(db, 'cleans'), where('onboard', '==', 2), orderBy('post_timestamp', 'desc')), 
        (snapshot) => {
            setBoardWashNum(snapshot.docs.length)
            if(snapshot.docs.length > 0) {
                setBoardWashLastest(dateFormat(snapshot.docs[0].data().post_timestamp))
            }
        })
        onSnapshot(query(collection(db, 'cleans'), and(where('onboard', '==', 2), or(where('status', '==', 1), where('status', '==', 6)))), 
        (snapshot) => {
            setBoardWashNormalNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'cleans'), and(where('onboard', '==', 2), or(where('status', '==', 2), where('status', '==', 3)))), 
        (snapshot) => {
            setBoardWashOfferNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'cleans'), where('onboard', '==', 2), where('status', '==', 4)), 
        (snapshot) => {
            setBoardWashAppointmentNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'cleans'), where('onboard', '==', 2), where('status', '==', 5)), 
        (snapshot) => {
            setBoardWashSuspendNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'cleans'), where('onboard', '==', 2), where('status', '==', 9)), 
        (snapshot) => {
            setBoardWashSuccessNum(snapshot.docs.length)
        })

        onSnapshot(query(collection(db, 'postshops'), orderBy('post_timestamp', 'desc')), 
        (snapshot) => {
            setBoardShopNum(snapshot.docs.length)
            if(snapshot.docs.length > 0) {
                setBoardShopLastest(dateFormat(snapshot.docs[0].data().post_timestamp))
            }
        })
        onSnapshot(query(collection(db, 'postshops'), or(where('status', '==', 1), where('status', '==', 6))), 
        (snapshot) => {
            setBoardShopNormalNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'postshops'), or(where('status', '==', 2), where('status', '==', 3))), 
        (snapshot) => {
            setBoardShopOfferNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'postshops'), where('status', '==', 4)), 
        (snapshot) => {
            setBoardShopAppointmentNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'postshops'), where('status', '==', 5)), 
        (snapshot) => {
            setBoardShopSuspendNum(snapshot.docs.length)
        })
        onSnapshot(query(collection(db, 'postshops'), where('status', '==', 9)), 
        (snapshot) => {
            setBoardShopSuccessNum(snapshot.docs.length)
        })
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><HiOutlineMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายการประกาศทั้งหมด</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Card variant="outlined" style={{ margin: 5 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ fontFamily: 'Prompt' }}>
                                <strong>ประกาศทิ้งซาก</strong> 
                                <Button size="medium" style={{ fontFamily: 'Prompt' }} onClick={() => { return navigate(`/admin/postlist/1/`) }}>ดูทั้งหมด</Button>
                            </Typography>
                            { board1Num > 0 &&
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ fontFamily: 'Prompt' }}>
                            ประกาศล่าสุดเมื่อวันที่ {board1Lastest}
                            </Typography>
                            }
                            <Typography variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                ทั้งหมด {number_format.format(board1Num)} ประกาศ
                            </Typography>
                            <Typography variant="body1" style={{ fontFamily: 'Prompt' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'primary.main',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ปกติ+ซ่อน</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board1NormalNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>มีผู้เสนอราคา</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board1OfferNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>นัดหมาย</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board1AppointmentNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'success.main',
                                    '&:hover': {
                                        bgcolor: 'success.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>สำเร็จ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board1SuccessNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'error.main',
                                    '&:hover': {
                                        bgcolor: 'error.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ระงับ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board1SuspendNum)}</strong></div>
                                    </div>
                                </Box>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" style={{ margin: 5 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ fontFamily: 'Prompt' }}>
                                <strong>ประกาศขายซาก</strong> 
                                <Button size="medium" style={{ fontFamily: 'Prompt' }} onClick={() => { return navigate(`/admin/postlist/2/`) }}>ดูทั้งหมด</Button>
                            </Typography>
                            { board2Num > 0 &&
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ fontFamily: 'Prompt' }}>
                            ประกาศล่าสุดเมื่อวันที่ {board2Lastest}
                            </Typography>
                            }
                            <Typography variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                ทั้งหมด {number_format.format(board2Num)} ประกาศ
                            </Typography>
                            <Typography variant="body1" style={{ fontFamily: 'Prompt' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'primary.main',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ปกติ+ซ่อน</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board2NormalNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>มีผู้เสนอราคา</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board2OfferNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>นัดหมาย</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board2AppointmentNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'success.main',
                                    '&:hover': {
                                        bgcolor: 'success.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>สำเร็จ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board2SuccessNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'error.main',
                                    '&:hover': {
                                        bgcolor: 'error.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ระงับ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board2SuspendNum)}</strong></div>
                                    </div>
                                </Box>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" style={{ margin: 5 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ fontFamily: 'Prompt' }}>
                                <strong>ประกาศขายเครื่องใช้ไฟฟ้ามือสอง</strong> 
                                <Button size="medium" style={{ fontFamily: 'Prompt' }} onClick={() => { return navigate(`/admin/postlist/3/`) }}>ดูทั้งหมด</Button>
                            </Typography>
                            <Typography variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                ทั้งหมด {number_format.format(board3Num)} ประกาศ
                            </Typography>
                            { board3Num > 0 &&
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ fontFamily: 'Prompt' }}>
                            ประกาศล่าสุดเมื่อวันที่ {board3Lastest}
                            </Typography>
                            }
                            <Typography variant="body1" style={{ fontFamily: 'Prompt' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'primary.main',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ปกติ+ซ่อน</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board3NormalNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>มีผู้เสนอราคา</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board3OfferNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>นัดหมาย</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board3AppointmentNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'success.main',
                                    '&:hover': {
                                        bgcolor: 'success.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>สำเร็จ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board3SuccessNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'error.main',
                                    '&:hover': {
                                        bgcolor: 'error.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ระงับ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board3SuspendNum)}</strong></div>
                                    </div>
                                </Box>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Card variant="outlined" style={{ margin: 5 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ fontFamily: 'Prompt' }}>
                                <strong>ประกาศแจ้งซ่อมเครื่องใช้ไฟฟ้า</strong> 
                                <Button size="medium" style={{ fontFamily: 'Prompt' }} onClick={() => { return navigate(`/admin/postlist/4/`) }}>ดูทั้งหมด</Button>
                            </Typography>
                            <Typography variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                ทั้งหมด {number_format.format(board4Num)} ประกาศ
                            </Typography>
                            { board4Num > 0 &&
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ fontFamily: 'Prompt' }}>
                            ประกาศล่าสุดเมื่อวันที่ {board4Lastest}
                            </Typography>
                            }
                            <Typography variant="body1" style={{ fontFamily: 'Prompt' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'primary.main',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ปกติ+ซ่อน</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board4NormalNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>มีผู้เสนอราคา</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board4OfferNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>นัดหมาย</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board4AppointmentNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'success.main',
                                    '&:hover': {
                                        bgcolor: 'success.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>สำเร็จ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board4SuccessNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'error.main',
                                    '&:hover': {
                                        bgcolor: 'error.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ระงับ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(board4SuspendNum)}</strong></div>
                                    </div>
                                </Box>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" style={{ margin: 5 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ fontFamily: 'Prompt' }}>
                                <strong>ประกาศแจ้งล้างเครื่องปรับอากาศ</strong> 
                                <Button size="medium" style={{ fontFamily: 'Prompt' }} onClick={() => { return navigate(`/admin/cleanlist/1/`) }}>ดูทั้งหมด</Button>
                            </Typography>
                            <Typography variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                ทั้งหมด {number_format.format(boardAirNum)} ประกาศ
                            </Typography>
                            { boardAirNum > 0 &&
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ fontFamily: 'Prompt' }}>
                            ประกาศล่าสุดเมื่อวันที่ {boardAirLastest}
                            </Typography>
                            }
                            <Typography variant="body1" style={{ fontFamily: 'Prompt' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'primary.main',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ปกติ+ซ่อน</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardAirNormalNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>มีผู้เสนอราคา</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardAirOfferNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>นัดหมาย</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardAirAppointmentNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'success.main',
                                    '&:hover': {
                                        bgcolor: 'success.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>สำเร็จ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardAirSuccessNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'error.main',
                                    '&:hover': {
                                        bgcolor: 'error.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ระงับ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardAirSuspendNum)}</strong></div>
                                    </div>
                                </Box>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                    <Card variant="outlined" style={{ margin: 5 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ fontFamily: 'Prompt' }}>
                                <strong>ประกาศแจ้งล้างเครื่องซักผ้า</strong> 
                                <Button size="medium" style={{ fontFamily: 'Prompt' }} onClick={() => { return navigate(`/admin/cleanlist/2/`) }}>ดูทั้งหมด</Button>
                            </Typography>
                            <Typography variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                ทั้งหมด {number_format.format(boardWashNum)} ประกาศ
                            </Typography>
                            { boardWashNum > 0 &&
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ fontFamily: 'Prompt' }}>
                            ประกาศล่าสุดเมื่อวันที่ {boardWashLastest}
                            </Typography>
                            }
                            <Typography variant="body1" style={{ fontFamily: 'Prompt' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'primary.main',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ปกติ+ซ่อน</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardWashNormalNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>มีผู้เสนอราคา</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardWashOfferNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>นัดหมาย</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardWashAppointmentNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'success.main',
                                    '&:hover': {
                                        bgcolor: 'success.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>สำเร็จ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardWashSuccessNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'error.main',
                                    '&:hover': {
                                        bgcolor: 'error.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ระงับ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardWashSuspendNum)}</strong></div>
                                    </div>
                                </Box>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <Card variant="outlined" style={{ margin: 5 }}>
                        <CardContent>
                            <Typography variant="h5" component="div" style={{ fontFamily: 'Prompt' }}>
                                <strong>ร้านค้าประกาศขายเครื่องใช้ไฟฟ้ามือสอง</strong> 
                                <Button size="medium" style={{ fontFamily: 'Prompt' }} onClick={() => { return navigate(`/admin/postshop/`) }}>ดูทั้งหมด</Button>
                            </Typography>
                            <Typography variant="h6" component="div" style={{ fontFamily: 'Prompt' }}>
                                ทั้งหมด {number_format.format(boardShopNum)} ประกาศ
                            </Typography>
                            { boardShopNum > 0 &&
                            <Typography sx={{ fontSize: 14 }} color="text.secondary" gutterBottom style={{ fontFamily: 'Prompt' }}>
                            ประกาศล่าสุดเมื่อวันที่ {boardShopLastest}
                            </Typography>
                            }
                            <Typography variant="body1" style={{ fontFamily: 'Prompt' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, flexWrap: 'wrap' }}>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'primary.main',
                                    '&:hover': {
                                        bgcolor: 'primary.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ปกติ+ซ่อน</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardShopNormalNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>มีผู้เสนอราคา</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardShopOfferNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'warning.main',
                                    '&:hover': {
                                        bgcolor: 'warning.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>นัดหมาย</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardShopAppointmentNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'success.main',
                                    '&:hover': {
                                        bgcolor: 'success.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>สำเร็จ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardShopSuccessNum)}</strong></div>
                                    </div>
                                </Box>
                                <Box sx={{ margin:'5px', width: 140, height: 100, borderRadius: 1, bgcolor: 'error.main',
                                    '&:hover': {
                                        bgcolor: 'error.dark',
                                    }, }}
                                >
                                    <div style={{ margin: 10, padding: 5 }}>
                                        <div className="text-white" style={{ fontSize: 20 }}><strong>ระงับ</strong></div>
                                        <div className="text-white" style={{ fontSize: 36 }}><strong>{number_format.format(boardShopSuspendNum)}</strong></div>
                                    </div>
                                </Box>
                                </div>
                            </Typography>
                        </CardContent>
                    </Card>
                </div>
            </div>
        </div>
    )
}

export default PostList;