import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import { IoMegaphoneOutline } from "react-icons/io5"
import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2, TbSearch } from "react-icons/tb"
import { LuAirVent } from "react-icons/lu"
import { CgSmartHomeWashMachine } from "react-icons/cg"

const PostOnboard = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)

    const [uid, setUid] = useState('')
    const [docId, setDocId] = useState('')
    const [memberId, setMemberId] = useState('')

    const [userStatus, setUserStatus] = useState(1)

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDocId(docs.docs[0].id)
                setMemberId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.status) setUserStatus(data.status)
            }
        }).catch((error) => {
            alert(error.message);
        })
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else getDocUser()
    }, [user, loading])

return (
    <>
        <div style={{ backgroundColor: "#fcfcfc", padding: 20}}>
            <div className="container">
                <div className="row" style={{ marginTop: 20 }}>
                    <div className="col">
                        <h3 className="text-center"><b>ลงประกาศใหม่</b></h3>
                    </div>
                </div>
                <div className="row gy-3" style={{ marginTop: 40 }}>
                    {userStatus == 2 && <>
                    <div className="col-xs-12 offset-md-2 col-md-4">
                        <div className="d-grid row-gap-3">
                            <button type="button" className="btn btn-outline-danger btn-lg text-start" onClick={() => { return navigate("/posts/throw") }}>
                                <RiDeleteBinLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ประกาศทิ้งซากเครื่องใช้ไฟฟ้า
                            </button>
                            <button type="button" className="btn btn-outline-primary btn-lg text-start" onClick={() => { return navigate("/posts/gabage") }}>
                                <BiMoneyWithdraw style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ประกาศขายซากเครื่องใช้ไฟฟ้า
                            </button>
                            <button type="button" className="btn btn-outline-success btn-lg text-start" onClick={() => { return navigate("/posts/second") }}>
                                <div className="d-block d-sm-none">
                                    <TbHexagonNumber2 style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /><span style={{ fontSize: 17 }}>ประกาศขายเครื่องใช้ไฟฟ้ามือสอง</span>
                                </div>
                                <div className="d-none d-sm-block">
                                <TbHexagonNumber2 style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ประกาศขายเครื่องใช้ไฟฟ้ามือสอง
                                </div>
                            </button>
                        </div>
                    </div>
                    <div className="col-xs-12 col-md-4">
                        <div className="d-grid row-gap-3">
                            <button type="button" className="btn btn-outline-danger btn-lg text-start" onClick={() => { return navigate("/posts/fix") }}>
                                <div className="d-block d-sm-none">
                                    <BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /><span style={{ fontSize: 17 }}>แจ้งความต้องการซ่อมเครื่องใช้ไฟฟ้า</span>
                                </div>
                                <div className="d-none d-sm-block">
                                    <BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งความต้องการซ่อมเครื่องใช้ไฟฟ้า
                                </div>
                            </button>
                            <button type="button" className="btn btn-outline-primary btn-lg text-start" onClick={() => { return navigate("/clean/air") }}>
                                <LuAirVent style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งความต้องการล้างแอร์
                            </button>
                            <button type="button" className="btn btn-outline-success btn-lg text-start" onClick={() => { return navigate("/clean/wash") }}>
                                <div className="d-block d-sm-none">
                                    <CgSmartHomeWashMachine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /><span style={{ fontSize: 17 }}>แจ้งความต้องการล้างเครื่องซักผ้า</span>
                                </div>
                                <div className="d-none d-sm-block">
                                    <CgSmartHomeWashMachine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งความต้องการล้างเครื่องซักผ้า
                                </div>
                            </button>
                        </div>
                    </div>
                    </>}
                    {userStatus == 1 && <>
                    <div className="col">
                        <h3 className="text-center text-danger"><b>คุณยังไม่สามารถลงประกาศได้ กรุณาติดต่อผู้ดูแลระบบ</b></h3>
                    </div>
                    </>}
                </div>
            </div>
        </div>
    </>
)
}

export default PostOnboard;