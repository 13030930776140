import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, getDoc, doc } from 'firebase/firestore'
import { useParams, Link } from "react-router-dom"

import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2 } from "react-icons/tb"
import { FiInfo } from "react-icons/fi"
import noImage from "../../assets/images/avatar.png"

import { Loading } from "../layouts/Loading"
import { getBrand, getModel } from '../helper/BrandHelper'
import { getElectric } from '../helper/ElectricTypeHelper'

const Posts = () => {
    const params = useParams()
    const posterId = params.id
    const [loading] = useAuthState(auth)
    const [posts, setPosts] = useState([])
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [posterName, setPosterName] = useState('')
    const number_format = new Intl.NumberFormat()
    const [poster, setPoster] = useState([])
    const [subDistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
 
    const dateFormatTime = (datetime) => {
        return (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const fetchPosts = async () => {
        setShowProgressBar(true)
        await getDocs(query(collection(db, 'posts'), 
                    where('onboard', '==', 4), 
                    where('poster_id', '==', posterId),
                    where('status', 'not-in', [5, 6]),
                    orderBy('status', 'asc'),
                    orderBy('post_timestamp', 'desc'))).then((postDocs) => {
            posts.splice(0, posts.length)
            setPosts([...posts])
            postDocs.forEach((docPost) => {
                var brand = 'ไม่ระบุ'
                var model = 'ไม่ระบุ'
                var type = 'ไม่ระบุ'
                var promisesTask = []
                var getBrandTask = getBrand(db, docPost.data().brand_id, docPost.data().brand).then((brandName) => {
                    brand = brandName
                })
                promisesTask.push(getBrandTask)

                var getModelTask = getModel(db, docPost.data().model_id, docPost.data().model).then((modelName) => {
                    model = modelName
                })
                promisesTask.push(getModelTask)
                var getElectricType = getElectric(db, docPost.data().type_id, docPost.data().other_type).then((typeName) => {
                    type = typeName
                })
                promisesTask.push(getElectricType)

                getDocs(query(collection(db, `posts/${docPost.id}/offers`), where('status', '==', 1), orderBy('offer_timestamp', 'desc'))).then((offerQuery) => {
                    var offersList = []
                    offerQuery.forEach((offer) => {
                        offersList.push({ id: offer.id, data: offer.data() })
                    })
                    Promise.all(promisesTask).then(() => {
                        posts.push({
                            id: docPost.id,
                            data: docPost.data(),
                            offer: offersList,
                            brand: brand,
                            model: model,
                            type: type
                        })
                        setPosts([...posts], {
                            id: docPost.id,
                            data: docPost.data(),
                            offer: offersList,
                            brand: brand,
                            model: model,
                            type: type
                        })
                    })
                })
            })
        }).then(() => {
            setShowProgressBar(false)
        })
    }

    const getUserDoc = () => {
        getDoc(doc(db, `users/`, posterId)).then((posterDoc) => {
            const dataPoster = posterDoc.data()
            if(dataPoster.display_name) setPosterName(dataPoster.display_name)
            setPoster(dataPoster)

            var promisesTask = []
            if(dataPoster.province) {
                const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', dataPoster.province * 1))).then((docs) => {
                    if(docs.docs.length > 0) setProvince(docs.docs[0].data().province_name)
                })
                promisesTask.push(provinceTask)
            }

            if(dataPoster.district) {
                const districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', dataPoster.district))).then((docs) => {
                    if(docs.docs.length > 0) setDistrict(docs.docs[0].data().district_name)
                })
                promisesTask.push(districtTask)
            }

            if(dataPoster.sub_district) {
                const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', dataPoster.sub_district * 1))).then((docs) => {
                    if(docs.docs.length > 0) setSubDistrict(docs.docs[0].data().subdistrict_name)
                })
                promisesTask.push(subdistrictTask)
            }
            Promise.all(promisesTask).then(() => {
            })
        })
    }

    useEffect(() => {
        if (loading) return;
        else { 
            fetchPosts()
            getUserDoc() 
        }
    }, [loading])

    var postKey = []
    var postList = []
    posts.forEach(element => {
        if(postKey.lastIndexOf(element.id) < 0) {
            postKey.push(element.id)
            postList.push(element)
        }
    })

    var maxWidthProfile = 170
    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        maxWidthProfile = 320
    } else {
        maxWidthProfile = 320
    }

    return (
    <>
        <Loading open={showProgressBar} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 20}}>
            <div className="flex-container" style={{ display: 'flex', marginLeft: 0, marginBottom: 30, alignContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: maxWidthProfile, marginBottom: 10 }}>
                    {/* <h6><strong>ข้อมูลผู้ใช้</strong></h6> */}
                    <div style={{ display: 'flex', flexDirection: 'column', width: maxWidthProfile }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', alignContent:'center' }}>
                            <div style={{ minWidth: 100, maxWidth: 150 }}>
                                {poster.image_profile?
                                (<img src={poster.image_profile} alt="WEEE" className='img-fluid' />):
                                (<img src={noImage} alt="WEEE" className='img-fluid' />)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5><strong>{poster.display_name}</strong></h5>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <font style={{ fontSize: 14 }}>
                                    สถานะการใช้งาน: <strong>{poster.status==1?(<font className='text-danger'>ยังไม่อนุมัติ</font>):(<font className='text-success'>อนุมัติ</font>)}</strong>
                                </font>
                            </div>
                            <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', alignContent:'center', marginTop: 10 }}>
                                <strong>ที่อยู่ที่ใช้สมัคร: </strong>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign:'center' }}>{subDistrict?subDistrict:"ไม่ระบุ"} {district?district:"ไม่ระบุ"} {province?province:"ไม่ระบุ"} {poster.postcode?poster.postcode:"ไม่ระบุ"} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, borderTop: '1px solid #cccccc', paddingTop: 10 }}>
                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ประกาศแจ้งซ่อมเครื่องใช้ไฟฟ้าของ {posterName}</b></h4></div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ borderBottom: '1px solid #cccccc' }}>
                                <ul className="nav nav-underline">
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/1/${posterId}`}><RiDeleteBinLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ทิ้งซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/2/${posterId}`}><BiMoneyWithdraw style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/3/${posterId}`}><TbHexagonNumber2 style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายมือสอง</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href={`/postlist/4/${posterId}`}><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งซ่อมเครื่องใช้ไฟฟ้า</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/air/${posterId}`}><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างแอร์</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/wash/${posterId}`}><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างเครื่องซักผ้า</a>
                                    </li>
                                </ul>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10}}>
                            {postList?.map((post, i) => (
                                <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={i}>
                                    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                        <img src={post.data.picture} alt="APP3R" height="150" />
                                    </div>
                                    <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                        <p className="card-text">
                                            {post.type} {post.brand} {post.model}<br/>
                                        </p>
                                        <p className="card-text">
                                            <strong>งบประมาณซ่อมไม่เกิน:</strong> {number_format.format(post.data.price)} บาท <br/>
                                            <strong>อาการ:</strong> {post.broken} <br/>
                                            <strong>อาการเพิ่มเติม:</strong> {post.data.description} <br/>
                                        </p>
                                        <p className="card-text">
                                            <strong>รูปแบบการซ่อม:</strong> 
                                            {post.data.fix_type=="1"?" ให้มาซ่อมในที่พักอาศัย":""}  
                                            {post.data.fix_type=="2"?" ต้องการให้มายกไปซ่อมที่ร้าน":""}  
                                            {post.data.fix_type=="3"?" ต้องการยกไปที่ร้านเอง":""} 
                                            <br/>
                                            <strong>ต้องการซ่อมไม่เกิน:</strong> {post.data.fix_day} วัน<br/>
                                        </p>
                                        <p className="card-text"><strong>วันที่ประกาศ:</strong> {dateFormatTime(post.data.post_timestamp)}</p>
                                    </div>
                                    <div className="card-body">
                                        <Link className="btn btn-primary m-1" to={`/postfix/${post.id}`} target='_blank'><FiInfo /> <strong>ดูประกาศ</strong></Link>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Posts;