import { doc, getDoc } from "firebase/firestore"

export async function getBroken(db, brokenId, brokenOther) {
    var broken = 'ไม่ระบุ'
    if(brokenId) {
        if(brokenId == '999' && brokenOther) {
            broken = brokenOther
        } else {
            await getDoc(doc(db, `brokens`, brokenId)).then((brokenDoc) => {
                broken = brokenDoc.data().name
            })
        }
    } else {
        if(brokenOther) { broken = brokenOther }
    }

    return broken
}