import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, getDoc, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { Link, useNavigate } from "react-router-dom";
import LeftMenu from './LeftMenu'
import { getBrand, getModel } from '../helper/BrandAirHelper'

import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2 } from "react-icons/tb"
import { FiEdit, FiMinusCircle, FiInfo, FiXCircle, FiCheckCircle, FiCalendar } from "react-icons/fi"
import { LuAirVent } from "react-icons/lu"
import { CgSmartHomeWashMachine } from "react-icons/cg"

import { Alert } from '../layouts/Alert'
import { Loading } from "../layouts/Loading"

import LeftMenuPost from './LeftMenuPost'
import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const PostsFix = () => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth)
    const number_format = new Intl.NumberFormat()
    const [openDrawer, setOpenDrawer] = useState(false)

    const [uid, setUid] = useState('')
    const [docId, setDocId] = useState('')
    const [keyword, setKeyword] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [posts, setPosts] = useState([])

    const [openHidePost, setOpenHidePost] = useState(false)
    const [openShowPost, setOpenShowPost] = useState(false)
    const [openDeletePost, setOpenDeletePost] = useState(false)
    const [choosePostId, setChoosePostId] = useState('')

    const [showProgressBar, setShowProgressBar] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const handleCloseHidePost = () => {
        setChoosePostId('')
        setOpenHidePost(false)
    }

    const handleOpenHidePost = (post_id) => {
        setChoosePostId(post_id)
        setOpenHidePost(true)
    }
    const handleCloseShowPost = () => {
        setChoosePostId('')
        setOpenShowPost(false)
    }

    const handleOpenShowPost = (post_id) => {
        setChoosePostId(post_id)
        setOpenShowPost(true)
    }
    const handleCloseDeletePost = () => {
        setChoosePostId('')
        setOpenDeletePost(false)
    }

    const handleOpenDeletePost = (post_id) => {
        setChoosePostId(post_id)
        setOpenDeletePost(true)
    }

    const handleHidePost = () => {
        updateDoc(doc(db, `cleans`, choosePostId), { status: 6 }).then(() => {
            navigate(0)
        })
    }

    const handleShowPost = () => {
        updateDoc(doc(db, `cleans`, choosePostId), { status: 1 }).then(() => {
            navigate(0)
        })
    }

    const handleDeletePost = () => {
        deleteDoc(doc(db, `cleans`, choosePostId)).then(() => {
            navigate(0)
        })
    }

    const handleSearch = (keyword) => {
        var q
        if(keyword) {
            q = query(collection(db, 'cleans'), 
                        where('onboard', '==', 2),
                        where('type', '>=', keyword),
                        where('type', '<=', keyword +  '\uf8ff'),
                        where('poster_id', '==', docId),
                        orderBy('type', 'asc'), 
                        orderBy('post_timestamp', 'desc'))

            onSnapshot(q, (querySnapshot) => {
                setPosts(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        } else {
            q = query(collection(db, 'cleans'), 
                        where('onboard', '==', 2),
                        where('poster_id', '==', docId),
                        orderBy('post_timestamp', 'desc'))

            onSnapshot(q, (querySnapshot) => {
                setPosts(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        }
    }
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH");
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]
    }

    const fetchPosts = () => {
        setShowProgressBar(true)
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then(async(docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDocId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)
                await getDocs(query(collection(db, 'cleans'), 
                            where('onboard', '==', 1), 
                            where('poster_id', '==', docs.docs[0].id), 
                            orderBy('post_timestamp', 'desc'))).then((postDocs) => {
                    posts.splice(0, posts.length)
                    setPosts([...posts])
                    postDocs.forEach((doc) => {
                        var brand1 = "ไม่ระบุ"
                        var brand2 = "ไม่ระบุ"
                        var brand3 = "ไม่ระบุ"
                        var model1 = "ไม่ระบุ"
                        var model2 = "ไม่ระบุ"
                        var model3 = "ไม่ระบุ"
                        var promisesTask = []
                        if(doc.data().have_air1) {
                            var getBrand1Task = getBrand(db, doc.data().air1_brand_id, doc.data().air1_brand).then((brandName) => {
                                brand1 = brandName
                            })
                            promisesTask.push(getBrand1Task)
            
                            var getModel1Task = getModel(db, doc.data().air1_model_id, doc.data().air1_model).then((modelName) => {
                                model1 = modelName
                            })
                            promisesTask.push(getModel1Task)
                        }
                        if(doc.data().have_air2) {
                            var getBrand2Task = getBrand(db, doc.data().air2_brand_id, doc.data().air2_brand).then((brandName) => {
                                brand2 = brandName
                            })
                            promisesTask.push(getBrand2Task)
            
                            var getModel2Task = getModel(db, doc.data().air2_model_id, doc.data().air2_model).then((modelName) => {
                                model2 = modelName
                            })
                            promisesTask.push(getModel2Task)
                        }
                        if(doc.data().have_air3) {
                            var getBrand3Task = getBrand(db, doc.data().air3_brand_id, doc.data().air3_brand).then((brandName) => {
                                brand3 = brandName
                            })
                            promisesTask.push(getBrand3Task)
            
                            var getModel3Task = getModel(db, doc.data().air3_model_id, doc.data().air3_model).then((modelName) => {
                                model3 = modelName
                            })
                            promisesTask.push(getModel3Task)
                        }

                        getDocs(query(collection(db, `cleans/${doc.id}/offers`), where('status', '==', 1), orderBy('offer_timestamp', 'desc'))).then((offerQuery) => {
                            var offersList = []
                            offerQuery.forEach((offer) => {
                                offersList.push({ id: offer.id, data: offer.data()})
                            })
                            posts.push({
                                id: doc.id,
                                data: doc.data(),
                                offer: offersList,
                                brand1: brand1,
                                brand2: brand2,
                                brand3: brand3,
                                model1: model1,
                                model2: model2,
                                model3: model3
                            })
                            setPosts([...posts], {
                                id: doc.id,
                                data: doc.data(),
                                offer: offersList
                            })
                        })
                    })
                }).then(() => {
                    setShowProgressBar(false)
                })
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        posts.splice(0, posts.length)
        setPosts([...posts])
        if (loading) { 
            return 
        } else {
            fetchPosts()
        }
        if (!user) return navigate("/")
    }, [user, loading]);

    var postKey = []
    var postList = []
    posts.forEach(async(element) => {
        if(postKey.lastIndexOf(element.id) < 0) {
            postKey.push(element.id)
            postList.push(element)
        }
    })

    return (
        <>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <Loading open={showProgressBar} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
            <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 280 }} role="presentation">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                            <div style={{ marginBottom: 10 }}>
                                <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                            </div>
                            <LeftMenuPost currentPage="post" isShop={isShop} postType={5} />
                        </div>
                    </div>
                </Box>
            </Drawer>
            <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                    <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 10, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                        <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                            <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                        </div>
                    </div>
                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <h4 className='d-block d-md-none d-lg-none'>
                                <b>ประกาศของฉัน {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} onClick={toggleDrawer(true)} >แจ้งความต้องการล้างแอร์<IoMdMenu style={{ fontSize: 25, marginLeft: 5, marginBottom: 3 }} /></font></b>
                            </h4>
                            <h4 className='d-none d-md-block d-lg-block'>
                                <b>ประกาศของฉัน {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} >แจ้งความต้องการล้างแอร์</font></b>
                            </h4>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                            <LeftMenu currentPage="post" isShop={isShop} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div className="d-none d-md-block d-lg-block" style={{ borderBottom: '1px solid #cccccc', paddingLeft: 20 }}>
                                <ul className="nav nav-underline">
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/"><RiDeleteBinLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ทิ้งซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/garbage"><BiMoneyWithdraw style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/second"><TbHexagonNumber2 style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายมือสอง</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/fix"><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งซ่อมเครื่องใช้ไฟฟ้า</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="/profile/posts/airs"><LuAirVent style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างแอร์</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/wash"><CgSmartHomeWashMachine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างเครื่องซักผ้า</a>
                                    </li>
                                </ul>
                            </div>
                            {/* <div>
                                <div className="row" style={{ marginTop: 10 }}>
                                    <div className="input-group" style={{ marginBottom: 10 }}>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="ค้นหาประกาศของฉัน" 
                                            aria-describedby="button-addon2"
                                            onChange={(e) => setKeyword(e.target.value)} 
                                            value={keyword} />
                                        <button 
                                            className="btn btn-outline-secondary" 
                                            type="button" 
                                            id="button-addon2"
                                            onClick={() => handleSearch(keyword)}><TbSearch /></button>
                                    </div>
                                </div>
                            </div> */}
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10}}>
                            {postList?.map((post, i) => (
                                <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={post.id}>
                                    {(post.offer.length > 0 && post.data.status==2) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <a href={`../../post/offer/air/${post.id}`} target='_blank'>
                                            <button type="button" className="btn btn-primary">
                                            ข้อเสนอ <span className="badge badge-light" style={{ backgroundColor: '#ff0000'}}>{post.offer.length}</span>
                                            </button>
                                            </a>
                                        </div>)
                                    }
                                    {(post.data.status==3) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-warning" style={{ color: '#000000'}}>รอยืนยันการรับบรริการ</button>
                                        </div>)
                                    }
                                    {(post.data.status==4) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}><strong>ร้านค้ายืนยันรับบริการ</strong></button>
                                        </div>)
                                    }
                                    {post.data.status == 5 &&
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-danger" style={{ color: '#FFFFFF'}}><strong>ระงับการประกาศ</strong></button>
                                    </div>)
                                    }
                                    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                        <img src={post.data.picture} alt="WEEE" height="150" />
                                    </div>
                                    <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                        <p className="card-text">
                                            {(post.data.have_air1) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์ติดตั้งผนัง</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}><strong>{post.brand1} {post.model1}</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(post.data.air1_BTU)} BTU<br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.data.air1_amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                            {(post.data.have_air2) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์แขวนใต้ฝ้า</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}><strong>{post.brand2} {post.model2}</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(post.data.air2_BTU)} BTU<br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.data.air2_amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                            {(post.data.have_air3) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์ฝังฝ้า 4 ทิศทาง</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}><strong>{post.brand3} {post.model3}</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(post.data.air3_BTU)} BTU<br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.data.air3_amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                        </p>
                                        <p className="card-text"><strong>วันที่ประกาศ:</strong><br/>{dateFormat(post.data.post_timestamp)}</p>
                                    </div>
                                    <div className="card-body">
                                        {(post.data.status==1) && (<>
                                            <Link className="btn btn-warning m-1" to={`/post/edit/air/${post.id}`} target='_blank'><FiEdit /> <strong>แก้ไข</strong></Link>
                                            <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenHidePost(post.id) }}><FiMinusCircle /> <strong>ซ่อน</strong></button>
                                        </>)}
                                        <Link className="btn btn-primary m-1" to={`/postclean/${post.id}`} target='_blank'><FiInfo /> <strong>ดูประกาศ</strong></Link>
                                        {(post.data.status==3) && (<>
                                            <Link className="btn btn-primary m-1" to={`/profile/cleandetail/${post.data.onboard}/${post.id}/`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                                        </>)}
                                        {(post.data.status==4) && (<>
                                            <Link className="btn btn-primary m-1" to={`/profile/cleandetail/${post.data.onboard}/${post.id}/`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                                            <Link className="btn btn-primary m-1" to={`/profile/appointments`} target='_blank'><FiCalendar /> <strong>ดูนัดหมาย</strong></Link>
                                        </>)}
                                        {(post.data.status==6) && (<>
                                            <Link className="btn btn-warning m-1" to={`/post/edit/air/${post.id}`} target='_blank'><FiEdit /> <strong>แก้ไข</strong></Link>
                                            <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenShowPost(post.id) }}><FiCheckCircle /> <strong>แสดง</strong></button>
                                            <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenDeletePost(post.id) }}><FiXCircle /> <strong>ลบ</strong></button>
                                        </>)}
                                        {(post.data.status==9) && (<>
                                            <Link className="btn btn-primary m-1" to={`/profile/cleandetail/${post.data.onboard}/${post.id}/`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                                        </>)}
                                    </div>
                                </div>
                            ))}
                            {postList.length <= 0 && (
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <font className="text-danger" style={{ fontWeight: '600'}}>ไม่พบประกาศ</font>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
                
                <Alert name="Hide Post"
                    title={"ซ่อนประกาศ"}
                    text={"คุณต้องการซ่อนประกาศนี้?"}
                    open={openHidePost} 
                    close={handleCloseHidePost}
                    submit={handleHidePost} />
                
                <Alert name="Show Post"
                    title={"แสดงประกาศ"}
                    text={"คุณต้องการแสดงประกาศนี้?"}
                    open={openShowPost} 
                    close={handleCloseShowPost}
                    submit={handleShowPost} />
                
                <Alert name="Delete Post"
                    title={"ลบประกาศ"}
                    text={"คุณต้องการลบประกาศนี้?"}
                    open={openDeletePost} 
                    close={handleCloseDeletePost}
                    submit={handleDeletePost} />
            </div>
        </>
    )
}

export default PostsFix;