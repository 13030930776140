import React, { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, doc, getDoc, onSnapshot, addDoc, updateDoc, orderBy, getDocs } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom"

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import dayjs from 'dayjs';

import { TbUser, TbEdit, TbUsers, TbUserPlus } from "react-icons/tb"
import { GoGear } from "react-icons/go"
import { AiFillShop, AiFillInfoCircle } from "react-icons/ai"
import { MdAttachMoney } from "react-icons/md"
import { HiArrowDownTray, HiArrowUpTray } from "react-icons/hi2"
import { getElectric } from '../helper/ElectricTypeHelper'

import LeftMenu from './LeftMenu';

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Finances = () => {
    const params = useParams();
    const shopId = params.shopId
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [openDrawer, setOpenDrawer] = useState(false)

    const [shop, setShop] = useState([])
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState()
    const [electricType, setElectricType] = useState([])

    const [spares, setSpares] = useState([])
    const [withdraws, setWithdraws] = useState([])

    const [selectSpare, setSelectSpare] = useState("")
    const [selectWithdraw, setSelectWithdraw] = useState("")

    const [withdrawDateErrorDiv, setwithdrawDateErrorDiv] = useState(false)
    const [withdrawAmountErrorDiv, setwithdrawAmountErrorDiv] = useState(false)

    const [openAddWithdraw, setOpenAddWithdraw] = useState(false)
    const [withdrawSpareId, setWithdrawSpareId] = useState('')
    const [withdrawAmount, setWithdrawAmount] = useState(0)
    const [withdrawDate, setWithdrawDate] = useState("")
    const [withdrawDescription, setWithdrawDescription] = useState("")
    const [appointmentId, setAppointmentId] = useState('')

    const [openEditWithdraw, setOpenEditWithdraw] = useState(false)
    const [editWithdrawId, setEditWithdrawId] = useState('')
    const [editWithdrawAmount, setEditWithdrawAmount] = useState(0)
    const [editWithdrawDate, setEditWithdrawDate] = useState("")
    const [editWithdrawDescription, setEditWithdrawDescription] = useState("")
    const [editAppointmentId, setEditAppointmentId] = useState('')

    const [openViewWithdraw, setOpenViewWithdraw] = useState(false)
    const [viewWithdrawId, setViewWithdrawId] = useState('')
    const [viewWithdrawAmount, setViewWithdrawAmount] = useState(0)
    const [viewWithdrawDate, setViewWithdrawDate] = useState("")
    const [viewWithdrawDescription, setViewWithdrawDescription] = useState("")
    const [viewAppointmentId, setViewAppointmentId] = useState('')

    const [spareNameErrorDiv, setSpareNameErrorDiv] = useState(false)
    const [spareAmountErrorDiv, setSpareAmountErrorDiv] = useState(false)
    const [sparePriceErrorDiv, setSparePriceErrorDiv] = useState(false)

    const [editSpareNameErrorDiv, setEditSpareNameErrorDiv] = useState(false)
    const [editSparePriceErrorDiv, setEditSparePriceErrorDiv] = useState(false)

    const [AddNumSpareAmountErrorDiv, setAddNumSpareAmountErrorDiv] = useState(false)

    const [openAddSpare, setOpenAddSpare] = useState(false)
    const [addSpareTypeId, setAddSpareTypeId] = useState('')
    const [addSpareName, setAddSpareName] = useState('')
    const [addSpareAmount, setAddSpareAmount] = useState(0)
    const [addSparePrice, setAddSparePrice] = useState(0)
    const [addSpareDescription, setAddSpareDescription] = useState('')

    const [openEditSpare, setOpenEditSpare] = useState(false)
    const [editSpareTypeId, setEditSpareTypeId] = useState('')
    const [editSpareName, setEditSpareName] = useState('')
    const [editSparePrice, setEditSparePrice] = useState(0)
    const [editSpareDescription, setEditSpareDescription] = useState('')

    const [openAddNumSpare, setOpenAddNumSpare] = useState(false)
    const [addNumSpareAmount, setAddNumSpareAmount] = useState(0)

    const [openViewSpare, seOpenViewSpare] = useState(false)
    const [openSpareTypeId, setOpenSpareTypeId] = useState('')
    const [openSpareName, setOpenSpareName] = useState('')
    const [openSpareAmount, setOpenSpareAmount] = useState(0)
    const [openSparePrice, setOpenSparePrice] = useState(0)
    const [openSpareDescription, setAOpenSpareDescription] = useState('')

    const [loading, error] = useAuthState(auth);
    
    const dateFormatEdit = (datetime) => {
        const date_string = new Date(datetime.seconds * 1000).toLocaleDateString('en-US')
        const date_split = date_string.split('/')
        return date_split[2]+'-'+date_split[0]+'-'+date_split[1]
    }
 
    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const getElectricType = () => {
        getDocs(query(collection(db, 'electric_type'))).then((docs) => {
            setElectricType(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleAddSpare = () => {
        if(validateAddSpare) {
            const new_date = new Date()
            addDoc(collection(db, `shops/${shopId}/spares/`), {
                spare_type: addSpareTypeId,
                spare_name: addSpareName,
                spare_amount: addSpareAmount*1,
                spare_price: addSparePrice*1,
                spare_description: addSpareDescription,
                date: new_date.getTime(),
                status: 1
            }).then(() => {
                setSpareNameErrorDiv(false)
                setSpareAmountErrorDiv(false)
                setSparePriceErrorDiv(false)
                handleCloseAddSpare()
            }).catch((error) => {
                alert(error)
            })
        }
    }

    const handleOpenAddSpare = () => {
        setOpenAddSpare(true)
    }

    const handleCloseAddSpare = () => {
        setAddSpareName('')
        setAddSpareAmount(0)
        setAddSparePrice(0)
        setSpareNameErrorDiv(false)
        setSpareAmountErrorDiv(false)
        setSparePriceErrorDiv(false)
        setOpenAddSpare(false)
    }

    const validateAddSpare = () => {
        if(addSpareName == '') {
            setSpareNameErrorDiv(true)
            return false
        }
        if(addSpareAmount <= 0) {
            setSpareAmountErrorDiv(true)
            return false
        }
        if(addSparePrice <= 0) {
            setSparePriceErrorDiv(true)
            return false
        }

        return true
    }

    const handleEditSpare = () => {
        if(validateEditSpare) {
            const new_date = new Date()
            updateDoc(doc(db, `shops/${shopId}/spares/`, selectSpare), {
                spare_type: editSpareTypeId,
                spare_name: editSpareName,
                spare_price: editSparePrice,
                spare_description: editSpareDescription,
                date: new_date.getTime()
            }).then(() => {
                setSelectSpare('')
                setEditSpareName('')
                setEditSparePrice(0)
                setEditSpareDescription('')
                setEditSpareNameErrorDiv(false)
                setEditSparePriceErrorDiv(false)
                handleCloseEditSpare()
            }).catch((error) => {
                alert(error)
            })
        }
    }

    const handleOpenEditSpare = async (id) => {
        setSelectSpare(id)
        await getDoc(doc(db, `shops/${shopId}/spares/`, id)).then((snapshot) => {
            const data = snapshot.data()
            setEditSpareTypeId(data.spare_type)
            setEditSpareName(data.spare_name)
            setEditSparePrice(data.spare_price)
            setEditSpareDescription(data.spare_description)
        }).then(() => {
            setOpenEditSpare(true)
        })
    }

    const handleCloseEditSpare = () => {
        setSelectSpare('')
        setEditSpareName('')
        setEditSparePrice(0)
        setEditSpareDescription('')
        setEditSpareNameErrorDiv(false)
        setEditSparePriceErrorDiv(false)
        setOpenEditSpare(false)
    }

    const validateEditSpare = () => {
        if(editSpareName == '') {
            setEditSpareNameErrorDiv(true)
            return false
        }
        if(editSparePrice <= 0) {
            setEditSparePriceErrorDiv(true)
            return false
        }

        return true
    }

    const handleAddNumSpare = () => {
        if(validateAddNumSpare) {
            const new_date = new Date()
            updateDoc(doc(db, `shops/${shopId}/spares/`, selectSpare), {
                spare_amount: addNumSpareAmount*1,
                date: new_date.getTime()
            }).then(() => {
                setAddNumSpareAmount(0)
                setAddNumSpareAmountErrorDiv(false)
                handleCloseAddNumSpare()
            }).catch((error) => {
                alert(error)
            })
        }
    }

    const handleOpenAddNumSpare = async (id) => {
        setSelectSpare(id)
        await getDoc(doc(db, `shops/${shopId}/spares/`, id)).then((snapshot) => {
            const data = snapshot.data()
            setAddNumSpareAmount(data.spare_amount)
        }).then(() => {
            setOpenAddNumSpare(true)
        })
    }

    const handleCloseAddNumSpare = () => {
        setAddNumSpareAmount(0)
        setAddNumSpareAmountErrorDiv(false)
        setOpenAddNumSpare(false)
    }

    const validateAddNumSpare = () => {
        if(addNumSpareAmount <= 0) {
            setAddNumSpareAmountErrorDiv(true)
            return false
        }

        return true
    }

    const handleOpenViewSpare = () => {
    }

    const handleCloseViewSpare = () => {
    }

    const handleAddWithdraw = () => {
        if(validateAddWithdraw) {
            const new_date = new Date()
            console.log(withdrawSpareId)
            getDoc(doc(db, `shops/${shopId}/spares/`, withdrawSpareId)).then((snapshot) => {
                const data = snapshot.data()
                updateDoc(doc(db, `shops/${shopId}/spares/`, withdrawSpareId), {
                    spare_amount: (data.spare_amount*1) - withdrawAmount*1
                }).then(() => {
                    addDoc(collection(db, `shops/${shopId}/spares/${withdrawSpareId}/withdraws/`), {
                        withdraw_amount: withdrawAmount*1,
                        withdraw_date: withdrawDate.toDate(),
                        date: new_date.getTime(),
                        status: 1
                    }).then(() => {
                        setwithdrawAmountErrorDiv(false)
                        setwithdrawDateErrorDiv(false)
                        handleCloseAddWithdraw()
                    }).catch((error) => {
                        alert(error)
                    })
                }) 
            })
        }
    }

    const handleOpenAddWithdraw = (id) => {
        setWithdrawSpareId(id)
        setOpenAddWithdraw(true)
    }

    const handleCloseAddWithdraw = () => {
        setWithdrawSpareId('')
        setOpenAddWithdraw(false)
    }

    const validateAddWithdraw = () => {
        if(withdrawAmount <= 0) {
            setwithdrawAmountErrorDiv(true)
            return false
        }
        if(withdrawDate == "") {
            setwithdrawDateErrorDiv(true)
            return false
        }

        return true
        
    }

    const handleEditWithdraw = () => {

    }

    const handleOpenEditWithdraw = () => {

    }

    const handleCloseEditWithdraw = () => {

    }

    const validateEditWithdraw = () => {
        
    }

    const handleOpenViewWithdraw = () => {

    }

    const handleCloseViewWithdraw = () => {

    }




    const getDocShop = async(id) => {
        await getDoc(doc(db, 'shops', id)).then((shopSnapshot) => {
            setShop(shopSnapshot.data());
            if(shopSnapshot.data().user_id) {
                setMemberId(shopSnapshot.data().user_id)
                getDoc(doc(db, 'users', shopSnapshot.data().user_id)).then((memberDoc) => {
                    setMember(memberDoc.data())
                })
            }
        }).then(() => {
            const sparesSnapshot = query(collection(db, `shops/${shopId}/spares/`), orderBy('spare_name', 'asc'))
            onSnapshot(sparesSnapshot, (querySnapshot) => {
                spares.splice(0, spares.length)
                setSpares([...spares])
                querySnapshot.docs.forEach(async (doc) => {
                    var electricTypeName = ''
                    var promisesTask = []
                    var getElectricTask = await getElectric(db, doc.data().spare_type, '').then((name) => {
                        electricTypeName = name
                    })
                    promisesTask.push(getElectricTask)
                    Promise.all(promisesTask).then(() => {
                        spares.push({
                            id: doc.id,
                            data: doc.data(),
                            electricName: electricTypeName,
                        })
                        setSpares([...spares], {
                            id: doc.id,
                            data: doc.data(),
                            electricName: electricTypeName,
                        })
                    })
                })
            })
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        getDocShop(shopId)
        getElectricType()
    },[loading])

    var spareKey = []
    var spareList = []
    spares.forEach(element => {
        if(spareKey.lastIndexOf(element.id) < 0) {
            spareKey.push(element.id)
            spareList.push(element)
        }
    })

return (
    <>
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="spares" isShop={true} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ข้อมูลอะไหล่</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="technicians" isShop={true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingTop: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 30 }}>
                            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                                <h4 style={{ display: 'flex', flex: 1}}><GoGear style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                <b>จัดการอะไหล่</b></h4>
                            </div>
                            {/* <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                                <h5 style={{ display: 'flex', flex: 1}}>
                                    <b style={{ marginTop: 3 }}>เดือน</b>
                                    <select name='selectedMonth' style={{ marginLeft: 10, height: 28, fontSize: 15, marginRight: 15 }}>
                                        <option value="1">มกราคม</option>
                                        <option value="2">กุมภาพันธ์</option>
                                        <option value="3">มีนาคม</option>
                                        <option value="4">เมษายน</option>
                                        <option value="5">พฤษภาคม</option>
                                        <option value="6">มิถุนายน</option>
                                        <option value="7">กรกฎาคม</option>
                                        <option value="8">สิงหาคม</option>
                                        <option value="9">กันยายน</option>
                                        <option value="10">ตุลาคม</option>
                                        <option value="11">พฤศจิกายน</option>
                                        <option value="12">ธันวาคม</option>
                                    </select>
                                    <b style={{ marginTop: 3 }}>ปี</b>
                                    <select name='selectedYear' style={{ marginLeft: 10, height: 28, fontSize: 15, marginRight: 15 }}>
                                        <option value="2566">2566</option>
                                        <option value="2567">2567</option>
                                        <option value="2568">2568</option>
                                        <option value="2569">2569</option>
                                        <option value="2570">2570</option>
                                    </select>
                                </h5>
                            </div> */}
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 5, marginRight: 10 }}>
                                    <div style={{ paddingTop: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row' }}>
                                        <h6 style={{ display: 'flex', flex: 1, marginTop: 5}}><GoGear style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>รายการอะไหล่</b></h6>
                                        <div style={{ float: 'right',  }}>
                                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenAddSpare() }}>
                                                <GoGear style={{ fontSize: 20, marginRight: 5 }} />เพิ่มอะไหล่
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ }}>
                                        <table className="table" style={{  }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                                    <th scope="col" colSpan={3} style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: '90%'}}>รายการอะไหล่</th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ชื่อ (ประเภทเครื่องใช้ไฟฟ้า)</th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}><div className="d-none d-md-block d-lg-block">คงเหลือ</div></th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}><div className="d-none d-md-block d-lg-block">ราคา/ชิ้น (บาท)</div></th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', minWidth: 120 }}> </th>
                                                </tr>
                                            {
                                                spareList?.map((spare, i) => (
                                                    <tr key={i}>
                                                        <td style={{ paddingLeft: 10 }}> </td>
                                                        <th>
                                                            <font style={{ marginRight: 10 }}>ชื่อ: {spare.data.spare_name}</font><br/>
                                                            <font style={{ marginRight: 10, fontSize: 13 }}>({spare.electricName})</font>
                                                            <font style={{ fontSize: 14 }} className='d-block d-md-none d-lg-none'>คงเหลือ: {number_format.format(spare.data.spare_amount)} ชิ้น</font>
                                                            <font style={{ fontSize: 14 }} className='d-block d-md-none d-lg-none'>ราคา/ชิ้น: {number_format.format(spare.data.spare_price)} บาท</font>
                                                        </th>
                                                        <td><div className="d-none d-md-block d-lg-block">{number_format.format(spare.data.spare_amount)}</div></td>
                                                        <td><div className="d-none d-md-block d-lg-block">{number_format.format(spare.data.spare_price)}</div></td>
                                                        <td style={{ }}>
                                                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex', marginBottom: 5 }} onClick={() => { handleOpenAddWithdraw(spare.id) }}><HiArrowUpTray style={{ fontSize: 20, marginRight: 5 }} />เบิกอะไหล่</button>
                                                                {/* <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex', marginBottom: 5 }} onClick={() => { handleOpenViewSpare(spare.id) }} ><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูล</button> */}
                                                                <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex', marginBottom: 5 }} onClick={() => { handleOpenAddNumSpare(spare.id) }} ><TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขจำนวน</button>
                                                                <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex', marginBottom: 5 }} onClick={() => { handleOpenEditSpare(spare.id) }} ><TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            <Dialog open={openAddSpare} onClose={handleCloseAddSpare} fullWidth={true} maxWidth='md'>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowDownTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />เพิ่มรายการอะไหล่</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div><strong>ประเภทเครื่องใช้ไฟฟ้า</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <select name='incomeType' onChange={(e) => setAddSpareTypeId(e.target.value)} style={{ marginLeft: 10, height: 35, fontSize: 15, marginRight: 15 }}>
                                    {electricType?.map((type, i) => (
                                    <option value={type.id} key={type.id}>
                                        {type.data.name}
                                    </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ marginTop: 10 }}><strong>ชื่ออะไหล่</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={addSpareName}
                                    onChange={(e) => setAddSpareName(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {spareNameErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกชื่ออะไหล่</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>จำนวน</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    value={addSpareAmount}
                                    onChange={(e) => setAddSpareAmount(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {spareAmountErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกจำนวนอะไหล่</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>ราคา/ชิ้น</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    value={addSparePrice}
                                    onChange={(e) => setAddSparePrice(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {sparePriceErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกราคา/ชิ้น</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>รายละเอียดเพิ่มเติม</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <textarea style={{ marginLeft: 10, display: 'flex', flex: 1, height: 100 }}
                                    onChange={(e) => setAddSpareDescription(e.target.value)}
                                    placeholder='รายละเอียดเพิ่มเติม' 
                                    value={addSpareDescription}></textarea>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAddSpare} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleAddSpare} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEditSpare} onClose={handleCloseEditSpare}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowDownTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />แก้ไขข้อมูลอะไหล่</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div><strong>ประเภทเครื่องใช้ไฟฟ้า</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <select name='editIncomeType' value={editSpareTypeId} onChange={(e) => setEditSpareTypeId(e.target.value)} style={{ marginLeft: 10, height: 35, fontSize: 15, marginRight: 15 }}>
                                    {electricType?.map((type, i) => (
                                    <option value={type.id} key={type.id}>
                                        {type.data.name}
                                    </option>
                                    ))}
                                </select>
                            </div>
                            <div style={{ marginTop: 10 }}><strong>ชื่ออะไหล่</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="text"
                                    fullWidth
                                    variant="standard"
                                    value={editSpareName}
                                    onChange={(e) => setEditSpareName(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {editSpareNameErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกชื่ออะไหล่</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>ราคา/ชิ้น</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    value={editSparePrice}
                                    onChange={(e) => setEditSparePrice(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {editSparePriceErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกราคา/ชิ้น</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>รายละเอียดเพิ่มเติม</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <textarea style={{ marginLeft: 10, display: 'flex', flex: 1, height: 100 }}
                                    onChange={(e) => setEditSpareDescription(e.target.value)}
                                    placeholder='รายละเอียดเพิ่มเติม' 
                                    value={editSpareDescription}></textarea>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseEditSpare} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleEditSpare} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAddNumSpare} onClose={handleCloseAddNumSpare}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowDownTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />แก้ไขจำนวนอะไหล่</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div style={{ marginTop: 10 }}><strong>จำนวนคงเหลือ</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    value={addNumSpareAmount}
                                    onChange={(e) => setAddNumSpareAmount(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {AddNumSpareAmountErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกจำนวนอะไหล่</strong>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAddNumSpare} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleAddNumSpare} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openViewSpare} onClose={handleCloseViewSpare}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowUpTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />ข้อมูลอะไหล่</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <strong style={{ marginRight: 10 }}>ประเภทรายรับ: </strong>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>ชื่อ: </strong> 
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>ราคา/ชิ้น: </strong>  บาท
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>จำนวนคงเหลือ: </strong>  บาท
                            </div>
                            <div style={{ marginTop: 10 }}></div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>รายละเอียดเพิ่มเติม: </strong> 
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseViewSpare} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ปิดหน้าต่าง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAddWithdraw} onClose={handleCloseAddWithdraw}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowUpTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />เพิ่มรายการเบิกอะไหล่</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div style={{ marginTop: 10 }}><strong>วันที่เบิก</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 10}}>
                                <div style={{ marginLeft: 10, display: 'flex', flex: 1, flexDirection: 'column'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="วันที่เบิก" format="DD/MM/YYYY" onChange={(value) => setWithdrawDate(value)} />
                                    </LocalizationProvider>
                                </div>
                                {withdrawDateErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกวันที่เบิก</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>จำนวน</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    value={withdrawAmount}
                                    onChange={(e) => setWithdrawAmount(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {withdrawAmountErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกจำนวนเงิน</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>รายละเอียดเพิ่มเติม</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <textarea style={{ marginLeft: 10, display: 'flex', flex: 1, height: 100 }}
                                    onChange={(e) => setWithdrawDescription(e.target.value)}
                                    placeholder='รายละเอียดเพิ่มเติม' 
                                    value={withdrawDescription}></textarea>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAddWithdraw} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleAddWithdraw} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>


        </div>
    </>
)
}

export default Finances;