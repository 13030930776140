import React, { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, orderBy, onSnapshot, addDoc, updateDoc, doc, deleteDoc, and, or } from 'firebase/firestore'
import { useParams, Link, useNavigate } from "react-router-dom";
import LeftMenu from './LeftMenu';
import { IoMegaphoneOutline } from 'react-icons/io5'

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { Loading } from "../layouts/Loading"

const Appointments = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const params = useParams()
    const [user, loading, error] = useAuthState(auth)
    const [uid, setUid] = useState('')
    const [user_id, setUserId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [shopId, setShopId] = useState(params.shopId)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [showLoading, setShowLoading] = useState(true)

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState('')

    const [appointments, setAppointments] = useState([])
    const [chooseAppointmentId, setChooseAppointmentId] = useState('')
    const [choosePostId, setChoosePostId] = useState('')
    const [choosePostOfferId, setChoosePostOfferId] = useState('')
    const [chooseUserId, setChooseUserId] = useState('')
    const [chooseUserOfferId, setChooseUserOfferId] = useState('')

    const [openFinishAppointment, setOpenFinishAppointment] = useState(false)
    const [openCancleAppointment, setOpenCancleAppointment] = useState(false)

    const handleOpenFinishAppointment = (appointmentId) => {
        setChooseAppointmentId(appointmentId)
        setOpenFinishAppointment(true)
    }

    const handleCloseFinishAppointment = () => {
        setChooseAppointmentId('')
        setOpenFinishAppointment(false)
    }

    const handleFinishAppointment = () => {
        const new_date = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointmentshops/`, chooseAppointmentId), { 
            status: 3,
            finish_message: message,
            score: score,
            finish_datetime: new_date.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        Promise.all(promisesTask).then(() => {
            handleCloseFinishAppointment()
            navigate(0)
        })
    }

    const handleOpenCancleAppointment = (appointmentId, postId, postOfferId, userId, userOfferId) => {
        setChooseAppointmentId(appointmentId)
        setChoosePostId(postId)
        setChoosePostOfferId(postOfferId)
        setChooseUserId(userId)
        setChooseUserOfferId(userOfferId)
        setOpenCancleAppointment(true)
    }

    const handleCloseCancleAppointment = () => {
        setChooseAppointmentId('')
        setChoosePostId('')
        setChoosePostOfferId('')
        setChooseUserId('')
        setChooseUserOfferId('')
        setOpenCancleAppointment(false)
    }

    const handleCancleAppointment = () => {
        const new_date = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointmentshops/`, chooseAppointmentId), { 
            status: 5,
            message: message,
            reason: reason,
            cancle_datetime: new_date.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        //// โอนเหรียญคืนผู้จอง
        //// โอนเหรียญคืนผู้จอง

        var updatePostTask = updateDoc(doc(db, `/postshops/`, choosePostId), { status: 1 })
        promisesTask.push(updatePostTask)
        
        var updateUserOfferTask = updateDoc(doc(db, `/users/${chooseUserId}/offers`, chooseUserOfferId), { status: 7 })
        promisesTask.push(updateUserOfferTask)
        
        var addCancleHistoryTask = addDoc(collection(db, `postshops/${choosePostId}/cancle_offers/`), {
            user_id: chooseUserId,
            cancle_message: message,
            cancle_reason: reason,
            cancle_datetime: new_date.getTime()
        })
        promisesTask.push(addCancleHistoryTask)

        var deleteOfferTask = deleteDoc(doc(db, `posts/${choosePostId}/offers/`, choosePostOfferId))
        promisesTask.push(deleteOfferTask)

        Promise.all(promisesTask).then(() => {
            handleCloseCancleAppointment(false)
            navigate(0)
        })
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const fetchAppointments = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setUserId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) {
                    setIsShop(data.is_shop)
                }
                if(data.freecoin) setOfferFreeCoin(data.freecoin)
                if(data.exchange_coin) setOfferExchangeCoin(data.exchange_coin)
                const postQuery = query(collection(db, 'appointmentshops'), where('shop_id', '==', shopId),
                                            /* and(where('user_id', '==', docs.docs[0].id), 
                                            or(where('status', '==', 1), where('status', '==', 2),
                                                where('status', '==', 3), where('status', '==', 6))), */
                                            orderBy('appointment_date', 'asc'), orderBy('appointment_hour', 'asc'), orderBy('appointment_minute', 'asc'))
                onSnapshot(postQuery, (querySnapshot) => {
                    appointments.splice(0, appointments.length)
                    setAppointments([...appointments])
                    querySnapshot.forEach( async(appointment) => {
                        console.log(appointment.data().post_id)
                        const postSnapshot = await getDoc(doc(db, `postshops`, appointment.data().post_id))
                        const offerSnapshot = await getDoc(doc(db, `postshops/${appointment.data().post_id}/offers`, appointment.data().offer_id))
                        const userSnapshot = await getDoc(doc(db, 'users', appointment.data().user_id))
                        
                        var brand = 'ไม่ระบุ'
                        var model = 'ไม่ระบุ'
                        if (postSnapshot.exists()) {
                            var promisesTask = []
                            if(postSnapshot.data().brand_id) {
                                if(postSnapshot.data().brand_id == '999') {
                                    if(postSnapshot.data().brand) {
                                        brand = postSnapshot.data().brand
                                    }
                                } else {
                                    var brand_id = postSnapshot.data().brand_id
                                    var getBrandTask = await getDoc(doc(db, 'brands', brand_id)).then((docBrand) => {
                                        brand = docBrand.data().name
                                    })
                                    promisesTask.push(getBrandTask)
                                }
                            } else {
                                if(postSnapshot.data().brand) {
                                    brand = postSnapshot.data().brand
                                }
                            }
                            if(postSnapshot.data().model_id) {
                                if(postSnapshot.data().model_id == '999') {
                                    if(postSnapshot.data().model) {
                                        model = postSnapshot.data().model
                                    }
                                } else {
                                    var model_id = postSnapshot.data().model_id
                                    var getModelTask = await getDoc(doc(db, 'models', model_id)).then((docModel) => {
                                        model = docModel.data().name
                                    })
                                    promisesTask.push(getModelTask)
                                }
                            } else {
                                if(postSnapshot.data().model) {
                                    model = postSnapshot.data().model
                                }
                            }

                            Promise.all(promisesTask)
                        }


                        var badgeStyle = { message: '', class: 'success', color: '#000000', show: false }
                        if(appointment.data().status==1) {
                            badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                        } else if(appointment.data().status==2) {
                            badgeStyle = { message: 'ร้านค้าจ่ายเหรียญซื้อสินค้า', class: 'success', color: '#ffffff', show: true }
                        } else if(appointment.data().status==3) {
                            badgeStyle = { message: 'ยืนยันขายซากสินค้า', class: 'success', color: '#ffffff', show: true }
                        } else if(appointment.data().status==4) {
                            badgeStyle = { message: 'ร้านค้ายกเลิกซื้อสินค้า', class: 'danger', color: '#ffffff', show: true }
                        } else if(appointment.data().status==5) {
                            badgeStyle = { message: 'ยกเลิกขายสินค้า', class: 'danger', color: '#ffffff', show: true }
                        } else if(appointment.data().status==6) {
                            badgeStyle = { message: 'ร้านค้ายืนยันได้รับสินค้า', class: 'success', color: '#ffffff', show: true }
                        } else if(appointment.data().status==7) {
                        } else if(appointment.data().status==8) {
                        } else if(appointment.data().status==9) {
                        }
                        
                        appointments.push({
                            id: appointment.id,
                            data: appointment.data(),
                            offer: offerSnapshot.data(),
                            post: postSnapshot.data(),
                            user: userSnapshot.data(),
                            brand: brand,
                            model: model,
                            badgeStyle: badgeStyle
                        })

                        setAppointments([...appointments, {
                            id: appointment.id,
                            data: appointment.data(),
                            offer: offerSnapshot.data(),
                            post: postSnapshot.data(),
                            user: userSnapshot.data(),
                            brand: brand,
                            model: model,
                            badgeStyle: badgeStyle
                        }])
                    })
                })
            }
        }).then(() => {
            setShowLoading(false)
        }).catch((error) => {
            setShowLoading(false)
            alert(error.message);
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else fetchAppointments()
    }, [user, loading])

    var appointmentKey = []
    var appointmentList = []
    appointments.forEach(async(element) => {
        if(appointmentKey.lastIndexOf(element.id) < 0) {
            appointmentKey.push(element.id)
            appointmentList.push(element)
        }
    })

    return (
    <>
        <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="appointmentposts" isShop={true} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', paddingTop: 5 }}><h4><b>การนัดหมายของฉัน</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="appointmentposts" isShop={true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {appointmentList?.map((appointment, i) => (
                            <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
                                {appointment.badgeStyle.show && (<>
                                <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                    <button type="button" className={"btn "+"btn-"+appointment.badgeStyle.class} style={{ color: appointment.badgeStyle.color }}><strong>{appointment.badgeStyle.message}</strong></button>
                                </div>
                                </>)}

                                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                    <img src={appointment.post.picture} alt="WEEE" height="150" />
                                </div>

                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                    <p className="card-text">
                                        {(appointment.data.appointment_type == 'post') && (
                                            <><strong>ประเภทนัดหมาย:</strong> ขายเครื่องใช้ไฟฟ้ามือสอง<br/></>
                                        )}
                                        <>{appointment.brand} {appointment.model}<br/></>

                                        <><strong>วันนัดหมาย:</strong> {dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/></>
                                        {/* <><strong>ที่อยู่:</strong> {appointment.post.address} <br/></> */}

                                        <><strong>วิธีจัดส่ง:</strong> {number_format.format(appointment.offer.appointment_delivery)} <br/></>
                                        <><strong>ราคา:</strong> {number_format.format(appointment.offer.price)} บาท <br/></>
                                        {(appointment.data.appointment_type == 'post') && (
                                            <>
                                            {(appointment.data.status == 6) && (<>
                                                <strong>คะแนน:</strong> {appointment.data.score} <br/>
                                                <strong>คำติชม:</strong> {appointment.data.finish_message} <br/>
                                            </>)}
                                            </>
                                        )}
                                    </p>
                                </div>
                            
                            <div className="card-body">
                                <>
                                    <Link className="btn btn-primary m-1" to={`/shop/post/${appointment.data.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                                    <Link className="btn btn-primary m-1" to={`/appointmentshops/${appointment.data.post_id}`} target='_blank'><strong>ดูนัดหมายและข้อเสนอ</strong></Link>
                                </>
                                {(appointment.data.status == 1) && (<>
                                    <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCancleAppointment(appointment.id, appointment.data.post_id, appointment.data.offer_id, appointment.data.user_id, appointment.data.user_offer_id) }}>
                                        <strong>ยกเลิกการขายสินค้า</strong>
                                    </button>
                                </>)}
                                {(appointment.data.status == 2) && (<>
                                    <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenFinishAppointment(appointment.id) }}>
                                        <strong>ยืนยันได้รับเหรียญ</strong>
                                    </button>
                                </>)}
                            </div>
                        </div>
                        ))}
                        {appointmentList.length <= 0 && (
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <font className="text-danger" style={{ fontWeight: '600'}}>ไม่พบการนัดหมายของร้านค้า</font>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>

            {/* Post - Board 2 Dialog */}
            <>
            <Dialog open={openFinishAppointment} onClose={handleCloseFinishAppointment} fullWidth={true} maxWidth='sm'>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันการขายสินค้ามือสอง</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex', width: 200}}><h5><strong>คำติชมเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='คำติชมเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                        <label className="" style={{ display: 'flex'}}><h5><strong>คะแนน</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                            <select name='score' onChange={(e) => setScore(e.target.value)} style={{ flex: 1 }}>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseFinishAppointment} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleFinishAppointment} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันการขายสินค้า</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancleAppointment} onClose={handleCloseCancleAppointment} fullWidth={true} maxWidth='sm'>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการขายสินค้า</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15, flexDirection: 'column' }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ flex: 1 }}>
                                                <option value="1">มารับไม่ตรงวันและเวลานัด</option>
                                                <option value="2">มีการเรียกรับเงินเพิ่ม</option>
                                                <option value="3">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleAppointment} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleAppointment} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการขายสินค้า</button>
                </DialogActions>
            </Dialog>
            </>
        </div>
    </>
    )
}

export default Appointments