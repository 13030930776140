import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, updateDoc, doc, orderBy,onSnapshot } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import { BsCalendarDate } from "react-icons/bs"
import { TbMessageCircle2 } from 'react-icons/tb'
import { PiHandCoinsDuotone } from 'react-icons/pi'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { MdOutlineLocalOffer } from 'react-icons/md'
import { AiFillExclamationCircle } from "react-icons/ai"
import LeftMenu from './LeftMenu'

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Notifications = () => {
    const navigate = useNavigate();
    const [user, loading] = useAuthState(auth)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [uid, setUid] = useState('')
    const [userId, setUserId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [notifications, setNotifications] = useState([])
    const [systemMessage, setsystemMessage] = useState([])

    const getDocUser = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setUserId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)

                const notiQuery = query(collection(db, `users/${docs.docs[0].id}/notifications`), orderBy('date', 'desc'))
                onSnapshot(notiQuery, (querySnapshot) => {
                    setNotifications(querySnapshot.docs.map((doc) => { 
                        return {
                            id: doc.id,
                            data: doc.data(),
                        }
                    }))
                })

                const messageQuery = query(collection(db, `users/${docs.docs[0].id}/system_message`), orderBy('date', 'desc'))
                onSnapshot(messageQuery, (querySnapshot) => {
                    setsystemMessage(querySnapshot.docs.map((doc) => { 
                        return {
                            id: doc.id,
                            data: doc.data(),
                        }
                    }))
                })

            }
        }).catch((err) => {
            alert(err.message)
        })
    }
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]+" น."
    }

    const readSystemNoti = (id, url) => {
        updateDoc(doc(db, `/users/${userId}/notifications`, id), {
            status: 1,
        }).then(() => {
            const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
            if (newWindow) newWindow.opener = null
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }
    
    useEffect(() => {
        if (loading) return
        if (!user) return navigate("/")
        else { 
            getDocUser() 
        }
    }, [user, loading])

    return (
    <>
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 280 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="notifications" isShop={isShop} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 10, marginBottom: 20 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ข้อความแจ้งเตือน</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="notifications" isShop={isShop} />
                    </div>
                    <div className="flex-container" style={{ display: 'flex', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 4 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #cccccc', marginBottom: 10}}>
                                <h4 style={{ display: 'flex', flex: 1}}><TbMessageCircle2 style={{ fontSize: 30, marginRight: 10 }} />
                                <b>ข้อความจากผู้ดูแลระบบ</b></h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                                {systemMessage?.map((message, i) => (
                                    <div key={i}>
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                            <div style={{ display: 'flex', width: 30 }}>
                                                <IoMegaphoneOutline style={{ fontSize: 25 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <font style={{ fontSize: 18 }}>{message.data.message}</font>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <font style={{ fontSize: 14 }}>
                                                        เวลา {dateFormat(message.data.date)}
                                                        <a href={`${message.data.link}`} target='_blank' className="nav-link link-primary">ดูรายละเอียดประกาศ</a>
                                                        <a href={`#`} onClick={() => {}} className="nav-link link-primary">ตอบกลับ</a>
                                                    </font>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                                {systemMessage.length <= 0 && (
                                    <div style={{ display: 'flex', alignItems: 'center', marginBottom: 20}}>
                                        <font className="text-danger" style={{ fontWeight: '600'}}>ไม่มีข้อความจากผู้ดูแลระบบ</font>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                        <div className="d-none d-md-block d-lg-block" style={{ minWidth: 10, marginLeft: 10, borderLeft: '1px solid #cccccc' }}>&nbsp;</div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', borderBottom: '1px solid #cccccc', marginBottom: 10}}>
                                <h4 style={{ display: 'flex', flex: 1}}><TbMessageCircle2 style={{ fontSize: 30, marginRight: 10 }} />
                                <b>ข้อความแจ้งเตือน</b></h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                                {notifications?.map((noti, i) => (
                                    <div key={i}>
                                    {noti.data.type && (
                                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                            {noti.data.status == 0 &&<div style={{ display: 'flex', width: 30 }}>
                                                <AiFillExclamationCircle className='text-danger' style={{ fontSize: 25, marginRight: 5 }} />
                                            </div>}
                                            <div style={{ display: 'flex', width: 30 }}>
                                                {!noti.data.type && (<TbMessageCircle2 style={{ fontSize: 25 }} />)}
                                                {noti.data.type == 'point' && (<TbMessageCircle2 style={{ fontSize: 25 }} />)}
                                                {noti.data.type == 'offer' && (<MdOutlineLocalOffer style={{ fontSize: 25 }} />)}
                                                {noti.data.type == 'post' && (<IoMegaphoneOutline style={{ fontSize: 25 }} />)}
                                                {noti.data.type == 'clean' && (<IoMegaphoneOutline style={{ fontSize: 25 }} />)}
                                                {noti.data.type == 'appointment' && (<BsCalendarDate style={{ fontSize: 25 }} />)}
                                                {noti.data.type == 'coin' && (<PiHandCoinsDuotone style={{ fontSize: 25 }} />)}
                                                
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <font style={{ fontSize: 18 }}>{noti.data.message}</font>
                                                </div>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <font style={{ fontSize: 14 }}>
                                                        เวลา {dateFormat(noti.data.date)}
                                                        <button style={{ textAlign: 'center', marginLeft: 10, fontSize: 12 }} className="btn btn-primary btn-sm" type='button' onClick={() => { readSystemNoti(noti.id, noti.data.link) }}>ดูรายละเอียด</button>
                                                    </font>
                                                </div>
                                            </div>
                                        </div>
                                    )}
                                    </div>
                                ))}
                                {notifications.length <= 0 && (
                                    <div style={{ display: 'flex', alignItems: 'center'}}>
                                        <font className="text-danger" style={{ fontWeight: '600'}}>ไม่มีข้อความแจ้งเตือน</font>
                                    </div>
                                )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Notifications