import { query, collection, getDocs, where, orderBy, getDoc, doc } from "firebase/firestore"

export async function getProvinceList(db) {
    var provinceList = []
    await getDocs(query(collection(db, 'provinces'), orderBy('province_id', 'asc'))).then((docs) => {
        provinceList = docs
    })
    return provinceList
}

export async function getDistrictListByProvince(db, provinceId) {
    var districtList = {}
    await getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceId * 1))).then((docs) => {
        districtList = docs
    })
    return districtList
}

export async function getSubDistrictListByDistrict(db, districtId) {
    var subDistrictList = {}
    await getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtId))).then((docs) => {
        subDistrictList = docs
    })
    return subDistrictList
}

export async function getPostcodeBySubDistrict(db, subDistrictId) {
    var postcode = {}
    await getDocs(query(collection(db, 'postcodes'), where('subdistrict_id', '==', subDistrictId*1))).then((docs) => {
        postcode = docs
    })
    return postcode
}

export async function getProvice(db, id) {
    var name = ""
    await getDocs(query(collection(db, 'provinces'), where('province_id', '==', id*1))).then((docs) => {
        name = docs.docs[0].data().province_name
    })
    return name
}

export async function getDistrict(db, id) {
    var name = ""
    await getDocs(query(collection(db, 'distrincts'), where('district_id', '==', id))).then((docs) => {
        name = docs.docs[0].data().district_name
    })
    return name
}

export async function getSubDistrict(db, id) {
    var name = ""
    await getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', id*1))).then((docs) => {
        name = docs.docs[0].data().subdistrict_name
    })
    return name
}