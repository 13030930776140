import { useState, useEffect } from 'react'
import "../../assets/css/modal.css";
import { db, auth } from "../../assets/js/firebase";
import { updateDoc, doc } from 'firebase/firestore'
import { useAuthState } from "react-firebase-hooks/auth";
import { useNavigate } from "react-router-dom";
import { BsCalendarDate } from "react-icons/bs"

function CancleAppointment({open, onClose, appointmentId}, props) {
    const [user, loading, error] = useAuthState(auth);
    const navigate = useNavigate();
    const [message, setMessage] = useState('');
    const [reason, setReason] = useState('');

    const handleClose = (e) => {
        if(e.target.className === 'modalContainer'){
            onClose()
        }
        return null
    }

    const handleSubmit = (e) => {
    	e.preventDefault()
        try {
            const new_date = new Date()
            updateDoc(doc(db, `/appointments/`, appointmentId), 
                { 
                    status: 3,
                    message: message,
                    reason: reason,
                    cancle_datetime: new_date.getTime()
                }
            ).then(() => {
                onClose()
            })
        } catch (err) {
            alert(err)
        }
    }

    useEffect(() => {
        if (loading) return;
    }, [user, loading]);

    if(open) {
        return (
            <div className='modalContainer' onClick={handleClose}>
                <div className='modal' style={{  }}>
                    <div className='modal__head'>
                        <div style={{ marginTop: 10, marginLeft: 5}}><h4><BsCalendarDate style={{ fontSize: 30, marginRight: 10, marginBottom: 3 }} /><strong>ปิดงาน</strong></h4></div>
                        <span className='modal__close' onClick={onClose}>x</span>
                    </div>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', marginTop: 10, flexDirection: 'column', width:'100%', marginBottom: 15 }}>
                                <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#eeeeee', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5 }}>
                                                <option value="1">มารับไม่ตรงวันและเวลานัด</option>
                                                <option value="2">มีการเรียกรับเงินเพิ่ม</option>
                                                <option value="3">ความพร้อมหรือความน่าเชื่อถือของร้านค้า</option>
                                                <option value="4">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                <div style={{ backgroundColor: '#eeeeee'}}>
                                    <div className="mb-3 row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#eeeeee', marginLeft: 0, marginRight: 0,  }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width:"100%", height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-grid gap-2" style={{ marginTop: 10 }}>
                                    <button className="btn btn-primary" type='button' onClick={handleSubmit}>ยืนยัน</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
    return null
}

export default CancleAppointment