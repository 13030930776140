import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc, orderBy } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { useParams, useNavigate } from "react-router-dom"
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import '../../assets/css/DatePicker.css'
import '../../assets/css/Calendar.css'
import addImage from "../../assets/images/add-image-icon.png"
import { FaMapMarkerAlt } from "react-icons/fa"
import dayjs from 'dayjs'

import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import { defauleImageSize } from "../../../assets/constants/images"
import { defaultLatLng, googleMapsApiKey, locationOptions } from "../../../assets/constants/locations"
import { 
    getProvinceList, 
    getDistrictListByProvince, 
    getSubDistrictListByDistrict, 
    getPostcodeBySubDistrict 
} from "../../../assets/js/AreaHelper"
import { Loading } from "../layouts/Loading"

const AddSellGabagePost = () => {
    const params = useParams()
    const navigate = useNavigate()
    const today = dayjs()
    const shopId = params.shopId
    const number_format = new Intl.NumberFormat()
    const [user, loading, error] = useAuthState(auth)

    const [uid, setUid] = useState('')
    const [docId, setDicId] = useState('')
    const [memberId, setMenberId] = useState('')

    const [electricType, setElectricType] = useState([])
    const [brandList, setBrandList] = useState([])
    const [modelList, setModelList] = useState([])

    const [price, setPrice] = useState('')
    const [picture1, setPicture1] = useState('')
    const [picture2, setPicture2] = useState('')
    const [picture3, setPicture3] = useState('')
    const [picture4, setPicture4] = useState('')
    const [picture5, setPicture5] = useState('')
    const [electricTypeId, setElectricTypeId] = useState(0)
    const [otherType, setOtherType] = useState('')
    const [brandId, setBrandId] = useState('')
    const [brand, setBrand] = useState('') // other brand
    const [modelId, setModelId] = useState('')
    const [model, setModel] = useState('') // other model
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [length, setLength] = useState('')
    const [weight, setWeight] = useState('')
    const [amount, setAmount] = useState(1)

    const [deliveryOption1, setDeliveryOption1] = useState(true)
    const [deliveryOption2, setDeliveryOption2] = useState(false)
    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [buyYear, setBuyYear] = useState('')
    const [condition, setCondition] = useState(1)
    const [option1, setOption1] = useState(false)
    const [option2, setOption2] = useState(false)
    const [option3, setOption3] = useState(false)
    const [option4, setOption4] = useState(false)
    const [description, setDescription] = useState('')
    const [address, setAddress] = useState('')
    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [isOnwer, setIsOwner] = useState(true)
    const [pickDate, setPickDate] = useState(today)
    const [hour, setHour] = useState('09')
    const [minute, setMinute] = useState('00')

    const [priceErrorDiv, setPriceErrorDiv] = useState(false)
    const [pictureErrorDiv, setPictureErrorDiv] = useState(false)
    const [typeIdErrorDiv, setTypeIdErrorDiv] = useState(false)
    const [otherTypeErrorDiv, setOtherTypeErrorDiv] = useState(false)
    const [useCoinErrorDiv, setUseCoinErrorDiv] = useState(false)
    const [brandIdErrorDiv, setBrandIdErrorDiv] = useState(false)
    const [brandErrorDiv, setBrandErrorDiv] = useState(false)
    const [modelIdErrorDiv, setModelIdErrorDiv] = useState(false)
    const [modelErrorDiv, setModelErrorDiv] = useState(false)
    const [buyYearErrorDiv, setBuyYearErrorDiv] = useState(false)

    const [sizeNumErrorDiv, setSizeNumnErrorDiv] = useState(false)
    const [weightNumErrorDiv, setWeightNumErrorDiv] = useState(false)
    const [amountNumErrorDiv, setAmountNumErrorDiv] = useState(false)
    
    const [srcPicture1, setSrcPicture1] = useState(addImage)
    const [srcPicture2, setSrcPicture2] = useState(addImage)
    const [srcPicture3, setSrcPicture3] = useState(addImage)
    const [srcPicture4, setSrcPicture4] = useState(addImage)
    const [srcPicture5, setSrcPicture5] = useState(addImage)

    const [offerUseCoin, setOfferUseCoin] = useState(0)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [useCoinFrom, setUseCoinFrom] = useState(1)
    const [gpUseCoin, setGpUseCoin] = useState(0)

    const [showProgressBar, setShowProgressBar] = useState(false)
    const [imageSizeError, setImageSizeError] = useState(false)

    const [currentLat, setCurrentLat] = useState(defaultLatLng.lat)
    const [currentLng, setCurrentLng] = useState(defaultLatLng.lng)
    const [regisLat, setRegisLat] = useState(defaultLatLng.lat)
    const [regisLng, setRegisLng] = useState(defaultLatLng.lng)

    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [provinceList, setProvinceList] = useState([])
    const [provinceId, setProvinceId] = useState(0)
    const [districtList, setDistrictList] = useState([])
    const [districtId, setDistrictId] = useState(0)
    const [subdistrictList, setSubDistrictList] = useState([])
    const [subdistrictId, setSubDistrictId] = useState(0)
    const [postcode, setPostcode] = useState('')

    const [addressNoRegis, setAddressNoRegis] = useState('')
    const [villageRegis, setVillageRegis] = useState('')
    const [buildingRegis, setBuildingRegis] = useState('')
    const [floorRegis, setFloorRegis] = useState('')
    const [roomRegis, setRoomRegis] = useState('')
    const [subroadRegis, setSubroadRegis] = useState('')
    const [roadRegis, setRoadRegis] = useState('')
    const [provinceIdRegis, setProvinceIdRegis] = useState(0)
    const [districtIdRegis, setDistrictIdRegis] = useState(0)
    const [subdistrictIdRegis, setSubDistrictIdRegis] = useState(0)
    const [postcodeRegis, setPostcodeRegis] = useState('')

    const [provinceIdErrorDiv, setProvinceIdErrorDiv] = useState(false)
    const [districtIdErrorDiv, setDistrictIdErrorDiv] = useState(false)
    const [subdistrictIdErrorDiv, setSubDistrictErrorDiv] = useState(false)

    const { isLoaded } = useLoadScript( { googleMapsApiKey: googleMapsApiKey } )
    const [center, setCenter] = useState( { lat: defaultLatLng.lat, lng: defaultLatLng.lng } )
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    const handelGoToRegisPosition = () => {
        setLat(regisLat)
        setLng(regisLng)
        setCenter({lat: regisLat, lng: regisLng})
    }

    const handelGoToCurrentPosition = () => {
        setLat(currentLat)
        setLng(currentLng)
        setCenter({lat: currentLat, lng: currentLng})
    }

    const locationSuccess = (pos) => {
        var crd = pos.coords
        setCurrentLat(crd.latitude)
        setCurrentLng(crd.longitude)
    }

    const locationErrors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                //console.log(result);
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
            });
        } else {
          //console.log("Geolocation is not supported by this browser.");
        }
    }, [])

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    function isOnlyNumericDecimal(text) {
        return /^[0-9.\b]+$/.test(text)
    }

    const handleImage1 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture1(file)
            setSrcPicture1(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const handleImage2 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture2(file)
            setSrcPicture2(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const handleImage3 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture3(file)
            setSrcPicture3(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const handleImage4 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture4(file)
            setSrcPicture4(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const handleImage5 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture5(file)
            setSrcPicture5(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const validateForm = () => {
        if(useCoinFrom == 1) {
            if(offerUseCoin > offerFreeCoin) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if (useCoinFrom == 2) {
            if(offerUseCoin > offerExchangeCoin) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        }
        
        if(!price || !isOnlyNumeric(price)) {
            setPriceErrorDiv(true)
            return false
        } else {
            setPriceErrorDiv(false)
        }
        if(!picture1 && !picture2 && !picture3 && !picture4 && !picture5) {
            setPictureErrorDiv(true)
            return false
        } else {
            setPictureErrorDiv(false)
        }
        if(!electricTypeId) {
            setTypeIdErrorDiv(true)
            return false
        } else {
            if(electricTypeId == '999') {
                if(!otherType) {
                    setOtherTypeErrorDiv(true)
                    return false
                } else {
                    setOtherTypeErrorDiv(false)
                }
            } else {
                setOtherTypeErrorDiv(false)
                setTypeIdErrorDiv(false)
            }
        }
        if(!brandId) {
            setBrandIdErrorDiv(true)
            return false
        } else {
            if(brandId == '999') {
                if(!brand) {
                    setBrandErrorDiv(true)
                    return false
                } else {
                    setBrandErrorDiv(false)
                }
            } else {
                setBrandErrorDiv(false)
                setBrandIdErrorDiv(false)
            }
        }
        if(!modelId) {
            setModelIdErrorDiv(true)
            return false
        } else {
            if(modelId == '999') {
                if(!model) {
                    setModelErrorDiv(true)
                    return false
                } else {
                    setModelErrorDiv(false)
                }
            } else {
                setModelErrorDiv(false)
                setModelIdErrorDiv(false)
            }
        }
        if((width && !isOnlyNumericDecimal(width)) || (height && !isOnlyNumericDecimal(height)) || (length && !isOnlyNumericDecimal(length))) {
            setSizeNumnErrorDiv(true)
            return false
        } else {
            setSizeNumnErrorDiv(false)
        }

        if((weight && !isOnlyNumericDecimal(weight))) {
            setWeightNumErrorDiv(true)
            return false
        } else {
            setWeightNumErrorDiv(false)
        }

        if((amount && !isOnlyNumericDecimal(amount)) || (amount * 1 < 1)) {
            setAmountNumErrorDiv(true)
            return false
        } else {
            setAmountNumErrorDiv(false)
        }

        if(!buyYear || !isOnlyNumeric(buyYear) || (buyYear.toString().length != 4)) {
            setBuyYearErrorDiv(true)
            return false;
        } else {
            setBuyYearErrorDiv(false)
        }

        if(provinceId == 0) {
            setProvinceIdErrorDiv(true)
            return false;
        } else {
            setProvinceIdErrorDiv(false)
        }

        if(districtId == 0) {
            setDistrictIdErrorDiv(true)
            return false;
        } else {
            setDistrictIdErrorDiv(false)
        }

        if(subdistrictId == 0) {
            setSubDistrictErrorDiv(true)
            return false;
        } else {
            setSubDistrictErrorDiv(false)
        }

        return true
    }

    const handleSubmit = (e) => {
    	e.preventDefault()
        if(validateForm()) {
            const new_date = new Date()
            setShowProgressBar(true)
            addDoc(collection(db, 'postshops'), {
                onboard: 3,
                poster_id: shopId,
                uid: uid,
                price: price*1,
                type_id: electricTypeId,
                other_type: otherType,
                brand_id: brandId,
                brand: brand,
                model_id: modelId,
                model: model,
                width: width*1,
                height: height*1,
                length: length*1,
                weight: weight*1,
                amount: amount*1,
                buy_year: buyYear*1,
                condition: condition,
                option1: option1,
                option2: option2,
                option3: option3,
                option4: option4,
                delivery_option1: deliveryOption1,
                delivery_option2: deliveryOption2,
                delivery_price: deliveryPrice*1,
                description: description,
                address: address,
                address_no: addressNo,
                road: road,
                subroad: subroad,
                village: village,
                building: building,
                floor: floor,
                room: room,
                sub_district: subdistrictId,
                district: districtId,
                province: provinceId,
                postcode: postcode,
                lat: lat,
                lng: lng,
                isOnwer: isOnwer?"1":"0",
                pick_date: pickDate.toDate(),
                hour: hour,
                minute: minute,
                coin_used: offerUseCoin,
                post_timestamp: new_date.getTime(),
                status: 1,
            }).then(function(docRef) {
                const docId = docRef.id
                const log_date = new Date()
                var fromCoinOffer = ""
                if(useCoinFrom == 1) {
                    fromCoinOffer = "freecoin"

                    updateDoc(doc(db, `/shops`, shopId), {
                        freecoin: (offerFreeCoin*1)-(offerUseCoin*1)
                    })

                    getDoc(doc(db, 'free_coins', 'sum_data')).then((sumDoc) => {
                        updateDoc(doc(db, 'free_coins', 'sum_data'), {
                            remain: (sumDoc.data().remain*1) + (offerUseCoin*1),
                            used: (sumDoc.data().used*1) + (offerUseCoin*1)
                        }).then(() => {
                            addDoc(collection(db, `/coins/used_log/transaction`), {
                                date: log_date,
                                coin: offerUseCoin,
                                event: 'ร้านค้าลงประกาศขายเครื่องใช้ไฟฟ้ามือสอง',
                                post_id: docId,
                                board: 'shop',
                                coin_type: fromCoinOffer,
                                shop_id: shopId
                            })
                            addDoc(collection(db, `/free_coins/logs/transaction`), {
                                date: log_date,
                                coin: offerUseCoin,
                                event: 'ร้านค้าลงประกาศขายเครื่องใช้ไฟฟ้ามือสอง',
                                post_id: docId,
                                board: 'shop',
                                shop_id: shopId,
                                type: 'use'
                            })
                        })
                    })
                } else {
                    fromCoinOffer = "exchange"
                    updateDoc(doc(db, `/shops`, shopId), {
                        exchange_coin: (offerExchangeCoin*1)-(offerUseCoin*1)
                    })
                    getDoc(doc(db, 'exchange_coins', 'sum_data')).then((sumDoc) => {
                        updateDoc(doc(db, 'exchange_coins', 'sum_data'), {
                            remain: (sumDoc.data().remain*1) + (offerUseCoin*1),
                            used: (sumDoc.data().used*1) + (offerUseCoin*1)
                        }).then(() => {
                            addDoc(collection(db, `/coins/used_log/transaction`), {
                                date: log_date,
                                coin: offerUseCoin,
                                event: 'ร้านค้าลงประกาศขายเครื่องใช้ไฟฟ้ามือสอง',
                                post_id: docId,
                                coin_type: fromCoinOffer,
                                board: 'shop',
                                shop_id: shopId
                            })
                            addDoc(collection(db, `/exchange_coins/logs/transaction`), {
                                date: log_date,
                                coin: offerUseCoin,
                                event: 'ร้านค้าลงประกาศขายเครื่องใช้ไฟฟ้ามือสอง',
                                post_id: docId,
                                board: 'shop',
                                shop_id: shopId,
                                type: 'use'
                            })
                        })
                    })
                }

                addDoc(collection(db, `/shops/${shopId}/coin_log`), {
                    date: log_date,
                    coin: offerUseCoin,
                    event: 'ลงประกาศขายเครื่องใช้ไฟฟ้ามือสอง',
                    post_id: docId,
                    coin_type: fromCoinOffer,
                    type: 'use'
                })

                if (picture1 || picture2 || picture3 || picture4 || picture5) {
                    const fileUpload = []
                    if(picture1) fileUpload.push(picture1)
                    if(picture2) fileUpload.push(picture2)
                    if(picture3) fileUpload.push(picture3)
                    if(picture4) fileUpload.push(picture4)
                    if(picture5) fileUpload.push(picture5)

                    const promises = []
                    var i = 1;
                    fileUpload.map((file) => {
                        const storageRef = ref(storage, `/postshops/${docId}/${i}`)
                        const vari = i
                        const uploadTask = uploadBytesResumable(storageRef, file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    addDoc(collection(db, `/postshops/${docId}/images`), {
                                        url: downloadURLs,
                                    })
                                    if(vari == 1) {
                                        updateDoc(doc(db, 'postshops', docId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        i++
                    })

                    Promise.all(promises).then(() => {
                        setShowProgressBar(false)
                        navigate(`/shop/posts/${shopId}`)
                    })
                } else {
                    setShowProgressBar(false)
                    navigate(`/shop/posts/${shopId}`)
                }
                
            }).catch((error) => {
                console.error("Error adding document: ", error);
            })
        }
    }

    const handleOption1Change = (e) => {
        setOption1(!option1)
    }

    const handleOption2Change = (e) => {
        setOption2(!option2)
    }

    const handleOption3Change = (e) => {
        setOption3(!option3)
    }

    const handleOption4Change = (e) => {
        setOption4(!option4)
    }

    const handleDeliveryOption1Change = (e) => {
        setDeliveryOption1(!deliveryOption1)
    }

    const handleDeliveryOption2Change = (e) => {
        setDeliveryOption2(!deliveryOption2)
    }

    const getDocUser = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user.uid));
            const docs = await getDocs(q);
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDicId(docs.docs[0].id)
                setMenberId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.lat) setLat(data.lat)
                if(data.lng) setLng(data.lng)
                if(data.lat) setRegisLat(data.lat)
                if(data.lng) setRegisLng(data.lng)
                setCenter({lat: data.lat, lng: data.lng})
            
                await getDoc(doc(db, `shops`, shopId)).then((docShop) => {
                    const dataShop = docShop.data()
                    if(dataShop.freecoin) setOfferFreeCoin(dataShop.freecoin)
                    if(dataShop.exchange_coin) setOfferExchangeCoin(dataShop.exchange_coin)
                })

                var promisesTask = []

                const districtTask = getDocs(query(collection(db, 'distrincts'), where('province_id', '==', data.province * 1))).then((docs) => {
                    setDistrictList(docs.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })))
                })
                promisesTask.push(districtTask)

                const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', data.district))).then((docs) => {
                    setSubDistrictList(docs.docs.map(doc => ({
                        id: doc.id,
                        data: doc.data()
                    })))
                })
                promisesTask.push(subdistrictTask)

                if(data.address) { setAddress(data.address); setAddressNoRegis(data.address) }
                if(data.village) { setVillage(data.village); setVillageRegis(data.village) }
                if(data.building) { setBuilding(data.building); setBuildingRegis(data.building) }
                if(data.floor) { setFloor(data.floor); setFloorRegis(data.floor) }
                if(data.room) { setRoom(data.room); setRoomRegis(data.room) }
                if(data.subroad) { setSubroad(data.subroad); setSubroadRegis(data.subroad); }
                if(data.road) { setRoad(data.road); setRoadRegis(data.road) }
                setProvinceId(data.province)
                setProvinceIdRegis(data.province)
                setDistrictId(data.district)
                setDistrictIdRegis(data.district)
                setSubDistrictId(data.sub_district)
                setSubDistrictIdRegis(data.sub_district)
                setPostcode(data.postcode)
                setPostcodeRegis(data.postcode)

                Promise.all(promisesTask)
            }
        } catch (err) {
            alert(err.message);
        }
    }

    const getElectricType = () => {
        getDocs(query(collection(db, 'electric_type'))).then((docs) => {
            setElectricType(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getBrand = () => {
        getDocs(query(collection(db, 'brands'))).then((docs) => {
            setBrandList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectBrand = (brandId) => {
        setBrandId(brandId)
        getDocs(query(collection(db, 'models'), where('brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleSelectProvince = (provinceId) => {
        setProvinceId(provinceId)

        setDistrictId(0)
        districtList.splice(0, districtList.length)
        setDistrictList([...districtList])

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceId*1), orderBy('province_id', 'asc'))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectDistrict = (districtId) => {
        setDistrictId(districtId)

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtId), orderBy('district_id', 'asc'))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handelSelectsubDistrict = (subdistrictId) => {
        setSubDistrictId(subdistrictId)
        setPostcode('')
        getDocs(query(collection(db, 'postcodes'), where('subdistrict_id', '==', subdistrictId*1))).then((docs) => {
            setPostcode(docs.docs[0].data().postcode)
        })
    }

    const getProvince = () => {
        getDocs(query(collection(db, 'provinces'), orderBy('province_id', 'asc'))).then((docs) => {
            setProvinceList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getUseRegisAddress = () => {
        var promisesTask = []

        const districtTask = getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceIdRegis * 1))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        promisesTask.push(districtTask)

        const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtIdRegis))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        promisesTask.push(subdistrictTask)
        Promise.all(promisesTask).then(() => {
            setAddressNo(addressNoRegis)
            setVillage(villageRegis)
            setBuilding(buildingRegis)
            setFloor(floorRegis)
            setRoom(roomRegis)
            setSubroad(subroadRegis)
            setRoad(roadRegis)
            setProvinceId(provinceIdRegis)
            setDistrictId(districtIdRegis)
            setSubDistrictId(subdistrictIdRegis)
            setPostcode(postcodeRegis)
        })
    }

    const getCoinRate = () => {
        getDoc(doc(db, 'coins', 'use_rate')).then((exchangeSnapshot) => {
            const useCoin = exchangeSnapshot.data()
            setOfferUseCoin(useCoin.board7_1)
            setGpUseCoin(useCoin.board7_4)
        })
    }

    const handelSelectElectric = (electricTypeId) => {
        setElectricTypeId(electricTypeId)
        getDocs(query(collection(db, 'brands'), where('electric_id', '==', electricTypeId))).then((docs) => {
            setBrandList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else { 
            getDocUser()
            getCoinRate()
        }
        getElectricType()
        getBrand()
        getProvince()
    }, [user, loading])

return (
    <>
        <Loading open={showProgressBar} text={"กำลังทำการลงประกาศ กรุณารอสักครู่"} />
        <div className='container' style={{ backgroundColor: "#fcfcfc", padding: 20 }}>
            <div className="row gy-3">
                <div className="col-xs-12 text-center">
                    <h2><strong>ลงประกาศขายเครื่องใช้ไฟฟ้ามือสอง</strong></h2>
                </div>
            </div>
            <div className="row gy-3" style={{ marginTop: 20 }}>
                <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                <div className="col-12 col-md-6">
                    <div style={{ display: 'flex', flex: 5, flexDirection: "column", alignItems: 'start' }}>
                        <font style={{ fontSize: 17 }}><strong>จำนวนเหรียญที่ต้องใช้ในการประกาศ {number_format.format(offerUseCoin)} เหรียญ</strong></font>
                    {useCoinErrorDiv &&
                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                            <strong>** จำนวนเหรียญที่ต้องใช้ในการประกาศไม่เพียงพอ</strong>
                        </div>
                    }
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>ตัดเหรียญจาก</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>ตัดเหรียญจาก</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5 }}>
                        <select name='useCoinFrom' onChange={(e) => setUseCoinFrom(e.target.value)} style={{ marginLeft: 0, paddingLeft: 5 }}>
                            <option value="1">เหรียญแจกฟรี (คุณมี {number_format.format(offerFreeCoin)} เหรียญ)</option>
                            <option value="2">เหรียญแลกเปลี่ยน (คุณมี {number_format.format(offerExchangeCoin)} เหรียญ)</option>
                        </select>
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>ราคาขาย (บาท) <font className="text-danger">*</font></strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>ราคาขาย (บาท) <font className="text-danger">*</font></strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                        <input type='text' name='price' onChange={(e) => setPrice(e.target.value)} 
                            value={price} placeholder='ราคาขาย (บาท)'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, fontSize: 17 }} />
                        {priceErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                <strong>** กรุณากรอกราคาขาย (เฉพาะตัวเลขเท่านั้น)</strong>
                            </div>
                        }
                    </div>
                </div>

                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>รูปภาพ <font className="text-danger">*</font></strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>รูปภาพ <font className="text-danger">*</font></strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                            <img src={srcPicture1} alt="WEEE" width="80" style={{marginBottom: 5}} />
                            <label htmlFor='fileUpload1' style={{ padding: 0}}>
                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                            </label>
                            <input style={{ display:'none' }} className="form-control file" id="fileUpload1" type="file" accept="image/*" onChange={(e) => handleImage1(e.target.files[0])} />
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                            <img src={srcPicture2} alt="WEEE" width="80" style={{marginBottom: 5}} />
                            <label htmlFor='fileUpload2'>
                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                            </label>
                            <input style={{ display:'none' }} className="form-control file" id="fileUpload2" type="file" accept="image/*" onChange={(e) => handleImage2(e.target.files[0])} />
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                            <img src={srcPicture3} alt="WEEE" width="80"style={{marginBottom: 5}}  />
                            <label htmlFor='fileUpload3'>
                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                            </label>
                            <input style={{ display:'none' }} className="form-control file" id="fileUpload3" type="file" accept="image/*" onChange={(e) => handleImage3(e.target.files[0])} />
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                            <img src={srcPicture4} alt="WEEE" width="80" style={{marginBottom: 5}} />
                            <label htmlFor='fileUpload4'>
                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                            </label>
                            <input style={{ display:'none' }} className="form-control file" id="fileUpload4" type="file" accept="image/*" onChange={(e) => handleImage4(e.target.files[0])} />
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                            <img src={srcPicture5} alt="WEEE" width="80" style={{marginBottom: 5}} />
                            <label htmlFor='fileUpload5'>
                                <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                            </label>
                            <input style={{ display:'none' }} className="form-control file" id="fileUpload5" type="file" accept="image/*" onChange={(e) => handleImage5(e.target.files[0])} />
                        </div>
                    </div>
                    {imageSizeError &&
                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                        </div>
                    }
                    {pictureErrorDiv &&
                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                            <strong>** กรุณาเลือกรูปภาพ</strong>
                        </div>
                    }
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>ประเภท <font className="text-danger">*</font></strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>ประเภท <font className="text-danger">*</font></strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <select name='electric_type' onChange={(e) => { handelSelectElectric(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, marginRight: 5, fontSize: 17 }}>
                                <option value="0">--- กรุณาเลือกประเภท ---</option>
                                {electricType?.map((type, i) => (
                                <option value={type.id} key={type.id}>
                                    {type.data.name}
                                </option>
                                ))}
                                <option value="999">อื่นๆ (โปรดระบุ)</option>
                            </select>
                            {typeIdErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                <strong>** กรุณาเลือกประเภท</strong>
                            </div>
                            }
                        </div>
                        {electricTypeId=='999' &&
                        (<input type='text' name='other_type' onChange={(e) => setOtherType(e.target.value)} 
                            value={otherType} placeholder='ประเภท เช่น ตู้เย็น, โทรทัศน์, พัดลม หรืออื่นๆ'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, fontSize: 17, marginTop: 5 }} />
                        )
                        }
                        {otherTypeErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                <strong>** กรุณากรอกประเภท</strong>
                            </div>
                        }
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>ยี่ห้อ <font className="text-danger">*</font></strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>ยี่ห้อ <font className="text-danger">*</font></strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <select name='brand_id' onChange={(e) => { handleSelectBrand(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, marginRight: 5, fontSize: 17 }}>
                                <option value="0">--- กรุณายี่ห้อ ---</option>
                                {brandList?.map((brand, i) => (
                                <option value={brand.id} key={brand.id}>
                                    {brand.data.name}
                                </option>
                                ))}
                                <option value="999">อื่นๆ (โปรดระบุ)</option>
                            </select>
                            {brandIdErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                <strong>** กรุณาเลือกยี่ห้อ</strong>
                            </div>
                            }
                        </div>
                        {brandId =='999' &&
                        (<input type='text' name='other_type' onChange={(e) => setBrand(e.target.value)} 
                            value={brand} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, fontSize: 17, marginTop: 5 }} />
                        )
                        }
                        {brandErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                <strong>** กรุณากรอกยี่ห้อ</strong>
                            </div>
                        }
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>รุ่น <font className="text-danger">*</font></strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>รุ่น <font className="text-danger">*</font></strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <select name='model_id' onChange={(e) => { setModelId(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, marginRight: 5, fontSize: 17 }}>
                                <option value="0">--- กรุณารุ่น ---</option>
                                {modelList?.map((model, i) => (
                                <option value={model.id} key={model.id}>
                                    {model.data.name}
                                </option>
                                ))}
                                <option value="999">อื่นๆ (โปรดระบุ)</option>
                            </select>
                            {modelIdErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                <strong>** กรุณาเลือกรุ่น</strong>
                            </div>
                            }
                        </div>
                        {modelId =='999' &&
                        (<input type='text' name='other_type' onChange={(e) => setModel(e.target.value)} 
                            value={model} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, fontSize: 17, marginTop: 5 }} />
                        )
                        }
                        {modelErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                <strong>** กรุณากรอกรุ่น</strong>
                            </div>
                        }
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>กว้าง X ยาว X สูง (ซม.)</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>กว้าง X ยาว X สูง (ซม.)</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5 }}>
                        <input type='text' name='width' onChange={(e) => setWidth(e.target.value)} 
                            value={width} placeholder='กว้าง (ซม.)'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: 30, fontSize: 17 }} />
                        <input type='text' name='length' onChange={(e) => setLength(e.target.value)} 
                            value={length} placeholder='ยาว (ซม.)'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: 30, fontSize: 17 }} />
                        <input type='text' name='height' onChange={(e) => setHeight(e.target.value)} 
                            value={height} placeholder='สูง (ซม.)'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: 30, fontSize: 17 }} />
                    </div>
                    {sizeNumErrorDiv &&
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: 5 }}>
                            <label className="col-form-label" style={{ display: 'flex', flex: 2, whiteSpace: 'nowrap' }}> </label>
                            <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 5, display: 'flex'}}>
                                <strong>** กรุณากรอกเฉพาะตัวเลขเท่านั้น</strong>
                            </div>
                        </div>
                    }
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>น้ำหนัก (กก.)</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>น้ำหนัก (กก.)</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5 }}>
                        <input type='text' name='weight' onChange={(e) => setWeight(e.target.value)} 
                            value={weight} placeholder='น้ำหนัก (กก.)'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, fontSize: 17 }} />
                    </div>
                    {weightNumErrorDiv &&
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: 5 }}>
                            <label className="col-form-label" style={{ display: 'flex', flex: 2, whiteSpace: 'nowrap' }}> </label>
                            <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 5, display: 'flex'}}>
                                <strong>** กรุณากรอกเฉพาะตัวเลขเท่านั้น</strong>
                            </div>
                        </div>
                    }
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>จำนวนชิ้น</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>จำนวนชิ้น</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5 }}>
                        <input type='text' name='amount' onChange={(e) => setAmount(e.target.value)} 
                            value={amount} placeholder='จำนวนชิ้น'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, fontSize: 17 }} />
                    </div>
                    {amountNumErrorDiv &&
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: 5 }}>
                            <label className="col-form-label" style={{ display: 'flex', flex: 2, whiteSpace: 'nowrap' }}> </label>
                            <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 5, display: 'flex'}}>
                                <strong>** กรุณากรอกเฉพาะตัวเลขเท่านั้น</strong>
                            </div>
                        </div>
                    }
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                <div className="col-12 col-md-6">
                    <div style={{ display: 'flex', flex: 5, flexDirection: 'column', alignItems: 'start' }}>
                        <font style={{ fontSize: 17 }}><input type="checkbox" checked={deliveryOption1?"checked":""} onChange={(e) => handleDeliveryOption1Change(e.target.value)} /> ให้ผู้ซื้อมารับสินค้าที่ร้าน/บริษัท</font>
                        <font style={{ fontSize: 17 }}><input type="checkbox" checked={deliveryOption2?"checked":""} onChange={(e) => handleDeliveryOption2Change(e.target.value)} /> บริการไปส่งสินค้าที่พัก ระบุราคาขนส่ง</font>
                    </div>
                </div>
                {deliveryOption2 && <>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>ค่าขนส่ง (บาท)</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>ค่าขนส่ง (บาท)</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5 }}>
                        <input type='text' name='deliveryPrice' onChange={(e) => setDeliveryPrice(e.target.value)} 
                            value={deliveryPrice} placeholder='ค่าขนส่ง (บาท)'
                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, fontSize: 17 }} />
                    </div>
                </div></>
                }
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>ปีที่ซื้อ (พ.ศ.)</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>ปีที่ซื้อ (พ.ศ.)</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                        <input type='text' name='buyYear' onChange={(e) => setBuyYear(e.target.value)} 
                            value={buyYear} placeholder='ปีที่ซี่ื้อ (พ.ศ.)'
                            style={{ paddingLeft: 8, marginRight: 5, marginBottom: 5, display: 'flex', flex: 1, fontSize: 17 }} />
                        <strong className='text-danger'>** ควรระบุเพื่อความน่าเชื่อถือและราคา</strong>
                    </div>
                    {buyYearErrorDiv &&
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', marginTop: 5 }}>
                            <label className="col-form-label" style={{ display: 'flex', flex: 2, whiteSpace: 'nowrap' }}> </label>
                            <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 5, display: 'flex'}}>
                            <strong>** กรุณากรอกปี (พ.ศ.) ที่ซื้อ ความยาวเท่่ากับ 4 ตัวอักษร และเป็นตัวเลขเท่านั้น</strong>
                            </div>
                        </div>
                    }
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>สถาพเครื่อง</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>สถาพเครื่อง</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5 }}>
                        <select name='condition' onChange={(e) => setCondition(e.target.value)} style={{ marginLeft: 0, paddingLeft: 5 }}>
                            <option value="1">มือสองเหมือนใหม่</option>
                            <option value="2">มือสองสภาพดี</option>
                            <option value="3">มือสองพอใช้</option>
                            <option value="4">มือสองเก่ามาก</option>
                        </select>
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                <div className="col-12 col-md-6">
                    <div style={{ display: 'flex', flex: 5, flexDirection: 'column', alignItems: 'start' }}>
                        <font style={{ fontSize: 17 }}><input type="checkbox" checked={option1?"checked":""} onChange={(e) => handleOption1Change(e.target.value)} /> อยู่ในระยะประกัน</font>
                        <font style={{ fontSize: 17 }}><input type="checkbox" checked={option2?"checked":""} onChange={(e) => handleOption2Change(e.target.value)} /> มีกล่อง</font>
                        <font style={{ fontSize: 17 }}><input type="checkbox" checked={option3?"checked":""} onChange={(e) => handleOption3Change(e.target.value)} /> มีคู่มือการใช้งาน</font>
                        <font style={{ fontSize: 17 }}><input type="checkbox" checked={option4?"checked":""} onChange={(e) => handleOption4Change(e.target.value)} /> เอกสารอื่นๆ</font>
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>รายละเอียดเพิ่มเติม</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>รายละเอียดเพิ่มเติม</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5, width:"100%" }}>
                        <textarea style={{ display: 'flex', flex: 1, width:"100%", height: 100, fontSize: 17, paddingLeft: 10 }}
                            onChange={(e) => setDescription(e.target.value)}
                            placeholder='รายละเอียดเพิ่มเติม' 
                            value={description}></textarea>
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>วันเวลาที่สะดวก</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>วันเวลาที่สะดวก</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker slotProps={{ textField: { size: 'small' } }} format="DD/MM/YYYY" minDate={today} defaultValue={today} onChange={(value) => { setPickDate(value)} } />
                        </LocalizationProvider>
                        <select name='hour' onChange={(e) => setHour(e.target.value)} style={{ width: 70, marginLeft: 10 }}>
                            <option value="00">00</option>
                            <option value="01">01</option>
                            <option value="02">02</option>
                            <option value="03">03</option>
                            <option value="04">04</option>
                            <option value="05">05</option>
                            <option value="06">06</option>
                            <option value="07">07</option>
                            <option value="08">08</option>
                            <option value="09">09</option>
                            <option value="10">10</option>
                            <option value="11">11</option>
                            <option value="12">12</option>
                            <option value="13">13</option>
                            <option value="14">14</option>
                            <option value="15">15</option>
                            <option value="16">16</option>
                            <option value="17">17</option>
                            <option value="18">18</option>
                            <option value="19">19</option>
                            <option value="20">20</option>
                            <option value="21">21</option>
                            <option value="22">22</option>
                            <option value="23">23</option>
                        </select>
                        <select name='minute' onChange={(e) => setMinute(e.target.value)} style={{ width: 70, marginLeft: 5 }}>
                            <option value="00">00</option>
                            <option value="15">15</option>
                            <option value="30">30</option>
                            <option value="45">45</option>
                        </select>
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>ตำแหน่งพิกัดร้านค้า</strong></font> 
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                        <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToRegisPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งเริ่มต้น</button>
                        <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToCurrentPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งปัจจุบัน</button>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>ตำแหน่งพิกัดร้านค้า</strong></font>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                            <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToRegisPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งเริ่มต้น</button>
                            <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToCurrentPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งปัจจุบัน</button>
                        </div>
                    </div>
                    <div style={{ marginBottom: 1, flexDirection: 'column', display: 'flex', flexWrap: 'nowrap', height:400, flex: 5 }}>
                        <div className='text-danger' style={{ marginBottom: 10, marginTop: 8, display: 'flex', flexWrap: 'nowrap' }}>
                            ** ลาก <FaMapMarkerAlt className='text-danger' style={{ marginLeft: 5, marginRight: 5 }} /> Marker  เพื่อกำหนดตำแหน่ง
                        </div>
                        <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                        {!isLoaded ? ( <h1>Loading...</h1> ) : (
                        <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                            <MarkerF
                                draggable={true} position={center}
                                onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                            />
                        </GoogleMap>
                        )}
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>ที่อยู่</strong></font>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                        <button style={{ textAlign: 'center' }} className="btn btn-success" type='button' onClick={() => { getUseRegisAddress() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ที่อยู่เริ่มต้น</button>
                    </div>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>ที่อยู่</strong></font>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                            <button style={{ textAlign: 'center' }} className="btn btn-success" type='button' onClick={() => { getUseRegisAddress() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ที่อยู่เริ่มต้น</button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection:'column', flex: 5 }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', flexWrap: 'wrap' }}>
                            <div style={{ display: 'flex', flexDirection:'column'}}>
                            <input type='text' name='addressNo' onChange={(e) => setAddressNo(e.target.value)} 
                                value={addressNo} placeholder='บ้านเลขที่'
                                style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection:'column'}}>
                            <input type='text' name='village' onChange={(e) => setVillage(e.target.value)} 
                                value={village} placeholder='หมู่บ้าน/สถานที่'
                                style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection:'column'}}>
                            <input type='text' name='room' onChange={(e) => setRoom(e.target.value)} 
                                value={room} placeholder='ห้อง'
                                style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection:'column'}}>
                            <input type='text' name='floor' onChange={(e) => setFloor(e.target.value)} 
                                value={floor} placeholder='ชั้น'
                                style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection:'column'}}>
                            <input type='text' name='building' onChange={(e) => setBuilding(e.target.value)} 
                                value={building} placeholder='อาคาร'
                                style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection:'column'}}>
                            <input type='text' name='subroad' onChange={(e) => setSubroad(e.target.value)} 
                                value={subroad} placeholder='ซอย'
                                style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection:'column'}}>
                            <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                value={road} placeholder='ถนน'
                                style={{ paddingLeft: 8, fontSize:17, marginTop: 7 }} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection:'column', marginTop: 7 }}>
                            <select name='province' value={provinceId} onChange={(e) => { handleSelectProvince(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17, flex: 1 }}>
                                <option value="0">--- กรุณาเลือกจังหวัด ---</option>
                                {provinceList?.map((province, i) => (
                                <option value={province.data.province_id} key={province.data.province_id}>
                                    {province.data.province_name}
                                </option>
                                ))}
                            </select>
                            {provinceIdErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                <strong>** กรุณาเลือกจังหวัด</strong>
                            </div>
                            }
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                            <select name='district' value={districtId} onChange={(e) => { handleSelectDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17, flex: 1 }}>
                                <option value="0" key="0">--- กรุณาเลือกอำเภอ ---</option>
                                {districtList?.map((district, i) => (
                                <option value={district.data.district_id} key={district.data.district_id}>
                                    {district.data.district_name}
                                </option>
                                ))}
                            </select>
                            {districtIdErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                <strong>** กรุณาเลือกอำเภอ</strong>
                            </div>
                            }
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                            <select name='subdistrict' value={subdistrictId} onChange={(e) => { handelSelectsubDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17, flex: 1 }}>
                                <option value="0">--- กรุณาเลือกตำบล ---</option>
                                {subdistrictList?.map((subdistrict, i) => (
                                <option value={subdistrict.data.subdistrict_id} key={subdistrict.data.subdistrict_id}>
                                    {subdistrict.data.subdistrict_name}
                                </option>
                                ))}
                            </select>
                            {subdistrictIdErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                <strong>** กรุณาเลือกตำบล</strong>
                            </div>
                            }
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                            <div style={{ display: 'flex', flexDirection:'column'}}>
                            <input type='text' name='postcode' readOnly
                                value={postcode} placeholder='รหัสไปรษณีย์'
                                style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize: 17 }} />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">
                    <font style={{ fontSize: 17 }}><strong>ที่อยู่เพิ่มเติม</strong></font>
                </div>
                <div className="col-12 col-md-6">
                    <div className="d-block d-sm-none">
                        <font style={{ fontSize: 17 }}><strong>ที่อยู่เพิ่มเติม</strong></font>
                    </div>
                    <div style={{ display: 'flex', flex: 5 }}>
                        <textarea style={{ display: 'flex', flex: 1, width:"100%", height: 100, fontSize: 17, paddingLeft: 10 }}
                            onChange={(e) => setAddress(e.target.value)}
                            placeholder='ที่อยู่เพิ่มเติม' 
                            value={address}></textarea>
                    </div>
                </div>
                <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                <div className="col-12 col-md-6">
                    <div style={{ marginBottom: 1, flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', flex: 5 }}>
                        <button style={{ textAlign: 'center' }} className="btn btn-primary" type='button' onClick={handleSubmit}>บันทึก</button>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}

export default AddSellGabagePost;