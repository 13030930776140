import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 350,
    bgcolor: 'background.paper',
    border: '2px solid #000',
    boxShadow: 24,
    p: 4,
}

export function Loading({ text, open }) {
    return (
        <Modal open={open} onClose={() => {}} aria-labelledby="modal-modal-title">
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    <font style={{ fontFamily: 'Prompt' }}>{text}</font>
                </Typography>
                <LinearProgress style={{ marginTop: 20}} />
            </Box>
        </Modal>
    )
}