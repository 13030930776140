import React, { useState, useEffect } from 'react'
import { collection, query, orderBy, onSnapshot, doc, getDoc, getDocs, or, updateDoc, setDoc } from "firebase/firestore"
import { db } from "../../assets/js/firebase"
import { useNavigate, useParams } from "react-router-dom"
import "../../assets/css/memberList.css";
import 'react-confirm-alert/src/react-confirm-alert.css'
import { Typography, Paper, TextField } from '@mui/material'
import { CiTextAlignCenter, CiEdit } from "react-icons/ci"
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material'

import { Editor } from "react-draft-wysiwyg"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css"
import { EditorState, ContentState, convertToRaw, convertFromHTML } from 'draft-js';
import { convertToHTML } from 'draft-convert'
import DOMPurify from 'dompurify'
import './Texts.css'

const Texts = () => {
    const navigate = useNavigate();
    const params = useParams()
    const number_format = new Intl.NumberFormat()

    const [openText1, setOpenText1] = useState(false)
    const [convertedContent1, setConvertedContent1] = useState('')
    const [editor1State, setEditor1State] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(convertedContent1)
        )
    ))

    const [openText2, setOpenText2] = useState(false)
    const [convertedContent2, setConvertedContent2] = useState('')
    const [editor2State, setEditor2State] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(convertedContent2)
        )
    ))

    const [openText3, setOpenText3] = useState(false)
    const [convertedContent3, setConvertedContent3] = useState('')
    const [editor3State, setEditor3State] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(convertedContent3)
        )
    ))

    const [openText4, setOpenText4] = useState(false)
    const [convertedContent4, setConvertedContent4] = useState('')
    const [editor4State, setEditor4State] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(convertedContent4)
        )
    ))

    const [openText5, setOpenText5] = useState(false)
    const [convertedContent5, setConvertedContent5] = useState('')
    const [editor5State, setEditor5State] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(convertedContent5)
        )
    ))

    const [openText6, setOpenText6] = useState(false)
    const [convertedContent6, setConvertedContent6] = useState('')
    const [editor6State, setEditor6State] = useState(() => EditorState.createWithContent(
        ContentState.createFromBlockArray(
          convertFromHTML(convertedContent6)
        )
    ))

    const handleText1 = () => {
        setDoc(doc(db, 'texts', 'text1_html'), {
            html: convertedContent1
        }).then(() => {
            setOpenText1(false)
            navigate(0)
        })
    }

    const handleText2 = () => {
        setDoc(doc(db, 'texts', 'text2_html'), {
            html: convertedContent2
        }).then(() => {
            setOpenText2(false)
            navigate(0)
        })
    }

    const handleText3 = () => {
        setDoc(doc(db, 'texts', 'text3_html'), {
            html: convertedContent3
        }).then(() => {
            setOpenText3(false)
            navigate(0)
        })
    }

    const handleText4 = () => {
        setDoc(doc(db, 'texts', 'text4_html'), {
            html: convertedContent4
        }).then(() => {
            setOpenText4(false)
            navigate(0)
        })
    }

    const handleText5 = () => {
        setDoc(doc(db, 'texts', 'text5_html'), {
            html: convertedContent5
        }).then(() => {
            setOpenText5(false)
            navigate(0)
        })
    }

    const handleText6 = () => {
        setDoc(doc(db, 'texts', 'text6_html'), {
            html: convertedContent6
        }).then(() => {
            setOpenText6(false)
            navigate(0)
        })
    }

    const handleCloseText1 = () => {
        setOpenText1(false)
    }

    const handleCloseText2 = () => {
        setOpenText2(false)
    }

    const handleCloseText3 = () => {
        setOpenText3(false)
    }

    const handleCloseText4 = () => {
        setOpenText4(false)
    }

    const handleCloseText5 = () => {
        setOpenText5(false)
    }

    const handleCloseText6 = () => {
        setOpenText5(false)
    }

    useEffect(() => {
        let html = convertToHTML(editor1State.getCurrentContent())
        setConvertedContent1(html)
    }, [editor1State])

    useEffect(() => {
        let html = convertToHTML(editor2State.getCurrentContent())
        setConvertedContent2(html)
    }, [editor2State])

    useEffect(() => {
        let html = convertToHTML(editor3State.getCurrentContent())
        setConvertedContent3(html)
    }, [editor3State])

    useEffect(() => {
        let html = convertToHTML(editor4State.getCurrentContent())
        setConvertedContent4(html)
    }, [editor4State])

    useEffect(() => {
        let html = convertToHTML(editor5State.getCurrentContent())
        setConvertedContent5(html)
    }, [editor5State])
    useEffect(() => {
        let html = convertToHTML(editor6State.getCurrentContent())
        setConvertedContent6(html)
    }, [editor6State])


    useEffect(() => {
        getDoc(doc(db, 'texts', 'text1_html')).then((textHtml) => {
            if(textHtml.exists()) {
                setEditor1State(() => EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(textHtml.data().html)
                    )
                ))
                setConvertedContent1(textHtml.data().html)
            }
        })

        getDoc(doc(db, 'texts', 'text2_html')).then((textHtml) => {
            if(textHtml.exists()) {
                setEditor2State(() => EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(textHtml.data().html)
                    )
                ))
                setConvertedContent2(textHtml.data().html)
            }
        })

        getDoc(doc(db, 'texts', 'text3_html')).then((textHtml) => {
            if(textHtml.exists()) {
                setEditor3State(() => EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(textHtml.data().html)
                    )
                ))
                setConvertedContent3(textHtml.data().html)
            }
        })

        getDoc(doc(db, 'texts', 'text4_html')).then((textHtml) => {
            if(textHtml.exists()) {
                setEditor4State(() => EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(textHtml.data().html)
                    )
                ))
                setConvertedContent4(textHtml.data().html)
            }
        })

        getDoc(doc(db, 'texts', 'text5_html')).then((textHtml) => {
            if(textHtml.exists()) {
                setEditor5State(() => EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(textHtml.data().html)
                    )
                ))
                setConvertedContent5(textHtml.data().html)
            }
        })

        getDoc(doc(db, 'texts', 'text6_html')).then((textHtml) => {
            if(textHtml.exists()) {
                setEditor6State(() => EditorState.createWithContent(
                    ContentState.createFromBlockArray(
                      convertFromHTML(textHtml.data().html)
                    )
                ))
                setConvertedContent6(textHtml.data().html)
            }
        })
    }, [])

    function createMarkup(html) {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><CiTextAlignCenter style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>การจัดการข้อความในระบบ</b></h4>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                <Paper elevation={2} style={{ display: 'flex', flex: 2, minHeight: 250, marginRight: 10, flexDirection: 'column' }}>
                    <div className='bg-primary' style={{ height: 40, backgroundColor: "#dddddd", paddingTop: 8, paddingLeft: 10}}>
                        <h5 className='text-white'><strong><CiTextAlignCenter style={{ marginRight: 8 }} />รายละเอียดการให้บริการ (หน้าแรก)</strong></h5>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
                        <Typography variant="h7" component="div" className='text-black' style={{ fontFamily: 'Prompt', flex: 1 }}>
                            ซื้อขายเครื่องใช้ไฟฟ้ามือสอง
                        </Typography>
                        <div style={{ marginLeft: 5 }}>
                            <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenText1(true) }}>
                                <CiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
                        <Typography variant="h7" component="div" className='text-black' style={{ fontFamily: 'Prompt', flex: 1 }}>
                            ทิ้ง/ซื้อขายซากเครื่องใช้ไฟฟ้า
                        </Typography>
                        <div style={{ marginLeft: 5 }}>
                            <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenText2(true) }}>
                                <CiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
                        <Typography variant="h7" component="div" className='text-black' style={{ fontFamily: 'Prompt', flex: 1 }}>
                            แจ้งความต้องการซ่อมเครื่องใช้ไฟฟ้า
                        </Typography>
                        <div style={{ marginLeft: 5 }}>
                            <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenText3(true) }}>
                                <CiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
                        <Typography variant="h7" component="div" className='text-black' style={{ fontFamily: 'Prompt', flex: 1 }}>
                            แจ้งความต้องการล้างแอร์/เครื่องซักผ้า
                        </Typography>
                        <div style={{ marginLeft: 5 }}>
                            <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenText4(true) }}>
                                <CiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข
                            </button>
                        </div>
                    </div>
                </Paper>
                <Paper elevation={2} style={{ display: 'flex', flex: 2, minHeight: 250, marginRight: 10, flexDirection: 'column' }}>
                    <div className='bg-success' style={{ backgroundColor: "#dddddd", paddingTop: 8, paddingLeft: 10, paddingRight: 10}}>
                        <h5 className='text-white'><strong><CiTextAlignCenter style={{ marginRight: 8 }} />ข้อกำหนดและเงื่อนไขการใช้งาน / นโยบายความเป็นส่วนตัว</strong></h5>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
                        <Typography variant="h7" component="div" className='text-black' style={{ fontFamily: 'Prompt', flex: 1 }}>
                            ข้อกำหนดและเงื่อนไขการใช้งาน
                        </Typography>
                        <div style={{ alignSelf: 'flex-end' }}>
                            <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenText5(true) }}>
                                <CiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', margin: 10 }}>
                        <Typography variant="h7" component="div" className='text-black' style={{ fontFamily: 'Prompt', flex: 1 }}>
                            นโยบายความเป็นส่วนตัว
                        </Typography>
                        <div style={{ alignSelf: 'flex-end' }}>
                            <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenText6(true) }}>
                                <CiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข
                            </button>
                        </div>
                    </div>
                </Paper>
            </div>

            <Dialog open={openText1} onClose={handleCloseText1} maxWidth="lg" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <CiTextAlignCenter style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>รายละเอียดการให้บริการซื้อขายเครื่องใช้ไฟฟ้ามือสอง</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                        <h4><strong>ข้อความรายละเอียดการให้บริการซื้อขายเครื่องใช้ไฟฟ้ามือสอง</strong></h4>
                    </div>
                    <Editor
                        editorState={editor1State}
                        onEditorStateChange={setEditor1State}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link'],
                            blockType: {
                                inDropdown: false,
                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                            },
                        }}
                    />
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                        <h4><strong>Preview</strong></h4>
                    </div>
                    <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(convertedContent1)}>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseText1} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleText1} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openText2} onClose={handleCloseText2} maxWidth="lg" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <CiTextAlignCenter style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>รายละเอียดการให้บริการทิ้ง/ซื้อขายซากเครื่องใช้ไฟฟ้า</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                        <h4><strong>ข้อความรายละเอียดการให้บริการทิ้ง/ซื้อขายซากเครื่องใช้ไฟฟ้า</strong></h4>
                    </div>
                    <Editor
                        editorState={editor2State}
                        onEditorStateChange={setEditor2State}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link'],
                            blockType: {
                                inDropdown: false,
                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                            },
                        }}
                    />
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                        <h4><strong>Preview</strong></h4>
                    </div>
                    <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(convertedContent2)}>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseText2} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleText2} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openText3} onClose={handleCloseText3} maxWidth="lg" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <CiTextAlignCenter style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>รายละเอียดการให้บริการแจ้งความต้องการซ่อมเครื่องใช้ไฟฟ้า</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                        <h4><strong>ข้อความรายละเอียดการให้บริการแจ้งความต้องการซ่อมเครื่องใช้ไฟฟ้า</strong></h4>
                    </div>
                    <Editor
                        editorState={editor3State}
                        onEditorStateChange={setEditor3State}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link'],
                            blockType: {
                                inDropdown: false,
                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                            },
                        }}
                    />
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                        <h4><strong>Preview</strong></h4>
                    </div>
                    <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(convertedContent3)}>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseText3} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleText3} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openText4} onClose={handleCloseText4} maxWidth="lg" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <CiTextAlignCenter style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>รายละเอียดการให้บริการแจ้งความต้องการล้างแอร์/เครื่องซักผ้า</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                        <h4><strong>ข้อความรายละเอียดการให้บริการแจ้งความต้องการล้างแอร์/เครื่องซักผ้า</strong></h4>
                    </div>
                    <Editor
                        editorState={editor4State}
                        onEditorStateChange={setEditor4State}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link'],
                            blockType: {
                                inDropdown: false,
                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                            },
                        }}
                    />
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                        <h4><strong>Preview</strong></h4>
                    </div>
                    <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(convertedContent4)}>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseText4} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleText4} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openText5} onClose={handleCloseText5} maxWidth="lg" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <CiTextAlignCenter style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อกำหนดและเงื่อนไขการใช้งาน</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                        <h4><strong>ข้อความข้อกำหนดและเงื่อนไขการใช้งาน</strong></h4>
                    </div>
                    <Editor
                        editorState={editor5State}
                        onEditorStateChange={setEditor5State}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link'],
                            blockType: {
                                inDropdown: false,
                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                            },
                        }}
                    />
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                        <h4><strong>Preview</strong></h4>
                    </div>
                    <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(convertedContent5)}>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseText5} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleText5} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openText6} onClose={handleCloseText6} maxWidth="lg" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <CiTextAlignCenter style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>นโยบายความเป็นส่วนตัว</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex', flexDirection: 'column' }}>
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                        <h4><strong>ข้อความนโยบายความเป็นส่วนตัว</strong></h4>
                    </div>
                    <Editor
                        editorState={editor6State}
                        onEditorStateChange={setEditor6State}
                        wrapperClassName="wrapper-class"
                        editorClassName="editor-class"
                        toolbarClassName="toolbar-class"
                        toolbar={{
                            options: ['inline', 'blockType', 'list', 'textAlign', 'colorPicker', 'link'],
                            blockType: {
                                inDropdown: false,
                                options: ['Normal', 'H1', 'H2', 'H3', 'H4', 'H5', 'H6']
                            },
                            list: {
                                inDropdown: false,
                                options: ['unordered', 'ordered'],
                            },
                        }}
                    />
                    <div style={{ display:'flex', flexDirection: 'column', flex: 1, marginTop: 10 }}>
                        <h4><strong>Preview</strong></h4>
                    </div>
                    <div
                        className="preview"
                        dangerouslySetInnerHTML={createMarkup(convertedContent6)}>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseText6} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleText6} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Texts