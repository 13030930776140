import React, { useState, useEffect } from 'react'
import { collection, query, orderBy, doc, getDoc, getDocs, where, and, or, Timestamp, onSnapshot } from "firebase/firestore"
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { HiOutlineMegaphone } from "react-icons/hi2"
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import Pagination from '@mui/material/Pagination';
import dayjs from 'dayjs'

import PostCard from '../layouts/PostCardBoard4'
import { getBrand, getModel } from '../helper/BrandHelper'
import { getBoartStatus } from '../helper/PostStatusHelper'
import { getBroken } from '../helper/BrokenHelper'

const PostList = () => {
    const today = dayjs()
    const [user, loading, error] = useAuthState(auth)
    const number_format = new Intl.NumberFormat()

    const onBoard = 4
    const [posts, setPosts] = useState([])
    const [postsPage, setPostsPage] = useState([])
    const [searchDate, setSearchDate] = useState(today)

    const [postKeys, setPostKeys] = useState([])
    const [page, setPage] = useState(1)

    const handlePage = (event, value) => {
        const postSlide = posts.slice(((value-1)*9), (((value-1)*9)+9))
        setPostsPage(postSlide)
        setPage(value)
    }

    const [status, setStatus] = React.useState({
        status1: false,
        status2: false,
        status3: false,
        status4: false,
        status5: false,
        status6: false,
        status9: false,
        statusAll: true
    })

    const handleChangeStatus = (event) => {
        setStatus({
          ...status,
          [event.target.name]: event.target.checked,
        })
    }

    const fetchPosts = () => {
        try {
            posts.splice(0, posts.length)
            setPosts([...posts])
            handleSearch()
        } catch (err) {
            alert(err.message)
        }
    }

    const handleSearch = async () => {
        setPage(1)
        const constraints = []
        const date = Timestamp.fromDate(searchDate.toDate()).toMillis()
        if(status.status1) { constraints.push(where('status', '==', 1)) }
        if(status.status2) { constraints.push(where('status', '==', 2)) }
        if(status.status3) { constraints.push(where('status', '==', 3)) }
        if(status.status4) { constraints.push(where('status', '==', 4)) }
        if(status.status5) { constraints.push(where('status', '==', 5)) }
        if(status.status6) { constraints.push(where('status', '==', 6)) }
        if(status.status9) { constraints.push(where('status', '==', 9)) }
        
        if(constraints.length > 0) {
            var queryPost = query(collection(db, 'posts'), 
            and(
                and(
                    where('onboard', '==', onBoard), 
                    where('post_timestamp', '<=', date)), 
                or(...constraints)
            ), orderBy('post_timestamp', 'desc'))
        } else {
            var queryPost = query(collection(db, 'posts'), where('onboard', '==', onBoard), where('post_timestamp', '<=', date), orderBy('post_timestamp', 'desc'))
        }

        onSnapshot(queryPost, (postDocs) => {
            posts.splice(0, posts.length)
            setPosts([...posts])
            postsPage.splice(0, postsPage.length)
            setPostsPage([...postsPage])
            postKeys.splice(0, postKeys.length)
            setPostKeys([...postKeys])
            postDocs.forEach((docPost) => {
                var brand = 'ไม่ระบุ'
                var model = 'ไม่ระบุ'
                var broken = 'ไม่ระบุ'
                var promisesTask = []
                var getBrandTask = getBrand(db, docPost.data().brand_id, docPost.data().brand).then((brandName) => {
                    brand = brandName
                })
                promisesTask.push(getBrandTask)

                var getModelTask = getModel(db, docPost.data().model_id, docPost.data().model).then((modelName) => {
                    model = modelName
                })
                promisesTask.push(getModelTask)

                var getBrokenTask = getBroken(db, docPost.data().broken_id, docPost.data().other_broken).then((brokenName) => {
                    broken = brokenName
                })
                promisesTask.push(getBrokenTask)
                
                getDocs(query(collection(db, `posts/${docPost.id}/offers`), where('status', '==', 1), orderBy('offer_timestamp', 'desc'))).then((offerQuery) => {
                    var offersList = []
                    offerQuery.forEach((offer) => {
                        offersList.push({ id: offer.id, data: offer.data()})
                    })
                    Promise.all(promisesTask).then( async () => {
                        if(postKeys.lastIndexOf(docPost.id) < 0) {
                            postKeys.push(docPost.id)

                            var posterDocs = await getDoc(doc(db, `users`, docPost.data().poster_id))
                            posts.push({
                                id: docPost.id,
                                data: docPost.data(),
                                offer: offersList,
                                brand: brand,
                                model: model,
                                broken: broken,
                                poster: posterDocs
                            })
                            setPosts([...posts], {
                                id: docPost.id,
                                data: docPost.data(),
                                offer: offersList,
                                brand: brand,
                                model: model,
                                broken: broken,
                                poster: posterDocs
                            })
                            const postSlide = posts.slice(0, 9)
                            setPostsPage(postSlide)
                        }
                    })
                })
            })
        })
    }

    const handleSearchDate = (value) => {
        setSearchDate(value)
    }

    useEffect(() => {
        posts.splice(0, posts.length)
        setPosts([...posts])
        fetchPosts()
    }, [user, loading])

    useEffect(() => {
        handleSearch()
    }, [status, searchDate])


    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1 }}><HiOutlineMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายการประกาศแจ้งซ่อมเครื่องใช้ไฟฟ้าทั้งหมด</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', flex: 1}}>
                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: 220, marginRight: 10 }}>
                    <div><h5><strong>ค้นหา</strong></h5></div>
                    <div>
                        <h6><strong>สถานะของประกาศ</strong></h6>
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <FormControlLabel
                        control={<Switch checked={status.status1} onChange={handleChangeStatus} name="status1" />}
                        style={{ marginRight: 0 }}
                        />{getBoartStatus(onBoard, 1, 'admin')}
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <FormControlLabel
                        control={<Switch checked={status.status2} onChange={handleChangeStatus} name="status2" />}
                        style={{ marginRight: 0 }}
                        />{getBoartStatus(onBoard, 2, 'admin')}
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <FormControlLabel
                        control={<Switch checked={status.status3} onChange={handleChangeStatus} name="status3" />}
                        style={{ marginRight: 0 }}
                        />{getBoartStatus(onBoard, 3, 'admin')}
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <FormControlLabel
                        control={<Switch checked={status.status4} onChange={handleChangeStatus} name="status4" />}
                        style={{ marginRight: 0 }}
                        />{getBoartStatus(onBoard, 4, 'admin')}
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <FormControlLabel
                        control={<Switch checked={status.status5} onChange={handleChangeStatus} name="status5" />}
                        style={{ marginRight: 0 }}
                        />{getBoartStatus(onBoard, 5, 'admin')}
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <FormControlLabel
                        control={<Switch checked={status.status6} onChange={handleChangeStatus} name="status6" />}
                        style={{ marginRight: 0 }}
                        />{getBoartStatus(onBoard, 6, 'admin')}
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <FormControlLabel
                        control={<Switch checked={status.status9} onChange={handleChangeStatus} name="status9" />}
                        style={{ marginRight: 0 }}
                        />{getBoartStatus(onBoard, 9, 'admin')}
                    </div>
                    <div style={{ marginTop: 10 }}>
                        <h6><strong>ประกาศก่อนวันที่</strong></h6>
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DatePicker format="DD/MM/YYYY" maxDate={today} defaultValue={today} onChange={(value) => handleSearchDate(value)} />
                        </LocalizationProvider>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', flex: 1, borderLeft: '1px solid #cccccc'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 5, paddingBottom: 10, alignContent: 'center' }}>
                        <div style={{ marginLeft: 15, paddingTop: 5, marginRight: 10, display: 'flex', flex: 1 }}><strong>ประกาศที่ {(((page-1)*9)+1)} - {(page*9 > posts.length)?posts.length:page*9} จากทั้งหมด {number_format.format(posts.length)} ประกาศ</strong></div>
                        <Pagination style={{ fontFamily: 'Prompt', marginRight: 10 }} count={Math.ceil(posts.length/9)} page={page} onChange={handlePage} color="primary" showFirstButton showLastButton />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {postsPage?.map((post, i) => (
                        <PostCard style={{ display: 'flex' }} post={post} key={i} />                  
                    ))}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default PostList;