import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, getDoc, addDoc, updateDoc, doc } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'

import { calScoreUser } from '../../../assets/js/ScoreHelper'
import { Loading } from "../layouts/Loading"
import { getBrand, getModel } from '../helper/BrandHelper'
import { getElectric } from '../helper/ElectricTypeHelper'
import { addUserNotification, addShopNotification } from "../../../assets/js/NotificationHelper"
import { addShopCoinLog, addUserCoinLog, addPlatformCoinLog, addExchangeLog } from "../../../assets/js/LogHelper"

export function AppointmentShopBoard2({appointment, offerExchangeCoin}) {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(false)

    const [openPayCoin, setOpenPayCoin] = useState(false)
    const [openCanclePay, setOpenCanclePay] = useState(false)
    const [openFinish, setOpenFinish] = useState(false)

    const [payCoin, setPayCoin] = useState(0)
    const [coinEnough, setCoinEnough] = useState(false)
    const [coinMessage, setCoinMessage] = useState('')

    const [gpRate, setGpRate] = useState(0)
    const [gpCoin, setGpCoin] = useState(0)
    const [exchangeRate, setExchangeRate] = useState(0)

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState('')

    const [type, setType] = useState('ไม่ระบุ')
    const [brand, setBrand] = useState('ไม่ระบุ')
    const [model, setModel] = useState('ไม่ระบุ')

    const userId = appointment.data.user_id
    const shopId = appointment.data.shop_id
    const postId = appointment.data.post_id
    const shopOfferId = appointment.data.shop_offer_id
    const postOfferId = appointment.data.offer_id

    var badge = { message: '', class: 'success', color: '#000000', show: false }
    if(appointment.data.status == 1) {
        badge = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
    } else if(appointment.data.status == 2) {
        badge = { message: 'ร้านค้าจ่ายเหรียญซื้อซาก', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data.status == 3) {
        badge = { message: 'ผู้ประกาศยืนยันขายซาก', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data.status == 4) {
        badge = { message: 'ร้านค้ายกเลิกซื้อซาก', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data.status == 5) {
        badge = { message: 'ผู้ประกาศยกเลิกขายซาก', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data.status == 6) {
        badge = { message: 'ร้านค้ายืนยันได้รับซาก', class: 'success', color: '#ffffff', show: true }
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const getPayRate = () => {
        var price = 0
        if(appointment.offer) {
            price = appointment.offer.price * 1
        }
        getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeRate) => {
            const rate = exchangeRate.data().money_to_coin_1 * 1
            const payCoin = Math.ceil(price/rate)
            setExchangeRate(rate)
            setPayCoin(payCoin)
        }).then(() => {
            getDoc(doc(db, 'coins', 'use_rate')).then((exchangeSnapshot) => {
                const useCoin = exchangeSnapshot.data()
                setGpRate(useCoin.board2_3 * 1)
                setGpCoin(useCoin.board2_3 * 1)
            })
        })
    }

    const handleOpenPay = () => {
        const price = appointment.offer.price * 1
        if(payCoin < offerExchangeCoin) { 
            setCoinEnough(true)
            setCoinMessage('คุณมีเหรียญทองจำนวน '+number_format.format(offerExchangeCoin)+' เหรียญ คุณจะใช้ '+number_format.format(payCoin)+' เหรียญ (ราคาสินค้า '+number_format.format(price)+' บาท อัตรา '+number_format.format(exchangeRate)+' บาท ต่อ 1 เหรียญทอง) เพื่อซื้อซาก?') 
        } else  { 
            setCoinEnough(false) 
            setCoinMessage('คุณมีจำนวนเหรียญทองไม่พอ คุณมี '+number_format.format(offerExchangeCoin)+' เหรียญ คุณต้องใช้ '+number_format.format(payCoin)+' เหรียญ (ราคาสินค้า '+number_format.format(price)+' บาท อัตรา '+number_format.format(exchangeRate)+' บาท ต่อ 1 เหรียญทอง) เพื่อซื้อซาก')
        }
        setOpenPayCoin(true)
    }
    
    const handleClosePay = () => {
        setCoinEnough(false)
        setOpenPayCoin(false)
    }
    
    const handleSubmitPay = () => {
        setOpenPayCoin(false)
        setShowLoading(true)
        var totalReceiveCoin = payCoin - gpCoin
        var message = ''

        updateDoc(doc(db, `appointments`, appointment.id), { 
            coin: payCoin,
            status: 2 
        }).then(() => {
            getDoc(doc(db, `users`, userId)).then((userDoc) => {
                var exchangeCoin = 0
                if(userDoc.data().exchange_coin) { exchangeCoin = userDoc.data().exchange_coin }
                updateDoc(doc(db, 'users/', userId), {
                    exchange_coin: (exchangeCoin * 1) + totalReceiveCoin
                })
            }).then(() => {
                message = "คุณได้รับเหรียญทองจากการขายซาก เป็นจำนวน "+number_format.format(payCoin)+" เหรียญ"
                addUserNotification(db, userId, message, 'coin', postId, '/profile/postdetail/2/'+postId)
                
                message = "คุณจ่ายเหรียญทองค่าธรรมเนียมจากการขายซาก เป็นจำนวน "+number_format.format(gpCoin)+" เหรียญ"
                addUserNotification(db, userId, message, 'coin', postId, '/profile/postdetail/2/'+postId)
                
                message = "คุณจ่ายเหรียญทองเพื่อซื้อซาก จำนวน "+number_format.format(payCoin)+" เหรียญ"
                addShopNotification(db, shopId, message, 'coin', postId, '/shop/postdetail/2/'+postId)
                
                addShopCoinLog(db, shopId, payCoin, 'ซื้อซาก', '2', "post", postId, "shop", "user", false)
                
                addUserCoinLog(db, userId, payCoin, 'ขายซาก', '2', "post", postId, "shop", "user", true)
                
                addUserCoinLog(db, userId, gpCoin, 'ค่าธรรมเนียมขายซาก', '2', "post", postId, "user", "platform", false)
                
                updateDoc(doc(db, 'shops/', shopId), {
                    exchange_coin: (offerExchangeCoin * 1) - payCoin
                }).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })

            })
        })
    }

    const handleOpenCanclePay = () => {
        setOpenCanclePay(true)
    }

    const handleCloseCanclePay = () => {
        setOpenCanclePay(false)
    }

    const handleCanclePay = () => {
        setShowLoading(true)
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointment.id), { 
            status: 4,
            shop_cancle_message: message,
            shop_cancle_reason: reason,
            shop_cancle_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        var updatePostTask = updateDoc(doc(db, `/posts/`, postId), { status: 1 })
        promisesTask.push(updatePostTask)
        
        var updateShopOfferTask = updateDoc(doc(db, `/shops/${shopId}/offers`, shopOfferId), { status: 4 })
        promisesTask.push(updateShopOfferTask)
        
        var addCancleHistoryTask = addDoc(collection(db, `posts/${postId}/cancle_offers/`), {
            shop_id: shopId,
            cancle_message: message,
            cancle_reason: reason,
            cancle_datetime: logDate.getTime()
        })
        promisesTask.push(addCancleHistoryTask)

        var addBlackListTask = addDoc(collection(db, `posts/${postId}/shop_blacklist/`), {
            shop_id: shopId,
        })
        promisesTask.push(addBlackListTask)

        var addShopBlackListTask = addDoc(collection(db, `shop_blacklist/`), {
            shop_id: shopId,
            post_id: postId,
            event: "ยกเลิกนัดหมาย",
            blacklist_datetime: logDate.getTime(),
            blacklist_status: 1
        })
        promisesTask.push(addShopBlackListTask)

        Promise.all(promisesTask).then(() => {
            handleCloseCanclePay(false)
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleOpenFinish = () => {
        setOpenFinish(true)
    }
    
    const handleCloseFinish = () => {
        setOpenFinish(false)
    }
    
    const handleSubmitFinish = () => {
        setShowLoading(true)
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointment.id), { 
            status: 6,
            message_for_user: message,
            score_for_user: score,
            shop_finish_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        var updatePostTask = updateDoc(doc(db, `/posts/`, postId), { 
            shop_finish_date: logDate.getTime()
        })
        promisesTask.push(updatePostTask)

        // Shop ได้รับแต้มสะสม
        var weePoint = 100
        var addWeePointTask = addDoc(collection(db, `/shops/${shopId}/points`), {
            datetime: logDate.getTime(),
            point: weePoint,
            post_id: postId,
            appointment_id: appointment.id
        })
        promisesTask.push(addWeePointTask)

        var addNotiShopTask = addDoc(collection(db, `/shops/${shopId}/notifications`), {
            date: logDate.getTime(),
            message: "คุณได้รับแต้มสะสมจากการขายซาก จำนวน "+number_format.format(weePoint)+" เหรียญ",
            link: '/post/'+postId,
            type: 'point',
            status: 0
        })
        promisesTask.push(addNotiShopTask)

        // User ได้รับแต้มสะสม
        var weePoint = 100
        var addUserWeePointTask = addDoc(collection(db, `/users/${userId}/points`), {
            datetime: logDate.getTime(),
            point: weePoint,
            post_id: postId,
            appointment_id: appointment.id
        })
        promisesTask.push(addUserWeePointTask)

        var addNotiUserTask = addDoc(collection(db, `/users/${userId}/notifications`), {
            date: logDate.getTime(),
            message: "คุณได้รับแต้มสะสมจากการขายซาก จำนวน "+number_format.format(weePoint)+" แต้ม",
            link: '/post/'+postId,
            type: 'point',
            status: 0
        })
        promisesTask.push(addNotiUserTask)

        var updatePostTask = updateDoc(doc(db, `/posts/`, postId), { 
            status: 9,
            appointment_id: appointment.id,
            finish_date: logDate.getTime()
        })
        promisesTask.push(updatePostTask)

        calScoreUser(db, userId, score)
        
        Promise.all(promisesTask).then(() => {
            handleCloseFinish()
            setShowLoading(false)
            navigate(0)
        })
    }

    useEffect(() => {
        getPayRate()
        getBrand(db, appointment.post.brand_id, appointment.post.brand).then((brandName) => {
            setBrand(brandName)
        })
    
        getModel(db, appointment.post.model_id, appointment.post.model).then((modelName) => {
            setModel(modelName)
        })
        getElectric(db, appointment.post.type_id, appointment.post.other_type).then((typeName) => {
            setType(typeName)
        })
    }, [loading])

    return (
        <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            {badge.show && (
            <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                <button type="button" className={"btn "+"btn-"+badge.class} style={{ color: badge.color }}><strong>{badge.message}</strong></button>
            </div>
            )}
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                <img src={appointment.post.picture} alt="WEEE" height="150" />
            </div>
            <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                <p className="card-text">
                    <strong>ประเภทนัดหมาย:</strong> ขายซาก<br/>
                    <strong>{type} {brand} {model}</strong><br/>
                    <><strong>วันนัดหมาย:</strong> {dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/></>
                    <><strong>ที่อยู่:</strong> {appointment.post.address} <br/></>
                    <strong>ราคาประกาศขาย:</strong> {number_format.format(appointment.post.price)} บาท <br/>
                    <strong>ราคาเสนอซื้อ:</strong> {number_format.format(appointment.offer.price)} บาท <br/>
                    {(appointment.data.status == 6) && (<>
                        <strong>คะแนน:</strong> {appointment.data.score} <br/>
                        <strong>คำติชม:</strong> {appointment.data.finish_message} <br/>
                    </>)}
                </p>
            </div>
            <div className="card-body">
                <Link className="btn btn-primary m-1" to={`/post/${appointment.data.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                <Link className="btn btn-primary m-1" to={`/shop/postdetail/${appointment.post.onboard}/${appointment.data.post_id}/${appointment.data.shop_id}`} target='_blank'><strong>ดูนัดหมายและข้อเสนอ</strong></Link>
                {(appointment.data.status == 1) && (<>
                <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenPay() }}>
                    <strong>จ่ายเหรียญซื้อซาก</strong>
                </button>
                <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCanclePay() }}>
                    <strong>ยกเลิกการซื้อซาก</strong>
                </button>
                </>)}
                {(appointment.data.status == 3) && (<>
                <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenFinish() }}>
                    <strong>ยืนยันได้รับซาก</strong>
                </button>
                </>)}
            </div>

            <Dialog open={openPayCoin} onClose={handleClosePay} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>จ่ายเหรียญซื้อซาก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                        <div style={{ backgroundColor: '#ffffff' }}>
                            <h5>จ่ายเหรียญซื้อซาก</h5>
                            {coinEnough &&
                            <h6><strong>{coinMessage}</strong></h6>
                            }
                            {!coinEnough &&
                            <h6 className='text-danger'><strong>{coinMessage}</strong></h6>
                            }
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleClosePay} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    {coinEnough &&
                    <button onClick={handleSubmitPay} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>จ่ายเหรียญซื้อซาก</button>
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={openFinish} onClose={handleCloseFinish} fullWidth={true} maxWidth='sm'>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันได้รับซาก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                        <div style={{ backgroundColor: '#ffffff' }}>
                            <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                <label className="" style={{ display: 'flex' }}><h5><strong>คำติชมเพิ่มเติม</strong></h5></label>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder='คำติชมเพิ่มเติม' 
                                        value={message}></textarea>
                                </div>
                            </div>
                            <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                <label className="" style={{ display: 'flex' }}><h5><strong>คะแนน</strong></h5></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='score' onChange={(e) => setScore(e.target.value)} style={{ flex: 1 }}>
                                        <option value="5">5</option>
                                        <option value="4">4</option>
                                        <option value="3">3</option>
                                        <option value="2">2</option>
                                        <option value="1">1</option>
                                    </select>
                                </div>
                            </div> 
                            <div style={{ display:'flex', flexDirection: 'column', marginTop: 10 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h6 className='text-danger'><strong>คุณจะได้รับแต้มสะสม เมื่อกดยืนยันได้รับซากแล้ว</strong></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseFinish} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleSubmitFinish} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันได้รับซาก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCanclePay} onClose={handleCloseCanclePay} fullWidth={true} maxWidth='sm'>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการเข้าซื้อซาก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                        <div style={{ backgroundColor: '#ffffff' }}>
                            <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                <label className="" style={{ display: 'flex'}}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                    <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ flex: 1, paddingLeft: 8 }}>
                                        <option value="1">ไม่เจอผู้ประกาศ</option>
                                        <option value="2">มีการเรียกเก็บเงินเพิ่ม</option>
                                        <option value="3">ซากฯไม่ตรงกับที่ประกาศ</option>
                                        <option value="4">ให้นำสิ่งอื่นที่นอกเหนือจากประกาศไปทิ้งด้วย</option>
                                        <option value="5">ความพร้อมหรือความน่าเชื่อถือของผู้ประกาศ</option>
                                        <option value="6">อื่นๆ (โปรดระบุ)</option>
                                    </select>
                                </div>
                            </div>
                            <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                <label className="" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                        onChange={(e) => setMessage(e.target.value)}
                                        placeholder='เหตุผลเพิ่มเติม' 
                                        value={message}></textarea>
                                </div>
                            </div> 
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCanclePay} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCanclePay} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการซื้อซาก</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}