import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, getDoc, addDoc, updateDoc } from "firebase/firestore"
import { db } from "../../assets/js/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { PiCoinsDuotone } from "react-icons/pi"
import { TbCirclePlus, TbCircleMinus } from "react-icons/tb";
import "../../assets/css/memberList.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { addExchangeLog } from "../../../assets/js/LogHelper"

const ExchangeCoins = () => {
    const navigate = useNavigate();
    const params = useParams()
    const number_format = new Intl.NumberFormat();

    const [allFreeCoin, setAllFreeCoin] = useState(0)
    const [remainFreeCoin, setRemainFreeCoin] = useState(0)
    const [usedFreeCoin, setUsedFreeCoin] = useState(0)
    const [coin, setCoin] = useState(0)
    const [descCoin, setDescCoin] = useState(0)
    const [transactions, setTransactions] = useState([])
    const [logs, setLogs] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [checkEndDate, setCheckEndDate] = useState(true)

    const [openAddCoin, setOpenAddCoin] = useState(false)
    const [openDecreaseCoin, setOpenDecreaseCoin] = useState(false)
    const [openExchangeRate, setOpenExchangeRate] = useState(false)

    const [moneyToCoin1, setMoneyToCoin1] = useState(0)
    const [moneyToCoin2, setMoneyToCoin2] = useState(0)
    const [coinToMoney1, setCoinToMoney1] = useState(0)
    const [coinToMoney2, setCoinToMoney2] = useState(0)

    const handleCloseAddCoin = () => {
        setOpenAddCoin(false)
    }

    const handleCloseDecreaseCoin = () => {
        setOpenDecreaseCoin(false)
    }

    const handleCloseExchangeRate = () => {
        setOpenExchangeRate(false)
    }

    const handleCheckEndDate = () => {
        setCheckEndDate(!checkEndDate)
    }
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime.seconds * 1000).toLocaleDateString("th-TH");
        const time_string = new Date(datetime.seconds * 1000).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        const date_sprit = date_string.split('/')
        return date_sprit[0]+" "+textMonth(date_sprit[1])+" "+date_sprit[2]+" เวลา "+time[0]+":"+time[1]+" น."
    }
    
    const dateFormatDate = (datetime) => {
        const date_string = new Date(datetime.seconds * 1000).toLocaleDateString("th-TH");
        const date_sprit = date_string.split('/')
        return date_sprit[0]+" "+textMonth(date_sprit[1])+" "+date_sprit[2]
    }

    const textMonth = (month) => {
        var text = ""
        switch(month) {
            case "1": text = "มกราคม"; break;
            case "2": text = "กุมภาพันธ์"; break;
            case "3": text = "มีนาคม"; break;
            case "4": text = "เมษายน"; break;
            case "5": text = "พฤษภาคม"; break;
            case "6": text = "มิถุนายน"; break;
            case "7": text = "กรกฎาคม"; break;
            case "8": text = "สิงหาคม"; break;
            case "9": text = "กันยายน"; break;
            case "10": text = "ตุลาคม"; break;
            case "11": text = "พฤศจิกายน"; break;
            case "12": text = "ธันวาคม"; break;
        }
        return text
    }

    const handleAddCoin = () => {
        const transaction_date = new Date()
        addDoc(collection(db, `exchange_coins/sum_data/transaction`), {
            amount: coin*1,
            date: transaction_date,
            type: 'increase'
        }).then(() => {
            addExchangeLog(db, coin, "เพิ่มเหรียญใน platform", '', "add")
            updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                all: allFreeCoin+(coin*1),
                remain: remainFreeCoin+(coin*1)
            }).then(() => {
                setOpenAddCoin(false)
                navigate(0)
            })
        })
    }

    const handleDecreaseCoin = () => {
        if(descCoin*1 <= remainFreeCoin) {
            const transaction_date = new Date()
            addDoc(collection(db, `exchange_coins/sum_data/transaction`), {
                amount: descCoin*1,
                date: transaction_date,
                type: 'decrease'
            }).then(() => {
                addExchangeLog(db, descCoin, "ลดเหรียญใน platform", '', "withdraw")
                updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                    all: allFreeCoin-(descCoin*1),
                    remain: remainFreeCoin-(descCoin*1)
                }).then(() => {
                    setOpenDecreaseCoin(false)
                    navigate(0)
                })
            })
        }
    }

    const handleExchangeRate = () => {
        if(checkEndDate) {
            var end_date = endDate.toDate()
        } else {
            var end_date = 0
        }

        const log_date = new Date()
        addDoc(collection(db, `exchange_coins/exchange_rate/change_log`), {
            log_date: log_date,
            start_date: startDate.toDate(),
            end_date: end_date,
            money_to_coin_1: moneyToCoin1*1,
            money_to_coin_2: moneyToCoin2*1,
            coin_to_money_1: coinToMoney1*1,
            coin_to_money_2: coinToMoney2*1
        }).then(() => {
            updateDoc(doc(db, 'exchange_coins/', 'exchange_rate'), {
                start_date: startDate.toDate(),
                end_date: end_date,
                money_to_coin_1: moneyToCoin1*1,
                money_to_coin_2: moneyToCoin2*1,
                coin_to_money_1: coinToMoney1*1,
                coin_to_money_2: coinToMoney2*1
            }).then(() => {
                setOpenExchangeRate(false)
                navigate(0)
            })
        })
    }

    const getExchangeCoins = () => {
        getDoc(doc(db, 'exchange_coins', 'sum_data')).then((freeSnapshot) => {
            const data = freeSnapshot.data()
            setAllFreeCoin(data.all)
            setRemainFreeCoin(data.all - data.exchange)
            setUsedFreeCoin(data.used)
        })
    }

    const getTransaction = () => {
        const tranQuery = query(collection(db, `exchange_coins/sum_data/transaction`), orderBy('date', 'desc'))
        onSnapshot(tranQuery, (querySnapshot) => {
            setTransactions(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getExchangeLog = () => {
        const logQuery = query(collection(db, `exchange_coins/exchange_rate/change_log`), orderBy('log_date', 'desc'))
        onSnapshot(logQuery, (querySnapshot) => {
            setLogs(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    useEffect(() => {
        getExchangeCoins()
        getTransaction()
        getExchangeLog()
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><PiCoinsDuotone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>ข้อมูลเหรียญแลกเปลี่ยน</b></h4>
            </div>
            <div>
                <Paper elevation={2} style={{ display: 'flex', flex: 1, marginBottom: 20, flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                        <div><h4><strong>จำนวนเหรียญในระบบ</strong></h4></div>
                        <div style={{ paddingLeft: 10, marginTop: -20 }}><font style={{ fontSize: 60, marginRight: 15 }}>{number_format.format(remainFreeCoin)}/{number_format.format(allFreeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font></div>
                    </div>
                </Paper>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', paddingRight: 15 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 2, paddingRight: 30}}>
                    <div style={{ paddingRight: 10, marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAddCoin(true) }}>
                            <TbCirclePlus style={{ fontSize: 20, marginRight: 5 }} /> <b>เติมเหรียญเข้าระบบ</b>
                        </button>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenDecreaseCoin(true) }}>
                            <TbCircleMinus style={{ fontSize: 20, marginRight: 5 }} /> <b>นำเหรียญออกจากระบบ</b>
                        </button>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        <table className="table" style={{ marginLeft: 10}}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>วันที่</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, textAlign: 'center'}}>จำนวน</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                                </tr>
                            </thead>
                            <tbody>
                            {transactions?.map((transaction, i) => (
                                <tr key={i}>
                                    <td style={{ paddingLeft: 10 }}> </td>
                                    <th scope="row">{dateFormat(transaction.data.date)}</th>
                                    <td style={{ textAlign:'right'}}>
                                        {
                                            (transaction.data.type == 'increase') && 
                                            (<font style={{ fontSize: 18 }} className='text-success'>+{number_format.format(transaction.data.amount)}</font>)
                                        }
                                        {
                                            (transaction.data.type == 'decrease') && 
                                            (<font style={{ fontSize: 18 }} className='text-danger'>-{number_format.format(transaction.data.amount)}</font>)
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 3, paddingLeft: 10}}>
                    <div style={{ paddingRight: 10, marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenExchangeRate(true) }}>
                            <TbCirclePlus style={{ fontSize: 20, marginRight: 5 }} /> <b>ตั้งค่าอัตราแลกเปลี่ยนเหรียญ</b>
                        </button>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        <table className="table" style={{ marginLeft: 10}}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>วันเริ่มต้น-วันสิ้นสุด</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, textAlign: 'center'}}>อัตราแลกเปลี่ยน</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>วันที่เปลี่ยน</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                                </tr>
                            </thead>
                            <tbody>
                            {logs?.map((log, i) => (
                                <tr key={i}>
                                    <td style={{ paddingLeft: 10 }}> </td>
                                    <td scope="row" nowrap  style={{ width: 180 }}><font style={{ fontSize: 14 }}>{dateFormatDate(log.data.start_date)} - 
                                    {
                                        (log.data.end_date == 0)?" ไม่กำหนด":(" "+dateFormatDate(log.data.end_date))
                                    }
                                    </font></td>
                                    <th scope="row" style={{ }} nowrap>
                                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                                            <div>
                                                <font style={{ fontSize: 16 }} className='text-primary'>
                                                    เงิน -{'>'} เหรียญ<br/><font style={{ fontSize: 14 }} className='text-black'>{number_format.format(log.data.money_to_coin_1)} บาท = {number_format.format(log.data.money_to_coin_2)} เหรียญ</font><br/>
                                                </font>
                                            </div>
                                            <div style={{ marginLeft: 20 }}>
                                                <font style={{ fontSize: 16 }} className='text-primary'>
                                                    เหรียญ -{'>'} เงิน<br/><font style={{ fontSize: 14 }} className='text-black'>{number_format.format(log.data.coin_to_money_1)} เหรียญ = {number_format.format(log.data.coin_to_money_2)} บาท</font>
                                                </font>
                                            </div>
                                        </div>
                                    </th>
                                    <td style={{ width: 150 }}><font style={{ fontSize: 14 }}>{dateFormat(log.data.log_date)}</font></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Dialog open={openAddCoin} onClose={handleCloseAddCoin}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>เติมเหรียญแลกเปลี่ยนเข้าระบบ</strong></div></DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="จำนวนเหรียญ"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={coin}
                    onChange={(e) => setCoin(e.target.value)}
                />
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAddCoin} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleAddCoin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>เติมเหรียญ</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDecreaseCoin} onClose={handleCloseDecreaseCoin}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>นำเหรียญแลกเปลี่ยนออกจากระบบ</strong></div></DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="จำนวนเหรียญ"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={descCoin}
                    onChange={(e) => setDescCoin(e.target.value)}
                />
                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                    <strong>** นำออกได้ไม่เกิน {remainFreeCoin}</strong>
                </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDecreaseCoin} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleDecreaseCoin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>นำเหรียญออก</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openExchangeRate} onClose={handleCloseExchangeRate}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>เปลี่ยนอัตราการแลกเปลี่ยนเหรียญ</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div><strong>อัตราแลกเปลี่ยนจากเงินเป็นเหรียญ</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="เงิน (บาท)"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    value={moneyToCoin1}
                                    onChange={(e) => setMoneyToCoin1(e.target.value)}
                                    style={{ paddingLeft: 5, marginTop: 15 }}
                                />
                                <div style={{ marginLeft: 10, width: 70, paddingTop: 30}}><h4>-{'>'}</h4></div>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="จำนวนเหรียญ"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    value={moneyToCoin2}
                                    onChange={(e) => setMoneyToCoin2(e.target.value)}
                                    style={{ paddingLeft: 5, marginTop: 15 }}
                                />
                            </div>
                            <div style={{ marginTop: 10 }}><strong>อัตราแลกเปลี่ยนจาเหรียญเป็นเงิน</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="จำนวนเหรียญ"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    value={coinToMoney1}
                                    onChange={(e) => setCoinToMoney1(e.target.value)}
                                    style={{ paddingLeft: 5, marginTop: 15 }}
                                />
                                <div style={{ marginLeft: 10, width: 70, paddingTop: 30}}><h4>-{'>'}</h4></div>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    label="เงิน (บาท)"
                                    type="number"
                                    fullWidth
                                    variant="standard"
                                    value={coinToMoney2}
                                    onChange={(e) => setCoinToMoney2(e.target.value)}
                                    style={{ paddingLeft: 5, marginTop: 15 }}
                                />
                            </div>
                        </div>
                        <div style={{ marginLeft: 20 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 10}}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="วันเริ่มต้น" format="DD/MM/YYYY" onChange={(value) => setStartDate(value)} />
                                    </LocalizationProvider>
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                                    <FormControlLabel control={<Checkbox
                                        checked={checkEndDate}
                                        onChange={() => { handleCheckEndDate() }}
                                    />} label="กำหนดวันสิ้นสุด" style={{ fontFamily: 'Prompt'}} />
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="วันสิ้นสุด" format="DD/MM/YYYY" disabled={!checkEndDate} onChange={(value) => setEndDate(value)} />
                                    </LocalizationProvider>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseExchangeRate} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleExchangeRate} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default ExchangeCoins;