const adsPositionAmount = [ 4, 5, 5, 4, 3, 8, 8, 10, 10, 10 ]
const adsPositionName = [ 
    "หน้าแรก ตำแหน่งบนสุด",
    "หน้าแรก ขายเครื่องใช้ไฟฟ้ามือสอง", 
    "หน้าแรก รวมช่างซ่อม",
    "หน้าแรก บริการล้างแอร์",
    "หน้าแรก บริการล้างเครื่องซักผ้า",
    "หน้าแสดงทั้งหมด ขายเครื่องใช้ไฟฟ้า",
    "หน้าแสดงทั้งหมด ขายซากเครื่องใช้ไฟฟ้า",
    "หน้าแสดงทั้งหมด แจ้งซ่อมเครื่องใช้ไฟฟ้า",
    "หน้าแสดงทั้งหมด แจ้งล้างแอร์",
    "หน้าแสดงทั้งหมด แจ้งล้างเครื่องซักผ้า" ]

export { adsPositionAmount , adsPositionName }