export function dateFormat (datetime) {
    return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }):"")
}

export function dateFormatTime (datetime) {
    return (datetime?new Date(datetime).toLocaleDateString("th-TH", {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
    }):"")
}