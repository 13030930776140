import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, auth } from "../../assets/js/firebase"
import { updateDoc, doc, getDoc, getDocs, query, collection, where, deleteDoc } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom"
import { signInWithEmailAndPassword, deleteUser } from "firebase/auth"

import { BsPersonVcard } from "react-icons/bs"
import { TbUser, TbCircleCheck, TbAlertCircle, TbMessageCircle2, TbHome, TbClock } from "react-icons/tb"
import { FiMail, FiEdit } from "react-icons/fi"
import { IoCloseCircle, IoChevronBackCircleSharp } from "react-icons/io5"
import { FaMapMarkerAlt } from 'react-icons/fa'
import { RiBankCardLine } from 'react-icons/ri'

import gmail from "../../assets/images/gmail-logo.png"
import noImage from "../../assets/images/avatar.png"

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { Card, CardContent, Typography, TextField } from '@mui/material';
import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Modal from '@mui/material/Modal'

const Member = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)
    const [memberAuth, setMemberAuth] = useState()

    const [showLoading, setShowLoading] = useState(false)

    const [uid, setUid] = useState('')
    const [member, setMember] = useState([])
    const [memberId, setMemberId] = useState(params.id)
    const [email, setEmail] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [verifyEmail, setVerifyEmail] = useState(false)

    const [subDistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [lat, setLat] = useState(15.11745249400088)
    const [lng, setLng] = useState(104.90284046686465)

    const [bankName, setBankName] = useState('')

    const [openAlertApprove, setOpenAlertApprove] = useState(false)
    const [openAlertDisable, setOpenAlertDisable] = useState(false)
    const [openAlertCancle, setOpenAlertCancle] = useState(false)
    const [openAlertDelete, setOpenAlertDelete] = useState(false)

    const [openSendMessage, setOpenSendMessage] = useState(false)
    const [message, setMessage] = useState('')

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    })

    const getDocUser = () => {
        getDoc(doc(db, `users`, memberId)).then(async (docData) => {
            const memberData = docData.data()
            setUid(memberData.uid)
            setMember(memberData)

            if(memberData.is_shop) setIsShop(memberData.is_shop)
            if(memberData.email) setEmail(memberData.email)

            var promisesTask = []
            if(memberData.province) {
                const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', memberData.province * 1))).then((docs) => {
                    if(docs.docs.length > 0) setProvince(docs.docs[0].data().province_name)
                })
                promisesTask.push(provinceTask)
            }

            if(memberData.district) {
                const districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', memberData.district))).then((docs) => {
                    if(docs.docs.length > 0) setDistrict(docs.docs[0].data().district_name)
                })
                promisesTask.push(districtTask)
            }

            if(memberData.sub_district) {
                const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', memberData.sub_district * 1))).then((docs) => {
                    if(docs.docs.length > 0) setSubDistrict(docs.docs[0].data().subdistrict_name)
                })
                promisesTask.push(subdistrictTask)
            }

            if(memberData.bank_id == 0 || !memberData.bank_id) {
                setBankName('ไม่ระบุ')
            } else {
                const bankTask  = getDoc(doc(db, `banks/${memberData.bank_id}`)).then((doc) => {
                    setBankName(doc.data().bank_name_th)
                })
                promisesTask.push(bankTask)
            }

            const currentUser = user
            var promisesAuth = []
            if(memberData.password) {
                const authTask = await signInWithEmailAndPassword(auth, memberData.email, memberData.password).then(async (result) => {
                    setMemberAuth(result)
                    setVerifyEmail(result.user.emailVerified)
                    await auth.updateCurrentUser(currentUser)
                })
                promisesAuth.push(authTask)
            }
            Promise.all(promisesAuth)
            

            setLat(memberData.lat)
            setLng(memberData.lng)

            Promise.all(promisesTask).then(() => {
                setShowLoading(false)
            })
        }).then(() => {
        }).catch((error) => {
            alert(error.message)
        })
    }

    const approveStatus = () => {
        updateDoc(doc(db, `users`, memberId), { status: 2 }).then(() => {
            navigate(0)
        })
    }

    const handleCloseAlertApprove = () => {
        setOpenAlertApprove(false)
    }

    const disableStatus = () => {
        updateDoc(doc(db, `users`, memberId), { status: 3 }).then(() => {
            navigate(0)
        })
    }

    const handleClose = () => { }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }
    
    useEffect(() => {
        if (loading) return;
        setShowLoading(true)
        getDocUser()
    }, [loading])

    const handleCloseAlertDisable = () => {
        setOpenAlertDisable(false)
    }

    const cancleStatus = () => {
        updateDoc(doc(db, 'users', memberId), {
            status: 1
        }).then(() => {
            navigate(0)
        })
    }

    const handleCloseAlertCancle = () => {
        setOpenAlertCancle(false)
    }

    const handleSendMessage = () => {
    }

    const handleCloseSendMessage = () => {
        setOpenSendMessage(false)
    }

    const deleteMember = async () => {
        const currentUser = user
        var promisesAuth = []

        if(member.password) {
            const authTask = await signInWithEmailAndPassword(auth, member.email, member.password).then(async (result) => {
                const delUser = await deleteUser(result.user)
                const delDoc = await deleteDoc(doc(db, `users`, memberId))
                const updateUser = await auth.updateCurrentUser(currentUser)
                promisesAuth.push(delUser)
                promisesAuth.push(delDoc)
                promisesAuth.push(updateUser)
            })
            promisesAuth.push(authTask)
        }
        Promise.all(promisesAuth).then(() => {
            navigate(`/admin/members/`)
        })
    }

    const handleCloseAlertDelete = () => {
        setOpenAlertDelete(false)
    }

    return (
        <>
            <Modal
                open={showLoading}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    กำลังเรียกข้อมูล กรุณารอสักครู่
                    </Typography>
                    <LinearProgress style={{ marginTop: 20 }} />
                </Box>
            </Modal>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
                {!showLoading && 
                <>
                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                        <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <h4 style={{ display: 'flex', flex: 1}}><TbUser style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อมูลผู้ใช้</b></h4>
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/members/`) }}>
                                    <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                                </button>
                            </div>
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenSendMessage(true) }}>
                                    <TbMessageCircle2 style={{ fontSize: 20, marginRight: 5 }} />ส่งข้อความ
                                </button>
                            </div>
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/member/edit/${memberId}`) }}>
                                    <FiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขข้อมูล
                                </button>
                            </div>
                            {member.status==1 && 
                            (<div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertApprove(true) }}>
                                    <TbCircleCheck style={{ fontSize: 20, marginRight: 5 }} />อนุมัติการใช้งาน
                                </button>
                            </div>)
                            }
                            {(member.status==1 || member.status==2) && 
                            (<div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDisable(true) }}>
                                    <TbAlertCircle style={{ fontSize: 20, marginRight: 5 }} />ระงับการใช้งาน
                                </button>
                            </div>)
                            }
                            {member.status==3 && 
                            (<div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertCancle(true) }}>
                                    <TbAlertCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิกระงับการใช้งาน
                                </button>
                            </div>)
                            }
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDelete(true) }}>
                                    <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบผู้ใช้
                                </button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 170 }}>
                                <div style={{ minWidth: 100, maxWidth: 150 }}>
                                    {member.image_profile?
                                    (<img src={member.image_profile} alt="WEEE" className='img-fluid' />):
                                    (<img src={noImage} alt="WEEE" className='img-fluid' />)}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                                <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                                    <h6><BsPersonVcard style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                    <b>ข้อมูลส่วนตัว</b></h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15 }}>
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>สถานะ</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{member.status==1?(<font className='text-danger'>ยังไม่อนุมัติ</font>):(<font className='text-success'>อนุมัติ</font>)}</div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>การยืนยัน Email</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{verifyEmail?(<font className='text-success'>ยืนยัน</font>):(<font className='text-danger'>ยังไม่ยืนยัน</font>)}</div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>Email</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{email}</div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อผู้ใช้</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{member.display_name?member.display_name:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อ-นามสกุล</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}><font style={{ marginRight: 10 }}>{member.firstname?member.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font> <font>{member.lastname?member.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font></div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>เบอร์โทรศัพท์</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{member.tel_no?member.tel_no:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>สำเนาบัตรประชาชน</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>
                                            {member.id_card?
                                            (<a href={member.id_card} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><img src={member.id_card} alt="WEEE" className='img-fluid' style={{ width: 200 }} /></a>):
                                            (<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                </div>
                                <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginTop: 15 }}>
                                    <h6><RiBankCardLine style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                    <b>บัญชีธนาคาร</b></h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 170}}><strong>ธนาคาร</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}><font style={{ marginRight: 10 }}>{bankName?bankName:(<font className="text-danger">ไม่ระบุ</font>)}</font></div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 170}}><strong>หมายเลขบัญชี</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{member.bank_account_no?member.bank_account_no:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 170}}><strong>สำเนาหน้าสมุดบัญชี</strong></label>
                                        <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>
                                            {member.book_bank?
                                            (<a href={member.book_bank} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><img src={member.book_bank} alt="WEEE" className='img-fluid' style={{ width: 200 }} /></a>):
                                            (<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 20}}>
                                <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                                    <h6><TbHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                    <b>ข้อมูลที่อยู่</b></h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>บ้านเลขที่</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {member.address?member.address:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>หมู่บ้าน/สถานที่</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {member.village?member.village:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>อาคาร</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {member.building?member.building:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ชั้น</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {member.floor?member.floor:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ถนน</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {member.road?member.road:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ห้อง</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {member.room?member.room:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ซอย</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {member.subroad?member.subroad:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ตำบล</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {subDistrict?subDistrict:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>อำเภอ</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {district?district:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>จังหวัด</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {province?province:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>รหัสไปรษณีย์</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {member.postcode?member.postcode:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', width: 170 }}><></></div>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginTop: 15 }}>
                                    <h6><FaMapMarkerAlt style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} />
                                    <b>ตำแหน่งพิกัดที่พักอาศัย</b></h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                                    <div className="mb-3 row" style={{ display: 'flex', height:400, flexDirection: 'column' }}>
                                        <div style={{  display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                            <GoogleMap mapContainerClassName="map-container" center={{ lat: lat, lng: lng }} zoom={17} >
                                                <MarkerF position={{ lat: lat, lng: lng }}/>
                                            </GoogleMap>
                                            )}
                                        </div>
                                    </div>
                                </div> 
                            </div>
                        </div>
                    </div>
                </>
                }
                <Dialog open={openAlertApprove} onClose={handleCloseAlertApprove} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                        {"คุณต้องการอนุมัติการใช้งานสมาชิกนี้?"}
                    </DialogTitle>
                    <DialogActions>
                    <Button onClick={handleCloseAlertApprove} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                    <Button onClick={() => { approveStatus() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openAlertDisable} onClose={handleCloseAlertDisable} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                        {"คุณต้องการระงับการใช้งานสมาชิกนี้?"}
                    </DialogTitle>
                    <DialogActions>
                    <Button onClick={handleCloseAlertDisable} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                    <Button onClick={() => { disableStatus() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openAlertCancle} onClose={handleCloseAlertCancle} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                        {"คุณต้องการยกเลิกระงับการใช้งานสมาชิกนี้?"}
                    </DialogTitle>
                    <DialogActions>
                    <Button onClick={handleCloseAlertCancle} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                    <Button onClick={() => { cancleStatus() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                    </DialogActions>
                </Dialog>
                <Dialog open={openAlertDelete} onClose={handleCloseAlertDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                        {"คุณต้องการลบสมาชิกนี้?"}
                    </DialogTitle>
                    <DialogActions>
                    <Button onClick={handleCloseAlertDelete} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                    <Button onClick={() => { deleteMember() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openSendMessage} onClose={handleCloseSendMessage}>
                    <Card variant="outlined">
                        <CardContent style={{ fontFamily: 'Prompt' }}>
                            <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                                ส่งข้อความ
                            </Typography>
                            <TextField
                                autoFocus
                                margin="dense"
                                label="ข้อความ"
                                fullWidth
                                variant="standard"
                                value={message}
                                onChange={(e) => setMessage(e.target.value)}
                                style={{ fontFamily: 'Prompt' }}
                                multiline
                                rows={4}
                            />
                        </CardContent>
                        <DialogActions>
                            <button onClick={handleCloseSendMessage} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                            <button onClick={handleSendMessage} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                        </DialogActions>
                    </Card>
                </Dialog>
            </div>
        </>
    )
}

export default Member;