import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, getDoc, doc } from 'firebase/firestore'
import { useParams, Link } from "react-router-dom"

import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2 } from "react-icons/tb"
import { FiInfo } from "react-icons/fi"
import noImage from "../../assets/images/avatar.png"

import { Loading } from "../layouts/Loading"
import { getBrand, getModel } from '../helper/BrandAirHelper'

const Posts = () => {
    const params = useParams()
    const posterId = params.id
    const [loading] = useAuthState(auth)
    const [posts, setPosts] = useState([])
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [posterName, setPosterName] = useState('')
    const number_format = new Intl.NumberFormat()
    const [poster, setPoster] = useState([])
    const [subDistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
 
    const dateFormat = (datetime) => {
        return (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const fetchPosts = async () => {
        setShowProgressBar(true)
        await getDocs(query(collection(db, 'cleans'), 
                    where('onboard', '==', 1), 
                    where('poster_id', '==', posterId),
                    where('status', 'not-in', [5, 6]),
                    orderBy('status', 'asc'),
                    orderBy('post_timestamp', 'desc'))).then((postDocs) => {
            posts.splice(0, posts.length)
            setPosts([...posts])
            postDocs.forEach((docPost) => {
                var brand1 = "ไม่ระบุ"
                var brand2 = "ไม่ระบุ"
                var brand3 = "ไม่ระบุ"
                var model1 = "ไม่ระบุ"
                var model2 = "ไม่ระบุ"
                var model3 = "ไม่ระบุ"
                var promisesTask = []
                if(docPost.data().have_air1) {
                    var getBrand1Task = getBrand(db, docPost.data().air1_brand_id, docPost.data().air1_brand).then((brandName) => {
                        brand1 = brandName
                    })
                    promisesTask.push(getBrand1Task)
    
                    var getModel1Task = getModel(db, docPost.data().air1_model_id, docPost.data().air1_model).then((modelName) => {
                        model1 = modelName
                    })
                    promisesTask.push(getModel1Task)
                }
                if(docPost.data().have_air2) {
                    var getBrand2Task = getBrand(db, docPost.data().air2_brand_id, docPost.data().air2_brand).then((brandName) => {
                        brand2 = brandName
                    })
                    promisesTask.push(getBrand2Task)
    
                    var getModel2Task = getModel(db, docPost.data().air2_model_id, docPost.data().air2_model).then((modelName) => {
                        model2 = modelName
                    })
                    promisesTask.push(getModel2Task)
                }
                if(docPost.data().have_air3) {
                    var getBrand3Task = getBrand(db, docPost.data().air3_brand_id, docPost.data().air3_brand).then((brandName) => {
                        brand3 = brandName
                    })
                    promisesTask.push(getBrand3Task)
    
                    var getModel3Task = getModel(db, docPost.data().air3_model_id, docPost.data().air3_model).then((modelName) => {
                        model3 = modelName
                    })
                    promisesTask.push(getModel3Task)
                }

                getDocs(query(collection(db, `cleans/${docPost.id}/offers`), where('status', '==', 1), orderBy('offer_timestamp', 'desc'))).then((offerQuery) => {
                    var offersList = []
                    offerQuery.forEach((offer) => {
                        offersList.push({ id: offer.id, data: offer.data()})
                    })
                    posts.push({
                        id: docPost.id,
                        data: docPost.data(),
                        offer: offersList,
                        brand1: brand1,
                        brand2: brand2,
                        brand3: brand3,
                        model1: model1,
                        model2: model2,
                        model3: model3
                    })
                    setPosts([...posts], {
                        id: docPost.id,
                        data: docPost.data(),
                        offer: offersList,
                        brand1: brand1,
                        brand2: brand2,
                        brand3: brand3,
                        model1: model1,
                        model2: model2,
                        model3: model3
                    })
                })
            })
        }).then(() => {
            setShowProgressBar(false)
        })
    }

    const getUserDoc = () => {
        getDoc(doc(db, `users/`, posterId)).then((posterDoc) => {
            const dataPoster = posterDoc.data()
            if(dataPoster.display_name) setPosterName(dataPoster.display_name)
            setPoster(dataPoster)

            var promisesTask = []
            if(dataPoster.province) {
                const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', dataPoster.province * 1))).then((docs) => {
                    if(docs.docs.length > 0) setProvince(docs.docs[0].data().province_name)
                })
                promisesTask.push(provinceTask)
            }

            if(dataPoster.district) {
                const districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', dataPoster.district))).then((docs) => {
                    if(docs.docs.length > 0) setDistrict(docs.docs[0].data().district_name)
                })
                promisesTask.push(districtTask)
            }

            if(dataPoster.sub_district) {
                const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', dataPoster.sub_district * 1))).then((docs) => {
                    if(docs.docs.length > 0) setSubDistrict(docs.docs[0].data().subdistrict_name)
                })
                promisesTask.push(subdistrictTask)
            }
            Promise.all(promisesTask).then(() => {
            })
        })
    }

    useEffect(() => {
        if (loading) return;
        else { 
            fetchPosts()
            getUserDoc() 
        }
    }, [loading])

    var postKey = []
    var postList = []
    posts.forEach(element => {
        if(postKey.lastIndexOf(element.id) < 0) {
            postKey.push(element.id)
            postList.push(element)
        }
    })

    var maxWidthProfile = 170
    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        maxWidthProfile = 320
    } else {
        maxWidthProfile = 320
    }

    return (
    <>
        <Loading open={showProgressBar} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 20}}>
            <div className="flex-container" style={{ display: 'flex', marginLeft: 0, marginBottom: 30, alignContent: 'center' }}>
                <div style={{ display: 'flex', flexDirection: 'column', width: maxWidthProfile, marginBottom: 10 }}>
                    {/* <h6><strong>ข้อมูลผู้ใช้</strong></h6> */}
                    <div style={{ display: 'flex', flexDirection: 'column', width: maxWidthProfile }}>
                        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', alignContent:'center' }}>
                            <div style={{ minWidth: 100, maxWidth: 150 }}>
                                {poster.image_profile?
                                (<img src={poster.image_profile} alt="WEEE" className='img-fluid' />):
                                (<img src={noImage} alt="WEEE" className='img-fluid' />)}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <h5><strong>{poster.display_name}</strong></h5>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <font style={{ fontSize: 14 }}>
                                    สถานะการใช้งาน: <strong>{poster.status==1?(<font className='text-danger'>ยังไม่อนุมัติ</font>):(<font className='text-success'>อนุมัติ</font>)}</strong>
                                </font>
                            </div>
                            <div style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%', alignContent:'center', marginTop: 10 }}>
                                <strong>ที่อยู่ที่ใช้สมัคร: </strong>
                                <div style={{ display: 'flex', flexDirection: 'column', width: '100%', textAlign:'center' }}>{subDistrict?subDistrict:"ไม่ระบุ"} {district?district:"ไม่ระบุ"} {province?province:"ไม่ระบุ"} {poster.postcode?poster.postcode:"ไม่ระบุ"} </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, borderTop: '1px solid #cccccc', paddingTop: 10 }}>
                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ประกาศแจ้งล้างแอร์ของ {posterName}</b></h4></div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ borderBottom: '1px solid #cccccc' }}>
                                <ul className="nav nav-underline">
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/1/${posterId}`}><RiDeleteBinLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ทิ้งซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/2/${posterId}`}><BiMoneyWithdraw style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/3/${posterId}`}><TbHexagonNumber2 style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายมือสอง</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/4/${posterId}`}><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งซ่อมเครื่องใช้ไฟฟ้า</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href={`/postlist/air/${posterId}`}><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างแอร์</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href={`/postlist/wash/${posterId}`}><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างเครื่องซักผ้า</a>
                                    </li>
                                </ul>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10}}>
                            {postList?.map((post, i) => (
                                <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={i}>
                                    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                        <img src={post.data.picture} alt="APP3R" height="150" />
                                    </div>
                                    <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                        <p className="card-text">
                                            {(post.data.have_air1) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์ติดตั้งผนัง</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}><strong>{post.brand1} {post.model1}</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(post.data.air1_BTU)} BTU<br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.data.air1_amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                            {(post.data.have_air2) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์แขวนใต้ฝ้า</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}><strong>{post.brand2} {post.model2}</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(post.data.air2_BTU)} BTU<br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.data.air2_amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                            {(post.data.have_air3) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์ฝังฝ้า 4 ทิศทาง</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}><strong>{post.brand3} {post.model3}</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(post.data.air3_BTU)} BTU<br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.data.air3_amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                        </p>
                                        <p className="card-text"><strong>วันที่ประกาศ:</strong><br/>{dateFormat(post.data.post_timestamp)}</p>
                                    </div>
                                    <div className="card-body">
                                        <Link className="btn btn-primary m-1" to={`/postclean/${post.id}`} target='_blank'><FiInfo /> <strong>ดูประกาศ</strong></Link>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Posts