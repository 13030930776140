import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc, orderBy } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { useNavigate } from "react-router-dom"
import { FaMapMarkerAlt } from "react-icons/fa"
import { defauleImageSize } from "../../../assets/constants/images"
import { defaultLatLng, googleMapsApiKey, locationOptions } from "../../../assets/constants/locations"
import { getProvinceList, getDistrictListByProvince, getSubDistrictListByDistrict, getPostcodeBySubDistrict } from "../../../assets/js/AreaHelper"
import { Loading } from "../layouts/Loading"
import { addUserNotification } from "../../../assets/js/NotificationHelper"
import { addPlatformCoinLog, addFreeCoinLog, addExchangeLog, addUserCoinLog } from "../../../assets/js/LogHelper"
import addImage from "../../assets/images/add-image-icon.png"

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from "react-icons/io5"

const AddPostFix = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [user, loading] = useAuthState(auth)

    const [uid, setUid] = useState('')
    const [memberId, setMemberId] = useState('')
    const [electricType, setElectricType] = useState([])
    const [brandList, setBrandList] = useState([])
    const [modelList, setModelList] = useState([])
    const [provinceList, setProvinceList] = useState([])
    const [districtList, setDistrictList] = useState([])
    const [subdistrictList, setSubDistrictList] = useState([])
    const [brokenList, setBrokenList] = useState([])

    const [price, setPrice] = useState('')

    const [picture1, setPicture1] = useState('')
    const [picture2, setPicture2] = useState('')
    const [picture3, setPicture3] = useState('')
    const [picture4, setPicture4] = useState('')
    const [picture5, setPicture5] = useState('')
    const [srcPicture1, setSrcPicture1] = useState(addImage)
    const [srcPicture2, setSrcPicture2] = useState(addImage)
    const [srcPicture3, setSrcPicture3] = useState(addImage)
    const [srcPicture4, setSrcPicture4] = useState(addImage)
    const [srcPicture5, setSrcPicture5] = useState(addImage)

    const [placePicture1, setPlacePicture1] = useState('')
    const [placePicture2, setPlacePicture2] = useState('')
    const [placePicture3, setPlacePicture3] = useState('')
    const [placePicture4, setPlacePicture4] = useState('')
    const [placePicture5, setPlacePicture5] = useState('')
    const [srcPlacePicture1, setSrcPlacePicture1] = useState(addImage)
    const [srcPlacePicture2, setSrcPlacePicture2] = useState(addImage)
    const [srcPlacePicture3, setSrcPlacePicture3] = useState(addImage)
    const [srcPlacePicture4, setSrcPlacePicture4] = useState(addImage)
    const [srcPlacePicture5, setSrcPlacePicture5] = useState(addImage)

    const [electricTypeId, setElectricTypeId] = useState(0)
    const [otherType, setOtherType] = useState('')
    const [brandId, setBrandId] = useState('')
    const [brand, setBrand] = useState('') // other brand
    const [modelId, setModelId] = useState('')
    const [model, setModel] = useState('') // other model

    const [description, setDescription] = useState('')
    const [address, setAddress] = useState('')
    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [owner, setOwner] = useState(1);
    const [ownerText, setOwnerText] = useState("")
    const [buyYear, setBuyYear] = useState('')
    const [fixDay, setFixDay] = useState(1)
    const [brokenId, setBrokenId] = useState(0)
    const [otherBroken, setOtherBroken] = useState('')
    const [fixType, setFixType] = useState(1)

    const [currentLat, setCurrentLat] = useState(defaultLatLng.lat)
    const [currentLng, setCurrentLng] = useState(defaultLatLng.lng)

    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [provinceId, setProvinceId] = useState(0)
    const [districtId, setDistrictId] = useState(0)
    const [subdistrictId, setSubDistrictId] = useState(0)
    const [postcode, setPostcode] = useState('')

    const [addressNoRegis, setAddressNoRegis] = useState('')
    const [villageRegis, setVillageRegis] = useState('')
    const [buildingRegis, setBuildingRegis] = useState('')
    const [floorRegis, setFloorRegis] = useState('')
    const [roomRegis, setRoomRegis] = useState('')
    const [subroadRegis, setSubroadRegis] = useState('')
    const [roadRegis, setRoadRegis] = useState('')
    const [provinceIdRegis, setProvinceIdRegis] = useState(0)
    const [districtIdRegis, setDistrictIdRegis] = useState(0)
    const [subdistrictIdRegis, setSubDistrictIdRegis] = useState(0)
    const [postcodeRegis, setPostcodeRegis] = useState('')

    const [showProgressBar, setShowProgressBar] = useState(false)
    const [imageSizeError, setImageSizeError] = useState(false)
    const [ownerTextErrorDiv, setOwnerTextErrorDiv] = useState(false)
    const [typeIdErrorDiv, setTypeIdErrorDiv] = useState(false)
    const [otherTypeErrorDiv, setOtherTypeErrorDiv] = useState(false)
    const [brokenIdErrorDiv, setBrokenIdErrorDiv] = useState(false)
    const [otherBrokenErrorDiv, setOtherBrokenErrorDiv] = useState(false)
    const [priceErrorDiv, setPriceErrorDiv] = useState(false)
    const [pictureErrorDiv, setPictureErrorDiv] = useState(false)
    const [useCoinErrorDiv, setUseCoinErrorDiv] = useState(false)
    const [buyYearErrorDiv, setBuyYearErrorDiv] = useState(false)
    const [buyYearMoreErrorDiv, setBuyYearMoreErrorDiv] = useState(false)
    const [fixDayErrorDiv, setFixDayErrorDiv] = useState(false)
    const [brandIdErrorDiv, setBrandIdErrorDiv] = useState(false)
    const [brandErrorDiv, setBrandErrorDiv] = useState(false)
    const [modelIdErrorDiv, setModelIdErrorDiv] = useState(false)
    const [modelErrorDiv, setModelErrorDiv] = useState(false)
    const [provinceIdErrorDiv, setProvinceIdErrorDiv] = useState(false)
    const [districtIdErrorDiv, setDistrictIdErrorDiv] = useState(false)
    const [subdistrictIdErrorDiv, setSubDistrictErrorDiv] = useState(false)
    const [placeImageSizeError, setPlaceImageSizeError] = useState(false)
    const [placePictureErrorDiv, setPlacePictureErrorDiv] = useState(false)

    const [offerUseCoin, setOfferUseCoin] = useState(0)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [useCoinFrom, setUseCoinFrom] = useState(1)

    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey })
    const [center, setCenter] = useState({ lat: defaultLatLng.lat, lng: defaultLatLng.lng })
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    const [openAcceptPolicy1, setOpenAcceptPolicy1] = useState(false)
    const [openAcceptPolicy2, setOpenAcceptPolicy2] = useState(false)
    const [openAcceptPolicy3, setOpenAcceptPolicy3] = useState(false)
    const [openAcceptPolicy4, setOpenAcceptPolicy4] = useState(false)
    const [openAcceptPolicy5, setOpenAcceptPolicy5] = useState(false)
    const [openAcceptPolicy6, setOpenAcceptPolicy6] = useState(false)
    const [openAcceptPolicy7, setOpenAcceptPolicy7] = useState(false)
    const [openAcceptPolicy8, setOpenAcceptPolicy8] = useState(false)
    const [checkPolicy1, setCheckPolicy1] = useState(false)
    const [checkPolicy2, setCheckPolicy2] = useState(false)
    const [checkPolicy3, setCheckPolicy3] = useState(false)
    const [checkPolicy4, setCheckPolicy4] = useState(false)
    const [checkPolicy5, setCheckPolicy5] = useState(false)
    const [checkPolicy6, setCheckPolicy6] = useState(false)
    const [checkPolicy7, setCheckPolicy7] = useState(false)
    const [checkPolicy8, setCheckPolicy8] = useState(false)
    const [policy1ErrorDiv, setPolicy1ErrorDiv] = useState(false)
    const [policy2ErrorDiv, setPolicy2ErrorDiv] = useState(false)
    const [policy3ErrorDiv, setPolicy3ErrorDiv] = useState(false)
    const [policy4ErrorDiv, setPolicy4ErrorDiv] = useState(false)
    const [policy5ErrorDiv, setPolicy5ErrorDiv] = useState(false)
    const [policy6ErrorDiv, setPolicy6ErrorDiv] = useState(false)
    const [policy7ErrorDiv, setPolicy7ErrorDiv] = useState(false)
    const [policy8ErrorDiv, setPolicy8ErrorDiv] = useState(false)

    const startAcceptPolicy = () => {
        if(validateForm()) {
            setOpenAcceptPolicy1(true)
        }
    }

    const closeAllPolicyError = () => {
        setPolicy1ErrorDiv(false)
        setPolicy2ErrorDiv(false)
        setPolicy3ErrorDiv(false)
        setPolicy4ErrorDiv(false)
        setPolicy5ErrorDiv(false)
        setPolicy6ErrorDiv(false)
        setPolicy7ErrorDiv(false)
        setPolicy8ErrorDiv(false)
        setCheckPolicy1(false)
        setCheckPolicy2(false)
        setCheckPolicy3(false)
        setCheckPolicy4(false)
        setCheckPolicy5(false)
        setCheckPolicy6(false)
        setCheckPolicy7(false)
        setCheckPolicy8(false)
    }

    const handleCloseAcceptPolicy1 = () => {
        setOpenAcceptPolicy1(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy2 = () => {
        setOpenAcceptPolicy2(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy3 = () => {
        setOpenAcceptPolicy3(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy4 = () => {
        setOpenAcceptPolicy4(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy5 = () => {
        setOpenAcceptPolicy5(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy6 = () => {
        setOpenAcceptPolicy6(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy7 = () => {
        setOpenAcceptPolicy7(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy8 = () => {
        setOpenAcceptPolicy8(false)
        closeAllPolicyError()
    }

    const handleCheckPolicy1 = () => {
        setCheckPolicy1(!checkPolicy1)
    }

    const handleCheckPolicy2 = () => {
        setCheckPolicy2(!checkPolicy2)
    }

    const handleCheckPolicy3 = () => {
        setCheckPolicy3(!checkPolicy3)
    }

    const handleCheckPolicy4 = () => {
        setCheckPolicy4(!checkPolicy4)
    }

    const handleCheckPolicy5 = () => {
        setCheckPolicy5(!checkPolicy5)
    }

    const handleCheckPolicy6 = () => {
        setCheckPolicy6(!checkPolicy6)
    }

    const handleCheckPolicy7 = () => {
        setCheckPolicy7(!checkPolicy7)
    }

    const handleCheckPolicy8 = () => {
        setCheckPolicy8(!checkPolicy8)
    }

    const handleAcceptPolicy1 = () => {
        if(!checkPolicy1) {
            setPolicy1ErrorDiv(true)
        } else {
            setPolicy1ErrorDiv(false)
            handleCloseAcceptPolicy1(false)
            setOpenAcceptPolicy2(true)
        }
    }

    const handleAcceptPolicy2 = () => {
        if(!checkPolicy2) {
            setPolicy2ErrorDiv(true)
        } else {
            setPolicy2ErrorDiv(false)
            handleCloseAcceptPolicy2(false)
            setOpenAcceptPolicy3(true)
        }
    }

    const handleAcceptPolicy3 = () => {
        if(!checkPolicy3) {
            setPolicy3ErrorDiv(true)
        } else {
            setPolicy3ErrorDiv(false)
            handleCloseAcceptPolicy3(false)
            setOpenAcceptPolicy4(true)
        }
    }

    const handleAcceptPolicy4 = () => {
        if(!checkPolicy4) {
            setPolicy4ErrorDiv(true)
        } else {
            setPolicy4ErrorDiv(false)
            handleCloseAcceptPolicy4(false)
            setOpenAcceptPolicy5(true)
        }
    }

    const handleAcceptPolicy5 = () => {
        if(!checkPolicy5) {
            setPolicy5ErrorDiv(true)
        } else {
            setPolicy5ErrorDiv(false)
            handleCloseAcceptPolicy5(false)
            setOpenAcceptPolicy6(true)
        }
    }

    const handleAcceptPolicy6 = () => {
        if(!checkPolicy6) {
            setPolicy6ErrorDiv(true)
        } else {
            setPolicy6ErrorDiv(false)
            handleCloseAcceptPolicy6(false)
            setOpenAcceptPolicy7(true)
        }
    }

    const handleAcceptPolicy7 = () => {
        if(!checkPolicy7) {
            setPolicy7ErrorDiv(true)
        } else {
            setPolicy7ErrorDiv(false)
            handleCloseAcceptPolicy7(false)
            setOpenAcceptPolicy8(true)
        }
    }

    const handleAcceptPolicy8 = () => {
        if(!checkPolicy8) {
            setPolicy8ErrorDiv(true)
        } else {
            setPolicy8ErrorDiv(false)
            handleCloseAcceptPolicy8(false)
            handleSubmit()
        }
    }


    const handelGoToRegisPosition = () => {
        setLat(defaultLatLng.lat)
        setLng(defaultLatLng.lng)
        setCenter({lat: defaultLatLng.lat, lng: defaultLatLng.lng})
    }

    const handelGoToCurrentPosition = () => {
        setLat(currentLat)
        setLng(currentLng)
        setCenter({lat: currentLat, lng: currentLng})
    }

    const locationSuccess = (pos) => {
        var crd = pos.coords
        setCurrentLat(crd.latitude)
        setCurrentLng(crd.longitude)
    }

    const locationErrors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
            });
        } else {
          //console.log("Geolocation is not supported by this browser.");
        }
    }, [])

    function isSpecialChar(text) {
        return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/.test(text)
    }

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    function isOnlyNumericDecimal(text) {
        return /^[0-9.\b]+$/.test(text)
    }

    const handleImage1 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture1(file)
            setSrcPicture1(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const handleImage2 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture2(file)
            setSrcPicture2(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const handleImage3 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture3(file)
            setSrcPicture3(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const handleImage4 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture4(file)
            setSrcPicture4(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const handleImage5 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSizeError(true)
        } else {
            setPicture5(file)
            setSrcPicture5(URL.createObjectURL(file))
            setImageSizeError(false)
        }
    }

    const handlePlaceImage1 = (file) => {
        if(file.size > defauleImageSize) {
            setPlaceImageSizeError(true)
        } else {
            setPlacePicture1(file)
            setSrcPlacePicture1(URL.createObjectURL(file))
            setPlaceImageSizeError(false)
        }
    }

    const handlePlaceImage2 = (file) => {
        if(file.size > defauleImageSize) {
            setPlaceImageSizeError(true)
        } else {
            setPlacePicture2(file)
            setSrcPlacePicture2(URL.createObjectURL(file))
            setPlaceImageSizeError(false)
        }
    }

    const handlePlaceImage3 = (file) => {
        if(file.size > defauleImageSize) {
            setPlaceImageSizeError(true)
        } else {
            setPlacePicture3(file)
            setSrcPlacePicture3(URL.createObjectURL(file))
            setPlaceImageSizeError(false)
        }
    }

    const handlePlaceImage4 = (file) => {
        if(file.size > defauleImageSize) {
            setPlaceImageSizeError(true)
        } else {
            setPlacePicture4(file)
            setSrcPlacePicture4(URL.createObjectURL(file))
            setPlaceImageSizeError(false)
        }
    }

    const handlePlaceImage5 = (file) => {
        if(file.size > defauleImageSize) {
            setPlaceImageSizeError(true)
        } else {
            setPlacePicture5(file)
            setSrcPlacePicture5(URL.createObjectURL(file))
            setPlaceImageSizeError(false)
        }
    }

    const validateForm = () => {
        if(useCoinFrom == 1) {
            if(offerUseCoin > offerFreeCoin) {
                setUseCoinErrorDiv(true)
                return false;
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if (useCoinFrom == 2) {
            if(offerUseCoin > offerExchangeCoin) {
                setUseCoinErrorDiv(true)
                return false;
            } else {
                setUseCoinErrorDiv(false)
            }
        }

        if(owner == 2) {
            if(!ownerText || isSpecialChar(ownerText)) {
                setOwnerTextErrorDiv(true)
                return false;
            } else {
                setOwnerTextErrorDiv(false)
            }
        }

        if(!electricTypeId) {
            setTypeIdErrorDiv(true)
            return false
        } else {
            if(electricTypeId == '999') {
                if(!otherType || isSpecialChar(otherType)) {
                    setOtherTypeErrorDiv(true)
                    return false
                } else {
                    setOtherTypeErrorDiv(false)
                }
            } else {
                setOtherTypeErrorDiv(false)
                setTypeIdErrorDiv(false)
            }
        }

        if(!brandId) {
            setBrandIdErrorDiv(true)
            return false
        } else {
            if(brandId == '999') {
                if(!brand) {
                    setBrandErrorDiv(true)
                    return false
                } else {
                    setBrandErrorDiv(false)
                }
            } else {
                setBrandErrorDiv(false)
                setBrandIdErrorDiv(false)
            }
        }

        if(!modelId) {
            setModelIdErrorDiv(true)
            return false
        } else {
            if(modelId == '999') {
                if(!model) {
                    setModelErrorDiv(true)
                    return false
                } else {
                    setModelErrorDiv(false)
                }
            } else {
                setModelErrorDiv(false)
                setModelIdErrorDiv(false)
            }
        }

        const today = new Date()
        const thaiYear = (today.getFullYear()*1) + 543
        if(!buyYear || !isOnlyNumeric(buyYear) || (buyYear.length != 4)) {
            setBuyYearErrorDiv(true)
            return false
        } if(buyYear > thaiYear) {
            setBuyYearMoreErrorDiv(true)
            return false
        } else {
            setBuyYearErrorDiv(false)
            setBuyYearMoreErrorDiv(false)
        }

        if(!picture1 && !picture2 && !picture3 && !picture4 && !picture5) {
            setPictureErrorDiv(true)
            return false
        } else {
            setPictureErrorDiv(false)
        }

        if(!brokenId) {
            setBrokenIdErrorDiv(true)
            return false
        } else {
            if(brokenId == '999') {
                if(!otherBroken || isSpecialChar(otherBroken)) {
                    setOtherBrokenErrorDiv(true)
                    return false
                } else {
                    setOtherBrokenErrorDiv(false)
                    setBrokenIdErrorDiv(false)
                }
            } else {
                setOtherBrokenErrorDiv(false)
                setBrokenIdErrorDiv(false)
            }
        }

        if(fixType == 1 || fixType == 2) {
            if(!placePicture1 && !placePicture2 && !placePicture3 && !placePicture4 && !placePicture5) {
                setPlacePictureErrorDiv(true)
                return false
            } else {
                setPlacePictureErrorDiv(false)
            }
        }

        if(!price || !isOnlyNumericDecimal(price)) {
            setPriceErrorDiv(true)
            return false
        } else {
            setPriceErrorDiv(false)
        }
        
        if(!fixDay || !isOnlyNumeric(price)) {
            setFixDayErrorDiv(true)
            return false;
        } else {
            setFixDayErrorDiv(false)
        }

        if(provinceId == 0) {
            setProvinceIdErrorDiv(true)
            return false;
        } else {
            setProvinceIdErrorDiv(false)
        }

        if(districtId == 0) {
            setDistrictIdErrorDiv(true)
            return false;
        } else {
            setDistrictIdErrorDiv(false)
        }

        if(subdistrictId == 0) {
            setSubDistrictErrorDiv(true)
            return false;
        } else {
            setSubDistrictErrorDiv(false)
        }

        return true
    }

    const handleSubmit = (e) => {
        if(validateForm()) {
            const new_date = new Date()
            setShowProgressBar(true)
            addDoc(collection(db, 'posts'), {
                onboard: 4,
                poster_id: memberId,
                uid: uid,
                owner: owner,
                owner_text: ownerText,
                type_id: electricTypeId,
                other_type: otherType,
                broken_id: brokenId,
                other_broken: otherBroken,
                brand_id: brandId,
                brand: brand,
                model_id: modelId,
                model: model,
                buy_year: buyYear*1,
                description: description,
                lat: lat,
                lng: lng,
                price: price*1,
                fix_day: fixDay*1,
                fix_type: fixType,
                address: address,
                address_no: addressNo,
                road: road,
                subroad: subroad,
                village: village,
                building: building,
                floor: floor,
                room: room,
                sub_district: subdistrictId,
                district: districtId,
                province: provinceId,
                postcode: postcode,
                coin_used: offerUseCoin*1,
                coin_type: useCoinFrom,
                post_timestamp: new_date.getTime(),
                status: 1,
            }).then(function(docRef) {
                var promisesTask = []
                const postId = docRef.id
                var coinType = ''
                var fromCoinOffer = ''
                var message = 'คุณได้ลงลงประกาศแจ้งซ่อมเครื่องใช้ไฟฟ้า'
                var logEvent = "ประชาชนลงประกาศแจ้งซ่อมเครื่องใช้ไฟฟ้า"
                var event = "ลงประกาศแจ้งซ่อมเครื่องใช้ไฟฟ้า"
                if(useCoinFrom == 1) {  
                    coinType = 'free_coins'
                    fromCoinOffer = 'freecoin'
                    var updateUserCoinTask = updateDoc(doc(db, `/users`, memberId), {
                        freecoin: (offerFreeCoin*1)-(offerUseCoin*1)
                    })
                    promisesTask.push(updateUserCoinTask)
                    var addCoinLogTask = addFreeCoinLog(db, offerUseCoin, logEvent, memberId, 'post')
                    promisesTask.push(addCoinLogTask)
                } else {
                    coinType = 'exchange_coins'
                    fromCoinOffer = 'exchange'
                    var updateUserCoinTask = updateDoc(doc(db, `/users`, memberId), {
                        exchange_coin: (offerExchangeCoin*1)-(offerUseCoin*1)
                    })
                    promisesTask.push(updateUserCoinTask)
                    var addCoinLogTask = addExchangeLog(db, offerUseCoin, logEvent, memberId, 'post')
                    promisesTask.push(addCoinLogTask)
                }

                getDoc(doc(db, coinType, 'sum_data')).then((sumDoc) => {
                    updateDoc(doc(db, coinType, 'sum_data'), {
                        remain: (sumDoc.data().remain*1) + (offerUseCoin*1),
                        used: (sumDoc.data().used*1) + (offerUseCoin*1)
                    }).then(() => {
                        var addPlatformCoinLogTask = addPlatformCoinLog(db, offerUseCoin, logEvent, fromCoinOffer, "post", postId, "user", memberId, 'platform', '', true)
                        promisesTask.push(addPlatformCoinLogTask)
                    })
                })

                var link = "/post/"+postId
                var addNotiUserTask = addUserNotification(db, memberId, message, 'post', postId, link)
                promisesTask.push(addNotiUserTask)

                var addUserCoinLogTask = addUserCoinLog(db, memberId, offerUseCoin, event, useCoinFrom, 'post', postId, 'user', 'platform', false)
                promisesTask.push(addUserCoinLogTask)

                Promise.all(promisesTask).then(() => {
                    if(fixType == 1 || fixType == 2) {
                        if (placePicture1 || placePicture2 || placePicture3 || placePicture4 || placePicture5) {
                            const filePlaceUpload = []
                            if(placePicture1) filePlaceUpload.push(placePicture1)
                            if(placePicture2) filePlaceUpload.push(placePicture2)
                            if(placePicture3) filePlaceUpload.push(placePicture3)
                            if(placePicture4) filePlaceUpload.push(placePicture4)
                            if(placePicture5) filePlaceUpload.push(placePicture5)
        
                            const promises = []
                            var i = 1;
                            filePlaceUpload.map((file) => {
                                const storageRef = ref(storage, `/posts/${postId}/place/${i}`)
                                const uploadTask = uploadBytesResumable(storageRef, file)
                                promises.push(uploadTask)
                                uploadTask.on( "state_changed",
                                    () => { },
                                    (error) => console.log(error),
                                    () => {
                                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                            addDoc(collection(db, `/posts/${postId}/place_images`), {
                                                url: downloadURLs,
                                            })
                                        })
                                    }
                                )
                                i++
                            })
                        }
                    }
                }).then(() => {
                    if (picture1 || picture2 || picture3 || picture4 || picture5) {
                        const fileUpload = []
                        if(picture1) fileUpload.push(picture1)
                        if(picture2) fileUpload.push(picture2)
                        if(picture3) fileUpload.push(picture3)
                        if(picture4) fileUpload.push(picture4)
                        if(picture5) fileUpload.push(picture5)
    
                        const promises = []
                        var i = 1;
                        fileUpload.map((file) => {
                            const storageRef = ref(storage, `/posts/${postId}/${i}`)
                            const vari = i
                            const uploadTask = uploadBytesResumable(storageRef, file)
                            promises.push(uploadTask)
                            uploadTask.on( "state_changed",
                                () => { },
                                (error) => console.log(error),
                                () => {
                                    getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                        addDoc(collection(db, `/posts/${postId}/images`), {
                                            url: downloadURLs,
                                        })
                                        if(vari == 1) {
                                            updateDoc(doc(db, 'posts', postId), {
                                                picture: downloadURLs
                                            })
                                        }
                                    })
                                }
                            )
                            i++
                        })
    
                        Promise.all(promises).then(() => {
                            setShowProgressBar(false)
                            navigate("/profile/posts/fix")
                        })
                    } else {
                        setShowProgressBar(false)
                        navigate("/profile/posts/fix")
                    }
                })
            }).catch((error) => {
                console.error("Error adding document: ", error);
            })
        }
    }

    const getDocUser = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user.uid));
            const docs = await getDocs(q);
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMemberId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.lat) setLat(data.lat)
                if(data.lng) setLng(data.lng)
                if(data.freecoin) setOfferFreeCoin(data.freecoin)
                if(data.exchange_coin) setOfferExchangeCoin(data.exchange_coin)

                getDistrict(data.province)
                getSubDistrict(data.district)

                if(data.address) { setAddress(data.address); setAddressNoRegis(data.address) }
                if(data.village) { setVillage(data.village); setVillageRegis(data.village) }
                if(data.building) { setBuilding(data.building); setBuildingRegis(data.building) }
                if(data.floor) { setFloor(data.floor); setFloorRegis(data.floor) }
                if(data.room) { setRoom(data.room); setRoomRegis(data.room) }
                if(data.subroad) { setSubroad(data.subroad); setSubroadRegis(data.subroad); }
                if(data.road) { setRoad(data.road); setRoadRegis(data.road) }
                setProvinceId(data.province)
                setProvinceIdRegis(data.province)
                setDistrictId(data.district)
                setDistrictIdRegis(data.district)
                setSubDistrictId(data.sub_district)
                setSubDistrictIdRegis(data.sub_district)
                setPostcode(data.postcode)
                setPostcodeRegis(data.postcode)
            }
        } catch (err) {
            console.error(err)
            alert(err.message)
        }
    }

    const getCoinRate = () => {
        getDoc(doc(db, 'coins', 'use_rate')).then((exchangeSnapshot) => {
            const useCoin = exchangeSnapshot.data()
            setOfferUseCoin(useCoin.board3_1)
        })
    }
    
    const handleSelectProvince = (provinceId) => {
        setProvinceId(provinceId)
        getDistrict(provinceId)
    }

    const handleSelectDistrict = (districtId) => {
        setDistrictId(districtId)
        getSubDistrict(districtId)
    }

    const handelSelectsubDistrict = (subdistrictId) => {
        setSubDistrictId(subdistrictId)
        setPostcode('')

        getPostcodeBySubDistrict(db, subdistrictId).then((postCodeList) => {
            setPostcode(postCodeList.docs[0].data().postcode)
        })
    }

    const getProvince = () => {
        getProvinceList(db).then((provinceList) => {
            setProvinceList(provinceList.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getDistrict = (provinceId) => {
        setDistrictId(0)
        districtList.splice(0, districtList.length)
        setDistrictList([...districtList])

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDistrictListByProvince(db, provinceId).then((districtList) => {
            setDistrictList(districtList.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getSubDistrict = (districtId) => {
        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')
        getSubDistrictListByDistrict(db, districtId).then((subDistrictList) => {
            setSubDistrictList(subDistrictList.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getUseRegisAddress = () => {
        var promisesTask = []
        getDistrict(provinceIdRegis)
        getSubDistrict(districtIdRegis)
        Promise.all(promisesTask).then(() => {
            setAddressNo(addressNoRegis)
            setVillage(villageRegis)
            setBuilding(buildingRegis)
            setFloor(floorRegis)
            setRoom(roomRegis)
            setSubroad(subroadRegis)
            setRoad(roadRegis)
            setProvinceId(provinceIdRegis)
            setDistrictId(districtIdRegis)
            setSubDistrictId(subdistrictIdRegis)
            setPostcode(postcodeRegis)
        })
    }

    const getElectricType = () => {
        getDocs(query(collection(db, 'electric_type'), orderBy('name', 'asc'))).then((docs) => {
            setElectricType(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handelSelectElectric = (electricTypeId) => {
        setElectricTypeId(electricTypeId)

        getDocs(query(collection(db, 'brands'), where('electric_id', '==', electricTypeId), orderBy('name', 'asc'))).then((docs) => {
            setBrandList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectBrand = (brandId) => {
        setBrandId(brandId)
        getDocs(query(collection(db, 'models'), where('brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data()
                }
            }))
        })
    }

    const getBrokenList = () => {
        getDocs(query(collection(db, 'brokens'), orderBy('name', 'asc'))).then((docs) => {
            setBrokenList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else { 
            getDocUser()
            getCoinRate()
        }
        getBrokenList()
        getElectricType()
        getProvince()
    }, [user, loading])

    return (
        <>
            <Loading open={showProgressBar} text={"กำลังทำการลงประกาศ กรุณารอสักครู่"} />
            <div className='container' style={{ backgroundColor: "#fcfcfc", padding: 20 }}>
                <div className="row gy-3">
                    <div className="col-xs-12 text-center">
                        <h2><strong>แจ้งความต้องการซ่อมเครื่องใช้ไฟฟ้า</strong></h2>
                    </div>
                </div>
                <div className="row gy-3" style={{ marginTop: 20 }}>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        &nbsp;
                    </div>
                    <div className="col-12 col-md-6">
                        <div style={{ display: 'flex', flex: 5, flexDirection: "column", alignItems: 'start' }}>
                            <font style={{ fontSize: 17 }}><strong>จำนวนเหรียญที่ต้องใช้ในการประกาศ {number_format.format(offerUseCoin)} เหรียญ</strong></font>
                            {useCoinErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** จำนวนเหรียญที่ต้องใช้ในการประกาศไม่เพียงพอ</strong>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ตัดเหรียญจาก</strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ตัดเหรียญจาก</strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5 }}>
                            <select name='useCoinFrom' onChange={(e) => setUseCoinFrom(e.target.value)} style={{ marginLeft: 0, paddingLeft: 5 }}>
                                <option value="1">เหรียญแจกฟรี (คุณมี {number_format.format(offerFreeCoin)} เหรียญ)</option>
                                <option value="2">เหรียญแลกเปลี่ยน (คุณมี {number_format.format(offerExchangeCoin)} เหรียญ)</option>
                            </select>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column', marginTop: 10 }}>
                            <select name='owner' onChange={(e) => setOwner(e.target.value)} style={{ fontSize: 17, paddingLeft: 5 }}>
                                <option value="1">เป็นเจ้าของเครื่องใช้ไฟฟ้า</option>
                                <option value="2">ไม่ได้เป็นเจ้าของฯ (โปรดระบุความเป็นเจ้าของ)</option>
                            </select>
                            {owner == 2 && 
                            (<div style={{ display:'flex', flexDirection:'column', textAlign:'left'}}>
                            <input type='text' name='ownerText' onChange={(e) => setOwnerText(e.target.value)} 
                                value={ownerText} placeholder='ระบุความเป็นเจ้าของ'
                                style={{ marginTop: 8, paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                            <font className="text-danger" style={{ marginLeft: 5, marginTop: 5 }}><strong>** ยินยอมรับผิดชอบแทนเจ้าของทุกประการ</strong></font>
                            {ownerTextErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 5, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาระบุความเป็นเจ้าของ และไม่มีอักขระพิเศษ</strong>
                                </div>
                            }
                            </div>)
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ประเภท <font className="text-danger">*</font></strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ประเภท <font className="text-danger">*</font></strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <select name='electric_type' onChange={(e) => { handelSelectElectric(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                    <option value="0">--- กรุณาเลือกประเภท ---</option>
                                    {electricType?.map((type, i) => (
                                    <option value={type.id} key={type.id}>
                                        {type.data.name}
                                    </option>
                                    ))}
                                    <option value="999">อื่นๆ (โปรดระบุ)</option>
                                </select>
                                {typeIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกประเภท</strong>
                                </div>
                                }
                            </div>
                            {electricTypeId=='999' &&
                            (<input type='text' name='other_type' onChange={(e) => setOtherType(e.target.value)} 
                                value={otherType} placeholder='ประเภท เช่น ตู้เย็น, โทรทัศน์, พัดลม หรืออื่นๆ'
                                style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 7 }} />
                            )
                            }
                            {otherTypeErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกประเภท และไม่มีอักขระพิเศษ</strong>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ยี่ห้อ <font className="text-danger">*</font></strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ยี่ห้อ <font className="text-danger">*</font></strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <select name='brand_id' onChange={(e) => { handleSelectBrand(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                    <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                    {brandList?.map((brand, i) => (
                                    <option value={brand.id} key={brand.id}>
                                        {brand.data.name}
                                    </option>
                                    ))}
                                    <option value="999">อื่นๆ (โปรดระบุ)</option>
                                </select>
                                {brandIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกยี่ห้อ</strong>
                                </div>
                                }
                            </div>
                            {brandId =='999' &&
                            (<input type='text' name='other_type' onChange={(e) => setBrand(e.target.value)} 
                                value={brand} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                            )
                            }
                            {brandErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกยี่ห้อ</strong>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>รุ่น <font className="text-danger">*</font></strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>รุ่น <font className="text-danger">*</font></strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <select name='model_id' onChange={(e) => { setModelId(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                    <option value="0">--- กรุณารุ่น ---</option>
                                    {modelList?.map((model, i) => (
                                    <option value={model.id} key={model.id}>
                                        {model.data.name}
                                    </option>
                                    ))}
                                    <option value="999">อื่นๆ (โปรดระบุ)</option>
                                </select>
                                {modelIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกรุ่น</strong>
                                </div>
                                }
                            </div>
                            {modelId =='999' &&
                            (<input type='text' name='other_type' onChange={(e) => setModel(e.target.value)} 
                                value={model} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, fontSize:17, marginTop: 10, marginBottom: 10 }} />
                            )
                            }
                            {modelErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกรุ่น</strong>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ปีที่ซื้อ (พ.ศ.) <font className="text-danger">*</font></strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ปีที่ซื้อ (พ.ศ.) <font className="text-danger">*</font></strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <input type='text' name='buyYear' onChange={(e) => setBuyYear(e.target.value)} 
                                value={buyYear} placeholder='ปีที่ซื้อ' maxLength="4"
                                style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize: 17 }} />
                            {buyYearErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกปี (พ.ศ.) ที่ซื้อ ความยาวเท่่ากับ 4 ตัวอักษร และเป็นตัวเลขเท่านั้น</strong>
                                </div>
                            }
                            {buyYearMoreErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกปีที่ซื้อไม่เกินปีปัจจุบัน</strong>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>รูปภาพ <font className="text-danger">*</font></strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>รูปภาพ <font className="text-danger">*</font></strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPicture1} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileUpload1' style={{ padding: 0}}>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileUpload1" type="file" accept="image/*" onChange={(e) => handleImage1(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPicture2} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileUpload2'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileUpload2" type="file" accept="image/*" onChange={(e) => handleImage2(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPicture3} alt="WEEE" width="80"style={{marginBottom: 5}}  />
                                    <label htmlFor='fileUpload3'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileUpload3" type="file" accept="image/*" onChange={(e) => handleImage3(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPicture4} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileUpload4'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileUpload4" type="file" accept="image/*" onChange={(e) => handleImage4(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPicture5} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileUpload5'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileUpload5" type="file" accept="image/*" onChange={(e) => handleImage5(e.target.files[0])} />
                                </div>
                            </div>
                            {imageSizeError &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                </div>
                            }
                            {pictureErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกรูปภาพ</strong>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>อาการ <font className="text-danger">*</font></strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>อาการ <font className="text-danger">*</font></strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <select name='broken_id' onChange={(e) => { setBrokenId(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                    <option value="0">--- กรุณาเลือกอาการ ---</option>
                                    {brokenList?.map((broken, i) => (
                                    <option value={broken.id} key={broken.id}>
                                        {broken.data.name}
                                    </option>
                                    ))}
                                    <option value="999">อื่นๆ (โปรดระบุ)</option>
                                </select>
                                {brokenIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกอาการ</strong>
                                </div>
                                }
                            </div>
                            {brokenId=='999' &&
                            (<input type='text' name='other_broken' onChange={(e) => setOtherBroken(e.target.value)} 
                                value={otherBroken} placeholder='อาการอื่นๆ (โปรดระบุ)'
                                style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize: 17, marginTop: 10 }} />
                            )
                            }
                            {otherBrokenErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกอาการอื่นๆ และไม่มีอักขระพิเศษ</strong>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>อาการเพิ่มเติม</strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>อาการเพิ่มเติม</strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, width:"100%" }}>
                            <textarea style={{ display: 'flex', flex: 1, width:"100%", height: 100, fontSize: 17, paddingLeft: 10, marginTop: 7 }}
                                onChange={(e) => setDescription(e.target.value)}
                                placeholder='อาการเพิ่มเติม' 
                                value={description}></textarea>
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>รูปแบบการซ่อม</strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>รูปแบบการซ่อม</strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <select name='type' onChange={(e) => setFixType(e.target.value)} style={{ paddingLeft: 8, fontSize: 17 }}>
                                    <option value="1">ให้มาซ่อมในที่พักอาศัย</option>
                                    <option value="2">ต้องการให้มายกไปซ่อมที่ร้าน</option>
                                    <option value="3">ต้องการยกไปที่ร้านเอง</option>
                                </select>
                                {(fixType == 1 || fixType == 2) &&
                                    (
                                        <>
                                            <div className='text-danger' style={{ marginBottom: 10, marginTop: 7, display: 'flex', flexWrap: 'nowrap', textAlign: 'left' }}>
                                                ** ผู้เรียกใช้บริการต้องเตรียมพื้นที่ให้ดำเนินการซ่อม หากผู้ให้บริการพบความเสี่ยงในการเกิดอุบัติเหตุหรือไม่ได้รับความร่วมมือ สามารถปฏิเสธการให้บริการได้ และผู้เรียกใช้บริการต้องจ่ายค่าเดินทางเป็นจำนวนเหรียญตามที่ระบุไว้ในข้อเสนอบริการ โดยไม่มีข้อโต้แย่งใดๆ
                                            </div>
                                        </>
                                    )
                                }
                            </div>
                        </div>
                    </div>
                    {(fixType == 1 || fixType == 2) && (
                    <>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>รูปภาพตำแหน่งที่ตั้งเครื่องใช้ไฟฟ้า </strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>รูปภาพตำแหน่งที่ตั้งเครื่องใช้ไฟฟ้า </strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPlacePicture1} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='filePlaceUpload1' style={{ padding: 0}}>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="filePlaceUpload1" type="file" accept="image/*" onChange={(e) => handlePlaceImage1(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPlacePicture2} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='filePlaceUpload2'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="filePlaceUpload2" type="file" accept="image/*" onChange={(e) => handlePlaceImage2(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPlacePicture3} alt="WEEE" width="80"style={{marginBottom: 5}}  />
                                    <label htmlFor='filePlaceUpload3'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="filePlaceUpload3" type="file" accept="image/*" onChange={(e) => handlePlaceImage3(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPlacePicture4} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='filePlaceUpload4'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="filePlaceUpload4" type="file" accept="image/*" onChange={(e) => handlePlaceImage4(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcPlacePicture5} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='filePlaceUpload5'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="filePlaceUpload5" type="file" accept="image/*" onChange={(e) => handlePlaceImage5(e.target.files[0])} />
                                </div>
                            </div>
                            {placeImageSizeError &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                </div>
                            }
                            {placePictureErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกรูปภาพตำแหน่งที่ตั้งเครื่องใช้ไฟฟ้า</strong>
                                </div>
                            }
                        </div>
                    </div>
                    </>
                    )}
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ตำแหน่งพิกัดที่พักอาศัย</strong></font> 
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                            <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToRegisPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งเริ่มต้น</button>
                            <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToCurrentPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งปัจจุบัน</button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ตำแหน่งพิกัดที่พักอาศัย</strong></font>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                                <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToRegisPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งเริ่มต้น</button>
                                <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToCurrentPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งปัจจุบัน</button>
                            </div>
                        </div>
                        <div style={{ marginBottom: 1, flexDirection: 'column', display: 'flex', flexWrap: 'nowrap', height:400, flex: 5 }}>
                            <div className='text-danger' style={{ marginBottom: 5, display: 'flex', flexWrap: 'nowrap' }}>
                                ** ลาก <FaMapMarkerAlt className='text-danger' style={{ marginLeft: 5, marginRight: 5 }} /> Marker  เพื่อกำหนดตำแหน่ง
                            </div>
                            <div className='text-danger' style={{ marginBottom: 5, display: 'flex', flexWrap: 'nowrap' }}>
                                ** กรุณาระบุพิกัดจริง เพราะมีผลต่อราคาที่เสนอในการซ่อม
                            </div>
                            <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                            <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                <MarkerF
                                    draggable={true} position={center}
                                    onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                />
                            </GoogleMap>
                            )}
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 15 }}><strong>มีงบประมาณการซ่อมไม่เกิน (บาท) <font className="text-danger">*</font></strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 15 }}><strong>มีงบประมาณการซ่อมไม่เกิน (บาท) <font className="text-danger">*</font></strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <input type='text' name='price' onChange={(e) => setPrice(e.target.value)} 
                                value={price} placeholder='งบประมาณการซ่อมไม่เกิน (บาท)'
                                style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize: 17 }} />
                            {priceErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                    <strong>** กรุณากรอกงบประมาณซ่อม และเป็นตัวเลขเท่านั้น</strong>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 15 }}><strong>ต้องการให้ซ่อมไม่เกิน (วัน) <font className="text-danger">*</font></strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 15 }}><strong>ต้องการให้ซ่อมไม่เกิน (วัน) <font className="text-danger">*</font></strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                            <input type='text' name='fixDay' onChange={(e) => setFixDay(e.target.value)} 
                                value={fixDay} placeholder='ต้องการให้ซ่อมไมเ่กิน (วัน)'
                                style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize: 17 }} />
                            {fixDayErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 7, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกจำนวนวันซ่อม และเป็นตัวเลขเท่านั้น</strong>
                                </div>
                            }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ที่อยู่</strong></font>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                            <button style={{ textAlign: 'center' }} className="btn btn-success" type='button' onClick={() => { getUseRegisAddress() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ที่อยู่เริ่มต้น</button>
                        </div>
                    </div><div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ที่อยู่</strong></font>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                                <button style={{ textAlign: 'center' }} className="btn btn-success" type='button' onClick={() => { getUseRegisAddress() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ที่อยู่เริ่มต้น</button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection:'column', flex: 5 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'row', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='addressNo' onChange={(e) => setAddressNo(e.target.value)} 
                                    value={addressNo} placeholder='บ้านเลขที่'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='village' onChange={(e) => setVillage(e.target.value)} 
                                    value={village} placeholder='หมู่บ้าน/สถานที่'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='room' onChange={(e) => setRoom(e.target.value)} 
                                    value={room} placeholder='ห้อง'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='floor' onChange={(e) => setFloor(e.target.value)} 
                                    value={floor} placeholder='ชั้น'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='building' onChange={(e) => setBuilding(e.target.value)} 
                                    value={building} placeholder='อาคาร'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='subroad' onChange={(e) => setSubroad(e.target.value)} 
                                    value={subroad} placeholder='ซอย'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                    value={road} placeholder='ถนน'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7 }} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'column', marginTop: 7 }}>
                                <select name='province' value={provinceId} onChange={(e) => { handleSelectProvince(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17, flex: 1 }}>
                                    <option value="0">--- กรุณาเลือกจังหวัด ---</option>
                                    {provinceList?.map((province, i) => (
                                    <option value={province.data.province_id} key={province.data.province_id}>
                                        {province.data.province_name}
                                    </option>
                                    ))}
                                </select>
                                {provinceIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกจังหวัด</strong>
                                </div>
                                }
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                <select name='district' value={districtId} onChange={(e) => { handleSelectDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17, flex: 1 }}>
                                    <option value="0" key="0">--- กรุณาเลือกอำเภอ ---</option>
                                    {districtList?.map((district, i) => (
                                    <option value={district.data.district_id} key={district.data.district_id}>
                                        {district.data.district_name}
                                    </option>
                                    ))}
                                </select>
                                {districtIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกอำเภอ</strong>
                                </div>
                                }
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                <select name='subdistrict' value={subdistrictId} onChange={(e) => { handelSelectsubDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17, flex: 1 }}>
                                    <option value="0">--- กรุณาเลือกตำบล ---</option>
                                    {subdistrictList?.map((subdistrict, i) => (
                                    <option value={subdistrict.data.subdistrict_id} key={subdistrict.data.subdistrict_id}>
                                        {subdistrict.data.subdistrict_name}
                                    </option>
                                    ))}
                                </select>
                                {subdistrictIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกตำบล</strong>
                                </div>
                                }
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='postcode' readOnly
                                    value={postcode} placeholder='รหัสไปรษณีย์'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize: 17 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ที่อยู่เพิ่มเติม</strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ที่อยู่เพิ่มเติม</strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5 }}>
                            <textarea style={{ display: 'flex', flex: 1, width:"100%", height: 100, fontSize: 17, paddingLeft: 10 }}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder='ที่อยู่เพิ่มเติม' 
                                value={address}></textarea>
                        </div>
                    </div>
                    <div className="col-12 offset-md-4 col-md-6">
                        <div style={{ marginBottom: 1, flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', flex: 5 }}>
                            <button style={{ textAlign: 'center' }} className="btn btn-primary" type='button' onClick={ () => { startAcceptPolicy() } }>บันทึก</button>
                        </div>
                    </div>
                </div>
            </div>


            <Dialog open={openAcceptPolicy1} onClose={handleCloseAcceptPolicy1} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>เนื้อหาที่สร้างโดยผู้ใช้งาน</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. หจก. ออนซอน อนุญาตให้ผู้ใช้งานสามารถสร้างและอัปโหลด (Upload) เนื้อหาต่างๆ (“เนื้อหาที่สร้างโดยผู้ใช้งาน”) ขึ้นมาบนแพลตฟอร์มได้ (“ผู้สร้างเนื้อหา”) ในรูปแบบของประกาศโฆษณา บทความ และข้อคิดเห็นทั่วไป โดยเนื้อหาที่สร้างโดยผู้ใช้งานจะเผยแพร่บนแพลตฟอร์มและเปิดให้ผู้ใช้งานทั่วไปเข้าถึงได้</p>
                            <p>2. ผู้สร้างเนื้อหาเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อความถูกต้อง ความน่าเชื่อถือ ลักษณะ การปลอดจากสิทธิ์ การตรงตามกฎหมาย มาตรฐานทางวิชาชีพ ลิขสิทธิ์ และข้อจำกัดทางกฎหมายที่เกี่ยวข้องกับเนื้อหาที่ตนสร้างอัปโหลด (Upload) หรือทำให้อยู่บนแพลตฟอร์มด้วยวิธีการอื่นใดทั้งหมด อาทิเช่นแต่ไม่จำกัดเพียง ข้อมูล ข้อความ เนื้อหา รูปภาพ ภาพถ่าย กราฟิก เพลง เสียง และแท็ก เป็นต้น อีกทั้งผู้สร้างเนื้อหาต้องปฏิบัติตามเงื่อนไข ในหัวข้อ “ลงประกาศและยื่นข้อเสนอ” อย่างเคร่งครัด</p>
                            <p>3. ท่านรับทราบและเข้าใจว่าแพลตฟอร์มเป็นเพียงช่องทางให้ผู้สร้างเนื้อหาเผยแพร่เนื้อหาที่สร้างโดยผู้ใช้งานเท่านั้น ท่านตกลงว่าท่านได้เข้าถึงเนื้อหาที่สร้างโดยผู้ใช้งานภายใต้ความเสี่ยงของท่านเอง การตัดสินใจใดๆ ของท่านในการเข้าถึงเนื้อหาที่สร้างโดยผู้ใช้งาน หรืออันเป็นผลมาจากการเข้าถึงเนื้อหาที่สร้างโดยผู้ใช้งาน เป็นข้อผูกพันระหว่างท่านและผู้สร้างเนื้อหาทั้งหมด ซึ่งท่านต้องรับผิดชอบแต่เพียงผู้เดียว หจก. ออนซอน ไม่มีส่วนเกี่ยวข้องด้วยแต่อย่างใด</p>
                            <p>4. หจก. ออนซอน จะไม่รับผิดชอบต่อ และจะไม่เฝ้าติดตามหรือตรวจสอบถึงความถูกต้อง ความน่าเชื่อถือ ลักษณะ การปลอดจากสิทธิ์ การตรงตามกฎหมาย มาตรฐานทางวิชาชีพ ลิขสิทธิ์ และข้อจำกัดทางกฎหมายที่เกี่ยวข้องกับเนื้อหาที่สร้างโดยผู้ใช้งาน ท่านจะไม่กำหนดให้ หจก. ออนซอน ต้องรับผิดชอบต่อการดำเนินการหรือไม่ดำเนินการใดๆ ของผู้ใช้งาน</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy1?"checked":""} onChange={(e) => handleCheckPolicy1(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy1ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy1} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy1} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy2} onClose={handleCloseAcceptPolicy2} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>สิทธิ์การใช้งานแบบจำกัดและทรัพย์สินทางปัญญา</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. หจก. ออนซอน มอบสิทธิ์การใช้งานแบบจำกัด และเพิกถอนได้ในการเข้าถึงและใช้บริการแพลตฟอร์มตามเงื่อนไขการใช้งานนี้</p>
                            <p>2. เนื้อหา เครื่องหมายการค้า เครื่องหมายบริการ โลโก้ และทรัพย์สินทางปัญญาอื่นๆ ที่มีกรรมสิทธิ์ทั้งหมดที่แสดงในแพลตฟอร์ม (“ทรัพย์สินทางปัญญา”) ไม่ว่าจะจดทะเบียนหรือไม่จดทะเบียน เป็นทรัพย์สินของ หจก. ออนซอน และหากเหมาะสมเป็นทรัพย์สินของบุคคลภายนอกที่ถือกรรมสิทธิ์ตามที่ระบุไว้ในแพลตฟอร์ม แพลตฟอร์มไม่มีการมอบสิทธิหรือสิทธิการใช้งานทั้งโดยตรงหรือโดยอ้อมแก่บุคคลใดที่เข้าถึงแพลตฟอร์มเพื่อใช้งานหรือลอกเลียนแบบทรัพย์สินทางปัญญาใด และไม่มีบุคคลใดที่เข้าถึงแพลตฟอร์มจะสามารถอ้างสิทธิ กรรมสิทธิ์ หรือผลประโยชน์ในแพลตฟอร์มนี้ได้โดยการใช้หรือเข้าถึงบริการ</p>
                            <p>3. ท่านตกลงที่จะปฏิบัติตามกฎหมายทรัพย์สินทางปัญญา และกฎหมายที่บังคับใช้อื่นๆทั้งหมดที่คุ้มครองบริการแพลตฟอร์มและเนื้อหาในแพลตฟอร์ม ท่านตกลงที่จะไม่คัดลอก แจกจ่าย เผยแพร่ต่อ ส่งต่อ แสดงแก่สาธารณะ ดำเนินการต่อสาธารณะ ดัดแปลง ปรับเปลี่ยน ให้เช่า ขาย หรือสร้างงานต่อเนื่องจากส่วนหนึ่งส่วนใดของแพลตฟอร์ม และเนื้อหาและบริการในแพลตฟอร์ม โดยไม่ได้รับคำยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจาก หจก. ออนซอน (คำยินยอมดังกล่าวจะถือว่ามอบให้สำหรับเทคโนโลยีเครื่องมือค้นหามาตรฐานที่ใช้โดยแพลตฟอร์มค้นหาทางอินเทอร์เน็ตเพื่อนำผู้ใช้อินเทอร์เน็ตมายังแพลตฟอร์มนี้)</p>
                            <p>4. ท่านตกลงจะไม่นำเนื้อหา เครื่องหมายการค้า เครื่องหมายบริการ โลโก้ รูปภาพ และทรัพย์สินทางปัญญาอื่น ที่เป็นของบุคคล นิติบุคล อื่นที่มิใช้ของท่านเข้ามาบันทึกหรืออ้างอิงในแพลตฟอร์ม หากท่านดำเนินการละเมิดทรัพย์สินทางปัญญาทั้งตั้งใจและไม่ตั้งใจ หากตรวจพบหรือมีการร้องเรียนถือเป็นอันยินยอมที่ท่านจะรับผิดชอบค่าเสียหายและยินยอมให้ดำเนินคดีตามกฎหมายและชดใช้ค่าใช้จ่ายทุกอย่างที่เกิดขึ้นทั้งระหว่างและสิ้นสุดการดำเนินคดี โดยไม่มีข้อโต้แย้งใดๆ</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy2?"checked":""} onChange={(e) => handleCheckPolicy2(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy2ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy2} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy2} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy3} onClose={handleCloseAcceptPolicy3} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ลงประกาศและยื่นข้อเสนอ</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. หากท่านเป็นผู้สร้างเนื้อหาหรือลงประกาศหรือยื่นข้อเสนอ ท่านยืนยันและรับรองว่าท่านเป็นเจ้าของ หรือได้รับสิทธิ ความยินยอม และการอนุญาตที่จำเป็นในการเผยแพร่ซึ่งเนื้อหาที่สร้างโดยผู้ใช้งานที่ท่านสร้าง อัปโหลด (Upload) หรือทำให้อยู่บนแพลตฟอร์มด้วยวิธีการอื่นใดทั้งหมด (“เนื้อหาที่ท่านส่งมา”) หจก. ออนซอน ไม่อนุญาตให้มีกิจกรรมที่เป็นการละเมิดสิทธิทางทรัพย์สินทางปัญญาใดๆ บนแพลตฟอร์ม หจก. ออนซอน สงวนสิทธิ์ในการลบเนื้อหาใดๆ หากมีการแจ้งอย่างถูกต้องว่าเนื้อหาดังกล่าวละเมิดสิทธิทางทรัพย์สินทางปัญญาของผู้อื่นตามกฎหมายที่เกี่ยวข้องกับสิทธิทางทรัพย์สินทางปัญญา</p>
                            <p>2. เนื้อหาที่ท่านส่งมา จะต้องไม่เป็น “เนื้อหาที่ต้องห้าม” ดังต่อไปนี้</p>
                            <p style={{ marginLeft: 15 }}>
                                1) ห้ามเนื้อหาที่เป็นเท็จ ไม่ถูกต้อง หลอกลวง ฉ้อฉล สร้างความหลงผิด สร้างความเข้าใจผิด หรือชี้นำไปในทางที่ผิด<br/>
                                2) ห้ามเนื้อหาที่ผิดกฎหมาย ผิดกฎระเบียบและ/หรือข้อบังคับ ผิดมาตรฐานทางวิชาชีพบัญชี ผิดจรรยาบรรณ หยาบคาย หมิ่นประมาท ทำให้เสื่อมเสีย หรือขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดี<br/>
                                3) ห้ามเนื้อหาที่มีความรุนแรง เช่น เนื้อหาดูหมิ่น เนื้อหาที่เกี่ยวข้องกับการใช้ความรุนแรง โหดร้าย การเปลือยกาย เพศสัมพันธ์ วิปริตทางเพศ ศิลปะลามก การลดคุณค่าทางเพศ การชักชวนค้าประเวณี ภาษาที่รุนแรง การกล่าวถึงโดยหยาบคายหรือถ้อยคำรังเกียจ หรือเนื้อหาใดที่ไม่เหมาะสม อนาจาร ไม่ถูกต้องหรือมีลักษณะที่รุนแรง โดยไม่คำนึงว่ามีเจตนาที่มุ่งร้ายหรือมีเจตนาที่จะก่อความรำคาญ ทำร้าย ข่มขู่หรือรบกวนบุคคลใด<br/>
                                4) ห้ามเนื้อหาที่เกี่ยวข้องกับการเมือง มีความรุนแรง โค่นล้มหรือปลุกระดม เช่น เนื้อหาที่กระตุ้นหรือสนับสนุนความขัดแย้งต่อประเทศหรือรัฐใด หรือ รัฐธรรมนูญ รัฐบาลหรือกฎหมายของประเทศนั้น หรือกระตุ้นหรือสนับสนุนความขัดแย้งระหว่างกลุ่ม เชื้อชาติ ชาติพันธุ์ ศาสนาหรือเพศหรือการทำให้ศาสนาหรือความเชื่อทางวัฒนธรรมเสื่อมเสีย<br/>
                                5) ห้ามเนื้อหาที่เป็นการสนับสนุนการก่อการร้ายหรือกิจกรรมที่เกี่ยวข้องกับการก่อการร้าย<br/>
                                6) ห้ามเนื้อหาที่เกี่ยวข้องกับการพนันและยาเสพติด<br/>
                                7) ห้ามเนื้อหาซึ่งไม่เหมาะสมสำหรับผู้ใช้ที่มีอายุ 20 ปีหรือต่ำกว่า<br/>
                                8) ห้ามเนื้อหาที่เกี่ยวข้องหรือสนับสนุนเนื้อหาที่ต้องห้ามหรือถูกสั่งห้ามตามกฎหมายใด ๆ<br/>
                                9) ห้ามเนื้อหาที่เกี่ยวข้องกับการแสดงตัว เลียนแบบ หรือปลอมแปลงเป็นบุคคลอื่นใด หรือแสดงข้อมูลเท็จเกี่ยวกับการเชื่อมโยงกับบุคคลอื่น<br/>
                                10) หน้าเนื้อหาที่มีไวรัสซอฟต์แวร์ หนอน ม้าโทรจัน หรือรหัสคอมพิวเตอร์ รูทีน ไฟล์หรือโปรแกรมที่ออกแบบมาเพื่อแทรกแซงทั้งโดยตรงและโดยอ้อมต่อ ปรับเปลี่ยน ขัดขวางทำลาย หรือจำกัดฟังก์ชันการทำงานของซอฟต์แวร์คอมพิวเตอร์หรือฮาร์ดแวร์ใดๆ หรือข้อมูลหรืออุปกรณ์โทรคมนาคม ใช้งานได้
                            </p>
                            <p>3. ผู้สร้างเนื้อหามีสิทธิความเป็นเจ้าของของท่านทั้งหมดในเนื้อหาที่ท่านส่งมา อย่างไรก็ตาม ผู้สร้างเนื้อหาตกลงว่าท่านอนุญาตให้ หจก. ออนซอน ได้สิทธิในการใช้ ทำซ้ำ ดัดแปลง เผยแพร่ แจกจ่าย แสดงแก่สาธารณะ ดำเนินการต่อสาธารณะ สร้างสรรค์งานต่อเนื่อง และ/หรือดำเนินการที่เกี่ยวกับบริการและธุรกิจของ หจก. ออนซอน ไม่ว่าในรูปแบบสื่อใด ไม่ว่าผ่านช่องทางสื่อใด และไม่ว่าบางส่วนหรือทั้งหมด ทั้งนี้ สิทธิดังกล่าวใช้บังคับได้ตลอดกาล ทั่วโลก เพิกถอนไม่ได้ ไม่ผูกขาด ไม่มีค่าใช้จ่าย และสามารถทำการอนุญาตช่วงและโอนการอนุญาตต่อได้</p>
                            <p>4. ผู้สร้างเนื้อหารับทราบและตกลงว่าเนื้อหาที่ท่านส่งมาไม่ถือว่าเป็นความลับโดย หจก. ออนซอน และจะถูกเผยแพร่หรือใช้โดย หจก. ออนซอน โดยปราศจากการชดเชยหรือการรับผิดต่อท่าน ไม่ว่าด้วยจุดประสงค์ใดก็ตาม ท่านอนุญาตให้ผู้ใช้งานแต่ละรายของแพลตฟอร์มเข้าถึงเนื้อหาที่ท่านส่งมาผ่านแพลตฟอร์ม อย่างไรก็ตามข้อกำหนดนี้ไม่ใช้บังคับกับข้อมูลส่วนบุคคลที่ต้องอยู่ภายใต้นโยบายการคุ้มครองข้อมูลส่วนบุคคลของแพลตฟอร์ม เว้นแต่ในกรณีที่ท่านเองเป็นผู้เปิดเผยและ/หรือทำให้ข้อมูลส่วนบุคคลของท่านใช้งานได้ เข้าถึงได้ และ/หรือแสดงแก่สาธารณะบนแพลตฟอร์ม</p>
                            <p>5. การอนุญาต ยินยอม ยืนยันของท่านข้างต้นนั้นเป็นการถาวรและไม่สามารถเพิกถอนได้</p>
                            <p>6. หจก. ออนซอน ไม่รับรองหรือมีหน้าที่ใดๆ ต่อเนื้อหาที่ท่านส่งมา หรือต่อการใช้บริการโดยผู้ใช้งานคนใด หรือต่อความเห็น ข้อแนะนำ หรือข้อคิดเห็นใดที่แสดงอยู่บนแพลตฟอร์ม หจก. ออนซอน ปฏิเสธอย่างชัดแจ้งซึ่งความรับผิดทั้งหมดที่เกี่ยวข้องกับเนื้อหาที่ท่านส่งมาและเนื้อหาที่สร้างโดยผู้ใช้งานทั้งหมด</p>
                            <p>7. หจก. ออนซอน สงวนสิทธิ์ในการลบเนื้อหาใดๆ หรือระงับไม่ให้บุคคลใดๆเข้าถึงเนื้อหาใดๆ โดยไม่แจ้งท่านล่วงหน้าหรือไม่ให้เหตุผลใด และท่านตกลงว่า หจก. ออนซอน จะไม่ต้องรับผิดสำหรับความสูญเสียหรือความเสียหายที่เกิดขึ้นไม่ว่าโดยทางใดที่เป็นผลมาจากการดำเนินการตามดุลพินิจของ หจก. ออนซอน ในการลบเนื้อหาใด</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy3?"checked":""} onChange={(e) => handleCheckPolicy3(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy3ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy3} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy3} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy4} onClose={handleCloseAcceptPolicy4} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>เงื่อนไขการลงประกาศ</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. “ประกาศ” คือพื้นที่บนแพลตฟอร์มซึ่ง หจก. ออนซอน ได้จัดให้มีขึ้น เพื่อให้ผู้ใช้ระบบได้ใช้เพื่อโฆษณาประชาสัมพันธ์บริการหรือโชว์ผลงานหรือขายสินค้า</p>
                            <p>2. ผู้ลงประกาศต้องปฏิบัติตามเงื่อนไขการลงประกาศดังต่อไปนี้โดยเคร่งครัด</p>
                            <p style={{ marginLeft: 15 }}>
                            1) เฉพาะผู้ใช้งานที่มีบัญชีผู้ใช้งาน (“สมาชิก”) เท่านั้นจึงจะสามารถลงประกาศได้<br/>
                            2) ผู้ลงประกาศต้องไม่ใช้ข้อมูลที่เป็นเท็จ หลอกลวง ฉ้อฉล คลุมเครือ สร้างหรืออาจก่อให้เกิดความเสียหายให้แก่บุคคลอื่น ในการลงประกาศบริการบัญชีของตน<br/>
                            3) ในการลงประกาศ ผู้ลงประกาศจะต้องเลือกประเภทประกาศให้ถูกต้อง โดยเนื้อหาในประกาศจะต้องตรงกับประเภทประกาศที่เลือกเท่านั้น และผู้ลงประกาศจะต้องกรอกข้อมูลในแบบฟอร์มการลงประกาศให้สอดคล้องกับหัวข้อที่กำหนดไว้ในแบบฟอร์มการลงประกาศเท่านั้น เพื่อไม่รบกวนสิทธิของสมาชิกท่านอื่น หาก หจก. ออนซอน พบว่าสมาชิกท่านใดลงประกาศซ้ำเดิม หจก. ออนซอน สงวนสิทธิในการลบประกาศ และ/หรือระงับการใช้งานบัญชีผู้ใช้งานของสมาชิกท่านนั้น โดยไม่ต้องแจ้งล่วงหน้าแต่อย่างใด
                            </p>
                            <p>3. ข้อห้ามในการลงประกาศ</p>
                            <p style={{ marginLeft: 15 }}>
                            1) ห้ามลงประกาศด้วยบริการหรือข้อมูลใดที่ไม่ใช่บริการที่มีในแพลตฟอร์ม<br/>
                            2) ห้ามลงประกาศด้วยเนื้อหาในประกาศที่ไม่สอดคล้องกับประเภทประกาศที่เลือก<br/>
                            3) ห้ามลงประกาศด้วยบริการเดิมซ้ำมากกว่า 1 ประกาศ<br/>
                            4) ห้ามใช้บัญชีผู้ใช้งานหลายบัญชีในการลงประกาศบริการเดียวกัน<br/>
                            5) ห้ามนำเข้า เสนอข้อมูล เผยแพร่ข้อมูล หรือกระทำการใดบนเว็บไซต์ซึ่งจะเป็นการผิดกฎหมาย ผิดศีลธรรมและวัฒนธรรมอันดี ก่อให้เกิดความขัดแย้ง ก่อให้เกิดความสูญเสียหรือเสียหายใดๆ หรือเป็นเนื้อหาต้องห้าม หากมีการร้องเรียนหรือฟ้องร้องถือเป็นความรับผิดชอบของผู้ลงประกาศ
                            </p>
                            <p>4. ผู้ใช้ระบบที่ลงประกาศรับทราบและตกลงว่า “เนื้อหาที่ใช้ในการลงประกาศ” ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะ ข้อมูล ข้อความตัวอักษร ข้อความใดๆ รูปภาพ และรูปถ่าย จะไม่เป็นความลับ จะถูกเผยแพร่และพร้อมสำหรับการรับชมแบบสาธารณะ</p>
                            <p>5. ผู้ใช้ระบบที่ลงประกาศต้องปฏิบัติตามเงื่อนไขในหัวข้อเนื้อหาที่ท่านส่งมา และอื่นๆทั้งหมดในเงื่อนไขการใช้งานฉบับนี้</p>
                            <p>6. ผู้ใช้ระบบที่ลงประกาศเป็นผู้รับผิดชอบต่อความรับผิดตามมาตรฐานทางวิชาชีพบัญชี ตามจรรยาบรรณของผู้ประกอบวิชาชีพนั้นๆ และตามกฎหมายใดๆ อันเนื่องมาจากเนื้อหาที่ใช้ในการลงประกาศ</p>
                            <p>7. ผู้ใช้ระบบที่ลงประกาศรับผิดชอบและยืนยันว่าเนื้อหาที่ใช้ในการลงประกาศเป็นของตน ผู้ซึ่งมีกรรมสิทธิ์ และสิทธิอื่น ๆ ที่สมบูรณ์และได้รับความคุมครองทางกฎหมาย หรือตนได้รับการอนุญาตให้ใช้ที่ถูกต้องตามกฎหมาย ผู้ลงประกาศต้องรับผิดชอบแต่เพียงฝ่ายเดียวในกรณีที่มีการเรียกร้องความเสียหายที่เกี่ยวข้องกับเนื้อหาที่ใช้ในการลงประกาศโดยบุคคลอื่นที่มีสิทธิ์ที่เหนือกว่าผู้ลงประกาศ</p>
                            <p>8. ผู้ลงประกาศเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อความถูกต้อง ความน่าเชื่อถือ ลักษณะ การปลอดจากสิทธิ์ การตรงตามกฎหมายและมาตรฐานทางวิชาชีพ ที่เกี่ยวข้องกับเนื้อหาที่ใช้ในการลงประกาศ หจก. ออนซอน จะไม่รับผิดชอบต่อ ไม่รับรอง และจะไม่เฝ้าติดตามหรือตรวจสอบถึงความถูกต้อง ความน่าเชื่อถือ ลักษณะ การปลอดจากสิทธิ์ การตรงตามกฎหมายและมาตรฐานทางวิชาชีพ ผู้ลงประกาศจะไม่กำหนดให้ หจก. ออนซอน ต้องรับผิดชอบต่อการดำเนินการหรือไม่ดำเนินการของผู้ใช้งานอื่นๆ อันเนื่องมาจากประกาศของผู้ลงประกาศ</p>
                            <p>9. หจก. ออนซอน สงวนสิทธิในการลบประกาศ ระงับการแสดงประกาศ หรือแก้ไขเปลี่ยนแปลงข้อมูลในประกาศ รวมทั้งปิดกั้นการเข้าสู่ระบบและระงับบัญชีผู้ใช้งานของผู้ลงประกาศ ซึ่งถือเป็นสิทธิขาดและขึ้นอยู่กับดุลพินิจของ หจก. ออนซอน แต่เพียงผู้เดียวโดยไม่ต้องบอกกล่าวแก่ผู้ลงประกาศเป็นการล่วงหน้า นอกจากนี้ หจก. ออนซอน ไม่มีภาระผูกพันหรือความรับผิดชอบต่อผลหรือต่อบุคคลใดๆ จากการดำเนินการนี้</p>
                            <p>10. การลงข้อความ</p>
                            <p style={{ marginLeft: 15 }}>
                            1) ผู้ลงประกาศจะต้องให้ข้อมูลเกี่ยวกับบริการบัญชีของตน อย่างถูกต้อง เป็นจริง มีรายละเอียดครบถ้วน ถูกต้องตามกฎหมายที่ใช้บังคับและตามที่ขึ้นทะเบียนไว้กับหน่วยงานกำกับดูแล (ถ้ามี) และจะต้องเป็นไปตามมาตรฐานทางวิชาชีพและจรรยาบรรณของผู้ประกอบวิชาชีพ<br/>
                            2) ข้อความที่ใช้ลงประกาศจะต้องเป็นข้อความที่เหมาะสม สุภาพ ไม่ผิดมาตรฐานทางวิชาชีพและจรรยาบรรณของผู้ประกอบวิชาชีพ ไม่ผิดกฎหมาย ไม่เป็นเนื้อหาต้องห้าม ไม่ก่อให้เกิดความสูญเสียหรือเสียหายใดๆ ไม่ประกอบด้วยลิงค์ไปยังภายนอกแพลตฟอร์ม และไม่ประกอบด้วยข้อมูลติดต่อ
                            </p>
                            <p>11. รูปภาพที่ใช้ในการลงประกาศจะต้องเป็นรูปที่เหมาะสม สุภาพ ไม่ผิดมาตรฐานทางวิชาชีพและจรรยาบรรณของผู้ประกอบวิชาชีพบัญชี ไม่ผิดกฎหมาย ไม่เป็นเนื้อหาต้องห้าม และจะต้องมีความชัดเจน เหมาะสม และมีความเกี่ยวข้องกับบริการบัญชีที่นำเสนอเท่านั้น</p>
                            <p>12. ราคาที่ระบุในประกาศต้องเป็นราคาซึ่งรวมภาษีและค่าธรรมเนียมต่างๆ แล้ว และต้องเป็นราคาที่ผู้ลงประกาศในฐานะผู้ประกอบวิชาชีพบัญชีพิจารณาแล้วเห็นว่าไม่ขัดต่อจรรยาบรรณของผู้ประกอบวิชาชีพบัญชี หจก. ออนซอน ไม่มีส่วนร่วม ส่วนรู้เห็น ส่วนเกี่ยวข้องใด หรืออิทธิพลใดต่อการตั้งราคาของผู้ลงประกาศข้อมูลติดต่อ</p>
                            <p>13. ผู้ลงประกาศต้องให้ข้อมูลการติดต่อ เช่น เบอร์โทรศัพท์ อีเมล์ ไลน์ไอดี เป็นต้น ภายในพื้นที่ที่แพลตฟอร์มได้จัดเตรียมไว้เท่านั้น โดยจะต้องเป็นข้อมูลติดต่อของผู้ลงประกาศที่ถูกต้อง เป็นจริง เป็นปัจจุบัน และสามารถติดต่อได้</p>
                            <p>14. เงื่อนไขการอัพเกรดประกาศ</p>
                            <p style={{ marginLeft: 15 }}>
                            1) “การอัพเกรดประกาศ” คือ การซื้อสิทธิพิเศษในการแสดงประกาศบนหน้าต่างๆ ของแพลตฟอร์ม ตามเงื่อนไขของแต่ละแพ็คเกจที่ตกลงซื้อ ผู้ลงประกาศมีสิทธิอัพเกรดประกาศได้ และผู้ใช้สิทธิอัพเกรดประกาศ (“ผู้ซื้อ”) จะต้องตกลงที่จะผูกพันกับเงื่อนไขการอัพเกรดประกาศนี้ หากท่านไม่ยอมรับที่จะผูกพันกับเงื่อนไขการอัพเกรดประกาศนี้ กรุณาอย่าทำการอัพเกรดประกาศ<br/>
                            2) ผู้ซื้อสามารถอัพเกรดประกาศได้เองผ่านหน้าบัญชีสมาชิก โดยผู้ซื้อเข้าใจและยอมรับว่า หจก. ออนซอน ไม่รับรองหรือรับประกันว่าจำนวนผู้เข้าชมของประกาศของผู้ซื้อจะเพิ่มขึ้นแต่อย่างใดจากการอัพเกรดประกาศ ผู้ซื้อควรอัพเกรดประกาศภายหลังจากที่ได้พิจารณางบประมาณและวัตถุประสงค์ของตนแล้วเท่านั้น หจก. ออนซอน จะไม่รับผิดต่อค่าชดเชยหรือความรับผิดใดๆ (รวมถึงแต่ไม่จำกัดเพียง ค่าใช้จ่ายและผลกำไรที่สูญเสียไปตามจริง) สำหรับผลลัพธ์หรือผลที่ตั้งใจไว้กับการอัพเกรดประกาศ<br/>
                            3) การอัพเกรดประกาศจะสิ้นสุดลงเมื่อครบระยะเวลาที่ผู้ซื้อเป็นผู้กำหนด (“ระยะเวลาการอัพเกรดประกาศ”) ทั้งนี้ผู้ซื้อไม่สามารถโอนระยะเวลาการอัพเกรดประกาศที่เหลือของประกาศหนึ่งไปใช้กับประกาศอื่นได้ อีกทั้งผู้ซื้อไม่สามารถยกเลิกคำสั่งซื้อและ/หรือร้องขอค่าธรรมเนียมคืนภายหลังจากที่ผู้ซื้อได้อัพเกรดประกาศและได้ชำระเงินเรียบร้อยแล้ว<br/>
                            4) ประกาศที่ลงประกาศบนแพลตฟอร์มจะต้องอยู่ภายใต้กฎหมายและกฎเกณฑ์ต่างๆ ที่เกี่ยวข้อง และเงื่อนไขการใช้งานนี้ ผู้ซื้อเข้าใจและตกลงให้ หจก. ออนซอน สิทธิในการลบประกาศออกได้ทันทีหากมีการละเมิดข้อกำหนดดังกล่าว และค่าธรรมเนียมการอัพเกรดประกาศที่ผู้ซื้อได้ชำระแล้วของประกาศที่ถูกลบออก ผู้ซื้อจะไม่ได้รับคืนแต่อย่างใด
                            </p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy4?"checked":""} onChange={(e) => handleCheckPolicy4(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy4ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy4} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy4} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy5} onClose={handleCloseAcceptPolicy5} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ข้อจำกัดความรับผิด</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. แพลตฟอร์มให้บริการในหลักเกณฑ์ “ตามสภาพ (as is)” และ “ตามที่มีอยู่หรือเป็นอยู่ (as available)” ไม่มีการรับรองหรือรับประกันอย่างใดๆ ไม่ว่าโดยปริยาย โดยชัดแจ้ง หรือโดยบทบัญญัติใดๆ</p>
                            <p>2. เนื้อหา ข้อมูล สาระ บทความ ความคิดเห็น และการเชื่อมต่ออื่นใดที่จัดสรรขึ้นหรือแสดงบนแพลตฟอร์มนี้ ไม่ได้รับการสำรวจ การตรวจสอบ หรือการรับรองโดย หจก. ออนซอน แต่อย่างใดทั้งสิ้น</p>
                            <p>3. ท่านรับทราบและยอมรับว่าความเสี่ยงทั้งหมดที่เกิดขึ้นจากการใช้งานหรือการเข้าถึงแพลตฟอร์มนี้ตกเป็นของท่าน</p>
                            <p>4. หจก. ออนซอน ไม่มีความรับผิดในความเสียหายใดๆ ซึ่งรวมถึงแต่ไม่เป็นการจำกัดเพียง ความเสียหายทางตรง ความเสียหายทางอ้อม ความเสียหายพิเศษ ความเสียหายโดยบังเอิญ ความเสียหายเกี่ยวเนื่อง ความสูญหาย หรือค่าใช้จ่ายใดๆที่เกิดขึ้นอันเกี่ยวเนื่องกับการไม่สามารถดำเนินการ ข้อผิดพลาด การงดเว้นการกระทำ การสอดแทรก การบกพร่อง ความล่าช้าในการดำเนินการหรือการถ่ายโอนข้อมูล ไวรัสคอมพิวเตอร์ หรือการไม่ทำงานของเครือข่ายหรือระบบ ทั้งนี้ ถึงแม้ว่า หจก. ออนซอน หรือตัวแทนของ หจก. ออนซอน จะได้รับแจ้งถึงความเป็นได้ในการเกิดความเสียหาย ความสูญหาย หรือค่าใช้จ่ายต่างๆดังกล่าวแล้วก็ตาม</p>
                            <p>5. หจก. ออนซอน ไม่รับประกันในความความปลอดภัยในข้อมูลใดๆ ที่ส่งโดยท่านหรือส่งไปยังท่าน ไม่ว่าจะผ่านแพลตฟอร์มหรือสื่อใดๆ และท่านยอมรับความเสี่ยงว่าข้อมูลใดๆ ที่ส่งหรือได้รับผ่านแพลตฟอร์มหรือสื่อใดๆ อาจจะถูกเข้าถึงโดยบุคคลภายนอกที่ไม่ได้รับอนุญาตได้ และ/หรือเปิดเผยโดย หจก. ออนซอน หรือตัวแทนของ หจก. ออนซอน ให้แก่บุคคลภายนอกที่อ้างว่าเป็นท่านหรืออ้างว่ากระทำการโดยได้รับมอบอำนาจจากท่าน การส่งข้อมูลทางอินเทอร์เน็ตและไปรษณีย์อิเล็กทรอนิกส์อาจจะถูกขัดขวาง ขาดการติดต่อ ล่าช้า จากความแออัดในการส่งข้อมูลทางอินเทอร์เน็ตหรือมีการส่งข้อมูลไม่ถูกต้องเนื่องจากลักษณะของอินเทอร์เน็ตที่อาจถูกเปิดเผยต่อสาธารณะได้</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy5?"checked":""} onChange={(e) => handleCheckPolicy5(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy5ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy5} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy5} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy6} onClose={handleCloseAcceptPolicy6} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>การชดใช้ค่าเสียหาย</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>ท่านตกลงที่จะชดใช้ ปกป้อง และทำให้ หจก. ออนซอน พ้นจากการเรียกร้องสิทธิ การดำเนินการ การดำเนินคดี และการฟ้องร้องคดี รวมถึงหนี้สิน ความเสียหาย การชำระหนี้ บทลงโทษ ค่าปรับ ค่าใช้จ่ายและต้นทุนที่เกี่ยวข้องทั้งหมด (รวมถึงแต่ไม่จำกัดเพียง ค่าใช้จ่ายในการยุติข้อพิพาทอื่นๆ) ที่เกิดแก่ หจก. ออนซอน อันเกิดจากการใช้งานหรือการเข้าถึงแพลตฟอร์มโดยท่าน หรือจากการละเมิดซึ่งเงื่อนไขการใช้งานฉบับนี้โดยท่าน</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy6?"checked":""} onChange={(e) => handleCheckPolicy6(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy6ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy6} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy6} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy7} onClose={handleCloseAcceptPolicy7} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ข้อห้ามการใช้บริการ และการบอกเลิกสัญญา</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. ท่านจะต้องไม่ใช้แพลตฟอร์มเพื่อวัตถุประสงค์ใดๆ ซึ่งเป็นสิ่งที่ผิดกฎหมายหรือต้องห้ามตามเงื่อนไขการใช้งานนี้ ท่านจะไม่ใช้แพลตฟอร์มในลักษณะใดๆ ซึ่งสามารถทำให้เกิดความเสียหายหรือทำให้ไร้ซึ่งความสามารถแก่แพลตฟอร์มหรือเครือข่ายที่เชื่อมต่อกับแพลตฟอร์ม หรือทำให้เกิดการรบกวนการใช้งานแพลตฟอร์มของบุคคลอื่นใด ท่านจะต้องไม่พยายามเข้าสู่แพลตฟอร์ม หรือระบบคอมพิวเตอร์หรือเครือข่ายที่เชื่อมต่อกับแพลตฟอร์ม โดยปราศจากอำนาจ ไม่ว่าด้วยวิธีการเจาะฐานข้อมูล (hacking) ทำลายรหัสลับส่วนตัว (password mining) หรือวิธีการอื่นใด</p>
                            <p>2. หจก. ออนซอน สงวนสิทธิที่จะใช้ดุลพินิจแต่เพียงผู้เดียวในการบอกเลิกสัญญานี้ ในการยกเลิกการให้เข้าสู่หรือใช้งานแพลตฟอร์มไม่ว่าบางส่วนหรือทั้งหมด และในการยกเลิกหรือระงับบัญชีผู้ใช้งาน ทั้งนี้การบอกเลิกหรือยกเลิกดังกล่าว หจก. ออนซอน สามารถกระทำเมื่อใดก็ได้ โดยไม่ต้องแจ้งสาเหตุ และไม่ต้องมีการแจ้งถึงการบอกเลิกหรือยกเลิก</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy7?"checked":""} onChange={(e) => handleCheckPolicy7(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy7ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy7} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy7} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy8} onClose={handleCloseAcceptPolicy8} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>การรายงานการละเมิดสิทธิ์ในทรัพย์สินทางปัญญา</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. ผู้ใช้งานเป็นปัจเจกบุคคลหรือธุรกิจที่เป็นอิสระและไม่เกี่ยวข้องกับ หจก. ออนซอน ไม่ว่าในทางใด หจก. ออนซอน  มิใช่ตัวแทนหรือผู้แทนของผู้ใช้งาน หจก. ออนซอน มิได้ครอบครองและ/หรือเป็นเจ้าของบริการบัญชีใดๆ ที่ลงประกาศไว้บนแพลตฟอร์ม</p>
                            <p>2. หากท่านเป็นเจ้าของสิทธิในทรัพย์สินทางปัญญาและท่านเชื่อว่าสิทธิของท่านได้ถูกละเมิด โปรดแจ้งให้ หจก. ออนซอน ทราบเป็นลายลักษณ์อักษรโดยส่งทางอีเมลมาที่ โปรดให้เวลา หจก. ออนซอน ประมวลข้อมูลและ หจก. ออนซอน จะตอบข้อร้องเรียนของท่านโดยเร็วที่สุดเท่าที่สามารถดำเนินการได้</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy8?"checked":""} onChange={(e) => handleCheckPolicy8(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy8ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy8} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy8} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddPostFix