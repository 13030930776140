import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, doc, getDoc, onSnapshot, updateDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useNavigate, useParams } from "react-router-dom"
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';
import { Card, CardContent, Typography, TextField } from '@mui/material';

import { FaMapMarkerAlt } from 'react-icons/fa'
import { TbUser, TbTool, TbEdit, TbForbid2, TbCircleCheck, TbPhoto, TbHome, TbAlertCircle, TbClock, TbMessageCircle2, TbSend } from "react-icons/tb"
import { AiFillShop, AiFillInfoCircle } from "react-icons/ai"
import { RiBankCardLine } from 'react-icons/ri'
import { IoFolderOpenOutline } from 'react-icons/io5'

const Shop = () => {
    const params = useParams()
    const shopId = params.id
    const [shop, setShop] = useState([])
    const [images, setImages] = useState([])
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState()
    const [shopStatus, setShopStatus] = useState(1)
    const navigate = useNavigate()

    const [lat, setLat] = useState(15.11745249400088);
    const [lng, setLng] = useState(104.90284046686465)

    const [openAlertApprove, setOpenAlertApprove] = useState(false)
    const [openAlertDisable, setOpenAlertDisable] = useState(false)
    const [openAlertCancle, setOpenAlertCancle] = useState(false)

    const [openSendMessage, setOpenSendMessage] = useState(false)
    const [message, setMessage] = useState('')

    const [loading, error] = useAuthState(auth)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    });

    const approveStatus = () => {
        updateDoc(doc(db, 'shops', shopId), {
            status: 2
        }).then(() => {
            navigate(0)
        })
    }

    const handleCloseAlertApprove = () => {
        setOpenAlertApprove(false)
    }

    const disableStatus = () => {
        updateDoc(doc(db, 'shops', shopId), {
            status: 3
        }).then(() => {
            navigate(0)
        })
    }

    const handleCloseAlertDisable = () => {
        setOpenAlertDisable(false)
    }

    const cancleStatus = () => {
        updateDoc(doc(db, 'shops', shopId), {
            status: 1
        }).then(() => {
            navigate(0)
        })
    }

    const handleCloseAlertCancle = () => {
        setOpenAlertCancle(false)
    }

    const handleSendMessage = () => {
        // collection -> shops -> shopid -> chats -> doc_id = (Admin, user_id)
        // fields -> chat_with (Admin, user_id)
        // collection -> messages
        // 
    }

    const handleCloseSendMessage = () => {
        setOpenSendMessage(false)
    }

    const getDocShop = (id) => {
        getDoc(doc(db, 'shops', id)).then((shopSnapshot) => {
            setShop(shopSnapshot.data())
            if(shopSnapshot.data().lat) setLat(shopSnapshot.data().lat)
            if(shopSnapshot.data().lng) setLng(shopSnapshot.data().lng)
            setShopStatus(shopSnapshot.data().status)
            if(shopSnapshot.data().user_id) {
                setMemberId(shopSnapshot.data().user_id)
                getDoc(doc(db, 'users', shopSnapshot.data().user_id)).then((memberDoc) => {
                    setMember(memberDoc.data())
                })
            }
        }).then(() => {
            const imagesSnapshot = query(collection(db, `shops/${shopId}/images`))
            onSnapshot(imagesSnapshot, (querySnapshot) => {
                setImages(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        })
    }

    useEffect(() => {
        getDocShop(shopId)
    },[loading])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>ข้อมูลร้านค้า</b></h4>
                    {(member) && (
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/member/view/${memberId}`) }}>
                            <TbUser style={{ fontSize: 20, marginRight: 5 }} />ข้อมูลผู้ใช้
                        </button>
                    </div>
                    )}
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shoptech/${shopId}`) }}>
                            <TbTool style={{ fontSize: 20, marginRight: 5 }} />ข้อมูลช่าง
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/offers/${shopId}`) }}>
                            <TbSend style={{ fontSize: 20, marginRight: 5 }} />ข้อมูลข้อเสนอ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenSendMessage(true) }}>
                            <TbMessageCircle2 style={{ fontSize: 20, marginRight: 5 }} />ส่งข้อความ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/edit/${shopId}`) }}>
                            <TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขข้อมูล
                        </button>
                    </div>
                    {shopStatus==1 && 
                    (<div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertApprove(true) }}>
                            <TbCircleCheck style={{ fontSize: 20, marginRight: 5 }} />อนุมัติการใช้งาน
                        </button>
                    </div>)}
                    {(shopStatus==1 || shopStatus==2) && 
                    (<div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDisable(true) }}>
                            <TbForbid2 style={{ fontSize: 20, marginRight: 5 }} />ระงับร้านค้า
                        </button>
                    </div>)}
                    {shopStatus==3 && 
                    (<div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertCancle(true) }}>
                            <TbAlertCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิกระงับการใช้งาน
                        </button>
                    </div>)
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>สถานะ</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shopStatus==1&&(<><font><TbClock style={{ fontSize: 20, marginRight: 10, marginTop: -5 }} />รอการอนุมัติ</font></>)}
                                {shopStatus==2&&(<><font className='text-success'><TbCircleCheck style={{ fontSize: 20, marginRight: 10, marginTop: -5 }} />อนุมัติ</font></>)}
                                {shopStatus==3&&(<><font className='text-danger'><TbAlertCircle style={{ fontSize: 20, marginRight: 10, marginTop: -5 }} />ระงับการใช้งาน</font></>)}
                            </div>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ผู้ใช้ </strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    {(member) && (
                                    <>
                                        <font style={{ marginRight: 10}}>{member.firstname?member.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                        <font style={{ marginRight: 10}}>{member.lastname?member.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                        <font style={{ marginRight: 10}}>({member.display_name?member.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font>
                                        <a href={`/admin/member/view/${memberId}`}><AiFillInfoCircle className='text-primary' style={{ fontSize: 25 }} /></a>
                                    </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>email </strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    {(member) && (
                                    <><font style={{ marginRight: 10}}>{member.email?member.email:""}</font></>
                                    )}
                                </div>
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ประเภทร้านค้า</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {(shop.type==1)&&(<div><TbUser style={{ fontSize: 20, marginRight: 5 }} />บุคคลธรรมดา</div>)}
                                {(shop.type==2)&&(<div><AiFillShop style={{ fontSize: 20, marginRight: 5 }} />นิติบุคคล</div>)}
                            </div>
                        </div> 
                        {(shop.type == 2) && (
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อร้านค้า</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.shopname?shop.shopname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        )}
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อ (เจ้าของ)</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.firstname?shop.firstname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>นามสกุล (เจ้าของ)</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.lastname?shop.lastname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>เบอร์โทรศัพท์</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.tel_no?shop.tel_no:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div>
                        <div style={{ paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                            <h6><RiBankCardLine style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>บัญชีธนาคาร</b></h6>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 170}}><strong>ธนาคาร</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.bank_name?shop.bank_name:(<font className="text-danger">ไม่ระบุ</font>)}
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 170}}><strong>หมายเลขบัญชี</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.bank_account_no?shop.bank_account_no:(<font className="text-danger">ไม่ระบุ</font>)}
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 170}}><strong>สำเนาหน้าสมุดบัญชี</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    { (shop.book_bank) &&
                                        <a href={shop.book_bank} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                    }
                                </div>
                            </div> 
                        </div>
                        <div style={{ paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                            <h6><TbPhoto style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>รูปภาพหน้าร้านค้า</b></h6>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column' }}>
                            <div className="mb-3 row" style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display:'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor:'#ffffff', marginBottom: 10, paddingTop:10, paddingBottom: 10, textAlign: 'left' }}>
                                {
                                    images?.map((image, i) => (
                                        <div key={image.id} style={{ margin: 10, minWidth: 100, maxWidth: 150}}>
                                            <a href={image.data.url} target='_blank'><img src={image.data.url} alt="WEEE" className='img-fluid' /></a>
                                        </div>
                                    ))
                                }
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3, borderLeft: '1px solid #cccccc', marginLeft: 30, paddingLeft: 20}}>
                        <div style={{ marginBottom: 10, marginTop: 5, borderBottom: '1px solid #cccccc' }}>
                            <h6><TbHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อมูลที่อยู่</b></h6>
                        </div>
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>บ้านเลขที่, หมู่บ้าน, อาคาร</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.address?shop.address:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ถนน</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.road?shop.road:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ตำบล</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.sub_district?shop.sub_district:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>อำเภอ</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.district?shop.district:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>จังหวัด</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.province?shop.province:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>รหัสไปรษณีย์</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.postcode?shop.postcode:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                            <h6><FaMapMarkerAlt style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} />
                            <b>ตำแหน่งพิกัดร้านค้า</b></h6>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="mb-3 row" style={{ display: 'flex', height:250, flexDirection: 'column' }}>
                                <div style={{  display: 'flex', flexWrap: 'nowrap', height:250, flex: 1 }}>
                                    {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                    <GoogleMap mapContainerClassName="map-container" center={{ lat: lat, lng: lng }} zoom={17} >
                                        <MarkerF position={{ lat: lat, lng: lng }}/>
                                    </GoogleMap>
                                    )}
                                </div>
                            </div>
                        </div> 
                        <div style={{ marginBottom: 10, paddingTop: 13, marginTop: 5, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                            <h6><IoFolderOpenOutline style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <strong>เอกสารประกอบการสมัคร</strong></h6>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 220}}><strong>สำเนาบัตรประชาชน</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                { (shop.id_card) &&
                                    <a href={shop.id_card} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                }
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 220}}><strong>ใบประกอบวิชาชีพ</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                { (shop.support_file_1) &&
                                    <a href={shop.support_file_1} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                }
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 220}}><strong>วุฒิการศึกษา</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                { (shop.support_file_2) &&
                                    <a href={shop.support_file_2} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                }
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 220}}><strong>ประกาศนียบัตร</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                { (shop.support_file_3) &&
                                    <a href={shop.support_file_3} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                }
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 220}}><strong>หนังสือรับรอง</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                { (shop.support_file_4) &&
                                    <a href={shop.support_file_4} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                }
                                </div>
                            </div> 
                            {(shop.type == 2) && (
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 220}}><strong>สำเนาใบสำคัญการจดทะเบียน</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                { (shop.support_file_5) &&
                                    <a href={shop.support_file_5} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                }
                                </div>
                            </div>)}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={openAlertApprove} onClose={handleCloseAlertApprove} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการอนุมัติการใช้งานร้านค้านี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertApprove} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { approveStatus() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertDisable} onClose={handleCloseAlertDisable} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการระงับการใช้งานร้านค้านี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDisable} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { disableStatus() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertCancle} onClose={handleCloseAlertCancle} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการยกเลิกระงับการใช้งานร้านค้านี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertCancle} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { cancleStatus() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
            <Dialog open={openSendMessage} onClose={handleCloseSendMessage}>
                <Card variant="outlined">
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ส่งข้อความ
                        </Typography>
                        <TextField
                            autoFocus
                            margin="dense"
                            label="ข้อความ"
                            fullWidth
                            variant="standard"
                            value={message}
                            onChange={(e) => setMessage(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                            multiline
                            rows={4}
                        />
                    </CardContent>
                    <DialogActions>
                        <button onClick={handleCloseSendMessage} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                        <button onClick={handleSendMessage} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                    </DialogActions>
                </Card>
            </Dialog>
        </div>
    )

}

export default Shop;