import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, updateDoc, doc, getDoc, addDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { createUserWithEmailAndPassword } from "firebase/auth"
import { useNavigate, useParams } from "react-router-dom";
import { TbUser } from "react-icons/tb"
import { IoCloseCircle, IoCheckmarkCircleSharp } from "react-icons/io5"

const AddAdmin = () => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);

    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [confirmPassword, setconfirmPassword] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [telNo, setTelNo] = useState('')
    const [picture, setPicture] = useState('');
    const inputRef = useRef(null);

    const [emailFormatErrorDiv, setEmailFormatErrorDiv] = useState(false)
    const [existEmailErrorDiv, setExistEmailErrorDiv] = useState(false)
    const [passwordErrorDiv, setPasswordErrorDiv] = useState(false)
    const [displayNameErrorDiv, setDisplayNameErrorDiv] = useState(false)
    const [firstnameErrorDiv, setFirstnameErrorDiv] = useState(false)
    const [lastnameErrorDiv, setLastnameErrorDiv] = useState(false)
    const [telNoErrorDiv, setTelNoErrorDiv] = useState(false)

    const handleSubmit = async (e) => {
    	e.preventDefault()
        if(validateForm())
		try {
            var userQuery = await getDocs(query(collection(db, 'admins'), where('email', '==', email)))
            if(userQuery.docs.length == 0) {
                const currentUser = user
                setExistEmailErrorDiv(false)
                const res = await createUserWithEmailAndPassword(auth, email, password);
                const uid = res.user.uid;
                await addDoc(collection(db, "admins"), {
                    uid: uid,
                    authProvider: "admin",
                    email: email,
                    password: password,
                    display_name: displayName,
                    firstname: firstname,
                    lastname: lastname,
                    tel_no: telNo
                }).then((docRef) => {
                    if (picture) {
                        const storageRef = ref(storage, `/admins/${docRef.id}/image/idcard/${picture.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, picture);
                        uploadTask.on(
                        "state_changed",
                        (snapshot) => { },
                        (err) => console.log(err),
                        () => { 
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                updateDoc(doc(db, 'admins', docRef.id), {
                                    id_card: url,
                                }).then(() => {
                                    auth.updateCurrentUser(currentUser)
                                    navigate(`/admin/users`)
                                })
                            });
                        })
                    } else {
                        auth.updateCurrentUser(currentUser)
                        navigate(`/admin/users`)
                    }
                });
            } else {
                setExistEmailErrorDiv(true)
            }
		} catch (err) {
			alert(err)
		}
    }

    const validateForm = () => {
        if(!email) {
            setEmailFormatErrorDiv(true)
            return false;
        } else {
            setEmailFormatErrorDiv(false)
        }

        if(!isValidEmail(email)) {
            setEmailFormatErrorDiv(true)
            return false;
        } else {
            setEmailFormatErrorDiv(false)
        }

        if(!password) {
            setPasswordErrorDiv(true)
            return false;
        } else {
            setPasswordErrorDiv(false)
        }
        if(!displayName) {
            setDisplayNameErrorDiv(true)
            return false;
        } else {
            setDisplayNameErrorDiv(false)
        }
        if(!firstname) {
            setFirstnameErrorDiv(true)
            return false;
        } else {
            setFirstnameErrorDiv(false)
        }
        if(!lastname) {
            setLastnameErrorDiv(true)
            return false;
        } else {
            setLastnameErrorDiv(false)
        }
        if(!telNo) {
            setTelNoErrorDiv(true)
            return false;
        } else {
            setTelNoErrorDiv(false)
        }
        
        return true
    }

    function isValidEmail(email) {
        return /\S+@\S+\.\S+/.test(email);
    }

    useEffect(() => {
        
    },[loading])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><TbUser style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>เพิ่มข้อมูลผู้ดูแลระบบ</b></h4>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                            <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/members/`) }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, flex: 4 }}>
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>Email <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='email' onChange={(e) => setEmail(e.target.value)} 
                                    value={email} placeholder='Email'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                {emailFormatErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอก Email ให้ถูกต้อง</strong>
                                    </div>
                                }
                                {existEmailErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** มีการใช้งาน Email นี้ในระบบแล้ว</strong>
                                    </div>
                                }
                            </div>
                        </div> <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>Password <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='password' name='password' onChange={(e) => setPassword(e.target.value)} 
                                    value={password} placeholder='password'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                {passwordErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอก Password</strong>
                                    </div>
                                }
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อผู้ใช้ <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='displayName' onChange={(e) => setDisplayName(e.target.value)} 
                                    value={displayName} placeholder='ชื่อผู้ใช้'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                {displayNameErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกชื่อผู้ใช้</strong>
                                    </div>
                                }
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อ <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='firstname' onChange={(e) => setFirstname(e.target.value)} 
                                    value={firstname} placeholder='ชื่อ'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                {firstnameErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกชื่อ</strong>
                                    </div>
                                }
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>นามสกุล <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='lastname' onChange={(e) => setLastname(e.target.value)} 
                                    value={lastname} placeholder='นามสกุล'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                {lastnameErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกนามสกุล</strong>
                                    </div>
                                }
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>เบอร์โทรศัพท์ <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='tel_no' onChange={(e) => setTelNo(e.target.value)} 
                                    value={telNo} placeholder='เบอร์โทรศัพท์'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                {telNoErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกเบอร์โทรศัพท์</strong>
                                    </div>
                                }
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>สำเนาบัตรประชาชน</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                            <input ref={inputRef} className="form-control file" id="idcard_image" type="file" accept="image/*" onChange={(e) => setPicture(e.target.files[0])} />
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddAdmin;