import React from 'react';
import ReactDOM from 'react-dom/client';
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import './index.css';
import App from './App';
import { GoogleOAuthProvider } from '@react-oauth/google'
import { render } from "react-dom"

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <GoogleOAuthProvider clientId="614133577445-hc05p2fj37v7g4fp9akbtm0hmg6q23f7.apps.googleusercontent.com">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>
);

/* render(
  <GoogleOAuthProvider clientId="614133577445-hc05p2fj37v7g4fp9akbtm0hmg6q23f7.apps.googleusercontent.com">
    <React.StrictMode>
      <App />
    </React.StrictMode>
  </GoogleOAuthProvider>,
  document.getElementById("root")
); */
