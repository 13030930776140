import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, onSnapshot, addDoc, updateDoc, doc, orderBy, setDoc } from 'firebase/firestore'
import { useParams, useNavigate, Link } from "react-router-dom"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";

import { AiOutlineCheckCircle, AiOutlinePlusCircle, AiFillCheckCircle } from "react-icons/ai"
import { IoMegaphoneOutline, IoHammerOutline } from "react-icons/io5"
import { ImCoinDollar } from "react-icons/im"
import { MdOutlineCalendarToday } from "react-icons/md"
import { RiHandCoinLine } from "react-icons/ri"
import { FaRegThumbsUp, FaRegHandshake } from "react-icons/fa"

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import { StarRating } from '../helper/StarHelper'
import './Post.css'

import { getBrand, getModel } from '../helper/BrandHelper'
import { getBroken } from '../helper/BrokenHelper'
import { getElectric } from '../helper/ElectricTypeHelper'
import { Loading } from "../layouts/Loading"
import { defaultLatLng, googleMapsApiKey, locationOptions } from "../../../assets/constants/locations"

const Post = () => {
    const params = useParams()
    const postId = params.id
    const navigate = useNavigate()
    const today = dayjs()
    const number_format = new Intl.NumberFormat()

    const onMobile = window.innerWidth < 920
    var imagePostHeight = 190
    var thumpnailWidth = 100
    var h1FontSize = 42
    var h2FontSize = 24
    var h3FontSize = 18
    var textFontSize = 16

    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        imagePostHeight = 100
        thumpnailWidth = 60
        h1FontSize = 32
        h2FontSize = 20
        h3FontSize = 18
        textFontSize = 16
    } else if(window.innerWidth < 576) {
        imagePostHeight = 100
        thumpnailWidth = 40
        h1FontSize = 24
        h2FontSize = 18
        h3FontSize = 14
        textFontSize = 14
    }

    const [width, setWidth] = useState(100)
    const [height, setHeight] = useState(imagePostHeight)

    const [user, loading, error] = useAuthState(auth)
    const [memberId, setMemberId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [shopId, setShopId] = useState(0)
    const [offered, setOffered] = useState(false)
    const [offer, setOffer] = useState('')
    const [onBoard, setOnBoard] = useState(1)
    const [postView, setPostView] = useState(0)
    const [status, setStatus] = useState(1)

    const [brandName, setBrandName] = useState('')
    const [modelName, setModelName] = useState('')
    const [brokenName, setBrokenName] = useState('')
    const [type, setType] = useState('')

    const [posterId, setPosterId] = useState('')
    const [posterName, setPosterName] = useState('')
    const [posterScore, setPosterScore] = useState(-1)
    const [numPost, setNumPost] = useState(0)

    const [fixPrice, setFixPrice] = useState(0)
    const [fixType, setFixType] = useState(0)

    const [pickDate, setPickDate] = useState(new Date())
    const [pickHour, setPickHour] = useState('09')
    const [pickMinute, setPickMinute] = useState('00')
    const [offerPrice, setOfferPrice] = useState(0)
    const [fromCoinOffer, setFromCoinOffer] = useState('freecoin')

    const [offerBuy, setOfferBuy] = useState(0)
    const [offerFix, setOfferFix] = useState(1)
    const [offerFixDay, setOfferFixDay] = useState(0)
    const [offerGaruntee, setOfferGaruntee] = useState(0)
    const [offerAgreement, setOfferAgreement] = useState(1)
    const [offerTravel, setOfferTravel] = useState(1)
    const [offerTravelPrice, setOfferTravelPrice] = useState(0)
    const [offerAfterFix, setOfferAfterFix] = useState(1)
    
    const [post, setPost] = useState([])
    const [images, setImages] = useState([])
    const [showImage, setShowImages] = useState('')
    const [lat, setLat] = useState(15.11745249400088)
    const [lng, setLng] = useState(104.90284046686465)

    const [address, setAddress] = useState('')
    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [subdistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [postcode, setPostcode] = useState('')

    const [question, setQuestion] = useState('')
    const [questions, setQuestions] = useState([])
    const [ownerQuestions, setOwnerQuestion] = useState([])
    const [answer, setAnswer] = useState('')

    const [offerUseCoin, setOfferUseCoin] = useState(0)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)

    const [openAcceptPostShop, setOpenAcceptPostShop] = useState(false)

    const [priceErrorDiv, setPriceErrorDiv] = useState(false)
    const [offerFixDayErrorDiv, setOfferFixDayErrorDiv] = useState(false)
    const [offerGarunteeErrorDiv, setOfferGarunteeErrorDiv] = useState(false)
    const [questionErrorDiv, setQuestionErrorDiv] = useState(false)
    const [answerErrorDiv, setAnswerErrorDiv] = useState(false)


    const [showLoading, setShowLoading] = useState(true)
    const [showSaving, setShowSaving] = useState(false)

    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey });
    const center = { lat: lat, lng: lng }

    const dateFormat = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const datePickerFormat = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("en-CA"):"")
    }

    const dateTimeFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH")
        const time_string = new Date(datetime).toLocaleTimeString("th-TH")
        return date_string+' '+time_string
    }

    const handleCloseAcceptPostShop = () => {
        setOpenAcceptPostShop(false)
    }

    const handlePickCoin = (event) => {
        setFromCoinOffer(event.target.value);
    };

    const validateAnswerForm = () => {
        if(!answer) {
            setAnswerErrorDiv(true)
            return false
        } else {
            setAnswerErrorDiv(false)
        }
        return true
    }

    const handleAnswerSubmit = (memberId) => {
        if(validateAnswerForm()) {
            setShowSaving(true)
            const new_date = new Date()
            setDoc(doc(db, `/posts/${postId}/questions/`, memberId), {
                last_update: new_date.getTime()
            }).then(() => {
                addDoc(collection(db, `/posts/${postId}/questions/${memberId}/messages`), {
                    message: answer,
                    message_datetime: new_date.getTime(),
                    from_user: 0,
                    status: 1
                }).then(() => {
                    setAnswer('')
                    setShowSaving(false)
                    getQuestionsOwner(postId)
                })
            })
        }
    }

    const validateQuestionForm = () => {
        if(!question) {
            setQuestionErrorDiv(true)
            return false
        } else {
            setQuestionErrorDiv(false)
        }
        return true
    }

    const handleQuestionSubmit = () => {
        if(validateQuestionForm()) {
            setShowSaving(true)
            const new_date = new Date()
            setDoc(doc(db, `/posts/${postId}/questions/`, memberId), {
                last_update: new_date.getTime()
            }).then(() => {
                addDoc(collection(db, `/posts/${postId}/questions/${memberId}/messages`), {
                    message: question,
                    message_datetime: new_date.getTime(),
                    from_user: 1,
                    status: 1
                }).then(() => {
                    setQuestion('')
                    setShowSaving(false)
                    getQuestionsUser(postId, memberId)
                })
            })
        }
    }

    const getImages = async (id) => {
        const imagesQuery = query(collection(db, `posts/${id}/images`))
        onSnapshot(imagesQuery, (querySnapshot) => {
            setImages(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const getQuestionsUser = async (id, memberId) => {
        const questionsQuery = query(collection(db, `posts/${id}/questions/${memberId}/messages`), orderBy('message_datetime'))
        onSnapshot(questionsQuery, (querySnapshot) => {
            setQuestions(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const getQuestionsOwner = async (id) => {
        ownerQuestions.splice(0, ownerQuestions.length)
        setOwnerQuestion([...ownerQuestions])
        const questionsQuery = query(collection(db, `posts/${id}/questions/`))
        onSnapshot(questionsQuery, (querySnapshot) => {
            querySnapshot.docs.forEach(async(user) => {
                const userSnapshot = await getDoc(doc(db, 'users', user.id))
                const messageSnapshot = await getDocs(query(collection(db, `posts/${id}/questions/${user.id}/messages`), orderBy('message_datetime', 'asc')))
                ownerQuestions.push({
                    user_id: userSnapshot.id,
                    user: userSnapshot.data(),
                    messages: messageSnapshot.docs
                })
                setOwnerQuestion([...ownerQuestions], {
                    user_id: userSnapshot.id,
                    user: userSnapshot.data(),
                    messages: messageSnapshot.docs
                })
            })
        })
    }

    const getPost = (id) => {
        getDoc(doc(db, 'posts', id)).then((postDoc) => {
            if (postDoc.exists()) {
                setPost(postDoc.data())
                setShowImages(postDoc.data().picture)
                setLat(postDoc.data().lat)
                setLng(postDoc.data().lng)
                setOnBoard(postDoc.data().onboard)
                getImages(id)
                setStatus(postDoc.data().status)
                
                getBrand(db, postDoc.data().brand_id, postDoc.data().brand).then((brand) => {
                    setBrandName(brand)
                })
                getModel(db, postDoc.data().model_id, postDoc.data().model).then((model) => {
                    setModelName(model)
                })
                getElectric(db, postDoc.data().type_id, postDoc.data().other_type).then((typeName) => {
                    setType(typeName)
                })
                getBroken(db, postDoc.data().broken_id, postDoc.data().broken_other).then((broken) => {
                    setBrokenName(broken)
                })
                getDoc(doc(db, `users/`, postDoc.data().poster_id)).then((posterDoc) => {
                    const dataPoster = posterDoc.data()
                    if(dataPoster.display_name) setPosterName(dataPoster.display_name)
                    getDocs(query(collection(db, 'posts'), 
                            where('poster_id', '==', postDoc.data().poster_id),
                            where('status', 'not-in', [5, 6])
                    )).then((numPosts) => {
                        var num = 0
                        num = num + numPosts.docs.length
                        getDocs(query(collection(db, 'cleans'), 
                            where('poster_id', '==', postDoc.data().poster_id),
                            where('status', 'not-in', [5, 6])
                        )).then((numCleans) => {
                            num = num + numCleans.docs.length
                            setNumPost(num)
                        })
                    })
                    if(dataPoster.score) { 
                        setPosterScore(dataPoster.score) 
                    } else {
                        setPosterScore(0)
                    }
                })
                setPosterId(postDoc.data().poster_id)
                setAddress(postDoc.data().address)
                setAddressNo(postDoc.data().address_no)
                setVillage(postDoc.data().village)
                setBuilding(postDoc.data().building)
                setFloor(postDoc.data().floor)
                setRoom(postDoc.data().room)
                setSubroad(postDoc.data().subroad)
                setRoad(postDoc.data().road)

                setFixPrice(postDoc.data().price)
                setFixType(postDoc.data().fix_type)

                var promisesTask = []

                if(postDoc.data().province) {
                    const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', postDoc.data().province * 1))).then((docs) => {
                        if(docs.docs.length > 0) setProvince(docs.docs[0].data().province_name)
                    })
                    promisesTask.push(provinceTask)
                }

                if(postDoc.data().district) {
                    const districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', postDoc.data().district))).then((docs) => {
                        if(docs.docs.length > 0) setDistrict(docs.docs[0].data().district_name)
                    })
                    promisesTask.push(districtTask)
                }

                if(postDoc.data().sub_district) {
                    const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', postDoc.data().sub_district * 1))).then((docs) => {
                        if(docs.docs.length > 0) setSubDistrict(docs.docs[0].data().subdistrict_name)
                    })
                    promisesTask.push(subdistrictTask)
                }

                setPostcode(postDoc.data().postcode)

                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                })

                if(postDoc.data().views) {
                    setPostView((postDoc.data().views*1) + 1)
                    updateDoc(doc(db, `/posts`, postId), {
                        views: (postDoc.data().views*1) + 1
                    })
                } else {
                    setPostView(1)
                    updateDoc(doc(db, `/posts`, postId), {
                        views: 1
                    })
                }

            } else {
                console.log("Post doesn't exist")
                navigate('/')
            }
            setShowLoading(false)
            return postDoc;
        }).then((postDoc) => {
            getDoc(doc(db, 'coins', 'use_rate')).then((exchangeSnapshot) => {
                const useCoin = exchangeSnapshot.data()
                setOfferUseCoin(useCoin.board4_2)
            })
        })
    }

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMemberId(docs.docs[0].id)
                if(data.is_shop) setIsShop(data.is_shop)
                if(data.shop_id) {
                    setShopId(data.shop_id)
                    getDoc(doc(db, `shops/`, data.shop_id)).then((shopDoc) => {
                        const dataShop = shopDoc.data()
                        if(dataShop.freecoin) setOfferFreeCoin(dataShop.freecoin)
                        if(dataShop.exchange_coin) setOfferExchangeCoin(dataShop.exchange_coin)
                    })
                    getDocs(query(collection(db, `posts/${postId}/offers`), where("shop_id", "==", data.shop_id))).then((offers) => {
                        if (offers.docs.length > 0) {
                            const dataOffer = offers.docs[0].data()
                            setOffered(true)
                            setOffer(dataOffer)
                        }
                    }).catch((error) => {
                        alert(error.message)
                    })
                }
                if(post.poster_id != docs.docs[0].id) {
                    getQuestionsUser(postId, docs.docs[0].id)
                }
            }
            setShowLoading(false)
        }).catch((error) => {
            alert(error.message)
        })
    }

    const validateOfferForm = () => {
        if(!offerPrice) {
            setPriceErrorDiv(true)
            return false
        } else {
            setPriceErrorDiv(false)
        }
        if(!offerFixDay) {
            setOfferFixDayErrorDiv(true)
            return false
        } else {
            setOfferFixDayErrorDiv(false)
        }
        if(!offerGaruntee) {
            setOfferGarunteeErrorDiv(true)
            return false
        } else {
            setOfferGarunteeErrorDiv(false)
        }
            
        return true
    }
    
    const handleOfferSubmit = () => {
        setShowSaving(true)
        if(validateOfferForm()) {
            const new_date = new Date()
            addDoc(collection(db, `/posts/${postId}/offers`), {
                shop_id: shopId,
                pick_date: pickDate.toDate(),
                hour: pickHour,
                minute: pickMinute,
                offer_price: offerPrice,
                offer_buy: offerBuy,
                offer_fix: offerFix,
                offer_fix_day: offerFixDay,
                offer_garuntee: offerGaruntee,
                offer_agreement: offerAgreement,
                offer_travel: offerTravel,
                offer_travel_price: offerTravelPrice,
                offer_after_fix: offerAfterFix,
                offer_timestamp: new_date.getTime(),
                status: 1
            }).then(function(docRef) {
                updateDoc(doc(db, `/posts`, postId), { status: 2 })
                addDoc(collection(db, `/shops/${shopId}/offers`), {
                    post_id: postId,
                    offer_id: docRef.id,
                    pick_date: pickDate.toDate(),
                    hour: pickHour,
                    minute: pickMinute,
                    offer_price: offerPrice,
                    offer_buy: offerBuy,
                    offer_fix: offerFix,
                    offer_fix_day: offerFixDay,
                    offer_garuntee: offerGaruntee,
                    offer_agreement: offerAgreement,
                    offer_travel: offerTravel,
                    offer_travel_price: offerTravelPrice,
                    offer_after_fix: offerAfterFix,
                    offer_timestamp: new_date.getTime(),
                    status: 1
                }).then(() => {
                    const log_date = new Date()
                    addDoc(collection(db, `/shops/${shopId}/coin_log`), {
                        date: log_date,
                        coin: offerUseCoin,
                        event: 'ยื่นข้อเสนอรับซ่อมเครื่องใช้ไฟฟ้า',
                        post_id: postId,
                        coin_type: fromCoinOffer
                    }).then(() => {
                        if(fromCoinOffer == 'freecoin') {
                            updateDoc(doc(db, `/shops`, shopId), {
                                freecoin: (offerFreeCoin*1)-(offerUseCoin*1)
                            })
                        } else if(fromCoinOffer == 'exchange') {
                            updateDoc(doc(db, `/shops`, shopId), {
                                exchange_coin: (offerExchangeCoin*1)-(offerUseCoin*1)
                            })
                        }
                        const date = new Date()
                        var link = "/profile/posts/"
                        if(post.onboard == 2) {
                            link = link+"garbage"
                        } else if(post.onboard == 3) {
                            link = link+"second"
                        }
                        addDoc(collection(db, `/users/${post.poster_id}/notifications`), {
                            date: date.getTime(),
                            message: "มีข้อเสนอใหม่ในประกาศ "+post.type+" "+post.brand+" "+post.model+" ชองคุณ",
                            link: link,
                            type: 'post',
                            post_id: postId,
                            status: 0
                        })
                    }).then(() => {
                        console.log(fromCoinOffer)
                        if(fromCoinOffer == 'freecoin') {
                            getDoc(doc(db, 'free_coins', 'sum_data')).then((sumDoc) => {
                                updateDoc(doc(db, 'free_coins', 'sum_data'), {
                                    used: (sumDoc.data().used*1) + (offerUseCoin*1)
                                }).then(() => {
                                    const log_date = new Date()
                                    addDoc(collection(db, `/coins/used_log/transaction`), {
                                        date: log_date,
                                        coin: offerUseCoin,
                                        event: 'ยื่นข้อเสนอรับซ่อมเครื่องใช้ไฟฟ้า',
                                        post_id: postId,
                                        coin_type: fromCoinOffer,
                                        user_id: memberId
                                    })
                                }).then(() => {
                                    setShowSaving(false)
                                    navigate(0)
                                })
                            })
                        } else if(fromCoinOffer == 'exchange') {
                            getDoc(doc(db, 'exchange_coins', 'sum_data')).then((sumDoc) => {
                                updateDoc(doc(db, 'exchange_coins', 'sum_data'), {
                                    used: (sumDoc.data().used*1) + (offerUseCoin*1),
                                    all: (sumDoc.data().all*1) + (offerUseCoin*1)
                                }).then(() => {
                                    const log_date = new Date()
                                    addDoc(collection(db, `/coins/used_log/transaction`), {
                                        date: log_date,
                                        coin: offerUseCoin,
                                        event: 'ยื่นข้อเสนอรับซ่อมเครื่องใช้ไฟฟ้า',
                                        post_id: postId,
                                        coin_type: fromCoinOffer,
                                        user_id: memberId
                                    })
                                }).then(() => {
                                    setShowSaving(false)
                                    navigate(0)
                                })
                            })
                        }
                    })
                })
            }).catch((error) => {
                alert(error)
                navigate('/')
            })
        }
    }

    const resizeDiv = new ResizeObserver((event) => {
        //console.log(event[0].contentBoxSize[0].inlineSize)
        //console.log(event[0])
        setWidth(event[0].contentBoxSize[0].inlineSize-1)
        setHeight(imagePostHeight)
    })

    useEffect(() => {
        resizeDiv.observe(document.getElementById("divPost"))
    })

    useEffect(() => {
        if (loading) return;
        if (user) {
            getDocUser()
        }
        getPost(postId)
        getSettings()
    }, [loading])

    const [offerFunction, setOfferFunction] = useState(true)

    const getSettings = () => {
        getDoc(doc(db, 'settings', 'functions')).then((result) => {
            const data = result.data()
            if(data.offer) { setOfferFunction(true) } else { setOfferFunction(false) }
        })
    }

    return (
        <>
            <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
            <Loading open={showSaving} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <div className="container-fluid">
                <div className="flex-container" style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 10, paddingRight: 10  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex' }}>
                            <p style={{ fontSize: h1FontSize, fontWeight: '600', color: '#3D5598', marginBottom: 0 }}>แจ้งซ่อมเครื่องไฟฟ้า</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="flex-container" style={{ flex: 1 }}>
                                <font style={{ fontSize: h2FontSize, fontWeight: '600' }}>ประกาศโดย:&nbsp;<font style={{ fontWeight: '400' }}>บุคคลทั่วไป</font></font>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', wordWrap: 'unset', alignItems: 'end', marginBottom: 3, marginLeft: 10 }}>
                                <font style={{ fontSize: h3FontSize, color: '#626167' }}>จำนวนผู้เข้าชม {number_format.format(postView)}</font>
                            </div>
                        </div>
                        <div id="divPost" style={{ display: 'flex', flexDirection: 'column', marginTop: 5, borderWidth: 1, borderColor: '#000000', borderStyle: 'inset', borderTopRightRadius: 15, borderTopLeftRadius: 15 }}>
                            <img style={{ display: 'flex', objectFit: "cover", borderTopRightRadius: 15, borderTopLeftRadius: 15, maxWidth: width }} src={showImage} alt="APP3R" />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', paddingTop: 10, paddingBottom: 5 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
                                {images?.map((image, i) => (
                                    <div key={image.id}  style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', paddingLeft: 10, paddingRight: 10 }}>
                                        <img style={{ maxWidth: thumpnailWidth }} src={image.data.url} alt="APP3R" onClick={() => { setShowImages(image.data.url) }} />
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                        {offerFunction && 
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {(isShop == 1 && !offered && (post.poster_id != memberId) && (status == 1 || status ==2)) &&
                            (<div style={{ marginTop: 15, marginBottom: 10, flex: 1, display: 'flex', flexDirection: 'column' }} /* className="flex-container-row" */>
                                {/* <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <h6>ใช้ <strong>{number_format.format(offerUseCoin)}</strong> เหรียญ เพื่อยื่นข้อเสนอ คุณมี</h6>
                                    <h6>เหรียญแจกฟรี <strong>{number_format.format(offerFreeCoin)}</strong> เหรียญ<br/>เหรียญแลกเปลี่ยน <strong>{number_format.format(offerExchangeCoin)}</strong> เหรียญ</h6>
                                </div> */}
                                <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                    <button type="button" className="btn btn-primary" style={{ width: 200 }} 
                                        onClick={() => setOpenAcceptPostShop(true)}>
                                        <AiOutlineCheckCircle style={{ fontSize: h2FontSize, marginRight: 5, marginBottom: 3 }} />
                                        <font style={{ fontSize: h3FontSize }}>{(post.onboard==2 || post.onboard==3)?"ยื่นข้อเสนอซื้อ":"ยื่นข้อเสนอ"}</font>
                                    </button>
                                </div>
                            </div>)
                        }
                        </div>
                        }
                        {(post.fix_type_home || post.fix_type_shop) && (
                        <div style={{ marginTop: 5, height: 300 }}>
                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                            <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                <MarkerF
                                    draggable={false} position={{lat, lng}}
                                />
                            </GoogleMap>
                            )}
                        </div>)}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FBEFE8', padding: 15 }}>
                            <div className="flex-container" style={{ marginBottom: 0 }}>
                                <font style={{ fontSize: h2FontSize, fontWeight: '600', color: '#3D5598', marginRight: 5 }}>ผู้ประกาศ:&nbsp;<font style={{ fontWeight: '400' }}>{posterName}</font></font>
                                <font className="flex-container" style={{ marginTop: 5 }}><StarRating score={posterScore} /> <div style={{ marginBottom: 0 }}>({number_format.format(posterScore)} คะแนน)</div></font>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
                                <font className="flex-container" style={{ fontSize: h3FontSize, marginBottom: 0, color: '#626167' }}>
                                    <font style={{ display: 'flex', flexDirection: 'column' }}>จำนวนประกาศทั้งหมด {numPost} ครั้ง&nbsp;</font>
                                    <font style={{ display: 'flex', flexDirection: 'column' }}><Link to={`/postlist/${onBoard}/${posterId}`} target='_blank'>[คลิกเพื่อดูประกาศทั้งหมดของผู้ประกาศรายนี้]</Link></font>
                                </font>
                            </div>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h1FontSize, fontWeight: '600', color: '#FF0000', marginBottom: 0 }}>
                                งบประมาณซ่อมไม่เกิน: {number_format.format(post.price)} บาท
                            </p>
                        </div>
                        {(post.owner == '1') && (
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <p style={{ fontSize: textFontSize, fontWeight: '400', marginBottom: 5 }}>**ผู้ลงประกาศเป็นเจ้าของเครื่องใช้ไฟฟ้า</p>
                        </div>)}
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>รุ่น: <font style={{ fontWeight: '400' }}>{modelName}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>ยี่ห้อ: <font style={{ fontWeight: '400' }}>{brandName}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h3FontSize, fontWeight: '600', marginBottom: 5, color: '#626167' }}>ปีที่ซื้อ: <font style={{ fontWeight: '400' }}>{post.buy_year?"พ.ศ. "+post.buy_year:"ไม่ระบุ"}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0, color: '#626167' }}>ต้องการซ่อมไม่เกิน: <font style={{ fontWeight: '400' }}>{post.fix_day} วัน</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0, color: '#626167' }}>อาการ: <font style={{ fontWeight: '400' }}>{brokenName}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h3FontSize, fontWeight: '600', marginBottom: 0, color: '#626167' }}>อาการเพิ่มเติม: <font style={{ fontWeight: '400' }}>{post.description?post.description:"ไม่ระบุ"}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h3FontSize, fontWeight: '600', marginBottom: 5, color: '#626167' }}>รูปแบบการซ่อม: <font style={{ fontWeight: '400' }}>
                                {post.fix_type_home && ("บริการมาซ่อมในที่พักอาศัย")}
                                {post.fix_type_shop && ("บริการไปรับกลับมาซ่อมที่ร้าน")}
                                {post.fix_type_send && ("ยกไปซ่อมที่ร้าน")}
                                </font>
                            </p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <p style={{ fontSize: textFontSize, fontWeight: '400', marginBottom: 0 }}>ที่อยู่: {(addressNo)?"บ้านเลขที่ "+addressNo+" ":""} 
                                {(village)?"หมู่บ้าน "+village+" ":""} 
                                {(building)?"อาคาร "+building+" ":""} 
                                {(floor)?"ชั้น "+floor+" ":""} 
                                {(room)?"ห้อง "+room+" ":""} 
                                {(subroad)?"ซอย "+subroad+" ":""} 
                                {(road)?"ถ."+road+" ":""} 
                                {(subdistrict)?subdistrict+" ":""} 
                                {(district)?district+" ":""} 
                                {(province)?province+" ":""} 
                                {(postcode)?postcode+" ":""}
                            </p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <p style={{ fontSize: textFontSize, fontWeight: '400' }}>ที่อยู่เพิ่มเติม: {post.address?post.address:"ไม่ระบุ"}</p>
                        </div>
                        {offerFunction && 
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {(isShop == 1 && !offered && (post.poster_id != memberId) && (status == 1 || status ==2)) &&
                            (<div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', flex: 1 }} className="d-sm-none">
                                <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                    <button type="button" className="btn btn-primary" style={{ width: 200 }} 
                                        onClick={() => setOpenAcceptPostShop(true)}>
                                        <AiOutlineCheckCircle style={{ fontSize: h2FontSize, marginRight: 5, marginBottom: 3 }} />
                                        <font style={{ fontSize: h3FontSize }}>{(post.onboard==2 || post.onboard==3)?"ยื่นข้อเสนอซ่อม":"ยื่นข้อเสนอซ่อม"}</font>
                                    </button>
                                </div>
                            </div>)
                        }
                        </div>
                        }
                        {(user && (post.poster_id != memberId)) && (<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F7FB', padding: 15 }}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 5 }}>คุยกับผู้ประกาศ</p>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {questions?.map((message, i) => 
                                <div style={{ display: 'flex', flexDirection: 'column' }} key={i}>
                                    {(message.data.from_user == 1) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'end' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: 'lightyellow', borderRadius: 15,
                                            }}>{message.data.message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'end' }}>{dateTimeFormat(message.data.message_datetime)}</p>
                                    </div>}

                                    {(message.data.from_user == 0) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'start' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: '#FFFFFF', borderRadius: 15,
                                            }}>{message.data.message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'start' }}>{dateTimeFormat(message.data.message_datetime)}</p>
                                    </div>}
                                </div>
                            )}
                            </div>
                            {((status == 1 || status ==2)) && (
                            <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 10}}>
                                    <input type='text' name='question' onChange={(e) => setQuestion(e.target.value)} 
                                        value={question} placeholder='ข้อความ'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    {questionErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกข้อความ</strong>
                                        </div>
                                    }
                                </div>
                                <button type="button" className="btn btn-success" style={{ width: 150 }} 
                                    onClick={handleQuestionSubmit}>
                                    <AiOutlinePlusCircle style={{ fontSize: textFontSize, marginRight: 5, marginBottom: 3 }} />
                                    <font style={{ fontSize: textFontSize }}>ส่งข้อความ</font>
                                </button>
                            </div>)}
                        </div>)}
                        {((post.poster_id == memberId)) && (<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F7FB', padding: 15 }}>
                            {ownerQuestions?.map((user, j) => (<>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 5 }}>ข้อความจาก {user.user.firstname} {user.user.lastname}</p>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {user.messages?.map((message, i) => 
                                <div style={{ display: 'flex', flexDirection: 'column' }}  key={i}>
                                    {(message.data().from_user == 0) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'end' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: 'lightyellow', borderRadius: 15,
                                            }}>{message.data().message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'end' }}>{dateTimeFormat(message.data().message_datetime)}</p>
                                    </div>}

                                    {(message.data().from_user == 1) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'start' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: '#FFFFFF', borderRadius: 15,
                                            }}>{message.data().message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'start' }}>{dateTimeFormat(message.data().message_datetime)}</p>
                                    </div>}
                                </div>
                            )}
                            </div>
                            <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 10}}>
                                    <input type='text' name='answer' onChange={(e) => setAnswer(e.target.value)} 
                                        value={answer} placeholder='ข้อความ'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    {answerErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกข้อความ</strong>
                                        </div>
                                    }
                                </div>
                                <button type="button" className="btn btn-success" style={{ width: 150 }} 
                                    onClick={() => { handleAnswerSubmit(user.user_id) }}>
                                    <AiOutlinePlusCircle style={{ fontSize: textFontSize, marginRight: 5, marginBottom: 3 }} />
                                    <font style={{ fontSize: textFontSize }}>ส่งข้อความ</font>
                                </button>
                            </div>
                            </>)
                            )}
                        </div>)}
                    </div>
                </div>
            </div>
            
            <Dialog open={openAcceptPostShop} onClose={handleCloseAcceptPostShop} maxWidth='lg'>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt'}}>
                        <h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                        <strong>ยื่นข้อเสนอรับซ่อมเครื่องใช้ไฟฟ้า</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="container">
                        <div className="row gy-3">
                            <div className="col-xs-12">
                                <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                    <ImCoinDollar style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                    <h5><strong>หักเหรียญจาก</strong></h5>
                                </label>
                                <div style={{ display: 'flex', flex: 1, paddingLeft: 30, flexDirection: 'column' }}>
                                    <RadioGroup
                                        defaultValue="freecoin" name="fromCoinOffer"
                                        value={fromCoinOffer} onChange={handlePickCoin}
                                    >
                                        <FormControlLabel control={<Radio />} value='freecoin'
                                            label={<span style={{ fontFamily: 'Prompt' }}>{"เหรียญแจกฟรี "}<strong>{number_format.format(offerFreeCoin)}</strong> เหรียญ</span>}
                                        />
                                        <FormControlLabel control={<Radio />} value='exchange'
                                            label={<span style={{ fontFamily: 'Prompt' }}>{"เหรียญแลกเปลี่ยน "}<strong>{number_format.format(offerExchangeCoin)}</strong> เหรียญ</span>}
                                        />
                                    </RadioGroup>
                                </div>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex'}}>
                                    <RiHandCoinLine style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                    <h5><strong>งบประมาณซ่อมไม่เกิน {number_format.format(post.price)} บาท </strong></h5>
                                </label>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <MdOutlineCalendarToday style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>วันเวลาที่สะดวกรับซ่อม</strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flex: 1, paddingLeft: 30 }}>
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker format="DD/MM/YYYY" minDate={today} defaultValue={today} onChange={(value) => setPickDate(value)} />
                                        </LocalizationProvider>
                                        <select name='hour' onChange={(e) => setPickHour(e.target.value)} style={{ width: 70, marginLeft: 10 }}>
                                            <option value="00">00</option>
                                            <option value="01">01</option>
                                            <option value="02">02</option>
                                            <option value="03">03</option>
                                            <option value="04">04</option>
                                            <option value="05">05</option>
                                            <option value="06">06</option>
                                            <option value="07">07</option>
                                            <option value="08">08</option>
                                            <option value="09">09</option>
                                            <option value="10">10</option>
                                            <option value="11">11</option>
                                            <option value="12">12</option>
                                            <option value="13">13</option>
                                            <option value="14">14</option>
                                            <option value="15">15</option>
                                            <option value="16">16</option>
                                            <option value="17">17</option>
                                            <option value="18">18</option>
                                            <option value="19">19</option>
                                            <option value="20">20</option>
                                            <option value="21">21</option>
                                            <option value="22">22</option>
                                            <option value="23">23</option>
                                        </select>
                                        <select name='minute' onChange={(e) => setPickMinute(e.target.value)} style={{ width: 70, marginLeft: 5 }}>
                                            <option value="00">00</option>
                                            <option value="15">15</option>
                                            <option value="30">30</option>
                                            <option value="45">45</option>
                                        </select>
                                    </div>
                                </div> 
                                <div className="" style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex'}}>
                                        <RiHandCoinLine style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>ราคาเสนอซ่อม (บาท) <font className="text-danger">*</font></strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                        <input type='text' name='offerPrice' onChange={(e) => setOfferPrice(e.target.value)} 
                                            value={offerPrice} placeholder='ราคาเสนอซ่อม (บาท)'
                                            style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                        {priceErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกราคาเสนอซ่อม</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                                    
                                <div className="" style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex' }}>
                                        <RiHandCoinLine style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>ราคาซื้อเป็นซาก (บาท)</strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                        <input type='text' name='offerBuy' onChange={(e) => setOfferBuy(e.target.value)} 
                                            value={offerBuy} placeholder='ราคาซื้อเป็นซาก (บาท)'
                                            style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div>

                                <div className="" style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex'}}>
                                        <IoHammerOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>รูปแบบการรับซ่อม<font className="text-danger">*</font></strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                        <select name='offerFix' onChange={(e) => setOfferFix(e.target.value)}>
                                            <option value="1">บริการไปซ่อมในที่พักอาศัย</option>
                                            <option value="2">บริการไปรับกลับมาซ่อมที่ร้าน</option>
                                            <option value="3">ลูกค้ายกมาซ่อมที่ร้าน</option>
                                        </select>
                                    </div>
                                </div>
                                
                                <div className="" style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex'}}>
                                        <IoHammerOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>ระยะเวลาที่ใช้ซ่อม (วัน) <font className="text-danger">*</font></strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                        <input type='text' name='offerFixDay' onChange={(e) => setOfferFixDay(e.target.value)} 
                                            value={offerFixDay} placeholder='ระยะเวลาที่ใช้ซ่อม (วัน)'
                                            style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                        {offerFixDayErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกระยะเวลาที่ใช้ซ่อม</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-6">
                                <div className="" style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex'}}>
                                        <FaRegThumbsUp style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>ระยะรับประกัน (วัน) <font className="text-danger">*</font></strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                        <input type='text' name='offerGaruntee' onChange={(e) => setOfferGaruntee(e.target.value)} 
                                            value={offerGaruntee} placeholder='ระยะรับประกัน (วัน)'
                                            style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                        {offerGarunteeErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกระยะรับประกัน</strong>
                                            </div>
                                        }
                                    </div>
                                </div>


                                <div className="" style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                    <label style={{ display: 'flex' }}>
                                        <FaRegHandshake style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>ข้อตกลง</strong></h5>
                                    </label>
                                    <label style={{ display: 'flex', paddingLeft: 15}}><h6><strong>ข้อตกลง</strong></h6></label>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                        <select name='offerAgreement' onChange={(e) => setOfferAgreement(e.target.value)} style={{  }}>
                                            <option value="1">เมื่อเริ่มดำเนินการ ไม่คืนมัดจำทุกกรณี</option>
                                            <option value="2">เมื่อรับงานแล้ว แต่ดำเนินการไม่เสร็จสิ้น คืนค่ามัดจำทั้งหมด</option>
                                            <option value="3">หากไม่ได้ดำเนินการ ยินดีคืนค่ามัดจำเต็มจำนวน</option>
                                        </select>
                                    </div>
                                    <label style={{ display: 'flex', paddingLeft: 15, marginTop: 10}}><h6><strong>การคิดค่าเดินทาง</strong></h6></label>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                        <select name='offerTravel' onChange={(e) => setOfferTravel(e.target.value)} style={{  }}>
                                            <option value="1">ฟรีค่าเดินทาง ไม่เก็บค่าเดินทางในทุกกรณี</option>
                                            <option value="2">เก็บค่าเดินทางในทุกกรณี</option>
                                            <option value="3">เก็บค่าเดินทางเมื่อได้ดำเนินการเท่านั้น</option>
                                        </select>
                                    </div>
                                    {(offerTravel == 2 || offerTravel == 3) && 
                                    <div style={{ display: 'flex', flexDirection: 'column', marginTop: 10 }}>
                                        <label style={{ display: 'flex', paddingLeft: 15 }}><h6><strong>ค่าเดินทาง (บาท)</strong></h6></label>
                                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                            <input type='text' name='offerTravelPrice' onChange={(e) => setOfferTravelPrice(e.target.value)} 
                                                value={offerTravelPrice} placeholder='ค่าเดินทาง (บาท)'
                                                style={{ paddingLeft: 8 }} />
                                        </div>
                                    </div>
                                    } 
                                    <label style={{ display: 'flex', paddingLeft: 15, marginTop: 10 }}><h6><strong>ในระยะรับประกัน</strong></h6></label>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                        <select name='offerAfterFix' onChange={(e) => setOfferAfterFix(e.target.value)} style={{  }}>
                                            <option value="1">ซ่อมฟรีทุกกรณี</option>
                                            <option value="2">ฟรีค่าแรง และเก็บค่าอะไหล่ (ถ้ามี)</option>
                                            <option value="3">เก็บค่าแรง และเก็บค่าอะไหล่ (ถ้ามี)</option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPostShop} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleOfferSubmit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยื่นข้อเสนอ</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Post