import { GoGraph } from "react-icons/go"
import { db } from "../../assets/js/firebase"
import { collection, onSnapshot, query, where } from "firebase/firestore"
import { useEffect, useState } from "react"

const Breadcrumb = () => {
    return (
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item">
                    <a
                        className="nav-link link-dark"
                        href="/admin/reports">รายงาน
                    </a>
                </li>
                <li className="breadcrumb-item active">
                    <strong>ผู้ใช้งานในระบบ</strong>
                </li>
            </ol>
        </nav>
    )
}

const CountByUserType = () => {
    const [users, setUsers] = useState([])
    const [shops, setShops] = useState([])
    const [technician, setTechnician] = useState([])
    const [bannedUsers, setBannedUsers] = useState([])

    const countCollection = (collectionName, setValue, setWhere=null) => {
        let queryObject
        if (setWhere) {
            queryObject = query(collection(db, collectionName), setWhere)
        } else {
            queryObject = query(collection(db, collectionName))
        }
        onSnapshot(queryObject, (querySnapshot) => {
            setValue(querySnapshot.docs.length)
        })
    }

    useEffect(() => {
        countCollection("users", setUsers)
        countCollection("shops", setShops)
        countCollection("technician", setTechnician)
        countCollection("users", setBannedUsers, where("status", "==", 3))
    }, []);

    return (
        <table className="table">
            <thead>
                <tr>
                    <th>ประเภทผู้ใช้ระบบ</th>
                    <th>จำนวน</th>
                </tr>
            </thead>
            <tbody>
                <tr>
                    <td>WeeeU (ประชาชน)</td>
                    <td>{users} คน</td>
                </tr>
                <tr>
                    <td>WeeeR (ร้านค้า)</td>
                    <td>{shops} คน</td>
                </tr>
                <tr>
                    <td>WeeeT (ช่าง)</td>
                    <td>{technician} คน</td>
                </tr>
                <tr>
                    <td>ผู้ใช้งานที่ถูกระงับ</td>
                    <td>{bannedUsers} คน</td>
                </tr>
            </tbody>
        </table>
    )
}

const UserReports = () => {
    return (
        <div className="container-fluid" style={{ marginTop: "30px" }}>
            <div className="row">
                <div className="col">
                    <h4><GoGraph /> ผู้ใช้งานในระบบ</h4>
                    <hr />
                    <Breadcrumb />
                    <br />
                    <CountByUserType />
                </div>
            </div>
        </div>
    );
}

export default UserReports