import React, { useState, useEffect } from 'react'
import { db } from "../../assets/js/firebase"
import { collection, addDoc, updateDoc, doc, getDocs, getDoc, query, where } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'

import { calScoreShop } from '../../../assets/js/ScoreHelper'
import { depositPercent, remainPercent } from '../../../assets/constants/coin'

export function AppointmentBoard4({appointment}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(true)

    const [userCoin, setUserCoin] = useState(0)

    const [openConfirm, setOpenConfirm] = useState(false)
    const [openCancle, setOpenCancle] = useState(false)

    const [openConfirmReport, setOpenConfirmReport] = useState(false)
    const [openCancleReport, setOpenCancleReport] = useState(false)
    const [openFinish, setOpenFinish] = useState(false)

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState('')

    const [coinRate, setCoinRate] = useState(1)
    const [depositCoin, setDepositCoin] = useState(0)
    const [remainCoin, setRemainCoin] = useState(0)
    const [depositCoinNotRound, setDepositCoinNotRound] = useState(0)
    const [remainCoinNotRound, setRemainCoinNotRound] = useState(0)
    const [depositEnough, setDepositEnough] = useState(false)
    const [depositMessage, setDepositMessage] = useState('')
    const [remainEnough, setRemainEnough] = useState(false)
    const [remainMessage, setRemainMessage] = useState('')

    const board = 4
    const appointmentId = appointment.id
    const postId = appointment.data.post_id
    const shopId = appointment.data.shop_id
    const postOfferId = appointment.data.offer_id
    const shopOfferId = appointment.data.shop_offer_id
    const userId = appointment.post.poster_id

    const offerPrice = (appointment.data.fix_price * 1)

    var badge = { message: '', class: 'success', color: '#000000', show: false }
    if(appointment.data.status == 1) {
        badge = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
    } else if(appointment.data().status == 2) {
        badge = { message: 'ร้านค้ายืนยันการรับซ่อม', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data().status == 3) {
        badge = { message: 'ยืนยันให้ซ่อม', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data().status == 4) {
        badge = { message: 'ร้านค้าปฏิเสธการรับซ่อม', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 5) {
        badge = { message: 'ไม่ให้ซ่อม', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 6) {
        badge = { message: 'ร้านค้ารายงานการซ่อมแล้ว', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data().status == 7) {
        badge = { message: 'ยืนยันรับรายงาน', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data().status == 8) {
        badge = { message: 'ร้านค้ายืนยันได้รับเหรียญ', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data().status == 9) {
        badge = { message: 'ยกเลิกจ่ายค่าซ่อม', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 10) {
        badge = { message: 'ร้านค้ายกเลิกการรับเหรียญ', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 11) {
        badge = { message: 'ยืนยันเสริ็จงาน', class: 'success', color: '#ffffff', show: true }
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const getCoinRate = () => {
        getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeSnapshot) => {
            const rate = exchangeSnapshot.data().money_to_coin_1 * 1
            setCoinRate(rate)
        })
    }

    const getUserCoin = () => {
        getDoc(doc(db, 'users', userId)).then((docs) => {
            if(docs.docs[0].exchange_coin) setUserCoin(docs.docs[0].exchange_coin)
        })
    }

    const handleOpenConfirm = () => {
        var depositCoinNotRound = Math.ceil(offerPrice * depositPercent)/coinRate
        var remainCoinNotRound = Math.ceil(offerPrice * remainPercent)/coinRate
        var depositCoin = Math.round(depositCoinNotRound)
        var remainCoin = Math.round(remainCoinNotRound)

        setDepositCoin(depositCoin)
        setRemainCoin(remainCoin)
        setDepositCoinNotRound(depositCoinNotRound)
        setRemainCoinNotRound(remainCoinNotRound)

        if(depositCoin <= userCoin) { 
            setDepositEnough(true) 
            setDepositMessage('คุณมีเหรียญซื้อ '+number_format.format(userCoin)+' เหรียญ คุณจะใช้ '+number_format.format(depositCoin)+' เหรียญ ('+(depositPercent*100)+'% ของราคาค่าซ่อม) เพื่อยืนยันการซ่อมเครื่องใช้ไฟฟ้า?')
        } else { 
            setDepositEnough(false) 
            setDepositMessage('คุณมีจำนวนเหรียญซื้อไม่พอ คุณมี '+number_format.format(userCoin)+' เหรียญ คุณต้องใช้ '+number_format.format(depositCoin)+' เหรียญ (('+(depositPercent*100)+'% ของราคาบริการซ่อมเครื่องใช้ไฟฟ้า)')
        }

        setOpenConfirm(true)
    }

    const handleCloseConfirm = () => {
        setDepositEnough(false)
        setOpenConfirm(false)
    }

    const handleConfirm = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            deposit_coin: depositCoin,
            remain_coin: remainCoin,
            deposit_coin_not_round: depositCoinNotRound,
            remain_coin_not_round: remainCoinNotRound,
            user_confirm_datetime: logDate.getTime(),
            status: 3,
        }).then(() => {
            getDoc(doc(db, 'exchange_coins', 'sum_data')).then((freeSnapshot) => {
                const data = freeSnapshot.data()
                var promisesTask = []
                var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
                    coin: depositCoin*1,
                    date: logDate.getTime(),
                    event_type: 'deposit',
                    event: 'มัดจำ '+(depositPercent*100)+'% ของค่าบริการซ่อมเครื่องใช้ไฟฟ้า',
                    type: 'increase',
                    post_type: 'fix',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addCoinLogTask)
                var updateCoinTask = updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                    all: (data.all*1)+(depositCoin*1)
                })
                promisesTask.push(updateCoinTask)
                var updateUserCoinTask = updateDoc(doc(db, 'users/', userId), {
                    exchange_coin: (userCoin*1)-(depositCoin*1)
                })
                promisesTask.push(updateUserCoinTask)
                var addUserCoinLogTask = addDoc(collection(db, `users/${userId}/coin_log/`), {
                    coin: depositCoin*1,
                    date: logDate.getTime(),
                    event_type: 'deposit',
                    event: 'มัดจำ '+(depositPercent*100)+'% ของค่าบริการซ่อมเครื่องใช้ไฟฟ้า',
                    coin_type: 'exchange',
                    post_type: 'fix',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addUserCoinLogTask)
                
                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const handleOpenCancle = () => {
        setOpenCancle(true)
    }

    const handleCloseCancle = () => {
        setOpenCancle(false)
    }

    const handleCancle = () => {
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointmentId), { 
            user_cancle_message: message,
            user_cancle_reason: reason,
            user_cancle_datetime: logDate.getTime(),
            status: 5,
        })
        promisesTask.push(updateAppointmentTask)

        var updatePostTask = updateDoc(doc(db, `/posts/`, postId), { 
            status: 5
        })
        promisesTask.push(updatePostTask)

        Promise.all(promisesTask).then(() => {
            handleCloseCancle()
            navigate(0)
        })
    }

    const handleOpenConfirmReport = () => {
        setOpenConfirmReport(true)
    }

    const handleCloseConfirmReport = () => {
        setOpenConfirmReport(false)
    }

    const handleConfirmReport = () => {
        // โอนเหรียญ 30% จาก platform ไป shop chooseShopId
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 7,
            user_job_confirm_datetime: logDate.getTime()
        }).then(() => {
            getDoc(doc(db, 'exchange_coins', 'sum_data')).then((freeSnapshot) => {
                const data = freeSnapshot.data()
                var promisesTask = []
                var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
                    coin: depositCoin*1,
                    date: logDate.getTime(),
                    event_type: 'transfer',
                    event: 'โอนมัดจำ 30% ของค่าบริการซ่อมเครื่องใช้ไฟฟ้าให้ร้านค้า',
                    type: 'decrease',
                    post_type: 'fix',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addCoinLogTask)

                var updateCoinTask = updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                    all: (data.all*1)-(depositCoin*1)
                })
                promisesTask.push(updateCoinTask)

                var getShopCoinTask = getDoc(doc(db, `shops`, shopId)).then((shopDoc) => {
                    var exchangeCoin = 0
                    if(shopDoc.data().exchange_coin) { exchangeCoin = shopDoc.data().exchange_coin }
                    updateDoc(doc(db, 'shops/', shopId), {
                        exchange_coin: (exchangeCoin*1)+(depositCoin*1)
                    })
                })
                promisesTask.push(getShopCoinTask)

                var addShopCoinLogTask = addDoc(collection(db, `shops/${shopId}/coin_log/`), {
                    coin: depositCoin*1,
                    date: logDate.getTime(),
                    event_type: 'transfer',
                    event: 'ค่ามัดจำ 30% ของค่าบริการซ่อมเครื่องใช้ไฟฟ้า',
                    coin_type: 'exchange',
                    post_type: 'fix',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addShopCoinLogTask)
            
                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const handleOpenCancleReport = () => {
        setOpenCancleReport(true)
    }

    const handleCloseCancleReport = () => {
        setOpenCancleReport(false)
    }

    const handleCancleReport = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 9,
            user_cancle_message: message,
            user_cancle_reason: reason,
            user_cancle_job_datetime: logDate.getTime()
        }).then(() => {
            updateDoc(doc(db, `posts`, postId), { 
                status: 5,
            }).then(() => {
                setShowLoading(false)
                navigate(0)
            })
        })
    }

    const handleOpenFinish = () => {
        if(remainCoin < userCoin) { 
            setRemainEnough(true) 
            setRemainMessage('คุณมีเหรียญซื้อ '+number_format.format(userCoin)+' เหรียญ คุณจะใช้ '+number_format.format(remainCoin)+' เหรียญ ('+(remainPercent*100)+'% ของราคาบริการ) เพื่อโอนให้ผู้ให้บริการ?')
        } else { 
            setRemainEnough(false) 
            setRemainMessage('คุณมีจำนวนเหรียญซื้อไม่พอ คุณมี '+number_format.format(userCoin)+' เหรียญ คุณต้องใช้ '+number_format.format(remainCoin)+' เหรียญ ('+(remainPercent*100)+'% ของราคาบริการ)')
        }

        setOpenFinish(true)
    }

    const handleCloseFinish = () => {
        setDepositEnough(false)
        setDepositMessage('')
        setOpenFinish(false)
    }

    const handleFinish = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 11,
            user_job_finish_datetime: logDate.getTime(),
            finish_message: message,
            score: score
        }).then(() => {
            var promisesTask = []
            var getShopCoinTask = getDoc(doc(db, `shops`, shopId)).then((shopDoc) => {
                var exchangeCoin = 0
                if(shopDoc.data().exchange_coin) { exchangeCoin = shopDoc.data().exchange_coin }
                updateDoc(doc(db, 'shops/', shopId), {
                    exchange_coin: (exchangeCoin*1)+(remainCoin*1)
                })
            })
            promisesTask.push(getShopCoinTask)

            var addShopCoinLogTask = addDoc(collection(db, `shops/${shopId}/coin_log/`), {
                coin: remainCoin*1,
                date: logDate.getTime(),
                event_type: 'transfer',
                event: 'ค่าบริการ 70% ของค่าบริการซ่อมเครื่องใช้ไฟฟ้า',
                coin_type: 'exchange',
                post_type: 'fix',
                post_id: postId,
                appointment_id: appointmentId
            })
            promisesTask.push(addShopCoinLogTask)
            
            var updateUserCoinTask = updateDoc(doc(db, 'users/', userId), {
                exchange_coin: (userCoin*1)-(remainCoin*1)
            })
            promisesTask.push(updateUserCoinTask)
            
            var addUserCoinLogTask = addDoc(collection(db, `users/${userId}/coin_log/`), {
                coin: remainCoin*1,
                date: logDate.getTime(),
                event_type: 'transfer',
                event: 'โอน 70% ของค่าบริการซ่อมเครื่องใช้ไฟฟ้าให้ร้านค้า',
                coin_type: 'exchange',
                post_type: 'fix',
                post_id: postId,
                appointment_id: appointmentId
            })
            promisesTask.push(addUserCoinLogTask)

            var updatePostTask = updateDoc(doc(db, `/posts/`, postId), { 
                status: 9,
                appointment_id: appointmentId,
                finish_date: logDate.getTime()
            })
            promisesTask.push(updatePostTask)
            
            calScoreShop(db, shopId, score)

            var weePoint = 100
            var addWeePointTask = addDoc(collection(db, `/shops/${shopId}/points`), {
                datetime: logDate.getTime(),
                point: weePoint,
                post_id: postId,
                appointment_id: appointmentId
            })
            promisesTask.push(addWeePointTask)
    
            var addNotiShopTask = addDoc(collection(db, `/shops/${shopId}/notifications`), {
                date: logDate.getTime(),
                message: "คุณได้รับแต้มสะสมจากการซ่อมเครื่องใช้ไฟฟ้า จำนวน "+number_format.format(weePoint)+" เหรียญ",
                link: '/post',
                post_id: postId,
                color: 'green',
                type: 'point',
                board: 'fix',
                status: 0
            })
            promisesTask.push(addNotiShopTask)
    
            // User ได้รับแต้มสะสม
            var weePoint = 100
            var addUserWeePointTask = addDoc(collection(db, `/users/${userId}/points`), {
                datetime: logDate.getTime(),
                point: weePoint,
                post_id: postId,
                appointment_id: appointmentId
            })
            promisesTask.push(addUserWeePointTask)
    
            var addNotiUserTask = addDoc(collection(db, `/users/${userId}/notifications`), {
                date: logDate.getTime(),
                message: "คุณได้รับแต้มสะสมจากการซ่อมเครื่องใช้ไฟฟ้า จำนวน "+number_format.format(weePoint)+" แต้ม",
                link: '/post',
                post_id: postId,
                color: 'green',
                type: 'point',
                board: 'fix',
                status: 0
            })
            promisesTask.push(addNotiUserTask)
        
            Promise.all(promisesTask).then(() => {
                setShowLoading(false)
                navigate(0)
            })
        })
    }

    useEffect(() => {
    })

    return (
        <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
            {badge.show && (
            <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                <button type="button" className={"btn "+"btn-"+badge.class} style={{ color: badge.color }}><strong>{badge.message}</strong></button>
            </div>
            )}
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                <img src={appointment.post.picture} alt="APP3R" height="150" />
            </div>

            <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                <p className="card-text">
                    <strong>ประเภทนัดหมาย:</strong> แจ้งซ่อมเครื่องใช้ไฟฟ้า<br/>
                    <strong>วันนัดหมาย:</strong><br/>
                    <h6 style={{ marginLeft: 15 }}>{dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/></h6>
                    <strong>ราคาเสนอซ่อม:</strong> {number_format.format(appointment.offer.offer_price)} บาท <br/>
                    <strong>อาการ:</strong> {appointment.post.description} <br/>
                    {(appointment.data.status > 1) && (<>
                        <strong>ราคาซ่อมหลังประเมิน:</strong> {number_format.format(appointment.data.fix_price)} บาท <br/>
                        <strong>ระยะเวลาที่ใช้ซ่อม:</strong> {appointment.data.fix_day} วัน <br/>
                        <strong>ผลประเมินอาการเบื้องต้น:</strong> {appointment.data.before_check} <br/>
                        <strong>ผลการตรวจสภาพก่อนซ่อม:</strong> {appointment.data.before_result} <br/>
                        <strong>อาการสภาพก่อนซ่อม:</strong> {appointment.data.before_symptom} <br/>
                    </>)}
                    {(appointment.data.status >= 6) && (<>
                        <strong>ประเมินการซ่อม:</strong> {(appointment.data.fix_report_able == 1)?'ได้':'ไม่ได้'}<br/>
                        <strong>ค่าอะไหล่:</strong> {appointment.data.fix_report_price} บาท <br/>
                        <strong>รายงานการซ่อม:</strong> {appointment.data.fix_report_description} <br/>
                    </>)}
                </p>
            </div>
            <div className="card-body">
                <Link className="btn btn-primary m-1" to={`/profile/postdetail/${board}/${appointment.data.post_id}`} target='_blank'><strong>ดูรายละเอียดของประกาศ</strong></Link>
                {(appointment.data.status == 2) && (<>
                    <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenConfirm() }}>
                        <strong>ให้ซ่อม</strong>
                    </button>
                    <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCancle() }}>
                        <strong>ไม่ให้ซ่อม</strong>
                    </button>
                </>)}
                {(appointment.data.status==6) && (<>
                    <button type="button" className="btn btn-success m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenConfirmReport() }}>โอนเหรียญค่าซ่อม (มัดจำ 30%)</button>
                    <button type="button" className="btn btn-danger m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenCancleReport() }}>ยกเลิกการซ่อม</button>
                </>)}
                {(appointment.data.status==8) && (<>
                    <button type="button" className="btn btn-success m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenFinish() }}>โอนเหรียญค่าซ่อมทั้งหมด</button>
                </>)}
            </div>

            <Dialog open={openCancle} onClose={handleCloseCancle} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ไม่ให้ซ่อม</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ไม่ให้ซ่อม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ flex: 1 }}>
                                                <option value="1">ราคาซ่อมสูงเกินงบที่ตั้งไว้</option>
                                                <option value="2">ใช้ระยะเวลานานเกินไป</option>
                                                <option value="3">มีการเรียกเก็บเงิน</option>
                                                <option value="4">ผู้เสนอไม่น่าเชื่อถือ</option>
                                                <option value="5">ไปถึงร้านฯ แต่ไม่เจอผู้เสนอฯ ตามวันเวลาที่นัด</option>
                                                <option value="6">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancle} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancle} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ไม่ให้ซ่อม</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirm} onClose={handleCloseConfirm} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันให้ซ่อมเครื่องใช้ไฟฟ้า</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันใช้บริการร้านนี้</h5>
                                    {depositEnough &&
                                    <h6><strong>{depositMessage}</strong></h6>
                                    }
                                    {!depositEnough &&
                                    <h6 className='text-danger'><strong>{depositMessage}</strong></h6>
                                    }
                                    <h6 className='text-danger'><strong>เมื่อยืนยัน จะถือว่าท่านยินยอมตามข้อเสนอการให้บริการ และราคาที่ผู้เสนอได้เสนอไว้ โดยไม่มีข้อโต้แย้งใด ๆ</strong></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseConfirm} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    {depositEnough && 
                    (<button onClick={handleConfirm} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันให้ซ่อมเครื่องใช้ไฟฟ้า</button>)
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirmReport} onClose={handleCloseConfirmReport}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันรับมอบงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันรับมอบงานนี้</h5>
                                    <h6 className='text-danger'><strong>หากยืนยันการรับงานแล้ว จะไม่สามารถยกเลิกได้</strong></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseConfirmReport} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleConfirmReport} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันรับมอบงาน</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancleReport} onClose={handleCloseCancleReport}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ไม่รับมอบงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ไม่รับมอบงาน</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5, paddingLeft: 10, paddingRight: 10 }}>
                                                <option value="1">ไปถึงที่ร้านฯ แต่ไม่เจอผู้ขาย/ไม่มาส่งสินค้าถึงที่พัก ตามวันเวลาที่นัด</option>
                                                <option value="2">มีการเรียกเก็บเงินเพิ่ม</option>
                                                <option value="3">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleReport} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleReport} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการให้บริการ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openFinish} onClose={handleCloseFinish}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันโอนเหรียญให้ร้่านค้า</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันโอนเหรียญให้ร้านค้า</h5>
                                    {remainEnough &&
                                    <h6><strong>{remainMessage}</strong></h6>
                                    }
                                    {!remainEnough &&
                                    <h6 className='text-danger'><strong>{remainMessage}</strong></h6>
                                    }
                                    <h6 className='text-danger'><strong>หากยืนยันการรับงานแล้ว จะไม่สามารถยกเลิกได้</strong></h6>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                        <label className="" style={{ display: 'flex'}}><h5><strong>คะแนน</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                            <select name='score' onChange={(e) => setScore(e.target.value)} style={{ flex: 1 }}>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                        <label className="" style={{ display: 'flex', width: 200}}><h5><strong>คำติชมเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='คำติชมเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseFinish} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    {remainEnough && 
                    (<button onClick={handleFinish} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันโอนเหรียญให้ร้่านค้า</button>)
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}