import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, getDoc, doc, updateDoc, addDoc } from 'firebase/firestore'
import { useParams, useNavigate } from "react-router-dom";

import { AiFillInfoCircle } from "react-icons/ai"
import { IoMegaphoneOutline } from 'react-icons/io5'

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import { StarRating } from '../helper/StarHelper'

import LeftMenu from './LeftMenu'

import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import LeftMenuPost from './LeftMenuPost'
import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const PostsFix = () => {
    const params = useParams()
    const postId = params.id
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [openDrawer, setOpenDrawer] = useState(false)

    const [user, loading, error] = useAuthState(auth)
    const [isShop, setIsShop] = useState(0)

    const [offerList, setOfferList] = useState([])
    const [offerFreeList, setOfferFreeList] = useState([])
    
    const [post, setPost] = useState([])

    const [hasAir1, setHasAir1] = useState(false)
    const [brandName1, setBrandName1] = useState('')
    const [modelName1, setModelName1] = useState('')
    const [air1BTU, setAir1BTU] = useState('')
    const [air1Amount, setAir1Amount] = useState(0)

    const [hasAir2, setHasAir2] = useState(false)
    const [brandName2, setBrandName2] = useState('')
    const [modelName2, setModelName2] = useState('')
    const [air2BTU, setAir2BTU] = useState('')
    const [air2Amount, setAir2Amount] = useState(0)

    const [hasAir3, setHasAir3] = useState(false)
    const [brandName3, setBrandName3] = useState('')
    const [modelName3, setModelName3] = useState('')
    const [air3BTU, setAir3BTU] = useState('')
    const [air3Amount, setAir3Amount] = useState(0)

    const [showLoading, setShowLoading] = useState(true)

    const [openCancleOffers, setOpenCancleOffers] = useState(false)
    const [cancleMessage, setCancleMessage] = useState('');
    const [cancleReason, setCancleReason] = useState(1);

    const [openChooseOffersShop, setOpenChooseOffersShop] = useState(false)
    const [chooseOfferId, setChooseOfferId] = useState('')
    const [chooseShopId, setChooseShopId] = useState('')
    const [chooseShopName, setChooseShopName] = useState('')
 
    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const getUser = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then(async(docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                if(data.is_shop) setIsShop(data.is_shop)
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }

    const getPost = () => {
        setShowLoading(true)
        getDoc(doc(db, 'cleans', postId)).then((postDoc) => {
            if (postDoc.exists()) {
                if(postDoc.data().have_air1) {

                    setHasAir1(true)

                    if(postDoc.data().air1_brand_id) {
                        if(postDoc.data().air1_brand_id == '999') {
                            if(postDoc.data().air1_brand) {
                                setBrandName1(postDoc.data().air1_brand)
                            } else {
                                setBrandName1("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_brands`, postDoc.data().air1_brand_id)).then((doc) => {
                                setBrandName1(doc.data().name)
                            })
                        }
                    } else {
                        if(postDoc.data().air1_brand) {
                            setBrandName1(postDoc.data().air1_brand)
                        } else {
                            setBrandName1("ไม่ระบุ")
                        }
                    }

                    if(postDoc.data().air1_model_id) {
                        if(postDoc.data().air1_model_id == '999') {
                            if(postDoc.data().air1_model) {
                                setModelName1(postDoc.data().air1_model)
                            } else {
                                setModelName1("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_models`, postDoc.data().air1_model_id)).then((doc) => {
                                setModelName1(doc.data().name)
                            })
                        }
                    } else  {
                        if(postDoc.data().air1_model) {
                            setModelName1(postDoc.data().air1_model)
                        } else {
                            setModelName1("ไม่ระบุ")
                        }
                    }

                    setAir1BTU(postDoc.data().air1_BTU)
                    setAir1Amount(postDoc.data().air1_amount)
                }

                if(postDoc.data().have_air2) {

                    setHasAir2(true)

                    if(postDoc.data().air2_brand_id) {
                        if(postDoc.data().air2_brand_id == '999') {
                            if(postDoc.data().air2_brand) {
                                setBrandName2(postDoc.data().air2_brand)
                            } else {
                                setBrandName2("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_brands`, postDoc.data().air2_brand_id)).then((doc) => {
                                setBrandName2(doc.data().name)
                            })
                        }
                    } else  {
                        if(postDoc.data().air2_brand) {
                            setBrandName2(postDoc.data().air2_brand)
                        } else {
                            setBrandName2("ไม่ระบุ")
                        }
                    }
                    if(postDoc.data().air2_model_id) {
                        if(postDoc.data().air2_model_id == '999') {
                            if(postDoc.data().air2_model) {
                                setModelName2(postDoc.data().air2_model)
                            } else {
                                setModelName2("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_models`, postDoc.data().air2_model_id)).then((doc) => {
                                setModelName2(doc.data().name)
                            })
                        }
                    } else  {
                        if(postDoc.data().air2_model) {
                            setModelName2(postDoc.data().air2_model)
                        } else {
                            setModelName2("ไม่ระบุ")
                        }
                    }

                    setAir2BTU(postDoc.data().air2_BTU)
                    setAir2Amount(postDoc.data().air2_amount)

                }

                if(postDoc.data().have_air3) {

                    setHasAir3(true)

                    if(postDoc.data().air3_brand_id) {
                        if(postDoc.data().air3_brand_id == '999') {
                            if(postDoc.data().air3_brand) {
                                setBrandName3(postDoc.data().air3_brand)
                            } else {
                                setBrandName3("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_brands`, postDoc.data().air3_brand_id)).then((doc) => {
                                setBrandName3(doc.data().name)
                            })
                        }
                    } else {
                        if(postDoc.data().air3_brand) {
                            setBrandName3(postDoc.data().air3_brand)
                        } else {
                            setBrandName3("ไม่ระบุ")
                        }
                    }
                    if(postDoc.data().air3_model_id) {
                        if(postDoc.data().air3_model_id == '999') {
                            if(postDoc.data().air3_model) {
                                setModelName3(postDoc.data().air3_model)
                            } else {
                                setModelName3("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_models`, postDoc.data().air3_model_id)).then((doc) => {
                                setModelName3(doc.data().name)
                            })
                        }
                    } else {
                        if(postDoc.data().air3_model) {
                            setModelName3(postDoc.data().air3_model)
                        } else {
                            setModelName3("ไม่ระบุ")
                        }
                    }

                    setAir3BTU(postDoc.data().air3_BTU)
                    setAir3Amount(postDoc.data().air3_amount)

                }

                setPost(postDoc.data())
                setShowLoading(false)
            } else {
                console.log("Post doesn't exist")
                navigate('/')
            }
        }).then(() => {
            offerList.splice(0, offerList.length)
            setOfferList([...offerList])
            offerFreeList.splice(0, offerFreeList.length)
            setOfferFreeList([...offerFreeList])
            getDocs(query(collection(db, `cleans/${postId}/offers`), 
                            orderBy('offer_timestamp', 'asc'))
            ).then((offerDocs) => {
                offerDocs.docs.map((offer) => {
                    getDoc(doc(db, 'shops', offer.data().shop_id)).then((shop) => {
                        if(offer.data().offer_travel == '2' || offer.data().offer_travel == '3') {
                            offerList.push({
                                id: offer.id,
                                data: offer.data(),
                                shopId: offer.data().shop_id,
                                shop: shop.data()
                            })
                            setOfferList([...offerList]) 
                        } else {
                            offerFreeList.push({
                                id: offer.id,
                                data: offer.data(),
                                shopId: offer.data().shop_id,
                                shop: shop.data()
                            })
                            setOfferFreeList([...offerFreeList]) 
                        }
                    })
                })
            })
        }).then(() => {
            setShowLoading(false)
        })
    }

    const handleOpenChooseOffer = (offerId, shopId, shopName) => {
        setChooseOfferId(offerId)
        setChooseShopId(shopId)
        setChooseShopName(shopName)
        setOpenChooseOffersShop(true)
    }

    const handleCloseChooseOffersShop = () => {
        setOpenChooseOffersShop(false)
    }

    const handleChooseOffer = () => {
        const offerId = chooseOfferId
        const shopId = chooseShopId
        setShowLoading(true)
        updateDoc(doc(db, `/cleans`, postId), { status: 3 }).then(() => {
            getDocs(query(collection(db, `/cleans/${postId}/offers`))).then((offerDocs) => {
                var promisesTask = []
                offerDocs.forEach((docRef) => {
                    if(docRef.data().status != 7 && docRef.data().status != 5) {
                        var updateTask = updateDoc(doc(db, `/cleans/${postId}/offers`, docRef.id), { status: 4 }).then(() => {
                            getDocs(query(collection(db, `/shops/${docRef.data().shop_id}/offers`), where('offer_id', '==', docRef.id))).then((offerShop) => {
                                offerShop.forEach((offer) => {
                                    updateDoc(doc(db, `/shops/${docRef.data().shop_id}/offers`, offer.id), { status: 4 })
                                })
                            })
                        })
                    }
                    promisesTask.push(updateTask)
                })

                Promise.all(promisesTask).then(() => {
                    updateDoc(doc(db, `/cleans/${postId}/offers/`, offerId), { status: 2 }).then(() => {
                        getDocs(query(collection(db, `/shops/${shopId}/offers`), where('offer_id', '==', offerId))).then((offerShop) => {
                            offerShop.forEach((docRef) => {
                                updateDoc(doc(db, `/shops/${shopId}/offers`, docRef.id), { status: 2 }).then(() => {
                                    const date = new Date()
                                    addDoc(collection(db, `/shops/${shopId}/notifications`), {
                                        date: date.getTime(),
                                        message: "ยินดีด้วย ข้อเสนอชองคุณได้รับการเลือก",
                                        link: "/shop/offers",
                                        type: "offer",
                                        color: 'green',
                                        board: 'clean',
                                        post_id: postId,
                                        offer_id: offerId,
                                        status: 0
                                    }).then(() => {
                                        setShowLoading(false)
                                        navigate(0)
                                    })
                                })
                            })
                        })
                    })
                })
            })
        })
    }

    const handleCancleOffer = () => {
        setShowLoading(true)
        getDocs(query(collection(db, `cleans/${postId}/offers`))).then((offerDocs) => {
            var promisesTask = []
            offerDocs.forEach((offer) => {
                if(offer.data().status == 2) {
                    var updateTask = updateDoc(doc(db, `cleans/${postId}/offers`, offer.id), { 
                        status: 7, 
                        cancle_reason: cancleReason,
                        cancle_message: cancleMessage
                    }).then(() => {
                        getDocs(query(collection(db, `/shops/${offer.data().shop_id}/offers`), where('post_id', '==', postId))).then((offerShop) => {
                            offerShop.forEach((docRef) => {
                                updateDoc(doc(db, `/shops/${offer.data().shop_id}/offers`, docRef.id), { status: 7 })
                            })
                        })
                    })
                } else if(offer.data().status == 4) {
                    var updateTask = updateDoc(doc(db, `cleans/${postId}/offers`, offer.id), { status: 1 }).then(() => {
                        getDocs(query(collection(db, `/shops/${offer.data().shop_id}/offers`), where('post_id', '==', postId))).then((offerShop) => {
                            offerShop.forEach((docRef) => {
                                updateDoc(doc(db, `/shops/${offer.data().shop_id}/offers`, docRef.id), { status: 1 })
                            })
                        })
                    })
                } else if(offer.data().status == 5) {
                } else if(offer.data().status == 7) {
                } else {
                    var updateTask = updateDoc(doc(db, `cleans/${postId}/offers`, offer.id), { status: 1 }).then(() => {
                        getDocs(query(collection(db, `/shops/${offer.data().shop_id}/offers`), where('post_id', '==', postId))).then((offerShop) => {
                            offerShop.forEach((docRef) => {
                                updateDoc(doc(db, `/shops/${offer.data().shop_id}/offers`, docRef.id), { status: 1 })
                            })
                        })
                    })
                }
                promisesTask.push(updateTask)
            })

            Promise.all(promisesTask).then(() => {
                getDocs(query(collection(db, `cleans/${postId}/offers`), where('status', '==', 1))).then((offerDocs) => {
                    if(offerDocs.docs.length > 0) {
                        updateDoc(doc(db, `cleans`, postId), { status: 2 }).then(() => {
                            navigate(0)
                        })
                    } else {
                        updateDoc(doc(db, `cleans`, postId), { status: 1 }).then(() => {
                            navigate(0)
                        })
                    }
                })
            })
        })
    }

    const handleOpenCancleOffer = () => {
        setOpenCancleOffers(true)
    }

    const handleCloseCancleOffer = () => {
        setOpenCancleOffers(false)
    }

    const styleLoading = {
        position: 'absolute',
        top: '25%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return 
        if (!user) {
            return navigate("/") 
        } else { getUser() }
        setShowLoading(true)
        getPost()
    }, [user, loading]);

    return (
        <>
        <Modal
            open={showLoading}
            onClose={() => { }}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={styleLoading}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                Loading
                </Typography>
                <LinearProgress style={{ marginTop: 20}} />
            </Box>
        </Modal>
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 280 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenuPost currentPage="post" isShop={isShop} postType={5} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 10, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ paddingLeft: 20, marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4>ประกาศของฉัน {">>"} แจ้งความต้องการล้างแอร์ {">>"} <strong>ข้อเสนอจากร้านค้า</strong></h4></div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                            <LeftMenu currentPage="post" isShop={isShop} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', flex: 1}}>
                                <div className="card" style={{ marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}>
                                    <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'row'}} >
                                        <p className="card-text" style={{ marginRight: 20 }}>
                                            {(hasAir1) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์ผนัง</strong><br/></font>
                                            <font style={{ fontSize: 17 }}><strong>{brandName1} {modelName1}</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(air1BTU)} BTU<br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(air1Amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                        </p>
                                        <p className="card-text" style={{ marginRight: 20 }}>
                                            {(hasAir2) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์แขวนใต้ฝ้า</strong><br/></font>
                                            <font style={{ fontSize: 17 }}><strong>{brandName2} {modelName2}</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(air2BTU)} BTU<br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(air2Amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                        </p>
                                        <p className="card-text">
                                            {(hasAir3) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์ฝังฝ้า 4 ทิศทาง</strong><br/></font>
                                            <font style={{ fontSize: 17 }}><strong>{brandName3} {modelName3}</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(air3BTU)} BTU<br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(air3Amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                        </p>
                                    </div>
                                </div>

                                {offerFreeList.length > 0 &&
                                <div className="card" style={{ display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 5, marginRight: 5, marginBottom: 10 }}>
                                    <div style={{ marginLeft: 10, marginTop: 10 }}><font style={{ fontSize: 21 }}><strong>ร้านค้าที่ไม่คิดค่าเดินทาง</strong></font></div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">ร้านค้า</th>
                                                <th scope="col">ข้อเสนอ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            offerFreeList?.map((offer, i) => (
                                                <>
                                                <tr key={i}>
                                                    <th scope="row"> 
                                                    </th>
                                                    <td>
                                                        {(offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname}<br/>
                                                        {offer.data.status == 1 && (<>
                                                        <button onClick={() => { handleOpenChooseOffer(offer.id, offer.shopId, (offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname) }} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>เลือกข้อเสนอ</button><br/>
                                                        </>)}
                                                        {offer.data.status == 2 && (<>
                                                        <font className="text-success" style={{ fontSize: 18 }}><strong>คุณเลือกข้อเสนอนี้แล้ว</strong></font>
                                                        <button onClick={() => handleOpenCancleOffer() } className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิกข้อเสนอ</button><br/>
                                                        </>)}
                                                        {offer.data.status == 5 && (<>
                                                        <font className="text-danger" style={{ fontSize: 18 }}><strong>ร้านค้ายกเลิกข้อเสนอ</strong></font>
                                                        </>)}
                                                        {offer.data.status == 7 && (<>
                                                        <font className="text-danger" style={{ fontSize: 18 }}><strong>คุณได้ยกเลิกข้อเสนอนี้แล้ว</strong></font>
                                                        </>)}
                                                        <StarRating score={offer.shop.score_total?offer.shop.score_total:0} />
                                                        <a href={`/shop/history/${offer.shopId}`} target='_blank'><AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} /></a>
                                                    </td>
                                                    <td>
                                                        <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.</p>
                                                        <p>
                                                            {(hasAir1) && 
                                                            <>
                                                            <font style={{ fontSize: 17 }}><strong>ราคาล้างแอร์ผนัง</strong><br/></font>
                                                            <font style={{ fontSize: 17 }}><strong>{brandName1} {modelName1}</strong><br/></font>
                                                            <font style={{ fontSize: 19 }}>ราคา: {number_format.format(offer.data.offer_price_air1)} บาท<br/></font>
                                                            </>
                                                            }
                                                            {(hasAir2) && 
                                                            <>
                                                            <font style={{ fontSize: 17 }}><strong>ราคาล้างแอร์แขวนใต้ฝ้า</strong><br/></font>
                                                            <font style={{ fontSize: 17 }}><strong>{brandName2} {modelName2}</strong><br/></font>
                                                            <font style={{ fontSize: 19 }}>ราคา: {number_format.format(offer.data.offer_price_air2)} บาท<br/></font>
                                                            </>
                                                            }
                                                            {(hasAir3) && 
                                                            <>
                                                            <font style={{ fontSize: 17 }}><strong>ราคาล้างแอร์ฝังฝ้า 4 ทิศทาง</strong><br/></font>
                                                            <font style={{ fontSize: 17 }}><strong>{brandName3} {modelName3}</strong><br/></font>
                                                            <font style={{ fontSize: 19 }}>ราคา: {number_format.format(offer.data.offer_price_air3)} บาท<br/></font>
                                                            </>
                                                            }

                                                        </p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <th scope="row"> 
                                                    </th>
                                                    <td colSpan={2}>
                                                        <font style={{ fontSize: 16 }}>
                                                            <strong>การรับประกัน: </strong>
                                                            {offer.data.offer_garuntee == '1' && "ซ่อมฟรีทุกกรณี"}
                                                            {offer.data.offer_garuntee == '2' && "ฟรีค่าแรง แต่เก็บค่าอะไหล่ (ถ้ามี)"}
                                                            {offer.data.offer_garuntee == '3' && "เก็บค่าแรง และค่าอะไหล่ (ถ้ามี)"}
                                                        </font><br/>
                                                        <font style={{ fontSize: 16 }}>
                                                            <strong>ระยะรับประกัน: </strong> {offer.data.offer_garuntee_day} วัน
                                                        </font><br/>
                                                        <font style={{ fontSize: 16 }}>
                                                            <strong>การมัดจำงาน: </strong>
                                                            {offer.data.offer_deposit == '1' && "เมื่อเริ่มดำเนินการ ไม่คืนค่ามัดจำทุกกรณี"}
                                                            {offer.data.offer_deposit == '2' && "เมื่อรับงานแล้ว แต่ดำเนินการไม่เสร็จส้ิน คืนค่ามัดจำทั้งหมด"}
                                                            {offer.data.offer_deposit == '3' && "หากไม่ได้ดำเนินการยินดีคืนค่ามัดจำเต็มจำนวน"}
                                                        </font><br/>
                                                        <font style={{ fontSize: 16 }}>
                                                            <strong>การคิดค่าเดินทาง: </strong>
                                                            {offer.data.offer_travel == '1' && "ฟรีค่าเดินทาง ไม่เก็บค่าเดินทางในทุกกรณี"}
                                                            {offer.data.offer_travel == '2' && "เก็บค่าเดินทางในทุกกรณี"}
                                                            {offer.data.offer_travel == '3' && "เก็บค่าเดินทางเมื่อได้ดำเนินการเท่านั้น"}
                                                            {(offer.data.offer_travel == '2' || offer.data.offer_travel == '3') && " ราคา "+number_format.format(offer.data.offer_travel_price)+" บาท"}
                                                        </font><br/>
                                                        <font style={{ fontSize: 16 }}>
                                                            <strong>ขั้นตอนการดำเนินงาน: </strong>
                                                        </font><br/>
                                                        <font style={{ fontSize: 15 }}>
                                                            {offer.data.offer_step1 && (<>- ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (ก่อนล้าง)<br/></>)}
                                                            {offer.data.offer_step2 && (<>- ปิดเบรกเกอร์แอร์<br/></>)}
                                                            {offer.data.offer_step3 && (<>- ปูผ้าใบกันเปื้อนรอบบริเวณพื้นที่ที่ต้องการล้างทำความสะอาดแอร์<br/>ตรวจสอบการทำงานของแอร์ บานสวิง วัดอุณหภูมิและกระแสไฟฟ้า<br/></>)}
                                                            {offer.data.offer_step4 && (<>- ล้างทำความสะอาดแผงฟิลคอยด์<br/></>)}
                                                            {offer.data.offer_step5 && (<>- ล้างทำความสะอาดแผ่นฟิลเตอร์<br/></>)}
                                                            {offer.data.offer_step6 && (<>- ล้างทำความสะอาดพัดลมกรงกระรอก<br/></>)}
                                                            {offer.data.offer_step7 && (<>- ดูดและเป่าท่อน้ำทิ้ง<br/></>)}
                                                            {offer.data.offer_step8 && (<>- ทำความสะอาดตัวเครื่อง (FAN COIL)<br/></>)}
                                                            {offer.data.offer_step9 && (<>- เป่าไล่ความชื้นในส่วนต่าง ๆ<br/></>)}
                                                            {offer.data.offer_step10 && (<>- ฉีดสเปรย์ฆ่าเชื้อโรคแผงฟิลคอยล์<br/></>)}
                                                            {offer.data.offer_step11 && (<>- ล้างทำความสะอาดคอยล์ร้อน<br/></>)}
                                                            {offer.data.offer_step12 && (<>- ตรวจสอบแผงควบคุม และการทำงานของเครื่อง<br/></>)}
                                                            {offer.data.offer_step13 && (<>- ตรวจวัดอุณหภูมิความเย็น<br/></>)}
                                                            {offer.data.offer_step14 && (<>- ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (หลังล้าง)<br/></>)}
                                                            {offer.data.offer_step15 && (<>- ตรวจสอบสายกราวน์และเช็คจุดต่อสายไฟ<br/></>)}
                                                            {offer.data.offer_step16 && (<>- เปิดเครื่องทดสอบประมาณ 15 นาที พร้อมตรวจสอบการทางานของแอร์<br/>เช่น ไฟหน้าเครื่อง ฟังเสียง อุณหภูมิ บานสวิง มีอาการน้ำหยดหรือไม่<br/></>)}
                                                        </font>
                                                    </td>
                                                </tr>
                                                </>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }

                                {offerList.length > 0 &&
                                <div className="card" style={{ display: 'flex', flexDirection: 'column', flex: 1, marginLeft: 5, marginRight: 5, marginBottom: 10 }}>
                                    <div style={{ marginLeft: 10, marginTop: 10 }}><font style={{ fontSize: 21 }}><strong>ร้านค้าที่คิดค่าเดินทาง</strong></font></div>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">ร้านค้า</th>
                                                <th scope="col">ข้อเสนอ</th>
                                                <th scope="col">ข้อตกลง</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            offerList?.map((offer, i) => (
                                            <tr key={i}>
                                                <th scope="row"> 
                                                </th>
                                                <td>
                                                    {(offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname}<br/>
                                                    {offer.data.status == 1 && (<>
                                                    <button onClick={() => { handleOpenChooseOffer(offer.id, offer.shopId, (offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname) }} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>เลือกข้อเสนอ</button><br/>
                                                    </>)}
                                                    {offer.data.status == 2 && (<>
                                                    <font className="text-success"  style={{ fontSize: 18 }}><strong>คุณเลือกข้อเสนอนี้แล้ว</strong></font>
                                                    <button onClick={() => handleOpenCancleOffer()} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิกข้อเสนอ</button><br/>
                                                    </>)}
                                                    {offer.data.status == 5 && (<>
                                                    <font className="text-danger" style={{ fontSize: 18 }}><strong>ร้านค้ายกเลิกข้อเสนอ</strong></font>
                                                    </>)}
                                                    {offer.data.status == 7 && (<>
                                                    <font className="text-danger" style={{ fontSize: 18 }}><strong>คุณได้ยกเลิกข้อเสนอนี้แล้ว</strong></font>
                                                    </>)}
                                                    <a href={`/shop/history/${offer.shopId}`} target='_blank'><AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} /></a>
                                                </td>
                                                <td>
                                                    <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.</p>
                                                    <p>
                                                        {(hasAir1) && 
                                                        <>
                                                        <font style={{ fontSize: 17 }}><strong>ราคาล้างแอร์ผนัง</strong><br/></font>
                                                        <font style={{ fontSize: 17 }}><strong>{brandName1} {modelName1}</strong><br/></font>
                                                        <font style={{ fontSize: 19 }}>ราคา: {number_format.format(offer.data.offer_price_air1)} บาท<br/></font>
                                                        </>
                                                        }
                                                        {(hasAir2) && 
                                                        <>
                                                        <font style={{ fontSize: 17 }}><strong>ราคาล้างแอร์แขวนใต้ฝ้า</strong><br/></font>
                                                        <font style={{ fontSize: 17 }}><strong>{brandName2} {modelName2}</strong><br/></font>
                                                        <font style={{ fontSize: 19 }}>ราคา: {number_format.format(offer.data.offer_price_air2)} บาท<br/></font>
                                                        </>
                                                        }
                                                        {(hasAir3) && 
                                                        <>
                                                        <font style={{ fontSize: 17 }}><strong>ราคาล้างแอร์ฝังฝ้า 4 ทิศทาง</strong><br/></font>
                                                        <font style={{ fontSize: 17 }}><strong>{brandName3} {modelName3}</strong><br/></font>
                                                        <font style={{ fontSize: 19 }}>ราคา: {number_format.format(offer.data.offer_price_air3)} บาท<br/></font>
                                                        </>
                                                        }

                                                    </p>
                                                </td>
                                                <td>
                                                    <font style={{ fontSize: 16 }}>
                                                        <strong>การรับประกัน: </strong>
                                                        {offer.data.offer_garuntee == '1' && "ซ่อมฟรีทุกกรณี"}
                                                        {offer.data.offer_garuntee == '2' && "ฟรีค่าแรง แต่เก็บค่าอะไหล่ (ถ้ามี)"}
                                                        {offer.data.offer_garuntee == '3' && "เก็บค่าแรง และค่าอะไหล่ (ถ้ามี)"}
                                                    </font><br/>
                                                    <font style={{ fontSize: 16 }}>
                                                        <strong>ระยะรับประกัน: </strong> {offer.data.offer_garuntee_day} วัน
                                                    </font><br/>
                                                    <font style={{ fontSize: 16 }}>
                                                        <strong>การมัดจำงาน: </strong>
                                                        {offer.data.offer_deposit == '1' && "เมื่อเริ่มดำเนินการ ไม่คืนค่ามัดจำทุกกรณี"}
                                                        {offer.data.offer_deposit == '2' && "เมื่อรับงานแล้ว แต่ดำเนินการไม่เสร็จส้ิน คืนค่ามัดจำทั้งหมด"}
                                                        {offer.data.offer_deposit == '3' && "หากไม่ได้ดำเนินการยินดีคืนค่ามัดจำเต็มจำนวน"}
                                                    </font><br/>
                                                    <font style={{ fontSize: 16 }}>
                                                        <strong>การคิดค่าเดินทาง: </strong>
                                                        {offer.data.offer_travel == '1' && "ฟรีค่าเดินทาง ไม่เก็บค่าเดินทางในทุกกรณี"}
                                                        {offer.data.offer_travel == '2' && "เก็บค่าเดินทางในทุกกรณี"}
                                                        {offer.data.offer_travel == '3' && "เก็บค่าเดินทางเมื่อได้ดำเนินการเท่านั้น"}
                                                        {(offer.data.offer_travel == '2' || offer.data.offer_travel == '3') && " ราคา "+number_format.format(offer.data.offer_travel_price)+" บาท"}
                                                    </font><br/>
                                                    <font style={{ fontSize: 16 }}>
                                                        <strong>ขั้นตอนการดำเนินงาน: </strong>
                                                    </font><br/>
                                                    <font style={{ fontSize: 15 }}>
                                                        {offer.data.offer_step1 && (<>- ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (ก่อนล้าง)<br/></>)}
                                                        {offer.data.offer_step2 && (<>- ปิดเบรกเกอร์แอร์<br/></>)}
                                                        {offer.data.offer_step3 && (<>- ปูผ้าใบกันเปื้อนรอบบริเวณพื้นที่ที่ต้องการล้างทำความสะอาดแอร์<br/>ตรวจสอบการทำงานของแอร์ บานสวิง วัดอุณหภูมิและกระแสไฟฟ้า<br/></>)}
                                                        {offer.data.offer_step4 && (<>- ล้างทำความสะอาดแผงฟิลคอยด์<br/></>)}
                                                        {offer.data.offer_step5 && (<>- ล้างทำความสะอาดแผ่นฟิลเตอร์<br/></>)}
                                                        {offer.data.offer_step6 && (<>- ล้างทำความสะอาดพัดลมกรงกระรอก<br/></>)}
                                                        {offer.data.offer_step7 && (<>- ดูดและเป่าท่อน้ำทิ้ง<br/></>)}
                                                        {offer.data.offer_step8 && (<>- ทำความสะอาดตัวเครื่อง (FAN COIL)<br/></>)}
                                                        {offer.data.offer_step9 && (<>- เป่าไล่ความชื้นในส่วนต่าง ๆ<br/></>)}
                                                        {offer.data.offer_step10 && (<>- ฉีดสเปรย์ฆ่าเชื้อโรคแผงฟิลคอยล์<br/></>)}
                                                        {offer.data.offer_step11 && (<>- ล้างทำความสะอาดคอยล์ร้อน<br/></>)}
                                                        {offer.data.offer_step12 && (<>- ตรวจสอบแผงควบคุม และการทำงานของเครื่อง<br/></>)}
                                                        {offer.data.offer_step13 && (<>- ตรวจวัดอุณหภูมิความเย็น<br/></>)}
                                                        {offer.data.offer_step14 && (<>- ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (หลังล้าง)<br/></>)}
                                                        {offer.data.offer_step15 && (<>- ตรวจสอบสายกราวน์และเช็คจุดต่อสายไฟ<br/></>)}
                                                        {offer.data.offer_step16 && (<>- เปิดเครื่องทดสอบประมาณ 15 นาที พร้อมตรวจสอบการทางานของแอร์<br/>เช่น ไฟหน้าเครื่อง ฟังเสียง อุณหภูมิ บานสวิง มีอาการน้ำหยดหรือไม่<br/></>)}
                                                    </font>
                                                </td>
                                            </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            
            <Dialog open={openChooseOffersShop} onClose={handleCloseChooseOffersShop} maxWidth="md">
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>เลือกข้อเสนอ</strong></h3></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', marginTop: 10, flexDirection: 'column', width:'100%', marginBottom: 15 }}>
                                <div style={{ backgroundColor: '#ffffff'}}>
                                    <h5>คุณต้องการเลือกข้อเสนอของร้าน <strong>{chooseShopName}</strong></h5>
                                    <h6 className='text-danger'><strong>หากท่านเลือกผู้บริการ จะถือว่าท่านให้ความยินยอมตามข้อเสนอ<br/>และเงื่อนไขของผเู้สนอให้บริการอย่างไม่มีเงื่อนไขใดๆ และยินดีจะปฏิบัติตามที่ได้กำหนดไว้</strong></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseChooseOffersShop} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleChooseOffer} className='btn btn-success' style={{ fontFamily: 'Prompt' }}>ยืนยันรับข้อเสนอ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancleOffers} onClose={handleCloseCancleOffer}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการเรียกใช้งาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                <option value="1">ผู้เสนอบริการตอบกลับช้า</option>
                                                <option value="2">ต้องการเปลี่ยนผู้เสนอบริการรายใหม่</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setCancleMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={cancleMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleOffer} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleOffer} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการเรียกใช้งาน</button>
                </DialogActions>
            </Dialog>
        </div>
        </>
    )
}

export default PostsFix;