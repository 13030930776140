import { doc, getDoc } from "firebase/firestore"

export async function getBrand(db, brandId, brandOther) {
    var brand = 'ไม่ระบุ'
    if(brandId) {
        if(brandId == '999' && brandOther) {
            brand = brandOther
        } else {
            await getDoc(doc(db, `brands`, brandId)).then((brandDoc) => {
                brand = brandDoc.data().name
            })
        }
    } else {
        if(brandOther) { brand = brandOther }
    }

    return brand
}

export async function getModel(db, modelId, modelOther) {
    var model = 'ไม่ระบุ'
    if(modelId) {
        if(modelId == '999' && modelOther) {
            model = modelOther
        } else {
            await getDoc(doc(db, `models`, modelId)).then((modelDoc) => {
                model = modelDoc.data().name
            })
        }
    } else {
        if(modelOther) { model = modelOther }
    }

    return model
}