import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth, deleteUser, signInWithEmailAndPassword } from "../../assets/js/firebase"
import { doc, getDoc, deleteDoc } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom"
import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material';

import { BsPersonVcard } from "react-icons/bs"
import { FiEdit } from "react-icons/fi"
import { IoCloseCircle, IoChevronBackCircleSharp } from "react-icons/io5"
import { RiAdminLine } from "react-icons/ri"

const Admin = () => {
    const params = useParams()
    const userId = params.id
    const navigate = useNavigate()
    const [admin, loading, error] = useAuthState(auth)

    const [user, setUser] = useState([])

    const [openAlertDelete, setOpenAlertDelete] = useState(false)

    const handleCloseAlertDelete = () => {
        setOpenAlertDelete(false)
    }

    const deleteAdmin = async () => {
        const currentUser = admin
        const email = user.email
        const password = user.password
        
        signInWithEmailAndPassword(auth, email, password).then((result) => {
            const delUser = result.user
            deleteUser(delUser).then(() => {
                deleteDoc(doc(db, `admins`, userId)).then(() => {
                    auth.updateCurrentUser(currentUser)
                    setOpenAlertDelete(false)
                    navigate(`/admin/users/`)
                })
            })
        })
    }

    const getDocUser = async(id) => {
        await getDoc(doc(db, 'admins', id)).then((user) => {
            setUser(user.data())
        })
    }

    useEffect(() => {
        getDocUser(userId)
    },[loading])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><RiAdminLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>ข้อมูลผู้ดูแลระบบ</b></h4>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/users/`) }}>
                            <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/user/edit/${userId}`) }}>
                            <FiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขข้อมูล
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDelete(true) }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบผู้ดูแลระบบ
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                        <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                            <h6><BsPersonVcard style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อมูลส่วนตัว</b></h6>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>อีเมล</strong></label>
                                <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{user.email?user.email:""}</div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อผู้ใช้</strong></label>
                                <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{user.display_name?user.display_name:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อ-นามสกุล</strong></label>
                                <div style={{ display: 'flex', flex: 1, marginTop: 8 }}><font style={{ marginRight: 10 }}>{user.firstname?user.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font> <font>{user.lastname?user.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font></div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>เบอร์โทรศัพท์</strong></label>
                                <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{user.tel_no?user.tel_no:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>สำเนาบัตรประชาชน</strong></label>
                                <div style={{ display: 'flex', flex: 1, marginTop: 8 }}>{user.id_card?(<a href={user.id_card} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><BsPersonVcard style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>):(<font className="text-danger">ไม่ระบุ</font>)}</div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={openAlertDelete} onClose={handleCloseAlertDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบผู้ดูแลระบบท่านนี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDelete} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { deleteAdmin() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Admin;