import React, { useState, useEffect } from 'react'
import { collection, query, where, getDocs, orderBy, onSnapshot, getDoc, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { db, storage, auth } from "../../assets/js/firebase"
import { useNavigate } from "react-router-dom"
import { Loading } from "../../../sites/components/layouts/Loading"
import { FaPowerOff } from "react-icons/fa6"
import FormGroup from '@mui/material/FormGroup'
import FormControlLabel from '@mui/material/FormControlLabel'
import Switch from '@mui/material/Switch'
import './ToggleButton.css'

const Setting = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [offer, setOffer] = useState(true)
    const [queue, setQueue] = useState(true)
    const [spare, setSpare] = useState(true)
    const [technician, setTechnician] = useState(true)

    const getSettings = () => {
        getDoc(doc(db, 'settings', 'functions')).then((result) => {
            const data = result.data()
            if(data.offer) { setOffer(true) } else { setOffer(false) }
            if(data.queue) { setQueue(true) } else { setQueue(false) }
            if(data.spare) { setSpare(true) } else { setSpare(false) }
            if(data.technician) { setTechnician(true) } else { setTechnician(false) }
        })
    }

    const handleChangeOffer = () => {
        var newValue = !offer
        setOffer(newValue)
        updateDoc(doc(db, 'settings', 'functions'), {
            offer: newValue,
        })
    }

    const handleChangeQueue = () => {
        var newValue = !queue
        setQueue(newValue)
        updateDoc(doc(db, 'settings', 'functions'), {
            queue: newValue,
        })
    }

    const handleChangeSpare = () => {
        var newValue = !spare
        setSpare(newValue)
        updateDoc(doc(db, 'settings', 'functions'), {
            spare: newValue,
        })
    }

    const handleChangeTech = () => {
        var newValue = !technician
        setTechnician(newValue)
        updateDoc(doc(db, 'settings', 'functions'), {
            technician: newValue,
        })
    }

    useEffect(() => {
        getSettings()
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <Loading open={showProgressBar} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><FaPowerOff style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>เปิด/ปิดการทำงานของระบบ</b></h4>
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
                <button onClick={handleChangeOffer} className={`toggle-button ${offer ? 'on' : 'off'}`}>
                    {offer ? 'ON' : 'OFF'}
                </button>
                <font style={{ fontSize: 20, marginLeft: 5 }}>ระบบยื่นข้อเสนอ</font>
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
                <button onClick={handleChangeTech} className={`toggle-button ${technician ? 'on' : 'off'}`}>
                    {technician ? 'ON' : 'OFF'}
                </button>
                <font style={{ fontSize: 20, marginLeft: 5 }}>ระบบจัดการช่าง</font>
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
                <button onClick={handleChangeQueue} className={`toggle-button ${queue ? 'on' : 'off'}`}>
                    {queue ? 'ON' : 'OFF'}
                </button>
                <font style={{ fontSize: 20, marginLeft: 5 }}>ระบบจัดการคิวช่าง</font>
            </div>
            
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
                <button onClick={handleChangeSpare} className={`toggle-button ${spare ? 'on' : 'off'}`}>
                    {spare ? 'ON' : 'OFF'}
                </button>
                <font style={{ fontSize: 20, marginLeft: 5 }}>ระบบจัดการอะไหล่</font>
            </div>

        </div>
    )
}

export default Setting