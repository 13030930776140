import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, getDocs, addDoc, updateDoc, doc, getDoc, onSnapshot, orderBy, where, deleteDoc } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom"

import { FiEdit } from "react-icons/fi"
import { IoCloseCircle, IoChevronBackCircleSharp } from "react-icons/io5"
import { AiFillShop, AiOutlineWarning, AiOutlineCheckCircle, AiFillInfoCircle } from "react-icons/ai"

import { getBoartStatus } from '../helper/PostStatusHelper'
import { getBrand, getModel } from '../helper/BrandAirHelper'
import { getBroken } from '../helper/BrokenHelper'
import { MessageToMember, MessageToShopOffer, MessageToShopAppointment } from '../layouts/SendMessageFromAdmin'
import { Alert } from '../layouts/Alert'

import dayjs from 'dayjs'

const Post = () => {
    const params = useParams()
    const postId = params.id
    const navigate = useNavigate()
    const today = dayjs()
    const number_format = new Intl.NumberFormat()

    const [air1Brand, setAir1Brand] = useState('ไม่ระบุ')
    const [air2Brand, setAir2Brand] = useState('ไม่ระบุ')
    const [air3Brand, setAir3Brand] = useState('ไม่ระบุ')

    const [air1Model, setAir1Model] = useState('ไม่ระบุ')
    const [air2Model, setAir2Model] = useState('ไม่ระบุ')
    const [air3Model, setAir3Model] = useState('ไม่ระบุ')


    const [post, setPost] = useState([])
    const [postMemberId, setPostMemberId] = useState('')
    const [postMember, setPostMember] = useState([])
    const [images, setImages] = useState([])
    const [brandName, setBrandName] = useState('ไม่ระบุ')
    const [modelName, setModelName] = useState('ไม่ระบุ')
    const [showImage, setShowImages] = useState('')
    const [address, setAddress] = useState('')
    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [subdistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [postcode, setPostcode] = useState('')
    const [status, setStatus] = useState(0)

    const [postOverDue, setPostOverDue] = useState(false)
    const [postOverDueDiffer, setPostOverDueDiffer] = useState(0)



    const [air1IsOver3M, setAir1IsOver3M] = useState(false)
    const [air1Over3MAmount, setAir1Over3MAmount] = useState(0)
    const [air1IsBelow3M, setAir1IsBelow3M] = useState(false)
    const [air1Below3MAmount, setAir1Below3MAmount] = useState(0)
    const [air1IsProblem1, setAir1IsPloblem1] = useState(false)
    const [air1IsProblem2, setAir1IsPloblem2] = useState(false)
    const [air1IsProblem3, setAir1IsPloblem3] = useState(false)
    const [air1IsProblem4, setAir1IsPloblem4] = useState(false)
    const [air1IsProblem5, setAir1IsPloblem5] = useState(false)
    const [air1ShowImages, setAir1ShowImages] = useState('')
    const [air1Images, setAir1Images] = useState([])

    const [air2IsOver3M, setAir2IsOver3M] = useState(false)
    const [air2Over3MAmount, setAir2Over3MAmount] = useState(0)
    const [air2IsBelow3M, setAir2IsBelow3M] = useState(false)
    const [air2Below3MAmount, setAir2Below3MAmount] = useState(0)
    const [air2IsProblem1, setAir2IsPloblem1] = useState(false)
    const [air2IsProblem2, setAir2IsPloblem2] = useState(false)
    const [air2IsProblem3, setAir2IsPloblem3] = useState(false)
    const [air2IsProblem4, setAir2IsPloblem4] = useState(false)
    const [air2IsProblem5, setAir2IsPloblem5] = useState(false)
    const [air2ShowImages, setAir2ShowImages] = useState('')
    const [air2Images, setAir2Images] = useState([])

    const [air3IsOver3M, setAir3IsOver3M] = useState(false)
    const [air3Over3MAmount, setAir3Over3MAmount] = useState(0)
    const [air3IsBelow3M, setAir3IsBelow3M] = useState(false)
    const [air3Below3MAmount, setAir3Below3MAmount] = useState(0)
    const [air3IsProblem1, setAir3IsPloblem1] = useState(false)
    const [air3IsProblem2, setAir3IsPloblem2] = useState(false)
    const [air3IsProblem3, setAir3IsPloblem3] = useState(false)
    const [air3IsProblem4, setAir3IsPloblem4] = useState(false)
    const [air3IsProblem5, setAir3IsPloblem5] = useState(false)
    const [air3ShowImages, setAir3ShowImages] = useState('')
    const [air3Images, setAir3Images] = useState([])





    const [offers, setOffers] = useState([])
    const [offerKeys, setOfferKeys] = useState([])
    const [appointment, setAppointment] = useState([])
    const [showAppointment, setShowAppointment] = useState(false)

    const [lat, setLat] = useState(15.11745249400088)
    const [lng, setLng] = useState(104.90284046686465)
    const [loading, error] = useAuthState(auth)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    })
    const center = { lat: lat, lng: lng }

    const [showLoading, setShowLoading] = useState(true)

    const [deleteOfferId, setDeleteOfferId] = useState(0)
    const [deleteShopId, setDeleteShopId] = useState(0)

    const [openMessageToMember, setOpenMessageToMember] = useState(false)

    const [openMessageToShop, setOpenMessageToShop] = useState(false)
    const [chooseOffer, setChooseOffer] = useState('')
    const [shopNameOffer, setShopNameOffer] = useState('')
    const [chooseShopId, setChooseShopId] = useState('')
    const [chooseAppointmentId, setChooseAppointmentId] = useState('')

    const [openMessageToShopAppointment, setOpenMessageToShopAppointment] = useState(false)
    const [chooseAppointment, setChooseAppointment] = useState('')
    const [shopNameAppointment, setShopNameAppointment] = useState('')

    const [openAlertDisable, setOpenAlertDisable] = useState(false)
    const [openAlertCancleDisable, setOpenAlertCancleDisable] = useState(false)

    const [openAlertDeleteOffer, setOpenAlertDeleteOffer] = useState(false)
    const [deleteOfferMessage, setDeleteOfferMessage] = useState('')

    const [openAlertDeletePost, setOpenAlertDeletePost] = useState(false)

    const dateFormat = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }
 
    const dateFormatTime = (datetime) => {
        const date = (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
        const time_string = new Date(datetime).toLocaleTimeString("th-TH")
        const time = time_string.split(':')
        return date+" เวลา "+time[0]+":"+time[1]+" น."
    }

    const getImages = async (id) => {
        const imagesQuery = query(collection(db, `cleans/${id}/images`))
        onSnapshot(imagesQuery, (querySnapshot) => {
            setImages(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleCloseMessageToMember = () => {
        setOpenMessageToMember(false)
    }

    const handleOpenMessageToMember = () => {
        setOpenMessageToMember(true)
    }

    const handleCloseMessageToShop = () => {
        setChooseOffer('')
        setChooseShopId('')
        setShopNameOffer('')
        setOpenMessageToShop(false)
    }

    const handleOpenMessageToShop = (offer) => {
        setChooseOffer(offer)
        setChooseShopId(offer.shop_id)
        const shopName = (offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname
        setShopNameOffer(shopName)
        setOpenMessageToShop(true)
    }

    const handleCloseMessageToShopAppointment = () => {
        setChooseAppointment('')
        setShopNameAppointment('')
        setChooseAppointmentId('')
        setOpenMessageToShopAppointment(false)
    }

    const handleOpenMessageToShopAppointment = (appointment) => {
        setChooseAppointment(appointment)
        setChooseAppointmentId(appointment.id)
        const shopName = (appointment.shop.type==1)?appointment.shop.firstname+" "+appointment.shop.lastname:appointment.shop.shopname
        setShopNameAppointment(shopName)
        setOpenMessageToShopAppointment(true)
    }

    const getPost = (id) => {
        getDoc(doc(db, 'cleans', id)).then((postDoc) => {
            if (postDoc.exists()) {
                setPost(postDoc.data())
                setShowImages(postDoc.data().picture)
                setLat(postDoc.data().lat)
                setLng(postDoc.data().lng)

                if(postDoc.data().have_air1) {
                    const imagesQuery = query(collection(db, `cleans/${id}/images1`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setAir1Images(querySnapshot.docs.map((doc, i) => { 
                            if(i == 0) setAir1ShowImages(doc.data().url)
                            return {
                                id: doc.id,
                                data: doc.data(),
                            }
                        }))
                    })
                    getBrand(db, postDoc.data().air1_brand_id, postDoc.data().air1_brand).then((brandName) => {
                        setAir1Brand(brandName)
                    })
                    getModel(db, postDoc.data().air1_model_id, postDoc.data().air1_model).then((modelName) => {
                        setAir1Model(modelName)
                    })
                }

                if(postDoc.data().have_air2) {
                    const imagesQuery = query(collection(db, `cleans/${id}/images2`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setAir2Images(querySnapshot.docs.map((doc, i) => { 
                            if(i == 0) setAir2ShowImages(doc.data().url)
                            return {
                                id: doc.id,
                                data: doc.data(),
                            }
                        }))
                    })
                    getBrand(db, postDoc.data().air2_brand_id, postDoc.data().air2_brand).then((brandName) => {
                        setAir2Brand(brandName)
                    })
                    getModel(db, postDoc.data().air2_model_id, postDoc.data().air2_model).then((modelName) => {
                        setAir2Model(modelName)
                    })
                }


                if(postDoc.data().have_air3) {
                    const imagesQuery = query(collection(db, `cleans/${id}/images3`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setAir3Images(querySnapshot.docs.map((doc, i) => { 
                            if(i == 0) setAir3ShowImages(doc.data().url)
                            return {
                                id: doc.id,
                                data: doc.data(),
                            }
                        }))
                    })
                    getBrand(db, postDoc.data().air3_brand_id, postDoc.data().air3_brand).then((brandName) => {
                        setAir3Brand(brandName)
                    })
                    getModel(db, postDoc.data().air3_model_id, postDoc.data().air3_model).then((modelName) => {
                        setAir3Model(modelName)
                    })
                }

                setAddress(postDoc.data().address)
                setAddressNo(postDoc.data().address_no)
                setVillage(postDoc.data().village)
                setBuilding(postDoc.data().building)
                setFloor(postDoc.data().floor)
                setRoom(postDoc.data().room)
                setSubroad(postDoc.data().subroad)
                setRoad(postDoc.data().road)

                var promisesTask = []

                if(postDoc.data().province) {
                    const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', postDoc.data().province * 1))).then((docs) => {
                        if(docs.docs.length > 0) setProvince(docs.docs[0].data().province_name)
                    })
                    promisesTask.push(provinceTask)
                }

                if(postDoc.data().district) {
                    const districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', postDoc.data().district))).then((docs) => {
                        if(docs.docs.length > 0) setDistrict(docs.docs[0].data().district_name)
                    })
                    promisesTask.push(districtTask)
                }

                if(postDoc.data().sub_district) {
                    const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', postDoc.data().sub_district * 1))).then((docs) => {
                        if(docs.docs.length > 0) setSubDistrict(docs.docs[0].data().subdistrict_name)
                    })
                    promisesTask.push(subdistrictTask)
                }

                setPostcode(postDoc.data().postcode)

                var memberDocs = getDoc(doc(db, `users`, postDoc.data().poster_id)).then((memberData) => {
                    setPostMemberId(memberData.id)
                    setPostMember(memberData.data())
                })
                promisesTask.push(memberDocs)

                Promise.all(promisesTask)

                getImages(id)
            } else {
                console.log("Post doesn't exist")
                navigate('/')
            }
            setShowLoading(false)
        }).then(() => {
            getDocs(query(collection(db, `cleans/${postId}/offers`), orderBy('offer_timestamp', 'asc'))).then((offerQuery) => {
                offers.splice(0, offers.length)
                setOffers([...offers])
                offerKeys.splice(0, offerKeys.length)
                setOfferKeys([...offerKeys])
                offerQuery.forEach(async(offer) => {
                    getDoc(doc(db, 'shops', offer.data().shop_id)).then(async (shopSnapshot) => {
                        if(shopSnapshot.exists) {
                            if(offerKeys.lastIndexOf(offer.id) < 0) {
                                offerKeys.push(offer.id)
                                var historyDocs = await getDocs(query(collection(db, `shops/${offer.data().shop_id}/cancle_history`)))
                                var offerOverDue = false
                                var todayToDate = today.toDate()
                                var offerToDate = offer.data().pick_date.toDate()
                                var todayDate = new Date(todayToDate.getFullYear(), todayToDate.getMonth(), todayToDate.getDate())
                                var offerDate = new Date(offerToDate.getFullYear(), offerToDate.getMonth(), offerToDate.getDate())
                                var overDueDay = 0
                                if(todayDate > offerDate) { 
                                    offerOverDue = true
                                    const diffTime = Math.abs(todayDate - offerDate);
                                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                                    overDueDay = diffDays
                                }
                                
                                offers.push({
                                    id: offer.id,
                                    data: offer.data(),
                                    over_due: offerOverDue,
                                    over_due_days: overDueDay,
                                    shop_id: offer.data().shop_id,
                                    shop: shopSnapshot.data(),
                                    shop_history: historyDocs
                                })
                                setOffers([...offers], {
                                    id: offer.id,
                                    data: offer.data(),
                                    over_due: offerOverDue,
                                    over_due_days: overDueDay,
                                    shop_id: offer.data().shop_id,
                                    shop: shopSnapshot.data(),
                                    shop_history: historyDocs
                                })
                            }
                        }
                    })
                })
            })
        }).then(() => {
            getDocs(query(collection(db, `appointments`), where('post_id', '==', postId))).then(async(appointmentQuery) => {
                if(appointmentQuery.docs.length > 0) {
                    var todayToDate = today.toDate()
                    var appointmentToDate = appointmentQuery.docs[0].data().appointment_date.toDate()
                    var todayDate = new Date(todayToDate.getFullYear(), todayToDate.getMonth(), todayToDate.getDate())
                    var appointmentDate = new Date(appointmentToDate.getFullYear(), appointmentToDate.getMonth(), appointmentToDate.getDate())
                    var appointmentOverDue = false
                    var overDueDay = 0
                    if(todayDate > appointmentDate) { 
                        appointmentOverDue = true
                        const diffTime = Math.abs(todayDate - appointmentDate);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                        overDueDay = diffDays
                    }
                    var shopDocs = await getDoc(doc(db, `shops`, appointmentQuery.docs[0].data().shop_id))
                    setAppointment({
                        id: appointmentQuery.docs[0].id,
                        data: appointmentQuery.docs[0].data(),
                        appointment_over_due: appointmentOverDue,
                        over_due_days: overDueDay,
                        shop: shopDocs
                    })
                    setShowAppointment(true)
                }
            })
        }) 
    }

    const disablePost = () => {
        updateDoc(doc(db, `/cleans/`, postId), { status: 5, before_disabled: status }).then(() => {
            setOpenAlertDisable(false)
            navigate(0)
        })
    }

    const handleCloseAlertDisable = () => {
        setOpenAlertDisable(false)
    }

    const cancleDisablePost = () => {
        updateDoc(doc(db, `/cleans/`, postId), { status: post.before_disabled }).then(() => {
            setOpenAlertCancleDisable(false)
            navigate(0)
        })
    }

    const handleCloseAlertCancleDisable = () => {
        setOpenAlertDisable(false)
    }

    const deleteOffer = () => {
        getDocs(query(collection(db, `shops/${deleteShopId}/offers`), where('offer_id', '==', deleteOfferId))).then((offerSnapshot) => {
            offerSnapshot.forEach((offer) => {
                deleteDoc(doc(db, `shops/${deleteShopId}/offers`, offer.id))
            })
        }).then(() => {
            deleteDoc(doc(db, `cleans/${postId}/offers`, deleteOfferId)).then(() => {
                if(post.status == 1 || post.status == 2) {
                    getDocs(query(collection(db, `cleans/${postId}/offers`), where("status", "!=", 5))).then((docs) => {
                        var status = 1
                        if (docs.docs.length > 0) { status = 2 }
                        updateDoc(doc(db, `cleans/`, postId), {
                            status: status
                        }).then(() => {
                            navigate(0)
                        })
                    })
                } else {
                    navigate(0)
                }
            })
        })
    }

    const handleOpenAlertDeleteOffer = (offer) => {
        setDeleteOfferId(offer.id)
        setDeleteShopId(offer.shop_id)
        const shopName = (offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname
        setDeleteOfferMessage("คุณต้องการลบข้อเสนอของร้าน "+shopName+" ใช่หรือไม่?")
        setOpenAlertDeleteOffer(true)
    } 

    const handleCloseAlertDeleteOffer = () => {
        setDeleteOfferId('')
        setDeleteShopId('')
        setDeleteOfferMessage('')
        setOpenAlertDeleteOffer(false)
    }

    const deletePost = () => {
        getDocs(query(collection(db, `cleans/${postId}/offers`))).then((offerSnapshot) => {
            offerSnapshot.forEach((offer) => {
                getDocs(query(collection(db, `shops/${offer.data().shop_id}/offers`), where('offer_id', '==', offer.id))).then((shopSnapshot) => {
                    shopSnapshot.forEach((shopOffer) => {
                        deleteDoc(doc(db, `shops/${offer.data().shop_id}/offers`, shopOffer.id))
                    })
                })
            })
        }).then(() => {
            deleteDoc(doc(db, `cleans`, postId)).then(() => {
                navigate(`/admin/posts/`)
            })
        })
    }

    const handleCloseAlertDeletePost = () => {
        setOpenAlertDeletePost(false)
    }

    useEffect(() => {
        getPost(postId)
    },[loading])
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>ข้อมูลประกาศแจ้งล้างเครื่องปรับอากาศ</b></h4>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(-1) }}>
                            <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenMessageToMember() }}>
                            <FiEdit style={{ fontSize: 20, marginRight: 5 }} />ส่งข้อความหาผู้ประกาศ
                        </button>
                    </div>
                    {(post.status != 5) &&
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDisable(true) }}>
                            <AiOutlineWarning style={{ fontSize: 20, marginRight: 5 }} />ระงับประกาศ
                        </button>
                    </div>
                    }
                    {(post.status == 5) &&
                    (<><div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertCancleDisable(true) }}>
                            <AiOutlineCheckCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิกการระงับ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDeletePost(true) }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบประกาศ
                        </button>
                    </div></>)
                    }
                </div>
                <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingTop: 10, flex: 4 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ marginRight: 5 }}>
                                <h4><span className="badge bg-success" style={{ marginRight: 5 }}>แจ้งล้างเครื่องปรับอากาศ</span></h4>
                            </div>
                            <div style={{ marginRight: 5 }}>
                                <h4>
                                    {(post.status == 1)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus('air', 1, 'admin')}</span>)}
                                    {(post.status == 2)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus('air', 2, 'admin')}</span>)}
                                    {(post.status == 3)&&(<span className="badge bg-warning" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus('air', 3, 'admin')}</span>)}
                                    {(post.status == 4)&&(<span className="badge bg-warning" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus('air', 4, 'admin')}</span>)}
                                    {(post.status == 5)&&(<span className="badge bg-danger" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus('air', 5, 'admin')}</span>)}
                                    {(post.status == 6)&&(<span className="badge bg-danger" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus('air', 6, 'admin')}</span>)}
                                    {(post.status == 9)&&(<span className="badge bg-success" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus('air', 9, 'admin')}</span>)}
                                </h4>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <h4 style={{ display: 'flex', flexDirection: 'row'}}>
                                <strong>{post.type} {brandName} {modelName}</strong>
                            </h4>
                        </div>
                        {(appointment.id) && 
                            (<div><h5><strong>วันที่นัดหมาย:</strong> {dateFormat(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น.</h5></div>)
                        }
                        <div style={{ marginTop: 5 }}>
                            <h6><strong>ผู้ประกาศ :</strong> {postMember.firstname} {postMember.lastname} ({postMember.email})</h6>
                            <a href={`/admin/member/view/${postMemberId}`} target='_blank' className="nav-link link-primary"><AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} className='text-primary' /> ดูข้อมูลผู้ประกาศ</a>
                        </div>


                        <div style={{ marginTop: 10 }}>
                        {(post.have_air1) && 
                            <>
                            <font style={{ fontSize: 17 }}><strong>บริการติดตั้งแอร์ผนัง</strong><br/></font>
                            <font style={{ fontSize: 17 }}><strong>{air1Brand} {air1Model}</strong><br/></font>
                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(post.air1_BTU)} BTU<br/></font>
                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.air1_amount)} เครื่อง<br/></font>

                        <div className="col-xs-12 offset-md-2 col-md-4">
                            <div className="card">
                                <img className="card-img-top" src={air1ShowImages} alt="WEEE" width="350" />
                                <div className="card-body mx-auto">
                                    <div className="d-flex flex-wrap">
                                        {
                                            air1Images?.map((image, i) => (
                                                <div key={image.id} className="m-2">
                                                    <img src={image.data.url} alt="WEEE" width="60" onClick={() => { setAir1ShowImages(image.data.url) }} />
                                                </div>
                                            ))
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                            </>
                            }
                            {(post.have_air2) && 
                            <>
                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์แขวนใต้ฝ้า</strong><br/></font>
                            <font style={{ fontSize: 17 }}><strong>{air2Brand} {air2Model}</strong><br/></font>
                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(post.air2_BTU)} BTU<br/></font>
                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.air2_amount)} เครื่อง<br/></font>
                            </>
                            }
                            {(post.have_air3) && 
                            <>
                            <font style={{ fontSize: 17 }}><strong>บริการล้างแอร์ฝังฝ้า 4 ทิศทาง</strong><br/></font>
                            <font style={{ fontSize: 17 }}><strong>{air3Brand} {air3Model}</strong><br/></font>
                            <font style={{ marginLeft: 10 }}>ขนาด: {number_format.format(post.air3_BTU)} BTU<br/></font>
                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.air3_amount)} เครื่อง<br/></font>
                            </>
                            }
                        </div>
                        <div style={{ marginTop: 10 }}><h6><strong>วันเวลาที่ลงประกาศ :</strong> {dateFormatTime(post.post_timestamp)}</h6></div> 
                        <div style={{ marginTop: 10 }}><h6><strong>ตำแหน่ง :</strong></h6></div>
                        <div style={{ marginTop: 5, height: 200 }}>
                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                            <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                <MarkerF
                                    draggable={false} position={{lat, lng}}
                                />
                            </GoogleMap>
                            )}
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <h6 style={{ marginBottom: 10 }}><strong>ที่อยู่ : </strong> </h6>
                            <h6 style={{ marginLeft: 10 }}>{(addressNo)?"บ้านเลขที่ "+addressNo+" ":""} 
                            {(village)?"หมู่บ้าน "+village+" ":""} 
                            {(building)?"อาคาร "+building+" ":""} 
                            {(floor)?"ชั้น "+floor+" ":""} 
                            {(room)?"ห้อง "+room+" ":""} 
                            {(subroad)?"ซอย "+subroad+" ":""} 
                            {(road)?"ถ."+road+" ":""} 
                            {(subdistrict)?subdistrict+" ":""} 
                            {(district)?district+" ":""} 
                            {(province)?province+" ":""} 
                            {(postcode)?postcode+" ":""}</h6>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <h6><strong>ที่อยู่เพิ่มเติม :</strong></h6>
                            <h6 style={{ marginLeft: 10 }}>{post.address?post.address:"ไม่ระบุ"}</h6>
                        </div>
                        <div style={{ marginTop: 10 }}><h6><strong>รูปภาพ :</strong></h6></div>
                        <div style={{ display:'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 15, marginBottom: 10, paddingBottom: 10, textAlign: 'left' }}>
                        {
                            images?.map((image, i) => (
                                <div key={image.id} style={{ margin: 5, minWidth: 100, maxWidth: 100}}>
                                    <img src={image.data.url} alt="WEEE" className='img-fluid' />
                                </div>
                            ))
                        }
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 5, borderLeft: '1px solid #cccccc', marginLeft: 30}}>
                        {(offers.length > 0) && 
                        (<div style={{ display:'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 20, paddingTop:10, textAlign: 'left' }}>
                            <h6><strong>ข้อเสนอ :</strong></h6>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {offers?.map((offer, i) => (
                                    <div className="card" style={{ flex: 1, minWidth: "16rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}  key={offer.id}>
                                        <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column' }} >
                                            <div>
                                                <h4>
                                                    {(offer.data.status == 1) && (<span className="badge bg-warning" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> รอเลือกข้อเสนอ</span>)}
                                                    {(offer.data.status == 2) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ข้อเสนอถูกเลือก</span>)}
                                                    {(offer.data.status == 3) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ยืนยันให้บริการ</span>)}
                                                    {(offer.data.status == 4) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ข้อเสนอไม่ถูกเลือก</span>)}
                                                    {(offer.data.status == 5) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้ายกเลิกข้อเสนอ</span>)}
                                                    {(offer.data.status == 6) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ผู้ประกาศยกเลิก</span>)}
                                                </h4>
                                            </div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>ร้านค้า:</strong> <br/>{(offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname}<br/>
                                            <a href={`/admin/shop/view/${offer.shop_id}`} target='_blank' className="nav-link link-primary"><AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} className='text-primary' /> ดูข้อมูลร้านค้า</a></div>
                                            
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>วันเวลาที่สะดวก:</strong> <br/>{dateFormat(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.</div>
                                            {/* <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>ราคาเสนอซ่อม:</strong> {number_format.format(offer.data.offer_price)} บาท</div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>ราคาเสนอซื้อซาก:</strong> {number_format.format(offer.data.offer_buy)} บาท</div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}>
                                                <strong>วิธีการรับซ่อม:</strong> 
                                                {offer.data.offer_fix=='1'?" บริการไปซ่อมในที่พักอาศัย":""}
                                                {offer.data.offer_fix=='2'?" บริการไปรับกลับมาซ่อมที่ร้าน":""}
                                                {offer.data.offer_fix=='3'?" ลูกค้ายกมาซ่อมที่ร้าน":""}
                                            </div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>ระยะเวลาที่ใช้ซ่อม:</strong> {offer.data.offer_fix_day} วัน</div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>ระยะรับประกัน:</strong> {offer.data.offer_garuntee} วัน</div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}>
                                                <strong>การมัดจำ:</strong>
                                                {offer.data.offer_agreement=='1'?" เมื่อเริ่มดำเนินการ ไม่คืนมัดจำทุกกรณี":""}
                                                {offer.data.offer_agreement=='2'?" เมื่อรับงานแล้ว แต่ดำเนินการไม่เสร็จสิ้น คืนค่ามัดจำทั้งหมด":""}
                                                {offer.data.offer_agreement=='3'?" หากไม่ได้ดำเนินการ ยินดีคืนค่ามัดจำเต็มจำนวน":""}
                                            </div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}>
                                                <strong>ค่าเดินทาง:</strong>
                                                {offer.data.offer_travel=='1'?" ฟรีค่าเดินทาง ไม่เก็บค่าเดินทางในทุกกรณี":""}
                                                {offer.data.offer_travel=='2'?(" เก็บค่าเดินทางในทุกกรณี "+(number_format.format(offer.data.offer_travel_price))+" บาท"):""}
                                                {offer.data.offer_travel=='3'?(" เก็บค่าเดินทางเมื่อได้ดำเนินการเท่านั้น "+(number_format.format(offer.data.offer_travel_price))+" บาท"):""}
                                            </div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}>
                                                <strong>ค่าแรงและค่าอะไหล่:</strong>
                                                {offer.data.offer_after_fix=='1'?" ซ่อมฟรีทุกกรณี":""}
                                                {offer.data.offer_after_fix=='2'?" ฟรีค่าแรง และเก็บค่าอะไหล่ (ถ้ามี)":""}
                                                {offer.data.offer_after_fix=='3'?" เก็บค่าแรง และเก็บค่าอะไหล่ (ถ้ามี)":""}
                                            </div> */}
                                            {(offer.over_due && (offer.data.status == 1 || offer.data.status == 2)) && 
                                                (<div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}>
                                                    <h6 className='text-danger'><strong>*** เลยวันที่กำหนดนัดซื้อขายไปแล้ว {offer.over_due_days} วัน ***</strong></h6>
                                                </div>)
                                            }
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>วันเวลาที่ยื่นข้อเสนอ:</strong> <br/>{dateFormatTime(offer.data.offer_timestamp)}</div>
                                            {(offer.data.status == 5) && (
                                                <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>วันเวลาที่ยกเลิกข้อเสนอ:</strong> <br/>{dateFormatTime(offer.data.offer_timestamp)}</div>
                                            )}
                                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <button style={{ marginRight: 10 }} type="button" className="btn btn-success btn-sm" onClick={() => { handleOpenMessageToShop(offer) }}>
                                                        <FiEdit style={{ fontSize: 20, marginRight: 5 }} /><strong>ส่งข้อความหาร้านค้า</strong>
                                                    </button>
                                                </div>
                                            {(post.status == 2 || post.status == 5) && 
                                                (<div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    <button type="button" className="btn btn-danger btn-sm" onClick={() => { handleOpenAlertDeleteOffer(offer) }}>
                                                        <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} /><strong>ลบข้อเสนอ</strong>
                                                    </button>
                                                </div>)
                                            }
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>)
                        }
                        {(offers.length == 0) && (
                            <div style={{ display:'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 20, paddingTop:10, textAlign: 'left' }}>
                                <h6><strong>ข้อเสนอ :</strong></h6>
                                <h6 style={{ marginLeft: 20 }}><strong className='text-danger'>ไม่มีข้อเสนอ</strong></h6>
                            </div>
                        )}
                        {showAppointment && <>
                            <div style={{ display:'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 20, paddingTop:10, textAlign: 'left' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginRight: 10 }}>
                                    <h6><strong>การนัดหมาย :</strong></h6>
                                    <div className="card" style={{ display:'flex', flexDirection: 'column', padding: 10, marginLeft: 5, marginRight: 5 }}>
                                        <div style={{ display:'flex' }}>
                                            <h4>
                                            {(appointment.data.status == 1) && (<span className="badge bg-warning" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> รอวันนัดหมาย</span>)}
                                            {(appointment.data.status == 2) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้ายืนยันการรับบริการ</span>)}
                                            {(appointment.data.status == 3) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ผู้ประกาศยืนยันให้ล้างแอร์</span>)}
                                            {(appointment.data.status == 4) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้าปฏิเสธการรับบริการ</span>)}
                                            {(appointment.data.status == 5) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ผู้ประกาศไม่ให้ล้างแอร์</span>)}
                                            {(appointment.data.status == 6) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้ารายงานการล้างแอร์แล้ว</span>)}
                                            {(appointment.data.status == 7) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ผู้ประกาศยืนยันรับรายงาน</span>)}
                                            {(appointment.data.status == 8) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้ายืนยันได้รับเหรียญ</span>)}
                                            {(appointment.data.status == 9) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ผู้ประกาศยกเลิกจ่ายค่าล้างแอร์</span>)}
                                            {(appointment.data.status == 10) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้ายกเลิกการรับเหรียญ</span>)}
                                            {(appointment.data.status == 11) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ผู้ประกาศยืนยันเสริ็จงาน</span>)}
                                            </h4>
                                        </div>
                                        <div style={{ marginBottom: 10, marginLeft: 10 }}><strong>วันนัดหมาย:</strong> {dateFormat(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น.</div>
                                        {appointment.data.status == 7 && (<div style={{ marginLeft: 10 }}>
                                            <div><strong>ผู้ประกาศมัดจำมาแล้ว 30% : {number_format.format(appointment.data.deposit_coin)} เหรียญ</strong></div>
                                        </div>)}
                                        {appointment.data.status == 11 && (<div style={{ marginLeft: 10 }}>
                                            <div><strong>ผู้ประกาศมัดจำเหรียญ 30% : {number_format.format(appointment.data.deposit_coin)} เหรียญ</strong></div>
                                            <div><strong>ผู้ประกาศจ่ายเหรียญทั้งหมด : {number_format.format(appointment.data.deposit_coin + appointment.data.remain_coin)} เหรียญ</strong></div>
                                        </div>)}

                                        {appointment.data.status == 5 && (<div style={{ marginLeft: 10 }}>
                                            <div><strong>วันที่ผู้ประกาศยกเลิก:</strong> {dateFormatTime(appointment.data.user_cancle_datetime)}</div>
                                            <div><strong>เหตุผลที่ยกเลิก :</strong> 
                                            {(appointment.data.user_cancle_reason == "1") && (" มารับไม่ตรงวันและเวลานัด")}
                                            {(appointment.data.user_cancle_reason == "2") && (" มีการเรียกรับเงินเพิ่ม")}
                                            {(appointment.data.user_cancle_reason == "3") && (" ความพร้อมหรือความน่าเชื่อถือของร้านค้า")}
                                            {(appointment.data.user_cancle_reason == "4") && (" อื่นๆ")}
                                            </div>
                                            <div><strong>เหตุผลเพิ่มเติม :</strong> {appointment.data.user_cancle_message}</div>
                                        </div>)}

                                        {appointment.data.status == 11 && (<div style={{ marginLeft: 10 }}>
                                            <div><strong>คะแนนจากผู้ประกาศ :</strong></div>
                                            <div style={{ marginLeft: 15 }}><strong>วันที่ให้คะแนน:</strong> {dateFormatTime(appointment.data.user_job_finish_datetime)}</div>
                                            <div style={{ marginLeft: 15 }}><strong>คะแนน:</strong> {appointment.data.score}</div>
                                            <div style={{ marginLeft: 15 }}><strong>คำติชม:</strong> {appointment.data.finish_message}</div>
                                            <div style={{ marginTop: 15 }}><strong>คะแนนจากร้านค้า :</strong></div>
                                            <div style={{ marginLeft: 15 }}><strong>วันที่ให้คะแนน:</strong> {dateFormatTime(appointment.data.shop_finish_datetime)}</div>
                                            <div style={{ marginLeft: 15 }}><strong>คะแนน:</strong> {appointment.data.score_for_user}</div>
                                            <div style={{ marginLeft: 15 }}><strong>คำติชม:</strong> {appointment.data.message_for_user}</div>
                                        </div>)}
                                        <div style={{ marginTop: 10, marginLeft: 10 }}>
                                            <button style={{ marginRight: 10 }} type="button" className="btn btn-success btn-sm" onClick={() => { handleOpenMessageToShopAppointment(appointment) }}>
                                                <FiEdit style={{ fontSize: 20, marginRight: 5 }} /><strong>ส่งข้อความหาร้านค้า</strong>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                {appointment.data.status >= 2 && 
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <h6><strong>บันทึกการตรวจสอบและซ่อม :</strong></h6>
                                    <div className="card" style={{ display:'flex', flexDirection: 'column', padding: 10, marginLeft: 5, marginRight: 5 }}>
                                        <div style={{ display:'flex', flexDirection: 'column' }}>
                                            {(appointment.data.status >= 2 && appointment.data.status != 4) && (<>
                                                <div><strong>ผลประเมินอาการเบื้องต้น :</strong> {appointment.data.before_check}</div>
                                                <div><strong>ผลการตรวจสภาพก่อนซ่อม :</strong> {appointment.data.before_result}</div>
                                                <div><strong>อาการสภาพก่อนซ่อม :</strong> {appointment.data.before_symptom}</div>
                                                <div><strong>ระยะเวลาที่ใช้ซ่อม :</strong> {appointment.data.fix_day} วัน</div>
                                                <div><strong>ราคาซ่อม :</strong> {number_format.format(appointment.data.fix_price)} บาท</div>
                                                <div><strong>วันที่ร้านยกเลิก:</strong> {dateFormatTime(appointment.data.accept_datetime)}</div>
                                            </>)}
                                            {(appointment.data.status >= 6) && (<>
                                                <div style={{ marginTop: 10 }}><strong>ประเมินการซ่อม :</strong> {appointment.data.fix_report_able==1?"ซ่อมได้":"ซ่อมไม่ได้"}</div>
                                                <div><strong>ค่าอะไหล่ :</strong> {number_format.format(appointment.data.fix_report_price)} บาท</div>
                                                <div><strong>รายงานเพิ่มเติม :</strong> {appointment.data.fix_report_description}</div>
                                                <div><strong>วันที่ร้านรายงานการประเมิน:</strong> {dateFormatTime(appointment.data.report_datetime)}</div>
                                            </>)}
                                            {(appointment.data.status >= 7) && (<>
                                                <div><strong>วันที่ผู้ประกาศรับรายงาน:</strong> {dateFormatTime(appointment.data.user_job_confirm_datetime)}</div>
                                            </>)}
                                            {appointment.data.status == 4 && (<>
                                                <div><strong>เหตุผลที่ยกเลิก :</strong> 
                                                {(appointment.data.shop_cancle_reason == "1") && (" ไม่นำเครื่องไฟฟ้ามาตามวันเวลาที่กำหนด")}
                                                {(appointment.data.shop_cancle_reason == "2") && (" อาการเสียหนักกว่าที่แจ้ง")}
                                                {(appointment.data.shop_cancle_reason == "3") && (" เครื่องมือ/อุปกรณ์หรืออะไหล่ไม่พร้อม")}
                                                {(appointment.data.shop_cancle_reason == "4") && (" มีข้อเรียกร้องมากกว่าที่แจ้ง")}
                                                {(appointment.data.shop_cancle_reason == "5") && (" ราคาซ่อมสูงกว่าที่ประเมินไว้")}
                                                {(appointment.data.shop_cancle_reason == "6") && (" ต้องใช้เวลาซ่อมนานกว่าที่ประเมินไว้")}
                                                {(appointment.data.shop_cancle_reason == "7") && (" ผู้ประกาศไม่น่าเชื่อถือ")}
                                                {(appointment.data.shop_cancle_reason == "8") && (" อื่นๆ")}
                                                </div>
                                                <div><strong>เหตุผลเพิ่มเติม :</strong> {appointment.data.shop_cancle_message}</div>
                                                <div><strong>วันที่ร้านยกเลิก:</strong> {dateFormatTime(appointment.data.shop_cancle_datetime)}</div>
                                            </>)}
                                        </div>
                                    </div>
                                </div>
                                }

                            </div>
                        </>}
                    </div>
                </div>
            </div>
            
            <Alert name="Disable Post"
                title={"คุณต้องการระงับประกาศนี้?"}
                text={""}
                open={openAlertDisable} 
                close={handleCloseAlertDisable}
                submit={disablePost} />

            <Alert name="Cancle Disable Post"
                title={"คุณต้องการยกเลิกระงับประกาศนี้?"}
                text={""}
                open={openAlertCancleDisable} 
                close={handleCloseAlertCancleDisable}
                submit={cancleDisablePost} />

            <Alert name="Delete Post"
                title={"คุณต้องการลบประกาศนี้?"}
                text={""}
                open={openAlertDeletePost} 
                close={handleCloseAlertDeletePost}
                submit={deletePost} />

            <Alert name="Delete Offer"
                title={"คุณต้องการลบข้อเสนอนี้?"}
                text={deleteOfferMessage}
                open={openAlertDeleteOffer} 
                close={handleCloseAlertDeleteOffer}
                submit={deleteOffer} />

            <MessageToShopAppointment
                to={shopNameAppointment}
                appointmentId={chooseAppointmentId}
                receiverId={chooseShopId}
                open={openMessageToShopAppointment} 
                close={handleCloseMessageToShopAppointment} />

            <MessageToShopOffer
                to={shopNameOffer}
                offerId={chooseOffer}
                receiverId={chooseShopId}
                open={openMessageToShop} 
                close={handleCloseMessageToShop} />

            <MessageToMember 
                to={postMember.firstname+" "+postMember.lastname} 
                postId={postId}
                receiverId={post.poster_id}
                open={openMessageToMember} 
                close={handleCloseMessageToMember} />
        </div>
    )
}

export default Post