import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, getDoc, doc } from 'firebase/firestore'
import { useParams, Link } from "react-router-dom"

import { FiInfo } from "react-icons/fi"

import { Loading } from "../layouts/Loading"
import { StarRating } from '../helper/StarHelper'

const OfferHistory = () => {
    const params = useParams()
    const shopId = params.id
    const [loading] = useAuthState(auth)
    const [offers, setOffers] = useState([])
    const [showProgressBar, setShowProgressBar] = useState(false)

    const [shopName, setShopName] = useState('')
    const [shop, setShop] = useState([])
    const [shopScore, setShopScore] = useState(0)
    const [shopScoreNum, setShopScoreNum] = useState(0)
 
    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }
 
    const dateFormatTime = (datetime) => {
        return (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const fetchPosts = async () => {
        setShowProgressBar(true)
        const order = []
        order.push(orderBy('pick_date', 'asc'))
        order.push(orderBy('hour', 'asc'))
        order.push(orderBy('minute', 'asc'))
        await getDocs(query(collection(db, `shops/${shopId}/offers/`), ...order)).then((offerDocs) => {
            offers.splice(0, offers.length)
            setOffers([...offers])
            offerDocs.forEach(async(offer) => {
                if(offer.data().post_type == 'post') {
                    var postSnapshot = await getDoc(doc(db, `posts/`, offer.data().post_id))
                } else {
                    var postSnapshot = await getDoc(doc(db, `cleans/`, offer.data().post_id))
                }
                if (postSnapshot.exists()) {
                    getDocs(query(collection(db, `appointments/`), 
                        where('post_id', '==', offer.data().post_id))).then((appointments) => {
                            var appointment = { isHave: false, data: [] }
                            if(appointments.docs.length > 0) {
                                appointment.isHave = true
                                appointment.data = appointments.docs[0].data()
                            }
                            offers.push({
                                id: offer.id,
                                data: offer.data(),
                                post: postSnapshot.data(),
                                appointment: appointment
                            })

                            setOffers([...offers], {
                                id: offer.id,
                                data: offer.data(),
                                post: postSnapshot.data(),
                                appointment: appointment
                            })
                    })
                }
            })
        }).then(() => {
            setShowProgressBar(false)
        })
    }

    const getShopDoc = () => {
        getDoc(doc(db, `shops/`, shopId)).then((shop) => {
            const shopData = shop.data()
            setShop(shopData)
            if(shopData.score_average) setShopScore(shopData.score_average)
            if(shopData.score_num) setShopScoreNum(shopData.score_num)
            if(shopData.type == 1) {
                setShopName(shopData.firstname+" "+shopData.lastname)
            } else {
                setShopName(shopData.shopname)
            }
        })
    }

    useEffect(() => {
        fetchPosts()
        getShopDoc() 
    }, [])

    var offerKey = []
    var offerList = []
    offers.forEach(element => {
        if(offerKey.lastIndexOf(element.id) < 0) {
            offerKey.push(element.id)
            offerList.push(element)
        }
    })

    return (
    <>
        <Loading open={showProgressBar} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 20}}>
            <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 0, marginRight: 20, marginBottom: 30 }}>
                
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ paddingLeft: 20, marginBottom: 10, borderBottom: '1px solid #cccccc' }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <h4><strong>รายการข้อเสนอของร้าน {shopName} </strong></h4> <StarRating fontSize={14} score={shopScore} /> ({shopScore} คะแนน/ {offerList.length} ข้อเสนอ)
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10}}>
                            {offerList?.map((offer, i) => (
                                <div className="card" style={{ width: "17rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={i}>
                                    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                        <img src={offer.post.picture} alt="APP3R" height="150" />
                                    </div>
                                    <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                        <p className="card-text">
                                            <strong>สถานะข้อเสนอ:</strong> 
                                            {offer.data.status == 1 && (<><font > รอผู้ประกาศเลือกข้อเสนอ</font></>)}
                                            {offer.data.status == 2 && (<><font className="text-success"> ข้อเสนอถูกเลือก</font></>)}
                                            {offer.data.status == 3 && (<><font className="text-success"> ร้านค้ายืนยันนัดหมาย</font></>)}
                                            {offer.data.status == 4 && (<><font className="text-danger"> ข้อเสนอไม่ถูกเลือก</font></>)}
                                            {offer.data.status == 5 && (<><font className="text-danger"> ร้านค้ายกเลิกข้อเสนอ</font></>)}
                                            {offer.data.status == 7 && (<><font className="text-danger"> ข้อเสนอถูกยกเลิก</font></>)}
                                            <br/>
                                            {offer.appointment.isHave && 
                                            (<><strong>สถานะการนัดหมาย:</strong> {offer.appointment.data.status}</>)
                                            }
                                        </p>
                                        <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong><br/>{dateFormatDate(offer.post.pick_date)} เวลา {offer.post.hour}:{offer.post.minute} น.</p>
                                        <p className="card-text"><strong>วันที่ประกาศ:</strong> {dateFormatTime(offer.post.post_timestamp)}</p>
                                    </div>
                                    <div className="card-body">
                                        <Link className="btn btn-primary m-1" to={`/post/${offer.data.post_id}`} target='_blank'><FiInfo /> <strong>ดูประกาศ</strong></Link>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default OfferHistory