import { doc, getDoc } from "firebase/firestore"

export async function getElectric(db, electricId, electricOther) {
    var electric = 'ไม่ระบุ'
    if(electricId) {
        if(electricId == '999' && electricOther) {
            electric = electricOther
        } else {
            await getDoc(doc(db, `electric_type`, electricId)).then((electricDoc) => {
                electric = electricDoc.data().name
            })
        }
    } else {
        if(electricOther) { 
            electric = electricOther
        }
    }

    return electric
}