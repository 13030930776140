import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, orderBy, onSnapshot, addDoc, updateDoc, doc } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import LeftMenu from './LeftMenu'
import { Loading } from "../layouts/Loading"
import { BsMegaphone } from "react-icons/bs"
import { TbAlertCircle } from "react-icons/tb"
import { IoMegaphoneOutline } from 'react-icons/io5'
import { ImCoinDollar } from "react-icons/im"
import { MdOutlineCalendarToday } from "react-icons/md"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { adsPositionAmount, adsPositionName } from '../../../assets/constants/ads'

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Appointments = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [user, loading] = useAuthState(auth)
    const [userId, setUserId] = useState('')
    const [shopId, setShopId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [showLoading, setShowLoading] = useState(true)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [activeNum, setActiveNum] = useState(0)
    const [activeList, setActiveList] = useState([])
    const [pendingNum, setPendingNum] = useState(0)
    const [pendingList, setPendingList] = useState([])
    const [requestNum, setRequestNum] = useState(0)
    const [requestList, setRequestList] = useState([])

    const [openShowAdsRequest, setOpenShowAdsRequest] = useState(false)
    const [adsDuration, setAdsDuration] = useState(0)
    const [adsTotalUseCoin, setAdsTotalUseCoin] = useState(0)
    const [adsPos3UseCoin, setAdsPos3UseCoin] = useState(0)
    const [adsPos4UseCoin, setAdsPos4UseCoin] = useState(0)
    const [adsPos5UseCoin, setAdsPos5UseCoin] = useState(0)
    const [adsPos8UseCoin, setAdsPos8UseCoin] = useState(0)
    const [adsPos9UseCoin, setAdsPos9UseCoin] = useState(0)
    const [adsPos10UseCoin, setAdsPos10UseCoin] = useState(0)
    const [adsExchangeCoin, setAdsExchangeCoin] = useState(0)
    const [adsPosition, setAdsPosition] = useState(3)
    const [useCoinErrorDiv, setUseCoinErrorDiv] = useState(false)

    const [adsDurationErrorDiv, setAdsDurationErrorDiv] = useState(false)
    const [adsQueuePos3, setAdsQueuePos3] = useState(0)
    const [finalDateAdsPos3, setFinalDateAdsPos3] = useState('')
    const [availableQueuePos3, setAvailableQueuePos3] = useState(false)
    const [adsQueuePos4, setAdsQueuePos4] = useState(0)
    const [finalDateAdsPos4, setFinalDateAdsPos4] = useState('')
    const [availableQueuePos4, setAvailableQueuePos4] = useState(false)
    const [adsQueuePos5, setAdsQueuePos5] = useState(0)
    const [finalDateAdsPos5, setFinalDateAdsPos5] = useState('')
    const [availableQueuePos5, setAvailableQueuePos5] = useState(false)
    const [adsQueuePos8, setAdsQueuePos8] = useState(0)
    const [finalDateAdsPos8, setFinalDateAdsPos8] = useState('')
    const [availableQueuePos8, setAvailableQueuePos8] = useState(false)
    const [adsQueuePos9, setAdsQueuePos9] = useState(0)
    const [finalDateAdsPos9, setFinalDateAdsPos9] = useState('')
    const [availableQueuePos9, setAvailableQueuePos9] = useState(false)
    const [adsQueuePos10, setAdsQueuePos10] = useState(0)
    const [finalDateAdsPos10, setFinalDateAdsPos10] = useState('')
    const [availableQueuePos10, setAvailableQueuePos10] = useState(false)

    const handleCalcleRequestAds = (requestId) => {
        getDoc(doc(db, `shops/${shopId}/ads/request/list/`, requestId)).then(async (adsData) => {
            updateDoc(doc(db, `shops/${shopId}/ads/request/list/`, requestId), { 
                status: 4
            }).then(() => {
                updateDoc(doc(db, `ads/request/position_${adsData.data().position}`, adsData.data().ads_id), { 
                    status: 4
                }).then(() => {
                    navigate(0)
                })
            })
        })
    }

    const handleCloseAdsRequest = () => {
        setOpenShowAdsRequest(false)
    }
    
    const handleOpenAdsRequest = () => {
        setOpenShowAdsRequest(true)
    }

    const handleAdsRequest = () => {
        if(validateAdsRequestForm()) {
            var usedCoinPerDay = adsPos3UseCoin * 1
            if(adsPosition == '4') { 
                usedCoinPerDay = adsPos4UseCoin * 1
            }
            if(adsPosition == '5') { 
                usedCoinPerDay = adsPos5UseCoin * 1
            }
            if(adsPosition == '8') { 
                usedCoinPerDay = adsPos8UseCoin * 1
            }
            if(adsPosition == '9') { 
                usedCoinPerDay = adsPos9UseCoin * 1
            }
            if(adsPosition == '10') { 
                usedCoinPerDay = adsPos10UseCoin * 1
            }
            const requestDate = new Date()
            
            addDoc(collection(db, `/ads/request/position_${adsPosition}`), {
                shop_id: shopId,
                used_coin_per_day: usedCoinPerDay,
                ads_duration: adsDuration * 1,
                total_used_coin: adsTotalUseCoin,
                request_timestamp: requestDate.getTime(),
                status: 1
            }).then((docRef) => {
                addDoc(collection(db, `shops/${shopId}/ads/request/list/`), {
                    ads_id: docRef.id,
                    position: adsPosition,
                    used_coin_per_day: usedCoinPerDay,
                    ads_duration: adsDuration * 1,
                    total_used_coin: adsTotalUseCoin,
                    request_timestamp: requestDate.getTime(),
                    status: 1
                }).then(() => {
                    navigate(0)
                })
            })
        }
    }

    const validateAdsRequestForm = () => {
        if(adsPosition == '3') {
            if((adsPos3UseCoin * 1) > (adsTotalUseCoin * 1)) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if(adsPosition == '4') {
            if((adsPos4UseCoin * 1) > (adsTotalUseCoin * 1)) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if(adsPosition == '5') {
            if((adsPos5UseCoin * 1) > (adsTotalUseCoin * 1)) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if(adsPosition == '8') {
            if((adsPos8UseCoin * 1) > (adsTotalUseCoin * 1)) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if(adsPosition == '9') {
            if((adsPos9UseCoin * 1) > (adsTotalUseCoin * 1)) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else {
            if((adsPos10UseCoin * 1) > (adsTotalUseCoin * 1)) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        }
        if(!adsDuration) {
            setAdsDurationErrorDiv(true)
            return false
        } else {
            setAdsDurationErrorDiv(false)
        }
        return true
    }

    const handlePickAdsPosition = (event) => {
        setAdsPosition(event.target.value)
        if(event.target.value == '3') {
            setAdsTotalUseCoin(adsDuration * adsPos3UseCoin)
        } else if(event.target.value == '4') {
            setAdsTotalUseCoin(adsDuration * adsPos4UseCoin)
        } else if(event.target.value == '5') {
            setAdsTotalUseCoin(adsDuration * adsPos5UseCoin)
        } else if(event.target.value == '8') {
            setAdsTotalUseCoin(adsDuration * adsPos8UseCoin)
        } else if(event.target.value == '9') {
            setAdsTotalUseCoin(adsDuration * adsPos9UseCoin)
        } else {
            setAdsTotalUseCoin(adsDuration * adsPos10UseCoin)
        }
    }
 
    const dateFormatTime = (datetime) => {
        return (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const getUseRate = () => {
        getDoc(doc(db, 'ads', 'ads_rate')).then((exchangeSnapshot) => {
            const data = exchangeSnapshot.data()
            if(data.position_3) setAdsPos3UseCoin(data.position_3)
            if(data.position_4) setAdsPos4UseCoin(data.position_4)
            if(data.position_5) setAdsPos5UseCoin(data.position_5)
            if(data.position_8) setAdsPos8UseCoin(data.position_8)
            if(data.position_9) setAdsPos9UseCoin(data.position_9)
            if(data.position_10) setAdsPos10UseCoin(data.position_10)
        })
    }

    const getListAds = () => {
        getDocs(query(collection(db, `ads/active/position_3`), orderBy('final_date', 'desc'))).then((adsDocs) => {
            if(!adsDocs.empty) {
                if(adsDocs.docs.length < adsPositionAmount[2]) {
                    setAvailableQueuePos3(true)
                    setAdsQueuePos3(adsDocs.docs.length)
                } else {
                    setFinalDateAdsPos3(dateFormatTime(adsDocs.docs[0].data().final_date))
                    setAvailableQueuePos3(false)
                }
            } else {
                setAvailableQueuePos3(true)
            }
        })
        getDocs(query(collection(db, `ads/active/position_4`), orderBy('final_date', 'desc'))).then((adsDocs) => {
            if(!adsDocs.empty) {
                if(adsDocs.docs.length < adsPositionAmount[3]) {
                    setAvailableQueuePos4(true)
                    setAdsQueuePos4(adsDocs.docs.length)
                } else {
                    setFinalDateAdsPos4(dateFormatTime(adsDocs.docs[0].data().final_date))
                    setAvailableQueuePos4(false)
                }
            } else {
                setAvailableQueuePos4(true)
            }
        })
        getDocs(query(collection(db, `ads/active/position_5`), orderBy('final_date', 'desc'))).then((adsDocs) => {
            if(!adsDocs.empty) {
                if(adsDocs.docs.length < adsPositionAmount[4]) {
                    setAvailableQueuePos5(true)
                    setAdsQueuePos5(adsDocs.docs.length)
                } else {
                    setFinalDateAdsPos5(dateFormatTime(adsDocs.docs[0].data().final_date))
                    setAvailableQueuePos5(false)
                }
            } else {
                setAvailableQueuePos5(true)
            }
        })
        getDocs(query(collection(db, `ads/active/position_8`), orderBy('final_date', 'desc'))).then((adsDocs) => {
            if(!adsDocs.empty) {
                if(adsDocs.docs.length < adsPositionAmount[7]) {
                    setAvailableQueuePos8(true)
                    setAdsQueuePos8(adsDocs.docs.length)
                } else {
                    setFinalDateAdsPos8(dateFormatTime(adsDocs.docs[0].data().final_date))
                    setAvailableQueuePos8(false)
                }
            } else {
                setAvailableQueuePos8(true)
            }
        })
        getDocs(query(collection(db, `ads/active/position_9`), orderBy('final_date', 'desc'))).then((adsDocs) => {
            if(!adsDocs.empty) {
                if(adsDocs.docs.length < adsPositionAmount[8]) {
                    setAvailableQueuePos9(true)
                    setAdsQueuePos9(adsDocs.docs.length)
                } else {
                    setFinalDateAdsPos9(dateFormatTime(adsDocs.docs[0].data().final_date))
                    setAvailableQueuePos9(false)
                }
            } else {
                setAvailableQueuePos9(true)
            }
        })
        getDocs(query(collection(db, `ads/active/position_10`), orderBy('final_date', 'desc'))).then((adsDocs) => {
            if(!adsDocs.empty) {
                if(adsDocs.docs.length < adsPositionAmount[9]) {
                    setAvailableQueuePos10(true)
                    setAdsQueuePos10(adsDocs.docs.length)
                } else {
                    setFinalDateAdsPos10(dateFormatTime(adsDocs.docs[0].data().final_date))
                    setAvailableQueuePos10(false)
                }
            } else {
                setAvailableQueuePos10(true)
            }
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user)  { return navigate("/") }
        else { 
            getUseRate()
            getListAds()
            getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
                if (docs.docs.length === 1) {
                    setUserId(docs.docs[0].id)
                    setShopId(docs.docs[0].data().shop_id)
                    getDoc(doc(db, `shops/`, docs.docs[0].data().shop_id)).then((shopDoc) => {
                        if(shopDoc.data().exchange_coin) setOfferExchangeCoin(shopDoc.data().exchange_coin)
                        if(shopDoc.data().exchange_coin) setAdsExchangeCoin(shopDoc.data().exchange_coin)
                    })

                    onSnapshot(query(collection(db, `shops/${docs.docs[0].data().shop_id}/ads/active/list/`)), (result) => {
                        if(!result.empty) {
                            activeList.splice(0, activeList.length)
                            setActiveList([...activeList])
                            setActiveNum(result.docs.length)
                            result.forEach(async(ads) => {
                                activeList.push({
                                    id: ads.id,
                                    data: ads.data()
                                })
                                setActiveList([...activeList], {
                                    id: ads.id,
                                    data: ads.data()
                                })
                            })
                        }
                    })
                    
                    onSnapshot(query(collection(db, `shops/${docs.docs[0].data().shop_id}/ads/pending/list/`)), (result) => {
                        if(!result.empty) {
                            pendingList.splice(0, pendingList.length)
                            setPendingList([...pendingList])
                            setPendingNum(result.docs.length)
                            result.forEach(async(ads) => {
                                pendingList.push({
                                    id: ads.id,
                                    data: ads.data()
                                })
                                setPendingList([...pendingList], {
                                    id: ads.id,
                                    data: ads.data()
                                })
                            })
                        }
                    })
                    
                    onSnapshot(query(collection(db, `shops/${docs.docs[0].data().shop_id}/ads/request/list/`)), (result) => {
                        if(!result.empty) {
                            requestList.splice(0, requestList.length)
                            setRequestList([...requestList])
                            setRequestNum(result.docs.length)
                            result.forEach(async(ads) => {
                                requestList.push({
                                    id: ads.id,
                                    data: ads.data()
                                })
                                setRequestList([...requestList], {
                                    id: ads.id,
                                    data: ads.data()
                                })
                            })
                        }
                    })
                }
            }).then(() => {
                setShowLoading(false)
            })
        }
    }, [user, loading])

    return (
    <>
        <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="ads" isShop={true} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>รายการโฆษณา</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="notifications" isShop={true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', marginTop: 5 }}>
                            <div><button type="button" className="btn btn-primary m-1" onClick={() => { handleOpenAdsRequest() }}><BsMegaphone /> <strong>ลงโฆษณา</strong></button></div>
                            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                                <h4 style={{ display: 'flex', flex: 1}}><BsMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                <b>รายการโฆษณา</b></h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">
                                                ตำแหน่ง
                                            </th>
                                            <th scope="col"><div className="d-none d-md-block d-lg-block">วันที่เริ่ม-สิ้นสุดประกาศ</div></th>
                                            <th scope="col"><div className="d-none d-md-block d-lg-block">จำนวนวันที่ประกาศ</div></th>
                                            <th scope="col"> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {activeList?.map((ads, i) => (
                                        <tr key={i}>
                                            <th scope="row">{i+1}</th>
                                            <td>
                                                <font style={{ fontSize: 18 }}>{adsPositionName[(ads.data.position*1) - 1]}</font>
                                                <font style={{ fontSize: 14 }}><div className='d-block d-md-none d-lg-none'>{dateFormatTime(ads.data.start_date)} - {dateFormatTime(ads.data.final_date)}</div></font>
                                                <font style={{ fontSize: 14 }}><div className='d-block d-md-none d-lg-none'>ระยะเวลา: {number_format.format(ads.data.ads_duration)} วัน</div></font>
                                            </td>
                                            <td><div className="d-none d-md-block d-lg-block">{dateFormatTime(ads.data.start_date)} - {dateFormatTime(ads.data.final_date)}</div></td>
                                            <td><div className="d-none d-md-block d-lg-block">ระยะเวลา {number_format.format(ads.data.ads_duration)} วัน</div></td>
                                            <td> </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                                <h4 style={{ display: 'flex', flex: 1}}><BsMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                <b>รายการรอโฆษณา</b></h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">ตำแหน่ง</th>
                                            <th scope="col"><div className="d-none d-md-block d-lg-block">วันที่ขอ</div></th>
                                            <th scope="col"><div className="d-none d-md-block d-lg-block">จำนวนวันที่ต้องการลงประกาศ</div></th>
                                            <th scope="col"> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {pendingList?.map((ads, i) => (
                                        <tr key={i}>
                                            <th scope="row">{i+1}</th>
                                            <td>
                                                <font style={{ fontSize: 18 }}>{adsPositionName[(ads.data.position*1) - 1]}</font>
                                                <font style={{ fontSize: 14 }}><div className='d-block d-md-none d-lg-none'>วันที่ขอ: {dateFormatTime(ads.data.request_timestamp)}</div></font>
                                                <font style={{ fontSize: 14 }}><div className='d-block d-md-none d-lg-none'>จำนวนวันที่ต้องการลงประกาศ: {number_format.format(ads.data.ads_duration)}</div></font>
                                            </td>
                                            <td><div className="d-none d-md-block d-lg-block">{dateFormatTime(ads.data.request_timestamp)}</div></td>
                                            <td><div className="d-none d-md-block d-lg-block">{number_format.format(ads.data.ads_duration)}</div></td>
                                            <td> </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                                <h4 style={{ display: 'flex', flex: 1}}><BsMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                <b>คำขอโฆษณา</b></h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                <table className="table">
                                    <thead>
                                        <tr>
                                            <th scope="col">#</th>
                                            <th scope="col">ตำแหน่ง</th>
                                            <th scope="col"><div className="d-none d-md-block d-lg-block">วันที่ขอ</div></th>
                                            <th scope="col"><div className="d-none d-md-block d-lg-block">จำนวนวันที่ต้องการลงประกาศ</div></th>
                                            <th scope="col"><div className="d-none d-md-block d-lg-block">สถานะ</div></th>
                                            <th scope="col"> </th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                    {requestList?.map((ads, i) => (
                                        <tr key={i}>
                                            <th scope="row">{i+1}</th>
                                            <td>
                                                <font style={{ fontSize: 18 }}>{adsPositionName[(ads.data.position*1) - 1]}</font>
                                                <font style={{ fontSize: 14 }} className='d-block d-md-none d-lg-none'>วันที่ขอ: {dateFormatTime(ads.data.request_timestamp)}</font>
                                                <font style={{ fontSize: 14 }} className='d-block d-md-none d-lg-none'>ระยะเวลา: {number_format.format(ads.data.ads_duration)} วัน</font>
                                                <font style={{ fontSize: 14 }} className='d-block d-md-none d-lg-none'>สถานะ:&nbsp;
                                                    {ads.data.status == 1 && "รออนุมัติ"}
                                                    {ads.data.status == 2 && "อนุมัติ"}
                                                    {ads.data.status == 3 && "ไม่อนุมัติ"}
                                                    {ads.data.status == 4 && "ยกเลิก"}
                                                </font>
                                            </td>
                                            <td><div className="d-none d-md-block d-lg-block">{dateFormatTime(ads.data.request_timestamp)}</div></td>
                                            <td><div className="d-none d-md-block d-lg-block">{number_format.format(ads.data.ads_duration)}</div></td>
                                            <td><div className="d-none d-md-block d-lg-block">
                                                {ads.data.status == 1 && "รออนุมัติ"}
                                                {ads.data.status == 2 && "อนุมัติ"}
                                                {ads.data.status == 3 && "ไม่อนุมัติ"}
                                                {ads.data.status == 4 && "ยกเลิก"}</div>
                                            </td>
                                            <td>
                                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                                    {ads.data.status == 1 && 
                                                    <button type="button" 
                                                        className="btn btn-danger btn-sm" 
                                                        style={{ marginRight: 5, display: 'flex', wordWrap: 'unset', whiteSpace: 'nowrap' }} 
                                                        onClick={() => { handleCalcleRequestAds(ads.id) }}>
                                                        <TbAlertCircle style={{ fontSize: 20, marginRight: 5 }} /><font style={{ wordWrap: 'unset', whiteSpace: 'nowrap' }}>ยกเลิกคำขอ</font></button>
                                                    }
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <Dialog open={openShowAdsRequest} onClose={handleCloseAdsRequest} maxWidth="md" fullWidth={true}>
            <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                <div style={{ fontFamily: 'Prompt'}}>
                    <h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                    <strong>ลงโฆษณาขายเครื่องใช้ไฟฟ้ามือสอง</strong></h3>
                </div>
            </DialogTitle>
            <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ display:'flex', flexDirection: 'row', alignItems:'center', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ backgroundColor: '#ffffff' }}>
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                    <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                        <ImCoinDollar style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>จำนวนเหรียญทองที่มี</strong> {number_format.format(adsExchangeCoin)} เหรียญ</h5>
                                    </label>
                                </div>
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                    <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                        <ImCoinDollar style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>ตำแหน่งที่ต้องการลงประกาศ:</strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flex: 1, paddingLeft: 30, flexDirection: 'column' }}>
                                        <RadioGroup
                                            defaultValue="freecoin" name="fromCoinOffer"
                                            value={adsPosition} onChange={handlePickAdsPosition}
                                        >
                                            <div className="flex-container" style={{ display: 'flex'}}>
                                                <div>
                                                    <FormControlLabel control={<Radio />} value='3' defaultChecked={true} style={{ marginBottom: 5 }}
                                                        label={<span style={{ fontFamily: 'Prompt' }}>{adsPositionName[2]}<strong> {number_format.format(adsPos3UseCoin)}</strong> เหรียญ / 1 วัน</span>}
                                                    />
                                                    {!availableQueuePos3 && <div><font className='text-danger'>คิวลงโฆษณาเต็ม คิวสุดท้ายจะจบในวันที่ {finalDateAdsPos3}</font></div>}

                                                    <FormControlLabel control={<Radio />} value='4'
                                                        label={<span style={{ fontFamily: 'Prompt' }}>{adsPositionName[3]}<strong> {number_format.format(adsPos4UseCoin)}</strong> เหรียญ / 1 วัน</span>}
                                                    />
                                                    {!availableQueuePos4 && <div><font className='text-danger'>คิวลงโฆษณาเต็ม คิวสุดท้ายจะจบในวันที่ {finalDateAdsPos4}</font></div>}

                                                    <FormControlLabel control={<Radio />} value='5'
                                                        label={<span style={{ fontFamily: 'Prompt' }}>{adsPositionName[4]}<strong> {number_format.format(adsPos5UseCoin)}</strong> เหรียญ / 1 วัน</span>}
                                                    />
                                                    {!availableQueuePos5 && <div><font className='text-danger'>คิวลงโฆษณาเต็ม คิวสุดท้ายจะจบในวันที่ {finalDateAdsPos5}</font></div>}
                                                </div>
                                                <div>
                                                    <FormControlLabel control={<Radio />} value='8' 
                                                        label={<span style={{ fontFamily: 'Prompt' }}>{adsPositionName[7]}<strong> {number_format.format(adsPos8UseCoin)}</strong> เหรียญ / 1 วัน</span>}
                                                    />
                                                    {!availableQueuePos8 && <div><font className='text-danger'>คิวลงโฆษณาเต็ม คิวสุดท้ายจะจบในวันที่ {finalDateAdsPos8}</font></div>}

                                                    <FormControlLabel control={<Radio />} value='9'
                                                        label={<span style={{ fontFamily: 'Prompt' }}>{adsPositionName[8]}<strong> {number_format.format(adsPos9UseCoin)}</strong> เหรียญ / 1 วัน</span>}
                                                    />
                                                    {!availableQueuePos9 && <div><font className='text-danger'>คิวลงโฆษณาเต็ม คิวสุดท้ายจะจบในวันที่ {finalDateAdsPos9}</font></div>}

                                                    <FormControlLabel control={<Radio />} value='10'
                                                        label={<span style={{ fontFamily: 'Prompt' }}>{adsPositionName[9]}<strong> {number_format.format(adsPos10UseCoin)}</strong> เหรียญ / 1 วัน</span>}
                                                    />
                                                    {!availableQueuePos10 && <div><font className='text-danger'>คิวลงโฆษณาเต็ม คิวสุดท้ายจะจบในวันที่ {finalDateAdsPos10}</font></div>}
                                                </div>
                                            </div>
                                        </RadioGroup>
                                        {useCoinErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** จำนวนเหรียญที่ต้องใช้ในการลงโฆษณาไม่เพียงพอ</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                        <MdOutlineCalendarToday style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>จำนวนวันโฆษณา (วัน)<font className="text-danger">*</font></strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: 30 }}>
                                        <input type='text' name='adsDuration' onChange={(e) =>  {
                                            setAdsDuration(e.target.value)
                                            if(adsPosition == '3') {
                                                setAdsTotalUseCoin(e.target.value * adsPos3UseCoin)
                                            } else if(adsPosition == '4') {
                                                setAdsTotalUseCoin(e.target.value * adsPos4UseCoin)
                                            } else if(adsPosition == '5') {
                                                setAdsTotalUseCoin(e.target.value * adsPos5UseCoin)
                                            } else if(adsPosition == '8') {
                                                setAdsTotalUseCoin(e.target.value * adsPos8UseCoin)
                                            } else if(adsPosition == '9') {
                                                setAdsTotalUseCoin(e.target.value * adsPos9UseCoin)
                                            } else {
                                                setAdsTotalUseCoin(e.target.value * adsPos10UseCoin)
                                            }
                                        }}
                                            value={adsDuration} placeholder='จำนวนวัน'
                                            style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                        {adsDurationErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                <strong>** กรุณากรอกจำนวนวันโฆษณา</strong>
                                            </div>
                                        }
                                    </div>
                                </div>
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                        <ImCoinDollar style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>จำนวนเหรียญทองที่ต้องใช้</strong> {number_format.format(adsTotalUseCoin)} เหรียญ</h5>
                                    </label>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </DialogContent>
            <DialogActions>
                <button onClick={handleCloseAdsRequest} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                <button onClick={handleAdsRequest} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ลงโฆษณา</button>
            </DialogActions>
        </Dialog>
    </>
    )
}

export default Appointments