import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, orderBy, onSnapshot, addDoc, updateDoc, doc, deleteDoc, and, or } from 'firebase/firestore'
import { Link, useNavigate } from "react-router-dom";
import LeftMenu from './LeftMenu';

import { AiOutlineClockCircle, AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai"
import { IoMegaphoneOutline } from 'react-icons/io5'

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';

const Appointments = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)
    const [uid, setUid] = useState('')
    const [user_id, setUserId] = useState('')
    const [isShop, setIsShop] = useState(0)

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState('')

    const [appointments, setAppointments] = useState([])
    const [chooseAppointmentId, setChooseAppointmentId] = useState('')
    const [choosePostId, setChoosePostId] = useState('')
    const [postOnBoard, setPostOnboard] = useState(1)

    const [openFinishAppointment, setOpenFinishAppointment] = useState(false)
    const [openCancleAppointment, setOpenCancleAppointment] = useState(false)

    const [openConfirmFix, setOpenConfirmFix] = useState(false)
    const [openCancleFix, setOpenCancleFix] = useState(false)
    
    const handleOpenFinishAppointment = (appointmentId, postId, postOnBoard) => {
        setChooseAppointmentId(appointmentId)
        setChoosePostId(postId)
        setPostOnboard(postOnBoard)
        setOpenFinishAppointment(true)
    }

    const handleCloseFinishAppointment = () => {
        setOpenFinishAppointment(false)
    }

    const handleFinishAppointment = () => {
        const new_date = new Date()
        updateDoc(doc(db, `/appointments/`, chooseAppointmentId), { 
            status: 2,
            message: message,
            score: score,
            close_datetime: new_date.getTime()
        }).then(() => {
            updateDoc(doc(db, `/posts/`, choosePostId), { 
                status: 6,
                appointment_id: chooseAppointmentId,
                finish_date: new_date
            }).then(() => {
                getDoc(doc(db, 'posts', choosePostId)).then((postDoc) => {
                    if(postDoc.data().onboard == 2) {

                    } else if(postDoc.data().onboard == 3) {

                    }
                })
            })
        }).then(() => {
            handleCloseFinishAppointment()
        })
    }

    const handleOpenCancleAppointment = (appointmentId, postId) => {
        setChooseAppointmentId(appointmentId)
        setChoosePostId(postId)
        setOpenCancleAppointment(true)
    }

    const handleCloseCancleAppointment = () => {
        setOpenCancleAppointment(false)
    }

    const handleCancleAppointment = () => {
        const new_date = new Date()
        updateDoc(doc(db, `/appointments/`, chooseAppointmentId), { 
            status: 3,
            message: message,
            reason: reason,
            cancle_datetime: new_date.getTime()
        }).then(() => {
            getDoc(doc(db, 'appoinments', chooseAppointmentId)).then((appointmentDoc) => {
                var depositeCoin = 0
                if(appointmentDoc.data().deposit_coin) depositeCoin = appointmentDoc.data().deposit_coin
                getDoc(doc(db, `posts`, choosePostId)).then((postDoc) => {
                    getDoc(doc(db, `shops/`, appointmentDoc.data().shop_id)).then((shopDoc) => {
                        const dataShop = shopDoc.data()
                        updateDoc(doc(db, `/shops`, appointmentDoc.data().shop_id), {
                            exchange_coin: (dataShop.exchange_coin*1)+(depositeCoin*1)
                        })
                    })
                })
            })
        }).then(() => {
            updateDoc(doc(db, `/posts/`, choosePostId), { 
                status: 1
            }).then(() => {
                getDocs(query(collection(db, `posts/${choosePostId}/offers`))).then((offers) => {
                    offers.forEach((offer) => {
                        getDocs(query(collection(db, `/shops/${offer.data().shop_id}/offers`), where('post_id', '==', choosePostId))).then((shopDocs) => {
                            shopDocs.forEach((docRef) => {
                                updateDoc(doc(db, `/shops/${offer.data().shop_id}/offers`, docRef.id), { status: 5 })
                            })
                        }).then(() => {
                            addDoc(collection(db, `posts/${choosePostId}/offer_history/`), {
                                hour: offer.data().hour,
                                message: offer.data().message,
                                minute: offer.data().minute,
                                offer_timestamp: offer.data().offer_timestamp,
                                pick_date: offer.data().pick_date,
                                shop_id: offer.data().shop_id,
                            }).then(() => {
                                deleteDoc(doc(db, `posts/${choosePostId}/offers/`, offer.id))
                            })
                        })
                    })
                }).then(() => {
                }).catch((error) => {
                    alert(error.message)
                })
            })
        }).then(() => {
            handleCloseFinishAppointment()
        })
    }

    const handleOpenConfirmFix = (appointmentId, postId) => {
        setChooseAppointmentId(appointmentId)
        setChoosePostId(postId)
        setOpenConfirmFix(true)
    }

    const handleCloseConfirmFix = () => {
        setOpenConfirmFix(false)
    }

    const handleConfirmFix = () => {

    }

    const handleOpenCancleFix = (appointmentId, postId) => {
        setChooseAppointmentId(appointmentId)
        setChoosePostId(postId)
        setOpenCancleFix(true)
    }

    const handleCloseCancleFix = () => {
        setOpenCancleFix(false)
    }

    const handleCancleFix = () => {
        const new_date = new Date()
        updateDoc(doc(db, `/appointments/`, chooseAppointmentId), { 
            status: 9,
            message: message,
            reason: reason,
            cancle_datetime: new_date.getTime()
        }).then(() => {
            updateDoc(doc(db, `/posts/`, choosePostId), { 
                status: 5
            }).then(() => {
                navigate(0)
            })
        })
    }

    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH")
        const time_string = new Date(datetime).toLocaleTimeString("th-TH")
        const time = time_string.split(':')
        return date_string+" "+time[0]+":"+time[1]
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const fetchAppointments = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setUserId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)
                const postQuery = query(collection(db, 'appointments'), 
                                            and(where('user_id', '==', docs.docs[0].id), 
                                            or(
                                                where('status', '==', 3),
                                                where('status', '==', 9))),
                                            orderBy('appointment_date', 'asc'), 
                                            orderBy('appointment_hour', 'asc'), 
                                            orderBy('appointment_minute', 'asc'))
                onSnapshot(postQuery, (querySnapshot) => {
                    appointments.splice(0, appointments.length)
                    setAppointments([...appointments])
                    querySnapshot.forEach( async(appointment) => {
                        const postSnapshot = await getDoc(doc(db, 'posts', appointment.data().post_id));
                        const offerSnapshot = await getDoc(doc(db, `posts/${appointment.data().post_id}/offers`, appointment.data().offer_id));
                        const shopSnapshot = await getDoc(doc(db, 'shops', appointment.data().shop_id));
                        appointments.push({
                            id: appointment.id,
                            data: appointment.data(),
                            offer: offerSnapshot.data(),
                            post: postSnapshot.data(),
                            shop: shopSnapshot.data()
                        })
                        setAppointments([...appointments])
                    })
                })
            }
        }).catch((error) => {
            alert(error.message);
        })
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else fetchAppointments()
    }, [user, loading])

    useEffect(() => {
    },[])

    return (
    <>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 20}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div style={{ paddingLeft: 20, marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>การนัดหมายของฉัน</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <LeftMenu currentPage="appointments" isShop={isShop} />
                    <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '1px solid #cccccc', paddingLeft: 20, flex: 1 }}>
                        <div style={{ borderBottom: '1px solid #cccccc', paddingLeft: 20 }}>
                            <ul className="nav nav-underline">
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/appointments/"><AiOutlineClockCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />รอวันนัดหมาย</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/appointments/close"><AiOutlineCheckCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />สำเร็จ</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/profile/appointments/cancle"><AiOutlineCloseCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ยกเลิกโดยผู้ประกาศ</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/appointments/shopcancle"><AiOutlineCloseCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ยกเลิกโดยร้านค้า</a>
                                </li>
                            </ul>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 15}}>
                        {
                            appointments?.map((appointment, i) => (
                            <div className="card" style={{ minWidth: "18rem", maxWidth: "21rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center', flex: 1 }} key={appointment.id}>
                                {appointment.data.status == 3 &&
                                (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                    <button type="button" className="btn btn-danger" style={{ color: '#FFFFFF'}}>ยกเลิก</button>
                                </div>)
                                }
                                {appointment.data.status == 9 &&
                                (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                    <button type="button" className="btn btn-danger" style={{ color: '#FFFFFF'}}>ไม่ให้ซ่อม</button>
                                </div>)
                                }
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                    <p className="card-text"><strong>วันที่นัดหมาย:</strong><br/><h5>{dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น.</h5></p>
                                </div>
                                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 10, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                    <img src={appointment.post.picture} alt="WEEE" height="150" />
                                </div>
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column', marginBottom: 70}} >
                                    <p className="card-text">
                                        {appointment.post.type} {appointment.post.brand} {appointment.data.model} <br/>
                                        {(appointment.post.onboard == 4 && appointment.data.status != 6) &&
                                        (<>
                                        <strong>ราคาซ่อม:</strong> {appointment.offer.offer_price} บาท <br/>
                                        <strong>อาการ:</strong> {appointment.post.description} <br/>
                                        </>)
                                        }
                                        {(appointment.post.onboard==2 || appointment.post.onboard==3) &&
                                            (<><strong>ราคา:</strong> {appointment.post.price} บาท <br/></>)
                                        }
                                        {(appointment.post.onboard == 4 && appointment.data.status == 6) &&
                                        (<>
                                        <strong style={{ fontSize: 19 }}>รายละเอียดแจ้งซ่อม</strong><br/>
                                        <strong>ราคาซ่อม:</strong> {appointment.data.fix_price} บาท <br/>
                                        <strong>ระยะเวลาที่ใช้ซ่อม:</strong> {appointment.data.fix_day} วัน <br/>
                                        <strong>ผลประเมินอาการเบื้องต้น:</strong> {appointment.data.before_check} <br/>
                                        <strong>ผลการตรวจสภาพก่อนซ่อม:</strong> {appointment.data.before_result} <br/>
                                        <strong>อาการสภาพก่อนซ่อม:</strong> {appointment.data.before_symptom} <br/>
                                        </>)
                                        }
                                    </p>
                                </div>
                                <div className="card-body" style={{ position: 'absolute', bottom: 0}}>
                                    {(appointment.data.status == 6) && 
                                        (<>
                                        <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenConfirmFix(appointment.id, appointment.data.post_id); }}>
                                            <strong>ให้ซ่อม</strong>
                                        </button>
                                        <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCancleFix(appointment.id, appointment.data.post_id); }}>
                                            <strong>ไม่ให้ซ่อม</strong>
                                        </button>
                                        </>)
                                    }
                                    {(appointment.data.status == 1) && 
                                        (<>
                                        <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenFinishAppointment(appointment.id, appointment.data.post_id, appointment.post.onboard); }}>
                                            <strong>ปิดงาน</strong>
                                        </button>
                                        <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCancleAppointment(appointment.id, appointment.data.post_id, appointment.post.onboard); }}>
                                            <strong>ยกเลิกงาน</strong>
                                        </button>
                                        </>)
                                    }
                                    <Link to={`/post/${appointment.data.post_id}`} target="_blank" className='btn btn-primary m-1'>
                                        <strong>รายละเอียด</strong>
                                    </Link>
                                </div>
                            </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={openFinishAppointment} onClose={handleCloseFinishAppointment}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ปิดงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>คำติชมเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='คำติชมเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 100}}><h5><strong>คะแนน</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <select name='score' onChange={(e) => setScore(e.target.value)} style={{ width: 70, marginLeft: 5 }}>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseFinishAppointment} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleFinishAppointment} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ปิดงาน</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancleAppointment} onClose={handleCloseCancleAppointment}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5 }}>
                                                <option value="1">มารับไม่ตรงวันและเวลานัด</option>
                                                <option value="2">มีการเรียกรับเงินเพิ่ม</option>
                                                <option value="3">ความพร้อมหรือความน่าเชื่อถือของร้านค้า</option>
                                                <option value="4">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleAppointment} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิด</button>
                    <button onClick={handleCancleAppointment} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกงาน</button>
                </DialogActions>
            </Dialog>
            
            <Dialog open={openCancleFix} onClose={handleCloseCancleFix}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ไม่ให้ซ่อม</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ไม่ให้ซ่อม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5 }}>
                                                <option value="1">ราคาซ่อมสูงเกินงบที่ตั้งไว้</option>
                                                <option value="2">ใช้ระยะเวลานานเกินไป</option>
                                                <option value="3">มีการเรียกเก็บเงิน</option>
                                                <option value="4">ผู้เสนอไม่น่าเชื่อถือ</option>
                                                <option value="5">ไปถึงร้านฯ แต่ไม่เจอผู้เสนอฯ ตามวันเวลาที่นัด</option>
                                                <option value="6">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleFix} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าว</button>
                    <button onClick={handleCancleFix} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ไม่ให้ซ่อม</button>
                </DialogActions>
            </Dialog>
        </div>
    </>
    )
}

export default Appointments