import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, updateDoc, doc, getDoc, addDoc, orderBy } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth"
import { useNavigate } from "react-router-dom";
import { TbUser, TbHome } from "react-icons/tb"
import { BsPersonVcard } from "react-icons/bs"
import { RiBankCardLine } from 'react-icons/ri'
import { FaMapMarkerAlt } from "react-icons/fa"
import { IoCloseCircle, IoCheckmarkCircleSharp } from "react-icons/io5"
import addImage from "../../assets/images/add-image-icon.png"

import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

const AddMember = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)

    const inputRef = useRef(null)
    const inputRef2 = useRef(null)

    const [imageProfile, setImageProfile] = useState('')
    const [imageProfileUrl, setImageProfileUrl] = useState(addImage)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [telNo, setTelNo] = useState('')
    const [lat, setLat] = useState(15.11745249400088)
    const [lng, setLng] = useState(104.90284046686465)
    const [idCard, setIdCard] = useState('')
    const [idCardUrl, setIdCardUrl] = useState('')
    const [bankId, setBankId] = useState(0)
    const [bankAccNo, setBankAccNo] = useState('')
    const [bookBank, setBookBank] = useState('');
    const [bookBankUrl, setBookBankUrl] = useState('')

    const [address, setAddress] = useState('')
    const [road, setRoad] = useState('')
    const [postcode, setPostcode] = useState('')

    const [subroad, setSubRoad] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')

    const [emailFormatErrorDiv, setEmailFormatErrorDiv] = useState(false)
    const [existEmailErrorDiv, setExistEmailErrorDiv] = useState(false)
    const [passwordErrorDiv, setPasswordErrorDiv] = useState(false)
    const [displayNameErrorDiv, setDisplayNameErrorDiv] = useState(false)
    const [firstnameErrorDiv, setFirstnameErrorDiv] = useState(false)
    const [lastnameErrorDiv, setLastnameErrorDiv] = useState(false)
    const [telNoErrorDiv, setTelNoErrorDiv] = useState(false)
    const [bankIdErrorDiv, setBankIdErrorDiv] = useState(false)
    const [bankAccNoErrorDiv, setBankAccNoErrorDiv] = useState(false)

    const [imageProfileSizeError, setImageProfileSizeError] = useState(false)
    const [imageIdCardSizeError, setImageIdCardSizeError] = useState(false)
    const [imageBookBankSizeError, setImageBookBankSizeError] = useState(false)
    const [showProgressBar, setShowProgressBar] = useState(false)

    const [bankList, setBankList] = useState([])
    const [provinceList, setProvinceList] = useState([])
    const [provinceId, setProvinceId] = useState(0)
    const [districtList, setDistrictList] = useState([])
    const [districtId, setDistrictId] = useState(0)
    const [subdistrictList, setSubDistrictList] = useState([])
    const [subdistrictId, setSubDistrictId] = useState(0)

    const [provinceIdErrorDiv, setProvinceIdErrorDiv] = useState(false)
    const [districtIdErrorDiv, setDistrictIdErrorDiv] = useState(false)
    const [subdistrictIdErrorDiv, setSubDistrictErrorDiv] = useState(false)

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    })
    const [center, setCenter] = useState({ lat: 15.11745249400088, lng: 104.90284046686465 })
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    function isValidEmail(text) {
        return /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(text)
    }

    function isSpecialChar(text) {
        return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/.test(text)
    }

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    const validateForm = () => {
        if(!email) {
            setEmailFormatErrorDiv(true)
            return false;
        } else {
            setEmailFormatErrorDiv(false)
        }
        
        if(!isValidEmail(email)) {
            setEmailFormatErrorDiv(true)
            return false;
        } else {
            setEmailFormatErrorDiv(false)
        }

        if(!password) {
            setPasswordErrorDiv(true)
            return false;
        } else {
            if(password.length < 6) { 
                setPasswordErrorDiv(true)
                return false;
            } else {
                setPasswordErrorDiv(false)
            }
        }

        if(!displayName || isSpecialChar(displayName)) {
            setDisplayNameErrorDiv(true)
            return false;
        } else {
            setDisplayNameErrorDiv(false)
        }

        if(!firstname || isSpecialChar(firstname)) {
            setFirstnameErrorDiv(true)
            return false;
        } else {
            setFirstnameErrorDiv(false)
        }

        if(!lastname || isSpecialChar(lastname)) {
            setLastnameErrorDiv(true)
            return false;
        } else {
            setLastnameErrorDiv(false)
        }

        if(!telNo || !isOnlyNumeric(telNo)) {
            setTelNoErrorDiv(true)
            return false;
        } else {
            setTelNoErrorDiv(false)
        }

        /* if(bankId == 0) {
            setBankIdErrorDiv(true)
            return false;
        } else {
            setBankIdErrorDiv(false)
        }

        if(!bankAccNo || !isOnlyNumeric(bankAccNo)) {
            setBankAccNoErrorDiv(true)
            return false;
        } else {
            setBankAccNoErrorDiv(false)
        } */

        if(provinceId == 0) {
            setProvinceIdErrorDiv(true)
            return false;
        } else {
            setProvinceIdErrorDiv(false)
        }

        if(districtId == 0) {
            setDistrictIdErrorDiv(true)
            return false;
        } else {
            setDistrictIdErrorDiv(false)
        }

        if(subdistrictId == 0) {
            setSubDistrictErrorDiv(true)
            return false;
        } else {
            setSubDistrictErrorDiv(false)
        }
        return true
    }

    const handleImageProfile = (file) => {
        if(file.size > 1 * 1000 * 1024) {
            setImageProfileSizeError(true)
        } else {
            setImageProfileUrl(URL.createObjectURL(file))
            setImageProfile(file)
            setImageProfileSizeError(false)
        }
    }

    const handleImageIdCard = (file) => {
        if(file.size > 1 * 1000 * 1024) {
            setImageIdCardSizeError(true)
        } else {
            setIdCardUrl(URL.createObjectURL(file))
            setIdCard(file)
            setImageIdCardSizeError(false)
        }
    }

    const handleImageBookBank = (file) => {
        if(file.size > 1 * 1000 * 1024) {
            setImageBookBankSizeError(true)
        } else {
            setBookBankUrl(URL.createObjectURL(file))
            setBookBank(file)
            setImageIdCardSizeError(false)
        }
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()
        if(validateForm()) {
            getDocs(query(collection(db, 'users'), where('email', '==', email))).then(async (userQuery) => {
                if(userQuery.docs.length == 0) {
                    const currentUser = user
                    setShowProgressBar(true)
                    setExistEmailErrorDiv(false)
                    const res = await createUserWithEmailAndPassword(auth, email, password)
                    await sendEmailVerification(auth.currentUser)
                    const uid = res.user.uid
                    await addDoc(collection(db, "users"), {
                        uid: uid,
                        authProvider: "email",
                        email: email,
                        password: password,
                        type: 1,
                        display_name: displayName,
                        firstname: firstname,
                        lastname: lastname,
                        tel_no: telNo,
                        address: address,
                        road: road,
                        subroad: subroad,
                        village: village,
                        building: building,
                        floor: floor,
                        room: room,
                        sub_district: subdistrictId,
                        district: districtId,
                        province: provinceId,
                        postcode: postcode,
                        lat: lat,
                        lng: lng,
                        bank_id: bankId,
                        bank_account_no: bankAccNo,
                        status: 1
                    }).then((docRef) => {
                        const docId = docRef.id
                        getDoc(doc(db, 'free_coins', 'get_free_rate')).then((giveSnapshot) => {
                            const data = giveSnapshot.data()
                            var givedCoin = data.coin * 1
                            var promisesDoc = []
                            const task1 = updateDoc(doc(db, 'users', docId), {
                                freecoin: data.coin * 1
                            })
                            promisesDoc.push(task1)
                            const task2 = getDoc(doc(db, 'free_coins', 'sum_data')).then((sumDoc) => {
                                var remain = (sumDoc.data().all*1) - (sumDoc.data().gived*1)
                                if(givedCoin > remain) {
                                    givedCoin = remain
                                } else {
                                    const task3 = updateDoc(doc(db, 'free_coins', 'sum_data'), {
                                        gived: (sumDoc.data().gived*1) + (givedCoin)
                                    }).then(() => {
                                        const log_date = new Date()
                                        const task4 = addDoc(collection(db, `/free_coins/logs/transaction`), {
                                            date: log_date,
                                            coin: givedCoin,
                                            event: 'แจกเหรียญฟรี สมัครสมาชิก',
                                            user_id: docId,
                                            type: 'give'
                                        })
                                        promisesDoc.push(task4)
                                    })
                                    promisesDoc.push(task3)
                                }
                            })
                            promisesDoc.push(task2)
                            Promise.all(promisesDoc).then(() => {
                                var promisesFile = []
                                var uploadSize = 0
                                if(bookBank) {
                                    const bookbankRef = ref(storage, `/users/${docId}/image/bookbank/${bookBank.name}`)
                                    const bookbankTask = uploadBytesResumable(bookbankRef, bookBank)
                                    uploadSize += bookBank.size
                                    promisesFile.push(bookbankTask)
                                    bookbankTask.on( "state_changed",
                                        () => { },
                                        (err) => console.log(err),
                                        () => { 
                                            getDownloadURL(bookbankTask.snapshot.ref).then((url) => {
                                                updateDoc(doc(db, `users`, docId), {
                                                    book_bank: url,
                                                })
                                            })
                                        }
                                    )
                                }
                                if(idCard) {
                                    const idCardRef = ref(storage, `/users/${docId}/image/idcard/${idCard.name}`)
                                    const idCardTask = uploadBytesResumable(idCardRef, idCard)
                                    promisesFile.push(idCardTask)
                                    idCardTask.on( "state_changed",
                                        () => { },
                                        (err) => console.log(err),
                                        () => { 
                                            getDownloadURL(idCardTask.snapshot.ref).then((url) => {
                                                updateDoc(doc(db, `users`, docId), {
                                                    id_card: url,
                                                })
                                            })
                                        }
                                    )
                                }
                                if(imageProfile) {
                                    const imageRef = ref(storage, `/users/${docId}/image/profile/${imageProfile.name}`)
                                    const imageTask = uploadBytesResumable(imageRef, imageProfile);
                                    promisesFile.push(imageTask)
                                    imageTask.on( "state_changed",
                                        () => { },
                                        (err) => console.log(err),
                                        () => { 
                                            getDownloadURL(imageTask.snapshot.ref).then((url) => {
                                                updateDoc(doc(db, `users`, docId), {
                                                    image_profile: url,
                                                })
                                            })
                                        }
                                    )
                                }
        
                                Promise.all(promisesFile).then(() => {
                                    setShowProgressBar(false)
                                    auth.updateCurrentUser(currentUser)
                                    navigate(`/admin/member/view/${docRef.id}`)
                                })
                            })
                        })
                    })
                } else {
                    setExistEmailErrorDiv(true)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }
    
    const getBankList = () => {
        getDocs(query(collection(db, 'banks'), orderBy('order', 'asc'))).then((docs) => {
            setBankList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getProvince = () => {
        getDocs(query(collection(db, 'provinces'), orderBy('province_id', 'asc'))).then((docs) => {
            setProvinceList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectProvince = (provinceId) => {
        setProvinceId(provinceId)

        setDistrictId(0)
        districtList.splice(0, districtList.length)
        setDistrictList([...districtList])

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceId*1), orderBy('province_id', 'asc'))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectDistrict = (districtId) => {
        setDistrictId(districtId)

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtId), orderBy('district_id', 'asc'))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handelSelectsubDistrict = (subdistrictId) => {
        setSubDistrictId(subdistrictId)
        setPostcode('')
        getDocs(query(collection(db, 'postcodes'), where('subdistrict_id', '==', subdistrictId*1))).then((docs) => {
            setPostcode(docs.docs[0].data().postcode)
        })
    }

    const handleClose = () => { }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    var locationOptions = {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 0,
    }

    const locationSuccess = (pos) => {
        var crd = pos.coords
        setLat(crd.latitude)
        setLng(crd.longitude)
        setCenter({lat: crd.latitude, lng: crd.longitude})
        console.log("fff")
    }

    const locationErrors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                //console.log(result);
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
            });
        } else {
          //console.log("Geolocation is not supported by this browser.");
        }
    }, [])

    useEffect(() => {
        if (loading) return;
        getBankList()
        getProvince()
    }, [loading])

    return (
        <>

            <Modal
                open={showProgressBar}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={style}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    กำลังทำการสมัครสมาชิก กรุณารอสักครู่
                    </Typography>
                    <LinearProgress style={{ marginTop: 20 }} />
                </Box>
            </Modal>
            <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <h4 style={{ display: 'flex', flex: 1}}><TbUser style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                        <b>เพิ่มข้อมูลผู้ใช้</b></h4>
                        <div style={{ float: 'right' }}>
                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                            </button>
                        </div>
                        <div style={{ float: 'right' }}>
                            <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/members/`) }}>
                                <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                            </button>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 170 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems:'center' }}>
                                <img src={imageProfileUrl} alt="WEEE" width="150" style={{ marginBottom: 5 }} />
                                <div style={{ display: "flex", flexDirection:'row' }}>
                                    <label htmlFor="fileProfileUpload" style={{ padding: 0 }}>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                </div>
                                {imageProfileSizeError &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                </div>
                                }
                                <input style={{ display:'none' }} className="form-control file" id="fileProfileUpload" type="file" accept="image/*" onChange={(e) => handleImageProfile(e.target.files[0])} />
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                                <h6><BsPersonVcard style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>ข้อมูลส่วนตัว</b></h6>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15 }}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>Email <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='email' onChange={(e) => setEmail(e.target.value)} 
                                            value={email} placeholder='Email'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {emailFormatErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอก Email ให้ถูกต้อง และไม่มีอักขระพิเศษ</strong>
                                        </div>
                                        }
                                        {existEmailErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** มีการใช้งาน Email นี้ในระบบแล้ว</strong>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>Password <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='password' name='password' onChange={(e) => setPassword(e.target.value)} 
                                            value={password} placeholder='password'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {passwordErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอก Password และมีความยาวมากกว่าหรือเท่ากับ 6 ตัวอักษร</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>ชื่อผู้ใช้ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='displayName' onChange={(e) => setDisplayName(e.target.value)} 
                                            value={displayName} placeholder='ชื่อผู้ใช้'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {displayNameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกชื่อผู้ใช้ และไม่มีอักขระพิเศษ</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>ชื่อ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='firstname' onChange={(e) => setFirstname(e.target.value)} 
                                            value={firstname} placeholder='ชื่อ'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {firstnameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกชื่อ และไม่มีอักขระพิเศษ</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>นามสกุล <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='lastname' onChange={(e) => setLastname(e.target.value)} 
                                            value={lastname} placeholder='นามสกุล'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {lastnameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกนามสกุล และไม่มีอักขระพิเศษ</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>เบอร์โทรศัพท์ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='telNo' onChange={(e) => setTelNo(e.target.value)} 
                                            value={telNo} placeholder='เบอร์โทรศัพท์'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {telNoErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกเบอร์โทรศัพท์ (เฉพาะตัวเลขเท่านั้น)</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>สำเนาบัตรประชาชน</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        {idCardUrl &&
                                        <a href={idCardUrl} target='_blank' style={{ marginBottom: 5, marginLeft: 5 }}><BsPersonVcard style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                        <input ref={inputRef} className="form-control file" id="idcard_image" type="file" accept="image/*" onChange={(e) => handleImageIdCard(e.target.files[0])} />
                                        {imageIdCardSizeError &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                            </div>
                            <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginTop: 15 }}>
                                <h6><RiBankCardLine style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>บัญชีธนาคาร</b></h6>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15 }}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 170 }}><strong>ธนาคาร{/*  <font className="text-danger">*</font> */}</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <select name='bankList' onChange={(e) => { setBankId(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, marginRight: 5 }}>
                                            <option value="0">--- กรุณาเลือกธนาคาร ---</option>
                                            {bankList?.map((bank, i) => (
                                            <option value={bank.id} key={bank.id}>
                                                {bank.data.bank_name_th} ({bank.data.bank_name_short})
                                            </option>
                                            ))}
                                        </select>
                                        {bankIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกธนาคาร</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 170 }}><strong>หมายเลขบัญชี{/*  <font className="text-danger">*</font> */}</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='bankAccNo' onChange={(e) => setBankAccNo(e.target.value)} 
                                            value={bankAccNo} placeholder='หมายเลขบัญชี'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {bankAccNoErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกเลขบัญชีธนาคาร (เฉพาะตัวเลขเท่านั้น)</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 170}}><strong>สำเนาหน้าสมุดบัญชี</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        {bookBankUrl &&
                                        <a href={bookBankUrl} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                        <input ref={inputRef2} className="form-control file" id="bookbank_image" type="file" accept="image/*" onChange={(e) => handleImageBookBank(e.target.files[0])} />
                                        {imageBookBankSizeError &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 20 }}>
                            <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                                <h6><TbHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>ข้อมูลที่อยู่</b></h6>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15 }}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>บ้านเลขที่</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='address' onChange={(e) => setAddress(e.target.value)} 
                                            value={address} placeholder='บ้านเลขที่'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>หมู่บ้าน/สถานที่</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='village' onChange={(e) => setVillage(e.target.value)} 
                                            value={village} placeholder='หมู่บ้าน/สถานที่'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>อาคาร</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='building' onChange={(e) => setBuilding(e.target.value)} 
                                            value={building} placeholder='อาคาร'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ชั้น</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='floor' onChange={(e) => setFloor(e.target.value)} 
                                            value={floor} placeholder='ชั้น'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ห้อง</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='room' onChange={(e) => setRoom(e.target.value)} 
                                            value={room} placeholder='ห้อง'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ซอย</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='subroad' onChange={(e) => setSubRoad(e.target.value)} 
                                            value={subroad} placeholder='ซอย'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ถนน</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                            value={road} placeholder='ถนน'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>จังหวัด <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <select name='province' onChange={(e) => { handleSelectProvince(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0">--- กรุณาเลือกจังหวัด ---</option>
                                            {provinceList?.map((province, i) => (
                                            <option value={province.data.province_id} key={province.data.province_id}>
                                                {province.data.province_name}
                                            </option>
                                            ))}
                                        </select>
                                        {provinceIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกจังหวัด</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>อำเภอ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <select name='district' onChange={(e) => { handleSelectDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0" key="0">--- กรุณาเลือกอำเภอ ---</option>
                                            {districtList?.map((district, i) => (
                                            <option value={district.data.district_id} key={district.data.district_id}>
                                                {district.data.district_name}
                                            </option>
                                            ))}
                                        </select>
                                        {districtIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกอำเภอ</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ตำบล <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <select name='subdistrict' onChange={(e) => { handelSelectsubDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0">--- กรุณาเลือกตำบล ---</option>
                                            {subdistrictList?.map((subdistrict, i) => (
                                            <option value={subdistrict.data.subdistrict_id} key={subdistrict.data.subdistrict_id}>
                                                {subdistrict.data.subdistrict_name}
                                            </option>
                                            ))}
                                        </select>
                                        {subdistrictIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกตำบล</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>รหัสไปรษณีย์ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='postcode' readOnly
                                            value={postcode} placeholder='รหัสไปรษณีย์'
                                            style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 170 }}><></></div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginTop: 15 }}>
                                <h6><FaMapMarkerAlt style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} />
                                <b>ตำแหน่งพิกัดที่พักอาศัย</b></h6>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap' }}>
                                    <font className="text-danger" style={{ marginRight: 5 }}>**</font> ลาก Marker <FaMapMarkerAlt className='text-danger' style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} /> เพื่อกำหนดตำแหน่ง
                                </div>
                                <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                                <div className="mb-3 row" style={{ display: 'flex', height:400, flexDirection: 'column' }}>
                                    <div style={{  display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                        {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                        <GoogleMap mapContainerClassName="map-container" center={{ lat: lat, lng: lng }} zoom={17} >
                                            <MarkerF 
                                                draggable={true} position={center}
                                                onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}/>
                                        </GoogleMap>
                                        )}
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AddMember;