import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, doc, getDoc, onSnapshot } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { FaMapMarkerAlt } from 'react-icons/fa'
import { TbUser, TbTool, TbEdit, TbForbid2, TbFileSearch, TbPhoto, TbHome, TbUsers, TbUserPlus } from "react-icons/tb"
import { AiFillShop, AiFillInfoCircle } from "react-icons/ai"

import LeftMenu from './LeftMenu'

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const ShopTech = () => {
    const params = useParams();
    const shopId = params.id
    const [shop, setShop] = useState([])
    const [technicians, setTechnicians] = useState([])
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState()
    const navigate = useNavigate();
    const [openDrawer, setOpenDrawer] = useState(false)

    const [loading, error] = useAuthState(auth);

    const getDocShop = async(id) => {
        await getDoc(doc(db, 'shops', id)).then((shopSnapshot) => {
            setShop(shopSnapshot.data());
            if(shopSnapshot.data().user_id) {
                setMemberId(shopSnapshot.data().user_id)
                getDoc(doc(db, 'users', shopSnapshot.data().user_id)).then((memberDoc) => {
                    setMember(memberDoc.data())
                })
            }
        }).then(() => {
            const techSnapshot = query(collection(db, `shops/${shopId}/technician`))
            onSnapshot(techSnapshot, (querySnapshot) => {
                setTechnicians(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        getDocShop(shopId)
    },[loading])

return (
    <>
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="technicians" isShop={true} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ข้อมูลช่าง</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="technicians" isShop={true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingTop: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 30 }}>
                            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                                <h4 style={{ display: 'flex', flex: 1}}><TbTool style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                <b>ข้อมูลช่าง</b></h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
                                    <div style={{ paddingTop: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row' }}>
                                        <h6 style={{ display: 'flex', flex: 1, marginTop: 5}}><TbUsers style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>รายชื่อช่างในร้าน</b></h6>
                                        <div style={{ float: 'right',  }}>
                                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/technician/add/${shopId}`) }}>
                                                <TbUserPlus style={{ fontSize: 20, marginRight: 5 }} />เพิ่มช่างในร้าน
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ }}>
                                        <table className="table" style={{  }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: '90%'}}>ชื่อ-นามสกุล</th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                technicians?.map((technician, i) => (
                                                    <tr key={i}>
                                                        <td style={{ paddingLeft: 10 }}> </td>
                                                        <th scope="row">
                                                            <>
                                                                <font style={{ marginRight: 10}}>{technician.data.firstname}</font>
                                                                <font style={{ marginRight: 10}}>{technician.data.lastname}</font>
                                                            </>
                                                        </th>
                                                        <td style={{ display: 'flex', flexDirection: 'row', flex:1}}>
                                                            <div className="flex-container" style={{  }} >
                                                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex', float:'right', whiteSpace: 'nowrap',marginBottom: 5 }} onClick={() => { return navigate(`/technician/edit/${shopId}/${technician.id}`) }}><TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขข้อมูล</button>
                                                                <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex', float:'right', whiteSpace: 'nowrap',marginBottom: 5 }} onClick={() => { return navigate(`/technician/view/${shopId}/${technician.id}`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูล</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="d-none d-md-block d-lg-block" style={{ display: 'flex', flexDirection: 'column', flex: 3, borderLeft: '1px solid #cccccc', marginLeft: 20, paddingLeft: 20}}>
                                    <div style={{ paddingTop: 10, borderBottom: '1px solid #cccccc' }}>
                                        <h6><AiFillShop style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>ข้อมูลร้านค้า</b></h6>
                                    </div>
                                    <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ผู้ใช้ </strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                {(member) && (
                                                <>
                                                    <font style={{ marginRight: 10}}>{member.firstname?member.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                                    <font style={{ marginRight: 10}}>{member.lastname?member.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                                    <font style={{ marginRight: 10}}>({member.display_name?member.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font>
                                                </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>email </strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                {(member) && (
                                                <><font style={{ marginRight: 10}}>{member.email?member.email:""}</font></>
                                                )}
                                            </div>
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ประเภทร้านค้า</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {(shop.type==1)&&(<div><TbUser style={{ fontSize: 20, marginRight: 5 }} />บุคคลธรรมดา</div>)}
                                            {(shop.type==2)&&(<div><AiFillShop style={{ fontSize: 20, marginRight: 5 }} />นิติบุคคล</div>)}
                                        </div>
                                    </div> 
                                    {(shop.type==2)&& (
                                    <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อร้านค้า</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.shopname?shop.shopname:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    )}
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อ (เจ้าของ)</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.firstname?shop.firstname:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>นามสกุล (เจ้าของ)</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.lastname?shop.lastname:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div> 
                                    <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>เบอร์โทรศัพท์</strong></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                            {shop.tel_no?shop.tel_no:(<font className="text-danger">ไม่ระบุ</font>)}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}

export default ShopTech;