import { collection, addDoc } from 'firebase/firestore'

export function addShopNotification(db, shopId, message, notiType, id, link) {
    const logDate = new Date()
    addDoc(collection(db, `/shops/${shopId}/notifications`), {
        date: logDate.getTime(),
        message: message,
        link: link,
        type: notiType,
        id: id,
        status: 0
    })
}

export function addUserNotification(db, userId, message, notiType, id, link) {
    const logDate = new Date()
    addDoc(collection(db, `/users/${userId}/notifications`), {
        date: logDate.getTime(),
        message: message,
        link: link,
        type: notiType,
        id: id,
        status: 0
    })
}