import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, and, or, limit, getDoc, doc, updateDoc } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom"
import { getBrand, getModel } from '../helper/BrandHelper'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

import Slider from '@mui/material/Slider'
import './../home/Home2.css'
import './Post.css'
import Pagination from '@mui/material/Pagination'

const Posts = () => {
    const params = useParams()
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [user, loading, error] = useAuthState(auth)

    const [uid, setUid] = useState('')
    const [docId, setDocId] = useState('')
    const [board, setBoard] = useState(params.board*1)
    const [keywordBrand, setKeywordBrand] = useState('')
    const [keywordModel, setKeywordModel] = useState('')

    const [posts, setPosts] = useState([])
    const [electricType, setElectricType] = useState([])
    const [searchTypeList, setSearchTypeList] = useState([])
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(99999999)
    const [sliderValue, setSliderValue] = useState([0, 99999999])
    const [openDrawer, setOpenDrawer] = useState(false)

    const onMobile = window.innerWidth < 920
    var imagePostHeight = 190
    var thumpnailWidth = 100
    var h1FontSize = 42
    var h2FontSize = 24
    var h3FontSize = 18
    var textFontSize = 16
    var numRowPost = 4

    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        imagePostHeight = 150
        thumpnailWidth = 80
        h1FontSize = 32
        h2FontSize = 20
        h3FontSize = 18
        textFontSize = 16
        numRowPost = 3
    } else if(window.innerWidth < 576) {
        imagePostHeight = 100
        thumpnailWidth = 60
        h1FontSize = 24
        h2FontSize = 18
        h3FontSize = 14
        textFontSize = 14
        numRowPost = 2
    }

    const [width, setWidth] = useState(100)
    const [height, setHeight] = useState(imagePostHeight)

    const [postsPage, setPostsPage] = useState([])
    const [page, setPage] = useState(1)

    const handlePage = (event, value) => {
        const postsSlide = posts.slice(((value-1)*20), (((value-1)*20)+20))
        setPostsPage(postsSlide)
        setPage(value)
    }
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH")
        const time_string = new Date(datetime).toLocaleTimeString("th-TH")
        const time = time_string.split(':')
        return date_string+" "+time[0]+":"+time[1]
    }

    const clickShopAds = (position, id) => {
        getDoc(doc(db, `ads/active/position_${position}`, id)).then(async(adsDoc) => {
            var click = 1
            if(adsDoc.data().click) {
                click = adsDoc.data().click + 1
            }
            updateDoc(doc(db, `ads/active/position_${position}`, id), {
                click: click
            }).then(() => {
                navigate(`/shop/history/${adsDoc.data().shop_id}`)
            })
        })
    }

    const getMinMaxPrice = () => {
        var minQuery = query(collection(db, 'posts'), where('onboard', '==', board), orderBy('price', 'asc'), limit(1))
        onSnapshot(minQuery, (querySnapshot) => {
            if(querySnapshot.docs.length > 0) {
                if(isNaN(querySnapshot.docs[0].data().price)) setMinPrice(0)
                else setMinPrice(querySnapshot.docs[0].data().price*1)
            }
        })
        var maxQuery = query(collection(db, 'posts'), where('onboard', '==', board), orderBy('price', 'desc'), limit(1))
        onSnapshot(maxQuery, (querySnapshot) => {
            if(querySnapshot.docs.length > 0) {
                if(isNaN(querySnapshot.docs[0].data().price)) setMaxPrice(0)
                else setMaxPrice(querySnapshot.docs[0].data().price*1)
            }
        })
    }

    const [adsPositionList, setAdsPositionList] = useState([])

    const adsColor = [ "#F3F7FB", "#EEF5E8", "#FBEFE8", "#FEFBF0" ]
    const fetchAdsPosition = () => {
        adsPositionList.splice(0, adsPositionList.length)
        setAdsPositionList([...adsPositionList])
        var position = 6
        if(board == 3) { position = 7 }
        onSnapshot(query(collection(db, `ads/active/position_${position}`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(8)), (result) => {
            result.forEach(async(ads) => {
                getDoc(doc(db, `posts/`, ads.data().post_id)).then((postDoc) => {
                    var brand = 'ไม่ระบุ'
                    var model = 'ไม่ระบุ'
                    var promisesTask = []
                    var getBrandTask = getBrand(db, postDoc.data().brand_id, postDoc.data().brand).then((brandName) => {
                        brand = brandName
                    })
                    promisesTask.push(getBrandTask)
    
                    var getModelTask = getModel(db, postDoc.data().model_id, postDoc.data().model).then((modelName) => {
                        model = modelName
                    })
                    promisesTask.push(getModelTask)

                    Promise.all(promisesTask).then(() => {
                        adsPositionList.push({
                            id: ads.id,
                            data: ads.data(),
                            post: postDoc.data(),
                            brand: brand,
                            model: model
                        })
                        setAdsPositionList([...adsPositionList], {
                            id: ads.id,
                            data: ads.data(),
                            post: postDoc.data(),
                            brand: brand,
                            model: model
                        })
                    })
                })
            })
        })
    }

    const handleSearch = () => {
        const constraints = []
        const order = []
        constraints.push(where('onboard', '==', board))
        constraints.push(or(where('status', '==', 1), where('status', '==', 2)))
        if(searchTypeList.length > 0) {
            constraints.push(where('type_id', 'in', searchTypeList))
        }
        if(keywordBrand) {
            constraints.push(and(where('brand', '>=', keywordBrand), where('brand', '<=', keywordBrand +  '\uf8ff')))
            order.push(orderBy('brand', 'asc'))
        }
        if(keywordModel) {
            constraints.push(and(where('model', '>=', keywordModel), where('model', '<=', keywordModel +  '\uf8ff')))
            order.push(orderBy('model', 'asc'))
        }
        if(sliderValue) {
            if(sliderValue[0] == minPrice && sliderValue[1] == maxPrice) {
            } else {
                constraints.push(and(where('price', '>=', sliderValue[0]), where('price', '<=', sliderValue[1])))
                order.push(orderBy('price', 'asc'))
            }
        }
        order.push(orderBy('post_timestamp', 'desc'))
        if(constraints.length >= 2) {
            var postQuery = query(collection(db, 'posts'), and(...constraints), ...order)
        } else {
            var postQuery = query(collection(db, 'posts'), ...constraints, ...order)
        }
        onSnapshot(postQuery, (querySnapshot) => {
            postsPage.splice(0, postsPage.length)
            setPostsPage([...postsPage])
            posts.splice(0, posts.length)
            setPosts([...posts])
            querySnapshot.forEach((doc) => {
                var brand = 'ไม่ระบุ'
                var model = 'ไม่ระบุ'
                var province = 'ไม่ระบุ'
                var district = 'ไม่ระบุ'
                var promisesTask = []

                var getBrandTask = getBrand(db, doc.data().brand_id, doc.data().brand).then((brandName) => {
                    brand = brandName
                })
                promisesTask.push(getBrandTask)

                var getModelTask = getModel(db, doc.data().model_id, doc.data().model).then((modelName) => {
                    model = modelName
                })
                promisesTask.push(getModelTask)

                if(doc.data().province) {
                    var provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', doc.data().province * 1))).then((docs) => {
                        if(docs.docs.length > 0) { province = docs.docs[0].data().province_name }
                    })
                    promisesTask.push(provinceTask)
                }

                if(doc.data().district) {
                    var districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', doc.data().district))).then((docs) => {
                        if(docs.docs.length > 0) { district = docs.docs[0].data().district_name }
                    })
                    promisesTask.push(districtTask)
                }

                Promise.all(promisesTask).then(() => {
                    posts.push({
                        id: doc.id,
                        data: doc.data(),
                        brand: brand,
                        model: model,
                        province: province,
                        district: district,
                    })
                    setPosts([...posts], {
                        id: doc.id,
                        data: doc.data(),
                        brand: brand,
                        model: model,
                        province: province,
                        district: district,
                    })

                    const postsSlide = posts.slice(0, 20)
                    setPostsPage(postsSlide)
                })
            })
        })
    }

    const handleSearchByType = (id) => {
        const others = Array.from(
            document.getElementsByClassName('type-other')
        )
        const allType = Array.from(
            document.getElementsByClassName('type-all')
        )
        if(id == 0) {
            searchTypeList.splice(0, searchTypeList.length)
            setSearchTypeList([...searchTypeList])
            allType.forEach(element => {
                element.checked = true
            })
            others.forEach(element => {
                element.checked = false
                element.removeAttribute('checked')
            })
        } else {
            if(searchTypeList.indexOf(id) < 0) {
                searchTypeList.push(id)
            } else {
                searchTypeList.splice(searchTypeList.indexOf(id), 1)
            }
            setSearchTypeList([...searchTypeList])
            if(searchTypeList.length > 0) {
                allType.forEach(element => {
                    element.checked = false
                    element.removeAttribute('checked')
                })
            } else {
                allType.forEach(element => {
                    element.checked = true
                })
            }
        }
        handleSearch()
    }

    const getElectricType = () => {
        getDocs(query(collection(db, 'electric_type'))).then((docs) => {
            setElectricType(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDocId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    const fetchPosts = async () => {
        try {
            handleSearch()
        } catch (err) {
            alert(err.message)
        }
    }

    const valuetext = (value) => {
        return `${value}°C`
    }

    const handleSliderChange = (event, newValue, activeThumb) => {
        const minDistance = 10
        if (!Array.isArray(newValue)) {
            return
        }
    
        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], maxPrice - minDistance)
                setSliderValue([clamped, clamped + minDistance])
            } else {
                const clamped = Math.max(newValue[1], minDistance)
                setSliderValue([clamped - minDistance, clamped])
            }
        } else {
            setSliderValue(newValue)
        }
    }

    useEffect(() => {
        if (loading) return;
        if (user) getDocUser()
        fetchPosts()
        getElectricType()
        if(board != 1) {
            getMinMaxPrice()
            fetchAdsPosition()
        }

    }, [user, loading])

    useEffect(() => {
        handleSearch()
    }, [keywordBrand, keywordModel, sliderValue])

    useEffect(() => {
        if(board != 1) setSliderValue([minPrice, maxPrice])
    }, [minPrice, maxPrice])

    const resizeDiv = new ResizeObserver((event) => {
        setWidth(event[0].contentBoxSize[0].inlineSize-1)
        setHeight(imagePostHeight)
    })

    useEffect(() => {
        resizeDiv.observe(document.getElementById("divPost"))
        resizeDiv.observe(document.getElementById("divAds"))
    })
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    return (
        <>
            <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 280 }} role="presentation">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h2FontSize, fontWeight: "600" }}>ค้นหาประกาศ</font></div>
                            {board!=1 &&
                                <div style={{ display: 'flex', flexDirection: 'column'}}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ช่วงราคา</font></div>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: textFontSize, fontWeight: "400" }}>{number_format.format(sliderValue[0])} - {number_format.format(sliderValue[1])} บาท</font></div>
                                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}>
                                        <Slider
                                            getAriaLabel={() => 'Minimum distance shift'}
                                            value={sliderValue}
                                            onChange={handleSliderChange}
                                            valueLabelDisplay="auto"
                                            getAriaValueText={valuetext}
                                            disableSwap
                                            min={minPrice}
                                            max={maxPrice}
                                        />
                                    </div>
                                </div>
                            }
                            <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ประเภท</font></div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingBottom: 10, paddingLeft: 10 }}>
                                <div><input className="form-check-input type-all" type="checkbox" value="0" defaultChecked={true} onClick={ (e) => { handleSearchByType(e.target.value) }} /> ทั้งหมด</div>
                                {electricType?.map((type, i) => (
                                <div style={{ whiteSpace: 'nowrap'}} key={type.id}><input className="form-check-input type-other" type="checkbox" value={type.id} onClick={ (e) => { handleSearchByType(e.target.value) }} /> {type.data.name}</div>
                                ))}
                                <div><input className="form-check-input type-other" type="checkbox" value="999" id="typeOther" onClick={ (e) => { handleSearchByType(e.target.value) }} /> อื่นๆ</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ยี่ห้อ</font></div>
                            <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="ค้นหายี่ห้อ" 
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { setKeywordBrand(e.target.value) }} 
                                        value={keywordBrand} /></div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>รุ่น</font></div>
                            <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="ค้นหารุ่น" 
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { setKeywordModel(e.target.value)  }} 
                                        value={keywordModel} /></div>
                        </div>
                    </div>
                </Box>
            </Drawer>
            <div className="container-fluid flex-container" style={{ marginTop: 10, paddingLeft: 20 }}>
                <div className="d-none d-md-block d-lg-block" style={{ display: 'flex', flexDirection: 'column', width: 250 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h2FontSize, fontWeight: "600" }}>ค้นหาประกาศ</font></div>
                        {board!=1 &&
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ช่วงราคา</font></div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: textFontSize, fontWeight: "400" }}>{number_format.format(sliderValue[0])} - {number_format.format(sliderValue[1])} บาท</font></div>
                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}>
                                    <Slider
                                        getAriaLabel={() => 'Minimum distance shift'}
                                        value={sliderValue}
                                        onChange={handleSliderChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetext}
                                        disableSwap
                                        min={minPrice}
                                        max={maxPrice}
                                    />
                                </div>
                            </div>
                        }
                        <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ประเภท</font></div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingBottom: 10, paddingLeft: 10 }}>
                            <div><input className="form-check-input type-all" type="checkbox" value="0" defaultChecked={true} onClick={ (e) => { handleSearchByType(e.target.value) }} /> ทั้งหมด</div>
                            {electricType?.map((type, i) => (
                            <div style={{ whiteSpace: 'nowrap'}} key={type.id}><input className="form-check-input type-other" type="checkbox" value={type.id} onClick={ (e) => { handleSearchByType(e.target.value) }} /> {type.data.name}</div>
                            ))}
                            <div><input className="form-check-input type-other" type="checkbox" value="999" id="typeOther" onClick={ (e) => { handleSearchByType(e.target.value) }} /> อื่นๆ</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ยี่ห้อ</font></div>
                        <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="ค้นหายี่ห้อ" 
                                    aria-describedby="button-addon2"
                                    onChange={(e) => { setKeywordBrand(e.target.value) }} 
                                    value={keywordBrand} /></div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>รุ่น</font></div>
                        <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="ค้นหารุ่น" 
                                    aria-describedby="button-addon2"
                                    onChange={(e) => { setKeywordModel(e.target.value)  }} 
                                    value={keywordModel} /></div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <font style={{ fontSize: h1FontSize, fontWeight: "600" }}>
                            {board==1 && "ประกาศทิ้งซากเครื่องใช้ไฟฟ้า"}
                            {board==2 && "ประกาศขายซากเครื่องใช้ไฟฟ้า"}
                            {board==3 && "ประกาศขายเครื่องใช้ไฟฟ้ามือสอง"}
                        </font>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginTop: 'auto', marginBottom: 5, alignSelf: 'end', float: 'right' }}>
                            <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                                <button type="button" className="btn btn-light" onClick={toggleDrawer(true)}>ค้นหา</button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className='flex-container' style={{ paddingTop: 10, paddingBottom: 5, alignContent: 'center' }}>
                                <div style={{ marginLeft: 15, paddingTop: 5, marginRight: 10, display: 'flex', flex: 1 }}><strong>รายการที่ {(((page-1)*20)+1)} - {(page*20 > posts.length)?posts.length:page*20} จากทั้งหมด {number_format.format(posts.length)} ประกาศ</strong></div>
                                <Pagination style={{ fontFamily: 'Prompt', marginRight: 10 }} count={Math.ceil(posts.length/20)} page={page} onChange={handlePage} color="primary" showFirstButton showLastButton />
                            </div>
                        </div>
                    </div>
                    {(board != 1) && (
                    <div id="divAds" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', alignContent: 'space-between', backgroundColor: '#FFFFFF', paddingTop: 10 }}>
                        {adsPositionList?.map((post, i) => (
                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', width: (width/numRowPost), padding: 5, marginBottom: 5, cursor: 'pointer'  }} key={i} onClick={() => { navigate(`/post/${post.id}`) }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#ffffff', borderRadius: 15, border: "1px solid #dddddd" }}>
                                <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: adsColor[i%4] }}>
                                        <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (width/numRowPost), height: height }} src={post.post.picture} alt="APP3R" />
                                    </div>
                                </div>
                            {post.post.onboard == 2 && (
                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1, backgroundColor: adsColor[i%4] }}>
                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                        <p className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </p>
                                        <font style={{ fontSize: 14, color: '#984740' }}>
                                            <font style={{ fontWeight: '600' }}>ราคาขาย:</font> ฿{number_format.format(post.post.price)} 
                                        </font>
                                        <font style={{ fontSize: 14, color: '#626167' }}>วันที่ประกาศ: {dateFormat(post.post.post_timestamp)}</font>
                                    </div>
                                </div>
                            )}
                            {post.post.onboard == 3 && (
                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1, backgroundColor: adsColor[i%4] }}>
                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                        <p className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </p>
                                        <font style={{ fontSize: 14, color: '#984740' }}>
                                            <font style={{ fontWeight: '400' }}>ราคาขาย:</font> ฿{number_format.format(post.post.price)}  
                                            <font style={{ marginLeft: 10 }}>สภาพ: 
                                            {post.post.condition?"":" ไม่ระบุ"}
                                            {post.post.condition==1?" มือสองเหมือนใหม่":""}
                                            {post.post.condition==2?" มือสองสภาพดี":""}
                                            {post.post.condition==3?" มือสองพอใช้":""}
                                            {post.post.condition==4?" มือสองเก่ามาก":""} </font>
                                        </font>
                                        <font style={{ fontSize: 14, color: '#626167' }}>วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}</font>
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>))}
                    </div>)}
                    <div id="divPost" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', alignContent: 'space-between', backgroundColor: '#FFFFFF', paddingTop: 10 }}>
                        {postsPage?.map((post, i) => (
                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', width: (width/numRowPost), padding: 5, marginBottom: 5, cursor: 'pointer' }} key={i} onClick={() => { navigate(`/post/${post.id}`) }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#ffffff', borderRadius: 15, border: "1px solid #dddddd" }}>
                                <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                        <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (width/numRowPost), height: height }} src={post.data.picture} alt="APP3R" />
                                    </div>
                                </div>
                            {post.data.onboard == 1 && (
                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                        <p className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </p>
                                        <font style={{ fontSize: 14, color: '#984740' }}>
                                            <font style={{ fontWeight: '600' }}>จังหวัด:</font> {post.province} <font style={{ fontWeight: '600' }}>อำเภอ:</font> {post.district}
                                        </font>
                                        <font style={{ fontSize: 14, color: '#626167' }}>วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}</font>
                                    </div>
                                </div>
                            )}
                            {post.data.onboard == 2 && (
                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                        <p className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </p>
                                        <font style={{ fontSize: 14, color: '#984740' }}>
                                            <font style={{ fontWeight: '600' }}>ราคาขาย:</font> ฿{number_format.format(post.data.price)} 
                                        </font>
                                        <font style={{ fontSize: 14, color: '#626167' }}>วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}</font>
                                    </div>
                                </div>
                            )}
                            {post.data.onboard == 3 && (
                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                        <p className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </p>
                                        <font style={{ fontSize: 14, color: '#984740' }}>
                                            <font style={{ fontWeight: '400' }}>ราคาขาย:</font> ฿{number_format.format(post.data.price)}  
                                            <font style={{ marginLeft: 10 }}>สภาพ: 
                                            {post.data.condition?"":" ไม่ระบุ"}
                                            {post.data.condition==1?" มือสองเหมือนใหม่":""}
                                            {post.data.condition==2?" มือสองสภาพดี":""}
                                            {post.data.condition==3?" มือสองพอใช้":""}
                                            {post.data.condition==4?" มือสองเก่ามาก":""} </font>
                                        </font>
                                        <font style={{ fontSize: 14, color: '#626167' }}>วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}</font>
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>))}
                    </div>
                </div>
            </div>
        </>
    )
}

export default Posts