import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, where, deleteDoc, doc, getDoc, getDocs } from "firebase/firestore"
import { db } from "../../assets/js/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { TbCirclePlus } from "react-icons/tb";
import { AiOutlineShop, AiFillShop, AiFillInfoCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import "../../assets/css/memberList.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { TbUser, TbSearch } from "react-icons/tb"

const UserPostList = () => {
    const [posts, setPosts] = useState([])
    const navigate = useNavigate();

    const params = useParams()
    const memberId = params.id
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH");
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        /* const split1 = stringified.split('T');
        const date = split1[0].replace(/\-/g, ' ');
        const dates = date.split(' ') */
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]
    }

    useEffect(() => {
        const postsQuery = query(collection(db, 'posts'), 
                where('poster_id', '==', memberId), 
                orderBy('post_timestamp', 'desc'))
        onSnapshot(postsQuery, (postsSnapshot) => {
            console.log(postsSnapshot)
            postsSnapshot.forEach((post) => {
                console.log(post.id)
                getDocs(collection(db, `posts/${post.id}/offers/`)).then((offers) => {
                    posts.push({
                        id: post.id,
                        data: post.data(),
                        offersNum: offers.docs.length
                    })
                    setPosts([...posts, {
                        id: post.id,
                        data: post.data(),
                        offersNum: offers.docs.length
                    }])
                })
            })
        })
    },[])

    var postKey = []
    var postList = []
    posts.forEach(element => {
        if(postKey.lastIndexOf(element.id) < 0) {
            postKey.push(element.id)
            postList.push(element)
        }
    });
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายการประกาศของผู้ใช้ </b></h4>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                <table className="table" style={{ marginLeft: 10}}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10 }}> </th>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>รายการประกาศ</th>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, textAlign: 'center'}}>จำนวนข้อเสนอ</th>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, textAlign: 'center'}}>สถานะ</th>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingLeft: 20}}> </th>
                        </tr>
                    </thead>
                    <tbody>
            {
                postList?.map((post, i) => (
                    <tr key={i}>
                        <td style={{ paddingLeft: 10 }}> </td>
                        <th scope="row">
                            {(post.data.onboard == 1)&&(<span className="badge bg-warning" style={{ marginRight: 5 }}>ทิ้งซาก</span>)}
                            {(post.data.onboard == 2)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}>ขายซาก</span>)}
                            {(post.data.onboard == 3)&&(<span className="badge bg-success" style={{ marginRight: 5 }}>ขายมือสอง</span>)}
                            {post.data.type} {post.data.brand} {post.data.model}
                            <p className="card-text" style={{ fontSize: 14 }}><strong>วันที่ประกาศ:</strong> {dateFormat(post.data.post_timestamp)}</p>
                        </th>
                        <td style={{ textAlign: 'center' }}><a href='' onClick={() => { return navigate(`/admin/post/offers/${post.id}`) }}>{post.offersNum}</a></td>
                        <td style={{ textAlign: 'center' }}>
                            {(post.data.status == 1)&&(<span className="badge bg-success" style={{ marginRight: 5 }}>ปกติ</span>)}
                            {(post.data.status == 2)&&(<span className="badge bg-warning" style={{ marginRight: 5 }}>มีผู้เสนอราคา</span>)}
                            {(post.data.status == 3)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}>เลือกข้อเสนอแล้ว</span>)}
                            {(post.data.status == 4)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}>ตกลงนัดหมาย</span>)}
                        </td>
                        <td style={{ paddingLeft: 20, height: '100%'}}>
                            <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/post/view/${post.id}`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูล</button>
                        </td>
                    </tr>
                ))
            }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default UserPostList;