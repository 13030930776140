import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, getDocs, doc, getDoc, onSnapshot, orderBy, where } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom"
import { AiFillShop, AiFillInfoCircle, AiFillCheckCircle } from "react-icons/ai"
import { getBoartStatus } from '../helper/PostStatusHelper'
import { getBrand, getModel } from '../helper/BrandHelper'
import { defaultLatLng, googleMapsApiKey } from "../../../assets/constants/locations"
import dayjs from 'dayjs'

const Post = () => {
    const params = useParams()
    const number_format = new Intl.NumberFormat()
    const board = 3
    const postId = params.id
    const shopId = params.shopId
    const navigate = useNavigate()
    const today = dayjs()

    const [post, setPost] = useState([])
    const [postMemberId, setPostMemberId] = useState('')
    const [postMember, setPostMember] = useState([])
    const [images, setImages] = useState([])
    const [brandName, setBrandName] = useState('ไม่ระบุ')
    const [modelName, setModelName] = useState('ไม่ระบุ')
    const [showImage, setShowImages] = useState('')
    const [address, setAddress] = useState('')
    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [subdistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [postcode, setPostcode] = useState('')
    const [status, setStatus] = useState(0)

    const [postOverDue, setPostOverDue] = useState(false)
    const [postOverDueDiffer, setPostOverDueDiffer] = useState(0)

    const [offers, setOffers] = useState([])
    const [offerKeys, setOfferKeys] = useState([])
    const [appointment, setAppointment] = useState([])
    const [showAppointment, setShowAppointment] = useState(false)

    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [loading, error] = useAuthState(auth)
    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey })
    const center = { lat: lat, lng: lng }

    const dateFormat = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }
 
    const dateFormatTime = (datetime) => {
        const date = (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
        const time_string = new Date(datetime).toLocaleTimeString("th-TH")
        const time = time_string.split(':')
        return date+" เวลา "+time[0]+":"+time[1]+" น."
    }

    const getImages = async (id) => {
        const imagesQuery = query(collection(db, `posts/${id}/images`))
        onSnapshot(imagesQuery, (querySnapshot) => {
            setImages(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const getPost = (id) => {
        getDoc(doc(db, 'posts', id)).then((postDoc) => {
            if (postDoc.exists()) {
                var todayToDate = today.toDate()
                var pickToDate = postDoc.data().pick_date.toDate()
                var todayDate = new Date(todayToDate.getFullYear(), todayToDate.getMonth(), todayToDate.getDate())
                var pickPostDate = new Date(pickToDate.getFullYear(), pickToDate.getMonth(), pickToDate.getDate())
                if(todayDate > pickPostDate) { 
                    setPostOverDue(true) 
                    const diffTime = Math.abs(todayDate - pickPostDate)
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                    setPostOverDueDiffer(diffDays)
                }

                setPost(postDoc.data())
                setShowImages(postDoc.data().picture)
                setLat(postDoc.data().lat)
                setLng(postDoc.data().lng)

                getBrand(db, postDoc.data().brand_id, postDoc.data().brand).then((brandName) => {
                    setBrandName(brandName)
                })

                getModel(db, postDoc.data().model_id, postDoc.data().model).then((modelName) => {
                    setModelName(modelName)
                })

                setAddress(postDoc.data().address)
                setAddressNo(postDoc.data().address_no)
                setVillage(postDoc.data().village)
                setBuilding(postDoc.data().building)
                setFloor(postDoc.data().floor)
                setRoom(postDoc.data().room)
                setSubroad(postDoc.data().subroad)
                setRoad(postDoc.data().road)

                var promisesTask = []

                if(postDoc.data().province) {
                    const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', postDoc.data().province * 1))).then((docs) => {
                        if(docs.docs.length > 0) setProvince(docs.docs[0].data().province_name)
                    })
                    promisesTask.push(provinceTask)
                }

                if(postDoc.data().district) {
                    const districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', postDoc.data().district))).then((docs) => {
                        if(docs.docs.length > 0) setDistrict(docs.docs[0].data().district_name)
                    })
                    promisesTask.push(districtTask)
                }

                if(postDoc.data().sub_district) {
                    const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', postDoc.data().sub_district * 1))).then((docs) => {
                        if(docs.docs.length > 0) setSubDistrict(docs.docs[0].data().subdistrict_name)
                    })
                    promisesTask.push(subdistrictTask)
                }

                var memberDocs = getDoc(doc(db, `users`, postDoc.data().poster_id)).then((memberData) => {
                    setPostMemberId(memberData.id)
                    setPostMember(memberData.data())
                })
                promisesTask.push(memberDocs)

                setPostcode(postDoc.data().postcode)

                Promise.all(promisesTask)

                getImages(id)
            } else {
                console.log("Post doesn't exist")
                navigate('/')
            }
        }).then(() => {
            getDocs(query(collection(db, `posts/${postId}/offers`), where('shop_id', '==', shopId), orderBy('offer_timestamp', 'asc'))).then((offerQuery) => {
                offers.splice(0, offers.length)
                setOffers([...offers])
                offerKeys.splice(0, offerKeys.length)
                setOfferKeys([...offerKeys])
                offerQuery.forEach(async(offer) => {
                    getDoc(doc(db, 'shops', offer.data().shop_id)).then(async (shopSnapshot) => {
                        if(shopSnapshot.exists) {
                            if(offerKeys.lastIndexOf(offer.id) < 0) {
                                offerKeys.push(offer.id)
                                var historyDocs = await getDocs(query(collection(db, `shops/${offer.data().shop_id}/cancle_history`)))
                                var offerOverDue = false
                                var todayToDate = today.toDate()
                                var offerToDate = offer.data().pick_date.toDate()
                                var todayDate = new Date(todayToDate.getFullYear(), todayToDate.getMonth(), todayToDate.getDate())
                                var offerDate = new Date(offerToDate.getFullYear(), offerToDate.getMonth(), offerToDate.getDate())
                                var overDueDay = 0
                                if(todayDate > offerDate) { 
                                    offerOverDue = true
                                    const diffTime = Math.abs(todayDate - offerDate);
                                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                                    overDueDay = diffDays
                                }
                                
                                offers.push({
                                    id: offer.id,
                                    data: offer.data(),
                                    over_due: offerOverDue,
                                    over_due_days: overDueDay,
                                    shop_id: offer.data().shop_id,
                                    shop: shopSnapshot.data(),
                                    shop_history: historyDocs
                                })
                                setOffers([...offers], {
                                    id: offer.id,
                                    data: offer.data(),
                                    over_due: offerOverDue,
                                    over_due_days: overDueDay,
                                    shop_id: offer.data().shop_id,
                                    shop: shopSnapshot.data(),
                                    shop_history: historyDocs
                                })
                            }
                        }
                    })
                })
            })
        }).then(() => {
            getDocs(query(collection(db, `appointments`), where('post_id', '==', postId), where('shop_id', '==', shopId))).then(async(appointmentQuery) => {
                if(appointmentQuery.docs.length > 0) {
                    var todayToDate = today.toDate()
                    var appointmentToDate = appointmentQuery.docs[0].data().appointment_date.toDate()
                    var todayDate = new Date(todayToDate.getFullYear(), todayToDate.getMonth(), todayToDate.getDate())
                    var appointmentDate = new Date(appointmentToDate.getFullYear(), appointmentToDate.getMonth(), appointmentToDate.getDate())
                    var appointmentOverDue = false
                    var overDueDay = 0
                    if(todayDate > appointmentDate) { 
                        appointmentOverDue = true
                        const diffTime = Math.abs(todayDate - appointmentDate);
                        const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24)); 
                        overDueDay = diffDays
                    }
                    var shopDocs = await getDoc(doc(db, `shops`, appointmentQuery.docs[0].data().shop_id))
                    setAppointment({
                        id: appointmentQuery.docs[0].id,
                        data: appointmentQuery.docs[0].data(),
                        appointment_over_due: appointmentOverDue,
                        over_due_days: overDueDay,
                        shop: shopDocs
                    })
                    setShowAppointment(true)
                }
            })
        }) 
    }

    useEffect(() => {
        getPost(postId)
    },[loading])
    
    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>ข้อมูลประกาศขายเครื่องใช้ไฟฟ้ามือสอง</b></h4>
                </div>
                <div className="flex-container"  style={{ display: 'flex', flex: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingTop: 10, flex: 4 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <div style={{ marginRight: 5 }}>
                                <h4><span className="badge bg-success" style={{ marginRight: 5 }}>ขายมือสอง</span></h4>
                            </div>
                            <div style={{ marginRight: 5 }}>
                                <h4>
                                    {(post.status == 1)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus(board, 1, 'admin')}</span>)}
                                    {(post.status == 2)&&(<span className="badge bg-primary" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus(board, 2, 'admin')}</span>)}
                                    {(post.status == 3)&&(<span className="badge bg-warning" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus(board, 3, 'admin')}</span>)}
                                    {(post.status == 4)&&(<span className="badge bg-warning" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus(board, 4, 'admin')}</span>)}
                                    {(post.status == 5)&&(<span className="badge bg-danger" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus(board, 5, 'admin')}</span>)}
                                    {(post.status == 6)&&(<span className="badge bg-danger" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus(board, 6, 'admin')}</span>)}
                                    {(post.status == 9)&&(<span className="badge bg-success" style={{ marginRight: 5 }}><strong>สถานะ:</strong> {getBoartStatus(board, 9, 'admin')}</span>)}
                                </h4>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                            <h4 style={{ display: 'flex', flexDirection: 'row'}}>
                                <strong>{post.type} {brandName} {modelName}</strong>
                            </h4>
                        </div>
                        {(appointment.id) && 
                            (<div><h5><strong>วันที่นัดหมาย:</strong> {dateFormat(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น.</h5></div>)
                        }
                        {(postOverDue && post.status == 1) && <div style={{ marginTop: 5 }}><h6 className='text-danger'><strong>*** เลยวันที่กำหนดซื้อขายไปแล้ว {postOverDueDiffer} วัน ***</strong></h6></div>}
                        <div style={{ marginTop: 5 }}><h6><strong>
                        {(post.isOnwer == '1')?"ผู้ลงประกาศเป็นเจ้าของเครื่องใช้ไฟฟ้า":"ผู้ลงประกาศไม่ได้เป็นเจ้าของเครื่องใช้ไฟฟ้า" }</strong></h6></div>
                        <div style={{ marginTop: 5 }}>
                            <h6><strong>ผู้ประกาศ :</strong> {postMember.firstname} {postMember.lastname} ({postMember.email})</h6>
                            <a href={`/member/view/${postMemberId}`} target='_blank' className="nav-link link-primary"><AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} className='text-primary' /> ดูข้อมูลผู้ประกาศ</a>
                        </div>
                        <div style={{ marginTop: 5 }}><h6><strong>ราคาประกาศขายเครื่องใช้ไฟฟ้า :</strong> {post.price?number_format.format(post.price)+" บาท":"ไม่ระบุ"}</h6></div>
                        <div style={{ marginTop: 5 }}><h6><strong>ขนาด (กxยxส) :</strong> {post.width?post.width:"ไม่ระบุ"} x {post.length?post.length:"ไม่ระบุ"} x {post.height?post.height:"ไม่ระบุ"} (ซม.)</h6></div>
                        <div style={{ marginTop: 5 }}><h6><strong>น้ำหนัก :</strong> {post.weight?post.weight+" กิโลกรัม":"ไม่ระบุ"}</h6></div>
                        <div style={{ marginTop: 5 }}><h6><strong>ปีที่ซื้อ :</strong> {post.buy_year?"พ.ศ. "+post.buy_year:"ไม่ระบุ"}</h6></div>
                        <div style={{ marginTop: 5 }}><h6>
                            <strong>สถาพเครื่อง :</strong> 
                            {post.condition?"":" ไม่ระบุ"}
                            {post.condition==1?" มือสองเหมือนใหม่":""}
                            {post.condition==2?" มือสองสภาพดี":""}
                            {post.condition==3?" มือสองพอใช้":""}
                            {post.condition==4?" มือสองเก่ามาก":""}
                            </h6></div>
                        <div style={{ marginTop: 5 }}>
                            {post.option1?(<><h6><AiFillCheckCircle className='text-success' style={{ fontSize: 20 }} /> อยู่ในระยะประกัน </h6></>):""}
                            {post.option2?(<><h6><AiFillCheckCircle className='text-success' style={{ fontSize: 20 }} /> มีกล่อง </h6></>):""}
                            {post.option3?(<><h6><AiFillCheckCircle className='text-success' style={{ fontSize: 20 }} /> มีคู่มือการใช้งาน </h6></>):""}
                            {post.option4?(<><h6><AiFillCheckCircle className='text-success' style={{ fontSize: 20 }} /> เอกสารอื่นๆ </h6></>):""}
                        </div>
                        <div style={{ marginTop: 5 }}>
                            <h6 style={{ marginBottom: 10 }}><strong>รายละเอียดเพิ่มเติม :</strong></h6>
                            <h6 style={{ marginLeft: 10 }}>{post.description?post.description:"ไม่ระบุ"}</h6>
                        </div>
                        <div style={{ marginTop: 10 }}><h6><strong>วันเวลาที่สะดวกซื้อขาย :</strong> {dateFormat(post.pick_date)} เวลา {post.hour}:{post.minute} น.</h6></div> 
                        <div style={{ marginTop: 10 }}><h6><strong>วันเวลาที่ลงประกาศ :</strong> {dateFormatTime(post.post_timestamp)}</h6></div> 
                        <div style={{ marginTop: 10 }}><h6><strong>ตำแหน่ง :</strong></h6></div>
                        <div style={{ marginTop: 5, height: 200 }}>
                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                            <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                <MarkerF
                                    draggable={false} position={{lat, lng}}
                                />
                            </GoogleMap>
                            )}
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <h6 style={{ marginBottom: 10 }}><strong>ที่อยู่ : </strong> </h6>
                            <h6 style={{ marginLeft: 10 }}>{(addressNo)?"บ้านเลขที่ "+addressNo+" ":""} 
                            {(village)?"หมู่บ้าน "+village+" ":""} 
                            {(building)?"อาคาร "+building+" ":""} 
                            {(floor)?"ชั้น "+floor+" ":""} 
                            {(room)?"ห้อง "+room+" ":""} 
                            {(subroad)?"ซอย "+subroad+" ":""} 
                            {(road)?"ถ."+road+" ":""} 
                            {(subdistrict)?subdistrict+" ":""} 
                            {(district)?district+" ":""} 
                            {(province)?province+" ":""} 
                            {(postcode)?postcode+" ":""}</h6>
                        </div>
                        <div style={{ marginTop: 15 }}>
                            <h6><strong>ที่อยู่เพิ่มเติม :</strong></h6>
                            <h6 style={{ marginLeft: 10 }}>{post.address?post.address:"ไม่ระบุ"}</h6>
                        </div>
                        <div style={{ marginTop: 10 }}><h6><strong>รูปภาพ :</strong></h6></div>
                        <div style={{ display:'flex', flexDirection: 'row', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 15, marginBottom: 10, paddingBottom: 10, textAlign: 'left' }}>
                        {images?.map((image, i) => (
                            <div key={image.id} style={{ margin: 5, minWidth: 100, maxWidth: 100}}>
                                <img src={image.data.url} alt="WEEE" className='img-fluid' />
                            </div>
                        ))}
                        </div>
                    </div>
                    <div className="d-none d-md-block d-lg-block" style={{ minWidth: 10, marginLeft: 10, borderLeft: '1px solid #cccccc' }}>&nbsp;</div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
                        {(offers.length > 0) && 
                        (<div style={{ display:'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 10, paddingTop:10, textAlign: 'left' }}>
                            <h6><strong>ข้อเสนอ :</strong></h6>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                {offers?.map((offer, i) => (
                                    <div className="card" style={{ flex: 1, minWidth: "16rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}  key={offer.id}>
                                        <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column' }} >
                                            <div>
                                                <h4>
                                                    {(offer.data.status == 1) && (<span className="badge bg-warning" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> รอเลือกข้อเสนอ</span>)}
                                                    {(offer.data.status == 2) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ข้อเสนอถูกเลือก</span>)}
                                                    {(offer.data.status == 3) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ยืนยันซื้อขาย</span>)}
                                                    {(offer.data.status == 4) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ข้อเสนอไม่ถูกเลือก</span>)}
                                                    {(offer.data.status == 5) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้ายกเลิก</span>)}
                                                    {(offer.data.status == 6) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ผู้ประกาศยกเลิก</span>)}
                                                </h4>
                                            </div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>ร้านค้า:</strong> <br/>{(offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname}</div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>ราคาเสนอซื้อเครื่องใช้ไฟฟ้า:</strong> {offer.data.price?number_format.format(offer.data.price)+" บาท":"ไม่ระบุ"}</div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>ข้อเสนอเพิ่มเติม:</strong> <br/>{offer.data.message}</div>
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>วันเวลาที่สะดวกเข้าซื้อขาย:</strong> <br/>{dateFormat(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.</div>
                                            {(offer.over_due && (offer.data.status == 1 || offer.data.status == 2)) && 
                                                (<div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}>
                                                    <h6 className='text-danger'><strong>*** เลยวันที่กำหนดซื้อขายไปแล้ว {offer.over_due_days} วัน ***</strong></h6>
                                                </div>)
                                            }
                                            <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>วันเวลาที่ยื่นข้อเสนอ:</strong> <br/>{dateFormatTime(offer.data.offer_timestamp)}</div>
                                            {(offer.data.status == 5) && (
                                                <div className="card-text" style={{ marginBottom: 5, marginLeft: 10 }}><strong>วันเวลาที่ยกเลิกข้อเสนอ:</strong> <br/>{dateFormatTime(offer.data.cancle_date)}</div>
                                            )}
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </div>)
                        }
                        {(offers.length == 0) && (
                            <div style={{ display:'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 10, paddingTop:10, textAlign: 'left' }}>
                                <h6><strong>ข้อเสนอ :</strong></h6>
                                <h6 style={{ marginLeft: 20 }}><strong className='text-danger'>ไม่มีข้อเสนอ</strong></h6>
                            </div>
                        )}
                        {showAppointment && <>
                            <div style={{ display:'flex', flexDirection: 'column', flexWrap: 'wrap', backgroundColor:'#ffffff', marginLeft: 10, paddingTop:10, textAlign: 'left' }}>
                                <h6><strong>การนัดหมาย :</strong></h6>
                                <div className="card" style={{ display:'flex', flexDirection: 'column', padding: 10, marginLeft: 5, marginRight: 5 }}>
                                    <div style={{ display:'flex' }}>
                                        <h4>
                                        {(appointment.data.status == 1) && (<span className="badge bg-warning" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> รอวันนัดหมาย</span>)}
                                        {(appointment.data.status == 2) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้าจ่ายเหรียญซื้อเครื่องใช้ไฟฟ้า</span>)}
                                        {(appointment.data.status == 3) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ผู้ประกาศยืนยันขายเครื่องใช้ไฟฟ้า</span>)}
                                        {(appointment.data.status == 4) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้ายกเลิกซื้อเครื่องใช้ไฟฟ้า</span>)}
                                        {(appointment.data.status == 5) && (<span className="badge bg-danger" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ผู้ประกาศยกเลิกขายเครื่องใช้ไฟฟ้า</span>)}
                                        {(appointment.data.status == 6) && (<span className="badge bg-success" style={{ marginRight: 5, marginBottom: 5 }}><strong>สถานะ:</strong> ร้านค้ายืนยันได้รับเครื่องใช้ไฟฟ้า</span>)}
                                        </h4>
                                    </div>
                                    <div style={{ marginBottom: 10, marginLeft: 10 }}><strong>วันนัดหมาย:</strong> {dateFormat(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น.</div>
                                    {appointment.data.status == 3 && (<div style={{ marginLeft: 10 }}>
                                        <div><strong>ร้านค้ามัดจำไปแล้ว 30% : {number_format.format(appointment.data.deposit_coin)} เหรียญ</strong></div>
                                    </div>)}
                                    {appointment.data.status == 6 && (<div style={{ marginLeft: 10 }}>
                                        <div><strong>ร้านค้ามัดจำเหรียญ 30% : {number_format.format(appointment.data.deposit_coin)} เหรียญ</strong></div>
                                        <div><strong>ร้านค้าจ่านยเหรียญทั้งหมด : {number_format.format(appointment.data.coin)} เหรียญ</strong></div>
                                    </div>)}
                                    {appointment.data.status == 4 && (<div style={{ marginLeft: 10 }}>
                                        <div><strong>วันที่ร้านค้ายกเลิก:</strong> {dateFormatTime(appointment.data.shop_cancle_datetime)}</div>
                                        <div><strong>เหตุผลที่ยกเลิก :</strong> 
                                        {(appointment.data.shop_cancle_reason == "1") && (" ไปถึงที่พักแต่ไม่เจอผู้ขาย/ไม่มาส่งสินค้าที่ร้านฯ ตามวันเวลาที่นัด")}
                                        {(appointment.data.shop_cancle_reason == "2") && (" มีการเรียกเก็บเงินเพิ่ม")}
                                        {(appointment.data.shop_cancle_reason == "3") && (" อื่นๆ")}
                                        </div>
                                        <div><strong>เหตุผลเพิ่มเติม :</strong> {appointment.data.shop_cancle_message}</div>
                                    </div>)}
                                    {appointment.data.status == 5 && (<div style={{ marginLeft: 10 }}>
                                        <div><strong>วันที่ผู้ประกาศยกเลิก:</strong> {dateFormatTime(appointment.data.user_cancle_datetime)}</div>
                                        <div><strong>เหตุผลที่ยกเลิก :</strong> 
                                        {(appointment.data.user_cancle_reason == "1") && (" มารับไม่ตรงวันและเวลานัด")}
                                        {(appointment.data.user_cancle_reason == "2") && (" มีการเรียกรับเงินเพิ่ม")}
                                        {(appointment.data.user_cancle_reason == "3") && (" ความพร้อมหรือความน่าเชื่อถือของร้านค้า")}
                                        {(appointment.data.user_cancle_reason == "4") && (" อื่นๆ")}
                                        </div>
                                        <div><strong>เหตุผลเพิ่มเติม :</strong> {appointment.data.user_cancle_message}</div>
                                    </div>)}
                                    {appointment.data.status == 6 && (<div style={{ marginLeft: 10 }}>
                                        <div><strong>คะแนนจากผู้ประกาศ :</strong></div>
                                        <div style={{ marginLeft: 15 }}><strong>วันที่ให้คะแนน:</strong> {dateFormatTime(appointment.data.finish_datetime)}</div>
                                        <div style={{ marginLeft: 15 }}><strong>คะแนน:</strong> {appointment.data.score}</div>
                                        <div style={{ marginLeft: 15 }}><strong>คำติชม:</strong> {appointment.data.finish_message}</div>
                                        <div style={{ marginTop: 15 }}><strong>คะแนนจากร้านค้า :</strong></div>
                                        <div style={{ marginLeft: 15 }}><strong>วันที่ให้คะแนน:</strong> {dateFormatTime(appointment.data.shop_finish_datetime)}</div>
                                        <div style={{ marginLeft: 15 }}><strong>คะแนน:</strong> {appointment.data.score_for_user}</div>
                                        <div style={{ marginLeft: 15 }}><strong>คำติชม:</strong> {appointment.data.message_for_user}</div>
                                    </div>)}
                                </div>
                            </div>
                        </>}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Post