import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, and, or, limit, getDoc, doc, updateDoc } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom"
import { getBrand, getModel } from '../helper/BrandHelper'
import { getBroken } from '../helper/BrokenHelper'
import noImage from '../../assets/images/no-image-icon.png'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

import Slider from '@mui/material/Slider'
import './../home/Home2.css'
import './Post.css'
import Pagination from '@mui/material/Pagination'

const Posts = () => {
    const params = useParams()
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [user, loading, error] = useAuthState(auth)

    const [uid, setUid] = useState('')
    const [docId, setDocId] = useState('')
    const [board, setBoard] = useState(4)
    const [keywordBrand, setKeywordBrand] = useState('')
    const [keywordModel, setKeywordModel] = useState('')

    const [posts, setPosts] = useState([])
    const [electricType, setElectricType] = useState([])
    const [searchTypeList, setSearchTypeList] = useState([])
    const [minPrice, setMinPrice] = useState(0)
    const [maxPrice, setMaxPrice] = useState(99999999)
    const [sliderValue, setSliderValue] = useState([0, 99999999])
    const [openDrawer, setOpenDrawer] = useState(false)

    const onMobile = window.innerWidth < 920
    var imagePostHeight = 190
    var thumpnailWidth = 100
    var h1FontSize = 42
    var h2FontSize = 24
    var h3FontSize = 18
    var textFontSize = 16
    var numRowPost = 3
    var fontSizeHeadSide = 30

    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        imagePostHeight = 150
        thumpnailWidth = 80
        h1FontSize = 32
        h2FontSize = 20
        h3FontSize = 18
        textFontSize = 16
        numRowPost = 2
        fontSizeHeadSide = 20
    } else if(window.innerWidth < 576) {
        imagePostHeight = 100
        thumpnailWidth = 60
        h1FontSize = 24
        h2FontSize = 18
        h3FontSize = 14
        textFontSize = 14
        numRowPost = 2
        fontSizeHeadSide = 20
    }

    const [width, setWidth] = useState(100)
    const [height, setHeight] = useState(imagePostHeight)
    const [widthImageShop, setWidthImageShop] = useState(100)
    const [heightImageShop, setHeightImageShop] = useState(100)

    const [postsPage, setPostsPage] = useState([])
    const [page, setPage] = useState(1)
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH")
        const time_string = new Date(datetime).toLocaleTimeString("th-TH")
        const time = time_string.split(':')
        return date_string+" "+time[0]+":"+time[1]
    }

    const clickShopAds = (position, id) => {
        getDoc(doc(db, `ads/active/position_${position}`, id)).then(async(adsDoc) => {
            var click = 1
            if(adsDoc.data().click) {
                click = adsDoc.data().click + 1
            }
            updateDoc(doc(db, `ads/active/position_${position}`, id), {
                click: click
            }).then(() => {
                navigate(`/shop/history/${adsDoc.data().shop_id}`)
            })
        })
    }

    const getMinMaxPrice = () => {
        var minQuery = query(collection(db, 'posts'), where('onboard', '==', board), orderBy('price', 'asc'), limit(1))
        onSnapshot(minQuery, (querySnapshot) => {
            if(querySnapshot.docs.length > 0) {
                if(isNaN(querySnapshot.docs[0].data().price)) setMinPrice(0)
                else setMinPrice(querySnapshot.docs[0].data().price*1)
            }
        })
        var maxQuery = query(collection(db, 'posts'), where('onboard', '==', board), orderBy('price', 'desc'), limit(1))
        onSnapshot(maxQuery, (querySnapshot) => {
            if(querySnapshot.docs.length > 0) {
                if(isNaN(querySnapshot.docs[0].data().price)) setMaxPrice(0)
                else setMaxPrice(querySnapshot.docs[0].data().price*1)
            }
        })
    }

    const [adsPosition8List, setAdsPosition8List] = useState([])

    const fetchAdsPosition8 = () => {
        adsPosition8List.splice(0, adsPosition8List.length)
        setAdsPosition8List([...adsPosition8List])
        onSnapshot(query(collection(db, `ads/active/position_8`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(10)), (result) => {
            result.forEach(async(ads) => {
                getDoc(doc(db, `shops/`, ads.data().shop_id)).then(async(shopDoc) => {
                    var province = 'ไม่ระบุ'
                    var district = 'ไม่ระบุ'
                    var promisesTask = []

                    if(shopDoc.data().province) {
                        var provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', shopDoc.data().province * 1))).then((docs) => {
                            if(docs.docs.length > 0) { province = docs.docs[0].data().province_name }
                        })
                        promisesTask.push(provinceTask)
                    }

                    if(shopDoc.data().district) {
                        var districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', shopDoc.data().district))).then((docs) => {
                            if(docs.docs.length > 0) { district = docs.docs[0].data().district_name }
                        })
                        promisesTask.push(districtTask)
                    }

                    var images = []
                    var imagesTask = await getDocs(query(collection(db, `shops/${ads.data().shop_id}/images`))).then((imageDocs) => {
                        imageDocs.docs.forEach((image) => {
                            images.push({
                                id: image.id,
                                data: image.data()
                            })
                        })
                    })
                    promisesTask.push(imagesTask)

                    getDocs(query(collection(db, `appointments/`), where('shop_id', '==', ads.data().shop_id))).then((appointments) => {
                        Promise.all(promisesTask).then(() => {
                            adsPosition8List.push({
                                id: ads.id,
                                data: ads.data(),
                                shop: shopDoc.data(),
                                province: province,
                                district: district,
                                appointment: appointments.docs.length,
                                images: images
                            })
                            setAdsPosition8List([...adsPosition8List], {
                                id: ads.id,
                                data: ads.data(),
                                shop: shopDoc.data(),
                                province: province,
                                district: district,
                                appointment: appointments.docs.length,
                                images: images
                            })
                        })
                    })
                })
            })
        })
    }

    const handleSearch = () => {
        const constraints = []
        const order = []
        constraints.push(where('onboard', '==', board))
        constraints.push(or(where('status', '==', 1), where('status', '==', 2)))
        if(searchTypeList.length > 0) {
            constraints.push(where('type_id', 'in', searchTypeList))
        }
        if(keywordBrand) {
            constraints.push(and(where('brand', '>=', keywordBrand), where('brand', '<=', keywordBrand +  '\uf8ff')))
            order.push(orderBy('brand', 'asc'))
        }
        if(keywordModel) {
            constraints.push(and(where('model', '>=', keywordModel), where('model', '<=', keywordModel +  '\uf8ff')))
            order.push(orderBy('model', 'asc'))
        }
        if(sliderValue) {
            if(sliderValue[0] == minPrice && sliderValue[1] == maxPrice) {
            } else {
                constraints.push(and(where('price', '>=', sliderValue[0]), where('price', '<=', sliderValue[1])))
                order.push(orderBy('price', 'asc'))
            }
        }
        order.push(orderBy('post_timestamp', 'desc'))
        if(constraints.length >= 2) {
            var postQuery = query(collection(db, 'posts'), and(...constraints), ...order)
        } else {
            var postQuery = query(collection(db, 'posts'), ...constraints, ...order)
        }
        onSnapshot(postQuery, (querySnapshot) => {
            postsPage.splice(0, postsPage.length)
            setPostsPage([...postsPage])
            posts.splice(0, posts.length)
            setPosts([...posts])
            querySnapshot.forEach((doc) => {
                var brand = 'ไม่ระบุ'
                var model = 'ไม่ระบุ'
                var province = 'ไม่ระบุ'
                var district = 'ไม่ระบุ'
                var broken = 'ไม่ระบุ'
                var promisesTask = []
                
                var getฺBrokenTask = getBroken(db, doc.data().broken_id, doc.data().other_broken).then((brokenName) => {
                    broken = brokenName
                })
                promisesTask.push(getฺBrokenTask)

                var getBrandTask = getBrand(db, doc.data().brand_id, doc.data().brand).then((brandName) => {
                    brand = brandName
                })
                promisesTask.push(getBrandTask)

                var getModelTask = getModel(db, doc.data().model_id, doc.data().model).then((modelName) => {
                    model = modelName
                })
                promisesTask.push(getModelTask)

                if(doc.data().province) {
                    var provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', doc.data().province * 1))).then((docs) => {
                        if(docs.docs.length > 0) { province = docs.docs[0].data().province_name }
                    })
                    promisesTask.push(provinceTask)
                }

                if(doc.data().district) {
                    var districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', doc.data().district))).then((docs) => {
                        if(docs.docs.length > 0) { district = docs.docs[0].data().district_name }
                    })
                    promisesTask.push(districtTask)
                }

                Promise.all(promisesTask).then(() => {
                    posts.push({
                        id: doc.id,
                        data: doc.data(),
                        brand: brand,
                        model: model,
                        province: province,
                        district: district,
                        broken: broken,
                    })
                    setPosts([...posts], {
                        id: doc.id,
                        data: doc.data(),
                        brand: brand,
                        model: model,
                        province: province,
                        district: district,
                        broken: broken,
                    })

                    const postsSlide = posts.slice(0, 18)
                    setPostsPage(postsSlide)
                })
            })
        })
    }

    const handleSearchByType = (id) => {
        const others = Array.from(
            document.getElementsByClassName('type-other')
        )
        const allType = Array.from(
            document.getElementsByClassName('type-all')
        )
        if(id == 0) {
            searchTypeList.splice(0, searchTypeList.length)
            setSearchTypeList([...searchTypeList])
            allType.forEach(element => {
                element.checked = true
            })
            others.forEach(element => {
                element.checked = false
                element.removeAttribute('checked')
            })
        } else {
            if(searchTypeList.indexOf(id) < 0) {
                searchTypeList.push(id)
            } else {
                searchTypeList.splice(searchTypeList.indexOf(id), 1)
            }
            setSearchTypeList([...searchTypeList])
            if(searchTypeList.length > 0) {
                allType.forEach(element => {
                    element.checked = false
                    element.removeAttribute('checked')
                })
            } else {
                allType.forEach(element => {
                    element.checked = true
                })
            }
        }
        handleSearch()
    }

    const getElectricType = () => {
        getDocs(query(collection(db, 'electric_type'))).then((docs) => {
            setElectricType(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDocId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    const fetchPosts = async () => {
        try {
            handleSearch()
        } catch (err) {
            alert(err.message)
        }
    }

    const valuetext = (value) => {
        return `${value}°C`
    }

    const handleSliderChange = (event, newValue, activeThumb) => {
        const minDistance = 10
        if (!Array.isArray(newValue)) {
            return
        }
    
        if (newValue[1] - newValue[0] < minDistance) {
            if (activeThumb === 0) {
                const clamped = Math.min(newValue[0], maxPrice - minDistance)
                setSliderValue([clamped, clamped + minDistance])
            } else {
                const clamped = Math.max(newValue[1], minDistance)
                setSliderValue([clamped - minDistance, clamped])
            }
        } else {
            setSliderValue(newValue)
        }
    }

    const handlePage = (event, value) => {
        const postsSlide = posts.slice(((value-1)*18), (((value-1)*18)+18))
        setPostsPage(postsSlide)
        setPage(value)
    }

    useEffect(() => {
        if (loading) return;
        if (user) getDocUser()
        fetchPosts()
        getElectricType()
        getMinMaxPrice()
        fetchAdsPosition8()
    }, [user, loading])

    useEffect(() => {
        handleSearch()
    }, [keywordBrand, keywordModel, sliderValue])

    useEffect(() => {
        setSliderValue([minPrice, maxPrice])
    }, [minPrice, maxPrice])

    const resizeDiv = new ResizeObserver((event) => {
        setWidth(event[0].contentBoxSize[0].inlineSize-1)
        setHeight(imagePostHeight)
    })

    useEffect(() => {
        resizeDiv.observe(document.getElementById("divPost"))
    })
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    return (
        <>
            <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 280 }} role="presentation">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h2FontSize, fontWeight: "600" }}>ค้นหาประกาศ</font></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ช่วงราคา</font></div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: textFontSize, fontWeight: "400" }}>{number_format.format(sliderValue[0])} - {number_format.format(sliderValue[1])} บาท</font></div>
                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}>
                                    <Slider
                                        getAriaLabel={() => 'Minimum distance shift'}
                                        value={sliderValue}
                                        onChange={handleSliderChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetext}
                                        disableSwap
                                        min={minPrice}
                                        max={maxPrice}
                                    />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ประเภท</font></div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingBottom: 10, paddingLeft: 10 }}>
                                <div><input className="form-check-input type-all" type="checkbox" value="0" defaultChecked={true} onClick={ (e) => { handleSearchByType(e.target.value) }} /> ทั้งหมด</div>
                                {electricType?.map((type, i) => (
                                <div style={{ whiteSpace: 'nowrap'}} key={type.id}><input className="form-check-input type-other" type="checkbox" value={type.id} onClick={ (e) => { handleSearchByType(e.target.value) }} /> {type.data.name}</div>
                                ))}
                                <div><input className="form-check-input type-other" type="checkbox" value="999" id="typeOther" onClick={ (e) => { handleSearchByType(e.target.value) }} /> อื่นๆ</div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ยี่ห้อ</font></div>
                            <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="ค้นหายี่ห้อ" 
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { setKeywordBrand(e.target.value) }} 
                                        value={keywordBrand} /></div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>รุ่น</font></div>
                            <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="ค้นหารุ่น" 
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { setKeywordModel(e.target.value)  }} 
                                        value={keywordModel} /></div>
                        </div>
                    </div>
                </Box>
            </Drawer>
            <div className="container-fluid flex-container" style={{ marginTop: 10, paddingLeft: 20 }}>
                <div className="d-none d-md-block d-lg-block" style={{ display: 'flex', flexDirection: 'column', width: 250 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h2FontSize, fontWeight: "600" }}>ค้นหาประกาศ</font></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ช่วงราคา</font></div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: textFontSize, fontWeight: "400" }}>{number_format.format(sliderValue[0])} - {number_format.format(sliderValue[1])} บาท</font></div>
                                <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}>
                                    <Slider
                                        getAriaLabel={() => 'Minimum distance shift'}
                                        value={sliderValue}
                                        onChange={handleSliderChange}
                                        valueLabelDisplay="auto"
                                        getAriaValueText={valuetext}
                                        disableSwap
                                        min={minPrice}
                                        max={maxPrice}
                                    />
                                </div>
                            </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ประเภท</font></div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingBottom: 10, paddingLeft: 10 }}>
                            <div><input className="form-check-input type-all" type="checkbox" value="0" defaultChecked={true} onClick={ (e) => { handleSearchByType(e.target.value) }} /> ทั้งหมด</div>
                            {electricType?.map((type, i) => (
                            <div style={{ whiteSpace: 'nowrap'}} key={type.id}><input className="form-check-input type-other" type="checkbox" value={type.id} onClick={ (e) => { handleSearchByType(e.target.value) }} /> {type.data.name}</div>
                            ))}
                            <div><input className="form-check-input type-other" type="checkbox" value="999" id="typeOther" onClick={ (e) => { handleSearchByType(e.target.value) }} /> อื่นๆ</div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>ยี่ห้อ</font></div>
                        <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="ค้นหายี่ห้อ" 
                                    aria-describedby="button-addon2"
                                    onChange={(e) => { setKeywordBrand(e.target.value) }} 
                                    value={keywordBrand} /></div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}><font style={{ fontSize: h3FontSize, fontWeight: "600" }}>รุ่น</font></div>
                        <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                    type="text" 
                                    className="form-control" 
                                    placeholder="ค้นหารุ่น" 
                                    aria-describedby="button-addon2"
                                    onChange={(e) => { setKeywordModel(e.target.value)  }} 
                                    value={keywordModel} /></div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <font style={{ fontSize: h1FontSize, fontWeight: "600" }}>ประกาศซ่อมเครื่องใช้ไฟฟ้า</font>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginTop: 'auto', marginBottom: 5, alignSelf: 'end', float: 'right' }}>
                            <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                                <button type="button" className="btn btn-light" onClick={toggleDrawer(true)}>ค้นหา</button>
                            </div>
                        </div>
                        <div className='flex-container' style={{ paddingTop: 10, paddingBottom: 5, alignContent: 'center' }}>
                            <div style={{ marginLeft: 15, paddingTop: 5, marginRight: 10, display: 'flex', flex: 1 }}><strong>รายการที่ {(((page-1)*18)+1)} - {(page*18 > posts.length)?posts.length:page*18} จากทั้งหมด {number_format.format(posts.length)} ประกาศ</strong></div>
                            <Pagination style={{ fontFamily: 'Prompt', marginRight: 10 }} count={Math.ceil(posts.length/18)} page={page} onChange={handlePage} color="primary" showFirstButton showLastButton />
                        </div>
                    </div>
                    <div className='flex-container' style={{ flex: 1 }}>
                        <div id="divPost" style={{ display: 'flex', flex: 3, flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', alignContent: 'space-between', backgroundColor: '#FFFFFF', paddingTop: 10 }}>
                            {postsPage?.map((post, i) => (
                            <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', width: (width/numRowPost), padding: 5, marginBottom: 5, cursor: 'pointer' }} key={i} onClick={() => { navigate(`/postfix/${post.id}`) }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#ffffff', borderRadius: 15, border: "1px solid #dddddd" }}>
                                    <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                            <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (width/numRowPost), height: height }} src={post.data.picture} alt="APP3R" />
                                        </div>
                                    </div>
                                    <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                        <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                            <div className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </div>
                                            <font style={{ fontSize: 14, color: '#984740' }}>
                                                <font style={{ fontWeight: '600' }}>ราคาขาย:</font> ฿{number_format.format(post.data.price)}  
                                            </font>
                                            <font style={{ fontSize: 14, color: '#626167' }}>
                                                อาการ: {post.broken}<br/>
                                                รูปแบบการซ่อม: 
                                                {post.data.fix_type_home?" บริการมาซ่อมในที่พักอาศัย":""}
                                                {post.data.fix_type_shop?" บริการไปรับกลับมาซ่อมที่ร้าน":""}
                                                {post.data.fix_type_send?" ยกไปซ่อมที่ร้าน":""}
                                                <br/>
                                                วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}
                                            </font>
                                        </div>
                                    </div>
                                </div>
                            </div>))}
                        </div>
                        <div id="divAds" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#FFFFFF', paddingTop: 10 }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ flexDirection: 'column', backgroundColor: '#FF6F62', alignItems: 'center', alignContent: 'center', padding: 10 }}>
                                    <div style={{ textAlign: 'center' }}><font style={{ fontSize: fontSizeHeadSide, fontWeight: '600', color: '#ffffff' }}>รวมช่าง</font></div>
                                </div>
                                <div id="divImageShop" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {adsPosition8List?.map((shop, i) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} key={i} onClick={() => { clickShopAds(8, shop.id) }}>
                                        <div style={{ flex: 3 }}>
                                            {(shop.images.length > 0) && 
                                            <img style={{ display: 'flex', objectFit: "contain", maxWidth: (widthImageShop/3), height: heightImageShop }} src={shop.images[0].data.url} alt="APP3R" />
                                            }
                                            {(shop.images.length == 0) && 
                                            <img style={{ display: 'flex', objectFit: "contain", maxWidth: (widthImageShop/3), height: heightImageShop }} src={noImage} alt="APP3R" />
                                            }
                                        </div>
                                        <div key={i} className="cut-text text-30-ch" style={{ padding: 5, flexDirection: 'row', fontSize: 14, marginTop: 5, marginLeft: 10, flexWrap: 'wrap', flex: 7 }}>
                                            <font style={{ color: '#3D5598', fontWeight: '600' }}>ร้าน/บริษัท: {(shop.shop.type == 2)?shop.shop.shopname:shop.shop.firstname+" "+shop.shop.lastname} </font><br/>
                                            <font style={{ color: '#3D5598', fontWeight: '600' }}>ที่ตั้ง: {shop.province} {shop.district}</font><br/>
                                            <font style={{ color: '#984740', fontWeight: '600' }}>คะแนน: {number_format.format(shop.shop.score_average)} </font><br/>
                                            <font style={{ color: '#626167' }}>ให้บริการไปแล้ว: {number_format.format(shop.appointment)} </font>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Posts