import React, { useState, useEffect } from 'react';
import { collection, query, onSnapshot, addDoc, orderBy, updateDoc, doc, deleteDoc } from "firebase/firestore"
import { db } from "../../assets/js/firebase";
import { useNavigate } from "react-router-dom";
import { TbCirclePlus } from "react-icons/tb";
import { AiFillInfoCircle } from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri"
import { FiDelete, FiEdit } from "react-icons/fi";
import { GiElectric } from "react-icons/gi"
import { IoCloseCircle, IoCheckmarkCircleSharp } from "react-icons/io5"
import { FaWrench } from "react-icons/fa"

import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';

const BrokenList = () => {
    const navigate = useNavigate();
    const [brokenList, setBrokenList] = useState([])
    const [name, setName] = useState('')
    const [nameErrorDiv, setNameErrorDiv] = useState(false)

    const [editId, setEditId] = useState('')
    const [nameEdit, setNameEdit] = useState('')
    const [openEdit, setOpenEdit] = useState(false)
    const [nameEditErrorDiv, setNameEditErrorDiv] = useState(false)

    const [deleteId, setDeleteId] = useState('')
    const [openAlertDelete, setOpenAlertDelete] = useState(false)

    const isSpecialChar = (text) => {
        return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/.test(text)
    }

    const handleCloseAlertDelete = () => {
        setDeleteId('')
        setOpenAlertDelete(false)
    }

    const handleOpenAlertDelete = (id) => {
        setDeleteId(id)
        setOpenAlertDelete(true)
    }

    const handleDelete = () => {
        deleteDoc(doc(db, `brokens`, deleteId)).then(() => {
            handleCloseAlertDelete()
        })
    }

    const validateFormEdit = () => {
        if(!nameEdit || isSpecialChar(nameEdit)) {
            setNameEditErrorDiv(true)
            return false;
        } else {
            setNameEditErrorDiv(false)
        }
        return true
    }

    const handleEdit = (e) => {
    	e.preventDefault()
        if(validateFormEdit()) {
            updateDoc(doc(db, `brokens`, editId), {
                name: nameEdit
            }).then(() => {
                setName('')
                handleCloseEdit()
            })
        }
    }

    const handleOpenEdit = (id, name) => {
        setEditId(id)
        setNameEdit(name)
        setNameEditErrorDiv(false)
        setOpenEdit(true)
    }

    const handleCloseEdit = () => {
        setEditId('')
        setNameEdit('')
        setNameEditErrorDiv(false)
        setOpenEdit(false)
    }

    const validateForm = () => {
        if(!name || isSpecialChar(name)) {
            setNameErrorDiv(true)
            return false;
        } else {
            setNameErrorDiv(false)
        }
        return true
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()
        if(validateForm()) {
            await addDoc(collection(db, "brokens"), {
                name: name
            }).then(() => {
                setName('')
            })
        }
    }

    useEffect(() => {
        const brokenQuery = query(collection(db, 'brokens'), orderBy('name', 'asc'))
        onSnapshot(brokenQuery, (querySnapshot) => {
            setBrokenList(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 20 }}>
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><FaWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายการอาการซ่อม</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ display: 'flex', flexDirection: 'row', marginRight: 20, paddingRight: 20, borderRight: "1px solid #dddddd"}}>
                    <table className="table" style={{ marginLeft: 10}}>
                        <thead>
                            <tr>
                                <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>อาการซ่อม</th>
                                <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            brokenList?.map((broken, i) => (
                                <tr key={i}>
                                    <th scope="row">{broken.data.name}</th>
                                    <td style={{ display: 'flex', flexDirection: 'row'}}>
                                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenEdit(broken.id, broken.data.name) }}><FiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข</button>
                                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenAlertDelete(broken.id) }}><FiDelete style={{ fontSize: 20, marginRight: 5 }} />ลบ</button>
                                    </td>
                                </tr>
                            ))
                        }
                        </tbody>
                    </table>
                </div>
                <div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                        <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                            <h6><FaWrench style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>เพิ่มรายการอาการซ่อม</b></h6>
                        </div>

                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1}}>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15}}>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 60}}><strong>ชื่อ <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='name' onChange={(e) => setName(e.target.value)} 
                                        value={name} placeholder='ชื่อ'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                    {nameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกอาการซ่อม</strong>
                                        </div>
                                    }
                                </div>
                            </div>  
                        </div>
                        <div style={{ flex: 1, display: 'flex', flexDirection: 'row', marginBottom: 10, marginTop:7}}>
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                    <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                </button>
                            </div>
                        </div>
                    </div>
                    </div>
                </div>
            </div>
            <Dialog open={openEdit} onClose={handleCloseEdit}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>แก้ไขอาการซ่อม</strong></div></DialogTitle>
                <DialogContent>
                    <div><h6>ชื่ออาการซ่อม</h6></div>
                    <TextField
                        autoFocus
                        margin="dense"
                        type="text"
                        fullWidth
                        variant="standard"
                        value={nameEdit}
                        onChange={(e) => setNameEdit(e.target.value)}
                        style={{ paddingLeft: 5, marginTop: 15 }}
                    />
                    {nameEditErrorDiv &&
                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                            <strong>** กรุณากรอกขื่ออาการซ่อม</strong>
                        </div>
                    }
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseEdit} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleEdit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openAlertDelete} onClose={handleCloseAlertDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบอาการซ่อมนี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDelete} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { handleDelete() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default BrokenList;