import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, addDoc, updateDoc, doc, getDoc, orderBy } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { useNavigate } from "react-router-dom"

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'
import { FaMapMarkerAlt } from "react-icons/fa"
import addImage from "../../assets/images/add-image-icon.png"
import { defauleImageSize } from "../../../assets/constants/images"
import { defaultLatLng, googleMapsApiKey, locationOptions } from "../../../assets/constants/locations"
import { 
    getProvinceList, 
    getDistrictListByProvince, 
    getSubDistrictListByDistrict, 
    getPostcodeBySubDistrict 
} from "../../../assets/js/AreaHelper"
import { Loading } from "../layouts/Loading"

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from "react-icons/io5"

const AddCleanAir = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const today = dayjs()
    const [user, loading] = useAuthState(auth)

    const [uid, setUid] = useState('')
    const [memberId, setMenberId] = useState('')

    const [address, setAddress] = useState('')
    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [pickDate, setPickDate] = useState(today)
    const [hour, setHour] = useState('09')
    const [minute, setMinute] = useState('00')

    const [offerUseCoin, setOfferUseCoin] = useState(0)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [useCoinFrom, setUseCoinFrom] = useState(1)
    const [useCoinErrorDiv, setUseCoinErrorDiv] = useState(false)

    const [brandList, setBrandList] = useState([])
    const [brandId1, setBrandId1] = useState('')
    const [brandId2, setBrandId2] = useState('')
    const [brandId3, setBrandId3] = useState('')
    const [brand1, setBrand1] = useState('')
    const [brand2, setBrand2] = useState('')
    const [brand3, setBrand3] = useState('')

    const [modelList1, setModelList1] = useState([])
    const [modelList2, setModelList2] = useState([])
    const [modelList3, setModelList3] = useState([])
    const [modelId1, setModelId1] = useState('')
    const [modelId2, setModelId2] = useState('')
    const [modelId3, setModelId3] = useState('')
    const [model1, setModel1] = useState('')
    const [model2, setModel2] = useState('')
    const [model3, setModel3] = useState('')

    const [haveAir1, setHaveAir1] = useState(true)
    const [air1BTU, setAir1BTU] = useState('')
    const [air1Amount, setAir1Amount] = useState(0)
    const [air1IsOver3M, setAir1IsOver3M] = useState(false)
    const [air1Over3MAmount, setAir1Over3MAmount] = useState(0)
    const [air1IsBelow3M, setAir1IsBelow3M] = useState(false)
    const [air1Below3MAmount, setAir1Below3MAmount] = useState(0)
    const [air1IsProblem1, setAir1IsPloblem1] = useState(false)
    const [air1IsProblem2, setAir1IsPloblem2] = useState(false)
    const [air1IsProblem3, setAir1IsPloblem3] = useState(false)
    const [air1IsProblem4, setAir1IsPloblem4] = useState(false)
    const [air1IsProblem5, setAir1IsPloblem5] = useState(false)
    const [srcAir1Picture1, setSrcAir1Picture1] = useState(addImage)
    const [srcAir1Picture2, setSrcAir1Picture2] = useState(addImage)
    const [srcAir1Picture3, setSrcAir1Picture3] = useState(addImage)
    const [srcAir1Picture4, setSrcAir1Picture4] = useState(addImage)
    const [srcAir1Picture5, setSrcAir1Picture5] = useState(addImage)
    const [air1Picture1, setAir1Picture1] = useState('')
    const [air1Picture2, setAir1Picture2] = useState('')
    const [air1Picture3, setAir1Picture3] = useState('')
    const [air1Picture4, setAir1Picture4] = useState('')
    const [air1Picture5, setAir1Picture5] = useState('')
    const [pictureAir1ErrorDiv, setPictureAir1ErrorDiv] = useState(false)

    const [haveAir2, setHaveAir2] = useState(false)
    const [air2BTU, setAir2BTU] = useState('')
    const [air2Amount, setAir2Amount] = useState(0)
    const [air2IsOver3M, setAir2IsOver3M] = useState(false)
    const [air2Over3MAmount, setAir2Over3MAmount] = useState(0)
    const [air2IsBelow3M, setAir2IsBelow3M] = useState(false)
    const [air2Below3MAmount, setAir2Below3MAmount] = useState(0)
    const [air2IsProblem1, setAir2IsPloblem1] = useState(false)
    const [air2IsProblem2, setAir2IsPloblem2] = useState(false)
    const [air2IsProblem3, setAir2IsPloblem3] = useState(false)
    const [air2IsProblem4, setAir2IsPloblem4] = useState(false)
    const [air2IsProblem5, setAir2IsPloblem5] = useState(false)
    const [srcAir2Picture1, setSrcAir2Picture1] = useState(addImage)
    const [srcAir2Picture2, setSrcAir2Picture2] = useState(addImage)
    const [srcAir2Picture3, setSrcAir2Picture3] = useState(addImage)
    const [srcAir2Picture4, setSrcAir2Picture4] = useState(addImage)
    const [srcAir2Picture5, setSrcAir2Picture5] = useState(addImage)
    const [air2Picture1, setAir2Picture1] = useState('')
    const [air2Picture2, setAir2Picture2] = useState('')
    const [air2Picture3, setAir2Picture3] = useState('')
    const [air2Picture4, setAir2Picture4] = useState('')
    const [air2Picture5, setAir2Picture5] = useState('')
    const [pictureAir2ErrorDiv, setPictureAir2ErrorDiv] = useState(false)

    const [haveAir3, setHaveAir3] = useState(false)
    const [air3BTU, setAir3BTU] = useState('')
    const [air3Amount, setAir3Amount] = useState(0)
    const [air3IsOver3M, setAir3IsOver3M] = useState(false)
    const [air3Over3MAmount, setAir3Over3MAmount] = useState(0)
    const [air3IsBelow3M, setAir3IsBelow3M] = useState(false)
    const [air3Below3MAmount, setAir3Below3MAmount] = useState(0)
    const [air3IsProblem1, setAir3IsPloblem1] = useState(false)
    const [air3IsProblem2, setAir3IsPloblem2] = useState(false)
    const [air3IsProblem3, setAir3IsPloblem3] = useState(false)
    const [air3IsProblem4, setAir3IsPloblem4] = useState(false)
    const [air3IsProblem5, setAir3IsPloblem5] = useState(false)
    const [srcAir3Picture1, setSrcAir3Picture1] = useState(addImage)
    const [srcAir3Picture2, setSrcAir3Picture2] = useState(addImage)
    const [srcAir3Picture3, setSrcAir3Picture3] = useState(addImage)
    const [srcAir3Picture4, setSrcAir3Picture4] = useState(addImage)
    const [srcAir3Picture5, setSrcAir3Picture5] = useState(addImage)
    const [air3Picture1, setAir3Picture1] = useState('')
    const [air3Picture2, setAir3Picture2] = useState('')
    const [air3Picture3, setAir3Picture3] = useState('')
    const [air3Picture4, setAir3Picture4] = useState('')
    const [air3Picture5, setAir3Picture5] = useState('')
    const [pictureAir3ErrorDiv, setPictureAir3ErrorDiv] = useState(false)

    const [brandIdErrorDiv1, setBrandIdErrorDiv1] = useState(false)
    const [brandIdErrorDiv2, setBrandIdErrorDiv2] = useState(false)
    const [brandIdErrorDiv3, setBrandIdErrorDiv3] = useState(false)
    const [brandErrorDiv1, setBrandErrorDiv1] = useState(false)
    const [brandErrorDiv2, setBrandErrorDiv2] = useState(false)
    const [brandErrorDiv3, setBrandErrorDiv3] = useState(false)
    const [modelIdErrorDiv1, setModelIdErrorDiv1] = useState(false)
    const [modelIdErrorDiv2, setModelIdErrorDiv2] = useState(false)
    const [modelIdErrorDiv3, setModelIdErrorDiv3] = useState(false)
    const [modelErrorDiv1, setModelErrorDiv1] = useState(false)
    const [modelErrorDiv2, setModelErrorDiv2] = useState(false)
    const [modelErrorDiv3, setModelErrorDiv3] = useState(false)

    const [currentLat, setCurrentLat] = useState(defaultLatLng.lat)
    const [currentLng, setCurrentLng] = useState(defaultLatLng.lng)
    const [regisLat, setRegisLat] = useState(defaultLatLng.lat)
    const [regisLng, setRegisLng] = useState(defaultLatLng.lng)

    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [provinceList, setProvinceList] = useState([])
    const [provinceId, setProvinceId] = useState(0)
    const [districtList, setDistrictList] = useState([])
    const [districtId, setDistrictId] = useState(0)
    const [subdistrictList, setSubDistrictList] = useState([])
    const [subdistrictId, setSubDistrictId] = useState(0)
    const [postcode, setPostcode] = useState('')

    const [addressNoRegis, setAddressNoRegis] = useState('')
    const [villageRegis, setVillageRegis] = useState('')
    const [buildingRegis, setBuildingRegis] = useState('')
    const [floorRegis, setFloorRegis] = useState('')
    const [roomRegis, setRoomRegis] = useState('')
    const [subroadRegis, setSubroadRegis] = useState('')
    const [roadRegis, setRoadRegis] = useState('')
    const [provinceIdRegis, setProvinceIdRegis] = useState(0)
    const [districtIdRegis, setDistrictIdRegis] = useState(0)
    const [subdistrictIdRegis, setSubDistrictIdRegis] = useState(0)
    const [postcodeRegis, setPostcodeRegis] = useState('')

    const [provinceIdErrorDiv, setProvinceIdErrorDiv] = useState(false)
    const [districtIdErrorDiv, setDistrictIdErrorDiv] = useState(false)
    const [subdistrictIdErrorDiv, setSubDistrictErrorDiv] = useState(false)
    const [imageSize1Error, setImageSize1Error] = useState(false)
    const [imageSize2Error, setImageSize2Error] = useState(false)
    const [imageSize3Error, setImageSize3Error] = useState(false)

    const [showProgressBar, setShowProgressBar] = useState(false)

    const [air1BTUErrorDiv, setAir1BTUErrorDiv] = useState(false)
    const [air1AmountErrorDiv, setAir1AmountErrorDiv] = useState(false)

    const [air2BTUErrorDiv, setAir2BTUErrorDiv] = useState(false)
    const [air2AmountErrorDiv, setAir2AmountErrorDiv] = useState(false)

    const [air3BTUErrorDiv, setAir3BTUErrorDiv] = useState(false)
    const [air3AmountErrorDiv, setAir3AmountErrorDiv] = useState(false)

    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey })
    const [center, setCenter] = useState({ lat: defaultLatLng.lat, lng: defaultLatLng.lng })
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    const [openAcceptPolicy1, setOpenAcceptPolicy1] = useState(false)
    const [openAcceptPolicy2, setOpenAcceptPolicy2] = useState(false)
    const [openAcceptPolicy3, setOpenAcceptPolicy3] = useState(false)
    const [openAcceptPolicy4, setOpenAcceptPolicy4] = useState(false)
    const [openAcceptPolicy5, setOpenAcceptPolicy5] = useState(false)
    const [openAcceptPolicy6, setOpenAcceptPolicy6] = useState(false)
    const [openAcceptPolicy7, setOpenAcceptPolicy7] = useState(false)
    const [openAcceptPolicy8, setOpenAcceptPolicy8] = useState(false)
    const [checkPolicy1, setCheckPolicy1] = useState(false)
    const [checkPolicy2, setCheckPolicy2] = useState(false)
    const [checkPolicy3, setCheckPolicy3] = useState(false)
    const [checkPolicy4, setCheckPolicy4] = useState(false)
    const [checkPolicy5, setCheckPolicy5] = useState(false)
    const [checkPolicy6, setCheckPolicy6] = useState(false)
    const [checkPolicy7, setCheckPolicy7] = useState(false)
    const [checkPolicy8, setCheckPolicy8] = useState(false)
    const [policy1ErrorDiv, setPolicy1ErrorDiv] = useState(false)
    const [policy2ErrorDiv, setPolicy2ErrorDiv] = useState(false)
    const [policy3ErrorDiv, setPolicy3ErrorDiv] = useState(false)
    const [policy4ErrorDiv, setPolicy4ErrorDiv] = useState(false)
    const [policy5ErrorDiv, setPolicy5ErrorDiv] = useState(false)
    const [policy6ErrorDiv, setPolicy6ErrorDiv] = useState(false)
    const [policy7ErrorDiv, setPolicy7ErrorDiv] = useState(false)
    const [policy8ErrorDiv, setPolicy8ErrorDiv] = useState(false)

    const startAcceptPolicy = () => {
        if(validateForm()) {
            setOpenAcceptPolicy1(true)
        }
    }

    const closeAllPolicyError = () => {
        setPolicy1ErrorDiv(false)
        setPolicy2ErrorDiv(false)
        setPolicy3ErrorDiv(false)
        setPolicy4ErrorDiv(false)
        setPolicy5ErrorDiv(false)
        setPolicy6ErrorDiv(false)
        setPolicy7ErrorDiv(false)
        setPolicy8ErrorDiv(false)
        setCheckPolicy1(false)
        setCheckPolicy2(false)
        setCheckPolicy3(false)
        setCheckPolicy4(false)
        setCheckPolicy5(false)
        setCheckPolicy6(false)
        setCheckPolicy7(false)
        setCheckPolicy8(false)
    }

    const handleCloseAcceptPolicy1 = () => {
        setOpenAcceptPolicy1(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy2 = () => {
        setOpenAcceptPolicy2(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy3 = () => {
        setOpenAcceptPolicy3(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy4 = () => {
        setOpenAcceptPolicy4(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy5 = () => {
        setOpenAcceptPolicy5(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy6 = () => {
        setOpenAcceptPolicy6(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy7 = () => {
        setOpenAcceptPolicy7(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy8 = () => {
        setOpenAcceptPolicy8(false)
        closeAllPolicyError()
    }

    const handleCheckPolicy1 = () => {
        setCheckPolicy1(!checkPolicy1)
    }

    const handleCheckPolicy2 = () => {
        setCheckPolicy2(!checkPolicy2)
    }

    const handleCheckPolicy3 = () => {
        setCheckPolicy3(!checkPolicy3)
    }

    const handleCheckPolicy4 = () => {
        setCheckPolicy4(!checkPolicy4)
    }

    const handleCheckPolicy5 = () => {
        setCheckPolicy5(!checkPolicy5)
    }

    const handleCheckPolicy6 = () => {
        setCheckPolicy6(!checkPolicy6)
    }

    const handleCheckPolicy7 = () => {
        setCheckPolicy7(!checkPolicy7)
    }

    const handleCheckPolicy8 = () => {
        setCheckPolicy8(!checkPolicy8)
    }

    const handleAcceptPolicy1 = () => {
        if(!checkPolicy1) {
            setPolicy1ErrorDiv(true)
        } else {
            setPolicy1ErrorDiv(false)
            handleCloseAcceptPolicy1(false)
            setOpenAcceptPolicy2(true)
        }
    }

    const handleAcceptPolicy2 = () => {
        if(!checkPolicy2) {
            setPolicy2ErrorDiv(true)
        } else {
            setPolicy2ErrorDiv(false)
            handleCloseAcceptPolicy2(false)
            setOpenAcceptPolicy3(true)
        }
    }

    const handleAcceptPolicy3 = () => {
        if(!checkPolicy3) {
            setPolicy3ErrorDiv(true)
        } else {
            setPolicy3ErrorDiv(false)
            handleCloseAcceptPolicy3(false)
            setOpenAcceptPolicy4(true)
        }
    }

    const handleAcceptPolicy4 = () => {
        if(!checkPolicy4) {
            setPolicy4ErrorDiv(true)
        } else {
            setPolicy4ErrorDiv(false)
            handleCloseAcceptPolicy4(false)
            setOpenAcceptPolicy5(true)
        }
    }

    const handleAcceptPolicy5 = () => {
        if(!checkPolicy5) {
            setPolicy5ErrorDiv(true)
        } else {
            setPolicy5ErrorDiv(false)
            handleCloseAcceptPolicy5(false)
            setOpenAcceptPolicy6(true)
        }
    }

    const handleAcceptPolicy6 = () => {
        if(!checkPolicy6) {
            setPolicy6ErrorDiv(true)
        } else {
            setPolicy6ErrorDiv(false)
            handleCloseAcceptPolicy6(false)
            setOpenAcceptPolicy7(true)
        }
    }

    const handleAcceptPolicy7 = () => {
        if(!checkPolicy7) {
            setPolicy7ErrorDiv(true)
        } else {
            setPolicy7ErrorDiv(false)
            handleCloseAcceptPolicy7(false)
            setOpenAcceptPolicy8(true)
        }
    }

    const handleAcceptPolicy8 = () => {
        if(!checkPolicy8) {
            setPolicy8ErrorDiv(true)
        } else {
            setPolicy8ErrorDiv(false)
            handleCloseAcceptPolicy8(false)
            handleSubmit()
        }
    }


    const handelGoToRegisPosition = () => {
        setLat(regisLat)
        setLng(regisLng)
        setCenter({lat: regisLat, lng: regisLng})
    }

    const handelGoToCurrentPosition = () => {
        setLat(currentLat)
        setLng(currentLng)
        setCenter({lat: currentLat, lng: currentLng})
    }

    const locationSuccess = (pos) => {
        var crd = pos.coords
        setCurrentLat(crd.latitude)
        setCurrentLng(crd.longitude)
    }

    const locationErrors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions)
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions)
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
            })
        } else {
          //console.log("Geolocation is not supported by this browser.")
        }
    }, [])

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    const handleHaveAir1Change = () => {
        setHaveAir1(!haveAir1)
    }

    const handleAir1IsOver3M = () => {
        setAir1IsOver3M(!air1IsOver3M)
    }

    const handleAir1IsBelow3M = () => {
        setAir1IsBelow3M(!air1IsBelow3M)
    }

    const handleAir1Image1 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize1Error(true)
        } else {
            setAir1Picture1(file)
            setSrcAir1Picture1(URL.createObjectURL(file))
            setImageSize1Error(false)
        }
    }

    const handleAir1Image2 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize1Error(true)
        } else {
            setAir1Picture2(file)
            setSrcAir1Picture2(URL.createObjectURL(file))
            setImageSize1Error(false)
        }
    }

    const handleAir1Image3 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize1Error(true)
        } else {
            setAir1Picture3(file)
            setSrcAir1Picture3(URL.createObjectURL(file))
            setImageSize1Error(false)
        }
    }

    const handleAir1Image4 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize1Error(true)
        } else {
            setAir1Picture4(file)
            setSrcAir1Picture4(URL.createObjectURL(file))
            setImageSize1Error(false)
        }
    }

    const handleAir1Image5 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize1Error(true)
        } else {
            setAir1Picture5(file)
            setSrcAir1Picture5(URL.createObjectURL(file))
            setImageSize1Error(false)
        }
    }

    const handleAir1IsProblem1 = () => {
        setAir1IsPloblem1(!air1IsProblem1)
    }

    const handleAir1IsProblem2 = () => {
        setAir1IsPloblem2(!air1IsProblem2)
    }

    const handleAir1IsProblem3 = () => {
        setAir1IsPloblem3(!air1IsProblem3)
    }

    const handleAir1IsProblem4 = () => {
        setAir1IsPloblem4(!air1IsProblem4)
    }

    const handleAir1IsProblem5 = () => {
        setAir1IsPloblem5(!air1IsProblem5)
    }


    const handleHaveAir2Change = () => {
        setHaveAir2(!haveAir2)
    }

    const handleAir2IsOver3M = () => {
        setAir2IsOver3M(!air2IsOver3M)
    }

    const handleAir2IsBelow3M = () => {
        setAir2IsBelow3M(!air2IsBelow3M)
    }

    const handleAir2Image1 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize2Error(true)
        } else {
            setAir2Picture1(file)
            setSrcAir2Picture1(URL.createObjectURL(file))
            setImageSize2Error(false)
        }
    }

    const handleAir2Image2 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize2Error(true)
        } else {
            setAir2Picture2(file)
            setSrcAir2Picture2(URL.createObjectURL(file))
            setImageSize2Error(false)
        }
    }

    const handleAir2Image3 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize2Error(true)
        } else {
            setAir2Picture3(file)
            setSrcAir2Picture3(URL.createObjectURL(file))
            setImageSize2Error(false)
        }
    }

    const handleAir2Image4 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize2Error(true)
        } else {
            setAir2Picture4(file)
            setSrcAir2Picture4(URL.createObjectURL(file))
            setImageSize2Error(false)
        }
    }

    const handleAir2Image5 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize2Error(true)
        } else {
            setAir2Picture5(file)
            setSrcAir2Picture5(URL.createObjectURL(file))
            setImageSize2Error(false)
        }
    }

    const handleAir2IsProblem1 = () => {
        setAir2IsPloblem1(!air2IsProblem1)
    }

    const handleAir2IsProblem2 = () => {
        setAir2IsPloblem2(!air2IsProblem2)
    }

    const handleAir2IsProblem3 = () => {
        setAir2IsPloblem3(!air2IsProblem3)
    }

    const handleAir2IsProblem4 = () => {
        setAir2IsPloblem4(!air2IsProblem4)
    }

    const handleAir2IsProblem5 = () => {
        setAir2IsPloblem5(!air2IsProblem5)
    }

    const handleHaveAir3Change = () => {
        setHaveAir3(!haveAir3)
    }

    const handleAir3IsOver3M = () => {
        setAir3IsOver3M(!air3IsOver3M)
    }

    const handleAir3IsBelow3M = () => {
        setAir3IsBelow3M(!air3IsBelow3M)
    }

    const handleAir3Image1 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize3Error(true)
        } else {
            setAir3Picture1(file)
            setSrcAir3Picture1(URL.createObjectURL(file))
            setImageSize3Error(false)
        }
    }

    const handleAir3Image2 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize3Error(true)
        } else {
            setAir3Picture2(file)
            setSrcAir3Picture2(URL.createObjectURL(file))
            setImageSize3Error(false)
        }
    }

    const handleAir3Image3 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize3Error(true)
        } else {
            setAir3Picture3(file)
            setSrcAir3Picture3(URL.createObjectURL(file))
            setImageSize3Error(false)
        }
    }

    const handleAir3Image4 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize3Error(true)
        } else {
            setAir3Picture4(file)
            setSrcAir3Picture4(URL.createObjectURL(file))
            setImageSize3Error(false)
        }
    }

    const handleAir3Image5 = (file) => {
        if(file.size > defauleImageSize) {
            setImageSize3Error(true)
        } else {
            setAir3Picture5(file)
            setSrcAir3Picture5(URL.createObjectURL(file))
            setImageSize3Error(false)
        }
    }

    const handleAir3IsProblem1 = () => {
        setAir3IsPloblem1(!air3IsProblem1)
    }

    const handleAir3IsProblem2 = () => {
        setAir3IsPloblem2(!air3IsProblem2)
    }

    const handleAir3IsProblem3 = () => {
        setAir3IsPloblem3(!air3IsProblem3)
    }

    const handleAir3IsProblem4 = () => {
        setAir3IsPloblem4(!air3IsProblem4)
    }

    const handleAir3IsProblem5 = () => {
        setAir3IsPloblem5(!air3IsProblem5)
    }

    const validateForm = () => {
        if(useCoinFrom == 1) {
            if(offerUseCoin > offerFreeCoin) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if (useCoinFrom == 2) {
            if(offerUseCoin > offerExchangeCoin) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        }

        if(haveAir1) {
            if(!brandId1 || brandId1 == '0') {
                setBrandIdErrorDiv1(true)
                return false
            } else {
                if(brandId1 == '999') {
                    if(!brand1) {
                        setBrandErrorDiv1(true)
                        return false
                    } else {
                        setBrandErrorDiv1(false)
                        setBrandIdErrorDiv1(false)
                    }
                } else {
                    setBrandErrorDiv1(false)
                    setBrandIdErrorDiv1(false)
                }
            }
    
            if(!modelId1) {
                setModelIdErrorDiv1(true)
                return false
            } else {
                if(modelId1 == '999' || modelId1 == '0') {
                    if(!model1) {
                        setModelErrorDiv1(true)
                        return false
                    } else {
                        setModelErrorDiv1(false)
                        setModelIdErrorDiv1(false)
                    }
                } else {
                    setModelErrorDiv1(false)
                    setModelIdErrorDiv1(false)
                }
            }

            if(!air1BTU || !isOnlyNumeric(air1BTU)) {
                setAir1BTUErrorDiv(true)
                return false
            } else {
                setAir1BTUErrorDiv(false)
            }

            if(!air1Amount || !isOnlyNumeric(air1Amount)) {
                setAir1AmountErrorDiv(true)
                return false
            } else {
                setAir1AmountErrorDiv(false)
            }

            if(!imageSize1Error) {
                if(!air1Picture1 && !air1Picture2 && !air1Picture3 && !air1Picture4 && !air1Picture5) {
                    setPictureAir1ErrorDiv(true)
                    return false
                } else {
                    setPictureAir1ErrorDiv(false)
                }
            } else {
                return false
            }
        }

        if(haveAir2) {
            if(!brandId2) {
                setBrandIdErrorDiv2(true)
                return false
            } else {
                if(brandId2 == '999' || brandId2 == '0') {
                    if(!brand2) {
                        setBrandErrorDiv2(true)
                        return false
                    } else {
                        setBrandErrorDiv2(false)
                        setBrandIdErrorDiv2(false)
                    }
                } else {
                    setBrandErrorDiv2(false)
                    setBrandIdErrorDiv2(false)
                }
            }
    
            if(!modelId2) {
                setModelIdErrorDiv2(true)
                return false
            } else {
                if(modelId2 == '999' || modelId2 == '0') {
                    if(!model2) {
                        setModelErrorDiv2(true)
                        return false
                    } else {
                        setModelErrorDiv2(false)
                        setModelIdErrorDiv2(false)
                    }
                } else {
                    setModelErrorDiv2(false)
                    setModelIdErrorDiv2(false)
                }
            }

            if(!air2BTU || !isOnlyNumeric(air2BTU)) {
                setAir2BTUErrorDiv(true)
                return false
            } else {
                setAir2BTUErrorDiv(false)
            }

            if(!air2Amount || !isOnlyNumeric(air2Amount)) {
                setAir2AmountErrorDiv(true)
                return false
            } else {
                setAir2AmountErrorDiv(false)
            }

            if(!imageSize2Error) {
                if(!air2Picture1 && !air2Picture2 && !air2Picture3 && !air2Picture4 && !air2Picture5) {
                    setPictureAir2ErrorDiv(true)
                    return false
                } else {
                    setPictureAir2ErrorDiv(false)
                }
            } else {
                return false
            }
        }

        if(haveAir3) {
            if(!brandId3) {
                setBrandIdErrorDiv3(true)
                return false
            } else {
                if(brandId3 == '999' || brandId3 == '0') {
                    if(!brand3) {
                        setBrandErrorDiv3(true)
                        return false
                    } else {
                        setBrandErrorDiv3(false)
                    }
                } else {
                    setBrandErrorDiv3(false)
                    setBrandIdErrorDiv3(false)
                }
            }
    
            if(!modelId3) {
                setModelIdErrorDiv3(true)
                return false
            } else {
                if(modelId3 == '999' || modelId3 == '0') {
                    if(!model3) {
                        setModelErrorDiv3(true)
                        return false
                    } else {
                        setModelErrorDiv3(false)
                    }
                } else {
                    setModelErrorDiv3(false)
                    setModelIdErrorDiv3(false)
                }
            }

            if(!air3BTU || !isOnlyNumeric(air3BTU)) {
                setAir3BTUErrorDiv(true)
                return false
            } else {
                setAir3BTUErrorDiv(false)
            }

            if(!air3Amount || !isOnlyNumeric(air3Amount)) {
                setAir3AmountErrorDiv(true)
                return false
            } else {
                setAir3AmountErrorDiv(false)
            }

            if(!imageSize3Error) {
                if(!air3Picture1 && !air3Picture2 && !air3Picture3 && !air3Picture4 && !air3Picture5) {
                    setPictureAir3ErrorDiv(true)
                    return false
                } else {
                    setPictureAir3ErrorDiv(false)
                }
            } else {
                return false
            }
        }

        if(provinceId == 0) {
            setProvinceIdErrorDiv(true)
            return false
        } else {
            setProvinceIdErrorDiv(false)
        }

        if(districtId == 0) {
            setDistrictIdErrorDiv(true)
            return false
        } else {
            setDistrictIdErrorDiv(false)
        }

        if(subdistrictId == 0) {
            setSubDistrictErrorDiv(true)
            return false
        } else {
            setSubDistrictErrorDiv(false)
        }

        return true
    }

    const handleSubmit = (e) => {
    	//e.preventDefault()
        if(validateForm()) {
            const new_date = new Date()
            setShowProgressBar(true)
            addDoc(collection(db, 'cleans'), {
                onboard: 1,
                poster_id: memberId,
                uid: uid,
                pick_date: pickDate.toDate(),
                hour: hour,
                minute: minute,
                
                have_air1: haveAir1,
                air1_brand_id: brandId1,
                air1_brand: brand1,
                air1_model_id: modelId1,
                air1_model: model1,
                air1_BTU: air1BTU,
                air1_amount: air1Amount*1,
                air1_is_over_3m: air1IsOver3M,
                air1_over_3m_amount: air1Over3MAmount*1,
                air1_is_below_3m: air1IsBelow3M,
                air1_below_3m_amount: air1Below3MAmount*1,
                air1_is_problem1: air1IsProblem1,
                air1_is_problem2: air1IsProblem2,
                air1_is_problem3: air1IsProblem3,
                air1_is_problem4: air1IsProblem4,
                air1_is_problem5: air1IsProblem5,
                
                have_air2: haveAir2,
                air2_brand_id: brandId2,
                air2_brand: brand2,
                air2_model_id: modelId2,
                air2_model: model2,
                air2_BTU: air2BTU,
                air2_amount: air2Amount*1,
                air2_is_over_3m: air2IsOver3M,
                air2_over_3m_amount: air2Over3MAmount*1,
                air2_is_below_3m: air2IsBelow3M,
                air2_below_3m_amount: air2Below3MAmount*1,
                air2_is_problem1: air2IsProblem1,
                air2_is_problem2: air2IsProblem2,
                air2_is_problem3: air2IsProblem3,
                air2_is_problem4: air2IsProblem4,
                air2_is_problem5: air2IsProblem5,
                
                have_air3: haveAir3,
                air3_brand_id: brandId3,
                air3_brand: brand3,
                air3_model_id: modelId3,
                air3_model: model3,
                air3_BTU: air3BTU,
                air3_amount: air3Amount*1,
                air3_is_over_3m: air3IsOver3M,
                air3_over_3m_amount: air3Over3MAmount*1,
                air3_is_below_3m: air3IsBelow3M,
                air3_below_3m_amount: air3Below3MAmount*1,
                air3_is_problem1: air3IsProblem1,
                air3_is_problem2: air3IsProblem2,
                air3_is_problem3: air3IsProblem3,
                air3_is_problem4: air3IsProblem4,
                air3_is_problem5: air3IsProblem5,

                lat: lat,
                lng: lng,
                address: address,
                address_no: addressNo,
                road: road,
                subroad: subroad,
                village: village,
                building: building,
                floor: floor,
                room: room,
                sub_district: subdistrictId,
                district: districtId,
                province: provinceId,
                postcode: postcode,
                coin_used: offerUseCoin*1,
                coin_type: useCoinFrom,
                post_timestamp: new_date.getTime(),
                status: 1,
            }).then(function(docRef) {
                const postId = docRef.id
                const log_date = new Date()
                var fromCoinOffer = ""
                if(useCoinFrom == 1) {
                    fromCoinOffer = "freecoin"
                    updateDoc(doc(db, `/users`, memberId), {
                        freecoin: (offerFreeCoin*1)-(offerUseCoin*1)
                    })

                    getDoc(doc(db, 'free_coins', 'sum_data')).then((sumDoc) => {
                        updateDoc(doc(db, 'free_coins', 'sum_data'), {
                            used: (sumDoc.data().used*1) + (offerUseCoin*1)
                        }).then(() => {
                            addDoc(collection(db, `/coins/used_log/transaction`), {
                                date: log_date,
                                coin: offerUseCoin,
                                coin_type: useCoinFrom,
                                event: 'ลงประกาศแจ้งความต้องการล้างแอร์',
                                board: 'air',
                                post_id: postId,
                                user_id: memberId
                            })

                            addDoc(collection(db, `/free_coins/logs/transaction`), {
                                date: log_date,
                                coin: offerUseCoin,
                                coin_type: useCoinFrom,
                                event: 'ลงประกาศแจ้งความต้องการล้างแอร์',
                                board: 'air',
                                post_id: postId,
                                user_id: memberId,
                                type: 'use'
                            })
                        })
                    })
                } else {
                    fromCoinOffer = "exchange"
                    updateDoc(doc(db, `/users`, memberId), {
                        exchange_coin: (offerExchangeCoin*1)-(offerUseCoin*1)
                    })
                    
                    getDoc(doc(db, 'exchange_coins', 'sum_data')).then((sumDoc) => {
                        updateDoc(doc(db, 'exchange_coins', 'sum_data'), {
                            used: (sumDoc.data().used*1) + (offerUseCoin*1)
                        }).then(() => {
                            addDoc(collection(db, `/coins/used_log/transaction`), {
                                date: log_date,
                                coin: offerUseCoin,
                                coin_type: useCoinFrom,
                                event: 'ลงประกาศแจ้งความต้องการล้างแอร์',
                                board: 'air',
                                post_id: postId,
                                user_id: memberId
                            })
                            
                            addDoc(collection(db, `/exchange_coins/logs/transaction`), {
                                date: log_date,
                                coin: offerUseCoin,
                                event: 'ลงประกาศแจ้งความต้องการล้างแอร์',
                                board: 'air',
                                post_id: postId,
                                user_id: memberId,
                                type: 'use'
                            })
                        })
                    })
                }
                addDoc(collection(db, `/users/${memberId}/coin_log`), {
                    date: log_date,
                    coin: offerUseCoin,
                    coin_type: useCoinFrom,
                    event: 'ลงประกาศแจ้งความต้องการล้างแอร์',
                    board: 'air',
                    post_id: postId
                })

                const promises = []
                if (air1Picture1 || air1Picture2 || air1Picture3 || air1Picture4 || air1Picture5) {
                    const fileAir1Upload = []
                    if(air1Picture1) fileAir1Upload.push(air1Picture1)
                    if(air1Picture2) fileAir1Upload.push(air1Picture2)
                    if(air1Picture3) fileAir1Upload.push(air1Picture3)
                    if(air1Picture4) fileAir1Upload.push(air1Picture4)
                    if(air1Picture5) fileAir1Upload.push(air1Picture5)
                    var i = 1
                    fileAir1Upload.map((file) => {
                        const storageRef = ref(storage, `/cleans/${postId}/air1/${i}`)
                        const vari = i
                        const uploadTask = uploadBytesResumable(storageRef, file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    addDoc(collection(db, `/cleans/${postId}/images1`), {
                                        url: downloadURLs,
                                    })
                                    if(vari == 1) {
                                        updateDoc(doc(db, 'cleans', postId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        i++
                    })
                }

                if (air2Picture1 || air2Picture2 || air2Picture3 || air2Picture4 || air2Picture5) {
                    const fileAir2Upload = []
                    if(air2Picture1) fileAir2Upload.push(air2Picture1)
                    if(air2Picture2) fileAir2Upload.push(air2Picture2)
                    if(air2Picture3) fileAir2Upload.push(air2Picture3)
                    if(air2Picture4) fileAir2Upload.push(air2Picture4)
                    if(air2Picture5) fileAir2Upload.push(air2Picture5)
                    var i = 1
                    fileAir2Upload.map((file) => {
                        const storageRef = ref(storage, `/cleans/${postId}/air2/${i}`)
                        const vari = i
                        const uploadTask = uploadBytesResumable(storageRef, file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    addDoc(collection(db, `/cleans/${postId}/images2`), {
                                        url: downloadURLs,
                                    })
                                    if(vari == 1) {
                                        updateDoc(doc(db, 'cleans', postId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        i++
                    })
                }

                if (air3Picture1 || air3Picture2 || air3Picture3 || air3Picture4 || air3Picture5) {
                    const fileAir3Upload = []
                    if(air3Picture1) fileAir3Upload.push(air3Picture1)
                    if(air3Picture2) fileAir3Upload.push(air3Picture2)
                    if(air3Picture3) fileAir3Upload.push(air3Picture3)
                    if(air3Picture4) fileAir3Upload.push(air3Picture4)
                    if(air3Picture5) fileAir3Upload.push(air3Picture5)
                    var i = 1
                    fileAir3Upload.map((file) => {
                        const storageRef = ref(storage, `/cleans/${postId}/air3/${i}`)
                        const vari = i
                        const uploadTask = uploadBytesResumable(storageRef, file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    addDoc(collection(db, `/cleans/${postId}/images3`), {
                                        url: downloadURLs,
                                    })
                                    if(vari == 1) {
                                        updateDoc(doc(db, 'cleans', postId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        i++
                    })
                }

                Promise.all(promises).then(() => {
                    setShowProgressBar(false)
                    navigate("/profile/posts/airs")
                })
            }).catch(function(error) {
                console.error("Error adding document: ", error)
            })
        }
    }

    const getDocUser = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user.uid))
            const docs = await getDocs(q)
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMenberId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.lat) setLat(data.lat)
                if(data.lng) setLng(data.lng)
                if(data.freecoin) setOfferFreeCoin(data.freecoin * 1)
                if(data.exchange_coin) setOfferExchangeCoin(data.exchange_coin * 1)
                if(data.lat) setRegisLat(data.lat)
                if(data.lng) setRegisLng(data.lng)
                setCenter({lat: data.lat, lng: data.lng})

                getDistrict(data.province)
                getSubDistrict(data.district)
                
                if(data.address) { setAddress(data.address); setAddressNoRegis(data.address) }
                if(data.village) { setVillage(data.village); setVillageRegis(data.village) }
                if(data.building) { setBuilding(data.building); setBuildingRegis(data.building) }
                if(data.floor) { setFloor(data.floor); setFloorRegis(data.floor) }
                if(data.room) { setRoom(data.room); setRoomRegis(data.room) }
                if(data.subroad) { setSubroad(data.subroad); setSubroadRegis(data.subroad) }
                if(data.road) { setRoad(data.road); setRoadRegis(data.road) }
                setProvinceId(data.province)
                setProvinceIdRegis(data.province)
                setDistrictId(data.district)
                setDistrictIdRegis(data.district)
                setSubDistrictId(data.sub_district)
                setSubDistrictIdRegis(data.sub_district)
                setPostcode(data.postcode)
                setPostcodeRegis(data.postcode)
            }
        } catch (err) {
            alert(err.message)
        }
    }

    const getCoinRate = () => {
        getDoc(doc(db, 'coins', 'use_rate')).then((exchangeSnapshot) => {
            const useCoin = exchangeSnapshot.data()
            setOfferUseCoin(useCoin.board6_1 * 1)
        })
    }
    
    const handleSelectProvince = (provinceId) => {
        setProvinceId(provinceId)
        getDistrict(provinceId)
    }

    const handleSelectDistrict = (districtId) => {
        setDistrictId(districtId)
        getSubDistrict(districtId)
    }

    const handelSelectsubDistrict = (subdistrictId) => {
        setSubDistrictId(subdistrictId)
        setPostcode('')

        getPostcodeBySubDistrict(db, subdistrictId).then((postCodeList) => {
            setPostcode(postCodeList.docs[0].data().postcode)
        })
    }

    const getProvince = () => {
        getProvinceList(db).then((provinceList) => {
            setProvinceList(provinceList.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getDistrict = (provinceId) => {
        setDistrictId(0)
        districtList.splice(0, districtList.length)
        setDistrictList([...districtList])

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDistrictListByProvince(db, provinceId).then((districtList) => {
            setDistrictList(districtList.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getSubDistrict = (districtId) => {
        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')
        getSubDistrictListByDistrict(db, districtId).then((subDistrictList) => {
            setSubDistrictList(subDistrictList.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getUseRegisAddress = () => {
        var promisesTask = []
        getDistrict(provinceIdRegis)
        getSubDistrict(districtIdRegis)
        Promise.all(promisesTask).then(() => {
            setAddressNo(addressNoRegis)
            setVillage(villageRegis)
            setBuilding(buildingRegis)
            setFloor(floorRegis)
            setRoom(roomRegis)
            setSubroad(subroadRegis)
            setRoad(roadRegis)
            setProvinceId(provinceIdRegis)
            setDistrictId(districtIdRegis)
            setSubDistrictId(subdistrictIdRegis)
            setPostcode(postcodeRegis)
        })
    }

    const getBrand = () => {
        getDocs(query(collection(db, 'air_brands'))).then((docs) => {
            setBrandList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectBrand1 = (brandId) => {
        setBrandId1(brandId)
        getDocs(query(collection(db, 'air_models'), where('air_brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList1(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleSelectBrand2 = (brandId) => {
        setBrandId2(brandId)
        getDocs(query(collection(db, 'air_models'), where('air_brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList2(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleSelectBrand3 = (brandId) => {
        setBrandId3(brandId)
        getDocs(query(collection(db, 'air_models'), where('air_brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList3(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else { 
            getDocUser()
            getCoinRate()
        }
        getProvince()
        getBrand()
    }, [user, loading])

    return (
        <>
            <Loading open={showProgressBar} text={"กำลังทำการลงประกาศ กรุณารอสักครู่"} />
            <div className='container' style={{ backgroundColor: "#fcfcfc", padding: 20 }}>
                <div className="row gy-3">
                    <div className="col-xs-12 text-center">
                        <h2><strong>ลงประกาศแจ้งความต้องการล้างแอร์</strong></h2>
                    </div>
                </div>
                <div className="row gy-3" style={{ marginTop: 20 }}>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                    <div className="col-12 col-md-6">
                        <div style={{ display: 'flex', flex: 5, flexDirection: "column", alignItems: 'start' }}>
                            <font style={{ fontSize: 17 }}><strong>จำนวนเหรียญที่ต้องใช้ในการประกาศ {number_format.format(offerUseCoin)} เหรียญ</strong></font>
                        {useCoinErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                <strong>** จำนวนเหรียญที่ต้องใช้ในการประกาศไม่เพียงพอ</strong>
                            </div>
                        }
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ตัดเหรียญจาก</strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ตัดเหรียญจาก</strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5 }}>
                            <select name='useCoinFrom' onChange={(e) => setUseCoinFrom(e.target.value)} style={{ marginLeft: 0, paddingLeft: 5 }}>
                                <option value="1">เหรียญแจกฟรี (คุณมี {number_format.format(offerFreeCoin)} เหรียญ)</option>
                                <option value="2">เหรียญแลกเปลี่ยน (คุณมี {number_format.format(offerExchangeCoin)} เหรียญ)</option>
                            </select>
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>วันเวลาที่ต้องการใช้บริการ</strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>วันเวลาที่ต้องการใช้บริการ</strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker slotProps={{ textField: { size: 'small' } }} format="DD/MM/YYYY" minDate={today} defaultValue={today} onChange={(value) => { setPickDate(value)} } />
                            </LocalizationProvider>
                            <select name='hour' onChange={(e) => setHour(e.target.value)} style={{ width: 70, marginLeft: 10 }}>
                                <option value="00">00</option>
                                <option value="01">01</option>
                                <option value="02">02</option>
                                <option value="03">03</option>
                                <option value="04">04</option>
                                <option value="05">05</option>
                                <option value="06">06</option>
                                <option value="07">07</option>
                                <option value="08">08</option>
                                <option value="09">09</option>
                                <option value="10">10</option>
                                <option value="11">11</option>
                                <option value="12">12</option>
                                <option value="13">13</option>
                                <option value="14">14</option>
                                <option value="15">15</option>
                                <option value="16">16</option>
                                <option value="17">17</option>
                                <option value="18">18</option>
                                <option value="19">19</option>
                                <option value="20">20</option>
                                <option value="21">21</option>
                                <option value="22">22</option>
                                <option value="23">23</option>
                            </select>
                            <select name='minute' onChange={(e) => setMinute(e.target.value)} style={{ width: 70, marginLeft: 5 }}>
                                <option value="00">00</option>
                                <option value="15">15</option>
                                <option value="30">30</option>
                                <option value="45">45</option>
                            </select>
                        </div>
                    </div>

                    <div className="d-none d-sm-block offset-md-2 col-md-12">
                        <font style={{ fontSize: 18 }}><input type="checkbox" checked={haveAir1?"checked":""} onChange={(e) => handleHaveAir1Change(e.target.value)} /> บริการล้างแอร์<strong>ติดผนัง</strong></font>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 18 }}><input type="checkbox" checked={haveAir1?"checked":""} onChange={(e) => handleHaveAir1Change(e.target.value)} /> บริการล้างแอร์<strong>ติดผนัง</strong></font>
                        </div>
                    </div>
                    {haveAir1 && (<>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>ยี่ห้อแอร์ <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>ยี่ห้อแอร์ <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='brand_id' onChange={(e) => { handleSelectBrand1(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                        {brandList?.map((brand, i) => (
                                        <option value={brand.id} key={brand.id}>
                                            {brand.data.name}
                                        </option>
                                        ))}
                                        <option value="999">อื่นๆ (โปรดระบุ)</option>
                                    </select>
                                    {brandIdErrorDiv1 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                        <strong>** กรุณาเลือกยี่ห้อ</strong>
                                    </div>
                                    }
                                </div>
                                {brandId1 =='999' &&
                                (<input type='text' name='other_type' onChange={(e) => setBrand1(e.target.value)} 
                                    value={brand1} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                )
                                }
                                {brandErrorDiv1 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกยี่ห้อ</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>รุ่นแอร์ <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>รุ่นแอร์ <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='model_id' onChange={(e) => { setModelId1(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณารุ่น ---</option>
                                        {modelList1?.map((model, i) => (
                                        <option value={model.id} key={model.id}>
                                            {model.data.name}
                                        </option>
                                        ))}
                                        <option value="999">อื่นๆ (โปรดระบุ)</option>
                                    </select>
                                    {modelIdErrorDiv1 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                        <strong>** กรุณาเลือกรุ่น</strong>
                                    </div>
                                    }
                                </div>
                                {modelId1 =='999' &&
                                (<input type='text' name='other_type' onChange={(e) => setModel1(e.target.value)} 
                                    value={model1} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                )
                                }
                                {modelErrorDiv1 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกรุ่น</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>ขนาด (BTU) <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>ขนาด (BTU) <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5 }}>
                                <input type='text' name='air1BTU' onChange={(e) => setAir1BTU(e.target.value)} 
                                    value={air1BTU} placeholder='ขนาด (BTU)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                {air1BTUErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                        <strong>** กรุณากรอกขนาด (BTU) และเป็นตัวเลขเท่านั้น</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5 }}>
                                <input type='text' name='air1Amount' onChange={(e) => setAir1Amount(e.target.value)} 
                                    value={air1Amount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                {air1AmountErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                        <strong>** กรุณากรอกจำนวน (เครื่อง) และเป็นตัวเลขเท่านั้น</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                        <div className="col-12 col-md-6">
                            <div style={{ display: 'flex', flex: 5 }}>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 17 }}><input type="checkbox" checked={air1IsOver3M?"checked":""} onChange={(e) => handleAir1IsOver3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงไม่เกิน 3 เมตร จำนวน</font></label>
                                <input type='text' name='air1Over3MAmount' onChange={(e) => setAir1Over3MAmount(e.target.value)} 
                                    value={air1Over3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginRight: 10, display: 'flex', flex: 1, width: 30, fontSize:17 }} />
                                <label style={{ marginTop: 5 }}><font style={{ fontSize: 17 }}>เครื่อง</font></label>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                        <div className="col-12 col-md-6">
                            <div style={{ display: 'flex', flex: 5 }}>
                            <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 17 }}><input type="checkbox" checked={air1IsBelow3M?"checked":""} onChange={(e) => handleAir1IsBelow3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงเกิน 3 เมตร จำนวน</font></label>
                                <input type='text' name='air1Below3MAmount' onChange={(e) => setAir1Below3MAmount(e.target.value)} 
                                    value={air1Below3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginRight: 10, display: 'flex', flex: 1, width: 30, fontSize:17 }} />
                                <label style={{ marginTop: 5 }}><font style={{ fontSize: 17 }}>เครื่อง</font></label>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>แอร์มีอาการดังต่อไปนี้</strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>แอร์มีอาการดังต่อไปนี้</strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem1?"checked":""} onChange={(e) => handleAir1IsProblem1(e.target.value)} /> บานสวิงทำงานไม่ปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem2?"checked":""} onChange={(e) => handleAir1IsProblem2(e.target.value)} /> คอยล์เย็นมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem3?"checked":""} onChange={(e) => handleAir1IsProblem3(e.target.value)} /> คอยล์ร้อนมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem4?"checked":""} onChange={(e) => handleAir1IsProblem4(e.target.value)} /> มีน้ำรั่วซึมหรือถาดน้ำทิ้งสภาพไม่ปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem5?"checked":""} onChange={(e) => handleAir1IsProblem5(e.target.value)} /> หน้ากากแอร์หลุดไม่ครบสภาพไม่ปกติ</font></label>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>รูปภาพคอยล์ร้อนและคอยล์เย็น <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>รูปภาพคอยล์ร้อนและคอยล์เย็น <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir1Picture1} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir1Upload1' style={{ padding: 0}}>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir1Upload1" type="file" accept="image/*" onChange={(e) => handleAir1Image1(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir1Picture2} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir1Upload2'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir1Upload2" type="file" accept="image/*" onChange={(e) => handleAir1Image2(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir1Picture3} alt="WEEE" width="80"style={{marginBottom: 5}}  />
                                    <label htmlFor='fileAir1Upload3'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir1Upload3" type="file" accept="image/*" onChange={(e) => handleAir1Image3(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir1Picture4} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir1Upload4'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir1Upload4" type="file" accept="image/*" onChange={(e) => handleAir1Image4(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir1Picture5} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir1Upload5'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir1Upload5" type="file" accept="image/*" onChange={(e) => handleAir1Image5(e.target.files[0])} />
                                </div>
                            </div>
                            {imageSize1Error &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                </div>
                            }
                            {pictureAir1ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกรูปภาพ</strong>
                                </div>
                            }
                        </div>
                    </>)}

                    <div className="d-none d-sm-block offset-md-2 col-md-12">
                        <font style={{ fontSize: 18 }}><input type="checkbox" checked={haveAir2?"checked":""} onChange={(e) => handleHaveAir2Change(e.target.value)} /> บริการล้างแอร์<strong>แขวนใต้ฝ้า</strong></font>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 18 }}><input type="checkbox" checked={haveAir2?"checked":""} onChange={(e) => handleHaveAir2Change(e.target.value)} /> บริการล้างแอร์<strong>แขวนใต้ฝ้า</strong></font>
                        </div>
                    </div>
                    {haveAir2 && (<>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>ยี่ห้อแอร์ <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>ยี่ห้อแอร์ <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='brand_id' onChange={(e) => { handleSelectBrand2(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                        {brandList?.map((brand, i) => (
                                        <option value={brand.id} key={brand.id}>
                                            {brand.data.name}
                                        </option>
                                        ))}
                                        <option value="999">อื่นๆ (โปรดระบุ)</option>
                                    </select>
                                    {brandIdErrorDiv2 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                        <strong>** กรุณาเลือกยี่ห้อ</strong>
                                    </div>
                                    }
                                </div>
                                {brandId2 =='999' &&
                                (<input type='text' name='other_type' onChange={(e) => setBrand2(e.target.value)} 
                                    value={brand2} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                )
                                }
                                {brandErrorDiv2 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกยี่ห้อ</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>รุ่นแอร์ <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>รุ่นแอร์ <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='model_id' onChange={(e) => { setModelId2(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณารุ่น ---</option>
                                        {modelList2?.map((model, i) => (
                                        <option value={model.id} key={model.id}>
                                            {model.data.name}
                                        </option>
                                        ))}
                                        <option value="999">อื่นๆ (โปรดระบุ)</option>
                                    </select>
                                    {modelIdErrorDiv2 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                        <strong>** กรุณาเลือกรุ่น</strong>
                                    </div>
                                    }
                                </div>
                                {modelId2 =='999' &&
                                (<input type='text' name='other_type' onChange={(e) => setModel2(e.target.value)} 
                                    value={model2} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                )
                                }
                                {modelErrorDiv2 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกรุ่น</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>ขนาด (BTU) <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>ขนาด (BTU) <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5 }}>
                                <input type='text' name='air2BTU' onChange={(e) => setAir2BTU(e.target.value)} 
                                    value={air2BTU} placeholder='ขนาด (BTU)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                {air2BTUErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                        <strong>** กรุณากรอกขนาด (BTU) และเป็นตัวเลขเท่านั้น</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5 }}>
                                <input type='text' name='air2Amount' onChange={(e) => setAir2Amount(e.target.value)} 
                                    value={air2Amount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                {air2AmountErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                        <strong>** กรุณากรอกจำนวน (เครื่อง) และเป็นตัวเลขเท่านั้น</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                        <div className="col-12 col-md-6">
                            <div style={{ display: 'flex', flex: 5 }}>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 17 }}><input type="checkbox" checked={air2IsOver3M?"checked":""} onChange={(e) => handleAir2IsOver3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงไม่เกิน 3 เมตร จำนวน</font></label>
                                <input type='text' name='air2Over3MAmount' onChange={(e) => setAir2Over3MAmount(e.target.value)} 
                                    value={air2Over3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginRight: 10, display: 'flex', flex: 1, width: 30, fontSize:17 }} />
                                <label style={{ marginTop: 5 }}><font style={{ fontSize: 17 }}>เครื่อง</font></label>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                        <div className="col-12 col-md-6">
                            <div style={{ display: 'flex', flex: 5 }}>
                            <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 17 }}><input type="checkbox" checked={air2IsBelow3M?"checked":""} onChange={(e) => handleAir2IsBelow3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงเกิน 3 เมตร จำนวน</font></label>
                                <input type='text' name='air2Below3MAmount' onChange={(e) => setAir2Below3MAmount(e.target.value)} 
                                    value={air2Below3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginRight: 10, display: 'flex', flex: 1, width: 30, fontSize:17 }} />
                                <label style={{ marginTop: 5 }}><font style={{ fontSize: 17 }}>เครื่อง</font></label>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>แอร์มีอาการดังต่อไปนี้</strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>แอร์มีอาการดังต่อไปนี้</strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem1?"checked":""} onChange={(e) => handleAir2IsProblem1(e.target.value)} /> บานสวิงทำงานไม่ปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem2?"checked":""} onChange={(e) => handleAir2IsProblem2(e.target.value)} /> คอยล์เย็นมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem3?"checked":""} onChange={(e) => handleAir2IsProblem3(e.target.value)} /> คอยล์ร้อนมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem4?"checked":""} onChange={(e) => handleAir2IsProblem4(e.target.value)} /> มีน้ำรั่วซึมหรือถาดน้ำทิ้งสภาพไม่ปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem5?"checked":""} onChange={(e) => handleAir2IsProblem5(e.target.value)} /> หน้ากากแอร์หลุดไม่ครบสภาพไม่ปกติ</font></label>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>รูปภาพคอยล์ร้อนและคอยล์เย็น <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>รูปภาพคอยล์ร้อนและคอยล์เย็น <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir2Picture1} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir2Upload1' style={{ padding: 0}}>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir2Upload1" type="file" accept="image/*" onChange={(e) => handleAir2Image1(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir2Picture2} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir2Upload2'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir2Upload2" type="file" accept="image/*" onChange={(e) => handleAir2Image2(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir2Picture3} alt="WEEE" width="80"style={{marginBottom: 5}}  />
                                    <label htmlFor='fileAir2Upload3'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir2Upload3" type="file" accept="image/*" onChange={(e) => handleAir2Image3(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir2Picture4} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir2Upload4'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir2Upload4" type="file" accept="image/*" onChange={(e) => handleAir2Image4(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir2Picture5} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir2Upload5'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir2Upload5" type="file" accept="image/*" onChange={(e) => handleAir2Image5(e.target.files[0])} />
                                </div>
                            </div>
                            {imageSize2Error &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                </div>
                            }
                            {pictureAir2ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกรูปภาพ</strong>
                                </div>
                            }
                        </div>
                    </>)}

                    <div className="d-none d-sm-block offset-md-2 col-md-12">
                        <font style={{ fontSize: 18 }}><input type="checkbox" checked={haveAir3?"checked":""} onChange={(e) => handleHaveAir3Change(e.target.value)} /> บริการล้างแอร์<strong>ฝังฝ้า 4 ทิศทาง</strong></font>
                    </div>
                    <div className="col-12 col-md-12">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 18 }}><input type="checkbox" checked={haveAir3?"checked":""} onChange={(e) => handleHaveAir3Change(e.target.value)} /> บริการล้างแอร์<strong>ฝังฝ้า 4 ทิศทาง</strong></font>
                        </div>
                    </div>
                    {haveAir3 && (<>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>ยี่ห้อแอร์ <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>ยี่ห้อแอร์ <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='brand_id' onChange={(e) => { handleSelectBrand3(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                        {brandList?.map((brand, i) => (
                                        <option value={brand.id} key={brand.id}>
                                            {brand.data.name}
                                        </option>
                                        ))}
                                        <option value="999">อื่นๆ (โปรดระบุ)</option>
                                    </select>
                                    {brandIdErrorDiv3 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                        <strong>** กรุณาเลือกยี่ห้อ</strong>
                                    </div>
                                    }
                                </div>
                                {brandId3 =='999' &&
                                (<input type='text' name='other_type' onChange={(e) => setBrand3(e.target.value)} 
                                    value={brand3} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                )
                                }
                                {brandErrorDiv3 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกยี่ห้อ</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>รุ่นแอร์ <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>รุ่นแอร์ <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='model_id' onChange={(e) => { setModelId3(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณารุ่น ---</option>
                                        {modelList3?.map((model, i) => (
                                        <option value={model.id} key={model.id}>
                                            {model.data.name}
                                        </option>
                                        ))}
                                        <option value="999">อื่นๆ (โปรดระบุ)</option>
                                    </select>
                                    {modelIdErrorDiv3 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                        <strong>** กรุณาเลือกรุ่น</strong>
                                    </div>
                                    }
                                </div>
                                {modelId3 =='999' &&
                                (<input type='text' name='other_type' onChange={(e) => setModel3(e.target.value)} 
                                    value={model3} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                )
                                }
                                {modelErrorDiv3 &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกรุ่น</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>ขนาด (BTU) <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>ขนาด (BTU) <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5 }}>
                                <input type='text' name='air3BTU' onChange={(e) => setAir3BTU(e.target.value)} 
                                    value={air3BTU} placeholder='ขนาด (BTU)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                {air3BTUErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                        <strong>** กรุณากรอกขนาด (BTU) และเป็นตัวเลขเท่านั้น</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5 }}>
                                <input type='text' name='air3Amount' onChange={(e) => setAir3Amount(e.target.value)} 
                                    value={air3Amount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                {air3AmountErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                        <strong>** กรุณากรอกจำนวน (เครื่อง) และเป็นตัวเลขเท่านั้น</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                        <div className="col-12 col-md-6">
                            <div style={{ display: 'flex', flex: 5 }}>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 17 }}><input type="checkbox" checked={air3IsOver3M?"checked":""} onChange={(e) => handleAir3IsOver3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงไม่เกิน 3 เมตร จำนวน</font></label>
                                <input type='text' name='air3Over3MAmount' onChange={(e) => setAir3Over3MAmount(e.target.value)} 
                                    value={air3Over3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginRight: 10, display: 'flex', flex: 1, width: 30, fontSize:17 }} />
                                <label style={{ marginTop: 5 }}><font style={{ fontSize: 17 }}>เครื่อง</font></label>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                        <div className="col-12 col-md-6">
                            <div style={{ display: 'flex', flex: 5 }}>
                            <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 17 }}><input type="checkbox" checked={air3IsBelow3M?"checked":""} onChange={(e) => handleAir3IsBelow3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงเกิน 3 เมตร จำนวน</font></label>
                                <input type='text' name='air3Below3MAmount' onChange={(e) => setAir3Below3MAmount(e.target.value)} 
                                    value={air3Below3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginRight: 10, display: 'flex', flex: 1, width: 30, fontSize:17 }} />
                                <label style={{ marginTop: 5 }}><font style={{ fontSize: 17 }}>เครื่อง</font></label>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>แอร์มีอาการดังต่อไปนี้</strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>แอร์มีอาการดังต่อไปนี้</strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 5, flexDirection: 'column' }}>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem1?"checked":""} onChange={(e) => handleAir3IsProblem1(e.target.value)} /> บานสวิงทำงานไม่ปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem2?"checked":""} onChange={(e) => handleAir3IsProblem2(e.target.value)} /> คอยล์เย็นมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem3?"checked":""} onChange={(e) => handleAir3IsProblem3(e.target.value)} /> คอยล์ร้อนมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem4?"checked":""} onChange={(e) => handleAir3IsProblem4(e.target.value)} /> มีน้ำรั่วซึมหรือถาดน้ำทิ้งสภาพไม่ปกติ</font></label>
                                <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem5?"checked":""} onChange={(e) => handleAir3IsProblem5(e.target.value)} /> หน้ากากแอร์หลุดไม่ครบสภาพไม่ปกติ</font></label>
                            </div>
                        </div>
                        <div className="d-none d-sm-block offset-md-2 col-md-2">
                            <font style={{ fontSize: 17 }}><strong>รูปภาพคอยล์ร้อนและคอยล์เย็น <font className="text-danger">*</font></strong></font>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="d-block d-sm-none">
                                <font style={{ fontSize: 17 }}><strong>รูปภาพคอยล์ร้อนและคอยล์เย็น <font className="text-danger">*</font></strong></font>
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir3Picture1} alt="APP3R" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir3Upload1' style={{ padding: 0}}>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir3Upload1" type="file" accept="image/*" onChange={(e) => handleAir3Image1(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir3Picture2} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir3Upload2'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir3Upload2" type="file" accept="image/*" onChange={(e) => handleAir3Image2(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir3Picture3} alt="WEEE" width="80"style={{marginBottom: 5}}  />
                                    <label htmlFor='fileAir3Upload3'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir3Upload3" type="file" accept="image/*" onChange={(e) => handleAir3Image3(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir3Picture4} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir3Upload4'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir3Upload4" type="file" accept="image/*" onChange={(e) => handleAir3Image4(e.target.files[0])} />
                                </div>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={srcAir3Picture5} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                    <label htmlFor='fileAir3Upload5'>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                    <input style={{ display:'none' }} className="form-control file" id="fileAir3Upload5" type="file" accept="image/*" onChange={(e) => handleAir3Image5(e.target.files[0])} />
                                </div>
                            </div>
                            {imageSize3Error &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                </div>
                            }
                            {pictureAir3ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกรูปภาพ</strong>
                                </div>
                            }
                        </div>
                    </>)}
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ตำแหน่งพิกัดที่พักอาศัย</strong></font> 
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                            <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToRegisPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งเริ่มต้น</button>
                            <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToCurrentPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งปัจจุบัน</button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ตำแหน่งพิกัดที่พักอาศัย</strong></font>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                                <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToRegisPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งเริ่มต้น</button>
                                <button style={{ textAlign: 'center', marginBottom: 7 }} className="btn btn-success" type='button' onClick={() => { handelGoToCurrentPosition() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ตำแหน่งปัจจุบัน</button>
                            </div>
                        </div>
                        <div style={{ marginBottom: 1, flexDirection: 'column', display: 'flex', flexWrap: 'nowrap', height:400, flex: 5 }}>
                            <div className='text-danger' style={{ marginBottom: 10, marginTop: 8, display: 'flex', flexWrap: 'nowrap' }}>
                                ** ลาก <FaMapMarkerAlt className='text-danger' style={{ marginLeft: 5, marginRight: 5 }} /> Marker  เพื่อกำหนดตำแหน่ง
                            </div>
                            <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                            <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                <MarkerF
                                    draggable={true} position={center}
                                    onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                />
                            </GoogleMap>
                            )}
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ที่อยู่</strong></font>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                            <button style={{ textAlign: 'center' }} className="btn btn-success" type='button' onClick={() => { getUseRegisAddress() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ที่อยู่เริ่มต้น</button>
                        </div>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ที่อยู่</strong></font>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, width:"100%", marginTop: 7 }}>
                                <button style={{ textAlign: 'center' }} className="btn btn-success" type='button' onClick={() => { getUseRegisAddress() }}><FaMapMarkerAlt className='text-white' style={{ marginRight: 5 }} /> ใช้ที่อยู่เริ่มต้น</button>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection:'column', flex: 5 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'row', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='addressNo' onChange={(e) => setAddressNo(e.target.value)} 
                                    value={addressNo} placeholder='บ้านเลขที่'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='village' onChange={(e) => setVillage(e.target.value)} 
                                    value={village} placeholder='หมู่บ้าน/สถานที่'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='room' onChange={(e) => setRoom(e.target.value)} 
                                    value={room} placeholder='ห้อง'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='floor' onChange={(e) => setFloor(e.target.value)} 
                                    value={floor} placeholder='ชั้น'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='building' onChange={(e) => setBuilding(e.target.value)} 
                                    value={building} placeholder='อาคาร'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='subroad' onChange={(e) => setSubroad(e.target.value)} 
                                    value={subroad} placeholder='ซอย'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7, marginRight: 5 }} />
                                </div>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                    value={road} placeholder='ถนน'
                                    style={{ paddingLeft: 8, fontSize:17, marginTop: 7 }} />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'column', marginTop: 7 }}>
                                <select name='province' value={provinceId} onChange={(e) => { handleSelectProvince(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17, flex: 1 }}>
                                    <option value="0">--- กรุณาเลือกจังหวัด ---</option>
                                    {provinceList?.map((province, i) => (
                                    <option value={province.data.province_id} key={province.data.province_id}>
                                        {province.data.province_name}
                                    </option>
                                    ))}
                                </select>
                                {provinceIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกจังหวัด</strong>
                                </div>
                                }
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                <select name='district' value={districtId} onChange={(e) => { handleSelectDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17, flex: 1 }}>
                                    <option value="0" key="0">--- กรุณาเลือกอำเภอ ---</option>
                                    {districtList?.map((district, i) => (
                                    <option value={district.data.district_id} key={district.data.district_id}>
                                        {district.data.district_name}
                                    </option>
                                    ))}
                                </select>
                                {districtIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกอำเภอ</strong>
                                </div>
                                }
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                <select name='subdistrict' value={subdistrictId} onChange={(e) => { handelSelectsubDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17, flex: 1 }}>
                                    <option value="0">--- กรุณาเลือกตำบล ---</option>
                                    {subdistrictList?.map((subdistrict, i) => (
                                    <option value={subdistrict.data.subdistrict_id} key={subdistrict.data.subdistrict_id}>
                                        {subdistrict.data.subdistrict_name}
                                    </option>
                                    ))}
                                </select>
                                {subdistrictIdErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** กรุณาเลือกตำบล</strong>
                                </div>
                                }
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                <div style={{ display: 'flex', flexDirection:'column'}}>
                                <input type='text' name='postcode' readOnly
                                    value={postcode} placeholder='รหัสไปรษณีย์'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize: 17 }} />
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">
                        <font style={{ fontSize: 17 }}><strong>ที่อยู่เพิ่มเติม</strong></font>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="d-block d-sm-none">
                            <font style={{ fontSize: 17 }}><strong>ที่อยู่เพิ่มเติม</strong></font>
                        </div>
                        <div style={{ display: 'flex', flex: 5 }}>
                            <textarea style={{ display: 'flex', flex: 1, width:"100%", height: 100, fontSize: 17, paddingLeft: 10 }}
                                onChange={(e) => setAddress(e.target.value)}
                                placeholder='ที่อยู่เพิ่มเติม' 
                                value={address}></textarea>
                        </div>
                    </div>
                    <div className="d-none d-sm-block offset-md-2 col-md-2">&nbsp;</div>
                    <div className="col-12 col-md-6">
                        <div style={{ marginBottom: 1, flexDirection: 'row', display: 'flex', flexWrap: 'nowrap', flex: 5 }}>
                            <button style={{ textAlign: 'center' }} className="btn btn-primary" type='button' onClick={ () => { startAcceptPolicy() } }>บันทึก</button>
                        </div>
                    </div>
                </div>
            </div>


            <Dialog open={openAcceptPolicy1} onClose={handleCloseAcceptPolicy1} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>เนื้อหาที่สร้างโดยผู้ใช้งาน</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. หจก. ออนซอน อนุญาตให้ผู้ใช้งานสามารถสร้างและอัปโหลด (Upload) เนื้อหาต่างๆ (“เนื้อหาที่สร้างโดยผู้ใช้งาน”) ขึ้นมาบนแพลตฟอร์มได้ (“ผู้สร้างเนื้อหา”) ในรูปแบบของประกาศโฆษณา บทความ และข้อคิดเห็นทั่วไป โดยเนื้อหาที่สร้างโดยผู้ใช้งานจะเผยแพร่บนแพลตฟอร์มและเปิดให้ผู้ใช้งานทั่วไปเข้าถึงได้</p>
                            <p>2. ผู้สร้างเนื้อหาเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อความถูกต้อง ความน่าเชื่อถือ ลักษณะ การปลอดจากสิทธิ์ การตรงตามกฎหมาย มาตรฐานทางวิชาชีพ ลิขสิทธิ์ และข้อจำกัดทางกฎหมายที่เกี่ยวข้องกับเนื้อหาที่ตนสร้างอัปโหลด (Upload) หรือทำให้อยู่บนแพลตฟอร์มด้วยวิธีการอื่นใดทั้งหมด อาทิเช่นแต่ไม่จำกัดเพียง ข้อมูล ข้อความ เนื้อหา รูปภาพ ภาพถ่าย กราฟิก เพลง เสียง และแท็ก เป็นต้น อีกทั้งผู้สร้างเนื้อหาต้องปฏิบัติตามเงื่อนไข ในหัวข้อ “ลงประกาศและยื่นข้อเสนอ” อย่างเคร่งครัด</p>
                            <p>3. ท่านรับทราบและเข้าใจว่าแพลตฟอร์มเป็นเพียงช่องทางให้ผู้สร้างเนื้อหาเผยแพร่เนื้อหาที่สร้างโดยผู้ใช้งานเท่านั้น ท่านตกลงว่าท่านได้เข้าถึงเนื้อหาที่สร้างโดยผู้ใช้งานภายใต้ความเสี่ยงของท่านเอง การตัดสินใจใดๆ ของท่านในการเข้าถึงเนื้อหาที่สร้างโดยผู้ใช้งาน หรืออันเป็นผลมาจากการเข้าถึงเนื้อหาที่สร้างโดยผู้ใช้งาน เป็นข้อผูกพันระหว่างท่านและผู้สร้างเนื้อหาทั้งหมด ซึ่งท่านต้องรับผิดชอบแต่เพียงผู้เดียว หจก. ออนซอน ไม่มีส่วนเกี่ยวข้องด้วยแต่อย่างใด</p>
                            <p>4. หจก. ออนซอน จะไม่รับผิดชอบต่อ และจะไม่เฝ้าติดตามหรือตรวจสอบถึงความถูกต้อง ความน่าเชื่อถือ ลักษณะ การปลอดจากสิทธิ์ การตรงตามกฎหมาย มาตรฐานทางวิชาชีพ ลิขสิทธิ์ และข้อจำกัดทางกฎหมายที่เกี่ยวข้องกับเนื้อหาที่สร้างโดยผู้ใช้งาน ท่านจะไม่กำหนดให้ หจก. ออนซอน ต้องรับผิดชอบต่อการดำเนินการหรือไม่ดำเนินการใดๆ ของผู้ใช้งาน</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy1?"checked":""} onChange={(e) => handleCheckPolicy1(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy1ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy1} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy1} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy2} onClose={handleCloseAcceptPolicy2} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>สิทธิ์การใช้งานแบบจำกัดและทรัพย์สินทางปัญญา</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. หจก. ออนซอน มอบสิทธิ์การใช้งานแบบจำกัด และเพิกถอนได้ในการเข้าถึงและใช้บริการแพลตฟอร์มตามเงื่อนไขการใช้งานนี้</p>
                            <p>2. เนื้อหา เครื่องหมายการค้า เครื่องหมายบริการ โลโก้ และทรัพย์สินทางปัญญาอื่นๆ ที่มีกรรมสิทธิ์ทั้งหมดที่แสดงในแพลตฟอร์ม (“ทรัพย์สินทางปัญญา”) ไม่ว่าจะจดทะเบียนหรือไม่จดทะเบียน เป็นทรัพย์สินของ หจก. ออนซอน และหากเหมาะสมเป็นทรัพย์สินของบุคคลภายนอกที่ถือกรรมสิทธิ์ตามที่ระบุไว้ในแพลตฟอร์ม แพลตฟอร์มไม่มีการมอบสิทธิหรือสิทธิการใช้งานทั้งโดยตรงหรือโดยอ้อมแก่บุคคลใดที่เข้าถึงแพลตฟอร์มเพื่อใช้งานหรือลอกเลียนแบบทรัพย์สินทางปัญญาใด และไม่มีบุคคลใดที่เข้าถึงแพลตฟอร์มจะสามารถอ้างสิทธิ กรรมสิทธิ์ หรือผลประโยชน์ในแพลตฟอร์มนี้ได้โดยการใช้หรือเข้าถึงบริการ</p>
                            <p>3. ท่านตกลงที่จะปฏิบัติตามกฎหมายทรัพย์สินทางปัญญา และกฎหมายที่บังคับใช้อื่นๆทั้งหมดที่คุ้มครองบริการแพลตฟอร์มและเนื้อหาในแพลตฟอร์ม ท่านตกลงที่จะไม่คัดลอก แจกจ่าย เผยแพร่ต่อ ส่งต่อ แสดงแก่สาธารณะ ดำเนินการต่อสาธารณะ ดัดแปลง ปรับเปลี่ยน ให้เช่า ขาย หรือสร้างงานต่อเนื่องจากส่วนหนึ่งส่วนใดของแพลตฟอร์ม และเนื้อหาและบริการในแพลตฟอร์ม โดยไม่ได้รับคำยินยอมเป็นลายลักษณ์อักษรล่วงหน้าจาก หจก. ออนซอน (คำยินยอมดังกล่าวจะถือว่ามอบให้สำหรับเทคโนโลยีเครื่องมือค้นหามาตรฐานที่ใช้โดยแพลตฟอร์มค้นหาทางอินเทอร์เน็ตเพื่อนำผู้ใช้อินเทอร์เน็ตมายังแพลตฟอร์มนี้)</p>
                            <p>4. ท่านตกลงจะไม่นำเนื้อหา เครื่องหมายการค้า เครื่องหมายบริการ โลโก้ รูปภาพ และทรัพย์สินทางปัญญาอื่น ที่เป็นของบุคคล นิติบุคล อื่นที่มิใช้ของท่านเข้ามาบันทึกหรืออ้างอิงในแพลตฟอร์ม หากท่านดำเนินการละเมิดทรัพย์สินทางปัญญาทั้งตั้งใจและไม่ตั้งใจ หากตรวจพบหรือมีการร้องเรียนถือเป็นอันยินยอมที่ท่านจะรับผิดชอบค่าเสียหายและยินยอมให้ดำเนินคดีตามกฎหมายและชดใช้ค่าใช้จ่ายทุกอย่างที่เกิดขึ้นทั้งระหว่างและสิ้นสุดการดำเนินคดี โดยไม่มีข้อโต้แย้งใดๆ</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy2?"checked":""} onChange={(e) => handleCheckPolicy2(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy2ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy2} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy2} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy3} onClose={handleCloseAcceptPolicy3} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ลงประกาศและยื่นข้อเสนอ</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. หากท่านเป็นผู้สร้างเนื้อหาหรือลงประกาศหรือยื่นข้อเสนอ ท่านยืนยันและรับรองว่าท่านเป็นเจ้าของ หรือได้รับสิทธิ ความยินยอม และการอนุญาตที่จำเป็นในการเผยแพร่ซึ่งเนื้อหาที่สร้างโดยผู้ใช้งานที่ท่านสร้าง อัปโหลด (Upload) หรือทำให้อยู่บนแพลตฟอร์มด้วยวิธีการอื่นใดทั้งหมด (“เนื้อหาที่ท่านส่งมา”) หจก. ออนซอน ไม่อนุญาตให้มีกิจกรรมที่เป็นการละเมิดสิทธิทางทรัพย์สินทางปัญญาใดๆ บนแพลตฟอร์ม หจก. ออนซอน สงวนสิทธิ์ในการลบเนื้อหาใดๆ หากมีการแจ้งอย่างถูกต้องว่าเนื้อหาดังกล่าวละเมิดสิทธิทางทรัพย์สินทางปัญญาของผู้อื่นตามกฎหมายที่เกี่ยวข้องกับสิทธิทางทรัพย์สินทางปัญญา</p>
                            <p>2. เนื้อหาที่ท่านส่งมา จะต้องไม่เป็น “เนื้อหาที่ต้องห้าม” ดังต่อไปนี้</p>
                            <p style={{ marginLeft: 15 }}>
                                1) ห้ามเนื้อหาที่เป็นเท็จ ไม่ถูกต้อง หลอกลวง ฉ้อฉล สร้างความหลงผิด สร้างความเข้าใจผิด หรือชี้นำไปในทางที่ผิด<br/>
                                2) ห้ามเนื้อหาที่ผิดกฎหมาย ผิดกฎระเบียบและ/หรือข้อบังคับ ผิดมาตรฐานทางวิชาชีพบัญชี ผิดจรรยาบรรณ หยาบคาย หมิ่นประมาท ทำให้เสื่อมเสีย หรือขัดต่อความสงบเรียบร้อยหรือศีลธรรมอันดี<br/>
                                3) ห้ามเนื้อหาที่มีความรุนแรง เช่น เนื้อหาดูหมิ่น เนื้อหาที่เกี่ยวข้องกับการใช้ความรุนแรง โหดร้าย การเปลือยกาย เพศสัมพันธ์ วิปริตทางเพศ ศิลปะลามก การลดคุณค่าทางเพศ การชักชวนค้าประเวณี ภาษาที่รุนแรง การกล่าวถึงโดยหยาบคายหรือถ้อยคำรังเกียจ หรือเนื้อหาใดที่ไม่เหมาะสม อนาจาร ไม่ถูกต้องหรือมีลักษณะที่รุนแรง โดยไม่คำนึงว่ามีเจตนาที่มุ่งร้ายหรือมีเจตนาที่จะก่อความรำคาญ ทำร้าย ข่มขู่หรือรบกวนบุคคลใด<br/>
                                4) ห้ามเนื้อหาที่เกี่ยวข้องกับการเมือง มีความรุนแรง โค่นล้มหรือปลุกระดม เช่น เนื้อหาที่กระตุ้นหรือสนับสนุนความขัดแย้งต่อประเทศหรือรัฐใด หรือ รัฐธรรมนูญ รัฐบาลหรือกฎหมายของประเทศนั้น หรือกระตุ้นหรือสนับสนุนความขัดแย้งระหว่างกลุ่ม เชื้อชาติ ชาติพันธุ์ ศาสนาหรือเพศหรือการทำให้ศาสนาหรือความเชื่อทางวัฒนธรรมเสื่อมเสีย<br/>
                                5) ห้ามเนื้อหาที่เป็นการสนับสนุนการก่อการร้ายหรือกิจกรรมที่เกี่ยวข้องกับการก่อการร้าย<br/>
                                6) ห้ามเนื้อหาที่เกี่ยวข้องกับการพนันและยาเสพติด<br/>
                                7) ห้ามเนื้อหาซึ่งไม่เหมาะสมสำหรับผู้ใช้ที่มีอายุ 20 ปีหรือต่ำกว่า<br/>
                                8) ห้ามเนื้อหาที่เกี่ยวข้องหรือสนับสนุนเนื้อหาที่ต้องห้ามหรือถูกสั่งห้ามตามกฎหมายใด ๆ<br/>
                                9) ห้ามเนื้อหาที่เกี่ยวข้องกับการแสดงตัว เลียนแบบ หรือปลอมแปลงเป็นบุคคลอื่นใด หรือแสดงข้อมูลเท็จเกี่ยวกับการเชื่อมโยงกับบุคคลอื่น<br/>
                                10) หน้าเนื้อหาที่มีไวรัสซอฟต์แวร์ หนอน ม้าโทรจัน หรือรหัสคอมพิวเตอร์ รูทีน ไฟล์หรือโปรแกรมที่ออกแบบมาเพื่อแทรกแซงทั้งโดยตรงและโดยอ้อมต่อ ปรับเปลี่ยน ขัดขวางทำลาย หรือจำกัดฟังก์ชันการทำงานของซอฟต์แวร์คอมพิวเตอร์หรือฮาร์ดแวร์ใดๆ หรือข้อมูลหรืออุปกรณ์โทรคมนาคม ใช้งานได้
                            </p>
                            <p>3. ผู้สร้างเนื้อหามีสิทธิความเป็นเจ้าของของท่านทั้งหมดในเนื้อหาที่ท่านส่งมา อย่างไรก็ตาม ผู้สร้างเนื้อหาตกลงว่าท่านอนุญาตให้ หจก. ออนซอน ได้สิทธิในการใช้ ทำซ้ำ ดัดแปลง เผยแพร่ แจกจ่าย แสดงแก่สาธารณะ ดำเนินการต่อสาธารณะ สร้างสรรค์งานต่อเนื่อง และ/หรือดำเนินการที่เกี่ยวกับบริการและธุรกิจของ หจก. ออนซอน ไม่ว่าในรูปแบบสื่อใด ไม่ว่าผ่านช่องทางสื่อใด และไม่ว่าบางส่วนหรือทั้งหมด ทั้งนี้ สิทธิดังกล่าวใช้บังคับได้ตลอดกาล ทั่วโลก เพิกถอนไม่ได้ ไม่ผูกขาด ไม่มีค่าใช้จ่าย และสามารถทำการอนุญาตช่วงและโอนการอนุญาตต่อได้</p>
                            <p>4. ผู้สร้างเนื้อหารับทราบและตกลงว่าเนื้อหาที่ท่านส่งมาไม่ถือว่าเป็นความลับโดย หจก. ออนซอน และจะถูกเผยแพร่หรือใช้โดย หจก. ออนซอน โดยปราศจากการชดเชยหรือการรับผิดต่อท่าน ไม่ว่าด้วยจุดประสงค์ใดก็ตาม ท่านอนุญาตให้ผู้ใช้งานแต่ละรายของแพลตฟอร์มเข้าถึงเนื้อหาที่ท่านส่งมาผ่านแพลตฟอร์ม อย่างไรก็ตามข้อกำหนดนี้ไม่ใช้บังคับกับข้อมูลส่วนบุคคลที่ต้องอยู่ภายใต้นโยบายการคุ้มครองข้อมูลส่วนบุคคลของแพลตฟอร์ม เว้นแต่ในกรณีที่ท่านเองเป็นผู้เปิดเผยและ/หรือทำให้ข้อมูลส่วนบุคคลของท่านใช้งานได้ เข้าถึงได้ และ/หรือแสดงแก่สาธารณะบนแพลตฟอร์ม</p>
                            <p>5. การอนุญาต ยินยอม ยืนยันของท่านข้างต้นนั้นเป็นการถาวรและไม่สามารถเพิกถอนได้</p>
                            <p>6. หจก. ออนซอน ไม่รับรองหรือมีหน้าที่ใดๆ ต่อเนื้อหาที่ท่านส่งมา หรือต่อการใช้บริการโดยผู้ใช้งานคนใด หรือต่อความเห็น ข้อแนะนำ หรือข้อคิดเห็นใดที่แสดงอยู่บนแพลตฟอร์ม หจก. ออนซอน ปฏิเสธอย่างชัดแจ้งซึ่งความรับผิดทั้งหมดที่เกี่ยวข้องกับเนื้อหาที่ท่านส่งมาและเนื้อหาที่สร้างโดยผู้ใช้งานทั้งหมด</p>
                            <p>7. หจก. ออนซอน สงวนสิทธิ์ในการลบเนื้อหาใดๆ หรือระงับไม่ให้บุคคลใดๆเข้าถึงเนื้อหาใดๆ โดยไม่แจ้งท่านล่วงหน้าหรือไม่ให้เหตุผลใด และท่านตกลงว่า หจก. ออนซอน จะไม่ต้องรับผิดสำหรับความสูญเสียหรือความเสียหายที่เกิดขึ้นไม่ว่าโดยทางใดที่เป็นผลมาจากการดำเนินการตามดุลพินิจของ หจก. ออนซอน ในการลบเนื้อหาใด</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy3?"checked":""} onChange={(e) => handleCheckPolicy3(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy3ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy3} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy3} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy4} onClose={handleCloseAcceptPolicy4} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>เงื่อนไขการลงประกาศ</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. “ประกาศ” คือพื้นที่บนแพลตฟอร์มซึ่ง หจก. ออนซอน ได้จัดให้มีขึ้น เพื่อให้ผู้ใช้ระบบได้ใช้เพื่อโฆษณาประชาสัมพันธ์บริการหรือโชว์ผลงานหรือขายสินค้า</p>
                            <p>2. ผู้ลงประกาศต้องปฏิบัติตามเงื่อนไขการลงประกาศดังต่อไปนี้โดยเคร่งครัด</p>
                            <p style={{ marginLeft: 15 }}>
                            1) เฉพาะผู้ใช้งานที่มีบัญชีผู้ใช้งาน (“สมาชิก”) เท่านั้นจึงจะสามารถลงประกาศได้<br/>
                            2) ผู้ลงประกาศต้องไม่ใช้ข้อมูลที่เป็นเท็จ หลอกลวง ฉ้อฉล คลุมเครือ สร้างหรืออาจก่อให้เกิดความเสียหายให้แก่บุคคลอื่น ในการลงประกาศบริการบัญชีของตน<br/>
                            3) ในการลงประกาศ ผู้ลงประกาศจะต้องเลือกประเภทประกาศให้ถูกต้อง โดยเนื้อหาในประกาศจะต้องตรงกับประเภทประกาศที่เลือกเท่านั้น และผู้ลงประกาศจะต้องกรอกข้อมูลในแบบฟอร์มการลงประกาศให้สอดคล้องกับหัวข้อที่กำหนดไว้ในแบบฟอร์มการลงประกาศเท่านั้น เพื่อไม่รบกวนสิทธิของสมาชิกท่านอื่น หาก หจก. ออนซอน พบว่าสมาชิกท่านใดลงประกาศซ้ำเดิม หจก. ออนซอน สงวนสิทธิในการลบประกาศ และ/หรือระงับการใช้งานบัญชีผู้ใช้งานของสมาชิกท่านนั้น โดยไม่ต้องแจ้งล่วงหน้าแต่อย่างใด
                            </p>
                            <p>3. ข้อห้ามในการลงประกาศ</p>
                            <p style={{ marginLeft: 15 }}>
                            1) ห้ามลงประกาศด้วยบริการหรือข้อมูลใดที่ไม่ใช่บริการที่มีในแพลตฟอร์ม<br/>
                            2) ห้ามลงประกาศด้วยเนื้อหาในประกาศที่ไม่สอดคล้องกับประเภทประกาศที่เลือก<br/>
                            3) ห้ามลงประกาศด้วยบริการเดิมซ้ำมากกว่า 1 ประกาศ<br/>
                            4) ห้ามใช้บัญชีผู้ใช้งานหลายบัญชีในการลงประกาศบริการเดียวกัน<br/>
                            5) ห้ามนำเข้า เสนอข้อมูล เผยแพร่ข้อมูล หรือกระทำการใดบนเว็บไซต์ซึ่งจะเป็นการผิดกฎหมาย ผิดศีลธรรมและวัฒนธรรมอันดี ก่อให้เกิดความขัดแย้ง ก่อให้เกิดความสูญเสียหรือเสียหายใดๆ หรือเป็นเนื้อหาต้องห้าม หากมีการร้องเรียนหรือฟ้องร้องถือเป็นความรับผิดชอบของผู้ลงประกาศ
                            </p>
                            <p>4. ผู้ใช้ระบบที่ลงประกาศรับทราบและตกลงว่า “เนื้อหาที่ใช้ในการลงประกาศ” ซึ่งรวมถึงแต่ไม่จำกัดเฉพาะ ข้อมูล ข้อความตัวอักษร ข้อความใดๆ รูปภาพ และรูปถ่าย จะไม่เป็นความลับ จะถูกเผยแพร่และพร้อมสำหรับการรับชมแบบสาธารณะ</p>
                            <p>5. ผู้ใช้ระบบที่ลงประกาศต้องปฏิบัติตามเงื่อนไขในหัวข้อเนื้อหาที่ท่านส่งมา และอื่นๆทั้งหมดในเงื่อนไขการใช้งานฉบับนี้</p>
                            <p>6. ผู้ใช้ระบบที่ลงประกาศเป็นผู้รับผิดชอบต่อความรับผิดตามมาตรฐานทางวิชาชีพบัญชี ตามจรรยาบรรณของผู้ประกอบวิชาชีพนั้นๆ และตามกฎหมายใดๆ อันเนื่องมาจากเนื้อหาที่ใช้ในการลงประกาศ</p>
                            <p>7. ผู้ใช้ระบบที่ลงประกาศรับผิดชอบและยืนยันว่าเนื้อหาที่ใช้ในการลงประกาศเป็นของตน ผู้ซึ่งมีกรรมสิทธิ์ และสิทธิอื่น ๆ ที่สมบูรณ์และได้รับความคุมครองทางกฎหมาย หรือตนได้รับการอนุญาตให้ใช้ที่ถูกต้องตามกฎหมาย ผู้ลงประกาศต้องรับผิดชอบแต่เพียงฝ่ายเดียวในกรณีที่มีการเรียกร้องความเสียหายที่เกี่ยวข้องกับเนื้อหาที่ใช้ในการลงประกาศโดยบุคคลอื่นที่มีสิทธิ์ที่เหนือกว่าผู้ลงประกาศ</p>
                            <p>8. ผู้ลงประกาศเป็นผู้รับผิดชอบแต่เพียงผู้เดียวต่อความถูกต้อง ความน่าเชื่อถือ ลักษณะ การปลอดจากสิทธิ์ การตรงตามกฎหมายและมาตรฐานทางวิชาชีพ ที่เกี่ยวข้องกับเนื้อหาที่ใช้ในการลงประกาศ หจก. ออนซอน จะไม่รับผิดชอบต่อ ไม่รับรอง และจะไม่เฝ้าติดตามหรือตรวจสอบถึงความถูกต้อง ความน่าเชื่อถือ ลักษณะ การปลอดจากสิทธิ์ การตรงตามกฎหมายและมาตรฐานทางวิชาชีพ ผู้ลงประกาศจะไม่กำหนดให้ หจก. ออนซอน ต้องรับผิดชอบต่อการดำเนินการหรือไม่ดำเนินการของผู้ใช้งานอื่นๆ อันเนื่องมาจากประกาศของผู้ลงประกาศ</p>
                            <p>9. หจก. ออนซอน สงวนสิทธิในการลบประกาศ ระงับการแสดงประกาศ หรือแก้ไขเปลี่ยนแปลงข้อมูลในประกาศ รวมทั้งปิดกั้นการเข้าสู่ระบบและระงับบัญชีผู้ใช้งานของผู้ลงประกาศ ซึ่งถือเป็นสิทธิขาดและขึ้นอยู่กับดุลพินิจของ หจก. ออนซอน แต่เพียงผู้เดียวโดยไม่ต้องบอกกล่าวแก่ผู้ลงประกาศเป็นการล่วงหน้า นอกจากนี้ หจก. ออนซอน ไม่มีภาระผูกพันหรือความรับผิดชอบต่อผลหรือต่อบุคคลใดๆ จากการดำเนินการนี้</p>
                            <p>10. การลงข้อความ</p>
                            <p style={{ marginLeft: 15 }}>
                            1) ผู้ลงประกาศจะต้องให้ข้อมูลเกี่ยวกับบริการบัญชีของตน อย่างถูกต้อง เป็นจริง มีรายละเอียดครบถ้วน ถูกต้องตามกฎหมายที่ใช้บังคับและตามที่ขึ้นทะเบียนไว้กับหน่วยงานกำกับดูแล (ถ้ามี) และจะต้องเป็นไปตามมาตรฐานทางวิชาชีพและจรรยาบรรณของผู้ประกอบวิชาชีพ<br/>
                            2) ข้อความที่ใช้ลงประกาศจะต้องเป็นข้อความที่เหมาะสม สุภาพ ไม่ผิดมาตรฐานทางวิชาชีพและจรรยาบรรณของผู้ประกอบวิชาชีพ ไม่ผิดกฎหมาย ไม่เป็นเนื้อหาต้องห้าม ไม่ก่อให้เกิดความสูญเสียหรือเสียหายใดๆ ไม่ประกอบด้วยลิงค์ไปยังภายนอกแพลตฟอร์ม และไม่ประกอบด้วยข้อมูลติดต่อ
                            </p>
                            <p>11. รูปภาพที่ใช้ในการลงประกาศจะต้องเป็นรูปที่เหมาะสม สุภาพ ไม่ผิดมาตรฐานทางวิชาชีพและจรรยาบรรณของผู้ประกอบวิชาชีพบัญชี ไม่ผิดกฎหมาย ไม่เป็นเนื้อหาต้องห้าม และจะต้องมีความชัดเจน เหมาะสม และมีความเกี่ยวข้องกับบริการบัญชีที่นำเสนอเท่านั้น</p>
                            <p>12. ราคาที่ระบุในประกาศต้องเป็นราคาซึ่งรวมภาษีและค่าธรรมเนียมต่างๆ แล้ว และต้องเป็นราคาที่ผู้ลงประกาศในฐานะผู้ประกอบวิชาชีพบัญชีพิจารณาแล้วเห็นว่าไม่ขัดต่อจรรยาบรรณของผู้ประกอบวิชาชีพบัญชี หจก. ออนซอน ไม่มีส่วนร่วม ส่วนรู้เห็น ส่วนเกี่ยวข้องใด หรืออิทธิพลใดต่อการตั้งราคาของผู้ลงประกาศข้อมูลติดต่อ</p>
                            <p>13. ผู้ลงประกาศต้องให้ข้อมูลการติดต่อ เช่น เบอร์โทรศัพท์ อีเมล์ ไลน์ไอดี เป็นต้น ภายในพื้นที่ที่แพลตฟอร์มได้จัดเตรียมไว้เท่านั้น โดยจะต้องเป็นข้อมูลติดต่อของผู้ลงประกาศที่ถูกต้อง เป็นจริง เป็นปัจจุบัน และสามารถติดต่อได้</p>
                            <p>14. เงื่อนไขการอัพเกรดประกาศ</p>
                            <p style={{ marginLeft: 15 }}>
                            1) “การอัพเกรดประกาศ” คือ การซื้อสิทธิพิเศษในการแสดงประกาศบนหน้าต่างๆ ของแพลตฟอร์ม ตามเงื่อนไขของแต่ละแพ็คเกจที่ตกลงซื้อ ผู้ลงประกาศมีสิทธิอัพเกรดประกาศได้ และผู้ใช้สิทธิอัพเกรดประกาศ (“ผู้ซื้อ”) จะต้องตกลงที่จะผูกพันกับเงื่อนไขการอัพเกรดประกาศนี้ หากท่านไม่ยอมรับที่จะผูกพันกับเงื่อนไขการอัพเกรดประกาศนี้ กรุณาอย่าทำการอัพเกรดประกาศ<br/>
                            2) ผู้ซื้อสามารถอัพเกรดประกาศได้เองผ่านหน้าบัญชีสมาชิก โดยผู้ซื้อเข้าใจและยอมรับว่า หจก. ออนซอน ไม่รับรองหรือรับประกันว่าจำนวนผู้เข้าชมของประกาศของผู้ซื้อจะเพิ่มขึ้นแต่อย่างใดจากการอัพเกรดประกาศ ผู้ซื้อควรอัพเกรดประกาศภายหลังจากที่ได้พิจารณางบประมาณและวัตถุประสงค์ของตนแล้วเท่านั้น หจก. ออนซอน จะไม่รับผิดต่อค่าชดเชยหรือความรับผิดใดๆ (รวมถึงแต่ไม่จำกัดเพียง ค่าใช้จ่ายและผลกำไรที่สูญเสียไปตามจริง) สำหรับผลลัพธ์หรือผลที่ตั้งใจไว้กับการอัพเกรดประกาศ<br/>
                            3) การอัพเกรดประกาศจะสิ้นสุดลงเมื่อครบระยะเวลาที่ผู้ซื้อเป็นผู้กำหนด (“ระยะเวลาการอัพเกรดประกาศ”) ทั้งนี้ผู้ซื้อไม่สามารถโอนระยะเวลาการอัพเกรดประกาศที่เหลือของประกาศหนึ่งไปใช้กับประกาศอื่นได้ อีกทั้งผู้ซื้อไม่สามารถยกเลิกคำสั่งซื้อและ/หรือร้องขอค่าธรรมเนียมคืนภายหลังจากที่ผู้ซื้อได้อัพเกรดประกาศและได้ชำระเงินเรียบร้อยแล้ว<br/>
                            4) ประกาศที่ลงประกาศบนแพลตฟอร์มจะต้องอยู่ภายใต้กฎหมายและกฎเกณฑ์ต่างๆ ที่เกี่ยวข้อง และเงื่อนไขการใช้งานนี้ ผู้ซื้อเข้าใจและตกลงให้ หจก. ออนซอน สิทธิในการลบประกาศออกได้ทันทีหากมีการละเมิดข้อกำหนดดังกล่าว และค่าธรรมเนียมการอัพเกรดประกาศที่ผู้ซื้อได้ชำระแล้วของประกาศที่ถูกลบออก ผู้ซื้อจะไม่ได้รับคืนแต่อย่างใด
                            </p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy4?"checked":""} onChange={(e) => handleCheckPolicy4(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy4ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy4} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy4} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy5} onClose={handleCloseAcceptPolicy5} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ข้อจำกัดความรับผิด</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. แพลตฟอร์มให้บริการในหลักเกณฑ์ “ตามสภาพ (as is)” และ “ตามที่มีอยู่หรือเป็นอยู่ (as available)” ไม่มีการรับรองหรือรับประกันอย่างใดๆ ไม่ว่าโดยปริยาย โดยชัดแจ้ง หรือโดยบทบัญญัติใดๆ</p>
                            <p>2. เนื้อหา ข้อมูล สาระ บทความ ความคิดเห็น และการเชื่อมต่ออื่นใดที่จัดสรรขึ้นหรือแสดงบนแพลตฟอร์มนี้ ไม่ได้รับการสำรวจ การตรวจสอบ หรือการรับรองโดย หจก. ออนซอน แต่อย่างใดทั้งสิ้น</p>
                            <p>3. ท่านรับทราบและยอมรับว่าความเสี่ยงทั้งหมดที่เกิดขึ้นจากการใช้งานหรือการเข้าถึงแพลตฟอร์มนี้ตกเป็นของท่าน</p>
                            <p>4. หจก. ออนซอน ไม่มีความรับผิดในความเสียหายใดๆ ซึ่งรวมถึงแต่ไม่เป็นการจำกัดเพียง ความเสียหายทางตรง ความเสียหายทางอ้อม ความเสียหายพิเศษ ความเสียหายโดยบังเอิญ ความเสียหายเกี่ยวเนื่อง ความสูญหาย หรือค่าใช้จ่ายใดๆที่เกิดขึ้นอันเกี่ยวเนื่องกับการไม่สามารถดำเนินการ ข้อผิดพลาด การงดเว้นการกระทำ การสอดแทรก การบกพร่อง ความล่าช้าในการดำเนินการหรือการถ่ายโอนข้อมูล ไวรัสคอมพิวเตอร์ หรือการไม่ทำงานของเครือข่ายหรือระบบ ทั้งนี้ ถึงแม้ว่า หจก. ออนซอน หรือตัวแทนของ หจก. ออนซอน จะได้รับแจ้งถึงความเป็นได้ในการเกิดความเสียหาย ความสูญหาย หรือค่าใช้จ่ายต่างๆดังกล่าวแล้วก็ตาม</p>
                            <p>5. หจก. ออนซอน ไม่รับประกันในความความปลอดภัยในข้อมูลใดๆ ที่ส่งโดยท่านหรือส่งไปยังท่าน ไม่ว่าจะผ่านแพลตฟอร์มหรือสื่อใดๆ และท่านยอมรับความเสี่ยงว่าข้อมูลใดๆ ที่ส่งหรือได้รับผ่านแพลตฟอร์มหรือสื่อใดๆ อาจจะถูกเข้าถึงโดยบุคคลภายนอกที่ไม่ได้รับอนุญาตได้ และ/หรือเปิดเผยโดย หจก. ออนซอน หรือตัวแทนของ หจก. ออนซอน ให้แก่บุคคลภายนอกที่อ้างว่าเป็นท่านหรืออ้างว่ากระทำการโดยได้รับมอบอำนาจจากท่าน การส่งข้อมูลทางอินเทอร์เน็ตและไปรษณีย์อิเล็กทรอนิกส์อาจจะถูกขัดขวาง ขาดการติดต่อ ล่าช้า จากความแออัดในการส่งข้อมูลทางอินเทอร์เน็ตหรือมีการส่งข้อมูลไม่ถูกต้องเนื่องจากลักษณะของอินเทอร์เน็ตที่อาจถูกเปิดเผยต่อสาธารณะได้</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy5?"checked":""} onChange={(e) => handleCheckPolicy5(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy5ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy5} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy5} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy6} onClose={handleCloseAcceptPolicy6} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>การชดใช้ค่าเสียหาย</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>ท่านตกลงที่จะชดใช้ ปกป้อง และทำให้ หจก. ออนซอน พ้นจากการเรียกร้องสิทธิ การดำเนินการ การดำเนินคดี และการฟ้องร้องคดี รวมถึงหนี้สิน ความเสียหาย การชำระหนี้ บทลงโทษ ค่าปรับ ค่าใช้จ่ายและต้นทุนที่เกี่ยวข้องทั้งหมด (รวมถึงแต่ไม่จำกัดเพียง ค่าใช้จ่ายในการยุติข้อพิพาทอื่นๆ) ที่เกิดแก่ หจก. ออนซอน อันเกิดจากการใช้งานหรือการเข้าถึงแพลตฟอร์มโดยท่าน หรือจากการละเมิดซึ่งเงื่อนไขการใช้งานฉบับนี้โดยท่าน</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy6?"checked":""} onChange={(e) => handleCheckPolicy6(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy6ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy6} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy6} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy7} onClose={handleCloseAcceptPolicy7} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ข้อห้ามการใช้บริการ และการบอกเลิกสัญญา</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. ท่านจะต้องไม่ใช้แพลตฟอร์มเพื่อวัตถุประสงค์ใดๆ ซึ่งเป็นสิ่งที่ผิดกฎหมายหรือต้องห้ามตามเงื่อนไขการใช้งานนี้ ท่านจะไม่ใช้แพลตฟอร์มในลักษณะใดๆ ซึ่งสามารถทำให้เกิดความเสียหายหรือทำให้ไร้ซึ่งความสามารถแก่แพลตฟอร์มหรือเครือข่ายที่เชื่อมต่อกับแพลตฟอร์ม หรือทำให้เกิดการรบกวนการใช้งานแพลตฟอร์มของบุคคลอื่นใด ท่านจะต้องไม่พยายามเข้าสู่แพลตฟอร์ม หรือระบบคอมพิวเตอร์หรือเครือข่ายที่เชื่อมต่อกับแพลตฟอร์ม โดยปราศจากอำนาจ ไม่ว่าด้วยวิธีการเจาะฐานข้อมูล (hacking) ทำลายรหัสลับส่วนตัว (password mining) หรือวิธีการอื่นใด</p>
                            <p>2. หจก. ออนซอน สงวนสิทธิที่จะใช้ดุลพินิจแต่เพียงผู้เดียวในการบอกเลิกสัญญานี้ ในการยกเลิกการให้เข้าสู่หรือใช้งานแพลตฟอร์มไม่ว่าบางส่วนหรือทั้งหมด และในการยกเลิกหรือระงับบัญชีผู้ใช้งาน ทั้งนี้การบอกเลิกหรือยกเลิกดังกล่าว หจก. ออนซอน สามารถกระทำเมื่อใดก็ได้ โดยไม่ต้องแจ้งสาเหตุ และไม่ต้องมีการแจ้งถึงการบอกเลิกหรือยกเลิก</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy7?"checked":""} onChange={(e) => handleCheckPolicy7(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy7ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy7} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy7} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy8} onClose={handleCloseAcceptPolicy8} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการลงประกาศหรือยื่นข้อเสนอ</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>การรายงานการละเมิดสิทธิ์ในทรัพย์สินทางปัญญา</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. ผู้ใช้งานเป็นปัจเจกบุคคลหรือธุรกิจที่เป็นอิสระและไม่เกี่ยวข้องกับ หจก. ออนซอน ไม่ว่าในทางใด หจก. ออนซอน  มิใช่ตัวแทนหรือผู้แทนของผู้ใช้งาน หจก. ออนซอน มิได้ครอบครองและ/หรือเป็นเจ้าของบริการบัญชีใดๆ ที่ลงประกาศไว้บนแพลตฟอร์ม</p>
                            <p>2. หากท่านเป็นเจ้าของสิทธิในทรัพย์สินทางปัญญาและท่านเชื่อว่าสิทธิของท่านได้ถูกละเมิด โปรดแจ้งให้ หจก. ออนซอน ทราบเป็นลายลักษณ์อักษรโดยส่งทางอีเมลมาที่ โปรดให้เวลา หจก. ออนซอน ประมวลข้อมูลและ หจก. ออนซอน จะตอบข้อร้องเรียนของท่านโดยเร็วที่สุดเท่าที่สามารถดำเนินการได้</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy8?"checked":""} onChange={(e) => handleCheckPolicy8(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy8ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy8} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy8} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default AddCleanAir;