import "./App.css"
import { BrowserRouter as Router, Route, Routes } from "react-router-dom"

import Header from "./sites/components/layouts/Header"
import Footer from "./sites/components/layouts/Footer"
import Home from "./sites/components/home/Home2"

import Home2 from "./sites/components/home/Home2"

import AboutUs from "./sites/components/home/AboutUs"
import Policy from "./sites/components/home/Policy"
import Privacy from "./sites/components/home/Privacy"
import Service1 from "./sites/components/home/Service1"
import Service2 from "./sites/components/home/Service2"
import Service3 from "./sites/components/home/Service3"
import Service4 from "./sites/components/home/Service4"

import Login from "./sites/components/authen/Login"
import Register from "./sites/components/authen/Register"

import PostOnboard from "./sites/components/posts/PostOnboard"
import AddThrowPost from "./sites/components/posts/AddBoard1"
import AddSellGabagePost from "./sites/components/posts/AddBoard2"
import AddSellSecondPost from "./sites/components/posts/AddBoard3"
import AddPostFix from "./sites/components/posts/AddBoardFix"

import AddCleanAir from "./sites/components/posts/AddCleanAir"
import AddCleanWashMac from "./sites/components/posts/AddCleanWashMac"

import PostDetail from "./sites/components/posts/Post"
import PostFix from "./sites/components/posts/PostFix"
import PostClean from "./sites/components/posts/PostClean"
import PostWash from "./sites/components/posts/PostWash"

import EditPostGabage from "./sites/components/member/EditPostGabage"
import EditPostThrow from "./sites/components/member/EditPostThrow"
import EditPostSecond from "./sites/components/member/EditPostSecond"
import EditPostFix from "./sites/components/member/EditPostFix"
import EditPostAir from "./sites/components/member/EditPostAir"
import EditPostWash from "./sites/components/member/EditPostWash"

import OfferAir from "./sites/components/member/OfferAir"

import PostsBoard1 from "./sites/components/posts/PostsBoard1"
import PostBoardList from "./sites/components/posts/PostList"
import FixBoardList from "./sites/components/posts/FixPost"
import AirBoardList from "./sites/components/posts/CleanPosts"
import WashBoardList from "./sites/components/posts/WashPosts"

import MemberListBoard1 from "./sites/components/member/PostListBoard1"
import MemberListBoard2 from "./sites/components/member/PostListBoard2"
import MemberListBoard3 from "./sites/components/member/PostListBoard3"
import MemberListBoard4 from "./sites/components/member/PostListBoard4"
import MemberListBoardAir from "./sites/components/member/PostListBoardAir"
import MemberListBoardWash from "./sites/components/member/PostListBoardWash"

import Profile from "./sites/components/member/Profile"
import EditProfile from "./sites/components/member/EditProfile"
import Address from "./sites/components/member/Address"
import Posts from "./sites/components/member/Posts"
import PostsGabage from "./sites/components/member/PostsGabage"
import PostsSecond from "./sites/components/member/PostsSecond"
import PostsFix from "./sites/components/member/PostsFix"
import PostsAir from "./sites/components/member/PostsCleanAir"
import PostsWash from "./sites/components/member/PostsCleanWash"
import Offers from "./sites/components/member/OfferShops"
import PostDetail1 from "./sites/components/member/PostDetail1"
import PostDetail2 from "./sites/components/member/PostDetail2"
import PostDetail3 from "./sites/components/member/PostDetail3"
import PostDetail4 from "./sites/components/member/PostDetail4"
import PostDetailAir from "./sites/components/member/PostDetailAir"
import PostDetailWash from "./sites/components/member/PostDetailWash"

import Appointments from "./sites/components/member/Appointments"
import AppointmentsClose from "./sites/components/member/AppointmentsClose"
import AppointmentsCancle from "./sites/components/member/AppointmentsCancle"
import MemberCoins from "./sites/components/coin/Coins"
import Notifications from "./sites/components/member/Notifications"

import AppointmentPosts from "./sites/components/member/AppointmentPosts"

import ShopProfile from "./sites/components/shop/Profile"
import ShopRegister from "./sites/components/shop/Register"
import ShopOffers from "./sites/components/shop/Offers"
import ShopOffersConfirm from "./sites/components/shop/OffersConfirm"
import ShopEdit from "./sites/components/shop/EditShop"
import ShopTech from "./sites/components/shop/ShopTech"
import Technician from "./sites/components/shop/Technician"
import AddTechnician from "./sites/components/shop/AddTechnician"
import EditTechnician from "./sites/components/shop/EditTechnician"
import ShopAppointment from "./sites/components/shop/Appointments"
import Finances from "./sites/components/shop/Finances"
import ShopNotifications from "./sites/components/shop/Notifications"
import ShopSpares from "./sites/components/shop/Spares"

import ShopCoins from "./sites/components/shop/Coins"
import ShopOfferHistory from "./sites/components/shop/OfferHistory"

import ShopAddSecond from "./sites/components/shop/PostOnBoard"
import ShopPosts from "./sites/components/shop/Posts"
import ShopEditPosts from "./sites/components/shop/EditPost"
import ShopPost from "./sites/components/shop/Post"
import ShopAppointmentPost from "./sites/components/shop/AppointmentPosts"

import ShopAds from "./sites/components/shop/Ads"

import ShopPostDetail1 from "./sites/components/shop/PostDetail1"
import ShopPostDetail2 from "./sites/components/shop/PostDetail2"
import ShopPostDetail3 from "./sites/components/shop/PostDetail3"
import ShopPostDetail4 from "./sites/components/shop/PostDetail4"
import ShopPostDetailAir from "./sites/components/shop/PostDetailAir"
import ShopPostDetailWash from "./sites/components/shop/PostDetailWash"

import AdminLogin from "./admin/components/authen/Login"
import AdminRegister from "./admin/components/authen/Register"
import AdminReset from "./admin/components/authen/Reset"
import AdminHeader from "./admin/components/layouts/Header"
import AdminLeftMenu from "./admin/components/layouts/LeftMenu"
import AdminFooter from "./admin/components/layouts/Footer"
import AdminHome from "./admin/components/dashboard/Home"
import AdminMembers from "./admin/components/member/MemberList"
import AdminMember from "./admin/components/member/Member"
import AdminAddMember from "./admin/components/member/AddMember"
import AdminEditMember from "./admin/components/member/EditMember"

import AdminPosts from "./admin/components/post/Posts"
import AdminPostsThrow from "./admin/components/post/PostListThrow"
import AdminPostsGarbage from "./admin/components/post/PostListGarbage"
import AdminPostsSecond from "./admin/components/post/PostListSecond"
import AdminMemberPosts from "./admin/components/post/UserPostList"
import AdminPost from "./admin/components/post/Post"

import AdminPostBoard1Detail from "./admin/components/post/PostBoard1Detail"
import AdminPostBoard2Detail from "./admin/components/post/PostBoard2Detail"
import AdminPostBoard3Detail from "./admin/components/post/PostBoard3Detail"
import AdminPostBoard4Detail from "./admin/components/post/PostBoard4Detail"
import AdminPostBoardAirDetail from "./admin/components/post/PostAirDetail"

import AdminBoard1Posts from "./admin/components/post/Board1PostList"
import AdminBoard2Posts from "./admin/components/post/Board2PostList"
import AdminBoard3Posts from "./admin/components/post/Board3PostList"
import AdminBoard4Posts from "./admin/components/post/Board4PostList"
import AdminBoardAirPosts from "./admin/components/post/BoardAirList"

import AdminShops from "./admin/components/shop/ShopList"
import AdminShop from "./admin/components/shop/Shop"
import AdminEditShop from "./admin/components/shop/EditShop"
import AdminAddShop from "./admin/components/shop/AddShop"
import AdminAddShopByUser from "./admin/components/shop/AddShopByUser"

import AdminShopTech from "./admin/components/shop/ShopTech"
import AdminAddTech from "./admin/components/shop/AddTechnician"
import AdminTechnician from "./admin/components/shop/Technician"
import AdminEditTech from "./admin/components/shop/EditTechnician"

import AdminCoins from "./admin/components/coins/Coins"
import AdminFreeCoins from "./admin/components/coins/FreeCoins"
import AdminExchangeCoins from "./admin/components/coins/ExCoins"
import AdminExchanges from "./admin/components/exchange/Exchanges"

import AdminUsers from "./admin/components/admins/AdminList"
import AdminAddUser from "./admin/components/admins/AddAdmins"
import AdminViewUser from "./admin/components/admins/Admin"
import AdminEditUser from "./admin/components/admins/EditAdmins"

import AdminElectrics from "./admin/components/electric/ElectricList"
import AdminBrokens from "./admin/components/broken/BrokenList"
import AdminBrands from "./admin/components/brand/BrandList"
import AdminAirBrands from "./admin/components/air/AirBrandList"
import AdminWashBrands from "./admin/components/washmac/WashBrandList"

import ImportExcel from "./sites/components/authen/ImportExcel"
import ImportElecBrand from "./sites/components/authen/ImportElecBrand"
import ImportElecModel from "./sites/components/authen/ImportElecModel"
import ImportAirModel from "./sites/components/authen/ImportAirModel"
import Under404 from "./sites/components/layouts/404"

import AdminReports from "./admin/components/reports/Reports"
import AdminUserReports from "./admin/components/reports/UserReports"

import AdminTexts from "./admin/components/texts/Texts"

import AdminAds from "./admin/components/ads/AdsSetting"
import AdminAdsPos1List from "./admin/components/ads/AdsPos1List"
import AdminAdsPos2List from "./admin/components/ads/AdsPos2List"
import AdminAdsPos3List from "./admin/components/ads/AdsPos3List"
import AdminAdsPos4List from "./admin/components/ads/AdsPos4List"
import AdminAdsPos6List from "./admin/components/ads/AdsPos6List"
import AdminAdsPos7List from "./admin/components/ads/AdsPos7List"

import AdminAdsPostList from "./admin/components/ads/AdsPostList"
import AdminAdsShopList from "./admin/components/ads/AdsShopList"

import AdminSettings from "./admin/components/settings/Settings"

const config_routes = [
	{ path: "/", module: <Home /> },
	{ path: "/login", module: <Login /> },
	{ path: "/register", module: <Register /> },

	{ path: "/home2", module: <Home2 /> },

	{ path: "/aboutus", module: <AboutUs /> },
	{ path: "/policy", module: <Policy /> },
	{ path: "/privacy", module: <Privacy /> },
	{ path: "/service1", module: <Service1 /> },
	{ path: "/service2", module: <Service2 /> },
	{ path: "/service3", module: <Service3 /> },
	{ path: "/service4", module: <Service4 /> },

	{ path: "/profile", module: <Profile /> },
	{ path: "/profile/edit", module: <EditProfile /> },
	{ path: "/profile/address", module: <Address /> },
	{ path: "/profile/posts", module: <Posts /> },
	{ path: "/profile/posts/garbage", module: <PostsGabage /> },
	{ path: "/profile/posts/second", module: <PostsSecond /> },
	{ path: "/profile/posts/fix", module: <PostsFix /> },
	{ path: "/profile/posts/airs", module: <PostsAir /> },
	{ path: "/profile/posts/wash", module: <PostsWash /> },
	{ path: "/profile/postdetail/1/:id", module: <PostDetail1 /> },
	{ path: "/profile/postdetail/2/:id", module: <PostDetail2 /> },
	{ path: "/profile/postdetail/3/:id", module: <PostDetail3 /> },
	{ path: "/profile/postdetail/4/:id", module: <PostDetail4 /> },
	{ path: "/profile/cleandetail/1/:id", module: <PostDetailAir /> },
	{ path: "/profile/cleandetail/2/:id", module: <PostDetailWash /> },
	{ path: "/profile/offers", module: <Offers /> },
	{ path: "/profile/appointments", module: <Appointments /> },
	{ path: "/profile/appointmentposts", module: <AppointmentPosts /> },
	{ path: "/profile/appointments/close", module: <AppointmentsClose /> },
	{ path: "/profile/appointments/cancle", module: <AppointmentsCancle /> },
	{ path: "/profile/notifications", module: <Notifications /> },
	{ path: "/profile/coins", module: <MemberCoins /> },

	{ path: "/post/:id", module: <PostDetail /> },
	{ path: "/post/edit/throw/:id", module: <EditPostThrow /> },
	{ path: "/post/edit/gabage/:id", module: <EditPostGabage /> },
	{ path: "/post/edit/second/:id", module: <EditPostSecond /> },
	{ path: "/postFix/:id", module: <PostFix /> },
	{ path: "/post/edit/fix/:id", module: <EditPostFix /> },
	{ path: "/postclean/:id", module: <PostClean /> },
	{ path: "/post/offer/air/:id", module: <OfferAir /> },
	{ path: "/postwash/:id", module: <PostWash /> },
	{ path: "/posts/onboard", module: <PostOnboard /> },
	{ path: "/posts/throw", module: <AddThrowPost /> },
	{ path: "/posts/gabage", module: <AddSellGabagePost /> },
	{ path: "/posts/second", module: <AddSellSecondPost /> },
	{ path: "/posts/fix", module: <AddPostFix /> },
	{ path: "/posts/board1", module: <PostsBoard1 /> },
	{ path: "/posts/board/:board", module: <PostBoardList /> },
	{ path: "/posts/boardfix", module: <FixBoardList /> },
	{ path: "/posts/boardair", module: <AirBoardList /> },
	{ path: "/posts/boardwash", module: <WashBoardList /> },
	{ path: "/clean/air", module: <AddCleanAir /> },
	{ path: "/clean/wash", module: <AddCleanWashMac /> },
	{ path: "/post/edit/air/:id", module: <EditPostAir /> },
	{ path: "/post/edit/wash/:id", module: <EditPostWash /> },

	{ path: "/postlist/1/:id", module: <MemberListBoard1 /> },
	{ path: "/postlist/2/:id", module: <MemberListBoard2 /> },
	{ path: "/postlist/3/:id", module: <MemberListBoard3 /> },
	{ path: "/postlist/4/:id", module: <MemberListBoard4 /> },
	{ path: "/postlist/air/:id", module: <MemberListBoardAir /> },
	{ path: "/postlist/wash/:id", module: <MemberListBoardWash /> },

	{ path: "/shop", module: <ShopProfile /> },
	{ path: "/shop/register", module: <ShopRegister /> },
	{ path: "/shop/edit/:id", module: <ShopEdit /> },
	{ path: "/shop/offers", module: <ShopOffers /> },
	{ path: "/shop/offers/confirm", module: <ShopOffersConfirm /> },
	{ path: "/shop/postdetail/1/:id/:shopId", module: <ShopPostDetail1 /> },
	{ path: "/shop/postdetail/2/:id/:shopId", module: <ShopPostDetail2 /> },
	{ path: "/shop/postdetail/3/:id/:shopId", module: <ShopPostDetail3 /> },
	{ path: "/shop/postdetail/4/:id/:shopId", module: <ShopPostDetail4 /> },
	{ path: "/shop/postcleans/1/:id/:shopId", module: <ShopPostDetailAir /> },
	{ path: "/shop/postcleans/2/:id/:shopId", module: <ShopPostDetailWash /> },
	{ path: "/shop/notifications/:id", module: <ShopNotifications /> },
	{ path: "/shop/technicians/:id", module: <ShopTech /> },
	{ path: "/shop/appointments", module: <ShopAppointment /> },

	{ path: "/shop/ads/:shopId", module: <ShopAds /> },

	{ path: "/technician/view/:shopId/:techId", module: <Technician /> },
	{ path: "/technician/add/:shopId", module: <AddTechnician /> },
	{ path: "/technician/edit/:shopId/:techId", module: <EditTechnician /> },

	{ path: "/shop/finances/:shopId", module: <Finances /> },
	{ path: "/shop/postonboard/:shopId", module: <ShopAddSecond /> },
	{ path: "/shop/posts/:shopId", module: <ShopPosts /> },
	{ path: "/shop/appointmentposts/:shopId", module: <ShopAppointmentPost /> },
	{ path: "/shop/post/edit/:id/", module: <ShopEditPosts /> },
	{ path: "/shop/post/:id/", module: <ShopPost /> },
	{ path: "/shop/spares/:shopId", module: <ShopSpares /> },
	{ path: "/shop/coins/:id", module: <ShopCoins /> },
	{ path: "/shop/history/:id", module: <ShopOfferHistory /> },
]

const config_admin_routes = [
	{ path: "/admin/dashboard", module: <AdminHome /> },
	{ path: "/admin/members", module: <AdminMembers /> },
	{ path: "/admin/member/view/:id", module: <AdminMember /> },
	{ path: "/admin/member/edit/:id", module: <AdminEditMember /> },
	{ path: "/admin/member/add", module: <AdminAddMember /> },
	{ path: "/admin/member/posts/:id", module: <AdminMemberPosts /> },

	{ path: "/admin/posts", module: <AdminPosts /> },
	{ path: "/admin/posts/throw", module: <AdminPostsThrow /> },
	{ path: "/admin/posts/garbage", module: <AdminPostsGarbage /> },
	{ path: "/admin/posts/second", module: <AdminPostsSecond /> },
	{ path: "/admin/postlist/1", module: <AdminBoard1Posts /> },
	{ path: "/admin/postlist/2", module: <AdminBoard2Posts /> },
	{ path: "/admin/postlist/3", module: <AdminBoard3Posts /> },
	{ path: "/admin/postlist/4", module: <AdminBoard4Posts /> },
	{ path: "/admin/cleanlist/1", module: <AdminBoardAirPosts /> },

	{ path: "/admin/post/view/:id", module: <AdminPost /> },
	{ path: "/admin/post/detail/1/:id", module: <AdminPostBoard1Detail /> },
	{ path: "/admin/post/detail/2/:id", module: <AdminPostBoard2Detail /> },
	{ path: "/admin/post/detail/3/:id", module: <AdminPostBoard3Detail /> },
	{ path: "/admin/post/detail/4/:id", module: <AdminPostBoard4Detail /> },
	{ path: "/admin/clean/detail/1/:id", module: <AdminPostBoardAirDetail /> },

	{ path: "/admin/shops", module: <AdminShops /> },
	{ path: "/admin/shop/add", module: <AdminAddShop /> },
	{ path: "/admin/shop/addByMember/:id", module: <AdminAddShopByUser /> },
	{ path: "/admin/shop/view/:id", module: <AdminShop /> },
	{ path: "/admin/shop/edit/:id", module: <AdminEditShop /> },

	{ path: "/admin/shoptech/:id", module: <AdminShopTech /> },
	{ path: "/admin/technician/add/:id", module: <AdminAddTech /> },
	{ path: "/admin/technician/view/:shopId/:techId", module: <AdminTechnician /> },
	{ path: "/admin/technician/edit/:shopId/:techId", module: <AdminEditTech /> },

	{ path: "/admin/coins", module: <AdminCoins /> },
	{ path: "/admin/coins/free", module: <AdminFreeCoins /> },
	{ path: "/admin/coins/exchange", module: <AdminExchangeCoins /> },
	{ path: "/admin/exchange", module: <AdminExchanges /> },

	{ path: "/admin/users", module: <AdminUsers /> },
	{ path: "/admin/user/add", module: <AdminAddUser /> },
	{ path: "/admin/user/view/:id", module: <AdminViewUser /> },
	{ path: "/admin/user/edit/:id", module: <AdminEditUser /> },

	{ path: "/admin/electric", module: <AdminElectrics /> },
	{ path: "/admin/broken", module: <AdminBrokens /> },
	{ path: "/admin/brand", module: <AdminBrands /> },
	{ path: "/admin/airbrand", module: <AdminAirBrands /> },
	{ path: "/admin/washbrand", module: <AdminWashBrands /> },

	{ path: '/admin/ads', module: <AdminAds /> },
	{ path: '/admin/ads/list/1', module: <AdminAdsPos1List /> },
	{ path: '/admin/ads/list/2', module: <AdminAdsPos2List /> },
	{ path: '/admin/ads/list/3', module: <AdminAdsPos3List /> },
	{ path: '/admin/ads/list/4', module: <AdminAdsPos4List /> },
	{ path: '/admin/ads/list/6', module: <AdminAdsPos6List /> },
	{ path: '/admin/ads/list/7', module: <AdminAdsPos7List /> },

	{ path: '/admin/ads/post/:id', module: <AdminAdsPostList /> },
	{ path: '/admin/ads/shop/:id', module: <AdminAdsShopList /> },

	{ path: "admin/reports", module: <AdminReports /> },
	{ path: "admin/reports/user", module: <AdminUserReports /> },

	{ path: "admin/texts", module: <AdminTexts /> },

	{ path: '/admin/settings', module: <AdminSettings /> },
]

function App() {
	return (
		<Router>
			<Routes>
				{/* <Route exact path="/importelecbrand" element={
					<>
						<div style={{ display: 'flex', flexDirection: 'column', minHeight: 500, paddingTop: '3.5rem' }}>
							<main className=""><ImportElecBrand /></main>
						</div>
					</>
				} />
				<Route exact path="/importelecmodel" element={
					<>
						<div style={{ display: 'flex', flexDirection: 'column', minHeight: 500, paddingTop: '3.5rem' }}>
							<main className=""><ImportElecModel /></main>
						</div>
					</>
				} />
				<Route exact path="/importairmodel" element={
					<>
						<div style={{ display: 'flex', flexDirection: 'column', minHeight: 500, paddingTop: '3.5rem' }}>
							<main className=""><ImportAirModel /></main>
						</div>
					</>
				} /> */}
				{/* <Route exact path="/import" element={
					<>
						<div style={{ display: 'flex', flexDirection: 'column', minHeight: 500, paddingTop: '3.5rem' }}>
							<main className=""><ImportExcel /></main>
						</div>
					</>
				} /> */}
				{/* Sites */}
				{config_routes.map((route, i) => {
						return (
							<Route exact key={"s"+i} path={route.path} element={
								<>
									<Header />
									<div style={{ display: 'flex', flexDirection: 'column', minHeight: 500, paddingTop: '7rem' }}>
										<main className="">{route.module}</main>
									</div>
									<Footer />
								</>
							} />
						)
				})}
				{/* Admin */}
				<Route exact path="/admin" element={<AdminLogin />} />
				<Route exact path="/admin/register" element={<AdminRegister />} />
				<Route exact path="/admin/reset" element={<AdminReset />} />
				{config_admin_routes.map((route, i) => {
						return (
							<Route exact key={"a"+i} path={route.path} element={
								<>
									<AdminHeader />
									<div style={{ display: 'flex', flexDirection: 'row', minHeight: '70rem', paddingTop: '3.5rem' }}>
										<AdminLeftMenu />
										<main className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>{route.module}</main>
									</div>
									<AdminFooter />
								</>
							} />
						)
				})}
				{/* Other */}
				<Route path="*" element={<Under404 />} />
			</Routes>
		</Router>
	);
}

export default App;
