import { getDoc, updateDoc, doc } from 'firebase/firestore'

export function calScoreShop(db, shopId, score) {
    getDoc(doc(db, `/shops/`, shopId)).then((shopDoc) => {
        var totalScore = 0
        var numScore = 0
        var averageScore = 0
        if(shopDoc.data().score_total) { 
            totalScore = (shopDoc.data().score_total * 1) + (score * 1)
        } else {
            totalScore = (score * 1)
        }
        if(shopDoc.data().score_num) { 
            numScore = (shopDoc.data().score_num * 1) + 1
        } else {
            numScore = 1
        }
        averageScore = totalScore / numScore

        updateDoc(doc(db, `/shops/`, shopId), { 
            score_total: totalScore,
            score_num: numScore,
            score_average: averageScore
        })
    })
}

export function calScoreUser(db, userId, score) {
    getDoc(doc(db, `/users/`, userId)).then((userDoc) => {
        var totalScore = 0
        var numScore = 0
        var averageScore = 0
        if(userDoc.data().score_total) { 
            totalScore = (userDoc.data().score_total * 1) + (score * 1)
        } else {
            totalScore = (score * 1)
        }
        if(userDoc.data().score_num) { 
            numScore = (userDoc.data().score_num * 1) + 1
        } else {
            numScore = 1
        }
        averageScore = totalScore / numScore

        updateDoc(doc(db, `/users/`, userId), { 
            score_total: totalScore,
            score_num: numScore,
            score_average: averageScore
        })
    })
}