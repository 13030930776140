import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, getDoc, addDoc, updateDoc, doc } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { getBrand, getModel } from '../helper/BrandHelper'
import { getElectric } from '../helper/ElectricTypeHelper'
import { calScoreUser } from '../../../assets/js/ScoreHelper'
import { Loading } from "../layouts/Loading"

export function AppointmentShopBoard1({appointment}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [user, loading, error] = useAuthState(auth)
    const [showLoading, setShowLoading] = useState(false)

    const [openCancle, setOpenCancle] = useState(false)
    const [openFinish, setOpenFinish] = useState(false)

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState('')

    const [type, setType] = useState('ไม่ระบุ')
    const [brand, setBrand] = useState('ไม่ระบุ')
    const [model, setModel] = useState('ไม่ระบุ')

    var badge = { message: '', class: 'success', color: '#000000', show: false }
    if(appointment.data.status == 1) {
        badge = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
    } else if(appointment.data.status == 2) {
        badge = { message: 'ผู้ประกาศยืนยันให้ซาก', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data.status == 3) {
        badge = { message: 'ร้านค้ายืนยันได้รับซาก', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data.status == 4) {
        badge = { message: 'ผู้ประกาศยกเลิกให้ซาก', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data.status == 5) {
        badge = { message: 'ร้านค้ายกเลิกรับซาก', class: 'danger', color: '#ffffff', show: true }
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleOpenFinish = () => {
        setOpenFinish(true)
    }

    const handleCloseFinish = () => {
        setOpenFinish(false)
    }

    const handleSubmitFinish = () => {
        setShowLoading(true)
        const logDate = new Date()
        const userId = appointment.data.user_id
        const postId = appointment.data.post_id
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointment.id), { 
            status: 3,
            message_for_user: message,
            score_for_user: score,
            shop_finish_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        var updatePostTask = updateDoc(doc(db, `/posts/`, postId), { 
            shop_finish_date: logDate.getTime()
        })
        promisesTask.push(updatePostTask)

        // User ได้รับแต้มสะสม
        var weePoint = 100
        var addWeePointTask = addDoc(collection(db, `/users/${userId}/points`), {
            datetime: logDate.getTime(),
            point: weePoint,
            post_id: postId,
            appointment_id: appointment.id
        })
        promisesTask.push(addWeePointTask)

        var addNotiUserTask = addDoc(collection(db, `/users/${userId}/notifications`), {
            date: logDate.getTime(),
            message: "คุณได้รับแต้มสะสมจากการขายซาก จำนวน "+number_format.format(weePoint)+" แต้ม",
            link: '/post/'+postId,
            type: 'point',
            status: 0
        })
        promisesTask.push(addNotiUserTask)

        calScoreUser(db, userId, score)
        
        Promise.all(promisesTask).then(() => {
            handleCloseFinish()
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleOpenCancle = () => {
        setOpenCancle(true)
    }

    const handleCloseCancle = () => {
        setOpenCancle(false)
    }

    const handleSubmitCancle = () => {
        setShowLoading(true)
        const logDate = new Date()
        const shopId = appointment.data.shop_id
        const postId = appointment.data.post_id
        const shopOfferId = appointment.data.shop_offer_id
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointment.id), { 
            status: 5,
            cancle_message: message,
            cancle_reason: reason,
            cancle_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        var updatePostTask = updateDoc(doc(db, `/posts/`, postId), { status: 1 })
        promisesTask.push(updatePostTask)
        
        var updateShopOfferTask = updateDoc(doc(db, `/shops/${shopId}/offers`, shopOfferId), { status: 5 })
        promisesTask.push(updateShopOfferTask)
        
        var addCancleHistoryTask = addDoc(collection(db, `posts/${postId}/cancle_offers/`), {
            shop_id: shopId,
            cancle_message: message,
            cancle_reason: reason,
            cancle_datetime: logDate.getTime()
        })
        promisesTask.push(addCancleHistoryTask)

        var addBlackListTask = addDoc(collection(db, `posts/${postId}/shop_blacklist/`), {
            shop_id: shopId,
        })
        promisesTask.push(addBlackListTask)

        var addShopBlackListTask = addDoc(collection(db, `shop_blacklist/`), {
            shop_id: shopId,
            post_id: postId,
            event: "ยกเลิกนัดหมาย",
            blacklist_datetime: logDate.getTime(),
            blacklist_status: 1
        })
        promisesTask.push(addShopBlackListTask)

        Promise.all(promisesTask).then(() => {
            handleCloseCancle()
            setShowLoading(false)
            navigate(0)
        })
    }

    useEffect(() => {
        getBrand(db, appointment.post.brand_id, appointment.post.brand).then((brandName) => {
            setBrand(brandName)
        })
    
        getModel(db, appointment.post.model_id, appointment.post.model).then((modelName) => {
            setModel(modelName)
        })
        getElectric(db, appointment.post.type_id, appointment.post.other_type).then((typeName) => {
            setType(typeName)
        })
    }, [loading])

    return (
        <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            {badge.show && (
            <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                <button type="button" className={"btn "+"btn-"+badge.class} style={{ color: badge.color }}><strong>{badge.message}</strong></button>
            </div>
            )}
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                <img src={appointment.post.picture} alt="WEEE" height="150" />
            </div>
            <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                <p className="card-text">
                    <strong>ประเภทนัดหมาย:</strong> ทิ้งซาก<br/>
                    <strong>{type} {brand} {model}</strong><br/>
                    <><strong>วันนัดหมาย:</strong> {dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/></>
                    <><strong>ที่อยู่:</strong> {appointment.post.address} <br/></>
                    {(appointment.data.status == 3) && (<>
                        <strong>คะแนน:</strong> {appointment.data.score} <br/>
                        <strong>คำติชม:</strong> {appointment.data.finish_message} <br/>
                    </>)}
                </p>
            </div>
            <div className="card-body">
                <Link className="btn btn-primary m-1" to={`/post/${appointment.data.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                <Link className="btn btn-primary m-1" to={`/shop/postdetail/${appointment.post.onboard}/${appointment.data.post_id}/${appointment.data.shop_id}`} target='_blank'><strong>ดูนัดหมายและข้อเสนอ</strong></Link>
                {(appointment.data.status == 2) && (<>
                    <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenFinish() }}>
                        <strong>ยืนยันการรับซาก</strong>
                    </button>
                </>)}
                {(appointment.data.status == 1 || appointment.data.status == 2) && (<>
                    <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCancle() }}>
                        <strong>ยกเลิกการรับซาก</strong>
                    </button>
                </>)}
            </div>

            <Dialog open={openFinish} onClose={handleCloseFinish} fullWidth={true} maxWidth='sm'>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันได้รับซาก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>คำติชมเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='คำติชมเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>คะแนน</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                            <select name='score' onChange={(e) => setScore(e.target.value)} style={{ flex: 1 }}>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseFinish} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleSubmitFinish} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันได้รับซาก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancle} onClose={handleCloseCancle} fullWidth={true} maxWidth='sm'>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการเข้ารับซาก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex'}}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ display: 'flex', flex: 1, width: 250 }}>
                                                <option value="1">ไม่เจอผู้ประกาศ</option>
                                                <option value="2">มีการเรียกเก็บเงินเพิ่ม</option>
                                                <option value="3">ซากฯไม่ตรงกับที่ประกาศ</option>
                                                <option value="4">ให้นำสิ่งอื่นที่นอกเหนือจากประกาศไปทิ้งด้วย</option>
                                                <option value="5">ความพร้อมหรือความน่าเชื่อถือของผู้ประกาศ</option>
                                                <option value="6">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancle} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิด</button>
                    <button onClick={handleSubmitCancle} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกเข้ารับซาก</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}