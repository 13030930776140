import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, doc, getDoc, onSnapshot, addDoc, updateDoc, orderBy } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom"

import TextField from '@mui/material/TextField'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import { TbEdit } from "react-icons/tb"
import { AiFillInfoCircle } from "react-icons/ai"
import { MdAttachMoney } from "react-icons/md"
import { HiArrowDownTray, HiArrowUpTray } from "react-icons/hi2"
import LeftMenu from './LeftMenu'

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Finances = () => {
    const params = useParams()
    const shopId = params.shopId
    const [shop, setShop] = useState([])
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState()
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [openDrawer, setOpenDrawer] = useState(false)

    const [incomes, setIncomes] = useState([])
    const [outcomes, setOutcomes] = useState([])

    const [selectIncome, setSelectIncome] = useState("")
    const [selectOutcome, setSelectOutcome] = useState("")

    const [outcomeDateErrorDiv, setOutcomeDateErrorDiv] = useState(false)
    const [outcomeAmountErrorDiv, setOutcomeAmountErrorDiv] = useState(false)

    const [incomeDateErrorDiv, setIncomeDateErrorDiv] = useState(false)
    const [incomeAmountErrorDiv, setIncomeAmountErrorDiv] = useState(false)

    const [openAddOutcome, setOpenAddOutcome] = useState(false)
    const [outcomeType, setOutcomeType] = useState(1)
    const [outcomeAmount, setOutcomeAmount] = useState(0)
    const [outcomeDate, setOutcomeDate] = useState("")
    const [outcomeDescription, setOutcomeDescription] = useState("")

    const [openEditOutcome, setOpenEditOutcome] = useState(false)
    const [editOutcomeType, setEditOutcomeType] = useState(1)
    const [editOutcomeAmount, setEditOutcomeAmount] = useState(0)
    const [editOutcomeDate, setEditOutcomeDate] = useState("")
    const [editOutcomeDescription, setEditOutcomeDescription] = useState("")

    const [openViewOutcome, setOpenViewOutcome] = useState(false)
    const [viewOutcomeType, setViewOutcomeType] = useState(1)
    const [viewOutcomeAmount, setViewOutcomeAmount] = useState(0)
    const [viewOutcomeDate, setViewOutcomeDate] = useState("")
    const [viewOutcomeDescription, setViewOutcomeDescription] = useState("")

    const [openAddIncome, setOpenAddIncome] = useState(false)
    const [incomeType, setIncomeType] = useState(1)
    const [incomeAmount, setIncomeAmount] = useState(0)
    const [incomeDate, setIncomeDate] = useState("")
    const [incomeDescription, setIncomeDescription] = useState("")

    const [openEditIncome, setOpenEditIncome] = useState(false)
    const [editIncomeType, setEditIncomeType] = useState(1)
    const [editIncomeAmount, setEditIncomeAmount] = useState(0)
    const [editIncomeDate, setEditIncomeDate] = useState("")
    const [editIncomeDescription, setEditIncomeDescription] = useState("")

    const [openViewIncome, setOpenViewIncome] = useState(false)
    const [viewIncomeType, setViewIncomeType] = useState(1)
    const [viewIncomeAmount, setViewIncomeAmount] = useState(0)
    const [viewIncomeDate, setViewIncomeDate] = useState("")
    const [viewIncomeDescription, setViewIncomeDescription] = useState("")

    const [loading, error] = useAuthState(auth)
    
    const dateFormatEdit = (datetime) => {
        const date_string = new Date(datetime.seconds * 1000).toLocaleDateString('en-US')
        const date_split = date_string.split('/')
        return date_split[2]+'-'+date_split[0]+'-'+date_split[1]
    }
 
    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const validateOutcomeForm = (event) => {
        if(event == "add") {
            if(outcomeAmount == 0) {
                setOutcomeAmountErrorDiv(true)
                return false
            }
            if(outcomeDate == "") {
                setOutcomeDateErrorDiv(true)
                return false
            }
        }
        if(event == "edit") {
            if(editOutcomeAmount == 0) {
                setOutcomeAmountErrorDiv(true)
                return false
            }
            if(editOutcomeDate == "") {
                setOutcomeDateErrorDiv(true)
                return false
            }
        }
        return true;
    }

    const handleAddOutcome = () => {
        if(validateOutcomeForm("add")) {
            const new_date = new Date()
            addDoc(collection(db, `shops/${shopId}/finances/outcomes/transactions/`), {
                outcome_type: outcomeType,
                outcome_amount: outcomeAmount,
                outcome_date: outcomeDate.toDate(),
                outcome_description: outcomeDescription,
                date: new_date.getTime(),
                status: 1
            }).then(() => {
                setOutcomeAmountErrorDiv(false)
                setOutcomeDateErrorDiv(false)
                handleCloseAddOutcome()
            }).catch((error) => {
                alert(error)
            })
        }
    }

    const handleOpenAddOutcome = () => {
        setOutcomeAmountErrorDiv(false)
        setOutcomeDateErrorDiv(false)
        setOpenAddOutcome(true)
    }

    const handleCloseAddOutcome = () => {
        setOpenAddOutcome(false)
    }

    const handleEditOutcome = () => {
        if(validateOutcomeForm("edit")) {
            const new_date = new Date()
            updateDoc(doc(db, `shops/${shopId}/finances/outcomes/transactions/`, selectOutcome), {
                outcome_type: editOutcomeType,
                outcome_amount: editOutcomeAmount,
                outcome_date: editOutcomeDate.toDate(),
                outcome_description: editOutcomeDescription,
                date: new_date.getTime(),
                status: 1
            }).then(() => {
                setOutcomeAmountErrorDiv(false)
                setOutcomeDateErrorDiv(false)
                handleCloseEditOutcome()
            }).catch((error) => {
                alert(error)
            })
        }
    }

    const handleOpenEditOutcome = (outcomeId) => {
        setOutcomeAmountErrorDiv(false)
        setOutcomeDateErrorDiv(false)
        setSelectOutcome(outcomeId)
        getDoc(doc(db, `shops/${shopId}/finances/outcomes/transactions/`, outcomeId)).then((outcomeSnapshot) => {
            const data = outcomeSnapshot.data()
            setEditOutcomeType(data.outcome_type)
            setEditOutcomeDate(dayjs(dateFormatEdit(data.outcome_date)))
            setEditOutcomeAmount(data.outcome_amount)
            setEditOutcomeDescription(data.outcome_description)
        }).then(() => {
            setOpenEditOutcome(true)
        })
    }

    const handleCloseEditOutcome = () => {
        setOpenEditOutcome(false)
    }

    const handleOpenViewOutcome = (outcomeId) => {
        setSelectOutcome(outcomeId)
        getDoc(doc(db, `shops/${shopId}/finances/outcomes/transactions/`, outcomeId)).then((outcomeSnapshot) => {
            const data = outcomeSnapshot.data()
            setViewOutcomeType(data.outcome_type)
            setViewOutcomeDate(dateFormatEdit(data.outcome_date))
            setViewOutcomeAmount(data.outcome_amount)
            setViewOutcomeDescription(data.outcome_description)
        }).then(() => {
            setOpenViewOutcome(true)
        })
    }

    const handleCloseViewOutcome = () => {
        setOpenViewOutcome(false)
    }

    const validateIncomeForm = (event) => {
        if(event == "add") {
            if(incomeAmount == 0) {
                setIncomeAmountErrorDiv(true)
                return false
            }
            if(incomeDate == "") {
                setIncomeDateErrorDiv(true)
                return false
            }
        }
        if(event == "edit") {
            if(editIncomeAmount == 0) {
                setIncomeAmountErrorDiv(true)
                return false
            }
            if(editIncomeDate == "") {
                setIncomeDateErrorDiv(true)
                return false
            }
        }
        return true;
    }

    const handleAddIncome = () => {
        if(validateIncomeForm("add")) {
            const new_date = new Date()
            addDoc(collection(db, `shops/${shopId}/finances/incomes/transactions/`), {
                income_type: incomeType,
                income_amount: incomeAmount,
                income_date: incomeDate.toDate(),
                income_description: incomeDescription,
                date: new_date.getTime(),
                status: 1
            }).then(() => {
                setIncomeAmountErrorDiv(false)
                setIncomeDateErrorDiv(false)
                handleCloseAddIncome()
            }).catch((error) => {
                alert(error)
            })
        }
    }

    const handleOpenAddIncome = () => {
        setIncomeAmountErrorDiv(false)
        setIncomeDateErrorDiv(false)
        setOpenAddIncome(true)
    }

    const handleCloseAddIncome = () => {
        setOpenAddIncome(false)
    }

    const handleEditIncome = () => {
        if(validateIncomeForm("edit")) {
            const new_date = new Date()
            console.log(editIncomeDate)
            updateDoc(doc(db, `shops/${shopId}/finances/incomes/transactions/`, selectIncome), {
                income_type: editIncomeType,
                income_amount: editIncomeAmount,
                income_date: editIncomeDate.toDate(),
                income_description: editIncomeDescription,
                date: new_date.getTime(),
                status: 1
            }).then(() => {
                setIncomeAmountErrorDiv(false)
                setIncomeDateErrorDiv(false)
                handleCloseEditIncome()
            }).catch((error) => {
                alert(error)
            })
        }
    }

    const handleOpenEditIncome = (incomeId) => {
        setOutcomeAmountErrorDiv(false)
        setOutcomeDateErrorDiv(false)
        setSelectIncome(incomeId)
        getDoc(doc(db, `shops/${shopId}/finances/incomes/transactions/`, incomeId)).then((incomeSnapshot) => {
            const data = incomeSnapshot.data()
            setEditIncomeType(data.income_type)
            setEditIncomeDate(dayjs(dateFormatEdit(data.income_date)))
            setEditIncomeAmount(data.income_amount)
            setEditIncomeDescription(data.income_description)
        }).then(() => {
            setOpenEditIncome(true)
        })
    }

    const handleCloseEditIncome = () => {
        setOpenEditIncome(false)
    }

    const handleOpenViewIncome = (incomeId) => {
        setSelectIncome(incomeId)
        getDoc(doc(db, `shops/${shopId}/finances/incomes/transactions/`, incomeId)).then((incomeSnapshot) => {
            const data = incomeSnapshot.data()
            setViewIncomeType(data.income_type)
            setViewIncomeDate(dateFormatEdit(data.income_date))
            setViewIncomeAmount(data.income_amount)
            setViewIncomeDescription(data.income_description)
        }).then(() => {
            setOpenViewIncome(true)
        })
    }

    const handleCloseViewIncome = () => {
        setOpenViewIncome(false)
    }

    const getDocShop = async(id) => {
        await getDoc(doc(db, 'shops', id)).then((shopSnapshot) => {
            setShop(shopSnapshot.data());
            if(shopSnapshot.data().user_id) {
                setMemberId(shopSnapshot.data().user_id)
                getDoc(doc(db, 'users', shopSnapshot.data().user_id)).then((memberDoc) => {
                    setMember(memberDoc.data())
                })
            }
        }).then(() => {
            const outcomesSnapshot = query(collection(db, `shops/${shopId}/finances/outcomes/transactions/`), orderBy('outcome_date', 'desc'))
            onSnapshot(outcomesSnapshot, (querySnapshot) => {
                setOutcomes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
            const incomesSnapshot = query(collection(db, `shops/${shopId}/finances/incomes/transactions/`), orderBy('income_date', 'desc'))
            onSnapshot(incomesSnapshot, (querySnapshot) => {
                setIncomes(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        getDocShop(shopId)
    },[loading])

return (
    <>
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="finances" isShop={true} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ข้อมูลรายรับ-รายจ่าย</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="finances" isShop={true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingTop: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 30 }}>
                            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                                <h4 style={{ display: 'flex', flex: 1}}><MdAttachMoney style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                <b>รายรับ-รายจ่าย</b></h4>
                            </div>
                            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                                <h5 style={{ display: 'flex', flex: 1}}>
                                    <b style={{ marginTop: 3 }}>เดือน</b>
                                    <select name='selectedMonth' style={{ marginLeft: 10, height: 28, fontSize: 15, marginRight: 15 }}>
                                        <option value="1">มกราคม</option>
                                        <option value="2">กุมภาพันธ์</option>
                                        <option value="3">มีนาคม</option>
                                        <option value="4">เมษายน</option>
                                        <option value="5">พฤษภาคม</option>
                                        <option value="6">มิถุนายน</option>
                                        <option value="7">กรกฎาคม</option>
                                        <option value="8">สิงหาคม</option>
                                        <option value="9">กันยายน</option>
                                        <option value="10">ตุลาคม</option>
                                        <option value="11">พฤศจิกายน</option>
                                        <option value="12">ธันวาคม</option>
                                    </select>
                                    <b style={{ marginTop: 3 }}>ปี</b>
                                    <select name='selectedYear' style={{ marginLeft: 10, height: 28, fontSize: 15, marginRight: 15 }}>
                                        <option value="2566">2566</option>
                                        <option value="2567">2567</option>
                                        <option value="2568">2568</option>
                                        <option value="2569">2569</option>
                                        <option value="2570">2570</option>
                                    </select>
                                </h5>
                            </div>
                            <div className="flex-container" style={{ display: 'flex' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <div style={{ paddingTop: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row' }}>
                                        <h6 style={{ display: 'flex', flex: 1, marginTop: 5}}><HiArrowDownTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>รายรับ</b></h6>
                                        <div style={{ float: 'right',  }}>
                                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenAddIncome() }}>
                                                <HiArrowDownTray style={{ fontSize: 20, marginRight: 5 }} />เพิ่มรายรับ
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ }}>
                                        <table className="table" style={{  }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: '90%'}}>ข้อมูลรายรับ</th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                incomes?.map((income, i) => (
                                                    <tr key={i}>
                                                        <td style={{ paddingLeft: 10 }}> </td>
                                                        <th>
                                                                <font style={{ marginRight: 10, fontSize: 13, fontWeight: '400' }}>{dateFormatDate(income.data.income_date)}</font><br/>
                                                                <font style={{ marginRight: 10}}>
                                                                    { income.data.income_type==1&&"รายรับจากแพลตฟอร์ม" }
                                                                    { income.data.income_type==2&&"รายรับนอกเหนือจากแพลตฟอร์ม" }
                                                                </font>
                                                                <font style={{ marginRight: 10}}>{number_format.format(income.data.income_amount)} บาท</font>
                                                        </th>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                                                <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex', float:'right', marginBottom: 5, whiteSpace: 'nowrap' }} onClick={() => { handleOpenViewIncome(income.id) }} ><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูล</button>
                                                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex', float:'right', whiteSpace: 'nowrap' }} onClick={() => { handleOpenEditIncome(income.id) }} ><TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                                <div className="d-none d-md-block d-lg-block" style={{ minWidth: 10, marginLeft: 10, borderLeft: '1px solid #cccccc' }}>&nbsp;</div>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <div style={{ paddingTop: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row' }}>
                                        <h6 style={{ display: 'flex', flex: 1, marginTop: 5}}><HiArrowUpTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                        <b>รายจ่าย</b></h6>
                                        <div style={{ float: 'right',  }}>
                                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenAddOutcome() }}>
                                                <HiArrowUpTray style={{ fontSize: 20, marginRight: 5 }} />เพิ่มรายจ่าย
                                            </button>
                                        </div>
                                    </div>
                                    <div style={{ }}>
                                        <table className="table" style={{  }}>
                                            <thead>
                                                <tr>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: '90%'}}>ข้อมูลรายจ่าย</th>
                                                    <th scope="col" style={{ backgroundColor: '#eeeeee' }}> </th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                outcomes?.map((outcome, i) => (
                                                    <tr key={i}>
                                                        <td style={{ paddingLeft: 10 }}> </td>
                                                        <th>
                                                                <font style={{ marginRight: 10, fontSize: 13 }}>{dateFormatDate(outcome.data.outcome_date)}</font><br/>
                                                                <font style={{ marginRight: 10}}>
                                                                    { outcome.data.outcome_type==1&&"จ่ายเงินเดือน" }
                                                                    { outcome.data.outcome_type==2&&"ซื้อเครื่องมือ/อุปกรณ์" }
                                                                    { outcome.data.outcome_type==3&&"ซื้ออะไหล่" }
                                                                    { outcome.data.outcome_type==4&&"ค่าน้ำ" }
                                                                    { outcome.data.outcome_type==5&&"ค่าไฟ" }
                                                                    { outcome.data.outcome_type==6&&"ค่าเช่า" }
                                                                    { outcome.data.outcome_type==7&&"อื่นๆ" }
                                                                </font>
                                                                <font style={{ marginRight: 10}}>{number_format.format(outcome.data.outcome_amount)} บาท</font>
                                                        </th>
                                                        <td>
                                                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                                                <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex', float:'right', marginBottom: 5, whiteSpace: 'nowrap' }} onClick={() => { handleOpenViewOutcome(outcome.id) }} ><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูล</button>
                                                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex', float:'right', whiteSpace: 'nowrap' }} onClick={() => { handleOpenEditOutcome(outcome.id) }} ><TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข</button>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={openAddOutcome} onClose={handleCloseAddOutcome}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowUpTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />เพิ่มรายจ่าย</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div><strong>ประเภทรายจ่าย</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <select name='outcomeType' onChange={(e) => setOutcomeType(e.target.value)} style={{ marginLeft: 10, height: 35, fontSize: 15, marginRight: 15 }}>
                                    <option value="1">จ่ายเงินเดือน</option>
                                    <option value="2">ซื้อเครื่องมือ/อุปกรณ์</option>
                                    <option value="3">ซื้ออะไหล่</option>
                                    <option value="4">ค่าน้ำ</option>
                                    <option value="5">ค่าไฟ</option>
                                    <option value="6">ค่าเช่า</option>
                                    <option value="7">อื่นๆ</option>
                                </select>
                            </div>
                            <div style={{ marginTop: 10 }}><strong>วันที่จ่าย</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 10}}>
                                <div style={{ marginLeft: 10, display: 'flex', flex: 1, flexDirection: 'column'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="วันที่จ่าย" format="DD/MM/YYYY" onChange={(value) => setOutcomeDate(value)} />
                                    </LocalizationProvider>
                                </div>
                                {outcomeDateErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกวันที่จ่าย</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>จำนวนเงิน (บาท)</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    variant="standard"
                                    value={outcomeAmount}
                                    onChange={(e) => setOutcomeAmount(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {outcomeAmountErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกจำนวนเงิน</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>รายละเอียดเพิ่มเติม</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <textarea style={{ marginLeft: 10, display: 'flex', flex: 1, height: 100 }}
                                    onChange={(e) => setOutcomeDescription(e.target.value)}
                                    placeholder='รายละเอียดเพิ่มเติม' 
                                    value={outcomeDescription}></textarea>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAddOutcome} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleAddOutcome} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openEditOutcome} onClose={handleCloseEditOutcome}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowUpTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />แก้ไขข้อมูลรายจ่าย</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div><strong>ประเภทรายจ่าย</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <select value={editOutcomeType} name='editOutcomeType' onChange={(e) => setEditOutcomeType(e.target.value)} style={{ marginLeft: 10, height: 35, fontSize: 15, marginRight: 15 }}>
                                    <option value="1">จ่ายเงินเดือน</option>
                                    <option value="2">ซื้อเครื่องมือ/อุปกรณ์</option>
                                    <option value="3">ซื้ออะไหล่</option>
                                    <option value="4">ค่าน้ำ</option>
                                    <option value="5">ค่าไฟ</option>
                                    <option value="6">ค่าเช่า</option>
                                    <option value="7">อื่นๆ</option>
                                </select>
                            </div>
                            <div style={{ marginTop: 10 }}><strong>วันที่จ่าย</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 10}}>
                                <div style={{ marginLeft: 10, display: 'flex', flex: 1, flexDirection: 'column'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="วันที่จ่าย" format="DD/MM/YYYY" defaultValue={editOutcomeDate} onChange={(value) => setEditOutcomeDate(value)} />
                                    </LocalizationProvider>
                                </div>
                                {outcomeDateErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกวันที่จ่าย</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>จำนวนเงิน (บาท)</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    variant="standard"
                                    value={editOutcomeAmount}
                                    onChange={(e) => setEditOutcomeAmount(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {outcomeAmountErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกจำนวนเงิน</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>รายละเอียดเพิ่มเติม</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <textarea style={{ marginLeft: 10, display: 'flex', flex: 1, height: 100 }}
                                    onChange={(e) => setEditOutcomeDescription(e.target.value)}
                                    placeholder='รายละเอียดเพิ่มเติม' 
                                    value={editOutcomeDescription}></textarea>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseEditOutcome} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleEditOutcome} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openViewOutcome} onClose={handleCloseViewOutcome}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowUpTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />ข้อมูลรายจ่าย</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <strong style={{ marginRight: 10 }}>ประเภทรายจ่าย: </strong>
                                {viewOutcomeType=='1'&&"จ่ายเงินเดือน"}
                                {viewOutcomeType=='2'&&"ซื้อเครื่องมือ/อุปกรณ์"}
                                {viewOutcomeType=='3'&&"ซื้ออะไหล่"}
                                {viewOutcomeType=='4'&&"ค่าน้ำ"}
                                {viewOutcomeType=='5'&&"ค่าไฟ"}
                                {viewOutcomeType=='6'&&"ค่าเช่า"}
                                {viewOutcomeType=='7'&&"อื่นๆ"}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>วันที่จ่าย: </strong> {viewOutcomeDate}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>จำนวนเงิน: </strong> {number_format.format(viewOutcomeAmount)} บาท
                            </div>
                            <div style={{ marginTop: 10 }}></div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>รายละเอียดเพิ่มเติม: </strong> {viewOutcomeDescription}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseViewOutcome} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ปิดหน้าต่าง</button>
                </DialogActions>
            </Dialog>
            
            <Dialog open={openAddIncome} onClose={handleCloseAddIncome}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowDownTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />เพิ่มรายรับ</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div><strong>ประเภทรายรับ</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <select name='incomeType' onChange={(e) => setIncomeType(e.target.value)} style={{ marginLeft: 10, height: 35, fontSize: 15, marginRight: 15, maxWidth: 250 }}>
                                    <option value="1">รายรับจากแพลตฟอร์ม</option>
                                    <option value="2">รายรับนอกเหนือจากแพลตฟอร์ม</option>
                                </select>
                            </div>
                            <div style={{ marginTop: 10 }}><strong>วันที่รับ</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ marginLeft: 10, display: 'flex', flex: 1, flexDirection: 'column'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="วันที่รับ" format="DD/MM/YYYY" onChange={(value) => setIncomeDate(value)} />
                                    </LocalizationProvider>
                                </div>
                                {incomeDateErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกวันที่จ่าย</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>จำนวนเงิน (บาท)</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    variant="standard"
                                    value={incomeAmount}
                                    onChange={(e) => setIncomeAmount(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {incomeAmountErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกจำนวนเงิน</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>รายละเอียดเพิ่มเติม</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <textarea style={{ marginLeft: 10, display: 'flex', flex: 1, height: 100 }}
                                    onChange={(e) => setIncomeDescription(e.target.value)}
                                    placeholder='รายละเอียดเพิ่มเติม' 
                                    value={incomeDescription}></textarea>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAddIncome} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleAddIncome} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openEditIncome} onClose={handleCloseEditIncome}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowDownTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />แก้ไขข้อมูลรายรับ</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div><strong>ประเภทรายรับ</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <select name='editIncomeType' value={editIncomeType} onChange={(e) => setEditIncomeType(e.target.value)} style={{ marginLeft: 10, height: 35, fontSize: 15, marginRight: 15 }}>
                                    <option value="1">รายรับจากแพลตฟอร์ม</option>
                                    <option value="2">รายรับนอกเหนือจากแพลตฟอร์ม</option>
                                </select>
                            </div>
                            <div style={{ marginTop: 10 }}><strong>วันที่รับ</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 10}}>
                                <div style={{ marginLeft: 10, display: 'flex', flex: 1, flexDirection: 'column'}}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker label="วันที่รับ" format="DD/MM/YYYY" defaultValue={editIncomeDate} onChange={(value) => setEditIncomeDate(value)} />
                                    </LocalizationProvider>
                                </div>
                                {incomeDateErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกวันที่จ่าย</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>จำนวนเงิน (บาท)</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                <TextField
                                    autoFocus
                                    margin="dense"
                                    type="number"
                                    variant="standard"
                                    value={editIncomeAmount}
                                    onChange={(e) => setEditIncomeAmount(e.target.value)}
                                    style={{ marginLeft: 10, paddingLeft: 5, marginTop: 5 }}
                                />
                                {incomeAmountErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณากรอกจำนวนเงิน</strong>
                                </div>
                                }
                            </div>
                            <div style={{ marginTop: 10 }}><strong>รายละเอียดเพิ่มเติม</strong></div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <textarea style={{ marginLeft: 10, display: 'flex', flex: 1, height: 100 }}
                                    onChange={(e) => setEditIncomeDescription(e.target.value)}
                                    placeholder='รายละเอียดเพิ่มเติม' 
                                    value={editIncomeDescription}></textarea>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseEditIncome} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleEditIncome} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openViewIncome} onClose={handleCloseViewIncome}>
                <DialogTitle><div style={{ fontFamily: 'Prompt', fontWeight: 'bold', borderBottom: '1px solid #cccccc' }}><strong><HiArrowUpTray style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />ข้อมูลรายรับ</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <strong style={{ marginRight: 10 }}>ประเภทรายรับ: </strong>
                                {viewIncomeType=='1'&&"รายรับจากแพลตฟอร์ม"}
                                {viewIncomeType=='2'&&"รายรับนอกเหนือจากแพลตฟอร์ม"}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>วันที่รับ: </strong> {viewIncomeDate}
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>จำนวนเงิน: </strong> {number_format.format(viewIncomeAmount)} บาท
                            </div>
                            <div style={{ marginTop: 10 }}></div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <strong style={{ marginRight: 10 }}>รายละเอียดเพิ่มเติม: </strong> {viewIncomeDescription}
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseViewIncome} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ปิดหน้าต่าง</button>
                </DialogActions>
            </Dialog>
        </div>
    </>
)
}

export default Finances;