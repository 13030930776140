import React from "react"
import "../../assets/css/admin.css";

const Footer = () => {
    return (
        <nav className="navbar navbar-expand-sm navbar-dark" style={{ backgroundColor: "#000000" }}>
            <div className="container-fluid">
                <a className="navbar-brand" href="/admin">Footer</a>
            </div>
		</nav>
    )
}

export default Footer;