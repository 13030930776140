import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, limit, and } from 'firebase/firestore'
import { useNavigate } from "react-router-dom";
import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw } from "react-icons/bi"
import { TbHexagonNumber2, TbSearch } from "react-icons/tb"

const Posts = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)

    const [uid, setUid] = useState('')
    const [docId, setDocId] = useState('')
    const [keywordBrand, setKeywordBrand] = useState('')
    const [keywordModel, setKeywordModel] = useState('')

    const [posts, setPosts] = useState([])
    const [electricType, setElectricType] = useState([])
    const [searchTypeList, setSearchTypeList] = useState([])
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH")
        const time_string = new Date(datetime).toLocaleTimeString("th-TH")
        const time = time_string.split(':')
        return date_string+" "+time[0]+":"+time[1]
    }

    const handleSearch = () => {
        const constraints = []
        const order = []
        constraints.push(where('onboard', '==', 1))
        if(searchTypeList.length > 0) {
            constraints.push(where('type_id', 'in', searchTypeList))
        }
        if(keywordBrand) {
            constraints.push(and(where('brand', '>=', keywordBrand), where('brand', '<=', keywordBrand +  '\uf8ff')))
            order.push(orderBy('brand', 'asc'))
        }
        if(keywordModel) {
            constraints.push(and(where('model', '>=', keywordModel), where('model', '<=', keywordModel +  '\uf8ff')))
            order.push(orderBy('model', 'asc'))
        }
        order.push(orderBy('post_timestamp', 'desc'))
        if(constraints.length >= 2) {
            var postQuery = query(collection(db, 'posts'), and(...constraints), ...order)
        } else {
            var postQuery = query(collection(db, 'posts'), ...constraints, ...order)
        }
        onSnapshot(postQuery, (querySnapshot) => {
            setPosts(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleKeywordBrand = (k) => {
        setKeywordBrand(k)
    }

    const handleKeywordModel = (k) => {
        setKeywordModel(k)
    }

    const handleSearchByType = (id) => {
        const others = Array.from(
            document.getElementsByClassName('type-other')
        )
        const allType = Array.from(
            document.getElementsByClassName('type-all')
        )
        if(id == 0) {
            searchTypeList.splice(0, searchTypeList.length)
            setSearchTypeList([...searchTypeList])
            allType.forEach(element => {
                element.checked = true
            })
            others.forEach(element => {
                element.checked = false
                element.removeAttribute('checked')
            })
        } else {
            if(searchTypeList.indexOf(id) < 0) {
                searchTypeList.push(id)
            } else {
                searchTypeList.splice(searchTypeList.indexOf(id), 1)
            }
            setSearchTypeList([...searchTypeList])
            if(searchTypeList.length > 0) {
                allType.forEach(element => {
                    element.checked = false
                    element.removeAttribute('checked')
                })
            } else {
                allType.forEach(element => {
                    element.checked = true
                })
            }
        }
        handleSearch()
    }

    const getElectricType = () => {
        getDocs(query(collection(db, 'electric_type'))).then((docs) => {
            setElectricType(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDocId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    const fetchPosts = async () => {
        try {
            handleSearch()
        } catch (err) {
            alert(err.message)
        }
    }

    useEffect(() => {
        if (loading) return;
        if (user) getDocUser()
        fetchPosts()
        getElectricType()
    }, [user, loading])

    useEffect(() => {
        handleSearch()
    }, [keywordBrand, keywordModel])

    return (
        <>
            <div style={{ backgroundColor: "#f0f0f0", display: 'flex', flexDirection: 'column', minHeight: 500, paddingTop: 10, alignItems: 'center', alignContent: 'center'}}>
                <div className='' style={{ backgroundColor: "#FFFFFF", display: 'flex', flexDirection: 'column', marginTop: 20, paddingBottom: 20, paddingLeft: 30, paddingRight: 30, width: 1240 }}>
                    <div style={{ marginTop: 20 }}><h3><strong>ประกาศทิ้งซากเครื่องใช้ไฟฟ้า</strong></h3></div>
                    <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 250, borderTop: '1px solid #dddddd', paddingTop: 25, paddingLeft: 10 }}>
                            <div><h5><strong>ค้นหาประกาศ</strong></h5></div>
                            <div><h6>ประเภท</h6></div>
                            <div style={{ paddingLeft: 10, paddingBottom: 10, paddingLeft: 10 }}>
                                <div><input className="form-check-input type-all" type="checkbox" value="0" defaultChecked={true} onClick={ (e) => { handleSearchByType(e.target.value) }} /> ทั้งหมด</div>
                            {electricType?.map((type, i) => (
                                <div key={type.id}><input className="form-check-input type-other" type="checkbox" value={type.id} onClick={ (e) => { handleSearchByType(e.target.value) }} /> {type.data.name}</div>
                            ))}
                                <div><input className="form-check-input type-other" type="checkbox" value="999" id="typeOther" onClick={ (e) => { handleSearchByType(e.target.value) }} /> อื่นๆ</div>
                            </div>
                            <div><h6>ยี่ห้อ</h6></div>
                            <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="ค้นหายี่ห้อ" 
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { handleKeywordBrand(e.target.value) }} 
                                        value={keywordBrand} /></div>
                            <div><h6>รุ่น</h6></div>
                            <div style={{ paddingLeft: 10, paddingBottom: 10, paddingRight: 25 }}><input 
                                        type="text" 
                                        className="form-control" 
                                        placeholder="ค้นหารุ่น" 
                                        aria-describedby="button-addon2"
                                        onChange={(e) => { handleKeywordModel(e.target.value)  }} 
                                        value={keywordModel} /></div>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', borderLeft: '1px solid #dddddd', borderTop: '1px solid #dddddd', paddingTop: 25}}>
                            <div style={{ display: 'flex', alignItems: 'center', alignContent: 'center', flexDirection:'row', paddingLeft: 20}}>
                            {posts?.map((post, i) => (
                                <div className='' key={post.id} onClick={() => navigate(`/post/${post.id}`)} style={{ cursor: 'pointer', maxWidth: 200, marginRight: 20}}>
                                    <div className="" style={{ marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}>
                                        <div style={{ display: 'flex', alignContent: 'center', textAlign:'center', flex: 1, border: "1px solid #dddddd", width: 200, height: 200 }} >
                                            <div style={{ width: 200, height: 200, alignItems: 'center', textAlign: 'center', display: 'flex', flexDirection: 'column', justifyContent:'center' }}><img src={post.data.picture} alt="WEEE" className="img-fluid align-middle" style={{ maxWidth: 200, maxHeight: 200, alignSelf: 'center' }} /></div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column', marginTop: 10}} >
                                            <div className="">
                                                <p style={{ fontSize: 16}}><strong>{post.data.type} {post.data.brand} {post.data.model}</strong></p>
                                                <p style={{ fontSize: 14}}><strong>วันที่ประกาศ:</strong><br/>{dateFormat(post.data.post_timestamp)}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                            </div>
                        </div>
                    </div>
                </div>
                <div style={{ minHeight: 50, borderBottom: "1px solid #dddddd" }}></div>
            </div>
        </>
    )
}

export default Posts