import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, orderBy, onSnapshot, doc, and, or } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import LeftMenu from './LeftMenu'

import { Loading } from "../layouts/Loading"
import { AppointmentBoard1 } from './AppointBoard1'
import { AppointmentBoard2 } from './AppointBoard2'
import { AppointmentBoard3 } from './AppointBoard3'
import { AppointmentBoard4 } from './AppointBoard4'
import { AppointmentAir } from './AppointAir'
import { AppointmentWash } from './AppointWash'

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Appointments = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)
    const [userId, setUserId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [showLoading, setShowLoading] = useState(true)
    const [appointments, setAppointments] = useState([])
    const [searchBoard, setSearchBoard] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)

    const handleSearchByBoard = (value) => {
        const status = value * 1
        const others = Array.from(
            document.getElementsByClassName('board-other')
        )
        const allBoard = Array.from(
            document.getElementsByClassName('board-all')
        )

        if(status == 0) {
            searchBoard.splice(0, searchBoard.length)
            setSearchBoard([...searchBoard])
            allBoard.forEach(element => {
                element.checked = true
            })
            others.forEach(element => {
                element.checked = false
                element.removeAttribute('checked')
            })
        } else {
            if(searchBoard.indexOf(status) < 0) {
                searchBoard.push(status)
            } else {
                searchBoard.splice(searchBoard.indexOf(status), 1)
            }
            setSearchBoard([...searchBoard])
            if(searchBoard.length > 0) {
                allBoard.forEach(element => {
                    element.checked = false
                    element.removeAttribute('checked')
                })
            } else {
                allBoard.forEach(element => {
                    element.checked = true
                })
            }
        }

        handleSearch()
    }

    const handleSearch = () => {
        if(userId) {
            setShowLoading(true)
            const constraints = []
            const order = []
            order.push(orderBy('appointment_date', 'asc'))
            order.push(orderBy('appointment_hour', 'asc'))
            order.push(orderBy('appointment_minute', 'asc'))

            if(searchBoard.length > 0) {
                searchBoard.forEach((board) => {
                    if(board == 1) { // ทิ้งซาก
                        constraints.push(and(where('appointment_type', '==', 'post'), where('post_onboard', '==', 1)))
                    } else if(board == 2) { // ขายซาก
                        constraints.push(and(where('appointment_type', '==', 'post'), where('post_onboard', '==', 2)))
                    } else if(board == 3) { // ขายมือสอง
                        constraints.push(and(where('appointment_type', '==', 'post'), where('post_onboard', '==', 3)))
                    } else if(board == 4) { // แจ้งซ่อม
                        constraints.push(and(where('appointment_type', '==', 'fix')))
                    } else if(board == 5) { // ร้านลงขาย
                    } else if(board == 6) { // แจ้งล้างแอร์
                        constraints.push(and(where('appointment_type', '==', 'air')))
                    } else if(board == 7) { // แจ้งล้างเครื่องซักผ้า
                        constraints.push(and(where('appointment_type', '==', 'wash')))
                    }
                })
            }
            
            if(constraints.length >= 2) {
                var appointmentQuery = query(collection(db, 'appointments'), and(where('user_id', '==', userId), or(...constraints)), ...order)
            } else {
                var appointmentQuery = query(collection(db, 'appointments'), and(where('user_id', '==', userId), ...constraints), ...order)
            }

            onSnapshot(appointmentQuery, (querySnapshot) => {
                appointments.splice(0, appointments.length)
                setAppointments([...appointments])
                var i = 1
                if(querySnapshot.docs.length == 0) { setShowLoading(false) }
                else {
                    querySnapshot.forEach(async(appointment) => {
                        if(appointment.data().appointment_type == 'air' || appointment.data().appointment_type == 'washing') {
                            var postSnapshot = await getDoc(doc(db, `cleans`, appointment.data().post_id))
                            var offerSnapshot = await getDoc(doc(db, `cleans/${appointment.data().post_id}/offers`, appointment.data().offer_id))
                        } else {
                            var postSnapshot = await getDoc(doc(db, `posts`, appointment.data().post_id))
                            var offerSnapshot = await getDoc(doc(db, `posts/${appointment.data().post_id}/offers`, appointment.data().offer_id))
                        }
                        const shopSnapshot = await getDoc(doc(db, 'shops', appointment.data().shop_id));
                        const checkSnapshot = await getDoc(doc(db, `appointments/${appointment.id}/report/before`))
                        const checkAfterSnapshot = await getDoc(doc(db, `appointments/${appointment.id}/report/after`))
                        if(postSnapshot.data()) {
                            appointments.push({
                                id: appointment.id,
                                data: appointment.data(),
                                offer: offerSnapshot.data(),
                                post: postSnapshot.data(),
                                shop: shopSnapshot.data(),
                                checkReportBefore: checkSnapshot.data(),
                                checkReportAfter: checkAfterSnapshot.data(),
                            })

                            setAppointments([...appointments], {
                                id: appointment.id,
                                data: appointment.data(),
                                offer: offerSnapshot.data(),
                                post: postSnapshot.data(),
                                shop: shopSnapshot.data(),
                                checkReportBefore: checkSnapshot.data(),
                                checkReportAfter: checkAfterSnapshot.data(),
                            })
                            i = i++
                            if(i == querySnapshot.docs.length) {
                                setShowLoading(false)
                            }
                        }
                    })
                    setShowLoading(false)
                }
            })
        }
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user)  { return navigate("/") }
        else { 
            getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
                if (docs.docs.length === 1) {
                    setUserId(docs.docs[0].id)
                    if(docs.docs[0].data().is_shop) setIsShop(docs.docs[0].data().is_shop)
                }
            })
        }
    }, [user, loading])

    useEffect(() => {
        handleSearch()
    }, [userId])

    return (
    <>
        <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 280 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="appointments" isShop={isShop} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 20 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }} ><h4><b>การนัดหมายของฉัน</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="appointments" isShop={isShop} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, paddingBottom: 10, borderBottom: '1px solid #cccccc' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}><h6><strong>ประเภทนัดหมาย</strong></h6></div>
                            </div>
                            <div style={{ paddingLeft: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-all" type="checkbox" value="0" defaultChecked={true} onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ทั้งหมด</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="1" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ทิ้งซาก</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="2" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ขายซาก</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="3" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ขายเครื่องใช้ไฟฟ้ามือสอง</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="4" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> แจ้งซ่อมเครื่องใช้ไฟฟ้า</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="6" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> แจ้งล้างแอร์</div>
                                <div style={{ whiteSpace: 'nowrap'}}><input className="form-check-input board-other" type="checkbox" value="7" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> แจ้งล้างเครื่องซักผ้า</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 15}}>
                        {appointments?.map((appointment, i) => (
                            <div key={appointment.id}>
                                {((appointment.data.appointment_type == 'post') && (appointment.post.onboard == 1)) && (<>
                                <AppointmentBoard1 appointment={appointment} />
                                </>)}
                                {((appointment.data.appointment_type == 'post') && (appointment.post.onboard == 2)) && (<>
                                <AppointmentBoard2 appointment={appointment} />
                                </>)}
                                {((appointment.data.appointment_type == 'post') && (appointment.post.onboard == 3)) && (<>
                                <AppointmentBoard3 appointment={appointment} />
                                </>)}
                                {(appointment.data.appointment_type == 'fix') && (
                                <AppointmentBoard4 appointment={appointment} />
                                )}
                                {(appointment.data.appointment_type == 'air') && (
                                <AppointmentAir appointment={appointment} />
                                )}
                                {(appointment.data.appointment_type == 'wash') && (
                                <AppointmentWash appointment={appointment} />
                                )}
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Appointments