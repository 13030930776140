import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, doc, updateDoc, addDoc, getDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom"
import useScript from '../../assets/js/useScript'
import { RxHome } from "react-icons/rx"
import { FaMapMarkerAlt } from "react-icons/fa"
import { IoCloseCircle, IoCheckmarkCircleSharp, IoChevronBackCircleSharp } from "react-icons/io5"
import { AiFillShop } from "react-icons/ai"
import addImage from "../../assets/images/add-image-icon.png"
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

const AddShop = () => {
    useScript('https://code.jquery.com/jquery-3.2.1.min.js')
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dependencies/JQL.min.js')
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dependencies/typeahead.bundle.js')
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dist/jquery.Thailand.min.js')

    const inputSubDistrictRef = useRef(null)
    const inputDistrictRef = useRef(null)
    const inputProvinceRef = useRef(null)
    const inputPostcodeRef = useRef(null)
    const params = useParams()
    const memberId = params.id
    const [images, setImages] = useState([
        { id: "", url: addImage, file: "", status: 0 },
        { id: "", url: addImage, file: "", status: 0 },
        { id: "", url: addImage, file: "", status: 0 },
        { id: "", url: addImage, file: "", status: 0 },
        { id: "", url: addImage, file: "", status: 0 }
    ])
    const [type, setType] = useState(1)
    const [member, setMember] = useState()
    const [shopname, setShopName] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [telNo, setTelNo] = useState('')
    const [address, setAddress] = useState('')
    const [road, setRoad] = useState('')
    const [subDistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [postcode, setPostcode] = useState('')

    const [pictureErrorDiv, setPictureErrorDiv] = useState(false)
    const [shopnameErrorDiv, setShopnameErrorDiv] = useState(false)
    const [firstnameErrorDiv, setFirstnameErrorDiv] = useState(false)
    const [lastnameErrorDiv, setLastnameErrorDiv] = useState(false)
    const [telNoErrorDiv, setTelNoErrorDiv] = useState(false)
    
    const [openSuccess, setOpenSuccess] = useState(false);

    const navigate = useNavigate()

    const [lat, setLat] = useState(15.11745249400088)
    const [lng, setLng] = useState(104.90284046686465)

    const [loading, error] = useAuthState(auth)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    })
    const center = { lat: lat, lng: lng }
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    const deleteImage = (i) => {
        let items = [...images]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setImages(items)
    }

    const handleImage = (file, i) => {
        let items = [...images]
        let item = {...items[i]}
        item.url = URL.createObjectURL(file)
        item.file = file
        item.status = 2
        items[i] = item
        setImages(items)
    }

    const setAllAddress = () => {
        const subDistrictRef = inputSubDistrictRef.current.value;
        const districtRef = inputDistrictRef.current.value;
        const provinceRef = inputProvinceRef.current.value;
        const postcodeRef = inputPostcodeRef.current.value;
        setSubDistrict(subDistrictRef)
        setDistrict(districtRef)
        setProvince(provinceRef)
        setPostcode(postcodeRef)
    }

    const validateForm = () => {
        if(type == 2) {
            if(!shopname) {
                setShopnameErrorDiv(true)
                return false;
            } else {
                setShopnameErrorDiv(false)
            }
        }
        if(!firstname) {
            setFirstnameErrorDiv(true)
            return false;
        } else {
            setFirstnameErrorDiv(false)
        }
        if(!lastname) {
            setLastnameErrorDiv(true)
            return false;
        } else {
            setLastnameErrorDiv(false)
        }
        if(!telNo) {
            setTelNoErrorDiv(true)
            return false;
        } else {
            setTelNoErrorDiv(false)
        }

        var numPic = 0
        images.forEach((image) => {
            if(image.status != 0) {
                numPic++;
            }
        })
        if(numPic > 0) {
            setPictureErrorDiv(false)
        } else {
            setPictureErrorDiv(true)
            return false;
        }

        return true
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()
        if(validateForm())
        try {
            addDoc(collection(db, "shops"), {
                user_id: memberId,
                uid: member.uid,
                type: type,
                firstname: firstname,
                lastname: lastname,
                tel_no: telNo,
                address: address,
                sub_district: subDistrict,
                district: district,
                province: province,
                postcode: postcode,
                lat: lat,
                lng: lng,
                status: 1,
            }).then((shopDocRef) => {
                if(type == 2) {
                    updateDoc(doc(db, 'shops', shopDocRef.id), {
                        shopname: shopname
                    })
                }

                updateDoc(doc(db, 'users', memberId), {
                    is_shop: 1,
                    shop_id: shopDocRef.id
                })

                images.forEach((image, i) => {
                    uploadFiles(image.file, i, shopDocRef.id)
                })
            }).then(() => {
                navigate(`/admin/shops/`)
            })
        } catch (err) {
			alert(err)
		}
    }

    const uploadFiles = (file, i, shopId) => {
        const promises = []
        const storageRef = ref(storage, `/shops/${shopId}/${i}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
        promises.push(uploadTask)
        uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => console.log(error),
            async() => {
                await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                    addDoc(collection(db, `/shops/${shopId}/images`), {
                        url: downloadURLs,
                    })
                })
            }
        )

        Promise.all(promises)
    }

    const getDocUser = () => {
        getDoc(doc(db, 'users', memberId)).then((memberDoc) => {
            setMember(memberDoc.data())
        })
    }

    useEffect(() => {
        getDocUser()
    }, [loading])

    useScript('https://firebasestorage.googleapis.com/v0/b/weee-81f51.appspot.com/o/js%2FthailandAddress.js?alt=media&token=b1c469d5-5f9e-4cb1-98b3-05056ea57a86');

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>เพิ่มร้านค้า</b></h4>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shops/`) }}>
                            <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                            <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shops/`) }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, flex: 3 }}>
                        <Collapse in={openSuccess}>
                            <Alert action={
                                <IconButton aria-label="close" color="inherit" size="small"
                                    onClick={() => { setOpenSuccess(false); }}
                                >
                                <CloseIcon fontSize="inherit" />
                                </IconButton>
                            } sx={{ mb: 2 }}> บันทึกสำเร็จ
                            </Alert>
                        </Collapse>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>Email <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <div style={{ flex: 1, display: 'flex', flexDirection: "column"}}>
                                    {(member) && (
                                    <><font style={{ marginRight: 10}}>{member.email?member.email:""}</font></>
                                    )}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ประเภทร้านค้า <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    <select name='type' onChange={(e) => setType(e.target.value)} value={type}>
                                        <option value="1">บุคคลธรรมดา</option>
                                        <option value="2">ร้าน/บริษัท/นิติบุคคล</option>
                                    </select>
                                </div>
                            </div>
                        </div> 
                        {(type==2)&& (
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ชื่อร้าน/บริษัท/นิติบุคคล <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='shopname' onChange={(e) => setShopName(e.target.value)} 
                                    value={shopname} placeholder='ชื่อร้าน/บริษัท/นิติบุคคล'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                {shopnameErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกชื่อร้าน/บริษัท/นิติบุคคล</strong>
                                    </div>
                                }
                            </div>
                        </div> 
                        )}
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ชื่อ (เจ้าของ) <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='firstname' onChange={(e) => setFirstname(e.target.value)} 
                                    value={firstname} placeholder='ชื่อ'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                {firstnameErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอก{type==1?"ชื่อ":"ชื่อเจ้าของกิจการ"}</strong>
                                    </div>
                                }
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>นามสกุล (เจ้าของ) <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='lastname' onChange={(e) => setLastname(e.target.value)} 
                                    value={lastname} placeholder='นามสกุล'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                {lastnameErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอก{type==1?"นามสกุล":"นามสกุลเจ้าของกิจการ"}</strong>
                                    </div>
                                }
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>เบอร์โทรศัพท์ <font className="text-danger">*</font></strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='tel_no' onChange={(e) => setTelNo(e.target.value)} 
                                    value={telNo} placeholder='เบอร์โทรศัพท์'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                {telNoErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกเบอร์โทรศัพท์</strong>
                                    </div>
                                }
                            </div>
                        </div>
                        <div style={{ marginBottom: 10, marginTop: 5, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                            <h6><RxHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อมูลที่อยู่</b></h6>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>บ้านเลขที่, หมู่บ้าน, อาคาร</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='address' onChange={(e) => setAddress(e.target.value)} 
                                    value={address} placeholder='บ้านเลขที่, หมู่บ้าน, อาคาร'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ถนน</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                    value={road} placeholder='ถนน'
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ตำบล</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' id="district" name='subDistrict' autoComplete='no' onChange={(e) => setSubDistrict(e.target.value)} onBlur={(e) => setAllAddress()}
                                    value={subDistrict} placeholder='ตำบล' ref={inputSubDistrictRef} 
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>อำเภอ</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' id="amphoe" name='district' autoComplete='no' onChange={(e) => setDistrict(e.target.value)} onBlur={(e) => setAllAddress()} 
                                    value={district} placeholder='อำเภอ' ref={inputDistrictRef} 
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>จังหวัด</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' id="province" name='province' autoComplete='no' onChange={(e) => setProvince(e.target.value)} onBlur={(e) => setAllAddress()} 
                                    value={province} placeholder='จังหวัด' ref={inputProvinceRef} 
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                            </div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>รหัสไปรษณีย์</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <input type='text' id="zipcode" name='postcode' autoComplete='no' onChange={(e) => setPostcode(e.target.value)} onBlur={(e) => setAllAddress()} 
                                    value={postcode} placeholder='รหัสไปรษณีย์' ref={inputPostcodeRef} 
                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                            </div>
                        </div> 
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 5, borderLeft: '1px solid #cccccc', marginLeft: 30}}>
                        <div style={{ display:'flex', flexDirection: 'column', paddingLeft: 30 }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                <label className="col-form-label" style={{ display: 'flex'}}><strong>{type==1?"รูปถ่ายหน้าร้าน":"รูปถ่ายหน้าร้าน/บริษัท"}</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
                                    {images?.map((image, i) => (
                                        <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                            <img src={image.url} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                            <div style={{ display: "flex", flexDirection:'row'}}>
                                            <label htmlFor={"fileUpload"+i} style={{ padding: 0 }}>
                                                {(image.status!=0)?
                                                (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                                                (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                                                }
                                            </label>
                                            {(image.status!=0) &&
                                            (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImage(i) }}>
                                                <IoCloseCircle style={{ fontSize: 20 }} />
                                            </button>)
                                            }
                                            </div>
                                            <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImage(e.target.files[0], i)} />
                                        </div>
                                    ))}
                                    </div>
                                    {pictureErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณาเลือก{type==1?"รูปถ่ายหน้าร้าน":"รูปถ่ายหน้าร้าน/บริษัท"} อย่างน้อย 1 รูป</strong>
                                        </div>
                                    }
                                </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30, marginTop: 10 }}>
                            <div className="mb-3 row" style={{ display: 'flex', height:400, flexDirection: 'column' }}>
                                <label className="col-form-label" style={{ display: 'flex'}}><strong>ตำแหน่งพิกัดที่พักอาศัย</strong></label>
                                <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap' }}>
                                    <font className="text-danger" style={{ marginRight: 5 }}>**</font> ลาก Marker <FaMapMarkerAlt className='text-danger' style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} /> เพื่อกำหนดตำแหน่ง
                                </div>
                                <div style={{  display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                    {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                    <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                        <MarkerF
                                            draggable={true} position={center}
                                            onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                        />
                                    </GoogleMap>
                                    )}
                                </div>
                                <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap', textAlign: 'right' }}>
                                    <div style={{ display: 'flex', float: 'right', flex: 1 }}>lat:{lat}, lng:{lng}</div>
                                </div>
                            </div>
                        </div> 
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AddShop