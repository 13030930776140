import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, getDoc, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { Link, useNavigate } from "react-router-dom"
import LeftMenu from './LeftMenu'

import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2 } from "react-icons/tb"
import { IoMegaphoneOutline } from 'react-icons/io5'
import { AiFillInfoCircle } from 'react-icons/ai'
import { FiEdit, FiMinusCircle, FiInfo, FiXCircle, FiCheckCircle, FiCalendar } from "react-icons/fi"
import { BsMegaphone } from "react-icons/bs"
import { ImCoinDollar } from "react-icons/im"
import { MdOutlineCalendarToday } from "react-icons/md"
import { StarRating } from '../helper/StarHelper'
import { LuAirVent } from "react-icons/lu"
import { CgSmartHomeWashMachine } from "react-icons/cg"

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { Alert } from '../layouts/Alert'
import { Loading } from "../layouts/Loading"
import { getBrand, getModel } from '../helper/BrandHelper'
import { addShopNotification } from "../../../assets/js/NotificationHelper"

import { adsPositionAmount } from '../../../assets/constants/ads'

import LeftMenuPost from './LeftMenuPost'
import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Posts = () => {
    const navigate = useNavigate();
    const number_format = new Intl.NumberFormat()
    const [user, loading] = useAuthState(auth)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [uid, setUid] = useState('')
    const [memberId, setMemberId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [posts, setPosts] = useState([])

    const [postId, setPostId] = useState('')
    const [openChooseOffersShop, setOpenChooseOffersShop] = useState(false)
    const [offerList, setOfferList] = useState([])
    const [confirmOffer, setConfirmOffer] = useState('')
    const [openErrorDiv, setOpenErrorDiv] = useState(false)

    const [openHidePost, setOpenHidePost] = useState(false)
    const [openShowPost, setOpenShowPost] = useState(false)
    const [openDeletePost, setOpenDeletePost] = useState(false)
    const [choosePostId, setChoosePostId] = useState('')

    const [openShowAdsRequest, setOpenShowAdsRequest] = useState(false)
    const [adsDuration, setAdsDuration] = useState(0)
    const [adsTotalUseCoin, setAdsTotalUseCoin] = useState(0)
    const [adsPos1UseCoin, setAdsPos1UseCoin] = useState(0)
    const [adsPos2UseCoin, setAdsPos2UseCoin] = useState(0)
    const [adsPos6UseCoin, setAdsPos6UseCoin] = useState(0)
    const [adsExchangeCoin, setAdsExchangeCoin] = useState(0)
    const [adsPosition, setAdsPosition] = useState(1)
    const [useCoinErrorDiv, setUseCoinErrorDiv] = useState(false)

    const [adsDurationErrorDiv, setAdsDurationErrorDiv] = useState(false)
    const [adsQueuePos1, setAdsQueuePos1] = useState(0)
    const [finalDateAdsPos1, setFinalDateAdsPos1] = useState('')
    const [availableQueuePos1, setAvailableQueuePos1] = useState(false)
    const [adsQueuePos2, setAdsQueuePos2] = useState(0)
    const [finalDateAdsPos2, setFinalDateAdsPos2] = useState('')
    const [availableQueuePos2, setAvailableQueuePos2] = useState(false)
    const [adsQueuePos6, setAdsQueuePos6] = useState(0)
    const [finalDateAdsPos6, setFinalDateAdsPos6] = useState('')
    const [availableQueuePos6, setAvailableQueuePos6] = useState(false)

    const [showProgressBar, setShowProgressBar] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const handleCloseHidePost = () => {
        setChoosePostId('')
        setOpenHidePost(false)
    }

    const handleOpenHidePost = (post_id) => {
        setChoosePostId(post_id)
        setOpenHidePost(true)
    }
    const handleCloseShowPost = () => {
        setChoosePostId('')
        setOpenShowPost(false)
    }

    const handleOpenShowPost = (post_id) => {
        setChoosePostId(post_id)
        setOpenShowPost(true)
    }
    const handleCloseDeletePost = () => {
        setChoosePostId('')
        setOpenDeletePost(false)
    }

    const handleOpenDeletePost = (post_id) => {
        setChoosePostId(post_id)
        setOpenDeletePost(true)
    }

    const handleHidePost = () => {
        var promises = []
        const updateTask = updateDoc(doc(db, `posts`, choosePostId), { status: 6 })
        promises.push(updateTask)
        Promise.all(promises).then(() => {
            navigate(0)
        })

    }

    const handleShowPost = () => {
        var promises = []
        const updateTask = updateDoc(doc(db, `posts`, choosePostId), { status: 1 })
        promises.push(updateTask)
        Promise.all(promises).then(() => {
            navigate(0)
        })
    }

    const handleDeletePost = () => {
        var promises = []
        const deleteTask = deleteDoc(doc(db, `posts`, choosePostId))
        promises.push(deleteTask)
        Promise.all(promises).then(() => {
            navigate(0)
        })

    }

    const handleCloseAcceptPostShop = () => {
        setOpenChooseOffersShop(false)
    }

    const handleCloseAdsRequest = () => {
        setChoosePostId('')
        setOpenShowAdsRequest(false)
    }
    
    const handleOpenAdsRequest = (post_id) => {
        setChoosePostId(post_id)
        setOpenShowAdsRequest(true)
    }

    const handleAdsRequest = () => {
        if(validateAdsRequestForm()) {
            var usedCoinPerDay = adsPos1UseCoin * 1
            if(adsPosition == '2') { 
                usedCoinPerDay = adsPos2UseCoin * 1
            }
            if(adsPosition == '6') { 
                usedCoinPerDay = adsPos6UseCoin * 1
            }
            const requestDate = new Date()
            addDoc(collection(db, `/ads/request/position_${adsPosition}`), {
                user_id: memberId,
                post_id: choosePostId,
                used_coin_per_day: usedCoinPerDay,
                ads_duration: adsDuration * 1,
                total_used_coin: adsTotalUseCoin,
                request_timestamp: requestDate.getTime(),
                status: 1
            }).then((docRef) => {
                addDoc(collection(db, `users/${memberId}/ads/request/list/`), {
                    ads_id: docRef.id,
                    post_id: choosePostId,
                    position: adsPosition,
                    used_coin_per_day: usedCoinPerDay,
                    ads_duration: adsDuration * 1,
                    total_used_coin: adsTotalUseCoin,
                    request_timestamp: requestDate.getTime(),
                    status: 1
                }).then(() => {
                    navigate(0)
                })
            })
        }
    }

    const validateAdsRequestForm = () => {
        if(adsPosition == '1') {
            if((adsPos1UseCoin * 1) > (adsTotalUseCoin * 1)) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if(adsPosition == '2') {
            if((adsPos2UseCoin * 1) > (adsTotalUseCoin * 1)) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else {
            if((adsPos6UseCoin * 1) > (adsTotalUseCoin * 1)) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        }
        if(!adsDuration) {
            setAdsDurationErrorDiv(true)
            return false
        } else {
            setAdsDurationErrorDiv(false)
        }
        return true
    }
 
    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }
 
    const dateFormatTime = (datetime) => {
        return (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const showChooseOffersList = (post) => {
        setConfirmOffer('')
        setPostId(post.id)
        offerList.splice(0, offerList.length)
        setOfferList([...offerList])
        getDocs(query(collection(db, `posts/${post.id}/offers`), orderBy('offer_timestamp', 'asc'))).then(async (offerDocs) => {
            offerDocs.docs.map((offer) => {
                getDoc(doc(db, 'shops', offer.data().shop_id)).then((shop) => {
                    offerList.push({
                        id: offer.id,
                        data: offer.data(),
                        shopId: offer.data().shop_id,
                        shop: shop.data()
                    })
                    setOfferList([...offerList]) 
                })
            })
        }).then(() => {
            setOpenChooseOffersShop(true)
        })
    }

    const handleOfferSubmit = () => {
        if(validateOfferForm()) {
            setShowLoading(true)
            getDoc(doc(db, `posts/${postId}/offers/`, confirmOffer)).then((offerDoc) => {
                updateDoc(doc(db, `/posts`, postId), { status: 3 }).then(async () => {
                    getDocs(query(collection(db, `/posts/${postId}/offers`))).then((offerDocs) => {
                        offerDocs.forEach(async(docRef) => {
                            updateDoc(doc(db, `/posts/${postId}/offers`, docRef.id), { status: 4 }).then(() => {
                                getDocs(query(collection(db, `/shops/${docRef.data().shop_id}/offers`), where('offer_id', '==', docRef.id))).then((offerShop) => {
                                    offerShop.forEach((offer) => {
                                        updateDoc(doc(db, `/shops/${docRef.data().shop_id}/offers`, offer.id), { status: 4 })
                                    })
                                })
                            })
                        })
                    }).then(() => {
                        updateDoc(doc(db, `/posts/${postId}/offers/`, confirmOffer), { status: 2 }).then(() => {
                            getDocs(query(collection(db, `/shops/${offerDoc.data().shop_id}/offers`), where('offer_id', '==', confirmOffer))).then((offerShop) => {
                                offerShop.forEach(async (docRef) => {
                                    updateDoc(doc(db, `/shops/${offerDoc.data().shop_id}/offers`, docRef.id), { status: 2 }).then(() => {
                                        addShopNotification(db, offerDoc.data().shop_id, "ยินดีด้วย ข้อเสนอซื้อเครื่องใช้ไฟฟ้ามือสองของคุณได้รับการเลือก", 'offer', postId, '/shop/offers')
                                        setShowLoading(false)
                                        setOpenChooseOffersShop(false)
                                        navigate(0)
                                    })
                                })
                            })
                        })
                    })
                })
            })
        }
    }

    const validateOfferForm = () => {
        if(!confirmOffer) {
            setOpenErrorDiv(true)
            return false;
        } else {
            setOpenErrorDiv(false)
        }
        return true
    }

    const fetchPosts = () => {
        setShowProgressBar(true)
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then(async(docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMemberId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)
                if(data.exchange_coin)  setAdsExchangeCoin(data.exchange_coin)
                await getDocs(query(collection(db, 'posts'), 
                            where('onboard', '==', 3), 
                            where('poster_id', '==', docs.docs[0].id), 
                            orderBy('post_timestamp', 'desc'))).then((postDocs) => {
                    posts.splice(0, posts.length)
                    setPosts([...posts])
                    postDocs.forEach((docPost) => {
                        var brand = 'ไม่ระบุ'
                        var model = 'ไม่ระบุ'
                        var promisesTask = []
                        var getBrandTask = getBrand(db, docPost.data().brand_id, docPost.data().brand).then((brandName) => {
                            brand = brandName
                        })
                        promisesTask.push(getBrandTask)
        
                        var getModelTask = getModel(db, docPost.data().model_id, docPost.data().model).then((modelName) => {
                            model = modelName
                        })
                        promisesTask.push(getModelTask)
                        getDocs(query(collection(db, `posts/${docPost.id}/offers`), where('status', '==', 1), orderBy('offer_timestamp', 'desc'))).then((offerQuery) => {
                            var offersList = []
                            offerQuery.forEach((offer) => {
                                offersList.push({ id: offer.id, data: offer.data()})
                            })
                            Promise.all(promisesTask).then(() => {
                                posts.push({
                                    id: docPost.id,
                                    data: docPost.data(),
                                    offer: offersList,
                                    brand: brand,
                                    model: model
                                })
                                setPosts([...posts], {
                                    id: docPost.id,
                                    data: docPost.data(),
                                    offer: offersList,
                                    brand: brand,
                                    model: model
                                })

                            })
                        })
                    })
                }).then(() => {
                    setShowProgressBar(false)
                })
            }
        }).catch((error) => {
            alert(error.message);
        })
    }

    const getUseRate = () => {
        getDoc(doc(db, 'ads', 'ads_rate')).then((exchangeSnapshot) => {
            const data = exchangeSnapshot.data()
            if(data.position_1) setAdsPos1UseCoin(data.position_1)
            if(data.position_2) setAdsPos2UseCoin(data.position_2)
            if(data.position_6) setAdsPos6UseCoin(data.position_6)
        })
    }

    const handlePickAdsPosition = (event) => {
        setAdsPosition(event.target.value)
        if(event.target.value == '1') {
            setAdsTotalUseCoin(adsDuration * adsPos1UseCoin)
        } else if(event.target.value == '2') {
            setAdsTotalUseCoin(adsDuration * adsPos2UseCoin)
        } else {
            setAdsTotalUseCoin(adsDuration * adsPos6UseCoin)
        }
    }

    const getListAds = () => {
        getDocs(query(collection(db, `ads/active/position_1`), orderBy('final_date', 'asc'))).then((adsDocs) => {
            if(!adsDocs.empty) {
                if(adsDocs.docs.length < adsPositionAmount[0]) {
                    setAvailableQueuePos1(true)
                    setAdsQueuePos1(adsDocs.docs.length)
                } else {
                    const adsDate = new Date(adsDocs.docs[0].data().final_date)
                    const finishDate = new Date()
                    finishDate.setDate(adsDate.getDate() + 1)
                    setFinalDateAdsPos1(dateFormatTime(finishDate))
                    setAvailableQueuePos1(false)
                }
            } else {
                setAvailableQueuePos1(true)
            }
        })
        getDocs(query(collection(db, `ads/active/position_2`), orderBy('final_date', 'asc'))).then((adsDocs) => {
            if(!adsDocs.empty) {
                if(adsDocs.docs.length < adsPositionAmount[1]) {
                    setAvailableQueuePos2(true)
                    setAdsQueuePos2(adsDocs.docs.length)
                } else {
                    setFinalDateAdsPos2(dateFormatTime(adsDocs.docs[0].data().final_date))
                    setAvailableQueuePos2(false)
                }
            } else {
                setAvailableQueuePos2(true)
            }
        })
        getDocs(query(collection(db, `ads/active/position_6`), orderBy('final_date', 'asc'))).then((adsDocs) => {
            if(!adsDocs.empty) {
                if(adsDocs.docs.length < adsPositionAmount[5]) {
                    setAvailableQueuePos6(true)
                    setAdsQueuePos6(adsDocs.docs.length)
                } else {
                    setFinalDateAdsPos6(dateFormatTime(adsDocs.docs[0].data().final_date))
                    setAvailableQueuePos6(false)
                }
            } else {
                setAvailableQueuePos6(true)
            }
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else {
            fetchPosts()
            getUseRate()
            getListAds()
        }
    }, [user, loading]);

    var postKey = []
    var postList = []
    posts.forEach(element => {
        if(postKey.lastIndexOf(element.id) < 0) {
            postKey.push(element.id)
            postList.push(element)
        }
    })

    return (
        <>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <Loading open={showProgressBar} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
            <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 280 }} role="presentation">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                            <div style={{ marginBottom: 10 }}>
                                <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                            </div>
                            <LeftMenuPost currentPage="post" isShop={isShop} postType={3} />
                        </div>
                    </div>
                </Box>
            </Drawer>
            <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                    <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 10, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                        <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                            <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                        </div>
                    </div>
                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <h4 className='d-block d-md-none d-lg-none'>
                                <b>ประกาศของฉัน {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} onClick={toggleDrawer(true)} >ขายเครื่องใช้ไฟฟ้ามือสอง<IoMdMenu style={{ fontSize: 25, marginLeft: 5, marginBottom: 3 }} /></font></b>
                            </h4>
                            <h4 className='d-none d-md-block d-lg-block'>
                                <b>ประกาศของฉัน {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} >ขายเครื่องใช้ไฟฟ้ามือสอง</font></b>
                            </h4>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                            <LeftMenu currentPage="post" isShop={isShop} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div className="d-none d-md-block d-lg-block" style={{ borderBottom: '1px solid #cccccc', paddingLeft: 20 }}>
                                <ul className="nav nav-underline">
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/"><RiDeleteBinLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ทิ้งซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/garbage"><BiMoneyWithdraw style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="/profile/posts/second"><TbHexagonNumber2 style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายมือสอง</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/fix"><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งซ่อมเครื่องใช้ไฟฟ้า</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/airs"><LuAirVent style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างแอร์</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/wash"><CgSmartHomeWashMachine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างเครื่องซักผ้า</a>
                                    </li>
                                </ul>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 10 }}>
                            {postList?.map((post, i) => (
                                <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={post.id}>
                                    {(post.offer.length > 0 && post.data.status==2) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-primary" onClick={() => { showChooseOffersList(post) }}>
                                            ข้อเสนอ <span className="badge badge-light" style={{ backgroundColor: '#ff0000'}}>{post.offer.length}</span>
                                            </button>
                                        </div>)
                                    }
                                    {(post.data.status==3) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-warning" style={{ color: '#000000'}}>รอยืนยันนัดหมาย</button>
                                        </div>)
                                    }
                                    {(post.data.status==4) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}><strong>ยืนยันนัดหมาย</strong></button>
                                        </div>)
                                    }
                                    {post.data.status == 5 &&
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-danger" style={{ color: '#FFFFFF'}}><strong>ระงับการประกาศ</strong></button>
                                        </div>)
                                    }
                                    {(post.data.status==6) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-danger" style={{ color: '#ffffff'}}><strong>ซ่อนประกาศ</strong></button>
                                        </div>)
                                    }
                                    {(post.data.status==9) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}><strong>ขายสำเร็จ</strong></button>
                                        </div>)
                                    }
                                    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                        <img src={post.data.picture} alt="APP3R" height="150" />
                                    </div>
                                    <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                        <p className="card-text">
                                            {post.data.type} {post.brand} {post.model}<br/>
                                            <strong>ราคาขาย: ฿ {number_format.format(post.data.price)}</strong><br/>
                                            <strong>ปีที่ซี่ื้อ: พ.ศ. {post.data.buy_year}</strong><br/>
                                            <strong>รายละเอียดเพิ่มเติม:</strong> {post.data.description}<br/>
                                        </p>
                                        <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong><br/>{dateFormatDate(post.data.pick_date)} เวลา {post.data.hour}:{post.data.minute} น.</p>
                                        <p className="card-text"><strong>วันที่ประกาศ:</strong> {dateFormatTime(post.data.post_timestamp)}</p>
                                    </div>
                                    <div className="card-body">
                                        {(post.data.status==1) && (<>
                                            <Link className="btn btn-warning m-1" to={`/post/edit/second/${post.id}`}><FiEdit /> <strong>แก้ไข</strong></Link>
                                            <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenHidePost(post.id) }}><FiMinusCircle /> <strong>ซ่อน</strong></button>
                                            <button type="button" className="btn btn-primary m-1" onClick={() => { handleOpenAdsRequest(post.id) }}><BsMegaphone /> <strong>ลงโฆษณา</strong></button>
                                        </>)}
                                        {(post.data.status==2) && (<>
                                            <button type="button" className="btn btn-primary m-1" onClick={() => { handleOpenAdsRequest(post.id) }}><BsMegaphone /> <strong>ลงโฆษณา</strong></button>
                                        </>)}
                                        <Link className="btn btn-primary m-1" to={`/post/${post.id}`} target='_blank'><FiInfo /> <strong>ดูประกาศ</strong></Link>
                                        {(post.data.status==3) && (<>
                                            <Link className="btn btn-primary m-1" to={`/profile/postdetail/${post.data.onboard}/${post.id}/`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                                        </>)}
                                        {(post.data.status==4) && (<>
                                            <Link className="btn btn-primary m-1" to={`/profile/postdetail/${post.data.onboard}/${post.id}/`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                                            <Link className="btn btn-primary m-1" to={`/profile/appointments`} target='_blank'><FiCalendar /> <strong>ดูนัดหมาย</strong></Link>
                                        </>)}
                                        {(post.data.status==6) && (<>
                                            <Link className="btn btn-warning m-1" to={`/post/edit/second/${post.id}`}><FiEdit /> <strong>แก้ไข</strong></Link>
                                            <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenShowPost(post.id) }}><FiCheckCircle /> <strong>แสดง</strong></button>
                                            <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenDeletePost(post.id) }}><FiXCircle /> <strong>ลบ</strong></button>
                                        </>)}
                                        {(post.data.status==9) && (<>
                                            <Link className="btn btn-primary m-1" to={`/profile/postdetail/${post.data.onboard}/${post.id}/`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                                        </>)}
                                    </div>
                                </div>
                            ))}
                            {postList.length <= 0 && (
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <font className="text-danger" style={{ fontWeight: '600'}}>ไม่พบประกาศ</font>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>

                <Dialog open={openChooseOffersShop} onClose={handleCloseAcceptPostShop} maxWidth="md" fullWidth={true}>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยื่นข้อเสนอ</strong></h3></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', marginTop: 10, flexDirection: 'column', width:'100%', marginBottom: 15 }}>
                                    <div style={{ backgroundColor: '#ffffff'}}>
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">ข้อเสนอร้านค้า</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {offerList?.map((offer, i) => (
                                                <tr key={i}>
                                                    <th scope="row">
                                                        <input className="form-check-input" type="radio" 
                                                            name="chooseOfferRadio" id="chooseOfferRadio" value={offer.id}
                                                            onClick={() => setConfirmOffer(offer.id)}
                                                        />
                                                    </th>
                                                    <td>
                                                        <div className="flex-container">
                                                            <div className="flex-container" style={{ marginBottom: 10, marginRight: 10 }}>
                                                                {(offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname}<br/>
                                                                <StarRating score={offer.shop.score_average?offer.shop.score_average:0} />
                                                                <a href={`/shop/history/${offer.shopId}`} target='_blank'><AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} /></a>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10, marginRight: 10}}>
                                                                <p style={{ marginBottom: 5 }}><strong>ราคาที่เสนอ:</strong> {number_format.format(offer.data.price)} บาท</p>
                                                                <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.</p>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10}}>
                                                                {offer.data.message}
                                                            </div>
                                                        </div>
                                                    </td>
                                                </tr>
                                            ))}
                                            </tbody>
                                        </table>
                                    </div>
                                    {openErrorDiv &&
                                        <div className="card-body text-danger border-danger" style={{ marginLeft: 10, marginTop:-5}}>
                                            <p className="font-weight-bold">** กรุณาเลือกข้อเสนอเพื่อยืนยันรับข้อเสนอ</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseAcceptPostShop} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleOfferSubmit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันรับข้อเสนอ</button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openShowAdsRequest} onClose={handleCloseAdsRequest} maxWidth="sm" fullWidth={true}>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                        <div style={{ fontFamily: 'Prompt'}}>
                            <h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ลงโฆษณาขายเครื่องใช้ไฟฟ้ามือสอง</strong></h3>
                        </div>
                    </DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ display:'flex', flexDirection: 'row', alignItems:'center', flex: 1 }}>
                                <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                            <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                                <ImCoinDollar style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>จำนวนเหรียญทองที่มี</strong> {number_format.format(adsExchangeCoin)} เหรียญ</h5>
                                            </label>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                            <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                                <ImCoinDollar style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>ตำแหน่งที่ต้องการลงประกาศ:</strong></h5>
                                            </label>
                                            <div style={{ display: 'flex', flex: 1, paddingLeft: 30, flexDirection: 'column' }}>
                                                <RadioGroup
                                                    defaultValue="freecoin" name="fromCoinOffer"
                                                    value={adsPosition} onChange={handlePickAdsPosition}
                                                >
                                                    <FormControlLabel control={<Radio />} value='1' defaultChecked={true} 
                                                        label={<span style={{ fontFamily: 'Prompt' }}>{"หน้าแรก ตำแหน่งบนสุด"}<strong> {number_format.format(adsPos1UseCoin)}</strong> เหรียญ / 1 วัน</span>}
                                                    />
                                                    {!availableQueuePos1 && <div><font className='text-danger'>คิวลงโฆษณาเต็ม คิวจะว่างในวันที่ {finalDateAdsPos1}</font></div>}
                                                    <FormControlLabel control={<Radio />} value='2' 
                                                        label={<span style={{ fontFamily: 'Prompt' }}>{"หน้าแรก ขายเครื่องใช้ไฟฟ้ามือสอง"}<strong> {number_format.format(adsPos2UseCoin)}</strong> เหรียญ / 1 วัน</span>}
                                                    />
                                                    {!availableQueuePos2 && <div><font className='text-danger'>คิวลงโฆษณาเต็ม คิวจะว่างในวันที่ {finalDateAdsPos2}</font></div>}
                                                    <FormControlLabel control={<Radio />} value='6'
                                                        label={<span style={{ fontFamily: 'Prompt' }}>{"หน้าแสดงทั้งหมด ขายเครื่องใช้ไฟฟ้ามือสอง "}<strong> {number_format.format(adsPos6UseCoin)}</strong> เหรียญ / 1 วัน</span>}
                                                    />
                                                    {!availableQueuePos6 && <div><font className='text-danger'>คิวลงโฆษณาเต็ม คิวจะว่างในวันที่ {finalDateAdsPos6}</font></div>}
                                                </RadioGroup>
                                                {useCoinErrorDiv &&
                                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                        <strong>** จำนวนเหรียญที่ต้องใช้ในการลงโฆษณาไม่เพียงพอ</strong>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                                <MdOutlineCalendarToday style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>จำนวนวันโฆษณา (วัน)<font className="text-danger">*</font></strong></h5>
                                            </label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: 30 }}>
                                                <input type='text' name='adsDuration' onChange={(e) =>  {
                                                    setAdsDuration(e.target.value)
                                                    if(adsPosition == '1') {
                                                        setAdsTotalUseCoin(e.target.value * adsPos1UseCoin)
                                                    } else if(adsPosition == '2') {
                                                        setAdsTotalUseCoin(e.target.value * adsPos2UseCoin)
                                                    } else {
                                                        setAdsTotalUseCoin(e.target.value * adsPos6UseCoin)
                                                    }
                                                }}
                                                    value={adsDuration} placeholder='จำนวนวัน'
                                                    style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                                {adsDurationErrorDiv &&
                                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                        <strong>** กรุณากรอกจำนวนวันโฆษณา</strong>
                                                    </div>
                                                }
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                                <ImCoinDollar style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>จำนวนเหรียญทองที่ต้องใช้</strong> {number_format.format(adsTotalUseCoin)} เหรียญ</h5>
                                            </label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseAdsRequest} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleAdsRequest} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ลงโฆษณา</button>
                    </DialogActions>
                </Dialog>
                
                <Alert name="Hide Post"
                    title={"ซ่อนประกาศ"}
                    text={"คุณต้องการซ่อนประกาศนี้?"}
                    open={openHidePost} 
                    close={handleCloseHidePost}
                    submit={handleHidePost} />
                
                <Alert name="Show Post"
                    title={"แสดงประกาศ"}
                    text={"คุณต้องการแสดงประกาศนี้?"}
                    open={openShowPost} 
                    close={handleCloseShowPost}
                    submit={handleShowPost} />
            
                <Alert name="Delete Post"
                    title={"ลบประกาศ"}
                    text={"คุณต้องการลบประกาศนี้?"}
                    open={openDeletePost} 
                    close={handleCloseDeletePost}
                    submit={handleDeletePost} />

            </div>
        </>
    )
}

export default Posts;