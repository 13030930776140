import React, { useState, useEffect } from 'react';
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, onSnapshot, addDoc, updateDoc, doc, orderBy, setDoc } from 'firebase/firestore'
import { useParams, useNavigate, Link } from "react-router-dom"
import { useLoadScript } from "@react-google-maps/api"

import { AiOutlineCheckCircle, AiOutlinePlusCircle } from "react-icons/ai"
import { IoMegaphoneOutline, IoCarOutline, IoHammerOutline } from "react-icons/io5"
import { ImCoinDollar } from "react-icons/im"
import { MdOutlineMessage, MdOutlineCalendarToday } from "react-icons/md"
import { RiHandCoinLine } from "react-icons/ri"
import { FaRegThumbsUp } from "react-icons/fa"

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import LinearProgress from '@mui/material/LinearProgress'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'

import { getBrand, getModel } from '../helper/BrandAirHelper'
import { StarRating } from '../helper/StarHelper'
import './Post.css'

const Post = () => {
    const params = useParams()
    const postId = params.id
    const navigate = useNavigate()
    const today = dayjs()
    const number_format = new Intl.NumberFormat()

    const onMobile = window.innerWidth < 920
    var imagePostHeight = 190
    var thumpnailWidth = 100
    var h1FontSize = 42
    var h2FontSize = 24
    var h3FontSize = 18
    var textFontSize = 16


    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        imagePostHeight = 100
        thumpnailWidth = 60
        h1FontSize = 32
        h2FontSize = 20
        h3FontSize = 18
        textFontSize = 16
    } else if(window.innerWidth < 576) {
        imagePostHeight = 100
        thumpnailWidth = 40
        h1FontSize = 24
        h2FontSize = 18
        h3FontSize = 14
        textFontSize = 14
    }

    const [width, setWidth] = useState(100)
    const [height, setHeight] = useState(imagePostHeight)

    const [user, loading, error] = useAuthState(auth)
    const [uid, setUid] = useState('')
    const [memberId, setMemberId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [shopId, setShopId] = useState(0)
    const [offered, setOffered] = useState(false)
    const [offer, setOffer] = useState('')
    const [onBoard, setOnBoard] = useState(1)
    const [postView, setPostView] = useState(0)
    const [status, setStatus] = useState(1)

    const [pickDate, setPickDate] = useState(today)
    const [pickHour, setPickHour] = useState('09')
    const [pickMinute, setPickMinute] = useState('00')
    const [offerMessage, setOfferMessage] = useState('')
    const [offerDeposit, setOfferDeposit] = useState(1)
    const [offerTravel, setOfferTravel] = useState(1)
    const [offerTravelPrice, setOfferTravelPrice] = useState(0)
    const [offerGaruntee, setOfferGaruntee] = useState(1)
    const [fromCoinOffer, setFromCoinOffer] = useState('freecoin')
    const [offerPriceAir1, setOfferPriceAir1] = useState(0)
    const [offerPriceAir2, setOfferPriceAir2] = useState(0)
    const [offerPriceAir3, setOfferPriceAir3] = useState(0)
    const [offerGarunteeDay, setOfferGarunteeDay] = useState(0)

    const [offerStep1, setOfferStep1] = useState(false)
    const [offerStep2, setOfferStep2] = useState(false)
    const [offerStep3, setOfferStep3] = useState(false)
    const [offerStep4, setOfferStep4] = useState(false)
    const [offerStep5, setOfferStep5] = useState(false)
    const [offerStep6, setOfferStep6] = useState(false)
    const [offerStep7, setOfferStep7] = useState(false)
    const [offerStep8, setOfferStep8] = useState(false)
    const [offerStep9, setOfferStep9] = useState(false)
    const [offerStep10, setOfferStep10] = useState(false)
    const [offerStep11, setOfferStep11] = useState(false)
    const [offerStep12, setOfferStep12] = useState(false)
    const [offerStep13, setOfferStep13] = useState(false)
    const [offerStep14, setOfferStep14] = useState(false)
    const [offerStep15, setOfferStep15] = useState(false)
    const [offerStep16, setOfferStep16] = useState(false)
    
    const [post, setPost] = useState([])
    const [lat, setLat] = useState(15.11745249400088)
    const [lng, setLng] = useState(104.90284046686465)

    const [address, setAddress] = useState('')
    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [subdistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [postcode, setPostcode] = useState('')

    const [hasAir1, setHasAir1] = useState(false)
    const [brandName1, setBrandName1] = useState('')
    const [modelName1, setModelName1] = useState('')
    const [air1BTU, setAir1BTU] = useState('')
    const [air1Amount, setAir1Amount] = useState(0)
    const [air1IsOver3M, setAir1IsOver3M] = useState(false)
    const [air1Over3MAmount, setAir1Over3MAmount] = useState(0)
    const [air1IsBelow3M, setAir1IsBelow3M] = useState(false)
    const [air1Below3MAmount, setAir1Below3MAmount] = useState(0)
    const [air1IsProblem1, setAir1IsPloblem1] = useState(false)
    const [air1IsProblem2, setAir1IsPloblem2] = useState(false)
    const [air1IsProblem3, setAir1IsPloblem3] = useState(false)
    const [air1IsProblem4, setAir1IsPloblem4] = useState(false)
    const [air1IsProblem5, setAir1IsPloblem5] = useState(false)
    const [air1ShowImages, setAir1ShowImages] = useState('')
    const [air1Images, setAir1Images] = useState([])

    const [hasAir2, setHasAir2] = useState(false)
    const [brandName2, setBrandName2] = useState('')
    const [modelName2, setModelName2] = useState('')
    const [air2BTU, setAir2BTU] = useState('')
    const [air2Amount, setAir2Amount] = useState(0)
    const [air2IsOver3M, setAir2IsOver3M] = useState(false)
    const [air2Over3MAmount, setAir2Over3MAmount] = useState(0)
    const [air2IsBelow3M, setAir2IsBelow3M] = useState(false)
    const [air2Below3MAmount, setAir2Below3MAmount] = useState(0)
    const [air2IsProblem1, setAir2IsPloblem1] = useState(false)
    const [air2IsProblem2, setAir2IsPloblem2] = useState(false)
    const [air2IsProblem3, setAir2IsPloblem3] = useState(false)
    const [air2IsProblem4, setAir2IsPloblem4] = useState(false)
    const [air2IsProblem5, setAir2IsPloblem5] = useState(false)
    const [air2ShowImages, setAir2ShowImages] = useState('')
    const [air2Images, setAir2Images] = useState([])

    const [hasAir3, setHasAir3] = useState(false)
    const [brandName3, setBrandName3] = useState('')
    const [modelName3, setModelName3] = useState('')
    const [air3BTU, setAir3BTU] = useState('')
    const [air3Amount, setAir3Amount] = useState(0)
    const [air3IsOver3M, setAir3IsOver3M] = useState(false)
    const [air3Over3MAmount, setAir3Over3MAmount] = useState(0)
    const [air3IsBelow3M, setAir3IsBelow3M] = useState(false)
    const [air3Below3MAmount, setAir3Below3MAmount] = useState(0)
    const [air3IsProblem1, setAir3IsPloblem1] = useState(false)
    const [air3IsProblem2, setAir3IsPloblem2] = useState(false)
    const [air3IsProblem3, setAir3IsPloblem3] = useState(false)
    const [air3IsProblem4, setAir3IsPloblem4] = useState(false)
    const [air3IsProblem5, setAir3IsPloblem5] = useState(false)
    const [air3ShowImages, setAir3ShowImages] = useState('')
    const [air3Images, setAir3Images] = useState([])

    const [offerUseCoin, setOfferUseCoin] = useState(0)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)

    const [openOfferPostShop, setOpenOfferPostShop] = useState(false)

    const [posterId, setPosterId] = useState('')
    const [posterName, setPosterName] = useState('')
    const [posterScore, setPosterScore] = useState(-1)
    const [numPost, setNumPost] = useState(0)

    const [offerUseCoinErrorDiv, setOfferUseCoinErrorDiv] = useState(false)
    const [offerPriceAir1ErrorDiv, setOfferPriceAir1ErrorDiv] = useState(false)
    const [offerPriceAir2ErrorDiv, setOfferPriceAir2ErrorDiv] = useState(false)
    const [offerPriceAir3ErrorDiv, setOfferPriceAir3ErrorDiv] = useState(false)
    const [offerGarunteeDayErrorDiv, setOfferGarunteeDayErrorDiv] = useState(false)

    const [showLoading, setShowLoading] = useState(true)

    const [question, setQuestion] = useState('')
    const [questions, setQuestions] = useState([])
    const [ownerQuestions, setOwnerQuestion] = useState([])
    const [answer, setAnswer] = useState('')
    const [questionErrorDiv, setQuestionErrorDiv] = useState(false)
    const [answerErrorDiv, setAnswerErrorDiv] = useState(false)

    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    });
    const center = { lat: lat, lng: lng }

    const dateTimeFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH")
        const time_string = new Date(datetime).toLocaleTimeString("th-TH")
        return date_string+' '+time_string
    }

    const validateAnswerForm = () => {
        if(!answer) {
            setAnswerErrorDiv(true)
            return false
        } else {
            setAnswerErrorDiv(false)
        }
        return true
    }

    const handleAnswerSubmit = (memberId) => {
        if(validateAnswerForm()) {
            setShowLoading(true)
            const new_date = new Date()
            setDoc(doc(db, `/cleans/${postId}/questions/`, memberId), {
                last_update: new_date.getTime()
            }).then(() => {
                addDoc(collection(db, `/cleans/${postId}/questions/${memberId}/messages`), {
                    message: answer,
                    message_datetime: new_date.getTime(),
                    from_user: 0,
                    status: 1
                }).then(() => {
                    setAnswer('')
                    setShowLoading(false)
                    getQuestionsOwner(postId)
                })
            })
        }
    }

    const validateQuestionForm = () => {
        if(!question) {
            setQuestionErrorDiv(true)
            return false
        } else {
            setQuestionErrorDiv(false)
        }
        return true
    }

    const handleQuestionSubmit = () => {
        if(validateQuestionForm()) {
            setShowLoading(true)
            const new_date = new Date()
            setDoc(doc(db, `/cleans/${postId}/questions/`, memberId), {
                last_update: new_date.getTime()
            }).then(() => {
                addDoc(collection(db, `/cleans/${postId}/questions/${memberId}/messages`), {
                    message: question,
                    message_datetime: new_date.getTime(),
                    from_user: 1,
                    status: 1
                }).then(() => {
                    setQuestion('')
                    setShowLoading(false)
                    getQuestionsUser(postId, memberId)
                })
            })
        }
    }

    const getQuestionsUser = async (id, memberId) => {
        const questionsQuery = query(collection(db, `cleans/${id}/questions/${memberId}/messages`), orderBy('message_datetime'))
        onSnapshot(questionsQuery, (querySnapshot) => {
            setQuestions(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const getQuestionsOwner = async (id) => {
        ownerQuestions.splice(0, ownerQuestions.length)
        setOwnerQuestion([...ownerQuestions])
        const questionsQuery = query(collection(db, `cleans/${id}/questions/`))
        onSnapshot(questionsQuery, (querySnapshot) => {
            querySnapshot.docs.forEach(async(user) => {
                const userSnapshot = await getDoc(doc(db, 'users', user.id))
                const messageSnapshot = await getDocs(query(collection(db, `cleans/${id}/questions/${user.id}/messages`), orderBy('message_datetime', 'asc')))
                ownerQuestions.push({
                    user_id: userSnapshot.id,
                    user: userSnapshot.data(),
                    messages: messageSnapshot.docs
                })
                setOwnerQuestion([...ownerQuestions], {
                    user_id: userSnapshot.id,
                    user: userSnapshot.data(),
                    messages: messageSnapshot.docs
                })
            })
        })
    }

    const handleOfferStep1Change = () => {
        setOfferStep1(!offerStep1)
    }
    const handleOfferStep2Change = () => {
        setOfferStep2(!offerStep2)
    }
    const handleOfferStep3Change = () => {
        setOfferStep3(!offerStep3)
    }
    const handleOfferStep4Change = () => {
        setOfferStep4(!offerStep4)
    }
    const handleOfferStep5Change = () => {
        setOfferStep5(!offerStep5)
    }
    const handleOfferStep6Change = () => {
        setOfferStep6(!offerStep6)
    }
    const handleOfferStep7Change = () => {
        setOfferStep7(!offerStep7)
    }
    const handleOfferStep8Change = () => {
        setOfferStep8(!offerStep8)
    }
    const handleOfferStep9Change = () => {
        setOfferStep9(!offerStep9)
    }
    const handleOfferStep10Change = () => {
        setOfferStep10(!offerStep10)
    }
    const handleOfferStep11Change = () => {
        setOfferStep11(!offerStep11)
    }
    const handleOfferStep12Change = () => {
        setOfferStep12(!offerStep12)
    }
    const handleOfferStep13Change = () => {
        setOfferStep13(!offerStep13)
    }
    const handleOfferStep14Change = () => {
        setOfferStep14(!offerStep14)
    }
    const handleOfferStep15Change = () => {
        setOfferStep15(!offerStep15)
    }
    const handleOfferStep16Change = () => {
        setOfferStep16(!offerStep16)
    }

    const dateFormat = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const datePickerFormat = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("en-CA"):"")
    }

    const handleCloseOfferPostShop = () => {
        setOpenOfferPostShop(false)
    }

    const handlePickCoin = (event) => {
        setFromCoinOffer(event.target.value);
    }

    const getPost = (id) => {
        setShowLoading(true)
        getDoc(doc(db, 'cleans', id)).then((postDoc) => {
            if (postDoc.exists()) {
                setOnBoard(postDoc.data().onboard)
                setStatus(postDoc.data().status)
                if(postDoc.data().have_air1) {
                    setHasAir1(true)
                    if(postDoc.data().air1_brand_id) {
                        if(postDoc.data().air1_brand_id == '999') {
                            if(postDoc.data().air1_brand) {
                                setBrandName1(postDoc.data().air1_brand)
                            } else {
                                setBrandName1("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_brands`, postDoc.data().air1_brand_id)).then((doc) => {
                                setBrandName1(doc.data().name)
                            })
                        }
                    } else {
                        if(postDoc.data().air1_brand) {
                            setBrandName1(postDoc.data().air1_brand)
                        } else {
                            setBrandName1("ไม่ระบุ")
                        }
                    }

                    if(postDoc.data().air1_model_id) {
                        if(postDoc.data().air1_model_id == '999') {
                            if(postDoc.data().air1_model) {
                                setModelName1(postDoc.data().air1_model)
                            } else {
                                setModelName1("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_models`, postDoc.data().air1_model_id)).then((doc) => {
                                setModelName1(doc.data().name)
                            })
                        }
                    } else  {
                        if(postDoc.data().air1_model) {
                            setModelName1(postDoc.data().air1_model)
                        } else {
                            setModelName1("ไม่ระบุ")
                        }
                    }

                    setAir1BTU(postDoc.data().air1_BTU)
                    setAir1Amount(postDoc.data().air1_amount)
                    setAir1IsOver3M(postDoc.data().air1_is_over_3m)
                    setAir1Over3MAmount(postDoc.data().air1_over_3m_amount)
                    setAir1IsBelow3M(postDoc.data().air1_is_below_3m)
                    setAir1Below3MAmount(postDoc.data().air1_below_3m_amount)
                    setAir1IsPloblem1(postDoc.data().air1_is_problem1)
                    setAir1IsPloblem2(postDoc.data().air1_is_problem2)
                    setAir1IsPloblem3(postDoc.data().air1_is_problem3)
                    setAir1IsPloblem4(postDoc.data().air1_is_problem4)
                    setAir1IsPloblem5(postDoc.data().air1_is_problem5)

                    const imagesQuery = query(collection(db, `cleans/${id}/images1`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setAir1Images(querySnapshot.docs.map((doc, i) => { 
                            if(i == 0) setAir1ShowImages(doc.data().url)
                            return {
                                id: doc.id,
                                data: doc.data(),
                            }
                        }))
                    })

                }

                if(postDoc.data().have_air2) {
                    setHasAir2(true)
                    if(postDoc.data().air2_brand_id) {
                        if(postDoc.data().air2_brand_id == '999') {
                            if(postDoc.data().air2_brand) {
                                setBrandName2(postDoc.data().air2_brand)
                            } else {
                                setBrandName2("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_brands`, postDoc.data().air2_brand_id)).then((doc) => {
                                setBrandName2(doc.data().name)
                            })
                        }
                    } else  {
                        if(postDoc.data().air2_brand) {
                            setBrandName2(postDoc.data().air2_brand)
                        } else {
                            setBrandName2("ไม่ระบุ")
                        }
                    }
                    if(postDoc.data().air2_model_id) {
                        if(postDoc.data().air2_model_id == '999') {
                            if(postDoc.data().air2_model) {
                                setModelName2(postDoc.data().air2_model)
                            } else {
                                setModelName2("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_models`, postDoc.data().air2_model_id)).then((doc) => {
                                setModelName2(doc.data().name)
                            })
                        }
                    } else  {
                        if(postDoc.data().air2_model) {
                            setModelName2(postDoc.data().air2_model)
                        } else {
                            setModelName2("ไม่ระบุ")
                        }
                    }

                    setAir2BTU(postDoc.data().air2_BTU)
                    setAir2Amount(postDoc.data().air2_amount)
                    setAir2IsOver3M(postDoc.data().air2_is_over_3m)
                    setAir2Over3MAmount(postDoc.data().air2_over_3m_amount)
                    setAir2IsBelow3M(postDoc.data().air2_is_below_3m)
                    setAir2Below3MAmount(postDoc.data().air2_below_3m_amount)
                    setAir2IsPloblem1(postDoc.data().air2_is_problem1)
                    setAir2IsPloblem2(postDoc.data().air2_is_problem2)
                    setAir2IsPloblem3(postDoc.data().air2_is_problem3)
                    setAir2IsPloblem4(postDoc.data().air2_is_problem4)
                    setAir2IsPloblem5(postDoc.data().air2_is_problem5)
                    
                    const imagesQuery = query(collection(db, `cleans/${id}/images2`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setAir2Images(querySnapshot.docs.map((doc, i) => { 
                            if(i == 0) setAir2ShowImages(doc.data().url)
                            return {
                                id: doc.id,
                                data: doc.data(),
                            }
                        }))
                    })

                }

                if(postDoc.data().have_air3) {
                    setHasAir3(true)
                    if(postDoc.data().air3_brand_id) {
                        if(postDoc.data().air3_brand_id == '999') {
                            if(postDoc.data().air3_brand) {
                                setBrandName3(postDoc.data().air3_brand)
                            } else {
                                setBrandName3("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_brands`, postDoc.data().air3_brand_id)).then((doc) => {
                                setBrandName3(doc.data().name)
                            })
                        }
                    } else {
                        if(postDoc.data().air3_brand) {
                            setBrandName3(postDoc.data().air3_brand)
                        } else {
                            setBrandName3("ไม่ระบุ")
                        }
                    }
                    if(postDoc.data().air3_model_id) {
                        if(postDoc.data().air3_model_id == '999') {
                            if(postDoc.data().air3_model) {
                                setModelName3(postDoc.data().air3_model)
                            } else {
                                setModelName3("ไม่ระบุ")
                            }
                        } else {
                            getDoc(doc(db, `air_models`, postDoc.data().air3_model_id)).then((doc) => {
                                setModelName3(doc.data().name)
                            })
                        }
                    } else {
                        if(postDoc.data().air3_model) {
                            setModelName3(postDoc.data().air3_model)
                        } else {
                            setModelName3("ไม่ระบุ")
                        }
                    }

                    setAir3BTU(postDoc.data().air3_BTU)
                    setAir3Amount(postDoc.data().air3_amount)
                    setAir3IsOver3M(postDoc.data().air3_is_over_3m)
                    setAir3Over3MAmount(postDoc.data().air3_over_3m_amount)
                    setAir3IsBelow3M(postDoc.data().air3_is_below_3m)
                    setAir3Below3MAmount(postDoc.data().air3_below_3m_amount)
                    setAir3IsPloblem1(postDoc.data().air3_is_problem1)
                    setAir3IsPloblem2(postDoc.data().air3_is_problem2)
                    setAir3IsPloblem3(postDoc.data().air3_is_problem3)
                    setAir3IsPloblem4(postDoc.data().air3_is_problem4)
                    setAir3IsPloblem5(postDoc.data().air3_is_problem5)
                    
                    const imagesQuery = query(collection(db, `cleans/${id}/images3`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setAir3Images(querySnapshot.docs.map((doc, i) => { 
                            if(i == 0) setAir3ShowImages(doc.data().url)
                            return {
                                id: doc.id,
                                data: doc.data(),
                            }
                        }))
                    })
                }

                getDoc(doc(db, `users/`, postDoc.data().poster_id)).then((posterDoc) => {
                    const dataPoster = posterDoc.data()
                    if(dataPoster.display_name) setPosterName(dataPoster.display_name)
                    getDocs(query(collection(db, 'posts'), 
                            where('poster_id', '==', postDoc.data().poster_id),
                            where('status', 'not-in', [5, 6])
                    )).then((numPosts) => {
                        var num = 0
                        num = num + numPosts.docs.length
                        getDocs(query(collection(db, 'cleans'), 
                            where('poster_id', '==', postDoc.data().poster_id),
                            where('status', 'not-in', [5, 6])
                        )).then((numCleans) => {
                            num = num + numCleans.docs.length
                            setNumPost(num)
                        })
                    })
                    if(dataPoster.score) { 
                        setPosterScore(dataPoster.score) 
                    } else {
                        setPosterScore(0)
                    }
                })

                setPosterId(postDoc.data().poster_id)
                setPost(postDoc.data())
                setLat(postDoc.data().lat)
                setLng(postDoc.data().lng)
                setAddress(postDoc.data().address)
                setAddressNo(postDoc.data().address_no)
                setVillage(postDoc.data().village)
                setBuilding(postDoc.data().building)
                setFloor(postDoc.data().floor)
                setRoom(postDoc.data().room)
                setSubroad(postDoc.data().subroad)
                setRoad(postDoc.data().road)

                var promisesTask = []

                if(postDoc.data().province) {
                    const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', postDoc.data().province * 1))).then((docs) => {
                        if(docs.docs.length > 0) setProvince(docs.docs[0].data().province_name)
                    })
                    promisesTask.push(provinceTask)
                }

                if(postDoc.data().district) {
                    const districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', postDoc.data().district))).then((docs) => {
                        if(docs.docs.length > 0) setDistrict(docs.docs[0].data().district_name)
                    })
                    promisesTask.push(districtTask)
                }

                if(postDoc.data().sub_district) {
                    const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', postDoc.data().sub_district * 1))).then((docs) => {
                        if(docs.docs.length > 0) setSubDistrict(docs.docs[0].data().subdistrict_name)
                    })
                    promisesTask.push(subdistrictTask)
                }

                setPostcode(postDoc.data().postcode)

                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                })
                
                if(postDoc.data().views) {
                    setPostView((postDoc.data().views*1) + 1)
                    updateDoc(doc(db, `/cleans`, postId), {
                        views: (postDoc.data().views*1) + 1
                    })
                } else {
                    setPostView(1)
                    updateDoc(doc(db, `/cleans`, postId), {
                        views: 1
                    })
                }
            } else {
                console.log("Post doesn't exist")
                navigate('/')
            }
        }).then(() => {
            getDoc(doc(db, 'coins', 'use_rate')).then((exchangeSnapshot) => {
                const useCoin = exchangeSnapshot.data()
                setOfferUseCoin(useCoin.board5_2)
            })
        })
    }

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMemberId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)
                if(data.shop_id) {
                    setShopId(data.shop_id)
                    getDoc(doc(db, `shops/`, data.shop_id)).then((shopDoc) => {
                        const dataShop = shopDoc.data()
                        if(dataShop.freecoin) setOfferFreeCoin(dataShop.freecoin)
                        if(dataShop.exchange_coin) setOfferExchangeCoin(dataShop.exchange_coin)
                    })
                    getDocs(query(collection(db, `cleans/${postId}/offers`), where("shop_id", "==", data.shop_id))).then((offers) => {
                        if (offers.docs.length > 0) {
                            const dataOffer = offers.docs[0].data()
                            setOffered(true)
                            setOffer(dataOffer)
                        }
                    }).catch((error) => {
                        alert(error.message)
                    })
                }
                if(post.poster_id != docs.docs[0].id) {
                    getQuestionsUser(postId, docs.docs[0].id)
                }
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    const validateOfferForm = () => {
        if(fromCoinOffer == 'freecoin') {
            if(offerFreeCoin < offerUseCoin) {
                return false
            }
        } else {
            if(offerExchangeCoin < offerUseCoin) {
                return false
            }
        }

        if(hasAir1) {
            if(!offerPriceAir1) {
                return false
            }
        }
        if(hasAir2) {
            if(!offerPriceAir2) {
                return false
            }
        }
        if(hasAir3) {
            if(!offerPriceAir3) {
                return false
            }
        }

        if(!offerGarunteeDay) {
            return false
        }
        return true
    }
    
    const handleOfferSubmit = () => {
        if(validateOfferForm()) {
            const new_date = new Date()
            addDoc(collection(db, `/cleans/${postId}/offers`), {
                shop_id: shopId,
                pick_date: pickDate.toDate(),
                hour: pickHour,
                minute: pickMinute,
                message: offerMessage,

                offer_deposit: offerDeposit,
                offer_travel: offerTravel,
                offer_travel_price: offerTravelPrice*1,
                offer_garuntee: offerGaruntee,
                offer_garuntee_day: offerGarunteeDay*1,
                offer_price_air1: offerPriceAir1*1,
                offer_price_air2: offerPriceAir2*1,
                offer_price_air3: offerPriceAir3*1,

                offer_step1: offerStep1,
                offer_step2: offerStep2,
                offer_step3: offerStep3,
                offer_step4: offerStep4,
                offer_step5: offerStep5,
                offer_step6: offerStep6,
                offer_step7: offerStep7,
                offer_step8: offerStep8,
                offer_step9: offerStep9,
                offer_step10: offerStep10,
                offer_step11: offerStep11,
                offer_step12: offerStep12,
                offer_step13: offerStep13,
                offer_step14: offerStep14,
                offer_step15: offerStep15,
                offer_step16: offerStep16,

                offer_timestamp: new_date.getTime(),
                status: 1
            }).then(function(docRef) {
                updateDoc(doc(db, `/cleans`, postId), {
                    status: 2
                })
                addDoc(collection(db, `/shops/${shopId}/offers`), {
                    post_id: postId,
                    board: 'cleans',
                    offer_id: docRef.id,
                    pick_date: pickDate.toDate(),
                    hour: pickHour,
                    minute: pickMinute,
                    message: offerMessage,
                    offer_timestamp: new_date.getTime(),
                    status: 1
                }).then(() => {
                    const log_date = new Date()
                    var event = "ยื่นข้อเสนอจ้างล้างแอร์"
                    addDoc(collection(db, `/shops/${shopId}/coin_log`), {
                        date: log_date,
                        coin: offerUseCoin,
                        event: event,
                        post_id: postId,
                        coin_type: fromCoinOffer
                    }).then(() => {
                        if(fromCoinOffer == 'freecoin') {
                            updateDoc(doc(db, `/shops`, shopId), {
                                freecoin: (offerFreeCoin*1)-(offerUseCoin*1)
                            })
                        } else if(fromCoinOffer == 'exchange') {
                            updateDoc(doc(db, `/shops`, shopId), {
                                exchange_coin: (offerExchangeCoin*1)-(offerUseCoin*1)
                            })
                        }
                        const date = new Date()
                        var link = "/profile/posts/air"
                        addDoc(collection(db, `/users/${post.poster_id}/notifications`), {
                            date: date.getTime(),
                            message: "มีข้อเสนอใหม่ในประกาศของคุณ",
                            type: 'post',
                            post_id: postId,
                            link: link,
                            status: 0
                        })
                    }).then(() => {
                        if(fromCoinOffer == 'freecoin') {
                            getDoc(doc(db, 'free_coins', 'sum_data')).then((sumDoc) => {
                                updateDoc(doc(db, 'free_coins', 'sum_data'), {
                                    used: (sumDoc.data().used*1) + (offerUseCoin*1)
                                }).then(() => {
                                    addDoc(collection(db, `/coins/used_log/transaction`), {
                                        date: log_date,
                                        coin: offerUseCoin,
                                        event: event,
                                        post_id: postId,
                                        coin_type: fromCoinOffer,
                                        user_id: memberId
                                    })
                                }).then(() => {
                                    navigate(0)
                                })
                            })
                        } else if(fromCoinOffer == 'exchange') {
                            getDoc(doc(db, 'exchange_coins', 'sum_data')).then((sumDoc) => {
                                updateDoc(doc(db, 'exchange_coins', 'sum_data'), {
                                    used: (sumDoc.data().used*1) + (offerUseCoin*1),
                                    all: (sumDoc.data().all*1) + (offerUseCoin*1)
                                }).then(() => {
                                    addDoc(collection(db, `/coins/used_log/transaction`), {
                                        date: log_date,
                                        coin: offerUseCoin,
                                        event: event,
                                        post_id: postId,
                                        coin_type: fromCoinOffer,
                                        user_id: memberId
                                    })
                                }).then(() => {
                                    navigate(0)
                                })
                            })
                        }
                    })
                })
            }).catch((error) => {
                console.log(error)
                navigate('/')
            })
        }
    }

    const handleClose = () => {

    }

    const resizeDiv = new ResizeObserver((event) => {
        //console.log(event[0].contentBoxSize[0].inlineSize)
        //console.log(event[0])
        setWidth(event[0].contentBoxSize[0].inlineSize-1)
        setHeight(imagePostHeight)
    })

    useEffect(() => {
        resizeDiv.observe(document.getElementById("divPost"))
    })

    const styleLoading = {
        position: 'absolute',
        top: '25%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        boxShadow: 24,
        p: 4,
    };

    useEffect(() => {
        if (loading) return;
        if (user) getDocUser()
        getPost(postId)
        getSettings()
    }, [loading])

    const [offerFunction, setOfferFunction] = useState(true)

    const getSettings = () => {
        getDoc(doc(db, 'settings', 'functions')).then((result) => {
            const data = result.data()
            if(data.offer) { setOfferFunction(true) } else { setOfferFunction(false) }
        })
    }

    return (
        <>
            <Modal
                open={showLoading}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={styleLoading}>
                    <Typography id="modal-modal-title" variant="h6" component="h2">
                    Loading
                    </Typography>
                    <LinearProgress style={{ marginTop: 20}} />
                </Box>
            </Modal>
            <div className="container-fluid">
                <div className="flex-container" style={{ paddingTop: 20, paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div id="divPost" style={{ display: 'flex' }}>
                            <p style={{ fontSize: h1FontSize, fontWeight: '600', color: '#3D5598', marginBottom: 0 }}>แจ้งความต้องการล้างแอร์</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="flex-container" style={{ flex: 1 }}>
                                <font style={{ fontSize: h2FontSize, fontWeight: '600' }}>&nbsp;</font>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', wordWrap: 'unset', alignItems: 'end', marginBottom: 3, marginLeft: 10 }}>
                                <font style={{ fontSize: h3FontSize, color: '#626167' }}>จำนวนผู้เข้าชม {number_format.format(postView)}</font>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FBEFE8', padding: 15 }}>
                            <div className="flex-container" style={{ marginBottom: 0 }}>
                                <font style={{ fontSize: h2FontSize, fontWeight: '600', color: '#3D5598', marginRight: 5 }}>ผู้ประกาศ:&nbsp;<font style={{ fontWeight: '400' }}>{posterName}</font></font>
                                <font className="flex-container" style={{ marginTop: 5 }}><StarRating score={posterScore} /> <div style={{ marginBottom: 0 }}>({number_format.format(posterScore)} คะแนน)</div></font>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
                                <font className="flex-container" style={{ fontSize: h3FontSize, marginBottom: 0, color: '#626167' }}>
                                    <font style={{ display: 'flex', flexDirection: 'column' }}>จำนวนประกาศทั้งหมด {numPost} ครั้ง&nbsp;</font>
                                    <font style={{ display: 'flex', flexDirection: 'column' }}><Link to={`/postlist/air/${posterId}`} target='_blank'>[คลิกเพื่อดูประกาศทั้งหมดของผู้ประกาศรายนี้]</Link></font>
                                </font>
                            </div>
                        </div>
                        {offerFunction && 
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {(isShop == 1 && !offered && (post.poster_id != memberId) && (status == 1 || status ==2)) &&
                            (<div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', flex: 1, paddingTop: 10 }} className="d-sm-none">
                                <div style={{ minWidth: 250, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                    <button type="button" className="btn btn-primary" style={{ width: 250 }} 
                                        onClick={() => setOpenOfferPostShop(true)}>
                                        <AiOutlineCheckCircle style={{ fontSize: h2FontSize, marginRight: 5, marginBottom: 3 }} />
                                        <font style={{ fontSize: h3FontSize }}>{(post.onboard==2 || post.onboard==3)?"ยื่นข้อเสนอรับบริการ":"ยื่นข้อเสนอรับบริการ"}</font>
                                    </button>
                                </div>
                            </div>)
                        }
                        </div>
                        }
                    </div>
                </div>
                {hasAir1 &&
                <div className="flex-container" style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div id="divPost" style={{ display: 'flex' }}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', color: '#3D5598', marginBottom: 0 }}>ต้องการบริการล้างแอร์ติดผนัง</p>
                        </div>
                        <div id="divPost" style={{ display: 'flex', flexDirection: 'column', marginTop: 5, borderWidth: 1, borderColor: '#000000', borderStyle: 'inset', borderTopRightRadius: 15, borderTopLeftRadius: 15 }}>
                            <img style={{ display: 'flex', objectFit: "cover", borderTopRightRadius: 15, borderTopLeftRadius: 15, maxWidth: width }} src={air1ShowImages} alt="APP3R" />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', paddingTop: 10, paddingBottom: 5 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
                                {air1Images?.map((image, i) => (
                                    <div key={image.id}  style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', paddingLeft: 10, paddingRight: 10 }}>
                                        <img style={{ maxWidth: thumpnailWidth }} src={image.data.url} alt="APP3R" onClick={() => { setAir1ShowImages(image.data.url) }} />
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>รุ่น: <font style={{ fontWeight: '400' }}>{modelName1}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>ยี่ห้อ: <font style={{ fontWeight: '400' }}>{brandName1}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>ขนาด: <font style={{ fontWeight: '400' }}>{number_format.format(air1BTU)} BTU</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>จำนวน: <font style={{ fontWeight: '400' }}>{air1Amount} เครื่อง</font></p>
                        </div>
                        {air1IsBelow3M && 
                            <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                                <p style={{ fontSize: h2FontSize, fontWeight: '400', marginBottom: 0 }}>มีคอยล์เย็นติดตั้งสูงไม่เกิน 3 เมตร จำนวน {air1Below3MAmount} เครื่อง</p>
                            </div>
                        }
                        {air1IsOver3M && 
                            <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                                <p style={{ fontSize: h2FontSize, fontWeight: '400', marginBottom: 0 }}>มีคอยล์เย็นติดตั้งสูงเกิน 3 เมตร จำนวน {air1Over3MAmount} เครื่อง</p>
                            </div>
                        }
                        {
                            (air1IsProblem1 || air1IsProblem2 || air1IsProblem3 || air1IsProblem4 || air1IsProblem5) && 
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: h3FontSize, fontWeight: '600', marginBottom: 0 }}>แอร์มีอาการดังต่อไปนี้: 
                                        <font style={{ fontWeight: '400' }}>
                                            {(!air1IsProblem1 && !air1IsProblem2 && !air1IsProblem3 && !air1IsProblem4 && !air1IsProblem5)?" ไม่ระบุ":""}
                                            {air1IsProblem1?(<><br/>- บานสวิงทำงานไม่ปกติ</>):""}
                                            {air1IsProblem2?(<><br/>- คอยล์เย็นมีเสียงดังหรือทำงานผิดปกติ</>):""}
                                            {air1IsProblem3?(<><br/>- คอยล์ร้อนมีเสียงดังหรือทำงานผิดปกติ</>):""}
                                            {air1IsProblem4?(<><br/>- มีน้ำรั่วซึมหรือถาดน้ำทิ้งสภาพไม่ปกติ</>):""}
                                            {air1IsProblem5?(<><br/>- หน้ากากแอร์หลุดไม่ครบสภาพไม่ปกติ</>):""}
                                        </font>
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>}
                {hasAir2 &&
                <div className="flex-container" style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div id="divPost" style={{ display: 'flex' }}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', color: '#3D5598', marginBottom: 0 }}>ต้องการบริการล้างแอร์แขวนใต้ฝ้า</p>
                        </div>
                        <div id="divPost" style={{ display: 'flex', flexDirection: 'column', marginTop: 5, borderWidth: 1, borderColor: '#000000', borderStyle: 'inset', borderTopRightRadius: 15, borderTopLeftRadius: 15 }}>
                            <img style={{ display: 'flex', objectFit: "cover", borderTopRightRadius: 15, borderTopLeftRadius: 15, maxWidth: width }} src={air2ShowImages} alt="APP3R" />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', paddingTop: 10, paddingBottom: 5 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
                                {air2Images?.map((image, i) => (
                                    <div key={image.id}  style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', paddingLeft: 10, paddingRight: 10 }}>
                                        <img style={{ maxWidth: thumpnailWidth }} src={image.data.url} alt="APP3R" onClick={() => { setAir2ShowImages(image.data.url) }} />
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>รุ่น: <font style={{ fontWeight: '400' }}>{modelName2}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>ยี่ห้อ: <font style={{ fontWeight: '400' }}>{brandName2}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>ขนาด: <font style={{ fontWeight: '400' }}>{number_format.format(air2BTU)} BTU</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>จำนวน: <font style={{ fontWeight: '400' }}>{air2Amount} เครื่อง</font></p>
                        </div>
                        {air2IsBelow3M && 
                            <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                                <p style={{ fontSize: h2FontSize, fontWeight: '400', marginBottom: 0 }}>มีคอยล์เย็นติดตั้งสูงไม่เกิน 3 เมตร จำนวน {air2Below3MAmount} เครื่อง</p>
                            </div>
                        }
                        {air2IsOver3M && 
                            <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                                <p style={{ fontSize: h2FontSize, fontWeight: '400', marginBottom: 0 }}>มีคอยล์เย็นติดตั้งสูงเกิน 3 เมตร จำนวน {air2Over3MAmount} เครื่อง</p>
                            </div>
                        }
                        {
                            (air2IsProblem1 || air2IsProblem2 || air2IsProblem3 || air2IsProblem4 || air2IsProblem5) && 
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: h3FontSize, fontWeight: '600', marginBottom: 0 }}>แอร์มีอาการดังต่อไปนี้: 
                                        <font style={{ fontWeight: '400' }}>
                                            {(!air2IsProblem1 && !air2IsProblem2 && !air2IsProblem3 && !air2IsProblem4 && !air2IsProblem5)?" ไม่ระบุ":""}
                                            {air2IsProblem1?(<><br/>- บานสวิงทำงานไม่ปกติ</>):""}
                                            {air2IsProblem2?(<><br/>- คอยล์เย็นมีเสียงดังหรือทำงานผิดปกติ</>):""}
                                            {air2IsProblem3?(<><br/>- คอยล์ร้อนมีเสียงดังหรือทำงานผิดปกติ</>):""}
                                            {air2IsProblem4?(<><br/>- มีน้ำรั่วซึมหรือถาดน้ำทิ้งสภาพไม่ปกติ</>):""}
                                            {air2IsProblem5?(<><br/>- หน้ากากแอร์หลุดไม่ครบสภาพไม่ปกติ</>):""}
                                        </font>
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>}
                {hasAir3 &&
                <div className="flex-container" style={{ paddingTop: 10, paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div id="divPost" style={{ display: 'flex' }}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', color: '#3D5598', marginBottom: 0 }}>ต้องการบริการล้างแอร์ฝังฝ้า 4 ทิศทาง</p>
                        </div>
                        <div id="divPost" style={{ display: 'flex', flexDirection: 'column', marginTop: 5, borderWidth: 1, borderColor: '#000000', borderStyle: 'inset', borderTopRightRadius: 15, borderTopLeftRadius: 15 }}>
                            <img style={{ display: 'flex', objectFit: "cover", borderTopRightRadius: 15, borderTopLeftRadius: 15, maxWidth: width }} src={air3ShowImages} alt="APP3R" />
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', paddingTop: 10, paddingBottom: 5 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
                                {air3Images?.map((image, i) => (
                                    <div key={image.id}  style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', paddingLeft: 10, paddingRight: 10 }}>
                                        <img style={{ maxWidth: thumpnailWidth }} src={image.data.url} alt="APP3R" onClick={() => { setAir3ShowImages(image.data.url) }} />
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>รุ่น: <font style={{ fontWeight: '400' }}>{modelName3}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>ยี่ห้อ: <font style={{ fontWeight: '400' }}>{brandName3}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>ขนาด: <font style={{ fontWeight: '400' }}>{number_format.format(air3BTU)} BTU</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>จำนวน: <font style={{ fontWeight: '400' }}>{air3Amount} เครื่อง</font></p>
                        </div>
                        {air3IsBelow3M && 
                            <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                                <p style={{ fontSize: h2FontSize, fontWeight: '400', marginBottom: 0 }}>มีคอยล์เย็นติดตั้งสูงไม่เกิน 3 เมตร จำนวน {air3Below3MAmount} เครื่อง</p>
                            </div>
                        }
                        {air3IsOver3M && 
                            <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                                <p style={{ fontSize: h2FontSize, fontWeight: '400', marginBottom: 0 }}>มีคอยล์เย็นติดตั้งสูงเกิน 3 เมตร จำนวน {air3Over3MAmount} เครื่อง</p>
                            </div>
                        }
                        {
                            (air3IsProblem1 || air3IsProblem2 || air3IsProblem3 || air3IsProblem4 || air3IsProblem5) && 
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <p style={{ fontSize: h3FontSize, fontWeight: '600', marginBottom: 0 }}>แอร์มีอาการดังต่อไปนี้: 
                                        <font style={{ fontWeight: '400' }}>
                                            {(!air3IsProblem1 && !air3IsProblem2 && !air3IsProblem3 && !air3IsProblem4 && !air3IsProblem5)?" ไม่ระบุ":""}
                                            {air3IsProblem1?(<><br/>- บานสวิงทำงานไม่ปกติ</>):""}
                                            {air3IsProblem2?(<><br/>- คอยล์เย็นมีเสียงดังหรือทำงานผิดปกติ</>):""}
                                            {air3IsProblem3?(<><br/>- คอยล์ร้อนมีเสียงดังหรือทำงานผิดปกติ</>):""}
                                            {air3IsProblem4?(<><br/>- มีน้ำรั่วซึมหรือถาดน้ำทิ้งสภาพไม่ปกติ</>):""}
                                            {air3IsProblem5?(<><br/>- หน้ากากแอร์หลุดไม่ครบสภาพไม่ปกติ</>):""}
                                        </font>
                                    </p>
                                </div>
                            </div>
                        }
                    </div>
                </div>}
                <div className="flex-container" style={{ paddingTop: 20, paddingBottom: 10, paddingLeft: 10, paddingRight: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {(isShop == 1 && !offered && (post.poster_id != memberId) && (status == 1 || status ==2)) &&
                            (<div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ minWidth: 250, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                    <button type="button" className="btn btn-primary" style={{ width: 250 }} 
                                        onClick={() => setOpenOfferPostShop(true)}>
                                        <AiOutlineCheckCircle style={{ fontSize: h2FontSize, marginRight: 5, marginBottom: 3 }} />
                                        <font style={{ fontSize: h3FontSize }}>{(post.onboard==2 || post.onboard==3)?"ยื่นข้อเสนอรับบริการ":"ยื่นข้อเสนอรับบริการ"}</font>
                                    </button>
                                </div>
                            </div>)
                        }
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600' }}>วันเวลาที่สะดวก: {dateFormat(post.pick_date)} เวลา {post.hour}:{post.minute} น.</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <p style={{ fontSize: textFontSize, fontWeight: '400', marginBottom: 0 }}>ที่อยู่: {(addressNo)?"บ้านเลขที่ "+addressNo+" ":""} 
                                {(village)?"หมู่บ้าน "+village+" ":""} 
                                {(building)?"อาคาร "+building+" ":""} 
                                {(floor)?"ชั้น "+floor+" ":""} 
                                {(room)?"ห้อง "+room+" ":""} 
                                {(subroad)?"ซอย "+subroad+" ":""} 
                                {(road)?"ถ."+road+" ":""} 
                                {(subdistrict)?subdistrict+" ":""} 
                                {(district)?district+" ":""} 
                                {(province)?province+" ":""} 
                                {(postcode)?postcode+" ":""}
                            </p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <p style={{ fontSize: textFontSize, fontWeight: '400' }}>ที่อยู่เพิ่มเติม: {post.address?post.address:"ไม่ระบุ"}</p>
                        </div>

                        {(user && (post.poster_id != memberId)) && (<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F7FB', padding: 15 }}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 5 }}>คุยกับผู้ประกาศ</p>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {questions?.map((message, i) => 
                                <div style={{ display: 'flex', flexDirection: 'column' }} key={i}>
                                    {(message.data.from_user == 1) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'end' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: 'lightyellow', borderRadius: 15,
                                            }}>{message.data.message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'end' }}>{dateTimeFormat(message.data.message_datetime)}</p>
                                    </div>}

                                    {(message.data.from_user == 0) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'start' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: '#FFFFFF', borderRadius: 15,
                                            }}>{message.data.message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'start' }}>{dateTimeFormat(message.data.message_datetime)}</p>
                                    </div>}
                                </div>
                            )}
                            </div>
                            {((status == 1 || status ==2)) && (
                            <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 10}}>
                                    <input type='text' name='question' onChange={(e) => setQuestion(e.target.value)} 
                                        value={question} placeholder='ข้อความ'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    {questionErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกข้อความ</strong>
                                        </div>
                                    }
                                </div>
                                <button type="button" className="btn btn-success" style={{ width: 150 }} 
                                    onClick={handleQuestionSubmit}>
                                    <AiOutlinePlusCircle style={{ fontSize: textFontSize, marginRight: 5, marginBottom: 3 }} />
                                    <font style={{ fontSize: textFontSize }}>ส่งข้อความ</font>
                                </button>
                            </div>)}
                        </div>)}
                        {((post.poster_id == memberId)) && (<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F7FB', padding: 15 }}>
                            {ownerQuestions?.map((user, j) => (<>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 5 }}>ข้อความจาก {user.user.firstname} {user.user.lastname}</p>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {user.messages?.map((message, i) => 
                                <div style={{ display: 'flex', flexDirection: 'column' }}  key={i}>
                                    {(message.data().from_user == 0) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'end' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: 'lightyellow', borderRadius: 15,
                                            }}>{message.data().message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'end' }}>{dateTimeFormat(message.data().message_datetime)}</p>
                                    </div>}

                                    {(message.data().from_user == 1) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'start' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: '#FFFFFF', borderRadius: 15,
                                            }}>{message.data().message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'start' }}>{dateTimeFormat(message.data().message_datetime)}</p>
                                    </div>}
                                </div>
                            )}
                            </div>
                            <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 10}}>
                                    <input type='text' name='answer' onChange={(e) => setAnswer(e.target.value)} 
                                        value={answer} placeholder='ข้อความ'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    {answerErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกข้อความ</strong>
                                        </div>
                                    }
                                </div>
                                <button type="button" className="btn btn-success" style={{ width: 150 }} 
                                    onClick={() => { handleAnswerSubmit(user.user_id) }}>
                                    <AiOutlinePlusCircle style={{ fontSize: textFontSize, marginRight: 5, marginBottom: 3 }} />
                                    <font style={{ fontSize: textFontSize }}>ส่งข้อความ</font>
                                </button>
                            </div>
                            </>)
                            )}
                        </div>)}
                    </div>
                </div>
            </div>

            <Dialog open={openOfferPostShop} onClose={handleCloseOfferPostShop} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt'}}>
                        <h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                        <strong>ยื่นข้อเสนอแจ้งความต้องการล้างแอร์</strong></h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="container">
                        <div className="row gy-5 gx-0">
                            <div className="col-xs-12 col-md-4">
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                    <label className="mb-3" style={{ display: 'flex', flexDirection: 'row', borderBottom: "1px solid #ddd"}}>
                                        <ImCoinDollar style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>หักเหรียญจาก</strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flex: 1, paddingLeft: 30, flexDirection: 'column' }}>
                                        <RadioGroup
                                            defaultValue="freecoin" name="fromCoinOffer"
                                            value={fromCoinOffer} onChange={handlePickCoin}
                                        >
                                            <FormControlLabel control={<Radio />} value='freecoin'
                                                label={<span style={{ fontFamily: 'Prompt' }}>{"เหรียญแจกฟรี "}<strong>{number_format.format(offerFreeCoin)}</strong> เหรียญ</span>}
                                            />
                                            <FormControlLabel control={<Radio />} value='exchange'
                                                label={<span style={{ fontFamily: 'Prompt' }}>{"เหรียญแลกเปลี่ยน "}<strong>{number_format.format(offerExchangeCoin)}</strong> เหรียญ</span>}
                                            />
                                        </RadioGroup>
                                    </div>
                                </div>
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                    <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <MdOutlineCalendarToday style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>วันเวลาที่ผู้ประกาศสะดวก</strong></h5>
                                    </label>
                                    <div style={{ }}><h6 style={{ marginLeft: 30 }}>{dateFormat(post.pick_date)} เวลา {post.hour}:{post.minute} น.</h6></div>
                                </div>
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <MdOutlineCalendarToday style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>วันเวลาที่พร้อมให้บริการ</strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flex: 1, paddingLeft: 30 }}>
                                        <div className="row gx-1 gy-3">
                                            <div className="col-xs-12 col-md-7">
                                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                    <DatePicker slotProps={{ textField: { size: 'small' } }} format="DD/MM/YYYY" minDate={today} defaultValue={dayjs(datePickerFormat(post.pick_date))} onChange={(value) => setPickDate(value)} />
                                                </LocalizationProvider>
                                            </div> 
                                            <div className="col-xs-12 col-md-5">
                                                <div className="d-flex">
                                                    <div class="flex-fill">
                                                        <select className="form-select" name='hour' onChange={(e) => setPickHour(e.target.value)}>
                                                            <option value="00">00</option>
                                                            <option value="01">01</option>
                                                            <option value="02">02</option>
                                                            <option value="03">03</option>
                                                            <option value="04">04</option>
                                                            <option value="05">05</option>
                                                            <option value="06">06</option>
                                                            <option value="07">07</option>
                                                            <option value="08">08</option>
                                                            <option value="09">09</option>
                                                            <option value="10">10</option>
                                                            <option value="11">11</option>
                                                            <option value="12">12</option>
                                                            <option value="13">13</option>
                                                            <option value="14">14</option>
                                                            <option value="15">15</option>
                                                            <option value="16">16</option>
                                                            <option value="17">17</option>
                                                            <option value="18">18</option>
                                                            <option value="19">19</option>
                                                            <option value="20">20</option>
                                                            <option value="21">21</option>
                                                            <option value="22">22</option>
                                                            <option value="23">23</option>
                                                        </select>
                                                    </div>
                                                    <div class="flex-fill">
                                                        <select className="form-select" name='minute' onChange={(e) => setPickMinute(e.target.value)}>
                                                            <option value="00">00</option>
                                                            <option value="15">15</option>
                                                            <option value="30">30</option>
                                                            <option value="45">45</option>
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div> 
                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                    <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                        <MdOutlineMessage style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                        <h5><strong>ข้อความเพิ่มเติม</strong></h5>
                                    </label>
                                    <div style={{ display: 'flex', flex: 1, paddingLeft: 30 }}>
                                        <textarea className="form-control" style={{ display: 'flex', flex: 1, height: 100 }}
                                            onChange={(e) => setOfferMessage(e.target.value)}
                                            placeholder='ข้อความเพิ่มเติม' 
                                            value={offerMessage}></textarea>
                                    </div>
                                </div> 
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <div style={{ display:'flex', flexDirection: 'column', flex: 1, marginLeft: 10 }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                            <label className="mb-3" style={{ display: 'flex', flexDirection: 'row', borderBottom: "1px solid #ddd"}}>
                                                <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>เสนอให้บริการ</strong></h5>
                                            </label>
                                            <div style={{ display: 'flex', flex: 1, paddingLeft: 30, flexDirection: 'column' }}>
                                                {hasAir1 && (
                                                    <>
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10}}>
                                                        <div><strong>บริการล้างแอร์ติดผนัง</strong> {brandName1} {modelName1} ขนาด {number_format.format(air1BTU)} BTU จำนวน {air1Amount} เครื่อง</div>
                                                        <div> ราคา <input type='text' name='offerPriceAir1' onChange={(e) => setOfferPriceAir1(e.target.value)} 
                                                                value={offerPriceAir1} placeholder='ราคา (บาท)'
                                                                style={{ paddingLeft: 8, marginLeft: 5, marginRight: 5, maxWidth: 100 }} /> บาท </div>
                                                    </div>
                                                    </>
                                                )}
                                                {hasAir2 && (
                                                    <>
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10}}>
                                                        <div><strong>บริการล้างแอร์แขวนใต้ฝ้า</strong> {brandName2} {modelName2} ขนาด {number_format.format(air2BTU)} BTU จำนวน {air2Amount} เครื่อง</div>
                                                        <div> ราคา <input type='text' name='offerPriceAir2' onChange={(e) => setOfferPriceAir2(e.target.value)} 
                                                                value={offerPriceAir2} placeholder='ราคา (บาท)'
                                                                style={{ paddingLeft: 8, marginLeft: 5, marginRight: 5, maxWidth: 100 }} /> บาท </div>
                                                    
                                                    </div>
                                                    </>
                                                )}
                                                {hasAir3 && (
                                                    <>
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10}}>
                                                        <div><strong>บริการล้างแอร์ฝังฝ้า 4 ทิศทาง</strong> {brandName3} {modelName3} ขนาด {number_format.format(air3BTU)} BTU จำนวน {air3Amount} เครื่อง</div>
                                                        <div> ราคา <input type='text' name='offerPriceAir3' onChange={(e) => setOfferPriceAir3(e.target.value)} 
                                                                value={offerPriceAir3} placeholder='ราคา (บาท)'
                                                                style={{ paddingLeft: 8, marginLeft: 5, marginRight: 5, maxWidth: 100 }} /> บาท </div>
                                                    
                                                    </div>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                                <FaRegThumbsUp style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>การรับประกัน</strong></h5>
                                            </label>
                                            <div style={{ paddingLeft: 30 }}>
                                                <select className="form-select" name='offerGaruntee' onChange={(e) => setOfferGaruntee(e.target.value)} style={{ fontSize: 16, paddingLeft: 8 }}>
                                                    <option value="1">ซ่อมฟรีทุกกรณี</option>
                                                    <option value="2">ฟรีค่าแรง แต่เก็บค่าอะไหล่ (ถ้ามี)</option>
                                                    <option value="3">เก็บค่าแรง และค่าอะไหล่ (ถ้ามี)</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                                <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>ระยะรับประกัน (วัน)</strong></h5>
                                            </label>
                                            <div style={{ display: 'flex', flex: 1, paddingLeft: 30, flexDirection: 'column' }}>
                                                <div>จำนวนวันหลังให้บริการ (หากมีการซ่อมหรือแก้ไขจากผู้อื่น ในช่วงประกัน ถือว่าการรับประกันสิ้นสุดลง)</div>
                                                <input className="form-control" type='text' name='offerGarunteeDay' onChange={(e) => setOfferGarunteeDay(e.target.value)} 
                                                    value={offerGarunteeDay} placeholder='ระยะรับประกัน (วัน)'
                                                    style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                                <RiHandCoinLine style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>มัดจำงาน</strong></h5>
                                            </label>
                                            <div style={{ paddingLeft: 30 }}>
                                                <select className="form-select" name='owner' onChange={(e) => setOfferDeposit(e.target.value)} style={{ fontSize: 16, paddingLeft: 8 }}>
                                                    <option value="1">เมื่อเริ่มดำเนินการ ไม่คืนค่ามัดจำทุกกรณี</option>
                                                    <option value="2">เมื่อรับงานแล้ว แต่ดำเนินการไม่เสร็จส้ิน คืนค่ามัดจำทั้งหมด</option>
                                                    <option value="3">หากไม่ได้ดำเนินการยินดีคืนค่ามัดจำเต็มจำนวน</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                                <IoCarOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>การคิดค่าเดินทาง</strong></h5>
                                            </label>
                                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 30 }}>
                                                <div>
                                                    <select className="form-select" name='offerTravel' onChange={(e) => setOfferTravel(e.target.value)} style={{ fontSize: 16, paddingLeft: 8 }}>
                                                        <option value="1">ฟรีค่าเดินทาง ไม่เก็บค่าเดินทางในทุกกรณี</option>
                                                        <option value="2">เก็บค่าเดินทางในทุกกรณี</option>
                                                        <option value="3">เก็บค่าเดินทางเมื่อได้ดำเนินการเท่านั้น</option>
                                                    </select>
                                                </div>
                                                {(offerTravel == 2 || offerTravel == 3) &&
                                                <div style={{ display: 'flex', flexDirection: 'row'}}>
                                                    <label style={{ display: 'flex', marginLeft: 15, marginTop: 15}}><h6><strong>ค่าเดินทาง (บาท)</strong></h6></label>
                                                    <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 15, marginTop: 10 }}>
                                                        <input type='text' name='offerTravelPrice' onChange={(e) => setOfferTravelPrice(e.target.value)} 
                                                            value={offerTravelPrice} placeholder='ค่าเดินทาง (บาท)'
                                                            style={{ paddingLeft: 8, marginRight: 5 }} />
                                                    </div>
                                                </div>
                                                }
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                            <div className="col-xs-12 col-md-4">
                                <div style={{ display:'flex', flexDirection: 'column', flex: 1, marginLeft: 10 }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                            <label className="mb-3" style={{ display: 'flex', flexDirection: 'row', borderBottom: "1px solid #ddd"}}>
                                                <IoHammerOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                                <h5><strong>ขั้นตอนการดำเนินงาน</strong></h5>
                                            </label>
                                            <div style={{ display: 'flex', flex: 1, paddingLeft: 30, flexDirection: 'column' }}>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep1?"checked":""} onChange={(e) => handleOfferStep1Change(e.target.value)} /> ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (ก่อนล้าง)</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep2?"checked":""} onChange={(e) => handleOfferStep2Change(e.target.value)} /> ปิดเบรกเกอร์แอร์</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep3?"checked":""} onChange={(e) => handleOfferStep3Change(e.target.value)} /> ปูผ้าใบกันเปื้อนรอบบริเวณพื้นที่ที่ต้องการล้างทำความสะอาดแอร์ ตรวจสอบการทำงานของแอร์ บานสวิง วัดอุณหภูมิและกระแสไฟฟ้า</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep4?"checked":""} onChange={(e) => handleOfferStep4Change(e.target.value)} /> ล้างทำความสะอาดแผงฟิลคอยด์</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep5?"checked":""} onChange={(e) => handleOfferStep5Change(e.target.value)} /> ล้างทำความสะอาดแผ่นฟิลเตอร์</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep6?"checked":""} onChange={(e) => handleOfferStep6Change(e.target.value)} /> ล้างทำความสะอาดพัดลมกรงกระรอก</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep7?"checked":""} onChange={(e) => handleOfferStep7Change(e.target.value)} /> ดูดและเป่าท่อน้ำทิ้ง</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep8?"checked":""} onChange={(e) => handleOfferStep8Change(e.target.value)} /> ทำความสะอาดตัวเครื่อง (FAN COIL)</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep9?"checked":""} onChange={(e) => handleOfferStep9Change(e.target.value)} /> เป่าไล่ความชื้นในส่วนต่าง ๆ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep10?"checked":""} onChange={(e) => handleOfferStep10Change(e.target.value)} /> ฉีดสเปรย์ฆ่าเชื้อโรคแผงฟิลคอยล์</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep11?"checked":""} onChange={(e) => handleOfferStep11Change(e.target.value)} /> ล้างทำความสะอาดคอยล์ร้อน</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep12?"checked":""} onChange={(e) => handleOfferStep12Change(e.target.value)} /> ตรวจสอบแผงควบคุม และการทำงานของเครื่อง</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep13?"checked":""} onChange={(e) => handleOfferStep13Change(e.target.value)} /> ตรวจวัดอุณหภูมิความเย็น</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep14?"checked":""} onChange={(e) => handleOfferStep14Change(e.target.value)} /> ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า (หลังล้าง)</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep15?"checked":""} onChange={(e) => handleOfferStep15Change(e.target.value)} /> ตรวจสอบสายกราวน์และเช็คจุดต่อสายไฟ</font>
                                                <font style={{ fontSize: 16 }}><input type="checkbox" checked={offerStep16?"checked":""} onChange={(e) => handleOfferStep16Change(e.target.value)} /> เปิดเครื่องทดสอบประมาณ 15 นาที พร้อมตรวจสอบการทางานของแอร์ เช่น ไฟหน้าเครื่อง ฟังเสียง อุณหภูมิ บานสวิง มีอาการน้ำหยดหรือไม่</font>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseOfferPostShop} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleOfferSubmit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยื่นข้อเสนอ</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Post