import React, { useState, useEffect } from 'react'
import { db } from "../../assets/js/firebase"
import { collection, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'

import { calScoreShop } from '../../../assets/js/ScoreHelper'
import { Loading } from "../layouts/Loading"
import { addUserNotification, addShopNotification } from "../../../assets/js/NotificationHelper"
import { addShopCoinLog, addUserCoinLog, addPlatformCoinLog, addExchangeLog } from "../../../assets/js/LogHelper"

export function AppointmentBoard3({appointment}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(false)

    const [openFinish, setOpenFinish] = useState(false)
    const [openCancle, setOpenCancle] = useState(false)

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState('')

    const board = 3
    const appointmentId = appointment.id
    const postId = appointment.data.post_id
    const shopId = appointment.data.shop_id
    const postOfferId = appointment.data.offer_id
    const shopOfferId = appointment.data.shop_offer_id
    const userId = appointment.post.poster_id

    var badge = { message: '', class: 'success', color: '#000000', show: false }
    if(appointment.data.status == 1) {
        badge = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
    } else if(appointment.data().status == 2) {
        badge = { message: 'ร้านค้าจ่ายเหรียญมัดจำซื้อสินค้าแล้ว', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data().status == 3) {
        badge = { message: 'ยืนยันขายสินค้า', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data().status == 4) {
        badge = { message: 'ร้านค้ายกเลิกซื้อสินค้า', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 5) {
        badge = { message: 'ยกเลิกขายสินค้า', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 6) {
        badge = { message: 'ร้านค้ายืนยันได้รับสินค้า', class: 'success', color: '#ffffff', show: true }
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleOpenFinish = () => {
        setOpenFinish(true)
    }

    const handleCloseFinish = () => {
        setOpenFinish(false)
    }

    const handleFinish = () => {
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 3,
            finish_message: message,
            score: score,
            finish_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        calScoreShop(db, shopId, score)

        Promise.all(promisesTask).then(() => {
            handleCloseFinish()
            navigate(0)
        })
    }

    const handleOpenCancle = () => {
        setOpenCancle(true)
    }

    const handleCloseCancle = () => {
        setOpenCancle(false)
    }

    const handleCancle = () => {
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 5,
            user_cancle_message: message,
            user_cancle_reason: reason,
            user_cancle_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        var updatePostTask = updateDoc(doc(db, `/posts/`, postId), { status: 1 })
        promisesTask.push(updatePostTask)
        
        var updateShopOfferTask = updateDoc(doc(db, `/shops/${shopId}/offers`, shopOfferId), { status: 7 })
        promisesTask.push(updateShopOfferTask)
        
        var addCancleHistoryTask = addDoc(collection(db, `posts/${postId}/cancle_offers/`), {
            shop_id: shopId,
            cancle_message: message,
            cancle_reason: reason,
            cancle_datetime: logDate.getTime()
        })
        promisesTask.push(addCancleHistoryTask)

        var deleteOfferTask = deleteDoc(doc(db, `posts/${postId}/offers/`, postOfferId))
        promisesTask.push(deleteOfferTask)

        Promise.all(promisesTask).then(() => {
            handleCloseCancle(false)
            navigate(0)
        })
    }

    useEffect(() => {
    })

    return (
        <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            {badge.show && (
            <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                <button type="button" className={"btn "+"btn-"+badge.class} style={{ color: badge.color }}><strong>{badge.message}</strong></button>
            </div>
            )}
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                <img src={appointment.post.picture} alt="WEEE" height="150" />
            </div>

            <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                <p className="card-text">
                    <strong>ประเภทนัดหมาย:</strong> ขายเครื่องใช้ไฟฟ้ามือสอง<br/>
                    <strong>วันนัดหมาย:</strong><br/>
                    <h6 style={{ marginLeft: 15 }}>{dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/></h6>
                    <strong>ราคา:</strong> {number_format.format(appointment.post.price)} บาท <br/>
                    {(appointment.data.status == 6) && (<>
                        <strong>คะแนน:</strong> {appointment.data.score} <br/>
                        <strong>คำติชม:</strong> {appointment.data.finish_message} <br/>
                    </>)}
                </p>
            </div>
            <div className="card-body">
                <Link className="btn btn-primary m-1" to={`/profile/postdetail/${board}/${appointment.data.post_id}`} target='_blank'><strong>ดูรายละเอียดของประกาศ</strong></Link>
                {(appointment.data.status == 1) && (<>
                    <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCancle() }}>
                        <strong>ยกเลิกการขายสินค้า</strong>
                    </button>
                </>)}
                {(appointment.data.status == 2) && (<>
                    <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenFinish() }}>
                        <strong>ยืนยันได้รับเหรียญ</strong>
                    </button>
                </>)}
            </div>

            <Dialog open={openFinish} onClose={handleCloseFinish} fullWidth={true} maxWidth='sm'>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันการขายสินค้า</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex', width: 200}}><h5><strong>คำติชมเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='คำติชมเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                        <label className="" style={{ display: 'flex'}}><h5><strong>คะแนน</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                            <select name='score' onChange={(e) => setScore(e.target.value)} style={{ flex: 1 }}>
                                                <option value="5">5</option>
                                                <option value="4">4</option>
                                                <option value="3">3</option>
                                                <option value="2">2</option>
                                                <option value="1">1</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div style={{ display:'flex', flexDirection: 'column', marginTop: 10 }}>
                                        <div style={{ backgroundColor: '#ffffff' }}>
                                            <h6 className='text-danger'><strong>คุณจะได้รับแต้มสะสม เมื่อผู้เข้ารับซื้อซากกดยืนยันได้รับสินค้าแล้ว</strong></h6>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseFinish} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleFinish} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันการขายสินค้า</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancle} onClose={handleCloseCancle} fullWidth={true} maxWidth='sm'>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการขายซาก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15, flexDirection: 'column' }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ flex: 1 }}>
                                                <option value="1">มารับไม่ตรงวันและเวลานัด</option>
                                                <option value="2">มีการเรียกรับเงินเพิ่ม</option>
                                                <option value="3">ความพร้อมหรือความน่าเชื่อถือของร้านค้า</option>
                                                <option value="4">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancle} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancle} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการขายซาก</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}