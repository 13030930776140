import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, getDoc, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { useParams, Link, useNavigate } from "react-router-dom"
import LeftMenu from './LeftMenu'

import { IoMegaphoneOutline } from 'react-icons/io5'
import { AiFillInfoCircle } from 'react-icons/ai'

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Posts = () => {
    const navigate = useNavigate()
    const params = useParams()
    const shopId = params.shopId
    const number_format = new Intl.NumberFormat()
    const [user, loading] = useAuthState(auth)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [uid, setUid] = useState('')
    const [docId, setDocId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [posts, setPosts] = useState([])

    const [postId, setPostId] = useState('')
    const [openChooseOffersShop, setOpenChooseOffersShop] = useState(false)
    const [offerList, setOfferList] = useState([])
    const [confirmOffer, setConfirmOffer] = useState('')
    const [openErrorDiv, setOpenErrorDiv] = useState(false)

    const [openHidePost, setOpenHidePost] = useState(false)
    const [openShowPost, setOpenShowPost] = useState(false)
    const [openDeletePost, setOpenDeletePost] = useState(false)
    const [choosePostId, setChoosePostId] = useState('')

    const handleCloseHidePost = () => {
        setChoosePostId('')
        setOpenHidePost(false)
    }

    const handleOpenHidePost = (post_id) => {
        setChoosePostId(post_id)
        setOpenHidePost(true)
    }
    const handleCloseShowPost = () => {
        setChoosePostId('')
        setOpenShowPost(false)
    }

    const handleOpenShowPost = (post_id) => {
        setChoosePostId(post_id)
        setOpenShowPost(true)
    }
    const handleCloseDeletePost = () => {
        setChoosePostId('')
        setOpenDeletePost(false)
    }

    const handleOpenDeletePost = (post_id) => {
        setChoosePostId(post_id)
        setOpenDeletePost(true)
    }

    const handleHidePost = () => {
        var promises = []
        const updateTask = updateDoc(doc(db, `postshops`, choosePostId), { status: 6 })
        promises.push(updateTask)
        Promise.all(promises).then(() => {
            navigate(0)
        })

    }

    const handleShowPost = () => {
        var promises = []
        const updateTask = updateDoc(doc(db, `postshops`, choosePostId), { status: 1 })
        promises.push(updateTask)
        Promise.all(promises).then(() => {
            navigate(0)
        })
    }

    const handleDeletePost = () => {
        var promises = []
        const deleteTask = deleteDoc(doc(db, `postshops`, choosePostId))
        promises.push(deleteTask)
        Promise.all(promises).then(() => {
            navigate(0)
        })

    }

    const handleCloseAcceptPostShop = () => {
        setOpenChooseOffersShop(false)
    }

    /* const handleSearch = (keyword) => {
        var q
        if(keyword) {
            q = query(collection(db, 'postshops'), 
                        where('type', '>=', keyword),
                        where('type', '<=', keyword +  '\uf8ff'),
                        where('poster_id', '==', shopId),
                        orderBy('type', 'asc'), 
                        orderBy('post_timestamp', 'desc'))

            onSnapshot(q, (querySnapshot) => {
                setPosts(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        } else {
            q = query(collection(db, 'postshops'), 
                        where('poster_id', '==', shopId),
                        orderBy('post_timestamp', 'desc'))

            onSnapshot(q, (querySnapshot) => {
                setPosts(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })

        }
    } */
 
    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }
 
    const dateFormatTime = (datetime) => {
        return (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const showChooseOffersList = (post) => {
        setConfirmOffer('')
        setPostId(post.id)
        offerList.splice(0, offerList.length)
        setOfferList([...offerList])
        getDocs(query(collection(db, `postshops/${post.id}/offers`), orderBy('offer_timestamp', 'asc'))).then(async (offerDocs) => {
            offerDocs.docs.map((offer) => {
                getDoc(doc(db, 'users', offer.data().user_id)).then((user) => {
                    offerList.push({
                        id: offer.id,
                        data: offer.data(),
                        userId: offer.data().user_id,
                        user: user.data()
                    })
                    setOfferList([...offerList]) 
                })
            })
        }).then(() => {
            setOpenChooseOffersShop(true)
        })
    }

    const handleOfferSubmit = () => {
        if(validateOfferForm()) {
            getDoc(doc(db, `postshops/${postId}/offers/`, confirmOffer)).then((offerDoc) => {
                updateDoc(doc(db, `/postshops`, postId), { status: 3 }).then(async () => {
                    getDocs(query(collection(db, `/postshops/${postId}/offers`))).then((offerDocs) => {
                        offerDocs.forEach(async(docRef) => {
                            updateDoc(doc(db, `/postshops/${postId}/offers`, docRef.id), { status: 4 }).then(() => {
                                getDocs(query(collection(db, `/users/${docRef.data().user_id}/offers`), where('offer_id', '==', docRef.id))).then((offerUser) => {
                                    offerUser.forEach((offer) => {
                                        updateDoc(doc(db, `/users/${docRef.data().user_id}/offers`, offer.id), { status: 4 })
                                    })
                                })
                            })
                        })
                    }).then(() => {
                        updateDoc(doc(db, `/postshops/${postId}/offers/`, confirmOffer), { status: 2 }).then(() => {
                            getDocs(query(collection(db, `/users/${offerDoc.data().user_id}/offers`), where('offer_id', '==', confirmOffer))).then((offerUser) => {
                                offerUser.forEach(async (docRef) => {
                                    updateDoc(doc(db, `/users/${offerDoc.data().user_id}/offers`, docRef.id), { status: 2 })
                                })
                            }).then(() => {
                                const date = new Date()
                                addDoc(collection(db, `/users/${offerDoc.data().user_id}/notifications`), {
                                    date: date.getTime(),
                                    message: "ยินดีด้วย ข้อเสนอซื้อเครื่องใช้ไฟฟ้ามือสองของคุณได้รับการเลือก",
                                    link: "/profile/offers",
                                    type: 'offer',
                                    color: 'green',
                                    offer_id: confirmOffer,
                                    post_id: postId,
                                    status: 0
                                }).then(() => {
                                    setOpenChooseOffersShop(false)
                                    navigate(0)
                                })
                            })
                        })
                    })
                })
            })
        }
    }

    const validateOfferForm = () => {
        if(!confirmOffer) {
            setOpenErrorDiv(true)
            return false;
        } else {
            setOpenErrorDiv(false)
        }
        return true
    }

    const fetchPosts = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then(async(docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDocId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)
                
                await getDocs(query(collection(db, 'postshops'), 
                            where('poster_id', '==', shopId), 
                            orderBy('post_timestamp', 'desc'))).then((postDocs) => {
                    posts.splice(0, posts.length)
                    setPosts([...posts])
                    postDocs.forEach((docPost) => {
                        var brand = 'ไม่ระบุ'
                        var model = 'ไม่ระบุ'
                        var promisesTask = []
                        if(docPost.data().brand_id) {
                            if(docPost.data().brand_id == '999') {
                                if(docPost.data().brand) {
                                    brand = docPost.data().brand
                                }
                            } else {
                                var brand_id = docPost.data().brand_id
                                var getBrandTask = getDoc(doc(db, `brands`, brand_id)).then((docBrand) => {
                                    brand = docBrand.data().name
                                })
                                promisesTask.push(getBrandTask)
                            }
                        } else {
                            if(docPost.data().brand) {
                                brand = docPost.data().brand
                            }
                        }
                        if(docPost.data().model_id) {
                            if(docPost.data().model_id == '999') {
                                if(docPost.data().model) {
                                    model = docPost.data().model
                                }
                            } else {
                                var model_id = docPost.data().model_id
                                var getModelTask = getDoc(doc(db, `models`, model_id)).then((docModel) => {
                                    model = docModel.data().name
                                })
                                promisesTask.push(getModelTask)
                            }
                        } else {
                            if(docPost.data().model) {
                                model = docPost.data().model
                            }
                        }
                        getDocs(query(collection(db, `postshops/${docPost.id}/offers`), where('status', '==', 1), orderBy('offer_timestamp', 'desc'))).then((offerQuery) => {
                            var offersList = []
                            offerQuery.forEach((offer) => {
                                offersList.push({ id: offer.id, data: offer.data()})
                            })
                            Promise.all(promisesTask).then(() => {
                                posts.push({
                                    id: docPost.id,
                                    data: docPost.data(),
                                    offer: offersList,
                                    brand: brand,
                                    model: model
                                })
                                setPosts([...posts], {
                                    id: docPost.id,
                                    data: docPost.data(),
                                    offer: offersList,
                                    brand: brand,
                                    model: model
                                })

                            })
                        })
                    })
                })
            }
        }).catch((error) => {
            alert(error.message)
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else fetchPosts()
    }, [user, loading]);

    var postKey = []
    var postList = []
    posts.forEach(element => {
        if(postKey.lastIndexOf(element.id) < 0) {
            postKey.push(element.id)
            postList.push(element)
        }
    })

    return (
    <>
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="posts" isShop={true} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', paddingTop: 5 }}><h4><b>ประกาศของร้านค้า</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="posts" isShop={true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {postList?.map((post, i) => (
                            <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={post.id}>
                                {(post.offer.length > 0 && post.data.status==2) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-primary" onClick={() => { showChooseOffersList(post) }}>
                                        ข้อเสนอ <span className="badge badge-light" style={{ backgroundColor: '#ff0000'}}>{post.offer.length}</span>
                                        </button>
                                    </div>)
                                }
                                {(post.data.status==3) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-warning" style={{ color: '#000000'}}>รอยืนยันนัดหมาย</button>
                                    </div>)
                                }
                                {(post.data.status==4) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}><strong>จองแล้ว</strong></button>
                                    </div>)
                                }
                                {post.data.status == 5 &&
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-danger" style={{ color: '#FFFFFF'}}><strong>ระงับการประกาศ</strong></button>
                                    </div>)
                                }
                                {(post.data.status==6) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-danger" style={{ color: '#ffffff'}}><strong>ซ่อนประกาศ</strong></button>
                                    </div>)
                                }
                                {(post.data.status==9) && 
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}><strong>ขายเรียบร้อย</strong></button>
                                    </div>)
                                }
                                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                    <img src={post.data.picture} alt="WEEE" height="150" />
                                </div>
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                    <p className="card-text">
                                        {post.data.type} {post.brand} {post.model}<br/>
                                        <strong>ราคาขาย: ฿ {post.data.price}</strong><br/>
                                        <strong>รายละเอียดเพิ่มเติม:</strong> {post.data.description}<br/>
                                    </p>
                                    <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong><br/>{dateFormatDate(post.data.pick_date)} เวลา {post.data.hour}:{post.data.minute} น.</p>
                                    <p className="card-text"><strong>วันที่ประกาศ:</strong> {dateFormatTime(post.data.post_timestamp)}</p>
                                </div>
                                <div className="card-body">
                                    {(post.data.status==1) && (<>
                                        <Link className="btn btn-warning m-1" to={`/shop/post/edit/${post.id}`}><strong>แก้ไขประกาศ</strong></Link>
                                        <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenHidePost(post.id) }}><strong>ซ่อนประกาศ</strong></button>
                                    </>)}
                                    <Link className="btn btn-primary m-1" to={`/shop/post/${post.id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                                    {(post.data.status==3) && (<>
                                        <button type="button" className="btn btn-primary m-1" onClick={() => {} }><strong>ดูข้อเสนอที่เลือกไว้</strong></button>
                                    </>)}
                                    {(post.data.status==4) && (<>
                                        <button type="button" className="btn btn-primary m-1" onClick={() => {} }><strong>ดูข้อเสนอที่เลือกไว้</strong></button>
                                        <button type="button" className="btn btn-success m-1" onClick={() => {} }><strong>ดูนัดหมาย</strong></button>
                                    </>)}
                                    {(post.data.status==6) && (<>
                                        <Link className="btn btn-warning m-1" to={`/post/edit/gabage/${post.id}`}><strong>แก้ไขประกาศ</strong></Link>
                                        <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenShowPost(post.id) }}><strong>แสดงประกาศ</strong></button>
                                        <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenDeletePost(post.id) }}><strong>ลบประกาศ</strong></button>
                                    </>)}
                                    <button type="button" className="btn btn-primary m-1" onClick={() => {  }}><strong>สำเนาประกาศ</strong></button>
                                </div>
                            </div>
                        ))}
                        {postList.length <= 0 && (
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <font className="text-danger" style={{ fontWeight: '600'}}>ไม่พบประกาศของร้านค้า</font>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={openChooseOffersShop} onClose={handleCloseAcceptPostShop} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยื่นข้อเสนอ</strong></h3></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', marginTop: 10, flexDirection: 'column', width:'100%', marginBottom: 15 }}>
                                <div style={{ backgroundColor: '#ffffff'}}>
                                    <table className="table">
                                        <thead>
                                            <tr>
                                                <th scope="col">#</th>
                                                <th scope="col">ผู้เสนอ</th>
                                                <th scope="col">ข้อเสนอ</th>
                                                <th scope="col">ข้อความ</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                        {
                                            offerList?.map((offer, i) => (
                                            <tr key={i}>
                                                <th scope="row">
                                                    <input className="form-check-input" type="radio" 
                                                        name="chooseOfferRadio" id="chooseOfferRadio" value={offer.id}
                                                        onClick={() => setConfirmOffer(offer.id)}
                                                     />
                                                </th>
                                                <td>
                                                    {offer.user.firstname+" "+offer.user.lastname}<br/>
                                                    <a href={`/user/history/${offer.userId}`} target='_blank'><AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} /></a>
                                                </td>
                                                <td>
                                                    <p style={{ marginBottom: 5 }}><strong>ราคาที่เสนอ:</strong> {number_format.format(offer.data.price)} บาท</p>
                                                    <p style={{ marginBottom: 5 }}><strong>วิธีรับสินค้า:</strong>
                                                        {offer.data.delivery_offer=='1'?(<> ให้ผู้ซื้อมารับสินค้าที่ร้าน/บริษัท<br/></>):""}
                                                        {offer.data.delivery_offer=='2'?(<> บริการไปส่งสินค้าที่พัก</>):"" }
                                                    </p>
                                                    <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.</p>
                                                </td>
                                                <td>{offer.data.message}</td>
                                            </tr>
                                            ))
                                        }
                                        </tbody>
                                    </table>
                                </div>
                                {openErrorDiv &&
                                    <div className="card-body text-danger border-danger" style={{ marginLeft: 10, marginTop:-5}}>
                                        <p className="font-weight-bold">** กรุณาเลือกข้อเสนอเพื่อยืนยันรับข้อเสนอ</p>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPostShop} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleOfferSubmit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันรับข้อเสนอ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openHidePost} onClose={handleCloseHidePost} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ซ่อนประกาศ</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                <h5>คุณต้องการซ่อนประกาศ?</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseHidePost} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleHidePost} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ซ่อนประกาศ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openShowPost} onClose={handleCloseShowPost} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>แสดงประกาศ</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                <h5>คุณต้องการแสดงประกาศ?</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShowPost} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleShowPost} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>แสดงประกาศ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openDeletePost} onClose={handleCloseDeletePost} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ลบประกาศ</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                <h5>คุณต้องการลบประกาศ?</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDeletePost} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleDeletePost} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ลบประกาศ</button>
                </DialogActions>
            </Dialog>
        </div>
    </>
    )
}

export default Posts