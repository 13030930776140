import { getDoc, doc } from 'firebase/firestore'
import { getBrand, getModel } from '../../sites/components/helper/BrandHelper'
import { getElectric } from '../../sites/components/helper/ElectricTypeHelper'

export async function getPostTitle(db, postId) {
    var title = getDoc(doc(db, `posts`, postId)).then(async(doc) => {
        title = ''
        if(doc.data().onboard == 1) {
            title += 'ประกาศทิ้งซาก '
        } else if(doc.data().onboard == 2) {
            title += 'ประกาศขายซาก '
        } else if(doc.data().onboard == 3) {
            title += 'ประกาศขายเครื่องใช้ไฟฟ้ามือสอง '
        } else if(doc.data().onboard == 4) {
            title += 'ประกาศแจ้งซ่อมเครื่องใช้ไฟฟ้า '
        }
        
        title += await getElectric(db, doc.data().type_id, doc.data().other_type)+" "
        title += await getBrand(db, doc.data().brand_id, doc.data().brand)+" "
        title += await getModel(db, doc.data().model_id, doc.data().model)+" "
        
        return title
    })

    return title
}