import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, doc, getDoc } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import DOMPurify from 'dompurify'

const AboutUs = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)
    const [serviceTextHTML, setServiceTextHTML] = useState('')

    useEffect(() => {
        if (loading) return;
    }, [loading])

    useEffect(() => {
        getDoc(doc(db, 'texts', 'text4_html')).then((textHtml) => {
            if(textHtml.exists()) {
                setServiceTextHTML(textHtml.data().html)
            }
        })
    }, [])

    function createMarkup(html) {
        return {
            __html: DOMPurify.sanitize(html)
        }
    }

    return (
        <>
            <div style={{ backgroundColor: "#fcfcfc", padding: 20}}>
                <div className="container">
                    <div className="row" style={{ marginTop: 20 }}>
                        <div className="col">
                            <h3 className="text-center"><b>รายละเอียดการให้บริการแจ้งความต้องการล้างแอร์/เครื่องซักผ้า</b></h3>
                        </div>
                    </div>
                    <div className="row gy-3" style={{ marginTop: 20 }}>
                        <div
                            dangerouslySetInnerHTML={createMarkup(serviceTextHTML)}>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default AboutUs