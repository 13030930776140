import React, { useState, useEffect } from 'react'
import { db } from "../../assets/js/firebase"
import { collection, getDoc, addDoc, updateDoc, doc, deleteDoc, getDocs, where, query } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { Loading } from "../layouts/Loading"
import { addUserNotification, addShopNotification } from "../../../assets/js/NotificationHelper"
import { addShopCoinLog, addUserCoinLog, addPlatformCoinLog, addExchangeLog } from "../../../assets/js/LogHelper"
import { calScoreUser } from '../../../assets/js/ScoreHelper'

export function AppointmentShopBoard3({appointment, offerExchangeCoin}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(false)
    
    const [payCoin, setPayCoin] = useState(0)
    const [coinEnough, setCoinEnough] = useState(false)
    const [coinMessage, setCoinMessage] = useState('')
    const depositCoin = appointment.data.deposit_coin * 1
    const [remainCoin, setRemainCoin] = useState(appointment.data.remain_coin * 1)

    const [gpRate, setGpRate] = useState(0)
    const [gpCoin, setGpCoin] = useState(0)

    const [openDeposit, setOpenDeposit] = useState(false)
    const [openCanclePay, setOpenCanclePay] = useState(false)
    const [openFinish, setOpenFinish] = useState(false)

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState(1)

    const [fineToUser, setFineToUser] = useState(0)
    const [fineToPlatform, setFineToPlatform] = useState(0)
    const [fineToOwner, setFineToOwner] = useState(0)
    const [fineToUserRate, setFineToUserRate] = useState(0)
    const [fineToPlatformRate, setFineToPlatformRate] = useState(0)
    const [fineToOwnerRate, setFineToOwnerRate] = useState(0)
    const [fineDiffDay, setFineDiffDay] = useState(0)

    const [userExchangeCoin, setUsetExchangeCoin] = useState(0)

    const userId = appointment.data.user_id
    const shopId = appointment.data.shop_id
    const postId = appointment.data.post_id
    const shopOfferId = appointment.data.shop_offer_id
    const postOfferId = appointment.data.offer_id

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const getUserData = () => {
        getDoc(doc(db, `users`, userId)).then((userDoc) => {
            if(userDoc.data().exchange_coin) { setUsetExchangeCoin(userDoc.data().exchange_coin*1) }
        })
    }

    const getPayRate = () => {
        var price = 0
        if(appointment.offer) {
            price = appointment.offer.price * 1
        }
        getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeRate) => {
            const rate = exchangeRate.data().money_to_coin_1 * 1
            const payCoin = Math.ceil(price/rate)
            setPayCoin(payCoin)
        }).then(() => {
            getDoc(doc(db, 'coins', 'use_rate')).then((exchangeSnapshot) => {
                const useCoin = exchangeSnapshot.data()
                setGpRate(useCoin.board3_3 * 1)
                var gpCoinCal = (price * ((useCoin.board3_3 * 1)/100))
                setGpCoin(gpCoinCal)
            })
        })
    }

    const calFineRate = () => {
        var appointDate = new Date(appointment.data.appointment_date.seconds * 1000)
        var today = new Date()
        var differenceDay = ((today.getTime() - appointDate.getTime()) / (1000 * 60 * 60 * 24)) % 365
        setFineDiffDay(Math.ceil(differenceDay))
        if(Math.ceil(differenceDay) <= 3) {
            setFineToUser(depositCoin*0.1)
            setFineToPlatform(depositCoin*0.1)
            setFineToOwner(depositCoin*0.8)
            setFineToUserRate(10)
            setFineToPlatformRate(10)
            setFineToOwnerRate(80)
        } else {
            setFineToUser(depositCoin*0.2)
            setFineToPlatform(depositCoin*0.3)
            setFineToOwner(depositCoin*0.5)
            setFineToUserRate(20)
            setFineToPlatformRate(30)
            setFineToOwnerRate(50)
        }
    }

    const handleOpenDeposit = () => {
        setOpenDeposit(true)
    }
    
    const handleCloseDeposit = () => {
        setOpenDeposit(false)
    }
    
    const handleSubmitDeposit = () => {
        setShowLoading(true)
        var message = ''
        updateDoc(doc(db, `appointments`, appointment.id), { 
            status: 2 
        }).then(() => {
            var exchangeCoin = userExchangeCoin
            var totalReceiveCoin = depositCoin - gpCoin

            addUserCoinLog(db, userId, depositCoin, 'ได้รับเหรียญทองค่ามัดจำ 30% ของราคาสินค้าจำนวน '+depositCoin+' เหรียญ', '2', "coin", postId, "platform", "user", true)
            addUserCoinLog(db, userId, depositCoin, 'จ่ายเหรียญทองค่าธรรมเนียม '+gpRate+'% ของราคาสินค้าจำนวน '+gpCoin+' เหรียญ', '2', "coin", postId, "user", "platform", false)
            updateDoc(doc(db, 'users/', userId), {
                exchange_coin: (exchangeCoin * 1) + totalReceiveCoin
            }).then(() => {
                message = "คุณได้รับเหรียญทองจากค่ามัดจำ เป็นจำนวน "+number_format.format(depositCoin)+" เหรียญ"
                addUserNotification(db, userId, message, 'coin', postId, '/profile/postdetail/3/'+postId)

                message = "คุณจ่ายค่าธรรมเนียม เป็นจำนวน "+number_format.format(gpCoin)+" เหรียญ"
                addUserNotification(db, userId, message, 'coin', postId, '/profile/postdetail/3/'+postId)
                
                getDoc(doc(db, 'exchange_coins', 'sum_data')).then((coinSnapshot) => {
                    const data = coinSnapshot.data()
                    addExchangeLog(db, depositCoin, 'โอนมัดจำ 30% ของราคาสินค้าให้ผู้ขาย จำนวน '+depositCoin+' เหรียญ', userId, 'decrease')
                    addExchangeLog(db, gpCoin, 'ได้รับค่าธรรมเนียม '+gpRate+'% ของราคาสินค้าให้ผู้ขาย จำนวน '+gpCoin+' เหรียญ', userId, 'increase')
                    
                    updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                        remain: (data.remain*1) - (totalReceiveCoin*1),
                        used: (data.used*1) - (totalReceiveCoin*1)
                    }).then(() => {
                        setOpenDeposit(false)
                        setShowLoading(false)
                        navigate(0)
                    })
                })
            })
        })
    }

    const handleOpenCanclePay = () => {
        setOpenCanclePay(true)
    }

    const handleCloseCanclePay = () => {
        setOpenCanclePay(false)
    }

    const handleCanclePayCoin = () => {
        setShowLoading(true)
        const logDate = new Date()
        var message = ''
        updateDoc(doc(db, `/appointments/`, appointment.id), { 
            status: 5,
            shop_cancle_message: message,
            shop_cancle_reason: reason,
            shop_cancle_datetime: logDate.getTime()
        }).then(() => {
            var exchangeCoin = userExchangeCoin
            updateDoc(doc(db, 'users/', userId), {
                exchange_coin: (exchangeCoin*1)+ fineToUser
            }).then(() => {
                var message = "คุณได้รับเหรียญทองจากค่ามัดจำ เนื่องจากร้านค้ายกเลิกการศื้อขาย "+fineToUserRate+"% เป็นจำนวน "+number_format.format(fineToUser)+" เหรียญ"
                addUserNotification(db, userId, message, 'coin', postId, '/profile/postdetail/3/'+postId)
                addUserCoinLog(db, userId, fineToUser, 'ได้รับเหรียญทองค่าปรับจากค่ามัดจำจำนวน '+fineToUser+' ('+fineToUserRate+'% ค่ามัดจำ)', '2', "post", postId, "platform", "user", true)
            })

            getDoc(doc(db, 'exchange_coins', 'sum_data')).then((coinSnapshot) => {
                const data = coinSnapshot.data()
                updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                    remain: (data.remain*1) - (fineToOwner+fineToUser*1),
                    used: (data.used*1) - (fineToOwner+fineToUser*1)
                })
            }).then(() => {
                addExchangeLog(db, fineToOwner, 'คืนค่ามัดจำจำนวน '+fineToOwner+' ('+fineToOwnerRate+'% ค่ามัดจำ)', shopId, 'decrease')
                addExchangeLog(db, fineToUser, 'คืนค่ามัดจำจำนวน '+fineToUser+' ('+fineToUserRate+'% ค่ามัดจำ)', userId, 'decrease')
                addPlatformCoinLog(db, fineToOwner, 'คืนเหรียญทองค่ามัดจำจำนวน '+fineToOwner+' ('+fineToOwnerRate+'% ค่ามัดจำ)', '2', 'post', postId, 'platform', '', 'shop', shopId, false)
                addPlatformCoinLog(db, fineToOwner, 'โอนเหรียญทองค่าปรับจำนวน '+fineToUser+' ('+fineToUserRate+'% ค่ามัดจำ)', '2', 'post', postId, 'platform', '', 'user', userId, false)
            })

            updateDoc(doc(db, 'shops/', shopId), {
                exchange_coin: (offerExchangeCoin*1)+ fineToOwner
            }).then(() => {
                var message = "คุณได้รับเหรียญจากการมัดจำสินค้า "+fineToOwnerRate+"% คืน จำนวน "+number_format.format(fineToOwner)+" เหรียญ"
                addShopNotification(db, shopId, message, 'coin', postId, '/shop/postdetail/3/'+postId)
                addShopCoinLog(db, shopId, fineToOwner, 'ได้รับเหรียญทองคืนจากค่ามัดจำจำนวน '+fineToOwner+' ('+fineToOwnerRate+'% ค่ามัดจำ)', '2', "post", postId, "platform", "shop", true)
            })
        }).then(() => {
            updateDoc(doc(db, `/posts/`, postId), { 
                status: 1 
            }).then(() => {
                addDoc(collection(db, `posts/${postId}/cancle_offers/`), {
                    shop_id: shopId,
                    cancle_message: message,
                    cancle_reason: reason,
                    cancle_datetime: logDate.getTime()
                })
                addDoc(collection(db, `posts/${postId}/shop_blacklist/`), {
                    shop_id: shopId,
                })
            }).then(() => {
                updateDoc(doc(db, `/shops/${shopId}/offers`, shopOfferId), { status: 4 }).then(() => {
                    getDocs(query(collection(db, `shops/${shopId}/reserve_coin`), where('appointment_id', '==', appointment.id))).then((reserveDocs) => {
                        if(!reserveDocs.empty) {
                            reserveDocs.forEach((reserve) => {
                                deleteDoc(doc(db, `shops/${shopId}/reserve_coin`, reserve.id))
                            })
                        }
                    })
                }).then(() => {
                    deleteDoc(doc(db, `posts/${postId}/offers/`, postOfferId)).then(() => {
                        handleCloseCanclePay(false)
                        setShowLoading(false)
                        //navigate(0)
                    })
                })
            })
        })
    }


    const handleOpenFinish = () => {
        // check เหรียญ ของผู้ซื้อว่าพอกับ remainCoin หรือไม่
        //if(remainCoin < offerExchangeCoin) { 
            setCoinEnough(true)
            setCoinMessage('คุณยืนยันจะจ่ายเหรีนญทองที่สำรองไว้ '+number_format.format(remainCoin)+' เหรียญ (70% ของราคาสินค้า) เพื่อยืนยันซื้อสินค้า?') 
        //} else  { 
            //setCoinEnough(false) 
            //setCoinMessage('คุณมีจำนวนเหรียญทองไม่พอ คุณมี '+number_format.format(offerExchangeCoin)+' เหรียญ คุณต้องใช้ '+number_format.format(remainCoin)+' เหรียญ (70% ของราคาสินค้า) เพื่อยืนยันซื้อสินค้า')
        //}
        setOpenFinish(true)
    }
    
    const handleCloseFinish = () => {
        setRemainCoin(0)
        setOpenFinish(false)
    }
    
    const handleSubmitFinish = () => {
        // Edit Here
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointment.id), { 
            coin: depositCoin + remainCoin,
            status: 6,
            message_for_user: message,
            score_for_user: score,
            shop_finish_datetime: logDate.getTime()
        }).then(() => {
            calScoreUser(db, userId, score)

            updateDoc(doc(db, `/posts/`, postId), { 
                shop_finish_date: logDate.getTime()
            })
        }).then(() => {
            // โอนเหรียญ 70% (remainCoin) ให้ user
            getDocs(query(collection(db, `shops/${shopId}/reserve_coin`), where('appointment_id', '==', appointment.id))).then((reserveDocs) => {
                if(!reserveDocs.empty) {
                    var reserveCoin = reserveDocs.docs[0].data().coin * 1
                    var reserveId = reserveDocs.docs[0].id
                    updateDoc(doc(db, `/shops/${shopId}/reserve_coin/`, reserveId), { 
                        finish_date: logDate.getTime(),
                        status: 2
                    }).then(() => {
                        var exchangeCoin = userExchangeCoin
                        updateDoc(doc(db, 'users/', userId), {
                            exchange_coin: (exchangeCoin*1) + reserveCoin
                        }).then(() => {
                            addUserNotification(db, userId, "คุณได้รับเหรียญจากการขายสินค้า 70% จำนวน "+number_format.format(reserveCoin)+" เหรียญ", 'coin', postId, '/post/'+postId)
                            addUserCoinLog(db, userId, reserveCoin, 'ค่าสินค้า 70% ของราคาสินค้า', '2', 'appointment', appointment.id, 'shop', 'user', true)
                            
                            addShopNotification(db, shopId, "คุณจ่ายเหรียญจากการซื้อสินค้า 70% จำนวน "+number_format.format(reserveCoin)+" เหรียญ", 'coin', postId, '/post/'+postId)
                            addShopCoinLog(db, shopId, reserveCoin, 'จ่ายเหรียญค่าสินค้า 70% ของราคาสินค้า', '2', "appointment", appointment.id, "shop", "user", false)
                        })
                    })
                }
            }).then(() => {
                /* getDoc(doc(db, `shops`, shopId)).then((shopDoc) => {
                    var exchangeCoin = 0
                    if(shopDoc.data().exchange_coin) { exchangeCoin = shopDoc.data().exchange_coin }
                    updateDoc(doc(db, 'shops/', shopId), {
                        exchange_coin: (exchangeCoin*1) - remainCoin
                    })
                }) */
            }).then(() => {
                // Shop ได้รับแต้มสะสม
                var weePoint = 100
                addDoc(collection(db, `/shops/${shopId}/points`), {
                    datetime: logDate.getTime(),
                    point: weePoint,
                    post_id: postId,
                    appointment_id: appointment.id
                })
                addShopNotification(db, shopId, "คุณได้รับแต้มสะสมจากการซื้อเครื่องใช้ไฟฟ้ามือสอง จำนวน "+number_format.format(weePoint)+" แต้ม", 'point', postId, '/post/'+postId)
            }).then(() => {
                // User ได้รับแต้มสะสม
                var weePoint = 100
                addDoc(collection(db, `/users/${userId}/points`), {
                    datetime: logDate.getTime(),
                    point: weePoint,
                    post_id: postId,
                    appointment_id: appointment.id
                })
                addUserNotification(db, userId, "คุณได้รับแต้มสะสมจากการขายเคื่องใช้ไฟฟ้ามือสอง จำนวน "+number_format.format(weePoint)+" แต้ม", 'point', postId, '/post/'+postId)
            }).then(() => {
                updateDoc(doc(db, `/posts/`, postId), { 
                    status: 9,
                    appointment_id: appointment.id,
                    finish_date: logDate.getTime()
                }).then(() => {
                    handleCloseFinish()
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    useEffect(() => {
        getPayRate()
        calFineRate()
        getUserData()
    })
    
    return (
        <>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
                {appointment.badgeStyle.show && (
                <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                    <button type="button" className={"btn "+"btn-"+appointment.badgeStyle.class} style={{ color: appointment.badgeStyle.color }}><strong>{appointment.badgeStyle.message}</strong></button>
                </div>
                )}
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                    <img src={appointment.post.picture} alt="WEEE" height="150" />
                </div>
                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                    <p className="card-text">
                        <strong>ประเภทนัดหมาย:</strong> ขายเครื่องใช้ไฟฟ้ามือสอง<br/>
                        <><strong>วันนัดหมาย:</strong> {dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/></>
                        <><strong>ที่อยู่:</strong> {appointment.post.address} <br/></>
                        {appointment.post.type} {appointment.post.brand} {appointment.data.model}<br/>
                        <strong>ราคาเสนอขาย:</strong> {number_format.format(appointment.post.price)} บาท <br/>
                        {appointment.offer && (
                            <><strong>ราคาเสนอซื้อ:</strong> {number_format.format(appointment.offer.price)} บาท <br/></>
                        )}
                        {(appointment.data.status == 6) && (<>
                            <strong>คะแนน:</strong> {appointment.data.score} <br/>
                            <strong>คำติชม:</strong> {appointment.data.finish_message} <br/>
                        </>)}
                    </p>
                </div>
                <div className="card-body">
                    <Link className="btn btn-primary m-1" to={`/post/${appointment.data.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                    <Link className="btn btn-primary m-1" to={`/shop/postdetail/${appointment.post.onboard}/${appointment.data.post_id}/${appointment.data.shop_id}`} target='_blank'><strong>ดูนัดหมายและข้อเสนอ</strong></Link>
                    {(appointment.data.status == 1) && (<>
                        <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenDeposit() }}>
                            <strong>จ่ายเหรียญซื้อสินค้า</strong>
                        </button>
                        <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCanclePay() }}>
                            <strong>ยกเลิกการซื้อสินค้า</strong>
                        </button>
                    </>)}
                    {(appointment.data.status == 3) && (<>
                        <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenFinish() }}>
                            <strong>ยืนยันได้รับสินค้า</strong>
                        </button>
                    </>)}
                </div>

                <Dialog open={openDeposit} onClose={handleCloseDeposit} maxWidth='sm' fullWidth={true}>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>จ่ายเหรียญมัดจำซื้อสินค้า</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <h5>จ่ายเหรียญซื้อสินค้า</h5>
                                        <h6><strong>คุณต้องการโอนเหรียญมัดจำ 30% จำนวน {depositCoin} เหรียญ ให้ผู้ขาย?</strong></h6>
                                        <h6 className='text-danger'><strong>หากกดจ่ายเหรียญฯ ไปแล้ว และกดยกเลิกภายหลังท่านจะไม่สามารถขอคืนเหรียญที่ใช้ในการจองได้ และต้องเสียค่าปรับตามจำนวนที่กำหนด</strong></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDeposit} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleSubmitDeposit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>จ่ายเหรียญมัดจำซื้อสินค้า</button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openFinish} onClose={handleCloseFinish} fullWidth={true} maxWidth='sm'>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันได้รับซาก</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <h5>จ่ายเหรียญซื้อสินค้ามือสอง</h5>
                                            {coinEnough &&
                                            <h6><strong>{coinMessage}</strong></h6>
                                            }
                                            {!coinEnough &&
                                            <h6 className='text-danger'><strong>{coinMessage}</strong></h6>
                                            }
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex' }}><h5><strong>คำติชมเพิ่มเติม</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder='คำติชมเพิ่มเติม' 
                                                    value={message}></textarea>
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex' }}><h5><strong>คะแนน</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                <select name='score' onChange={(e) => setScore(e.target.value)} style={{ flex: 1 }}>
                                                    <option value="5">5</option>
                                                    <option value="4">4</option>
                                                    <option value="3">3</option>
                                                    <option value="2">2</option>
                                                    <option value="1">1</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div style={{ display:'flex', flexDirection: 'column', marginTop: 10 }}>
                                            <div style={{ backgroundColor: '#ffffff' }}>
                                                <h6 className='text-danger'><strong>คุณจะได้รับแต้มสะสม เมื่อกดยืนยันได้รับสินค้าแล้ว</strong></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseFinish} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        {coinEnough &&
                        <button onClick={handleSubmitFinish} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันได้รับสินค้า</button>
                        }
                    </DialogActions>
                </Dialog>

                <Dialog open={openCanclePay} onClose={handleCloseCanclePay} fullWidth={true} maxWidth='sm'>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการเข้าซื้อสินค้า</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        {/* {(reason != 1) && */}
                                        <div style={{ display:'flex', flexDirection: 'column', marginTop: 10 }}>
                                            <div style={{ backgroundColor: '#ffffff' }}>
                                                <h5 className='text-danger'><strong>*** หากคุณยกเลิกการซื้อขาย คุณจะต้องจ่ายค่าปรับ ***</strong></h5>
                                                <h6 className='text-danger'><strong>จากจำนวนเหรีนญทองที่มัดจำไว้ {depositCoin} เหรียญ</strong></h6>
                                                <h6 className='text-danger'><strong>ให้ผู้ประกาศร้อยละ {fineToUserRate} ของจำนวนเหรีนญที่จอง คิดเป็น {fineToUser} เหรียญ</strong></h6>
                                                <h6 className='text-danger'><strong>ให้แพลตฟอร์มร้อยละ {fineToPlatformRate} ของจำนวนเหรีนญที่จอง คิดเป็น {fineToPlatform} เหรียญ</strong></h6>
                                                <h6 className='text-danger'><strong>และจะได้คืนร้อยละ {fineToOwnerRate} ของจำนวนเหรีนญที่จอง คิดเป็น {fineToOwner} เหรียญ</strong></h6>
                                            </div>
                                        </div>{/* } */}
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="" style={{ display: 'flex'}}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                                <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ flex: 1 }}>
                                                    <option value="1">ไปถึงที่พักแต่ไม่เจอผู้ขาย/ไม่มาส่งสินค้าที่ร้านฯ ตามวันเวลาที่นัด</option>
                                                    <option value="2">มีการเรียกเก็บเงินเพิ่ม</option>
                                                    <option value="3">อื่นๆ (โปรดระบุ)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                <textarea style={{ display: 'flex', flex: 1, height: 100 }}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder='เหตุผลเพิ่มเติม' 
                                                    value={message}></textarea>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseCanclePay} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleCanclePayCoin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการเข้าซื้อสินค้า</button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}