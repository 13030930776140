import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, updateDoc, doc, addDoc, getDoc, orderBy } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { createUserWithEmailAndPassword, sendEmailVerification } from "firebase/auth"
import { useNavigate } from "react-router-dom"
import { BsPersonVcard } from "react-icons/bs"
import { TbHome } from 'react-icons/tb'
import { FaLastfmSquare, FaMapMarkerAlt } from 'react-icons/fa'
import { RiBankCardLine } from 'react-icons/ri'
import { IoCloseCircle, IoCheckmarkCircleSharp } from "react-icons/io5"
import addImage from "../../assets/images/add-image-icon.png"

import { defauleImageSize } from "../../../assets/constants/images"
import { defaultLatLng, googleMapsApiKey, locationOptions } from "../../../assets/constants/locations"
import { Loading } from "../layouts/Loading"

import { addFreeCoinLog } from "../../../assets/js/LogHelper"
import { addUserNotification } from "../../../assets/js/NotificationHelper"

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from "react-icons/io5"

const Register = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)
    const inputRef = useRef(null)
    const inputRef2 = useRef(null)

    const [imageProfile, setImageProfile] = useState('')
    const [imageProfileUrl, setImageProfileUrl] = useState(addImage)
    const [email, setEmail] = useState('')
    const [password, setPassword] = useState('')
    const [displayName, setDisplayName] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [telNo, setTelNo] = useState('')
    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [idCard, setIdCard] = useState('')
    const [idCardUrl, setIdCardUrl] = useState('')
    const [bankId, setBankId] = useState(0)
    const [bankAccNo, setBankAccNo] = useState('')
    const [bookBank, setBookBank] = useState('')
    const [bookBankUrl, setBookBankUrl] = useState('')

    const [address, setAddress] = useState('')
    const [road, setRoad] = useState('')
    const [postcode, setPostcode] = useState('')

    const [subroad, setSubRoad] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')

    const [emailFormatErrorDiv, setEmailFormatErrorDiv] = useState(false)
    const [existEmailErrorDiv, setExistEmailErrorDiv] = useState(false)
    const [passwordErrorDiv, setPasswordErrorDiv] = useState(false)
    const [displayNameErrorDiv, setDisplayNameErrorDiv] = useState(false)
    const [firstnameErrorDiv, setFirstnameErrorDiv] = useState(false)
    const [lastnameErrorDiv, setLastnameErrorDiv] = useState(false)
    const [telNoErrorDiv, setTelNoErrorDiv] = useState(false)
    const [bankIdErrorDiv, setBankIdErrorDiv] = useState(false)
    const [bankAccNoErrorDiv, setBankAccNoErrorDiv] = useState(false)

    const [imageProfileSizeError, setImageProfileSizeError] = useState(false)
    const [imageIdCardSizeError, setImageIdCardSizeError] = useState(false)
    const [imageBookBankSizeError, setImageBookBankSizeError] = useState(false)
    const [showProgressBar, setShowProgressBar] = useState(false)

    const [bankList, setBankList] = useState([])
    const [provinceList, setProvinceList] = useState([])
    const [provinceId, setProvinceId] = useState(0)
    const [districtList, setDistrictList] = useState([])
    const [districtId, setDistrictId] = useState(0)
    const [subdistrictList, setSubDistrictList] = useState([])
    const [subdistrictId, setSubDistrictId] = useState(0)

    const [provinceIdErrorDiv, setProvinceIdErrorDiv] = useState(false)
    const [districtIdErrorDiv, setDistrictIdErrorDiv] = useState(false)
    const [subdistrictIdErrorDiv, setSubDistrictErrorDiv] = useState(false)

    const { isLoaded } = useLoadScript( { googleMapsApiKey: googleMapsApiKey } )
    const [center, setCenter] = useState( { lat: defaultLatLng.lat, lng: defaultLatLng.lng } )
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    const [openAcceptPolicy1, setOpenAcceptPolicy1] = useState(false)
    const [openAcceptPolicy2, setOpenAcceptPolicy2] = useState(false)
    const [openAcceptPolicy3, setOpenAcceptPolicy3] = useState(false)
    const [openAcceptPolicy4, setOpenAcceptPolicy4] = useState(false)
    const [openAcceptPolicy5, setOpenAcceptPolicy5] = useState(false)
    const [openAcceptPolicy6, setOpenAcceptPolicy6] = useState(false)
    const [checkPolicy1, setCheckPolicy1] = useState(false)
    const [checkPolicy2, setCheckPolicy2] = useState(false)
    const [checkPolicy3, setCheckPolicy3] = useState(false)
    const [checkPolicy4, setCheckPolicy4] = useState(false)
    const [checkPolicy5, setCheckPolicy5] = useState(false)
    const [checkPolicy6, setCheckPolicy6] = useState(false)
    const [policy1ErrorDiv, setPolicy1ErrorDiv] = useState(false)
    const [policy2ErrorDiv, setPolicy2ErrorDiv] = useState(false)
    const [policy3ErrorDiv, setPolicy3ErrorDiv] = useState(false)
    const [policy4ErrorDiv, setPolicy4ErrorDiv] = useState(false)
    const [policy5ErrorDiv, setPolicy5ErrorDiv] = useState(false)
    const [policy6ErrorDiv, setPolicy6ErrorDiv] = useState(false)

    const startAcceptPolicy = () => {
        setOpenAcceptPolicy1(true)
    }

    const closeAllPolicyError = () => {
        setPolicy1ErrorDiv(false)
        setPolicy2ErrorDiv(false)
        setPolicy3ErrorDiv(false)
        setPolicy4ErrorDiv(false)
        setPolicy5ErrorDiv(false)
        setPolicy6ErrorDiv(false)
        setCheckPolicy1(false)
        setCheckPolicy2(false)
        setCheckPolicy3(false)
        setCheckPolicy4(false)
        setCheckPolicy5(false)
        setCheckPolicy6(false)
    }

    const handleCloseAcceptPolicy1 = () => {
        setOpenAcceptPolicy1(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy2 = () => {
        setOpenAcceptPolicy2(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy3 = () => {
        setOpenAcceptPolicy3(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy4 = () => {
        setOpenAcceptPolicy4(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy5 = () => {
        setOpenAcceptPolicy5(false)
        closeAllPolicyError()
    }

    const handleCloseAcceptPolicy6 = () => {
        setOpenAcceptPolicy6(false)
        closeAllPolicyError()
    }

    const handleCheckPolicy1 = () => {
        setCheckPolicy1(!checkPolicy1)
    }

    const handleCheckPolicy2 = () => {
        setCheckPolicy2(!checkPolicy2)
    }

    const handleCheckPolicy3 = () => {
        setCheckPolicy3(!checkPolicy3)
    }

    const handleCheckPolicy4 = () => {
        setCheckPolicy4(!checkPolicy4)
    }

    const handleCheckPolicy5 = () => {
        setCheckPolicy5(!checkPolicy5)
    }

    const handleCheckPolicy6 = () => {
        setCheckPolicy6(!checkPolicy6)
    }

    const handleAcceptPolicy1 = () => {
        if(!checkPolicy1) {
            setPolicy1ErrorDiv(true)
        } else {
            setPolicy1ErrorDiv(false)
            handleCloseAcceptPolicy1(false)
            setOpenAcceptPolicy2(true)
        }
    }

    const handleAcceptPolicy2 = () => {
        if(!checkPolicy2) {
            setPolicy2ErrorDiv(true)
        } else {
            setPolicy2ErrorDiv(false)
            handleCloseAcceptPolicy2(false)
            setOpenAcceptPolicy3(true)
        }
    }

    const handleAcceptPolicy3 = () => {
        if(!checkPolicy3) {
            setPolicy3ErrorDiv(true)
        } else {
            setPolicy3ErrorDiv(false)
            handleCloseAcceptPolicy3(false)
            setOpenAcceptPolicy4(true)
        }
    }

    const handleAcceptPolicy4 = () => {
        if(!checkPolicy4) {
            setPolicy4ErrorDiv(true)
        } else {
            setPolicy4ErrorDiv(false)
            handleCloseAcceptPolicy4(false)
            setOpenAcceptPolicy5(true)
        }
    }

    const handleAcceptPolicy5 = () => {
        if(!checkPolicy5) {
            setPolicy5ErrorDiv(true)
        } else {
            setPolicy5ErrorDiv(false)
            handleCloseAcceptPolicy5(false)
            setOpenAcceptPolicy6(true)
        }
    }

    const handleAcceptPolicy6 = () => {
        if(!checkPolicy6) {
            setPolicy6ErrorDiv(true)
        } else {
            setPolicy6ErrorDiv(false)
            handleCloseAcceptPolicy6(false)
            handleSubmit()
        }
    }


    function isValidEmail(text) {
        return /[a-z0-9]+@[a-z]+\.[a-z]{2,3}/.test(text)
    }

    function isSpecialChar(text) {
        return /[ `!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/.test(text)
    }

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    const validateForm = () => {
        if(!email) {
            setEmailFormatErrorDiv(true)
            return false
        } else {
            setEmailFormatErrorDiv(false)
        }
        
        if(!isValidEmail(email)) {
            setEmailFormatErrorDiv(true)
            return false
        } else {
            setEmailFormatErrorDiv(false)
        }

        if(!password) {
            setPasswordErrorDiv(true)
            return false
        } else {
            if(password.length < 6) { 
                setPasswordErrorDiv(true)
                return false
            } else {
                setPasswordErrorDiv(false)
            }
        }

        if(!displayName || isSpecialChar(displayName)) {
            setDisplayNameErrorDiv(true)
            return false
        } else {
            setDisplayNameErrorDiv(false)
        }

        if(!firstname || isSpecialChar(firstname)) {
            setFirstnameErrorDiv(true)
            return false
        } else {
            setFirstnameErrorDiv(false)
        }

        if(!lastname || isSpecialChar(lastname)) {
            setLastnameErrorDiv(true)
            return false
        } else {
            setLastnameErrorDiv(false)
        }

        if(!telNo || !isOnlyNumeric(telNo)) {
            setTelNoErrorDiv(true)
            return false
        } else {
            setTelNoErrorDiv(false)
        }

        /* if(bankId == 0) {
            setBankIdErrorDiv(true)
            return false
        } else {
            setBankIdErrorDiv(false)
        }

        if(!bankAccNo || !isOnlyNumeric(bankAccNo)) {
            setBankAccNoErrorDiv(true)
            return false
        } else {
            setBankAccNoErrorDiv(false)
        } */

        if(provinceId == 0) {
            setProvinceIdErrorDiv(true)
            return false
        } else {
            setProvinceIdErrorDiv(false)
        }

        if(districtId == 0) {
            setDistrictIdErrorDiv(true)
            return false
        } else {
            setDistrictIdErrorDiv(false)
        }

        if(subdistrictId == 0) {
            setSubDistrictErrorDiv(true)
            return false
        } else {
            setSubDistrictErrorDiv(false)
        }
        return true
    }

    const handleImageProfile = (file) => {
        if(file.size > defauleImageSize) {
            setImageProfileSizeError(true)
        } else {
            setImageProfileUrl(URL.createObjectURL(file))
            setImageProfile(file)
            setImageProfileSizeError(false)
        }
    }

    const handleImageIdCard = (file) => {
        if(file.size > defauleImageSize) {
            setImageIdCardSizeError(true)
        } else {
            setIdCardUrl(URL.createObjectURL(file))
            setIdCard(file)
            setImageIdCardSizeError(false)
        }
    }

    const handleImageBookBank = (file) => {
        if(file.size > defauleImageSize) {
            setImageBookBankSizeError(true)
        } else {
            setBookBankUrl(URL.createObjectURL(file))
            setBookBank(file)
            setImageIdCardSizeError(false)
        }
    }

    const handleSubmit = async (e) => {
        if(validateForm()) {
            getDocs(query(collection(db, 'users'), where('email', '==', email))).then(async (userQuery) => {
                if(userQuery.docs.length == 0) {
                    setShowProgressBar(true)
                    setExistEmailErrorDiv(false)
                    const res = await createUserWithEmailAndPassword(auth, email, password)
                    await sendEmailVerification(auth.currentUser)
                    const uid = res.user.uid
                    await addDoc(collection(db, "users"), {
                        uid: uid,
                        authProvider: "email",
                        email: email,
                        password: password,
                        type: 1,
                        display_name: displayName,
                        firstname: firstname,
                        lastname: lastname,
                        tel_no: telNo,
                        address: address,
                        road: road,
                        subroad: subroad,
                        village: village,
                        building: building,
                        floor: floor,
                        room: room,
                        sub_district: subdistrictId,
                        district: districtId,
                        province: provinceId,
                        postcode: postcode,
                        lat: lat,
                        lng: lng,
                        bank_id: bankId,
                        bank_account_no: bankAccNo,
                        status: 1
                    }).then((docRef) => {
                        const memberId = docRef.id
                        getDoc(doc(db, 'free_coins', 'get_free_rate')).then((giveSnapshot) => {
                            var givedCoin = giveSnapshot.data().coin * 1
                            var promisesDoc = []
                            const updateUserCoinTask = updateDoc(doc(db, 'users', memberId), {
                                freecoin: giveSnapshot.data().coin * 1
                            })
                            promisesDoc.push(updateUserCoinTask)

                            const getCoinDataTask = getDoc(doc(db, 'free_coins', 'sum_data')).then((sumDoc) => {
                                var remain = sumDoc.data().remain * 1
                                if((givedCoin) > remain) {
                                    givedCoin = remain
                                }
                                const updateFreeCoinTask = updateDoc(doc(db, 'free_coins', 'sum_data'), {
                                    gived: (sumDoc.data().gived * 1) + (givedCoin),
                                    remain: (sumDoc.data().remain * 1) - (givedCoin)
                                }).then(() => {
                                    const addFreeConLogTask = addFreeCoinLog(db, givedCoin, "แจกเหรียญเงิน สมัครสมาชิก", memberId, "give")
                                    promisesDoc.push(addFreeConLogTask)

                                    const addNotiUserTask = addUserNotification(db, memberId, "คุณได้รับเหรียญเงินจำนวน "+givedCoin+" เหรียญ", 'coin', '', '/profile/coins')
                                    promisesDoc.push(addNotiUserTask)
                                })
                                promisesDoc.push(updateFreeCoinTask)
                                
                            })
                            promisesDoc.push(getCoinDataTask)

                            Promise.all(promisesDoc).then(() => {
                                var promisesFile = []
                                var uploadSize = 0
                                if(bookBank) {
                                    const bookbankRef = ref(storage, `/users/${memberId}/image/bookbank/${bookBank.name}`)
                                    const bookbankTask = uploadBytesResumable(bookbankRef, bookBank)
                                    uploadSize += bookBank.size
                                    promisesFile.push(bookbankTask)
                                    bookbankTask.on( "state_changed",
                                        () => { },
                                        (err) => console.log(err),
                                        () => { 
                                            getDownloadURL(bookbankTask.snapshot.ref).then((url) => {
                                                updateDoc(doc(db, `users`, memberId), {
                                                    book_bank: url,
                                                })
                                            })
                                        }
                                    )
                                }
                                if(idCard) {
                                    const idCardRef = ref(storage, `/users/${memberId}/image/idcard/${idCard.name}`)
                                    const idCardTask = uploadBytesResumable(idCardRef, idCard)
                                    promisesFile.push(idCardTask)
                                    idCardTask.on( "state_changed",
                                        () => { },
                                        (err) => console.log(err),
                                        () => { 
                                            getDownloadURL(idCardTask.snapshot.ref).then((url) => {
                                                updateDoc(doc(db, `users`, memberId), {
                                                    id_card: url,
                                                })
                                            })
                                        }
                                    )
                                }
                                if(imageProfile) {
                                    const imageRef = ref(storage, `/users/${memberId}/image/profile/${imageProfile.name}`)
                                    const imageTask = uploadBytesResumable(imageRef, imageProfile);
                                    promisesFile.push(imageTask)
                                    imageTask.on( "state_changed",
                                        () => { },
                                        (err) => console.log(err),
                                        () => { 
                                            getDownloadURL(imageTask.snapshot.ref).then((url) => {
                                                updateDoc(doc(db, `users`, memberId), {
                                                    image_profile: url,
                                                })
                                            })
                                        }
                                    )
                                }
        
                                Promise.all(promisesFile).then(() => {
                                    setShowProgressBar(false)
                                    navigate(`/`)
                                })
                            })
                        })
                    })
                } else {
                    setExistEmailErrorDiv(true)
                }
            }).catch((error) => {
                console.log(error)
            })
        }
    }

    const getBankList = () => {
        getDocs(query(collection(db, 'banks'), orderBy('order', 'asc'))).then((docs) => {
            setBankList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getProvince = () => {
        getDocs(query(collection(db, 'provinces'), orderBy('province_id', 'asc'))).then((docs) => {
            setProvinceList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectProvince = (provinceId) => {
        setProvinceId(provinceId)

        setDistrictId(0)
        districtList.splice(0, districtList.length)
        setDistrictList([...districtList])

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceId*1), orderBy('province_id', 'asc'))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectDistrict = (districtId) => {
        setDistrictId(districtId)

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtId), orderBy('district_id', 'asc'))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handelSelectsubDistrict = (subdistrictId) => {
        setSubDistrictId(subdistrictId)
        setPostcode('')
        getDocs(query(collection(db, 'postcodes'), where('subdistrict_id', '==', subdistrictId*1))).then((docs) => {
            setPostcode(docs.docs[0].data().postcode)
        })
    }

    const locationSuccess = (pos) => {
        var crd = pos.coords
        setLat(crd.latitude)
        setLng(crd.longitude)
        setCenter({lat: crd.latitude, lng: crd.longitude})
    }

    const locationErrors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
            });
        } else {
          //console.log("Geolocation is not supported by this browser.");
        }
    }, [])

    const handleClose = () => { }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',
        border: '2px solid #000',
        boxShadow: 24,
        p: 4,
    }

    useEffect(() => {
        if (loading) return;
        getBankList()
        getProvince()
    }, [loading])

    return (
        <>
            <Loading open={showProgressBar} text={"กำลังทำการสมัครสมาชิก กรุณารอสักครู่"} />
            <div style={{ backgroundColor: "#fcfcfc", paddingTop: 20 }}>
                <div className="container-fluid">
                    <div className="row gy-3">
                        <div className="col-xs-12 col-md-12">
                            <div className="d-flex justify-content-between">
                                <div>
                                    <h5>
                                        <BsPersonVcard style={{ fontSize: 30, marginRight: 10 }} />
                                        <b>สมัครสมาชิก</b>
                                    </h5>
                                </div>
                                <div className="d-flex">
                                    <div>
                                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={ () => { startAcceptPolicy() } }>
                                            <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />สมัครสมาชิก
                                        </button>
                                    </div>
                                    <div>
                                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/`) }}>
                                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="row gy-3">
                        <div className="col-xs-12 col-md-2">
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems:'center' }}>
                                <img src={imageProfileUrl} alt="WEEE" width="150" style={{ marginBottom: 5 }} />
                                <div style={{ display: "flex", flexDirection:'row' }}>
                                    <label htmlFor="fileProfileUpload" style={{ padding: 0 }}>
                                        <a className='btn btn-primary btn-sm'>เลือกรูป</a>
                                    </label>
                                </div>
                                {imageProfileSizeError &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                    <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                </div>
                                }
                                <input style={{ display:'none' }} className="form-control file" id="fileProfileUpload" type="file" accept="image/*" onChange={(e) => handleImageProfile(e.target.files[0])} />
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-5">
                            <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                                <h6><BsPersonVcard style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>ข้อมูลส่วนตัว</b></h6>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15 }}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>Email <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='email' onChange={(e) => setEmail(e.target.value)} 
                                            value={email} placeholder='Email'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {emailFormatErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอก Email ให้ถูกต้อง และไม่มีอักขระพิเศษ</strong>
                                        </div>
                                        }
                                        {existEmailErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** มีการใช้งาน Email นี้ในระบบแล้ว</strong>
                                        </div>
                                        }
                                    </div>
                                </div>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>Password <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='password' name='password' onChange={(e) => setPassword(e.target.value)} 
                                            value={password} placeholder='password'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {passwordErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอก Password และมีความยาวมากกว่าหรือเท่ากับ 6 ตัวอักษร</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>ชื่อผู้ใช้ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='displayName' onChange={(e) => setDisplayName(e.target.value)} 
                                            value={displayName} placeholder='ชื่อผู้ใช้'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {displayNameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกชื่อผู้ใช้ และไม่มีอักขระพิเศษ</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>ชื่อ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='firstname' onChange={(e) => setFirstname(e.target.value)} 
                                            value={firstname} placeholder='ชื่อ'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {firstnameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกชื่อ และไม่มีอักขระพิเศษ</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>นามสกุล <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='lastname' onChange={(e) => setLastname(e.target.value)} 
                                            value={lastname} placeholder='นามสกุล'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {lastnameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกนามสกุล และไม่มีอักขระพิเศษ</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150 }}><strong>เบอร์โทรศัพท์ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='telNo' onChange={(e) => setTelNo(e.target.value)} 
                                            value={telNo} placeholder='เบอร์โทรศัพท์'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {telNoErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกเบอร์โทรศัพท์ (เฉพาะตัวเลขเท่านั้น)</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>สำเนาบัตรประชาชน</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        {idCardUrl &&
                                        <a href={idCardUrl} target='_blank' style={{ marginBottom: 5, marginLeft: 5 }}><BsPersonVcard style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                        <input ref={inputRef} className="form-control file" id="idcard_image" type="file" accept="image/*" onChange={(e) => handleImageIdCard(e.target.files[0])} />
                                        {imageIdCardSizeError &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                            </div>
                            <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginTop: 15 }}>
                                <h6><RiBankCardLine style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>บัญชีธนาคาร</b></h6>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15 }}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 170 }}><strong>ธนาคาร{/*  <font className="text-danger">*</font> */}</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <select name='bankList' onChange={(e) => { setBankId(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, marginRight: 5 }}>
                                            <option value="0">--- กรุณาเลือกธนาคาร ---</option>
                                            {bankList?.map((bank, i) => (
                                            <option value={bank.id} key={bank.id}>
                                                {bank.data.bank_name_th} ({bank.data.bank_name_short})
                                            </option>
                                            ))}
                                        </select>
                                        {bankIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกธนาคาร</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 170 }}><strong>หมายเลขบัญชี{/*  <font className="text-danger">*</font> */}</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='bankAccNo' onChange={(e) => setBankAccNo(e.target.value)} 
                                            value={bankAccNo} placeholder='หมายเลขบัญชี'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                        {bankAccNoErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกเลขบัญชีธนาคาร (เฉพาะตัวเลขเท่านั้น)</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 170}}><strong>สำเนาหน้าสมุดบัญชี</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        {bookBankUrl &&
                                        <a href={bookBankUrl} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                        <input ref={inputRef2} className="form-control file" id="bookbank_image" type="file" accept="image/*" onChange={(e) => handleImageBookBank(e.target.files[0])} />
                                        {imageBookBankSizeError &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                            </div>
                        </div>
                        <div className="col-xs-12 col-md-5">
                            <div style={{ marginBottom: 10, paddingTop: 5, borderBottom: '1px solid #cccccc' }}>
                                <h6><TbHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>ข้อมูลที่อยู่</b></h6>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 15 }}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>บ้านเลขที่</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='address' onChange={(e) => setAddress(e.target.value)} 
                                            value={address} placeholder='บ้านเลขที่'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>หมู่บ้าน/สถานที่</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='village' onChange={(e) => setVillage(e.target.value)} 
                                            value={village} placeholder='หมู่บ้าน/สถานที่'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>อาคาร</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='building' onChange={(e) => setBuilding(e.target.value)} 
                                            value={building} placeholder='อาคาร'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ชั้น</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='floor' onChange={(e) => setFloor(e.target.value)} 
                                            value={floor} placeholder='ชั้น'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ห้อง</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='room' onChange={(e) => setRoom(e.target.value)} 
                                            value={room} placeholder='ห้อง'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ซอย</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='subroad' onChange={(e) => setSubRoad(e.target.value)} 
                                            value={subroad} placeholder='ซอย'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ถนน</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                            value={road} placeholder='ถนน'
                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>จังหวัด <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <select name='province' onChange={(e) => { handleSelectProvince(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0">--- กรุณาเลือกจังหวัด ---</option>
                                            {provinceList?.map((province, i) => (
                                            <option value={province.data.province_id} key={province.data.province_id}>
                                                {province.data.province_name}
                                            </option>
                                            ))}
                                        </select>
                                        {provinceIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกจังหวัด</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>อำเภอ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <select name='district' onChange={(e) => { handleSelectDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0" key="0">--- กรุณาเลือกอำเภอ ---</option>
                                            {districtList?.map((district, i) => (
                                            <option value={district.data.district_id} key={district.data.district_id}>
                                                {district.data.district_name}
                                            </option>
                                            ))}
                                        </select>
                                        {districtIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกอำเภอ</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>ตำบล <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <select name='subdistrict' onChange={(e) => { handelSelectsubDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0">--- กรุณาเลือกตำบล ---</option>
                                            {subdistrictList?.map((subdistrict, i) => (
                                            <option value={subdistrict.data.subdistrict_id} key={subdistrict.data.subdistrict_id}>
                                                {subdistrict.data.subdistrict_name}
                                            </option>
                                            ))}
                                        </select>
                                        {subdistrictIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกตำบล</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200 }}><strong>รหัสไปรษณีย์ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input type='text' name='postcode' readOnly
                                            value={postcode} placeholder='รหัสไปรษณีย์'
                                            style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xs-12 offset-md-2 col-md-10">
                            <div style={{ display: 'flex', flexDirection: 'column', width: 170 }}><></></div>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginTop: 15 }}>
                                    <h6><FaMapMarkerAlt style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} />
                                    <b>ตำแหน่งพิกัดที่พักอาศัย</b></h6>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                    <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap' }}>
                                        <font className="text-danger" style={{ marginRight: 5 }}>**</font> ลาก Marker <FaMapMarkerAlt className='text-danger' style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} /> เพื่อกำหนดตำแหน่ง
                                    </div>
                                    <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                                    <div className="mb-3 row" style={{ display: 'flex', height:400, flexDirection: 'column' }}>
                                        <div style={{  display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                            <GoogleMap mapContainerClassName="map-container" center={{ lat: lat, lng: lng }} zoom={17} >
                                                <MarkerF 
                                                    draggable={true} position={center}
                                                    onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}/>
                                            </GoogleMap>
                                            )}
                                        </div>
                                    </div>
                                </div> 
                            </div>
                            <hr />
                            <div className="d-flex">
                                <div>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={ () => { startAcceptPolicy() } }>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />สมัครสมาชิก
                                    </button>
                                </div>
                                <div>
                                    <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/`) }}>
                                        <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>


            <Dialog open={openAcceptPolicy1} onClose={handleCloseAcceptPolicy1} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>เงื่อนไขการใช้งาน</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. แพลตฟอร์มนี้ให้บริการเฉพาะผู้ที่บรรลุนิติภาวะแล้วเท่านั้น หากท่านยังไม่บรรลุนิติภาวะ ท่านต้องหยุดการเข้าถึงแพลตฟอร์มในขณะนี้ การใช้บริการแพลตฟอร์มเป็นการแสดงเจตนายืนยันว่าท่านบรรลุนิติภาวะแล้ว และมีคุณสมบัติครบถ้วนที่จะทำการยอมรับเงื่อนไขการใช้งาน อย่างไรก็ตามหาก หจก. ออนซอน พบว่าท่านมีคุณสมบัติไม่ครบถ้วนตามที่ หจก. ออนซอน กำหนด ทั้งนี้ หจก. ออนซอน มีสิทธิ์ที่จะระงับการใช้งานบัญชีผู้ใช้ของท่าน และหากมีความเสียหายหรือข้อร้องเรียนอันเกิดจากท่านทั้งตั้งใจหรือการแอบอ้างโดยใช้ชื่อหรือหลักฐานของผู้อื่นถือว่าท่านยอมรับให้ดำเนินคดีตามกฎหมายและยินยอมชดใช้ความเสียหายทั้งหมดรวมถึงค่าใช้จ่ายในการดำเนินคดีทั้งหมด</p>
                            <p>2. เงื่อนไขการใช้งานนี้เป็นสัญญาระหว่าง หจก. ออนซอน ในฐานะผู้ให้บริการแพลตฟอร์มกับท่าน ในฐานะผู้ใช้บริการแพลตฟอร์ม (“ท่าน” หรือ “ผู้ใช้งาน”) ท่านตกลงว่าความสัมพันธ์ระหว่างท่านกับ หจก. ออนซอน อันเป็นผลมาจากการทำสัญญานี้ หรือจากการใช้งานแพลตฟอร์ม ไม่ถือเป็นการเป็นหุ้นส่วน การลงทุนร่วม หรือความสัมพันธ์ระหว่างตัวการกับตัวแทน ระหว่างท่านและ หจก. ออนซอน และไม่มีสิ่งใดอนุญาตให้ท่านสร้างภาระค่าใช้จ่ายหรือหนี้สินในนามของหจก. ออนซอน</p>
                            <p>3. หจก. ออนซอน ขอสงวนสิทธิที่จะแก้ไขหรือเปลี่ยนแปลงเงื่อนไขการใช้งานโดยไม่ต้องแจ้งให้ท่านทราบ ท่านมีหน้าที่ที่จะต้องคอยตรวจสอบเงื่อนไขการใช้งาน รวมทั้งเงื่อนไขเพิ่มเติมใดๆ ที่ระบุอยู่ในแพลตฟอร์มอย่างสม่ำเสมอ การใช้แพลตฟอร์มของท่านอย่างต่อเนื่องย่อมก่อให้เกิดการตกลงและสัญญาตามเงื่อนไขการใช้งานใดๆที่ได้ถูกแก้ไขหรือเปลี่ยนแปลงดังกล่าวทั้งหมดด้วย</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy1?"checked":""} onChange={(e) => handleCheckPolicy1(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy1ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy1} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy1} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy2} onClose={handleCloseAcceptPolicy2} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>การเก็บรวบรวม ใช้ และเปิดเผยข้อมูลส่วนบุคคล</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. ท่านตกลงและยอมรับว่าข้อมูลส่วนบุคคลของท่านทั้งหมดที่ หจก. ออนซอน รวบรวมได้จะถูกเก็บรวบรวม ถูกใช้ และ/หรือถูกเปิดเผยต่อบุคคลและเพื่อวัตถุประสงค์ตามที่ระบุอยู่ในนโยบายการคุ้มครองข้อมูลส่วนบุคคลของแพลตฟอร์ม</p>
                            <p>2. ท่านยินยอมและอนุญาตในการที่ หจก. ออนซอน ใช้ข้อมูลใดๆ ที่ท่านให้ไว้ (รวมทั้งข้อมูลส่วนบุคคล) เพื่อประโยชน์ในการส่งอีเมล์ข้อมูลรายละเอียดหรือการส่งเสริมการขายให้แก่ท่าน ท่านสามารถยกเลิกรับอีเมล์ส่งเสริมการขายในภายหลังได้ด้วยการคลิกที่ลิงค์ที่แนบไว้กับอีเมล์ส่งเสริมการขายใดๆ</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy2?"checked":""} onChange={(e) => handleCheckPolicy2(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy2ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy2} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy2} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy3} onClose={handleCloseAcceptPolicy3} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>สัญญาให้บริการ</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. สัญญาให้บริการระบบ Platform 3R ระหว่างผู้ใช้บริการแพลตฟอร์ม ประกอบไปด้วย ประชาชนทั่วไปที่สมัครเข้าใช้งานแพลตฟอร์ม  ในข้อตกลงนี้ คือ “สมาชิก” และสมาชิกที่เป็นนิติบุคคล ร้าน หรือ บริษัท ในข้อตกลงนี้ คือ “ผู้ให้บริการ”</p>
                            <p>2. สัญญาให้บริการระบบ Platform 3R เป็นสัญญาโดยตรงระหว่างสมาชิกและผู้ให้บริการ หจก. ออนซอน ไม่ได้เป็นคู่สัญญาของสัญญาฉบับนั้นหรือสัญญาอื่นใดระหว่างสมาชิกและผู้ให้บริการ หจก. ออนซอน จะไม่ยอมรับข้อผูกพันใดๆที่เกี่ยวเนื่องกับสัญญาดังกล่าว คู่สัญญาของธุรกรรมดังกล่าวจะรับผิดชอบต่อสัญญาระหว่างคู่สัญญาทั้งหมด หจก. ออนซอน ไม่มีส่วนเกี่ยวข้องในธุรกรรมระหว่างสมาชิกและผู้ให้บริการ หจก. ออนซอน ไม่รับประกันในผลของงานใดๆ ของผู้ให้บริการ</p>
                            <p>3. หจก. ออนซอน แนะนำให้สมาชิกต้องใช้ความระมัดระวังรอบคอบในการตรวจสอบความมีตัวตน ความน่าเชื่อถือ และความรู้ ความสามารถ ของผู้ให้บริการหรือคู่สัญญา</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy3?"checked":""} onChange={(e) => handleCheckPolicy3(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy3ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy3} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy3} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy4} onClose={handleCloseAcceptPolicy4} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>บัญชีผู้ใช้งานและความปลอดภัย</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. การใช้บริการบางอย่างของแพลตฟอร์มอาจจำเป็นต้องมีการลงทะเบียนสร้างบัญชีผู้ใช้งาน โดยการกำหนดชื่อผู้ใช้งาน (username) ที่ไม่ซ้ำกันและรหัสผ่าน และการให้ข้อมูลบางอย่างของท่าน ท่านยืนยันว่าข้อมูลต่างๆที่ใช้ในการสร้างบัญชีผู้ใช้งานเป็นข้อมูลที่ถูกต้องแท้จริงของท่านเอง ท่านต้องไม่สร้างบัญชีผู้ใช้งานมากกว่า 1 บัญชี ท่านต้องไม่แกล้งทำเป็นบุคคลใดนอกเหนือจากตัวท่านเอง ท่านต้องไม่ทำให้ หจก. ออนซอน หรือบุคคลภายนอกเข้าใจผิดในเรื่องต้นกำเนิดของการส่งข้อมูลใดๆ และท่านตกลงและยอมรับว่าสิทธิการเป็นเจ้าของในข้อมูลของบัญชีผู้ใช้งานเป็นของ หจก. ออนซอน และของท่านร่วมกัน</p>
                            <p>2. หจก. ออนซอน ห้ามไม่ให้ท่านใช้บัญชีผู้ใช้งานของบุคคลอื่นโดยไม่ได้รับอนุญาต ท่านจะต้องรักษารหัสของบัญชีผู้ใช้งานของท่านไว้อย่างปลอดภัย ท่านจะไม่เปิดเผยข้อมูลของบัญชีผู้ใช้งานต่อบุคคลภายนอกไม่ว่าทั้งหมดหรือบางส่วน และจะไม่ยินยอมให้บุคคลภายนอกสามารถเข้าถึงหรือใช้ข้อมูลบัญชีผู้ใช้ของท่าน ท่านจะต้องรับผิดชอบแต่เพียงผู้เดียวสำหรับกิจกรรมใดๆ ที่เกิดขึ้นในและ/หรือจากบัญชีผู้ใช้งานของท่าน โดยท่านจะต้องรับผิดต่อความสูญเสียหรือเสียหายที่ หจก. ออนซอน หรือบุคคลภายนอกอื่นได้รับเนื่องจากการเข้าถึงบัญชีผู้ใช้งานและ/หรือการทำกิจกรรมใดๆผ่านบัญชีผู้ใช้งานของท่าน</p>
                            <p>3. ท่านตกลงที่จะเปลี่ยนรหัสผ่านของท่านเป็นครั้งคราว หจก. ออนซอน อาจจะร้องขอให้ท่านอัพเดทข้อมูลของท่าน หรือยกเลิกชื่อผู้ใช้งานและ/หรือรหัสผ่านทันทีในเวลาใดๆ โดยดุลพินิจแต่ฝ่ายเดียวของ หจก. ออนซอน โดยไม่ต้องให้เหตุผลหรือคำบอกกล่าวล่วงหน้า และ หจก. ออนซอน ไม่ต้องรับผิดหรือรับผิดชอบในความสูญเสียใดๆ ที่ท่านได้รับจากคำขอหรือการยกเลิกดังกล่าว</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy4?"checked":""} onChange={(e) => handleCheckPolicy4(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy4ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy4} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy4} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy5} onClose={handleCloseAcceptPolicy5} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ลิงก์ของบุคคลภายนอก</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. แพลตฟอร์มอาจมีลิงก์ไปยังเว็บไซต์ แพลตฟอร์ม เครือข่าย หรือสื่ออื่นใดของบุคคลภายนอก (“ลิงก์ของบุคคลภายนอก”) ลิงก์ของบุคคลภายนอกเหล่านี้ไม่ได้เป็นของหรือควบคุมโดย หจก. ออนซอน แต่ดำเนินการโดย และเป็นทรัพย์สินของบุคคลภายนอก หจก. ออนซอน ไม่ได้ตรวจสอบและไม่รับผิดชอบต่อเนื้อหา ฟังก์ชันการทำงาน ความปลอดภัย บริการ นโยบายการคุ้มครองข้อมูลส่วนบุคคล หรือการปฏิบัติอื่นๆ ของลิงก์ของบุคคลภายนอกเหล่านี้ หจก. ออนซอน ขอแนะนำให้ท่านอ่านข้อกำหนดและนโยบายใดๆ ที่เผยแพร่โดยบุคคลภายนอกดังกล่าว อีกทั้งท่านรับทราบและตกลงว่า หจก. ออนซอน จะไม่รับผิดชอบในลักษณะใดก็ตามอันเนื่องมาจากการใช้งานหรือการไม่สามารถใช้งานลิงก์ของบุคคลภายนอก</p>
                            <p>2. ท่านรับทราบและตกลงว่า หจก. ออนซอน สงวนสิทธิ์ในการลบลิงก์ของบุคคลภายนอกโดยไม่ต้องให้เหตุผลหรือคำบอกกล่าวล่วงหน้า และ หจก. ออนซอน ไม่ต้องรับผิดชอบในความสูญเสียใดๆ จากการลบลิงก์ของบุคคลภายนอกดังกล่าว</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy5?"checked":""} onChange={(e) => handleCheckPolicy5(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy5ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy5} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy5} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openAcceptPolicy6} onClose={handleCloseAcceptPolicy6} maxWidth="md" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt', display: 'flex', flexDirection:'row'}}>
                        <h3>
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                            <strong>ข้อตกลงและเงื่อนไขการใช้งานระบบ Platform 3R</strong>
                        </h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10, display: 'flex' }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <h4><strong>ข้อกำหนดทั่วไป</strong></h4>
                        </div>
                        <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                            <p>1. หจก. ออนซอน สงวนสิทธิทั้งหมดที่ไม่ได้แจ้งไว้ในที่นี้</p>
                            <p>2. เว้นแต่จะระบุไว้เป็นอย่างอื่น สกุลเงินที่ใช้อ้างถึงบนแพลตฟอร์มคือเงินบาทไทย</p>
                            <p>3. การที่ หจก. ออนซอน ไม่ได้บังคับตามเงื่อนไขการใช้งานนี้จะไม่ถือเป็นการสละสิทธิในเงื่อนไขนี้ และการไม่ได้บังคับดังกล่าวจะไม่กระทบต่อสิทธิที่จะบังคับตามเงื่อนไขการใช้งานนี้ในภายหลัง หจก. ออนซอน ยังคงมีสิทธิที่จะใช้สิทธิและการเยียวยาของ หจก. ออนซอน ในสถานการณ์อื่นใดที่ท่านกระทำการผิดสัญญาในเงื่อนไขการใช้งานนี้</p>
                            <p>4. หากข้อกำหนดใดในเงื่อนไขการใช้งานนี้ถือว่าผิดกฎหมาย เป็นโมฆะ หรือไม่สามารถบังคับใช้ได้ไม่ว่าด้วยเหตุผลใด ให้ถือว่าข้อกำหนดนั้นสามารถแยกออกจากกันได้จากข้อกำหนดที่เหลือ และไม่ส่งผลต่อความสมบูรณ์และการมีผลบังคับใช้ของข้อกำหนดที่เหลือ</p>
                            <p>5. หจก. ออนซอน สงวนสิทธิที่จะมอบหมายหรือทำสัญญาช่วงการปฏิบัติตามหน้าที่ใดๆ ของ หจก. ออนซอน อันเกี่ยวพันกับแพลตฟอร์มและ/หรือ หจก. ออนซอน และสงวนสิทธิที่จะใช้ผู้ให้บริการภายนอก ผู้รับเหมาช่วง และ/หรือตัวแทนรายใดในเงื่อนไขที่ หจก. ออนซอน เห็นว่าเหมาะสม</p>
                            <p>6. ท่านไม่อาจโอนสิทธิของท่านภายใต้เงื่อนไขการใช้งานนี้หากไม่ได้รับความยินยอมล่วงหน้าเป็นลายลักษณ์อักษรจากหจก. ออนซอน ทั้งนี้ หจก. ออนซอน อาจจะโอนสิทธิของ หจก. ออนซอน ภายใต้เงื่อนไขการใช้งานนี้แก่บุคคลภายนอกคนใดก็ได้</p>
                            <p>7. หจก. ออนซอน ไม่ต้องรับผิดในการไม่ปฏิบัติตามหน้าที่ของ หจก. ออนซอน ภายใต้เงื่อนไขการใช้งานนี้ (หรือส่วนหนึ่งส่วนใดของเงื่อนไขการใช้งานนี้) หรือในความไม่ถูกต้อง ความไม่น่าเชื่อถือ หรือความไม่เหมาะสมใดๆของแพลตฟอร์ม หากไม่ว่าทั้งหมดหรือบางส่วน ไม่ว่าโดยตรงหรือโดยอ้อม เกิดจากเหตุการณ์หรือความผิดพลาดที่อยู่เหนือการควบคุมอย่างเหมาะสมของ หจก. ออนซอน (เหตุสุดวิสัย)</p>
                            <p>8. เงื่อนไขการใช้งานนี้อยู่ภายใต้บังคับของกฎหมายแห่งราชอาณาจักรไทย และข้อพิพาทใดๆที่เกี่ยวข้องจะอยู่ภายใต้อำนาจในการตัดสินคดีของศาลไทย</p>
                            <p>9. คำถามและข้อร้องเรียน หากท่านมีคำถาม ข้อร้องเรียน หรือปัญหาใดๆเกี่ยวกับเงื่อนไขการใช้งานนี้ โปรดติดต่อ หจก. ออนซอน ที่ หรือผ่านทางช่องทางการติดต่อในหน้าติดต่อเรา</p>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                            <input type="checkbox" checked={checkPolicy6?"checked":""} onChange={(e) => handleCheckPolicy6(e.target.value)} style={{ fontSize: 20, marginRight: 5 }} /> ยอมรับข้อตกลง
                            </div>
                            {policy6ErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณายอมรับข้อตกลง</strong>
                                </div>
                            }
                        </div> 
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPolicy6} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleAcceptPolicy6} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยอมรับข้อตกลง</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Register;