import React, { useState, useMemo, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api";
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, doc, getDoc, onSnapshot } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom";
import { FaMapMarkerAlt } from 'react-icons/fa'
import { TbUser, TbTool, TbEdit, TbForbid2, TbFileSearch, TbPhoto, TbHome, TbUsers, TbUserPlus } from "react-icons/tb"
import { AiFillShop, AiFillInfoCircle } from "react-icons/ai"

const ShopTech = () => {
    const params = useParams();
    const shopId = params.id
    const [shop, setShop] = useState([])
    const [technicians, setTechnicians] = useState([])
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState()
    const navigate = useNavigate();

    const [loading, error] = useAuthState(auth);

    const getDocShop = async(id) => {
        await getDoc(doc(db, 'shops', id)).then((shopSnapshot) => {
            setShop(shopSnapshot.data());
            if(shopSnapshot.data().user_id) {
                setMemberId(shopSnapshot.data().user_id)
                getDoc(doc(db, 'users', shopSnapshot.data().user_id)).then((memberDoc) => {
                    setMember(memberDoc.data())
                })
            }
        }).then(() => {
            const techSnapshot = query(collection(db, `shops/${shopId}/technician`))
            onSnapshot(techSnapshot, (querySnapshot) => {
                setTechnicians(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        })
    }

    useEffect(() => {
        getDocShop(shopId)
    },[loading])

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}><TbTool style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                    <b>ข้อมูลช่าง</b></h4>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/view/${shopId}`) }}>
                            <AiFillShop style={{ fontSize: 20, marginRight: 5 }} />ข้อมูลร้านค้า
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
                        <div style={{ paddingTop: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row' }}>
                            <h6 style={{ display: 'flex', flex: 1, marginTop: 5}}><TbUsers style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>รายชื่อช่างในร้าน</b></h6>
                            <div style={{ float: 'right',  }}>
                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/technician/add/${shopId}`) }}>
                                    <TbUserPlus style={{ fontSize: 20, marginRight: 5 }} />เพิ่มช่างในร้าน
                                </button>
                            </div>
                        </div>
                        <div style={{ }}>
                            <table className="table" style={{  }}>
                                <thead>
                                    <tr>
                                        <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                        <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: '90%'}}>ชื่อ-นามสกุล</th>
                                        <th scope="col" style={{ backgroundColor: '#eeeeee', minWidth: 220}}> </th>
                                    </tr>
                                </thead>
                                <tbody>
                                {
                                    technicians?.map((technician, i) => (
                                        <tr key={i}>
                                            <td style={{ paddingLeft: 10 }}> </td>
                                            <th scope="row">
                                                <>
                                                    <font style={{ marginRight: 10}}>{technician.data.firstname}</font>
                                                    <font style={{ marginRight: 10}}>{technician.data.lastname}</font>
                                                </>
                                            </th>
                                            <td style={{ display: 'flex', flexDirection: 'row', flex:1}}>
                                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex', float:'right' }} onClick={() => { return navigate(`/admin/technician/edit/${shopId}/${technician.id}`) }}><TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขข้อมูล</button>
                                                <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex', float:'right' }} onClick={() => { return navigate(`/admin/technician/view/${shopId}/${technician.id}`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูล</button>
                                            </td>
                                        </tr>
                                    ))
                                }
                                </tbody>
                            </table>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3, borderLeft: '1px solid #cccccc', marginLeft: 20, paddingLeft: 20}}>
                        <div style={{ paddingTop: 10, borderBottom: '1px solid #cccccc' }}>
                            <h6><AiFillShop style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อมูลร้านค้า</b></h6>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ผู้ใช้ </strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    {(member) && (
                                    <>
                                        <font style={{ marginRight: 10}}>{member.firstname?member.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                        <font style={{ marginRight: 10}}>{member.lastname?member.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                        <font style={{ marginRight: 10}}>({member.display_name?member.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font>
                                        <a href={`/admin/member/view/${memberId}`}><AiFillInfoCircle className='text-primary' style={{ fontSize: 25 }} /></a>
                                    </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>email </strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    {(member) && (
                                    <><font style={{ marginRight: 10}}>{member.email?member.email:""}</font></>
                                    )}
                                </div>
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ประเภทร้านค้า</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {(shop.type==1)&&(<div><TbUser style={{ fontSize: 20, marginRight: 5 }} />บุคคลธรรมดา</div>)}
                                {(shop.type==2)&&(<div><AiFillShop style={{ fontSize: 20, marginRight: 5 }} />นิติบุคคล</div>)}
                            </div>
                        </div> 
                        {(shop.type==2)&& (
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อร้านค้า</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.shopname?shop.shopname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        )}
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อ (เจ้าของ)</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.firstname?shop.firstname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>นามสกุล (เจ้าของ)</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.lastname?shop.lastname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>เบอร์โทรศัพท์</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.tel_no?shop.tel_no:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ShopTech;