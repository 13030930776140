import React, { useState, useEffect } from 'react'
import { collection, query, orderBy, onSnapshot, where, getDocs, and, or } from "firebase/firestore"
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { useNavigate } from "react-router-dom"
import { TbCirclePlus } from "react-icons/tb"
import { AiOutlineShop, AiFillShop, AiFillInfoCircle } from "react-icons/ai"
import { FiEdit } from "react-icons/fi"
import "../../assets/css/memberList.css"
import 'react-confirm-alert/src/react-confirm-alert.css'
import { TbUser, TbSearch } from "react-icons/tb"
import { IoCloseCircle } from "react-icons/io5"
import Pagination from '@mui/material/Pagination'

const MemberList = () => {
    const [users, setUsers] = useState([])
    const [user, loading, error] = useAuthState(auth)
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()

    const [keyword, setKeyword] = useState("")
    const [field, setField] = useState('email')
    const [order, setOrder] = useState('asc')
    const [orderby, setOrderBy] = useState('email')

    const [searchTypeStatus, setSearchTypeStatus] = useState([])
    const [searchTypeShop, setSearchTypeShop] = useState([])
    const [usersPage, setUsersPage] = useState([])
    const [page, setPage] = useState(1)

    const handleClearSearch = () => {
        searchTypeStatus.splice(0, searchTypeStatus.length)
        setSearchTypeStatus([...searchTypeStatus])
        searchTypeShop.splice(0, searchTypeShop.length)
        setSearchTypeShop([...searchTypeShop])
        setKeyword("")
        setField('email')

        handleSearch()
    }

    const handlePage = (event, value) => {
        const userSlide = users.slice(((value-1)*20), (((value-1)*20)+20))
        setUsersPage(userSlide)
        setPage(value)
    }

    const handleSearchByStatus = (value) => {
        const status = value * 1
        const others = Array.from(
            document.getElementsByClassName('type-other')
        )
        const allStatus = Array.from(
            document.getElementsByClassName('type-all')
        )

        if(status == 0) {
            searchTypeStatus.splice(0, searchTypeStatus.length)
            setSearchTypeStatus([...searchTypeStatus])
            allStatus.forEach(element => {
                element.checked = true
            })
            others.forEach(element => {
                element.checked = false
                element.removeAttribute('checked')
            })
        } else {
            if(searchTypeStatus.indexOf(status) < 0) {
                searchTypeStatus.push(status)
            } else {
                searchTypeStatus.splice(searchTypeStatus.indexOf(status), 1)
            }
            setSearchTypeStatus([...searchTypeStatus])
            if(searchTypeStatus.length > 0) {
                allStatus.forEach(element => {
                    element.checked = false
                    element.removeAttribute('checked')
                })
            } else {
                allStatus.forEach(element => {
                    element.checked = true
                })
            }
        }

        handleSearch()
    }

    const handleSearchByShop = (value) => {
        const status = value * 1
        const others = Array.from(
            document.getElementsByClassName('shop-other')
        )
        const allStatus = Array.from(
            document.getElementsByClassName('shop-all')
        )

        if(status == 99) {
            searchTypeShop.splice(0, searchTypeShop.length)
            setSearchTypeShop([...searchTypeShop])
            allStatus.forEach(element => {
                element.checked = true
            })
            others.forEach(element => {
                element.checked = false
                element.removeAttribute('checked')
            })
        } else {
            if(searchTypeShop.indexOf(status) < 0) {
                searchTypeShop.push(status)
            } else {
                searchTypeShop.splice(searchTypeShop.indexOf(status), 1)
            }
            setSearchTypeShop([...searchTypeShop])
            if(searchTypeShop.length > 0) {
                allStatus.forEach(element => {
                    element.checked = false
                    element.removeAttribute('checked')
                })
            } else {
                allStatus.forEach(element => {
                    element.checked = true
                })
            }
        }

        handleSearch()
    }

    const handleSearch = () => {
        const constraints = []
        const order = []
        order.push(orderBy(field, 'asc'))

        if(searchTypeStatus.length > 0) {
            constraints.push(where('status', 'in', searchTypeStatus))
        }

        if(searchTypeShop.length > 0) {
            constraints.push(where('is_shop', 'in', searchTypeShop))
        }

        if(keyword) {
            constraints.push(and(where(field, '>=', keyword), where(field, '<=', keyword +  '\uf8ff')))
        }
        
        if(constraints.length >= 2) {
            var userQuery = query(collection(db, 'users'), and(...constraints), ...order)
        } else {
            var userQuery = query(collection(db, 'users'), ...constraints, ...order)
        }

        onSnapshot(userQuery, (querySnapshot) => {
            users.splice(0, users.length)
            setUsers([...users])
            usersPage.splice(0, usersPage.length)
            setUsersPage([...usersPage])
            querySnapshot.forEach(async(member) => {
                const postQuery = await getDocs(query(collection(db, 'posts'), where('poster_id', '==', member.id))).then((postQuery) => {
                    users.push({
                        id: member.id,
                        data: member.data(),
                        postNum: postQuery.docs.length
                    })
                    setUsers([...users], {
                        id: member.id,
                        data: member.data(),
                        postNum: postQuery.docs.length
                    })
                    const userSlide = users.slice(0, 20)
                    setUsersPage(userSlide)
                })
            })
        })
    }

    useEffect(() => {
        users.splice(0, users.length)
        setUsers([...users])
        handleSearch()
    }, [user, loading])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'column', marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><TbUser style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายชื่อผู้ใช้</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', width: 220, marginRight: 10 }}>
                    <div><h5><strong>ค้นหา</strong></h5></div>
                    <div>
                        <h6><strong>สถานะของผู้ใช้งาน</strong></h6>
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <div style={{ whiteSpace: 'nowrap'}}><input className="form-check-input type-all" type="checkbox" value="0" defaultChecked={true} onClick={ (e) => { handleSearchByStatus(e.target.value) }} /> ทั้งหมด</div>
                        <div style={{ whiteSpace: 'nowrap'}}><input className="form-check-input type-other" type="checkbox" value="2" onClick={ (e) => { handleSearchByStatus(e.target.value) }} /> อนุมัติ</div>
                        <div style={{ whiteSpace: 'nowrap'}}><input className="form-check-input type-other" type="checkbox" value="1" onClick={ (e) => { handleSearchByStatus(e.target.value) }} /> ยังไม่อนุมัติ</div>
                    </div>
                    <div>
                        <h6><strong>ร้านค้า</strong></h6>
                    </div>
                    <div style={{ paddingLeft: 10 }}>
                        <div style={{ whiteSpace: 'nowrap'}}><input className="form-check-input shop-all" type="checkbox" value="99" defaultChecked={true} onClick={ (e) => { handleSearchByShop(e.target.value) }} /> ทั้งหมด</div>
                        <div style={{ whiteSpace: 'nowrap'}}><input className="form-check-input shop-other" type="checkbox" value="1" onClick={ (e) => { handleSearchByShop(e.target.value) }} /> มีร้านค้า</div>
                        <div style={{ whiteSpace: 'nowrap'}}><input className="form-check-input shop-other" type="checkbox" value="0" onClick={ (e) => { handleSearchByShop(e.target.value) }} /> ไม่มีร้านค้า</div>
                    </div>
                    <div>
                        <h6><strong>ค้นหาด้วย</strong></h6>
                    </div>
                    <div style={{ paddingLeft: 10, display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                        <select name='field' onChange={(e) => setField(e.target.value)} style={{ height: 30, display: 'flex', flex: 1 }}>
                            <option value="email">Email</option>
                            <option value="display_name">ชื่อผู้ใช้</option>
                            <option value="firstname">ชื่อ</option>
                            <option value="lastname">นามสกุล</option>
                        </select>
                    </div>
                    <div style={{ paddingLeft: 10, display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="keyword" 
                            aria-describedby="button-addon2"
                            onChange={(e) => setKeyword(e.target.value)} 
                            value={keyword}
                            style={{ height:30 }} />
                    </div>
                    <div style={{ paddingLeft: 10, display: 'flex', flexDirection: 'row', alignItems: 'flex-end', marginBottom: 10 }}>
                        <div style={{ display: 'flex', flex: 1}}> </div>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleClearSearch() }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} /> <b>ล้างเงื่อนไข</b>
                        </button>
                        <button type="button" className="btn btn-success btn-sm" style={{ display: 'flex' }} onClick={() => { handleSearch() }}>
                            <TbSearch style={{ fontSize: 20, marginRight: 5 }} /> <b>ค้นหา</b>
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap', flex: 1, borderLeft: '1px solid #cccccc'}}>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingTop: 10, paddingBottom: 5, alignContent: 'center' }}>
                        <div style={{ marginLeft: 15, paddingTop: 5, marginRight: 10, display: 'flex', flex: 1 }}><strong>รายการที่ {(((page-1)*20)+1)} - {(page*9 > users.length)?users.length:page*20} จากทั้งหมด {number_format.format(users.length)} รายการ</strong></div>
                        <div style={{ paddingRight: 10, marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/member/add`) }}>
                                <TbCirclePlus style={{ fontSize: 20, marginRight: 5 }} /> <b>เพิ่มผู้ใช้</b>
                            </button>
                        </div>
                        <Pagination style={{ fontFamily: 'Prompt', marginRight: 10 }} count={Math.ceil(users.length/20)} page={page} onChange={handlePage} color="primary" showFirstButton showLastButton />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                    {users.length > 0?
                        <table className="table" style={{ marginLeft: 10}}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}> </th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>Email</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ชื่อ-นามสกุล (ชื่อผู้ใช้)</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>สถานะยืนยัน</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, textAlign: 'center'}}>จำนวนประกาศ</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                                </tr>
                            </thead>
                            <tbody>
                        {
                        usersPage?.map((user, i) => (
                            <tr key={i}>
                                <td style={{ paddingLeft: 10 }}> </td>
                                <th scope="row">{user.data.email}</th>
                                <td>
                                    <font style={{ marginRight: 10}}>{user.data.firstname?user.data.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                    <font style={{ marginRight: 10}}>{user.data.lastname?user.data.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                    <font style={{ marginRight: 10}}>({user.data.display_name?user.data.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font> 
                                </td>
                                <td style={{ textAlign: 'center' }}><font style={{ }}>{user.data.status==1?(<font className='text-danger'>ยังไม่อนุมัติ</font>):(<font className='text-success'>อนุมัติ</font>)}</font></td>
                                <td style={{ textAlign: 'center' }}><a href='' onClick={() => { return navigate(`/admin/member/posts/${user.id}`) }}>{user.postNum}</a></td>
                                <td style={{ display: 'flex', flexDirection: 'row'}}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/member/edit/${user.id}`) }}><FiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไข</button>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/member/view/${user.id}`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูล</button>
                                    { user.data.is_shop === 1?
                                        (<button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/view/${user.data.shop_id}`) }}><AiFillShop style={{ fontSize: 20, marginRight: 5 }} />ร้านค้า</button>)
                                        :(<button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} disabled><AiOutlineShop style={{ fontSize: 20, marginRight: 5 }} />ร้านค้า</button>)
                                    }
                                </td>
                            </tr>
                        ))
                        }
                            </tbody>
                        </table>
                        :<>
                        <div style={{ marginLeft: 20, marginTop: 20 }}><h4 className='text-danger'><strong>ไม่พบข้อมูลผู้ใช้ระบบ</strong></h4></div>
                        </>
                    }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default MemberList;