import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, doc, updateDoc, addDoc, getDoc, getDocs, query, where, orderBy } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useNavigate } from "react-router-dom"

import { RxHome } from "react-icons/rx"
import { FaMapMarkerAlt } from "react-icons/fa"
import { RiBankCardLine } from "react-icons/ri"
import { IoCloseCircle, IoCheckmarkCircleSharp, IoChevronBackCircleSharp, IoFolderOpenOutline } from "react-icons/io5"
import { AiFillShop, AiOutlineFileImage } from "react-icons/ai"
import addImage from "../../assets/images/add-image-icon.png"

import { addFreeCoinLog } from "../../../assets/js/LogHelper"
import { addShopNotification } from "../../../assets/js/NotificationHelper"

import { Loading } from "../layouts/Loading"
import { defaultLatLng, googleMapsApiKey } from "../../../assets/constants/locations"

const RegisterShop = () => {
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const inputRef = useRef(null)
    const inputRef2 = useRef(null)
    const inputRef3 = useRef(null)
    const inputRef4 = useRef(null)
    const inputRef5 = useRef(null)
    const inputRef6 = useRef(null)
    const inputRef7 = useRef(null)

    const [bankList, setBankList] = useState([])
    const [subdistrictList, setSubDistrictList] = useState([])
    const [districtList, setDistrictList] = useState([])
    const [provinceList, setProvinceList] = useState([])
    
    const [memberId, setMemberId] = useState('')
    const [images, setImages] = useState([
        { id: "", url: addImage, file: null, status: 0 },
        { id: "", url: addImage, file: null, status: 0 },
        { id: "", url: addImage, file: null, status: 0 },
        { id: "", url: addImage, file: null, status: 0 },
        { id: "", url: addImage, file: null, status: 0 }
    ])
    const [type, setType] = useState(1)
    const [member, setMember] = useState()
    const [shopname, setShopName] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [telNo, setTelNo] = useState('')
    const [address, setAddress] = useState('')
    const [room, setRoom] = useState('')
    const [floor, setFloor] = useState('')
    const [building, setBuilding] = useState('')
    const [village, setVillage] = useState('')
    const [subroad, setSubRoad] = useState('')
    const [road, setRoad] = useState('')
    const [subdistrictId, setSubDistrictId] = useState(0)
    const [districtId, setDistrictId] = useState(0)
    const [provinceId, setProvinceId] = useState(0)
    const [postcode, setPostcode] = useState('')

    const [bankId, setBankId] = useState(0)
    const [bankAccNo, setBankAccNo] = useState('')

    const [bookBank, setBookBank] = useState('')
    const [idCard, setIdCard] = useState('')
    const [supportFile1, setSupportFile1] = useState('')
    const [supportFile2, setSupportFile2] = useState('')
    const [supportFile3, setSupportFile3] = useState('')
    const [supportFile4, setSupportFile4] = useState('')
    const [supportFile5, setSupportFile5] = useState('')

    const [showProgressBar, setShowProgressBar] = useState(false)
    const [showLoading, setShowLoading] = useState(true)

    const [pictureErrorDiv, setPictureErrorDiv] = useState(false)
    const [shopnameErrorDiv, setShopnameErrorDiv] = useState(false)
    const [firstnameErrorDiv, setFirstnameErrorDiv] = useState(false)
    const [lastnameErrorDiv, setLastnameErrorDiv] = useState(false)
    const [telNoErrorDiv, setTelNoErrorDiv] = useState(false)
    const [imageSizeError, setImageSizeError] = useState(false)
    const [bankIdErrorDiv, setBankIdErrorDiv] = useState(false)
    const [bankAccNoErrorDiv, setBankAccNoErrorDiv] = useState(false)
    const [provinceIdErrorDiv, setProvinceIdErrorDiv] = useState(false)
    const [districtIdErrorDiv, setDistrictIdErrorDiv] = useState(false)
    const [subdistrictIdErrorDiv, setSubDistrictErrorDiv] = useState(false)

    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)

    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey })
    const [center, setCenter] = useState({ lat: defaultLatLng.lat, lng: defaultLatLng.lng })
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    function isSpecialChar(text) {
        return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/.test(text)
    }

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    var locationOptions = {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 0,
    }

    const locationSuccess = (pos) => {
        var crd = pos.coords
        setLat(crd.latitude)
        setLng(crd.longitude)
        setCenter({lat: crd.latitude, lng: crd.longitude})
    }

    const locationErrors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                //console.log(result);
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
            });
        } else {
          //console.log("Geolocation is not supported by this browser.");
        }
    }, [])

    const getBankList = () => {
        getDocs(query(collection(db, 'banks'), orderBy('order', 'asc'))).then((docs) => {
            setBankList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getProvince = () => {
        getDocs(query(collection(db, 'provinces'), orderBy('province_id', 'asc'))).then((docs) => {
            setProvinceList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectProvince = (provinceId) => {
        setProvinceId(provinceId)

        setDistrictId(0)
        districtList.splice(0, districtList.length)
        setDistrictList([...districtList])

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceId*1), orderBy('province_id', 'asc'))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectDistrict = (districtId) => {
        setDistrictId(districtId)

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtId), orderBy('district_id', 'asc'))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handelSelectsubDistrict = (subdistrictId) => {
        setSubDistrictId(subdistrictId)
        setPostcode('')
        getDocs(query(collection(db, 'postcodes'), where('subdistrict_id', '==', subdistrictId*1))).then((docs) => {
            setPostcode(docs.docs[0].data().postcode)
        })
    }

    const deleteImage = (i) => {
        let items = [...images]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setImages(items)
    }

    const handleImage = (file, i) => {
        if(file.size > 1 * 1000 * 1024) {
            setImageSizeError(true)
        } else {
            let items = [...images]
            let item = {...items[i]}
            item.url = URL.createObjectURL(file)
            item.file = file
            item.status = 2
            items[i] = item
            setImages(items)
            setImageSizeError(false)
        }
    }

    const validateForm = () => {
        if(type == 2) {
            if(!shopname || isSpecialChar(shopname)) {
                setShopnameErrorDiv(true)
                return false
            } else {
                setShopnameErrorDiv(false)
            }
        }

        if(!firstname || isSpecialChar(firstname)) {
            setFirstnameErrorDiv(true)
            return false
        } else {
            setFirstnameErrorDiv(false)
        }

        if(!lastname || isSpecialChar(lastname)) {
            setLastnameErrorDiv(true)
            return false
        } else {
            setLastnameErrorDiv(false)
        }

        if(!telNo || !isOnlyNumeric(telNo)) {
            setTelNoErrorDiv(true)
            return false
        } else {
            setTelNoErrorDiv(false)
        }

        var numPic = 0
        images.forEach((image) => {
            if(image.status != 0) {
                numPic++
            }
        })
        if(numPic > 0) {
            setPictureErrorDiv(false)
        } else {
            setPictureErrorDiv(true)
            return false
        }

        if(!bankId) {
            setBankIdErrorDiv(true)
            return false
        } else {
            setBankIdErrorDiv(false)
        }

        if(provinceId == 0) {
            setProvinceIdErrorDiv(true)
            return false
        } else {
            setProvinceIdErrorDiv(false)
        }

        if(districtId == 0) {
            setDistrictIdErrorDiv(true)
            return false
        } else {
            setDistrictIdErrorDiv(false)
        }

        if(subdistrictId == 0) {
            setSubDistrictErrorDiv(true)
            return false
        } else {
            setSubDistrictErrorDiv(false)
        }

        return true
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()
        if(validateForm()) {
            setShowProgressBar(true)
            addDoc(collection(db, "shops"), {
                user_id: memberId,
                uid: member.uid,
                type: type,
                firstname: firstname,
                lastname: lastname,
                tel_no: telNo,
                address: address,
                road: road,
                subroad: subroad,
                village: village,
                building: building,
                floor: floor,
                room: room,
                sub_district: subdistrictId,
                district: districtId,
                province: provinceId,
                postcode: postcode,
                lat: lat,
                lng: lng,
                bank_id: bankId,
                bank_account_no: bankAccNo,
                status: 1,
            }).then((shopDocRef) => {
                const shopId = shopDocRef.id
                if(type == 2) {
                    updateDoc(doc(db, 'shops', shopId), { shopname: shopname })
                    if(supportFile5) {
                        const storageRef = ref(storage, `/shops/${shopId}/image/support_file_5/${supportFile5.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, supportFile5)
                        uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err),
                            () => { 
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    updateDoc(doc(db, `shops`, shopId), {
                                        support_file_5: url,
                                    })
                                })
                            }
                        )
                    }
                }

                getDoc(doc(db, 'free_coins', 'get_free_rate')).then((giveSnapshot) => {
                    var givedCoin = giveSnapshot.data().coin_for_shop*1
                    updateDoc(doc(db, 'shops', shopId), {
                        freecoin: givedCoin
                    }).then(() => {

                        getDoc(doc(db, 'free_coins', 'sum_data')).then((sumDoc) => {
                            const remain = sumDoc.data().remain * 1
                            if(givedCoin > remain) {
                                givedCoin = remain
                            } 
                            updateDoc(doc(db, 'free_coins', 'sum_data'), {
                                gived: (sumDoc.data().gived * 1) + (givedCoin),
                                remain: (sumDoc.data().remain * 1) - (givedCoin)
                            }).then(() => {
                                addFreeCoinLog(db, givedCoin, "แจกเหรียญเงิน สมัครร้านค้า", memberId, "give")
                                addShopNotification(db, shopId, "คุณได้รับเหรียญเงินจำนวน "+givedCoin+" เหรียญ", 'coin', '', '/shops/coins')
                            })
                            
                        })
                    })
                })

                updateDoc(doc(db, 'users', memberId), {
                    is_shop: 1,
                    shop_id: shopId
                }).then(() => {
                    addDoc(collection(db, `/shops/${shopId}/technician`), {
                        firstname: firstname,
                        lastname: lastname,
                    })
                }).then(() => {
                    const promises = []
                    images.forEach((image, i) => {
                        if(image.status != 0) {
                            const storageRef = ref(storage, `/shops/${shopId}/${i}`)
                            const uploadTask = uploadBytesResumable(storageRef, image.file)
                            promises.push(uploadTask)
                            uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err),
                                async() => {
                                    await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                        addDoc(collection(db, `/shops/${shopId}/images`), {
                                            url: downloadURLs,
                                        })
                                    })
                                }
                            )
                        }
                    })

                    if(bookBank) {
                        const storageRef = ref(storage, `/shops/${shopId}/image/bookbank/${bookBank.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, bookBank)
                        promises.push(uploadTask)
                        uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err),
                            () => { 
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    updateDoc(doc(db, `shops`, shopId), {
                                        book_bank: url,
                                    })
                                })
                            }
                        )
                    }
    
                    if(idCard) {
                        const storageRef = ref(storage, `/shops/${shopId}/image/idcard/${idCard.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, idCard)
                        promises.push(uploadTask)
                        uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err),
                            () => { 
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    updateDoc(doc(db, `shops`, shopId), {
                                        id_card: url,
                                    })
                                })
                            }
                        )
                    }
    
                    if(supportFile1) {
                        const storageRef = ref(storage, `/shops/${shopId}/image/support_file_1/${supportFile1.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, supportFile1)
                        promises.push(uploadTask)
                        uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err),
                            () => { 
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    updateDoc(doc(db, `shops`, shopId), {
                                        support_file_1: url,
                                    })
                                })
                            }
                        )
                    }
    
                    if(supportFile2) {
                        const storageRef = ref(storage, `/shops/${shopId}/image/support_file_2/${supportFile2.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, supportFile2)
                        promises.push(uploadTask)
                        uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err),
                            () => { 
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    updateDoc(doc(db, `shops`, shopId), {
                                        support_file_2: url,
                                    })
                                })
                            }
                        )
                    }
    
                    if(supportFile3) {
                        const storageRef = ref(storage, `/shops/${shopId}/image/support_file_3/${supportFile3.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, supportFile3)
                        promises.push(uploadTask)
                        uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err),
                            () => { 
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    updateDoc(doc(db, `shops`, shopId), {
                                        support_file_3: url,
                                    })
                                })
                            }
                        )
                    }
    
                    if(supportFile4) {
                        const storageRef = ref(storage, `/shops/${shopId}/image/support_file_4/${supportFile4.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, supportFile4)
                        promises.push(uploadTask)
                        uploadTask.on("state_changed", (snapshot) => { }, (err) => console.log(err),
                            () => { 
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    updateDoc(doc(db, `shops`, shopId), {
                                        support_file_4: url,
                                    })
                                })
                            }
                        )
                    }

                    Promise.all(promises).then(() => {
                        setShowProgressBar(false)
                        navigate(`/shop/`)
                    })
                })
            }).catch((err) => {
                console.log(err)
            })
        }
    }

    const getDocUser = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                setMemberId(docs.docs[0].id)
                setMember(docs.docs[0].data())
                setFirstname(docs.docs[0].data().firstname)
                setLastname(docs.docs[0].data().lastname)
                setShowLoading(false)
            }
        })
    }

    useEffect(() => {
        if (loading) return
        if (user) getDocUser()
        getProvince()
        getBankList()
    }, [loading, user])

    return (
        <>
            <Loading open={showProgressBar} text={"กำลังทำการสมัครร้านค้า กรุณารอสักครู่"} />
            <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
            <div className='container' style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10, maxWidth: 900 }}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                    <div className="flex-container" style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <h4 style={{ display: 'flex', flex: 1 }}><AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                            <b>สมัครร้านค้า</b></h4>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/profile/`) }}>
                                    <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                                </button>
                            </div>
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                    <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                </button>
                            </div>
                            <div style={{ float: 'right' }}>
                                <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/shop/`) }}>
                                    <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                                </button>
                            </div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                            <div className="" style={{ display: 'flex', marginBottom: 10 }}>
                                <label className="" style={{ display: 'flex', flex: 3 }}><strong>Email <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    {(member) && (<><font style={{ marginRight: 10}}>{member.email?member.email:""}</font></> )}
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                <label className="" style={{ display: 'flex', flex: 3 }}><strong>ประเภทร้านค้า <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <select name='type' onChange={(e) => setType(e.target.value)} value={type} style={{ display: 'flex', paddingLeft: 8, paddingRight: 8 }}>
                                        <option value="1">บุคคลธรรมดา</option>
                                        <option value="2">ร้าน/บริษัท/นิติบุคคล</option>
                                    </select>
                                </div>
                            </div> 
                            {(type==2)&& (
                            <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                <label className="" style={{ display: 'flex', flex: 3 }}><strong>ชื่อร้าน/บริษัท/นิติบุคคล <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='shopname' onChange={(e) => setShopName(e.target.value)} 
                                        value={shopname} placeholder='ชื่อร้าน/บริษัท/นิติบุคคล'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    {shopnameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกชื่อร้าน/บริษัท/นิติบุคคล</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            )}
                            <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                <label className="" style={{ display: 'flex', flex: 3 }}><strong>ชื่อ (เจ้าของ) <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='firstname' onChange={(e) => setFirstname(e.target.value)} 
                                        value={firstname} placeholder='ชื่อ'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    {firstnameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอก{type==1?"ชื่อ":"ชื่อเจ้าของกิจการ"}</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                <label className="" style={{ display: 'flex', flex: 3 }}><strong>นามสกุล (เจ้าของ) <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='lastname' onChange={(e) => setLastname(e.target.value)} 
                                        value={lastname} placeholder='นามสกุล'
                                        style={{ paddingLeft: 8,display: 'flex', flex: 1 }} />
                                    {lastnameErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอก{type==1?"นามสกุล":"นามสกุลเจ้าของกิจการ"}</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                <label className="" style={{ display: 'flex', flex: 3 }}><strong>เบอร์โทรศัพท์ <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='tel_no' onChange={(e) => setTelNo(e.target.value)} 
                                        value={telNo} placeholder='เบอร์โทรศัพท์'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    {telNoErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกเบอร์โทรศัพท์</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 10 }}>
                            <div style={{ paddingTop: 8, paddingBottom: 8, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                                <font style={{ fontSize: 18 }}><AiOutlineFileImage style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <strong>{type==1?"รูปถ่ายหน้าร้าน":"รูปถ่ายหน้าร้าน/บริษัท"}</strong></font>
                            </div>
                            <div style={{ display:'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', alignItems: 'flex-start', alignContent: 'flex-start'}}>
                                    {images?.map((image, i) => (
                                        <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center', minWidth: 100 }}>
                                            <img src={image.url} alt="WEEE" width="80" style={{marginBottom: 5}} />
                                            <div style={{ display: "flex", flexDirection:'row'}}>
                                            <label htmlFor={"fileUpload"+i} style={{ padding: 0 }}>
                                                {(image.status!=0)?
                                                (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                                                (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                                                }
                                            </label>
                                            {(image.status!=0) &&
                                            (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImage(i) }}>
                                                <IoCloseCircle style={{ fontSize: 20 }} />
                                            </button>)
                                            }
                                            </div>
                                            <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImage(e.target.files[0], i)} />
                                        </div>
                                    ))}
                                    </div>
                                    { imageSizeError &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                    }
                                    {pictureErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณาเลือก{type==1?"รูปถ่ายหน้าร้าน":"รูปถ่ายหน้าร้าน/บริษัท"} อย่างน้อย 1 รูป</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 10 }}>
                            <div style={{ paddingTop: 8, paddingBottom: 8, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                                <font style={{ fontSize: 18 }}><RiBankCardLine style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <strong>บัญชีธนาคาร</strong></font>
                            </div>
                            <div style={{ display:'flex', flexDirection: 'column' }}>
                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>ธนาคาร <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <select name='bankList' onChange={(e) => { setBankId(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0">--- กรุณาเลือกธนาคาร ---</option>
                                            {bankList?.map((bank, i) => (
                                            <option value={bank.id} key={bank.id}>
                                                {bank.data.bank_name_th} ({bank.data.bank_name_short})
                                            </option>
                                            ))}
                                        </select>
                                        {bankIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกธนาคาร</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 
                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>หมายเลขบัญชี</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input type='text' name='bankAccNo' onChange={(e) => setBankAccNo(e.target.value)} 
                                            value={bankAccNo} placeholder='หมายเลขบัญชี' style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                        {bankAccNoErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณากรอกเลขบัญชีธนาคาร (เฉพาะตัวเลขเท่านั้น)</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>สำเนาหน้าสมุดบัญชี</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input ref={inputRef2} className="form-control file" id="bookbank_image" type="file" accept="image/*" onChange={(e) => setBookBank(e.target.files[0])} />
                                    </div>
                                </div> 

                            </div>
                        </div>

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 10 }}>
                            <div style={{ paddingTop: 8, paddingBottom: 8, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginBottom: 10 }}>
                                <font style={{ fontSize: 18 }}>
                                    <RxHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                    <strong>ข้อมูลที่อยู่</strong>
                                </font>
                            </div>

                            <div style={{ display:'flex', flexDirection: 'column' }}>
                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>บ้านเลขที่</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input type='text' name='address' onChange={(e) => setAddress(e.target.value)} 
                                            value={address} placeholder='บ้านเลขที่'
                                            style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>หมู่บ้าน/สถานที่</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input type='text' name='village' onChange={(e) => setVillage(e.target.value)} 
                                            value={village} placeholder='หมู่บ้าน/สถานที่' style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div>

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>อาคาร</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input type='text' name='building' onChange={(e) => setBuilding(e.target.value)} 
                                            value={building} placeholder='อาคาร' style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>ชั้น</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input type='text' name='floor' onChange={(e) => setFloor(e.target.value)} 
                                            value={floor} placeholder='ชั้น' style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>ห้อง</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input type='text' name='room' onChange={(e) => setRoom(e.target.value)} 
                                            value={room} placeholder='ห้อง' style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>ซอย</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input type='text' name='subroad' onChange={(e) => setSubRoad(e.target.value)} 
                                            value={subroad} placeholder='ซอย' style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>ถนน</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                            value={road} placeholder='ถนน' style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>จังหวัด <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <select name='province' onChange={(e) => { handleSelectProvince(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0">--- กรุณาเลือกจังหวัด ---</option>
                                            {provinceList?.map((province, i) => (
                                            <option value={province.data.province_id} key={province.data.province_id}>
                                                {province.data.province_name}
                                            </option>
                                            ))}
                                        </select>
                                        {provinceIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกจังหวัด</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>อำเภอ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <select name='district' onChange={(e) => { handleSelectDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0" key="0">--- กรุณาเลือกอำเภอ ---</option>
                                            {districtList?.map((district, i) => (
                                            <option value={district.data.district_id} key={district.data.district_id}>
                                                {district.data.district_name}
                                            </option>
                                            ))}
                                        </select>
                                        {districtIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกอำเภอ</strong>
                                        </div>
                                        }
                                    </div>
                                </div>

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>ตำบล <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <select name='subdistrict' onChange={(e) => { handelSelectsubDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                            <option value="0">--- กรุณาเลือกตำบล ---</option>
                                            {subdistrictList?.map((subdistrict, i) => (
                                            <option value={subdistrict.data.subdistrict_id} key={subdistrict.data.subdistrict_id}>
                                                {subdistrict.data.subdistrict_name}
                                            </option>
                                            ))}
                                        </select>
                                        {subdistrictIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกตำบล</strong>
                                        </div>
                                        }
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>รหัสไปรษณีย์ <font className="text-danger">*</font></strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input type='text' name='postcode' readOnly
                                            value={postcode} placeholder='รหัสไปรษณีย์' style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    </div>
                                </div>

                            </div>

                        </div>

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 10 }}>
                            <div style={{ paddingTop: 8, paddingBottom: 8, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginBottom: 10 }}>
                                <font style={{ fontSize: 18 }}>
                                <FaMapMarkerAlt style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <strong>ตำแหน่งพิกัด{type==1?"ร้าน":"ร้าน/บริษัท"}</strong></font>
                            </div>
                            <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', marginBottom: 10 }}>
                                <div style={{ marginBottom: 1, flexDirection: 'column', display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                    <div className='text-danger' style={{ marginBottom: 5, display: 'flex', flexWrap: 'nowrap' }}>
                                        ** ลาก <FaMapMarkerAlt className='text-danger' style={{ marginLeft: 5, marginRight: 5 }} /> Marker  เพื่อกำหนดตำแหน่ง
                                    </div>
                                    <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                                    {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                    <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                        <MarkerF
                                            draggable={true} position={center}
                                            onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                        />
                                    </GoogleMap>
                                    )}
                                </div>
                            </div>
                        </div>

                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 10 }}>
                            <div style={{ paddingTop: 8, paddingBottom: 8, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginBottom: 10 }}>
                                <font style={{ fontSize: 18 }}><IoFolderOpenOutline style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <strong>เอกสารประกอบการสมัคร</strong></font>
                            </div>

                            <div style={{ display:'flex', flexDirection: 'column' }}>
                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>สำเนาบัตรประชาชน</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input ref={inputRef} className="form-control file" id="idcard_image" type="file" accept="image/*" onChange={(e) => setIdCard(e.target.files[0])} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>ใบประกอบวิชาชีพ</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input ref={inputRef3} className="form-control file" id="support_file_1" type="file" accept="image/*" onChange={(e) => setSupportFile1(e.target.files[0])} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>วุฒิการศึกษา</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input ref={inputRef4} className="form-control file" id="support_file_2" type="file" accept="image/*" onChange={(e) => setSupportFile2(e.target.files[0])} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>ประกาศนียบัตร</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input ref={inputRef5} className="form-control file" id="support_file_3" type="file" accept="image/*" onChange={(e) => setSupportFile3(e.target.files[0])} />
                                    </div>
                                </div> 

                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>หนังสือรับรอง</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input ref={inputRef6} className="form-control file" id="support_file_4" type="file" accept="image/*" onChange={(e) => setSupportFile4(e.target.files[0])} />
                                    </div>
                                </div> 

                                {(type == 2) && (
                                <div className="flex-container" style={{ display: 'flex', marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 3 }}><strong>สำเนาใบสำคัญการจดทะเบียน</strong></label>
                                    <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                        <input ref={inputRef7} className="form-control file" id="support_file_5" type="file" accept="image/*" onChange={(e) => setSupportFile5(e.target.files[0])} />
                                    </div>
                                </div>
                                )}
                            </div>
                        </div>
                    </div>

                    <div style={{ marginTop: 10, borderTop: '1px solid #cccccc', paddingTop: 8, display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <h4 style={{ display: 'flex', flex: 1}}> </h4>
                        <div style={{ float: 'right' }}>
                            <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/profile/`) }}>
                                <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                            </button>
                        </div>
                        <div style={{ float: 'right' }}>
                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                            </button>
                        </div>
                        <div style={{ float: 'right' }}>
                            <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/shop/`) }}>
                                <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default RegisterShop