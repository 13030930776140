import React, { useState, useEffect } from 'react'
import { db } from "../../assets/js/firebase"
import { collection, getDoc, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { getBroken } from '../helper/BrokenHelper'
import { Loading } from "../layouts/Loading"

export function AppointmentShopBoard4({appointment}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(false)

    const [brokenName, setBrokenName] = useState('')
    const appointmentId = appointment.id
    const userId = appointment.data.user_id
    const shopId = appointment.data.shop_id
    const postId = appointment.data.post_id
    const shopOfferId = appointment.data.shop_offer_id
    const postOfferId = appointment.data.offer_id
    const depositCoin = appointment.data.deposit_coin * 1
    const remainCoin = appointment.data.remain_coin * 1

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState('')

    const [openFixCase, setOpenFixCase] = useState(false)
    const [openCancleFix, setOpenCancleFix] = useState(false)
    const [openFixReport, setOpenFixReport] = useState(false)
    const [openConfirmFixJob, setOpenConfirmFixJob] = useState(false)
    const [openCancleFixJob, setOpenCancleFixJob] = useState(false)

    const [fixDay, setFixDay] = useState(0)
    const [fixPrice, setFixPrice] = useState(0)
    const [beforeCheck, setBeforeCheck] = useState('')
    const [beforeResult, setBeforeResult] = useState('')
    const [beforeSymptom, setBeforeSymptom] = useState('')

    const [fixReportAble, setFixReportAble] = useState(1)
    const [fixReportPrice, setFixReportPrice] = useState(0)
    const [fixReportDescription, setFixReportDescription] = useState('')

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleOpenFixCase = () => {
        setOpenFixCase(true)
    }

    const handleCloseFixCase = () => {
        setOpenFixCase(false)
    }

    const handleFixCase = () => {
        setShowLoading(true)
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 2,
            before_check: beforeCheck,
            before_result: beforeResult,
            before_symptom: beforeSymptom,
            fix_day: fixDay,
            fix_price: fixPrice,
            accept_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        var addNotiUserTask = addDoc(collection(db, `/users/${userId}/notifications`), {
            date: logDate.getTime(),
            message: "ทางร้าน/บริษัท ยินดีให้บริการซ่อมเครื่องใช้ไฟฟ้า กรุณากดยืนยันหรือยกเลิก เพ่ือดำเนินการต่อไป",
            link: 'profile/appointments',
            type: 'appointment',
            appointment_id: appointmentId,
            status: 0
        })
        promisesTask.push(addNotiUserTask)

        Promise.all(promisesTask).then(() => {
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleOpenCancleFix = () => {
        setOpenCancleFix(true)
    }

    const handleCloseCancleFix = () => {
        setOpenCancleFix(false)
    }

    const handleCancleFix = () => {
        setShowLoading(true)
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 4,
            shop_cancle_message: message,
            shop_cancle_reason: reason,
            shop_cancle_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        var updatePostTask = updateDoc(doc(db, `/posts/`, postId), { 
            status: 1
        })
        promisesTask.push(updatePostTask)

        var updateShopOfferTask = updateDoc(doc(db, `/shops/${shopId}/offers`, shopOfferId), { status: 5 })
        promisesTask.push(updateShopOfferTask)

        var addCancleOfferTask = addDoc(collection(db, `posts/${postId}/cancle_offers/`), {
            shop_id: shopId,
            cancle_message: message,
            cancle_reason: reason,
            cancle_datetime: logDate.getTime()
        })
        promisesTask.push(addCancleOfferTask)
        
        var deletePostOfferTask = deleteDoc(doc(db, `posts/${postId}/offers/`, postOfferId))
        promisesTask.push(deletePostOfferTask)
        
        var addBlackListTask = addDoc(collection(db, `posts/${postId}/shop_blacklist/`), {
            shop_id: shopId,
        })
        promisesTask.push(addBlackListTask)

        Promise.all(promisesTask).then(() => {
            handleCloseCancleFix()
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleOpenFixReport = () => {
        setOpenFixReport(true)
    }

    const handleCloseFixReport = () => {
        setOpenFixReport(false)
    }

    const handleFixReport = () => {
        setShowLoading(true)
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 6,
            fix_report_able: fixReportAble,
            fix_report_price: fixReportPrice,
            fix_report_description: fixReportDescription,
            report_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        Promise.all(promisesTask).then(() => {
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleOpenConfirmFixJob = () => {
        setOpenConfirmFixJob(true)
    }

    const handleCloseConfirmFixJob = () => {
        setOpenConfirmFixJob(false)
    }

    const handleConfirmFixJob = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 8,
            message_for_user: message,
            score_for_user: score,
            shop_finish_datetime: logDate.getTime()
        }).then(() => {
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleOpenCanclemFixJob = () => {
        setOpenCancleFixJob(true)
    }

    const handleCloseCancleFixJob = () => {
        setOpenCancleFixJob(false)
    }

    const handleCancleFixJob = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 10,
            shop_cancle_message: message,
            shop_cancle_reason: reason,
            shop_cancle_datetime: logDate.getTime()
        }).then(() => {
            getDoc(doc(db, `posts`, postId)).then((postDoc) => {
                var promisesTask = []
                var addNotiTask = addDoc(collection(db, `/users/${userId}/notifications`), {
                    date: logDate.getTime(),
                    message: "ช่าง แจ้งยกเลิกบริการ",
                    link: 'profile/appointments',
                    type: 'appointment',
                    appointment_id: appointmentId,
                    status: 0
                }).then(() => {
                })
                promisesTask.push(addNotiTask)

                var updatePostTask = updateDoc(doc(db, `posts`, postId), { 
                    status: 5,
                })
                promisesTask.push(updatePostTask)

                var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
                    coin: depositCoin * 1,
                    date: logDate.getTime(),
                    event_type: 'deposit',
                    event: 'คืนมัดจำ 30% ของค่าบริการซ่อมเครื่องใช้ไฟฟ้า',
                    type: 'decrease',
                    post_type: 'fix',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addCoinLogTask)

                var getUserCoinTask = getDoc(doc(db, `users`, userId)).then((userDoc) => {
                    var exchangeCoin = 0
                    if(userDoc.data().exchange_coin) { exchangeCoin = userDoc.data().exchange_coin }
                    updateDoc(doc(db, 'users/', userId), {
                        exchange_coin: (exchangeCoin * 1)+(depositCoin * 1)
                    })
                })
                promisesTask.push(getUserCoinTask)

                var addUserCoinLogTask = addDoc(collection(db, `users/${userId}/coin_log/`), {
                    coin: depositCoin * 1,
                    date: logDate.getTime(),
                    event_type: 'deposit',
                    event: 'คืนค่ามัดจำ 30% ของค่าบริการซ่อมเครื่องใช้ไฟฟ้า',
                    coin_type: 'exchange',
                    post_type: 'fix',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addUserCoinLogTask)
            
                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }


    useEffect(() => {
        getBroken(db, appointment.post.broken_id, appointment.post.broken_other).then((broken) => {
            setBrokenName(broken)
        })
    })
    
    return (
        <>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
                {appointment.badgeStyle.show && (
                <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                    <button type="button" className={"btn "+"btn-"+appointment.badgeStyle.class} style={{ color: appointment.badgeStyle.color }}><strong>{appointment.badgeStyle.message}</strong></button>
                </div>
                )}
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                    <img src={appointment.post.picture} alt="WEEE" height="150" />
                </div>
                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                    <p className="card-text">
                        <strong>ประเภทนัดหมาย:</strong> แจ้งซ่อมเครื่องใช้ไฟฟ้า<br/>
                        <strong>วันนัดหมาย:</strong> {dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/>
                        <strong>ที่อยู่:</strong> {appointment.post.address} <br/>
                        {appointment.post.type} {appointment.post.brand} {appointment.data.model}<br/>
                        <strong>ปีที่ซื้อ:</strong> {appointment.post.buy_year?"พ.ศ. "+appointment.post.buy_year:"ไม่ระบุ"} <br/>
                        <strong>งบประมาณซ่อมไม่เกิน:</strong> {number_format.format(appointment.post.price)} บาท <br/>
                        <strong>ราคาเสนอซ่อม:</strong> {number_format.format(appointment.offer.offer_price)} บาท <br/>
                        {appointment.offer.offer_buy > 0 && (<>
                        <strong>ราคาเสนอซื้อซาก:</strong> {number_format.format(appointment.offer.offer_buy)} บาท <br/>
                        </>)}
                        <strong>อาการ:</strong> {brokenName} <br/>
                        <strong>อาการเพิ่มเติม:</strong> {appointment.post.description} <br/>
                        {(appointment.data.status > 1) &&
                            (<>
                            <strong>ราคาซ่อม:</strong> {appointment.data.fix_price} บาท <br/>
                            <strong>ระยะเวลาที่ใช้ซ่อม:</strong> {appointment.data.fix_day} วัน <br/>
                            <strong>ผลประเมินอาการเบื้องต้น:</strong> {appointment.data.before_check} <br/>
                            <strong>ผลการตรวจสภาพก่อนซ่อม:</strong> {appointment.data.before_result} <br/>
                            <strong>อาการสภาพก่อนซ่อม:</strong> {appointment.data.before_symptom} <br/>
                            </>
                        )}
                        {(appointment.data.status >= 6) &&
                            (<>
                            <strong>ประเมินการซ่อม:</strong> {(appointment.data.fix_report_able == 1)?'ได้':'ไม่ได้'}<br/>
                            <strong>ค่าอะไหล่:</strong> {appointment.data.fix_report_price} บาท <br/>
                            <strong>รายงานการซ่อม:</strong> {appointment.data.fix_report_description} <br/>
                            </>
                        )}
                    </p>
                </div>
                <div className="card-body">
                    <Link className="btn btn-primary m-1" to={`/post/${appointment.data.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                    <Link className="btn btn-primary m-1" to={`/shop/postdetail/${appointment.post.onboard}/${appointment.data.post_id}/${appointment.data.shop_id}`} target='_blank'><strong>ดูนัดหมายและข้อเสนอ</strong></Link>
                    {(appointment.data.status == 1) && (<>
                    <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenFixCase() }}><strong>ยืนยันการรับซ่อม</strong></button>
                    <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCancleFix() }}><strong>ปฏิเสธการรับซ่อม</strong></button>
                    </>)}
                    {(appointment.data.status == 3) && (
                    <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenFixReport() }}><strong>รายงานซ่อม</strong></button>
                    )}
                    {(appointment.data.status == 7) && (<>
                    <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenConfirmFixJob() }}><strong>ยืนยันได้รับเหรียญ</strong></button>
                    <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCanclemFixJob() }}><strong>ปฏิเสธการซ่อม</strong></button>
                    </>)}
                </div>

                <Dialog open={openFixCase} onClose={handleCloseFixCase} maxWidth='lg' fullWidth={true}>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันการรับซ่อม</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                <h5 className='text-danger'><strong>*** เมื่อยืนยันการรับซ่อม จะถือว่าท่านยินดีปฏิบัติตามข้อเสนอและราคาซ่อมที่เสนอไว้ และปฏิบัติตามเงื่อนไขการให้บริการ</strong></h5>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flex: 1, marginRight: 15}}>
                                                <div className="" style={{ display: 'flex', flexDirection: 'column', marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                                                    <label className="" style={{ display: 'flex'}}><h5><strong>ระยะเวลาที่ใช้ซ่อม (วัน)</strong></h5></label>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <input type='text' name='offerFixDay' onChange={(e) => setFixDay(e.target.value)} 
                                                            value={fixDay} placeholder='ระยะเวลาที่ใช้ซ่อม (วัน)'
                                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                                    </div>
                                                </div>
                                                <div className="" style={{ display: 'flex', flexDirection: 'column', marginLeft: 0, marginRight: 0, marginTop: 10 }}>
                                                    <label className="" style={{ display: 'flex'}}><h5><strong>ราคาซ่อม (บาท)</strong></h5></label>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <input type='text' name='offerPrice' onChange={(e) => setFixPrice(e.target.value)} 
                                                            value={fixPrice} placeholder='ราคาซ่อม (บาท)'
                                                            style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                                    </div>
                                                </div>
                                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                    <label className="" style={{ display: 'flex' }}><h5><strong>อาการสภาพก่อนซ่อม</strong></h5></label>
                                                    <div style={{ display: 'flex', flex: 1 }}>
                                                        <textarea style={{ display: 'flex', flex: 1, paddingLeft: 8, height: 100 }}
                                                            onChange={(e) => setBeforeSymptom(e.target.value)}
                                                            placeholder='อาการสภาพก่อนซ่อม' 
                                                            value={beforeSymptom}></textarea>
                                                    </div>
                                                </div> 
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                    <label className="" style={{ display: 'flex' }}><h5><strong>ผลประเมินอาการเบื้องต้น</strong></h5></label>
                                                    <div style={{ display: 'flex', flex: 1 }}>
                                                        <textarea style={{ display: 'flex', flex: 1, paddingLeft: 8, height: 100 }}
                                                            onChange={(e) => setBeforeCheck(e.target.value)}
                                                            placeholder='ผลประเมินอาการเบื้องต้น' 
                                                            value={beforeCheck}></textarea>
                                                    </div>
                                                </div> 
                                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                    <label className="" style={{ display: 'flex' }}><h5><strong>ผลการตรวจสภาพก่อนซ่อม</strong></h5></label>
                                                    <div style={{ display: 'flex', flex: 1 }}>
                                                        <textarea style={{ display: 'flex', flex: 1, paddingLeft: 8, height: 100 }}
                                                            onChange={(e) => setBeforeResult(e.target.value)}
                                                            placeholder='ผลการตรวจสภาพก่อนซ่อม' 
                                                            value={beforeResult}></textarea>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseFixCase} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleFixCase} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันการรับซ่อม</button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openCancleFix} onClose={handleCloseCancleFix} maxWidth='sm' fullWidth={true}>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ปฏิเสธรับซ่อม</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ไม่รับซ่อม</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                                <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ paddingLeft: 10, paddingRight: 10, flex: 1 }}>
                                                    <option value="1">ไม่นำเครื่องไฟฟ้ามาตามวันเวลาที่กำหนด</option>
                                                    <option value="2">อาการเสียหนักกว่าที่แจ้ง</option>
                                                    <option value="3">เครื่องมือ/อุปกรณ์หรืออะไหล่ไม่พร้อม</option>
                                                    <option value="4">มีข้อเรียกร้องมากกว่าที่แจ้ง</option>
                                                    <option value="5">ราคาซ่อมสูงกว่าที่ประเมินไว้</option>
                                                    <option value="6">ต้องใช้เวลาซ่อมนานกว่าที่ประเมินไว้</option>
                                                    <option value="7">ผู้ประกาศไม่น่าเชื่อถือ</option>
                                                    <option value="8">อื่นๆ (โปรดระบุ)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder='เหตุผลเพิ่มเติม' 
                                                    value={message}></textarea>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseCancleFix} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleCancleFix} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ปฏิเสธรับซ่อม</button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openFixReport} onClose={handleCloseFixReport} maxWidth='md' fullWidth={true}>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>บันทึกรายงานการซ่อม</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div style={{ display: "flex", flexDirection: "row" }}>
                                            <div style={{ display: "flex", flexDirection: "column", flex: 1, marginRight: 15}}>
                                                <div className="" style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                                    <label className="" style={{ display: 'flex' }}><h5><strong>ประเมินการซ่อม</strong></h5></label>
                                                    <div style={{ display: 'flex', marginBottom: 15 }}>
                                                        <select name='fixReportAble' onChange={(e) => setFixReportAble(e.target.value)} style={{ flex: 1 }}>
                                                            <option value="1">ได้</option>
                                                            <option value="2">ไม่ได้</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className="" style={{ display: 'flex', flexDirection: 'column', marginLeft: 0, marginRight: 0 }}>
                                                    <label className="" style={{ display: 'flex'}}><h5><strong>ค่าอะไหล่ (บาท)</strong></h5></label>
                                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                        <input type='text' name='fixReportPrice' onChange={(e) => setFixReportPrice(e.target.value)} 
                                                            value={fixReportPrice} placeholder='ค่าอะไหล่ (บาท)'
                                                            style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div style={{ display: "flex", flexDirection: "column", flex: 2}}>
                                                <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                                    <label className="" style={{ display: 'flex' }}><h5><strong>รายงานเพิ่มเติม</strong></h5></label>
                                                    <div style={{ display: 'flex', flex: 1 }}>
                                                        <textarea style={{ display: 'flex', flex: 1, paddingLeft: 8, height: 150 }}
                                                            onChange={(e) => setFixReportDescription(e.target.value)}
                                                            placeholder='รายงานเพิ่มเติม' 
                                                            value={fixReportDescription}></textarea>
                                                    </div>
                                                </div> 
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseFixReport} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleFixReport} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึกรายงานการซ่อม</button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openCancleFixJob} onClose={handleCloseCancleFixJob}>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการให้บริการซ่อมเครื่องใช้ไฟฟ้า</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ยกเลิกบริการ</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                                <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5, paddingLeft: 10, paddingRight: 10 }}>
                                                    <option value="1">มารับไม่ตรงวันและเวลาที่นัด</option>
                                                    <option value="2">มีการเรียกรับเงินเพิ่ม</option>
                                                    <option value="3">อื่นๆ (โปรดระบุ)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                                <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder='เหตุผลเพิ่มเติม' 
                                                    value={message}></textarea>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseCancleFixJob} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleCancleFixJob} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการให้บริการ</button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openConfirmFixJob} onClose={handleCloseConfirmFixJob} fullWidth={true} maxWidth='sm'>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันการรับเหรียญ</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="" style={{ display: 'flex', width: 200}}><h5><strong>คำติชมเพิ่มเติม</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder='คำติชมเพิ่มเติม' 
                                                    value={message}></textarea>
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex'}}><h5><strong>คะแนน</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                <select name='score' onChange={(e) => setScore(e.target.value)} style={{ flex: 1 }}>
                                                    <option value="5">5</option>
                                                    <option value="4">4</option>
                                                    <option value="3">3</option>
                                                    <option value="2">2</option>
                                                    <option value="1">1</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div style={{ display:'flex', flexDirection: 'column', marginTop: 10 }}>
                                            <div style={{ backgroundColor: '#ffffff' }}>
                                                <h6 className='text-danger'><strong>คุณจะได้รับแต้มสะสม เมื่อผู้ประกาศกดยืนยันแล้ว</strong></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseConfirmFixJob} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleConfirmFixJob} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันการรับเหรียญ</button>
                    </DialogActions>
                </Dialog>

            </div>
        </>
    )
}