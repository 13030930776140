import React, { useState, useEffect, useRef } from 'react'
import { db } from "../../assets/js/firebase"
import { collection, query, doc, getDoc, updateDoc, addDoc, where, onSnapshot, getDocs, orderBy } from "firebase/firestore"
import { useNavigate, useParams } from "react-router-dom"
import { BsMegaphone } from "react-icons/bs"
import { TbCircleCheck, TbAlertCircle } from "react-icons/tb"
import { getPostTitle } from '../../../assets/js/PostHelper'
import { getUserTitle } from '../../../assets/js/UserHelper'

const Ads = () => {
    const navigate = useNavigate()
    const initialized = useRef(false)
    const [loadingAds, setLoadingAds] = useState(false)
    const params = useParams()
    const position = 7
    const number_format = new Intl.NumberFormat()
    const positionAmount = [ 4, 5, 5, 4, 3, 8, 8, 10, 10, 10 ]
    const positionName = [ 
        "หน้าแรก ตำแหน่งบนสุด",
        "หน้าแรก ขายเครื่องใช้ไฟฟ้ามือสอง", 
        "หน้าแรก รวมช่างซ่อม",
        "หน้าแรก บริการล้างแอร์",
        "หน้าแรก บริการล้างเครื่องซักผ้า",
        "หน้าแสดงทั้งหมด ขายเครื่องใช้ไฟฟ้า",
        "หน้าแสดงทั้งหมด ขายซากเครื่องใช้ไฟฟ้า",
        "หน้าแสดงทั้งหมด แจ้งซ่อมเครื่องใช้ไฟฟ้า",
        "หน้าแสดงทั้งหมด แจ้งล้างแอร์",
        "หน้าแสดงทั้งหมด แจ้งล้างเครื่องซักผ้า" ]

    const [activeNum, setActiveNum] = useState(0)
    const [activeList, setActiveList] = useState([])
    const [pendingNum, setPendingNum] = useState(0)
    const [pendingList, setPendingList] = useState([])
    const [requestNum, setRequestNum] = useState(0)
    const [requestList, setRequestList] = useState([])
 
    const dateFormatTime = (datetime) => {
        return (datetime?new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const getAds = async () => {
        //getDocs(collection(db, `ads/active/position_${position}`), where('status', '==', 1), orderBy('final_date', 'asc')).then((result) => {
        onSnapshot(query(collection(db, `ads/active/position_${position}`), where('status', '==', 1), orderBy('final_date', 'asc')), (result) => {
            activeList.splice(0, activeList.length)
            setActiveList([...activeList])
            if(!result.empty) {
                setActiveNum(result.docs.length)
                result.forEach(async(ads) => {
                    const postTitle = await getPostTitle(db, ads.data().post_id)
                    const userTitle = await getUserTitle(db, ads.data().user_id)
                    const today = new Date()
                    //today.setDate(today.getDate() + 3)
                    //const adsDate = new Date(today.getDate() - 5)
                    const adsDate = ads.data().final_date
                    const diffTime = adsDate - today
                    const diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24))
                    if(diffDays < 0) {
                        updateDoc(doc(db, `ads/active/position_${position}`, ads.id), { 
                            status: 2 // to expired
                        }).then(() => {
                            getDocs(query(collection(db, `ads/pending/position_${position}`), where('status', '==', 1), orderBy('pending_timestamp', 'asc'))).then((adsDocs) => {
                                if(!adsDocs.empty) {
                                    updateDoc(doc(db, `ads/pending/position_${position}`, adsDocs.docs[0].id), { 
                                        status: 2 // to activate
                                    }).then(() => {
                                        const requestDate = new Date()
                                        const finalDate = new Date()
                                        finalDate.setDate(today.getDate() + (adsDocs.docs[0].data().ads_duration -1))
                                        addDoc(collection(db, `/ads/active/position_${position}`), {
                                            ads_id: adsDocs.docs[0].data().ads_id,
                                            user_id: adsDocs.docs[0].data().user_id,
                                            post_id: adsDocs.docs[0].data().post_id,
                                            used_coin_per_day: adsDocs.docs[0].data().used_coin_per_day,
                                            ads_duration: adsDocs.docs[0].data().ads_duration * 1,
                                            total_used_coin: adsDocs.docs[0].data().total_used_coin * 1,
                                            start_date: today.getTime(),
                                            final_date: finalDate.getTime(),
                                            active_date: requestDate.getTime(),
                                            status: 1
                                        }).then(() => {
                                            addDoc(collection(db, `users/${adsDocs.docs[0].data().user_id}/ads/active/list/`), {
                                                ads_id: adsDocs.docs[0].data().ads_id,
                                                position: position,
                                                post_id: adsDocs.docs[0].data().post_id,
                                                used_coin_per_day: adsDocs.docs[0].data().used_coin_per_day,
                                                ads_duration: adsDocs.docs[0].data().ads_duration * 1,
                                                total_used_coin: adsDocs.docs[0].data().total_used_coin * 1,
                                                start_date: today.getTime(),
                                                final_date: finalDate.getTime(),
                                                active_date: requestDate.getTime(),
                                                status: 1
                                            })
                                        }).then(() => {
                                            getDocs(collection(db, `users/${adsDocs.docs[0].data().user_id}/ads/active/list/`), where('ads_id', '==', ads.id)).then((docs) => {
                                                updateDoc(doc(db, `users/${adsDocs.docs[0].data().user_id}/ads/active/list/`, docs.docs[0].id), {
                                                    status: 2 
                                                })
                                            })
                                        })
                                    })
                                }
                            })
                        })
                    } else {
                        activeList.push({
                            id: ads.id,
                            data: ads.data(),
                            post_title: postTitle,
                            user_title: userTitle
                        })
                        setActiveList([...activeList], {
                            id: ads.id,
                            data: ads.data(),
                            post_title: postTitle,
                            user_title: userTitle
                        })
                    }
                })
            }
        })

        onSnapshot(query(collection(db, `ads/pending/position_${position}`), where('status', '==', 1), orderBy('pending_timestamp', 'asc')), (result) => {
            if(!result.empty) {
                pendingList.splice(0, pendingList.length)
                setPendingList([...pendingList])
                setPendingNum(result.docs.length)
                result.forEach(async(ads) => {
                    const postTitle = await getPostTitle(db, ads.data().post_id)
                    const userTitle = await getUserTitle(db, ads.data().user_id)
                    pendingList.push({
                        id: ads.id,
                        data: ads.data(),
                        post_title: postTitle,
                        user_title: userTitle
                    })
                    setPendingList([...pendingList], {
                        id: ads.id,
                        data: ads.data(),
                        post_title: postTitle,
                        user_title: userTitle
                    })
                })
            }
        })

        onSnapshot(query(collection(db, `ads/request/position_${position}`), where('status', '==', 1), orderBy('request_timestamp', 'asc')), (result) => {
            if(!result.empty) {
                requestList.splice(0, requestList.length)
                setRequestList([...requestList])
                setRequestNum(result.docs.length)
                result.forEach(async(ads) => {
                    const postTitle = await getPostTitle(db, ads.data().post_id)
                    const userTitle = await getUserTitle(db, ads.data().user_id)
                    requestList.push({
                        id: ads.id,
                        data: ads.data(),
                        post_title: postTitle,
                        user_title: userTitle
                    })
                    setRequestList([...requestList], {
                        id: ads.id,
                        data: ads.data(),
                        post_title: postTitle,
                        user_title: userTitle
                    })
                })
            }
        })
    }

    const approveAdsToQueue = (adsId) => {
        getDoc(doc(db, `ads/request/position_${position}`, adsId)).then(async (adsData) => {
            const requestDate = new Date()
            if(positionAmount[position - 1] == activeNum) {
                addDoc(collection(db, `/ads/pending/position_${position}`), {
                    ads_id: adsId,
                    user_id: adsData.data().user_id,
                    post_id: adsData.data().post_id,
                    used_coin_per_day: adsData.data().used_coin_per_day,
                    ads_duration: adsData.data().ads_duration * 1,
                    total_used_coin: adsData.data().total_used_coin * 1,
                    pending_timestamp: requestDate.getTime(),
                    status: 1
                }).then(function() {
                    updateDoc(doc(db, `ads/request/position_${position}`, adsId), { 
                        status: 2 
                    }).then(() => {
                        getDocs(collection(db, `users/${adsData.data().user_id}/ads/request/list/`), where('ads_id', '==', adsId)).then((docs) => {
                            updateDoc(doc(db, `users/${adsData.data().user_id}/ads/request/list/`, docs.docs[0].id), {
                                status: 2 
                            }).then(() => {
                                addDoc(collection(db, `users/${adsData.data().user_id}/ads/pending/list/`), {
                                    ads_id: adsId,
                                    position: position,
                                    post_id: adsData.data().post_id,
                                    used_coin_per_day: adsData.data().used_coin_per_day,
                                    ads_duration: adsData.data().ads_duration * 1,
                                    total_used_coin: adsData.data().total_used_coin * 1,
                                    pending_timestamp: requestDate.getTime(),
                                    status: 1
                                }).then(function() {
                                    navigate(0)
                                })
                            })
                        })
                    })
                })
            } else {
                const today = new Date()
                const finalDate = new Date()
                finalDate.setDate(today.getDate() + (adsData.data().ads_duration -1))

                addDoc(collection(db, `/ads/active/position_${position}`), {
                    ads_id: adsId,
                    user_id: adsData.data().user_id,
                    post_id: adsData.data().post_id,
                    used_coin_per_day: adsData.data().used_coin_per_day,
                    ads_duration: adsData.data().ads_duration * 1,
                    total_used_coin: adsData.data().total_used_coin * 1,
                    start_date: today.getTime(),
                    final_date: finalDate.getTime(),
                    active_date: requestDate.getTime(),
                    status: 1
                }).then(function() {
                    updateDoc(doc(db, `ads/request/position_${position}`, adsId), { 
                        status: 2 
                    }).then(() => {
                        getDocs(collection(db, `users/${adsData.data().user_id}/ads/request/list/`), where('ads_id', '==', adsId)).then((docs) => {
                            updateDoc(doc(db, `users/${adsData.data().user_id}/ads/request/list/`, docs.docs[0].id), {
                                status: 2 
                            }).then(() => {
                                addDoc(collection(db, `users/${adsData.data().user_id}/ads/active/list/`), {
                                    ads_id: adsId,
                                    position: position,
                                    post_id: adsData.data().post_id,
                                    used_coin_per_day: adsData.data().used_coin_per_day,
                                    ads_duration: adsData.data().ads_duration * 1,
                                    total_used_coin: adsData.data().total_used_coin * 1,
                                    start_date: today.getTime(),
                                    final_date: finalDate.getTime(),
                                    active_date: requestDate.getTime(),
                                    status: 1
                                }).then(function() {
                                    navigate(0)
                                })
                            })
                        })
                    })
                })
            }
            return false
        })
    }

    const unapproveAdsToQueue = (adsId) => {
        updateDoc(doc(db, `ads/request/position_${position}`, adsId), { 
            status: 3 
        }).then(() => {
            getDoc(doc(db, `ads/request/position_${position}`, adsId)).then(async (adsData) => {
                getDocs(collection(db, `users/${adsData.data().user_id}/ads/request/list/`), where('ads_id', '==', adsId)).then((docs) => {
                    updateDoc(doc(db, `users/${adsData.data().user_id}/ads/request/list/`, docs.docs[0].id), {
                        status: 3 
                    }).then(() => {
                        navigate(0)
                    })
                })
            })
        })
    }

    useEffect(()=>{
        if (!initialized.current) {
            initialized.current = true
            if(!loadingAds) {
                getAds().then(() => {
                    setLoadingAds(true)
                })
            }
        }
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><BsMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายการโฆษณาตำแหน่งที่ {position} - {positionName[position - 1]}</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ประกาศ</th>
                            <th scope="col">วันที่เริ่ม-สิ้นสุดประกาศ</th>
                            <th scope="col">จำนวนวันที่ประกาศ</th>
                            <th scope="col"> </th>
                        </tr>
                    </thead>
                    <tbody>
                    {activeList?.map((ads, i) => (
                        <tr key={i}>
                            <th scope="row">{i+1}</th>
                            <td><font style={{ fontSize: 18 }}>{ads.post_title}</font><br/>ผู้ประกาศ: {ads.user_title}</td>
                            <td>{dateFormatTime(ads.data.start_date)} - {dateFormatTime(ads.data.final_date)}</td>
                            <td>{number_format.format(ads.data.ads_duration)}</td>
                            <td> </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><BsMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>คิวรอโฆษณาตำแหน่งที่ {position} - {positionName[position - 1]}</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ผู้ใช้ระบบ</th>
                            <th scope="col">ประกาศ</th>
                            <th scope="col">วันที่ขอ</th>
                            <th scope="col">จำนวนวันที่ต้องการลงประกาศ</th>
                            <th scope="col"> </th>
                        </tr>
                    </thead>
                    <tbody>
                    {pendingList?.map((ads, i) => (
                        <tr key={i}>
                            <th scope="row">{i+1}</th>
                            <td>{ads.user_title}</td>
                            <td>{ads.post_title}</td>
                            <td>{dateFormatTime(ads.data.request_timestamp)}</td>
                            <td>{number_format.format(ads.data.ads_duration)}</td>
                            <td> </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><BsMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>คำขอโฆษณาตำแหน่งที่ {position} - {positionName[position - 1]}</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">ผู้ใช้ระบบ</th>
                            <th scope="col">ประกาศ</th>
                            <th scope="col">วันที่ขอ</th>
                            <th scope="col">จำนวนวันที่ต้องการลงประกาศ</th>
                            <th scope="col"> </th>
                        </tr>
                    </thead>
                    <tbody>
                    {requestList?.map((ads, i) => (
                        <tr key={i}>
                            <th scope="row">{i+1}</th>
                            <td>{ads.user_title}</td>
                            <td>{ads.post_title}</td>
                            <td>{dateFormatTime(ads.data.request_timestamp)}</td>
                            <td>{number_format.format(ads.data.ads_duration)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" 
                                        className="btn btn-success btn-sm" 
                                        style={{ marginRight: 5, display: 'flex', wordWrap: 'unset' }} 
                                        onClick={() => { approveAdsToQueue(ads.id) }}>
                                        <TbCircleCheck style={{ fontSize: 20, marginRight: 5 }} /><font style={{ wordWrap: 'unset' }}>อนุมัติ</font></button>
                                    <button type="button" 
                                        className="btn btn-danger btn-sm" 
                                        style={{ marginRight: 5, display: 'flex', wordWrap: 'unset' }} 
                                        onClick={() => { unapproveAdsToQueue(ads.id) }}>
                                        <TbAlertCircle style={{ fontSize: 20, marginRight: 5 }} /><font style={{ wordWrap: 'unset' }}>ไม่อนุมัติ</font></button>
                                </div>
                            </td>
                        </tr>
                    ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default Ads