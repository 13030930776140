import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, getDoc, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import LeftMenu from './LeftMenu'
import addImage from "../../assets/images/add-image-icon.png"

import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import dayjs from 'dayjs'

import { IoCheckmarkCircleSharp, IoCloseCircle, IoChevronBackCircleSharp } from 'react-icons/io5'
import { AiFillEdit } from 'react-icons/ai'
import { FaMapMarkerAlt } from "react-icons/fa"

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { Loading } from "../layouts/Loading"

import LeftMenuPost from './LeftMenuPost'
import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { defaultLatLng, googleMapsApiKey } from "../../../assets/constants/locations"
import { defauleImageSize } from "../../../assets/constants/images"

const EditPostAir = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const number_format = new Intl.NumberFormat()
    const today = dayjs()
    const [postId, setPostId] = useState(params.id)

    const [uid, setUid] = useState('')
    const [member, setMember] = useState([])
    const [memberId, setMenberId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [onBoard, setOnBoard] = useState(1)
    const [status, setStatus] = useState(1)

    const [address, setAddress] = useState('')
    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [pickDate, setPickDate] = useState(today)
    const [hour, setHour] = useState('09')
    const [minute, setMinute] = useState('00')

    const [offerUseCoin, setOfferUseCoin] = useState(0)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [useCoinFrom, setUseCoinFrom] = useState(1)
    const [useCoinErrorDiv, setUseCoinErrorDiv] = useState(false)

    const [brandList, setBrandList] = useState([])
    const [brandId1, setBrandId1] = useState('')
    const [brandId2, setBrandId2] = useState('')
    const [brandId3, setBrandId3] = useState('')
    const [brand1, setBrand1] = useState('')
    const [brand2, setBrand2] = useState('')
    const [brand3, setBrand3] = useState('')

    const [modelList1, setModelList1] = useState([])
    const [modelList2, setModelList2] = useState([])
    const [modelList3, setModelList3] = useState([])
    const [modelId1, setModelId1] = useState('')
    const [modelId2, setModelId2] = useState('')
    const [modelId3, setModelId3] = useState('')
    const [model1, setModel1] = useState('')
    const [model2, setModel2] = useState('')
    const [model3, setModel3] = useState('')

    const [hasAir1, setHasAir1] = useState(true)
    const [air1BTU, setAir1BTU] = useState('')
    const [air1Amount, setAir1Amount] = useState(0)
    const [air1IsOver3M, setAir1IsOver3M] = useState(false)
    const [air1Over3MAmount, setAir1Over3MAmount] = useState(0)
    const [air1IsBelow3M, setAir1IsBelow3M] = useState(false)
    const [air1Below3MAmount, setAir1Below3MAmount] = useState(0)
    const [air1IsProblem1, setAir1IsPloblem1] = useState(false)
    const [air1IsProblem2, setAir1IsPloblem2] = useState(false)
    const [air1IsProblem3, setAir1IsPloblem3] = useState(false)
    const [air1IsProblem4, setAir1IsPloblem4] = useState(false)
    const [air1IsProblem5, setAir1IsPloblem5] = useState(false)
    const [srcAir1Picture1, setSrcAir1Picture1] = useState(addImage)
    const [srcAir1Picture2, setSrcAir1Picture2] = useState(addImage)
    const [srcAir1Picture3, setSrcAir1Picture3] = useState(addImage)
    const [srcAir1Picture4, setSrcAir1Picture4] = useState(addImage)
    const [srcAir1Picture5, setSrcAir1Picture5] = useState(addImage)
    const [air1Picture1, setAir1Picture1] = useState('')
    const [air1Picture2, setAir1Picture2] = useState('')
    const [air1Picture3, setAir1Picture3] = useState('')
    const [air1Picture4, setAir1Picture4] = useState('')
    const [air1Picture5, setAir1Picture5] = useState('')
    const [pictureAir1ErrorDiv, setPictureAir1ErrorDiv] = useState(false)
    const [air1Images, setAir1Images] = useState([])

    const [hasAir2, setHasAir2] = useState(false)
    const [air2BTU, setAir2BTU] = useState('')
    const [air2Amount, setAir2Amount] = useState(0)
    const [air2IsOver3M, setAir2IsOver3M] = useState(false)
    const [air2Over3MAmount, setAir2Over3MAmount] = useState(0)
    const [air2IsBelow3M, setAir2IsBelow3M] = useState(false)
    const [air2Below3MAmount, setAir2Below3MAmount] = useState(0)
    const [air2IsProblem1, setAir2IsPloblem1] = useState(false)
    const [air2IsProblem2, setAir2IsPloblem2] = useState(false)
    const [air2IsProblem3, setAir2IsPloblem3] = useState(false)
    const [air2IsProblem4, setAir2IsPloblem4] = useState(false)
    const [air2IsProblem5, setAir2IsPloblem5] = useState(false)
    const [srcAir2Picture1, setSrcAir2Picture1] = useState(addImage)
    const [srcAir2Picture2, setSrcAir2Picture2] = useState(addImage)
    const [srcAir2Picture3, setSrcAir2Picture3] = useState(addImage)
    const [srcAir2Picture4, setSrcAir2Picture4] = useState(addImage)
    const [srcAir2Picture5, setSrcAir2Picture5] = useState(addImage)
    const [air2Picture1, setAir2Picture1] = useState('')
    const [air2Picture2, setAir2Picture2] = useState('')
    const [air2Picture3, setAir2Picture3] = useState('')
    const [air2Picture4, setAir2Picture4] = useState('')
    const [air2Picture5, setAir2Picture5] = useState('')
    const [pictureAir2ErrorDiv, setPictureAir2ErrorDiv] = useState(false)
    const [air2Images, setAir2Images] = useState([])

    const [hasAir3, setHasAir3] = useState(false)
    const [air3BTU, setAir3BTU] = useState('')
    const [air3Amount, setAir3Amount] = useState(0)
    const [air3IsOver3M, setAir3IsOver3M] = useState(false)
    const [air3Over3MAmount, setAir3Over3MAmount] = useState(0)
    const [air3IsBelow3M, setAir3IsBelow3M] = useState(false)
    const [air3Below3MAmount, setAir3Below3MAmount] = useState(0)
    const [air3IsProblem1, setAir3IsPloblem1] = useState(false)
    const [air3IsProblem2, setAir3IsPloblem2] = useState(false)
    const [air3IsProblem3, setAir3IsPloblem3] = useState(false)
    const [air3IsProblem4, setAir3IsPloblem4] = useState(false)
    const [air3IsProblem5, setAir3IsPloblem5] = useState(false)
    const [srcAir3Picture1, setSrcAir3Picture1] = useState(addImage)
    const [srcAir3Picture2, setSrcAir3Picture2] = useState(addImage)
    const [srcAir3Picture3, setSrcAir3Picture3] = useState(addImage)
    const [srcAir3Picture4, setSrcAir3Picture4] = useState(addImage)
    const [srcAir3Picture5, setSrcAir3Picture5] = useState(addImage)
    const [air3Picture1, setAir3Picture1] = useState('')
    const [air3Picture2, setAir3Picture2] = useState('')
    const [air3Picture3, setAir3Picture3] = useState('')
    const [air3Picture4, setAir3Picture4] = useState('')
    const [air3Picture5, setAir3Picture5] = useState('')
    const [pictureAir3ErrorDiv, setPictureAir3ErrorDiv] = useState(false)
    const [air3Images, setAir3Images] = useState([])

    const [brandIdErrorDiv1, setBrandIdErrorDiv1] = useState(false)
    const [brandIdErrorDiv2, setBrandIdErrorDiv2] = useState(false)
    const [brandIdErrorDiv3, setBrandIdErrorDiv3] = useState(false)
    const [brandErrorDiv1, setBrandErrorDiv1] = useState(false)
    const [brandErrorDiv2, setBrandErrorDiv2] = useState(false)
    const [brandErrorDiv3, setBrandErrorDiv3] = useState(false)
    const [modelIdErrorDiv1, setModelIdErrorDiv1] = useState(false)
    const [modelIdErrorDiv2, setModelIdErrorDiv2] = useState(false)
    const [modelIdErrorDiv3, setModelIdErrorDiv3] = useState(false)
    const [modelErrorDiv1, setModelErrorDiv1] = useState(false)
    const [modelErrorDiv2, setModelErrorDiv2] = useState(false)
    const [modelErrorDiv3, setModelErrorDiv3] = useState(false)

    const [currentLat, setCurrentLat] = useState(defaultLatLng.lat)
    const [currentLng, setCurrentLng] = useState(defaultLatLng.lng)
    const [regisLat, setRegisLat] = useState(defaultLatLng.lat)
    const [regisLng, setRegisLng] = useState(defaultLatLng.lng)

    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [provinceList, setProvinceList] = useState([])
    const [provinceId, setProvinceId] = useState(0)
    const [districtList, setDistrictList] = useState([])
    const [districtId, setDistrictId] = useState(0)
    const [subdistrictList, setSubDistrictList] = useState([])
    const [subdistrictId, setSubDistrictId] = useState(0)
    const [postcode, setPostcode] = useState('')

    const [addressNoRegis, setAddressNoRegis] = useState('')
    const [villageRegis, setVillageRegis] = useState('')
    const [buildingRegis, setBuildingRegis] = useState('')
    const [floorRegis, setFloorRegis] = useState('')
    const [roomRegis, setRoomRegis] = useState('')
    const [subroadRegis, setSubroadRegis] = useState('')
    const [roadRegis, setRoadRegis] = useState('')
    const [provinceIdRegis, setProvinceIdRegis] = useState(0)
    const [districtIdRegis, setDistrictIdRegis] = useState(0)
    const [subdistrictIdRegis, setSubDistrictIdRegis] = useState(0)
    const [postcodeRegis, setPostcodeRegis] = useState('')

    const [provinceIdErrorDiv, setProvinceIdErrorDiv] = useState(false)
    const [districtIdErrorDiv, setDistrictIdErrorDiv] = useState(false)
    const [subdistrictIdErrorDiv, setSubDistrictErrorDiv] = useState(false)
    const [imageSize1Error, setImageSize1Error] = useState(false)
    const [imageSize2Error, setImageSize2Error] = useState(false)
    const [imageSize3Error, setImageSize3Error] = useState(false)

    const [showLoading, setShowLoading] = useState(false)
    const [showProgressBar, setShowProgressBar] = useState(false)
    const [openAlertDelete, setOpenAlertDelete] = useState(false)

    const [air1BTUErrorDiv, setAir1BTUErrorDiv] = useState(false)
    const [air1AmountErrorDiv, setAir1AmountErrorDiv] = useState(false)

    const [air2BTUErrorDiv, setAir2BTUErrorDiv] = useState(false)
    const [air2AmountErrorDiv, setAir2AmountErrorDiv] = useState(false)

    const [air3BTUErrorDiv, setAir3BTUErrorDiv] = useState(false)
    const [air3AmountErrorDiv, setAir3AmountErrorDiv] = useState(false)

    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey })
    const [center, setCenter] = useState({ lat: defaultLatLng.lat, lng: defaultLatLng.lng })
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    var locationOptions = {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 0,
    }
    
    const handelGoToRegisPosition = () => {
        setLat(regisLat)
        setLng(regisLng)
        setCenter({lat: regisLat, lng: regisLng})
    }

    const handelGoToCurrentPosition = () => {
        setLat(currentLat)
        setLng(currentLng)
        setCenter({lat: currentLat, lng: currentLng})
    }

    const locationSuccess = (pos) => {
        var crd = pos.coords
        setCurrentLat(crd.latitude)
        setCurrentLng(crd.longitude)
    }

    const locationErrors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                //console.log(result);
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
            });
        } else {
          //console.log("Geolocation is not supported by this browser.");
        }
    }, [])

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    const handleHaveAir1Change = () => {
        setHasAir1(!hasAir1)
    }

    const handleAir1IsOver3M = () => {
        setAir1IsOver3M(!air1IsOver3M)
    }

    const handleAir1IsBelow3M = () => {
        setAir1IsBelow3M(!air1IsBelow3M)
    }

    const handleAir1IsProblem1 = () => {
        setAir1IsPloblem1(!air1IsProblem1)
    }

    const handleAir1IsProblem2 = () => {
        setAir1IsPloblem2(!air1IsProblem2)
    }

    const handleAir1IsProblem3 = () => {
        setAir1IsPloblem3(!air1IsProblem3)
    }

    const handleAir1IsProblem4 = () => {
        setAir1IsPloblem4(!air1IsProblem4)
    }

    const handleAir1IsProblem5 = () => {
        setAir1IsPloblem5(!air1IsProblem5)
    }


    const handleHaveAir2Change = () => {
        setHasAir2(!hasAir2)
    }

    const handleAir2IsOver3M = () => {
        setAir2IsOver3M(!air2IsOver3M)
    }

    const handleAir2IsBelow3M = () => {
        setAir2IsBelow3M(!air2IsBelow3M)
    }

    const handleAir2IsProblem1 = () => {
        setAir2IsPloblem1(!air2IsProblem1)
    }

    const handleAir2IsProblem2 = () => {
        setAir2IsPloblem2(!air2IsProblem2)
    }

    const handleAir2IsProblem3 = () => {
        setAir2IsPloblem3(!air2IsProblem3)
    }

    const handleAir2IsProblem4 = () => {
        setAir2IsPloblem4(!air2IsProblem4)
    }

    const handleAir2IsProblem5 = () => {
        setAir2IsPloblem5(!air2IsProblem5)
    }

    const handleHaveAir3Change = () => {
        setHasAir3(!hasAir3)
    }

    const handleAir3IsOver3M = () => {
        setAir3IsOver3M(!air3IsOver3M)
    }

    const handleAir3IsBelow3M = () => {
        setAir3IsBelow3M(!air3IsBelow3M)
    }

    const handleAir3IsProblem1 = () => {
        setAir3IsPloblem1(!air3IsProblem1)
    }

    const handleAir3IsProblem2 = () => {
        setAir3IsPloblem2(!air3IsProblem2)
    }

    const handleAir3IsProblem3 = () => {
        setAir3IsPloblem3(!air3IsProblem3)
    }

    const handleAir3IsProblem4 = () => {
        setAir3IsPloblem4(!air3IsProblem4)
    }

    const handleAir3IsProblem5 = () => {
        setAir3IsPloblem5(!air3IsProblem5)
    }

    const validateForm = () => {
        /* if(useCoinFrom == 1) {
            if(offerUseCoin > offerFreeCoin) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } else if (useCoinFrom == 2) {
            if(offerUseCoin > offerExchangeCoin) {
                setUseCoinErrorDiv(true)
                return false
            } else {
                setUseCoinErrorDiv(false)
            }
        } */

        if(hasAir1) {
            if(!brandId1 || brandId1 == '0') {
                setBrandIdErrorDiv1(true)
                return false
            } else {
                if(brandId1 == '999') {
                    if(!brand1) {
                        setBrandErrorDiv1(true)
                        return false
                    } else {
                        setBrandErrorDiv1(false)
                        setBrandIdErrorDiv1(false)
                    }
                } else {
                    setBrandErrorDiv1(false)
                    setBrandIdErrorDiv1(false)
                }
            }
    
            if(!modelId1) {
                setModelIdErrorDiv1(true)
                return false
            } else {
                if(modelId1 == '999' || modelId1 == '0') {
                    if(!model1) {
                        setModelErrorDiv1(true)
                        return false
                    } else {
                        setModelErrorDiv1(false)
                        setModelIdErrorDiv1(false)
                    }
                } else {
                    setModelErrorDiv1(false)
                    setModelIdErrorDiv1(false)
                }
            }

            if(!air1BTU || !isOnlyNumeric(air1BTU)) {
                setAir1BTUErrorDiv(true)
                return false
            } else {
                setAir1BTUErrorDiv(false)
            }

            if(!air1Amount || !isOnlyNumeric(air1Amount)) {
                setAir1AmountErrorDiv(true)
                return false
            } else {
                setAir1AmountErrorDiv(false)
            }

            if(!imageSize1Error) {
                var numPic1 = 0
                air1Images.forEach((image) => {
                    if(image.status != 0) {
                        numPic1++;
                    }
                })
                if(numPic1 > 0) {
                    setPictureAir1ErrorDiv(false)
                } else {
                    setPictureAir1ErrorDiv(true)
                    return false;
                }
            } else {
                return false
            }
        }

        if(hasAir2) {
            if(!brandId2) {
                setBrandIdErrorDiv2(true)
                return false
            } else {
                if(brandId2 == '999' || brandId2 == '0') {
                    if(!brand2) {
                        setBrandErrorDiv2(true)
                        return false
                    } else {
                        setBrandErrorDiv2(false)
                        setBrandIdErrorDiv2(false)
                    }
                } else {
                    setBrandErrorDiv2(false)
                    setBrandIdErrorDiv2(false)
                }
            }
    
            if(!modelId2) {
                setModelIdErrorDiv2(true)
                return false
            } else {
                if(modelId2 == '999' || modelId2 == '0') {
                    if(!model2) {
                        setModelErrorDiv2(true)
                        return false
                    } else {
                        setModelErrorDiv2(false)
                        setModelIdErrorDiv2(false)
                    }
                } else {
                    setModelErrorDiv2(false)
                    setModelIdErrorDiv2(false)
                }
            }

            if(!air2BTU || !isOnlyNumeric(air2BTU)) {
                setAir2BTUErrorDiv(true)
                return false
            } else {
                setAir2BTUErrorDiv(false)
            }

            if(!air2Amount || !isOnlyNumeric(air2Amount)) {
                setAir2AmountErrorDiv(true)
                return false
            } else {
                setAir2AmountErrorDiv(false)
            }

            if(!imageSize2Error) {
                var numPic2 = 0
                air2Images.forEach((image) => {
                    if(image.status != 0) {
                        numPic2++;
                    }
                })
                if(numPic2 > 0) {
                    setPictureAir2ErrorDiv(false)
                } else {
                    setPictureAir2ErrorDiv(true)
                    return false;
                }
            } else {
                return false
            }
        }

        if(hasAir3) {
            if(!brandId3) {
                setBrandIdErrorDiv3(true)
                return false
            } else {
                if(brandId3 == '999' || brandId3 == '0') {
                    if(!brand3) {
                        setBrandErrorDiv3(true)
                        return false
                    } else {
                        setBrandErrorDiv3(false)
                    }
                } else {
                    setBrandErrorDiv3(false)
                    setBrandIdErrorDiv3(false)
                }
            }
    
            if(!modelId3) {
                setModelIdErrorDiv3(true)
                return false
            } else {
                if(modelId3 == '999' || modelId3 == '0') {
                    if(!model3) {
                        setModelErrorDiv3(true)
                        return false
                    } else {
                        setModelErrorDiv3(false)
                    }
                } else {
                    setModelErrorDiv3(false)
                    setModelIdErrorDiv3(false)
                }
            }

            if(!air3BTU || !isOnlyNumeric(air3BTU)) {
                setAir3BTUErrorDiv(true)
                return false
            } else {
                setAir3BTUErrorDiv(false)
            }

            if(!air3Amount || !isOnlyNumeric(air3Amount)) {
                setAir3AmountErrorDiv(true)
                return false
            } else {
                setAir3AmountErrorDiv(false)
            }

            if(!imageSize3Error) {
                var numPic3 = 0
                air3Images.forEach((image) => {
                    if(image.status != 0) {
                        numPic3++;
                    }
                })
                if(numPic3 > 0) {
                    setPictureAir3ErrorDiv(false)
                } else {
                    setPictureAir3ErrorDiv(true)
                    return false;
                }
            } else {
                return false
            }
        }

        if(provinceId == 0) {
            setProvinceIdErrorDiv(true)
            return false
        } else {
            setProvinceIdErrorDiv(false)
        }

        if(districtId == 0) {
            setDistrictIdErrorDiv(true)
            return false
        } else {
            setDistrictIdErrorDiv(false)
        }

        if(subdistrictId == 0) {
            setSubDistrictErrorDiv(true)
            return false
        } else {
            setSubDistrictErrorDiv(false)
        }

        return true
    }

    const handleSubmit = (e) => {
    	e.preventDefault()
        if(validateForm()) {
            const new_date = new Date()
            setShowProgressBar(true)
            updateDoc(doc(db, 'cleans', postId), {
                pick_date: pickDate.toDate(),
                hour: hour,
                minute: minute,
                
                have_air1: hasAir1,
                air1_brand_id: brandId1,
                air1_brand: brand1,
                air1_model_id: modelId1,
                air1_model: model1,
                air1_BTU: air1BTU,
                air1_amount: air1Amount*1,
                air1_is_over_3m: air1IsOver3M,
                air1_over_3m_amount: air1Over3MAmount*1,
                air1_is_below_3m: air1IsBelow3M,
                air1_below_3m_amount: air1Below3MAmount*1,
                air1_is_problem1: air1IsProblem1,
                air1_is_problem2: air1IsProblem2,
                air1_is_problem3: air1IsProblem3,
                air1_is_problem4: air1IsProblem4,
                air1_is_problem5: air1IsProblem5,
                
                have_air2: hasAir2,
                air2_brand_id: brandId2,
                air2_brand: brand2,
                air2_model_id: modelId2,
                air2_model: model2,
                air2_BTU: air2BTU,
                air2_amount: air2Amount*1,
                air2_is_over_3m: air2IsOver3M,
                air2_over_3m_amount: air2Over3MAmount*1,
                air2_is_below_3m: air2IsBelow3M,
                air2_below_3m_amount: air2Below3MAmount*1,
                air2_is_problem1: air2IsProblem1,
                air2_is_problem2: air2IsProblem2,
                air2_is_problem3: air2IsProblem3,
                air2_is_problem4: air2IsProblem4,
                air2_is_problem5: air2IsProblem5,
                
                have_air3: hasAir3,
                air3_brand_id: brandId3,
                air3_brand: brand3,
                air3_model_id: modelId3,
                air3_model: model3,
                air3_BTU: air3BTU,
                air3_amount: air3Amount*1,
                air3_is_over_3m: air3IsOver3M,
                air3_over_3m_amount: air3Over3MAmount*1,
                air3_is_below_3m: air3IsBelow3M,
                air3_below_3m_amount: air3Below3MAmount*1,
                air3_is_problem1: air3IsProblem1,
                air3_is_problem2: air3IsProblem2,
                air3_is_problem3: air3IsProblem3,
                air3_is_problem4: air3IsProblem4,
                air3_is_problem5: air3IsProblem5,

                lat: lat,
                lng: lng,
                address: address,
                address_no: addressNo,
                road: road,
                subroad: subroad,
                village: village,
                building: building,
                floor: floor,
                room: room,
                sub_district: subdistrictId,
                district: districtId,
                province: provinceId,
                postcode: postcode,
                status: 1,
            }).then(() => {
                const promises = []
                var vari = 1
                air1Images.forEach((image, i) => {
                    if(image.status == 0) {
                        if(image.id != "") {
                            deleteDoc(doc(db, `cleans/${postId}/images1`, image.id));
                        }
                    } else if(image.status == 2) {
                        const storageRef = ref(storage, `/cleans/${postId}/air1/${i+1}`)
                        const uploadTask = uploadBytesResumable(storageRef, image.file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    if(image.id == "") {
                                        addDoc(collection(db, `/cleans/${postId}/images1`), {
                                            url: downloadURLs,
                                        })
                                    } else {
                                        updateDoc(doc(db, `/cleans/${postId}/images1`, image.id), {
                                            url: downloadURLs
                                        })
                                    }
                                    if(i+1 == 1) {
                                        updateDoc(doc(db, 'cleans', postId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        vari++
                    }
                })
                vari = 1
                air2Images.forEach((image, i) => {
                    if(image.status == 0) {
                        if(image.id != "") {
                            deleteDoc(doc(db, `cleans/${postId}/images2`, image.id));
                        }
                    } else if(image.status == 2) {
                        const storageRef = ref(storage, `/cleans/${postId}/air2/${i+1}`)
                        const uploadTask = uploadBytesResumable(storageRef, image.file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    if(image.id == "") {
                                        addDoc(collection(db, `/cleans/${postId}/images2`), {
                                            url: downloadURLs,
                                        })
                                    } else {
                                        updateDoc(doc(db, `/cleans/${postId}/images2`, image.id), {
                                            url: downloadURLs
                                        })
                                    }
                                    if(i+1 == 1) {
                                        updateDoc(doc(db, 'cleans', postId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        vari++
                    }
                })
                vari = 1
                air3Images.forEach((image, i) => {
                    if(image.status == 0) {
                        if(image.id != "") {
                            deleteDoc(doc(db, `cleans/${postId}/images3`, image.id));
                        }
                    } else if(image.status == 2) {
                        const storageRef = ref(storage, `/cleans/${postId}/air3/${i+1}`)
                        const uploadTask = uploadBytesResumable(storageRef, image.file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    if(image.id == "") {
                                        addDoc(collection(db, `/cleans/${postId}/images3`), {
                                            url: downloadURLs,
                                        })
                                    } else {
                                        updateDoc(doc(db, `/cleans/${postId}/images3`, image.id), {
                                            url: downloadURLs
                                        })
                                    }
                                    if(i+1 == 1) {
                                        updateDoc(doc(db, 'cleans', postId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        vari++
                    }
                })

                Promise.all(promises).then(() => {
                    setShowProgressBar(false)
                    navigate("/profile/posts/airs")
                })
            }).catch(function(error) {
                console.error("Error adding document: ", error)
            })
        }
    }

    const getPost = () => {
        setShowLoading(true)
        getDoc(doc(db, 'cleans', postId)).then((postDoc) => {
            if (postDoc.exists()) {
                setOnBoard(postDoc.data().onboard)
                setStatus(postDoc.data().status)
                if(postDoc.data().have_air1) {
                    setHasAir1(true)
                    setBrandId1(postDoc.data().air1_brand_id)
                    setBrand1(postDoc.data().air1_brand)
                    setModelId1(postDoc.data().air1_model_id)
                    setModel1(postDoc.data().air1_model)
                    setAir1BTU(postDoc.data().air1_BTU)
                    setAir1Amount(postDoc.data().air1_amount)
                    setAir1IsOver3M(postDoc.data().air1_is_over_3m)
                    setAir1Over3MAmount(postDoc.data().air1_over_3m_amount)
                    setAir1IsBelow3M(postDoc.data().air1_is_below_3m)
                    setAir1Below3MAmount(postDoc.data().air1_below_3m_amount)
                    setAir1IsPloblem1(postDoc.data().air1_is_problem1)
                    setAir1IsPloblem2(postDoc.data().air1_is_problem2)
                    setAir1IsPloblem3(postDoc.data().air1_is_problem3)
                    setAir1IsPloblem4(postDoc.data().air1_is_problem4)
                    setAir1IsPloblem5(postDoc.data().air1_is_problem5)

                    const imagesQuery = query(collection(db, `cleans/${postId}/images1`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setAir1Images(querySnapshot.docs.map((doc, i) => { 
                            return {
                                id: doc.id,
                                url: doc.data().url,
                                file: "",
                                status: 1
                            }
                        }))
                    })
                }
                if(postDoc.data().have_air2) {
                    setHasAir2(true)
                    setBrandId2(postDoc.data().air2_brand_id)
                    setBrand2(postDoc.data().air2_brand)
                    setModelId2(postDoc.data().air2_model_id)
                    setModel2(postDoc.data().air2_model)
                    setAir2BTU(postDoc.data().air2_BTU)
                    setAir2Amount(postDoc.data().air2_amount)
                    setAir2IsOver3M(postDoc.data().air2_is_over_3m)
                    setAir2Over3MAmount(postDoc.data().air2_over_3m_amount)
                    setAir2IsBelow3M(postDoc.data().air2_is_below_3m)
                    setAir2Below3MAmount(postDoc.data().air2_below_3m_amount)
                    setAir2IsPloblem1(postDoc.data().air2_is_problem1)
                    setAir2IsPloblem2(postDoc.data().air2_is_problem2)
                    setAir2IsPloblem3(postDoc.data().air2_is_problem3)
                    setAir2IsPloblem4(postDoc.data().air2_is_problem4)
                    setAir2IsPloblem5(postDoc.data().air2_is_problem5)
                    
                    const imagesQuery = query(collection(db, `cleans/${postId}/images2`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setAir2Images(querySnapshot.docs.map((doc, i) => { 
                            return {
                                id: doc.id,
                                url: doc.data().url,
                                file: "",
                                status: 1
                            }
                        }))
                    })
                }
                if(postDoc.data().have_air3) {
                    setHasAir3(true)
                    setBrandId3(postDoc.data().air3_brand_id)
                    setBrand3(postDoc.data().air3_brand)
                    setModelId3(postDoc.data().air3_model_id)
                    setModel3(postDoc.data().air3_model)
                    setAir3BTU(postDoc.data().air3_BTU)
                    setAir3Amount(postDoc.data().air3_amount)
                    setAir3IsOver3M(postDoc.data().air3_is_over_3m)
                    setAir3Over3MAmount(postDoc.data().air3_over_3m_amount)
                    setAir3IsBelow3M(postDoc.data().air3_is_below_3m)
                    setAir3Below3MAmount(postDoc.data().air3_below_3m_amount)
                    setAir3IsPloblem1(postDoc.data().air3_is_problem1)
                    setAir3IsPloblem2(postDoc.data().air3_is_problem2)
                    setAir3IsPloblem3(postDoc.data().air3_is_problem3)
                    setAir3IsPloblem4(postDoc.data().air3_is_problem4)
                    setAir3IsPloblem5(postDoc.data().air3_is_problem5)
                    
                    const imagesQuery = query(collection(db, `cleans/${postId}/images3`))
                    onSnapshot(imagesQuery, (querySnapshot) => {
                        setAir3Images(querySnapshot.docs.map((doc, i) => { 
                            return {
                                id: doc.id,
                                url: doc.data().url,
                                file: "",
                                status: 1
                            }
                        }))
                    })
                }

                setLat(postDoc.data().lat)
                setLng(postDoc.data().lng)
                setAddress(postDoc.data().address)
                setAddressNo(postDoc.data().address_no)
                setVillage(postDoc.data().village)
                setBuilding(postDoc.data().building)
                setFloor(postDoc.data().floor)
                setRoom(postDoc.data().room)
                setSubroad(postDoc.data().subroad)
                setRoad(postDoc.data().road)
                var promisesTask = []

                if(postDoc.data().province) {
                    setProvinceId(postDoc.data().province)
                    const districtTask = getDocs(query(collection(db, 'distrincts'), where('province_id', '==', postDoc.data().province * 1))).then((docs) => {
                        setDistrictList(docs.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })))
                    })
                    promisesTask.push(districtTask)
                }

                if(postDoc.data().district) {
                    setDistrictId(postDoc.data().district)
                    const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', postDoc.data().district))).then((docs) => {
                        setSubDistrictList(docs.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })))
                    })
                    promisesTask.push(subdistrictTask)
                }
                if(postDoc.data().sub_district) { setSubDistrictId(postDoc.data().sub_district) }
                if(postDoc.data().postcode) { setPostcode(postDoc.data().postcode) }
                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                })
            } else {
                console.log("Post doesn't exist")
                navigate('/')
            }
        })
    }

    useEffect(() => {
        if(air1Images.length < 5) {
            setAir1Images([...air1Images, { id: "", url: addImage, file: "", status: 0 }])
        }
    }, [air1Images])

    useEffect(() => {
        if(air2Images.length < 5) {
            setAir2Images([...air2Images, { id: "", url: addImage, file: "", status: 0 }])
        }
    }, [air2Images])

    useEffect(() => {
        if(air3Images.length < 5) {
            setAir3Images([...air3Images, { id: "", url: addImage, file: "", status: 0 }])
        }
    }, [air3Images])

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMember(data)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)
                if(data.address) { setAddressNoRegis(data.address) }
                if(data.village) { setVillageRegis(data.village) }
                if(data.building) { setBuildingRegis(data.building) }
                if(data.floor) { setFloorRegis(data.floor) }
                if(data.room) { setRoomRegis(data.room) }
                if(data.subroad) { setSubroadRegis(data.subroad); }
                if(data.road) { setRoadRegis(data.road) }
                if(data.province) { setProvinceIdRegis(data.province) }
                if(data.district) { setDistrictIdRegis(data.district); }
                if(data.sub_district) { setSubDistrictIdRegis(data.sub_district) }
                setPostcodeRegis(data.postcode)
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    const deletePost = async () => {
        var promisesAuth = []
        const authTask = await deleteDoc(doc(db, `cleans`, postId))
        promisesAuth.push(authTask)
        Promise.all(promisesAuth).then(() => {
            navigate("/profile/posts/air")
        })
    }

    const handleCloseAlertDelete = () => {
        setOpenAlertDelete(false)
    }

    const getUseRegisAddress = () => {
        var promisesTask = []

        const districtTask = getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceIdRegis * 1))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        promisesTask.push(districtTask)

        const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtIdRegis))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        promisesTask.push(subdistrictTask)
        Promise.all(promisesTask).then(() => {
            setAddressNo(addressNoRegis)
            setVillage(villageRegis)
            setBuilding(buildingRegis)
            setFloor(floorRegis)
            setRoom(roomRegis)
            setSubroad(subroadRegis)
            setRoad(roadRegis)
            setProvinceId(provinceIdRegis)
            setDistrictId(districtIdRegis)
            setSubDistrictId(subdistrictIdRegis)
            setPostcode(postcodeRegis)
        })
    }

    const handleSelectProvince = (provinceId) => {
        setProvinceId(provinceId)

        setDistrictId(0)
        districtList.splice(0, districtList.length)
        setDistrictList([...districtList])

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceId*1), orderBy('province_id', 'asc'))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectDistrict = (districtId) => {
        setDistrictId(districtId)

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtId), orderBy('district_id', 'asc'))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handelSelectsubDistrict = (subdistrictId) => {
        setSubDistrictId(subdistrictId)
        setPostcode('')
        getDocs(query(collection(db, 'postcodes'), where('subdistrict_id', '==', subdistrictId*1))).then((docs) => {
            setPostcode(docs.docs[0].data().postcode)
        })
    }

    const getProvince = () => {
        getDocs(query(collection(db, 'provinces'), orderBy('province_id', 'asc'))).then((docs) => {
            setProvinceList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    const getBrand = () => {
        getDocs(query(collection(db, 'air_brands'))).then((docs) => {
            setBrandList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectBrand1 = (brandId) => {
        setBrandId1(brandId)
        getDocs(query(collection(db, 'air_models'), where('air_brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList1(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleSelectBrand2 = (brandId) => {
        setBrandId2(brandId)
        getDocs(query(collection(db, 'air_models'), where('air_brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList2(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleSelectBrand3 = (brandId) => {
        setBrandId3(brandId)
        getDocs(query(collection(db, 'air_models'), where('air_brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList3(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const handleImageAir1 = (file, i) => {
        let items = [...air1Images]
        let item = {...items[i]}
        item.url = URL.createObjectURL(file)
        item.file = file
        item.status = 2
        items[i] = item
        setAir1Images(items)
    }

    const deleteImageAir1 = (i) => {
        let items = [...air1Images]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setAir1Images(items)
    }

    const handleImageAir2 = (file, i) => {
        let items = [...air2Images]
        let item = {...items[i]}
        item.url = URL.createObjectURL(file)
        item.file = file
        item.status = 2
        items[i] = item
        setAir2Images(items)
    }

    const deleteImageAir2 = (i) => {
        let items = [...air2Images]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setAir2Images(items)
    }

    const handleImageAir3 = (file, i) => {
        let items = [...air3Images]
        let item = {...items[i]}
        item.url = URL.createObjectURL(file)
        item.file = file
        item.status = 2
        items[i] = item
        setAir3Images(items)
    }

    const deleteImageAir3 = (i) => {
        let items = [...air3Images]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setAir3Images(items)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/") 
        else  {
            getPost()
            getDocUser()
            getProvince()
            getBrand()
        }
    }, [user, loading])

    return (
    <>
        <Loading open={showLoading} text={"กำลังทำเรียกข้อมูล กรุณารอสักครู่"} />
        <Loading open={showProgressBar} text={"กำลังทำแก้ไขประกาศ กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 280 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenuPost currentPage="post" isShop={isShop} postType={5} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 10, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <h4 className='d-block d-md-none d-lg-none'>
                            <b>แก้ไขประกาศ {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} onClick={toggleDrawer(true)} >แจ้งล้างเครื่องปรับอากาศ<IoMdMenu style={{ fontSize: 25, marginLeft: 5, marginBottom: 3 }} /></font></b>
                        </h4>
                        <h4 className='d-none d-md-block d-lg-block'>
                            <b>แก้ไขประกาศ {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} >แจ้งล้างเครื่องปรับอากาศ</font></b>
                        </h4>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenuPost currentPage="post" isShop={isShop} postType={5} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div className="flex-container" style={{ marginTop: 5, marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <h4 style={{ display: 'flex', flex: 1 }}>
                                    <AiFillEdit style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                    <font style={{ fontSize: 20 }}>แก้ไขข้อมูลประกาศ</font>
                                </h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/profile/posts/airs`) }}>
                                        <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDelete(true) }}>
                                        <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบประกาศ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}><h6><strong>วันเวลาที่ต้องการใช้บริการ</strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'row' }}>
                                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                                        <DatePicker slotProps={{ textField: { size: 'small' } }} format="DD/MM/YYYY" minDate={today} defaultValue={today} onChange={(value) => { setPickDate(value)} } />
                                    </LocalizationProvider>
                                    <select name='hour' onChange={(e) => setHour(e.target.value)} style={{ width: 70, marginLeft: 10 }}>
                                        <option value="00">00</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                    </select>
                                    <select name='minute' onChange={(e) => setMinute(e.target.value)} style={{ width: 70, marginLeft: 5 }}>
                                        <option value="00">00</option>
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="45">45</option>
                                    </select>
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <font style={{ fontSize: 18 }}><input type="checkbox" checked={hasAir1?"checked":""} onChange={(e) => handleHaveAir1Change(e.target.value)} /> บริการล้างแอร์<strong>ติดผนัง</strong></font>
                            </div>
                            {hasAir1 && (<>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ยี่ห้อแอร์ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='brand_id' value={brandId1} onChange={(e) => { handleSelectBrand1(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                            {brandList?.map((brand, i) => (
                                            <option value={brand.id} key={brand.id}>
                                                {brand.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {brandIdErrorDiv1 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกยี่ห้อ</strong>
                                        </div>
                                        }
                                    </div>
                                    {brandId1 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setBrand1(e.target.value)} 
                                        value={brand1} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {brandErrorDiv1 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกยี่ห้อ</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>รุ่นแอร์ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                        <select name='model_id' value={modelId1} onChange={(e) => { setModelId1(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                            <option value="0">--- กรุณารุ่น ---</option>
                                            {modelList1?.map((model, i) => (
                                            <option value={model.id} key={model.id}>
                                                {model.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {modelIdErrorDiv1 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกรุ่น</strong>
                                        </div>
                                        }
                                    </div>
                                    {modelId1 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setModel1(e.target.value)} 
                                        value={model1} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {modelErrorDiv1 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกรุ่น</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ขนาด (BTU) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='air1BTU' onChange={(e) => setAir1BTU(e.target.value)} 
                                        value={air1BTU} placeholder='ขนาด (BTU)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                    {air1BTUErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกขนาด (BTU) และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='air1Amount' onChange={(e) => setAir1Amount(e.target.value)} 
                                        value={air1Amount} placeholder='จำนวน (เครื่อง)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                    {air1AmountErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกจำนวน (เครื่อง) และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong> </strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'row' }}>
                                    <font style={{ fontSize: 17 }}><input type="checkbox" checked={air1IsOver3M?"checked":""} onChange={(e) => handleAir1IsOver3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงไม่เกิน 3 เมตร จำนวน</font>
                                    <input type='text' name='air1Over3MAmount' onChange={(e) => setAir1Over3MAmount(e.target.value)} 
                                    value={air1Over3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginLeft: 10, marginRight: 10, display: 'flex', width: 30, fontSize:17 }} />
                                    <font style={{ fontSize: 17 }}>เครื่อง</font>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong> </strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'row' }}>
                                    <font style={{ fontSize: 17 }}><input type="checkbox" checked={air1IsBelow3M?"checked":""} onChange={(e) => handleAir1IsBelow3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงเกิน 3 เมตร จำนวน</font>
                                    <input type='text' name='air1Below3MAmount' onChange={(e) => setAir1Below3MAmount(e.target.value)} 
                                    value={air1Below3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginLeft: 10, marginRight: 10, display: 'flex', width: 30, fontSize:17 }} />
                                    <font style={{ fontSize: 17 }}>เครื่อง</font>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>แอร์มีอาการดังต่อไปนี้</strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem1?"checked":""} onChange={(e) => handleAir1IsProblem1(e.target.value)} /> บานสวิงทำงานไม่ปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem2?"checked":""} onChange={(e) => handleAir1IsProblem2(e.target.value)} /> คอยล์เย็นมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem3?"checked":""} onChange={(e) => handleAir1IsProblem3(e.target.value)} /> คอยล์ร้อนมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem4?"checked":""} onChange={(e) => handleAir1IsProblem4(e.target.value)} /> มีน้ำรั่วซึมหรือถาดน้ำทิ้งสภาพไม่ปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air1IsProblem5?"checked":""} onChange={(e) => handleAir1IsProblem5(e.target.value)} /> หน้ากากแอร์หลุดไม่ครบสภาพไม่ปกติ</font></label>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>รูปภาพคอยล์ร้อนและคอยล์เย็น <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {air1Images?.map((image, i) => (
                                        <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                            <img src={image.url} alt="WEEE" width="100" style={{marginBottom: 5}} />
                                            <div style={{ display: "flex", flexDirection:'row'}}>
                                                <label htmlFor={"fileUpload"+i} style={{ padding: 0}}>
                                                    {(image.status!=0)?
                                                    (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                                                    (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                                                    }
                                                </label>
                                                {(image.status!=0) &&
                                                (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImageAir1(i) }}>
                                                    <IoCloseCircle style={{ fontSize: 20 }} />
                                                </button>)
                                                }
                                            </div>
                                            <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImageAir1(e.target.files[0], i)} />
                                        </div>
                                        ))}
                                    </div>
                                    {imageSize1Error &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                    }
                                    {pictureAir1ErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณาเลือกรูปภาพ</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            </>)}

                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <font style={{ fontSize: 18 }}><input type="checkbox" checked={hasAir2?"checked":""} onChange={(e) => handleHaveAir2Change(e.target.value)} /> บริการล้างแอร์<strong>แขวนใต้ฝ้า</strong></font>
                            </div>
                            {hasAir2 && (<>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ยี่ห้อแอร์ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='brand_id' value={brandId2} onChange={(e) => { handleSelectBrand2(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                            {brandList?.map((brand, i) => (
                                            <option value={brand.id} key={brand.id}>
                                                {brand.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {brandIdErrorDiv2 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกยี่ห้อ</strong>
                                        </div>
                                        }
                                    </div>
                                    {brandId2 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setBrand2(e.target.value)} 
                                        value={brand2} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {brandErrorDiv2 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกยี่ห้อ</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>รุ่นแอร์ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                        <select name='model_id' value={modelId2} onChange={(e) => { setModelId2(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                            <option value="0">--- กรุณารุ่น ---</option>
                                            {modelList2?.map((model, i) => (
                                            <option value={model.id} key={model.id}>
                                                {model.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {modelIdErrorDiv2 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกรุ่น</strong>
                                        </div>
                                        }
                                    </div>
                                    {modelId2 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setModel2(e.target.value)} 
                                        value={model2} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {modelErrorDiv2 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกรุ่น</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ขนาด (BTU) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='air2BTU' onChange={(e) => setAir2BTU(e.target.value)} 
                                        value={air2BTU} placeholder='ขนาด (BTU)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                    {air2BTUErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกขนาด (BTU) และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='air2Amount' onChange={(e) => setAir2Amount(e.target.value)} 
                                        value={air2Amount} placeholder='จำนวน (เครื่อง)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                    {air2AmountErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกจำนวน (เครื่อง) และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong> </strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'row' }}>
                                    <font style={{ fontSize: 17 }}><input type="checkbox" checked={air2IsOver3M?"checked":""} onChange={(e) => handleAir2IsOver3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงไม่เกิน 3 เมตร จำนวน</font>
                                    <input type='text' name='air2Over3MAmount' onChange={(e) => setAir2Over3MAmount(e.target.value)} 
                                    value={air2Over3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginLeft: 10, marginRight: 10, display: 'flex', width: 30, fontSize:17 }} />
                                    <font style={{ fontSize: 17 }}>เครื่อง</font>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong> </strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'row' }}>
                                    <font style={{ fontSize: 17 }}><input type="checkbox" checked={air2IsBelow3M?"checked":""} onChange={(e) => handleAir2IsBelow3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงเกิน 3 เมตร จำนวน</font>
                                    <input type='text' name='air2Below3MAmount' onChange={(e) => setAir2Below3MAmount(e.target.value)} 
                                    value={air2Below3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginLeft: 10, marginRight: 10, display: 'flex', width: 30, fontSize:17 }} />
                                    <font style={{ fontSize: 17 }}>เครื่อง</font>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>แอร์มีอาการดังต่อไปนี้</strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem1?"checked":""} onChange={(e) => handleAir2IsProblem1(e.target.value)} /> บานสวิงทำงานไม่ปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem2?"checked":""} onChange={(e) => handleAir2IsProblem2(e.target.value)} /> คอยล์เย็นมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem3?"checked":""} onChange={(e) => handleAir2IsProblem3(e.target.value)} /> คอยล์ร้อนมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem4?"checked":""} onChange={(e) => handleAir2IsProblem4(e.target.value)} /> มีน้ำรั่วซึมหรือถาดน้ำทิ้งสภาพไม่ปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air2IsProblem5?"checked":""} onChange={(e) => handleAir2IsProblem5(e.target.value)} /> หน้ากากแอร์หลุดไม่ครบสภาพไม่ปกติ</font></label>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>รูปภาพคอยล์ร้อนและคอยล์เย็น <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {air2Images?.map((image, i) => (
                                        <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                            <img src={image.url} alt="WEEE" width="100" style={{marginBottom: 5}} />
                                            <div style={{ display: "flex", flexDirection:'row'}}>
                                                <label htmlFor={"fileUpload"+i} style={{ padding: 0}}>
                                                    {(image.status!=0)?
                                                    (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                                                    (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                                                    }
                                                </label>
                                                {(image.status!=0) &&
                                                (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImageAir2(i) }}>
                                                    <IoCloseCircle style={{ fontSize: 20 }} />
                                                </button>)
                                                }
                                            </div>
                                            <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImageAir2(e.target.files[0], i)} />
                                        </div>
                                        ))}
                                    </div>
                                    {imageSize2Error &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                    }
                                    {pictureAir2ErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณาเลือกรูปภาพ</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            </>)}

                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <font style={{ fontSize: 18 }}><input type="checkbox" checked={hasAir3?"checked":""} onChange={(e) => handleHaveAir3Change(e.target.value)} /> บริการล้างแอร์<strong>ฝังฝ้า 4 ทิศทาง</strong></font>
                            </div>
                            {hasAir3 && (<>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ยี่ห้อแอร์ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                    <select name='brand_id' value={brandId3} onChange={(e) => { handleSelectBrand3(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                        <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                            {brandList?.map((brand, i) => (
                                            <option value={brand.id} key={brand.id}>
                                                {brand.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {brandIdErrorDiv3 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกยี่ห้อ</strong>
                                        </div>
                                        }
                                    </div>
                                    {brandId3 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setBrand3(e.target.value)} 
                                        value={brand3} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {brandErrorDiv3 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกยี่ห้อ</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>รุ่นแอร์ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                        <select name='model_id' value={modelId3} onChange={(e) => { setModelId3(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, fontSize: 17 }}>
                                            <option value="0">--- กรุณารุ่น ---</option>
                                            {modelList3?.map((model, i) => (
                                            <option value={model.id} key={model.id}>
                                                {model.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {modelIdErrorDiv3 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                            <strong>** กรุณาเลือกรุ่น</strong>
                                        </div>
                                        }
                                    </div>
                                    {modelId3 =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setModel3(e.target.value)} 
                                        value={model3} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17, marginTop: 10 }} />
                                    )
                                    }
                                    {modelErrorDiv3 &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกรุ่น</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>ขนาด (BTU) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='air3BTU' onChange={(e) => setAir3BTU(e.target.value)} 
                                        value={air3BTU} placeholder='ขนาด (BTU)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                    {air3BTUErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกขนาด (BTU) และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>จำนวน (เครื่อง) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <input type='text' name='air3Amount' onChange={(e) => setAir3Amount(e.target.value)} 
                                        value={air3Amount} placeholder='จำนวน (เครื่อง)'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1, fontSize:17 }} />
                                    {air3AmountErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 1, display: 'flex', marginTop: 7}}>
                                            <strong>** กรุณากรอกจำนวน (เครื่อง) และเป็นตัวเลขเท่านั้น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong> </strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'row' }}>
                                    <font style={{ fontSize: 17 }}><input type="checkbox" checked={air3IsOver3M?"checked":""} onChange={(e) => handleAir3IsOver3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงไม่เกิน 3 เมตร จำนวน</font>
                                    <input type='text' name='air3Over3MAmount' onChange={(e) => setAir3Over3MAmount(e.target.value)} 
                                    value={air3Over3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginLeft: 10, marginRight: 10, display: 'flex', width: 30, fontSize:17 }} />
                                    <font style={{ fontSize: 17 }}>เครื่อง</font>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong> </strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'row' }}>
                                    <font style={{ fontSize: 17 }}><input type="checkbox" checked={air3IsBelow3M?"checked":""} onChange={(e) => handleAir3IsBelow3M(e.target.value)} /> มีคอยล์เย็นติดตั้งสูงเกิน 3 เมตร จำนวน</font>
                                    <input type='text' name='air3Below3MAmount' onChange={(e) => setAir3Below3MAmount(e.target.value)} 
                                    value={air3Below3MAmount} placeholder='จำนวน (เครื่อง)'
                                    style={{ paddingLeft: 8, marginLeft: 10, marginRight: 10, display: 'flex', width: 30, fontSize:17 }} />
                                    <font style={{ fontSize: 17 }}>เครื่อง</font>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>แอร์มีอาการดังต่อไปนี้</strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem1?"checked":""} onChange={(e) => handleAir3IsProblem1(e.target.value)} /> บานสวิงทำงานไม่ปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem2?"checked":""} onChange={(e) => handleAir3IsProblem2(e.target.value)} /> คอยล์เย็นมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem3?"checked":""} onChange={(e) => handleAir3IsProblem3(e.target.value)} /> คอยล์ร้อนมีเสียงดังหรือทำงานผิดปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem4?"checked":""} onChange={(e) => handleAir3IsProblem4(e.target.value)} /> มีน้ำรั่วซึมหรือถาดน้ำทิ้งสภาพไม่ปกติ</font></label>
                                    <label style={{ marginTop: 5, marginRight: 10 }}><font style={{ fontSize: 15 }}><input type="checkbox" checked={air3IsProblem5?"checked":""} onChange={(e) => handleAir3IsProblem5(e.target.value)} /> หน้ากากแอร์หลุดไม่ครบสภาพไม่ปกติ</font></label>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}><h6><strong>รูปภาพคอยล์ร้อนและคอยล์เย็น <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 9, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                        {air3Images?.map((image, i) => (
                                        <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                            <img src={image.url} alt="WEEE" width="100" style={{marginBottom: 5}} />
                                            <div style={{ display: "flex", flexDirection:'row'}}>
                                                <label htmlFor={"fileUpload"+i} style={{ padding: 0}}>
                                                    {(image.status!=0)?
                                                    (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                                                    (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                                                    }
                                                </label>
                                                {(image.status!=0) &&
                                                (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImageAir3(i) }}>
                                                    <IoCloseCircle style={{ fontSize: 20 }} />
                                                </button>)
                                                }
                                            </div>
                                            <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImageAir3(e.target.files[0], i)} />
                                        </div>
                                        ))}
                                    </div>
                                    {imageSize3Error &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** ขนาดรูปภาพต้องไม่เกิน 1MB</strong>
                                        </div>
                                    }
                                    {pictureAir3ErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณาเลือกรูปภาพ</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            </>)}
                            <div className="flex-container" style={{ display: 'flex', marginBottom: 10, marginTop: 10, height:400 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}><h6><strong>ตำแหน่งพิกัดที่พักอาศัย</strong></h6></label>
                                <div style={{ marginBottom: 1, flexDirection: 'column', display: 'flex', flexWrap: 'nowrap', height:400, flex: 9 }}>
                                    <div className='text-danger' style={{ marginBottom: 10, marginTop: 8, display: 'flex', flexWrap: 'nowrap' }}>
                                        ** ลาก <FaMapMarkerAlt className='text-danger' style={{ marginLeft: 5, marginRight: 5 }} /> Marker  เพื่อกำหนดตำแหน่ง
                                    </div>
                                    <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                                    <div className="mb-3 row" style={{ display: 'flex', height:400, flexDirection: 'column' }}>
                                        <div style={{  display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                            <GoogleMap mapContainerClassName="map-container" center={{ lat: lat, lng: lng }} zoom={17} >
                                                <MarkerF
                                                    draggable={true} position={center}
                                                    onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                                />
                                            </GoogleMap>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}> </label>
                                <div className="flex-container" style={{ marginBottom: 1, display: 'flex', flexWrap: 'nowrap', flex: 9 }}>
                                    <button style={{ textAlign: 'center', marginRight: 15, marginBottom: 5 }} className="btn btn-success" type='button' onClick={() => { handelGoToRegisPosition() }}>ไปยังตำแหน่งเริ่มต้น</button>
                                    <button style={{ textAlign: 'center', marginRight: 15, marginBottom: 5 }} className="btn btn-success" type='button' onClick={() => { handelGoToCurrentPosition() }}>ไปยังตำแหน่งปัจจุบัน</button>
                                </div>
                            </div>
                            <div className="" style={{ display: 'flex', marginTop: 10, flexDirection: 'column' }}>
                                <div  className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                    <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}><h6><strong>ที่อยู่</strong></h6></label>
                                    <div style={{ display: 'flex', flex: 9, width:"100%" }}>
                                        <button style={{ textAlign: 'center' }} className="btn btn-success" type='button' onClick={() => { getUseRegisAddress() }}>ใช้ที่อยู่เริ่มต้น</button>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                    <label className='d-none d-md-block d-lg-block' style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3 }}>
                                        <h6><strong> </strong></h6>
                                    </label>
                                    <div style={{ display: 'flex', flexDirection:'column', flex: 9 }}>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', flexWrap: 'wrap' }}>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='addressNo' onChange={(e) => setAddressNo(e.target.value)} 
                                                value={addressNo} placeholder='บ้านเลขที่'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='village' onChange={(e) => setVillage(e.target.value)} 
                                                value={village} placeholder='หมู่บ้าน/สถานที่'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='room' onChange={(e) => setRoom(e.target.value)} 
                                                value={room} placeholder='ห้อง'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='floor' onChange={(e) => setFloor(e.target.value)} 
                                                value={floor} placeholder='ชั้น'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='building' onChange={(e) => setBuilding(e.target.value)} 
                                                value={building} placeholder='อาคาร'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='subroad' onChange={(e) => setSubroad(e.target.value)} 
                                                value={subroad} placeholder='ซอย'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                                value={road} placeholder='ถนน'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <select name='province' value={provinceId} onChange={(e) => { handleSelectProvince(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                <option value="0">--- กรุณาเลือกจังหวัด ---</option>
                                                {provinceList?.map((province, i) => (
                                                <option value={province.data.province_id} key={province.data.province_id}>
                                                    {province.data.province_name}
                                                </option>
                                                ))}
                                            </select>
                                            {provinceIdErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                                <strong>** กรุณาเลือกจังหวัด</strong>
                                            </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <select name='district' value={districtId} onChange={(e) => { handleSelectDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                <option value="0" key="0">--- กรุณาเลือกอำเภอ ---</option>
                                                {districtList?.map((district, i) => (
                                                <option value={district.data.district_id} key={district.data.district_id}>
                                                    {district.data.district_name}
                                                </option>
                                                ))}
                                            </select>
                                            {districtIdErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                                <strong>** กรุณาเลือกอำเภอ</strong>
                                            </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <select name='subdistrict' value={subdistrictId} onChange={(e) => { handelSelectsubDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                <option value="0">--- กรุณาเลือกตำบล ---</option>
                                                {subdistrictList?.map((subdistrict, i) => (
                                                <option value={subdistrict.data.subdistrict_id} key={subdistrict.data.subdistrict_id}>
                                                    {subdistrict.data.subdistrict_name}
                                                </option>
                                                ))}
                                            </select>
                                            {subdistrictIdErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                                <strong>** กรุณาเลือกตำบล</strong>
                                            </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='postcode' readOnly
                                                value={postcode} placeholder='รหัสไปรษณีย์'
                                                style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 3}}><h6><strong>ที่อยู่เพิ่มเติม</strong></h6></label>
                                <div style={{ display: 'flex', flex: 9 }}>
                                    <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 10 }}
                                        onChange={(e) => setAddress(e.target.value)}
                                        placeholder='ที่อยู่เพิ่มเติม' 
                                        value={address}></textarea>
                                </div>
                            </div> 
                        </div>
                        <div className="flex-container d-block d-md-none d-lg-none" style={{ marginTop: 5, marginTop: 10, borderTop: '1px solid #cccccc', display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/profile/posts/airs`) }}>
                                        <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDelete(true) }}>
                                        <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบประกาศ
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog open={openAlertDelete} onClose={handleCloseAlertDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                        {"คุณต้องการลบประกาศนี้?"}
                    </DialogTitle>
                    <DialogActions>
                    <Button onClick={handleCloseAlertDelete} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                    <Button onClick={() => { deletePost() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    </>
    )
}

export default EditPostAir