import { Link } from "react-router-dom"
import { dateFormat, dateFormatTime } from '../helper/DateHelper'
import { getBoartStatus } from '../helper/PostStatusHelper'
import { AiFillCheckCircle } from "react-icons/ai"

const PostCard = ({post}) => {
    const number_format = new Intl.NumberFormat()
    const status = getBoartStatus(3, post.data.status, 'admin')

    return (
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }} key={post.id}>
            <Link className="btn btn-white" to={`/admin/post/detail/3/${post.id}`} style={{ display: 'flex', flex: 1 }}>
                <div className="card" style={{ width: "16rem", alignItems:'center', display: 'flex', flex: 1 }}>
                    {(post.data.status==1) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-primary" style={{ color: '#ffffff'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {(post.data.status==2) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-primary">
                                <strong>สถานะ:</strong> {status} <span className="badge badge-light" style={{ backgroundColor: '#ff0000'}}>{post.offer.length}</span>
                            </button>
                        </div>)
                    }
                    {(post.data.status==3) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-warning" style={{ color: '#000000'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {(post.data.status==4) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {post.data.status == 5 &&
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-danger" style={{ color: '#FFFFFF'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {(post.data.status==6) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-danger" style={{ color: '#ffffff'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    {(post.data.status==9) && 
                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                            <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}>
                                <strong>สถานะ:</strong> {status}
                            </button>
                        </div>)
                    }
                    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center' }} >
                        <img src={post.data.picture} alt="WEEE" height="150" />
                    </div>
                    <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                        <div className="card-text" style={{ textAlign: 'left' }}>
                            {post.data.type} {post.brand} {post.model}<br/>
                            <strong>รายละเอียดเพิ่มเติม:</strong> {(post.data.description).substring(0, 60)+(((post.data.description).length > 60)?"...":"")}
                        </div> 
                        <div className="card-text" style={{ textAlign: 'left' }}><strong>ปีที่ซื้อ :</strong> {post.data.buy_year?"พ.ศ. "+post.data.buy_year:"ไม่ระบุ"}</div>
                        <div className="card-text" style={{ textAlign: 'left' }}>
                            <strong>สถาพเครื่อง :</strong> 
                            {post.data.condition?"":" ไม่ระบุ"}
                            {post.data.condition==1?" มือสองเหมือนใหม่":""}
                            {post.data.condition==2?" มือสองสภาพดี":""}
                            {post.data.condition==3?" มือสองพอใช้":""}
                            {post.data.condition==4?" มือสองเก่ามาก":""}
                        </div>
                        <div className="card-text" style={{ textAlign: 'left' }}>
                            {post.data.option1?(<><h6><AiFillCheckCircle className='text-success' style={{ fontSize: 20 }} /> อยู่ในระยะประกัน </h6></>):""}
                            {post.data.option2?(<><h6><AiFillCheckCircle className='text-success' style={{ fontSize: 20 }} /> มีกล่อง </h6></>):""}
                            {post.data.option3?(<><h6><AiFillCheckCircle className='text-success' style={{ fontSize: 20 }} /> มีคู่มือการใช้งาน </h6></>):""}
                            {post.data.option4?(<><h6><AiFillCheckCircle className='text-success' style={{ fontSize: 20 }} /> เอกสารอื่นๆ </h6></>):""}
                        </div>
                        <div className="card-text" style={{ textAlign: 'left' }}><strong>ผู้ประกาศ :</strong> {post.poster.data().firstname} {post.poster.data().lastname}</div> 
                        <div className="card-text" style={{ textAlign: 'left' }}><strong>ราคาประกาศขายซาก :</strong> {number_format.format(post.data.price)} บาท</div> 
                        <div className="card-text" style={{ textAlign: 'left' }}><strong>วันเวลาที่สะดวกให้รับซาก :</strong><br/>{dateFormat(post.data.pick_date)} เวลา {post.data.hour}:{post.data.minute} น.</div> 
                        <div className="card-text" style={{ textAlign: 'left' }}><strong>วันที่ลงประกาศ:</strong><br/>{dateFormatTime(post.data.post_timestamp)}</div>
                        {(post.post_over_due && post.data.status == 1) && <div style={{ marginTop: 5, textAlign: 'left' }}><h6 className='text-danger'><strong>*** เลยวันที่กำหนดรับซากไปแล้ว {post.over_due_days} วัน ***</strong></h6></div>}
                    </div>
                </div>
            </Link>
        </div>
    )
} 

export default PostCard