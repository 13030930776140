import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, getDoc, getDocs, or, updateDoc } from "firebase/firestore"
import { db } from "../../assets/js/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillShop, AiFillInfoCircle } from "react-icons/ai";
import { BiMoneyWithdraw } from "react-icons/bi"
import { PiHandCoinsDuotone, PiCoinsDuotone } from "react-icons/pi"
import { RiExchangeFundsFill } from "react-icons/ri"
import "../../assets/css/memberList.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Dialog, DialogActions } from '@mui/material';
import { Card, CardContent, CardActions } from '@mui/material';
import { Typography, Paper, TextField } from '@mui/material'

const Coins = () => {
    const navigate = useNavigate();
    const params = useParams()
    const number_format = new Intl.NumberFormat();

    const [allFreeCoin, setAllFreeCoin] = useState(0)
    const [remainFreeCoin, setRemainFreeCoin] = useState(0)
    const [givedFreeCoin, setGivedFreeCoin] = useState(0)
    const [usedFreeCoin, setUsedFreeCoin] = useState(0)
    const [allExchangeCoin, setAllExchangeCoin] = useState(0)
    const [remainExchangeCoin, setRemainExchangeCoin] = useState(0)
    const [exchangeExchangeCoin, setExchangeExchangeCoin] = useState(0)
    const [usedExchangeCoin, setUsedExchangeCoin] = useState(0)

    const [openBoard1Coin, setOpenBoard1Coin] = useState(false)
    const [board1_1Coin, setBoard1_1Coin] = useState(0)
    const [board1_2Coin, setBoard1_2Coin] = useState(0)

    const [openBoard2Coin, setOpenBoard2Coin] = useState(false)
    const [board2_1Coin, setBoard2_1Coin] = useState(0)
    const [board2_2Coin, setBoard2_2Coin] = useState(0)
    const [board2_3Coin, setBoard2_3Coin] = useState(0)

    const [openBoard3Coin, setOpenBoard3Coin] = useState(false)
    const [board3_1Coin, setBoard3_1Coin] = useState(0)
    const [board3_2Coin, setBoard3_2Coin] = useState(0)
    const [board3_3Coin, setBoard3_3Coin] = useState(0)
    const [board3_4Coin, setBoard3_4Coin] = useState(0)

    const [openBoard4Coin, setOpenBoard4Coin] = useState(false)
    const [board4_1Coin, setBoard4_1Coin] = useState(0)
    const [board4_2Coin, setBoard4_2Coin] = useState(0)
    const [board4_3Coin, setBoard4_3Coin] = useState(0)
    const [board4_4Coin, setBoard4_4Coin] = useState(0)

    const [openBoard5Coin, setOpenBoard5Coin] = useState(false)
    const [board5_1Coin, setBoard5_1Coin] = useState(0)
    const [board5_2Coin, setBoard5_2Coin] = useState(0)
    const [board5_3Coin, setBoard5_3Coin] = useState(0)
    const [board5_4Coin, setBoard5_4Coin] = useState(0)

    const [openBoard6Coin, setOpenBoard6Coin] = useState(false)
    const [board6_1Coin, setBoard6_1Coin] = useState(0)
    const [board6_2Coin, setBoard6_2Coin] = useState(0)
    const [board6_3Coin, setBoard6_3Coin] = useState(0)
    const [board6_4Coin, setBoard6_4Coin] = useState(0)

    const [openBoard7Coin, setOpenBoard7Coin] = useState(false)
    const [board7_1Coin, setBoard7_1Coin] = useState(0)
    const [board7_2Coin, setBoard7_2Coin] = useState(0)
    const [board7_3Coin, setBoard7_3Coin] = useState(0)
    const [board7_4Coin, setBoard7_4Coin] = useState(0)
    const [board7_5Coin, setBoard7_5Coin] = useState(0)
    
    const [moneyToCoin1, setMoneyToCoin1] = useState(0)
    const [moneyToCoin2, setMoneyToCoin2] = useState(0)
    const [coinToMoney1, setCoinToMoney1] = useState(0)
    const [coinToMoney2, setCoinToMoney2] = useState(0)

    const handleBoard1Coin = () => {
        updateDoc(doc(db, 'coins', 'use_rate'), {
            board1_1: board1_1Coin*1,
            board1_2: board1_2Coin*1
        }).then(() => {
            setOpenBoard1Coin(false)
            navigate(0)
        })
    }

    const handleCloseBoard1Coin = () => {
        setOpenBoard1Coin(false)
    }

    const handleBoard2Coin = () => {
        updateDoc(doc(db, 'coins', 'use_rate'), {
            board2_1: board2_1Coin*1,
            board2_2: board2_2Coin*1,
            board2_3: board2_3Coin*1
        }).then(() => {
            setOpenBoard2Coin(false)
            navigate(0)
        })
    }

    const handleCloseBoard2Coin = () => {
        setOpenBoard2Coin(false)
    }

    const handleBoard3Coin = () => {
        updateDoc(doc(db, 'coins', 'use_rate'), {
            board3_1: board3_1Coin*1,
            board3_2: board3_2Coin*1,
            board3_3: board3_3Coin*1,
            board3_4: board3_4Coin*1
        }).then(() => {
            setOpenBoard3Coin(false)
            navigate(0)
        })
    }

    const handleCloseBoard3Coin = () => {
        setOpenBoard3Coin(false)
    }

    const handleBoard4Coin = () => {
        updateDoc(doc(db, 'coins', 'use_rate'), {
            board4_1: board4_1Coin*1,
            board4_2: board4_2Coin*1,
            board4_3: board4_3Coin*1,
            board4_4: board4_4Coin*1
        }).then(() => {
            setOpenBoard4Coin(false)
            navigate(0)
        })
    }

    const handleCloseBoard4Coin = () => {
        setOpenBoard4Coin(false)
    }

    const handleBoard5Coin = () => {
        updateDoc(doc(db, 'coins', 'use_rate'), {
            board5_1: board5_1Coin*1,
            board5_2: board5_2Coin*1,
            board5_3: board5_3Coin*1,
            board5_4: board5_4Coin*1
        }).then(() => {
            setOpenBoard5Coin(false)
            navigate(0)
        })
    }

    const handleCloseBoard5Coin = () => {
        setOpenBoard5Coin(false)
    }

    const handleBoard6Coin = () => {
        updateDoc(doc(db, 'coins', 'use_rate'), {
            board6_1: board6_1Coin*1,
            board6_2: board6_2Coin*1,
            board6_3: board6_3Coin*1,
            board6_4: board6_4Coin*1
        }).then(() => {
            setOpenBoard6Coin(false)
            navigate(0)
        })
    }

    const handleCloseBoard6Coin = () => {
        setOpenBoard6Coin(false)
    }

    const handleBoard7Coin = () => {
        updateDoc(doc(db, 'coins', 'use_rate'), {
            board7_1: board7_1Coin*1,
            board7_2: board7_2Coin*1,
            board7_3: board7_3Coin*1,
            board7_4: board7_4Coin*1,
            board7_5: board7_5Coin*1
        }).then(() => {
            setOpenBoard7Coin(false)
            navigate(0)
        })
    }

    const handleCloseBoard7Coin = () => {
        setOpenBoard7Coin(false)
    }

    const getFreeCoins = () => {
        getDoc(doc(db, 'free_coins', 'sum_data')).then((freeSnapshot) => {
            const data = freeSnapshot.data()
            setAllFreeCoin(data.all*1)
            setRemainFreeCoin(data.remain*1)
            setGivedFreeCoin(data.gived*1)
            setUsedFreeCoin(data.used*1)
        })
    }

    const getExchangeCoins = () => {
        getDoc(doc(db, 'exchange_coins', 'sum_data')).then((exchangeSnapshot) => {
            const data = exchangeSnapshot.data()
            setAllExchangeCoin(data.all)
            setRemainExchangeCoin(data.remain)
            setExchangeExchangeCoin(data.exchange)
            setUsedExchangeCoin(data.used)
        })
    }

    const getExchangeRate = () => {
        getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeSnapshot) => {
            const data = exchangeSnapshot.data()
            setMoneyToCoin1(data.money_to_coin_1)
            setMoneyToCoin2(data.money_to_coin_2)
            setCoinToMoney1(data.coin_to_money_1)
            setCoinToMoney2(data.coin_to_money_2)
        })
    }

    const getUseRate = () => {
        getDoc(doc(db, 'coins', 'use_rate')).then((exchangeSnapshot) => {
            const data = exchangeSnapshot.data()
            setBoard1_1Coin(data.board1_1*1)
            setBoard1_2Coin(data.board1_2*1)

            setBoard2_1Coin(data.board2_1*1)
            setBoard2_2Coin(data.board2_2*1)
            setBoard2_3Coin(data.board2_3*1)

            setBoard3_1Coin(data.board3_1*1)
            setBoard3_2Coin(data.board3_2*1)
            setBoard3_3Coin(data.board3_3*1)
            setBoard3_4Coin(data.board3_4*1)

            setBoard4_1Coin(data.board4_1*1)
            setBoard4_2Coin(data.board4_2*1)
            setBoard4_3Coin(data.board4_3*1)
            setBoard4_4Coin(data.board4_4*1)

            setBoard5_1Coin(data.board5_1*1)
            setBoard5_2Coin(data.board5_2*1)
            setBoard5_3Coin(data.board5_3*1)
            setBoard5_4Coin(data.board5_4*1)

            setBoard6_1Coin(data.board6_1*1)
            setBoard6_2Coin(data.board6_2*1)
            setBoard6_3Coin(data.board6_3*1)
            setBoard6_4Coin(data.board6_4*1)

            setBoard7_1Coin(data.board7_1*1)
            setBoard7_2Coin(data.board7_2*1)
            setBoard7_3Coin(data.board7_3*1)
            setBoard7_4Coin(data.board7_4*1)
            setBoard7_5Coin(data.board7_5*1)
        })
    }

    useEffect(() => {
        getFreeCoins()
        getExchangeCoins()
        getUseRate()
        getExchangeRate()
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><RiExchangeFundsFill style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>ข้อมูลเหรียญ WEEE</b></h4>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                <Paper elevation={2} style={{ display: 'flex', flex: 2, minHeight: 250, marginRight: 10, flexDirection: 'column' }}>
                    <div className='bg-primary' style={{ height: 50, backgroundColor: "#dddddd", paddingTop: 8, paddingLeft: 20}}>
                        <h3 className='text-white'><strong><PiCoinsDuotone style={{ marginRight: 8 }} />เหรียญเงิน</strong></h3>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                        <div><h4><strong>จำนวนเหรียญคงเหลือ/ทั้งหมดในระบบ</strong></h4></div>
                        <div style={{ paddingLeft: 10, marginTop: -20 }}><font style={{ fontSize: 40, marginRight: 15 }}>{number_format.format(remainFreeCoin)}/{number_format.format(allFreeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                        <div><h4><strong>จำนวนเหรียญที่แจก</strong></h4></div>
                        <div style={{ paddingLeft: 10, marginTop: -20 }}><font style={{ fontSize: 40, marginRight: 15 }}>{number_format.format(givedFreeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                        <div><h4><strong>จำนวนเหรียญที่ใช้ไปแล้ว</strong></h4></div>
                        <div style={{ paddingLeft: 10, marginTop: -20 }}><font style={{ fontSize: 40, marginRight: 15 }}>{number_format.format(usedFreeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font></div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 25, marginBottom: 15, float: 'right'}}>
                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { navigate(`/admin/coins/free/`) }}>
                            <AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูลเพิ่มเติม
                        </button>
                    </div>
                </Paper>
                <Paper elevation={2} style={{ display: 'flex', flex: 3, minHeight: 250, marginLeft: 10, flexDirection: 'column' }}>
                    <div className='bg-success' style={{ height: 50, backgroundColor: "#dddddd", paddingTop: 8, paddingLeft: 20}}>
                        <h3 className='text-white'><strong><PiCoinsDuotone style={{ marginRight: 8 }} />เหรียญทอง</strong></h3>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1}}>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                                <div><h4><strong>จำนวนเหรียญคงเหลือ/ทั้งหมดในระบบ</strong></h4></div>
                                <div style={{ paddingLeft: 10, marginTop: -20 }}><font style={{ fontSize: 40, marginRight: 15 }}>{number_format.format(remainExchangeCoin)}/{number_format.format(allExchangeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                                <div><h4><strong>จำนวนเหรียญที่ถูกแลก</strong></h4></div>
                                <div style={{ paddingLeft: 10, marginTop: -20 }}><font style={{ fontSize: 40, marginRight: 15 }}>{number_format.format(exchangeExchangeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                                <div><h4><strong>จำนวนเหรียญที่ใช้ไปแล้ว</strong></h4></div>
                                <div style={{ paddingLeft: 10, marginTop: -20 }}><font style={{ fontSize: 40, marginRight: 15 }}>{number_format.format(usedExchangeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font></div>
                            </div>
                        </div>
                        <div style={{ borderLeft:'1px solid #dddddd', minWidth: 250, paddingLeft: 20, marginTop: 15, display: 'flex', flexDirection:'column'}}>
                            <div><h4><strong>อัตราแลกเปลี่ยน</strong></h4></div>
                            <div><h6><strong>เงิน (บาท) -{'>'} เหรียญ</strong></h6></div>
                            <div><h5>{number_format.format(moneyToCoin1)} บาท -{'>'} {number_format.format(moneyToCoin2)} เหรียญ</h5></div>
                            <div><h6><strong>เหรียญ -{'>'} เงิน (บาท)</strong></h6></div>
                            <div><h5>{number_format.format(coinToMoney1)} เหรียญ -{'>'} {number_format.format(coinToMoney2)} บาท</h5></div>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 25, marginBottom: 15, float: 'right'}}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { navigate(`/admin/coins/exchange/`) }}>
                            <AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูลเพิ่มเติม
                        </button>
                    </div>
                </Paper>
            </div>
            <div style={{ marginBottom: 10, paddingTop: 15, borderTop: '1px solid #cccccc', borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><PiHandCoinsDuotone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>ตั้งค่าการใช้เหรียญ WEEE</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                <Card variant="outlined" style={{ marginBottom: 15, minHeight: 300, marginLeft: 5, marginRight: 5, flex: 1 }}>
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
                                <Typography variant="h6" component="div" className='text-primary' style={{ fontFamily: 'Prompt', fontWeight: 'bold', flex: 1 }}>
                                    ประชาชน
                                </Typography>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenBoard1Coin(true) }}>
                                        <BiMoneyWithdraw style={{ fontSize: 20, marginRight: 5 }} />แก้ไขจำนวนเหรียญ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าทิ้งซากเครื่องใช้ไฟฟ้า
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ตัดเมื่อร้าน/บริษัท<br/>แจ้งความต้องการรับซากฯ
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board1_1Coin)} เหรียญ</strong></div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ marginBottom: 15, minHeight: 300, marginLeft: 5, marginRight: 5, flex: 1 }}>
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
                                <Typography variant="h6" component="div" className='text-primary' style={{ fontFamily: 'Prompt', fontWeight: 'bold', flex: 1 }}>
                                    ประชาชน
                                </Typography>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenBoard2Coin(true) }}>
                                        <BiMoneyWithdraw style={{ fontSize: 20, marginRight: 5 }} />แก้ไขจำนวนเหรียญ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าขายซากเครื่องใช้ไฟฟ้า
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ตัดเมื่อประชาชน<br/>ประกาศขายซากฯ
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board2_1Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ตัดเมื่อร้าน/บริษัท<br/>แจ้งความต้องการซื้อซากฯ
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board2_2Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row'}}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ตัดเมื่อประชาชน<br/>ได้รับเหรียญจากการขายซากฯ
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board2_3Coin)} เหรียญ</strong></div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ marginBottom: 15, minHeight: 300, marginLeft: 5, marginRight: 5, flex: 1 }}>
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
                                <Typography variant="h6" component="div" className='text-primary' style={{ fontFamily: 'Prompt', fontWeight: 'bold', flex: 1 }}>
                                    ประชาชน
                                </Typography>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenBoard3Coin(true) }}>
                                        <BiMoneyWithdraw style={{ fontSize: 20, marginRight: 5 }} />แก้ไขจำนวนเหรียญ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าขายเครื่องใช้ไฟฟ้ามือสอง
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อประชาชน<br/>ประกาศขายเครื่องใช้ไฟฟ้าฯ
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board3_1Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อร้าน/บริษัท<br/>แจ้งความต้องการซื้อฯ
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board3_2Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ค่าร้อยละของราคาขายค่าธรรมเนียมที่ตัด<br/> เมื่อผู้ขายได้รับเหรียญจากการขายฯ
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>ร้อยละ {number_format.format(board3_3Coin)}</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ค่าปรับที่ไม่ส่งสินค้าตามกำหนด
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board3_4Coin)} เหรียญ</strong></div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                <Card variant="outlined" style={{ marginBottom: 15, minHeight: 300, marginLeft: 5, marginRight: 5, flex: 1 }}>
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
                                <Typography variant="h6" component="div" className='text-success' style={{ fontFamily: 'Prompt', fontWeight: 'bold', flex: 1 }}>
                                    ร้านค้า/บริษัท
                                </Typography>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenBoard7Coin(true) }}>
                                        <BiMoneyWithdraw style={{ fontSize: 20, marginRight: 5 }} />แก้ไขจำนวนเหรียญ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าขายเครื่องใช้ไฟฟ้ามือสอง
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อร้านค้า/บริษัท<br/>ประกาศขายเครื่องใช้ไฟฟ้าฯ
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board7_1Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อประชาชนจองสินค้า
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board7_2Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อร้านค้า/บริษัท<br/>บันทึกสินค้าติดจองแล้ว
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board7_3Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ค่าร้อยละของราคาขายค่าธรรมเนียมที่ตัด<br/>เมื่อผู้ขายได้รับเหรียญจากการขายฯ
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>ร้อยละ {number_format.format(board7_4Coin)}</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    จำนวนเหรียญค่าปรับที่ไม่ส่งสินค้าตามกำหนด
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board7_5Coin)} เหรียญ</strong></div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ marginBottom: 15, minHeight: 300, marginLeft: 5, marginRight: 5, flex: 1 }}>
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
                                <Typography variant="h6" component="div" className='text-success' style={{ fontFamily: 'Prompt', fontWeight: 'bold', flex: 1 }}>
                                    ร้านค้า/บริษัท
                                </Typography>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenBoard4Coin(true) }}>
                                        <BiMoneyWithdraw style={{ fontSize: 20, marginRight: 5 }} />แก้ไขจำนวนเหรียญ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ซ่อมเครื่องใช้ไฟฟ้า
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อประกาศแจ้งซ่อม<br/>(ตัดจากประชาชน)
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board4_1Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อแจ้งความต้องการรับงาน<br/>(ตัดจากร้านค้า/บริษัท)
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board4_2Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อการซ่อมเสร็จสิ้น<br/>ประชาชนยืนยันเสร็จสิ้นงานซ่อม<br/>(ตัดจากร้านค้า/บริษัท)
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board4_3Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ค่าปรับทิ้งงาน
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board4_4Coin)} เหรียญ</strong></div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                <Card variant="outlined" style={{ marginBottom: 15, minHeight: 300, marginLeft: 5, marginRight: 5, flex: 1 }}>
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
                                <Typography variant="h6" component="div" className='text-success' style={{ fontFamily: 'Prompt', fontWeight: 'bold', flex: 1 }}>
                                    ร้านค้า/บริษัท
                                </Typography>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenBoard5Coin(true) }}>
                                        <BiMoneyWithdraw style={{ fontSize: 20, marginRight: 5 }} />แก้ไขจำนวนเหรียญ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            บริการล้างแอร์
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อประกาศแจ้งใช้บริการ<br/>(ตัดจากประชาชน)
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board5_1Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อแจ้งความต้องการให้บริการ<br/>(ตัดจากร้านค้า/บริษัท)
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board5_2Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อการให้บริการเสร็จแล้ว<br/>(ตัดจากร้านค้า/บริษัท)
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board5_3Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ค่าปรับทิ้งงาน
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board5_4Coin)} เหรียญ</strong></div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
                <Card variant="outlined" style={{ marginBottom: 15, minHeight: 300, marginLeft: 5, marginRight: 5, flex: 1 }}>
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <div style={{ display: 'flex', marginBottom: 10 }}>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row'}}>
                                <Typography variant="h6" component="div" className='text-success' style={{ fontFamily: 'Prompt', fontWeight: 'bold', flex: 1 }}>
                                    ร้านค้า/บริษัท
                                </Typography>
                                <div style={{ alignSelf: 'flex-end' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ display: 'flex' }} onClick={() => { setOpenBoard6Coin(true) }}>
                                        <BiMoneyWithdraw style={{ fontSize: 20, marginRight: 5 }} />แก้ไขจำนวนเหรียญ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            บริการล้างเครื่องซักผ้า
                        </Typography>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingRight: 5 }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อประกาศแจ้งใช้บริการ<br/>(ตัดจากประชาชน)
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board6_1Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อแจ้งความต้องการให้บริการ<br/>(ตัดจากร้านค้า/บริษัท)
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board6_2Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ที่ตัดเมื่อการให้บริการเสร็จแล้ว<br/>(ตัดจากร้านค้า/บริษัท)
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board6_3Coin)} เหรียญ</strong></div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, paddingRight: 10 }}>
                                    ค่าปรับทิ้งงาน
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}><strong>{number_format.format(board6_4Coin)} เหรียญ</strong></div>
                            </div>
                        </div>
                    </CardContent>
                </Card>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                
            </div>
            <Dialog open={openBoard1Coin} onClose={handleCloseBoard1Coin}>
                <Card variant="outlined">
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าประชาชนทิ้งซากเครื่องใช้ไฟฟ้า
                        </Typography>
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อร้าน/บริษัทแจ้งความต้องการรับซากฯ (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={board1_1Coin}
                            onChange={(e) => setBoard1_1Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        {/* <TextField
                            autoFocus
                            margin="dense"
                            label="จำนวนแต้มสะสมที่ให้เมื่อประชาชนที่ทิ้งซากฯ (สำเร็จทุกขั้นตอน)"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board1_2Coin}
                            onChange={(e) => setBoard1_2Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        /> */}
                    </CardContent>
                    <DialogActions>
                        <button onClick={handleCloseBoard1Coin} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                        <button onClick={handleBoard1Coin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                    </DialogActions>
                </Card>
            </Dialog>

            <Dialog open={openBoard2Coin} onClose={handleCloseBoard2Coin}>
                <Card variant="outlined">
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าประชาชนขายซากเครื่องใช้ไฟฟ้า
                        </Typography>
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อประชาชนประกาศขายซากฯ (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={board2_1Coin}
                            onChange={(e) => setBoard2_1Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อร้าน/บริษัทแจ้งความต้องการซื้อซากฯ (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board2_2Coin}
                            onChange={(e) => setBoard2_2Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อประชาชนได้รับเหรียญจากการขายซากฯ (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board2_3Coin}
                            onChange={(e) => setBoard2_3Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                    </CardContent>
                    <DialogActions>
                        <button onClick={handleCloseBoard2Coin} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                        <button onClick={handleBoard2Coin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                    </DialogActions>
                </Card>
            </Dialog>

            <Dialog open={openBoard3Coin} onClose={handleCloseBoard3Coin}>
                <Card variant="outlined">
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าประชาชนขายเครื่องใช้ไฟฟ้ามือสอง
                        </Typography>
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อประชาชนประกาศขายเครื่องใช้ไฟฟ้าฯ (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={board3_1Coin}
                            onChange={(e) => setBoard3_1Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อร้าน/บริษัทแจ้งความต้องการซื้อฯ (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board3_2Coin}
                            onChange={(e) => setBoard3_2Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>ร้อยละของราคาขายค่าธรรมเนียมที่ตัดเมื่อผู้ขายได้รับเหรียญจากการขายฯ (ร้อยละ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board3_3Coin}
                            onChange={(e) => setBoard3_3Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญค่าปรับที่ไม่ส่งสินค้าตามกำหนด (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board3_4Coin}
                            onChange={(e) => setBoard3_4Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                    </CardContent>
                    <DialogActions>
                        <button onClick={handleCloseBoard3Coin} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                        <button onClick={handleBoard3Coin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                    </DialogActions>
                </Card>
            </Dialog>

            <Dialog open={openBoard4Coin} onClose={handleCloseBoard4Coin}>
                <Card variant="outlined">
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าร้านค้า/บริษัทซ่อมเครื่องใช้ไฟฟ้า
                        </Typography>
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อประกาศแจ้งใช้บริการ(ตัดจากประชาชน) (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={board4_1Coin}
                            onChange={(e) => setBoard4_1Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อแจ้งความต้องการรับงาน (ตัดจากร้านค้า/บริษัท) (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board4_2Coin}
                            onChange={(e) => setBoard4_2Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อการซ่อมเสร็จสิ้น ประชาชนยืนยันเสร็จสิ้นงานซ่อม (ตัดจากร้านค้า/บริษัท) (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board4_3Coin}
                            onChange={(e) => setBoard4_3Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญค่าปรับที่ทิ้งงาน (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board4_4Coin}
                            onChange={(e) => setBoard4_4Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                    </CardContent>
                    <DialogActions>
                        <button onClick={handleCloseBoard4Coin} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                        <button onClick={handleBoard4Coin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                    </DialogActions>
                </Card>
            </Dialog>

            <Dialog open={openBoard5Coin} onClose={handleCloseBoard5Coin}>
                <Card variant="outlined">
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าร้านค้า/บริษัทบริการล้างแอร์
                        </Typography>
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อประกาศแจ้งใช้บริการ(ตัดจากประชาชน) (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={board5_1Coin}
                            onChange={(e) => setBoard5_1Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อแจ้งความต้องการให้บริการ (ตัดจากร้านค้า/บริษัท) (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board5_2Coin}
                            onChange={(e) => setBoard5_2Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อการให้บริการเสร็จแล้ว (ตัดจากร้านค้า/บริษัท) (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board5_3Coin}
                            onChange={(e) => setBoard5_3Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญค่าปรับที่ทิ้งงาน (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board5_4Coin}
                            onChange={(e) => setBoard5_4Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                    </CardContent>
                    <DialogActions>
                        <button onClick={handleCloseBoard5Coin} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                        <button onClick={handleBoard5Coin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                    </DialogActions>
                </Card>
            </Dialog>

            <Dialog open={openBoard6Coin} onClose={handleCloseBoard6Coin}>
                <Card variant="outlined">
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าร้านค้า/บริษัทบริการล้างเครื่องซักผ้า
                        </Typography>
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อประกาศแจ้งใช้บริการ(ตัดจากประชาชน) (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={board6_1Coin}
                            onChange={(e) => setBoard6_1Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อแจ้งความต้องการให้บริการ (ตัดจากร้านค้า/บริษัท) (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board6_2Coin}
                            onChange={(e) => setBoard6_2Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อการให้บริการเสร็จแล้ว (ตัดจากร้านค้า/บริษัท) (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board6_3Coin}
                            onChange={(e) => setBoard6_3Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญค่าปรับที่ทิ้งงาน (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board6_4Coin}
                            onChange={(e) => setBoard6_4Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                    </CardContent>
                    <DialogActions>
                        <button onClick={handleCloseBoard6Coin} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                        <button onClick={handleBoard6Coin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                    </DialogActions>
                </Card>
            </Dialog>

            <Dialog open={openBoard7Coin} onClose={handleCloseBoard7Coin}>
                <Card variant="outlined">
                    <CardContent style={{ fontFamily: 'Prompt' }}>
                        <Typography gutterBottom variant="h5" component="div" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                            ตั้งค่าร้านค้า/บริษัทขายเครื่องใช้ไฟฟ้ามือสอง
                        </Typography>
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อร้านค้า/บริษัทประกาศขายเครื่องใช้ไฟฟ้าฯ (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            fullWidth
                            variant="standard"
                            value={board7_1Coin}
                            onChange={(e) => setBoard7_1Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อประชาชนจองสินค้า (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board7_2Coin}
                            onChange={(e) => setBoard7_2Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญที่ตัดเมื่อร้านค้า/บริษัทบันทึกสินค้าติดจองแล้ว (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board7_3Coin}
                            onChange={(e) => setBoard7_3Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>ร้อยละของราคาขายค่าธรรมเนียมที่ตัดเมื่อผู้ขายได้รับเหรียญจากการขายฯ (ร้อยละ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board7_4Coin}
                            onChange={(e) => setBoard7_4Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                        <div><h5>จำนวนเหรียญค่าปรับที่ไม่ส่งสินค้าตามกำหนด (เหรียญ)</h5></div>
                        <TextField
                            autoFocus
                            margin="dense"
                            type="number"
                            InputLabelProps={{ fontFamily: 'Prompt' }}
                            fullWidth
                            variant="standard"
                            value={board7_5Coin}
                            onChange={(e) => setBoard7_5Coin(e.target.value)}
                            style={{ fontFamily: 'Prompt' }}
                        />
                    </CardContent>
                    <DialogActions>
                        <button onClick={handleCloseBoard7Coin} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                        <button onClick={handleBoard7Coin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                    </DialogActions>
                </Card>
            </Dialog>
        </div>
    )
}

export default Coins;