import React, { useState, useEffect } from 'react'
import { db } from "../../assets/js/firebase"
import { collection, addDoc, updateDoc, doc, getDoc, query, onSnapshot } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from 'react-icons/ai'

import { calScoreShop } from '../../../assets/js/ScoreHelper'
import { depositPercent, remainPercent } from '../../../assets/constants/coin'

export function AppointmentWash({appointment}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(true)

    const [userCoin, setUserCoin] = useState(0)

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState('')

    const [coinRate, setCoinRate] = useState(1)
    const [depositCoin, setDepositCoin] = useState(0)
    const [remainCoin, setRemainCoin] = useState(0)
    const [depositCoinNotRound, setDepositCoinNotRound] = useState(0)
    const [remainCoinNotRound, setRemainCoinNotRound] = useState(0)
    const [depositEnough, setDepositEnough] = useState(false)
    const [depositMessage, setDepositMessage] = useState('')
    const [remainEnough, setRemainEnough] = useState(false)
    const [remainMessage, setRemainMessage] = useState('')

    const board = 'wash'
    const appointmentId = appointment.id
    const postId = appointment.data.post_id
    const shopId = appointment.data.shop_id
    const postOfferId = appointment.data.offer_id
    const shopOfferId = appointment.data.shop_offer_id
    const userId = appointment.post.poster_id

    const offerPrice = (appointment.data.fix_price * 1)
    if(appointment.offer.offer_price_wash1) { offerPrice += appointment.offer.offer_price_wash1 * 1 }
    if(appointment.offer.offer_price_wash2) { offerPrice += appointment.offer.offer_price_wash2 * 1 }

    var badge = { message: '', class: 'success', color: '#000000', show: false }
    if(appointment.data.status == 1) {
        if(!appointment.checkReportBefore) {
            badge = { message: 'รอสำรวจหน้างาน', class: 'warning', color: '#000000', show: true }
        } else {
            badge = { message: 'รอยืนยันให้บริการ', class: 'warning', color: '#000000', show: true }
        }
    } else if(appointment.data().status == 2) {
        badge = { message: 'รอตัดสินใจใช้บริการ', class: 'warning', color: '#000000', show: true }
    } else if(appointment.data().status == 3) {
        badge = { message: 'ตกลงใช้บริการ', class: 'success', color: '#ffffff', show: true }
    } else if(appointment.data().status == 4) {
        badge = { message: 'ช่างยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 5) {
        badge = { message: 'ผู้เรียกยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 6) {
    } else if(appointment.data().status == 7) {
    } else if(appointment.data().status == 8) {
        badge = { message: 'รอรับมอบงาน', class: 'warning', color: '#000000', show: true }
    } else if(appointment.data().status == 9) {
        badge = { message: 'รับมอบงาน', class: 'succes', color: '#ffffff', show: true }
    } else if(appointment.data().status == 10) {
        badge = { message: 'ช่างยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 11) {
        badge = { message: 'ไม่รับมอบงาน', class: 'danger', color: '#ffffff', show: true }
    } else if(appointment.data().status == 12) {
        badge = { message: 'โอนเหรียญเรียบร้อย', class: 'success', color: '#ffffff', show: true }
    }

    getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeSnapshot) => {
        const rate = exchangeSnapshot.data().money_to_coin_1 * 1
        setCoinRate(rate)
    })

    getDoc(doc(db, 'users', userId)).then((docs) => {
        if(docs.docs[0].exchange_coin) setUserCoin(docs.docs[0].exchange_coin)
    })

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const beforeReport = appointment.checkReportBefore
    const afterReport = appointment.checkReportAfter
    const [reportImages, setReportImages] = useState([])
    onSnapshot(query(collection(db, `appointments/${appointmentId}/report/after/images`)), (querySnapshot) => {
        setReportImages(querySnapshot.docs.map((doc, i) => { 
            return {
                id: doc.id,
                data: doc.data(),
            }
        }))
    })

    const [openConfirmJob, setOpenConfirmJob] = useState(false)
    const [openCancleJob, setOpenCancleJob] = useState(false)
    const [openFinishJob, setOpenFinishJob] = useState(false)
    const [openConfirm, setOpenConfirm] = useState(false)
    const [openShowBefore, setOpenShowBefore] = useState(false)
    const [openCancle, setOpenCancle] = useState(false)
    const [openShowReportAfter, setOpenShowReportAfter] = useState(false)
    
    const handleOpenShowBefore = () => { setOpenShowBefore(true) }

    const handleCloseShowBefore = () => { setOpenShowBefore(false) }

    const handleOpenConfirm = () => {
        var depositCoinNotRound = Math.ceil(offerPrice * depositPercent)/coinRate
        var depositCoin = Math.round(depositCoinNotRound)

        setDepositCoin(depositCoin)
        setRemainCoin(remainCoin)
        setDepositCoinNotRound(depositCoinNotRound)
        setRemainCoinNotRound(remainCoinNotRound)

        if(depositCoin <= userCoin) { 
            setDepositEnough(true) 
            setDepositMessage('คุณมีเหรียญซื้อ '+number_format.format(userCoin)+' เหรียญ คุณจะใช้ '+number_format.format(depositCoin)+' เหรียญ ('+(depositPercent*100)+'% ของราคาค่าซ่อม) เพื่อยืนยันการซ่อมเครื่องใช้ไฟฟ้า?')
        } else { 
            setDepositEnough(false) 
            setDepositMessage('คุณมีจำนวนเหรียญซื้อไม่พอ คุณมี '+number_format.format(userCoin)+' เหรียญ คุณต้องใช้ '+number_format.format(depositCoin)+' เหรียญ (('+(depositPercent*100)+'% ของราคาบริการซ่อมเครื่องใช้ไฟฟ้า)')
        }

        setOpenConfirm(true)
    }

    const handleCloseConfirm = () => {
        setDepositEnough(false) 
        setDepositMessage('')
        setOpenConfirm(false)
    }

    const handleConfirm = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            deposit_coin: depositCoin,
            remain_coin: remainCoin,
            deposit_coin_not_round: depositCoinNotRound,
            remain_coin_not_round: remainCoinNotRound,
            user_confirm_datetime: logDate.getTime(),
            status: 3,
        }).then(() => {
            getDoc(doc(db, 'exchange_coins', 'sum_data')).then((freeSnapshot) => {
                const data = freeSnapshot.data()
                var promisesTask = []
                var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
                    coin: depositCoin*1,
                    date: logDate.getTime(),
                    event_type: 'deposit',
                    event: 'มัดจำ 30% ของค่าบริการล้างเครื่องซักผ้า',
                    type: 'increase',
                    post_type: 'wash',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addCoinLogTask)
                var updateCoinTask = updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                    all: (data.all*1)+(depositCoin*1)
                })
                promisesTask.push(updateCoinTask)
                var updateUserCoinTask = updateDoc(doc(db, 'users/', userId), {
                    exchange_coin: (userCoin*1)-(depositCoin*1)
                })
                promisesTask.push(updateUserCoinTask)
                var addUserCoinLogTask = addDoc(collection(db, `users/${userId}/coin_log/`), {
                    coin: depositCoin*1,
                    date: logDate.getTime(),
                    event_type: 'deposit',
                    event: 'มัดจำ 30% ของค่าบริการล้างเครื่องซักผ้า',
                    coin_type: 'exchange',
                    post_type: 'wash',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addUserCoinLogTask)
                
                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const handleOpenFinishJob = () => {
        var remainCoinNotRound = Math.ceil(offerPrice * remainPercent)/coinRate
        var remainCoin = Math.round(remainCoinNotRound)

        if(remainCoin <= userCoin) { 
            setRemainEnough(true) 
            setRemainMessage('คุณมีเหรียญซื้อ '+number_format.format(userCoin)+' เหรียญ คุณจะใช้ '+number_format.format(remainCoin)+' เหรียญ ('+(remainPercent*100)+'% ของราคาบริการ) เพื่อโอนให้ผู้ให้บริการ?')
        } else { 
            setRemainEnough(false) 
            setRemainMessage('คุณมีจำนวนเหรียญซื้อไม่พอ คุณมี '+number_format.format(userCoin)+' เหรียญ คุณต้องใช้ '+number_format.format(remainCoin)+' เหรียญ ('+(remainPercent*100)+'% ของราคาบริการ)')
        }
        setOpenFinishJob(true)
    }

    const handleCloseFinishJob = () => {
        setRemainEnough(false) 
        setRemainMessage('')
        setOpenFinishJob(false)
    }

    const handleFinishJob = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 12,
            user_job_finish_datetime: logDate.getTime()
        }).then(() => {
            getDoc(doc(db, 'exchange_coins', 'sum_data')).then((freeSnapshot) => {
                var promisesTask = []
                var getShopCoinTask = getDoc(doc(db, `shops`, shopId)).then((shopDoc) => {
                    var exchangeCoin = 0
                    if(shopDoc.data().exchange_coin) { exchangeCoin = shopDoc.data().exchange_coin }
                    updateDoc(doc(db, 'shops/', shopId), {
                        exchange_coin: (exchangeCoin*1)+(remainCoin*1)
                    })
                })
                promisesTask.push(getShopCoinTask)

                var addShopCoinLogTask = addDoc(collection(db, `shops/${shopId}/coin_log/`), {
                    coin: remainCoin*1,
                    date: logDate.getTime(),
                    event_type: 'transfer',
                    event: 'ค่าบริการ 70% ของค่าบริการล้างเครื่องซักผ้า',
                    coin_type: 'exchange',
                    post_type: 'wash',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addShopCoinLogTask)
                
                var updateUserCoinTask = updateDoc(doc(db, 'users/', userId), {
                    exchange_coin: (userCoin*1)-(remainCoin*1)
                })
                promisesTask.push(updateUserCoinTask)
                
                var addUserCoinLogTask = addDoc(collection(db, `users/${userId}/coin_log/`), {
                    coin: remainCoin*1,
                    date: logDate.getTime(),
                    event_type: 'transfer',
                    event: 'โอน 70% ของค่าบริการล้างเครื่องซักผ้าให้ร้านค้า',
                    coin_type: 'exchange',
                    post_type: 'wash',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addUserCoinLogTask)

                var updatePostTask = updateDoc(doc(db, `/cleans/`, postId), { 
                    status: 9,
                    appointment_id: appointmentId,
                    finish_date: logDate.getTime()
                })
                promisesTask.push(updatePostTask)

                // Shop ได้รับแต้มสะสม
                var weePoint = 100
                var addWeePointTask = addDoc(collection(db, `/shops/${shopId}/points`), {
                    datetime: logDate.getTime(),
                    point: weePoint,
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addWeePointTask)
        
                var addNotiShopTask = addDoc(collection(db, `/shops/${shopId}/notifications`), {
                    date: logDate.getTime(),
                    message: "คุณได้รับแต้มสะสมจากการบริการล้างเครื่องซักผ้า จำนวน "+number_format.format(weePoint)+" เหรียญ",
                    link: '/post',
                    post_id: postId,
                    color: 'green',
                    type: 'point',
                    board: 'wash',
                    status: 0
                })
                promisesTask.push(addNotiShopTask)
        
                // User ได้รับแต้มสะสม
                var weePoint = 100
                var addUserWeePointTask = addDoc(collection(db, `/users/${userId}/points`), {
                    datetime: logDate.getTime(),
                    point: weePoint,
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addUserWeePointTask)
        
                var addNotiUserTask = addDoc(collection(db, `/users/${userId}/notifications`), {
                    date: logDate.getTime(),
                    message: "คุณได้รับแต้มสะสมจากการบริการล้างเครื่องซักผ้า จำนวน "+number_format.format(weePoint)+" แต้ม",
                    link: '/post',
                    post_id: postId,
                    color: 'green',
                    type: 'point',
                    board: 'wash',
                    status: 0
                })
                promisesTask.push(addNotiUserTask)
            
                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const handleOpenCancle = () => { setOpenCancle(true) }

    const handleCloseCancle = () => { setOpenCancle(false) }

    const handleCancle = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            user_cancle_message: message,
            user_cancle_reason: reason,
            user_cancle_datetime: logDate.getTime(),
            status: 5,
        }).then(() => {
            addDoc(collection(db, `/shops/${shopId}/notifications`), {
                date: logDate.getTime(),
                message: "ผู้เรียกใช้บริการ แจ้งยกเลิกบริการ",
                link: '/shops/appointments',
                type: 'appointment',
                color: 'red',
                board: 'wash',
                appointment_id: appointmentId,
                status: 0
            }).then(() => {
                updateDoc(doc(db, `cleans`, postId), { 
                    status: 8, // status: 5
                }).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const handleOpenShowReportAfter = () => { setOpenShowReportAfter(true) }

    const handleCloseShowReportAfter = () => { setOpenShowReportAfter(false) }

    const handleOpenConfirmJob = () => { setOpenConfirmJob(true) }

    const handleCloseConfirmJob = () => { setOpenConfirmJob(false) }

    const handleConfirmJob = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            status: 9,
            user_job_confirm_datetime: logDate.getTime()
        }).then(() => {
            getDoc(doc(db, 'exchange_coins', 'sum_data')).then((freeSnapshot) => {
                var promisesTask = []
                var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
                    coin: depositCoin*1,
                    date: logDate.getTime(),
                    event_type: 'transfer',
                    event: 'โอนมัดจำ 30% ของค่าบริการล้างเครื่องซักผ้าให้ร้านค้า',
                    type: 'decrease',
                    post_type: 'wash',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addCoinLogTask)

                var updateCoinTask = updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                    all: (freeSnapshot.data().all*1)-(depositCoin*1)
                })
                promisesTask.push(updateCoinTask)

                var getShopCoinTask = getDoc(doc(db, `shops`, shopId)).then((shopDoc) => {
                    var exchangeCoin = 0
                    if(shopDoc.data().exchange_coin) { exchangeCoin = shopDoc.data().exchange_coin }
                    updateDoc(doc(db, 'shops/', shopId), {
                        exchange_coin: (exchangeCoin*1)+(depositCoin*1)
                    })
                })
                promisesTask.push(getShopCoinTask)

                var addShopCoinLogTask = addDoc(collection(db, `shops/${shopId}/coin_log/`), {
                    coin: depositCoin*1,
                    date: logDate.getTime(),
                    event_type: 'transfer',
                    event: 'ค่ามัดจำ 30% ของค่าบริการล้างเครื่องซักผ้า',
                    coin_type: 'exchange',
                    post_type: 'wash',
                    post_id: postId,
                    appointment_id: appointmentId
                })
                promisesTask.push(addShopCoinLogTask)
            
                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const handleOpenCancleJob = () => { setOpenCancleJob(true) }

    const handleCloseCancleJob = () => { setOpenCancleJob(false) }

    const handleCancleJob = () => {
        setShowLoading(true)
        const logDate = new Date()
        updateDoc(doc(db, `/appointments/`, appointmentId), { 
            user_cancle_message: message,
            user_cancle_reason: reason,
            user_cancle_job_datetime: logDate.getTime(),
            status: 11,
        }).then(() => {
            updateDoc(doc(db, `cleans`, postId), { 
                status: 5,
            }).then(() => {
                setShowLoading(false)
                navigate(0)
            })
        })
    }

    useEffect(() => {
    })

    return (
        <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
            {badge.show && (
            <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                <button type="button" className={"btn "+"btn-"+badge.class} style={{ color: badge.color }}><strong>{badge.message}</strong></button>
            </div>
            )}
            <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                <img src={appointment.post.picture} alt="WEEE" height="150" />
            </div>

            <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                <p className="card-text">
                    <strong>ประเภทนัดหมาย:</strong> ล้างเครื่องซักผ้า<br/>
                    <strong>วันนัดหมาย:</strong><br/>
                    <h6 style={{ marginLeft: 15 }}>{dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/></h6>
                </p>
            </div>
            <div className="card-body">
                <Link className="btn btn-primary m-1" to={`/postclean/${appointment.data.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                <Link className="btn btn-primary m-1" to={`/shop/wash/offer/${appointment.data.post_id}`} target='_blank'><strong>ดูข้อเสนอ</strong></Link>
                {appointment.checkReportBefore && (<>
                    <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenShowBefore() }}>ดูรายงานสำรวจหน้างาน</button>
                    {(appointment.data.status==1) && (<>
                        <button type="button" className="btn btn-success m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenConfirm() }}>ตัดสินใจใช้บริการ</button>
                        <button type="button" className="btn btn-danger m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenCancle() }}>ไม่ใช้บริการ</button>
                    </>)}
                </>)}
                {appointment.checkReportAfter && (<>
                    <button type="button" className="btn btn-warning m-1 text-black" style={{ color: '#ffffff' }} onClick={() => { handleOpenShowReportAfter() }}>ดูรายงานบันทึกส่งมอบงาน</button>
                    {(appointment.data.status==8) && (<>
                        <button type="button" className="btn btn-success m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenConfirmJob() }}>ยืนยันรับมอบงาน</button>
                        <button type="button" className="btn btn-danger m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenCancleJob() }}>ไม่รับมอบงาน</button>
                    </>)}
                </>)}
                {(appointment.data.status==9) && (<>
                    <button type="button" className="btn btn-success m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenFinishJob() }}>โอนเหรียญค่าบริการ</button>
                </>)}
            </div>

            <Dialog open={openShowBefore} onClose={handleCloseShowBefore} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานสำรวจหน้างาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อาการผิดหลังสำรวจ</strong></h5></label>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before1) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before1) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ระบบเปิด-ปิด ทำงานไม่ปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before2) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before2) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ทดสอบการซัก ทำงานไม่ปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before3) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before3) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ฟังเสียงเครื่องทำงาน ทำงานไม่ปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before4) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before4) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ตรวจสภาพภายนอกเครื่องมีสนิมผุกร่อน</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before5) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before5) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    แผงควบคุมอิเล็กทรอนิกส์ทำงานผิดปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before6) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before6) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    แกนซักไม่อยู่ในสภาพปกติหรือชำรุด</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before7) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before7) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ถ่ายน้ำไม่ออก</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before8) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before8) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ไม่ปั่น ปั่นไม่แห้งไม่หมาด</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before9) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before9) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    เครื่องปั่นมีเสียงดัง</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before10) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before10) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    เครื่องปั่นไม่หยุด</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before11) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before11) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    แกนหัก</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before12) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before12) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ไม่ระบายน้ำทิ้ง</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before13) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before13) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    เครื่องไม่ทำงาน</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before14) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before14) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    น้้ำรั่ว จากเครื่อง</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before15) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before15) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    เครื่องไม่ผสมผงซักฟอก</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before16) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before16) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    วัดค่าแรงดัน และกระแสไฟฟ้าผิดปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before17) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before17) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ไม่มีขั้วเสียบสายไฟ สายกราวด์</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before18) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before18) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ชุดทำความร้อน (เฉพาะเครื่องซักผ้าบางรุ่น) ทำงานผิดปกติ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before19) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before19) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ถึงที่พักแต่ไม่พบผู้ประกาศหรือไม่สามารถ เข้าถึงเครื่องใช้ไฟฟ้าได้</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before20) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before20) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    สภาพพื้นที่ไม่อำนวยต่อการซ่อม</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before21) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before21) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    ไม่ได้รับความร่วมมือจากผู้เรียกใช้บริการ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before22) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before22) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    มีความเสี่ยงในการเกิดอุบัติเหตุ</font>
                                                <font style={{ fontSize: 16 }}>
                                                    {(beforeReport.check_wash_before23) && (<AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    {(!beforeReport.check_wash_before23) && (<AiOutlineCloseCircle className='text-danger' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />)}
                                                    เคร่ืองซักผ้ามีความผิดปกติ ควรดำเนิการซ่อม</font>
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>อื่นๆ</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>
                                                    <textarea style={{ display: 'flex', flex: 1 }}
                                                        readOnly={true}
                                                        value={beforeReport.beforeWashSymptom}></textarea>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShowBefore} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirm} onClose={handleCloseConfirm}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันให้บริการ</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันใช้บริการร้านนี้</h5>
                                    {depositEnough &&
                                    <h6><strong>{depositMessage}</strong></h6>
                                    }
                                    {!depositEnough &&
                                    <h6 className='text-danger'><strong>{depositMessage}</strong></h6>
                                    }
                                    <h6 className='text-danger'><strong>เมื่อยืนยัน จะถือว่าท่านยินยอมตามข้อเสนอการให้บริการ และราคาที่ผู้เสนอได้เสนอไว้ โดยไม่มีข้อโต้แย้งใด ๆ</strong></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseConfirm} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    {depositEnough && 
                    (<button onClick={handleConfirm} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันใช้บริการ</button>)
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={openCancle} onClose={handleCloseCancle}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ไม่ใช้บริการล้างเครื่องซักผัก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ไม่ใช้บริการ</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5, paddingLeft: 10, paddingRight: 10 }}>
                                                <option value="1">มีการเรียกรับเงิน</option>
                                                <option value="2">ผู้ให้บริการไม่น่าเชื่อถือ</option>
                                                <option value="3">ไม่มาตามวันเวลาที่นัด</option>
                                                <option value="4">เครื่องซักผักมีความผิดปกติ ควรดำเนิการซ่อม</option>
                                                <option value="5">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancle} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancle} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ไม่ใช้บริการ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openShowReportAfter} onClose={handleCloseShowReportAfter} maxWidth='lg' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>รายงานส่งมอบงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div style={{ display: "flex", flexDirection: "row" }}>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>ขั้นตอนการดำเนินงาน</strong></h5></label>
                                                {afterReport.job_report_step1 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจวัดค่าแรงดันและค่ากระแสไฟฟ้า</font>
                                                }
                                                {afterReport.job_report_step2 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ปิดเบรกเกอร์เครื่องใช้ไฟฟ้า</font>
                                                }
                                                <font style={{ fontSize: 17 }}><strong>ฉีดล้างด้วยเครื่องฉีดน้ำแรงดันสูงทั้งภายในและภายนอกถัง</strong></font>
                                                {afterReport.job_report_step3 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ถังซัก</font>
                                                }
                                                {afterReport.job_report_step4 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ถังอุ้มน้ำ</font>
                                                }
                                                {afterReport.job_report_step5 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตะแกรงกรองฝุ่น</font>
                                                }
                                                {afterReport.job_report_step6 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ที่ใส่ผงซักฟอก</font>
                                                }
                                                {afterReport.job_report_step7 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> จานซัก</font>
                                                }
                                                {afterReport.job_report_step8 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> แผ่นฟิลเตอร์</font>
                                                }
                                                {afterReport.job_report_step9 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตะแกรงกรองผง</font>
                                                }
                                                <font style={{ fontSize: 17 }}><strong>ถอดชิ้นส่วนและอะไหล่</strong></font>
                                                {afterReport.job_report_step10 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> เดรนวาล์วหรือเดรนปั้มออกมาทำความสะอาด</font>
                                                }
                                                {afterReport.job_report_step11 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ทำความสะอาดมอเตอร์และสายพาน</font>
                                                }
                                                {afterReport.job_report_step12 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> เป่าไล่ความชื้นชิ้นส่วนและอะไหล่ต่าง ๆ</font>
                                                }
                                                {afterReport.job_report_step13 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ประกอบชิ้นส่วนคืนพร้อมอัดจารบีเข้ากับชิ้นส่วนที่จำเป็น ตั้งสายพานใหม่ตามรุ่นเครื่องซักผ้า</font>
                                                }
                                                <font style={{ fontSize: 17 }}><strong>ตรวจสอบการทำงาน ก่อนส่งงาน</strong></font>
                                                {afterReport.job_report_step14 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบการทำงานเครื่องซักผ้า</font>
                                                }
                                                {afterReport.job_report_step15 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบแผงควบคุมการทำงานเครื่อง</font>
                                                }
                                                {afterReport.job_report_step16 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบวัดค่าแรงดัน และค่ากระแสไฟฟ้า</font>
                                                }
                                                {afterReport.job_report_step17 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบขั้วเสียบสายไฟ สายกราวด์</font>
                                                }
                                                {afterReport.job_report_step18 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบจุดต่อ ข้อต่อต่างๆ</font>
                                                }
                                                {afterReport.job_report_step19 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบชุดทำความร้อน (เฉพาะเครื่องซักผ้าบางรุ่น)</font>
                                                }
                                                {afterReport.job_report_step20 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจสอบขอบยางประตู/ฝาเปิด</font>
                                                }
                                                {afterReport.job_report_step21 && 
                                                <font style={{ fontSize: 16 }}><AiOutlineCheckCircle className='text-success' style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} /> ตรวจเช็กการทำงานของเครื่องซักผ้าตามโปรแกรมการทำงานต่าง ๆ</font>
                                                }
                                            </div>
                                        </div>
                                        <div style={{ display: "flex", flexDirection: "column", flex: 1}}>
                                            <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                                <label className="" style={{ display: 'flex' }}><h5><strong>รายงานเพิ่มเติม</strong></h5></label>
                                                <div style={{ display: 'flex', flex: 1 }}>
                                                    <textarea style={{ display: 'flex', flex: 1 }}
                                                        placeholder='รายงานเพิ่มเติม' readOnly={true}
                                                        value={afterReport.afterWashSymptom}></textarea>
                                                </div>
                                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                    <label className="col-form-label" style={{ display: 'flex'}}><font style={{ fontSize: 17 }}><strong>รูปภาพงานเพิ่มเติม <font className="text-danger">*</font></strong></font></label>
                                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'row' }}>
                                                        <div className="card-body mx-auto">
                                                            <div className="d-flex flex-wrap">
                                                                {reportImages?.map((image, i) => (
                                                                    <div key={image.id} className="m-2">
                                                                        <img src={image.data.url} alt="WEEE" width="120" />
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div> 
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShowReportAfter} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openConfirmJob} onClose={handleCloseConfirmJob}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันรับมอบงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันรับมอบงานนี้</h5>
                                    <h6 className='text-danger'><strong>หากยืนยันการรับงานแล้ว จะไม่สามารถยกเลิกได้</strong></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseConfirmJob} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleConfirmJob} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันรับมอบงาน</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openCancleJob} onClose={handleCloseCancleJob}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ไม่รับมอบงาน</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ไม่รับมอบงาน</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ marginLeft: 5, paddingLeft: 10, paddingRight: 10 }}>
                                                <option value="1">การบริการไม่ได้มาตรฐาน</option>
                                                <option value="2">การดาเนินงานไม่เรียบร้อย</option>
                                                <option value="3">มีการเรียกเก็บเงินเพิ่ม</option>
                                                <option value="4">อื่นๆ (โปรดระบุ)</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                            <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                onChange={(e) => setMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={message}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleJob} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleJob} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการให้บริการ</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openFinishJob} onClose={handleCloseFinishJob}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันโอนเหรียญให้ร้่านค้า</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันโอนเหรียญให้ร้านค้า</h5>
                                    {remainEnough &&
                                    <h6><strong>{remainMessage}</strong></h6>
                                    }
                                    {!remainEnough &&
                                    <h6 className='text-danger'><strong>{remainMessage}</strong></h6>
                                    }
                                    <h6 className='text-danger'><strong>หากยืนยันการรับงานแล้ว จะไม่สามารถยกเลิกได้</strong></h6>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseFinishJob} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    {remainEnough && 
                    (<button onClick={handleFinishJob} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันโอนเหรียญให้ร้่านค้า</button>)
                    }
                </DialogActions>
            </Dialog>
        </div>
    )
}