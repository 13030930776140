import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, orderBy, onSnapshot, doc } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import LeftMenu from './LeftMenu'

import { AppointmentShopBoard } from './AppointBoardShop'
import { Loading } from "../layouts/Loading"

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Appointments = () => {
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const [uid, setUid] = useState('')
    const [user_id, setUserId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [openDrawer, setOpenDrawer] = useState(false)

    const [showLoading, setShowLoading] = useState(true)
    const [appointments, setAppointments] = useState([])

    const fetchAppointments = () => {
        setShowLoading(true)
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setUserId(docs.docs[0].id)
                if(data.is_shop) setIsShop(data.is_shop)
                if(data.uid) setUid(data.uid)
                    const postQuery = query(collection(db, 'appointmentshops'),
                                                where('user_id', '==', docs.docs[0].id), 
                                                orderBy('appointment_date', 'asc'), 
                                                orderBy('appointment_hour', 'asc'), 
                                                orderBy('appointment_minute', 'asc'))
                    onSnapshot(postQuery, (querySnapshot) => {
                        appointments.splice(0, appointments.length)
                        setAppointments([...appointments])
                        querySnapshot.forEach( async(appointment) => {
                            var postType = 'posts'
                            if(appointment.data().appointment_type == 'air' || appointment.data().appointment_type == 'washing') {
                                postType = 'cleans'
                            }
                            const postSnapshot = await getDoc(doc(db, `postshops`, appointment.data().post_id));
                            const offerSnapshot = await getDoc(doc(db, `postshops/${appointment.data().post_id}/offers`, appointment.data().offer_id));
                            const checkSnapshot = await getDoc(doc(db, `appointmentshops/${appointment.id}/report/before`))
                            const checkAfterSnapshot = await getDoc(doc(db, `appointmentshops/${appointment.id}/report/after`))
                            
                            var badgeStyle = { message: '', class: 'success', color: '#000000', show: false }
                            if(appointment.data().appointment_type == 'air' || appointment.data().appointment_type == 'washing') {
                                if(appointment.data().status==1) {
                                    if(!appointment.checkReportBefore) {
                                        badgeStyle = { message: 'รอสำรวจหน้างาน', class: 'warning', color: '#000000', show: true }
                                    } else {
                                        badgeStyle = { message: 'รอยืนยันให้บริการ', class: 'warning', color: '#000000', show: true }
                                    }
                                } else if(appointment.data().status==2) {
                                    badgeStyle = { message: 'รอตัดสินใจใช้บริการ', class: 'warning', color: '#000000', show: true }
                                } else if(appointment.data().status==3) {
                                    badgeStyle = { message: 'ตกลงใช้บริการ', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==4) {
                                    badgeStyle = { message: 'ช่างยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==5) {
                                    badgeStyle = { message: 'ผู้เรียกยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==6) {
                                } else if(appointment.data().status==7) {
                                } else if(appointment.data().status==8) {
                                    badgeStyle = { message: 'รอผู้เรียกรับมอบงาน', class: 'warning', color: '#000000', show: true }
                                } else if(appointment.data().status==9) {
                                    badgeStyle = { message: 'ผู้เรียกรับมอบงาน', class: 'succes', color: '#ffffff', show: true }
                                } else if(appointment.data().status==10) {
                                    badgeStyle = { message: 'ช่างยกเลิกบริการ', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==11) {
                                    badgeStyle = { message: 'ผู้เรียกไม่รับมอบงาน', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==12) {
                                    badgeStyle = { message: 'ผู้เรียกโอนเหรียญเรียบร้อย', class: 'success', color: '#ffffff', show: true }
                                }
                            } else if(appointment.data().appointment_type == 'post') {
                                if(appointment.data().status==1) {
                                    if(postSnapshot.data().onboard == 1) {
                                        badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                    } else if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                    }
                                } else if(appointment.data().status==2) {
                                    if(postSnapshot.data().onboard == 1) {
                                        badgeStyle = { message: 'ผู้ประกาศยืนยันให้ซาก', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'จ่ายเหรียญซื้อซากแล้ว', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'จ่ายเหรียญมัดจำซื้อสินค้าแล้ว', class: 'success', color: '#ffffff', show: true }
                                    }
                                } else if(appointment.data().status==3) {
                                    if(postSnapshot.data().onboard == 1) {
                                        badgeStyle = { message: 'ยืนยันรับซาก', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'ผู้ประกาศยืนยันขายซาก', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'ผู้ประกาศยืนยันขายสินค้า', class: 'success', color: '#ffffff', show: true }
                                    }
                                } else if(appointment.data().status==4) {
                                    if(postSnapshot.data().onboard == 1) {
                                        badgeStyle = { message: 'ผู้ประกาศยกเลิกให้ซาก', class: 'danger', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'ยกเลิกการซื้อซาก', class: 'danger', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'ยกเลิกการซื้อสินค้า', class: 'danger', color: '#ffffff', show: true }
                                    }
                                } else if(appointment.data().status==5) {
                                    if(postSnapshot.data().onboard == 1) {
                                        badgeStyle = { message: 'ยกเลิกการรับซาก', class: 'danger', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'ผู้ประกาศยกเลิกการขายซาก', class: 'danger', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'ผู้ประกาศยกเลิกการขายสินค้า', class: 'danger', color: '#ffffff', show: true }
                                    }
                                } else if(appointment.data().status==6) {
                                    if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'ยืนยันได้รับซากแล้ว', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'ยืนยันได้รับสินค้าแล้ว', class: 'success', color: '#ffffff', show: true }
                                    }
                                }
                            } else if(appointment.data().appointment_type == 'fix') {
                                if(appointment.data().status==1) {
                                    badgeStyle = { message: 'รอวันนัดหมาย', class: 'warning', color: '#000000', show: true }
                                } else if(appointment.data().status==2) {
                                    badgeStyle = { message: 'ยืนยันการรับซ่อม', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==3) {
                                    badgeStyle = { message: 'ผู้ประกาศยืนยันให้ซ่อม', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==4) {
                                    badgeStyle = { message: 'ปฏิเสธการรับซ่อม', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==5) {
                                    badgeStyle = { message: 'ผู้ประกาศไม่ให้ซ่อม', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==6) {
                                    badgeStyle = { message: 'รายงานการซ่อมแล้ว', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==7) {
                                    badgeStyle = { message: 'ผู้ประกาศยืนยันรับรายงาน', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==8) {
                                    badgeStyle = { message: 'ยืนยันได้รับเหรียญ', class: 'success', color: '#ffffff', show: true }
                                } else if(appointment.data().status==9) {
                                    badgeStyle = { message: 'ผู้ประกาศยกเลิกจ่ายค่าซ่อม', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==10) {
                                    badgeStyle = { message: 'ยกเลิกการรับเหรียญ', class: 'danger', color: '#ffffff', show: true }
                                } else if(appointment.data().status==11) {
                                    badgeStyle = { message: 'ผู้ประกาศยืนยันเสริ็จงาน', class: 'success', color: '#ffffff', show: true }
                                }
                            }
                            
                            appointments.push({
                                id: appointment.id,
                                data: appointment.data(),
                                offer: offerSnapshot.data(),
                                post: postSnapshot.data(),
                                checkReportBefore: checkSnapshot.data(),
                                checkReportAfter: checkAfterSnapshot.data(),
                                badgeStyle: badgeStyle
                            })

                            setAppointments([...appointments, {
                                id: appointment.id,
                                data: appointment.data(),
                                offer: offerSnapshot.data(),
                                post: postSnapshot.data(),
                                checkReportBefore: checkSnapshot.data(),
                                checkReportAfter: checkAfterSnapshot.data(),
                                badgeStyle: badgeStyle
                            }])
                        })
                    })
                    setShowLoading(false)
            }
            setShowLoading(false)
        }).catch((error) => {
            alert(error.message);
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else fetchAppointments()
    }, [user, loading])

    var appointmentKey = []
    var appointmentList = []
    appointments.forEach(async(element) => {
        if(appointmentKey.lastIndexOf(element.id) < 0) {
            appointmentKey.push(element.id)
            appointmentList.push(element)
        }
    })

    return (
    <>
        <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 280 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="appointmentposts" isShop={isShop} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>การนัดหมายของร้านค้า</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="appointmentposts" isShop={isShop} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                        {appointmentList?.map((appointment, i) => (
                            <AppointmentShopBoard appointment={appointment} offerExchangeCoin={offerExchangeCoin} />
                        ))}
                        {appointmentList.length <= 0 && (
                            <div style={{ display: 'flex', alignItems: 'center'}}>
                                <font className="text-danger" style={{ fontWeight: '600'}}>ไม่พบการนัดหมายของร้านค้า</font>
                            </div>
                        )}
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
    )
}

export default Appointments