import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, getDoc, addDoc, updateDoc } from "firebase/firestore"
import { db } from "../../assets/js/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { PiCoinsDuotone } from "react-icons/pi"
import { TbCirclePlus, TbCircleMinus } from "react-icons/tb";
import "../../assets/css/memberList.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'

import { addFreeCoinLog } from "../../../assets/js/LogHelper"

const FreeCoins = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()

    const [allFreeCoin, setAllFreeCoin] = useState(0)
    const [remainFreeCoin, setRemainFreeCoin] = useState(0)
    const [givedFreeCoin, setGivedFreeCoin] = useState(0)
    const [usedFreeCoin, setUsedFreeCoin] = useState(0)
    const [coin, setCoin] = useState(0)
    const [descCoin, setDescCoin] = useState(0)
    const [giveCoin, setGiveCoin] = useState(0)
    const [giveCoinShop, setGiveCoinShop] = useState(0)
    const [transactions, setTransactions] = useState([])
    const [logs, setLogs] = useState([])
    const [startDate, setStartDate] = useState("")
    const [endDate, setEndDate] = useState("")
    const [checkEndDate, setCheckEndDate] = useState(true)

    const [openAddCoin, setOpenAddCoin] = useState(false)
    const [openDecreaseCoin, setOpenDecreaseCoin] = useState(false)
    const [openGiveCoin, setOpenGiveCoin] = useState(false)

    const handleCloseAddCoin = () => {
        setOpenAddCoin(false)
    }

    const handleCloseDecreaseCoin = () => {
        setOpenDecreaseCoin(false)
    }
    
    const handleCloseGiveCoin = () => {
        setOpenGiveCoin(false)
    }

    const handleCheckEndDate = () => {
        setCheckEndDate(!checkEndDate)
    }
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime.seconds * 1000).toLocaleDateString("th-TH");
        const time_string = new Date(datetime.seconds * 1000).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        const date_sprit = date_string.split('/')
        return date_sprit[0]+" "+textMonth(date_sprit[1])+" "+date_sprit[2]+" เวลา "+time[0]+":"+time[1]+" น."
    }
    
    const dateFormatDate = (datetime) => {
        if(datetime == 0) {
            return "ไม่กำหนด"
        }
        const date_string = new Date(datetime.seconds * 1000).toLocaleDateString("th-TH");
        const date_sprit = date_string.split('/')
        return date_sprit[0]+" "+textMonth(date_sprit[1])+" "+date_sprit[2]
    }

    const textMonth = (month) => {
        var text = ""
        switch(month) {
            case "1": text = "มกราคม"; break;
            case "2": text = "กุมภาพันธ์"; break;
            case "3": text = "มีนาคม"; break;
            case "4": text = "เมษายน"; break;
            case "5": text = "พฤษภาคม"; break;
            case "6": text = "มิถุนายน"; break;
            case "7": text = "กรกฎาคม"; break;
            case "8": text = "สิงหาคม"; break;
            case "9": text = "กันยายน"; break;
            case "10": text = "ตุลาคม"; break;
            case "11": text = "พฤศจิกายน"; break;
            case "12": text = "ธันวาคม"; break;
        }
        return text
    }

    const handleAddCoin = () => {
        const transaction_date = new Date()

        addDoc(collection(db, `free_coins/get_free_rate/transaction`), {
            amount: coin*1,
            date: transaction_date,
            type: 'increase'
        }).then(() => {
            addFreeCoinLog(db, coin, "เพิ่มเหรียญใน platform", '', "add")
            updateDoc(doc(db, 'free_coins/', 'sum_data'), {
                all: allFreeCoin+(coin*1),
                remain: remainFreeCoin+(coin*1)
            }).then(() => {
                setOpenAddCoin(false)
                navigate(0)
            })
        })
    }

    const handleDecreaseCoin = () => {
        if(descCoin * 1 <= remainFreeCoin) {
            const transaction_date = new Date()
            addDoc(collection(db, `free_coins/get_free_rate/transaction`), {
                amount: descCoin * 1,
                date: transaction_date,
                type: 'decrease'
            }).then(() => {
                addFreeCoinLog(db, descCoin, "ลดเหรียญใน platform", '', "withdraw")
                updateDoc(doc(db, 'free_coins/', 'sum_data'), {
                    all: allFreeCoin-(descCoin*1),
                    remain: remainFreeCoin-(descCoin*1)
                }).then(() => {
                    setOpenDecreaseCoin(false)
                    navigate(0)
                })
            })
        }
    }

    const handleGiveCoin = () => {
        if(checkEndDate) {
            var end_date = endDate.toDate()
        } else {
            var end_date = 0
        }

        const log_date = new Date()
        addDoc(collection(db, `free_coins/get_free_rate/change_log`), {
            log_date: log_date,
            start_date: startDate.toDate(),
            end_date: end_date,
            coin: giveCoin*1,
            coin_for_shop: giveCoinShop*1
        }).then(() => {
            updateDoc(doc(db, 'free_coins/', 'get_free_rate'), {
                start_date: startDate.toDate(),
                end_date: end_date,
                coin: giveCoin*1,
                coin_for_shop: giveCoinShop*1
            }).then(() => {
                setOpenGiveCoin(false)
                navigate(0)
            })
        })
    }

    const getFreeCoins = () => {
        getDoc(doc(db, 'free_coins', 'sum_data')).then((freeSnapshot) => {
            const data = freeSnapshot.data()
            setAllFreeCoin(data.all)
            setRemainFreeCoin(data.remain)
            setGivedFreeCoin(data.gived)
            setUsedFreeCoin(data.used)
        })
    }

    const getTransaction = () => {
        const tranQuery = query(collection(db, `free_coins/get_free_rate/transaction`), orderBy('date', 'desc'))
        onSnapshot(tranQuery, (querySnapshot) => {
            setTransactions(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getGiveLog = () => {
        const logQuery = query(collection(db, `free_coins/get_free_rate/change_log`), orderBy('log_date', 'desc'))
        onSnapshot(logQuery, (querySnapshot) => {
            setLogs(querySnapshot.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getGiveCoin = () => {
        getDoc(doc(db, 'free_coins', 'get_free_rate')).then((giveSnapshot) => {
            const data = giveSnapshot.data()
            setGiveCoin(data.coin)
            setGiveCoinShop(data.coin_for_shop)
        })
    }

    useEffect(() => {
        getFreeCoins()
        getTransaction()
        getGiveCoin()
        getGiveLog()
    },[])

    return (
        <>
        <div style={{ display: 'flex', flexDirection: 'column', flex: 1, padding: 20 }}>
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><PiCoinsDuotone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>ข้อมูลเหรียญแจกฟรี</b></h4>
            </div>
            <div>
                <Paper elevation={2} style={{ display: 'flex', flex: 1, marginBottom: 20, flexDirection: 'column' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 25, marginTop: 15}}>
                        <div style={{ paddingLeft: 10, marginTop: -20, display:'flex', paddingTop: 20 }}>
                            <div style={{ display: 'flex', flexDirection:'column', flex: 2 }}>
                                <div><h4><strong>จำนวนเหรียญที่เหลือ/ทั้งหมดในระบบ</strong></h4></div>
                                <div>
                                    <font style={{ fontSize: 60, marginRight: 15 }}>{number_format.format(remainFreeCoin)}/{number_format.format(allFreeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection:'column', flex: 1 }}>
                                <div><h4><strong>จำนวนเหรียญที่แจก</strong></h4></div>
                                <div>
                                    <font style={{ fontSize: 40, marginRight: 15 }}>{number_format.format(givedFreeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font>
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection:'column', flex: 1 }}>
                                <div><h4><strong>จำนวนเหรียญที่ใช้ไปแล้ว</strong></h4></div>
                                <div>
                                    <font style={{ fontSize: 40, marginRight: 15 }}>{number_format.format(usedFreeCoin)}</font><font style={{ fontSize: 30 }}>เหรียญ</font>
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </Paper>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'row', paddingRight: 15 }}>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 2, paddingRight: 30}}>
                    <div style={{ paddingRight: 10, marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAddCoin(true) }}>
                            <TbCirclePlus style={{ fontSize: 20, marginRight: 5 }} /> <b>เติมเหรียญเข้าระบบ</b>
                        </button>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenDecreaseCoin(true) }}>
                            <TbCircleMinus style={{ fontSize: 20, marginRight: 5 }} /> <b>นำเหรียญออกจากระบบ</b>
                        </button>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        <table className="table" style={{ marginLeft: 10}}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>วันที่</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, textAlign: 'center'}}>จำนวน</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                                </tr>
                            </thead>
                            <tbody>
                            {transactions?.map((transaction, i) => (
                                <tr key={i}>
                                    <td style={{ paddingLeft: 10 }}> </td>
                                    <th scope="row">{dateFormat(transaction.data.date)}</th>
                                    <td style={{ textAlign:'right'}}>
                                        {
                                            (transaction.data.type == 'increase') && 
                                            (<font style={{ fontSize: 18 }} className='text-success'>+{number_format.format(transaction.data.amount)}</font>)
                                        }
                                        {
                                            (transaction.data.type == 'decrease') && 
                                            (<font style={{ fontSize: 18 }} className='text-danger'>-{number_format.format(transaction.data.amount)}</font>)
                                        }
                                    </td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column', flex: 3, paddingLeft: 10}}>
                    <div style={{ paddingRight: 10, marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenGiveCoin(true) }}>
                            <TbCirclePlus style={{ fontSize: 20, marginRight: 5 }} /> <b>เปลี่ยนจำหนวนเหรีบญแจกฟรีเมื่อสมัครสมาชิก</b>
                        </button>
                    </div>
                    <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                        <table className="table" style={{ marginLeft: 10}}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>วันเริ่มต้น-วันสิ้นสุด</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, textAlign: 'center'}}>แจกสมาชิก</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, textAlign: 'center'}}>แจกร้านค้า</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>วันที่เปลี่ยน</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                                </tr>
                            </thead>
                            <tbody>
                            {logs?.map((log, i) => (
                                <tr key={i}>
                                    <td style={{ paddingLeft: 10 }}> </td>
                                    <td scope="row"><font style={{ fontSize: 14 }}>{dateFormatDate(log.data.start_date)} - {dateFormatDate(log.data.end_date)}</font></td>
                                    <th scope="row" style={{ textAlign: 'right'}}><font style={{ fontSize: 18 }} className='text-success'>{number_format.format(log.data.coin)}</font></th>
                                    <th scope="row" style={{ textAlign: 'right'}}><font style={{ fontSize: 18 }} className='text-success'>{number_format.format(log.data.coin_for_shop)}</font></th>
                                    <td><font style={{ fontSize: 14 }}>{dateFormat(log.data.log_date)}</font></td>
                                </tr>
                            ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
            <Dialog open={openAddCoin} onClose={handleCloseAddCoin}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>เติมเหรียญเข้าระบบ</strong></div></DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="จำนวนเหรียญ"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={coin}
                    onChange={(e) => setCoin(e.target.value)}
                />
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAddCoin} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleAddCoin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>เติมเหรียญ</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openDecreaseCoin} onClose={handleCloseDecreaseCoin}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>นำเหรียญออกจากระบบ</strong></div></DialogTitle>
                <DialogContent>
                <TextField
                    autoFocus
                    margin="dense"
                    label="จำนวนเหรียญ"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={descCoin}
                    onChange={(e) => setDescCoin(e.target.value)}
                />
                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                    <strong>** นำออกได้ไม่เกิน {remainFreeCoin}</strong>
                </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseDecreaseCoin} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleDecreaseCoin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>นำเหรียญออก</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openGiveCoin} onClose={handleCloseGiveCoin}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>เปลี่ยนจำนวนเหรียญแจกฟรีเมื่อสมัครสมาชิก</strong></div></DialogTitle>
                <DialogContent>
                    <div style={{ display: 'flex', flexDirection: 'column', paddingTop: 10}}>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="วันเริ่มต้น" format="DD/MM/YYYY" onChange={(value) => setStartDate(value)} />
                            </LocalizationProvider>
                        </div>
                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                            <FormControlLabel control={<Checkbox
                                checked={checkEndDate}
                                onChange={() => { handleCheckEndDate() }}
                            />} label="กำหนดวันสิ้นสุด" style={{ fontFamily: 'Prompt'}} />
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DatePicker label="วันสิ้นสุด" format="DD/MM/YYYY" disabled={!checkEndDate} onChange={(value) => setEndDate(value)} />
                            </LocalizationProvider>
                        </div>
                    </div>
                <TextField
                    autoFocus
                    margin="dense"
                    label="จำนวนเหรียญเมื่อสมัครสมาชิกทั่วไป"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={giveCoin}
                    onChange={(e) => setGiveCoin(e.target.value)}
                    style={{ paddingLeft: 5, marginTop: 15 }}
                />
                <TextField
                    autoFocus
                    margin="dense"
                    label="จำนวนเหรียญเมื่อสมัครร้านค้า"
                    type="number"
                    fullWidth
                    variant="standard"
                    value={giveCoinShop}
                    onChange={(e) => setGiveCoinShop(e.target.value)}
                    style={{ paddingLeft: 5, marginTop: 15 }}
                />
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseGiveCoin} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                    <button onClick={handleGiveCoin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
        </div>
        </>
    )
}

export default FreeCoins;