import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, getDoc, doc, updateDoc, addDoc } from 'firebase/firestore'
import { Link, useNavigate } from "react-router-dom";

import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2, TbSearch } from "react-icons/tb"
import { IoMegaphoneOutline } from 'react-icons/io5'
import { AiFillInfoCircle } from 'react-icons/ai'
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import LeftMenu from './LeftMenu'
import { Alert } from '../layouts/Alert'
import { Loading } from "../layouts/Loading"
import { LuAirVent } from "react-icons/lu"
import { CgSmartHomeWashMachine } from "react-icons/cg"

import LeftMenuPost from './LeftMenuPost'
import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const PostsFix = () => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const number_format = new Intl.NumberFormat();
    const [openDrawer, setOpenDrawer] = useState(false)

    const [uid, setUid] = useState('');
    const [docId, setDocId] = useState('');
    const [keyword, setKeyword] = useState('');
    const [isShop, setIsShop] = useState(0);
    const [posts, setPosts] = useState([]);

    const [postId, setPostId] = useState('')
    const [openChooseOffersShop, setOpenChooseOffersShop] = useState(false)
    const [offerList, setOfferList] = useState([])
    const [confirmOffer, setConfirmOffer] = useState('')
    const [openErrorDiv, setOpenErrorDiv] = useState(false)

    const [openCancleOffers, setOpenCancleOffers] = useState(false)
    const [canclePostId, setCanclePostId] = useState('')
    const [cancleMessage, setCancleMessage] = useState('');
    const [cancleReason, setCancleReason] = useState(1);

    const [showProgressBar, setShowProgressBar] = useState(false)
    const [showLoading, setShowLoading] = useState(false)

    const handleCloseAcceptPostShop = () => {
        setOpenChooseOffersShop(false)
    }

    const handleOpenCancleOffer = (postId) => {
        setCanclePostId(postId)
        setOpenCancleOffers(true)
    }

    const handleCloseCancleOffer = () => {
        setOpenCancleOffers(false)
    }

    const handleCancleOffer = () => {
        getDocs(query(collection(db, `cleans/${canclePostId}/offers`))).then((offerDocs) => {
            offerDocs.forEach((offer) => {
                if(offer.data().status == 2) {
                    updateDoc(doc(db, `cleans/${canclePostId}/offers`, offer.id), { 
                        status: 7, 
                        cancle_reason: cancleReason,
                        cancle_message: cancleMessage
                    }).then(() => {
                        getDocs(query(collection(db, `/cleans/${offer.data().shop_id}/offers`), where('post_id', '==', canclePostId))).then((offerShop) => {
                            offerShop.forEach((docRef) => {
                                updateDoc(doc(db, `/cleans/${offer.data().shop_id}/offers`, docRef.id), { status: 7 })
                            })
                        })
                    })
                } else if(offer.data().status == 4) {
                    updateDoc(doc(db, `cleans/${canclePostId}/offers`, offer.id), { status: 1 })
                } else {
                    updateDoc(doc(db, `cleans/${canclePostId}/offers`, offer.id), { status: 1 })
                }
            })
        }).then(() => {
            updateDoc(doc(db, `cleans`, canclePostId), {  status: 1 }).then(() => {
                navigate(0)
            })
        })
    }

    const handleSearch = (keyword) => {
        var q
        if(keyword) {
            q = query(collection(db, 'cleans'), 
                        where('onboard', '==', 2),
                        where('type', '>=', keyword),
                        where('type', '<=', keyword +  '\uf8ff'),
                        where('poster_id', '==', docId),
                        orderBy('type', 'asc'), 
                        orderBy('post_timestamp', 'desc'))

            onSnapshot(q, (querySnapshot) => {
                setPosts(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        } else {
            q = query(collection(db, 'cleans'), 
                        where('onboard', '==', 2),
                        where('poster_id', '==', docId),
                        orderBy('post_timestamp', 'desc'))

            onSnapshot(q, (querySnapshot) => {
                setPosts(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))
            })
        }
    }
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH");
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]
    }
 
    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const showChooseOffersList = (post) => {
        setConfirmOffer('')
        setPostId(post.id)
        offerList.splice(0, offerList.length)
        setOfferList([...offerList])
        getDocs(query(collection(db, `cleans/${post.id}/offers`), 
                        where('status', '==', 1), 
                        orderBy('offer_timestamp', 'asc'))
        ).then((offerDocs) => {
            offerDocs.docs.map((offer) => {
                getDoc(doc(db, 'shops', offer.data().shop_id)).then((shop) => {
                    offerList.push({
                        id: offer.id,
                        data: offer.data(),
                        shopId: offer.data().shop_id,
                        shop: shop.data()
                    })
                    setOfferList([...offerList]) 
                })
            })
        }).then(() => {
            setOpenChooseOffersShop(true)
        })
    }

    const handleOfferSubmit = () => {
        if(validateOfferForm()) {
            getDoc(doc(db, `cleans/${postId}/offers/`, confirmOffer)).then((offerDoc) => {
                updateDoc(doc(db, `/cleans`, postId), { status: 3 }).then(() => {
                    getDocs(query(collection(db, `/cleans/${postId}/offers`))).then((offerDocs) => {
                        offerDocs.forEach((docRef) => {
                            updateDoc(doc(db, `/cleans/${postId}/offers`, docRef.id), { status: 4 }).then(() => {
                                getDocs(query(collection(db, `/shops/${docRef.data().shop_id}/offers`), where('offer_id', '==', docRef.id))).then((offerShop) => {
                                    offerShop.forEach((offer) => {
                                        updateDoc(doc(db, `/shops/${docRef.data().shop_id}/offers`, offer.id), { status: 4 })
                                    })
                                })
                            })
                        })
                    }).then(() => {
                        updateDoc(doc(db, `/cleans/${postId}/offers/`, confirmOffer), { status: 2 }).then(() => {
                            getDocs(query(collection(db, `/shops/${offerDoc.data().shop_id}/offers`), where('offer_id', '==', confirmOffer))).then((offerShop) => {
                                offerShop.forEach((docRef) => {
                                    updateDoc(doc(db, `/shops/${offerDoc.data().shop_id}/offers`, docRef.id), { status: 2 })
                                })
                            }).then(() => {
                                const date = new Date()
                                addDoc(collection(db, `/shops/${offerDoc.data().shop_id}/notifications`), {
                                    date: date.getTime(),
                                    message: "ยินดีด้วย ข้อเสนอชองคุณได้รับการเลือก",
                                    link: "/shop/offers",
                                    type: 'offer',
                                    color: 'green',
                                    offer_id: confirmOffer,
                                    post_id: postId,
                                    status: 0
                                }).then(() => {
                                    setOpenChooseOffersShop(false)
                                })
                            })
                        })
                    })
                })
            })
        }
    }

    const validateOfferForm = () => {
        if(!confirmOffer) {
            setOpenErrorDiv(true)
            return false;
        } else {
            setOpenErrorDiv(false)
        }
        return true
    }

    const fetchPosts = () => {
        setShowProgressBar(true)
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then(async(docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setDocId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)
                await getDocs(query(collection(db, 'cleans'), 
                            where('onboard', '==', 2), 
                            where('poster_id', '==', docs.docs[0].id), 
                            orderBy('post_timestamp', 'desc'))).then((postDocs) => {
                    posts.splice(0, posts.length)
                    setPosts([...posts])
                    postDocs.forEach((doc) => {
                        getDocs(query(collection(db, `cleans/${doc.id}/offers`), where('status', '==', 1), orderBy('offer_timestamp', 'desc'))).then((offerQuery) => {
                            var offersList = []
                            offerQuery.forEach((offer) => {
                                offersList.push({ id: offer.id, data: offer.data()})
                            })
                            posts.push({
                                id: doc.id,
                                data: doc.data(),
                                offer: offersList
                            })
                            setPosts([...posts], {
                                id: doc.id,
                                data: doc.data(),
                                offer: offersList
                            })
                        })
                    })
                }).then(() => {
                    setShowProgressBar(false)
                })
            }
        }).catch((error) => {
            console.log(error.message)
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        posts.splice(0, posts.length)
        setPosts([...posts])
        if (loading) { 
            return 
        } else {
            fetchPosts()
        }
        if (!user) return navigate("/")
    }, [user, loading]);

    var postKey = []
    var postList = []
    posts.forEach(element => {
        if(postKey.lastIndexOf(element.id) < 0) {
            postKey.push(element.id)
            postList.push(element)
        }
    })

    return (
        <>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <Loading open={showProgressBar} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
            <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
                <Box sx={{ width: 280 }} role="presentation">
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                            <div style={{ marginBottom: 10 }}>
                                <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                            </div>
                            <LeftMenuPost currentPage="post" isShop={isShop} postType={6} />
                        </div>
                    </div>
                </Box>
            </Drawer>
            <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
                <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                    <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 10, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                        <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                            <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                        </div>
                    </div>
                    <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}>
                        <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                            <h4 className='d-block d-md-none d-lg-none'>
                                <b>ประกาศของฉัน {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} onClick={toggleDrawer(true)} >แจ้งความต้องการล้างเครื่องซักผ้า<IoMdMenu style={{ fontSize: 25, marginLeft: 5, marginBottom: 3 }} /></font></b>
                            </h4>
                            <h4 className='d-none d-md-block d-lg-block'>
                                <b>ประกาศของฉัน {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} >แจ้งความต้องการล้างเครื่องซักผ้า</font></b>
                            </h4>
                        </div>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row' }}>
                        <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                            <LeftMenu currentPage="post" isShop={isShop} />
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div className="d-none d-md-block d-lg-block" style={{ borderBottom: '1px solid #cccccc', paddingLeft: 20 }}>
                                <ul className="nav nav-underline">
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/"><RiDeleteBinLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ทิ้งซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/garbage"><BiMoneyWithdraw style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายซาก</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/second"><TbHexagonNumber2 style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายมือสอง</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/fix"><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งซ่อมเครื่องใช้ไฟฟ้า</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link" href="/profile/posts/airs"><LuAirVent style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างแอร์</a>
                                    </li>
                                    <li className="nav-item">
                                        <a className="nav-link active" aria-current="page" href="/profile/posts/wash"><CgSmartHomeWashMachine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างเครื่องซักผ้า</a>
                                    </li>
                                </ul>
                            </div>
                            {/* <div>
                                <div className="row" style={{ marginTop: 10 }}>
                                    <div className="input-group" style={{ marginBottom: 10 }}>
                                        <input 
                                            type="text" 
                                            className="form-control" 
                                            placeholder="ค้นหาประกาศของฉัน" 
                                            aria-describedby="button-addon2"
                                            onChange={(e) => setKeyword(e.target.value)} 
                                            value={keyword} />
                                        <button 
                                            className="btn btn-outline-secondary" 
                                            type="button" 
                                            id="button-addon2"
                                            onClick={() => handleSearch(keyword)}><TbSearch /></button>
                                    </div>
                                </div>
                            </div> */}
                            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                            {postList?.map((post, i) => (
                                <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={post.id}>
                                    {(post.offer.length > 0 && post.data.status==2) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <a href={`../../post/offer/air/${post.id}`} target='_blank'>
                                            <button type="button" className="btn btn-primary">
                                            ข้อเสนอ <span className="badge badge-light" style={{ backgroundColor: '#ff0000'}}>{post.offer.length}</span>
                                            </button>
                                            </a>
                                        </div>)
                                    }
                                    {(post.data.status==3) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-warning" style={{ color: '#000000'}}>รอยืนยันการรับบริการ</button>
                                        </div>)
                                    }
                                    {(post.data.status==4) && 
                                        (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                            <button type="button" className="btn btn-success" style={{ color: '#ffffff'}}><strong>ดูข้อมูลนัดหมาย</strong></button>
                                        </div>)
                                    }
                                    {post.data.status==5 &&
                                    (<div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                        <button type="button" className="btn btn-danger" style={{ color: '#FFFFFF'}}><strong>ระงับการประกาศ</strong></button>
                                    </div>)
                                    }
                                    <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                        <img src={post.data.picture} alt="WEEE" height="150" />
                                    </div>
                                    <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                        <p className="card-text">
                                            {(post.data.wash_type1) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างเครื่องซักผ้าฝาบน</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ความจุ: 
                                                {(post.data.wash1_capacity == '1') && "ไม่เกิน 15 กก." }
                                                {(post.data.wash1_capacity == '2') && "15 - 19 กก."}
                                                {(post.data.wash1_capacity == '3') && "19 - 22กก."}
                                                {(post.data.wash1_capacity == '4') && "22 กก. ขึ้นไป"}
                                            <br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.data.wash1_amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                            {(post.data.wash_type2) && 
                                            <>
                                            <font style={{ fontSize: 17 }}><strong>บริการล้างเครื่องซักผ้าฝาหน้า</strong><br/></font>
                                            <font style={{ marginLeft: 10 }}>ความจุ: 
                                                {(post.data.wash2_capacity == '1') && "ไม่เกิน 15 กก." }
                                                {(post.data.wash2_capacity == '2') && "15 - 19 กก."}
                                                {(post.data.wash2_capacity == '3') && "19 - 22กก."}
                                                {(post.data.wash2_capacity == '4') && "22 กก. ขึ้นไป"}
                                            <br/></font>
                                            <font style={{ marginLeft: 10 }}>จำนวน: {number_format.format(post.data.wash2_amount)} เครื่อง<br/></font>
                                            </>
                                            }
                                        </p>
                                        <p className="card-text">
                                        </p>
                                        <p className="card-text"><strong>วันที่ประกาศ:</strong><br/>{dateFormat(post.data.post_timestamp)}</p>
                                    </div>
                                    <div className="card-body">
                                        {(post.data.status==1) && 
                                            (<button type="button" className="btn btn-warning m-1" onClick={() => navigate(`/post/edit/wash/${post.id}`)}><strong>แก้ไข</strong></button>)
                                        }
                                        {(post.data.status==2) && 
                                            (<button type="button" className="btn btn-danger m-1" onClick={() => {}}><strong>ปิดประกาศ</strong></button>)
                                        }
                                        {(post.data.status==3) && 
                                            (<button type="button" className="btn btn-danger m-1" onClick={() => handleOpenCancleOffer(post.id) }><strong>ยกเลิกการเรียกใช้งาน</strong></button>)
                                        }
                                        <Link className='btn btn-primary m-1' to={`/postwash/${post.id}`}><strong>รายละเอียด</strong></Link>
                                    </div>
                                </div>
                            ))}
                            {postList.length <= 0 && (
                                <div style={{ display: 'flex', alignItems: 'center'}}>
                                    <font className="text-danger" style={{ fontWeight: '600'}}>ไม่พบประกาศ</font>
                                </div>
                            )}
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog open={openChooseOffersShop} onClose={handleCloseAcceptPostShop} maxWidth="md">
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยื่นข้อเสนอ</strong></h3></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', marginTop: 10, flexDirection: 'column', width:'100%', marginBottom: 15 }}>
                                    <div style={{ backgroundColor: '#ffffff'}}>
                                        {/* <div className='text-danger'><h5>** การเลือกจะถือว่า ท่านยินยอมใช้รูปแบบการรับซ่อม และเงื่อนไข เป็นไปตามที่ผู้เสนอกำหนด</h5></div> */}
                                        <table className="table">
                                            <thead>
                                                <tr>
                                                    <th scope="col">#</th>
                                                    <th scope="col">ร้านค้า</th>
                                                    <th scope="col">ข้อเสนอ</th>
                                                    <th scope="col">ข้อตกลง</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                            {
                                                offerList?.map((offer, i) => (
                                                <tr key={i}>
                                                    <th scope="row">
                                                        <input className="form-check-input" type="radio" 
                                                            name="chooseOfferRadio" id="chooseOfferRadio" value={offer.id}
                                                            onClick={() => setConfirmOffer(offer.id)}
                                                        />
                                                    </th>
                                                    <td>
                                                        {(offer.shop.type==1)?offer.shop.firstname+" "+offer.shop.lastname:offer.shop.shopname}<br/>
                                                        <a href={`/shop/history/${offer.shopId}`} target='_blank'><AiFillInfoCircle style={{ fontSize: 20, marginBottom: 3 }} /></a>
                                                    </td>
                                                    <td>
                                                        <p style={{ marginBottom: 5 }}><strong>วันเวลาที่สะดวก:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.</p>
                                                    </td>
                                                    <td>
                                                        
                                                    </td>
                                                </tr>
                                                ))
                                            }
                                            </tbody>
                                        </table>
                                    </div>
                                    {openErrorDiv &&
                                        <div className="card-body text-danger border-danger" style={{ marginLeft: 10, marginTop:-5}}>
                                            <p className="font-weight-bold">** กรุณาเลือกข้อเสนอเพื่อยืนยันรับข้อเสนอ</p>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseAcceptPostShop} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleOfferSubmit} className='btn btn-success' style={{ fontFamily: 'Prompt' }}>ยืนยันรับข้อเสนอ</button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openCancleOffers} onClose={handleCloseCancleOffer}>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการเรียกใช้งาน</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                                <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5 }}>
                                                    <option value="1">ผู้เสนอบริการตอบกลับช้า</option>
                                                    <option value="2">ต้องการเปลี่ยนผู้เสนอบริการรายใหม่</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="row" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, width:"100%" }}>
                                                <textarea style={{ display: 'flex', flex: 1, width: 400, height: 100 }}
                                                    onChange={(e) => setCancleMessage(e.target.value)}
                                                    placeholder='เหตุผลเพิ่มเติม' 
                                                    value={cancleMessage}></textarea>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseCancleOffer} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleCancleOffer} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการเรียกใช้งาน</button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}

export default PostsFix;