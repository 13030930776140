import React, { useState, useEffect } from 'react';
import { collection, query, onSnapshot } from "firebase/firestore"
import { db } from "../../assets/js/firebase";
import { useNavigate } from "react-router-dom";
import { TbCirclePlus } from "react-icons/tb";
import { AiFillInfoCircle } from "react-icons/ai";
import { RiAdminLine } from "react-icons/ri"
import { FiEdit } from "react-icons/fi";

const AdminList = () => {
    const [users, setUsers] = useState([])
    const navigate = useNavigate();

    useEffect(() => {
        const userQuery = query(collection(db, 'admins'))
        onSnapshot(userQuery, (querySnapshot) => {
            setUsers(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    },[])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><RiAdminLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายชื่อผู้ดูแลระบบ</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <div style={{ paddingRight: 10, marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/user/add`) }}>
                        <TbCirclePlus style={{ fontSize: 20, marginRight: 5 }} /> <b>เพิ่มผู้ดูแลระบบ</b>
                    </button>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                <table className="table" style={{ marginLeft: 10}}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}> </th>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>Email</th>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ชื่อ-นามสกุล (ชื่อผู้ใช้)</th>
                            <th scope="col" style={{ backgroundColor: '#eeeeee'}}> </th>
                        </tr>
                    </thead>
                    <tbody>
                    {
                        users?.map((user, i) => (
                            <tr key={i}>
                                <td style={{ paddingLeft: 10 }}> </td>
                                <th scope="row">{user.data.email}</th>
                                <td>
                                    <font style={{ marginRight: 10}}>{user.data.firstname?user.data.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                    <font style={{ marginRight: 10}}>{user.data.lastname?user.data.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                    <font style={{ marginRight: 10}}>({user.data.display_name?user.data.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font>
                                </td>
                                <td style={{ display: 'flex', flexDirection: 'row'}}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/user/edit/${user.id}`) }}><FiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขข้อมูล</button>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/user/view/${user.id}`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูล</button>
                                </td>
                            </tr>
                        ))
                    }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default AdminList;