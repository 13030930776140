import React, { useState, useMemo, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, doc, getDoc, onSnapshot, orderBy } from 'firebase/firestore'

import { TbUser, TbChevronRight, TbMessageCircle2, TbTool, TbHexagonNumber2 } from "react-icons/tb"
import { RiExchangeFundsFill } from "react-icons/ri"
import { IoMegaphoneOutline } from "react-icons/io5"
import { AiOutlineShop, AiFillExclamationCircle } from "react-icons/ai"
import { MdOutlineLocalOffer, MdAttachMoney } from "react-icons/md"
import { BsCalendarDate, BsMegaphone } from "react-icons/bs"
import { HiQueueList } from "react-icons/hi2"
import { HiOutlineDocumentReport } from "react-icons/hi"
import { GoGear } from "react-icons/go"
import './Shop.css'

const LeftMenu = ({currentPage}) => {    
    const [user, loading, error] = useAuthState(auth)
    const [shopId, setShopId] = useState('')
    const [newNoti, setNewNoti] = useState(false)
    const [queue, setQueue] = useState(true)
    const [spare, setSpare] = useState(true)
    const [technician, setTechnician] = useState(true)

    const getSettings = () => {
        getDoc(doc(db, 'settings', 'functions')).then((result) => {
            const data = result.data()
            if(data.queue) { setQueue(true) } else { setQueue(false) }
            if(data.spare) { setSpare(true) } else { setSpare(false) }
            if(data.technician) { setTechnician(true) } else { setTechnician(false) }
        })
    }

    const getNewNoti = () => {
    }

    const getDocShop = async() => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user.uid));
            const docs = await getDocs(q);
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setShopId(data.shop_id)

                const notiQuery = query(collection(db, `shops/${data.shop_id}/notifications`), where('status', '==', 0), orderBy('date', 'desc'))
                onSnapshot(notiQuery, (querySnapshot) => {
                    if(querySnapshot.docs.length > 0) setNewNoti(true)
                })

                const messageQuery = query(collection(db, `shops/${data.shop_id}/system_message`), where('status', '==', 0), orderBy('date', 'desc'))
                onSnapshot(messageQuery, (querySnapshot) => {
                    if(querySnapshot.docs.length > 0) setNewNoti(true)
                })
            }
        } catch (err) {
            alert(err.message);
        }
    }
    
    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else { 
            getDocShop()
            getNewNoti()
        }
        getSettings()
    }, [user, loading])
    
    const navigate = useNavigate()
    return (            
        <div style={{ display: 'flex', width: 250, flexDirection: 'column' }}>
            <div className="d-flex flex-column p-2" style={{ width: 250 }}>
                <button type="button" className="btn btn-primary" style={{  }} onClick={() => { return navigate("/profile/")}}><TbUser style={{ fontSize: 20, marginRight: 5, marginBottom: 3 }} />จัดการข้อมูลส่วนตัว</button>
            </div>
            <div className="d-flex flex-column p-2" style={{ width: 250 }}>
                <button type="button" className="btn btn-success" style={{  }} onClick={() => { return navigate(`/shop/postonboard/${shopId}`) }}><TbHexagonNumber2 style={{ fontSize: 20, marginRight: 5, marginBottom: 5 }} />ลงประกาศขายของมือสอง</button>
            </div>
            <div className="d-flex flex-column p-2" style={{ width: 250, marginRight: 20 }}>
                <ul className="nav flex-column mb-auto nav-underline">
                    <li>
                        <a href="/shop" className={(currentPage=="profile")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <AiOutlineShop style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <strong>ข้อมูลร้านค้า</strong>
                            { (currentPage=="profile") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href={`/shop/notifications/${shopId}`} className={(currentPage=="chats")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <TbMessageCircle2 style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อความ</b>
                            { (currentPage=="chats") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                            {newNoti &&
                            <AiFillExclamationCircle className='text-danger' style={{ fontSize: 25, marginLeft: 5, marginRight: 5, paddingBottom: 3, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href={`/shop/ads/${shopId}`} className={(currentPage=="ads")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <BsMegaphone style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการโฆษณา</b>
                            { (currentPage=="ads") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href={`/shop/posts/${shopId}`} className={(currentPage=="posts")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการประกาศร้านค้า</b>
                            { (currentPage=="posts") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href={`/shop/appointmentposts/${shopId}`} className={(currentPage=="appointmentposts")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการนัดรับ/ส่งสินค้า</b>
                            { (currentPage=="appointmentposts") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="/shop/offers" className={(currentPage=="offers")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <MdOutlineLocalOffer style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการข้อเสนอ</b>
                            { (currentPage=="offers") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="/shop/appointments" className={(currentPage=="appointments")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <BsCalendarDate style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการนัดหมาย</b>
                            { (currentPage=="appointments") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href={`/shop/finances/${shopId}`} className={(currentPage=="finances")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <MdAttachMoney style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการรายรับ-รายจ่าย</b>
                            { (currentPage=="finances") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    {technician && 
                    <li>
                        <a href={`/shop/technicians/${shopId}`} className={(currentPage=="technicians")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <TbTool style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการช้อมูลช่าง</b>
                            { (currentPage=="technicians") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    }
                    {queue && 
                    <li>
                        <a href={`/shop/queues/${shopId}`} className={(currentPage=="coins")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <HiQueueList style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการคิวงานช่าง</b>
                            { (currentPage=="coins") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    }
                    {spare && 
                    <li>
                        <a href={`/shop/spares/${shopId}`} className={(currentPage=="spares")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <GoGear style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการอะไหล่</b>
                            { (currentPage=="spares") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    }
                    <li>
                        <a href={`/shop/coins/${shopId}`} className={(currentPage=="coins")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <RiExchangeFundsFill style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>เหรียญ WEEE</b>
                            { (currentPage=="coins") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href={`/shop/reports/${shopId}`} className={(currentPage=="coins")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <HiOutlineDocumentReport style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>รายงาน</b>
                            { (currentPage=="coins") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default LeftMenu;