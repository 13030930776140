import { getDoc, doc } from 'firebase/firestore'

export async function getUserTitle(db, userId) {
    var title = getDoc(doc(db, `users`, userId)).then((doc) => {
        title = doc.data().firstname+" "+doc.data().lastname
        
        return title
    })

    return title
}