import React, { useState, useEffect, useRef } from 'react'
import { db } from "../../assets/js/firebase"
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, deleteDoc, query, where } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { FiInfo, FiXCircle, FiCheckCircle } from "react-icons/fi"

import { Loading } from "../layouts/Loading"
import { addUserNotification, addShopNotification } from "../../../assets/js/NotificationHelper"
import { addShopCoinLog, addUserCoinLog, addPlatformCoinLog, addExchangeLog } from "../../../assets/js/LogHelper"

export function OfferShopBoard3({offer, offerExchangeCoin}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(false)

    const postOfferId = offer.data.offer_id
    const postId = offer.post_id
    const shopOfferId = offer.id
    const shopId = offer.shop_id
    const userId = offer.post.poster_id

    const [cancleMessage, setCancleMessage] = useState('')
    const [cancleReason, setCancleReason] = useState(1)

    const [openCancleOffer, setOpenCancleOffer] = useState(false)
    const [openShopConfirm, setOpenShopConfirm] = useState(false)
    const [openShopCancle, setOpenShopCancle] = useState(false)

    const [depositCoin, setDepositCoin] = useState(0)
    const [depositEnough, setDepositEnough] = useState(false)
    const [depositMessage, setDepositMessage] = useState('')
    const [remainCoin, setRemainCoin] = useState(0)
    const [coinRate, setCoinRate] = useState(0)
    const [allCoin, setAllCoin] = useState(0)

    const calDeposit = () => {
        var price = offer.data.price * 1
        const depositPercent = Math.ceil((price * 30)/100)
        const remainPercent = Math.ceil((price * 70)/100)

        getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeSnapshot) => {
            const rate = exchangeSnapshot.data().money_to_coin_1 * 1
            const depositCoin = Math.ceil(depositPercent/rate)
            const remainCoin = Math.ceil(remainPercent/rate)
            const allUseCoin = depositCoin + remainCoin
            setCoinRate(rate)
            setDepositCoin(depositCoin)
            setAllCoin(allUseCoin)
            setRemainCoin(remainCoin)
            if(allUseCoin < offerExchangeCoin) { 
                setDepositEnough(true)
                setDepositMessage('คุณมีเหรียญทองจำนวน '+number_format.format(offerExchangeCoin)+' เหรียญ คุณจะต้องใช้ทั้งหมด '+number_format.format(allUseCoin)+' เหรียญ แบ่งเป็น '+number_format.format(depositCoin)+' เหรียญ (30% ของราคาซื้อขาย) เพื่อมัดจำการจองสินค้า และอีก '+number_format.format(remainCoin)+' เหรียญ (70% ของราคาซื้อขาย) เพื่อซื้อสินค้า?') 
            } else  { 
                setDepositEnough(false) 
                setDepositMessage('คุณมีจำนวนเหรียญทองไม่พอ คุณมี '+number_format.format(offerExchangeCoin)+' เหรียญ คุณจะต้องมีทั้งหมด '+number_format.format(allUseCoin)+' เหรียญ แบ่งเป็น '+number_format.format(depositCoin)+' เหรียญ (30% ของราคาซื้อขาย) เพื่อมัดจำการจองสินค้า และอีก '+number_format.format(remainCoin)+' เหรียญ (70% ของราคาซื้อขาย) เพื่อซื้อสินค้า')
            }
        })
    }
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]+" น."
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleCloseShopConfirm = () => {
        setOpenShopConfirm(false)
    }

    const handleOpenShopConfirm = () => {
        setOpenShopConfirm(true)
    }

    const handleShopConfirm = () => {
        setShowLoading(true)
        const logDate = new Date()

        updateDoc(doc(db, `/posts`, postId), { status: 4 }).then(() => {
            addShopCoinLog(db, shopId, depositCoin, 'จ่ายเหรียญเพื่อจองสินค้า 30%', '2', "post", postId, "shop", "platform", false)
            addShopCoinLog(db, shopId, remainCoin, 'จ่ายเหรียญเพื่อสำรองซื้อสินค้า 70%', '3', "post", postId, "shop", "platform", false)
            
            updateDoc(doc(db, 'shops/', shopId), {
                exchange_coin: (offerExchangeCoin * 1)-((depositCoin * 1) + (remainCoin * 1))
            }).then(() => {
                addUserNotification(db, userId, 'ร้านค้ายืนยันจองสินค้าแล้ว', 'offer', postId, '/profile/posts/second/'+postId)
                addShopNotification(db, shopId, "คุณจ่ายเหรียญเพื่อจองสินค้า จำนวน "+number_format.format(depositCoin)+" เหรียญ", 'offer', postId, '/post/'+postId)
                addShopNotification(db, shopId, "จ่ายเหรียญเพื่อสำรองซื้อสินค้า จำนวน "+number_format.format(remainCoin)+" เหรียญ", 'offer', postId, '/post/'+postId)
                addExchangeLog(db, depositCoin, 'มัดจำ 30% ของราคาเพื่อจองสินค้า', shopId, 'offer')
                
                getDoc(doc(db, 'exchange_coins', 'sum_data')).then((freeSnapshot) => {
                    const data = freeSnapshot.data()
                    updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                        remain: (data.remain*1) + (depositCoin*1),
                        used: (data.used*1) + (depositCoin*1)
                    })
                })
            }).then(() => {
                addDoc(collection(db, 'appointments'), {
                    post_id: postId,
                    offer_id: postOfferId,
                    shop_offer_id: shopOfferId,
                    user_id: userId,
                    shop_id: shopId,
                    appointment_type: "post",
                    post_onboard: 3,
                    appointment_date: offer.data.pick_date,
                    appointment_hour: offer.data.hour,
                    appointment_minute: offer.data.minute,
                    appointment_timestamp: logDate.getTime(),
                    deposit_coin: depositCoin,
                    remain_coin: remainCoin,
                    status: 1
                }).then((docRef) => {
                    /* start - ดึงเหรียญเข้า reserve coin */
                    addDoc(collection(db, `shops/${shopId}/reserve_coin/`), {
                        appointment_id: docRef.id,
                        post_id: postId,
                        offer_id: postOfferId,
                        shop_offer_id: shopOfferId,
                        user_id: userId,
                        appointment_type: "post",
                        post_onboard: 3,
                        coin: remainCoin * 1,
                        reserve_timestamp: logDate.getTime(),
                        status: 1
                    }).then(async() => {
                        await updateDoc(doc(db, `/posts/${postId}/offers`, postOfferId), { status: 3 })
                        await updateDoc(doc(db, `/shops/${shopId}/offers`, shopOfferId), { status: 3 })
                    }).then(() => {
                        setShowLoading(false)
                        navigate(0)
                    })
                    /* end - ดึงเหรียญเข้า reserve coin */
                })
            })
        })
    }

    const handleCloseCancleOffer = () => {
        setOpenCancleOffer(false)
    }

    const handleOpenCancleOffer = () => {
        setOpenCancleOffer(true)
    }

    const handleCancleOffer = () => {
        setShowLoading(true)
        const logDate = new Date()
        var promisesTask = []
        var addCancleHistoryTask = addDoc(collection(db, `posts/${postId}/cancle_offers`), {
            shop_id: shopId,
            cancle_reason: cancleReason,
            cancle_message: cancleMessage,
            cancle_datetime: logDate.getTime()
        })
        promisesTask.push(addCancleHistoryTask)

        var deletePostOfferTask = deleteDoc(doc(db, `posts/${postId}/offers`, postOfferId)).then(() => {
            getDocs(query(collection(db, `posts/${postId}/offers`), where("status", "!=", 5))).then((docs) => {
                var status = 1
                if (docs.docs.length > 0) { status = 2 }
                updateDoc(doc(db, `posts/`, postId), {
                    status: status
                })
            })
        })
        promisesTask.push(deletePostOfferTask)

        var deleteShopOfferTask = deleteDoc(doc(db, `shops/${shopId}/offers`, shopOfferId))
        promisesTask.push(deleteShopOfferTask)

        Promise.all(promisesTask).then(() => {
            setOpenCancleOffer(false)
            setShowLoading(false)
        })
    }

    const handleCloseShopCancle = () => {
        setOpenShopCancle(false)
    }

    const handleOpenShopCancle = () => {
        setOpenShopCancle(true)
    }

    const handleShopCancle = () => {
        setShowLoading(true)
        const logDate = new Date()
        
        updateDoc(doc(db, `posts/${postId}/offers`, postOfferId), {
            status: 5,
            cancle_date: logDate.getTime(),
            cancle_reason: cancleReason,
            cancle_message: cancleMessage
        }).then(() => {
            getDocs(query(collection(db, `posts/${postId}/offers`), where("status", "!=", 5))).then((docs) => {
                var promisesTask = []
                var postStatus = 1
                if (docs.docs.length > 0) { 
                    postStatus = 2 
                    var updateExistOfferTask = updateDoc(doc(db, `posts/${postId}/offers`, where("status", "!=", 5)), {
                        status: 1
                    })
                    promisesTask.push(updateExistOfferTask)
                }
                var updatePostTask = updateDoc(doc(db, `posts/`, postId), {
                    status: postStatus
                })
                promisesTask.push(updatePostTask)

                var updateAddNotiTask = addDoc(collection(db, `/users/${userId}/notifications`), {
                    date: logDate.getTime(),
                    message: "ผู้เสนอยกเลิกข้อเสนอ กรุณาเลือกข้อเสนอรายใหม่",
                    link: "/profile/posts",
                    type: 'offer',
                    offer_id: postOfferId,
                    status: 0
                })
                promisesTask.push(updateAddNotiTask)

                var updateShopTask = updateDoc(doc(db, `shops/${shopId}/offers`, shopOfferId), {
                    status: 5
                })
                promisesTask.push(updateShopTask)

                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    useEffect(() => {
        calDeposit()
    })

    return (
        <>
            <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
            <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}  key={offer.post_id}>
                {offer.badgeStyle.show && 
                <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                    <button type="button" className={"btn "+"btn-"+offer.badgeStyle.class} style={{ color: offer.badgeStyle.color }}><strong>{offer.badgeStyle.message}</strong></button>
                </div>}
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                    <img src={offer.post.picture} alt="APP3R" height="150" />
                </div>
                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                    <p className="card-text">
                        <strong>ขายเครื่องใช้ไฟฟ้ามือสอง</strong><br/>
                        {offer.data.type} {offer.brand} {offer.model}<br/>
                        <strong>ราคาเสนอซื้อ:</strong> {number_format.format(offer.data.price)} บาท <br/>
                        <strong>ราคาประกาศขาย:</strong> {number_format.format(offer.post.price)} บาท <br/>
                        <strong>ที่อยู่:</strong> {offer.post.address} <br/>
                        <strong>วันที่ยื่นข้อเสนอ:</strong> {dateFormat(offer.data.offer_timestamp)} <br/>
                        <strong>ข้อเสนอ:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.
                    </p>
                </div>
                <div className="card-body">
                    <Link className="btn btn-primary m-1" to={`/postFix/${offer.post_id}`} target='_blank'><FiInfo /> <strong>ดูประกาศ</strong></Link>
                    <Link className="btn btn-primary m-1" to={`/shop/postdetail/${offer.post.onboard}/${offer.post_id}/${offer.shop_id}`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                    {(offer.data.status==1) && (<>
                        <button type="button" className="btn btn-danger m-1" onClick={() => handleOpenCancleOffer() }><FiXCircle /> <strong>ยกเลิกข้อเสนอซื้อสินค้า</strong></button>
                    </>)}
                    {(offer.data.status==2) && (<>
                        <button type="button" className="btn btn-success m-1" onClick={() => handleOpenShopConfirm() }><FiCheckCircle /> <strong>ยืนยันการจองสินค้า</strong></button>
                        <button type="button" className="btn btn-danger m-1" onClick={() => handleOpenShopCancle() }><FiXCircle /> <strong>ยกเลิกการจองสินค้า</strong></button>
                    </>)}
                </div>
            </div>
            
            <Dialog open={openCancleOffer} onClose={handleCloseCancleOffer} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกข้อเสนอรับซื้อเครื่องใช้ไฟฟ้ามือสอง</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5, flex: 1 }}>
                                                <option value="1">หลังจากเสนอฯ ผู้ประกาศตอบกลับช้า</option>
                                                <option value="2">รับงานใหม่</option>
                                                <option value="3">อื่นๆ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setCancleMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={cancleMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleOffer} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleOffer} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกข้อเสนอรับซื้อเครื่องใช้ไฟฟ้ามือสอง</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openShopConfirm} onClose={handleCloseShopConfirm} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันรับซื้อเครื่องใช้ไฟฟ้ามือสอง</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันรับซื้อเครื่องใช้ไฟฟ้ามือสอง?</h5>
                                    <h6><strong>ราคาซื้อขาย : </strong>{number_format.format(offer.data.price)} บาท</h6>
                                    <h6><strong>อัตราแลกเปลี่ยนเหรียญทอง: </strong>{number_format.format(coinRate)} บาท เท่ากับ 1 เหรียญทอง</h6>
                                    <h6><strong>จำนวนเหรียญมัดจำ: </strong>{number_format.format(depositCoin)} เหรียญทอง</h6>
                                    <h6><strong>จำนวนเหรียญที่จะต้องจ่ายหลังซื้อขาย: </strong>{number_format.format(remainCoin)} เหรียญทอง</h6>
                                    {depositEnough && <h6><strong>{depositMessage}</strong></h6>}
                                    {!depositEnough && <h6 className='text-danger'><strong>{depositMessage}</strong></h6>}
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShopConfirm} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    {depositEnough &&
                    <button onClick={handleShopConfirm} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันรับซื้อเครื่องใช้ไฟฟ้ามือสอง</button>
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={openShopCancle} onClose={handleCloseShopCancle} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการจองเครื่องใช้ไฟฟ้ามือสอง</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5, flex: 1 }}>
                                                <option value="1">ไม่สะดวกไปตามเวลานัดหมาย</option>
                                                <option value="2">รับงานใหม่</option>
                                                <option value="3">อื่นๆ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100 }}
                                                onChange={(e) => setCancleMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={cancleMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShopCancle} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleShopCancle} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการจองเครื่องใช้ไฟฟ้ามือสอง</button>
                </DialogActions>
            </Dialog>
        </>
    )
}