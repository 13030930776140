import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, doc, getDoc, getDocs, or, updateDoc, where, addDoc } from "firebase/firestore"
import { db } from "../../assets/js/firebase";
import { useNavigate, useParams } from "react-router-dom";
import { AiFillShop, AiFillInfoCircle, AiOutlineCheckCircle } from "react-icons/ai"
import { BiMoneyWithdraw, BiUserCircle } from "react-icons/bi"
import { PiHandCoinsDuotone, PiCoinsDuotone } from "react-icons/pi"
import { RiExchangeFundsFill } from "react-icons/ri"
import { FiEdit } from "react-icons/fi"
import { HiOutlineDocumentSearch } from "react-icons/hi"
import "../../assets/css/memberList.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { Dialog, DialogActions, DialogContent, DialogTitle } from '@mui/material';
import { Card, CardContent, CardActions } from '@mui/material'
import { Typography, Paper, TextField } from '@mui/material'

import { addUserNotification, addShopNotification } from "../../../assets/js/NotificationHelper"

const Exchanges = () => {
    const navigate = useNavigate();
    const params = useParams()
    const number_format = new Intl.NumberFormat();

    const [buyCoins, setBuyCoins] = useState([])
    const [sellCoins, setSellCoins] = useState([])

    const [openBuyApprove, setOpenBuyApprove] = useState(false)
    const [buyId, setBuyId] = useState('')
    const [buyDate, setBuyDate] = useState('')
    const [buyRate, setBuyRate] = useState('')
    const [buyRateNum, setBuyRateNum] = useState(0)
    const [buyAmount, setBuyAmount] = useState('')
    const [buyAmountNum, setBuyAmountNum] = useState(0)
    const [buyUserName, setBuyUserName] = useState('')
    const [buySlip, setBuySlip] = useState('')
    const [buyMemberId, setBuyMemmberId] = useState('')
    const [buyMemberType, setBuyMemmberType] = useState('user')
    const [memberCurrentCoin, setMemberCurrentCoin] = useState(0)

    const [openBuyCancle, setOpenBuyCancle] = useState(false)
    
    const dateTimeFormat = (datetime) => {
        const date_string = new Date(datetime.seconds * 1000).toLocaleDateString("th-TH");
        const time_string = new Date(datetime.seconds * 1000).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        const date_sprit = date_string.split('/')
        return date_sprit[0]+" "+textMonth(date_sprit[1])+" "+date_sprit[2]+" เวลา "+time[0]+":"+time[1]+" น."
    }

    const textMonth = (month) => {
        var text = ""
        switch(month) {
            case "1": text = "มกราคม"; break;
            case "2": text = "กุมภาพันธ์"; break;
            case "3": text = "มีนาคม"; break;
            case "4": text = "เมษายน"; break;
            case "5": text = "พฤษภาคม"; break;
            case "6": text = "มิถุนายน"; break;
            case "7": text = "กรกฎาคม"; break;
            case "8": text = "สิงหาคม"; break;
            case "9": text = "กันยายน"; break;
            case "10": text = "ตุลาคม"; break;
            case "11": text = "พฤศจิกายน"; break;
            case "12": text = "ธันวาคม"; break;
        }
        return text
    }

    const handleBuyApprove = () => {
        updateDoc(doc(db, 'exchange_coins/buy_coin/transaction', buyId), {
            status: 2
        }).then(() => {
            if(buyMemberType == 'user') {
                updateDoc(doc(db, 'users', buyMemberId), {
                    exchange_coin: memberCurrentCoin+(buyAmountNum*1)
                }).then(() => {
                    const transaction_date = new Date()
                    addDoc(collection(db, `users/${buyMemberId}/coin_log`), {
                        date: transaction_date,
                        coin: buyAmountNum*1,
                        rate_per_coin: buyRateNum*1,
                        event: 'buy'
                    }).then(() => {
                        addUserNotification(db, buyMemberId, "คุณได้แลกเหรียญทองจำนวน "+buyAmountNum+" เหรียญ", 'coin', '', '/profile/coins')
                        getDoc(doc(db, 'exchange_coins', 'sum_data')).then((sumDoc) => {
                            updateDoc(doc(db, 'exchange_coins', 'sum_data'), {
                                remain:(sumDoc.data().remain*1) - (buyAmountNum*1), 
                                exchange: (sumDoc.data().exchange*1) + (buyAmountNum*1)
                            }).then(() => {
                                setOpenBuyApprove(false)
                                navigate(0)
                            })
                        })
                    })
                })
            } else {
                updateDoc(doc(db, 'shops', buyMemberId), {
                    exchange_coin: memberCurrentCoin+(buyAmountNum*1)
                }).then(() => {
                    const transaction_date = new Date()
                    addDoc(collection(db, `shops/${buyMemberId}/coin_log`), {
                        date: transaction_date,
                        coin: buyAmountNum*1,
                        rate_per_coin: buyRateNum*1,
                        event: 'buy'
                    }).then(() => {
                        addShopNotification(db, buyMemberId, "คุณได้แลกเหรียญทองจำนวน "+buyAmountNum+" เหรียญ", 'coin', '', '/shop/coins/'+buyMemberId)
                        getDoc(doc(db, 'exchange_coins', 'sum_data')).then((sumDoc) => {
                            updateDoc(doc(db, 'exchange_coins', 'sum_data'), {
                                remain:(sumDoc.data().remain*1) - (buyAmountNum*1), 
                                exchange: (sumDoc.data().exchange*1) + (buyAmountNum*1)
                            }).then(() => {
                                setOpenBuyApprove(false)
                                navigate(0)
                            })
                        })
                    })
                })
            }
        })
    }

    const handleOpenBuyApprove = (buy) => {
        setOpenBuyApprove(true)
        setBuyId(buy.id)
        if(buy.data.user_id) {
            getDoc(doc(db, 'users', buy.data.user_id)).then((memberDoc) => {
                setBuyUserName(memberDoc.data().firstname+" "+memberDoc.data().lastname)
                setBuyRate(number_format.format(buy.data.rate_per_coin)+" บาท/เหรียญ")
                setBuyRateNum(buy.data.rate_per_coin*1)
                setBuyDate(dateTimeFormat(buy.data.date))
                setBuyAmount(number_format.format(buy.data.coin)+" เหรียญ")
                setBuyAmountNum(buy.data.coin)
                setBuySlip(buy.data.buy_slip)

                setBuyMemmberId(buy.data.user_id)
                setBuyMemmberType('user')
                if(memberDoc.data().exchange_coin) setMemberCurrentCoin(memberDoc.data().exchange_coin*1)
            })
        } else {
            getDoc(doc(db, 'shops', buy.data.shop_id)).then((memberDoc) => {
                if(memberDoc.data().shopname) {
                    setBuyUserName(memberDoc.data().shopname)
                } else {
                    setBuyUserName(memberDoc.data().firstname+" "+memberDoc.data().lastname)
                }
                setBuyRate(number_format.format(buy.data.rate_per_coin)+" บาท/เหรียญ")
                setBuyRateNum(buy.data.rate_per_coin*1)
                setBuyDate(dateTimeFormat(buy.data.date))
                setBuyAmount(number_format.format(buy.data.coin)+" เหรียญ")
                setBuyAmountNum(buy.data.coin)
                setBuySlip(buy.data.buy_slip)

                setBuyMemmberId(buy.data.shop_id)
                setBuyMemmberType('shop')
                if(memberDoc.data().exchange_coin) setMemberCurrentCoin(memberDoc.data().exchange_coin*1)
            })
        }
    }

    const handleCloseBuyApprove = () => {
        setOpenBuyApprove(false)
    }

    const handleBuyCancle = () => {
        updateDoc(doc(db, 'exchange_coins/buy_coin/transaction', buyId), {
            status: 3
        }).then(() => {
            setOpenBuyApprove(false)
            navigate(0)
        })
    }

    const handleOpenBuyCancle = (buy) => {
        setOpenBuyCancle(true)
        setBuyId(buy.id)
        if(buy.data.user_id) {
            getDoc(doc(db, 'users', buy.data.user_id)).then((memberDoc) => {
                setBuyUserName(memberDoc.data().firstname+" "+memberDoc.data().lastname)
                setBuyRate(number_format.format(buy.data.rate_per_coin)+" บาท/เหรียญ")
                setBuyDate(dateTimeFormat(buy.data.date))
                setBuyAmount(number_format.format(buy.data.coin)+" เหรียญ")
                setBuySlip(buy.data.buy_slip)
                setBuyMemmberType('user')
            })
        } else {
            getDoc(doc(db, 'shops', buy.data.shop_id)).then((memberDoc) => {
                if(memberDoc.data().shopname) {
                    setBuyUserName(memberDoc.data().shopname)
                } else {
                    setBuyUserName(memberDoc.data().firstname+" "+memberDoc.data().lastname)
                }
                setBuyRate(number_format.format(buy.data.rate_per_coin)+" บาท/เหรียญ")
                setBuyDate(dateTimeFormat(buy.data.date))
                setBuyAmount(number_format.format(buy.data.coin)+" เหรียญ")
                setBuySlip(buy.data.buy_slip)
                setBuyMemmberType('shop')
            })
        }
    }

    const handleCloseBuyCancle = () => {
        setOpenBuyCancle(false)
    }

    useEffect(() => {
        const buyQuery = query(collection(db, 'exchange_coins/buy_coin/transaction'), where('status', '==', 1), orderBy('date', 'desc'))
        onSnapshot(buyQuery, (querySnapshot) => {
            querySnapshot.forEach((buy) => {
                if(buy.data().user_id) {
                    console.log(buy.data().user_id)
                    getDoc(doc(db, 'users', buy.data().user_id)).then((memberDoc) => {
                        buyCoins.push({
                            id: buy.id,
                            data: buy.data(),
                            user: memberDoc.data(),
                            type: 'user',
                            view_id: buy.data().user_id
                        })
                        setBuyCoins([...buyCoins, {
                            id: buy.id,
                            data: buy.data(),
                            user: memberDoc.data(),
                            type: 'user',
                            view_id: buy.data().user_id
                        }])
                    }).catch(() => {
                        getDoc(doc(db, 'shops', buy.data().user_id)).then((memberDoc) => {
                            buyCoins.push({
                                id: buy.id,
                                data: buy.data(),
                                user: memberDoc.data(),
                                type: 'shop',
                                view_id: buy.data().user_id
                            })
                            setBuyCoins([...buyCoins, {
                                id: buy.id,
                                data: buy.data(),
                                user: memberDoc.data(),
                                type: 'shop',
                                view_id: buy.data().user_id
                            }])
                        })
                    })
                } else {
                    getDoc(doc(db, 'shops', buy.data().shop_id)).then((memberDoc) => {
                        buyCoins.push({
                            id: buy.id,
                            data: buy.data(),
                            user: memberDoc.data(),
                            type: 'shop',
                            view_id: buy.data().shop_id
                        })
                        setBuyCoins([...buyCoins, {
                            id: buy.id,
                            data: buy.data(),
                            user: memberDoc.data(),
                            type: 'shop',
                            view_id: buy.data().shop_id
                        }])
                    })
                }
            })
        })
    },[])

    var buyKey = []
    var buyList = []
    buyCoins.forEach(element => {
        if(buyKey.lastIndexOf(element.id) < 0) {
            buyKey.push(element.id)
            buyList.push(element)
        }
    });

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><PiHandCoinsDuotone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>ซื้อ-ขายเหรียญ WEEE</b></h4>
            </div>
            <div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column' }}>
                <Paper elevation={2} style={{ display: 'flex', flex: 1, minHeight: 250, marginBottom: 10, flexDirection: 'column' }}>
                    <div className='bg-primary' style={{ height: 50, backgroundColor: "#dddddd", paddingTop: 8, paddingLeft: 20}}>
                        <h3 className='text-white'><strong><PiCoinsDuotone style={{ marginRight: 8 }} />ข้อมูลการซื้อเหรียญ</strong></h3>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 25, marginTop: 15, marginBottom: 15, float: 'right'}}>
                        <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { navigate(`/admin/coins/free/`) }}>
                            <AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูประวัติการซื้อเหรียญ
                        </button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <table className="table" style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 10}}> </th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>วันที่ซื้อ</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>จำนวนที่ซื้อ</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ราคา/อัตราแลกเปลี่ยน</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}> </th>
                                </tr>
                            </thead>
                            <tbody>
                            {
                                buyList?.map((buy, i) => (
                                <tr key={i}>
                                    <td style={{ paddingLeft: 10 }}> </td>
                                    <td scope="row">{dateTimeFormat(buy.data.date)}</td>
                                    <th scope="row">{number_format.format(buy.data.coin)} เหรียญ</th>
                                    <td scope="row">
                                        {number_format.format(buy.data.amount)} บาท
                                        <br/>
                                        ({number_format.format(buy.data.rate_per_coin)} บาท/เหรียญ)
                                    </td>
                                    <td scope='row'>
                                        <div style={{ display: 'flex', flexDirection: 'row'}}>
                                            <a href={buy.data.buy_slip} target='_blank' className="btn btn-primary btn-sm" style={{ marginRight: 5}}><HiOutlineDocumentSearch style={{ fontSize: 20, marginRight: 5 }} />หลักฐานการโอน</a>
                                            {buy.type=='user'?
                                            (<a href={`/admin/member/view/${buy.view_id}`} target='_blank' className="btn btn-primary btn-sm" style={{ marginRight: 5}}><BiUserCircle style={{ fontSize: 20, marginRight: 5 }} />ข้อมูลผู้ซื้อ</a>):
                                            (<a href={`/admin/shop/view/${buy.view_id}`} target='_blank' className="btn btn-primary btn-sm" style={{ marginRight: 5}}><BiUserCircle style={{ fontSize: 20, marginRight: 5 }} />ข้อมูลร้านค้า</a>)}
                                            
                                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenBuyApprove(buy) }}><AiOutlineCheckCircle style={{ fontSize: 20, marginRight: 5 }} />อนุมัติการโอน</button>
                                            <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { handleOpenBuyCancle(buy) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก</button>
                                        </div>
                                    </td>
                                </tr>
                                ))
                            }
                            </tbody>
                        </table>
                    </div>
                </Paper>
                <Paper elevation={2} style={{ display: 'flex', flex: 1, minHeight: 250, marginTop: 10, flexDirection: 'column' }}>
                    <div className='bg-success' style={{ height: 50, backgroundColor: "#dddddd", paddingTop: 8, paddingLeft: 20}}>
                        <h3 className='text-white'><strong><PiCoinsDuotone style={{ marginRight: 8 }} />ข้อมูลการขายเหรียญ</strong></h3>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', paddingLeft: 25, marginTop: 15, marginBottom: 15, float: 'right'}}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { navigate(`/admin/coins/exchange/`) }}>
                            <AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูประวัติการขายเหรียญ
                        </button>
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row'}}>
                        <table className="table" style={{ marginLeft: 10, marginRight: 10, marginBottom: 10 }}>
                            <thead>
                                <tr>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 50}}> </th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>วันที่ซื้อ</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>จำนวนที่ซื้อ</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ราคา/อัตราแลกเปลี่ยน</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ผู้ซื้อ</th>
                                    <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>หลักฐานการโอน</th>
                                </tr>
                            </thead>
                            <tbody></tbody>
                        </table>
                    </div>
                </Paper>
            </div>
            <Dialog open={openBuyApprove} onClose={handleCloseBuyApprove}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>อนุมัติการซื้อเหรียญ</strong></div></DialogTitle>
                <DialogContent>
                        <strong>วันที่ซื้อ:</strong> {buyDate}<br/>
                        <strong>{buyMemberType=='user'?'ผู้ซื้อ':'ร้านค้า'}:</strong> {buyUserName}<br/>
                        <strong>จำนวนที่ซื้อ:</strong> {buyAmount}<br/>
                        <strong>อัตราแลกเปลี่ยน:</strong> {buyRate}<br/>
                        <img width='400' src={buySlip} />
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseBuyApprove} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ปิด</button>
                    <button onClick={handleBuyApprove} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>อนุมัติ</button>
                </DialogActions>
            </Dialog>
            <Dialog open={openBuyCancle} onClose={handleCloseBuyCancle}>
                <DialogTitle><div style={{ fontFamily: 'Prompt'}}><strong>ยกเลิกการซื้อเหรียญ</strong></div></DialogTitle>
                <DialogContent>
                        <strong>วันที่ซื้อ:</strong> {buyDate}<br/>
                        <strong>{buyMemberType=='user'?'ผู้ซื้อ':'ร้านค้า'}:</strong> {buyUserName}<br/>
                        <strong>จำนวนที่ซื้อ:</strong> {buyAmount}<br/>
                        <strong>อัตราแลกเปลี่ยน:</strong> {buyRate}<br/>
                        <img width='400' src={buySlip} />
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseBuyCancle} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ปิด</button>
                    <button onClick={handleBuyCancle} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ไม่อนุมัติ</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Exchanges;