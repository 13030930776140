import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, getDoc, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { useNavigate, useParams } from "react-router-dom"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import LeftMenu from './LeftMenu'
import DatePicker from "react-date-picker"
import '../../assets/css/DatePicker.css'
import '../../assets/css/Calendar.css'
import addImage from "../../assets/images/add-image-icon.png"

import { IoCheckmarkCircleSharp, IoCloseCircle, IoChevronBackCircleSharp } from 'react-icons/io5'
import { AiFillEdit } from 'react-icons/ai'
import { FaMapMarkerAlt } from "react-icons/fa"

import { Button, Dialog, DialogActions, DialogTitle } from '@mui/material'
import { Loading } from "../layouts/Loading"

import LeftMenuPost from './LeftMenuPost'
import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import { defaultLatLng, googleMapsApiKey } from "../../../assets/constants/locations"

const EditPostSecond = () => {
    const params = useParams()
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)
    const [isShop, setIsShop] = useState(0)
    const [electricType, setElectricType] = useState([])
    const [brandList, setBrandList] = useState([])
    const [modelList, setModelList] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)

    const [postId, setPostId] = useState(params.id)
    const [uid, setUid] = useState('');
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState([])

    const [post, setPost] = useState([])
    const [images, setImages] = useState([])

    const [isOnwer, setIsOwner] = useState(true)
    const [price, setPrice] = useState('')
    const [electricTypeId, setElectricTypeId] = useState(0)
    const [otherType, setOtherType] = useState('')
    const [brandId, setBrandId] = useState('')
    const [brand, setBrand] = useState('') // other brand
    const [modelId, setModelId] = useState('')
    const [model, setModel] = useState('') // other model
    const [width, setWidth] = useState('')
    const [height, setHeight] = useState('')
    const [length, setLength] = useState('')
    const [weight, setWeight] = useState('')
    const [buyYear, setBuyYear] = useState('')
    const [condition, setCondition] = useState('')
    const [option1, setOption1] = useState(false)
    const [option2, setOption2] = useState(false)
    const [option3, setOption3] = useState(false)
    const [option4, setOption4] = useState(false)
    const [description, setDescription] = useState('')
    const [pickDate, setPickDate] = useState(new Date())
    const [hour, setHour] = useState('09')
    const [minute, setMinute] = useState('00')
    const [lat, setLat] = useState(defaultLatLng.lat)
    const [lng, setLng] = useState(defaultLatLng.lng)
    const [address, setAddress] = useState('')

    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [provinceList, setProvinceList] = useState([])
    const [provinceId, setProvinceId] = useState(0)
    const [districtList, setDistrictList] = useState([])
    const [districtId, setDistrictId] = useState(0)
    const [subdistrictList, setSubDistrictList] = useState([])
    const [subdistrictId, setSubDistrictId] = useState(0)
    const [postcode, setPostcode] = useState('')

    const [addressNoRegis, setAddressNoRegis] = useState('')
    const [villageRegis, setVillageRegis] = useState('')
    const [buildingRegis, setBuildingRegis] = useState('')
    const [floorRegis, setFloorRegis] = useState('')
    const [roomRegis, setRoomRegis] = useState('')
    const [subroadRegis, setSubroadRegis] = useState('')
    const [roadRegis, setRoadRegis] = useState('')
    const [provinceIdRegis, setProvinceIdRegis] = useState(0)
    const [districtIdRegis, setDistrictIdRegis] = useState(0)
    const [subdistrictIdRegis, setSubDistrictIdRegis] = useState(0)
    const [postcodeRegis, setPostcodeRegis] = useState('')

    const [provinceIdErrorDiv, setProvinceIdErrorDiv] = useState(false)
    const [districtIdErrorDiv, setDistrictIdErrorDiv] = useState(false)
    const [subdistrictIdErrorDiv, setSubDistrictErrorDiv] = useState(false)
    const [pictureErrorDiv, setPictureErrorDiv] = useState(false)
    const [priceErrorDiv, setPriceErrorDiv] = useState(false)
    const [typeIdErrorDiv, setTypeIdErrorDiv] = useState(false)
    const [otherTypeErrorDiv, setOtherTypeErrorDiv] = useState(false)
    const [sizeNumErrorDiv, setSizeNumnErrorDiv] = useState(false)
    const [weightNumErrorDiv, setWeightNumErrorDiv] = useState(false)
    const [brandIdErrorDiv, setBrandIdErrorDiv] = useState(false)
    const [brandErrorDiv, setBrandErrorDiv] = useState(false)
    const [modelIdErrorDiv, setModelIdErrorDiv] = useState(false)
    const [modelErrorDiv, setModelErrorDiv] = useState(false)
    const [buyYearErrorDiv, setBuyYearErrorDiv] = useState(false)

    const [showProgressBar, setShowProgressBar] = useState(false)
    const [openAlertDelete, setOpenAlertDelete] = useState(false)

    const [currentLat, setCurrentLat] = useState(defaultLatLng.lat)
    const [currentLng, setCurrentLng] = useState(defaultLatLng.lng)
    const [regisLat, setRegisLat] = useState(defaultLatLng.lat)
    const [regisLng, setRegisLng] = useState(defaultLatLng.lng)

    const { isLoaded } = useLoadScript({ googleMapsApiKey: googleMapsApiKey })
    const [center, setCenter] = useState({ lat: defaultLatLng.lat, lng: defaultLatLng.lng })
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    var locationOptions = {
        enableHighAccuracy: false,
        timeout: 10000,
        maximumAge: 0,
    }

    const locationSuccess = (pos) => {
        var crd = pos.coords
        setCurrentLat(crd.latitude)
        setCurrentLng(crd.longitude)
    }

    const locationErrors = (err) => {
        console.warn(`ERROR(${err.code}): ${err.message}`)
    }

    useEffect(() => {
        if (navigator.geolocation) {
          navigator.permissions.query({ name: "geolocation" }).then(function (result) {
                //console.log(result);
                if (result.state === "granted") {
                //If granted then you can directly call your function here
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "prompt") {
                //If prompt then the user will be asked to give permission
                navigator.geolocation.getCurrentPosition(locationSuccess, locationErrors, locationOptions);
                } else if (result.state === "denied") {
                //If denied then you have to show instructions to enable location
                }
            });
        } else {
          //console.log("Geolocation is not supported by this browser.");
        }
    }, [])

    function isSpecialChar(text) {
        return /[`!@#$%^&*()_+\-=\[\]{};':"\\|,<>\/?~]/.test(text)
    }

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    function isOnlyNumericDecimal(text) {
        return /^[0-9.\b]+$/.test(text)
    }

    const handelGoToRegisPosition = () => {
        setLat(regisLat)
        setLng(regisLng)
        setCenter({lat: regisLat, lng: regisLng})
    }

    const handelGoToCurrentPosition = () => {
        setLat(currentLat)
        setLng(currentLng)
        setCenter({lat: currentLat, lng: currentLng})
    }

    const handleIsOwnerChange = (e) => {
        setIsOwner(!isOnwer)
    }

    const handleOption1Change = (e) => {
        setOption1(!option1)
    }

    const handleOption2Change = (e) => {
        setOption2(!option2)
    }

    const handleOption3Change = (e) => {
        setOption3(!option3)
    }

    const handleOption4Change = (e) => {
        setOption4(!option4)
    }

    const deleteImage = (i) => {
        let items = [...images]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setImages(items)
    }

    const handleImage = (file, i) => {
        let items = [...images]
        let item = {...items[i]}
        item.url = URL.createObjectURL(file)
        item.file = file
        item.status = 2
        items[i] = item
        setImages(items)
    }

    const getElectricType = () => {
        getDocs(query(collection(db, 'electric_type'), orderBy('name', 'asc'))).then((docs) => {
            setElectricType(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const getPost = () => {
        getDoc(doc(db, 'posts', postId)).then((postDoc) => {
            if (postDoc.exists()) {
                if(postDoc.data().poster_id != memberId) {
                    console.log("You are not owner")
                    navigate('/')
                }
                const data = postDoc.data()
                setPost(data)
                setPrice(data.price*1)
                setElectricTypeId(data.type_id)

                var promisesTask = []
                if(data.type_id) {
                    setElectricTypeId(data.type_id)
                    const modelTask = getDocs(query(collection(db, 'brands'), where('electric_id', '==', data.type_id), orderBy('name', 'asc'))).then((docs) => {
                        setBrandList(docs.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })))
                    })
                    promisesTask.push(modelTask)
                    Promise.all(promisesTask).then(() => {
                        if(data.brand_id) { setBrandId(data.brand_id) } else { setBrandId("999") }
                    })
                } else {
                    setElectricTypeId(999)
                }
                setOtherType(data.other_type)

                setBrand(data.brand)
                setModel(data.model)
                if(data.brand_id) { 
                    setBrandId(data.brand_id)
                    const modelTask = getDocs(query(collection(db, 'models'), where('brand_id', '==', data.brand_id), orderBy('name', 'asc'))).then((docs) => {
                        setModelList(docs.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })))
                    })
                    promisesTask.push(modelTask)
                    Promise.all(promisesTask).then(() => {
                        if(data.model_id) { setModelId(data.model_id) } else { setModelId("999") }
                    })
                 } else { 
                    setBrandId("999") 
                }

                setWidth(data.width)
                setHeight(data.height)
                setLength(data.length)
                setWeight(data.weight)
                setDescription(data.description)
                setLat(data.lat)
                setLng(data.lng)
                setCenter({ lat: data.lat, lng: data.lng })
                setRegisLat(data.lat)
                setRegisLng(data.lng)
                setAddress(data.address)
                setHour(data.hour)
                setMinute(data.minute)
                if(typeof data.pick_date.toDate === 'function')
                    setPickDate(data.pick_date.toDate())

                setRegisLat(data.lat)
                setRegisLng(data.lng)
                setAddress(data.address)

                if(data.address_no) { setAddressNo(data.address_no) }
                if(data.village) { setVillage(data.village) }
                if(data.building) { setBuilding(data.building) }
                if(data.floor) { setFloor(data.floor) }
                if(data.room) { setRoom(data.room) }
                if(data.subroad) { setSubroad(data.subroad) }
                if(data.road) { setRoad(data.road) }
                if(data.buy_year) { setBuyYear(data.buy_year) }
                if(data.option1) { setOption1(data.option1) }
                if(data.option2) { setOption2(data.option2) }
                if(data.option3) { setOption3(data.option3) }
                if(data.option4) { setOption4(data.option4) }
                if(data.condition) { setCondition(data.condition) }

                var promisesTask = []

                if(data.province) {
                    setProvinceId(data.province)
                    const districtTask = getDocs(query(collection(db, 'distrincts'), where('province_id', '==', data.province * 1))).then((docs) => {
                        setDistrictList(docs.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })))
                    })
                    promisesTask.push(districtTask)
                }

                if(data.district) {
                    setDistrictId(data.district)
                    const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', data.district))).then((docs) => {
                        setSubDistrictList(docs.docs.map(doc => ({
                            id: doc.id,
                            data: doc.data()
                        })))
                    })
                    promisesTask.push(subdistrictTask)
                }
                Promise.all(promisesTask)
                if(data.sub_district) { setSubDistrictId(data.sub_district) }
                if(data.postcode) { setPostcode(data.postcode) }
            } else {
                console.log("Post doesn't exist")
                navigate('/')
            }
        }).then(() => {
            const imagesSnapshot =  query(collection(db, `posts/${postId}/images`))
            onSnapshot(imagesSnapshot, (querySnapshot) => {
                setImages(querySnapshot.docs.map((doc, i) => ({
                    id: doc.id,
                    url: doc.data().url,
                    file: "",
                    status: 1
                })))
            })
        }).catch((error) => {
            alert(error.message)
        })
    }

    useEffect(() => {
        if(images.length < 5) {
            setImages([...images, { id: "", url: addImage, file: "", status: 0 }])
        }
    }, [images])

    const getDocUser = async () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMemberId(docs.docs[0].id)
                setMember(data)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)

                if(data.address) { setAddressNoRegis(data.address) }
                if(data.village) { setVillageRegis(data.village) }
                if(data.building) { setBuildingRegis(data.building) }
                if(data.floor) { setFloorRegis(data.floor) }
                if(data.room) { setRoomRegis(data.room) }
                if(data.subroad) { setSubroadRegis(data.subroad); }
                if(data.road) { setRoadRegis(data.road) }
                if(data.province) { setProvinceIdRegis(data.province) }
                if(data.district) { setDistrictIdRegis(data.district); }
                if(data.sub_district) { setSubDistrictIdRegis(data.sub_district) }
                setPostcodeRegis(data.postcode)
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    const validateForm = () => {
        var numPic = 0
        images.forEach((image) => {
            if(image.status != 0) {
                numPic++;
            }
        })
        if(numPic > 0) {
            setPictureErrorDiv(false)
        } else {
            setPictureErrorDiv(true)
            return false;
        }
        
        if(!price || !isOnlyNumericDecimal(price)) {
            setPriceErrorDiv(true)
            return false
        } else {
            setPriceErrorDiv(false)
        }

        if(!electricTypeId) {
            setTypeIdErrorDiv(true)
            return false
        } else {
            if(electricTypeId == '999') {
                if(!otherType || isSpecialChar(otherType)) {
                    setOtherTypeErrorDiv(true)
                    return false
                } else {
                    setOtherTypeErrorDiv(false)
                }
            } else {
                setOtherTypeErrorDiv(false)
                setTypeIdErrorDiv(false)
            }
        }
        if(!brandId) {
            setBrandIdErrorDiv(true)
            return false
        } else {
            if(brandId == '999') {
                if(!brand || isSpecialChar(brand)) {
                    setBrandErrorDiv(true)
                    return false
                } else {
                    setBrandErrorDiv(false)
                }
            } else {
                setBrandErrorDiv(false)
                setBrandIdErrorDiv(false)
            }
        }
        if(!modelId) {
            setModelIdErrorDiv(true)
            console.log(1)
            return false
        } else {
            if(modelId == '999') {
                console.log(2)
                if(!model || isSpecialChar(model)) {
                    console.log(model)
                    setModelErrorDiv(true)
                    return false
                } else {
                    setModelErrorDiv(false)
                }
            } else {
                setModelErrorDiv(false)
                setModelIdErrorDiv(false)
            }
        }

        if((width && !isOnlyNumericDecimal(width)) || (height && !isOnlyNumericDecimal(height)) || (length && !isOnlyNumericDecimal(length))) {
            setSizeNumnErrorDiv(true)
            return false
        } else {
            setSizeNumnErrorDiv(false)
        }
        
        if((weight && !isOnlyNumericDecimal(weight))) {
            setWeightNumErrorDiv(true)
            return false
        } else {
            setWeightNumErrorDiv(false)
        }

        if(provinceId == 0) {
            setProvinceIdErrorDiv(true)
            return false;
        } else {
            setProvinceIdErrorDiv(false)
        }

        if(districtId == 0) {
            setDistrictIdErrorDiv(true)
            return false;
        } else {
            setDistrictIdErrorDiv(false)
        }

        if(subdistrictId == 0) {
            setSubDistrictErrorDiv(true)
            return false;
        } else {
            setSubDistrictErrorDiv(false)
        }

        return true
    }

    const deletePost = async () => {
        var promisesAuth = []
        const authTask = await deleteDoc(doc(db, `posts`, postId))
        promisesAuth.push(authTask)
        Promise.all(promisesAuth).then(() => {
            navigate("/profile/posts/second")
        })
    }

    const handleCloseAlertDelete = () => {
        setOpenAlertDelete(false)
    }

    const handleSubmit = (e) => {
    	e.preventDefault()
        if(validateForm()) {
            const new_date = new Date()
            setShowProgressBar(true)
            updateDoc(doc(db, 'posts', postId), {
                price: price*1,
                type_id: electricTypeId,
                other_type: otherType,
                brand_id: brandId,
                brand: brand,
                model_id: modelId,
                model: model,
                width: width,
                height: height,
                length: length,
                weight: weight,
                buy_year: buyYear*1,
                condition: condition,
                option1: option1,
                option2: option2,
                option3: option3,
                option4: option4,
                description: description,
                address: address,
                address_no: addressNo,
                road: road,
                subroad: subroad,
                village: village,
                building: building,
                floor: floor,
                room: room,
                sub_district: subdistrictId,
                district: districtId,
                province: provinceId,
                postcode: postcode,
                lat: lat,
                lng: lng,
                isOnwer: isOnwer?"1":"0",
                pick_date: pickDate,
                hour: hour,
                minute: minute,
                edit_timestamp: new_date.getTime(),
                status: 1,
            }).then(() => {
                const promises = []
                var vari = 1;
                images.forEach((image, i) => {
                    if(image.status == 0) {
                        if(image.id != "") {
                            deleteDoc(doc(db, `posts/${postId}/images`, image.id));
                        }
                    } else if(image.status == 2) {
                        const storageRef = ref(storage, `/posts/${postId}/${i+1}`)
                        const uploadTask = uploadBytesResumable(storageRef, image.file)
                        promises.push(uploadTask)
                        uploadTask.on( "state_changed",
                            () => { },
                            (error) => console.log(error),
                            () => {
                                getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                                    if(image.id == "") {
                                        addDoc(collection(db, `/posts/${postId}/images`), {
                                            url: downloadURLs,
                                        })
                                    } else {
                                        updateDoc(doc(db, `/posts/${postId}/images`, image.id), {
                                            url: downloadURLs
                                        })
                                    }
                                    if(i+1 == 1) {
                                        updateDoc(doc(db, 'posts', postId), {
                                            picture: downloadURLs
                                        })
                                    }
                                })
                            }
                        )
                        vari++
                    }
                })

                Promise.all(promises).then(() => {
                    setShowProgressBar(false)
                    navigate("/profile/posts/second")
                })
            })
        }
    }

    const getBrand = () => {
        getDocs(query(collection(db, 'brands'), orderBy('name', 'asc'))).then((docs) => {
            setBrandList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handelSelectElectric = (electricTypeId) => {
        setElectricTypeId(electricTypeId)

        getDocs(query(collection(db, 'brands'), where('electric_id', '==', electricTypeId))).then((docs) => {
            setBrandList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectBrand = (brandId) => {
        setBrandId(brandId)
        getDocs(query(collection(db, 'models'), where('brand_id', '==', brandId), orderBy('name', 'asc'))).then((modelDocs) => {
            setModelList(modelDocs.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const getUseRegisAddress = () => {
        var promisesTask = []

        const districtTask = getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceIdRegis * 1))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        promisesTask.push(districtTask)

        const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtIdRegis))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
        promisesTask.push(subdistrictTask)
        Promise.all(promisesTask).then(() => {
            setAddressNo(addressNoRegis)
            setVillage(villageRegis)
            setBuilding(buildingRegis)
            setFloor(floorRegis)
            setRoom(roomRegis)
            setSubroad(subroadRegis)
            setRoad(roadRegis)
            setProvinceId(provinceIdRegis)
            setDistrictId(districtIdRegis)
            setSubDistrictId(subdistrictIdRegis)
            setPostcode(postcodeRegis)
        })
    }

    const handleSelectProvince = (provinceId) => {
        setProvinceId(provinceId)

        setDistrictId(0)
        districtList.splice(0, districtList.length)
        setDistrictList([...districtList])

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'distrincts'), where('province_id', '==', provinceId*1), orderBy('province_id', 'asc'))).then((docs) => {
            setDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handleSelectDistrict = (districtId) => {
        setDistrictId(districtId)

        setSubDistrictId(0)
        subdistrictList.splice(0, subdistrictList.length)
        setSubDistrictList([...subdistrictList])
        setPostcode('')

        getDocs(query(collection(db, 'subdistrincts'), where('district_id', '==', districtId), orderBy('district_id', 'asc'))).then((docs) => {
            setSubDistrictList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }

    const handelSelectsubDistrict = (subdistrictId) => {
        setSubDistrictId(subdistrictId)
        setPostcode('')
        getDocs(query(collection(db, 'postcodes'), where('subdistrict_id', '==', subdistrictId*1))).then((docs) => {
            setPostcode(docs.docs[0].data().postcode)
        })
    }

    const getProvince = () => {
        getDocs(query(collection(db, 'provinces'), orderBy('province_id', 'asc'))).then((docs) => {
            setProvinceList(docs.docs.map(doc => ({
                id: doc.id,
                data: doc.data()
            })))
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/") 
        else  {
            getDocUser()
            getElectricType()
            //getBrand()
            getProvince()
        }
    }, [user, loading])

    useEffect(() => {
        if(memberId != '')  {
            getPost()
        }
    }, [memberId])

    return (
    <>
        <Loading open={showProgressBar} text={"กำลังทำแก้ไขประกาศ กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 280 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 250, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 250, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenuPost currentPage="post" isShop={isShop} postType={3} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 10, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', flex: 1 }}>
                        <h4 className='d-block d-md-none d-lg-none'>
                            <b>แก้ไขประกาศ {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} onClick={toggleDrawer(true)} >ขายเครื่องใช้ไฟฟ้ามือสอง<IoMdMenu style={{ fontSize: 25, marginLeft: 5, marginBottom: 3 }} /></font></b>
                        </h4>
                        <h4 className='d-none d-md-block d-lg-block'>
                            <b>แก้ไขประกาศ {'>>'}<font style={{ fontWeight: '400', marginLeft: 5 }} >ขายเครื่องใช้ไฟฟ้ามือสอง</font></b>
                        </h4>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="post" isShop={isShop} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div className="flex-container" style={{ marginTop: 5, marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                <h4 style={{ display: 'flex', flex: 1 }}>
                                    <AiFillEdit style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                    <font style={{ fontSize: 20 }}>แก้ไขข้อมูลประกาศ</font>
                                </h4>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 5 }}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/profile/posts/second`) }}>
                                        <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDelete(true) }}>
                                        <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบประกาศ
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', alignItems: 'start' }}>
                                    <label><h6><input type="checkbox" checked={isOnwer?"checked":""} onChange={(e) => handleIsOwnerChange(e.target.value)} /> เป็นเจ้าของเครื่องใช้ไฟฟ้า</h6></label>
                                </div>
                            </div>
                            <div className="" style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap' }}>
                                    {images?.map((image, i) => (
                                    <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                        <img src={image.url} alt="WEEE" width="100" style={{marginBottom: 5}} />
                                        <div style={{ display: "flex", flexDirection:'row'}}>
                                            <label htmlFor={"fileUpload"+i} style={{ padding: 0}}>
                                                {(image.status!=0)?
                                                (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                                                (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                                                }
                                            </label>
                                            {(image.status!=0) &&
                                            (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImage(i) }}>
                                                <IoCloseCircle style={{ fontSize: 20 }} />
                                            </button>)
                                            }
                                        </div>
                                        <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImage(e.target.files[0], i)} />
                                    </div>
                                    ))}
                                </div>
                                {pictureErrorDiv &&
                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                    <strong>** กรุณาเลือกรูปถ่าย อย่างน้อย 1 รูป</strong>
                                </div>
                                }
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 5, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>ราคาขาย (บาท) <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 8, flexDirection: 'column' }}>
                                    <input type='text' name='price' onChange={(e) => setPrice(e.target.value)} 
                                        value={price} placeholder='ราคาขาย (บาท)'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex' }} />
                                    {priceErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, display: 'flex'}}>
                                            <strong>** กรุณากรอกราคาขาย (เฉพาะตัวเลขเท่านั้น)</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 5, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>ประเภท <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 8, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <select name='electric_type' value={electricTypeId} onChange={(e) => { handelSelectElectric(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, marginRight: 5 }}>
                                            <option value="0">--- กรุณาเลือกประเภท ---</option>
                                            {electricType?.map((type, i) => (
                                            <option value={type.id} key={type.id}>
                                                {type.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {typeIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, display: 'flex' }}>
                                            <strong>** กรุณาเลือกประเภท</strong>
                                        </div>
                                        }
                                    </div>
                                    {electricTypeId=='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setOtherType(e.target.value)} 
                                        value={otherType} placeholder='ประเภท เช่น ตู้เย็น, โทรทัศน์, พัดลม หรืออื่นๆ'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', marginTop: 5 }} />
                                    )
                                    }
                                    {otherTypeErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, display: 'flex'}}>
                                            <strong>** กรุณากรอกประเภท</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 5, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>ยี่ห้อ <font className="text-danger">*</font></strong></h6></label>
                                <div style={{ display: 'flex', flex: 8, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <select name='brand_id' value={brandId} onChange={(e) => { handleSelectBrand(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, marginRight: 5 }}>
                                            <option value="0">--- กรุณาเลือกยี่ห้อ ---</option>
                                            {brandList?.map((brand, i) => (
                                            <option value={brand.id} key={brand.id}>
                                                {brand.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {brandIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, display: 'flex' }}>
                                            <strong>** กรุณาเลือกยี่ห้อ</strong>
                                        </div>
                                        }
                                    </div>
                                    {brandId =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setBrand(e.target.value)} 
                                        value={brand} placeholder='ยี่ห้ออื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', marginTop: 5 }} />
                                    )
                                    }
                                    {brandErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, display: 'flex'}}>
                                            <strong>** กรุณากรอกยี่ห้อ</strong>
                                        </div>
                                    }
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 5, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>รุ่น</strong></h6></label>
                                <div style={{ display: 'flex', flex: 8, flexDirection: 'column' }}>
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                        <select name='model_id' value={modelId} onChange={(e) => { setModelId(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8, marginRight: 5 }}>
                                            <option value="0">--- กรุณาเลือกรุ่น ---</option>
                                            {modelList?.map((model, i) => (
                                            <option value={model.id} key={model.id}>
                                                {model.data.name}
                                            </option>
                                            ))}
                                            <option value="999">อื่นๆ (โปรดระบุ)</option>
                                        </select>
                                        {modelIdErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, display: 'flex' }}>
                                            <strong>** กรุณาเลือกรุ่น</strong>
                                        </div>
                                        }
                                    </div>
                                    {modelId =='999' &&
                                    (<input type='text' name='other_type' onChange={(e) => setModel(e.target.value)} 
                                        value={model} placeholder='รุ่นอื่นๆ (โปรดระบุ)'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', marginTop: 5, marginBottom: 10 }} />
                                    )
                                    }
                                    {modelErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, display: 'flex'}}>
                                            <strong>** กรุณากรอกรุ่น</strong>
                                        </div>
                                    }
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>กว้าง X ยาว X สูง (ซม.)</strong></h6></label>
                                <div className="flex-container" style={{ display: 'flex', flex: 8 }}>
                                    <input type='text' name='width' onChange={(e) => setWidth(e.target.value)} 
                                        value={width} placeholder='กว้าง (ซม.)'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, marginBottom: 5 }} />
                                    <input type='text' name='length' onChange={(e) => setLength(e.target.value)} 
                                        value={length} placeholder='ยาว (ซม.)'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, marginBottom: 5 }} />
                                    <input type='text' name='height' onChange={(e) => setHeight(e.target.value)} 
                                        value={height} placeholder='สูง (ซม.)'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, marginBottom: 5 }} />
                                </div>
                            </div> 
                            {sizeNumErrorDiv &&
                                <div className="flex-container" style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 2}}> </label>
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 8, display: 'flex'}}>
                                        <strong>** กรุณากรอกเฉพาะตัวเลขเท่านั้น</strong>
                                    </div>
                                </div>
                            }
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>น้ำหนัก (กก.)</strong></h6></label>
                                <div style={{ display: 'flex', flex: 8 }}>
                                    <input type='text' name='weight' onChange={(e) => setWeight(e.target.value)} 
                                        value={weight} placeholder='น้ำหนัก (กก.)'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                </div>
                            </div> 
                            {weightNumErrorDiv &&
                                <div className="flex-container" style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 2}}> </label>
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 8, display: 'flex'}}>
                                        <strong>** กรุณากรอกเฉพาะตัวเลขเท่านั้น</strong>
                                    </div>
                                </div>
                            }
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2 }}><h6><strong>ปีที่ซื้อ (พ.ศ.)</strong></h6></label>
                                <div style={{ display: 'flex', flex: 8 }}>
                                    <input type='text' name='buyYear' onChange={(e) => setBuyYear(e.target.value)} 
                                        value={buyYear} placeholder='ปีที่ซี่ื้อ (พ.ศ.)'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', flexWrap: 'wrap', marginTop: 5 }}>
                                <label className="" style={{ display: 'flex', flex: 2, whiteSpace: 'nowrap' }}><font style={{ fontSize: 17 }}> </font></label>
                                <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 8, display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                                    <font style={{ fontWeight: '600'}}>** ควรระบุเพื่อความน่าเชื่อถือและเพื่อใช้ในการประเมินราคา</font>
                                </div>
                            </div> 
                            {buyYearErrorDiv &&
                                <div className="flex-container" style={{ display: 'flex', marginTop: 5, marginBottom: 10 }}>
                                    <label className="" style={{ display: 'flex', flex: 2}}> </label>
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, flex: 8, display: 'flex'}}>
                                        <strong>** กรุณากรอกปี (พ.ศ.) ที่ซื้อ ความยาวเท่่ากับ 4 ตัวอักษร และเป็นตัวเลขเท่านั้น</strong>
                                    </div>
                                </div>
                            }
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2 }}><h6><strong>สถาพเครื่อง</strong></h6></label>
                                <div style={{ display: 'flex', flex: 8 }}>
                                    <select name='condition' onChange={(e) => setCondition(e.target.value)} style={{ marginLeft: 0, paddingLeft: 5 }}>
                                        <option value="1">มือสองเหมือนใหม่</option>
                                        <option value="2">มือสองสภาพดี</option>
                                        <option value="3">มือสองพอใช้</option>
                                        <option value="4">มือสองเก่ามาก</option>
                                    </select>
                                </div>
                            </div> 

                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2 }}> </label>
                                <div style={{ display: 'flex', flex: 8, flexDirection: 'column', alignItems: 'start' }}>
                                    <h6><input type="checkbox" checked={option1?"checked":""} onChange={(e) => handleOption1Change(e.target.value)} /> อยู่ในระยะประกัน</h6>
                                    <h6><input type="checkbox" checked={option2?"checked":""} onChange={(e) => handleOption2Change(e.target.value)} /> มีกล่อง</h6>
                                    <h6><input type="checkbox" checked={option3?"checked":""} onChange={(e) => handleOption3Change(e.target.value)} /> มีคู่มือการใช้งาน</h6>
                                    <h6><input type="checkbox" checked={option4?"checked":""} onChange={(e) => handleOption4Change(e.target.value)} /> เอกสารอื่นๆ</h6>
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>รายละเอียดเพิ่มเติม</strong></h6></label>
                                <div style={{ display: 'flex', flex: 8 }}>
                                    <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 10, marginRight: 5 }}
                                        onChange={(e) => setDescription(e.target.value)}
                                        placeholder='รายละเอียดเพิ่มเติม' 
                                        value={description}></textarea>
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>วันเวลาที่สะดวก</strong></h6></label>
                                <div style={{ display: 'flex', flex: 8 }}>
                                    <DatePicker onChange={setPickDate} value={pickDate} style={{ width:200 }} format='dd/MM/yyyy' locale="th-TH" required clearIcon={null} />
                                    <select name='hour' value={hour} onChange={(e) => setHour(e.target.value)} style={{ width: 70, marginLeft: 10 }}>
                                        <option value="00">00</option>
                                        <option value="01">01</option>
                                        <option value="02">02</option>
                                        <option value="03">03</option>
                                        <option value="04">04</option>
                                        <option value="05">05</option>
                                        <option value="06">06</option>
                                        <option value="07">07</option>
                                        <option value="08">08</option>
                                        <option value="09">09</option>
                                        <option value="10">10</option>
                                        <option value="11">11</option>
                                        <option value="12">12</option>
                                        <option value="13">13</option>
                                        <option value="14">14</option>
                                        <option value="15">15</option>
                                        <option value="16">16</option>
                                        <option value="17">17</option>
                                        <option value="18">18</option>
                                        <option value="19">19</option>
                                        <option value="20">20</option>
                                        <option value="21">21</option>
                                        <option value="22">22</option>
                                        <option value="23">23</option>
                                    </select>
                                    <select name='minute' value={minute} onChange={(e) => setMinute(e.target.value)} style={{ width: 70, marginLeft: 5, marginRight: 5 }}>
                                        <option value="00">00</option>
                                        <option value="15">15</option>
                                        <option value="30">30</option>
                                        <option value="45">45</option>
                                    </select>
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10, height:400 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>ตำแหน่งพิกัดที่พักอาศัย</strong></h6></label>
                                <div style={{ marginBottom: 1, flexDirection: 'column', display: 'flex', flexWrap: 'nowrap', height:400, flex: 8 }}>
                                    <div className='text-danger' style={{ marginBottom: 10, marginTop: 8, display: 'flex', flexWrap: 'nowrap' }}>
                                        ** ลาก <FaMapMarkerAlt className='text-danger' style={{ marginLeft: 5, marginRight: 5 }} /> Marker  เพื่อกำหนดตำแหน่ง
                                    </div>
                                    <div><strong>Latitude:</strong> {lat}, <strong>Longitude:</strong> {lng}</div>
                                    <div className="mb-3 row" style={{ display: 'flex', height:400, flexDirection: 'column' }}>
                                        <div style={{  display: 'flex', flexWrap: 'nowrap', height:400, flex: 1 }}>
                                            {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                            <GoogleMap mapContainerClassName="map-container" center={{ lat: lat, lng: lng }} zoom={17} >
                                                <MarkerF
                                                    draggable={true} position={center}
                                                    onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                                />
                                            </GoogleMap>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="flex-container" style={{ display: 'flex', marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}> </label>
                                <div className="flex-container" style={{ marginBottom: 1, display: 'flex', flexWrap: 'nowrap', flex: 8 }}>
                                    <button style={{ textAlign: 'center', marginRight: 15, marginBottom: 5 }} className="btn btn-success" type='button' onClick={() => { handelGoToRegisPosition() }}>ไปยังตำแหน่งเริ่มต้น</button>
                                    <button style={{ textAlign: 'center', marginRight: 15, marginBottom: 5 }} className="btn btn-success" type='button' onClick={() => { handelGoToCurrentPosition() }}>ไปยังตำแหน่งปัจจุบัน</button>
                                </div>
                            </div>
                            <div className="" style={{ display: 'flex', flex: 1, marginTop: 10, flexDirection: 'column' }}>
                                <div  className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                    <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>ที่อยู่</strong></h6></label>
                                    <div style={{ display: 'flex', flex: 8, width:"100%" }}>
                                        <button style={{ textAlign: 'center' }} className="btn btn-success" type='button' onClick={() => { getUseRegisAddress() }}>ใช้ที่อยู่เริ่มต้น</button>
                                    </div>
                                </div>
                                <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10}}>
                                    <label className='d-none d-md-block d-lg-block' style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2 }}>
                                        <h6><strong> </strong></h6>
                                    </label>
                                    <div style={{ display: 'flex', flexDirection:'column', flex: 8 }}>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', flexWrap: 'wrap' }}>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='addressNo' onChange={(e) => setAddressNo(e.target.value)} 
                                                value={addressNo} placeholder='บ้านเลขที่'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='village' onChange={(e) => setVillage(e.target.value)} 
                                                value={village} placeholder='หมู่บ้าน/สถานที่'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='room' onChange={(e) => setRoom(e.target.value)} 
                                                value={room} placeholder='ห้อง'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='floor' onChange={(e) => setFloor(e.target.value)} 
                                                value={floor} placeholder='ชั้น'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='building' onChange={(e) => setBuilding(e.target.value)} 
                                                value={building} placeholder='อาคาร'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='subroad' onChange={(e) => setSubroad(e.target.value)} 
                                                value={subroad} placeholder='ซอย'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                                value={road} placeholder='ถนน'
                                                style={{ paddingLeft: 8, marginTop: 7, marginRight: 10 }} />
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <select name='province' value={provinceId} onChange={(e) => { handleSelectProvince(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                <option value="0">--- กรุณาเลือกจังหวัด ---</option>
                                                {provinceList?.map((province, i) => (
                                                <option value={province.data.province_id} key={province.data.province_id}>
                                                    {province.data.province_name}
                                                </option>
                                                ))}
                                            </select>
                                            {provinceIdErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                                <strong>** กรุณาเลือกจังหวัด</strong>
                                            </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <select name='district' value={districtId} onChange={(e) => { handleSelectDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                <option value="0" key="0">--- กรุณาเลือกอำเภอ ---</option>
                                                {districtList?.map((district, i) => (
                                                <option value={district.data.district_id} key={district.data.district_id}>
                                                    {district.data.district_name}
                                                </option>
                                                ))}
                                            </select>
                                            {districtIdErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                                <strong>** กรุณาเลือกอำเภอ</strong>
                                            </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <select name='subdistrict' value={subdistrictId} onChange={(e) => { handelSelectsubDistrict(e.target.value) }} style={{ paddingLeft: 8, paddingRight: 8 }}>
                                                <option value="0">--- กรุณาเลือกตำบล ---</option>
                                                {subdistrictList?.map((subdistrict, i) => (
                                                <option value={subdistrict.data.subdistrict_id} key={subdistrict.data.subdistrict_id}>
                                                    {subdistrict.data.subdistrict_name}
                                                </option>
                                                ))}
                                            </select>
                                            {subdistrictIdErrorDiv &&
                                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex' }}>
                                                <strong>** กรุณาเลือกตำบล</strong>
                                            </div>
                                            }
                                        </div>
                                        <div style={{ display: 'flex', flex: 1, flexDirection:'row', marginTop: 7 }}>
                                            <div style={{ display: 'flex', flexDirection:'column'}}>
                                            <input type='text' name='postcode' readOnly
                                                value={postcode} placeholder='รหัสไปรษณีย์'
                                                style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div> 
                            <div className="flex-container" style={{ display: 'flex', flex: 1, marginTop: 10 }}>
                                <label className="" style={{ display: 'flex', paddingRight: 10, paddingTop: 10, whiteSpace: 'nowrap', flexWrap: 'nowrap', flex: 2}}><h6><strong>ที่อยู่เพิ่มเติม</strong></h6></label>
                                <div style={{ display: 'flex', flex: 8 }}>
                                    <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 10 }}
                                        onChange={(e) => setAddress(e.target.value)}
                                        placeholder='ที่อยู่เพิ่มเติม' 
                                        value={address}></textarea>
                                </div>
                            </div> 
                        </div>
                        <div className="flex-container d-block d-md-none d-lg-none" style={{ marginTop: 5, marginTop: 10, borderTop: '1px solid #cccccc', display: 'flex' }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginTop: 10 }}>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/profile/posts/second`) }}>
                                        <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                                        <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                                    </button>
                                </div>
                                <div style={{ float: 'right' }}>
                                    <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDelete(true) }}>
                                        <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบประกาศ
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Dialog open={openAlertDelete} onClose={handleCloseAlertDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                    <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                        {"คุณต้องการลบประกาศนี้?"}
                    </DialogTitle>
                    <DialogActions>
                    <Button onClick={handleCloseAlertDelete} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                    <Button onClick={() => { deletePost() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                    </DialogActions>
                </Dialog>
            </div>
        </div>
    </>
    )
}

export default EditPostSecond;