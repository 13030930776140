import React, { useState, useEffect } from 'react'
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy,onSnapshot } from 'firebase/firestore'
import { useAuthState } from "react-firebase-hooks/auth"
import { useNavigate } from "react-router-dom"
import { TbUser, TbChevronRight, TbSettings, TbMessageCircle2 } from "react-icons/tb"
import { IoMegaphoneOutline } from "react-icons/io5"
import { AiOutlineShop, AiFillExclamationCircle } from "react-icons/ai"
import { BsCalendarDate } from "react-icons/bs"
import { MdOutlineLocalOffer } from "react-icons/md"
import { LuAirVent } from "react-icons/lu"
import { CgSmartHomeWashMachine } from "react-icons/cg"

import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2 } from "react-icons/tb"

const LeftMenu = ({currentPage, isShop, postType}) => {    
    const navigate = useNavigate()
    const [user, loading] = useAuthState(auth)
    const [newNoti, setNewNoti] = useState(false)

    const getNewNoti = () => {
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const notiQuery = query(collection(db, `users/${docs.docs[0].id}/notifications`), where('status', '==', 0), orderBy('date', 'desc'))
                onSnapshot(notiQuery, (querySnapshot) => {
                    if(querySnapshot.docs.length > 0) setNewNoti(true)
                })

                const messageQuery = query(collection(db, `users/${docs.docs[0].id}/system_message`), where('status', '==', 0), orderBy('date', 'desc'))
                onSnapshot(messageQuery, (querySnapshot) => {
                    if(querySnapshot.docs.length > 0) setNewNoti(true)
                })
            }
        })
    }
    
    useEffect(() => {
        if (loading) return
        if (!user) return navigate("/")
        else { 
            getNewNoti() 
        }
    }, [user, loading])

    return (            
        <div style={{ display: 'flex', width: 250, flexDirection: 'column' }}>
            <div className="d-flex flex-column p-2" style={{ width: 250 }}>
            { isShop?
                <button type="button" className="btn btn-primary" style={{ marginRight: 10 }} onClick={() => { return navigate("/shop/")}}><AiOutlineShop style={{ fontSize: 20, marginRight: 5, marginBottom: 3 }} />จัดการร้านค้า</button>
                :<button type="button" className="btn btn-primary" style={{ marginRight: 10 }} onClick={() => { return navigate("/shop/register")}}><AiOutlineShop style={{ fontSize: 20, marginRight: 5, marginBottom: 3 }} />สมัครร้านค้า</button>
            }
            </div>
            <div className="d-flex flex-column p-2" style={{ width: 250, marginRight: 20 }}>
                <ul className="nav flex-column mb-auto nav-underline">
                    <li>
                        <a href="/profile" className={(currentPage=="profile")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <TbUser style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <strong>ข้อมูลส่วนตัว</strong>
                            { (currentPage=="profile") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="/profile/notifications" className={(currentPage=="notifications")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <TbMessageCircle2 style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อความ</b>
                            { (currentPage=="notifications") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                            {newNoti &&
                            <AiFillExclamationCircle className='text-danger' style={{ fontSize: 25, marginLeft: 5, marginRight: 5, paddingBottom: 3, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="/profile/posts" className={(currentPage=="post")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <IoMegaphoneOutline style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการประกาศ</b>
                            { (currentPage=="post") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                        <div style={{ borderBottom: '1px solid #cccccc', paddingLeft: 15, paddingTop: 10, paddingRight: 10 }}>
                            <div><a className={(postType==1)?"nav-link link-primary active":"nav-link link-dark"} href="/profile/posts/"><RiDeleteBinLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ทิ้งซาก</a></div>
                            <div><a className={(postType==2)?"nav-link link-primary active":"nav-link link-dark"} href="/profile/posts/garbage"><BiMoneyWithdraw style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายซาก</a></div>
                            <div><a className={(postType==3)?"nav-link link-primary active":"nav-link link-dark"} href="/profile/posts/second"><TbHexagonNumber2 style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ขายมือสอง</a></div>
                            <div><a className={(postType==4)?"nav-link link-primary active":"nav-link link-dark"} href="/profile/posts/fix"><BiWrench style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />แจ้งซ่อมเครื่องใช้ไฟฟ้า</a></div>
                            <div><a className={(postType==5)?"nav-link link-primary active":"nav-link link-dark"} href="/profile/posts/airs"><LuAirVent style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างแอร์</a></div>
                            <div><a className={(postType==6)?"nav-link link-primary active":"nav-link link-dark"} href="/profile/posts/wash"><CgSmartHomeWashMachine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ล้างเครื่องซักผ้า</a></div>
                        </div>
                    </li>
                    <li>
                        <a href="/profile/appointments" className={(currentPage=="appointments")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <BsCalendarDate style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการนัดหมาย</b>
                            { (currentPage=="appointments") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="/profile/offers" className={(currentPage=="offers")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <MdOutlineLocalOffer style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการข้อเสนอจองสินค้า</b>
                            { (currentPage=="offers") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="/profile/appointmentposts" className={(currentPage=="appointmentposts")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <BsCalendarDate style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>จัดการนัดรับสินค้า</b>
                            { (currentPage=="appointmentposts") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                    <li>
                        <a href="/profile/coins" className={(currentPage=="coins")?"nav-link link-primary active":"nav-link link-dark"} aria-current="page">
                            <TbSettings style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>เหรียญ WEEE</b>
                            { (currentPage=="setting") &&
                            <TbChevronRight style={{ fontSize: 25, marginRight: 5, paddingBottom: 5, float:'right' }} />
                            }
                        </a>
                    </li>
                </ul>
            </div>
        </div>
    )
}

export default LeftMenu;