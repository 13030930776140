import React, { useState, useEffect } from 'react'
import { collection, query, where, getDocs, orderBy, onSnapshot, getDoc, doc, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage"
import { db, storage, auth } from "../../assets/js/firebase"
import { useNavigate } from "react-router-dom"
import { AiFillInfoCircle } from "react-icons/ai"
import { FiEdit, FiSave } from "react-icons/fi"
import { BsMegaphone } from "react-icons/bs"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import addImage from "../../assets/images/add-image-icon.png"
import { IoCloseCircle } from 'react-icons/io5'
import { Loading } from "../../../sites/components/layouts/Loading"

const Ads = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showProgressBar, setShowProgressBar] = useState(false)

    const [slideImage, setSlideImage] = useState([])

    const [position1Coin, setPosition1Coin] = useState(0)
    const [position2Coin, setPosition2Coin] = useState(0)
    const [position3Coin, setPosition3Coin] = useState(0)
    const [position4Coin, setPosition4Coin] = useState(0)
    const [position5Coin, setPosition5Coin] = useState(0)
    const [position6Coin, setPosition6Coin] = useState(0)
    const [position7Coin, setPosition7Coin] = useState(0)
    const [position8Coin, setPosition8Coin] = useState(0)
    const [position9Coin, setPosition9Coin] = useState(0)
    const [position10Coin, setPosition10Coin] = useState(0)

    const [position1Num, setPosition1Num] = useState(0)
    const [position2Num, setPosition2Num] = useState(0)
    const [position3Num, setPosition3Num] = useState(0)
    const [position4Num, setPosition4Num] = useState(0)
    const [position5Num, setPosition5Num] = useState(0)
    const [position6Num, setPosition6Num] = useState(0)
    const [position7Num, setPosition7Num] = useState(0)
    const [position8Num, setPosition8Num] = useState(0)
    const [position9Num, setPosition9Num] = useState(0)
    const [position10Num, setPosition10Num] = useState(0)

    const [position1Request, setPosition1Request] = useState(0)
    const [position2Request, setPosition2Request] = useState(0)
    const [position3Request, setPosition3Request] = useState(0)
    const [position4Request, setPosition4Request] = useState(0)
    const [position5Request, setPosition5Request] = useState(0)
    const [position6Request, setPosition6Request] = useState(0)
    const [position7Request, setPosition7Request] = useState(0)
    const [position8Request, setPosition8Request] = useState(0)
    const [position9Request, setPosition9Request] = useState(0)
    const [position10Request, setPosition10Request] = useState(0)

    const [openSettingCoin, setOpenSettingCoin] = useState(false)

    const deleteImage = (i) => {
        let items = [...slideImage]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setSlideImage(items)
    }

    const handleImage = (file, i) => {
        let items = [...slideImage]
        let item = {...items[i]}
        item.url = URL.createObjectURL(file)
        item.file = file
        item.status = 2
        items[i] = item
        setSlideImage(items)
    }

    const getUseRate = () => {
        getDoc(doc(db, 'ads', 'ads_rate')).then((exchangeSnapshot) => {
            const data = exchangeSnapshot.data()
            if(data.position_1) setPosition1Coin(data.position_1)
            if(data.position_2) setPosition2Coin(data.position_2)
            if(data.position_3) setPosition3Coin(data.position_3)
            if(data.position_4) setPosition4Coin(data.position_4)
            if(data.position_5) setPosition5Coin(data.position_5)
            if(data.position_6) setPosition6Coin(data.position_6)
            if(data.position_7) setPosition7Coin(data.position_7)
            if(data.position_8) setPosition8Coin(data.position_8)
            if(data.position_9) setPosition9Coin(data.position_9)
            if(data.position_10) setPosition10Coin(data.position_10)
        })

        getDocs(query(collection(db, 'ads/active/position_1'))).then((numActives) => {
            setPosition1Num(numActives.docs.length)
        })
        getDocs(query(collection(db, 'ads/active/position_2'))).then((numActives) => {
            setPosition2Num(numActives.docs.length)
        })
        getDocs(query(collection(db, 'ads/active/position_3'))).then((numActives) => {
            setPosition3Num(numActives.docs.length)
        })
        getDocs(query(collection(db, 'ads/active/position_4'))).then((numActives) => {
            setPosition4Num(numActives.docs.length)
        })
        getDocs(query(collection(db, 'ads/active/position_5'))).then((numActives) => {
            setPosition5Num(numActives.docs.length)
        })
        getDocs(query(collection(db, 'ads/active/position_6'))).then((numActives) => {
            setPosition6Num(numActives.docs.length)
        })
        getDocs(query(collection(db, 'ads/active/position_7'))).then((numActives) => {
            setPosition7Num(numActives.docs.length)
        })
        getDocs(query(collection(db, 'ads/active/position_8'))).then((numActives) => {
            setPosition8Num(numActives.docs.length)
        })
        getDocs(query(collection(db, 'ads/active/position_9'))).then((numActives) => {
            setPosition9Num(numActives.docs.length)
        })
        getDocs(query(collection(db, 'ads/active/position_10'))).then((numActives) => {
            setPosition10Num(numActives.docs.length)
        })

        getDocs(query(collection(db, 'ads/request/position_1'), where('status', '==', 1))).then((numRequests) => {
            setPosition1Request(numRequests.docs.length)
        })
        getDocs(query(collection(db, 'ads/request/position_2'), where('status', '==', 1))).then((numRequests) => {
            setPosition2Request(numRequests.docs.length)
        })
        getDocs(query(collection(db, 'ads/request/position_3'), where('status', '==', 1))).then((numRequests) => {
            setPosition3Request(numRequests.docs.length)
        })
        getDocs(query(collection(db, 'ads/request/position_4'), where('status', '==', 1))).then((numRequests) => {
            setPosition4Request(numRequests.docs.length)
        })
        getDocs(query(collection(db, 'ads/request/position_5'), where('status', '==', 1))).then((numRequests) => {
            setPosition5Request(numRequests.docs.length)
        })
        getDocs(query(collection(db, 'ads/request/position_6'), where('status', '==', 1))).then((numRequests) => {
            setPosition6Request(numRequests.docs.length)
        })
        getDocs(query(collection(db, 'ads/request/position_7'), where('status', '==', 1))).then((numRequests) => {
            setPosition7Request(numRequests.docs.length)
        })
        getDocs(query(collection(db, 'ads/request/position_8'), where('status', '==', 1))).then((numRequests) => {
            setPosition8Request(numRequests.docs.length)
        })
        getDocs(query(collection(db, 'ads/request/position_9'), where('status', '==', 1))).then((numRequests) => {
            setPosition9Request(numRequests.docs.length)
        })
        getDocs(query(collection(db, 'ads/request/position_10'), where('status', '==', 1))).then((numRequests) => {
            setPosition10Request(numRequests.docs.length)
        })
    }

    const handleCloseSettingCoin = () => {
        setOpenSettingCoin(false)
    }

    const handleSettingCoin = () => {
        updateDoc(doc(db, 'ads', 'ads_rate'), {
            position_1: position1Coin*1,
            position_2: position2Coin*1,
            position_3: position3Coin*1,
            position_4: position4Coin*1,
            position_5: position5Coin*1,
            position_6: position6Coin*1,
            position_7: position7Coin*1,
            position_8: position8Coin*1,
            position_9: position9Coin*1,
            position_10: position10Coin*1,
        }).then(() => {
            setOpenSettingCoin(false)
            navigate(0)
        })
    }

    const submitSlideAds = () => {
        const promises = []
        var vari = 1
        setShowProgressBar(true)
        slideImage.forEach((image, i) => {
            if(image.status == 0) {
                if(image.id != "") {
                    deleteDoc(doc(db, `slide_ads/`, image.id));
                }
            } else if(image.status == 2) {
                const storageRef = ref(storage, `slide_ads/${i+1}`)
                const uploadTask = uploadBytesResumable(storageRef, image.file)
                promises.push(uploadTask)
                uploadTask.on( "state_changed",
                    () => { },
                    (error) => console.log(error),
                    () => {
                        getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                            console.log(image.id)
                            if(image.id == "") {
                                addDoc(collection(db, `slide_ads/`), {
                                    url: downloadURLs,
                                    position: i
                                })
                            } else {
                                updateDoc(doc(db, `slide_ads/`, image.id), {
                                    url: downloadURLs,
                                    position: i
                                })
                            }
                        })
                    }
                )
                vari++
            }
        })

        Promise.all(promises).then(() => {
            setShowProgressBar(false)
        })
    }

    useEffect(() => {
        getUseRate()
        const imagesSnapshot =  query(collection(db, `slide_ads`), orderBy('position', 'asc'))
        onSnapshot(imagesSnapshot, (querySnapshot) => {
            setSlideImage(querySnapshot.docs.map((doc, i) => ({
                id: doc.id,
                url: doc.data().url,
                file: "",
                status: 1
            })))
        })
    },[])

    useEffect(() => {
        if(slideImage.length < 5) {
            setSlideImage([...slideImage, { id: "", url: addImage, file: "", status: 0 }])
        }
    }, [slideImage])

    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <Loading open={showProgressBar} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><BsMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>ข้อมูล Slide Ads</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { submitSlideAds() }}><FiSave style={{ fontSize: 20, marginRight: 5 }} />บันทึก Slide Ads</button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                {slideImage?.map((image, i) => (
                <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                    <img src={image.url} alt="WEEE" width="100" style={{marginBottom: 5}} />
                    <div style={{ display: "flex", flexDirection:'row'}}>
                        <label htmlFor={"fileUpload"+i} style={{ padding: 0}}>
                            {(image.status!=0)?
                            (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                            (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                            }
                        </label>
                        {(image.status!=0) &&
                        (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImage(i) }}>
                            <IoCloseCircle style={{ fontSize: 20 }} />
                        </button>)
                        }
                    </div>
                    <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImage(e.target.files[0], i)} />
                </div>
                ))}
            </div>
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><BsMegaphone style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>ข้อมูลการโฆษณา</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'nowrap'}}>
                <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenSettingCoin(true) }}><FiEdit style={{ fontSize: 20, marginRight: 5 }} />ตั้งค่าการใช้เหรียญในการลงโฆษณา</button>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap'}}>
                <table className="table">
                    <thead>
                        <tr>
                            <th scope="col">#</th>
                            <th scope="col">รายละเอียด</th>
                            <th scope="col">เหรียญที่ใช้</th>
                            <th scope="col">ads ที่ active/ทั้งหมด</th>
                            <th scope="col">รออนุมัติ</th>
                            <th scope="col"> </th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <th scope="row">1</th>
                            <td>หน้าแรก ตำแหน่งบนสุด</td>
                            <td>{number_format.format(position1Coin)}</td>
                            <td>{number_format.format(position1Num)}/4</td>
                            <td>{number_format.format(position1Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/post/1`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">2</th>
                            <td>หน้าแรก ขายเครื่องใช้ไฟฟ้ามือสอง</td>
                            <td>{number_format.format(position2Coin)}</td>
                            <td>{number_format.format(position2Num)}/5</td>
                            <td>{number_format.format(position2Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/post/2`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">3</th>
                            <td>หน้าแรก รวมช่างซ่อม</td>
                            <td>{number_format.format(position3Coin)}</td>
                            <td>{number_format.format(position3Num)}/5</td>
                            <td>{number_format.format(position3Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/shop/3`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">4</th>
                            <td>หน้าแรก บริการล้างแอร์</td>
                            <td>{number_format.format(position4Coin)}</td>
                            <td>{number_format.format(position4Num)}/4</td>
                            <td>{number_format.format(position4Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/shop/4`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">5</th>
                            <td>หน้าแรก บริการล้างเครื่องซักผ้า</td>
                            <td>{number_format.format(position5Coin)}</td>
                            <td>{number_format.format(position5Num)}/3</td>
                            <td>{number_format.format(position5Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/shop/5`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">6</th>
                            <td>หน้าแสดงทั้งหมด ขายเครื่องใช้ไฟฟ้า</td>
                            <td>{number_format.format(position6Coin)}</td>
                            <td>{number_format.format(position6Num)}/8</td>
                            <td>{number_format.format(position6Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/post/6`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">7</th>
                            <td>หน้าแสดงทั้งหมด ขายซากเครื่องใช้ไฟฟ้า</td>
                            <td>{number_format.format(position7Coin)}</td>
                            <td>{number_format.format(position7Num)}/8</td>
                            <td>{number_format.format(position7Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/post/7`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">8</th>
                            <td>หน้าแสดงทั้งหมด แจ้งซ่อมเครื่องใช้ไฟฟ้า</td>
                            <td>{number_format.format(position8Coin)}</td>
                            <td>{number_format.format(position8Num)}/10</td>
                            <td>{number_format.format(position8Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/shop/8`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">9</th>
                            <td>หน้าแสดงทั้งหมด แจ้งล้างแอร์</td>
                            <td>{number_format.format(position9Coin)}</td>
                            <td>{number_format.format(position9Num)}/10</td>
                            <td>{number_format.format(position9Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/shop/9`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <th scope="row">10</th>
                            <td>หน้าแสดงทั้งหมด แจ้งล้างเครื่องซักผ้า</td>
                            <td>{number_format.format(position10Coin)}</td>
                            <td>{number_format.format(position10Num)}/10</td>
                            <td>{number_format.format(position10Request)}</td>
                            <td>
                                <div style={{ display: 'flex', flexDirection: 'row' }}>
                                    <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/ads/shop/10`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูรายการโฆษณา</button>
                                </div>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <Dialog open={openSettingCoin} onClose={handleCloseSettingCoin} maxWidth="sm" fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h3><BsMegaphone style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ตั้งค่าการใช้เหรียญในการลงโฆษณา</strong></h3></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 1 - หน้าแรก ตำแหน่งบนสุด จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position1' onChange={(e) => setPosition1Coin(e.target.value)} 
                                    value={position1Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 2 - หน้าแรก ขายเครื่องใช้ไฟฟ้ามือสอง จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position2' onChange={(e) => setPosition2Coin(e.target.value)} 
                                    value={position2Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 3 - หน้าแรก รวมช่างซ่อม จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position3' onChange={(e) => setPosition3Coin(e.target.value)} 
                                    value={position3Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 4 - หน้าแรก บริการล้างแอร์ จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position4' onChange={(e) => setPosition4Coin(e.target.value)} 
                                    value={position4Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 5 - หน้าแรก บริการล้างเครื่องซักผ้า จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position5' onChange={(e) => setPosition5Coin(e.target.value)} 
                                    value={position5Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 6 - หน้าแสดงทั้งหมด ขายเครื่องใช้ไฟฟ้า จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position6' onChange={(e) => setPosition6Coin(e.target.value)} 
                                    value={position6Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 7 - หน้าแสดงทั้งหมด ขายซากเครื่องใช้ไฟฟ้า จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position7' onChange={(e) => setPosition7Coin(e.target.value)} 
                                    value={position7Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 8 - หน้าแสดงทั้งหมด แจ้งซ่อมเครื่องใช้ไฟฟ้า จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position8' onChange={(e) => setPosition8Coin(e.target.value)} 
                                    value={position8Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 9 - หน้าแสดงทั้งหมด แจ้งล้างแอร์ จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position9' onChange={(e) => setPosition9Coin(e.target.value)} 
                                    value={position9Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'row', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 10 }}>
                            <label className="" style={{ display: 'flex', marginRight: 10 }}><strong>ตำแหน่งที่ 10 - หน้าแสดงทั้งหมด แจ้งล้างเครื่องซักผ้า จำนวน</strong></label>
                            <div style={{ display: 'flex', marginRight: 10, width: 50 }}>
                                <input type='text' name='position10' onChange={(e) => setPosition10Coin(e.target.value)} 
                                    value={position10Coin} placeholder='จำนวนเหรียญ'
                                    style={{ paddingLeft: 8, display: 'flex', flex: 1, width: 40 }} />
                            </div>
                            <label className="" style={{ display: 'flex' }}><strong>เหรียญ</strong></label>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseSettingCoin} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleSettingCoin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>บันทึก</button>
                </DialogActions>
            </Dialog>
        </div>
    )
}

export default Ads