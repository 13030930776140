import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, onSnapshot, addDoc, updateDoc, doc, orderBy, setDoc } from 'firebase/firestore'
import { useParams, useNavigate, Link } from "react-router-dom"

import { AiOutlineCheckCircle, AiFillInfoCircle, AiOutlinePlusCircle } from "react-icons/ai"
import { IoMegaphoneOutline } from "react-icons/io5"
import { ImCoinDollar } from "react-icons/im";
import { MdOutlineAttachMoney, MdOutlineMessage, MdOutlineCalendarToday } from "react-icons/md"
import { FaCarSide } from "react-icons/fa"

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { RadioGroup, Radio, FormControlLabel } from '@mui/material'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

import { getBrand, getModel } from '../helper/BrandHelper'
import { getElectric } from '../helper/ElectricTypeHelper'
import { Loading } from "../layouts/Loading"

import { StarRating } from '../helper/StarHelper'
import './Shop.css'

const Post = () => {
    const params = useParams()
    const postId = params.id
    const navigate = useNavigate()
    const today = dayjs()
    const number_format = new Intl.NumberFormat()

    const onMobile = window.innerWidth < 920
    var imagePostHeight = 190
    var thumpnailWidth = 100
    var h1FontSize = 42
    var h2FontSize = 24
    var h3FontSize = 18
    var textFontSize = 16
    var numRowPost = 4

    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        imagePostHeight = 100
        thumpnailWidth = 80
        h1FontSize = 32
        h2FontSize = 20
        h3FontSize = 18
        textFontSize = 16
        numRowPost = 3
    } else if(window.innerWidth < 576) {
        imagePostHeight = 100
        thumpnailWidth = 60
        h1FontSize = 24
        h2FontSize = 18
        h3FontSize = 14
        textFontSize = 14
        numRowPost = 2
    }

    const [width, setWidth] = useState(100)
    const [height, setHeight] = useState(imagePostHeight)

    const [uid, setUid] = useState('')
    const [user, loading, error] = useAuthState(auth)
    const [memberId, setMemberId] = useState('')
    const [isShop, setIsShop] = useState(0)
    const [shopId, setShopId] = useState(0)
    const [offered, setOffered] = useState(false)
    const [offer, setOffer] = useState('')
    const [onBoard, setOnBoard] = useState(1)
    const [status, setStatus] = useState(1)

    const [ownerId, setOwnerId] = useState('')

    const [brandName, setBrandName] = useState('')
    const [modelName, setModelName] = useState('')
    const [type, setType] = useState('')

    const [posterId, setPosterId] = useState('')
    const [posterName, setPosterName] = useState('')
    const [posterScore, setPosterScore] = useState(-1)
    const [numPost, setNumPost] = useState(0)

    const [pickDate, setPickDate] = useState(today)
    const [pickHour, setPickHour] = useState('09')
    const [pickMinute, setPickMinute] = useState('00')
    const [offerPrice, setOfferPrice] = useState(0)
    const [offerMessage, setOfferMessage] = useState('')
    const [fromCoinOffer, setFromCoinOffer] = useState('freecoin')
    const [postView, setPostView] = useState(0)
    const [offerDelivery, setOfferDelivery] = useState(false)
    
    const [post, setPost] = useState([])
    const [images, setImages] = useState([])
    const [showImage, setShowImages] = useState('')
    const [address, setAddress] = useState('')
    const [addressNo, setAddressNo] = useState('')
    const [village, setVillage] = useState('')
    const [building, setBuilding] = useState('')
    const [floor, setFloor] = useState('')
    const [room, setRoom] = useState('')
    const [subroad, setSubroad] = useState('')
    const [road, setRoad] = useState('')
    const [subdistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [postcode, setPostcode] = useState('')

    const [question, setQuestion] = useState('')
    const [questions, setQuestions] = useState([])
    const [ownerQuestions, setOwnerQuestion] = useState([])
    const [answer, setAnswer] = useState('')

    const [offerUseCoin, setOfferUseCoin] = useState(0)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)

    const [openAcceptPostShop, setOpenAcceptPostShop] = useState(false)
    const [openAddQuestion, setOpenAddQuestion] = useState(false)

    const [priceErrorDiv, setPriceErrorDiv] = useState(false)
    const [useCoinErrorDiv, setUseCoinErrorDiv] = useState(false)
    const [questionErrorDiv, setQuestionErrorDiv] = useState(false)
    const [answerErrorDiv, setAnswerErrorDiv] = useState(false)

    const [deliveryOption1, setDeliveryOption1] = useState(true)
    const [deliveryOption2, setDeliveryOption2] = useState(false)
    const [deliveryPrice, setDeliveryPrice] = useState(0)
    const [buyYear, setBuyYear] = useState('')
    const [condition, setCondition] = useState(1)
    const [option1, setOption1] = useState(false)
    const [option2, setOption2] = useState(false)
    const [option3, setOption3] = useState(false)
    const [option4, setOption4] = useState(false)

    const [showLoading, setShowLoading] = useState(true)
    const [showSaving, setShowSaving] = useState(false)

    const dateFormat = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const dateTimeFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH")
        const time_string = new Date(datetime).toLocaleTimeString("th-TH")
        return date_string+' '+time_string
    }

    function isOnlyNumeric(text) {
        return /^[0-9\b]+$/.test(text)
    }

    const datePickerFormat = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("en-CA"):"")
    }

    const handleCloseAcceptPostShop = () => {
        setOpenAcceptPostShop(false)
    }

    const validateAnswerForm = () => {
        if(!answer) {
            setAnswerErrorDiv(true)
            return false
        } else {
            setAnswerErrorDiv(false)
        }
        return true
    }

    const handleAnswerSubmit = (memberId) => {
        if(validateAnswerForm()) {
            setShowSaving(true)
            const new_date = new Date()
            setDoc(doc(db, `/postsshops/${postId}/questions/`, memberId), {
                last_update: new_date.getTime()
            }).then(() => {
                addDoc(collection(db, `/postsshops/${postId}/questions/${memberId}/messages`), {
                    message: answer,
                    message_datetime: new_date.getTime(),
                    from_user: 0,
                    status: 1
                }).then(() => {
                    setAnswer('')
                    setShowSaving(false)
                    getQuestionsOwner(postId)
                })
            })
        }
    }

    const validateQuestionForm = () => {
        if(!question) {
            setQuestionErrorDiv(true)
            return false
        } else {
            setQuestionErrorDiv(false)
        }
        return true
    }

    const handleQuestionSubmit = () => {
        if(validateQuestionForm()) {
            setShowSaving(true)
            const new_date = new Date()
            setDoc(doc(db, `/postsshops/${postId}/questions/`, memberId), {
                last_update: new_date.getTime()
            }).then(() => {
                addDoc(collection(db, `/postsshops/${postId}/questions/${memberId}/messages`), {
                    message: question,
                    message_datetime: new_date.getTime(),
                    from_user: 1,
                    status: 1
                }).then(() => {
                    setQuestion('')
                    setShowSaving(false)
                    getQuestionsUser(postId, memberId)
                })
            })
        }
    }

    const getQuestionsUser = async (id, memberId) => {
        const questionsQuery = query(collection(db, `postsshops/${id}/questions/${memberId}/messages`), orderBy('message_datetime'))
        onSnapshot(questionsQuery, (querySnapshot) => {
            setQuestions(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const getQuestionsOwner = async (id) => {
        ownerQuestions.splice(0, ownerQuestions.length)
        setOwnerQuestion([...ownerQuestions])
        const questionsQuery = query(collection(db, `postsshops/${id}/questions/`))
        onSnapshot(questionsQuery, (querySnapshot) => {
            querySnapshot.docs.forEach(async(user) => {
                const userSnapshot = await getDoc(doc(db, 'users', user.id))
                const messageSnapshot = await getDocs(query(collection(db, `postsshops/${id}/questions/${user.id}/messages`), orderBy('message_datetime', 'asc')))
                ownerQuestions.push({
                    user_id: userSnapshot.id,
                    user: userSnapshot.data(),
                    messages: messageSnapshot.docs
                })
                setOwnerQuestion([...ownerQuestions], {
                    user_id: userSnapshot.id,
                    user: userSnapshot.data(),
                    messages: messageSnapshot.docs
                })
            })
        })
    }

    const handlePickCoin = (event) => {
        setFromCoinOffer(event.target.value);
    }

    const getImages = async (id) => {
        const imagesQuery = query(collection(db, `postshops/${id}/images`))
        onSnapshot(imagesQuery, (querySnapshot) => {
            setImages(querySnapshot.docs.map((doc) => { 
                return {
                    id: doc.id,
                    data: doc.data(),
                }
            }))
        })
    }

    const getBrandName = (brand_id) => {
        getDoc(doc(db, `brands`, brand_id)).then((doc) => {
            setBrandName(doc.data().name)
        })
    }

    const getModelName = (model_id) => {
        if(model_id == '999') {
            setModelName('อื่นๆ')
        } else {
            getDoc(doc(db, `models`, model_id)).then((doc) => {
                setModelName(doc.data().name)
            })
        }
    }

    const getPost = (id) => {
        getDoc(doc(db, 'postshops', postId)).then((postDoc) => {
            if (postDoc.exists()) {
                setPost(postDoc.data())
                setShowImages(postDoc.data().picture)
                setPosterId(postDoc.data().poster_id)
                setStatus(postDoc.data().status)
                getBrand(db, postDoc.data().brand_id, postDoc.data().brand).then((brand) => {
                    setBrandName(brand)
                })
                getModel(db, postDoc.data().model_id, postDoc.data().model).then((model) => {
                    setModelName(model)
                })
                getElectric(db, postDoc.data().type_id, postDoc.data().other_type).then((typeName) => {
                    setType(typeName)
                })
                getDoc(doc(db, `shops/`, postDoc.data().poster_id)).then((posterDoc) => {
                    const dataPoster = posterDoc.data()
                    if(dataPoster.user_id) setOwnerId(dataPoster.user_id)
                    if(dataPoster.shopname) setPosterName(dataPoster.shopname)
                    getDocs(query(collection(db, 'posts'), 
                            where('poster_id', '==', postDoc.data().poster_id),
                            where('status', 'not-in', [5, 6])
                    )).then((numPosts) => {
                        var num = 0
                        num = num + numPosts.docs.length
                    })
                    if(dataPoster.score_average) { 
                        setPosterScore(dataPoster.score_average) 
                    } else {
                        setPosterScore(0)
                    }
                })

                setPickDate(dayjs(datePickerFormat(postDoc.data().pick_date)))
                setAddress(postDoc.data().address)
                setAddressNo(postDoc.data().address_no)
                setVillage(postDoc.data().village)
                setBuilding(postDoc.data().building)
                setFloor(postDoc.data().floor)
                setRoom(postDoc.data().room)
                setSubroad(postDoc.data().subroad)
                setRoad(postDoc.data().road)

                var promisesTask = []

                if(postDoc.data().province) {
                    const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', postDoc.data().province * 1))).then((docs) => {
                        if(docs.docs.length > 0) setProvince(docs.docs[0].data().province_name)
                    })
                    promisesTask.push(provinceTask)
                }

                if(postDoc.data().district) {
                    const districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', postDoc.data().district))).then((docs) => {
                        if(docs.docs.length > 0) setDistrict(docs.docs[0].data().district_name)
                    })
                    promisesTask.push(districtTask)
                }

                if(postDoc.data().sub_district) {
                    const subdistrictTask = getDocs(query(collection(db, 'subdistrincts'), where('subdistrict_id', '==', postDoc.data().sub_district * 1))).then((docs) => {
                        if(docs.docs.length > 0) setSubDistrict(docs.docs[0].data().subdistrict_name)
                    })
                    promisesTask.push(subdistrictTask)
                }

                setPostcode(postDoc.data().postcode)

                Promise.all(promisesTask)

                getImages(id)
                getQuestionsOwner(id)

                if(postDoc.data().views) {
                    setPostView((postDoc.data().views*1) + 1)
                    updateDoc(doc(db, `/postshops`, postId), {
                        views: (postDoc.data().views*1) + 1
                    })
                } else {
                    setPostView(1)
                    updateDoc(doc(db, `/postshops`, postId), {
                        views: 1
                    })
                }
            } else {
                console.log("Post doesn't exist")
                navigate('/')
            }
            setShowLoading(false)
        }).then(() => {
            getDoc(doc(db, 'coins', 'use_rate')).then((exchangeSnapshot) => {
                const useCoin = exchangeSnapshot.data()
                setOfferUseCoin(useCoin.board7_2)
            })
        })
    }

    const getDocUser = async () => {
        setShowLoading(true)
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setMemberId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)

                if(data.shop_id) setShopId(data.shop_id)
                if(data.freecoin) setOfferFreeCoin(data.freecoin)
                if(data.exchange_coin) setOfferExchangeCoin(data.exchange_coin)

                getDocs(query(collection(db, `postshops/${postId}/offers`), where("user_id", "==", docs.docs[0].id))).then((offers) => {
                    if (offers.docs.length > 0) {
                        const dataOffer = offers.docs[0].data()
                        setOffered(true)
                        setOffer(dataOffer)
                    }
                }).catch((error) => {
                    alert(error.message)
                })

                setShowLoading(false)
            }
        }).catch((error) => {
            alert(error.message)
        })
    }

    const validateOfferForm = () => {
            if(!offerPrice) {
                setPriceErrorDiv(true)
                return false;
            } else {
                setPriceErrorDiv(false)
            }
        return true
    }
    
    const handleOfferSubmit = () => {
        if(validateOfferForm()) {
            const new_date = new Date()
            addDoc(collection(db, `/postshops/${postId}/offers`), {
                user_id: memberId,
                pick_date: pickDate.toDate(),
                hour: pickHour,
                minute: pickMinute,
                price: offerPrice,
                message: offerMessage,
                offer_timestamp: new_date.getTime(),
                delivery_offer: offerDelivery,
                status: 1
            }).then(function(docRef) {
                addDoc(collection(db, `/users/${memberId}/offers`), {
                    post_id: postId,
                    offer_id: docRef.id,
                    pick_date: pickDate.toDate(),
                    hour: pickHour,
                    minute: pickMinute,
                    price: offerPrice,
                    message: offerMessage,
                    offer_timestamp: new_date.getTime(),
                    status: 1
                }).then(() => {
                    var promisesTask = []
                    var updatePostTask = updateDoc(doc(db, `/postshops`, postId), { status: 2 })
                    promisesTask.push(updatePostTask)

                    var addShopCoinLogTask = addDoc(collection(db, `/users/${memberId}/coin_log`), {
                        date: new_date.getTime(),
                        coin: offerUseCoin,
                        event: "ยื่นข้อเสนอซื้อเครื่องใช้ไฟฟ้ามือสอง",
                        post_id: postId,
                        coin_type: fromCoinOffer
                    })
                    promisesTask.push(addShopCoinLogTask)
                    
                    var addUserNotiTask = addDoc(collection(db, `/shops/${post.poster_id}/notifications`), {
                        date: new_date.getTime(),
                        message: 'มีข้อเสนอใหม่ในประกาศขายเครื่องใช้ไฟฟ้ามือสองชองคุณ',
                        type: 'post',
                        post_id: postId,
                        link: '/shop/posts',
                        status: 0
                    })
                    promisesTask.push(addUserNotiTask)

                    var coinType = 'free_coins'
                    var updateShopCoinTask = updateDoc(doc(db, `/users`, memberId), {
                        freecoin: (offerFreeCoin*1)-(offerUseCoin*1)
                    })
                    if(fromCoinOffer == 'exchange') { 
                        coinType = 'exchange_coins' 
                        updateShopCoinTask = updateDoc(doc(db, `/users`, memberId), {
                            exchange_coin: (offerExchangeCoin*1)-(offerUseCoin*1)
                        })
                    }
                    promisesTask.push(updateShopCoinTask)

                    var updateCoinTask = getDoc(doc(db, coinType, 'sum_data')).then((sumDoc) => {
                        updateDoc(doc(db, coinType, 'sum_data'), {
                            used: (sumDoc.data().used*1) + (offerUseCoin*1)
                        }).then(() => {
                            addDoc(collection(db, `/coins/used_log/transaction`), {
                                date: new_date.getTime(),
                                coin: offerUseCoin,
                                event: "ยื่นข้อเสนอซื้อเครื่องใช้ไฟฟ้ามือสอง",
                                post_id: postId,
                                coin_type: fromCoinOffer,
                                user_id: memberId
                            })
                        })
                    })
                    promisesTask.push(updateCoinTask)

                    Promise.all(promisesTask).then(() => {
                        navigate(0)
                    })
                })
            }).catch((error) => {
                console.log(error)
                navigate('/')
            })
        }
    }

    const resizeDiv = new ResizeObserver((event) => {
        //console.log(event[0].contentBoxSize[0].inlineSize)
        //console.log(event[0])
        setWidth(event[0].contentBoxSize[0].inlineSize-1)
        setHeight(imagePostHeight)
    })

    useEffect(() => {
        resizeDiv.observe(document.getElementById("divPost"))
    })

    useEffect(() => {
        if (loading) return;
        if (user) getDocUser()
        getPost(postId)
        getSettings()
    }, [loading])

    const [offerFunction, setOfferFunction] = useState(true)

    const getSettings = () => {
        getDoc(doc(db, 'settings', 'functions')).then((result) => {
            const data = result.data()
            if(data.offer) { setOfferFunction(true) } else { setOfferFunction(false) }
        })
    }

    return (
        <>
            <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
            <div className="container-fluid">
                <div className="flex-container" style={{ paddingTop: 20, paddingBottom: 20, paddingLeft: 10, paddingRight: 10  }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex' }}>
                            <p style={{ fontSize: h1FontSize, fontWeight: '600', color: '#3D5598', marginBottom: 0 }}>
                                ประกาศขายเครื่องใช้ไฟฟ้ามือสอง
                            </p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            <div className="flex-container" style={{ flex: 1 }}>
                                <font style={{ fontSize: h2FontSize, fontWeight: '600' }}>ประกาศโดย:&nbsp;<font style={{ fontWeight: '400' }}>ร้านค้า</font></font>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'row', wordWrap: 'unset', alignItems: 'end', marginBottom: 3, marginLeft: 10 }}>
                                <font style={{ fontSize: h3FontSize, color: '#626167' }}>จำนวนผู้เข้าชม {number_format.format(postView)}</font>
                            </div>
                        </div>
                        <div id="divPost" style={{ display: 'flex', flexDirection: 'column', marginTop: 5, borderWidth: 1, borderColor: '#000000', borderStyle: 'inset', borderTopRightRadius: 15, borderTopLeftRadius: 15 }}>
                            <div style={{ display: 'flex', flexDirection: 'row' }}>
                                <img style={{ display: 'flex', flex: 1, objectFit: "cover", borderTopRightRadius: 15, borderTopLeftRadius: 15, maxWidth: width }} src={showImage} alt="APP3R" />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', alignContent: 'center', paddingTop: 10, paddingBottom: 5 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', flex: 1, alignItems: 'center', alignContent: 'center', flexWrap: 'wrap' }}>
                                {images?.map((image, i) => (
                                    <div key={image.id}  style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', paddingLeft: 10, paddingRight: 10 }}>
                                        <img style={{ display: 'flex', maxWidth: thumpnailWidth }} src={image.data.url} alt="APP3R" onClick={() => { setShowImages(image.data.url) }} />
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                        {offerFunction && 
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                            {(isShop == 1 && !offered && (post.poster_id != shopId) && (status == 1 || status ==2)) &&
                            (<div style={{ marginTop: 15, marginBottom: 10, flex: 1, display: 'flex', flexDirection: 'column' }} /* className="flex-container-row" */>
                                {/* <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                    <h6>ใช้ <strong>{number_format.format(offerUseCoin)}</strong> เหรียญ เพื่อยื่นข้อเสนอ คุณมี</h6>
                                    <h6>เหรียญแจกฟรี <strong>{number_format.format(offerFreeCoin)}</strong> เหรียญ<br/>เหรียญแลกเปลี่ยน <strong>{number_format.format(offerExchangeCoin)}</strong> เหรียญ</h6>
                                </div> */}
                                <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                    <button type="button" className="btn btn-primary" style={{ width: 200 }} 
                                        onClick={() => setOpenAcceptPostShop(true)}>
                                        <AiOutlineCheckCircle style={{ fontSize: h2FontSize, marginRight: 5, marginBottom: 3 }} />
                                        <font style={{ fontSize: h3FontSize }}>{(post.onboard==2 || post.onboard==3)?"ยื่นข้อเสนอซื้อ":"ยื่นข้อเสนอ"}</font>
                                    </button>
                                </div>
                            </div>)
                            }
                        </div>
                        }
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#FBEFE8', padding: 15 }}>
                            <p className="flex-container" style={{ marginBottom: 0 }}>
                                <font style={{ fontSize: h2FontSize, fontWeight: '600', color: '#3D5598', marginRight: 5 }}>ผู้ประกาศ:&nbsp;<font style={{ fontWeight: '400' }}>{posterName}</font></font>
                                <font className="flex-container" style={{ marginTop: 5 }}><StarRating score={posterScore} /> ({number_format.format(posterScore)} คะแนน)</font>
                            </p>
                            <p style={{ display: 'flex', flexDirection: 'row', marginBottom: 0 }}>
                                <font className="flex-container" style={{ fontSize: h3FontSize, marginBottom: 0, color: '#626167' }}>
                                    <font style={{ display: 'flex', flexDirection: 'column' }}>จำนวนประกาศทั้งหมด {numPost} ครั้ง&nbsp;</font>
                                    <font style={{ display: 'flex', flexDirection: 'column' }}><Link to={`/postlist/${onBoard}/${posterId}`} target='_blank'>[คลิกเพื่อดูประกาศทั้งหมดของผู้ประกาศรายนี้]</Link></font>
                                </font>
                            </p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h1FontSize, fontWeight: '600', color: '#FF0000', marginBottom: 0 }}>
                                ราคาขาย: {number_format.format(post.price)} บาท
                            </p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>รุ่น: <font style={{ fontWeight: '400' }}>{modelName}</font></p>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 5 }}>ยี่ห้อ: <font style={{ fontWeight: '400' }}>{brandName}</font></p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <p style={{ fontSize: textFontSize, fontWeight: '400', marginBottom: 5 }}>ขนาด (กxยxส): {post.width?post.width:"ไม่ระบุ"} x {post.length?post.length:"ไม่ระบุ"} x {post.height?post.height:"ไม่ระบุ"} (ซม.)</p>
                            <p style={{ fontSize: textFontSize, fontWeight: '400', marginBottom: 5 }}>น้ำหนัก: {post.weight?post.weight+" กิโลกรัม":"ไม่ระบุ"}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <p style={{ fontSize: h3FontSize, fontWeight: '600', marginBottom: 0 }}>ปีที่ซื้อ: <font style={{ fontWeight: '400' }}>{post.buy_year?"พ.ศ. "+post.buy_year:"ไม่ระบุ"}</font></p>
                                <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 0 }}>สถาพ: 
                                    <font style={{ fontWeight: '400' }}>
                                    {post.condition?"":" ไม่ระบุ"}
                                    {post.condition==1?" มือสองเหมือนใหม่":""}
                                    {post.condition==2?" มือสองสภาพดี":""}
                                    {post.condition==3?" มือสองพอใช้":""}
                                    {post.condition==4?" มือสองเก่ามาก":""}
                                    </font>
                                </p>
                                <p style={{ fontSize: h3FontSize, fontWeight: '600', marginBottom: 0 }}>อื่นๆ: 
                                    <font style={{ fontWeight: '400' }}>
                                        {(!post.option1 && !post.option2 && !post.option3 && !post.option4)?" ไม่ระบุ":""}
                                        {post.option1?(<><br/>- อยู่ในระยะประกัน</>):""}
                                        {post.option2?(<><br/>- มีกล่อง</>):""}
                                        {post.option3?(<><br/>- มีคู่มือการใช้งาน</>):""}
                                        {post.option4?(<><br/>- เอกสารอื่นๆ</>):""}
                                    </font>
                                </p>
                            </div>
                        </div>
                        <div style={{ display: 'flex', paddingLeft: 10, paddingRight: 10}}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600' }}>วันเวลาที่สะดวก: {dateFormat(post.pick_date)} เวลา {post.hour}:{post.minute} น.</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <p style={{ fontSize: textFontSize, fontWeight: '400' }}>รายละเอียดเพิ่มเติม: {post.description?post.description:"ไม่ระบุ"}</p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <p style={{ fontSize: textFontSize, fontWeight: '400' }}>ที่อยู่: {(addressNo)?"บ้านเลขที่ "+addressNo+" ":""} 
                                {(village)?"หมู่บ้าน "+village+" ":""} 
                                {(building)?"อาคาร "+building+" ":""} 
                                {(floor)?"ชั้น "+floor+" ":""} 
                                {(room)?"ห้อง "+room+" ":""} 
                                {(subroad)?"ซอย "+subroad+" ":""} 
                                {(road)?"ถ."+road+" ":""} 
                                {(subdistrict)?subdistrict+" ":""} 
                                {(district)?district+" ":""} 
                                {(province)?province+" ":""} 
                                {(postcode)?postcode+" ":""}
                            </p>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10, paddingRight: 10, color: '#626167' }}>
                            <p style={{ fontSize: textFontSize, fontWeight: '400' }}>ที่อยู่เพิ่มเติม: {post.address?post.address:"ไม่ระบุ"}</p>
                        </div>
                        {offerFunction && 
                        <div style={{ display: 'flex', flexDirection: 'row' }}>
                        {(isShop == 1 && !offered && (post.poster_id != shopId) && (status == 1 || status ==2)) &&
                            (<div style={{ marginBottom: 10, display: 'flex', flexDirection: 'column', flex: 1 }} className="d-sm-none">
                                <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column', alignItems: 'end' }}>
                                    <button type="button" className="btn btn-primary" style={{ width: 200 }} 
                                        onClick={() => setOpenAcceptPostShop(true)}>
                                        <AiOutlineCheckCircle style={{ fontSize: h2FontSize, marginRight: 5, marginBottom: 3 }} />
                                        <font style={{ fontSize: h3FontSize }}>{(post.onboard==2 || post.onboard==3)?"ยื่นข้อเสนอซื้อ":"ยื่นข้อเสนอ"}</font>
                                    </button>
                                </div>
                            </div>)
                        }
                        </div>
                        }
                        {(user && (post.poster_id != shopId)) && (<div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F7FB', padding: 15 }}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 5 }}>คุยกับผู้ประกาศ</p>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {questions?.map((message, i) => 
                                <div style={{ display: 'flex', flexDirection: 'column' }} key={i}>
                                    {(message.data.from_user == 1) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'end' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: 'lightyellow', borderRadius: 15,
                                            }}>{message.data.message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'end' }}>{dateTimeFormat(message.data.message_datetime)}</p>
                                    </div>}

                                    {(message.data.from_user == 0) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'start' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: '#FFFFFF', borderRadius: 15,
                                            }}>{message.data.message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'start' }}>{dateTimeFormat(message.data.message_datetime)}</p>
                                    </div>}
                                </div>
                            )}
                            </div>
                            {((status == 1 || status ==2)) && (
                            <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 10}}>
                                    <input type='text' name='question' onChange={(e) => setQuestion(e.target.value)} 
                                        value={question} placeholder='ข้อความ'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    {questionErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกข้อความ</strong>
                                        </div>
                                    }
                                </div>
                                <button type="button" className="btn btn-success" style={{ width: 150 }} 
                                    onClick={handleQuestionSubmit}>
                                    <AiOutlinePlusCircle style={{ fontSize: textFontSize, marginRight: 5, marginBottom: 3 }} />
                                    <font style={{ fontSize: textFontSize }}>ส่งข้อความ</font>
                                </button>
                            </div>)}
                        </div>)}
                        {((post.poster_id == shopId)) && (
                        <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#F3F7FB', padding: 15 }}>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 5 }}>คำถามจากผู้ที่สนใจ</p>
                            {ownerQuestions?.map((user, j) => (<>
                            <p style={{ fontSize: h2FontSize, fontWeight: '600', marginBottom: 5 }}>ข้อความจาก {user.user.firstname} {user.user.lastname}</p>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {user.messages?.map((message, i) => 
                                <div style={{ display: 'flex', flexDirection: 'column' }}  key={i}>
                                    {(message.data().from_user == 0) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'end' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: 'lightyellow', borderRadius: 15,
                                            }}>{message.data().message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'end' }}>{dateTimeFormat(message.data().message_datetime)}</p>
                                    </div>}

                                    {(message.data().from_user == 1) &&
                                    <div key={i} style={{ display: 'flex', flexDirection: 'column', alignSelf: 'start' }} >
                                        <p style={{ 
                                            padding: 15, display: 'flex', flexDirection: 'column', marginBottom: 3,
                                            backgroundColor: '#FFFFFF', borderRadius: 15,
                                            }}>{message.data().message}</p>
                                        <p style={{paddingLeft: 15, paddingBottom: 0, fontSize: 14, color: '#626167', alignSelf: 'start' }}>{dateTimeFormat(message.data().message_datetime)}</p>
                                    </div>}
                                </div>
                            )}
                            </div>
                            <div style={{ minWidth: 200, display: 'flex', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginBottom: 10}}>
                                    <input type='text' name='answer' onChange={(e) => setAnswer(e.target.value)} 
                                        value={answer} placeholder='ข้อความ'
                                        style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                    {answerErrorDiv &&
                                        <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                            <strong>** กรุณากรอกข้อความ</strong>
                                        </div>
                                    }
                                </div>
                                <button type="button" className="btn btn-success" style={{ width: 150 }} 
                                    onClick={() => { handleAnswerSubmit(user.user_id) }}>
                                    <AiOutlinePlusCircle style={{ fontSize: textFontSize, marginRight: 5, marginBottom: 3 }} />
                                    <font style={{ fontSize: textFontSize }}>ส่งข้อความ</font>
                                </button>
                            </div>
                            </>)
                            )}
                        </div>)}
                    </div>
                </div>
            </div>

            <Dialog open={openAcceptPostShop} onClose={handleCloseAcceptPostShop} fullWidth={true} maxWidth="md">
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'>
                    <div style={{ fontFamily: 'Prompt'}}>
                        <h3><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                        <strong>ยื่นข้อเสนอซื้อเครื่องใช้ไฟฟ้ามือสอง</strong></h3>
                    </div>
                </DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', }}>
                            <div style={{ display:'flex', flexDirection: 'row' }}>
                                <div style={{ backgroundColor: '#ffffff', flex: 1 }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                        <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                            <ImCoinDollar style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                            <h5><strong>หักเหรียญจาก</strong></h5>
                                        </label>
                                        <div style={{ display: 'flex', flex: 1, paddingLeft: 30, flexDirection: 'column' }}>
                                            <RadioGroup
                                                defaultValue="freecoin" name="fromCoinOffer"
                                                value={fromCoinOffer} onChange={handlePickCoin}
                                            >
                                                <FormControlLabel control={<Radio />} value='freecoin'
                                                    label={<span style={{ fontFamily: 'Prompt' }}>{"เหรียญแจกฟรี "}<strong>{number_format.format(offerFreeCoin)}</strong> เหรียญ</span>}
                                                />
                                                <FormControlLabel control={<Radio />} value='exchange'
                                                    label={<span style={{ fontFamily: 'Prompt' }}>{"เหรียญแลกเปลี่ยน "}<strong>{number_format.format(offerExchangeCoin)}</strong> เหรียญ</span>}
                                                />
                                            </RadioGroup>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff' }}>
                                        <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <MdOutlineCalendarToday style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                            <h5><strong>วันเวลาที่ร้านค้าสะดวก</strong></h5>
                                        </label>
                                        <div style={{ }}><h5 style={{ marginLeft: 30 }}>{dateFormat(post.pick_date)} เวลา {post.hour}:{post.minute} น.</h5></div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                        <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <MdOutlineCalendarToday style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                            <h5><strong>วันเวลาที่สะดวกซื้อเครื่องใช้ไฟฟ้ามือสอง</strong></h5>
                                        </label>
                                        <div style={{ display: 'flex', flex: 1, paddingLeft: 30 }}>
                                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                                                <DatePicker format="DD/MM/YYYY" minDate={dayjs(datePickerFormat(post.pick_date))} defaultValue={dayjs(datePickerFormat(post.pick_date))} onChange={(value) => setPickDate(value)} />
                                            </LocalizationProvider>
                                            <select name='hour' onChange={(e) => setPickHour(e.target.value)} style={{ width: 70, marginLeft: 10 }}>
                                                <option value="00">00</option>
                                                <option value="01">01</option>
                                                <option value="02">02</option>
                                                <option value="03">03</option>
                                                <option value="04">04</option>
                                                <option value="05">05</option>
                                                <option value="06">06</option>
                                                <option value="07">07</option>
                                                <option value="08">08</option>
                                                <option value="09">09</option>
                                                <option value="10">10</option>
                                                <option value="11">11</option>
                                                <option value="12">12</option>
                                                <option value="13">13</option>
                                                <option value="14">14</option>
                                                <option value="15">15</option>
                                                <option value="16">16</option>
                                                <option value="17">17</option>
                                                <option value="18">18</option>
                                                <option value="19">19</option>
                                                <option value="20">20</option>
                                                <option value="21">21</option>
                                                <option value="22">22</option>
                                                <option value="23">23</option>
                                            </select>
                                            <select name='minute' onChange={(e) => setPickMinute(e.target.value)} style={{ width: 70, marginLeft: 5 }}>
                                                <option value="00">00</option>
                                                <option value="15">15</option>
                                                <option value="30">30</option>
                                                <option value="45">45</option>
                                            </select>
                                        </div>
                                    </div> 
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                        <label className="" style={{ display: 'flex', flexDirection: 'row'}}>
                                            <MdOutlineAttachMoney style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                            <h5><strong>ราคาเสนอซื้อ (บาท) <font className="text-danger">*</font></strong></h5>
                                        </label>
                                        <div style={{ display: 'flex', flex: 1, flexDirection: 'column', paddingLeft: 30 }}>
                                            <input type='text' name='offerPrice' onChange={(e) => setOfferPrice(e.target.value)} 
                                                value={offerPrice} placeholder='ราคาซื้อ (บาท)'
                                                style={{ paddingLeft: 8, display: 'flex', flex: 1 }} />
                                            {priceErrorDiv &&
                                                <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                                    <strong>** กรุณากรอกราคาเสนอซื้อ</strong>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                <div style={{ backgroundColor: '#ffffff', flex: 1 }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                        <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <FaCarSide style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                            <h5><strong>เลือกวิธีจัดส่ง</strong></h5>
                                        </label>
                                        <div style={{ display: 'flex', flex: 1, paddingLeft: 30 }}>
                                            <select name='useCoinFrom'
                                            value={offerDelivery}
                                            onChange={(e) => setOfferDelivery(e.target.value)} style={{ marginLeft: 0, paddingLeft: 5, flex: 1 }}>
                                                {post.delivery_option1 && (<option value="1">ไปรับสินค้าที่ร้าน/บริษัท</option>)}
                                                {post.delivery_option2 && (<option value="2">บริการส่งสินค้าที่พัก ราคาขนส่ง {post.delivery_price?number_format.format(post.delivery_price)+" บาท":"จัดส่งฟรี"}</option>)}
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginTop: 10 }}>
                                        <label className="" style={{ display: 'flex', flexDirection: 'row' }}>
                                            <MdOutlineMessage style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} />
                                            <h5><strong>สอบถามรายละเอียดเพิ่มเติม</strong></h5>
                                        </label>
                                        <div style={{ display: 'flex', flex: 1, paddingLeft: 30 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setOfferMessage(e.target.value)}
                                                placeholder='สอบถามรายละเอียดเพิ่มเติม' 
                                                value={offerMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseAcceptPostShop} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ยกเลิก</button>
                    <button onClick={handleOfferSubmit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยื่นข้อเสนอ</button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Post