import { GoGraph } from "react-icons/go"

const reportRows = [
    {id: 1, title: "ผู้ใช้งานในระบบ", link: "/admin/reports/user"},
    {id: 2, title: "การทิ้งซากเครื่องใช้ไฟฟ้า", link: "#2"},
    {id: 3, title: "ขายซากเครื่องใช้ไฟฟ้า", link: "#3"},
    {id: 4, title: "ประชาชนขายเครื่องใช้ไฟฟ้ามือสอง", link: "#4"},
    {id: 5, title: "ร้าน/บริษัทขายเครื่องใช้ไฟฟ้ามือสอง", link: "#5"},
    {id: 6, title: "ซ่อมเครื่องใช้ไฟฟ้า", link: "#6"},
    {id: 7, title: "บริการล้างแอร์", link: "#7"},
    {id: 8, title: "บริการล้างเครื่องซักผ้า", link: "#8"},
    {id: 9, title: "ตรวจสอบเหรียญในระบบ", link: "#9"},
]

const Reports = () => {
    return (
        <div className="container-fluid" style={{ marginTop: "30px" }}>
            <div className="row">
                <div className="col">
                    <h4><GoGraph /> รายงาน</h4>
                    <hr />
                </div>
            </div>
            <div className="row">
                <div className="col">
                    <table className="table">
                        <thead>
                            <tr>
                                <th>#</th>
                                <th>รายงาน</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                reportRows.map((row) => (
                                    <tr>
                                        <td><a className="nav-link link-dark" href={row.link}>{row.id}</a></td>
                                        <td><a className="nav-link link-dark" href={row.link}>{row.title}</a></td>
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default Reports