import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, auth } from "../../assets/js/firebase"
import { doc, getDoc, deleteDoc } from 'firebase/firestore'
import { useNavigate, useParams } from "react-router-dom"
import { FaMapMarkerAlt } from 'react-icons/fa'
import { RxHome } from "react-icons/rx"
import { TbUser, TbEdit, TbUserPlus, TbTool } from "react-icons/tb"
import { AiFillShop } from "react-icons/ai"
import { IoCloseCircle, IoChevronBackCircleSharp } from "react-icons/io5"
import noImage from "../../assets/images/avatar.png"

import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogTitle from '@mui/material/DialogTitle'
import LeftMenu from './LeftMenu'

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Technician = () => {
    const params = useParams()
    const shopId = params.shopId
    const techId = params.techId
    const [shop, setShop] = useState([])
    const [member, setMember] = useState()
    const navigate = useNavigate()
    const [openDrawer, setOpenDrawer] = useState(false)

    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [telNo, setTelNo] = useState('')
    const [address, setAddress] = useState('')
    const [road, setRoad] = useState('')
    const [subDistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [postcode, setPostcode] = useState('')
    const [imageProfileUrl, setImageProfileUrl] = useState(noImage)

    const [lat, setLat] = useState(15.11745249400088)
    const [lng, setLng] = useState(104.90284046686465)

    const [loading] = useAuthState(auth)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    })
    const center = { lat: lat, lng: lng }

    const [openAlertDelete, setOpenAlertDelete] = useState(false);

    const handleCloseAlertDelete = () => {
        setOpenAlertDelete(false)
    }

    const deleteTech = () => {
        deleteDoc(doc(db, `shops/${shopId}/technician/`, techId)).then(() => {
            navigate(`/shop/technicians/${shopId}`)
        })
    }

    const getDocShop = async(id) => {
        await getDoc(doc(db, 'shops', id)).then((shopSnapshot) => {
            setShop(shopSnapshot.data());
            if(shopSnapshot.data().user_id) {
                getDoc(doc(db, 'users', shopSnapshot.data().user_id)).then((memberDoc) => {
                    setMember(memberDoc.data())
                })
            }
        })
    }

    const getDocTech = async(id) => {
        await getDoc(doc(db, `shops/${shopId}/technician/`, id)).then((techSnapshot) => {
            if(techSnapshot.data().firstname) setFirstname(techSnapshot.data().firstname)
            if(techSnapshot.data().lastname) setLastname(techSnapshot.data().lastname)
            if(techSnapshot.data().tel_no) setTelNo(techSnapshot.data().tel_no)
            if(techSnapshot.data().address) setAddress(techSnapshot.data().address)
            if(techSnapshot.data().road) setRoad(techSnapshot.data().road)
            if(techSnapshot.data().sub_district) setSubDistrict(techSnapshot.data().sub_district)
            if(techSnapshot.data().district) setDistrict(techSnapshot.data().district)
            if(techSnapshot.data().province) setProvince(techSnapshot.data().province)
            if(techSnapshot.data().postcode) setPostcode(techSnapshot.data().postcode)
            if(techSnapshot.data().lat) setLat(techSnapshot.data().lat)
            if(techSnapshot.data().lng) setLng(techSnapshot.data().lng)
            if(techSnapshot.data().image_profile) setImageProfileUrl(techSnapshot.data().image_profile)
        })
    }
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        getDocShop(shopId)
        getDocTech(techId)
    },[loading])


return (
    <>
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 20 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="technicians" isShop={true} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, borderBottom: '1px solid #cccccc', paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ข้อมูลช่าง</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="technicians" isShop={true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingTop: 10 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 30 }}>
                            <div className="flex-container" style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flex: 1 }}>
                                <div style={{ display: 'flex', flexDirection: 'row' , flex:1}}>
                                    <h4 style={{ display: 'flex', flex: 1}}><TbTool style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                                    <b>ข้อมูลช่าง</b></h4>
                                </div>
                                <div className="flex-container" style={{ display: 'flex' }}>
                                    
                    <div style={{ float: 'right', marginBottom: 5 }}>
                        <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/shop/technicians/${shopId}`) }}>
                            <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                        </button>
                    </div>
                    <div style={{ float: 'right', marginBottom: 5 }}>
                        <button type="button" className="btn btn-warning btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/technician/edit/${shopId}/${techId}`) }}>
                            <TbEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขช้อมูลช่าง
                        </button>
                    </div>
                    <div style={{ float: 'right', marginBottom: 5 }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { setOpenAlertDelete(true) }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ลบช้อมูลช่าง
                        </button>
                    </div>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 5 }}>
                        <div style={{ display: 'flex'}}>
                            <div style={{ display: 'flex', minWidth: 200}}>
                                <div style={{ margin: 10, minWidth: 100, maxWidth: 150}}>
                                    <img src={imageProfileUrl} alt="WEEE" className='img-fluid' />
                                </div>
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column'}}>
                                <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 140}}><strong>ชื่อ</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>{firstname?firstname:""}</div>
                                </div> 
                                <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 140}}><strong>นามสกุล</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>{lastname?lastname:""}</div>
                                </div>
                                <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 140}}><strong>เบอร์โทรศัพท์</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>{telNo?telNo:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                                </div>
                            </div>
                        </div> 
                        <div style={{ marginBottom: 10, marginTop: 5, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                            <h6><RxHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อมูลที่อยู่</b></h6>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>บ้านเลขที่, หมู่บ้าน, อาคาร</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>{address?address:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ถนน</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>{road?road:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ตำบล</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>{subDistrict?subDistrict:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>อำเภอ</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>{district?district:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>จังหวัด</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>{province?province:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                        </div> 
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>รหัสไปรษณีย์</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>{postcode?postcode:(<font className="text-danger">ไม่ระบุ</font>)}</div>
                        </div> 
                        <div style={{ marginTop: 10, marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                            <h6><FaMapMarkerAlt style={{ fontSize: 20, marginRight: 5 }} />
                            <b>ตำแหน่งพิกัดที่พักอาศัย</b></h6>
                        </div>
                        <div className="mb-3 row" style={{ display: 'flex', height:250, flexDirection: 'column' }}>
                            <div style={{  display: 'flex', flexWrap: 'nowrap', height:250, flex: 1 }}>
                                {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                    <MarkerF position={center} />
                                </GoogleMap>
                                )}
                            </div>
                            <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap', textAlign: 'right' }}>
                                <div style={{ display: 'flex', float: 'right', flex: 1 }}>lat:{lat}, lng:{lng}</div>
                            </div>
                        </div>
                    </div>
                    <div className="d-none d-md-block d-lg-block" style={{ display: 'flex', flexDirection: 'column', flex: 3, borderLeft: '1px solid #cccccc', marginLeft: 20, paddingLeft: 20}}>
                        <div style={{ paddingTop: 10, borderBottom: '1px solid #cccccc' }}>
                            <h6><AiFillShop style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                            <b>ข้อมูลร้านค้า</b></h6>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ผู้ใช้ </strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    {(member) && (
                                    <>
                                        <font style={{ marginRight: 10}}>{member.firstname?member.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                        <font style={{ marginRight: 10}}>{member.lastname?member.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                        <font style={{ marginRight: 10}}>({member.display_name?member.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font>
                                    </>
                                    )}
                                </div>
                            </div>
                        </div>
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>email </strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                <div style={{ display: 'flex', flex: 1 }}>
                                    {(member) && (
                                    <><font style={{ marginRight: 10}}>{member.email?member.email:""}</font></>
                                    )}
                                </div>
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ประเภทร้านค้า</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {(shop.type==1)&&(<div><TbUser style={{ fontSize: 20, marginRight: 5 }} />บุคคลธรรมดา</div>)}
                                {(shop.type==2)&&(<div><AiFillShop style={{ fontSize: 20, marginRight: 5 }} />นิติบุคคล</div>)}
                            </div>
                        </div> 
                        {(shop.type==2)&& (
                        <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อร้านค้า</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.shopname?shop.shopname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        )}
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>ชื่อ (เจ้าของ)</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.firstname?shop.firstname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>นามสกุล (เจ้าของ)</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.lastname?shop.lastname:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div> 
                        <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                            <label className="col-form-label" style={{ display: 'flex', width: 150}}><strong>เบอร์โทรศัพท์</strong></label>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                {shop.tel_no?shop.tel_no:(<font className="text-danger">ไม่ระบุ</font>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Dialog open={openAlertDelete} onClose={handleCloseAlertDelete} aria-labelledby="alert-dialog-title" aria-describedby="alert-dialog-description">
                <DialogTitle id="alert-dialog-title" style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>
                    {"คุณต้องการลบข้อมูลช่างคนนี้?"}
                </DialogTitle>
                <DialogActions>
                <Button onClick={handleCloseAlertDelete} style={{ fontFamily: 'Prompt' }}>ยกเลิก</Button>
                <Button onClick={() => { deleteTech() }} style={{ fontFamily: 'Prompt' }} autoFocus>ยืนยัน</Button>
                </DialogActions>
            </Dialog>



                    </div>
                </div>
            </div>
        </div>
    </>
)
}

export default Technician;