import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, getDocs, getDoc, addDoc, updateDoc, doc, deleteDoc, query, where } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { FiInfo, FiXCircle, FiCheckCircle } from "react-icons/fi"

import { Loading } from "../layouts/Loading"
import { addFreeCoinLog, addExchangeLog } from "../../../assets/js/LogHelper"
import { addShopNotification, addUserNotification } from "../../../assets/js/NotificationHelper"
import { getBrand, getModel } from '../helper/BrandHelper'
import { getElectric } from '../helper/ElectricTypeHelper'

export function OfferShopBoard1({offer}) {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)
    const [showLoading, setShowLoading] = useState(false)

    const postOfferId = offer.data.offer_id
    const postId = offer.post_id
    const shopOfferId = offer.id
    const shopId = offer.shop_id
    const userId = offer.post.poster_id

    const [type, setType] = useState('ไม่ระบุ')
    const [brand, setBrand] = useState('ไม่ระบุ')
    const [model, setModel] = useState('ไม่ระบุ')

    const [cancleMessage, setCancleMessage] = useState('')
    const [cancleReason, setCancleReason] = useState(1)

    const [openCancleOffer, setOpenCancleOffer] = useState(false)
    const [openShopConfirm, setOpenShopConfirm] = useState(false)
    const [openShopCancle, setOpenShopCancle] = useState(false)
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]+" น."
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleCloseShopConfirm = () => {
        setOpenShopConfirm(false)
    }

    const handleOpenShopConfirm = () => {
        setOpenShopConfirm(true)
    }

    const handleShopConfirm = () => {
        setShowLoading(true)
        const logDate = new Date()

        var promisesTask = []
        var updatePost = updateDoc(doc(db, `/posts`, postId), { status: 4 })
        promisesTask.push(updatePost)

        var updateAddNotiTask = addUserNotification(db, userId, "ผู้เสนอยืนยันเข้ารับซาก ดูรายละเอียดการนัดหมาย", 'offer', postId, "/profile/appointments")
        promisesTask.push(updateAddNotiTask)

        var addAppointment = addDoc(collection(db, 'appointments'), {
            post_id: postId,
            offer_id: postOfferId,
            shop_offer_id: shopOfferId,
            user_id: userId,
            shop_id: shopId,
            appointment_type: "post",
            post_onboard: 1,
            appointment_date: offer.data.pick_date,
            appointment_hour: offer.data.hour,
            appointment_minute: offer.data.minute,
            appointment_timestamp: logDate.getTime(),
            deposit_coin: 0,
            remain_coin: 0,
            status: 1
        })
        promisesTask.push(addAppointment)

        var updatePostOffer = updateDoc(doc(db, `/posts/${postId}/offers`, postOfferId), { status: 3 })
        promisesTask.push(updatePostOffer)

        var updateShopOffer = updateDoc(doc(db, `/shops/${shopId}/offers`, shopOfferId), { status: 3 })
        promisesTask.push(updateShopOffer)

        Promise.all(promisesTask).then(() => {
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleCloseCancleOffer = () => {
        setOpenCancleOffer(false)
    }

    const handleOpenCancleOffer = () => {
        setOpenCancleOffer(true)
    }

    const handleCancleOffer = () => {
        setShowLoading(true)
        const logDate = new Date()
        var promisesTask = []
        var addCancleHistoryTask = addDoc(collection(db, `posts/${postId}/cancle_offers`), {
            shop_id: shopId,
            cancle_reason: cancleReason,
            cancle_message: cancleMessage,
            cancle_datetime: logDate.getTime()
        })
        promisesTask.push(addCancleHistoryTask)

        if(cancleReason == 1) {
            var returnCoinTask = getDoc(doc(db, `posts/${postId}/offers`, postOfferId)).then((offerDoc) => {
                const amountCoin = offerDoc.data().coin * 1
                if(offerDoc.data().coin_type == 'freecoin') {
                    getDoc(doc(db, 'free_coins', 'sum_data')).then((sumDoc) => {
                        var updateCoinTask = updateDoc(doc(db, 'free_coins', 'sum_data'), {
                            remain: (sumDoc.data().remain * 1) - (amountCoin),
                            used: (sumDoc.data().used * 1) - (amountCoin)
                        }).then(() => {
                            addFreeCoinLog(db, amountCoin, "คืนเหรียญเงินให้ร้านค้าจำนวน "+amountCoin+" เหรียญ", postId, "return")
                            addShopNotification(db, shopId, "คุณได้รับเหรียญเงินคืนจำนวน "+amountCoin+" เหรียญ จากการยกเลิกข้อเสนอ", 'coin', postId, '/post/'+postId)
                        })
                        promisesTask.push(updateCoinTask)
                    })
                    getDoc(doc(db, `shops`, shopId)).then((shopDoc) => {
                        updateDoc(doc(db, `shops`, shopId), {
                            freecoin: (shopDoc.data().freecoin * 1) + (amountCoin)
                        })
                    })
                } else {
                    getDoc(doc(db, 'exchange_coins', 'sum_data')).then((sumDoc) => {
                        var updateCoinTask = updateDoc(doc(db, 'exchange_coins', 'sum_data'), {
                            remain: (sumDoc.data().remain * 1) - (amountCoin),
                            used: (sumDoc.data().used * 1) - (amountCoin)
                        }).then(() => {
                            addExchangeLog(db, amountCoin, "คืนเหรียญทองให้ร้านค้าจำนวน "+amountCoin+" เหรียญ", postId, "return")
                            addShopNotification(db, shopId, "คุณได้รับเหรียญทองคืนจำนวน "+amountCoin+" เหรียญ จากการยกเลิกข้อเสนอ", 'coin', postId, '/post/'+postId)
                        })
                        promisesTask.push(updateCoinTask)
                    })
                    getDoc(doc(db, `shops`, shopId)).then((shopDoc) => {
                        updateDoc(doc(db, `shops`, shopId), {
                            exchange_coin: (shopDoc.data().exchange_coin * 1) + (amountCoin)
                        })
                    })
                }
                var deletePostOfferTask = deleteDoc(doc(db, `posts/${postId}/offers`, postOfferId)).then(() => {
                    getDocs(query(collection(db, `posts/${postId}/offers`), where("status", "!=", 5))).then((docs) => {
                        var status = 1
                        if (docs.docs.length > 0) { status = 2 }
                        updateDoc(doc(db, `posts/`, postId), {
                            status: status
                        })
                    })
                })
                promisesTask.push(deletePostOfferTask)

                var deleteShopOfferTask = deleteDoc(doc(db, `shops/${shopId}/offers`, shopOfferId))
                promisesTask.push(deleteShopOfferTask)
            })
            promisesTask.push(returnCoinTask)
        } else {
            var deletePostOfferTask = deleteDoc(doc(db, `posts/${postId}/offers`, postOfferId)).then(() => {
                getDocs(query(collection(db, `posts/${postId}/offers`), where("status", "!=", 5))).then((docs) => {
                    var status = 1
                    if (docs.docs.length > 0) { status = 2 }
                    updateDoc(doc(db, `posts/`, postId), {
                        status: status
                    })
                })
            })
            promisesTask.push(deletePostOfferTask)

            var deleteShopOfferTask = deleteDoc(doc(db, `shops/${shopId}/offers`, shopOfferId))
            promisesTask.push(deleteShopOfferTask)
        }

        Promise.all(promisesTask).then(() => {
            setOpenCancleOffer(false)
            setShowLoading(false)
        })
    }

    const handleCloseShopCancle = () => {
        setOpenShopCancle(false)
    }

    const handleOpenShopCancle = () => {
        setOpenShopCancle(true)
    }

    const handleShopCancle = () => {
        setShowLoading(true)
        const logDate = new Date()
        
        updateDoc(doc(db, `posts/${postId}/offers`, postOfferId), {
            status: 5,
            cancle_date: logDate.getTime(),
            cancle_reason: cancleReason,
            cancle_message: cancleMessage
        }).then(() => {
            getDocs(query(collection(db, `posts/${postId}/offers`), where("status", "!=", 5))).then((docs) => {
                var promisesTask = []
                var postStatus = 1
                if (docs.docs.length > 0) { 
                    postStatus = 2 
                    var updateExistOfferTask = updateDoc(doc(db, `posts/${postId}/offers`, where("status", "!=", 5)), {
                        status: 1
                    })
                    promisesTask.push(updateExistOfferTask)
                }
                var updatePostTask = updateDoc(doc(db, `posts/`, postId), {
                    status: postStatus
                })
                promisesTask.push(updatePostTask)
                
                var updateAddNotiTask = addUserNotification(db, userId, "ผู้เสนอยกเลิกข้อเสนอ กรุณาเลือกข้อเสนอรายใหม่", 'offer', postId, "/profile/posts")
                promisesTask.push(updateAddNotiTask)

                var updateShopTask = updateDoc(doc(db, `shops/${shopId}/offers`, shopOfferId), {
                    status: 5
                })
                promisesTask.push(updateShopTask)

                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    useEffect(() => {
        getBrand(db, offer.post.brand_id, offer.post.brand).then((brandName) => {
            setBrand(brandName)
        })
    
        getModel(db, offer.post.model_id, offer.post.model).then((modelName) => {
            setModel(modelName)
        })
        getElectric(db, offer.post.type_id, offer.post.other_type).then((typeName) => {
            setType(typeName)
        })
    }, [loading])

    return (
        <>
            <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
            <div className="card" style={{ width: "20rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}  key={offer.post_id}>
                {offer.badgeStyle.show && 
                <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                    <button type="button" className={"btn "+"btn-"+offer.badgeStyle.class} style={{ color: offer.badgeStyle.color }}><strong>{offer.badgeStyle.message}</strong></button>
                </div>}
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                    <img src={offer.post.picture} alt="APP3R" height="150" />
                </div>
                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                    <p className="card-text">
                        <strong>ทิ้งซากเครื่องใช้ไฟฟ้า</strong><br/>
                        {type} {brand} {model}<br/>
                        <strong>ที่อยู่:</strong> {offer.post.address} <br/>
                        <strong>วันที่ยื่นข้อเสนอ:</strong> {dateFormat(offer.data.offer_timestamp)} <br/>
                        <strong>ข้อเสนอ:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.
                    </p>
                </div>
                <div className="card-body">
                    <Link className="btn btn-primary m-1" to={`/postFix/${offer.post_id}`} target='_blank'><FiInfo /> <strong>ดูประกาศ</strong></Link>
                    <Link className="btn btn-primary m-1" to={`/shop/postdetail/${offer.post.onboard}/${offer.post_id}/${offer.shop_id}`} target='_blank'><FiInfo /> <strong>ดูรายละเอียด</strong></Link>
                    {(offer.data.status==1) && (<>
                        <button type="button" className="btn btn-danger m-1" onClick={() => handleOpenCancleOffer() }><FiXCircle /> <strong>ยกเลิกข้อเสนอรับซาก</strong></button>
                    </>)}
                    {(offer.data.status==2) && (<>
                        <button type="button" className="btn btn-success m-1" onClick={() => handleOpenShopConfirm() }><FiCheckCircle /> <strong>ยืนยันการเข้ารับซาก</strong></button>
                        <button type="button" className="btn btn-danger m-1" onClick={() => handleOpenShopCancle() }><FiXCircle /> <strong>ยกเลิกการเข้ารับซาก</strong></button>
                    </>)}
                </div>
            </div>
            
            <Dialog open={openCancleOffer} onClose={handleCloseCancleOffer} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกข้อเสนอเข้ารับซาก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5, flex: 1 }}>
                                                <option value="1">หลังจากเสนอฯ ผู้ประกาศตอบกลับช้า</option>
                                                <option value="2">รับงานใหม่</option>
                                                <option value="3">อื่นๆ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <div style={{ backgroundColor: '#ffffff' }}>
                                            <h6 className='text-danger'><strong>*** คุณจะได้รับเหรียญคืนในกรณีที่ผู้ประกาศตอบกลับช้าเท่านั้น</strong></h6>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setCancleMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={cancleMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleOffer} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleOffer} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกข้อเสนอเข้ารับซาก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openShopConfirm} onClose={handleCloseShopConfirm} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันเข้ารับซาก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>คุณยืนยันเข้ารับซาก?</h5>
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShopConfirm} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleShopConfirm} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันเข้ารับซาก</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openShopCancle} onClose={handleCloseShopCancle} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการเข้ารับซาก</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5, flex: 1 }}>
                                                <option value="1">ไม่สะดวกไปตามเวลานัดหมาย</option>
                                                <option value="2">รับงานใหม่</option>
                                                <option value="3">อื่นๆ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100 }}
                                                onChange={(e) => setCancleMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={cancleMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShopCancle} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleShopCancle} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการเข้ารับซาก</button>
                </DialogActions>
            </Dialog>
        </>
    )
}