import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'

export function Alert({ title, text, open, close, submit}) {
    return (
        <Dialog open={open} onClose={close} maxWidth='sm' fullWidth={true}>
            <DialogTitle style={{ fontFamily: 'Prompt', fontWeight: 'bold'}}>{title}</DialogTitle>
            <DialogContent style={{ fontFamily: 'Prompt' }}>
                <div style={{ display: 'flex', flex: 1, marginLeft: 15 }}><h5>{text}</h5></div>
            </DialogContent>
            <DialogActions>
                <button onClick={close} className='btn btn-danger' style={{ fontFamily: 'Prompt' }}>ยกเลิก</button>
                <button onClick={submit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยัน</button>
            </DialogActions>
        </Dialog>
    )
}