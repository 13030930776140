import { getDoc, doc } from 'firebase/firestore'

export async function getShopTitle(db, shopId) {
    var title = getDoc(doc(db, `shops`, shopId)).then((doc) => {
        if(doc.data().shopname) {
            title = doc.data().shopname
        } else {
            title = doc.data().firstname+" "+doc.data().lastname
        }
        
        return title
    })

    return title
}