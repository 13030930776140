import React, { useState, useEffect } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, onSnapshot, addDoc, updateDoc, doc, deleteDoc, orderBy, and, or } from 'firebase/firestore'
import { Link, useNavigate } from "react-router-dom"
import LeftMenu from './LeftMenu'
import { IoMegaphoneOutline  } from 'react-icons/io5'
import { FiInfo, FiCalendar } from "react-icons/fi"

import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material';
import { Loading } from "../layouts/Loading"
import { OfferShopBoard1 } from './OfferBoard1'
import { OfferShopBoard2 } from './OfferBoard2'
import { OfferShopBoard3 } from './OfferBoard3'
import { OfferShopBoard4 } from './OfferBorad4'
import { OfferShopAir } from './OfferAir'
import { OfferShopWash } from './OfferWash'

import { IoMdMenu } from "react-icons/io"
import logo from "../../assets/images/weee-logo.png"
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'

const Offers = () => {
    const navigate = useNavigate()
    const [user, loading, error] = useAuthState(auth)

    const [uid, setUid] = useState('')
    const [memberId, setMemberId] = useState('')
    const [shopId, setShopId] = useState('')
    const [offers, setOffers] = useState([])
    const [searchBoard, setSearchBoard] = useState([])
    const [openDrawer, setOpenDrawer] = useState(false)

    const [chooseOffer, setChooseOffer] = useState([])
    const [chooseOfferId, setChooseOfferId] = useState('')
    const [choosePostId, setChoosePostId] = useState('')
    const [choosePostOnboard, setChoosePostOnboard] = useState('posts')
    const [shopOfferId, setShopOfferId] = useState('')
    const [depositEnough, setDepositEnough] = useState(false)
    const [offerFreeCoin, setOfferFreeCoin] = useState(0)
    const [offerExchangeCoin, setOfferExchangeCoin] = useState(0)
    const [depositMessage, setDepositMessage] = useState('')

    const [confirmMessage, setConfirmMessage] = useState('')
    const [confirmTitle, setConfirmTitle] = useState('')
    const [confirmButton, setConfirmButton] = useState('')
    const [cancleOfferMessage, setCancleOfferMessage] = useState('')
    const [cancleOfferTitle, setCancleOfferTitle] = useState('')
    const [cancleOfferButton, setCancleOfferButton] = useState('')

    const [openCancleOffer, setOpenCancleOffer] = useState(false);
    const [cancleMessage, setCancleMessage] = useState('');
    const [cancleReason, setCancleReason] = useState(1);

    const [openShopConfirm, setOpenShopConfirm] = useState(false);

    const [openShopCancle, setOpenShopCancle] = useState(false);
    const [shopCancleMessage, setShopCancleMessage] = useState('')
    const [shopCancleTitle, setShopCancleTitle] = useState('')
    const [shopCancleButton, setShopCancleButton] = useState('')

    const [showLoading, setShowLoading] = useState(true)
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        });
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]+" น."
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const handleCloseShopConfirm = () => {
        setOpenShopConfirm(false)
    }

    const handleOpenShopConfirm = (offerId, postId, onBoard, shopOfferId, offer) => {
        setChooseOfferId(offerId)
        setChoosePostId(postId)
        setChoosePostOnboard(onBoard)
        setShopOfferId(shopOfferId)
        setChooseOffer(offer)
        if(onBoard == 'cleans') {
            setDepositEnough(false)
            setDepositMessage('')
            setConfirmButton('ยืนยันให้บริการ')
            setDepositEnough(true)
            if(offer.post.onboard == 1) { 
                setConfirmTitle('ยืนยันให้บริการล้างแอร์')
                setConfirmMessage('คุณยืนยันให้บริการล้างแอร์?')
            } else if(offer.post.onboard == 2) {
                setConfirmTitle('ยืนยันให้บริการล้างเครื่องซักผ้า')
                setConfirmMessage('คุณยืนยันให้บริการล้างเครื่องซักผ้า?')
            }
        }
        setOpenShopConfirm(true)
    }

    const handleShopConfirm = () => {
        setShowLoading(true)
        const postData = chooseOffer.post
        const offerPostId = chooseOffer.data.offer_id
        const offerShopId = chooseOffer.id
        const postId = chooseOffer.post_id
        const confirmDate = new Date()
        var depositCoinAppoint = 0
        var remainCoinAppoint = 0

        var promisesTask = []
        var updatePost = updateDoc(doc(db, `/${choosePostOnboard}`, postId), { status: 4 })
        promisesTask.push(updatePost)

        if(choosePostOnboard == 'cleans') {
            var appointmentType = "air"
            if(postData.onboard == 2) {
                appointmentType = "washing"
            }
        }

        var addAppointment = addDoc(collection(db, 'appointments'), {
            post_id: postId,
            offer_id: offerPostId,
            shop_offer_id: offerShopId,
            user_id: postData.poster_id,
            shop_id: chooseOffer.shop_id,
            appointment_type: appointmentType,
            post_onboard: postData.onboard,
            appointment_date: chooseOffer.data.pick_date,
            appointment_hour: chooseOffer.data.hour,
            appointment_minute: chooseOffer.data.minute,
            appointment_timestamp: confirmDate.getTime(),
            deposit_coin: depositCoinAppoint,
            remain_coin: remainCoinAppoint,
            status: 1
        })
        promisesTask.push(addAppointment)

        var updatePostOffer = updateDoc(doc(db, `/${choosePostOnboard}/${postId}/offers`, offerPostId), { status: 3 })
        promisesTask.push(updatePostOffer)

        var updateShopOffer = updateDoc(doc(db, `/shops/${chooseOffer.shop_id}/offers`, offerShopId), { status: 3 })
        promisesTask.push(updateShopOffer)

        Promise.all(promisesTask).then(() => {
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleCloseCancleOffer = () => {
        setChooseOfferId('')
        setChoosePostId('')
        setChoosePostOnboard('')
        setShopOfferId('')
        setCancleOfferTitle('')
        setCancleOfferMessage('')
        setCancleOfferButton('')
        setOpenCancleOffer(false)
    }

    const handleOpenCancleOffer = (offerId, postId, onBoard, shopOfferId, offer) => {
        setChooseOfferId(offerId)
        setChoosePostId(postId)
        setChoosePostOnboard(onBoard)
        setShopOfferId(shopOfferId)

        if(onBoard == 'cleans') {
            setCancleOfferButton('ยกเลิกข้อเสนอบริการ')
            if(offer.post.onboard == 1) { 
                setCancleOfferTitle('ยกเลิกข้อเสนอบริการล้างแอร์')
                setCancleOfferMessage('คุณยืนยันยกเลิกข้อเสนอบริการล้างแอร์?')
            } else if(offer.post.onboard == 2) {
                setCancleOfferTitle('ยกเลิกข้อเสนอบริการล้างเครื่องซักผ้า')
                setCancleOfferMessage('คุณยืนยันยกเลิกข้อเสนอบริการล้างเครื่องซักผ้า?')
            }
        }
        setOpenCancleOffer(true)
    }

    const handleCancleOffer = () => {
        setShowLoading(true)
        const cancle_date = new Date()
        var promisesTask = []
        var addCancleHistoryTask = addDoc(collection(db, `${choosePostOnboard}/${choosePostId}/cancle_offers`), {
            shop_id: shopId,
            cancle_reason: cancleReason,
            cancle_message: cancleMessage,
            cancle_datetime: cancle_date.getTime()
        })
        promisesTask.push(addCancleHistoryTask)

        var deletePostOfferTask = deleteDoc(doc(db, `${choosePostOnboard}/${choosePostId}/offers`, chooseOfferId)).then(() => {
            getDocs(query(collection(db, `${choosePostOnboard}/${choosePostId}/offers`), where("status", "!=", 5))).then((docs) => {
                var status = 1
                if (docs.docs.length > 0) { status = 2 }
                updateDoc(doc(db, `${choosePostOnboard}/`, choosePostId), {
                    status: status
                })
            })
        })
        promisesTask.push(deletePostOfferTask)

        var deleteShopOfferTask = deleteDoc(doc(db, `shops/${shopId}/offers`, shopOfferId))
        promisesTask.push(deleteShopOfferTask)

        Promise.all(promisesTask).then(() => {
            setOpenCancleOffer(false)
            setShowLoading(false)
        })
    }

    const handleCloseShopCancle = () => {
        setShopCancleButton('')
        setShopCancleMessage('')
        setShopCancleTitle('')
        setOpenShopCancle(false)
    }

    const handleOpenShopCancle = (offerId, postId, onBoard, shopOfferId, offer) => {
        setChooseOfferId(offerId)
        setChoosePostId(postId)
        setChoosePostOnboard(onBoard)
        setShopOfferId(shopOfferId)

        if(onBoard == 'cleans') {
            setShopCancleButton('ยกเลิกข้อเสนอบริการ')
            if(offer.post.onboard == 1) { 
                setShopCancleTitle('ยกเลิกข้อเสนอบริการล้างแอร์')
                setShopCancleMessage('คุณยืนยันยกเลิกข้อเสนอบริการล้างแอร์?')
            } else if(offer.post.onboard == 2) {
                setShopCancleTitle('ยกเลิกข้อเสนอบริการล้างเครื่องซักผ้า')
                setShopCancleMessage('คุณยืนยันยกเลิกข้อเสนอบริการล้างเครื่องซักผ้า?')
            }
        }
        
        setOpenShopCancle(true)
    }

    const handleShopCancle = () => {
        setShowLoading(true)
        const date = new Date()
        updateDoc(doc(db, `${choosePostOnboard}/${choosePostId}/offers`, chooseOfferId), {
            status: 5,
            cancle_date: date.getTime(),
            cancle_reason: cancleReason,
            cancle_message: cancleMessage
        }).then(() => {
            getDocs(query(collection(db, `${choosePostOnboard}/${choosePostId}/offers`), where("status", "!=", 5))).then((docs) => {
                var promisesTask = []
                var postStatus = 1
                if (docs.docs.length > 0) { 
                    postStatus = 2 
                    var updateExistOfferTask = updateDoc(doc(db, `${choosePostOnboard}/${choosePostId}/offers`, where("status", "!=", 5)), {
                        status: 1
                    })
                    promisesTask.push(updateExistOfferTask)
                }
                var updatePostTask = updateDoc(doc(db, `${choosePostOnboard}/`, choosePostId), {
                    status: postStatus
                })
                promisesTask.push(updatePostTask)

                var updateAddNotiTask = getDoc(doc(db, `${choosePostOnboard}/`, choosePostId)).then((postDoc) => {
                    const postData = postDoc.data();
                    var link = "/profile/posts"
                    addDoc(collection(db, `/users/${postData.poster_id}/notifications`), {
                        date: date.getTime(),
                        message: "ผู้เสนอยกเลิกข้อเสนอ กรุณาเลือกข้อเสนอรายใหม่",
                        link: link,
                        type: 'offer',
                        offer_id: chooseOfferId,
                        status: 0
                    })
                })
                promisesTask.push(updateAddNotiTask)

                var updateShopTask = updateDoc(doc(db, `shops/${shopId}/offers`, shopOfferId), {
                    status: 5
                })
                promisesTask.push(updateShopTask)

                Promise.all(promisesTask).then(() => {
                    setShowLoading(false)
                    navigate(0)
                })
            })
        })
    }

    const handleSearchByBoard = (value) => {
        const status = value * 1
        const others = Array.from(
            document.getElementsByClassName('board-other')
        )
        const allBoard = Array.from(
            document.getElementsByClassName('board-all')
        )

        if(status == 0) {
            searchBoard.splice(0, searchBoard.length)
            setSearchBoard([...searchBoard])
            allBoard.forEach(element => {
                element.checked = true
            })
            others.forEach(element => {
                element.checked = false
                element.removeAttribute('checked')
            })
        } else {
            if(searchBoard.indexOf(status) < 0) {
                searchBoard.push(status)
            } else {
                searchBoard.splice(searchBoard.indexOf(status), 1)
            }
            setSearchBoard([...searchBoard])
            if(searchBoard.length > 0) {
                allBoard.forEach(element => {
                    element.checked = false
                    element.removeAttribute('checked')
                })
            } else {
                allBoard.forEach(element => {
                    element.checked = true
                })
            }
        }

        handleSearch()
    }

    const handleSearch = () => {
        if(shopId) {
            setShowLoading(true)
            const constraints = []
            const order = []
            order.push(orderBy('pick_date', 'asc'))
            order.push(orderBy('hour', 'asc'))
            order.push(orderBy('minute', 'asc'))

            if(searchBoard.length > 0) {
                searchBoard.forEach((board) => {
                    if(board == 1) { // ทิ้งซาก
                        constraints.push(and(where('post_type', '==', 'post'), where('post_onboard', '==', 1)))
                    } else if(board == 2) { // ขายซาก
                        constraints.push(and(where('post_type', '==', 'post'), where('post_onboard', '==', 2)))
                    } else if(board == 3) { // ขายมือสอง
                        constraints.push(and(where('post_type', '==', 'post'), where('post_onboard', '==', 3)))
                    } else if(board == 4) { // แจ้งซ่อม
                        constraints.push(and(where('post_type', '==', 'fix')))
                    } else if(board == 5) { // ร้านลงขาย
                    } else if(board == 6) { // แจ้งล้างแอร์
                        constraints.push(and(where('post_type', '==', 'air')))
                    } else if(board == 7) { // แจ้งล้างเครื่องซักผ้า
                        constraints.push(and(where('post_type', '==', 'wash')))
                    }
                })
            }

            if(constraints.length >= 2) {
                var offerQuery = query(collection(db, `shops/${shopId}/offers/`), or(...constraints), ...order)
            } else {
                var offerQuery = query(collection(db, `shops/${shopId}/offers/`), ...constraints, ...order)
            }

            onSnapshot(offerQuery, (querySnapshot) => {
                offers.splice(0, offers.length)
                setOffers([...offers])
                if(querySnapshot.docs.length == 0) { setShowLoading(false) }
                else {
                    querySnapshot.forEach(async(offer) => {
                        var collection = 'posts'
                        var collection_brand = 'brands'
                        var collection_model = 'models'
                        if(offer.data().board) {
                            collection = offer.data().board
                        }
                        const postSnapshot = await getDoc(doc(db, collection, offer.data().post_id))
                        if (postSnapshot.exists()) {
                            if(offer.data().board == 'cleans') {
                                if(postSnapshot.data().onboard == 1) {
                                    collection_brand = 'air_brands'
                                    collection_model = 'air_models'
                                } else {
                                    collection_brand = 'wash_brands'
                                    collection_model = 'wash_models'
                                }
                            }
                            var brand = 'ไม่ระบุ'
                            var model = 'ไม่ระบุ'
                            var promisesTask = []
                            if(postSnapshot.data().brand_id) {
                                if(postSnapshot.data().brand_id == '999') {
                                    if(postSnapshot.data().brand) {
                                        brand = postSnapshot.data().brand
                                    }
                                } else {
                                    var brand_id = postSnapshot.data().brand_id
                                    var getBrandTask = await getDoc(doc(db, collection_brand, brand_id)).then((docBrand) => {
                                        brand = docBrand.data().name
                                    })
                                    promisesTask.push(getBrandTask)
                                }
                            } else {
                                if(postSnapshot.data().brand) {
                                    brand = postSnapshot.data().brand
                                }
                            }
                            if(postSnapshot.data().model_id) {
                                if(postSnapshot.data().model_id == '999') {
                                    if(postSnapshot.data().model) {
                                        model = postSnapshot.data().model
                                    }
                                } else {
                                    var model_id = postSnapshot.data().model_id
                                    var getModelTask = await getDoc(doc(db, collection_model, model_id)).then((docModel) => {
                                        model = docModel.data().name
                                    })
                                    promisesTask.push(getModelTask)
                                }
                            } else {
                                if(postSnapshot.data().model) {
                                    model = postSnapshot.data().model
                                }
                            }
                            Promise.all(promisesTask)

                            var badgeStyle = { message: '', class: 'success', color: '#000000', show: false }
                            if(offer.data().status==1) {
                                badgeStyle = { message: 'รอเลือกข้อเสนอ', class: 'warning', color: '#000000', show: true }
                            } else if(offer.data().status==2) {
                                badgeStyle = { message: 'ข้อเสนอถูกเลือก', class: 'success', color: '#ffffff', show: true }
                            } else if(offer.data().status==3) {
                                if(offer.data().board == 'cleans') {
                                    badgeStyle = { message: 'ยืนยันให้บริการ', class: 'success', color: '#ffffff', show: true }
                                } else {
                                    if(postSnapshot.data().onboard == 1) {
                                        badgeStyle = { message: 'ยืนยันเข้ารับซาก', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 2) {
                                        badgeStyle = { message: 'ยืนยันเข้าซื้อซาก', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 3) {
                                        badgeStyle = { message: 'ยืนยันเข้าซื้อสินค้า', class: 'success', color: '#ffffff', show: true }
                                    } else if(postSnapshot.data().onboard == 4) {
                                        badgeStyle = { message: 'ยืนยันให้บริการซ่อม', class: 'success', color: '#ffffff', show: true }
                                    }
                                }
                            } else if(offer.data().status==4) {
                                badgeStyle = { message: 'ข้อเสนอไม่ถูกเลือก', class: 'danger', color: '#ffffff', show: true }
                            } else if(offer.data().status==5) {
                                badgeStyle = { message: 'ยกเลิกข้อเสนอ', class: 'danger', color: '#ffffff', show: true }
                            } else if(offer.data().status==6) {
                            } else if(offer.data().status==7) {
                                badgeStyle = { message: 'ข้อเสนอถูกยกเลิก', class: 'danger', color: '#ffffff', show: true }
                            } else if(offer.data().status==8) {
                            } else if(offer.data().status==9) {
                            }

                            offers.push({
                                id: offer.id,
                                post_id: postSnapshot.id,
                                data: offer.data(),
                                post: postSnapshot.data(),
                                shop_id: shopId,
                                brand: brand,
                                model: model,
                                badgeStyle: badgeStyle
                            })
                            setOffers([...offers], {
                                id: offer.id,
                                post_id: postSnapshot.id,
                                data: offer.data(),
                                post: postSnapshot.data(),
                                shop_id: shopId,
                                brand: brand,
                                model: model,
                                badgeStyle: badgeStyle
                            })
                        }
                    })
                    setShowLoading(false)
                }
            })
        }
    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else {
            getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((docs) => {
                if (docs.docs.length === 1) {
                    setShopId(docs.docs[0].data().shop_id)

                    if(docs.docs[0].data().shop_id) {
                        getDoc(doc(db, `shops/`, docs.docs[0].data().shop_id)).then((shopDoc) => {
                            const dataShop = shopDoc.data()
                            if(dataShop.freecoin) setOfferFreeCoin(dataShop.freecoin)
                            if(dataShop.exchange_coin) setOfferExchangeCoin(dataShop.exchange_coin)
                        })
                    }
                }
            })
        }
    }, [user, loading])
    
    const toggleDrawer = (newOpen) => () => {
        setOpenDrawer(newOpen)
    }

    useEffect(() => {
        handleSearch()
    }, [shopId])

    var offerKey = []
    var offerList = []
    offers.forEach(async(element) => {
        if(offerKey.lastIndexOf(element.id) < 0) {
            offerKey.push(element.id)
            offerList.push(element)
        }
    })

    return (
    <>
        <Loading open={showLoading} text={"กำลังเรียกข้อมูล กรุณารอสักครู่"} />
        <Drawer open={openDrawer} onClose={toggleDrawer(false)}>
            <Box sx={{ width: 290 }} role="presentation">
                <div style={{ display: 'flex', flexDirection: 'column', width: 260, marginLeft: 10 }}>
                    <div style={{ display: 'flex', flexDirection: 'column', width: 260, paddingTop: 15, paddingLeft: 10 }}>
                        <div style={{ marginBottom: 10 }}>
                            <a className="navbar-brand" href="/"><img src={logo} className="" style={{ height: 40 }} alt="WEEE" /></a>
                        </div>
                        <LeftMenu currentPage="offers" isShop={true} />
                    </div>
                </div>
            </Box>
        </Drawer>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 10}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div className='d-block d-md-none d-lg-none' style={{ display: 'flex', fleDirection: 'row', marginBottom: 5, paddingBottom: 5 }}>
                    <div style={{ display: 'flex', flexDisplay: 'column', alignmentBaseline: 'baseline' }}>
                        <button type="button" className="btn btn-primary" onClick={toggleDrawer(true)}><IoMdMenu style={{ fontSize: 25, marginRight: 5 }} />เมนูผู้ใช้</button>
                    </div>
                </div>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>ข้อเสนอของฉัน</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #cccccc', marginRight: 20 }} className="d-none d-md-block d-lg-block">  
                        <LeftMenu currentPage="profile" isShop={true} />
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 5, paddingBottom: 10, borderBottom: '1px solid #cccccc' }}>
                            <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'wrap' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flexWrap: 'nowrap' }}><h6><strong>ประเภทข้อเสนอ</strong></h6></div>
                            </div>
                            <div style={{ paddingLeft: 10, display: 'flex', flexDirection: 'row', flexWrap: 'wrap' }}>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-all" type="checkbox" value="0" defaultChecked={true} onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ทั้งหมด</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="1" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ทิ้งซาก</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="2" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ขายซาก</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="3" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> ขายมือสอง</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="4" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> แจ้งซ่อม</div>
                                <div style={{ whiteSpace: 'nowrap', marginRight: 10 }}><input className="form-check-input board-other" type="checkbox" value="6" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> แจ้งล้างแอร์</div>
                                <div style={{ whiteSpace: 'nowrap'}}><input className="form-check-input board-other" type="checkbox" value="7" onClick={ (e) => { handleSearchByBoard(e.target.value) }} /> แจ้งล้างเครื่องซักผ้า</div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 5}}>
                        
                        {offerList?.map((offer, i) => (
                            <div key={offer.id}>
                                {(offer.data.post_type == 'post' && offer.post.onboard == 1) && (
                                    <OfferShopBoard1 offer={offer} />
                                )}
                                {(offer.data.post_type == 'post' && offer.post.onboard == 2) && (
                                    <OfferShopBoard2 offer={offer} />
                                )}
                                {(offer.data.post_type == 'post' && offer.post.onboard == 3) && (
                                    <OfferShopBoard3 offer={offer} offerExchangeCoin={offerExchangeCoin} />
                                )}
                                {(offer.data.post_type == 'fix' && offer.post.onboard == 4) && (
                                    <OfferShopBoard4 offer={offer} />
                                )}
                                {(offer.data.post_type == 'air') && (
                                    <OfferShopAir offer={offer} />
                                )}
                                {(offer.data.post_type == 'wash') && (
                                    <OfferShopWash offer={offer} />
                                )}

                                {
                                 !(!offer.data.board) && (
                            <div className="card" style={{ width: "21rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }}  key={offer.post_id}>
                                {offer.badgeStyle.show && 
                                <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                                    <button type="button" className={"btn "+"btn-"+offer.badgeStyle.class} style={{ color: offer.badgeStyle.color }}><strong>{offer.badgeStyle.message}</strong></button>
                                </div>}
                                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                    <img src={offer.post.picture} alt="WEEE" height="150" />
                                </div>
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                    <p className="card-text">
                                        {/* ล้างแอร์, ล้างเครื่องซักผ้า */}
                                        {(offer.data.board == 'cleans') && 
                                            (<>
                                            {offer.post.onboard == 2 && (<><strong>แจ้งความต้องการล้างเครื่องซักผ้า</strong><br/></>)}
                                            <>{offer.brand} {offer.model}<br/></>
                                            <><strong>ที่อยู่:</strong> {offer.post.address} <br/></>
                                            </>)
                                        }
                                        <strong>วันที่ยื่นข้อเสนอ:</strong> {dateFormat(offer.data.offer_timestamp)} <br/>
                                        <strong>ข้อเสนอ:</strong> {dateFormatDate(offer.data.pick_date)} เวลา {offer.data.hour}:{offer.data.minute} น.
                                    </p>
                                </div>
                                <div className="card-body">
                                    {/* ล้างแอร์, ล้างเครื่องซักผ้า */}
                                    {(offer.data.board == 'cleans') && 
                                        (<>
                                        {/* ล้างเครื่องซักผ้า */}
                                        {offer.post.onboard == 2 && (<>
                                            <Link className="btn btn-primary m-1" to={`/postwash/${offer.post_id}`} target='_blank'><FiInfo /> <strong>ดูประกาศ</strong></Link>
                                            <Link className="btn btn-primary m-1" to={`/shop/wash/offer/${offer.post_id}`} target='_blank'><FiInfo /> <strong>ดูข้อเสนอ</strong></Link>
                                            {(offer.data.status==2) && (<>
                                                <button type="button" className="btn btn-success m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenShopConfirm(offer.data.offer_id, offer.post_id, 'cleans', offer.id, offer) }}>ยืนยันให้บริการ</button>
                                                {/* <button type="button" className="btn btn-danger m-1" style={{ color: '#ffffff' }} onClick={() => {}}>ยกเลิกให้บริการ</button> */}
                                            </>)}
                                            {(offer.data.status==1) && (<>
                                                <button type="button" className="btn btn-danger m-1" style={{ color: '#ffffff' }} onClick={() => { handleOpenCancleOffer(offer.data.offer_id, offer.post_id, 'cleans', offer.id, offer) }}>ยกเลิกข้อเสนอ</button>
                                            </>)}
                                            {(offer.data.status==3) && (<>
                                                <Link className="btn btn-primary m-1" to={`/shop/appointments`} target='_blank'><FiCalendar /> <strong>ดูนัดหมาย</strong></Link>
                                            </>)}
                                        </>)}
                                        </>)
                                    }
                                </div>
                            </div>
                                )}
                            </div>
                        ))}
                        </div>
                    </div>
                </div>
            </div>

            <Dialog open={openCancleOffer} onClose={handleCloseCancleOffer} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>{cancleOfferTitle}</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5, flex: 1 }}>
                                                <option value="1">หลังจากเสนอฯ ผู้ประกาศตอบกลับช้า</option>
                                                <option value="2">รับงานใหม่</option>
                                                <option value="3">อื่นๆ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                onChange={(e) => setCancleMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={cancleMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseCancleOffer} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleCancleOffer} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>{cancleOfferButton}</button>
                </DialogActions>
            </Dialog>

            <Dialog open={openShopConfirm} onClose={handleCloseShopConfirm} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>{confirmTitle}</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <h5>{confirmMessage}</h5>
                                    {depositEnough &&
                                    <h6><strong>{depositMessage}</strong></h6>
                                    }
                                    {!depositEnough &&
                                    <h6 className='text-danger'><strong>{depositMessage}</strong></h6>
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShopConfirm} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    {depositEnough &&
                    <button onClick={handleShopConfirm} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>{confirmButton}</button>
                    }
                </DialogActions>
            </Dialog>

            <Dialog open={openShopCancle} onClose={handleCloseShopCancle} maxWidth='sm' fullWidth={true}>
                <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>{shopCancleTitle}</strong></h4></div></DialogTitle>
                <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                    <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                        <div style={{ display:'flex', flexDirection: 'row', alignItems:'center' }}>
                            <div style={{ display:'flex', flexDirection: 'column', flex: 1 }}>
                                <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                            <select name='reason' onChange={(e) => setCancleReason(e.target.value)} style={{ paddingLeft: 5, paddingRight: 5, flex: 1 }}>
                                                <option value="1">ไม่สะดวกไปตามเวลานัดหมาย</option>
                                                <option value="2">รับงานใหม่</option>
                                                <option value="3">อื่นๆ</option>
                                            </select>
                                        </div>
                                    </div>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                        <label className="" style={{ display: 'flex' }}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                        <div style={{ display: 'flex', flex: 1 }}>
                                            <textarea style={{ display: 'flex', flex: 1, height: 100 }}
                                                onChange={(e) => setCancleMessage(e.target.value)}
                                                placeholder='เหตุผลเพิ่มเติม' 
                                                value={cancleMessage}></textarea>
                                        </div>
                                    </div> 
                                </div>
                            </div>
                        </div>
                    </div>
                </DialogContent>
                <DialogActions>
                    <button onClick={handleCloseShopCancle} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                    <button onClick={handleShopCancle} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>{shopCancleButton}</button>
                </DialogActions>
            </Dialog>
        </div>
    </>
    )
}

export default Offers;