import React, { useState, useEffect, useRef } from 'react'
import { useAuthState } from "react-firebase-hooks/auth"
import { GoogleMap, MarkerF, useLoadScript } from "@react-google-maps/api"
import { db, storage, auth } from "../../assets/js/firebase"
import { collection, query, doc, getDoc, onSnapshot, updateDoc, addDoc, deleteDoc } from 'firebase/firestore'
import { ref, uploadBytesResumable, getDownloadURL } from "firebase/storage";
import { useNavigate, useParams } from "react-router-dom"
import useScript from '../../assets/js/useScript'
import { RxHome } from "react-icons/rx"
import { FaMapMarkerAlt } from "react-icons/fa"
import { IoCloseCircle, IoCheckmarkCircleSharp, IoChevronBackCircleSharp, IoFolderOpenOutline } from "react-icons/io5"
import { AiFillShop, AiFillInfoCircle } from "react-icons/ai"
import { RiBankCardLine } from 'react-icons/ri'
import { TbPhoto } from 'react-icons/tb'
import addImage from "../../assets/images/add-image-icon.png"
import Alert from '@mui/material/Alert'
import CloseIcon from '@mui/icons-material/Close'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'

const EditShop = () => {
    useScript('https://code.jquery.com/jquery-3.2.1.min.js')
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dependencies/JQL.min.js')
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dependencies/typeahead.bundle.js')
    useScript('https://earthchie.github.io/jquery.Thailand.js/jquery.Thailand.js/dist/jquery.Thailand.min.js')

    const inputSubDistrictRef = useRef(null)
    const inputDistrictRef = useRef(null)
    const inputProvinceRef = useRef(null)
    const inputPostcodeRef = useRef(null)
    const inputRef = useRef(null);
    const inputRef2 = useRef(null);
    const inputRef3 = useRef(null);
    const inputRef4 = useRef(null);
    const inputRef5 = useRef(null);
    const inputRef6 = useRef(null);
    const inputRef7 = useRef(null);

    const params = useParams()
    const shopId = params.id
    const [shop, setShop] = useState()
    const [images, setImages] = useState([])
    const [memberId, setMemberId] = useState('')
    const [member, setMember] = useState()
    const [type, setType] = useState(1)
    const [shopname, setShopName] = useState('')
    const [firstname, setFirstname] = useState('')
    const [lastname, setLastname] = useState('')
    const [telNo, setTelNo] = useState('')
    const [address, setAddress] = useState('')
    const [road, setRoad] = useState('')
    const [subDistrict, setSubDistrict] = useState('')
    const [district, setDistrict] = useState('')
    const [province, setProvince] = useState('')
    const [postcode, setPostcode] = useState('')

    const [pictureErrorDiv, setPictureErrorDiv] = useState(false)
    const [shopnameErrorDiv, setShopnameErrorDiv] = useState(false)
    const [firstnameErrorDiv, setFirstnameErrorDiv] = useState(false)
    const [lastnameErrorDiv, setLastnameErrorDiv] = useState(false)
    const [telNoErrorDiv, setTelNoErrorDiv] = useState(false)

    const [bankName, setBankName] = useState('')
    const [bankAccNo, setBankAccNo] = useState('')

    const [bookBankUrl, setBookBankUrl] = useState('')
    const [bookBank, setBookBank] = useState('')

    const [idCard, setIdCard] = useState('');
    const [idCardUrl, setIdCardUrl] = useState('');

    const [supportFile1, setSupportFile1] = useState('');
    const [supportFile1Url, setSupportFile1Url] = useState('');
    const [supportFile2, setSupportFile2] = useState('');
    const [supportFile2Url, setSupportFile2Url] = useState('');
    const [supportFile3, setSupportFile3] = useState('');
    const [supportFile3Url, setSupportFile3Url] = useState('');
    const [supportFile4, setSupportFile4] = useState('');
    const [supportFile4Url, setSupportFile4Url] = useState('');
    const [supportFile5, setSupportFile5] = useState('');
    const [supportFile5Url, setSupportFile5Url] = useState('');

    const [openSuccess, setOpenSuccess] = useState(false);

    const navigate = useNavigate()

    const [lat, setLat] = useState(15.11745249400088)
    const [lng, setLng] = useState(104.90284046686465)

    const [loading, error] = useAuthState(auth)
    const { isLoaded } = useLoadScript({
        googleMapsApiKey: 'AIzaSyAMD9ipSB_UIMpmMEa9T_3OOdM25P5ADVo',
    })
    const center = { lat: lat, lng: lng }
    const onMarkerDragEnd = (lat, lng) => {
        setLat(lat)
        setLng(lng)
    }

    const deleteImage = (i) => {
        let items = [...images]
        let item = {...items[i]}
        item.url = addImage
        item.file = ""
        item.status = 0
        items[i] = item
        setImages(items)
    }

    const handleImage = (file, i) => {
        let items = [...images]
        let item = {...items[i]}
        item.url = URL.createObjectURL(file)
        item.file = file
        item.status = 2
        items[i] = item
        setImages(items)
    }

    const setAllAddress = () => {
        const subDistrictRef = inputSubDistrictRef.current.value;
        const districtRef = inputDistrictRef.current.value;
        const provinceRef = inputProvinceRef.current.value;
        const postcodeRef = inputPostcodeRef.current.value;
        setSubDistrict(subDistrictRef)
        setDistrict(districtRef)
        setProvince(provinceRef)
        setPostcode(postcodeRef)
    }

    const getDocShop = async(id) => {
        await getDoc(doc(db, 'shops', id)).then((shopSnapshot) => {
            const data = shopSnapshot.data()
            setShop(data);
            if(data.lat) setLat(data.lat)
            if(data.lng) setLng(data.lng)
            if(data.type) setType(data.type)
            if(data.shopname) setShopName(data.shopname)
            if(data.firstname) setFirstname(data.firstname)
            if(data.lastname) setLastname(data.lastname)
            if(data.tel_no) setTelNo(data.tel_no)
            if(data.address) setAddress(data.address)
            if(data.road) setRoad(data.road)
            if(data.sub_district) setSubDistrict(data.sub_district)
            if(data.district) setDistrict(data.district)
            if(data.province) setProvince(data.province)
            if(data.postcode) setPostcode(data.postcode)

            if(data.bank_name) setBankName(data.bank_name)
            if(data.book_bank) setBookBank(data.book_bank)
            if(data.bank_account_no) setBankAccNo(data.bank_account_no)
            if(data.book_bank) setBookBankUrl(data.book_bank)

            if(data.id_card) setIdCardUrl(data.id_card)
            if(data.support_file_1) setSupportFile1Url(data.support_file_1)
            if(data.support_file_2) setSupportFile2Url(data.support_file_2)
            if(data.support_file_3) setSupportFile3Url(data.support_file_3)
            if(data.support_file_4) setSupportFile4Url(data.support_file_4)
            if(data.support_file_5) setSupportFile5Url(data.support_file_5)

            if(data.user_id) {
                setMemberId(data.user_id)
                getDoc(doc(db, 'users', data.user_id)).then((memberDoc) => {
                    setMember(memberDoc.data())
                })
            }
        }).then(() => {
            const imagesSnapshot =  query(collection(db, `shops/${shopId}/images`))
            onSnapshot(imagesSnapshot, (querySnapshot) => {
                setImages(querySnapshot.docs.map((doc, i) => ({
                    id: doc.id,
                    url: doc.data().url,
                    file: "",
                    status: 1
                })))
            })
        })
    }

    const validateForm = () => {
        if(type == 2) {
            if(!shopname) {
                setShopnameErrorDiv(true)
                return false;
            } else {
                setShopnameErrorDiv(false)
            }
        }
        if(!firstname) {
            setFirstnameErrorDiv(true)
            return false;
        } else {
            setFirstnameErrorDiv(false)
        }
        if(!lastname) {
            setLastnameErrorDiv(true)
            return false;
        } else {
            setLastnameErrorDiv(false)
        }
        if(!telNo) {
            setTelNoErrorDiv(true)
            return false;
        } else {
            setTelNoErrorDiv(false)
        }

        var numPic = 0
        images.forEach((image) => {
            if(image.status != 0) {
                numPic++;
            }
        })
        if(numPic > 0) {
            setPictureErrorDiv(false)
        } else {
            setPictureErrorDiv(true)
            return false;
        }

        return true
    }

    const handleSubmit = async (e) => {
    	e.preventDefault()
        if(validateForm())
        try {
            updateDoc(doc(db, 'shops', shopId), {
                type: type,
                firstname: firstname,
                lastname: lastname,
                tel_no: telNo,
                address: address,
                road: road,
                sub_district: subDistrict,
                district: district,
                province: province,
                postcode: postcode,
                lat: lat,
                lng: lng,
                bank_name: bankName,
                bank_account_no: bankAccNo,
            }).then(() => {
                if(type == 2) {
                    updateDoc(doc(db, 'shops', shopId), {
                        shopname: shopname
                    })

                    if(supportFile5) {
                        const storageRef = ref(storage, `/shops/${shopId}/image/support_file_5/${supportFile5.name}`)
                        const uploadTask = uploadBytesResumable(storageRef, supportFile5);
                        uploadTask.on(
                            "state_changed",
                            (snapshot) => { },
                            (err) => console.log(err),
                            () => { 
                                getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                    updateDoc(doc(db, `shops`, shopId), {
                                        support_file_5: url,
                                    })
                                })
                            }
                        )
                    }
                }
                
                if(bookBank) {
                    const storageRef = ref(storage, `/shops/${shopId}/image/bookbank/${bookBank.name}`)
                    const uploadTask = uploadBytesResumable(storageRef, bookBank);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => { },
                        (err) => console.log(err),
                        () => { 
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                updateDoc(doc(db, `shops`, shopId), {
                                    book_bank: url,
                                })
                            })
                        }
                    )
                }

                if(idCard) {
                    const storageRef = ref(storage, `/shops/${shopId}/image/idcard/${idCard.name}`)
                    const uploadTask = uploadBytesResumable(storageRef, idCard);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => { },
                        (err) => console.log(err),
                        () => { 
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                updateDoc(doc(db, `shops`, shopId), {
                                    id_card: url,
                                })
                            })
                        }
                    )
                }

                if(supportFile1) {
                    const storageRef = ref(storage, `/shops/${shopId}/image/support_file_1/${supportFile1.name}`)
                    const uploadTask = uploadBytesResumable(storageRef, supportFile1);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => { },
                        (err) => console.log(err),
                        () => { 
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                updateDoc(doc(db, `shops`, shopId), {
                                    support_file_1: url,
                                })
                            })
                        }
                    )
                }

                if(supportFile2) {
                    const storageRef = ref(storage, `/shops/${shopId}/image/support_file_2/${supportFile2.name}`)
                    const uploadTask = uploadBytesResumable(storageRef, supportFile2);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => { },
                        (err) => console.log(err),
                        () => { 
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                updateDoc(doc(db, `shops`, shopId), {
                                    support_file_2: url,
                                })
                            })
                        }
                    )
                }

                if(supportFile3) {
                    const storageRef = ref(storage, `/shops/${shopId}/image/support_file_3/${supportFile3.name}`)
                    const uploadTask = uploadBytesResumable(storageRef, supportFile3);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => { },
                        (err) => console.log(err),
                        () => { 
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                updateDoc(doc(db, `shops`, shopId), {
                                    support_file_3: url,
                                })
                            })
                        }
                    )
                }

                if(supportFile4) {
                    const storageRef = ref(storage, `/shops/${shopId}/image/support_file_4/${supportFile4.name}`)
                    const uploadTask = uploadBytesResumable(storageRef, supportFile4);
                    uploadTask.on(
                        "state_changed",
                        (snapshot) => { },
                        (err) => console.log(err),
                        () => { 
                            getDownloadURL(uploadTask.snapshot.ref).then((url) => {
                                updateDoc(doc(db, `shops`, shopId), {
                                    support_file_4: url,
                                })
                            })
                        }
                    )
                }

                images.forEach((image, i) => {
                    if(image.status == 0) {
                        if(image.id != "") {
                            deleteDoc(doc(db, `shops/${shopId}/images`, image.id));
                        }
                    } else if(image.status == 2) {
                        uploadFiles(image.file, image.id, i)
                    }
                })
            }).then(() => {
                setOpenSuccess(true)
            }).then(() => {
                navigate(0)
            })
        } catch {

        }
    }

    const uploadFiles = (file, imageId, i) => {
        const promises = []
        const storageRef = ref(storage, `/shops/${shopId}/${i}`)
        const uploadTask = uploadBytesResumable(storageRef, file)
        promises.push(uploadTask)
        uploadTask.on(
            "state_changed",
            (snapshot) => {},
            (error) => console.log(error),
            async() => {
                await getDownloadURL(uploadTask.snapshot.ref).then((downloadURLs) => {
                    if(imageId == "") {
                        console.log(downloadURLs)
                        addDoc(collection(db, `/shops/${shopId}/images`), {
                            url: downloadURLs,
                        })
                    } else {
                        updateDoc(doc(db, `/shops/${shopId}/images`, imageId), {
                            url: downloadURLs
                        })
                    }
                })
            }
        )

        Promise.all(promises)
    }

    useEffect(() => {
        getDocShop(shopId)
    }, [loading])

    useScript('https://firebasestorage.googleapis.com/v0/b/weee-81f51.appspot.com/o/js%2FthailandAddress.js?alt=media&token=b1c469d5-5f9e-4cb1-98b3-05056ea57a86');
    
    var len = images.length
    for(var i = len+1; i<=5; i++) {
        setImages([...images, {
            id: "",
            url: addImage,
            file: "",
            status: 0
        }])
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', marginTop: 20 }} className="p-3">
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 10, marginRight: 10, marginBottom: 30 }}>
                <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1 }}>
                    <h4 style={{ display: 'flex', flex: 1}}>
                        <AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                        <b>แก้ไขข้อมูลร้านค้า</b>
                    </h4>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-secondary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/view/${shopId}`) }}>
                            <IoChevronBackCircleSharp style={{ fontSize: 20, marginRight: 5 }} />ย้อนกลับ
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={handleSubmit}>
                            <IoCheckmarkCircleSharp style={{ fontSize: 20, marginRight: 5 }} />บันทึก
                        </button>
                    </div>
                    <div style={{ float: 'right' }}>
                        <button type="button" className="btn btn-danger btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/view/${shopId}`) }}>
                            <IoCloseCircle style={{ fontSize: 20, marginRight: 5 }} />ยกเลิก
                        </button>
                    </div>
                </div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3 }}>
                        <Collapse in={openSuccess}>
                            <Alert action={
                                <IconButton aria-label="close" color="inherit" size="small" onClick={() => { setOpenSuccess(false); }} >
                                    <CloseIcon fontSize="inherit" />
                                </IconButton>
                            } sx={{ mb: 2 }}> บันทึกสำเร็จ
                            </Alert>
                        </Collapse>
                        <div className="row" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ผู้ใช้ </strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        {(member) && (
                                        <>
                                            <font style={{ marginRight: 10}}>{member.firstname?member.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                            <font style={{ marginRight: 10}}>{member.lastname?member.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                            <font style={{ marginRight: 10}}>({member.display_name?member.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font>
                                            <a href={`/admin/member/view/${memberId}`}><AiFillInfoCircle className='text-primary' style={{ fontSize: 25 }} /></a>
                                        </>
                                        )}
                                    </div>
                                </div>
                            </div>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>email </strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        {(member) && (
                                        <><font style={{ marginRight: 10}}>{member.email?member.email:""}</font></>
                                        )}
                                    </div>
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ประเภทร้านค้า <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <div style={{ display: 'flex', flex: 1 }}>
                                        <select name='type' onChange={(e) => setType(e.target.value)} value={type}>
                                            <option value="1">บุคคลธรรมดา</option>
                                            <option value="2">ร้าน/บริษัท/นิติบุคคล</option>
                                        </select>
                                    </div>
                                </div>
                            </div> 
                            {(type==2)&& (
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ชื่อร้าน/บริษัท/นิติบุคคล <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='shopname' onChange={(e) => setShopName(e.target.value)} 
                                        value={shopname} placeholder='ชื่อร้าน/บริษัท/นิติบุคคล'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    {shopnameErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกชื่อร้าน/บริษัท/นิติบุคคล</strong>
                                    </div>
                                    }
                                </div>
                            </div> 
                            )}
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ชื่อ (เจ้าของ) <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='firstname' onChange={(e) => setFirstname(e.target.value)} 
                                        value={firstname} placeholder='ชื่อ'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    {firstnameErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอก{type==1?"ชื่อ":"ชื่อเจ้าของกิจการ"}</strong>
                                    </div>
                                    }
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>นามสกุล (เจ้าของ) <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='lastname' onChange={(e) => setLastname(e.target.value)} 
                                        value={lastname} placeholder='นามสกุล'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    {lastnameErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอก{type==1?"นามสกุล":"นามสกุลเจ้าของกิจการ"}</strong>
                                    </div>
                                    }
                                </div>
                            </div>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>เบอร์โทรศัพท์ <font className="text-danger">*</font></strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='tel_no' onChange={(e) => setTelNo(e.target.value)} 
                                        value={telNo} placeholder='เบอร์โทรศัพท์'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                    {telNoErrorDiv &&
                                    <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                        <strong>** กรุณากรอกเบอร์โทรศัพท์</strong>
                                    </div>
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                            <div style={{ paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc', marginBottom: 10 }}>
                                <h6><RiBankCardLine style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>บัญชีธนาคาร</b></h6>
                            </div>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ธนาคาร</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='bankName' onChange={(e) => setBankName(e.target.value)} 
                                        value={bankName} placeholder='ธนาคาร'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>หมายเลขบัญชี</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='bankAccNo' onChange={(e) => setBankAccNo(e.target.value)} 
                                        value={bankAccNo} placeholder='หมายเลขบัญชี'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1 }} />
                                </div>
                            </div> 
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>สำเนาหน้าสมุดบัญชี</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input ref={inputRef2} className="form-control file" id="bookbank_image" type="file" accept="image/*" onChange={(e) => setBookBank(e.target.files[0])} />
                                    { (bookBankUrl) &&
                                        <a href={bookBankUrl} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                    }
                                </div>
                            </div> 
                        </div>
                        <div className="row" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                            <div style={{ paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                                <h6><TbPhoto style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>{type==1?"รูปถ่ายหน้าร้าน":"รูปถ่ายหน้าร้าน/บริษัท"}</b></h6>
                            </div>
                            <div style={{ display: 'flex', flex: 1, flexDirection: 'row', flexWrap: 'wrap', paddingTop: 10 }}>
                                {images?.map((image, i) => (
                                <div key={i} style={{ display: 'flex', flex: 1, flexDirection: 'column', margin: 5, alignItems:'center' }}>
                                    <img src={image.url} alt="WEEE" width="150" style={{marginBottom: 5}} />
                                    <div style={{ display: "flex", flexDirection:'row'}}>
                                        <label htmlFor={"fileUpload"+i} style={{ padding: 0}}>
                                            {(image.status!=0)?
                                            (<a className='btn btn-primary btn-sm'>เปลี่ยนรูป</a>):
                                            (<a className='btn btn-primary btn-sm'>เลือกรูป</a>)
                                            }
                                        </label>
                                        {(image.status!=0) &&
                                        (<button type="button" className="btn btn-danger btn-sm" style={{ marginLeft: 5, display: 'flex' }} onClick={() => { deleteImage(i) }}>
                                            <IoCloseCircle style={{ fontSize: 20 }} />
                                        </button>)
                                        }
                                    </div>
                                    <input style={{ display:'none' }} className="form-control file" id={"fileUpload"+i} type="file" accept="image/*" onChange={(e) => handleImage(e.target.files[0], i)} />
                                </div>
                                ))}
                            </div>
                            {pictureErrorDiv &&
                            <div className="text-danger border-danger" style={{ marginLeft: 10, marginTop: 5, flex: 1, display: 'flex'}}>
                                <strong>** กรุณาเลือก{type==1?"รูปถ่ายหน้าร้าน":"รูปถ่ายหน้าร้าน/บริษัท"} อย่างน้อย 1 รูป</strong>
                            </div>
                            }
                        </div>
                    </div>
                    <div style={{ display: 'flex', width: 1, borderLeft: '1px solid #cccccc', marginLeft: 30, marginRight: 30 }}></div>
                    <div style={{ display: 'flex', flexDirection: 'column', flex: 3, paddingRight: 10}}>
                        <div className="row" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                            <div style={{ marginBottom: 10, paddingTop: 8, borderBottom: '1px solid #cccccc' }}>
                                <h6><RxHome style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>ข้อมูลที่อยู่</b></h6>
                            </div>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>บ้านเลขที่, หมู่บ้าน, อาคาร</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='address' onChange={(e) => setAddress(e.target.value)} 
                                        value={address} placeholder='บ้านเลขที่, หมู่บ้าน, อาคาร'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                </div>
                            </div>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ถนน</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' name='road' onChange={(e) => setRoad(e.target.value)} 
                                        value={road} placeholder='ถนน'
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%' }} />
                                </div>
                            </div>
                            <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ตำบล</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' id="district" name='subDistrict' autoComplete='no' onChange={(e) => setSubDistrict(e.target.value)} onBlur={(e) => setAllAddress()}
                                        value={subDistrict} placeholder='ตำบล' ref={inputSubDistrictRef}
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                                </div>
                            </div>
                            <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>อำเภอ</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' id="amphoe" name='district' autoComplete='no' onChange={(e) => setDistrict(e.target.value)} onBlur={(e) => setAllAddress()} 
                                        value={district} placeholder='อำเภอ' ref={inputDistrictRef}
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                                </div>
                            </div>
                            <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>จังหวัด</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' id="province" name='province' autoComplete='no' onChange={(e) => setProvince(e.target.value)} onBlur={(e) => setAllAddress()} 
                                        value={province} placeholder='จังหวัด' ref={inputProvinceRef}
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                                </div>
                            </div>
                            <div className="mb-1 row" style={{ display: 'flex', flexDirection: 'row' }}>
                                <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>รหัสไปรษณีย์</strong></label>
                                <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                    <input type='text' id="zipcode" name='postcode' autoComplete='no' onChange={(e) => setPostcode(e.target.value)} onBlur={(e) => setAllAddress()} 
                                        value={postcode} placeholder='รหัสไปรษณีย์' ref={inputPostcodeRef}
                                        style={{ paddingLeft: 8, marginRight: 5, display: 'flex', flex: 1, width: '100%', borderWidth: 1, height: 30 }} />
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ display: 'flex', flexDirection: 'column' }}>
                            <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                                <h6><FaMapMarkerAlt style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <b>ตำแหน่งพิกัดร้านค้า</b></h6>
                            </div>
                            <div style={{ marginBottom: 10, display: 'flex', flexWrap: 'nowrap' }}>
                                <font className="text-danger" style={{ marginRight: 5 }}>**</font> ลาก Marker <FaMapMarkerAlt className='text-danger' style={{ fontSize: 20, marginLeft: 5, marginRight: 5 }} /> เพื่อกำหนดตำแหน่ง
                            </div>
                            <div className="mb-3 row" style={{ display: 'flex', height:250, flexDirection: 'column' }}>
                                <div style={{  display: 'flex', flexWrap: 'nowrap', height:250, flex: 1 }}>
                                    {!isLoaded ? ( <h1>Loading...</h1> ) : (
                                    <GoogleMap mapContainerClassName="map-container" center={center} zoom={17} >
                                        <MarkerF
                                            draggable={true} position={center}
                                            onDragEnd={(e) => onMarkerDragEnd(e.latLng.lat, e.latLng.lng)}
                                        />
                                    </GoogleMap>
                                    )}
                                </div>
                                <div style={{ display: 'flex', flexWrap: 'nowrap', textAlign: 'right' }}>
                                    <div style={{ display: 'flex', float: 'right', flex: 1 }}>lat:{lat}, lng:{lng}</div>
                                </div>
                            </div>
                        </div>
                        <div className="row" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                            <div style={{ marginBottom: 10, paddingTop: 13, borderBottom: '1px solid #cccccc', borderTop: '1px solid #cccccc' }}>
                                <h6><IoFolderOpenOutline style={{ fontSize: 25, marginRight: 5, paddingBottom: 5 }} />
                                <strong>เอกสารประกอบการสมัคร</strong></h6>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', paddingLeft: 10}}>
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>สำเนาบัตรประชาชน</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input ref={inputRef} className="form-control file" id="idcard_image" type="file" accept="image/*" onChange={(e) => setIdCard(e.target.files[0])} />
                                        { (idCardUrl) &&
                                            <a href={idCardUrl} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ใบประกอบวิชาชีพ</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input ref={inputRef3} className="form-control file" id="support_file_1" type="file" accept="image/*" onChange={(e) => setSupportFile1(e.target.files[0])} />
                                        { (supportFile1Url) &&
                                            <a href={supportFile1Url} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>วุฒิการศึกษา</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input ref={inputRef4} className="form-control file" id="support_file_2" type="file" accept="image/*" onChange={(e) => setSupportFile2(e.target.files[0])} />
                                        { (supportFile2Url) &&
                                            <a href={supportFile2Url} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>ประกาศนียบัตร</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input ref={inputRef5} className="form-control file" id="support_file_3" type="file" accept="image/*" onChange={(e) => setSupportFile3(e.target.files[0])} />
                                        { (supportFile3Url) &&
                                            <a href={supportFile3Url} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                    </div>
                                </div> 
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>หนังสือรับรอง</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input ref={inputRef6} className="form-control file" id="support_file_4" type="file" accept="image/*" onChange={(e) => setSupportFile4(e.target.files[0])} />
                                        { (supportFile4Url) &&
                                            <a href={supportFile4Url} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                    </div>
                                </div> 
                                {(type == 2) && (
                                <div className="row" style={{ display: 'flex', flexDirection: 'row' }}>
                                    <label className="col-form-label" style={{ display: 'flex', width: 200}}><strong>สำเนาใบสำคัญการจดทะเบียน</strong></label>
                                    <div style={{ display: 'flex', flex: 1, flexDirection: 'column', marginTop: 7 }}>
                                        <input ref={inputRef7} className="form-control file" id="support_file_5" type="file" accept="image/*" onChange={(e) => setSupportFile5(e.target.files[0])} />
                                        { (supportFile5Url) &&
                                            <a href={supportFile5Url} target='_blank' style={{ marginBottom: 5, marginLeft: 5}}><RiBankCardLine style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} /> กดเพื่อดูรูปภาพ</a>
                                        }
                                    </div>
                                </div>)}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default EditShop