import React, { useState, useEffect } from 'react'
import { db } from "../../assets/js/firebase"
import { collection, getDoc, addDoc, updateDoc, doc, deleteDoc } from 'firebase/firestore'
import { useNavigate, Link } from "react-router-dom"
import { Dialog, DialogActions, DialogTitle, DialogContent } from '@mui/material'
import { IoMegaphoneOutline } from 'react-icons/io5'
import { Loading } from "../layouts/Loading"

export function AppointmentShopBoard({appointment, offerExchangeCoin}) {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [showLoading, setShowLoading] = useState(false)
    
    const [payCoin, setPayCoin] = useState(0)
    const [coinEnough, setCoinEnough] = useState(false)
    const [coinMessage, setCoinMessage] = useState('')
    const [depositCoin, setDepositCoin] = useState(appointment.data.deposit_coin * 1)
    const [remainCoin, setRemainCoin] = useState(appointment.data.remain_coin * 1)

    const [openDeposit, setOpenDeposit] = useState(false)
    const [openCanclePay, setOpenCanclePay] = useState(false)
    const [openFinish, setOpenFinish] = useState(false)

    const [message, setMessage] = useState('')
    const [score, setScore] = useState('5')
    const [reason, setReason] = useState('')

    const userId = appointment.data.user_id
    const shopId = appointment.data.shop_id
    const postId = appointment.data.post_id
    const postOfferId = appointment.data.offer_id
    const userOfferId = appointment.data.user_offer_id

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const getPayRate = () => {
        var price = 0
        if(appointment.offer) {
            price = appointment.offer.price * 1
        }
        getDoc(doc(db, 'exchange_coins', 'exchange_rate')).then((exchangeRate) => {
            const rate = exchangeRate.data().money_to_coin_1 * 1
            const payCoin = Math.ceil(price/rate)
            setPayCoin(payCoin)
        })
    }

    const handleOpenDeposit = () => {
        setOpenDeposit(true)
    }
    
    const handleCloseDeposit = () => {
        setOpenDeposit(false)
    }
    
    const handleSubmitDeposit = () => {
        setShowLoading(true)
        const logDate = new Date()
        var promisesTask = []

        var updateAppointmentTask = updateDoc(doc(db, `appointmentshops`, appointment.id), { 
            status: 2 
        })
        promisesTask.push(updateAppointmentTask)

        // เติมเหรียญจำนวน depositCoin ให้ user 
        var getUserCoinTask = getDoc(doc(db, `shops`, shopId)).then((userDoc) => {
            var exchangeCoin = 0
            if(userDoc.data().exchange_coin) { exchangeCoin = userDoc.data().exchange_coin }
            updateDoc(doc(db, 'shops/', userId), {
                exchange_coin: (exchangeCoin * 1) + depositCoin
            })
        })
        promisesTask.push(getUserCoinTask)

        var addNotiUserTask = addDoc(collection(db, `/shops/${shopId}/notifications`), {
            date: logDate.getTime(),
            message: "คุณได้รับเหรียญจากการมัดจำสินค้า 30% จำนวน "+number_format.format(depositCoin)+" เหรียญ",
            link: '/shop/post/'+postId,
            type: 'coin',
            status: 0
        })
        promisesTask.push(addNotiUserTask)

        var updateCoinTask = getDoc(doc(db, 'exchange_coins', 'sum_data')).then((coinSnapshot) => {
            const data = coinSnapshot.data()
            updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                all: (data.all*1) - depositCoin
            })
        })
        promisesTask.push(updateCoinTask)

        var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
            coin: depositCoin,
            date: logDate.getTime(),
            event_type: 'transfer',
            event: 'โอนมัดจำ 30% ของราคาสินค้า ให้ผู้ขาย',
            type: 'decrese',
            post_type: 'second',
            post_id: postId
        })
        promisesTask.push(addCoinLogTask)

        var addUserCoinLogTask = addDoc(collection(db, `shops/${shopId}/coin_log/`), {
            coin: depositCoin,
            date: logDate.getTime(),
            event_type: 'increase',
            event: 'มัดจำ 30% ของราคาสินค้า ให้ผู้ขาย',
            coin_type: 'exchange',
            post_type: 'second',
            post_id: postId,
            appointment_id: appointment.id
        })
        promisesTask.push(addUserCoinLogTask)
                    
        Promise.all(promisesTask).then(() => {
            setOpenDeposit(false)
            setShowLoading(false)
            navigate(0)
        })
    }

    const handleOpenCanclePay = () => {
        setOpenCanclePay(true)
    }

    const handleCloseCanclePay = () => {
        setOpenCanclePay(false)
    }

    const handleCanclePayCoin = () => {
        setShowLoading(true)
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointmentshops/`, appointment.id), { 
            status: 5,
            shop_cancle_message: message,
            shop_cancle_reason: reason,
            shop_cancle_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        var updatePostTask = updateDoc(doc(db, `/postshops/`, postId), { status: 1 })
        promisesTask.push(updatePostTask)
        
        var updateShopOfferTask = updateDoc(doc(db, `/users/${userId}/offers`, userOfferId), { status: 4 })
        promisesTask.push(updateShopOfferTask)
        
        var addCancleHistoryTask = addDoc(collection(db, `postshops/${postId}/cancle_offers/`), {
            user_id: userId,
            cancle_message: message,
            cancle_reason: reason,
            cancle_datetime: logDate.getTime()
        })
        promisesTask.push(addCancleHistoryTask)

        var deleteOfferTask = deleteDoc(doc(db, `postshops/${postId}/offers/`, postOfferId))
        promisesTask.push(deleteOfferTask)

        var addBlackListTask = addDoc(collection(db, `postshops/${postId}/user_blacklist/`), {
            user_id: userId,
        })
        promisesTask.push(addBlackListTask)

        // โอนเหรียญจำนวน depositCoin คืนให้ shop
        var getShopCoinTask = getDoc(doc(db, `users`, userId)).then((shopDoc) => {
            var exchangeCoin = 0
            if(shopDoc.data().exchange_coin) { exchangeCoin = shopDoc.data().exchange_coin }
            updateDoc(doc(db, 'users/', userId), {
                exchange_coin: (exchangeCoin*1)+ depositCoin
            })
        })
        promisesTask.push(getShopCoinTask)

        var addNotiShopTask = addDoc(collection(db, `/users/${userId}/notifications`), {
            date: logDate.getTime(),
            message: "คุณได้รับเหรียญจากการมัดจำสินค้า 30% คืน จำนวน "+number_format.format(depositCoin)+" เหรียญ",
            link: '/shop/post/'+postId,
            type: 'coin',
            status: 0
        })
        promisesTask.push(addNotiShopTask)

        var updateCoinTask = getDoc(doc(db, 'exchange_coins', 'sum_data')).then((coinSnapshot) => {
            const data = coinSnapshot.data()
            updateDoc(doc(db, 'exchange_coins/', 'sum_data'), {
                all: (data.all*1) - depositCoin
            })
        })
        promisesTask.push(updateCoinTask)

        var addCoinLogTask = addDoc(collection(db, `exchange_coins/logs/transaction`), {
            coin: depositCoin,
            date: logDate.getTime(),
            event_type: 'transfer',
            event: 'โอนมัดจำ 30% ของราคาสินค้าคืน ให้ผู้จอง',
            type: 'decrese',
            post_type: 'second',
            post_id: postId
        })
        promisesTask.push(addCoinLogTask)

        var addShopCoinLogTask = addDoc(collection(db, `users/${userId}/coin_log/`), {
            coin: depositCoin,
            date: logDate.getTime(),
            event_type: 'increase',
            event: 'คืนมัดจำ 30% ของราคาสินค้า ให้ผู้ขาย',
            coin_type: 'exchange',
            post_type: 'second',
            post_id: postId,
            appointment_id: appointment.id
        })
        promisesTask.push(addShopCoinLogTask)

        Promise.all(promisesTask).then(() => {
            handleCloseCanclePay(false)
            setShowLoading(false)
            navigate(0)
        })
    }


    const handleOpenFinish = () => {
        // check เหรียญ ของผู้ซื้อว่าพอกับ remainCoin หรือไม่
        if(remainCoin < offerExchangeCoin) { 
            setCoinEnough(true)
            setCoinMessage('คุณมีเหรียญซื้อ '+number_format.format(offerExchangeCoin)+' เหรียญ คุณจะใช้ '+number_format.format(remainCoin)+' เหรียญ (70% ของราคาสินค้า) เพื่อยืนยันซื้อสินค้า?') 
        } else  { 
            setCoinEnough(false) 
            setCoinMessage('คุณมีจำนวนเหรียญซื้อไม่พอ คุณมี '+number_format.format(offerExchangeCoin)+' เหรียญ คุณต้องใช้ '+number_format.format(remainCoin)+' เหรียญ (70% ของราคาสินค้า) เพื่อยืนยันซื้อสินค้า')
        }
        setOpenFinish(true)
    }
    
    const handleCloseFinish = () => {
        setRemainCoin(0)
        setOpenFinish(false)
    }
    
    const handleSubmitFinish = () => {
        const logDate = new Date()
        var promisesTask = []
        var updateAppointmentTask = updateDoc(doc(db, `/appointmentposts/`, appointment.id), { 
            coin: depositCoin + remainCoin,
            status: 6,
            message_for_user: message,
            score_for_user: score,
            shop_finish_datetime: logDate.getTime()
        })
        promisesTask.push(updateAppointmentTask)

        var updatePostTask = updateDoc(doc(db, `/postshops/`, postId), { 
            shop_finish_date: logDate.getTime()
        })
        promisesTask.push(updatePostTask)

        // โอนเหรียญ 70% (remainCoin) ให้ user
        var getUserCoinTask = getDoc(doc(db, `shops`, shopId)).then((userDoc) => {
            var exchangeCoin = 0
            if(userDoc.data().exchange_coin) { exchangeCoin = userDoc.data().exchange_coin }
            updateDoc(doc(db, 'shops/', shopId), {
                exchange_coin: (exchangeCoin*1) + remainCoin
            })
        })
        promisesTask.push(getUserCoinTask)

        var addNotiUserTask = addDoc(collection(db, `/shops/${shopId}/notifications`), {
            date: logDate.getTime(),
            message: "คุณได้รับเหรียญจากการขายสินค้า 70% จำนวน "+number_format.format(remainCoin)+" เหรียญ",
            link: '/shop/post/'+postId,
            type: 'coin',
            status: 0
        })
        promisesTask.push(addNotiUserTask)

        var addUserCoinLogTask = addDoc(collection(db, `shops/${shopId}/coin_log/`), {
            coin: remainCoin,
            date: logDate.getTime(),
            event_type: 'increase',
            event: 'ค่าสินค้า 70% ของราคาสินค้า',
            coin_type: 'exchange',
            post_type: 'second',
            post_id: postId,
            appointment_id: appointment.id
        })
        promisesTask.push(addUserCoinLogTask)

        var getShopCoinTask = getDoc(doc(db, `users`, userId)).then((shopDoc) => {
            var exchangeCoin = 0
            if(shopDoc.data().exchange_coin) { exchangeCoin = shopDoc.data().exchange_coin }
            updateDoc(doc(db, 'users/', userId), {
                exchange_coin: (exchangeCoin*1) - remainCoin
            })
        })
        promisesTask.push(getShopCoinTask)

        var addNotiShopTask = addDoc(collection(db, `/users/${userId}/notifications`), {
            date: logDate.getTime(),
            message: "คุณจ่ายเหรียญจากการซื้อสินค้า 70% จำนวน "+number_format.format(remainCoin)+" เหรียญ",
            link: '/shop/post/'+postId,
            type: 'coin',
            status: 0
        })
        promisesTask.push(addNotiShopTask)

        var addShopCoinLogTask = addDoc(collection(db, `users/${userId}/coin_log/`), {
            coin: remainCoin,
            date: logDate.getTime(),
            event_type: 'increase',
            event: 'จ่ายเหรียญ 70% ของราคาสินค้า ให้ผู้ขาย',
            coin_type: 'exchange',
            post_type: 'second',
            post_id: postId,
            appointment_id: appointment.id
        })
        promisesTask.push(addShopCoinLogTask)

        // Shop ได้รับแต้มสะสม
        var weePoint = 100
        var addWeePointTask = addDoc(collection(db, `/users/${userId}/points`), {
            datetime: logDate.getTime(),
            point: weePoint,
            post_id: postId,
            appointment_id: appointment.id
        })
        promisesTask.push(addWeePointTask)

        var addNotiShopTask = addDoc(collection(db, `/users/${userId}/notifications`), {
            date: logDate.getTime(),
            message: "คุณได้รับแต้มสะสมจากการขายซาก จำนวน "+number_format.format(weePoint)+" เหรียญ",
            link: '/shop/post/'+postId,
            type: 'point',
            status: 0
        })
        promisesTask.push(addNotiShopTask)

        // User ได้รับแต้มสะสม
        var weePoint = 100
        var addUserWeePointTask = addDoc(collection(db, `/shops/${shopId}/points`), {
            datetime: logDate.getTime(),
            point: weePoint,
            post_id: postId,
            appointment_id: appointment.id
        })
        promisesTask.push(addUserWeePointTask)

        var addNotiUserTask = addDoc(collection(db, `/shops/${shopId}/notifications`), {
            date: logDate.getTime(),
            message: "คุณได้รับแต้มสะสมจากการขายซาก จำนวน "+number_format.format(weePoint)+" แต้ม",
            link: '/shop/post/'+postId,
            type: 'point',
            status: 0
        })
        promisesTask.push(addNotiUserTask)

        var updatePostTask = updateDoc(doc(db, `/postshops/`, postId), { 
            status: 9,
            appointment_id: appointment.id,
            finish_date: logDate.getTime()
        })
        promisesTask.push(updatePostTask)
        
        Promise.all(promisesTask).then(() => {
            handleCloseFinish()
            setShowLoading(false)
            navigate(0)
        })
        
    }

    useEffect(() => {
        getPayRate()
    })
    
    return (
        <>
            <Loading open={showLoading} text={"กำลังบันทึกข้อมูล กรุณารอสักครู่"} />
            <div className="card" style={{ width: "19rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
                {appointment.badgeStyle.show && (
                <div className="float-right" style={{ position:'absolute', float: 'right', display:'flex', alignSelf: 'end', marginTop: 5, marginRight: 5}} >
                    <button type="button" className={"btn "+"btn-"+appointment.badgeStyle.class} style={{ color: appointment.badgeStyle.color }}><strong>{appointment.badgeStyle.message}</strong></button>
                </div>
                )}
                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                    <img src={appointment.post.picture} alt="WEEE" height="150" />
                </div>
                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                    <p className="card-text">
                        <strong>ประเภทนัดหมาย:</strong> ร้านค้าขายเครื่องใช้ไฟฟ้ามือสอง<br/>
                        <><strong>วันนัดหมาย:</strong> {dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น. <br/></>
                        <><strong>ที่อยู่:</strong> {appointment.post.address} <br/></>
                        {appointment.post.type} {appointment.post.brand} {appointment.data.model}<br/>
                        <strong>ราคาเสนอขาย:</strong> {number_format.format(appointment.post.price)} บาท <br/>
                        {appointment.offer && (
                            <><strong>ราคาเสนอซื้อ:</strong> {number_format.format(appointment.offer.price)} บาท <br/></>
                        )}
                        {(appointment.data.status == 6) && (<>
                            <strong>คะแนน:</strong> {appointment.data.score} <br/>
                            <strong>คำติชม:</strong> {appointment.data.finish_message} <br/>
                        </>)}
                    </p>
                </div>
                <div className="card-body">
                    <Link className="btn btn-primary m-1" to={`/shop/post/${appointment.data.post_id}`} target='_blank'><strong>ดูประกาศ</strong></Link>
                    {/* <Link className="btn btn-primary m-1" to={`/shop/postdetail/${appointment.post.onboard}/${appointment.data.post_id}/${appointment.data.shop_id}`} target='_blank'><strong>ดูนัดหมายและข้อเสนอ</strong></Link> */}
                    {(appointment.data.status == 1) && (<>
                        <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenDeposit() }}>
                            <strong>จ่ายเหรียญซื้อสินค้า</strong>
                        </button>
                        <button type="button" className="btn btn-danger m-1" onClick={() => { handleOpenCanclePay() }}>
                            <strong>ยกเลิกการซื้อสินค้า</strong>
                        </button>
                    </>)}
                    {(appointment.data.status == 3) && (<>
                        <button type="button" className="btn btn-success m-1" onClick={() => { handleOpenFinish() }}>
                            <strong>ยืนยันได้รับสินค้า</strong>
                        </button>
                    </>)}
                </div>

                <Dialog open={openDeposit} onClose={handleCloseDeposit} maxWidth='sm' fullWidth={true}>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>จ่ายเหรียญมัดจำซื้อสินค้า</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <h5>จ่ายเหรียญซื้อสินค้า</h5>
                                        <h6><strong>คุณต้องการโอนเหรียญมัดจำ 30% ให้ผู้ขาย?</strong></h6>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseDeposit} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleSubmitDeposit} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>จ่ายเหรียญมัดจำซื้อสินค้า</button>
                    </DialogActions>
                </Dialog>

                <Dialog open={openFinish} onClose={handleCloseFinish} fullWidth={true} maxWidth='sm'>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยืนยันได้รับซาก</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                    <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <h5>จ่ายเหรียญซื้อสินค้ามือสอง</h5>
                                            {coinEnough &&
                                            <h6><strong>{coinMessage}</strong></h6>
                                            }
                                            {!coinEnough &&
                                            <h6 className='text-danger'><strong>{coinMessage}</strong></h6>
                                            }
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex' }}><h5><strong>คำติชมเพิ่มเติม</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                <textarea style={{ display: 'flex', flex: 1, height: 100, paddingLeft: 8 }}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder='คำติชมเพิ่มเติม' 
                                                    value={message}></textarea>
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0, marginTop: 10 }}>
                                            <label className="" style={{ display: 'flex' }}><h5><strong>คะแนน</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, flexDirection: 'column' }}>
                                                <select name='score' onChange={(e) => setScore(e.target.value)} style={{ flex: 1 }}>
                                                    <option value="5">5</option>
                                                    <option value="4">4</option>
                                                    <option value="3">3</option>
                                                    <option value="2">2</option>
                                                    <option value="1">1</option>
                                                </select>
                                            </div>
                                        </div> 
                                        <div style={{ display:'flex', flexDirection: 'column', marginTop: 10 }}>
                                            <div style={{ backgroundColor: '#ffffff' }}>
                                                <h6 className='text-danger'><strong>คุณจะได้รับแต้มสะสม เมื่อกดยืนยันได้รับสินค้าแล้ว</strong></h6>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseFinish} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        {coinEnough &&
                        <button onClick={handleSubmitFinish} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยืนยันได้รับสินค้า</button>
                        }
                    </DialogActions>
                </Dialog>

                <Dialog open={openCanclePay} onClose={handleCloseCanclePay} fullWidth={true} maxWidth='sm'>
                    <DialogTitle style={{ paddingBottom: 5 }} className='bg-primary text-white'><div style={{ fontFamily: 'Prompt'}}><h4><IoMegaphoneOutline style={{ fontSize: 25, marginRight: 10, marginBottom: 5 }} /><strong>ยกเลิกการเข้าซื้อสินค้า</strong></h4></div></DialogTitle>
                    <DialogContent style={{ fontFamily: 'Prompt', marginTop: 10 }}>
                        <div className="" style={{ backgroundColor: "#ffffff", display: 'flex', flexDirection: 'column' }}>
                            <div style={{ display:'flex', flexDirection: 'column', alignItems:'center' }}>
                                <div style={{ display:'flex', flexDirection: 'column', width:'100%' }}>
                                    <div style={{ backgroundColor: '#ffffff' }}>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="" style={{ display: 'flex'}}><h5><strong>เหตุผลที่ยกเลิก</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1, marginBottom: 15 }}>
                                                <select name='reason' onChange={(e) => setReason(e.target.value)} style={{ flex: 1 }}>
                                                    <option value="1">ไปถึงที่พักแต่ไม่เจอผู้ขาย/ไม่มาส่งสินค้าที่ร้านฯ ตามวันเวลาที่นัด</option>
                                                    <option value="2">มีการเรียกเก็บเงินเพิ่ม</option>
                                                    <option value="3">อื่นๆ (โปรดระบุ)</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className="" style={{ display: 'flex', flex: 1, flexDirection: 'column', backgroundColor: '#ffffff', marginLeft: 0, marginRight: 0, paddingBottom: 0 }}>
                                            <label className="" style={{ display: 'flex', width: 200}}><h5><strong>เหตุผลเพิ่มเติม</strong></h5></label>
                                            <div style={{ display: 'flex', flex: 1 }}>
                                                <textarea style={{ display: 'flex', flex: 1, height: 100 }}
                                                    onChange={(e) => setMessage(e.target.value)}
                                                    placeholder='เหตุผลเพิ่มเติม' 
                                                    value={message}></textarea>
                                            </div>
                                        </div> 
                                    </div>
                                </div>
                            </div>
                        </div>
                    </DialogContent>
                    <DialogActions>
                        <button onClick={handleCloseCanclePay} className='btn btn-danger' style={{ fontFamily: 'Prompt', float: 'right' }}>ปิดหน้าต่าง</button>
                        <button onClick={handleCanclePayCoin} className='btn btn-primary' style={{ fontFamily: 'Prompt' }}>ยกเลิกการเข้าซื้อสินค้า</button>
                    </DialogActions>
                </Dialog>
            </div>
        </>
    )
}