import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDoc, getDocs, orderBy, onSnapshot, addDoc, updateDoc, doc } from 'firebase/firestore'
import { useNavigate } from "react-router-dom";
import LeftMenu from './LeftMenu';
import { RiDeleteBinLine } from "react-icons/ri"
import { BiMoneyWithdraw } from "react-icons/bi"
import { TbHexagonNumber2, TbSearch } from "react-icons/tb"
import { AiOutlineClockCircle, AiOutlineCloseCircle, AiOutlineCheckCircle } from "react-icons/ai"
import noImage from "../../assets/images/no-image-icon.png"
import CloseAppointment from './CloseAppointment';
import CancleAppointment from './CancleAppointment';

const AppointmentsClose = () => {
    const navigate = useNavigate();
    const [user, loading, error] = useAuthState(auth);
    const [uid, setUid] = useState('');
    const [user_id, setUserId] = useState('');
    const [isShop, setIsShop] = useState(0);

    const [appointments, setAppointments] = useState([]);
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH");
        const time_string = new Date(datetime).toLocaleTimeString("th-TH");
        const time = time_string.split(':');
        return date_string+" "+time[0]+":"+time[1]
    }

    const dateFormatDate = (datetime) => {
        return (datetime?new Date(datetime.seconds * 1000).toLocaleDateString("th-TH", {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
        }):"")
    }

    const fetchAppointments = async () => {
        try {
            const q = query(collection(db, "users"), where("uid", "==", user.uid));
            const docs = await getDocs(q);
            if (docs.docs.length === 1) {
                const data = docs.docs[0].data()
                setUserId(docs.docs[0].id)
                if(data.uid) setUid(data.uid)
                if(data.is_shop) setIsShop(data.is_shop)
                const postQuery = query(collection(db, 'appointments'), 
                                            where('user_id', '==', docs.docs[0].id), 
                                            where('status', '==', 2),
                                            orderBy('appointment_date', 'asc'), 
                                            orderBy('appointment_hour', 'asc'), 
                                            orderBy('appointment_minute', 'asc'))

                onSnapshot(postQuery, (querySnapshot) => {
                    setAppointments([])
                    querySnapshot.forEach( async(appointment) => {
                        const postSnapshot = await getDoc(doc(db, 'posts', appointment.data().post_id));
                        const offerSnapshot = await getDoc(doc(db, `posts/${appointment.data().post_id}/offers`, appointment.data().offer_id));
                        const shopSnapshot = await getDoc(doc(db, 'shops', appointment.data().shop_id));

                        setAppointments([...appointments, {
                            id: appointment.id,
                            data: appointment.data(),
                            offer: offerSnapshot.data(),
                            post: postSnapshot.data(),
                            shop: shopSnapshot.data()
                        }])
                    })
                })
            }
        } catch (err) {
            console.error(err);
            alert(err.message);
        }

    }

    useEffect(() => {
        if (loading) return;
        if (!user) return navigate("/")
        else fetchAppointments()
    }, [user, loading]);

    useEffect(() => {
    },[])

return (
    <>
        <div style={{ backgroundColor: "#fcfcfc", minHeight: 500, padding: 20}}>
            <div style={{ display: 'flex', flexDirection: 'column', marginLeft: 20, marginRight: 20, marginBottom: 30 }}>
                <div style={{ paddingLeft: 20, marginBottom: 10, borderBottom: '1px solid #cccccc' }}><h4><b>การนัดหมายของฉัน</b></h4></div>
                <div style={{ display: 'flex', flexDirection: 'row' }}>
                    <LeftMenu currentPage="appointments" isShop={isShop} />
                    <div style={{ display: 'flex', flexDirection: 'column', borderLeft: '1px solid #cccccc', paddingLeft: 20, flex: 1 }}>
                        <div style={{ borderBottom: '1px solid #cccccc', paddingLeft: 20 }}>
                            <ul className="nav nav-underline">
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/appointments/"><AiOutlineClockCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />รอวันนัดหมาย</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link active" aria-current="page" href="/profile/appointments/close"><AiOutlineCheckCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />สำเร็จ</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/appointments/cancle"><AiOutlineCloseCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ยกเลิกโดยผู้ประกาศ</a>
                                </li>
                                <li className="nav-item">
                                    <a className="nav-link" href="/profile/appointments/shopcancle"><AiOutlineCloseCircle style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />ยกเลิกโดยร้านค้า</a>
                                </li>
                            </ul>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', marginTop: 15}}>
                        {
                            appointments?.map((appointment, i) => (
                            <div className="card" style={{ width: "16rem", marginLeft: 5, marginRight: 5, marginBottom: 10, alignItems:'center' }} key={appointment.id}>
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                    <p className="card-text"><strong>วันที่นัดหมาย:</strong><br/>{dateFormatDate(appointment.data.appointment_date)} เวลา {appointment.data.appointment_hour}:{appointment.data.appointment_minute} น.</p>
                                    <p className="card-text"><strong>วันที่ปิดงาน:</strong><br/>{dateFormat(appointment.data.close_datetime)}</p>
                                </div>
                                <div style={{ marginLeft: 20, marginRight: 20, marginTop: 20, display: 'flex', alignContent: 'center', textAlign:'center', flex: 1 }} >
                                    <img src={appointment.post.picture} alt="WEEE" height="150" />
                                </div>
                                <div className="card-body" style={{ display: 'flex', flex: 1, alignSelf: 'start', alignItems: 'start', flexDirection:'column'}} >
                                    <p className="card-text">{appointment.post.type} {appointment.post.brand} {appointment.data.model}</p>
                                    {(appointment.post.onboard==2 || appointment.post.onboard==3) &&
                                        (<p className="card-text"><strong>ราคา:</strong> {appointment.post.price} บาท</p>)
                                    }
                                </div>
                                <div className="card-body">
                                    <button type="button" className="btn btn-primary m-1" onClick={() => navigate(`/post/${appointment.data.post_id}`)}><strong>รายละเอียด</strong></button>
                                </div>
                            </div>
                            ))
                        }
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </>
)
}

export default AppointmentsClose;