import React, { useState, useEffect } from 'react';
import { collection, query, orderBy, onSnapshot, where, deleteDoc, doc, getDoc } from "firebase/firestore"
import { db } from "../../assets/js/firebase";
import { useNavigate } from "react-router-dom";
import { TbCirclePlus } from "react-icons/tb";
import { AiOutlineShop, AiFillShop, AiFillInfoCircle } from "react-icons/ai";
import { FiEdit } from "react-icons/fi";
import "../../assets/css/memberList.css";
import 'react-confirm-alert/src/react-confirm-alert.css';
import { TbUser, TbSearch } from "react-icons/tb"

const ShopList = () => {
    const [shops, setShops] = useState([])
    const navigate = useNavigate();

    const [keyword, setKeyword] = useState("")
    const [field, setField] = useState('firstname')
    const [order, setOrder] = useState('asc')
    const [orderby, setOrderBy] = useState('firstname')

    const handleSearch = (keyword) => {
        if(keyword) {
            var shopQuery = query(collection(db, 'shops'), 
            where(field, '>=', keyword),
            where(field, '<=', keyword +  '\uf8ff'), 
            orderBy(field, order))

            onSnapshot(shopQuery, (querySnapshot) => {
                querySnapshot.forEach((shop) => {
                    getDoc(doc(db, 'users', shop.data().user_id)).then((memberDoc) => {
                        shops.push({
                            id: shop.id,
                            data: shop.data(),
                            user: memberDoc.data()
                        })
                        setShops([...shops, {
                            id: shop.id,
                            data: shop.data(),
                            user: memberDoc.data()
                        }])
                    })
                })
            })
        } else {
            const shopQuery = query(collection(db, 'shops'), orderBy(orderby, order))
            onSnapshot(shopQuery, (querySnapshot) => {
                querySnapshot.forEach((shop) => {
                    getDoc(doc(db, 'users', shop.data().user_id)).then((memberDoc) => {
                        shops.push({
                            id: shop.id,
                            data: shop.data(),
                            user: memberDoc.data()
                        })
                        setShops([...shops, {
                            id: shop.id,
                            data: shop.data(),
                            user: memberDoc.data()
                        }])
                    })
                })
                /*setShops(querySnapshot.docs.map(doc => ({
                    id: doc.id,
                    data: doc.data()
                })))*/
            })
        }
    }

    useEffect(() => {
        const shopQuery = query(collection(db, 'shops'), orderBy(orderby, order))
        onSnapshot(shopQuery, (querySnapshot) => {
            querySnapshot.forEach((shop) => {
                getDoc(doc(db, 'users', shop.data().user_id)).then((memberDoc) => {
                    shops.push({
                        id: shop.id,
                        data: shop.data(),
                        user: memberDoc.data()
                    })
                    setShops([...shops, {
                        id: shop.id,
                        data: shop.data(),
                        user: memberDoc.data()
                    }])
                })
            })
        })
    },[])

    var shopKey = []
    var shopList = []
    shops.forEach(element => {
        if(shopKey.lastIndexOf(element.id) < 0) {
            shopKey.push(element.id)
            shopList.push(element)
        }
    });
    return (
        <div style={{ display: 'flex', flexDirection: 'column' }} className="p-3">
            <div style={{ marginBottom: 10, borderBottom: '1px solid #cccccc', display: 'flex', flexDirection: 'row', flex: 1, marginTop: 15 }}>
                <h4 style={{ display: 'flex', flex: 1}}><AiFillShop style={{ fontSize: 30, marginRight: 5, paddingBottom: 5 }} />
                <b>รายชื่อร้านค้า</b></h4>
            </div>
            <div style={{ display: 'flex', flexDirection: 'row'}}>
                <div className="row" style={{ marginRight: 5}}>
                    <div className="input-group" style={{ marginBottom: 10 }}>
                        <input 
                            type="text" 
                            className="form-control" 
                            placeholder="ชื่อร้านค้า" 
                            aria-describedby="button-addon2"
                            onChange={(e) => setKeyword(e.target.value)} 
                            value={keyword}
                            style={{ width: 400 }} />
                        <button 
                            className="btn btn-outline-secondary" 
                            type="button" 
                            id="button-addon2"
                            onClick={() => handleSearch(keyword)}><TbSearch /></button>
                    </div>
                </div>
                <div style={{ paddingRight: 10, marginBottom: 10, display: 'flex', flexDirection: 'row' }}>
                    <button type="button" className="btn btn-success" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/add`) }}>
                        <TbCirclePlus style={{ fontSize: 25, paddingBottom: 3, marginRight: 5 }} /> <b>เพิ่มร้านค้า</b>
                    </button>
                </div>
            </div>
            <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-3">
                <table className="table" style={{ marginLeft: 10}}>
                    <thead>
                        <tr>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10, width: 50}}> </th>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', paddingTop: 10}}>ชื่อร้านค้า</th>
                            <th scope="col" style={{ backgroundColor: '#eeeeee', width: 300}}> </th>
                        </tr>
                    </thead>
                    <tbody>
            {
                shopList?.map((shop, i) => (
                    <tr key={i}>
                        <td style={{ paddingLeft: 10 }}><input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" /></td>
                        <th scope="row">
                            {(shop.data.type==1 && shop.user)&&(
                                <>
                                    <font style={{ marginRight: 10}}>{shop.user.firstname?shop.user.firstname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                    <font style={{ marginRight: 10}}>{shop.user.lastname?shop.user.lastname:(<font className="text-danger">ไม่ระบุ</font>)}</font>
                                    <font style={{ marginRight: 10}}>({shop.user.display_name?shop.user.display_name:(<font className="text-danger">ไม่ระบุ</font>)})</font>
                                </>
                            )}
                            {(shop.data.type==2)&&(shop.data.shopname)}
                        </th>
                        <td style={{ display: 'flex', flexDirection: 'row'}}>
                            <button type="button" className="btn btn-success btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/edit/${shop.id}`) }}><FiEdit style={{ fontSize: 20, marginRight: 5 }} />แก้ไขข้อมูล</button>
                            <button type="button" className="btn btn-primary btn-sm" style={{ marginRight: 5, display: 'flex' }} onClick={() => { return navigate(`/admin/shop/view/${shop.id}`) }}><AiFillInfoCircle style={{ fontSize: 20, marginRight: 5 }} />ดูข้อมูล</button>
                        </td>
                    </tr>
                ))
            }
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ShopList;