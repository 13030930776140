import React, { useState, useEffect, useRef } from 'react';
import { useAuthState } from "react-firebase-hooks/auth";
import { db, auth } from "../../assets/js/firebase"
import { collection, query, where, getDocs, orderBy, onSnapshot, limit, or, and, getDoc, doc, updateDoc } from 'firebase/firestore'
import { useNavigate } from "react-router-dom"
import { Slide } from 'react-slideshow-image'
import { getBrand, getModel } from '../helper/BrandHelper'
import { getBroken } from '../helper/BrokenHelper'
import { getBrand as getAirBrand, getModel as getAirModel } from '../helper/BrandAirHelper'
import { getBrand as getWashBrand, getModel as getWashModel } from '../helper/BrandWashHelper'
import noImage from '../../assets/images/no-image-icon.png'
import { MdOutlinePolicy } from "react-icons/md"
import { BiMoneyWithdraw, BiWrench } from "react-icons/bi"
import { TbHexagonNumber2 } from "react-icons/tb"
import { LuAirVent } from "react-icons/lu"
import { CgSmartHomeWashMachine } from "react-icons/cg"

import "react-slideshow-image/dist/styles.css"
import './slide_style.css'
import './Home2.css'

const responsiveSettings = [
    {
        breakpoint: 800,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    },
    {
        breakpoint: 500,
        settings: {
            slidesToShow: 1,
            slidesToScroll: 1
        }
    }
];

const divStyle = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundSize: 'cover',
    height: window.innerWidth < 920?'200px':'400px'
}

const Home = () => {
    const navigate = useNavigate()
    const number_format = new Intl.NumberFormat()
    const [user, loading, error] = useAuthState(auth)
    const initialized = useRef(false)

    const onMobile = window.innerWidth < 920
    var limitRow = 4
    var limitRowPost = 3;
    var fontSizeHeadLaw = 20;
    var fontSizeDetailLaw = 18;
    var iconSizeLaw = 60
    var fontSizeHeadPost = 30;
    var fontSizeViewAllPost = 20;
    var imagePostHeight = 190
    var fontSizeHeadSale = 60;
    var fontSizeDetailSale = 20;
    var fontSizeHeadSide = 30;
    var flexLeft = 9
    var flexRight = 3

    if(window.innerWidth < 920 && window.innerWidth >= 576) {
        limitRow = 2;
        limitRowPost = 2;
        fontSizeHeadLaw = 18;
        fontSizeDetailLaw = 16;
        iconSizeLaw = 45
        fontSizeHeadPost = 18;
        fontSizeViewAllPost = 14;
        imagePostHeight = 100
        fontSizeHeadSale = 30;
        fontSizeDetailSale = 14;
        fontSizeHeadSide = 20;
        flexLeft = 10
        flexRight = 10
    } else if(window.innerWidth < 576) {
        limitRow = 2;
        limitRowPost = 2;
        fontSizeHeadLaw = 14;
        fontSizeDetailLaw = 12;
        iconSizeLaw = 30
        fontSizeHeadPost = 14;
        fontSizeViewAllPost = 12;
        imagePostHeight = 100
        fontSizeHeadSale = 20;
        fontSizeDetailSale = 14;
        fontSizeHeadSide = 20;
        flexLeft = 10
        flexRight = 10
    }

    const adsPositionAmount = [ 4, 5, 5, 4, 3, 8, 8, 10, 10, 10 ]
    const ads1Color = [ "#F3F7FB", "#EEF5E8", "#FBEFE8", "#FEFBF0" ]
    const ads2Color = [ "#F3F7FB", "#EEF5E8", "#FBEFE8", "#FEFBF0", "#F2F2F2" ]

    const [board1Posts, setBoard1Posts] = useState([])
    const [board2Posts, setBoard2Posts] = useState([])
    const [board3Posts, setBoard3Posts] = useState([])
    const [boardFixPosts, setBoardFixPosts] = useState([])
    const [boardAirPosts, setBoardAirPosts] = useState([])
    const [boardWashPosts, setBoardWashPosts] = useState([])

    const [adsPosition1List, setAdsPosition1List] = useState([])
    const [adsPosition2List, setAdsPosition2List] = useState([])
    const [adsPosition3List, setAdsPosition3List] = useState([])
    const [adsPosition4List, setAdsPosition4List] = useState([])
    const [adsPosition5List, setAdsPosition5List] = useState([])

    const [topTenShopList, setTopTenShopList] = useState([])

    const [width, setWidth] = useState(100)
    const [height, setHeight] = useState(imagePostHeight)

    const [widthAds1, setWidthAds1] = useState(100)
    const [heightAds1, setHeightAds1] = useState(120)

    const [widthAds2, setWidthAds2] = useState(100)
    const [heightAds2, setHeightAds2] = useState(100)

    const [widthImageShop, setWidthImageShop] = useState(100)
    const [heightImageShop, setHeightImageShop] = useState(100)

    const [widthSlide, setWidthSlide] = useState(500)
    const [heightSlide, setHeightSlide] = useState(100)

    const [slideImages, setSlideImages] = useState([])

    const fetchBoard1 = async () => {
        try {
            board1Posts.splice(0, board1Posts.length)
            setBoard1Posts([...board1Posts])
            const postQuery = query(collection(db, 'posts'), and(where('onboard', '==', 1), or(where('status', '==', 1), where('status', '==', 2))), orderBy('post_timestamp', 'desc'), limit(6))
            onSnapshot(postQuery, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var brand = 'ไม่ระบุ'
                    var model = 'ไม่ระบุ'
                    var province = 'ไม่ระบุ'
                    var district = 'ไม่ระบุ'
                    var promisesTask = []
                    var getBrandTask = getBrand(db, doc.data().brand_id, doc.data().brand).then((brandName) => {
                        brand = brandName
                    })
                    promisesTask.push(getBrandTask)
    
                    var getModelTask = getModel(db, doc.data().model_id, doc.data().model).then((modelName) => {
                        model = modelName
                    })
                    promisesTask.push(getModelTask)

                    if(doc.data().province) {
                        var provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', doc.data().province * 1))).then((docs) => {
                            if(docs.docs.length > 0) { province = docs.docs[0].data().province_name }
                        })
                        promisesTask.push(provinceTask)
                    }

                    if(doc.data().district) {
                        var districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', doc.data().district))).then((docs) => {
                            if(docs.docs.length > 0) { district = docs.docs[0].data().district_name }
                        })
                        promisesTask.push(districtTask)
                    }

                    Promise.all(promisesTask).then(() => {
                        board1Posts.push({
                            id: doc.id,
                            data: doc.data(),
                            brand: brand,
                            model: model,
                            province: province,
                            district: district
                        })
                        setBoard1Posts([...board1Posts], {
                            id: doc.id,
                            data: doc.data(),
                            brand: brand,
                            model: model,
                            province: province,
                            district: district
                        })

                    })
                })
            })
        } catch (err) {
            console.error(err)
        }
    }

    const fetchBoard2 = async () => {
        try {
            board2Posts.splice(0, board2Posts.length)
            setBoard2Posts([...board2Posts])
            const postQuery = query(collection(db, 'posts'), and(where('onboard', '==', 2), or(where('status', '==', 1), where('status', '==', 2))), orderBy('post_timestamp', 'desc'), limit(6))
            onSnapshot(postQuery, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var brand = 'ไม่ระบุ'
                    var model = 'ไม่ระบุ'
                    var promisesTask = []
                    var getBrandTask = getBrand(db, doc.data().brand_id, doc.data().brand).then((brandName) => {
                        brand = brandName
                    })
                    promisesTask.push(getBrandTask)
    
                    var getModelTask = getModel(db, doc.data().model_id, doc.data().model).then((modelName) => {
                        model = modelName
                    })
                    promisesTask.push(getModelTask)

                    Promise.all(promisesTask).then(() => {
                        board2Posts.push({
                            id: doc.id,
                            data: doc.data(),
                            brand: brand,
                            model: model
                        })
                        setBoard2Posts([...board2Posts], {
                            id: doc.id,
                            data: doc.data(),
                            brand: brand,
                            model: model
                        })

                    })
                })
            })
        } catch (err) {
            console.error(err)
        }
    }

    const fetchBoard3 = async () => {
        try {
            board3Posts.splice(0, board3Posts.length)
            setBoard3Posts([...board3Posts])
            const postQuery = query(collection(db, 'posts'), and(where('onboard', '==', 3), or(where('status', '==', 1), where('status', '==', 2))), orderBy('post_timestamp', 'desc'), limit(9))
            onSnapshot(postQuery, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var brand = 'ไม่ระบุ'
                    var model = 'ไม่ระบุ'
                    var promisesTask = []
                    var getBrandTask = getBrand(db, doc.data().brand_id, doc.data().brand).then((brandName) => {
                        brand = brandName
                    })
                    promisesTask.push(getBrandTask)
    
                    var getModelTask = getModel(db, doc.data().model_id, doc.data().model).then((modelName) => {
                        model = modelName
                    })
                    promisesTask.push(getModelTask)

                    Promise.all(promisesTask).then(() => {
                        board3Posts.push({
                            id: doc.id,
                            data: doc.data(),
                            brand: brand,
                            model: model
                        })
                        setBoard3Posts([...board3Posts], {
                            id: doc.id,
                            data: doc.data(),
                            brand: brand,
                            model: model
                        })

                    })
                })
            })
        } catch (err) {
            console.error(err)
        }
    }

    const fetchBoard4 = async () => {
        try {
            boardFixPosts.splice(0, boardFixPosts.length)
            setBoardFixPosts([...boardFixPosts])
            const postQuery = query(collection(db, 'posts'), and(where('onboard', '==', 4), or(where('status', '==', 1), where('status', '==', 2))), orderBy('post_timestamp', 'desc'), limit(9))
            onSnapshot(postQuery, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var brand = 'ไม่ระบุ'
                    var model = 'ไม่ระบุ'
                    var broken = 'ไม่ระบุ'
                    var promisesTask = []
                    var getBrandTask = getBrand(db, doc.data().brand_id, doc.data().brand).then((brandName) => {
                        brand = brandName
                    })
                    promisesTask.push(getBrandTask)
    
                    var getModelTask = getModel(db, doc.data().model_id, doc.data().model).then((modelName) => {
                        model = modelName
                    })
                    promisesTask.push(getModelTask)

                    var getฺBrokenTask = getBroken(db, doc.data().broken_id, doc.data().broken_other).then((brokenName) => {
                        broken = brokenName
                    })
                    promisesTask.push(getฺBrokenTask)

                    Promise.all(promisesTask).then(() => {
                        boardFixPosts.push({
                            id: doc.id,
                            data: doc.data(),
                            brand: brand,
                            model: model,
                            broken: broken,
                        })
                        setBoardFixPosts([...boardFixPosts], {
                            id: doc.id,
                            data: doc.data(),
                            brand: brand,
                            model: model,
                            broken: broken,
                        })

                    })
                })
            })
        } catch (err) {
            console.error(err)
        }
    }

    const fetchBoardAir = async () => {
        try {
            boardAirPosts.splice(0, boardAirPosts.length)
            setBoardAirPosts([...boardAirPosts])
            const postQuery = query(collection(db, 'cleans'), and(where('onboard', '==', 1), or(where('status', '==', 1), where('status', '==', 2))), orderBy('post_timestamp', 'desc'), limit(6))
            onSnapshot(postQuery, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var brand1 = 'ไม่ระบุ'
                    var model1 = 'ไม่ระบุ'
                    var brand2 = 'ไม่ระบุ'
                    var model2 = 'ไม่ระบุ'
                    var brand3 = 'ไม่ระบุ'
                    var model3 = 'ไม่ระบุ'
                    var promisesTask = []
                    if(doc.data().have_air1) {
                        var getBrand1Task = getAirBrand(db, doc.data().air1_brand_id, doc.data().air1_brand).then((brandName) => {
                            brand1 = brandName
                        })
                        promisesTask.push(getBrand1Task)
        
                        var getModel1Task = getAirModel(db, doc.data().air1_model_id, doc.data().air1_model).then((modelName) => {
                            model1 = modelName
                        })
                        promisesTask.push(getModel1Task)
                    }
                    if(doc.data().have_air2) {
                        var getBrand2Task = getAirBrand(db, doc.data().air2_brand_id, doc.data().air2_brand).then((brandName) => {
                            brand2 = brandName
                        })
                        promisesTask.push(getBrand2Task)
        
                        var getModel2Task = getAirModel(db, doc.data().air2_model_id, doc.data().air2_model).then((modelName) => {
                            model2 = modelName
                        })
                        promisesTask.push(getModel2Task)
                    }
                    if(doc.data().have_air3) {
                        var getBrand3Task = getAirBrand(db, doc.data().air3_brand_id, doc.data().air3_brand).then((brandName) => {
                            brand3 = brandName
                        })
                        promisesTask.push(getBrand3Task)
        
                        var getModel3Task = getAirModel(db, doc.data().air3_model_id, doc.data().air3_model).then((modelName) => {
                            model3 = modelName
                        })
                        promisesTask.push(getModel3Task)
                    }

                    Promise.all(promisesTask).then(() => {
                        boardAirPosts.push({
                            id: doc.id,
                            data: doc.data(),
                            brand1: brand1,
                            model1: model1,
                            brand2: brand2,
                            model2: model2,
                            brand3: brand3,
                            model3: model3,
                        })
                        setBoardAirPosts([...boardAirPosts], {
                            id: doc.id,
                            data: doc.data(),
                            brand1: brand1,
                            model1: model1,
                            brand2: brand2,
                            model2: model2,
                            brand3: brand3,
                            model3: model3,
                        })

                    })
                })
            })
        } catch (err) {
            console.error(err)
        }
    }

    const fetchBoardWash = async () => {
        try {
            boardWashPosts.splice(0, boardWashPosts.length)
            setBoardWashPosts([...boardWashPosts])
            const postQuery = query(collection(db, 'cleans'), and(where('onboard', '==', 2), or(where('status', '==', 1), where('status', '==', 2))), orderBy('post_timestamp', 'desc'), limit(6))
            onSnapshot(postQuery, (querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    var brand1 = 'ไม่ระบุ'
                    var model1 = 'ไม่ระบุ'
                    var brand2 = 'ไม่ระบุ'
                    var model2 = 'ไม่ระบุ'
                    var promisesTask = []
                    if(doc.data().wash_type1) {
                        var getBrand1Task = getWashBrand(db, doc.data().wash1_brand_id, doc.data().wash1_brand).then((brandName) => {
                            brand1 = brandName
                        })
                        promisesTask.push(getBrand1Task)
        
                        var getModel1Task = getWashModel(db, doc.data().wash1_model_id, doc.data().wash1_model).then((modelName) => {
                            model1 = modelName
                        })
                        promisesTask.push(getModel1Task)
                    }
                    if(doc.data().wash_type2) {
                        var getBrand2Task = getWashBrand(db, doc.data().wash2_brand_id, doc.data().wash2_brand).then((brandName) => {
                            brand2 = brandName
                        })
                        promisesTask.push(getBrand2Task)
        
                        var getModel2Task = getWashModel(db, doc.data().wash2_model_id, doc.data().wash2_model).then((modelName) => {
                            model2 = modelName
                        })
                        promisesTask.push(getModel2Task)
                    }

                    Promise.all(promisesTask).then(() => {
                        boardWashPosts.push({
                            id: doc.id,
                            data: doc.data(),
                            brand1: brand1,
                            model1: model1,
                            brand2: brand2,
                            model2: model2,
                        })
                        setBoardWashPosts([...boardWashPosts], {
                            id: doc.id,
                            data: doc.data(),
                            brand1: brand1,
                            model1: model1,
                            brand2: brand2,
                            model2: model2,
                        })

                    })
                })
            })
        } catch (err) {
            console.error(err)
        }
    }

    const fetchAdsPosition1 = () => {
        adsPosition1List.splice(0, adsPosition1List.length)
        setAdsPosition1List([...adsPosition1List])
        getDocs(query(collection(db, `ads/active/position_1`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[0]))).then((result) => {
            result.forEach(async(ads) => {
                getDoc(doc(db, `posts/`, ads.data().post_id)).then((postDoc) => {
                    var brand = 'ไม่ระบุ'
                    var model = 'ไม่ระบุ'
                    var promisesTask = []
                    var getBrandTask = getBrand(db, postDoc.data().brand_id, postDoc.data().brand).then((brandName) => {
                        brand = brandName
                    })
                    promisesTask.push(getBrandTask)
    
                    var getModelTask = getModel(db, postDoc.data().model_id, postDoc.data().model).then((modelName) => {
                        model = modelName
                    })
                    promisesTask.push(getModelTask)

                    Promise.all(promisesTask).then(() => {
                        adsPosition1List.push({
                            id: ads.id,
                            data: ads.data(),
                            post: postDoc.data(),
                            brand: brand,
                            model: model
                        })
                        setAdsPosition1List([...adsPosition1List], {
                            id: ads.id,
                            data: ads.data(),
                            post: postDoc.data(),
                            brand: brand,
                            model: model
                        })
                    })
                })
            })
        })
        /* onSnapshot(query(collection(db, `ads/active/position_1`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[0])), (result) => {
            result.forEach(async(ads) => {
                getDoc(doc(db, `posts/`, ads.data().post_id)).then((postDoc) => {
                    var brand = 'ไม่ระบุ'
                    var model = 'ไม่ระบุ'
                    var promisesTask = []
                    var getBrandTask = getBrand(db, postDoc.data().brand_id, postDoc.data().brand).then((brandName) => {
                        brand = brandName
                    })
                    promisesTask.push(getBrandTask)
    
                    var getModelTask = getModel(db, postDoc.data().model_id, postDoc.data().model).then((modelName) => {
                        model = modelName
                    })
                    promisesTask.push(getModelTask)

                    Promise.all(promisesTask).then(() => {
                        adsPosition1List.push({
                            id: ads.id,
                            data: ads.data(),
                            post: postDoc.data(),
                            brand: brand,
                            model: model
                        })
                        setAdsPosition1List([...adsPosition1List], {
                            id: ads.id,
                            data: ads.data(),
                            post: postDoc.data(),
                            brand: brand,
                            model: model
                        })
                    })
                })
            })
        }) */
    }

    const fetchAdsPosition2 = () => {
        adsPosition2List.splice(0, adsPosition2List.length)
        setAdsPosition2List([...adsPosition2List])
        //onSnapshot(query(collection(db, `ads/active/position_2`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[1])), (result) => {
        getDocs(query(collection(db, `ads/active/position_2`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[1]))).then((result) => {
            result.forEach(async(ads) => {
                getDoc(doc(db, `posts/`, ads.data().post_id)).then(async(postDoc) => {
                    var brand = 'ไม่ระบุ'
                    var model = 'ไม่ระบุ'
                    var promisesTask = []
                    var getBrandTask = getBrand(db, postDoc.data().brand_id, postDoc.data().brand).then((brandName) => {
                        brand = brandName
                    })
                    promisesTask.push(getBrandTask)
    
                    var getModelTask = getModel(db, postDoc.data().model_id, postDoc.data().model).then((modelName) => {
                        model = modelName
                    })
                    promisesTask.push(getModelTask)

                    var images = []
                    var imagesTask = await getDocs(query(collection(db, `posts/${ads.data().post_id}/images`), limit(3))).then((imageDocs) => {
                        imageDocs.docs.forEach((image) => {
                            images.push({
                                id: image.id,
                                data: image.data()
                            })
                        })
                    })
                    promisesTask.push(imagesTask)

                    Promise.all(promisesTask).then(() => {
                        adsPosition2List.push({
                            id: ads.id,
                            data: ads.data(),
                            post: postDoc.data(),
                            brand: brand,
                            model: model,
                            images: images
                        })
                        setAdsPosition2List([...adsPosition2List], {
                            id: ads.id,
                            data: ads.data(),
                            post: postDoc.data(),
                            brand: brand,
                            model: model,
                            images: images
                        })
                    })
                })
            })
        })
    }

    const fetchAdsPosition3 = () => {
        adsPosition3List.splice(0, adsPosition3List.length)
        setAdsPosition3List([...adsPosition3List])
        //onSnapshot(query(collection(db, `ads/active/position_3`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[2])), (result) => {
        getDocs(query(collection(db, `ads/active/position_3`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[2]))).then((result) => {
            result.forEach(async(ads) => {
                getDoc(doc(db, `shops/`, ads.data().shop_id)).then(async(shopDoc) => {
                    var province = 'ไม่ระบุ'
                    var district = 'ไม่ระบุ'
                    var promisesTask = []

                    if(shopDoc.data().province) {
                        var provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', shopDoc.data().province * 1))).then((docs) => {
                            if(docs.docs.length > 0) { province = docs.docs[0].data().province_name }
                        })
                        promisesTask.push(provinceTask)
                    }

                    if(shopDoc.data().district) {
                        var districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', shopDoc.data().district))).then((docs) => {
                            if(docs.docs.length > 0) { district = docs.docs[0].data().district_name }
                        })
                        promisesTask.push(districtTask)
                    }

                    var images = []
                    var imagesTask = await getDocs(query(collection(db, `shops/${ads.data().shop_id}/images`))).then((imageDocs) => {
                        imageDocs.docs.forEach((image) => {
                            images.push({
                                id: image.id,
                                data: image.data()
                            })
                        })
                    })
                    promisesTask.push(imagesTask)

                    getDocs(query(collection(db, `appointments/`), where('shop_id', '==', ads.data().shop_id))).then((appointments) => {
                        Promise.all(promisesTask).then(() => {
                            adsPosition3List.push({
                                id: ads.id,
                                data: ads.data(),
                                shop: shopDoc.data(),
                                province: province,
                                district: district,
                                appointment: appointments.docs.length,
                                images: images
                            })
                            setAdsPosition3List([...adsPosition3List], {
                                id: ads.id,
                                data: ads.data(),
                                shop: shopDoc.data(),
                                province: province,
                                district: district,
                                appointment: appointments.docs.length,
                                images: images
                            })
                        })
                    })
                })
            })
        })
    }

    const fetchAdsPosition4 = () => {
        adsPosition4List.splice(0, adsPosition4List.length)
        setAdsPosition4List([...adsPosition4List])
        //onSnapshot(query(collection(db, `ads/active/position_4`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[3])), (result) => {
        getDocs(query(collection(db, `ads/active/position_4`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[3]))).then((result) => {
            result.forEach(async(ads) => {
                getDoc(doc(db, `shops/`, ads.data().shop_id)).then(async(shopDoc) => {
                    var province = 'ไม่ระบุ'
                    var district = 'ไม่ระบุ'
                    var promisesTask = []

                    if(shopDoc.data().province) {
                        var provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', shopDoc.data().province * 1))).then((docs) => {
                            if(docs.docs.length > 0) { province = docs.docs[0].data().province_name }
                        })
                        promisesTask.push(provinceTask)
                    }

                    if(shopDoc.data().district) {
                        var districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', shopDoc.data().district))).then((docs) => {
                            if(docs.docs.length > 0) { district = docs.docs[0].data().district_name }
                        })
                        promisesTask.push(districtTask)
                    }

                    var images = []
                    var imagesTask = await getDocs(query(collection(db, `shops/${ads.data().shop_id}/images`))).then((imageDocs) => {
                        imageDocs.docs.forEach((image) => {
                            images.push({
                                id: image.id,
                                data: image.data()
                            })
                        })
                    })
                    promisesTask.push(imagesTask)

                    getDocs(query(collection(db, `appointments/`), where('shop_id', '==', ads.data().shop_id))).then((appointments) => {
                        Promise.all(promisesTask).then(() => {
                            adsPosition4List.push({
                                id: ads.id,
                                data: ads.data(),
                                shop: shopDoc.data(),
                                province: province,
                                district: district,
                                appointment: appointments.docs.length,
                                images: images
                            })
                            setAdsPosition4List([...adsPosition4List], {
                                id: ads.id,
                                data: ads.data(),
                                shop: shopDoc.data(),
                                province: province,
                                district: district,
                                appointment: appointments.docs.length,
                                images: images
                            })
                        })
                    })
                })
            })
        })
    }

    const fetchAdsPosition5 = () => {
        adsPosition5List.splice(0, adsPosition5List.length)
        setAdsPosition5List([...adsPosition5List])
        //onSnapshot(query(collection(db, `ads/active/position_5`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[4])), (result) => {
        getDocs(query(collection(db, `ads/active/position_5`), where('status', '==', 1), orderBy('active_date', 'asc'), limit(adsPositionAmount[4]))).then((result) => {
            result.forEach(async(ads) => {
                getDoc(doc(db, `shops/`, ads.data().shop_id)).then(async(shopDoc) => {
                    var province = 'ไม่ระบุ'
                    var district = 'ไม่ระบุ'
                    var promisesTask = []

                    if(shopDoc.data().province) {
                        var provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', shopDoc.data().province * 1))).then((docs) => {
                            if(docs.docs.length > 0) { province = docs.docs[0].data().province_name }
                        })
                        promisesTask.push(provinceTask)
                    }

                    if(shopDoc.data().district) {
                        var districtTask = getDocs(query(collection(db, 'distrincts'), where('district_id', '==', shopDoc.data().district))).then((docs) => {
                            if(docs.docs.length > 0) { district = docs.docs[0].data().district_name }
                        })
                        promisesTask.push(districtTask)
                    }

                    var images = []
                    var imagesTask = await getDocs(query(collection(db, `shops/${ads.data().shop_id}/images`))).then((imageDocs) => {
                        imageDocs.docs.forEach((image) => {
                            images.push({
                                id: image.id,
                                data: image.data()
                            })
                        })
                    })
                    promisesTask.push(imagesTask)

                    getDocs(query(collection(db, `appointments/`), where('shop_id', '==', ads.data().shop_id))).then((appointments) => {
                        Promise.all(promisesTask).then(() => {
                            adsPosition5List.push({
                                id: ads.id,
                                data: ads.data(),
                                shop: shopDoc.data(),
                                province: province,
                                district: district,
                                appointment: appointments.docs.length,
                                images: images
                            })
                            setAdsPosition5List([...adsPosition5List], {
                                id: ads.id,
                                data: ads.data(),
                                shop: shopDoc.data(),
                                province: province,
                                district: district,
                                appointment: appointments.docs.length,
                                images: images
                            })
                        })
                    })
                })
            })
        })
    }

    const fetchTopTenShop = () => {
        topTenShopList.splice(0, topTenShopList.length)
        setTopTenShopList([...topTenShopList])
        onSnapshot(query(collection(db, `shops`), orderBy('score_average', 'desc'), limit(10)), (results) => {
            results.forEach((shop) => {
                var promisesTask = []
                var province = 'ไม่ระบุ'
                const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', shop.data().province * 1))).then((docs) => {
                    if(docs.docs.length > 0) { province =docs.docs[0].data().province_name }
                })
                promisesTask.push(provinceTask)

                Promise.all(promisesTask).then(() => {
                    topTenShopList.push({
                        id: shop.id,
                        data: shop.data(),
                        province: province
                    })
                    setTopTenShopList([...topTenShopList], {
                        id: shop.id,
                        data: shop.data(),
                        province: province
                    })
                })
            })
        })
    }

    const fetchTopTenShopByProvince = () => {
        topTenShopList.splice(0, topTenShopList.length)
        setTopTenShopList([...topTenShopList])
        getDocs(query(collection(db, "users"), where("uid", "==", user.uid))).then((userDocs) => {
            if (userDocs.docs.length === 1) {
                const data = userDocs.docs[0].data()
                if(data.province) {
                    var promisesTask = []
                    var province = 'ไม่ระบุ'
                    const provinceTask = getDocs(query(collection(db, 'provinces'), where('province_id', '==', data.province))).then((docs) => {
                        if(docs.docs.length > 0) { province =docs.docs[0].data().province_name }
                    })
                    promisesTask.push(provinceTask)

                    Promise.all(promisesTask).then(() => {
                        onSnapshot(query(collection(db, `shops`), where('province', '==', data.province), orderBy('score_average', 'desc'), limit(10)), (results) => {
                            if(results.empty) {
                                fetchTopTenShop()
                            } else {
                                results.forEach((shop) => {
                                    topTenShopList.push({
                                        id: shop.id,
                                        data: shop.data(),
                                        province: province
                                    })
                                    setTopTenShopList([...topTenShopList], {
                                        id: shop.id,
                                        data: shop.data(),
                                        province: province
                                    })
                                })
                            }
                        })
                    })
                } else {
                    fetchTopTenShop()
                }
            }
        })
    }
    
    const dateFormat = (datetime) => {
        const date_string = new Date(datetime).toLocaleDateString("th-TH")
        return date_string
    }

    useEffect(() => {
        const resizeDiv = new ResizeObserver(
            (event) => {
                setWidth(event[0].contentBoxSize[0].inlineSize-1)
                setHeight(imagePostHeight)
            }
        )
        resizeDiv.observe(document.getElementById("divPost"))

        const resizeDivAds1 = new ResizeObserver((event) => {
            setWidthAds1(event[0].contentBoxSize[0].inlineSize)
            //setHeight(event[0].contentBoxSize[0].blockSize)
        })
        resizeDivAds1.observe(document.getElementById("divAds1"))

        const resizeDivAds2 = new ResizeObserver((event) => {
            setWidthAds2(event[0].contentBoxSize[0].inlineSize)
            //setHeight(event[0].contentBoxSize[0].blockSize)
        })
        resizeDivAds2.observe(document.getElementById("divAds2"))

        const resizeDivImageShop = new ResizeObserver((event) => {
            setWidthImageShop(event[0].contentBoxSize[0].inlineSize)
            //setHeight(event[0].contentBoxSize[0].blockSize)
        })
        resizeDivImageShop.observe(document.getElementById("divImageShop"))

        const resizeDivSlideAds = new ResizeObserver((event) => {
            setWidthSlide(event[0].contentBoxSize[0].inlineSize)
            //setHeight(event[0].contentBoxSize[0].blockSize)
        })
        resizeDivSlideAds.observe(document.getElementById("slideAds"))
    })

    const clickPostAds = (position, id) => {
        getDoc(doc(db, `ads/active/position_${position}`, id)).then(async(adsDoc) => {
            var click = 1
            if(adsDoc.data().click) {
                click = adsDoc.data().click + 1
            }
            updateDoc(doc(db, `ads/active/position_${position}`, id), {
                click: click
            }).then(() => {
                navigate(`/post/${adsDoc.data().post_id}`)
            })
        })
    }

    const clickShopAds = (position, id) => {
        getDoc(doc(db, `ads/active/position_${position}`, id)).then(async(adsDoc) => {
            var click = 1
            if(adsDoc.data().click) {
                click = adsDoc.data().click + 1
            }
            updateDoc(doc(db, `ads/active/position_${position}`, id), {
                click: click
            }).then(() => {
                navigate(`/shop/history/${adsDoc.data().shop_id}`)
            })
        })
    }

    useEffect(() => {
        const imagesSnapshot =  query(collection(db, `slide_ads`), orderBy('position', 'asc'))
        onSnapshot(imagesSnapshot, (querySnapshot) => {
            querySnapshot.docs.forEach((doc) => {
                const img = new Image()
                img.src = doc.data().url
                img.onload = () => {
                    //console.log(img.height)
                    //console.log(img.width)
                }
            })
            setSlideImages(querySnapshot.docs.map((doc, i) => ({
                id: doc.id,
                url: doc.data().url,
                file: "",
                status: 1
            })))
        })
    }, [])

    useEffect(() => {
        if (!initialized.current) {
            initialized.current = true
            
            if (user) {
                fetchTopTenShopByProvince()
            } else {
                fetchTopTenShop()
            }
            fetchBoard3()
            fetchBoard2()
            fetchBoard1()
            fetchBoard4()
            fetchBoardAir()
            fetchBoardWash()

            fetchAdsPosition1()
            fetchAdsPosition2()
            fetchAdsPosition3()
            fetchAdsPosition4()
            fetchAdsPosition5()
        }

    }, [user])

    return (
        <div className="container-fluid" style={{ marginTop: 20 }}>
            <div className="flex-container" style={{ marginBottom: 15 }}>
                <div className='slide-container' id='slideAds' style={{ display: 'flex', flexDirection: 'column', flex: flexLeft, marginLeft: 10 }}>
                    {slideImages.length > 0 &&
                        <Slide slidesToScroll={1} slidesToShow={1} indicators={true} responsive={responsiveSettings}>
                            {slideImages.map((slideImage, index)=> (
                            <div key={index}>
                                <div style={{ ...divStyle, 'backgroundImage': `url(${slideImage.url})`, backgroundSize: window.innerWidth < 920?'100% auto':'auto 100%', backgroundRepeat: 'no-repeat', backgroundPosition: 'center' }}></div>
                            </div>
                            ))} 
                        </Slide>
                        }
                </div>
                <div style={{ flex: flexRight, paddingTop: 10 }}>
                    <div className="flex-container-row">
                        <div className="flex-row" style={{ marginBottom: 10, minHeight: 87 }}>
                            <div style={{ width: iconSizeLaw, marginRight: 15 }}>
                                <TbHexagonNumber2 style={{ height: iconSizeLaw, width: iconSizeLaw }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <font style={{ fontSize: fontSizeHeadLaw, fontWeight: '600', color: '#3D5598' }}>ซื้อขายเครื่องใช้ไฟฟ้ามือสอง</font><br/>
                                <font style={{ fontSize: fontSizeDetailLaw, color: '#626167' }}><a href='/service1'>รายละเอียดการให้บริการ</a></font>
                            </div>
                        </div>
                        <div className="flex-row" style={{ marginBottom: 10, minHeight: 87 }}>
                            <div style={{ width: iconSizeLaw, marginRight: 15 }}>
                                <BiMoneyWithdraw style={{ height: iconSizeLaw, width: iconSizeLaw }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <font style={{ fontSize: fontSizeHeadLaw, fontWeight: '600', color: '#3D5598' }}>ทิ้ง/ซื้อขายซากเครื่องใช้ไฟฟ้า</font><br/>
                                <font style={{ fontSize: fontSizeDetailLaw, color: '#626167' }}><a href='/service2'>รายละเอียดการให้บริการ</a></font>
                            </div>
                        </div>
                    </div>
                    <div className="flex-container-row">
                        <div className="flex-row" style={{ marginBottom: 10, flex: 1, minHeight: 87 }}>
                            <div style={{ width: iconSizeLaw, marginRight: 15 }}>
                                <BiWrench style={{ height: iconSizeLaw, width: iconSizeLaw }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <font style={{ fontSize: fontSizeHeadLaw, fontWeight: '600', color: '#3D5598' }}>แจ้งความต้องการซ่อมเครื่องใช้ไฟฟ้า</font><br/>
                                <font style={{ fontSize: fontSizeDetailLaw, color: '#626167' }}><a href='/service3'>รายละเอียดการให้บริการ</a></font>
                            </div>
                        </div>
                        <div className="flex-row" style={{ marginBottom: 10, flex: 1, minHeight: 87 }}>
                            <div style={{ width: iconSizeLaw, marginRight: 15 }}>
                                <CgSmartHomeWashMachine style={{ height: iconSizeLaw, width: iconSizeLaw }} />
                            </div>
                            <div style={{ flexDirection: 'column' }}>
                                <font style={{ fontSize: fontSizeHeadLaw, fontWeight: '600', color: '#3D5598' }}>แจ้งความต้องการล้างแอร์/เครื่องซักผ้า</font><br/>
                                <font style={{ fontSize: fontSizeDetailLaw, color: '#626167' }}><a href='/service4'>รายละเอียดการให้บริการ</a></font>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div id="divAds1" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'stretch', backgroundColor: '#ffffff' }}>
                {adsPosition1List?.map((ads, i) => (
                    <div style={{ display: 'flex', flexDirection: 'column', width: (widthAds1/limitRow), padding: 5, marginBottom: 5, cursor: 'pointer' }} key={ads.id} onClick={() => { clickPostAds(1, ads.id) }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                            <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: ads1Color[i] }}>
                                    <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (widthAds1/limitRow), height: ((widthAds1*0.7)/limitRow) }} src={ads.post.picture} alt="APP3R" />
                                </div>
                            </div>

                            {/* <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                <div style={{ display: 'flex', flexDirection: 'column', backgroundColor: ads1Color[i], flex: 1, paddingLeft: 20, paddingRight: 20, alignContent: 'center', alignItems: 'center' }}>
                                    <img style={{ display: 'flex', objectFit: "cover", borderRadius: 15, maxWidth: (widthAds1*0.7/limitRow), height: (widthAds1*0.7/limitRow), marginTop: 10 }} src={ads.post.picture} alt="APP3R" />
                                </div>
                            </div> */}

                            <div style={{ alignContent: 'center', alignItems: 'stretch', flexDirection: 'row', backgroundColor: ads1Color[i], padding: 15, flex: 1 }}>
                                <div className="cut-text text-15-ch" style={{ fontSize: 14 }}>
                                    <font style={{ fontSize: 14, fontWeight: '400', color: '#3D5598' }}>ยี่ห้อ: {ads.brand} รุ่น: {ads.model} </font> <br/>
                                    <font style={{ fontSize: 14, color: '#984740' }}>
                                        <font style={{ fontWeight: '400' }}>ราคาขาย:</font> ฿{number_format.format(ads.post.price)} 
                                        <font style={{ marginLeft: 10 }}>สภาพ: 
                                        {ads.post.condition?"":" ไม่ระบุ"}
                                        {ads.post.condition==1?" มือสองเหมือนใหม่":""}
                                        {ads.post.condition==2?" มือสองสภาพดี":""}
                                        {ads.post.condition==3?" มือสองพอใช้":""}
                                        {ads.post.condition==4?" มือสองเก่ามาก":""} </font>
                                    </font> <br/>
                                    <font style={{ fontSize: 14, color: '#626167' }}>วันที่ประกาศ: {dateFormat(ads.post.post_timestamp)}</font>
                                </div>
                            </div>
                        </div>
                    </div>
                ))}
            </div>
            <div className="flex-container">
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div className='flex-to-column' style={{ marginRight: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexLeft }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, flex: 1, alignItems: 'end' }}><font style={{ fontSize: fontSizeHeadPost, fontWeight: '600', color: '#3D5598' }}><b>ขายเครื่องใช้ไฟฟ้ามือสอง</b></font></div>
                                <div style={{ display: 'flex', flexDirection: 'row', wordWrap: 'unset', alignItems: 'end', alignSelf: 'end' }}><font style={{ fontSize: fontSizeViewAllPost, color: '#3D5598' }}><a href='/posts/board/3'>[ดูทั้งหมด]</a></font></div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexRight }}>
                            
                        </div>
                    </div>
                    <div className='flex-to-column'>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexLeft }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 15, marginBottom: 15 }}>
                                <div id="divPost" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'stretch', alignContent: 'space-between', backgroundColor: '#F2F2F2', paddingTop: 10 }}>
                                    {board3Posts?.map((post, i) => (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', width: (width/limitRowPost), padding: 5, marginBottom: 5, cursor: 'pointer' }} key={post.id} onClick={() => navigate(`/post/${post.id}`)}>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#ffffff', borderRadius: 15 }}>
                                                <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (width/limitRowPost), height: ((width*(flexLeft/10))/limitRowPost) }} src={post.data.picture} alt="APP3R" />
                                                    </div>
                                                </div>
                                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                                        <p className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </p>
                                                        <font style={{ fontSize: 14, color: '#984740' }}>
                                                            <font style={{ fontWeight: '400' }}>ราคาขาย:</font> ฿{number_format.format(post.data.price)}  
                                                            <font style={{ marginLeft: 10 }}>สภาพ: 
                                                            {post.data.condition?"":" ไม่ระบุ"}
                                                            {post.data.condition==1?" มือสองเหมือนใหม่":""}
                                                            {post.data.condition==2?" มือสองสภาพดี":""}
                                                            {post.data.condition==3?" มือสองพอใช้":""}
                                                            {post.data.condition==4?" มือสองเก่ามาก":""} </font>
                                                        </font>
                                                        <font style={{ fontSize: 14, color: '#626167' }}>วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 15, marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, flex: 1, alignItems: 'end' }}><font style={{ fontSize: fontSizeHeadPost, fontWeight: '600', color: '#3D5598' }}><b>ขายซากเครื่องใช้ไฟฟ้า</b></font></div>
                                    <div style={{ display: 'flex', flexDirection: 'row', wordWrap: 'unset', alignItems: 'end' }}><font style={{ fontSize: fontSizeViewAllPost, color: '#3D5598' }}><a href='/posts/board/2'>[ดูทั้งหมด]</a></font></div>
                                </div>
                                <div id="divPost" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', alignContent: 'space-between', backgroundColor: '#D2DEFF', paddingTop: 10 }}>
                                    {board2Posts?.map((post, i) => (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', width: (width/limitRowPost), padding: 5, marginBottom: 5, cursor: 'pointer' }} key={post.id} onClick={() => navigate(`/post/${post.id}`)}>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#ffffff', borderRadius: 15 }}>
                                                <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (width/limitRowPost), height: ((width*(flexLeft/10))/limitRowPost) }} src={post.data.picture} alt="APP3R" />
                                                    </div>
                                                </div>
                                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                                        <p className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </p>
                                                        <font style={{ fontSize: 14, color: '#984740' }}>
                                                            <font style={{ fontWeight: '600' }}>ราคาขาย:</font> ฿{number_format.format(post.data.price)} 
                                                        </font>
                                                        <font style={{ fontSize: 14, color: '#626167' }}>วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 15, marginBottom: 15 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, flex: 1, alignItems: 'end' }}><font style={{ fontSize: fontSizeHeadPost, fontWeight: '600', color: '#3D5598' }}><b>ทิ้งซากเครื่องใช้ไฟฟ้า</b></font></div>
                                    <div style={{ display: 'flex', flexDirection: 'row', wordWrap: 'unset', alignItems: 'end' }}><font style={{ fontSize: fontSizeViewAllPost, color: '#3D5598' }}><a href='/posts/board/1'>[ดูทั้งหมด]</a></font></div>
                                </div>
                                <div id="divPost" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', alignContent: 'space-between', backgroundColor: '#C1E6C0', paddingTop: 10 }}>
                                    {board1Posts?.map((post, i) => (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', width: (width/limitRowPost), padding: 5, marginBottom: 5, cursor: 'pointer' }} key={post.id} onClick={() => navigate(`/post/${post.id}`)}>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#ffffff', borderRadius: 15 }}>
                                                <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (width/limitRowPost), height: ((width*(flexLeft/10))/limitRowPost) }} src={post.data.picture} alt="APP3R" />
                                                    </div>
                                                </div>
                                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                                        <p className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </p>
                                                        <font style={{ fontSize: 14, color: '#984740' }}>
                                                            <font style={{ fontWeight: '600' }}>จังหวัด:</font> {post.province} <font style={{ fontWeight: '600' }}>อำเภอ:</font> {post.district}
                                                        </font>
                                                        <font style={{ fontSize: 14, color: '#626167' }}>วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}</font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexRight }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ flexDirection: 'column', backgroundColor: '#FDEBE9', color: '#FF6F62', alignItems: 'center', alignContent: 'center' }}>
                                    <div style={{ textAlign: 'center' }}><font style={{ fontSize: fontSizeHeadSale, fontWeight: '500' }}>Sale</font></div>
                                    <div style={{ textAlign: 'center', marginBottom: 10 }}><font style={{ fontSize: fontSizeDetailSale }}>เครื่องใช้ไฟฟ้ามือสอง</font></div>
                                </div>
                                <div id="divAds2" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                    {adsPosition2List?.map((ads, i) => (
                                    <div key={ads.id} style={{ display: 'flex', flexDirection: 'column', backgroundColor: ads2Color[i], paddingTop: 5, marginBottom: 10, cursor: 'pointer' }} onClick={() => { clickPostAds(2, ads.id) }}>
                                        <div id="divAds2" style={{ display: 'flex', flexDirection: 'row', padding: 5 }}>
                                        {ads.images?.map((image, j) => (
                                            <div key={j} className={(window.innerWidth < 920 && j > 0)?"":""} style={{ display: 'flex', flexDirection: 'column', backgroundColor: '#ffffff', flex: 1, paddingLeft: 10, paddingRight: 10 }}>
                                                <img style={{ display: 'flex', objectFit: "contain", maxWidth: (widthAds2/((onMobile && j > 0)?3:1)), height: heightAds2, marginTop: 10, marginBottom: 10 }} src={image.data.url} alt="APP3R" />
                                            </div>
                                        ))}
                                        </div>
                                        <div style={{ marginTop: 5, marginBottom: 5, display: 'flex', flexDirection: 'row' }}>
                                            <div className="cut-text" style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10, fontSize: 14 }}>
                                                <font style={{ fontWeight: '400', color: '#3D5598' }}>
                                                    ยี่ห้อ: {ads.brand}<br/>
                                                    รุ่น: {ads.model} 
                                                </font>
                                                <font style={{ color: '#984740' }}>
                                                    <font style={{ fontWeight: '400' }}>ราคา:</font> ฿{number_format.format(ads.post.price)} 
                                                </font>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10, fontSize: 14 }}>
                                                <font style={{ color: '#984740' }}>
                                                    สภาพ: 
                                                    {ads.post.condition?"":" ไม่ระบุ"}
                                                    {ads.post.condition==1?" มือสองเหมือนใหม่":""}
                                                    {ads.post.condition==2?" มือสองสภาพดี":""}
                                                    {ads.post.condition==3?" มือสองพอใช้":""}
                                                    {ads.post.condition==4?" มือสองเก่ามาก":""} 
                                                </font>
                                                <font style={{ color: '#626167' }}>
                                                    ปีที่ซื้อ: {ads.post.buy_year} <br/>
                                                    วันที่ประกาศ: {dateFormat(ads.post.post_timestamp)}
                                                </font>
                                            </div>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, paddingLeft: 10, paddingRight: 10, fontSize: 14 }}>
                                                <font style={{ color: '#626167' }}>
                                                    รายละเอียด: {ads.post.description}
                                                </font>
                                            </div>
                                        </div>
                                    </div>
                                    ))}
                                </div>
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column', marginBottom: 10 }}>
                                <div style={{ flexDirection: 'column', backgroundColor: '#FDEBE9', alignItems: 'center', alignContent: 'center', padding: 10 }}>
                                    <div style={{ textAlign: 'center' }}><font style={{ fontSize: 18, fontWeight: '600' }}>10 อันดับร้านที่ได้รับคะแนนประเมินสูงสุด</font></div>
                                </div>
                                {topTenShopList?.map((shop, i) => (
                                <div key={i} className="flex-container cut-text" style={{ padding: 5, fontSize: 14, marginLeft: 10 }}>
                                    <font>{i+1}. ร้าน/บริษัท: {(shop.data.type == 2)?shop.data.shopname:shop.data.firstname+" "+shop.data.lastname} </font>
                                    <font style={{ color: '#984740', fontWeight: '600', marginLeft: 10 }}>คะแนน: {number_format.format(shop.data.score_average)} </font>
                                    <font className="d-none d-md-block" style={{ color: '#3D5598', marginLeft: 10 }}> จังหวัด: {shop.province}</font>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-container">
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div className='flex-to-column' style={{ marginRight: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexLeft }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, flex: 1, alignItems: 'end' }}><font style={{ fontSize: fontSizeHeadPost, fontWeight: '600', color: '#3D5598' }}><b>แจ้งซ่อมความต้องการซ่อมเครื่องใช้ไฟฟ้า</b></font></div>
                                <div style={{ display: 'flex', flexDirection: 'row', wordWrap: 'unset', alignItems: 'end' }}><font style={{ fontSize: fontSizeViewAllPost, color: '#3D5598' }}><a href='/posts/boardfix'>[ดูทั้งหมด]</a></font></div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexRight }}>
                            
                        </div>
                    </div>
                    <div className='flex-to-column'>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexLeft }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 15, marginBottom: 15 }}>
                                <div id="divPost" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', alignContent: 'space-between', backgroundColor: '#FDEBE9', paddingTop: 10 }}>
                                    {boardFixPosts?.map((post, i) => (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', width: (width/limitRowPost), padding: 5, marginBottom: 5, cursor: 'pointer' }} key={post.id} onClick={() => navigate(`/postfix/${post.id}`)}>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#ffffff', borderRadius: 15 }}>
                                                <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (width/limitRowPost), height: ((width*(flexLeft/10))/limitRowPost) }} src={post.data.picture} alt="APP3R" />
                                                    </div>
                                                </div>
                                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                                        <p className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>ยี่ห้อ: {post.brand} รุ่น: {post.model} </p>
                                                        <font style={{ fontSize: 14, color: '#984740' }}>
                                                            <font style={{ fontWeight: '600' }}>ราคาขาย:</font> ฿{number_format.format(post.data.price)}  
                                                        </font>
                                                        <font style={{ fontSize: 14, color: '#626167' }}>
                                                            อาการ: {post.broken}<br/>
                                                            รูปแบบการซ่อม: 
                                                            {post.fix_type_home?"บริการมาซ่อมในที่พักอาศัย":""}
                                                            {post.fix_type_shop?"บริการไปรับกลับมาซ่อมที่ร้าน":""}
                                                            {post.fix_type_send?"ยกไปซ่อมที่ร้าน":""}
                                                            <br/>
                                                            วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}
                                                        </font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexRight }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ flexDirection: 'column', backgroundColor: '#FDEBE9', alignItems: 'center', alignContent: 'center', padding: 10 }}>
                                    <div style={{ textAlign: 'center' }}><font style={{ fontSize: fontSizeHeadSide, fontWeight: '600', color: '#FF6F62' }}>รวมช่าง</font></div>
                                </div>
                                <div id="divImageShop" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {adsPosition3List?.map((shop, i) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} key={i} onClick={() => { clickShopAds(3, shop.id) }}>
                                        <div style={{ flex: 3 }}>
                                            {(shop.images.length > 0) && 
                                            <img style={{ display: 'flex', objectFit: "contain", maxWidth: (widthImageShop/3), height: heightImageShop }} src={shop.images[0].data.url} alt="APP3R" />
                                            }
                                            {(shop.images.length == 0) && 
                                            <img style={{ display: 'flex', objectFit: "contain", maxWidth: (widthImageShop/3), height: heightImageShop }} src={noImage} alt="APP3R" />
                                            }
                                        </div>
                                        <div key={i} className="cut-text text-30-ch" style={{ padding: 5, flexDirection: 'row', fontSize: 14, marginTop: 5, marginLeft: 10, flexWrap: 'wrap', flex: 7 }}>
                                            <font style={{ color: '#3D5598', fontWeight: '600' }}>ร้าน/บริษัท: {(shop.shop.type == 2)?shop.shop.shopname:shop.shop.firstname+" "+shop.shop.lastname} </font><br/>
                                            <font style={{ color: '#3D5598', fontWeight: '600' }}>ที่ตั้ง: {shop.province} {shop.district}</font><br/>
                                            <font style={{ color: '#984740', fontWeight: '600' }}>คะแนน: {number_format.format(shop.shop.score_average?shop.shop.score_average:0)} </font><br/>
                                            <font style={{ color: '#626167' }}>ให้บริการไปแล้ว: {number_format.format(shop.appointment)} </font>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-container">
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div className='flex-to-column' style={{ marginRight: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexLeft }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, flex: 1, alignItems: 'end' }}><font style={{ fontSize: fontSizeHeadPost, fontWeight: '600', color: '#3D5598' }}><b>แจ้งความต้องการล้างแอร์</b></font></div>
                                <div style={{ display: 'flex', flexDirection: 'row', wordWrap: 'unset', alignItems: 'end' }}><font style={{ fontSize: fontSizeViewAllPost, color: '#3D5598' }}><a href='/posts/boardair'>[ดูทั้งหมด]</a></font></div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexRight }}>
                            
                        </div>
                    </div>
                    <div className='flex-to-column'>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexLeft }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 15, marginBottom: 15 }}>
                                <div id="divPost" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', alignContent: 'space-between', backgroundColor: '#DFE6FA', paddingTop: 10 }}>
                                    {boardAirPosts?.map((post, i) => (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', width: (width/limitRowPost), padding: 5, marginBottom: 5, cursor: 'pointer' }} key={post.id} onClick={() => navigate(`/postclean/${post.id}`)}>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#ffffff', borderRadius: 15 }}>
                                                <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (width/limitRowPost), height: ((width*(flexLeft/10))/limitRowPost) }} src={post.data.picture} alt="APP3R" />
                                                    </div>
                                                </div>
                                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                                        <div className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>
                                                            {post.data.have_air1 && (
                                                                <div><strong>บริการล้างแอร์ติดผนัง</strong> <br/>ยี่ห้อ {post.brand1} รุ่น: {post.model1}  <br/>ขนาด {number_format.format(post.data.air1_BTU)} BTU  <br/>จำนวน {post.data.air1_amount} เครื่อง</div>
                                                            )}
                                                            {post.data.have_air2 && (
                                                                <div><strong>บริการล้างแอร์แขวนใต้ฝ้า</strong> <br/>ยี่ห้อ {post.brand2} รุ่น: {post.model2}  <br/>ขนาด {number_format.format(post.data.air2_BTU)} BTU  <br/>จำนวน {post.data.air2_amount} เครื่อง</div>
                                                            )}
                                                            {post.data.have_air3 && (
                                                                <div><strong>บริการล้างแอร์ฝังฝ้า 4 ทิศทาง</strong> <br/>ยี่ห้อ {post.brand3} รุ่น: {post.model3}  <br/>ขนาด {number_format.format(post.data.air3_BTU)} BTU  <br/>จำนวน {post.data.air3_amount} เครื่อง</div>
                                                            )}
                                                        </div>
                                                        <font style={{ fontSize: 14, color: '#626167' }}>
                                                            วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}
                                                        </font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexRight }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ flexDirection: 'column', backgroundColor: '#FDEBE9', alignItems: 'center', alignContent: 'center', padding: 10 }}>
                                    <div style={{ textAlign: 'center' }}><font style={{ fontSize: fontSizeHeadSide, fontWeight: '600', color: '#FF6F62' }}>บริการล้างแอร์</font></div>
                                </div>
                                <div id="divImageShop" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {adsPosition4List?.map((shop, i) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} key={i} onClick={() => { clickShopAds(4, shop.id) }}>
                                        <div style={{ flex: 3 }}>
                                            {(shop.images.length > 0) && 
                                            <img style={{ display: 'flex', objectFit: "contain", maxWidth: (widthImageShop/3), height: heightImageShop }} src={shop.images[0].data.url} alt="APP3R" />
                                            }
                                            {(shop.images.length == 0) && 
                                            <img style={{ display: 'flex', objectFit: "contain", maxWidth: (widthImageShop/3), height: heightImageShop }} src={noImage} alt="APP3R" />
                                            }
                                        </div>
                                        <div key={i} className="cut-text text-30-ch" style={{ padding: 5, flexDirection: 'row', fontSize: 14, marginTop: 5, marginLeft: 10, flexWrap: 'wrap', flex: 7 }}>
                                            <font style={{ color: '#3D5598', fontWeight: '600' }}>ร้าน/บริษัท: {(shop.shop.type == 2)?shop.shop.shopname:shop.shop.firstname+" "+shop.shop.lastname} </font><br/>
                                            <font style={{ color: '#3D5598', fontWeight: '600' }}>ที่ตั้ง: {shop.province} {shop.district}</font><br/>
                                            <font style={{ color: '#984740', fontWeight: '600' }}>คะแนน: {number_format.format(shop.shop.score_average)} </font><br/>
                                            <font style={{ color: '#626167' }}>ให้บริการไปแล้ว: {number_format.format(shop.appointment)} </font>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="flex-container">
                <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                    <div className='flex-to-column' style={{ marginRight: 15 }}>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexLeft }}>
                            <div style={{ display: 'flex', flexDirection: 'row', marginBottom: 10 }}>
                                <div style={{ display: 'flex', flexDirection: 'row', marginLeft: 10, flex: 1, alignItems: 'end' }}><font style={{ fontSize: fontSizeHeadPost, fontWeight: '600', color: '#3D5598' }}><b>แจ้งความต้องการล้างเครื่องซักผ้า</b></font></div>
                                <div style={{ display: 'flex', flexDirection: 'row', wordWrap: 'unset', alignItems: 'end' }}><font style={{ fontSize: fontSizeViewAllPost, color: '#3D5598' }}><a href='/posts/boardwash'>[ดูทั้งหมด]</a></font></div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexRight }}>
                            
                        </div>
                    </div>
                    <div className='flex-to-column'>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexLeft }}>
                            <div style={{ display: 'flex', flexDirection: 'column', marginRight: 15, marginBottom: 15 }}>
                                <div id="divPost" style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', alignItems: 'center', alignContent: 'space-between', backgroundColor: '#FDEBE9', paddingTop: 10 }}>
                                    {boardWashPosts?.map((post, i) => (
                                        <div style={{ display: 'flex', flexDirection: 'column', alignSelf: 'stretch', width: (width/limitRowPost), padding: 5, marginBottom: 5, cursor: 'pointer' }} key={post.id} onClick={() => navigate(`/postwash/${post.id}`)}>
                                            <div style={{ display: 'flex', flexDirection: 'column', flex: 1, backgroundColor: '#ffffff', borderRadius: 15 }}>
                                                <div style={{ alignContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', flexDirection: 'column', flex: 1 }}>
                                                        <img style={{ display: 'flex', objectFit: "cover", borderTopLeftRadius: 15, borderTopRightRadius: 15, maxWidth: (width/limitRowPost), height: ((width*(flexLeft/10))/limitRowPost) }} src={post.data.picture} alt="APP3R" />
                                                    </div>
                                                </div>
                                                <div style={{ justifyContent: 'start', alignItems: 'stretch', flexDirection: 'row', padding: 15, flex: 1 }}>
                                                    <div className="cut-text text-15-ch" style={{ fontSize: 14, display: 'flex', flexDirection: 'column', alignSelf: 'flex-start', flex: 1 }}>
                                                        <div className="truncate-overflow" style={{ fontSize: 14, fontWeight: '400', color: '#3D5598', marginBottom: 0 }}>
                                                            {post.data.wash_type1 && (
                                                                <div>
                                                                    <strong>บริการล้างเครื่องซักผ้าฝาบน</strong> <br/>
                                                                    ยี่ห้อ {post.brand1} รุ่น: {post.model1}  <br/>
                                                                    ขนาด 
                                                                    {post.data.wash1_capacity == '1' && "ความจุ ไม่เกิน 15 กก."}
                                                                    {post.data.wash1_capacity == '2' && "ความจุ 15 - 19 กก."}
                                                                    {post.data.wash1_capacity == '3' && "ความจุ 19 - 22 กก."}
                                                                    {post.data.wash1_capacity == '4' && "ความจุ 22 กก. ขึ้นไป"}  <br/>
                                                                    จำนวน {post.data.wash1_amount} เครื่อง
                                                                </div>
                                                            )}
                                                            {post.data.wash_type2 && (
                                                                <div>
                                                                    <strong>บริการล้างเครื่องซักผ้าฝาหน้า</strong> <br/>
                                                                    ยี่ห้อ {post.brand2} รุ่น: {post.model2}  <br/>
                                                                    ขนาด 
                                                                    {post.data.wash2_capacity == '1' && "ความจุ ไม่เกิน 15 กก."}
                                                                    {post.data.wash2_capacity == '2' && "ความจุ 15 - 19 กก."}
                                                                    {post.data.wash2_capacity == '3' && "ความจุ 19 - 22 กก."}
                                                                    {post.data.wash2_capacity == '4' && "ความจุ 22 กก. ขึ้นไป"}  <br/>
                                                                    จำนวน {post.data.wash2_amount} เครื่อง
                                                                </div>
                                                            )}
                                                        </div>
                                                        <font style={{ fontSize: 14, color: '#626167' }}>
                                                            วันที่ประกาศ: {dateFormat(post.data.post_timestamp)}
                                                        </font>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexDirection: 'column', flex: flexRight }}>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div style={{ flexDirection: 'column', backgroundColor: '#FDEBE9', alignItems: 'center', alignContent: 'center', padding: 10 }}>
                                    <div style={{ textAlign: 'center' }}><font style={{ fontSize: fontSizeHeadSide, fontWeight: '600', color: '#FF6F62' }}>บริการล้างเครื่องซักผ้า</font></div>
                                </div>
                                <div id="divImageShop" style={{ display: 'flex', flexDirection: 'column', marginBottom: 15 }}>
                                {adsPosition5List?.map((shop, i) => (
                                    <div style={{ display: 'flex', flexDirection: 'row', cursor: 'pointer' }} key={i} onClick={() => { clickShopAds(5, shop.id) }}>
                                        <div style={{ flex: 3 }}>
                                            {(shop.images.length > 0) && 
                                            <img style={{ display: 'flex', objectFit: "contain", maxWidth: (widthImageShop/3), height: heightImageShop }} src={shop.images[0].data.url} alt="APP3R" />
                                            }
                                            {(shop.images.length == 0) && 
                                            <img style={{ display: 'flex', objectFit: "contain", maxWidth: (widthImageShop/3), height: heightImageShop }} src={noImage} alt="APP3R" />
                                            }
                                        </div>
                                        <div key={i} className="cut-text text-30-ch" style={{ padding: 5, flexDirection: 'row', fontSize: 14, marginTop: 5, marginLeft: 10, flexWrap: 'wrap', flex: 7 }}>
                                            <font style={{ color: '#3D5598', fontWeight: '600' }}>ร้าน/บริษัท: {(shop.shop.type == 2)?shop.shop.shopname:shop.shop.firstname+" "+shop.shop.lastname} </font><br/>
                                            <font style={{ color: '#3D5598', fontWeight: '600' }}>ที่ตั้ง: {shop.province} {shop.district}</font><br/>
                                            <font style={{ color: '#984740', fontWeight: '600' }}>คะแนน: {number_format.format(shop.shop.score_average)} </font><br/>
                                            <font style={{ color: '#626167' }}>ให้บริการไปแล้ว: {number_format.format(shop.appointment)} </font>
                                        </div>
                                    </div>
                                ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Home